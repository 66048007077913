import type { AxiosResponse } from "axios";
import { action, makeObservable, observable } from "mobx";

import type { Review } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";
import { containsError } from "@/utils/errors";

import { API_URL } from "../../components/constants";
import { objectToParamsString } from "../helpers/ParamsHelper";
import type { MainStore } from "../Main";

export default class ReviewsStore {
  mainStore: MainStore;

  current: Review | undefined = undefined;
  allReviews: Array<Review> = [];
  requestedReviews: Array<Review> = [];
  approvedReviews: Array<Review> = [];
  pendingReviewCount: number = 0;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      current: observable,
      allReviews: observable,
      requestedReviews: observable,
      approvedReviews: observable,
      setCurrent: action,
      pendingReviewCount: observable,
    });

    this.mainStore = mainStore;
  }

  /**
    GET /api/react/reviews/all
  */
  async fetchAllReviews() {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/reviews/all`,
        headers: this.mainStore.getHeaders(),
      });

      this.setAllReviews(response.data.all_reviews);
      this.setRequestedReviews(response.data.requested_reviews);
      this.setApprovedReviews(response.data.approved_reviews);

      this.setPendingReviewCount(response.data.requested_reviews.length);

      return response.data;
    } catch (error) {
      window.console.log(`"Reviews#fetchAllReviews" error ${error}`);
    }
  }

  /**
    GET /api/react/reviews/requested
  */
  async fetchRequestedReviews() {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/reviews/requested`,
        headers: this.mainStore.getHeaders(),
      });

      this.setRequestedReviews(response.data.requested_reviews);
      this.setPendingReviewCount(response.data.pending_review_count);

      return response.data;
    } catch (error) {
      window.console.log(
        `"Reviews#fetchRequestedReviews" error ${error}`,
        error,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async createApproval(recordVersionID) {
    const params = { review_type: "approval" };
    this.create(recordVersionID, params);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async createDenial(recordVersionID) {
    const params = { review_type: "denial" };
    this.create(recordVersionID, params);
  }

  // POST /api/react/record_versions/:record_version_id/create
  // do not use this directly, use shortcuts CreateApproval and Create Denial
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async create(recordVersionID, params) {
    const paramsString = objectToParamsString(params);
    try {
      const response: AxiosResponse<Review> = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/reviews?${paramsString}`,
        headers: this.mainStore.getHeaders(),
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        if (
          containsError(
            // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
            response.response.data.errors,
            "base",
            "Validation failed: Record version has already been taken",
          )
        ) {
          this.mainStore.toast.setText(
            "You have already approved this record. If this continues to occur, try refreshing or signing out and signing back in.",
          );
        }
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          response.response.data.cells_errors,
        );
        return;
      }

      this.setCurrent(response.data);
      this.fetchRequestedReviews();
    } catch (error) {
      window.console.log(`"Reviews#create" error ${error}`);
    }
  }

  // # POST /api/react/record_versions/:external_token/reviews/public_create/:user_token
  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  async publicCreate(params) {
    const paramsString = objectToParamsString({
      review_type: params.reviewType,
    });

    try {
      const response: AxiosResponse<Review> = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${params.externalToken}/reviews/public_create/${params.userToken}?${paramsString}`,
        headers: this.mainStore.getHeaders(),
      });

      return response;
    } catch (error) {
      window.console.log(`"Reviews#publicCreate" error ${error}`);
      return error;
    }
  }

  // DELETE /api/react/record_versions/:record_version_id/reviews
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID, deleteAll = false) {
    const params = {};
    if (deleteAll) {
      // @ts-expect-error TS(2339) FIXME: Property 'delete_all' does not exist on type '{}'.
      params.delete_all = true;
    }

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/record_versions/${recordVersionID}/reviews`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });
    } catch (error) {
      window.console.log(`"Approvals#delete" error ${error}`);
    }
  }

  setCurrent(value: Review | undefined) {
    this.current = value;
  }

  setAllReviews(list: Array<Review>) {
    this.allReviews = list;
  }

  setRequestedReviews(list: Array<Review>) {
    this.requestedReviews = list;
  }

  setApprovedReviews(list: Array<Review>) {
    this.approvedReviews = list;
  }

  setPendingReviewCount(count: number) {
    this.pendingReviewCount = count;
  }

  // Store Helpers

  cleanup() {
    this.setCurrent(undefined);
    this.setAllReviews([]);
    this.setRequestedReviews([]);
    this.setApprovedReviews([]);
  }
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const HeartSolid = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.1983 3.17118C17.6948 3.28855 19.0999 3.93722 20.16 5.00001C21.3275 6.17475 21.9829 7.76374 21.9829 9.42001C21.9829 11.0763 21.3275 12.6653 20.16 13.84L13.95 20.06C13.43 20.5718 12.7296 20.8586 12 20.8586C11.2704 20.8586 10.57 20.5718 10.05 20.06L3.83997 13.84C2.71479 12.7227 2.05475 11.2205 1.99277 9.63603C1.93079 8.05153 2.47148 6.5024 3.50594 5.3006C4.54041 4.09879 5.99182 3.33358 7.56789 3.15905C9.14396 2.98452 10.7276 3.41365 12 4.36001C13.2127 3.47548 14.7018 3.05381 16.1983 3.17118Z"
      fill="#353549"
    />
  </svg>
);

HeartSolid.displayName = "HeartSolidIcon";

export default HeartSolid;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import checkIcon from "@/images/table-image/icon/check-icon.svg";

import plusBlack from "../../../../images/table-image/icon/plus-black.svg";

interface Props {
  fieldName: string;
  hasErrors: boolean;
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions: any[];
  width: number | string;
  dataTestId?: string;
  errorMessage?: string;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  isMultiSelect?: boolean;
}

function TagSelect({
  fieldName,
  recordVersionID,
  selectedOptions,
  width,
  hasErrors,
  errorMessage,
  locked,
  hasErrorClass,
  dataTestId,
  pinned,
  isMultiSelect,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const titleInputRef = useRef();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selectedOptions);
  const [addNew, setAddNew] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const { company } = mainStore.companies;
  const { tags } = mainStore.tags;

  // Effects
  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (addNew) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      titleInputRef.current.focus();
    }
  }, [addNew]);

  const refreshProducts = () => {
    if (company?.id) {
      mainStore.tags.index(company.id);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createNewTag();
    }
  };

  const createNewTag = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'undefined... Remove this comment to see the full error message
    const { value } = titleInputRef.current;
    if (value === 0) {
      return;
    }

    if (company && company.id) {
      mainStore.tags.create(company.id, value);
    }

    setAddNew(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const addItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const onClose = () => {
    if (selectedItems !== selectedOptions) {
      mainStore.recordVersions.update({
        fieldName,
        recordVersionID,
        value: mainStore.avroSchemas.serializeValue(fieldName, selectedItems),
      });
    }

    setAddNew(false);
  };

  const handlePopUpOpen = () => {
    refreshProducts();
    setShowPopup(true);
    setIsSelectModalOpen(true);
  };

  const handlePopUpClose = () => {
    onClose();
    setShowPopup(false);
    setIsSelectModalOpen(false);
  };

  const liClassNames = classNames({
    active: isSelectModalOpen,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "locked-cell pointer-events-none": locked,
    pinned,
  });

  const renderTrigger = (
    <li className={liClassNames} style={{ width }} data-testid={dataTestId}>
      <div className="cell-content">
        {errorMessage ? (
          <span>{errorMessage}</span>
        ) : (
          <div className="options tags">
            {tags
              .filter((tag) => selectedOptions?.includes(tag.id))
              .map((tag) => (
                <div className="option tag" key={tag.id}>
                  <span className="value value-tag">{tag.title}</span>
                </div>
              ))}
          </div>
        )}
      </div>
    </li>
  );

  const renderContent = (
    <div
      className="options-dropdown tags"
      data-testid={`${dataTestId}-options`}
    >
      <div className="options-dropdown-list">
        <ul>
          <div>
            {tags.length === 0 && (
              <h4 className="no-result">No result found</h4>
            )}

            {tags.map((tag) => {
              const isSelected = selectedItems.includes(tag.id);
              return (
                <li
                  key={tag.id}
                  className={classNames({
                    active: selectedItems.includes(tag.id),
                  })}
                  onClick={() => {
                    addItem(tag.id);
                  }}
                  data-testid="tag-select-li"
                >
                  {isMultiSelect && (
                    <span
                      className={classNames("select-option-checkbox", {
                        checked: isSelected,
                      })}
                    >
                      {isSelected && <img src={checkIcon} alt="check-icon" />}
                    </span>
                  )}
                  <span className="item">{tag.title}</span>
                </li>
              );
            })}
          </div>
        </ul>
        {mainStore.userPermissions.canManageTags && (
          <div className="option-link">
            {addNew && (
              <div className="option-link-element option-link-form">
                <input
                  type="text"
                  placeholder="Add New +"
                  // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
                  ref={titleInputRef}
                  onKeyDown={handleKeyDown}
                />
                <button onClick={createNewTag}>
                  <img src={plusBlack} alt="plus-black" />
                </button>
              </div>
            )}

            {!addNew && (
              <button
                className="option-link-element option-add-new"
                onClick={() => {
                  setAddNew(true);
                }}
              >
                Add New +
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      {renderContent}
    </Popup>
  );
}

TagSelect.defaultProps = {
  hasErrors: false,
  hasErrorClass: "has-errors",
  dataTestId: "tag-select-input",
};

export default observer(TagSelect);

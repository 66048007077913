import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import { Button, TextButton, Typography } from "../../Elements";
import DuplicateWorkspaceLoader from "./DuplicateWorkspaceLoader";
import { compareModules } from "./helpers";
import ModuleSummaryItem from "./ModuleSummaryItem";

interface DuplicatePreviewProps {
  sourceWorkspaceId: string;
  newWorkspaceName: string;
  onConfirm: () => void;
  onBack: () => void;
  onCancel: () => void;
  loading: boolean;
  selectedModules: string[];
  modulesWithRecords: string[];
  defaultModules: string[];
}

const DuplicatePreview = (props: DuplicatePreviewProps) => {
  const mainStore = useMainStore();

  const isLoading =
    mainStore.workspaceSummary.currentWorkspaceId !== props.sourceWorkspaceId ||
    mainStore.workspaceSummary.modules.length === 0;

  useEffect(() => {
    mainStore.workspaceSummary.index(props.sourceWorkspaceId);
  }, [props.sourceWorkspaceId]);

  return (
    <div className="duplicate-preview">
      <Typography
        label="Review your Workspace template below"
        weight="semiBold"
        color="generalLightBlue"
      />
      <Typography
        label={props.newWorkspaceName}
        size="smh"
        weight="semiBold"
        color="generalMidnightDark"
        className="duplicate-preview__name"
      />

      {isLoading && <DuplicateWorkspaceLoader />}
      {!isLoading && (
        <div className="duplicate-preview__modules">
          <Typography
            label="Included Internal Modules"
            color="brandingDimViolet"
            size="sm"
            weight="semiBold"
            className="duplicate-preview__modules-title"
          />
          {[...mainStore.workspaceSummary.modules]
            .sort((a, b) => compareModules(a, b, props.defaultModules))
            .filter((module) =>
              props.selectedModules.includes(module.identifier),
            )
            .map((module) => (
              <ModuleSummaryItem
                key={module.id}
                summary={module}
                canCopyOver={props.modulesWithRecords.includes(
                  module.identifier,
                )}
              />
            ))}
        </div>
      )}

      <Typography
        label="Collaboration workspace will be applied to the new workspace configurations. You can change them in settings after the workspace has been created."
        size="sm"
        weight="semiBold"
        className="duplicate-preview__notes"
      />
      <div className="duplicate-preview__actions">
        <Button
          label="Back"
          theme="tertiary"
          onClick={props.onBack}
          disabled={props.loading}
        />
        <Button
          label="Create Workspace"
          onClick={props.onConfirm}
          disabled={isLoading || props.loading}
          active={props.loading}
        />
      </div>
      <TextButton
        label="Cancel"
        theme="destructive"
        onClick={props.onCancel}
        disabled={props.loading}
      />
    </div>
  );
};

export default observer(DuplicatePreview);

export const RISK_REGISTER_SCORING_COLORS = [
  "#4C50BE",
  "#6D8CF9",
  "#6EC8F9",
  "#FDBD32",
  "#FF87A7",
  "#F95B46",
  "#E63956",
  "#9CCC65",
  "#FFEE58",
];
export const INHERENT_MATRIX_TITLE = "Inherent Risk";
export const RESIDUAL_MATRIX_TITLE = "Residual Risk";

import type { ColDef } from "@ag-grid-community/core";
import type { MiniTagProps } from "@themis/ui";
import { LinkButton, MiniTag } from "@themis/ui";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import type { RecordVersionDataRiskLevelOptions } from "@/api";
import { Typography } from "@/components/Elements";

import type { TableRows } from "../select-table-rows/select-table-rows";
import { PiiCategory } from "./pii-category/pii-category";
import { QuestionnairesCell } from "./questionnaires-cell/questionnaires-cell";

export const useDueDiligenceTableColumns = (
  workspaceId: string,
): ColDef<TableRows[number]>[] => {
  const { formatMessage } = useIntl();

  return useMemo((): ColDef<TableRows[number]>[] => {
    return [
      {
        sortable: false,
        field: "name",
        headerName: formatMessage({ defaultMessage: "Account Name" }),
        cellStyle: { padding: "0 8px" },
        minWidth: 160,
        cellRenderer: (props: { data: TableRows[number] }) => {
          return <Typography>{props.data.name}</Typography>;
        },
      },
      {
        headerName: formatMessage({ defaultMessage: "Questions" }),
        field: "checklistCount",
        cellStyle: { padding: "0 8px" },
        minWidth: 160,
        cellRenderer: (props: { data: TableRows[number] }) => {
          return (
            <QuestionnairesCell
              completed={props.data.completedChecklistCount ?? 0}
              length={props.data.checklistCount ?? 0}
            />
          );
        },
      },
      {
        headerName: formatMessage({ defaultMessage: "Contracts" }),
        field: "contracts",
        cellStyle: { padding: "0 8px" },
        sortable: true,
        minWidth: 120,
        cellRenderer: (props: { data: TableRows[number] }) => {
          const contractsLength = props.data.contracts?.length ?? 0;

          return (
            <Typography>
              {formatMessage(
                {
                  defaultMessage:
                    "{count, plural, =0 {# Contract} one {# Contract} other {# Contracts}}",
                },
                { count: contractsLength },
              )}
            </Typography>
          );
        },
      },
      {
        cellStyle: { padding: "0 8px" },
        headerName: formatMessage({ defaultMessage: "Risk Level" }),
        field: "riskLevel",
        sortable: true,
        minWidth: 120,
        cellRenderer: (props: { data: TableRows[number] }) => {
          if (props.data.riskLevel == null) {
            return null;
          }

          const colors: Record<
            RecordVersionDataRiskLevelOptions,
            MiniTagProps["color"]
          > = {
            high: "red",
            low: "green",
            medium: "yellow",
          };

          return (
            <MiniTag
              className="tw-capitalize"
              color={colors[props.data.riskLevel]}
            >
              {props.data.riskLevel}
            </MiniTag>
          );
        },
      },
      {
        cellStyle: { padding: "0 8px" },
        headerName: formatMessage({ defaultMessage: "PII Provided" }),
        field: "piiProvided",
        sortable: true,
        minWidth: 120,
        cellRenderer: (props: { data: TableRows[number] }) => {
          return (
            <Typography className="tw-capitalize">
              {props.data.piiProvided === "yes"
                ? formatMessage({ defaultMessage: "Yes" })
                : formatMessage({ defaultMessage: "No" })}
            </Typography>
          );
        },
      },
      {
        cellStyle: { padding: "0 8px" },
        headerName: formatMessage({ defaultMessage: "PII Categories" }),
        field: "piiCategories",
        sortable: true,
        autoHeight: true,
        minWidth: 180,
        cellRenderer: (props: { data: TableRows[number] }) => {
          return (
            <ul className="tw-overflow-scroll">
              {props.data.piiCategories.map((category) => (
                <li key={category}>
                  <PiiCategory category={category} />
                </li>
              ))}
            </ul>
          );
        },
      },
      {
        headerName: formatMessage({ defaultMessage: "Material" }),
        sortable: true,
        field: "material",
        cellStyle: { padding: "0 8px" },
        minWidth: 100,
        cellRenderer: (props: { data: TableRows[number] }) => {
          return (
            <Typography className="tw-capitalize">
              {props.data.material === "yes"
                ? formatMessage({ defaultMessage: "Yes" })
                : formatMessage({ defaultMessage: "No" })}
            </Typography>
          );
        },
      },
      {
        cellStyle: { padding: "0 8px" },
        sortable: false,
        minWidth: 100,
        cellRenderer: (props: { data: TableRows[number] }) => {
          return (
            <LinkButton
              color="tertiary"
              to={generatePath(
                "/workspaces/:workspaceId/modules/vendor-due-diligence/:vendorId",
                {
                  workspaceId,
                  vendorId: props.data.id,
                },
              )}
            >
              {formatMessage({ defaultMessage: "View" })}
            </LinkButton>
          );
        },
      },
    ];
  }, [formatMessage, workspaceId]);
};

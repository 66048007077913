import type { ValueFormatterParams } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import type { PreviewTableColumn } from "@themis/ui";
import React from "react";
import { PiGlobeBold, PiTextTBold } from "react-icons/pi";
import { generatePath } from "react-router-dom";

import type { Account, FieldOption } from "@/api";

import { AccountTypeCellTooltip } from "../components/account-type-cell-tooltip";
import { AccountTypesTags } from "../components/account-type-tags/account-types-tags";
import { AccountActionsCell } from "../components/AccountsTable/AccountActionsCell";
import { AccountContactCell } from "../components/AccountsTable/AccountContactCell";
import { AccountIdLinkCell } from "../components/AccountsTable/AccountIdLinkCell";
import { ActiveTag } from "../components/ActiveTag";
import { ACCOUNT_DETAILS_PATH } from "../pages";

export const accountTableColumns = ({
  workspaceId,
  accountTypeFieldOptions,
}: {
  workspaceId: number;
  accountTypeFieldOptions: FieldOption[];
}) => [
  {
    headerName: "Title",
    field: "name",
    editable: false,
    minWidth: 250,
    cellRenderer: "identifierCellRenderer",
    cellRendererParams: (props: CustomCellRendererProps) => {
      const accountId = props.data.id;
      return {
        to: generatePath(ACCOUNT_DETAILS_PATH, {
          workspace_id: workspaceId,
          accountId,
        }),
        ...props,
      };
    },
  },
  {
    headerName: "Account Types",
    field: "account_types",
    tooltipField: "account_types",
    tooltipComponentParams: {
      accountTypeFieldOptions,
    },
    tooltipComponent: AccountTypeCellTooltip,
    cellStyle: () => {
      return { paddingLeft: "8px" };
    },
    valueFormatter: (params: ValueFormatterParams) =>
      params.value?.length ? params.value[0] : "",
    cellRenderer: (
      props: CustomCellRendererProps<Account, Account["account_types"]>,
    ) => (
      <AccountTypesTags
        isTableTooltip
        accountTypes={props.value || []}
        accountTypeFieldOptions={accountTypeFieldOptions}
        numberOfAccountTypesToShow={2}
      />
    ),

    minWidth: 300,
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 200,
    cellStyle: () => {
      return { paddingLeft: "8px" };
    },
    cellRenderer: (props: CustomCellRendererProps) => {
      return <ActiveTag isActive={props.value === "active"} />;
    },
  },
  {
    headerName: "Parent Account",
    minWidth: 200,
    field: "parent_account_name",
    cellRenderer: "textRenderCell",
    valueFormatter: (params: ValueFormatterParams) => params.value || "N/A",
  },
  {
    headerName: "Description",
    field: "description",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "Company Website",
    field: "website",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "Main Contact",
    field: "main_contact",
    minWidth: 200,
    cellStyle: () => {
      return { paddingLeft: "8px" };
    },
    cellRenderer: (props: CustomCellRendererProps) =>
      props.value ? (
        <AccountContactCell contact={props.value} display="name" />
      ) : null,
  },
  {
    headerName: "Company Phone",
    field: "phone",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "Legal Entity Name",
    field: "legal_name",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "DUNS Number",
    field: "duns_number",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "Street",
    field: "mailing_street",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "City",
    field: "mailing_city",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "State",
    field: "mailing_state",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "Postal Code",
    field: "mailing_postal_code",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "Country",
    field: "mailing_country",
    minWidth: 200,
    cellRenderer: "textRenderCell",
  },
  {
    headerName: "",
    minWidth: 40,
    width: 40,
    sortable: false,
    resizable: false,
    pinned: "right" as const,
    cellStyle: () => {
      return { justifyContent: "center" };
    },
    cellRenderer: (props: CustomCellRendererProps) => (
      <AccountActionsCell accountId={props.data.id} />
    ),
  },
];

export const PREVIEW_CHILD_ACCOUNTS_COLUMNS: PreviewTableColumn<Account>[] = [
  {
    title: "ID",
    type: "id",
    width: 160,
    Icon: PiGlobeBold,
    Component: (account) => <AccountIdLinkCell accountId={account.id} />,
  },
  {
    key: "name",
    title: "Name",
    type: "locked",
    width: 676,
    isRowStart: true,
    isRowEnd: true,
    Icon: PiTextTBold,
  },
] as const;

import { useRouteMatch } from "react-router-dom";

/**
 * Custom hook to determine if user is on a custom data page within a module
 * such as `/workspaces/:workspace_id/modules/:moduleIdentifier/notifications`
 * (can add more to the list later).
 *
 * This is used to prevent fetching Table details and RecordVersions in
 * `<DataLoader />` on those module custom data pages and triggering that same
 * fetch when going back to the same module base page (was not triggering
 * before and was causing infinite looking loader on page).
 */
export function useIsModuleCustomDataPage() {
  // Can add more to list in `useRouteMatch` arguments for future use
  const match = useRouteMatch([
    "/workspaces/:workspace_id/modules/:moduleIdentifier/notifications",
  ]);

  return !!match;
}

import { PreviewTable } from "@themis/ui";
import React from "react";
import { useParams } from "react-router-dom";

import { useRecordVersion } from "@/api/queries/recordVersions";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";
import type { TopLevelModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { PREVIEW_RELATED_FINDINGS_COLUMNS } from "../../config/previewRelatedFindingsTable";
import { PreviewRelatedFindingActionsCell } from "./PreviewRelatedFindingsActionsCell";

export function PreviewRelatedFindingList({
  isModuleAdded,
}: {
  isModuleAdded: (moduleIdentifier: TopLevelModuleIdentifier) => boolean;
}) {
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const {
    data: recordVersionData,
    isPending,
    isError,
  } = useRecordVersion(Number(record_version_id));

  if (isPending || isError) {
    return null;
  }

  const linkedFindings =
    recordVersionData.data.record_version.record.linked_findings;

  return (
    <InfoPreviewSection
      title="Related Findings"
      countDescription="Finding"
      count={linkedFindings.length}
      content={
        Boolean(linkedFindings.length) && (
          <PreviewTable
            responsive
            columns={PREVIEW_RELATED_FINDINGS_COLUMNS}
            rows={linkedFindings}
            // eslint-disable-next-line react/no-unstable-nested-components
            rowActions={(linkedFinding) => (
              <PreviewRelatedFindingActionsCell
                {...linkedFinding}
                isModuleAdded={isModuleAdded}
              />
            )}
          />
        )
      }
    />
  );
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  SaveControlsModulesMutationRequest,
  SaveControlsModulesMutationResponse,
  SaveControlsModulesPathParams,
} from "../../models/SaveControlsModules";

/**
 * @description Save controls modules
 * @summary Save controls modules
 * @link /record_versions/:record_version_id/controls/modules
 */
export async function saveControlsModules(
  recordVersionId: SaveControlsModulesPathParams["record_version_id"],
  data: SaveControlsModulesMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<SaveControlsModulesMutationResponse>["data"]> {
  const res = await client<
    SaveControlsModulesMutationResponse,
    SaveControlsModulesMutationRequest
  >({
    method: "post",
    url: `/record_versions/${recordVersionId}/controls/modules`,
    data,
    ...options,
  });
  return res.data;
}

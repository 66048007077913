const PASSWORD_MIN_LENGTH = 8;
const UPPERCASE_REGEX = /[A-Z]/;
const LOWERCASE_REGEX = /[a-z]/;
const NUMBERS_REGEX = /[0-9]/;
const SYMBOL_CHARACTERS = "#?!@$%^&*-";
const SYMBOLS_REGEX = new RegExp(`(?=.*?[${SYMBOL_CHARACTERS}])`);

const PASSWORD_RULES: Array<{
  label: string;
  validator: (password: string) => boolean;
}> = [
  {
    label: "At least 8 characters",
    validator: (password) => password.length >= PASSWORD_MIN_LENGTH,
  },
  {
    label: "1 uppercase letter",
    validator: (password) => UPPERCASE_REGEX.test(password),
  },
  {
    label: "1 lowercase letter",
    validator: (password) => LOWERCASE_REGEX.test(password),
  },
  {
    label: "1 number",
    validator: (password) => NUMBERS_REGEX.test(password),
  },
  {
    label: "1 symbol",
    validator: (password) => SYMBOLS_REGEX.test(password),
  },
];

// @ts-expect-error TS(7006) FIXME: Parameter 'password' implicitly has an 'any' type.
export function validatePassword(password, confirmation) {
  const contentRules = PASSWORD_RULES.map((rule) => ({
    label: rule.label,
    isValid: rule.validator(password),
  }));

  const doesMatch = password === confirmation;
  return {
    contentRules,
    doesMatch,
    isValid: contentRules.every((result) => result.isValid) && doesMatch,
  };
}

import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import RiskRegisterInherentValuesCell from "../../risk-register/RiskRegisterInherentValuesCell";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  fieldName?: string;
  isEditable?: boolean;
  locked?: boolean;
}

function ViewRiskRegisterInherentValuesCell({
  recordVersion,
  fieldName,
  recordVersionID,
  locked,
  isEditable,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const value = mainStore.avroSchemas.firstValueForField(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    fieldName,
    recordVersion.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersionID,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <RiskRegisterInherentValuesCell
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      fieldName={fieldName}
      value={value}
      recordVersionID={recordVersionID}
      locked={locked}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      hasErrorClass="detail-view-has-errors"
      // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
      isUserEditable={isEditable}
    />
  );
}

ViewRiskRegisterInherentValuesCell.defaultProps = {
  isEditable: true,
};

export default observer(ViewRiskRegisterInherentValuesCell);

import classNames from "classnames";
import React from "react";

interface Props {
  title: string;
  enabled?: boolean;
  extraClassNames?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
}

function SmallButton({ title, onClick, enabled, extraClassNames }: Props) {
  const buttonClassNames = classNames("button", extraClassNames, {
    "button-active": enabled,
    "button-disabled": !enabled,
  });

  return (
    <button className={buttonClassNames} onClick={onClick} disabled={!enabled}>
      {title}
    </button>
  );
}

SmallButton.defaultProps = {
  enabled: true,
};

export default SmallButton;

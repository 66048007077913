import { useToast } from "@themis/ui";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

import type { RecordVersion } from "@/api";
import DashboardHeader from "@/components/dashboard/DashboardHeader";
import DetailView from "@/components/detailView/DetailView";
import {
  activeIssuesUrl,
  allIssuesUrl,
  completedIssuesUrl,
  metricsDashboardUrl,
} from "@/components/reports/common/MetricsPage/urlPaths";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import ActionPlans from "@/components/table/issue-management/ActionPlans";
import IMControls from "@/components/table/issue-management/IMControls";
import IMOperationalControls from "@/components/table/issue-management/IMOperationalControls";
import IMOperationalControlsAddNew from "@/components/table/issue-management/IMOperationalControlsAddNew";
import IMRelatedRisks from "@/components/table/issue-management/IMRelatedRisks";
import IMRelatedRisksAddNew from "@/components/table/issue-management/IMRelatedRisksAddNew";
import IssueManagement from "@/components/table/issue-management/IssueManagement";
import CreativeView from "@/components/table/shared/creative-view/CreativeView";
import ViewBuildChecklist from "@/components/table/shared/dynamic-form/ViewBuildChecklist";
import LegacyAuditTrail from "@/components/table/shared/LegacyAuditTrail";
import { useMainStore } from "@/contexts/Store";
import { useSearchParams } from "@/hooks/useSearchParams";

function minDateFunction(columnTitle: string, recordVersion: RecordVersion) {
  if (!recordVersion || columnTitle !== "due_date") {
    return;
  }

  const actionPlansDates = recordVersion.record.action_plans.reduce<number[]>(
    (dates, { target_completion_date }) => {
      return target_completion_date
        ? [...dates, dayjs(target_completion_date).valueOf()]
        : dates;
    },
    [],
  );

  return actionPlansDates.length
    ? dayjs(Math.max(...actionPlansDates)).toDate()
    : null;
}

const IssueManagementRouter = () => {
  const mainStore = useMainStore();
  const history = useHistory();
  const toast = useToast();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const workspaceId = Number(workspace_id);
  const [{ section_tag_id }] = useSearchParams<{ section_tag_id?: string }>();

  async function handleCreateIssue(data: unknown, files: unknown) {
    const response = await mainStore.issueManagement.create({
      workspaceID: workspaceId,
      tableName: mainStore.context.tableName as string,
      sectionTagID: section_tag_id ? Number(section_tag_id) : undefined,
      issueData: data,
      files,
    });

    if (response.errors) {
      return response;
    }

    toast({
      content: "The issue has been successfully created!",
      variant: "success",
    });

    history.push(
      `/workspaces/${workspaceId}/modules/issue-management/${response.id}`,
    );
  }

  return (
    <Switch>
      <Route
        path={[
          `/workspaces/:workspace_id${allIssuesUrl}`,
          `/workspaces/:workspace_id${activeIssuesUrl}`,
          `/workspaces/:workspace_id${completedIssuesUrl}`,
          `/workspaces/:workspace_id${metricsDashboardUrl}`,
        ]}
      >
        <IssueManagement />
      </Route>
      <Route path="*">
        <DashboardHeader />
        <DashboardContentWrapper>
          <Switch>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:parent_record_version_id/:moduleIdentifier/:record_version_id/audit_trail"
            >
              <LegacyAuditTrail isRecordView />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/:moduleIdentifier/audit_trail"
            >
              <LegacyAuditTrail />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/:moduleIdentifier/:record_version_id/audit_trail"
            >
              <LegacyAuditTrail isRecordView />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/:moduleIdentifier/:tab/:record_version_id/audit_trail"
            >
              <LegacyAuditTrail isRecordView />
            </Route>
            <Route
              key="/modules/issue_management/attachment_view"
              path="/workspaces/:workspace_id/modules/issue-management/attachment_view/:record_version_id/:file_id?"
            >
              <CreativeView />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:record_version_id/action-plans"
            >
              <ActionPlans />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/new-record"
            >
              <DetailView
                isNewRecord
                isDataFetchingDisabled
                onCreateRecord={handleCreateIssue}
                moduleIdentifier="issue_management"
                ignoredFields={["validator", "status", "action_plan_owners"]}
              />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:record_version_id"
            >
              <DetailView
                moduleIdentifier="issue_management"
                minDateFunction={minDateFunction}
              />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:parent_record_version_id/:moduleIdentifier/:record_version_id"
            >
              <DetailView
                moduleIdentifier="action_plans"
                maxDateFunction={(columnTitle, recordVersion) => {
                  if (
                    columnTitle === "target_completion_date" &&
                    recordVersion?.record?.parent_due_date
                  ) {
                    return new Date(recordVersion.record.parent_due_date);
                  }
                }}
              />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:record_version_id/controls"
            >
              <IMControls />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:record_version_id/operational-controls"
            >
              <IMOperationalControls />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:record_version_id/operational-controls-add-new"
            >
              <IMOperationalControlsAddNew />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:record_version_id/related-risks"
            >
              <IMRelatedRisks />
            </Route>
            <Route
              exact
              path="/workspaces/:workspace_id/modules/issue-management/:record_version_id/related-risks-add-new"
            >
              <IMRelatedRisksAddNew />
            </Route>

            <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:record_id/create/:field_name">
              <ViewBuildChecklist />
            </Route>
            <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:checklist_id/update">
              <ViewBuildChecklist editingMode />
            </Route>
            <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists-templates/:checklist_id/update">
              <ViewBuildChecklist editingMode isTemplate />
            </Route>
          </Switch>
        </DashboardContentWrapper>
      </Route>
    </Switch>
  );
};

export default observer(IssueManagementRouter);

/* eslint-disable no-param-reassign */
import { first, get, keys, pick, set } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { Button } from "../../Elements";
import Loading from "../../Loading";
import PermissionGroup from "./PermissionGroup";
import PermissionHeader from "./PermissionHeader";

// @ts-expect-error TS(7006) FIXME: Parameter 'permissionGroups' implicitly has an 'an... Remove this comment to see the full error message
const getGroupActions = (permissionGroups, groupName, isEdit) => {
  const groupData = get(
    // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
    permissionGroups.find((group) => keys(group)[0] === groupName),
    groupName,
  );

  const permission = isEdit ? { view: true, edit: true } : { view: true };

  const actions = groupData.actions.reduce(
    // @ts-expect-error TS(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
    (acc, action) => ({
      ...acc,
      [action.key]: { state: permission },
    }),
    {},
  );

  const groupActions = {
    all: { state: permission },
    actions,
  };

  if (groupData.targets) {
    const targets = groupData.targets.reduce(
      // @ts-expect-error TS(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
      (acc, target) => ({
        ...acc,
        [keys(target)[0]]: { state: permission },
      }),
      {},
    );

    // @ts-expect-error TS(2339) FIXME: Property 'targets' does not exist on type '{ all: ... Remove this comment to see the full error message
    groupActions.targets = targets;
  }

  return groupActions;
};

function PermissionDetail() {
  // Import MobX stores
  const mainStore = useMainStore();

  // States
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [permissionSet, setPermissionSet] = useState({});

  const [isLoadingPermissionGroups, setIsLoadingPermissionGroups] =
    useState(true);
  const [isLoadingPermissionSet, setIsLoadingPermissionSet] = useState(false);

  // Variables
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  // Hooks
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
  const isNewID = params.id === "new";

  useEffect(() => {
    loadPermissionGroups();
  }, []);

  useEffect(() => {
    if (!isNewID) {
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
      loadPermissionSet(params.id);
    }
  }, [params, isNewID]);

  const pageType = useMemo(() => {
    if (isNewID) {
      return "create";
    }

    const search = new URLSearchParams(location.search);
    if (search.get("clone") === "true") {
      return "clone";
    }

    return "edit";
  }, [location.search, isNewID]);

  // @ts-expect-error TS(2339) FIXME: Property 'read_only' does not exist on type '{}'.
  const isReadOnlyPermission = Boolean(permissionSet.read_only);
  const isReadOnlyPermissionEdit = isReadOnlyPermission && pageType === "edit";

  const isGroupDisabled = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'groupName' implicitly has an 'any' type... Remove this comment to see the full error message
    (groupName) => {
      if (isReadOnlyPermissionEdit) {
        return true;
      }

      if (!isNewID) {
        if (
          (["workspace", "module"].includes(groupName) &&
            // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
            permissionSet.permission_type === "company") ||
          // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
          (["workspace", "module"].includes(permissionSet.permission_type) &&
            groupName === "company")
        ) {
          return true;
        }
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
      const selectedGroups = keys(permissionSet.data);
      if (
        selectedGroups.length > 0 &&
        ((["workspace", "module"].includes(groupName) &&
          selectedGroups[0] === "company") ||
          (["workspace", "module"].includes(selectedGroups[0]) &&
            groupName === "company"))
      ) {
        return true;
      }

      return false;
    },
    [isReadOnlyPermissionEdit, permissionSet, isNewID],
  );

  const isSaveDisabled = useMemo(
    () =>
      isReadOnlyPermissionEdit ||
      // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
      !permissionSet.name ||
      // @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type '{}'... Remove this comment to see the full error message
      !permissionSet.description ||
      // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
      keys(permissionSet.data).length === 0 ||
      // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
      (permissionSet.data.module &&
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
        !permissionSet.data.module.all &&
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
        !permissionSet.data.module.actions),
    [permissionSet, isReadOnlyPermissionEdit],
  );

  // Variables
  const isLoading = isLoadingPermissionGroups || isLoadingPermissionSet;
  const isCreatePage = pageType === "create" || pageType === "clone";

  // Functions
  const loadPermissionGroups = async () => {
    setIsLoadingPermissionGroups(true);

    try {
      const data = await mainStore.permissions.getPermissionGroups();
      setPermissionGroups(data);
    } catch {
      setPermissionGroups([]);
    } finally {
      setIsLoadingPermissionGroups(false);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'permissionID' implicitly has an 'any' t... Remove this comment to see the full error message
  const loadPermissionSet = async (permissionID) => {
    setIsLoadingPermissionSet(true);

    try {
      const data = await mainStore.permissions.getPermissionSet(permissionID);
      initializePermissionSet(data);
    } catch (error) {
      initializePermissionSet({});
    } finally {
      setIsLoadingPermissionSet(false);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'saved' implicitly has an 'any' type.
  const initializePermissionSet = (saved) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'acc' implicitly has an 'any' type.
    const permissions = saved.data.reduce((acc, permissionGroup) => {
      const groupName = first(keys(permissionGroup));
      // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const { all, actions, targets } = permissionGroup[groupName];

      if (keys(all).length > 0) {
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        acc = { ...acc, ...set(acc, [groupName, "all"], all) };
      }

      if (keys(actions).length > 0) {
        // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
        actions.forEach((action) => {
          if (action.state && keys(action.state).length > 0) {
            acc = {
              ...acc,
              ...set(
                acc,
                [groupName, "actions", action.key, "state"],
                action.state,
              ),
            };
          }
        });
      }

      if (keys(targets).length > 0) {
        keys(targets).forEach((moduleID) => {
          const state = get(targets, [moduleID, "state"]);

          if (keys(state).length > 0) {
            acc = {
              ...acc,
              // @ts-expect-error TS(2769) FIXME: No overload matches this call.
              ...set(acc, [groupName, "targets", moduleID, "state"], state),
            };
          }
        });
      }

      return acc;
    }, {});

    setPermissionSet({
      ...saved,
      name: `${saved.name}${pageType === "clone" ? " - Copy" : ""}`,
      data: permissions,
    });
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
  const handlePermissionDetailChange = (key, value) => {
    setPermissionSet((prevPermission) => ({ ...prevPermission, [key]: value }));
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'groupName' implicitly has an 'any' type... Remove this comment to see the full error message
  const handlePermissionGroupChange = (groupName, changes) => {
    if (changes.all) {
      const isEdit = Boolean(changes.all.state.edit);
      const workspaceActions = getGroupActions(
        permissionGroups,
        "workspace",
        isEdit,
      );
      const moduleActions = getGroupActions(permissionGroups, "module", isEdit);

      const changesWithSub = { [groupName]: changes, module: moduleActions };

      if (groupName === "company") {
        // @ts-expect-error TS(2339) FIXME: Property 'workspace' does not exist on type '{ [x:... Remove this comment to see the full error message
        changesWithSub.workspace = workspaceActions;
      }

      setPermissionSet((prevSate) => ({
        ...prevSate,
        data: {
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
          ...prevSate.data,
          ...changesWithSub,
        },
      }));
      return;
    }

    if (keys(changes).length === 0) {
      const newPermissionSet = { ...permissionSet };
      // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
      delete newPermissionSet.data[groupName];
      setPermissionSet(newPermissionSet);
      return;
    }

    setPermissionSet((prevState) => ({
      ...prevState,
      data: {
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
        ...prevState.data,
        [groupName]: {
          ...changes,
        },
      },
    }));
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const handleModuleLockChange = (value) => {
    setPermissionSet((prevState) => ({ ...prevState, locked_modules: value }));
  };

  const handleSave = () => (isCreatePage ? handleCreate() : handleUpdate());

  const handleCreate = async () => {
    try {
      await mainStore.permissions.createPermissionSet(permissionSet);
      mainStore.toast.setText("A new role has been added!");
      history.push("/settings/roles");
    } catch {
      mainStore.toast.setErrorText("Failed to create role!");
    }
  };

  const handleUpdate = async () => {
    const permissionSetParams = [
      !isReadOnlyPermission && "name",
      "description",
      "data",
      "locked_modules",
    ].filter(Boolean);
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    const payload = pick(permissionSet, permissionSetParams);

    try {
      await mainStore.permissions.updatePermissionSet(
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
        permissionSet.id,
        payload,
      );
      mainStore.toast.setText("Role has been updated!");
      history.push("/settings/roles");
    } catch {
      mainStore.toast.setErrorText("Failed to update role!");
    }
  };

  if (isLoading) {
    return (
      <>
        <PermissionHeader type={isCreatePage ? "create" : "edit"} />
        <Loading loadingLayout="small-table" showTableHeader={false} />
      </>
    );
  }

  const saveButton = (
    <Button
      label="Save Role"
      size="sm"
      disabled={isSaveDisabled}
      data-testid="save-role"
      onClick={handleSave}
    />
  );

  return (
    <>
      <PermissionHeader
        type={isCreatePage ? "create" : "edit"}
        rightSideElement={saveButton}
      />
      {isLoading ? (
        <Loading loadingLayout="small-table" showTableHeader={false} />
      ) : (
        <div className="permission-detail">
          <div className="permission-detail-content">
            <div className="permission-detail-section permission-detail-main-section">
              <div className="permission-detail-role-input">
                Role Name
                <div>
                  <input
                    placeholder="Type here"
                    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
                    value={permissionSet.name || ""}
                    disabled={isReadOnlyPermissionEdit}
                    data-testid="role-name"
                    onChange={(evt) =>
                      handlePermissionDetailChange("name", evt.target.value)
                    }
                  />
                </div>
              </div>

              <div className="permission-detail-role-input">
                Role Description
                <div>
                  <textarea
                    placeholder="Type here"
                    // @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type '{}'... Remove this comment to see the full error message
                    value={permissionSet.description || ""}
                    disabled={isReadOnlyPermissionEdit}
                    data-testid="role-description"
                    onChange={(evt) =>
                      handlePermissionDetailChange(
                        "description",
                        evt.target.value,
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <section className="permission-detail-section">
              <div className="permission-detail-section-title">
                Associated Permissions
              </div>

              {permissionGroups.map((permissionGroupObj, idx) => {
                const groupName = first(keys(permissionGroupObj));
                // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
                const permissionGroup = permissionGroupObj[groupName];
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                const selection = get(permissionSet, ["data", groupName]);

                return (
                  <PermissionGroup
                    key={idx}
                    groupName={groupName}
                    permissionGroup={permissionGroup}
                    selection={selection}
                    // @ts-expect-error TS(2339) FIXME: Property 'locked_modules' does not exist on type '... Remove this comment to see the full error message
                    modulesLocked={permissionSet.locked_modules}
                    modulesLockDisabled={!isCreatePage}
                    disabled={isGroupDisabled(groupName)}
                    onChange={(changes) =>
                      handlePermissionGroupChange(groupName, changes)
                    }
                    onModuleLockChange={handleModuleLockChange}
                    moduleWorkspaces={moduleWorkspaces}
                  />
                );
              })}
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default observer(PermissionDetail);

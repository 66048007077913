import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useLinkToEdit } from "../../../../hooks/useChecklistNavigatePath";
import closeIcon from "../../../../images/table-image/icon/close-icon2.svg";
import responseIcon from "../../../../images/table-image/icon/response.svg";
import Switch from "../../shared/Switch";

const SUCCESS_MSG_TIMEOUT = 2000;

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checklist: any;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClose?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleNAClick?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOnBlur?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSwitchClick?: (...args: any[]) => any;
  isCustomField?: boolean;
  modulePath?: string;
}

function CompleteChecklistSidebar({
  handleClose,
  checklist,
  disabled,
  handleSwitchClick,
  handleNAClick,
  handleOnBlur,
  isCustomField,
  modulePath,
}: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  // State
  const [savedItem, setSavedItem] = useState(null);

  const checklistID = checklist.id;
  const linkToEdit = useLinkToEdit(
    workspaceID!,
    modulePath!,
    checklistID,
    Boolean(isCustomField),
  );

  // Elements
  const renderHeading = (
    <div className="heading" data-testid="checklist-complete-sidebar-header">
      <h3>{checklist.name}</h3>
      <div>
        {!disabled && <Link to={linkToEdit}>Edit</Link>}
        <img src={closeIcon} alt="cm-sidebar-close" onClick={handleClose} />
      </div>
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const renderChecklistQuestionItem = (item) => {
    const renderNameRow = (
      <div className="question-item-row name">
        <p className="name">
          {item.new_product_question?.name || item.question?.name}
        </p>
        <div
          className={classNames("saved-success-msg", {
            active: savedItem === item.id,
          })}
          data-testid="checklist-response-saved"
        >
          <img src={responseIcon} alt="response-icon" />
          <span>Response Saved</span>
        </div>
      </div>
    );

    const renderFinalResponse = (
      <div
        className="question-final-response"
        data-testid="checklist-question-final-response"
      >
        <span>Response:</span>
        <div
          className={classNames({
            yes: item.answer === "Yes",
            no: item.answer === "No",
          })}
        >
          {item.answer || "N/A"}
        </div>
      </div>
    );

    const renderItemRow = (
      <div className="question-item-row">
        <div
          className="checklist-complete-sidebar-switch"
          data-testid="checklist-complete-sidebar-switch-container"
        >
          <Switch
            active={item.answer !== "N/A"}
            indeterminate={item.answer === "N/A" || !item.answer}
            checked={item.answer === "Yes"}
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onChange={(e) => handleSwitchClick(item.id, e)}
          />
          <span className="switch-value">{item.answer}</span>
        </div>

        <div
          className={classNames("na-container", {
            active: item.answer === "N/A",
          })}
        >
          <label>
            <input
              type="checkbox"
              checked={item.answer === "N/A"}
              // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onChange={(e) => handleNAClick(item.id, e)}
              data-testid="checklist-complete-sidebar-na"
            />
            <span>N/A</span>
          </label>
        </div>
      </div>
    );

    const renderTextArea = (
      <textarea
        defaultValue={item.comment}
        disabled={item.answer === "N/A" || disabled}
        placeholder={
          disabled ? "– No Additional Comments –" : "Additional Comments"
        }
        onBlur={(e) => handleLocalOnBlur(e, item)}
        data-testid="checklist-complete-sidebar-comment"
      />
    );

    return (
      <div
        key={item.id}
        className="question-item"
        data-testid="checklist-complete-sidebar-item"
      >
        {renderNameRow}
        {disabled ? renderFinalResponse : renderItemRow}
        {renderTextArea}
      </div>
    );
  };

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleLocalOnBlur(e, item) {
    const { value } = e.target;
    const { id, comment } = item;
    if (value === comment) {
      return;
    }

    setSavedItem(id);
    setTimeout(() => setSavedItem(null), SUCCESS_MSG_TIMEOUT);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleOnBlur(value, id);
  }

  return (
    <section
      className="checklists-sidebar"
      data-testid="checklist-complete-sidebar"
    >
      {renderHeading}
      {checklist.checklist_questions.map(renderChecklistQuestionItem)}
    </section>
  );
}

CompleteChecklistSidebar.defaultProps = {
  disabled: false,
  isCustomField: false,
  handleClose: () => {},
  handleSwitchClick: () => {},
  handleNAClick: () => {},
  handleOnBlur: () => {},
};

export default observer(CompleteChecklistSidebar);

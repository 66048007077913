import React from "react";
import type { Control } from "react-hook-form";
import { useFieldArray, useWatch } from "react-hook-form";

import {
  Button,
  Flex,
  IconButton,
  MiniTag,
  Typography,
} from "@/components/Elements";
import type { DateNotificationFormValues } from "@/features/notifications/pages/single/date/types";

import MultiSelectField from "../multi-select-field";
import SelectField from "../select-field";

interface CriteriaProps {
  control: Control<DateNotificationFormValues>;
  getOptions: (
    fieldName: string,
    index?: number,
  ) => {
    value: string;
    label: string;
    colorSchemeId?: number;
  }[];
}

const emptyCriteria = { fieldName: "", fieldValues: [] };

export const Criteria: React.FC<CriteriaProps> = ({ control, getOptions }) => {
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "criteria",
  });

  const selectedCriteria = useWatch({ control, name: "criteria" });

  const fieldNameOptions = getOptions("criteria_name");

  const filteredFieldNameOptions = (index: number) =>
    fieldNameOptions.filter((fieldNameOption) => {
      // Always keep selected option that corresponds to `selectedCriteria[index].fieldName`
      if (selectedCriteria[index]?.fieldName === fieldNameOption.value) {
        return true;
      }

      return !selectedCriteria
        .map((singleSelectedCriteria) => singleSelectedCriteria.fieldName)
        .includes(fieldNameOption.value);
    });

  const disabled = fieldNameOptions.length === fields.length;

  return (
    <>
      <Flex justifySpaceBetween className="notification-details--header">
        <Flex columnGap={8} alignCenter>
          <Typography
            label="Criteria"
            color="generalMidnightDark"
            size="lg"
            weight="semiBold"
          />
          <Typography
            label="Criteria is optional"
            color="extrasBlueGrayDarker"
            size="sm"
            weight="semiBold"
          />
        </Flex>
        <Button
          disabled={disabled}
          data-tooltip-select={
            disabled ? "There are no more criteria selections" : undefined
          }
          type="button"
          size="sm"
          label="Add Criteria"
          onClick={() => append(emptyCriteria)}
        />
      </Flex>
      {!!fields.length && (
        <Flex column rowGap={12} className="notification-details--box">
          {fields.map((field, index) => (
            <Flex key={field.id} alignCenter justifySpaceBetween>
              <Flex alignCenter columnGap={12}>
                <MiniTag square label={index + 1} />
                If
                <SelectField
                  control={control}
                  name={`criteria.${index}.fieldName`}
                  placeholder="- Criteria -"
                  options={filteredFieldNameOptions(index)}
                />
                is
                <MultiSelectField
                  tooltipText={
                    !selectedCriteria[index]?.fieldName
                      ? "Value is dependent on Criteria. Please select a criteria first before editing this field."
                      : undefined
                  }
                  disabled={!selectedCriteria[index]?.fieldName}
                  control={control}
                  name={`criteria.${index}.fieldValues`}
                  placeholder="- Value -"
                  options={getOptions("criteria_value", index)}
                />
              </Flex>
              <Flex>
                <IconButton
                  transparent
                  icon="sync"
                  onClick={() => {
                    update(index, emptyCriteria);
                  }}
                />
                <IconButton
                  transparent
                  icon="trash"
                  disabled={disabled}
                  onClick={() => {
                    if (disabled) {
                      return;
                    }

                    remove(index);
                  }}
                />
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </>
  );
};

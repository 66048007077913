import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Archive = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 19.0016C21 20.1053 20.1074 21 19.0049 21H4.99507C3.89322 21 2.99998 20.1029 2.99998 19.0016V9C2.44709 9 1.99998 8.54902 1.99998 7.99573V4.99791C1.99998 3.8945 2.8982 3 4 3H20C21.1045 3 22 3.89826 22 4.99791V7.99573C22 8.55037 21.5542 9 21.0013 9L21 19.0016ZM4.99998 9V19H19V9H4.99998ZM3.99997 4.99999V6.99999H20V4.99999H3.99997ZM7.99998 12C7.99998 11.4477 8.44724 11 8.99701 11H15.003C15.5536 11 16 11.4439 16 12C16 12.5523 15.5527 13 15.003 13H8.99701C8.44637 13 7.99998 12.5561 7.99998 12Z"
      fill="#353549"
    />
  </svg>
);

Archive.displayName = "ArchiveIcon";

export default Archive;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateAccountContractMutationRequest,
  CreateAccountContractMutationResponse,
  CreateAccountContractPathParams,
} from "../../models/CreateAccountContract";

/**
 * @description Create account contract
 * @summary Create account contract
 * @link /accounts/:account_id/contracts
 */
export async function createAccountContract(
  accountId: CreateAccountContractPathParams["account_id"],
  data: CreateAccountContractMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateAccountContractMutationResponse>["data"]> {
  const res = await client<
    CreateAccountContractMutationResponse,
    CreateAccountContractMutationRequest
  >({
    method: "post",
    url: `/accounts/${accountId}/contracts`,
    data,
    ...options,
  });
  return res.data;
}

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { attachmentFileType } from "@/api";
import {
  getAllAttachments,
  getFirstAttachment,
  getLatestAttachment,
  maxAttachmentVersion,
} from "@/components/helpers/AttachmentGroupsHelper";
import { useMainStore } from "@/contexts/Store";
import arrowDownBlack from "@/images/dashboard/arrow-down-black.svg";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFile?: (...args: any[]) => any;
  handleChangeVersion: (
    newAttachmentID: number,
    newCommentsMode?: boolean,
  ) => Promise<void>;
}

function CreativeViewRecordVersion({ fetchFile, handleChangeVersion }: Props) {
  const mainStore = useMainStore();

  const [isAttachmentSelectionOpen, setIsAttachmentSelectionOpen] =
    useState(false);
  const [isVersionsPopUpOpen, setIsVersionsPopUpOpen] = useState(false);

  const {
    attachmentID: currentAttachmentID,
    attachmentGroupID: currentAttachmentGroupID,
  } = mainStore.files;

  const { list: attachmentGroups } = mainStore.attachmentGroups;
  const { themisModuleIdentifier } = mainStore.context;

  // Variables
  const allAttachments = getAllAttachments(attachmentGroups);
  const currentAttachment = allAttachments.find(
    (attachment) => attachment.id === currentAttachmentID,
  );
  const currentAttachmentGroup = attachmentGroups.find(
    (attachmentGroup) => attachmentGroup.id === currentAttachmentGroupID,
  );
  const latestAttachmentVersion = maxAttachmentVersion(
    currentAttachmentGroup?.attachments,
  );

  const isLatestVersion =
    currentAttachment?.version === latestAttachmentVersion;

  const isFigmaActiveFile =
    currentAttachment?.file_type === attachmentFileType.figma;
  const shouldShowVersions = [
    "policy",
    "procedures",
    "marketing",
    "documents",
    "training",
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  ].includes(themisModuleIdentifier);

  // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentID' implicitly has an 'any' t... Remove this comment to see the full error message
  const fetchFileHandler = async (attachmentID) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    await fetchFile(attachmentID);
    setIsAttachmentSelectionOpen(false);
  };

  const handleVersionClick = async (attachmentId: number) => {
    await handleChangeVersion(attachmentId);
    setIsVersionsPopUpOpen(false);
  };

  const renderToolbarVersions = attachmentGroups && (
    <Popup
      trigger={
        <div
          data-testid="cv-toolbar-multiple-versions-popup-trigger"
          className="tw-border-1 tw-flex tw-h-[34px] tw-w-[150px] tw-items-center tw-justify-between tw-text-ellipsis tw-rounded-md tw-border-t tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-px-4 tw-text-neutral-500"
        >
          <span>{`V${currentAttachment?.version}${
            isLatestVersion ? " (Current)" : ""
          }`}</span>
          <img src={arrowDownBlack} alt="arrow-down" />
        </div>
      }
      open={isVersionsPopUpOpen}
      onOpen={() => setIsVersionsPopUpOpen(true)}
      onClose={() => setIsVersionsPopUpOpen(false)}
      keepTooltipInside
    >
      <div
        className="versions-popup tw-bg-neutral-25"
        data-testid="cv-toolbar-multiple-versions-popup-container"
      >
        {currentAttachmentGroup &&
          currentAttachmentGroup.attachments.map((attachment) => {
            const isActive = attachment.version === currentAttachment?.version;
            const isLatestAttachmentVersion =
              attachment.version === latestAttachmentVersion;

            return (
              <div
                key={attachment.id}
                data-testid="cv-toolbar-multiple-versions-popup-item"
                className={classNames("item", { active: isActive })}
                onClick={() => handleVersionClick(attachment.id)}
              >
                {`Version ${attachment.version}${
                  isLatestAttachmentVersion ? " (Current)" : ""
                }`}
              </div>
            );
          })}
      </div>
    </Popup>
  );

  return (
    <section
      data-testid="cv-toolbar-multiple-left"
      className="bg-transparent tw-absolute tw-z-[999] tw-ml-[96px] tw-flex tw-h-[40px] tw-content-start tw-items-center tw-justify-center tw-pl-2"
    >
      <div className="tw-border-1 tw-w-max-[510px] tw-flex tw-h-[40px] tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-border-t tw-border-solid tw-border-neutral-100 tw-bg-neutral-25">
        {/* is this really what we want to do? show the dropdown when you're on a "screenshot, but not if you're on the first figma file? */}
        {!isFigmaActiveFile && (
          <>
            <Popup
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => (
                <div>
                  <div
                    data-testid="cv-toolbar-multiple-attachments-popup-trigger"
                    className={classNames(
                      "tw-border-1 tw-flex tw-h-[34px] tw-w-[280px] tw-items-center tw-justify-between tw-text-ellipsis tw-rounded-md tw-border-t tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-px-4 tw-text-neutral-500",
                      {
                        "attachment-active-selection":
                          isAttachmentSelectionOpen,
                      },
                    )}
                  >
                    <div
                      className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap"
                      data-testid="filename-container"
                    >
                      {" "}
                      {currentAttachment?.original?.filename}
                    </div>
                    <img src={arrowDownBlack} alt="arrow down" />
                  </div>
                </div>
              )}
              open={isAttachmentSelectionOpen}
              onOpen={() => setIsAttachmentSelectionOpen(true)}
              onClose={() => setIsAttachmentSelectionOpen(false)}
              keepTooltipInside
              disabled={attachmentGroups.length === 1}
            >
              <div
                className="versions-popup tw-bg-neutral-25"
                data-testid="cv-toolbar-multiple-attachments-popup-container"
              >
                {attachmentGroups.map((attachmentGroup) => {
                  const latestAttachment = getLatestAttachment(attachmentGroup);
                  const firstAttachment = getFirstAttachment(attachmentGroup);
                  const attachmentID =
                    attachmentGroup.id === currentAttachmentGroupID
                      ? currentAttachmentID
                      : latestAttachment?.id;

                  return (
                    <div
                      key={`creative-${attachmentGroup.id}`}
                      data-testid="cv-toolbar-multiple-attachments-popup-item"
                      className={classNames("item tw-bg-neutral-25", {
                        active: attachmentID === currentAttachmentID,
                      })}
                      onClick={() => fetchFileHandler(attachmentID)}
                    >
                      {firstAttachment?.original?.filename}
                    </div>
                  );
                })}
              </div>
            </Popup>
            {shouldShowVersions && renderToolbarVersions}
          </>
        )}
      </div>
    </section>
  );
}

export default observer(CreativeViewRecordVersion);

import React from "react";

import { useMainStore } from "@/contexts/Store";

interface Props {
  identifier?: string;
}

function ModuleDescription({ identifier }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { moduleInfo } = mainStore.moduleWorkspaces;

  return (
    <div
      className="cell-description-wrap"
      data-testid="module-description-block"
    >
      {/* @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
      {moduleInfo[identifier].description}
    </div>
  );
}

export default ModuleDescription;

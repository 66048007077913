import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useContext, useMemo, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";

import { StoreContext } from "../../../contexts/Store";
import ApproveButton from "../shared/ApproveButton";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import MoveToSectionPopup from "../shared/MoveToSectionPopup";
import PublishFlow from "../shared/PublishFlow";
import Unlock from "../shared/Unlock";
import ViewLink from "../shared/ViewLink";

interface Props {
  recordVersionID: number;
  isHistory?: boolean;
  isLocked?: boolean;
  isVersionHistory?: boolean;
  showOnlyIcon: boolean;
}

function RiskRegisterContextMenu({
  recordVersionID,
  isLocked,
  isVersionHistory,
  showOnlyIcon,
}: Props) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);
  const history = useHistory();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  // Variables
  const { canDeleteRecords, canManageSections } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;
  const rv = isVersionHistory
    ? mainStore.riskRegisters.versionHistoryRecords.find(
        (recordVersion: RecordVersion) => recordVersion.id === recordVersionID,
      )!
    : mainStore.recordVersions.list.find(
        (recordVersion) => recordVersion.id === recordVersionID,
      )!;
  const currentUserID = mainStore.users.user.id;
  const isApprover = mainStore.avroSchemas
    .valueForField("approvers", rv?.data)
    ?.includes(currentUserID);
  const rvLocked = rv?.status === "completed" || isLocked;

  const { taskDetail } = mainStore;

  const [view, setView] = useState("main"); // one of ['main', 'approved', 'delete', 'archive', 'move']

  // Button Display
  const showApprovers = isApprover && !rvLocked && !isVersionHistory;
  const showPublish =
    !isVersionHistory && rv?.status === "ready_to_finalize" && !rvLocked;
  const showUnlock = !isVersionHistory && rv?.status === "completed";

  // Functions
  const isRiskRegisterDetailView = useMemo(() => {
    // /modules/risk-register/1234 :  ✔
    // /modules/risk-register/1234/controls :  ✖
    // /modules/risk-register/1234/operational-controls :  ✖

    return location.pathname.includes(
      `/modules/risk-register/${recordVersionID}`,
    );
  }, [location.pathname]);

  async function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      if (isRiskRegisterDetailView) {
        history.push(`/workspaces/${workspaceID}/modules/risk-register`);
      }
      await mainStore.riskRegisters.delete(recordVersionID);
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  const handleMove = async (rvID: number, sectionTagID: number) => {
    await mainStore.riskRegisters.updateSection(rvID, sectionTagID);
  };

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(rv);
  }

  async function onUnlock() {
    const result = await mainStore.riskRegisters.unlock(recordVersionID);
    if (isRiskRegisterDetailView) {
      history.push(
        `/workspaces/${workspaceID}/modules/risk-register/${result.record_version.id}`,
      );
    }
  }

  const onMove = () => {
    setView("move");
  };

  function handleApprove() {
    mainStore.riskRegisters.approve(recordVersionID);
  }

  // renders
  const renderMainButton = () => {
    if (showPublish) {
      return (
        <PublishFlow
          recordVersionID={rv.id}
          tableName="Default"
          moduleStore={mainStore.riskRegisters}
          moduleIdentifier="risk_register"
        />
      );
    } else if (showApprovers) {
      return <ApproveButton onClick={handleApprove} />;
    } else if (showUnlock) {
      return (
        <Unlock
          onYes={onUnlock}
          tableName="Default"
          moduleIdentifier="new_product_approval"
          buttonText="Unlock"
          canEveryoneUnlock
        />
      );
    }
    return (
      <ViewLink
        url={`/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}`}
      />
    );
  };

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="risk-register-actions"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="rr-context-menu-dropdown"
    >
      <ul>
        <li onClick={handleCreateTask}>Create Task</li>

        <li
          onClick={() =>
            history.push(
              `/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}`,
            )
          }
          data-testid="move-button-trigger"
        >
          Details
        </li>

        {!rvLocked && canManageSections && (
          <li onClick={onMove} data-testid="move-button-trigger">
            Move to
          </li>
        )}
        {!rvLocked && canDeleteRecords && (
          <>
            <hr />
            <li
              onClick={deleteRowConfirmation}
              data-testid="rr-context-menu-dropdown-delete"
            >
              Delete
            </li>
          </>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Risk Register"
      content="Are you sure you want to delete this risk register? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={handlePopUpClose}
    />
  );

  return (
    <div
      className={classNames("list-points risk-register-actions-container", {
        "without-background": !showOnlyIcon,
      })}
      data-testid="risk-register-actions-container"
    >
      {renderMainButton()}

      <Popup
        position="bottom right"
        open={isOpenModal}
        trigger={renderTrigger}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
      >
        {isDropdownOpen && view === "main" && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
        {view === "move" && (
          <MoveToSectionPopup
            moduleName="riskRegister"
            recordVersionID={recordVersionID}
            onMove={handleMove}
            onClose={() => setView("main")}
            omitTopLevelSection
          />
        )}
      </Popup>
    </div>
  );
}

RiskRegisterContextMenu.defaultProps = {
  isLocked: false,
  isVersionHistory: false,
  isHistory: false,
  showOnlyIcon: true,
};

export default observer(RiskRegisterContextMenu);

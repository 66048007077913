var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { filterCellProps } from "../../../lib/utils";
import { Select } from "../../Form/Select";
var SelectCell = function (_a) {
    var _b;
    var items = _a.items, multiple = _a.multiple, onValueChange = _a.onValueChange, value = _a.value, defaultOpen = _a.defaultOpen, props = __rest(_a, ["items", "multiple", "onValueChange", "value", "defaultOpen"]);
    var selected = props.selected, onSelect = props.onSelect, restProps = __rest(props, ["selected", "onSelect"]);
    var filteredProps = filterCellProps(__assign({ value: value, onValueChange: onValueChange }, restProps));
    var editable = Boolean((_b = props.colDef) === null || _b === void 0 ? void 0 : _b.editable);
    return (React.createElement(Select, __assign({ className: "tw-h-full tw-rounded-none tw-border-none tw-bg-transparent read-only:tw-bg-transparent group-hover/row:tw-bg-neutral-50", selected: value, items: items, onSelect: onValueChange, multiple: multiple, defaultOpen: !!(defaultOpen && editable), onPopupClosed: function () { return props.stopEditing && props.stopEditing(); }, hideCaret: true, readOnly: !editable }, filteredProps)));
};
export default SelectCell;

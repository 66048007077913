var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import classNames from "classnames";
import React from "react";
import { PiCaretLeftBold, PiCaretRightBold, PiDotsThreeOutlineFill, } from "react-icons/pi";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import { ToggleButtonGroup, ToggleButtonGroupItem, } from "../Button/ToggleButtonGroup";
export function Pagination(_a) {
    var page = _a.page, totalPages = _a.totalPages, onPageChange = _a.onPageChange, className = _a.className, _b = _a.chunkSize, chunkSize = _b === void 0 ? 5 : _b;
    var chunkStart = Math.floor((page - 1) / chunkSize) * chunkSize + 1;
    var chunkEnd = Math.min(chunkStart + chunkSize - 1, totalPages);
    var position = totalPages - page < chunkSize
        ? chunkSize - (totalPages - page)
        : page % chunkSize || chunkSize;
    var handleSetPage = function (newPage) {
        if (newPage !== page) {
            onPageChange(newPage);
        }
    };
    var handlePrevPage = function () {
        if (page > 1) {
            return handleSetPage(page - 1);
        }
        return handleSetPage(1);
    };
    var handleNextPage = function () {
        if (page < totalPages) {
            return handleSetPage(page + 1);
        }
        return handleSetPage(totalPages);
    };
    var handleNextPageChunk = function () {
        var t = chunkSize - (position - 1);
        handleSetPage(page + t);
    };
    var handlePrevPageChunk = function () {
        if (totalPages - page < chunkSize) {
            var pos = chunkSize - (totalPages - page);
            handleSetPage(page - pos);
        }
        else {
            handleSetPage(page - position);
        }
    };
    var getToggleButton = function (pageNumber) {
        return (React.createElement(ToggleButtonGroupItem, { key: pageNumber, value: pageNumber.toString(), onClick: function () { return handleSetPage(pageNumber); }, "aria-label": "Page ".concat(pageNumber), className: "tw-h-7 tw-w-7 tw-bg-transparent tw-text-xxs data-[state=on]:tw-bg-neutral-500" }, pageNumber));
    };
    var getLeftDotsButton = function () {
        return (React.createElement(IconButton, { key: "dots-left", Icon: function () { return React.createElement(PiDotsThreeOutlineFill, { className: "tw-text-xxs" }); }, color: "transparent", onClick: handlePrevPageChunk, "aria-label": "Previous Chunk", className: "tw-h-7 tw-w-7 tw-items-end tw-bg-transparent tw-pb-1.5" }));
    };
    var getRightDotsButton = function () {
        return (React.createElement(IconButton, { key: "dots-right", Icon: function () { return React.createElement(PiDotsThreeOutlineFill, { className: "tw-text-xxs" }); }, color: "transparent", onClick: handleNextPageChunk, "aria-label": "Next Chunk", className: "tw-h-7 tw-w-7 tw-items-end tw-bg-transparent tw-pb-1.5" }));
    };
    function createPageButtons() {
        // If there are fewer pages than the chunk size, display all pages
        if (totalPages <= chunkSize) {
            return Array.from({ length: totalPages }, function (_, i) {
                return getToggleButton(i + 1);
            });
        }
        // If the current page is within the first chunk, display the first chunk, dots, and the last page
        if (page <= chunkSize) {
            return __spreadArray(__spreadArray([], Array.from({ length: chunkSize }, function (_, i) { return getToggleButton(i + 1); }), true), [
                getRightDotsButton(),
                getToggleButton(totalPages),
            ], false);
        }
        // If the current page is within the last chunk, display the first page, dots, and the last chunk
        if (page >= totalPages - (chunkSize - 1)) {
            return __spreadArray([
                getToggleButton(1),
                getLeftDotsButton()
            ], Array.from({ length: chunkSize }, function (_, i) {
                return getToggleButton(totalPages - (chunkSize - 1) + i);
            }), true);
        }
        // Otherwise, display the first page, dots, the current chunk, dots, and the last page
        return __spreadArray(__spreadArray([
            getToggleButton(1),
            getLeftDotsButton()
        ], Array.from({ length: chunkSize }, function (_, i) {
            return getToggleButton(chunkStart + i);
        }), true), [
            getRightDotsButton(),
            getToggleButton(totalPages),
        ], false);
    }
    if (totalPages < 1) {
        return React.createElement("div", null);
    }
    var getChunk = function () {
        if (totalPages - chunkSize < page && page > chunkSize) {
            return "".concat(totalPages - chunkSize + 1, " - ").concat(totalPages, " of ").concat(totalPages);
        }
        return "".concat(chunkStart, " - ").concat(chunkEnd, " of ").concat(totalPages);
    };
    return (React.createElement("div", { className: classNames("tw-flex tw-gap-2", { className: className }) },
        React.createElement(Button, { LeftIcon: function () { return React.createElement(PiCaretLeftBold, { className: "tw-h-3 tw-w-3" }); }, onClick: handlePrevPage, disabled: page === 1, color: "transparent", "aria-label": "Previous Page", className: "tw-h-7 tw-text-xxs disabled:tw-bg-transparent disabled:tw-text-neutral-200" }, "Back"),
        React.createElement(ToggleButtonGroup, { value: page.toString(), size: "md", type: "single" }, createPageButtons()),
        React.createElement(Button, { RightIcon: function () { return React.createElement(PiCaretRightBold, { className: "tw-h-3 tw-w-3" }); }, onClick: handleNextPage, disabled: page === totalPages, color: "transparent", "aria-label": "Next Page", className: "tw-h-7 tw-text-xxs disabled:tw-bg-transparent disabled:tw-text-neutral-200" }, "Next"),
        React.createElement("div", { className: "tw-flex tw-h-7 tw-items-center tw-text-xxs tw-font-semibold tw-text-neutral-300" }, getChunk())));
}

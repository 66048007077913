import dayjs from "dayjs";

import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

export const REPORT_ENABLED_MODULES: ModuleIdentifier[] = [
  "complaints",
  "conflicts_of_interest",
  "control_mapping",
  "customer_support",
  "documents",
  "finra",
  "issue_management",
  "marketing",
  "new_product_approval",
  "policy",
  "procedures",
  "qa_tests_development",
  "risk_register",
  "training",
  "vendor_due_diligence",
] as const;

export const MODULE_CHARTS = {
  policy: "policy",
  documents: "documents",
  procedures: "procedures",
};

export const DOCUMENTS_AT_RISK_DAYS = 14; // for Documents module we need two weeks

const today = dayjs().startOf("day");
let nextMonth = dayjs().startOf("day");
nextMonth = nextMonth.add(1, "month");

export const MONTH_AT_RISK = Math.abs(
  (nextMonth.valueOf() - today.valueOf()) / 86400000,
); // for Procedures, Policy module we need month

// DOCUMENTS constant for ModuleSummaryCharts
export const DOCUMENTS_OPEN_STATUS = [
  {
    label: "In Review",
    value: "in_review",
  },
  {
    label: "Pending Attachment",
    value: "pending_attachment",
  },
  {
    label: "Pending Finalization",
    value: "pending_finalization",
  },
];

export const DOCUMENTS_CLOSED_STATUS = [
  // without archived
  {
    label: "Published",
    value: "published",
  },
];

// POLICIES constant for ModuleSummaryCharts
export const POLICIES_OPEN_STATUS = [
  {
    label: "In Review",
    value: "in_review",
  },
  {
    label: "Pending Attachment",
    value: "pending_attachment",
  },
  {
    label: "Ready to Finalize",
    value: "ready_to_finalize",
  },
];

export const POLICIES_CLOSED_STATUS = [
  // without archived
  {
    label: "Completed",
    value: "completed",
  },
];

// PROCEDURES constant for ModuleSummaryCharts
export const PROCEDURES_OPEN_STATUS = [
  {
    label: "In Review",
    value: "in_review",
  },
  {
    label: "Pending Attachment",
    value: "pending_attachment",
  },
  {
    label: "Ready to Finalize",
    value: "ready_to_finalize",
  },
];

export const PROCEDURES_CLOSED_STATUS = [
  // without archived
  {
    label: "Completed",
    value: "completed",
  },
];

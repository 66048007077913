import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ShowModuleAssessmentPathParams,
  ShowModuleAssessmentQueryResponse,
} from "../../models/ShowModuleAssessment";

/**
 * @description Module Assessment with related data
 * @summary Module Assessment
 * @link /module_assessments/:id
 */
export async function showModuleAssessment(
  id: ShowModuleAssessmentPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ShowModuleAssessmentQueryResponse>["data"]> {
  const res = await client<ShowModuleAssessmentQueryResponse>({
    method: "get",
    url: `/module_assessments/${id}`,
    ...options,
  });
  return res.data;
}

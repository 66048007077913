import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React from "react";

import type {
  RiskRangeError,
  ScoringMatrixRating,
} from "@/stores/types/risk-register-types";

import RangeTableInputGroup from "./RangeTableInputGroup";
import ScoringMatrixRangeRatingRow from "./ScoringMatrixRangeRatingRow";

interface Props {
  groupTitle: string;
  riskType: string;
  riskRatings: ScoringMatrixRating[];
  setRiskRatings: (ratings: ScoringMatrixRating[]) => void;
  errors: RiskRangeError[];
  setErrors: React.Dispatch<React.SetStateAction<RiskRangeError[]>>;
}

function ScoringMatrixRangeRatings({
  groupTitle,
  riskType,
  riskRatings,
  setRiskRatings,
  errors,
  setErrors,
}: Props) {
  const removeRating = (index: number) => {
    const riskRating = riskRatings.find((subRiskRating, i) => i === index);

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (riskRating.id) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      riskRating._destroy = true;
      const newList = [...riskRatings];
      setRiskRatings(newList);
    } else {
      const newList = riskRatings.filter((subRiskRating, i) => i !== index);
      setRiskRatings(newList);
    }
  };

  const updateTitle = (index: number, value: string) => {
    riskRatings[index].title = value;
    setRiskRatings([...riskRatings]);
  };

  const updateValue = (index: number, value: number) => {
    riskRatings[index].value = value;
    setRiskRatings([...riskRatings]);
  };

  const updateColor = (index: number, value: string) => {
    riskRatings[index].color = value;
    setRiskRatings([...riskRatings]);
  };

  const checkError = (
    title: string,
    value: number,
    index: number,
    secondValue?: number,
  ): boolean => {
    const existingError = errors.find((error) => error.index === index);

    if (title.length === 0) {
      if (!existingError) {
        setErrors((prev) => [
          ...prev,
          { title: "The title cannot be blank", index },
        ]);
      }
      return true;
    }
    if (existingError && existingError.title === "The title cannot be blank") {
      setErrors(errors.filter((e) => e.index !== index));
    }

    if (value > 10) {
      if (!existingError) {
        setErrors((prev) => [
          ...prev,
          { title: "Value must not be greater than 10", index },
        ]);
      }
      return true;
    }
    if (
      existingError &&
      existingError.title === "Value must not be greater than 10"
    ) {
      setErrors(errors.filter((e) => e.index !== index));
    }

    if (secondValue && value <= secondValue) {
      if (!existingError) {
        setErrors((prev) => [
          ...prev,
          { title: "Not a valid range. Please try again.", index },
        ]);
      }
      return true;
    }
    if (
      existingError &&
      existingError.title === "Not a valid range. Please try again."
    ) {
      setErrors(errors.filter((e) => e.index !== index));
    }

    for (let subIndex = 0; subIndex < riskRatings.length; ++subIndex) {
      if (subIndex !== index && riskRatings[subIndex].title === title) {
        if (!existingError) {
          setErrors((prev) => [
            ...prev,
            { title: "The title is taken", index },
          ]);
        }
        return true;
      }
    }

    if (existingError) {
      setErrors(errors.filter((e) => e.index !== index));
    }

    return false;
  };

  const rows = riskRatings.map((riskRating, index) =>
    riskRating._destroy ? null : (
      <div key={index} className="input-group-row-modal">
        <ScoringMatrixRangeRatingRow
          text={riskRating.title}
          previousValue={index > 0 ? riskRatings[index - 1]?.value : 0}
          value={riskRating.value}
          index={index}
          color={riskRating.color}
          updateTitle={updateTitle}
          updateColor={updateColor}
          updateValue={updateValue}
          removeRating={removeRating}
          hasError={checkError(
            riskRating.title,
            riskRating.value,
            index,
            riskRatings[index - 1]?.value,
          )}
        />
      </div>
    ),
  );

  return (
    <RangeTableInputGroup
      title={groupTitle}
      addNewInputText={`Add New ${capitalize(riskType)}`}
      addNewInput={() =>
        setRiskRatings([
          ...riskRatings,
          { title: "", value: 0, color: "#66AA22", risk_type: riskType },
        ])
      }
      showAddNew={riskRatings.length < 5}
      hasError={errors.length > 0}
    >
      {rows}
    </RangeTableInputGroup>
  );
}

export default observer(ScoringMatrixRangeRatings);

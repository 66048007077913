import React from "react";

import type { Workspace } from "@/stores/types/workspace-types";

import { getWorkspaceLogo } from "../../helpers/getWorkspaceLogo";

interface WorkspaceItemProps {
  showCheckbox?: boolean;
  checked?: boolean;
  workspace: Workspace;
  onChange?: (id: number) => void;
}

const WorkspaceItem = ({
  showCheckbox = false,
  checked,
  workspace,
  onChange,
}: WorkspaceItemProps) => {
  const { logo } = getWorkspaceLogo(workspace);
  return (
    <div className="workspace-container">
      {showCheckbox && (
        <input
          type="checkbox"
          checked={checked}
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          onChange={() => onChange(workspace.id)}
          data-testid={`${workspace.name}-checkbox`}
        />
      )}
      <img src={logo} alt="workspace-icon" />
      <span className="workspace-item-title">{workspace.name}</span>
    </div>
  );
};

export default WorkspaceItem;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Key = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 4.40994L21.71 3.70994C21.8983 3.52164 22.0041 3.26624 22.0041 2.99994C22.0041 2.73364 21.8983 2.47824 21.71 2.28994C21.5217 2.10164 21.2663 1.99585 21 1.99585C20.7337 1.99585 20.4783 2.10164 20.29 2.28994L18.89 3.69994L16.06 6.52994L9.75 12.8299C8.71604 12.1478 7.46567 11.8737 6.24117 12.0608C5.01667 12.248 3.90522 12.883 3.12219 13.8428C2.33915 14.8026 1.94029 16.0189 2.00288 17.2561C2.06547 18.4932 2.58505 19.6631 3.46096 20.539C4.33686 21.4149 5.50672 21.9345 6.74386 21.9971C7.98099 22.0597 9.19732 21.6608 10.1572 20.8778C11.117 20.0947 11.752 18.9833 11.9391 17.7588C12.1262 16.5343 11.8522 15.2839 11.17 14.2499L16.76 8.64994L18.88 10.7799C18.9732 10.8725 19.0838 10.9458 19.2054 10.9957C19.3269 11.0455 19.4571 11.071 19.5885 11.0705C19.7199 11.07 19.8499 11.0437 19.9712 10.993C20.0924 10.9423 20.2024 10.8682 20.295 10.7749C20.3876 10.6817 20.4609 10.5711 20.5107 10.4496C20.5606 10.328 20.586 10.1978 20.5856 10.0664C20.5851 9.93501 20.5588 9.80499 20.508 9.68378C20.4573 9.56256 20.3832 9.45252 20.29 9.35994L18.17 7.23994L19.59 5.82994L20.29 6.52994C20.3826 6.62318 20.4926 6.69727 20.6138 6.74798C20.7351 6.79869 20.8651 6.82503 20.9965 6.8255C21.1279 6.82596 21.2581 6.80054 21.3796 6.75069C21.5012 6.70083 21.6118 6.62752 21.705 6.53494C21.7982 6.44236 21.8723 6.33232 21.923 6.2111C21.9738 6.08989 22.0001 5.95987 22.0006 5.82848C22.001 5.69708 21.9756 5.56688 21.9257 5.44531C21.8759 5.32374 21.8026 5.21318 21.71 5.11994L21 4.40994ZM7 19.9999C6.40666 19.9999 5.82664 19.824 5.33329 19.4944C4.83994 19.1647 4.45542 18.6962 4.22836 18.148C4.0013 17.5998 3.94189 16.9966 4.05764 16.4147C4.1734 15.8327 4.45912 15.2982 4.87868 14.8786C5.29824 14.4591 5.83279 14.1733 6.41473 14.0576C6.99667 13.9418 7.59987 14.0012 8.14805 14.2283C8.69623 14.4554 9.16476 14.8399 9.49441 15.3332C9.82405 15.8266 10 16.4066 10 16.9999C10 17.7956 9.68393 18.5587 9.12132 19.1213C8.55871 19.6839 7.79565 19.9999 7 19.9999Z"
      fill="#353549"
    />
  </svg>
);

Key.displayName = "KeyIcon";

export default Key;

import "./styles.scss";

import classNames from "classnames";
import React from "react";

import { userColors } from "@/components/constants";
import { Typography } from "@/components/Elements";

import { Flex } from "../Elements";

export function UsersCircle(props: {
  initials?: string;
  iconColorIndex?: number;
  small?: boolean;
  customColor?: string;
}) {
  if (!props.initials) {
    return null;
  }

  return (
    <Flex
      shrink={0}
      style={{
        background: props.customColor || userColors[props.iconColorIndex || 0],
      }}
      justifyCenter
      alignCenter
      className={classNames("users-circle__container", {
        "users-circle--small": props.small,
      })}
    >
      <Typography
        label={props.initials}
        size="xxs"
        color="generalWhite"
        weight="semiBold"
      />
    </Flex>
  );
}

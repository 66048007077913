import "./file-field.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import type { ActiveStorageFileUpload } from "react-activestorage-provider";
import { DirectUploadProvider } from "react-activestorage-provider";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import Spinner from "@/components/table/shared/Spinner";
import { useMainStore } from "@/contexts/Store";
import { downloadFile, fetchFile } from "@/stores/helpers/AttachmentHelper";

import fileAddGrayIcon from "../../../../images/table-image/icon/file-add-gray-icon.svg";

export const FILE_TYPE = {
  PNG: ".png",
  JPEG: ".jpeg",
  DOCX: ".docx",
  PDF: "application/pdf",
  XML: ".xml",
  EXCEL: ".xlsx",
  POWERPOINT: ".ppt",
  TEXT: ".txt",
  images: "images/*",
} as const;

type FileType = keyof typeof FILE_TYPE;

function mapFileTypesToAcceptProperty(fileTypes: FileType[] | null): string {
  if (!fileTypes) {
    return Object.values(FILE_TYPE).join(", ");
  }

  return fileTypes.map((fileType) => FILE_TYPE[fileType]).join(", ");
}

function mapFileTypesToAcceptPlainText(fileTypes: FileType[]): string {
  return fileTypes.join(" / ");
}

type Props<T extends FieldValues> = UseControllerProps<T> & {
  acceptableFileTypes?: FileType[];
  width?: number | string;
  hasErrors?: boolean;
  hasErrorClass?: string;
  locked?: boolean;
  hasDisabledIconDownloadFile?: boolean;
};

function FileField<T extends FieldValues>({
  acceptableFileTypes,
  width,
  hasErrors,
  hasErrorClass = "has-errors",
  locked,
  hasDisabledIconDownloadFile,
  ...controller
}: Props<T>) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [upload, setUpload] = useState<File | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const [lockedPopup, setLockedPopup] = useState(false);

  // Hooks
  const { field, fieldState } = useController(controller);

  // Variables
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const triggerClasses = classNames("file-field", {
    active: showPopup,
    "file-field__illumination": showPopup,
    "file-uploaded": !!field.value && !locked,
    [hasErrorClass]: hasErrors || !!fieldState.error,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
  });

  // Functions
  function onUploadModalOpen() {
    setUpload(null);
    setShowPopup(true);
  }

  function onUploadModalClose() {
    setShowPopup(false);
  }

  function handleOnSuccess(fileIDs: string[]) {
    field.onChange({
      id: fileIDs[0],
      name: upload?.name,
    });
    onUploadModalClose();
  }

  async function handleDownload() {
    setLockedPopup(false);

    if (!field.value.file_name || !field.value.file_url) {
      return;
    }
    const downloadResponse = await fetchFile(field.value.file_url);
    const blob = downloadResponse.data;
    downloadFile(blob, field.value.file_name);
  }

  // Elements
  const renderTrigger = (
    <li className={triggerClasses} style={{ width }}>
      <div className="cell-content">
        {field.value?.file_name ? (
          <div className="file-field__filename">{field.value.file_name}</div>
        ) : (
          "+ Add a file"
        )}
        {!!field.value?.file_url && !hasDisabledIconDownloadFile && (
          <Icon
            className="file-field__download-icon"
            name="download"
            onMouseDown={() => setLockedPopup(true)}
            onClick={handleDownload}
          />
        )}
      </div>
    </li>
  );

  return (
    <Popup
      position="bottom left"
      trigger={renderTrigger}
      disabled={locked || lockedPopup || isReadOnly}
      open={showPopup}
      onOpen={onUploadModalOpen}
      onClose={onUploadModalClose}
      keepTooltipInside
    >
      <DirectUploadProvider
        onSuccess={handleOnSuccess}
        render={({ handleUpload, uploads }) => (
          <div data-testid="file-input-popup-content">
            <div className="drag-drop-wrap">
              <div className="drag-drop-block">
                {!upload && (
                  <div>
                    <img src={fileAddGrayIcon} alt="file-add-gray-icon.svg" />
                    <p>
                      Drag & drop into this box
                      <br />- or -
                    </p>
                    <button>Choose a file</button>
                    <p>
                      {acceptableFileTypes &&
                        `We accept: ${mapFileTypesToAcceptPlainText(
                          acceptableFileTypes,
                        )}`}
                      {!acceptableFileTypes &&
                        "We accept all kinds of files like images, pdfs, excel, powerpoint, etc."}
                    </p>
                    <input
                      type="file"
                      accept={
                        acceptableFileTypes?.length
                          ? mapFileTypesToAcceptProperty(acceptableFileTypes)
                          : undefined
                      }
                      onDrop={(event) =>
                        setUpload(
                          (event.target as HTMLInputElement)
                            ?.files?.[0] as File,
                        )
                      }
                      onChange={(event) => {
                        setUpload(
                          (event.target as HTMLInputElement)
                            ?.files?.[0] as File,
                        );
                        handleUpload(event.currentTarget.files);
                      }}
                    />
                  </div>
                )}
                {uploads.map((elem: ActiveStorageFileUpload) => {
                  switch (elem.state) {
                    case "waiting":
                      return (
                        <div key={elem.id} className="uploading">
                          <p>0%</p>
                          <Spinner />
                        </div>
                      );
                    case "uploading":
                      return (
                        <div key={elem.id} className="uploading">
                          <p>{Math.round(elem.progress)}%</p>
                          <Spinner />
                        </div>
                      );
                    case "error":
                      return (
                        <p key={elem.id}>
                          Error uploading {elem.file.name}: {elem.error}
                        </p>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          </div>
        )}
      />
    </Popup>
  );
}

export default observer(FileField);

import type {
  Template,
  VendorQuestionnaireFormRead,
  VendorQuestionnaireUpdateRequest,
} from "@/stores/types/vendor-types";

import api from "../api";

export const ExternalVendorQuestionnaireApi = {
  async get(id: string): Promise<Template> {
    const response = await api.get<VendorQuestionnaireFormRead>(
      `/forms/checklists/${id}`,
    );
    return response.data.template;
  },

  async update(
    id: string,
    data: VendorQuestionnaireUpdateRequest,
  ): Promise<Template> {
    const response = await api.post<
      VendorQuestionnaireUpdateRequest,
      { data: VendorQuestionnaireFormRead }
    >(`/forms/checklists/${id}/answers`, data);
    return response.data.template;
  },

  async fetchAttachment(id: string, question_id: number, filename: string) {
    const response = await api.post<
      { id: number },
      { data: { url: string; file_name: string } }
    >(`/forms/checklists/${id}/fetch_attachment`, { id: question_id });

    const { url } = response.data;

    const fileResponse = await api.get<Blob | MediaSource>(url, {
      responseType: "blob",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(fileResponse.data);
    link.download = filename;
    link.click();
  },
};

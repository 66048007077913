import { map } from "lodash";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";
import { complianceTemplatesAnnouncementKey } from "@/stores/data/Announcements";

import { Typography } from "../../Elements";
import PageHeader from "../../shared/PageHeader";
import ComplianceTemplate from "./ComplianceTemplate";
import {
  COMPLIANCE_TEMPLATES,
  COMPLIANCE_TEMPLATES_PREVIEWABLE,
} from "./config";

export default function ComplianceTemplates() {
  const mainStore = useMainStore();
  const description = `
  Themis has made a number of key policies available to our customers.
  These policies can be adapted and adopted to help accelerate your preparedness for any partnership.
  We've made 3 of these available for you to review.
  Should you want to learn more about how Themis can help you or how you can access all of these templates, please contact us at:
  `;

  useEffect(() => {
    mainStore.announcements.dismissAnnouncement(
      complianceTemplatesAnnouncementKey,
    );
  }, []);

  return (
    <div className="dashboard-content compliance-templates">
      <PageHeader title="Compliance Templates" />
      <div className="dashboard-content-wrap">
        <div className="compliance-templates__content">
          <Typography
            color="generalMidnightDark"
            weight="semiBold"
            size="lg"
            label="What are compliance templates?"
            className="compliance-templates__title"
          />
          <Typography
            data-testid="compliance-templates-description"
            weight="semiBold"
            color="generalDarkGray"
            label={description}
            className="compliance-templates__suggestions"
          />
          <a
            href="mailto:hello@themis.com"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Typography
              weight="semiBold"
              color="brandingHighlightTurquoise"
              label="hello@themis.com"
            />
          </a>

          {map(COMPLIANCE_TEMPLATES_PREVIEWABLE, (template, index) => (
            <ComplianceTemplate
              key={index}
              title={template.title}
              description={template.description}
              attachmentName={template.attachmentName}
              className="compliance-templates__template"
              previewableFileName={template.attachmentName}
            />
          ))}

          <Typography
            color="generalMidnightDark"
            weight="semiBold"
            size="lg"
            label="Additional Policies"
            className="compliance-templates__additional-policies-title"
          />

          {map(COMPLIANCE_TEMPLATES, (template, index) => (
            <ComplianceTemplate
              key={index}
              title={template.title}
              description={template.description}
              attachmentName={template.attachmentName}
              className="compliance-templates__template"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListOfCompanyUsersPathParams,
  ListOfCompanyUsersQueryParams,
  ListOfCompanyUsersQueryResponse,
} from "../../models/ListOfCompanyUsers";

/**
 * @description List of company users
 * @summary List of company users
 * @link /companies/:company_id/users
 */
export async function listOfCompanyUsers(
  companyId: ListOfCompanyUsersPathParams["company_id"],
  params?: ListOfCompanyUsersQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListOfCompanyUsersQueryResponse>["data"]> {
  const res = await client<ListOfCompanyUsersQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/users`,
    params,
    ...options,
  });
  return res.data;
}

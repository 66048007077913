var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import * as React from "react";
import { cn } from "../../lib/utils";
import { toggleButtonVariants } from "./ToggleButton";
var ToggleButtonGroupContext = React.createContext({
    size: "md",
    variant: "default",
});
var ToggleButtonGroup = React.forwardRef(function (_a, ref) {
    var className = _a.className, size = _a.size, variant = _a.variant, children = _a.children, props = __rest(_a, ["className", "size", "variant", "children"]);
    return (React.createElement(ToggleGroupPrimitive.Root, __assign({ ref: ref, className: cn("tw-flex tw-items-center tw-justify-center tw-gap-1", className) }, props),
        React.createElement(ToggleButtonGroupContext.Provider, { value: { size: size, variant: variant } }, children)));
});
ToggleButtonGroup.displayName = "ToggleButtonGroup";
var ToggleButtonGroupItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, size = _a.size, variant = _a.variant, props = __rest(_a, ["className", "children", "size", "variant"]);
    var context = React.useContext(ToggleButtonGroupContext);
    return (React.createElement(ToggleGroupPrimitive.Item, __assign({ ref: ref, className: cn(toggleButtonVariants({
            size: context.size || size,
            variant: context.variant || variant,
        }), className) }, props), children));
});
ToggleButtonGroupItem.displayName = "ToggleButtonGroupItem";
export { ToggleButtonGroup, ToggleButtonGroupItem };

export const RECORD_VERSION_DRAGGABLE_ID_PREFIX = "record-version-draggable-";
export const SECTION_DROPPABLE_ID_PREFIX = "section-droppable-";

export const QUESTION_DRAGGABLE_ID_PREFIX = "question-draggable-";
export const QUESTIONNAIRE_GROUP_DROPPABLE_ID_PREFIX = "group-droppable-";
export const QUESTION_LIST_DROPPABLE_ID = "question-list";

export function getDroppableIdFromSectionId(sectionId?: number): string {
  return `${SECTION_DROPPABLE_ID_PREFIX}${sectionId || 0}`;
}

export function getSectionIdFromDroppableId(droppableId?: string): number {
  if (!droppableId || !droppableId.startsWith(SECTION_DROPPABLE_ID_PREFIX)) {
    return -1;
  }
  return parseInt(droppableId.substring(SECTION_DROPPABLE_ID_PREFIX.length));
}

export function getDraggbleIdFromRecordVersionId(
  recordVersionId: number,
): string {
  return `${RECORD_VERSION_DRAGGABLE_ID_PREFIX}${recordVersionId}`;
}

export function getRecordVersionIdFromDraggableId(
  draggableId?: string,
): number {
  if (
    !draggableId ||
    !draggableId.startsWith(RECORD_VERSION_DRAGGABLE_ID_PREFIX)
  ) {
    return -1;
  }
  return parseInt(
    draggableId.substring(RECORD_VERSION_DRAGGABLE_ID_PREFIX.length),
  );
}

export function getDroppableIdFromQuestionnaireGroupId(
  groupId?: number,
): string {
  return `${QUESTIONNAIRE_GROUP_DROPPABLE_ID_PREFIX}${groupId || 0}`;
}

export function getQuestionnaireGroupIdFromDroppableId(
  droppableId?: string,
): number {
  if (
    !droppableId ||
    !droppableId.startsWith(QUESTIONNAIRE_GROUP_DROPPABLE_ID_PREFIX)
  ) {
    return -1;
  }
  return parseInt(
    droppableId.substring(QUESTIONNAIRE_GROUP_DROPPABLE_ID_PREFIX.length),
  );
}

export function getDraggbleIdFromQuestionId(questionId?: number): string {
  return `${QUESTION_DRAGGABLE_ID_PREFIX}${questionId}`;
}

export function getQuestionIdFromDraggableId(draggableId?: string): number {
  if (!draggableId || !draggableId.startsWith(QUESTION_DRAGGABLE_ID_PREFIX)) {
    return -1;
  }
  return parseInt(draggableId.substring(QUESTION_DRAGGABLE_ID_PREFIX.length));
}

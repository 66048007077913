import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { Department } from "../types/user-types";

export default class DepartmentsStore {
  mainStore: MainStore;

  // Observable objects
  departments: Department[] = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      departments: observable,
      setDepartments: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async index(companyID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}/departments`,
        headers: this.mainStore.getHeaders(),
      });

      this.setDepartments(response.data.departments);
    } catch (error) {
      window.console.log(`"Departments#Index for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async createWithParams(companyID, params) {
    const departmentParams = {
      department: params,
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/companies/${companyID}/departments`,
        headers: this.mainStore.getHeaders(),
        data: departmentParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        const toastError = result.response.data.errors;
        this.mainStore.toast.setInfoText(toastError);
        return;
      }

      // Refetch the list of Departments
      this.index(companyID);
    } catch (error) {
      window.console.log(
        `"Departments#createWithParams for Company" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async updateWithParams(companyID, departmentID, params) {
    const departmentParams = {
      department: params,
    };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/departments/${departmentID}`,
        headers: this.mainStore.getHeaders(),
        data: departmentParams,
      });

      // Refetch the list of Departments
      this.index(companyID);
    } catch (error) {
      window.console.log(
        `"Departments#updateWithParams for Company" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async delete(companyID, departmentID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/departments/${departmentID}`,
        headers: this.mainStore.getHeaders(),
      });

      // Refetch the list of Departments
      this.index(companyID);
    } catch (error) {
      window.console.log(`"Departments#delete" error ${error}`);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setDepartments(value) {
    if (value) {
      this.departments = value;
    } else {
      this.departments = [];
    }
  }

  // Store Helpers

  cleanup() {
    this.setDepartments([]);
  }
}

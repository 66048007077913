import type { Account } from "@/api";

export const selectTableRows = (account: Account | undefined) => {
  if (!account?.vendors) {
    return [];
  }

  const rows = account.vendors.map((vendor) => {
    const riskLevel = vendor.data.risk_level?.options?.[0];
    const piiProvided = vendor.data.pii_provided?.options?.[0];
    const material = vendor.data.material?.options?.[0];
    const piiCategories = vendor.data.pii_categories?.options ?? [];

    return {
      id: vendor.id,
      name: account.name,
      piiProvided,
      checklistCount: vendor.record.checklist_count,
      completedChecklistCount: vendor.record.completed_checklist_count,
      riskLevel,
      material,
      contracts: vendor.contracts,
      piiCategories,
    };
  });

  return rows;
};

export type TableRows = ReturnType<typeof selectTableRows>;

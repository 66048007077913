import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Slider = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16C13.3063 16 14.4175 16.8349 14.8293 18.0001L21 18C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20L14.829 20.0009C14.4169 21.1656 13.3059 22 12 22C10.6941 22 9.58312 21.1656 9.17102 20.0009L3 20C2.44772 20 2 19.5523 2 19C2 18.4477 2.44772 18 3 18L9.17067 18.0001C9.58249 16.8349 10.6937 16 12 16ZM12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18ZM19 9C20.6569 9 22 10.3431 22 12C22 13.6569 20.6569 15 19 15C17.6941 15 16.5831 14.1656 16.171 13.0009L3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11L16.1707 11.0001C16.5825 9.83485 17.6937 9 19 9ZM19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11ZM5 2C6.3118 2 7.42695 2.84196 7.83453 4.01495C7.88799 4.00469 7.94344 4 8 4H21C21.5523 4 22 4.44772 22 5C22 5.55228 21.5523 6 21 6H8C7.94344 6 7.88799 5.99531 7.83399 5.98628C7.42695 7.15804 6.3118 8 5 8C3.34315 8 2 6.65685 2 5C2 3.34315 3.34315 2 5 2ZM5 4C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6C5.55228 6 6 5.55228 6 5C6 4.44772 5.55228 4 5 4Z"
      fill="#353549"
    />
  </svg>
);

Slider.displayName = "SliderIcon";

export default Slider;

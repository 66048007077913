import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import SectionSelection from "@/components/shared/SectionSelection";
import { useMainStore } from "@/contexts/Store";

import type { KRISection } from "../pages/types";

interface Props {
  moduleWorkspaceID: number | null;
  type: "company" | "themis";
}

function CopyToKRIsButton({ moduleWorkspaceID, type }: Props) {
  const mainStore = useMainStore();
  const history = useHistory();

  const isThemisLibrary = type === "themis";
  const buttonEnabled = isThemisLibrary
    ? mainStore.keyRiskIndicatorsThemisLibrary.checkedRows.length > 0
    : mainStore.keyRiskIndicatorsCompanyLibrary.checkedRowIDs.length > 0;
  useEffect(() => {
    if (mainStore.sectionTags.list.length === 0) {
      mainStore.sectionTags.index(Number(moduleWorkspaceID));
    }
  }, [moduleWorkspaceID]);

  const sectionTags = mainStore.sectionTags.orderedList;
  // "Unassigned"  is the way sectionSelector component knows to select the top level section
  // Gets sent as section_tag: null to the backend
  const sectionTagsWithGeneralOption: KRISection[] = [
    { id: "unassigned", title: "General" },
    ...sectionTags,
  ];

  const selectedSectionTagID = isThemisLibrary
    ? mainStore.keyRiskIndicatorsThemisLibrary.selectedSectionTagID
    : mainStore.keyRiskIndicatorsCompanyLibrary.selectedSectionTagID;

  function onSelectSection(sectionId: number | "unassigned") {
    if (isThemisLibrary) {
      mainStore.keyRiskIndicatorsThemisLibrary.setSelectedSectionTagID(
        sectionId,
      );
    } else {
      mainStore.keyRiskIndicatorsCompanyLibrary.setSelectedSectionTagID(
        sectionId,
      );
    }
  }

  async function handleContinue() {
    if (!moduleWorkspaceID || !mainStore.context.companyID) {
      return;
    }

    if (isThemisLibrary) {
      await mainStore.keyRiskIndicatorsThemisLibrary.copyToKri({
        moduleWorkspaceID,
      });
      mainStore.keyRiskIndicatorsThemisLibrary.setSelectedSectionTagID(null);
      mainStore.keyRiskIndicatorsThemisLibrary.setCheckedRows([]);
    } else {
      await mainStore.keyRiskIndicatorsCompanyLibrary.copyToKri({
        companyID: mainStore.context.companyID,
        moduleWorkspaceID,
      });
      mainStore.keyRiskIndicatorsCompanyLibrary.setCheckedRowIDs([]);
    }

    history.push("./list");
  }

  const button = (
    <button
      className="table-button header-action"
      data-testid="add-to-company-kri-library-button"
      disabled={!buttonEnabled}
    >
      Add to KRIs
    </button>
  );

  return (
    <Popup trigger={button}>
      <SectionSelection
        title="Select a destination section for your new KRIs"
        sections={sectionTagsWithGeneralOption}
        selectedSection={selectedSectionTagID}
        onSelectSection={onSelectSection}
        handleContinue={handleContinue}
      />
    </Popup>
  );
}

export default observer(CopyToKRIsButton);

import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import Spinner from "./Spinner";

function PageLoading() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { isLoading } = mainStore.pageLoading;

  if (!isLoading) {
    return null;
  }

  return (
    <div className="page-loading-overlay" data-testid="page-loading-overlay">
      Loading
      <Spinner />
    </div>
  );
}

export default observer(PageLoading);

import React from "react";

import globalId from "../../../images/table-image/icon/globalId.svg";

export function GlobalIdColumn() {
  return (
    <li className="list-title-wrap global-id-width global-id-mr global-id-column">
      <div className="title-trigger-wrap">
        <div
          className="title-trigger-block global-id-column-context"
          data-testid="global-id"
        >
          <img src={globalId} alt="column-icon" data-testid="global-id-icon" />
          ID
        </div>
      </div>
    </li>
  );
}

export default GlobalIdColumn;

import {
  IconButton,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TextInput,
  useToast,
} from "@themis/ui";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { PiLinkSimpleBold, PiXBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import type { RecordShow } from "@/api";
import { useSaveControlsModules } from "@/api/queries/controlsModules";
import { useListShowRecords } from "@/api/queries/records";

import { isInAPopup } from "../components/helpers";
import CreateControlMappingMenu from "../components/SlideMenu/CreateControlMappingMenu";
import LibraryControlMappingMenu from "../components/SlideMenu/LibraryControlMappingMenu";
import LinkControlMappingMenu from "../components/SlideMenu/LinkControlMappingMenu";
import { useControlMappingSidebar } from "../providers/ControlMappingsSidebarProvider";

function getModuleNameFromDataType(dataType?: string) {
  switch (dataType) {
    case "record:policy":
      return "Policies";
    case "record:procedure":
      return "Procedures";
    case "record:training":
      return "Trainings";
    case "receord:audit":
      return "Audits";
    case "record:qa_tests_development":
      return "Tests";
    default:
      return "Controls";
  }
}

export function ControlMappingTabPage({
  recordVersionId,
  tab,
  dataType,
  linkedRecords = [],
}: {
  recordVersionId: number;
  tab: string;
  dataType?: string;
  linkedRecords?: number[];
}) {
  const { workspace_id: workspaceID } = useParams<{ workspace_id: string }>();
  const [tabName, setTabName] = useState(tab);
  const [createNewDefaults, setCreateNewDefaults] = useState<{
    title?: string;
    description?: string;
  }>({});

  const { closeSidebar } = useControlMappingSidebar();
  const toast = useToast();
  const { mutateAsync: updateResult } = useSaveControlsModules({
    recordVersionId,
    onSuccess: () => {
      toast({ content: "Control saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update control.",
        variant: "error",
      });
    },
  });

  const listShowRecord = useListShowRecords({
    data_type: dataType || "",
    workspace_id: Number(workspaceID),
  });

  const moduleRecords = listShowRecord?.data?.data?.filter(
    (record: RecordShow) => !linkedRecords.includes(record.id!),
  );

  useEffect(() => {
    document.addEventListener("mousedown", closeOnClickOutside);
    return () => {
      document.removeEventListener("mousedown", closeOnClickOutside);
    };
  });

  const closeOnClickOutside = (e: MouseEvent) => {
    if (
      !resultDetailContainer.current?.contains(e.target as Node) &&
      !isInAPopup(e.target as HTMLElement)
    ) {
      closeSidebar();
    }
  };

  const createNewFromLibraryItem = (item: {
    title: string;
    description: string;
  }) => {
    setCreateNewDefaults({
      title: item.title,
      description: item.description,
    });
    setTabName("create_new_control_mapping");
  };

  const handleTabChange = (value: string) => {
    setTabName(value);
  };
  const handleLinkRecord = (value: number | number[], close?: boolean) => {
    const recordsToLink = linkedRecords.concat(value);
    updateResult({
      controls_data: { records_ids: recordsToLink },
      linked_extra_records: true,
    });
    if (close) {
      closeSidebar();
    }
  };

  function getLinkedRecordSelectionPage() {
    if (moduleRecords && moduleRecords.length > 0) {
      return (
        <div className="tw-flex tw-flex-col tw-px-5">
          <TextInput
            size="lg"
            placeholder="Search Controls"
            className="tw-my-4"
          />
          <div className="tw-py-4">
            {moduleRecords && moduleRecords.length > 0 && (
              <div className="tw-w-full tw-border-0 tw-border-t tw-border-solid tw-border-generals-underline" />
            )}
            {moduleRecords &&
              moduleRecords.map((record: RecordShow) => (
                <div
                  key={`record-${record.id}`}
                  className="tw-flex tw-items-center tw-border-0 tw-border-b tw-border-solid tw-border-generals-underline tw-py-2"
                >
                  <div className="tw-font-meidum tw-line-clamp-1 tw-cursor-default tw-text-sm tw-text-neutral-300">
                    {record.meta?.name ? record.meta.name : record.global_id}
                  </div>
                  <div className="tw-flex-grow" />
                  <IconButton
                    color="transparent"
                    size="sm"
                    Icon={PiLinkSimpleBold}
                    onClick={() =>
                      record.id && handleLinkRecord(record.id, true)
                    }
                  />
                </div>
              ))}
          </div>
        </div>
      );
    }
    return (
      <div className="tw-flex tw-flex-grow tw-items-center tw-justify-center tw-text-sm tw-font-semibold tw-text-neutral-500">
        There are no {getModuleNameFromDataType(dataType).toLocaleLowerCase()}{" "}
        to link within this module
      </div>
    );
  }
  const resultDetailContainer = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={resultDetailContainer}
      className={classNames("slide-animation tw-z-30 tw-h-full tw-w-[700px]", {
        "tw-flex tw-flex-col": !!dataType,
      })}
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-px-5 tw-py-3 tw-text-base tw-font-semibold tw-text-neutral-500">
          Select {dataType ? getModuleNameFromDataType(dataType) : "Controls"}{" "}
          to Link
        </div>
        <PiXBold
          onClick={closeSidebar}
          className="tw-mr-2 tw-h-5 tw-w-5 tw-cursor-pointer tw-text-neutral-500"
        />
      </div>
      {dataType ? (
        getLinkedRecordSelectionPage()
      ) : (
        <Tabs
          onValueChange={handleTabChange}
          value={tabName}
          className="tw-border-0"
        >
          <TabsList className="tw-gap-4 tw-px-6 tw-py-0">
            <TabsTrigger
              value="create_new_control_mapping"
              className="tw-rounded-none tw-p-0 hover:tw-bg-primaryDim-25 data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300  data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              Create New
            </TabsTrigger>
            <TabsTrigger
              value="add_control_mapping_from_library"
              className="tw-rounded-none tw-p-0 hover:tw-bg-primaryDim-25 data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300  data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              Themis Library
            </TabsTrigger>
            <TabsTrigger
              value="link_existing_control_mapping"
              className="tw-rounded-none tw-p-0 hover:tw-bg-primaryDim-25 data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2  data-[state=active]:tw-border-solid data-[state=active]:tw-border-primaryDim-300  data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-text-primaryDim-300"
            >
              My Controls
            </TabsTrigger>
          </TabsList>
          <TabsContent value="create_new_control_mapping" className="tw-h-full">
            <div className="tw-px-5">
              <CreateControlMappingMenu
                recordVersionId={recordVersionId}
                defaultTitle={createNewDefaults.title}
                defaultDescription={createNewDefaults.description}
                onClose={closeSidebar}
              />
            </div>
          </TabsContent>
          <TabsContent
            value="add_control_mapping_from_library"
            className="tw-h-full"
          >
            <LibraryControlMappingMenu
              recordVersionId={recordVersionId}
              onSelectLibraryItem={createNewFromLibraryItem}
            />
          </TabsContent>
          <TabsContent
            value="link_existing_control_mapping"
            className="tw-h-full"
          >
            <LinkControlMappingMenu
              recordVersionId={recordVersionId}
              onClose={closeSidebar}
              linkedRecords={linkedRecords}
              onLinkRecord={handleLinkRecord}
            />
          </TabsContent>
        </Tabs>
      )}
    </div>
  );
}

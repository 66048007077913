import React, { useState } from "react";

import { Button, Flex, IconButton } from "@/components/Elements";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import GenericInputCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/GenericInputCell";
import GenericTextAreaCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/GenericTextAreaCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";

import type { RiskArea } from "../../types/risk-area";

interface Props {
  index: number;
  riskArea: RiskArea;
  isEditing: boolean;
  isDisabled: boolean;
  onEdit: () => void;
  onSave: (update: RiskArea) => void;
  onCancel: () => void;
}

export default function RiskAreaRow(props: Props) {
  const [editedRiskArea, setEditedRiskArea] = useState({
    name: "",
    definition: "",
  });

  const handleEditClick = () => {
    props.onEdit();
    setEditedRiskArea({
      name: props.riskArea.name,
      definition: props.riskArea.definition || "",
    });
  };

  if (props.isEditing) {
    return (
      <TableRow
        key={props.riskArea.id}
        rowIndex={props.index}
        extraClassName="risk-area-row"
      >
        <GenericInputCell
          value={editedRiskArea.name}
          onChange={(name) => setEditedRiskArea({ ...editedRiskArea, name })}
          isFirstDataCell
        />
        <GenericTextAreaCell
          value={editedRiskArea.definition}
          onChange={(definition) =>
            setEditedRiskArea({ ...editedRiskArea, definition })
          }
          isLastDataCell
        />
        <ActionCell>
          <Flex wrap={false} columnGap={3}>
            <IconButton
              color="extrasGreen"
              icon="check"
              disabled={props.isDisabled}
              onClick={() =>
                props.onSave({ ...props.riskArea, ...editedRiskArea })
              }
            />
            <IconButton
              icon="close"
              theme="tertiary"
              disabled={props.isDisabled}
              onClick={props.onCancel}
            />
          </Flex>
        </ActionCell>
      </TableRow>
    );
  }

  return (
    <TableRow
      key={props.riskArea.id}
      rowIndex={props.index}
      extraClassName="risk-area-row"
    >
      <ContentCell content={props.riskArea.name} isFirstDataCell />
      <ContentCell content={props.riskArea.definition} isLastDataCell />
      <ActionCell>
        <Button
          disabled={props.isDisabled}
          label="Edit"
          theme="tertiary"
          onClick={handleEditClick}
        />
      </ActionCell>
    </TableRow>
  );
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateFindingStatusCloseMutationResponse,
  UpdateFindingStatusClosePathParams,
} from "../../models/UpdateFindingStatusClose";

/**
 * @description Update finding status to close
 * @summary Update finding status to close
 * @link /findings/:finding_id/status/close
 */
export async function updateFindingStatusClose(
  findingId: UpdateFindingStatusClosePathParams["finding_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateFindingStatusCloseMutationResponse>["data"]> {
  const res = await client<UpdateFindingStatusCloseMutationResponse>({
    method: "put",
    url: `/findings/${findingId}/status/close`,
    ...options,
  });
  return res.data;
}

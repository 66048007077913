import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ShowContractPathParams,
  ShowContractQueryResponse,
} from "../../models/ShowContract";

/**
 * @description Contract data
 * @summary contract
 * @link /contracts/:id
 */
export async function showContract(
  id: ShowContractPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ShowContractQueryResponse>["data"]> {
  const res = await client<ShowContractQueryResponse>({
    method: "get",
    url: `/contracts/${id}`,
    ...options,
  });
  return res.data;
}

import classNames from "classnames";
import * as React from "react";

interface PopupMenuHeaderProps {
  label?: string;
  subtext?: string;
  noHoverEffect?: boolean;
  icon?: React.JSX.Element;
  onClick?: () => void;
}

export default function PopupMenuHeader(props: PopupMenuHeaderProps) {
  return (
    <div
      onClick={props.onClick}
      className={classNames("popup-header", {
        "popup-header--no-hover": props.noHoverEffect,
      })}
    >
      {props.icon && <div className="icon-wrapper">{props.icon}</div>}

      <div>
        <div className="label">{props.label}</div>
        {props.subtext && <div className="subtext">{props.subtext}</div>}
      </div>
    </div>
  );
}

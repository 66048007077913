import "./index.scss";

import { IconButton } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { PiXBold } from "react-icons/pi";

interface Props {
  open: boolean;
  onClose: (save: boolean) => void;
  title?: string;
  children?: React.ReactNode;
  modalClassnames?: string;
}

function Modal({ title, open, onClose, children, modalClassnames }: Props) {
  if (!open) {
    return null;
  }
  return (
    <div className="modal">
      <div
        className={classNames("modal-window", {
          [(modalClassnames || "").toString()]: modalClassnames,
        })}
      >
        <div
          className={classNames({
            "tw-flex tw-h-[28px] tw-w-[432px] tw-justify-between": title,
          })}
        >
          <div className="tw-text-base tw-text-neutral-500">{title}</div>
          <div>
            <IconButton
              color="transparent"
              Icon={PiXBold}
              aria-label="Close Modal"
              onClick={() => onClose(false)}
            />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}

export default Modal;

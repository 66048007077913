import React from "react";

import { Typography } from "@/components/Elements";

export function NotificationText(props: {
  accent?: boolean;
  label: string;
  small?: boolean;
}) {
  return (
    <Typography
      label={props.label}
      size={props.small ? "sm" : "md"}
      color={props.accent ? "accentsSkyBlue" : "generalMidnightDark"}
      weight="semiBold"
    />
  );
}

import { Button } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiUploadSimpleBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import SectionSelection from "../shared/SectionSelection";
import ImportFiles from "./ImportFiles";
import ImportRecords from "./ImportRecords";

interface Props {
  currentSection?: number;
  parentRecordVersionID?: number;
  subModuleDownload?: boolean;
  topLevelSection?: boolean;
  importFromWS?: boolean;
  openImportSlideOut?: () => void;
}

function ImportBulk({
  topLevelSection,
  subModuleDownload,
  parentRecordVersionID,
  currentSection,
  importFromWS,
  openImportSlideOut,
}: Props) {
  const mainStore = useMainStore();

  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const moduleIdentifier = mainStore.context.themisModuleIdentifier;
  const subModuleName = mainStore.context.subModuleTableName;
  // -1 is on purpose to remove sections as sub modules do not have a section
  const moduleWorkspaceID = subModuleDownload
    ? -1
    : mainStore.context.moduleWorkspaceID;
  const sections = mainStore.sectionTags.list.filter(
    (sectionTag) => sectionTag.module_workspace_id === moduleWorkspaceID,
  );
  const isImportFilesEnabled =
    [
      "policy",
      "procedures",
      "issue_management",
      "marketing",
      "training",
      "documents",
      "audits",
      "complaints",
      "qa_tests_development",
      "conflicts_of_interest",
      "vendor_due_diligence",
    ].includes(moduleIdentifier || "") ||
    ["samples"].includes(subModuleName || "");
  const isImportSheetEnabled =
    [
      "policy",
      "procedures",
      "issue_management",
      "training",
      "documents",
      "complaints",
      "customer_support",
      "vendor_due_diligence",
      "control_mapping",
      "risk_register",
      "qa_tests_development",
      "key_risk_indicators",
    ].includes(moduleIdentifier || "") ||
    ["values"].includes(subModuleName || "");

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [importType, setImportType] = useState(""); // One of 'files' or 'sheet'
  const [step, setStep] = useState(0);
  const [selectedSection, setSelectedSection] = useState<
    number | "unassigned" | null
  >(null); // this is null on purpose. do not replace with useState()

  function onPopupOpen() {
    setSelectedSection(currentSection || null);
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
    setStep(0);
    setSelectedSection(null);
    setImportType("");
  }

  function onChooseSheet() {
    setImportType("sheet");
    if (sections.length === 0 || moduleIdentifier === "documents") {
      setStep(2);
    } else {
      setStep(1);
    }
  }

  function onChooseFiles() {
    setImportType("files");
    if (sections.length === 0 || moduleIdentifier === "documents") {
      setStep(2);
    } else {
      setStep(1);
    }
  }

  function onSelectSection(section_id: number | "unassigned") {
    setSelectedSection(section_id);
  }

  function onContinue() {
    setStep(2);
  }

  const renderSectionSelection = (
    <SectionSelection
      title="Select a section to import your files"
      sections={sections}
      selectedSection={selectedSection}
      onSelectSection={onSelectSection}
      handleContinue={onContinue}
      topLevelSection={topLevelSection}
    />
  );

  const renderButtonTrigger = (
    <Button
      color="tertiary"
      LeftIcon={PiUploadSimpleBold}
      disabled={!hasModuleWriteAccess}
      data-testid="import-button"
    >
      Import
    </Button>
  );

  const handleImportFromWS = () => {
    openImportSlideOut?.();
    onPopupClose();
  };

  return (
    <Popup
      position="bottom right"
      trigger={renderButtonTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      closeOnDocumentClick={!(importType === "files" && step === 2)}
      keepTooltipInside
      nested
    >
      {step === 0 && (
        <div className="select-small-wrap wide">
          <ul>
            {isImportSheetEnabled && (
              <li
                data-testid={`import-spreadsheet-${selectedSection}`}
                onClick={onChooseSheet}
              >
                Upload Spreadsheet
              </li>
            )}
            {isImportFilesEnabled && (
              <li onClick={onChooseFiles}>Upload Multiple Files</li>
            )}
            {!isImportSheetEnabled &&
              !isImportFilesEnabled &&
              !importFromWS && <li>No import options available</li>}
            {importFromWS && (
              <>
                <hr className="import-data-from-ws-seperator" />
                <li onClick={handleImportFromWS}>
                  Import records from another Workspace
                </li>
              </>
            )}
          </ul>
        </div>
      )}
      {step === 1 && renderSectionSelection}
      {step === 2 && (
        <div>
          {importType === "files" && (
            <ImportFiles
              setShowPopup={setShowPopup}
              sectionId={
                selectedSection === "unassigned" ? null : selectedSection
              }
              parentRecordVersionID={parentRecordVersionID}
              subModuleDownload={subModuleDownload}
            />
          )}
          {importType === "sheet" && (
            <ImportRecords
              setShowPopup={setShowPopup}
              sectionId={
                selectedSection === "unassigned" ? null : selectedSection
              }
              parentRecordVersionID={parentRecordVersionID}
              subModuleDownload={subModuleDownload}
            />
          )}
        </div>
      )}
    </Popup>
  );
}

ImportBulk.defaultProps = {
  currentSection: null,
  parentRecordVersionID: -1,
  subModuleDownload: false,
  topLevelSection: false,
};

export default observer(ImportBulk);

import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

interface Props {
  width: number | string;
  dataTestId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOnBlur?: (...args: any[]) => any;
  initialValue?: number | string;
  locked?: boolean;
  type?: string;
}

function RegularTextInput({
  width,
  initialValue,
  type,
  locked,
  dataTestId,
  handleOnBlur,
}: Props) {
  // Refs
  const textInput = useRef(null);

  // State
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");

  // vars
  const style = { width };
  const liClassNames = classNames("cell input-cell", {
    active: isActive,
    "locked-cell pointer-events-none": locked,
  });

  // Effects
  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
    setValue(initialValue || "");
  }, [initialValue]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      textInput.current.blur();
    }
  };

  const onBlur = () => {
    setIsActive(false);
    if (value !== initialValue) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      handleOnBlur(value);
    }
  };

  const handleClick = () => {
    if (!locked) {
      setIsActive(true);
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      textInput.current.focus();
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <li
      style={style}
      className={liClassNames}
      onClick={handleClick}
      onBlur={onBlur}
    >
      <div className="cell-content">
        <textarea
          ref={textInput}
          // @ts-expect-error TS(2322) FIXME: Type '{ ref: MutableRefObject<null>; type: string ... Remove this comment to see the full error message
          type={type}
          data-testid={dataTestId}
          disabled={locked}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="N/A"
        />
      </div>
    </li>
  );
}

RegularTextInput.defaultProps = {
  type: "text",
  dataTestId: "input-cell",
  locked: false,
  handleOnBlur: () => {},
};

export default RegularTextInput;

import React from "react";
import type { OnChangeHandlerFunc } from "react-mentions";

import { Button, Flex } from "@/components/Elements";

import UserMentionsInput from "../UserMentionsInput";

export interface CreativeViewCommentFormProps {
  reply?: boolean;
  textValue: string;
  handleChange: OnChangeHandlerFunc;
  handleConfirm(): void;
  handleReject(): void;
}

function CreativeViewCommentForm({
  textValue,
  handleChange,
  handleConfirm,
  handleReject,
}: CreativeViewCommentFormProps) {
  function handleKeyDown(
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (event.key === "Enter" && !event.shiftKey) {
      handleConfirm();
    }
  }

  return (
    <div className="creative-form" data-testid="cv-comment-form">
      <UserMentionsInput
        singleLine={false}
        editable
        content={textValue}
        placeholder="Add a comment. Use @ to mention a user."
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Flex columnGap={8} data-testid="cv-comment-form-actions">
        <Button
          size="sm"
          data-testid="cv-comment-form-confirm"
          disabled={!textValue}
          onClick={handleConfirm}
          label="Post"
        />
        <Button
          theme="tertiary"
          size="sm"
          data-testid="cv-comment-form-reject"
          onClick={handleReject}
          label="Cancel"
        />
      </Flex>
    </div>
  );
}

export default CreativeViewCommentForm;

import classNames from "classnames";
import React from "react";

import type { THEMIS_ICONS } from "@/assets";
import type {
  THEMIS_ICON_POSITIONS,
  THEMIS_TEXT_BUTTON_THEMES,
} from "@/config/theme";

import Icon from "../Icon";
import Typography from "../Typography";

type TextButtonProps = {
  className?: string;
  disabled?: boolean;
  icon?: keyof typeof THEMIS_ICONS;
  iconPosition?: (typeof THEMIS_ICON_POSITIONS)[number];
  label: string;
  theme?: (typeof THEMIS_TEXT_BUTTON_THEMES)[number];
} & React.HTMLAttributes<HTMLButtonElement>;

const TextButton = ({
  className = "",
  disabled = false,
  icon,
  iconPosition = "left",
  theme = "default",
  label,
  ...rest
}: TextButtonProps) => {
  const iconComponent = icon && <Icon name={icon} />;
  const typographyComponent = (
    <Typography label={label} size="sm" type="span" />
  );

  return (
    <button
      className={classNames(
        "themisTextButton",
        `themisTextButton__${theme}`,
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {iconPosition === "left" ? (
        <>
          {iconComponent}
          {typographyComponent}
        </>
      ) : (
        <>
          {typographyComponent}
          {iconComponent}
        </>
      )}
    </button>
  );
};

TextButton.displayName = "TextButton";

export default TextButton;

import { Button, IconButton } from "@themis/ui";
import classNames from "classnames";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import FindingsSendToIssuesBulk from "@/features/qa/components/FindingsSendToIssuesBulk";

import SlideMenu from "../../slideMenu/SlideMenu";

interface Props {
  findingsRecordVersionId: number;
}

function QAFindingsContextMenu({ findingsRecordVersionId }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id } = useParams();
  const testRecordVersionId = Number(record_version_id);

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  // Variables
  const { canDeleteRecords } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === findingsRecordVersionId,
  );
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const statusValue = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isOpenIssue = statusValue === "open";
  const hasSentIssue = statusValue === "sent";
  const hasClosedIssue = statusValue === "closed";

  // Functions
  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
  }

  async function handleActionClick() {
    if (!hasSentIssue) {
      mainStore.dynamicTable.clearSelectedRecordVersionIDs();
      mainStore.dynamicTable.addSelectedRecordVersionID(
        findingsRecordVersionId,
      );
    }
  }

  async function deleteRow() {
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.qa.deleteTestFindings(findingsRecordVersionId);
      mainStore.toast.setText("Test Findings deleted!");
    }
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  function handleViewIssueDetailClick() {
    history.push(
      `/workspaces/${workspaceID}/modules/qa-tests/${testRecordVersionId}/view-issues/${findingsRecordVersionId}`,
    );
  }

  async function handleCloseClick() {
    await mainStore.qa.closeFinding(findingsRecordVersionId);
    handlePopUpClose();
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="qa-findings-points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderDeleteConfirmation = (
    <div>
      <h4>Delete Test Finding</h4>
      <p>
        Are you sure you want to delete this finding? This is not reversible.
      </p>
      <div className="confirmation">
        <button onClick={deleteRow}>Yes</button>
        <button onClick={noButtonPressed}>No</button>
      </div>
    </div>
  );

  const renderActionBtn = (
    <Button
      color="tertiary"
      size="md"
      onClick={handleActionClick}
      disabled={hasSentIssue || hasClosedIssue || !hasModuleWriteAccess}
    >
      {isOpenIssue && "Send"}
      {hasSentIssue && "Sent"}
      {hasClosedIssue && "Closed"}
    </Button>
  );

  return (
    <div className="list-points">
      {hasModuleWriteAccess && (
        <SlideMenu trigger={renderActionBtn}>
          <FindingsSendToIssuesBulk
            parentRecordVersionID={testRecordVersionId}
          />
        </SlideMenu>
      )}

      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && (
          <div className="table-dropdown policy-dropdown">
            <ul>
              <li
                data-testid="view-detail"
                onClick={handleViewIssueDetailClick}
              >
                View Issue Details
              </li>
              {!hasClosedIssue && !hasSentIssue && hasModuleWriteAccess && (
                <li
                  data-testid="close-findings-button"
                  onClick={handleCloseClick}
                >
                  Close Issue
                </li>
              )}
              {!hasSentIssue && !hasClosedIssue && canDeleteRecords && (
                <>
                  <hr />
                  <li
                    data-testid="qa-delete-trigger"
                    onClick={deleteRowConfirmation}
                  >
                    Delete
                  </li>
                </>
              )}
            </ul>
          </div>
        )}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

export default QAFindingsContextMenu;

import React from "react";
import { Button } from "../Button/Button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, } from "./Dialog";
var ConfirmationDialog = function (_a) {
    var title = _a.title, description = _a.description, onCancel = _a.onCancel, onConfirm = _a.onConfirm, _b = _a.isOpen, isOpen = _b === void 0 ? false : _b, onIsOpenChange = _a.onIsOpenChange, _c = _a.confirmText, confirmText = _c === void 0 ? "Confirm" : _c, _d = _a.confirmVariant, confirmVariant = _d === void 0 ? "primary" : _d;
    var handleAction = function (action) {
        onIsOpenChange(false);
        if (action) {
            action();
        }
    };
    return (React.createElement(Dialog, { open: isOpen },
        React.createElement(DialogContent, null,
            React.createElement(DialogHeader, null,
                React.createElement(DialogTitle, null, title),
                React.createElement(DialogDescription, null, description)),
            React.createElement(DialogFooter, null,
                React.createElement(Button, { color: "transparent", onClick: function () { return handleAction(onCancel); } }, "Cancel"),
                React.createElement(Button, { color: confirmVariant, onClick: function () { return handleAction(onConfirm); } }, confirmText)))));
};
export { ConfirmationDialog };

import { type DropdownOption } from "@/components/Elements/Dropdown/Dropdown";

export const TERMS: DropdownOption[] = [
  {
    id: 0,
    name: "On termination date",
  },
  {
    id: 30,
    name: "30",
  },
  {
    id: 60,
    name: "60",
  },
  {
    id: 90,
    name: "90",
  },
];

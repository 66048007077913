import type { Control } from "react-hook-form";

import type { ValidationSchema } from "../../use-validation-schema/use-validation-schema";

export interface DropdownColumnControlsProps {
  control: Control<ValidationSchema>;
}

export const DropdownColumnControls = ({
  ...rest
}: DropdownColumnControlsProps) => {
  return <div {...rest}>{null}</div>;
};

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import type { relatedRecord } from "@/stores/types/control-types";

interface Props {
  recordVersionID: number;
  items?: relatedRecord[];
  locked?: boolean;
  pinned?: boolean;
  width?: number;
}

function OperationalControls({
  width,
  recordVersionID,
  items,
  pinned,
  locked,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const history = useHistory();
  // only Operational Controls are saved as 'record_versions' related instead of 'records', so only Operational Controls will not have module_workspace_id
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const filteredItems = items.filter((item) => !item.module_workspace_id);
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const count = filteredItems.length;
  const liClassNames = classNames("controls-cell", {
    pinned,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
    "pointer-events-none": locked || isReadOnly,
  });

  // Functions
  const handleCellClick = () => {
    if (themisModuleIdentifier === "issue_management") {
      return history.push(
        `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/operational-controls`,
      );
    }
    history.push(
      `/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}/operational-controls`,
    );
  };

  return (
    <li
      className={liClassNames}
      style={{ width }}
      data-testid="rr-control-cell"
      onClick={handleCellClick}
    >
      <div className="cell-content">
        <span
          className={classNames("controls-cell-value", {
            "with-value": count > 0,
          })}
        >
          {count > 0
            ? `${count} Controls added`
            : "- Add Operational Controls -"}
        </span>
      </div>
    </li>
  );
}

OperationalControls.defaultProps = {
  width: 200,
  items: [],
};

export default observer(OperationalControls);

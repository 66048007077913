import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CountByDateMutationRequest,
  CountByDateMutationResponse,
} from "../../models/CountByDate";

/**
 * @description The count of records on the specified date
 * @summary records count by specified date
 * @link /reports/count_by_date
 */
export async function countByDate(
  data: CountByDateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CountByDateMutationResponse>["data"]> {
  const res = await client<
    CountByDateMutationResponse,
    CountByDateMutationRequest
  >({
    method: "put",
    url: `/reports/count_by_date`,
    data,
    ...options,
  });
  return res.data;
}

import { filter, map, mapValues, reduce } from "lodash";

export function getPermissionMap(
  user: {
    roles: Array<{
      workspace_id: number;
      permission_set?: {
        company_id: number;
        data?: {
          module?: {
            targets?: Record<string, { state?: { [key: string]: boolean } }>;
          };
        };
      };
    }>;
  },
  currentWorkspaceId: number,
  currentCompanyId: number,
  permissionType: string,
): Record<string, boolean> {
  // filter out inapplicable roles and simplify the structure to {moduleName1: true|false, ...}
  const relevantRoles = filter(
    user.roles,
    ({ workspace_id, permission_set }) =>
      workspace_id === currentWorkspaceId &&
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      permission_set.company_id === currentCompanyId,
  );
  const viewMaps = map(relevantRoles, ({ permission_set }) =>
    mapValues(
      permission_set?.data?.module?.targets,
      (val) => !!val?.state?.[permissionType],
    ),
  );

  // get the union the multiple roles' permissions
  return reduce(
    viewMaps,
    (previous, current) =>
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      mapValues(current, (value, key) => previous[key] || value),
    {},
  );
}

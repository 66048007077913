import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import ChangeManagementContextMenu from "./ChangeManagementContextMenu";

function ChangeManagementActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();

  // Variables
  const path = location.pathname;
  const Id = Number(path.substring(path.lastIndexOf("/") + 1));

  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === Id,
  );

  if (!recordVersion) {
    return null;
  }

  return (
    <ChangeManagementContextMenu
      recordVersion={recordVersion}
      showOnlyIcon={false}
    />
  );
}

export default observer(ChangeManagementActionBar);

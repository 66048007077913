import React from "react";
import { Cell, Pie, PieChart as RechartPieChart } from "recharts";

import { TruncatedLegend } from "../TruncatedLegend";

export interface PieChartProps {
  data: {
    name: string;
    value: number;
    color: string;
  }[];
  centerValue?: number;
  isEmptyState?: boolean;
}

export const PieChart = ({
  data,
  centerValue,
  isEmptyState,
}: PieChartProps) => {
  return (
    <div className="tw-relative tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
      {centerValue && (
        <div className="tw-absolute tw-z-10 -tw-ml-[105px] tw-flex tw-h-[135px] tw-w-[135px] tw-items-center tw-justify-center tw-text-2xl tw-font-semibold tw-text-neutral-500">
          {centerValue}
        </div>
      )}
      <div className="tw-relative tw-flex tw-h-full tw-items-center tw-justify-center tw-gap-5">
        <div className="tw-relative tw-h-[135px] tw-w-[135px]">
          <RechartPieChart
            height={135}
            width={135}
            className="tw-rounded-full tw-bg-white tw-shadow-lg"
          >
            <Pie
              data={
                isEmptyState
                  ? [{ name: "No Data", value: 0.01, color: "#ebebf5" }]
                  : data
              }
              innerRadius={40}
              outerRadius={64}
              paddingAngle={0}
              dataKey="value"
            >
              {isEmptyState ? (
                <Cell key="cell-0" fill="#ebebf5" />
              ) : (
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))
              )}
            </Pie>
          </RechartPieChart>
        </div>
        <TruncatedLegend
          seriesNames={data.map(({ name }) => name)}
          isVertical
        />
      </div>
    </div>
  );
};

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateFieldOptionMutationRequest,
  CreateFieldOptionMutationResponse,
  CreateFieldOptionPathParams,
} from "../../models/CreateFieldOption";

/**
 * @description Create a new field option for a specific field
 * @summary Create a new field option
 * @link /fields/:field_id/options
 */
export async function createFieldOption(
  fieldId: CreateFieldOptionPathParams["field_id"],
  data: CreateFieldOptionMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateFieldOptionMutationResponse>["data"]> {
  const res = await client<
    CreateFieldOptionMutationResponse,
    CreateFieldOptionMutationRequest
  >({
    method: "post",
    url: `/fields/${fieldId}/options`,
    data,
    ...options,
  });
  return res.data;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { camelCase, isEmpty, sortBy, startCase } from "lodash";

export function titleCase(value: string | undefined) {
  return startCase(camelCase(value));
}

export function isPresent(value: any) {
  // Null and undefined are not present
  if (!value) {
    return false;
  }

  // Empty arrays and objects are not present
  if (typeof value === "object" && isEmpty(value)) {
    return false;
  }

  // Empty strings are not present
  if (typeof value === "string" && value.trim().length === 0) {
    return false;
  }

  // Numbers (including 0), non-empty strings, and all other types are present
  return true;
}

export function filterIntegerChange(
  filterArg: any,
  value: string[],
  setTableFiltersParam: (...args: any[]) => any,
  getTableFiltersParam: (...args: any[]) => any,
  callback: (...args: any[]) => any,
) {
  setTableFiltersParam([
    ...getTableFiltersParam().filter(
      (item: any) => item.name !== filterArg.name,
    ),
    {
      name: filterArg.name,
      condition: filterArg.condition,
      options:
        value.length > 1
          ? sortBy(
              [
                {
                  ...filterArg.options[0],
                  value: value[0],
                },
                {
                  ...filterArg.options[1],
                  value: value[1],
                },
              ],
              (filterItem) => filterItem?.value,
            )
          : [
              {
                ...filterArg.options[0],
                value: value[0],
              },
            ],
    },
  ]);
  callback(false);
}

import classNames from "classnames";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../Elements";
import ConfirmationDialog from "../shared/ConfirmationDialog";

interface Props {
  actionPlansRecordVersionID: number;
}

function ActionPlansContextMenu({ actionPlansRecordVersionID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id } = useParams();
  const imRecordVersionID = Number(record_version_id);

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  // Variables
  const { user } = mainStore.users;
  const {
    canDeleteRecords,
    canPublishOrLockRecords,
    canUnpublishOrUnlockRecords,
  } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;
  const buttonClasses = classNames("points-button", {
    "big-active": isOpenModal,
  });
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === actionPlansRecordVersionID,
  );
  const statusValue = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const ownersIDs =
    mainStore.avroSchemas.valueForField(
      "action_plan_owners",
      recordVersion?.data,
    ) || [];
  const isOwner = ownersIDs.includes(user.id);

  // Functions
  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  async function deleteRow() {
    await mainStore.issueManagement.deleteActionPlan(
      actionPlansRecordVersionID,
    );
    mainStore.toast.setText("Action Plan deleted!");
  }

  function deleteRowConfirmation() {
    setIsDeleteConfirmationOpen(true);
  }

  function handleViewIssueDetailClick() {
    history.push(
      `/workspaces/${workspaceID}/modules/issue-management/${imRecordVersionID}/action-plans/${actionPlansRecordVersionID}`,
    );
  }

  function handleClose() {
    mainStore.issueManagement.closeActionPlan(actionPlansRecordVersionID);
  }

  function handleUnlock() {
    mainStore.issueManagement.unlockActionPlan(actionPlansRecordVersionID);
  }

  // elements
  const renderTrigger = (
    <button
      className={buttonClasses}
      data-testid="action-plans-points-button-trigger"
    >
      <Icon
        name="moreVertical"
        color={isOpenModal ? "generalWhite" : "generalMidnightDark"}
      />
    </button>
  );

  const renderActionButton = () => {
    if (statusValue === "open" && canPublishOrLockRecords && isOwner) {
      return (
        <button
          type="button"
          className="finalize-active"
          data-testid="action-plans-close-btn"
          onClick={handleClose}
        >
          Close
        </button>
      );
    }

    if (statusValue === "closed" && canUnpublishOrUnlockRecords && isOwner) {
      return (
        <button
          type="button"
          className="unlock"
          data-testid="action-plans-unlock-btn"
          onClick={handleUnlock}
        >
          Unlock
        </button>
      );
    }

    return (
      <button
        type="button"
        className="finalize-active"
        data-testid="action-plans-view-btn"
        onClick={handleViewIssueDetailClick}
      >
        View
      </button>
    );
  };

  return (
    <div className="list-points">
      {renderActionButton()}

      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {!isDeleteConfirmationOpen && (
          <div
            className="table-dropdown policy-dropdown"
            data-testid="action-plans-content-menu-dropdown"
          >
            <ul>
              <li
                data-testid="view-detail"
                onClick={handleViewIssueDetailClick}
              >
                View Action Detail
              </li>
              {canDeleteRecords && (
                <>
                  <hr />
                  <li
                    data-testid="action-plans-delete-trigger"
                    onClick={deleteRowConfirmation}
                  >
                    Delete
                  </li>
                </>
              )}
            </ul>
          </div>
        )}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            <ConfirmationDialog
              handleConfirm={deleteRow}
              handleReject={handlePopUpClose}
              heading="Delete IM Action Plan?"
              content="Are you sure you want to delete this action plan? This is not reversible."
            />
          </div>
        )}
      </Popup>
    </div>
  );
}

export default ActionPlansContextMenu;

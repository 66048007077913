import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useLoading } from "../../../../hooks/useLoading";
import Loading from "../../../Loading";
import RecordNotFound from "../../../shared/RecordNotFound";
import Textarea from "../../shared/dynamic-form/view-textarea/Textarea";
import ViewFileUploader from "../../shared/dynamic-form/ViewFileUploader";
import SectionTagViewDocument from "./SectionTagViewDocument";

interface Props {
  isCreate?: boolean;
}

function ChangeManagementsSectionDetailView({ isCreate }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'section_id' does not exist on type '{}'.
  const { section_id } = useParams();
  const sectionID = Number(section_id);
  const sectionTag = mainStore.sectionTags.list?.find(
    (section_tag) => section_tag.id === sectionID,
  );

  // Hooks
  const loading = useLoading(mainStore.fields.list);

  // Effects
  useEffect(() => {
    setTitle(sectionTag?.title || "");
    setDetails(sectionTag?.details || "");
    setNotes(sectionTag?.notes || "");
  }, [sectionTag?.id]);

  useEffect(() => {
    if (sectionTag) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'FolderFile[] | undefined' is not... Remove this comment to see the full error message
      setFiles(sectionTag.files);
    }
  }, [sectionTag]);

  useEffect(handleAutoSave, [files]);

  // Functions
  function handleAutoSave() {
    handleSubmit();
  }

  function handleSubmit() {
    const payload = {
      title,
      details,
      notes,
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      files: files.map((item) => item.id),
    };

    mainStore.changeManagements.setCurrentSectionTags(payload);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  function handleAddFile(ids, fileNames, fileData) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    const newFiles = ids.map((id, index) => ({
      id,
      name: fileNames[index].name,
      fileData: fileData[index],
    }));

    // @ts-expect-error TS(2345) FIXME: Argument of type '(prevFiles: never[]) => any[]' i... Remove this comment to see the full error message
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function handleRemoveFile(id) {
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
    setFiles(files.filter((item) => item.id !== id));
  }

  // Variables
  const renderNameInput = (
    <div className="section-row one-cols" data-testid="ch-section-input">
      <div className="column column-input">
        <h4>Section Name</h4>
        <input
          name="title"
          type="text"
          data-testid="sidebar-name-input"
          placeholder="Name of Section (e.g Change to processes)"
          required
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          onBlur={handleAutoSave}
        />
      </div>
    </div>
  );

  const renderDetailsArea = (
    <div className="section-row one-cols" data-testid="ch-section-details">
      <div className="column column-textarea">
        <h4>Section Description</h4>
        <Textarea
          name="details"
          data-testid="sidebar-details-input"
          placeholder="Description for the records in this section"
          // @ts-expect-error TS(2322) FIXME: Type '{ name: string; "data-testid": string; place... Remove this comment to see the full error message
          required
          value={details}
          onChange={(event) => setDetails(event.target.value)}
          onBlur={handleAutoSave}
        />
      </div>
    </div>
  );

  const renderNotes = (
    <div className="section-row one-cols" data-testid="ch-section-notes">
      <div className="column column-textarea">
        <h4>Extra Info</h4>
        <Textarea
          name="notes"
          data-testid="sidebar-notes-input"
          placeholder="Notes (Optional)"
          value={notes}
          onChange={(event) => setNotes(event.target.value)}
          onBlur={handleAutoSave}
        />
      </div>
    </div>
  );

  const renderAttachment = (
    <section className="section-upload" data-testid="ch-section-attachments">
      <div className="section-header">
        <h3>Attachments</h3>
        <div>
          <ViewFileUploader
            recordVersion={{}}
            fieldName=""
            label="Upload Attachment"
            handleAddFile={handleAddFile}
            isMultiple
          />
        </div>
      </div>

      <div className="section-content">
        <SectionTagViewDocument
          files={files}
          handleRemoveFile={handleRemoveFile}
          sectionID={sectionID}
        />
      </div>
    </section>
  );

  if (loading) {
    return <Loading loadingLayout="small-table" showTableHeader={false} />;
  } else if (!sectionTag && !isCreate) {
    return <RecordNotFound />;
  }

  return (
    <div className="detail-view template" data-testid="ch-section-detail-view">
      <div className="detail-view-wrap">
        <section>
          <div className="section-header">
            <h3>Section Details</h3>
          </div>
          {renderNameInput}
          {renderDetailsArea}
          {renderNotes}
        </section>
        {renderAttachment}
      </div>
    </div>
  );
}

export default observer(ChangeManagementsSectionDetailView);

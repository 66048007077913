import { useQueryClient } from "@tanstack/react-query";
import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { debounce, kebabCase } from "lodash";
import type { ChangeEvent, ChangeEventHandler } from "react";
import React, { useCallback, useEffect, useState } from "react";
import {
  PiDotsThreeOutlineVerticalFill,
  PiEyeBold,
  PiPencilSimpleLineBold,
} from "react-icons/pi";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import type { AppliedPermissionSet, AttachmentGroup } from "@/api";
import { COMPANY_USERS_QUERY_KEY } from "@/api/queries/users";
import { userColors } from "@/components/constants";
import {
  formatDate,
  formatDateAndTime,
  stringToDate,
  stringToDateAndTime,
} from "@/components/helpers/DateFormatters";
import { validateEmail } from "@/components/helpers/EmailValidator";
import SharedModules from "@/components/settings/SharedModules";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useMainStore } from "@/contexts/Store";
import PolicyFileSelect from "@/features/policy/components/PolicyFileSelect";
import type { SelectOption } from "@/stores/types/field-types";

import AttestationsMenu from "../policy/AttestationsMenu";
import AttestationStatus from "../policy/AttestationStatus";
import CompletionPercentage from "../policy/CompletionPercentage";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import UsersCircle from "../shared/UsersCircle";
import CloseConfirmationDialog from "./CloseConfirmationDialog";
import InputTable from "./columns/InputTable";
import DepartmentsSelect from "./columns/users/DepartmentsSelect";
import MembershipSelect from "./columns/users/MembershipSelect";
import RolesSelect from "./columns/users/RolesSelect";
import Status from "./columns/users/Status";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import UpdateConfirmationDialog from "./UpdateConfirmationDialog";

type InputData = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

interface Props {
  data: InputData;
  headers: {
    field_name: string;
    width?: number;
    disabled?: boolean;
    title: string;
  }[];
  model:
    | "Attestation"
    | "PoliciesLibrary"
    | "AuditTrail"
    | "Contact"
    | "Department"
    | "Product"
    | "RiskType"
    | "System"
    | "Tag"
    | "User"
    | "WorkspaceUser"
    | "ThemisCustomAudit";
  archived?: boolean;
  displayWorkspaceID?: number;
  extraMargin?: boolean;
  globalID?: string;
  hasErrorClass?: string;
  id?: number;
  inTag?: boolean;
  isNewRecord?: boolean;
  reload?: () => void;
  removeNewEntry?: () => void;
  showCheckbox?: boolean;
  disableStretchCell?: boolean;
  attachmentGroups?: AttachmentGroup[];
  attachmentViewText?: string;
  attachmentDownloadText?: string;
}

interface RenderItemProps {
  id: string;
  name: string;
  value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled: boolean;
  countLabel: number;
}

interface ErrorsState {
  rolesError: boolean;
  emailError: boolean;
  fullNameError: boolean;
}

const initialErrorsState: ErrorsState = {
  rolesError: false,
  emailError: false,
  fullNameError: false,
};

function ListSettings({
  archived,
  headers,
  data,
  model,
  isNewRecord = false,
  removeNewEntry,
  reload,
  displayWorkspaceID,
  hasErrorClass = "has-errors",
  globalID,
  id,
  showCheckbox,
  extraMargin,
  inTag,
  disableStretchCell = false,
  attachmentGroups = [],
  attachmentViewText = "",
  attachmentDownloadText = "",
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { pathname } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  // State
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState(initialErrorsState);
  const [hasRoleName, setHasRoleName] = useState(false);
  const [params, setParams] = useState<InputData>({});
  const [showPopup, setShowPopup] = useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [slideMenuType, setSlideMenuType] = useState("share");
  const [showDeleteContent, setShowDeleteContent] = useState(false);
  const [showArchiveContent, setShowArchiveContent] = useState(false);

  // Variables
  const { company } = mainStore.companies;
  const {
    activeWorkspace,
    isAdmin,
    isCW,
    isIW,
    isWorkspaceAdmin,
    themisModuleIdentifier,
  } = mainStore.context;
  const { permissionSets, modules } = mainStore.permissions;
  const { list: workspaces } = mainStore.workspaces;
  const { user } = mainStore.users;
  const workspaceID = displayWorkspaceID || activeWorkspace?.id;
  const title = data.title || data.name || data.full_name || data.id || "";

  const isInteralUsersPage = RegExp(/\/settings\/users-internal$/g).test(
    pathname,
  );

  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // Effects
  useEffect(() => {
    if (isNewRecord) {
      setIsFormEnabled(true);
    }
  }, [isNewRecord]);

  useEffect(() => {
    if (Object.keys(params).length || hasRoleName) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        rolesError: hasRoleName ? false : prevErrors.rolesError,
        emailError: params.email ? false : prevErrors.emailError,
        fullNameError: params.full_name ? false : prevErrors.fullNameError,
      }));
    }
  }, [params, hasRoleName]);

  // Functions
  function editClick() {
    setIsFormEnabled(true);
  }

  function invalidateAllUsersQuery() {
    if (isIW && pathname.match(/\/settings\/users.*/)) {
      queryClient.invalidateQueries({
        queryKey: [COMPANY_USERS_QUERY_KEY, company.id],
      });
    }
  }

  function goToUserDetailView() {
    if (pathname.startsWith("/settings/users-internal")) {
      history.push(`/settings/users/${data.id}?in=1`);
    } else {
      history.push(`/settings/users/${data.id}`);
    }
  }

  function goToRecordDetailView() {
    if (themisModuleIdentifier) {
      const baseURL = `/workspaces/${workspaceID}/modules/${kebabCase(
        themisModuleIdentifier,
      )}`;
      history.push(`${baseURL}/${data.id}`);
    }
  }

  function validatePresence(fieldName: string) {
    const validValueInData = data[fieldName]?.trim()?.length > 0;
    const validValueInParams = params[fieldName]?.trim()?.length > 0;
    const presentValueInParams = fieldName in params;
    const validForNewRecord = isNewRecord && validValueInParams;
    const validForExistingRecord =
      !isNewRecord &&
      (validValueInParams || (validValueInData && !presentValueInParams));
    return validForNewRecord || validForExistingRecord;
  }

  async function acceptClick() {
    const rolesError = isNewRecord && !hasRoleName && model === "User";
    const emailError =
      isNewRecord && !params.email && ["Contact", "User"].includes(model);

    const validFullName = validatePresence("full_name");
    const fullNameError = !validFullName && ["Contact", "User"].includes(model);

    if (!rolesError && !emailError && !fullNameError) {
      setIsFormEnabled(false);
    }

    setValidationErrors({
      rolesError,
      emailError,
      fullNameError,
    });

    if (rolesError || emailError || fullNameError) {
      editClick();
      mainStore.toast.setErrorText("Required fields must be filled in!");
      return;
    }

    // Do not send API request if there are no changes
    if (!Object.keys(params).length) {
      return;
    }

    if (params.email) {
      const isEmailValid = validateEmail(params.email);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        emailError: !isEmailValid,
      }));
      if (!isEmailValid) {
        setIsFormEnabled(true);
        mainStore.toast.setErrorText("Email must be valid!");
        return;
      }
    }

    if (model === "Department") {
      if (isNewRecord) {
        // Create Department
        if (company?.id) {
          mainStore.departments.createWithParams(company.id, params);
          removeNewEntry?.();
        }
      } else {
        // Update Department
        if (company?.id) {
          mainStore.departments.updateWithParams(company.id, data.id, params);
        }
      }
    } else if (model === "WorkspaceUser") {
      if (isNewRecord) {
        // Create User
        if (workspaceID && params.email) {
          await mainStore.users.createWithParams(
            workspaceID,
            params,
            false,
            false,
          );
          removeNewEntry?.();
          invalidateAllUsersQuery();
        }
      } else {
        // Update User
        if (company?.id) {
          params.workspace_id = displayWorkspaceID;
          await mainStore.users.updateWithParams(
            company.id,
            data.id,
            params,
            false,
            false,
            workspaceID,
          );
        }
        invalidateAllUsersQuery();
      }
    } else if (model === "User") {
      if (isNewRecord) {
        // Create User
        if (workspaceID && params.email) {
          if (params.roles) {
            params.skip_default_permission = true;
          }
          await mainStore.users.createWithParams(workspaceID, params);
          removeNewEntry?.();
          invalidateAllUsersQuery();
        }
      } else {
        // Update User
        if (company?.id) {
          if (displayWorkspaceID) {
            updateParamsValue("workspace_id", displayWorkspaceID);
          }
          await mainStore.users.updateWithParams(company.id, data.id, params);
          invalidateAllUsersQuery();
        }
      }
    } else if (model === "Contact") {
      if (isNewRecord) {
        // Create Contact
        if (workspaceID && params.email) {
          mainStore.contacts.create(params);
          removeNewEntry?.();
        }
      } else {
        // Update Contact
        if (data.id) {
          mainStore.contacts.update(data.id, params);
          setShowPopup(false);
        }
      }
    } else if (model === "Product") {
      if (isNewRecord) {
        // Create Product
        if (company?.id) {
          mainStore.products.create(company.id, params);
          removeNewEntry?.();
        }
      } else {
        // Update Product
        if (company?.id) {
          mainStore.products.update(company.id, data.id, params);
        }
      }
    } else if (model === "RiskType") {
      if (isNewRecord) {
        // Create RiskType
        if (company?.id) {
          mainStore.riskTypes.create({ companyID: company.id, params });
          removeNewEntry?.();
        }
      } else {
        // Update RiskType
        if (company?.id) {
          mainStore.riskTypes.update(company.id, data.id, params);
        }
      }
    } else if (model === "Tag") {
      if (isNewRecord) {
        if (company?.id && params.title) {
          mainStore.tags.create(company.id, params.title);
          removeNewEntry?.();
        }
      } else {
        if (company?.id && params.title && data.id) {
          mainStore.tags.update(company.id, data.id, params.title);
        }
      }
    }
  }

  async function handleDeleteAttestation(attestationID?: number) {
    const attestationListParams = {
      all_users: true,
    };
    await mainStore.attestations.delete(attestationID, attestationListParams);
    reload?.();
  }

  const onDeleteYes = useCallback(
    async (parameters?: InputData) => {
      // If it's a new record - just remove it from table
      if (isNewRecord) {
        removeNewEntry?.();
        return;
      }

      if (model === "Department") {
        // Delete Department
        if (company?.id) {
          mainStore.departments.delete(company.id, data.id);
        }
      } else if (model === "WorkspaceUser") {
        // Delete User
        if (workspaceID) {
          await mainStore.users.removeFromWorkspace(workspaceID, data.id);
          invalidateAllUsersQuery();
        }
      } else if (model === "User") {
        // Soft Delete User
        if (workspaceID) {
          if (parameters?.removeFromCompany) {
            await mainStore.users.removeFromCompany(
              company?.id,
              workspaceID,
              data.id,
            );
          } else {
            await mainStore.users.removeFromWorkspace(workspaceID, data.id);
          }
          invalidateAllUsersQuery();
        }
      } else if (model === "Contact") {
        // Delete Contact
        if (workspaceID && data.id) {
          mainStore.contacts.delete(data.id);
        }
      } else if (model === "Product") {
        // Delete Product
        if (company?.id) {
          mainStore.products.delete(company.id, data.id);
        }
      } else if (model === "RiskType") {
        // Delete RiskType
        if (company?.id) {
          mainStore.riskTypes.delete(company.id, data.id);
        }
      } else if (model === "Tag") {
        // Delete Tag
        if (company?.id && data.id) {
          mainStore.tags.delete(company.id, data.id);
        }
      } else if (model === "Attestation") {
        // Delete Attestation
        handleDeleteAttestation(data.id);
      }
    },
    [id],
  );

  const onDeleteNo = useCallback(() => {
    onPopupClose();
  }, []);

  const onDiscardYes = useCallback(() => {
    // If it's a new record - just remove it from table
    if (isNewRecord) {
      removeNewEntry?.();
      return;
    }

    if (model === "Department") {
      // Reload index of Departments
      setIsFormEnabled(false);
      setParams({});
      setValidationErrors(initialErrorsState);
    } else if (model === "User" || model === "Contact") {
      // Reload index of Users
      setIsFormEnabled(false);
      setParams({});
      setValidationErrors(initialErrorsState);
    }
  }, []);

  async function onArchiveYes() {
    await mainStore.trainings.archiveAttestations(data.id);
    onPopupClose();
    reload?.();
    mainStore.toast.setInfoText("Attestation archived");
  }

  function onPopupClose() {
    setShowDeleteContent(false);
    setShowArchiveContent(false);
    setShowPopup(false);
  }

  const onSaveComplete = () => {
    setIsFormEnabled(false);
  };

  function valueChanged(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    // Get field name and the value
    const { name, value } = event.target;
    updateParamsValue(name, value);
  }

  function updateParamsValue(name: string, value: string | number) {
    setParams({ ...params, [name]: value });
  }

  function updateDepartment(_name: string, department?: SelectOption) {
    if (department) {
      updateParamsValue("department_id", department.id);
    }
  }

  function handleStatusChange(status: string) {
    updateParamsValue("status", status);
    mainStore.users.updateWithParams(company.id, data.id, { status });
  }

  function handleReinvite() {
    mainStore.users.reinvite(workspaceID, data.id);
  }

  function openAttestationStatusDetails() {
    openSlideMenu();
    setSlideMenuType("details");
  }

  function closeAttestationStatusDetails() {
    setShowSlideMenu(false);
  }

  function handleAttestationStatusClick() {
    if (showSlideMenu) {
      closeAttestationStatusDetails();
    } else {
      openAttestationStatusDetails();
    }
  }

  function onCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
    const isAttestations = model === "Attestation";
    if (e.target.checked) {
      if (isAttestations) {
        mainStore.dynamicTable.addSelectedRecordVersionID(data.id);
      } else {
        mainStore.dynamicTable.addSelectedUserID(data.id);
      }
    } else {
      if (isAttestations) {
        mainStore.dynamicTable.removeSelectedRecordVersionID(data.id);
      } else {
        mainStore.dynamicTable.removeSelectedUserID(data.id);
      }
    }
  }

  const debouncedHandleReinvite = debounce(handleReinvite, 2000);

  const additionalParams: { roles?: AppliedPermissionSet[] } = {};
  if (model === "User" && Array.isArray(data.roles) && data.roles.length) {
    additionalParams.roles = data.roles;
  }

  const discardDeleteButtons = isFormEnabled ? (
    <CloseConfirmationDialog
      shouldUpdateStateOnYes={false}
      onYes={onDiscardYes}
    />
  ) : (
    <DeleteConfirmationDialog
      {...additionalParams}
      model={model}
      inTag={inTag}
      title={title}
      onYes={onDeleteYes}
      onNo={onDeleteNo}
    />
  );

  function renderFieldComponent(props: RenderItemProps, item: InputData) {
    const { name, value, disabled } = props;
    const { external } = item;

    switch (name) {
      case "meta.name":
        return <InputTable value={item.name} name={name} disabled={disabled} />;
      case "email":
        return (
          <InputTable {...props} model={model} placeholder="– Type here –" />
        );
      case "full_name":
        return disabled ? (
          <UsersCircle
            user={item}
            additionalStyles={{ background: userColors[item.icon_color_index] }}
          />
        ) : (
          <InputTable
            {...props}
            placeholder="– Type here –"
            updateParameterValue={updateParamsValue}
          />
        );
      case "roles":
        return (
          <RolesSelect
            {...props}
            company={company}
            permissionSets={permissionSets}
            workspaces={workspaces}
            modules={modules}
            activeWorkspace={activeWorkspace}
            isNewRecord={isNewRecord}
            isAdmin={isAdmin}
            isCW={isCW}
            isWorkspaceAdmin={isWorkspaceAdmin}
            isCurrentUser={user.id === data.id}
            requireInternalWorkspace={isInteralUsersPage}
            onChange={updateParamsValue}
            onFinish={onSaveComplete}
            setHasRoleName={setHasRoleName}
          />
        );
      case "department":
        return disabled ? (
          <InputTable {...props} value={value?.title || "N/A"} model={model} />
        ) : (
          <DepartmentsSelect {...props} onChange={updateDepartment} />
        );
      case "status":
        return (
          <Status
            {...props}
            displayName={data.email || data.full_name}
            canAuthenticateWithEmailAndPassword={
              data.can_authenticate_with_email_and_password
            }
            handleStatusChange={handleStatusChange}
            handleReinvite={debouncedHandleReinvite}
          />
        );
      case "initial_attestation_date":
      case "latest_attestation_date":
        return (
          <InputTable
            value={
              formatDate(stringToDate(item.initial_attestation_date)) ||
              undefined
            }
            name={name}
            disabled={disabled}
          />
        );
      case "record_version":
        return (
          <InputTable value={item.version} name={name} disabled={disabled} />
        );
      case "attestation_completion":
        return (
          <CompletionPercentage progress={item.attestation_completion || 0} />
        );
      case "attestation_status":
        return (
          <AttestationStatus
            status={item.attestation_status}
            recordVersionID={item.id}
            showSlideMenu={showSlideMenu}
          />
        );
      case "internal":
        return <InputTable {...props} value={value ? "Yes" : "No"} />;
      case "module_admin":
        return <InputTable value={value || "N/A"} name={name} disabled />;
      case "username":
        return (
          <UsersCircle
            user={item}
            additionalStyles={{ background: userColors[item.icon_color_index] }}
          />
        );
      case "audited_changes":
        return <InputTable value={item.description} name={name} disabled />;
      case "created_at":
        return (
          <InputTable
            value={
              formatDateAndTime(stringToDateAndTime(item.created_at)) ||
              undefined
            }
            name={name}
            disabled
          />
        );
      case "membership":
        return (
          <MembershipSelect
            {...props}
            external={external}
            onChange={updateParamsValue}
          />
        );
      case "assignees":
        return (
          <div className="list-settings-assignees-wrapper">
            {mainStore.users.users
              .filter((i) => item.assignees?.includes(i.id))
              .map((u) => (
                <UsersCircle key={u.id} user={u} showFullName={false} />
              ))}
          </div>
        );
      case "contributor":
        return <InputTable {...props} model={model} placeholder="" />;
      case "original":
        if (themisModuleIdentifier === "policy") {
          return (
            <PolicyFileSelect
              isInTableView
              fieldName={name}
              attachmentGroups={attachmentGroups}
              attachmentViewText={attachmentViewText}
              attachmentDownloadText={attachmentDownloadText}
            />
          );
        }

        return <InputTable {...props} model={model} />;

      default:
        return <InputTable {...props} model={model} />;
    }
  }

  // elements
  // eslint-disable-next-line react/no-unstable-nested-components
  const GlobalID = () => {
    if (!globalID) {
      return null;
    }

    return (
      <li
        className="global-id-cell global-id-width global-id-mr cell computed-columns read-only"
        data-testid={globalID}
      >
        <div
          className="cell-content global-id-context small-font"
          data-testid={`global-id-${globalID}`}
          onClick={goToRecordDetailView}
        >
          <span className="small-font">{globalID}</span>
        </div>
      </li>
    );
  };

  const renderFields = headers.map((header) => {
    const { field_name, disabled, title: headerTitle } = header;
    const width = header.width || 200;
    const style = { width };
    const value =
      params[field_name] === undefined ? data[field_name] : params[field_name];

    const inputDisabled =
      isNewRecord && field_name === "email"
        ? false
        : !isFormEnabled || disabled || false;
    const props = {
      id: data.id,
      name: field_name,
      placeholder: headerTitle,
      value,
      onChange: valueChanged,
      disabled: inputDisabled,
      countLabel: data.used_count,
    };

    const hasEmailError = validationErrors.emailError && field_name === "email";
    const hasRolesError = validationErrors.rolesError && field_name === "roles";
    const hasFullNameError =
      validationErrors.fullNameError && field_name === "full_name";
    const liClassNames = classNames(
      {
        disabled: inputDisabled,
        "form-enabled": isFormEnabled,
        [hasErrorClass]: hasEmailError || hasRolesError || hasFullNameError,
        "cell-tags-wrap": [
          "Product",
          "RiskType",
          "System",
          "Department",
          "Tag",
        ].includes(model),
      },
      field_name,
    );

    return (
      <li
        key={field_name}
        style={style}
        className={liClassNames}
        onClick={
          liClassNames.includes("attestation_status") ||
          liClassNames.includes("attestation_completion")
            ? handleAttestationStatusClick
            : undefined
        }
      >
        {renderFieldComponent(props, data)}
      </li>
    );
  });

  // If the form is new and enabled
  const editAcceptButtons = isFormEnabled ? (
    <UpdateConfirmationDialog
      onYes={acceptClick}
      model={model}
      countLabel={data.used_count}
      showConfirmation={!isNewRecord && data.used_count !== 0}
      message={
        model === "User"
          ? "Updating User will result in changes to any related module records. Are you sure you want to proceed?"
          : ""
      }
    />
  ) : (
    <IconButton
      data-testid="user-edit-trigger"
      color="tertiary"
      size="lg"
      Icon={PiPencilSimpleLineBold}
      onClick={editClick}
    />
  );

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          showPopup,
      })}
    />
  );

  function openSlideMenu() {
    setShowSlideMenu(true);
    setShowPopup(false);
  }

  return (
    <div
      className="list-table-settings"
      data-testid="list-settings-row"
      id={`${model?.toLowerCase()}-${isNewRecord ? "new" : data.id}`}
      style={extraMargin && !showCheckbox ? { marginLeft: "35px" } : {}}
    >
      <ul>
        <div className="list-table-settings-wrap">
          {showCheckbox && (
            <div
              className="table-checkbox"
              onClick={(e) => e.stopPropagation()}
              data-testid="record-checkbox"
              style={{
                justifyContent: "flex-start",
                width: "28px",
                marginTop: "10px",
                marginRight: "2px",
                paddingLeft: "6px",
              }}
            >
              <input type="checkbox" id={data.id} onChange={onCheckboxChange} />
              <label htmlFor={data.id} />
            </div>
          )}
          <GlobalID />
          {renderFields}
          {!disableStretchCell && <span className="stretch-cell" />}
        </div>
        {model === "Attestation" && !archived && hasModuleWriteAccess && (
          <div className="buttons-table-settings-wrap buttons-table-attestation">
            <Popup
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => renderTrigger}
              position="bottom right"
              open={showPopup}
              onOpen={() => setShowPopup(true)}
              onClose={onPopupClose}
              keepTooltipInside
            >
              <div
                className={classNames("table-dropdown", {
                  "success-dropdown": showDeleteContent,
                  "policy-dropdown": !showDeleteContent && !showArchiveContent,
                  archive: showArchiveContent,
                })}
                style={{ width: 230 }}
              >
                {showDeleteContent && (
                  <ConfirmationDialog
                    handleConfirm={onDeleteYes}
                    handleReject={onDeleteNo}
                    heading="Delete Attestation"
                    content="Do you want to delete this attestation?"
                  />
                )}
                {showArchiveContent && (
                  <div>
                    <h4>Archive Attestation</h4>
                    <p>
                      Please read carefully and make sure this Attestation
                      qualifies the following points before you confirm archive:
                    </p>
                    <ul className="ul-square">
                      <li>Attestation is no longer enforced</li>
                      <li>No further updates can be made</li>
                      <li>Open versions will be deleted with archive</li>
                    </ul>
                    <div className="confirmation">
                      <button type="button" onClick={onArchiveYes}>
                        Yes
                      </button>
                      <button type="button" onClick={onPopupClose}>
                        No
                      </button>
                    </div>
                  </div>
                )}
                {!showDeleteContent && !showArchiveContent && (
                  <ul>
                    <li
                      data-testid="edit-attestation-button"
                      onClick={() => {
                        openSlideMenu();
                        setSlideMenuType("edit");
                      }}
                    >
                      Edit Attestation List
                    </li>
                    <li
                      data-testid="resend-attestation-button"
                      onClick={() => {
                        openSlideMenu();
                        setSlideMenuType("share");
                      }}
                    >
                      Resend Attestation
                    </li>
                    {data.attestation_status === "incomplete" && (
                      <li onClick={() => setShowDeleteContent(true)}>Delete</li>
                    )}
                    {data.attestation_status === "complete" &&
                      themisModuleIdentifier === "training" && (
                        <li onClick={() => setShowArchiveContent(true)}>
                          Archive
                        </li>
                      )}
                  </ul>
                )}
              </div>
            </Popup>
          </div>
        )}

        {model === "Contact" && !isFormEnabled && (
          <div className="buttons-table-settings-wrap contact-actions buttons-table-attestation">
            <Popup
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => renderTrigger}
              position="bottom right"
              open={showPopup}
              onOpen={() => setShowPopup(true)}
              onClose={() => setShowPopup(false)}
              keepTooltipInside
            >
              <div
                className="table-dropdown policy-dropdown"
                data-testid="contacts-dropdown"
              >
                <ul>
                  <li data-testid="contacts-edit-trigger" onClick={editClick}>
                    Edit
                  </li>
                  <li
                    data-testid="contacts-delete-trigger"
                    onClick={onDeleteYes}
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </Popup>
          </div>
        )}
        {([
          "User",
          "WorkspaceUser",
          "Product",
          "RiskType",
          "System",
          "Department",
          "Tag",
        ].includes(model) ||
          isFormEnabled) && (
          <div
            className={classNames(
              "tw-min-w-[130px]",
              "old-table-buttons-padding",
              "buttons-table-settings-wrap",
              {
                "buttons-contact-wrap": model === "Contact",
              },
            )}
          >
            {["/settings/users", "/settings/users-internal"].includes(
              pathname,
            ) && (
              <IconButton
                color="tertiary"
                size="lg"
                Icon={PiEyeBold}
                onClick={goToUserDetailView}
                disabled={isFormEnabled}
              />
            )}
            {editAcceptButtons}
            {pathname !== "/settings/workspace" && discardDeleteButtons}
          </div>
        )}
      </ul>
      <SlideMenu
        open={showSlideMenu}
        closeSlideMenu={() => setShowSlideMenu(false)}
        slideMenuID={data.id}
        singleSlideMenu
      >
        {pathname === "/settings/workspace" ? (
          <SharedModules />
        ) : (
          <AttestationsMenu
            menu={slideMenuType}
            recordVersionID={data.id}
            emailSlideClose={reload}
            recordVersionName={data.name}
          />
        )}
      </SlideMenu>
    </div>
  );
}

ListSettings.defaultProps = {
  archived: false,
  isNewRecord: false,
  hasErrorClass: "has-errors",
  globalID: null,
  showCheckbox: false,
  extraMargin: false,
  inTag: false,
};

export default ListSettings;

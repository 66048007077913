import AddFile from "./svgs/add-file";
import Anchor from "./svgs/anchor";
import Apple from "./svgs/apple";
import Archive from "./svgs/archive";
import ArrowDown from "./svgs/arrow-down";
import ArrowDownBlack from "./svgs/arrow-down-black";
import ArrowUp from "./svgs/arrow-up";
import Attachment from "./svgs/attachment";
import BarChart1 from "./svgs/bar-chart-1";
import BarChart2 from "./svgs/bar-chart-2";
import Block from "./svgs/block";
import Book1 from "./svgs/book-1";
import Book2 from "./svgs/book-2";
import BookmarkLinear from "./svgs/bookmark-linear";
import BookmarkSolid from "./svgs/bookmark-solid";
import Broadcast from "./svgs/broadcast";
import Calendar from "./svgs/calendar";
import Check from "./svgs/check";
import CheckBlue from "./svgs/check-blue";
import CheckedLinear from "./svgs/checked-linear";
import CheckedSolid from "./svgs/checked-solid";
import ChevronDown from "./svgs/chevron-down";
import ChevronLeft from "./svgs/chevron-left";
import ChevronRight from "./svgs/chevron-right";
import ChevronUp from "./svgs/chevron-up";
import Cintagon from "./svgs/cintagon";
import CircleLinear from "./svgs/circle-linear";
import CircleSolid from "./svgs/circle-solid";
import Clock1 from "./svgs/clock-1";
import Clock2 from "./svgs/clock-2";
import Close from "./svgs/close";
import CloudDownload from "./svgs/cloud-download";
import CloudUpload from "./svgs/cloud-upload";
import CommentLinear from "./svgs/comment-linear";
import CommentSolid from "./svgs/comment-solid";
import Confirm from "./svgs/confirm";
import Congrats from "./svgs/congrats";
import Contact from "./svgs/contact";
import Copy from "./svgs/copy";
import Crop from "./svgs/crop";
import Crown from "./svgs/crown";
import Dashboard from "./svgs/dashboard";
import DataList from "./svgs/data-list";
import Department from "./svgs/department";
import Diamond from "./svgs/diamond";
import Divider from "./svgs/divider";
import Document from "./svgs/document";
import Documents from "./svgs/documents";
import Donut from "./svgs/donut";
import Download from "./svgs/download";
import Duplicate from "./svgs/duplicate";
import Edit from "./svgs/edit";
import Email from "./svgs/email";
import Equal from "./svgs/equal";
import Export from "./svgs/export";
import Eye from "./svgs/eye";
import EyeOff from "./svgs/eye-off";
import FaceRecognition from "./svgs/face-recognition";
import Facebook from "./svgs/facebook";
import Feedback from "./svgs/feedback";
import Figma from "./svgs/figma";
import Filters from "./svgs/filters";
import FlagLinear from "./svgs/flag-linear";
import FlagSolid from "./svgs/flag-solid";
import FolderLinear from "./svgs/folder-linear";
import FolderSolid from "./svgs/folder-solid";
import Forward from "./svgs/forward";
import Gear from "./svgs/gear";
import Globe from "./svgs/globe";
import Google from "./svgs/google";
import GoogleMono from "./svgs/google-mono";
import Graph from "./svgs/graph";
import HeartLinear from "./svgs/heart-linear";
import HeartSolid from "./svgs/heart-solid";
import Help from "./svgs/help";
import Home from "./svgs/home";
import Hub from "./svgs/hub";
import Import from "./svgs/import";
import Info from "./svgs/info";
import Invite from "./svgs/invite";
import Key from "./svgs/key";
import Lightbulb from "./svgs/lightbulb";
import LineChart from "./svgs/line-chart";
import Link from "./svgs/link";
import LinkedIn from "./svgs/linkedin";
import ListBullet from "./svgs/list-bullet";
import Loading from "./svgs/loading";
import Lock from "./svgs/lock";
import Marker from "./svgs/marker";
import Menu from "./svgs/menu";
import Minus from "./svgs/minus";
import MinusRect from "./svgs/minus-rect";
import Module from "./svgs/module";
import Monitor from "./svgs/monitor";
import MoreHorizontal from "./svgs/more-horizontal";
import MoreVertical from "./svgs/more-vertical";
import Notification from "./svgs/notification";
import NotificationOff from "./svgs/notification-off";
import Nudge from "./svgs/nudge";
import Number from "./svgs/number";
import Octagon from "./svgs/octagon";
import Partial from "./svgs/partial";
import Pause from "./svgs/pause";
import People from "./svgs/people";
import Phone from "./svgs/phone";
import Photo1 from "./svgs/photo-1";
import Photo2 from "./svgs/photo-2";
import Pin1 from "./svgs/pin-1";
import Pin2 from "./svgs/pin-2";
import Play from "./svgs/play";
import Plus from "./svgs/plus";
import PlusRect from "./svgs/plus-rect";
import Pointer from "./svgs/pointer";
import Printer from "./svgs/printer";
import PriorityHigh from "./svgs/priority-high";
import PriorityLow from "./svgs/priority-low";
import PriorityMedium from "./svgs/priority-medium";
import PushLeft from "./svgs/push-left";
import PushRight from "./svgs/push-right";
import RadioChecked from "./svgs/radio-checked";
import RadioUnchecked from "./svgs/radio-unchecked";
import Refresh from "./svgs/refresh";
import Reorder from "./svgs/reorder";
import Reply from "./svgs/reply";
import Resize from "./svgs/resize";
import RoundSquareLinear from "./svgs/round-square-linear";
import RoundSquareSolid from "./svgs/round-square-solid";
import Search from "./svgs/search";
import Send from "./svgs/send";
import Settings from "./svgs/settings";
import SettingsWhite from "./svgs/settings-white";
import Sextagon from "./svgs/sextagon";
import Share from "./svgs/share";
import SharePoint from "./svgs/sharepoint";
import ShieldSafe from "./svgs/shield-safe";
import Shredder from "./svgs/shredder";
import Slider from "./svgs/slider";
import Square from "./svgs/square";
import StarLinear from "./svgs/star-linear";
import StarSolid from "./svgs/star-solid";
import Statstics from "./svgs/statistics";
import Status from "./svgs/status";
import Suitcase from "./svgs/suitcase";
import SunLinear from "./svgs/sun-linear";
import SunSolid from "./svgs/sun-solid";
import Sunny from "./svgs/sunny";
import Switch from "./svgs/switch";
import Sync from "./svgs/sync";
import Table from "./svgs/table";
import Tag from "./svgs/tag";
import Text from "./svgs/text";
import ThemisLogo from "./svgs/themis-logo";
import ThumbsDown from "./svgs/thumbs-down";
import ThumbsUp from "./svgs/thumbs-up";
import Toggle from "./svgs/toggle";
import Trash from "./svgs/trash";
import Triangle from "./svgs/triangle";
import Twitter from "./svgs/twitter";
import Unlock from "./svgs/unlock";
import Upload from "./svgs/upload";
import UploadPolicy from "./svgs/upload-policy";
import User from "./svgs/user";
import Warning from "./svgs/warning";
import ZoomIn from "./svgs/zoom-in";
import ZoomOut from "./svgs/zoom-out";

export const THEMIS_COLORFUL_ICONS = {
  figma: Figma,
  google: Google,
  sharePoint: SharePoint,
} as const;

export const THEMIS_ICONS = {
  ...THEMIS_COLORFUL_ICONS,
  addFile: AddFile,
  anchor: Anchor,
  apple: Apple,
  archive: Archive,
  arrowDown: ArrowDown,
  arrowDownBlack: ArrowDownBlack,
  arrowUp: ArrowUp,
  attachment: Attachment,
  barChart1: BarChart1,
  barChart2: BarChart2,
  block: Block,
  book1: Book1,
  book2: Book2,
  bookmarkLinear: BookmarkLinear,
  bookmarkSolid: BookmarkSolid,
  broadcast: Broadcast,
  calendar: Calendar,
  check: Check,
  checkBlue: CheckBlue,
  checkedLinear: CheckedLinear,
  checkedSolid: CheckedSolid,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  cintagon: Cintagon,
  circleLinear: CircleLinear,
  circleSolid: CircleSolid,
  clock1: Clock1,
  clock2: Clock2,
  close: Close,
  cloudDownload: CloudDownload,
  cloudUpload: CloudUpload,
  commentLinear: CommentLinear,
  commentSolid: CommentSolid,
  confirm: Confirm,
  congrats: Congrats,
  contact: Contact,
  copy: Copy,
  crop: Crop,
  crown: Crown,
  dashboard: Dashboard,
  dataList: DataList,
  department: Department,
  diamond: Diamond,
  divider: Divider,
  document: Document,
  documents: Documents,
  donut: Donut,
  download: Download,
  duplicate: Duplicate,
  edit: Edit,
  email: Email,
  equal: Equal,
  export: Export,
  eyeOff: EyeOff,
  eye: Eye,
  faceRecognition: FaceRecognition,
  facebook: Facebook,
  feedback: Feedback,
  filters: Filters,
  flagLinear: FlagLinear,
  flagSolid: FlagSolid,
  folderLinear: FolderLinear,
  folderSolid: FolderSolid,
  forward: Forward,
  gear: Gear,
  globe: Globe,
  googleMono: GoogleMono,
  graph: Graph,
  heartLinear: HeartLinear,
  heartSolid: HeartSolid,
  help: Help,
  home: Home,
  hub: Hub,
  import: Import,
  info: Info,
  invite: Invite,
  key: Key,
  lightbulb: Lightbulb,
  lineChart: LineChart,
  link: Link,
  linkedIn: LinkedIn,
  listBullet: ListBullet,
  loading: Loading,
  lock: Lock,
  marker: Marker,
  menu: Menu,
  minusRect: MinusRect,
  minus: Minus,
  module: Module,
  monitor: Monitor,
  moreHorizontal: MoreHorizontal,
  moreVertical: MoreVertical,
  notificationOff: NotificationOff,
  notification: Notification,
  nudge: Nudge,
  number: Number,
  octagon: Octagon,
  partial: Partial,
  pause: Pause,
  people: People,
  phone: Phone,
  photo1: Photo1,
  photo2: Photo2,
  pin1: Pin1,
  pin2: Pin2,
  play: Play,
  plusRect: PlusRect,
  plus: Plus,
  pointer: Pointer,
  printer: Printer,
  priorityHigh: PriorityHigh,
  priorityLow: PriorityLow,
  priorityMedium: PriorityMedium,
  pushLeft: PushLeft,
  pushRight: PushRight,
  radioChecked: RadioChecked,
  radioUnchecked: RadioUnchecked,
  refresh: Refresh,
  reorder: Reorder,
  reply: Reply,
  resize: Resize,
  roundSquareLinear: RoundSquareLinear,
  roundSquareSolid: RoundSquareSolid,
  search: Search,
  send: Send,
  settings: Settings,
  settingsWhite: SettingsWhite,
  sextagon: Sextagon,
  share: Share,
  shieldSafe: ShieldSafe,
  shredder: Shredder,
  slider: Slider,
  square: Square,
  starLinear: StarLinear,
  starSolid: StarSolid,
  statstics: Statstics,
  status: Status,
  suitcase: Suitcase,
  sunLinear: SunLinear,
  sunSolid: SunSolid,
  sunny: Sunny,
  switch: Switch,
  sync: Sync,
  table: Table,
  tag: Tag,
  text: Text,
  themisLogo: ThemisLogo,
  thumbsDown: ThumbsDown,
  thumbsUp: ThumbsUp,
  toggle: Toggle,
  trash: Trash,
  triangle: Triangle,
  twitter: Twitter,
  unlock: Unlock,
  uploadPolicy: UploadPolicy,
  upload: Upload,
  user: User,
  warning: Warning,
  zoomIn: ZoomIn,
  zoomOut: ZoomOut,
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type {
  CreateTaskableMutationRequest,
  CreateTaskMutationRequest,
  ListTasksQueryParams,
  Task,
  UpdateTaskMutationRequest,
} from "../gen";
import {
  createThemisDocument,
  deleteThemisDocument,
} from "../gen/axios/documentsController";
import {
  createTask,
  createTaskable,
  deleteTaskable,
  getTask,
  listTasks,
  updateTask,
} from "../gen/axios/tasksController";

export const TASKS_QUERY_KEY = "tasks";

export function useTasks(
  companyId: number,
  queryParams?: ListTasksQueryParams | null,
) {
  return useQuery({
    queryKey: [TASKS_QUERY_KEY, companyId, queryParams],
    queryFn: () => listTasks(companyId, queryParams || undefined),
    enabled: !!companyId && queryParams !== null,
    placeholderData: (prevData) => prevData,
  });
}

export function useTask(companyId: number, taskId: number) {
  return useQuery({
    queryKey: [TASKS_QUERY_KEY, companyId, taskId],
    queryFn: () => getTask(taskId),
    enabled: !!companyId && !!taskId,
  });
}

export function useCreateTask({
  companyId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (task: CreateTaskMutationRequest) =>
      createTask(companyId, task),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

export function useUpdateTask({ companyId }: { companyId: number }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      ...task
    }: Pick<Task, "id"> & UpdateTaskMutationRequest) => updateTask(id, task),
    onSuccess: () => {
      if (queryClient.isMutating({ mutationKey: [TASKS_QUERY_KEY] }) === 0) {
        queryClient.invalidateQueries({
          queryKey: [TASKS_QUERY_KEY, companyId],
        });
      }
    },
  });
}

export function useCreateTaskAttachment({ companyId }: { companyId: number }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      taskId,
      signedId,
    }: {
      taskId: number;
      signedId: string;
    }) => {
      const response = await createThemisDocument("tasks", taskId, {
        data: { file: signedId, field_name: "attachments" },
      });
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId, taskId],
      });
      return response;
    },
  });
}

export function useDeleteTaskAttachment({ companyId }: { companyId: number }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      taskId,
      attachmentId,
    }: {
      taskId: number;
      attachmentId: number;
    }) => {
      await deleteThemisDocument(attachmentId);
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId, taskId],
      });
    },
  });
}

export function useCreateTaskable({
  companyId,
  taskId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  taskId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (taskable: CreateTaskableMutationRequest) =>
      createTaskable(taskId, taskable),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

export function useDeleteTaskable({
  companyId,
  taskId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  taskId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (taskableId: number) => deleteTaskable(taskId, taskableId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

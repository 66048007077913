import { LinkedRecord } from "./LinkedRecord";
import type { ThemisDocument } from "./ThemisDocument";
import type { User } from "./User";

export const findingStatus = {
  open: "open",
  sent: "sent",
  closed: "closed",
} as const;
export type FindingStatus = (typeof findingStatus)[keyof typeof findingStatus];
export const findingSource = {
  compliance: "compliance",
  audit: "audit",
  regulatory: "regulatory",
} as const;
export type FindingSource = (typeof findingSource)[keyof typeof findingSource];
export const findingRiskLevel = {
  low: "low",
  medium: "medium",
  high: "high",
} as const;
export type FindingRiskLevel =
  (typeof findingRiskLevel)[keyof typeof findingRiskLevel];
export type Finding = {
  /**
   * @type object
   */
  custom_fields: {
    [key: string]: string | string[];
  };
  /**
   * @description ID
   * @type integer
   */
  id: number;
  /**
   * @description Name
   * @type string
   */
  name: string;
  /**
   * @description Status
   * @type string
   */
  status: FindingStatus;
  /**
   * @description Description
   * @type string
   */
  description: string | null;
  /**
   * @description Source
   * @type string
   */
  source: FindingSource | null;
  /**
   * @description Risk Level
   * @type string
   */
  risk_level: FindingRiskLevel | null;
  /**
   * @description Due Date
   * @type string, date
   */
  due_date: string | null;
  /**
   * @description Date Identified
   * @type string, date
   */
  date_identified: string | null;
  /**
   * @description Action Plan
   * @type string
   */
  action_plan_title: string | null;
  supporting_evidence: ThemisDocument;
  owner: User | null;
  /**
   * @description List of Records this Finding is linked to
   * @type array
   */
  linked_records: LinkedRecord[];
  /**
   * @description Record ID
   * @type integer
   */
  record_id: number | null;
  /**
   * @description Record Name
   * @type string
   */
  record_name: string | null;
};

import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { IntegrationsAPI } from "@/api/legacy/integrations";
import FeatureFlagOrAccessWrapper from "@/components/FeatureFlagOrAccessWrapper";
import LoadingWrapper from "@/components/loading-wrapper";
import CompanySettingsNavigation from "@/components/settings/CompanySettingsNavigation";
import RenderIntegrationItem from "@/components/settings/integrations/components/integration-item";
import { useMainStore } from "@/contexts/Store";
import { useLoadableData } from "@/hooks/useLoadableData";
import zenDesk from "@/images/table-image/icon/zen-desk.svg";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

function SettingsIntegrations() {
  // Import MobX stores
  const mainStore = useMainStore();

  const history = useHistory();

  const zendeskModuleAdded = mainStore.moduleWorkspaces.isModuleAdded(
    TopLevelModule.ZENDESK,
  );

  // state
  const [loadingDisconnect, setLoadingDisconnect] = useState(false);

  const {
    loading: loadingIntegrations,
    data: integrationData,
    errorText,
    refetch,
  } = useLoadableData({
    request: () =>
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      IntegrationsAPI.getIntegrations(mainStore.context.workspaceID),
    onError: () =>
      "There was an issue loading the zendesk configuration. Please refresh to try again.",
    enabled: zendeskModuleAdded,
  });

  const handleDisconnectZendesk = async () => {
    setLoadingDisconnect(true);

    try {
      await IntegrationsAPI.disconnectZendesk(
        integrationData.integrations.zendesk.id,
      );

      await refetch();

      mainStore.toast.setText("Successfully disconnected Zendesk Integration.");
    } catch (error) {
      mainStore.toast.setErrorText(
        "There was an issue disconnecting Zendesk Integration, please try again.",
      );
    } finally {
      setLoadingDisconnect(false);
    }
  };

  return (
    <div className="settings-wrap company-info-settings-container">
      <CompanySettingsNavigation />

      <div className="workspace-dropdown-wrap workspace-dropdown-integration">
        <div className="workspace-dropdown">
          <LoadingWrapper
            loading={
              loadingIntegrations || !mainStore.moduleWorkspaces.list.length
            }
            errorText={errorText}
            loadingLayout="sections"
          >
            <FeatureFlagOrAccessWrapper
              enabled={zendeskModuleAdded}
              noAccessMessage="You do not have access to this feature"
            >
              {integrationData && (
                <RenderIntegrationItem
                  icon={zenDesk}
                  title="Zendesk"
                  subtitle="Connect your company's Zendesk account and automate the import of Zendesk tickets needed to be reviewed by your team."
                  enabled={Boolean(integrationData.integrations.zendesk.id)}
                  onConnect={() =>
                    history.push("/settings/integrations/zendesk/connect")
                  }
                  onConfigure={() =>
                    history.push(
                      `/settings/integrations/zendesk/configuration/${integrationData.integrations.zendesk.id}`,
                    )
                  }
                  onDisconnect={handleDisconnectZendesk}
                  loadingDisconnect={loadingDisconnect}
                />
              )}
            </FeatureFlagOrAccessWrapper>
          </LoadingWrapper>
        </div>
      </div>
    </div>
  );
}

export default observer(SettingsIntegrations);

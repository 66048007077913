import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ApiAuthUpdateMutationRequest,
  ApiAuthUpdateMutationResponse,
  ApiAuthUpdatePathParams,
} from "../../models/ApiAuthUpdate";

/**
 * @description Update API Auth
 * @summary Update API Auth
 * @link /integrations/api_auth/:id
 */
export async function apiAuthUpdate(
  id: ApiAuthUpdatePathParams["id"],
  data: ApiAuthUpdateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ApiAuthUpdateMutationResponse>["data"]> {
  const res = await client<
    ApiAuthUpdateMutationResponse,
    ApiAuthUpdateMutationRequest
  >({
    method: "put",
    url: `/integrations/api_auth/${id}`,
    data,
    ...options,
  });
  return res.data;
}

"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { format } from "date-fns";
import * as React from "react";
import { DayPicker } from "react-day-picker";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { cn } from "../../lib/utils";
import { Button } from "../Button/Button";
import { buttonBaseVariants } from "../Button/ButtonBase";
function Calendar(_a) {
    var className = _a.className, classNames = _a.classNames, _b = _a.showOutsideDays, showOutsideDays = _b === void 0 ? true : _b, onClickMonth = _a.onClickMonth, props = __rest(_a, ["className", "classNames", "showOutsideDays", "onClickMonth"]);
    return (React.createElement(DayPicker, __assign({ showOutsideDays: showOutsideDays, className: cn("tw-p-2 tw-shadow-none", className), classNames: __assign({ months: "tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-x-4 sm:tw-space-y-0", month: "tw-space-y-4", caption: "tw-flex tw-justify-center tw-relative tw-items-center", caption_label: "tw-text-sm tw-font-semibold tw-text-neutral-600", nav: "tw-space-x-1 tw-flex tw-items-center", nav_button: cn(buttonBaseVariants({ color: "transparent" }), "tw-h-7 tw-w-7 tw-p-0"), nav_button_previous: "tw-absolute tw-left-1", nav_button_next: "tw-absolute tw-right-1", table: "tw-w-full tw-border-collapse tw-space-y-1", head_row: "tw-flex", head_cell: "tw-text-neutral-300 tw-rounded-md tw-w-9 tw-font-semibold tw-text-[0.65rem]", row: "tw-flex tw-w-full tw-mt-1", cell: "tw-h-7 tw-w-9 tw-text-center tw-flex-grow tw-text-xs tw-p-0 tw-relative focus-within:tw-relative focus-within:tw-z-20", day: cn(buttonBaseVariants({ color: "grey" }), "tw-h-7 tw-w-9 tw-p-0 tw-font-normal aria-selected:tw-bg-secondary-300 aria-selected:hover:tw-bg-secondary-400 aria-selected:tw-text-neutral-25"), day_range_end: "day-range-end", day_today: "tw-text-primary-300" }, classNames), components: {
            IconLeft: function () { return React.createElement(PiCaretLeftBold, { className: "tw-h-4 tw-w-4" }); },
            IconRight: function () { return React.createElement(PiCaretRightBold, { className: "tw-h-4 tw-w-4" }); },
            CaptionLabel: function (_a) {
                var displayMonth = _a.displayMonth;
                return (React.createElement(Button, { color: "transparent", onClick: onClickMonth }, format(displayMonth, "MMM yyyy")));
            },
        } }, props)));
}
Calendar.displayName = "Calendar";
export { Calendar };

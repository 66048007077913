import classNames from "classnames";
import { observer } from "mobx-react";
import type { ChangeEvent } from "react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { getMatchingRecordsForMatrixIndex } from "@/features/risk-register/helpers";
import ScoringMatrixRatings from "@/features/risk-register/ScoringMatrixRatings";
import type {
  RiskRegisterScoringMatrix,
  ScoringMatrixCell,
  ScoringMatrixRating,
  ScoringMatrixRow,
} from "@/stores/types/risk-register-types";

import { Icon, Typography } from "../../Elements";
import { INHERENT_MATRIX_TITLE, RESIDUAL_MATRIX_TITLE } from "./Constants";

interface Props {
  handleEditSubmit: (matrix: RiskRegisterScoringMatrix) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setMatrix: (...args: any[]) => void;
  disabled?: boolean;
  editingMode?: boolean;
  riskRatings: ScoringMatrixRating[];
  setRiskRatings: (ratings: ScoringMatrixRating[]) => void;
  setEditingMatrixChanged: (changed: boolean) => void;
}

function ScoringMatrix({
  matrix,
  setMatrix,
  editingMode,
  disabled,
  riskRatings,
  setRiskRatings,
  handleEditSubmit,
  setEditingMatrixChanged,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [cellPopupOpened, setCellPopupOpened] = useState<null | string>(null);

  const riskType =
    matrix.name === INHERENT_MATRIX_TITLE ? "inherent" : "residual";

  function handleRowTitleChange(
    rowIndex: number,
    e: ChangeEvent<HTMLInputElement>,
  ) {
    const { value } = e.target;
    setMatrix({
      ...matrix,
      scoring_matrix_rows: matrix.scoring_matrix_rows.map(
        (row: ScoringMatrixRow, index: number) =>
          index === rowIndex ? { ...row, title: value } : row,
      ),
    });
  }

  function handleColumnTitleChange(
    columnIndex: number,
    e: ChangeEvent<HTMLInputElement>,
  ) {
    const { value } = e.target;

    setMatrix({
      ...matrix,
      scoring_matrix_columns: matrix.scoring_matrix_columns.map(
        (column: ScoringMatrixCell, index: number) =>
          index === columnIndex ? { ...column, title: value } : column,
      ),
    });
  }

  function handleAddNewRow() {
    setMatrix({
      ...matrix,
      scoring_matrix_rows: [
        ...matrix.scoring_matrix_rows,
        {
          title: "New",
          definition: "",
          scoring_matrix_cells: matrix.scoring_matrix_columns.map(() => ({})),
        },
      ],
    });
  }

  async function handleChangeCellRiskType(
    cellID: number,
    matrixRatingID: number | undefined,
    matrixTitle: string,
  ) {
    const foundRating = riskRatings.find(
      (rating) =>
        (!rating.id &&
          !!rating.risk_type &&
          rating.risk_type === riskType &&
          rating.title === matrixTitle) ||
        rating.id === matrixRatingID,
    );
    matrix.scoring_matrix_rows = matrix.scoring_matrix_rows.map(
      (row: ScoringMatrixRow) => {
        row.scoring_matrix_cells = row.scoring_matrix_cells.map((cell) => {
          if (cell.id === cellID) {
            return {
              ...(foundRating || {}),
              id: cellID,
              rating_id: Number(foundRating?.id),
            };
          }
          return cell;
        }) as ScoringMatrixCell[];
        return row;
      },
    );

    setMatrix({ ...matrix });
  }

  function handleAddNewColumn() {
    setMatrix({
      ...matrix,
      scoring_matrix_rows: matrix.scoring_matrix_rows.map(
        (row: ScoringMatrixRow) => ({
          ...row,
          scoring_matrix_cells: [...row.scoring_matrix_cells, {}],
        }),
      ),
      scoring_matrix_columns: [
        ...matrix.scoring_matrix_columns,
        {
          title: "New",
          definition: "",
        },
      ],
    });
  }

  function handleRowDelete(rowIndex: number) {
    setMatrix({
      ...matrix,
      scoring_matrix_rows: matrix.scoring_matrix_rows.filter(
        (_: never, index: number) => index !== rowIndex,
      ),
    });
  }

  function handleColumnDelete(columnIndex: number) {
    setMatrix({
      ...matrix,
      scoring_matrix_rows: matrix.scoring_matrix_rows.map(
        (row: ScoringMatrixRow) => ({
          ...row,
          scoring_matrix_cells: row.scoring_matrix_cells.filter(
            (_, idx) => idx !== columnIndex,
          ),
        }),
      ),
      scoring_matrix_columns: matrix.scoring_matrix_columns.filter(
        (_: never, index: number) => index !== columnIndex,
      ),
    });
  }

  // elements
  const renderHeader = (
    <div
      className="rr-scoring-matrix-header"
      data-testid="rr-scoring-matrix-heading"
    >
      <div className="rr-scoring-matrix-header-right">
        {editingMode && (
          <>
            <div className="rr-scoring-matrix-edit-helper-text">
              Click on a cell to select a different rating
            </div>
            {matrix.name === INHERENT_MATRIX_TITLE && (
              <button
                type="button"
                className="add-new"
                disabled={matrix.scoring_matrix_rows.length > 4}
                onClick={handleAddNewRow}
                data-testid="rr-scoring-matrix-edit-add-row"
              >
                <Icon name="plus" color="generalDark" size="de" />
                Add New Impact
              </button>
            )}
            <button
              type="button"
              className="add-new"
              disabled={matrix.scoring_matrix_columns.length > 4}
              onClick={handleAddNewColumn}
              data-testid="rr-scoring-matrix-edit-add-column"
            >
              <Icon name="plus" color="generalDark" size="de" />
              {matrix.name === INHERENT_MATRIX_TITLE
                ? "Add New Likelihood"
                : "Add New Control"}
            </button>
          </>
        )}
      </div>
    </div>
  );

  const renderRowsHeading = (
    <div
      className="rr-scoring-matrix-impact"
      data-testid="rr-scoring-matrix-rows-category"
    >
      <span>
        {matrix.name === INHERENT_MATRIX_TITLE ? "Impact" : "Inherent"}
      </span>
    </div>
  );

  const renderColumnsHeading = (
    <div
      className="rr-scoring-matrix-likelihood"
      data-testid="rr-scoring-matrix-columns-category"
    >
      <span>
        {matrix.name === INHERENT_MATRIX_TITLE ? "Likelihood" : "Control"}
      </span>
    </div>
  );

  const renderRows = (row: ScoringMatrixRow, rowIndex: number) => (
    <div
      key={rowIndex}
      className="rr-scoring-matrix-top-row"
      data-testid="rr-scoring-matrix-row"
    >
      <div className="rr-scoring-matrix-cell-title">
        {(!editingMode || matrix.name === RESIDUAL_MATRIX_TITLE) && (
          <h5>{row.title}</h5>
        )}
        {editingMode && matrix.name === INHERENT_MATRIX_TITLE && (
          <div className="rr-scoring-matrix-cell-title-edit">
            <input
              type="text"
              value={row.title}
              onChange={(e) => handleRowTitleChange(rowIndex, e)}
            />
            {matrix.scoring_matrix_rows.length > 2 && (
              <Icon
                name="trash"
                color="generalDark"
                size="de"
                onClick={() => handleRowDelete(rowIndex)}
                data-testid="rr-scoring-matrix-row-delete"
              />
            )}
          </div>
        )}
      </div>

      {row.scoring_matrix_cells.map(
        (cell: ScoringMatrixCell, colIndex: number) => {
          const rating = riskRatings
            .filter((rr) => !rr._destroy)
            .find(
              (rr) =>
                (Number.isNaN(cell.rating_id) && cell.title === rr.title) ||
                rr.id === cell.rating_id,
            );

          const style = {
            backgroundColor: rating?.color,
          };
          const active = [cellPopupOpened].includes(`${rowIndex}-${colIndex}`);

          const renderSelectedCellPopupContent = () => {
            const filteredRecordVersions = getMatchingRecordsForMatrixIndex(
              matrix,
              mainStore.recordVersions.list,
              rowIndex,
              colIndex,
            );

            if (editingMode) {
              return (
                <div
                  className="matrix-options-wrapper"
                  data-testid="matrix-rating-dropdown"
                >
                  {riskRatings
                    .filter((rr) => !rr._destroy)
                    .map((matrixRating) => (
                      <div
                        onClick={() => {
                          handleChangeCellRiskType(
                            cell.id,
                            matrixRating.id,
                            matrixRating.title,
                          );
                          setCellPopupOpened(null);
                        }}
                        key={matrixRating.id}
                      >
                        <span className="matrix-cell-content rarrmv-selection-padding">
                          <span
                            className="matrix-cell-color-ball"
                            style={{ backgroundColor: matrixRating.color }}
                          />
                          <span className="space-before-ball">
                            {matrixRating.title}
                          </span>
                        </span>
                      </div>
                    ))}
                </div>
              );
            }

            return (
              <div
                className="common-popup rr-scoring-matrix-cell-popup"
                data-testid="rr-scoring-matrix-cell-popup"
              >
                <div className="heading">
                  <h4>{matrix.name} Events</h4>
                  <span>{filteredRecordVersions.length}</span>
                </div>

                {filteredRecordVersions.map((item, idx) => {
                  const value = mainStore.avroSchemas.valueForField(
                    "risk_event",
                    item.data,
                  );

                  return <p key={idx}>{value}</p>;
                })}
              </div>
            );
          };

          return (
            <Popup
              key={colIndex}
              position="bottom left"
              trigger={
                <div
                  style={style}
                  className={classNames("rr-scoring-matrix-cell")}
                  data-testid="rr-scoring-matrix-cell"
                >
                  {rating?.title && (
                    <div className="rr-scoring-matrix-cell-circle">
                      <div className="rr-scoring-matrix-cell-circle-text">
                        {rating?.value}
                      </div>
                    </div>
                  )}
                  {rating?.title}
                </div>
              }
              open={active}
              onOpen={() => setCellPopupOpened(`${rowIndex}-${colIndex}`)}
              onClose={() => setCellPopupOpened(null)}
              keepTooltipInside
            >
              {renderSelectedCellPopupContent()}
            </Popup>
          );
        },
      )}
    </div>
  );

  const renderColumnsTitles = (column: ScoringMatrixCell, index: number) => (
    <div
      key={index}
      className="rr-scoring-matrix-cell-title"
      data-testid="rr-scoring-matrix-column"
    >
      {!editingMode && <h5>{column.title}</h5>}
      {editingMode && (
        <div className="rr-scoring-matrix-cell-title-edit">
          <input
            type="text"
            value={column.title}
            onChange={(e) => handleColumnTitleChange(index, e)}
          />
          {matrix.scoring_matrix_columns.length > 2 && (
            <Icon
              name="trash"
              color="generalDark"
              size="de"
              onClick={() => handleColumnDelete(index)}
              data-testid="rr-scoring-matrix-column-delete"
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <div
      className={classNames("rr-scoring-matrix", { disabled })}
      data-testid="rr-scoring-matrix"
    >
      {editingMode && (
        <ScoringMatrixRatings
          groupTitle={`${matrix.name} Rating`}
          dataTestID={`${riskType}-risk-ratings`}
          riskType={riskType}
          riskRatings={riskRatings}
          setRiskRatings={setRiskRatings}
          handleEditSubmit={handleEditSubmit}
          setEditingMatrixChanged={setEditingMatrixChanged}
        />
      )}
      <Typography
        className={classNames({ "tw-pt-6": editingMode })}
        label={`${matrix.name} Matrix`}
        size="lg"
        color="generalMidnightDark"
        weight="semiBold"
        data-testid="matrix-name"
      />
      {renderHeader}
      <div className="rr-scoring-matrix-top">
        {renderRowsHeading}
        <div className="rr-scoring-matrix-top-rows">
          {matrix.scoring_matrix_rows.map(renderRows)}
        </div>
      </div>
      <div className="rr-scoring-matrix-bottom">
        <div className="rr-scoring-matrix-bottom-content">
          <div className="rr-scoring-matrix-columns-titles">
            {matrix.scoring_matrix_columns.map(renderColumnsTitles)}
          </div>
          {renderColumnsHeading}
        </div>
      </div>
    </div>
  );
}

ScoringMatrix.defaultProps = {
  disabled: false,
  editingMode: false,
};

export default observer(ScoringMatrix);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const CheckBlue = (props) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.2758 9.08609C4.1127 9.08609 3.9479 9.02375 3.82322 8.89823L0.380275 5.45613C0.130078 5.20593 0.130078 4.80118 0.380275 4.55098C0.630471 4.30078 1.03523 4.30078 1.28542 4.55098L4.2758 7.53965L10.7147 1.10159C10.9649 0.851391 11.3697 0.851391 11.6199 1.10159C11.8701 1.35178 11.8701 1.75654 11.6199 2.00674L4.72837 8.89823C4.6037 9.02375 4.43975 9.08609 4.2758 9.08609Z"
      fill="#4CB2DD"
    />
  </svg>
);

CheckBlue.displayName = "CheckBlueIcon";

export default CheckBlue;

import type { AxiosResponse } from "axios";

import type { BaseRiskArea, RiskArea } from "@/features/risk-assessment";

import api from "../api";

export const RiskAreaApi = {
  async getAll(workspaceId: number | string): Promise<RiskArea[]> {
    const response = await api.get<{ risk_areas: RiskArea[] }>(
      `/workspaces/${workspaceId}/risk_areas`,
    );

    return response.data.risk_areas;
  },
  upsert(
    workspaceId: number | string,
    riskArea: BaseRiskArea,
    riskAreaId?: number,
  ): Promise<RiskArea> {
    return riskAreaId
      ? update(workspaceId, riskAreaId, riskArea)
      : create(workspaceId, riskArea);
  },
};

async function create(
  workspaceId: number | string,
  riskArea: BaseRiskArea,
): Promise<RiskArea> {
  const response = await api.post<
    BaseRiskArea,
    AxiosResponse<{ risk_area: RiskArea }>
  >(`workspaces/${workspaceId}/risk_areas`, riskArea);

  return response.data.risk_area;
}

async function update(
  workspaceId: number | string,
  riskAreaId: number,
  riskArea: BaseRiskArea,
): Promise<RiskArea> {
  const response = await api.put<
    BaseRiskArea,
    AxiosResponse<{ risk_area: RiskArea }>
  >(`workspaces/${workspaceId}/risk_areas/${riskAreaId}`, riskArea);

  return response.data.risk_area;
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Confirm = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM10.2426 16.3137L6 12.0711L7.41421 10.6569L10.2426 13.4853L15.8995 7.82843L17.3137 9.24264L10.2426 16.3137Z"
      fill="#353549"
    />
  </svg>
);

Confirm.displayName = "ConfirmIcon";

export default Confirm;

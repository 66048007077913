import { useMainStore } from "@/contexts/Store";
import { scrollToCustomColumn } from "@/utils/scroll";

import AddColumnButton from "./AddColumnButton";

interface CustomColumnProps {
  sectionTableID?: string;
  subTableID?: string;
}

export function useCustomColumns({
  sectionTableID,
  subTableID = undefined,
}: CustomColumnProps) {
  const mainStore = useMainStore();

  // Variables
  const tableID = subTableID || mainStore.context.tableID;

  // `params`
  // {
  //   "data_type": "com.askthemis.types.v1.long_text"
  //   "display_name": "Field Name",
  //   "is_multiselect": true,
  //   "only_internal_workspace": true
  // }
  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  const createColumn = async (params) => {
    const success = await mainStore.fields.create(tableID, params);

    if (success) {
      scrollToCustomColumn(sectionTableID);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  const renameColumn = async (name, displayName) => {
    await mainStore.fields.updateDisplayName(tableID, name, displayName);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  const deleteColumn = async (name) => {
    await mainStore.fields.delete(tableID, name);
  };

  return {
    deleteColumn,
    renameColumn,
    createColumn,
    AddColumnButton,
  };
}

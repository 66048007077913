import "./assessment-scoring.scss";

import React from "react";

import { MiniTag, Typography } from "@/components/Elements";
import { getDefinitionsFromScores } from "@/features/risk-assessment/utils/scoring-helpers";

import type { AssessmentScoring as Scoring } from "../../../types/assessment";
import type { RiskArea } from "../../../types/risk-area";
import type { RiskMethodology } from "../../../types/risk-methodology";
import RiskAreaScoring from "./RiskAreaScoring";

interface Props {
  scoring: Scoring;
  riskMethodology: RiskMethodology;
  riskAreas: RiskArea[];
  headerText?: string;
}
function AssessmentScoring({
  riskAreas,
  scoring,
  riskMethodology,
  headerText = "Questionnaires",
}: Props) {
  const {
    overallRiskRating,
    controlRiskRating,
    inherentRiskRating,
    residualRiskRating,
  } = getDefinitionsFromScores(scoring, riskMethodology);

  return (
    <div className="assessment-scoring">
      <Typography
        label={`${headerText} Scoring`}
        color="generalMidnightDark"
        size="lg"
        weight="bold"
        className="assessment-scoring__header"
      />
      <Typography
        label="Overall Scoring"
        color="generalMidnightDark"
        weight="semiBold"
        className="assessment-scoring__overall-scoring-text"
      />
      <div className="assessment-scoring__overall-scoring">
        {overallRiskRating && (
          <div>
            <MiniTag
              style={{
                border: "none",
                backgroundColor: overallRiskRating.color,
              }}
              active
              className="assessment-scoring__score"
              label={overallRiskRating.text}
            />
            <Typography
              label={scoring.inherent_risk_score}
              color="generalMidnightDark"
              size="lg"
              weight="semiBold"
            />
            <Typography
              label="Overall Risk Score & Level"
              color="generalMidnightDark"
            />
          </div>
        )}
        {inherentRiskRating && (
          <div>
            <MiniTag
              style={{
                border: "none",
                backgroundColor: inherentRiskRating.color,
              }}
              active
              className="assessment-scoring__score"
              label={inherentRiskRating.text}
            />
            <Typography
              label={scoring.inherent_risk_score}
              color="generalMidnightDark"
              size="lg"
              weight="semiBold"
            />
            <Typography
              label="Inherent Risk Score & Level"
              color="generalMidnightDark"
            />
          </div>
        )}
        {controlRiskRating && (
          <div>
            <MiniTag
              style={{
                border: "none",
                backgroundColor: controlRiskRating.color,
              }}
              active
              className="assessment-scoring__score"
              label={controlRiskRating.text}
            />
            <Typography
              label={scoring.control_risk_score}
              color="generalMidnightDark"
              size="lg"
              weight="semiBold"
            />
            <Typography
              label="Control Risk Score & Level"
              color="generalMidnightDark"
            />
          </div>
        )}
        {residualRiskRating && (
          <div>
            <MiniTag
              style={{
                border: "none",
                backgroundColor: residualRiskRating.color,
              }}
              active
              label={residualRiskRating.text}
            />
            <Typography
              label="Residual Risk Level"
              color="generalMidnightDark"
            />
          </div>
        )}
      </div>
      <RiskAreaScoring
        riskAreas={riskAreas}
        scoring={scoring}
        riskMethodology={riskMethodology}
      />
    </div>
  );
}

export default AssessmentScoring;

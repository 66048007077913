/**
 * turns a params object into a params url string, for example:
 * @example
 * params = {first_name: "Michael", last_name: "Benson"}
 *  objectToParamsString(params) =>
 * 'first_name=Michael&last_name=Benson'
 */
export function objectToParamsString(params: Record<string, string>) {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
}

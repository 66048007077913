import { Droppable } from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import type { Folder } from "@/stores/types/section-tags";

import { Icon } from "../../Elements";
import { getDroppableIdFromSectionId } from "../../helpers/draggable";
import DocumentFolderPermissions from "../shared/DocumentFolderPermissions";
import { FOLDER_COLORS_LIGHT } from "./colors";
import FolderChangeColorForm from "./forms/ChangeColor";
import FolderRenameForm from "./forms/Rename";

interface Props {
  folder: Folder;
  moduleWorkspaceID: number;
  parentFolderID?: number;
}

const FolderCard = ({ moduleWorkspaceID, folder, parentFolderID }: Props) => {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();

  // States
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("menu");

  // Variables
  const contactIconColor = FOLDER_COLORS_LIGHT.includes(folder.color_name)
    ? "generalBlack"
    : "generalWhite";
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { canManageSectionPermissions, canRemoveFolders } =
    mainStore.userPermissions;
  const { workspaceID } = mainStore.context;

  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
    }
  }, [showPopup]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'mode' implicitly has an 'any' type.
  const handleUpdate = async (mode, params) => {
    params.parent_section_tag_id = parentFolderID || undefined;

    try {
      await mainStore.sectionTags.update(moduleWorkspaceID, folder.id, params);
      mainStore.toast.setInfoText(
        mode === "title" ? "Folder renamed!" : "Folder color changed!",
      );
    } catch {
      mainStore.toast.setErrorText(
        mode === "title" ? "Unable to rename" : "Unable to change color",
      );
    } finally {
      setShowPopup(false);
    }
  };

  const handleDelete = async () => {
    await mainStore.sectionTags.delete(moduleWorkspaceID, folder.id);
    setShowPopup(false);
  };

  const handleClick = () => {
    history.push(
      `/workspaces/${workspaceID}/modules/documents/folder/${folder.id}`,
    );
  };

  return (
    <Droppable droppableId={getDroppableIdFromSectionId(folder.id)}>
      {(provided, snapshot) => (
        <div
          className={classNames(
            "folder-card",
            folder.color_name,
            snapshot.isDraggingOver && "droppable",
          )}
          data-testid="folder-card"
          onClick={handleClick}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {snapshot.isDraggingOver && <div className="folder-droppable" />}
          <span className="folder-icon" />
          {folder.user_access_count > 0 && (
            <div className="folder-permissions-container">
              <Icon
                className="folder-permissions-contact-icon"
                name="contact"
                color={contactIconColor}
              />
              <div
                className="folder-permissions-count"
                data-testid="folder-permissions-count"
              >
                {folder.user_access_count}
              </div>
            </div>
          )}
          <span className="folder-card-name" data-testid="folder-card-name">
            {folder.title}
          </span>
          <span className="folder-back-effect" />

          {isCurrentWorkspaceActive && (
            <Popup
              position="bottom right"
              trigger={
                <span
                  className="folder-card-menu-button"
                  data-testid="folder-card-menu-button"
                />
              }
              open={showPopup}
              arrow={false}
              keepTooltipInside
              onOpen={() => setShowPopup(true)}
              onClose={() => setShowPopup(false)}
            >
              {viewPopup === "menu" && (
                <ul
                  className="table-dropdown folder-card-menu"
                  data-testid="folder-card-menu"
                >
                  <li
                    data-testid="rename-menu-option"
                    onClick={() => setViewPopup("rename")}
                  >
                    Rename Folder
                  </li>
                  <li
                    data-testid="change-color-menu-option"
                    onClick={() => setViewPopup("change-color")}
                  >
                    Change Color
                  </li>
                  {canManageSectionPermissions && (
                    <li
                      data-testid="folder-permissions-menu-option"
                      onClick={() => setViewPopup("permissions")}
                    >
                      Permissions
                    </li>
                  )}
                  <hr />
                  {canRemoveFolders && (
                    <li
                      data-testid="delete-menu-option"
                      onClick={() => setViewPopup("delete")}
                    >
                      Delete
                    </li>
                  )}
                </ul>
              )}

              {viewPopup === "rename" && (
                <FolderRenameForm
                  defaultTitle={folder.title}
                  onSubmit={(params) => handleUpdate("title", params)}
                />
              )}

              {viewPopup === "change-color" && (
                <FolderChangeColorForm
                  defaultColor={folder.color_name}
                  onSubmit={(params) => handleUpdate("color", params)}
                />
              )}

              {viewPopup === "permissions" && (
                <DocumentFolderPermissions
                  moduleWorkspaceID={moduleWorkspaceID}
                  objectID={folder.id}
                  selectedDepartmentIDs={folder.department_ids}
                  selectedUserIDs={folder.user_ids}
                  selectableDepartmentIDs={folder.selectable_department_ids}
                  selectableUserIDs={folder.selectable_user_ids}
                />
              )}

              {viewPopup === "delete" && (
                <div
                  className="table-dropdown folder-delete-confirm"
                  data-testid="folder-delete-confirm"
                >
                  <div>Delete Folder</div>
                  <div>
                    Are you sure you want to delete this folder? Users who only
                    got invited to this folder will no longer have access.
                  </div>
                  <div>
                    <button
                      className="confirm"
                      data-testid="delete-confirm-button"
                      onClick={handleDelete}
                    >
                      Yes
                    </button>
                    <button
                      className="cancel"
                      data-testid="delete-cancel-button"
                      onClick={() => setShowPopup(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          )}

          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default observer(FolderCard);

// Keep in sync with: app/javascript/stylesheets/designSystem/_colors.scss

export const COLOR = {
  // General
  generalWhite: "#FFFFFF",
  generalBlack: "#000000",
  generalLightGray: "#F7F7F8",
  generalTableCellGray: "#F5F5FA",
  generalGray: "#E6E6E9",
  generalIconGray: "#E4E4EB",
  generalMidGray: "#E6E6E9",
  generalDarkGray: "#8C8C97",
  generalDuskDark: "#4D4D6A",
  generalMidnightDark: "#353549",
  generalDark: "#1F1F28",
  generalSharpDark: "#070810",
  generalLockedCellOutline: "#D9DBF5",
  generalPanelBackground: "#F5F5FF",
  generalLightBlue: "#6870B4",
  generalNotePad: "#FDD98C",
  generalErrorField: "#FDD98C",
  generalError: "#E63956",

  // Branding
  brandingHighlightViolet: "#6D71F9",
  brandingHoverNavy: "#4347CA",
  brandingDimViolet: "#696FA3",
  brandingBrightViolet: "#E1E2F0",
  brandingHoverViolet: "#F0F0FE",
  brandingHighlightTurquoise: "#4CB2DD",
  brandingDimTurquoise: "#2A96C4",
  brandingBrightTurquoise: "#CBEBF4",
  brandingHoverTurquoise: "#EBF4FD",

  // Logo
  logoC1: "#36B5FF",
  logoC2: "#2F16BC",
  logoC3: "#C078FF",
  logoC4: "#E3AEFF",

  // Accents
  accentsYellow: "#FDBD32",
  accentsOrange: "#FF9F4D",
  accentsSalmon: "#F95B46",
  accentsPink: "#FF87A7",
  accentsPurple: "#791778",
  accentsLavender: "#B88EEE",
  accentsViolet: "#6217C1",
  accentsNavy: "#5C5FC8",
  accentsBlue: "#6D8CF9",
  accentsSkyBlue: "#6EC8F9",

  // Extras
  extrasSkyBlue: "#BEDFFF",
  extrasLightCyan: "#C5EEFA",
  extrasAqua: "#97DAEE",
  extrasDeepSkyBlue: "#39B6EB",
  extrasTeal: "#21A9D3",
  extrasPersianBlue: "#125B9E",
  extrasDeepNavy: "#061DA8",
  extrasDarkNavy: "#3A14A7",
  extrasDarkSky: "#4C50BE",
  extrasLightNavy: "#9B9BED",
  extrasLilac: "#C6C6FF",
  extrasLightBlue: "#C5D2FF",
  extrasPeriwinkle: "#E9EEFF",
  extrasLightViolet: "#E3E3FD",
  extrasHeather: "#E5D3FD",
  extrasBallet: "#FADAF9",
  extrasWine: "#D685D3",
  extrasFuchsia: "#ED21E5",
  extrasUltra: "#E99FFB",
  extrasLightCherry: "#FAE5FF",
  extrasCarmine: "#C00B48",
  extrasPurple: "#4D004C",
  extrasAmber: "#C33802",
  extrasGinger: "#EC5C0F",
  extrasSalmon: "#FF9A8B",
  extrasRuby: "#F8899C",
  extrasFlamingo: "#FFBECA",
  extrasLightFlamingo: "#FDD3DA",
  extrasBlush: "#FDE1DD",
  extrasLightBlush: "#FDECEF",
  extrasPaleGold: "#FBF1DC",
  extrasYellow: "#F8C453",
  extrasGold: "#FDD08C",
  extrasLime: "#EDFDDD",
  extrasGrassGreen: "#AADF75",
  extrasGreen: "#0E8219",
  extrasShamrock: "#30A071",
  extrasMint: "#6CE9B6",
  extrasLightMint: "#DDFDF0",
  extrasMetalGray: "#ACACAF",
  extrasSilver: "#E1E1E3",
  extrasCloudyGray: "#D6D6D6",
  extrasFogGray: "#F5F5F5",
  extrasBlueGrayDarker: "#8080A3",
  extrasSlateBlue: "#6161C3",
  extrasDim300: "#7676BC",
  extrasDarkGunmetal: "#1E1E29",
  extrasBlueLightDark: "#6870B4",
  extrasBlueDark: "#21205F",
  extrasBatteryChargedBlue: "#27aae1",
};

export type ColorName = keyof typeof COLOR;

// Colors
export const THEMIS_COLORS = [
  "generalWhite",
  "generalLightGray",
  "generalTableCellGray",
  "generalGray",
  "generalMidGray",
  "generalDarkGray",
  "generalMidnightDark",
  "generalDark",
  "generalSharpDark",
  "generalLockedCellOutline",
  "generalPanelBackground",
  "generalLightBlue",
  "generalNotePad",
  "generalErrorField",
  "generalError",
  "brandingHighlightViolet",
  "brandingHoverNavy",
  "brandingDimViolet",
  "brandingBrightViolet",
  "brandingHoverViolet",
  "brandingHighlightTurquoise",
  "brandingDimTurquoise",
  "brandingBrightTurquoise",
  "brandingHoverTurquoise",
  "accentsYellow",
  "accentsOrange",
  "accentsSalmon",
  "accentsPink",
  "accentsPurple",
  "accentsLavender",
  "accentsViolet",
  "accentsNavy",
  "accentsBlue",
  "accentsSkyBlue",
  "extrasSkyBlue",
  "extrasLightCyan",
  "extrasAqua",
  "extrasDeepSkyBlue",
  "extrasTeal",
  "extrasPersianBlue",
  "extrasDeepNavy",
  "extrasLightNavy",
  "extrasLilac",
  "extrasLightBlue",
  "extrasPeriwinkle",
  "extrasLightViolet",
  "extrasHeather",
  "extrasBallet",
  "extrasWine",
  "extrasFuchsia",
  "extrasUltra",
  "extrasLightCherry",
  "extrasCarmine",
  "extrasAmber",
  "extrasGinger",
  "extrasSalmon",
  "extrasRuby",
  "extrasFlamingo",
  "extrasLightFlamingo",
  "extrasBlush",
  "extrasLightBlush",
  "extrasPaleGold",
  "extrasGold",
  "extrasLime",
  "extrasGrassGreen",
  "extrasGreen",
  "extrasShamrock",
  "extrasMint",
  "extrasLightMint",
  "extrasMetalGray",
  "extrasSilver",
  "extrasCloudyGray",
  "extrasFogGray",
  "extrasBlueLightDark",
];

export const THEMIS_ICON_SIZES = ["sm", "de", "md", "lg", "xl", "xxl"] as const;
export const THEMIS_ICON_POSITIONS = ["left", "right"] as const;

// Typography
export const THEMIS_TYPOGRAPHY_SIZES = [
  "xxs",
  "xs",
  "sm",
  "de",
  "md",
  "lg",
  "xl",
  "smh",
  "mdh",
  "lgh",
  "xlh",
] as const;
export const THEMIS_TYPOGRAPHY_WEIGHTS = [
  "medium",
  "semiBold",
  "bold",
] as const;
export const THEMIS_TYPOGRAPHY_TAGS = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "span",
  "div",
] as const;

// Button
export const THEMIS_BUTTON_THEMES = [
  "primary",
  "secondary",
  "tertiary",
  "active",
] as const;
export const THEMIS_BUTTON_SIZES = ["sm", "de"] as const;

// TextButton
export const THEMIS_TEXT_BUTTON_THEMES = [
  "default",
  "white",
  "destructive",
] as const;

// Toggle
export const THEMIS_TOGGLE_SIZES = ["xs", "sm", "de"] as const;
export const THEMIS_TOGGLE_LABEL_TYPES = ["text", "tag"] as const;

// IconButton
export const THEMIS_ICON_BUTTON_THEMES = [
  "primary",
  "secondary",
  "tertiary",
] as const;
export const THEMIS_ICON_BUTTON_SIZES = ["sm", "de"] as const;
export const THEMIS_ICON_BUTTON_TYPES = ["default", "narrow"] as const;

// Avatar
export const THEMIS_AVATAR_SIZES = ["sm", "de", "md"] as const;
export const THEMIS_AVATAR_STATUS = [
  "default",
  "approved",
  "denied",
  "contact",
  "disabled",
  "pending",
  "number",
  "deleted",
] as const;

// MiniTag
export const THEMIS_MINITAG_TYPES = ["solid", "outline"] as const;
export const THEMIS_MINITAG_THEMES = [
  "purple",
  "seaBlue",
  "turquoise",
  "lavender",
  "blue",
  "wine",
  "violet",
  "cherry",
  "pink",
  "red",
  "salmon",
  "orange",
  "green",
  "mint",
  "black",
  "gray",
  "navy",
] as const;
export const THEMIS_MINITAG_ICON_POSTIONS = ["left", "right"] as const;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import BrexJsonData from "../../../finra.json";

export interface ProblemProductCodesProps {
  fieldName: string;
  hasErrors: boolean;
  isProductCodes: boolean;
  recordVersionID: number;
  errorMessage?: string;
  hasErrorClass?: string;
  locked?: boolean;
  selectedCode?: number;
  width: number | string;
  dataTestID?: string;
}

function ProblemProductCodes({
  fieldName,
  recordVersionID,
  isProductCodes,
  selectedCode,
  hasErrors,
  errorMessage,
  locked,
  hasErrorClass = "has-errors",
  width,
  dataTestID = "problem-product-codes",
}: ProblemProductCodesProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [query, setQuery] = useState("");

  // Variables
  const codesList = isProductCodes
    ? BrexJsonData.productCodes
    : BrexJsonData.problemCodes;
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  const filteredCodesList = codesList.filter(
    (code) =>
      code.title.toLowerCase().includes(query.toLowerCase()) ||
      code.code?.toString().toLowerCase().includes(query.toLowerCase()),
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleSearch(event) {
    setQuery(event.target.value);
  }

  function handlePopUpClose() {
    setQuery("");
    setShowPopup(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'code' implicitly has an 'any' type.
  function addItemByCode(code) {
    if (selectedCode !== code) {
      mainStore.recordVersions.update({
        fieldName,
        recordVersionID,
        value: mainStore.avroSchemas.serializeValue(fieldName, code),
      });
    }

    setShowPopup(false);
  }

  const selectedCodePresent = typeof selectedCode === "number";
  const triggerClasses = classNames("codes-cells", {
    active: showPopup,
    [hasErrorClass]: hasErrors,
    illumination: selectedCodePresent,
    "locked-cell": locked,
    "pointer-events-none": locked || !hasModuleWriteAccess,
    "table-cell--disabled": !hasModuleWriteAccess,
  });

  const code = codesList.find((elem) => selectedCode === elem.code);
  const codeClasses = classNames("codes-element", {
    "not-available": code?.title === "N/A",
  });

  const renderTrigger = (
    <li className={triggerClasses} style={{ width }} data-testid={dataTestID}>
      <div className="cell-content">
        {errorMessage && <span>{errorMessage}</span>}

        {!errorMessage && !selectedCodePresent && (
          <input
            data-testid="problem-product-codes-input"
            onChange={handleSearch}
            value={query}
            placeholder="- Select -"
            className="codes-cells__input"
          />
        )}

        {!errorMessage && selectedCodePresent && (
          <div className={codeClasses}>
            {code?.code.toString() !== "-1" && `${code?.code} - `}
            {code?.title}
          </div>
        )}
      </div>
    </li>
  );

  return (
    <Popup
      position="bottom left"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      <div className="table-dropdown codes-popup-wrap">
        {filteredCodesList.length > 0 && (
          <ul>
            {filteredCodesList.map((elem) => {
              const notAvailable = classNames({
                "not-available": elem.title === "N/A",
              });
              return (
                <li
                  className={notAvailable}
                  key={elem.id}
                  onClick={() => addItemByCode(elem.code)}
                >
                  {elem.code.toString() !== "-1" && `${elem.code} - `}
                  {elem.title}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </Popup>
  );
}

export default observer(ProblemProductCodes);

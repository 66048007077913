import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Document = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9979 20L6.00006 20L5.99553 4.00003H11.0001V10C11.0001 10.5523 11.4478 11 12.0001 11H18.0001L17.9979 20ZM13.0001 4.00003V9.00003H18L13.0001 4.00003ZM5.99547 2H13.3028C13.5787 2 13.9618 2.15949 14.1533 2.3511L19.649 7.84885C19.8429 8.04276 20 8.42796 20 8.69925V20.0007C20 21.1049 19.1055 22 17.9979 22H6.00213C4.89638 22 4 21.1018 4 20V4.00001C4 2.89544 4.89588 2 5.99547 2Z"
      fill="#353549"
    />
  </svg>
);

Document.displayName = "DocumentIcon";

export default Document;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type { ControlMappingsDetailsQueryResponse } from "../../models/ControlMappingsDetails";

/**
 * @description Control Mappings Details
 * @summary Control Mappings Details
 * @link /control_mappings/details
 */
export async function controlMappingsDetails(
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ControlMappingsDetailsQueryResponse>["data"]> {
  const res = await client<ControlMappingsDetailsQueryResponse>({
    method: "get",
    url: `/control_mappings/details`,
    ...options,
  });
  return res.data;
}

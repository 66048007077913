import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { Icon } from "@/components/Elements";
import type { Section } from "@/stores/types/section-tags";

interface NestedSectionDisplayerProps {
  sections: Section[];
  selectedSectionID: number;
  setSelectedSectionID: (sectionID: number) => void;
  showFolderIcon: boolean;
}

function NestedSectionDisplayer({
  sections,
  selectedSectionID,
  setSelectedSectionID,
  showFolderIcon,
}: NestedSectionDisplayerProps) {
  return (
    <>
      <li
        data-testid="import-workspace-section-block-top-level"
        key="top-level"
        className="import-ws-section-selection"
        onClick={() => {}}
      >
        <div className="creative-block">
          <div className="import-ws-folder-icon-title-block">
            <div />
            <div>
              <h3>Top Level Section</h3>
            </div>
          </div>
          <div />
        </div>
      </li>
      {sections.map((section) => {
        const isSelected = selectedSectionID === section.id;
        const selectedSection = classNames("import-ws-section-selection", {
          "selected-section-import": isSelected,
        });
        return (
          <>
            <li
              data-testid={`import-workspace-section-block-${section.id}`}
              key={section.id}
              className={selectedSection}
              onClick={() => setSelectedSectionID(section.id)}
            >
              <div className="creative-block">
                <div className="import-ws-folder-icon-title-block">
                  <div>{showFolderIcon && <Icon name="folderSolid" />}</div>
                  <div>
                    <h3>{section.title}</h3>
                  </div>
                </div>
                <div>
                  {isSelected && <Icon name="checkBlue" className="check" />}
                </div>
              </div>
            </li>
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            {section.sub_sections.length > 0 && (
              <ul style={{ marginLeft: "10px" }}>
                <NestedSectionDisplayer
                  // @ts-expect-error TS(2322) FIXME: Type 'Section[] | undefined' is not assignable to ... Remove this comment to see the full error message
                  sections={section.sub_sections}
                  selectedSectionID={selectedSectionID}
                  setSelectedSectionID={setSelectedSectionID}
                  showFolderIcon={showFolderIcon}
                />
              </ul>
            )}
          </>
        );
      })}
    </>
  );
}

export default observer(NestedSectionDisplayer);

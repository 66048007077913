export const moduleInfo = [
  {
    identifier: "policy",
    name: "Policies",
    description:
      "Policies set direction, guide and influence decision-making. Policies guide the day-to-day actions and strategies. Policies change infrequently and are usually broad and general.\n\nTogether, policies and procedures guide your organization and reduce the risk of liability. They promote consistency across the organization, both for employees and for customers. Policies and procedures can prevent workplace incidents by putting proper safety measures in place. If an incident does take place, good policies and procedures get operations back to normal at a faster rate.",
    features: [
      "Central repository of all policies",
      "Obtain approvals",
      "Maintain version history",
      "Send attestations to employees",
      "Historical versions of policies",
    ],
    link: "https://help.themis.com/en/articles/7169499-policies",
  },
  {
    identifier: "procedures",
    name: "Procedures",
    description:
      "Procedures describe, step by step, what actions need to be taken. They are updated often as business processes change and are important for training new employees, compliance, process improvement, and auditing. Procedures are usually created within a department and can be separately categorized in Themis.\n\nTogether, policies and procedures guide your organization and reduce the risk of liability. They promote consistency across the organization, both for employees and for customers. Policies and procedures can prevent workplace incidents by putting proper safety measures in place. If an incident does take place, good policies and procedures get operations back to normal at a faster rate.",
    features: [
      "Central repository of all procedures",
      "Obtain approvals",
      "Maintain version history",
      "Send attestations to employees",
      "Historical versions of procedures",
    ],
    link: "https://help.themis.com/en/articles/7169502-procedures",
  },
  {
    identifier: "issue_management",
    name: "Issue Management",
    description:
      "From time to time, banks and fintechs will need to address issues that arise during the course of business. The Issue Management module helps monitor, address, and verify that these issues have been resolved while keeping a historical record of all issues. It is designed to be a control center for issue tracking, including associating risk and controls, collecting supporting evidence, and validating an action plan and resolution.",
    features: [
      "Define issues",
      "Build an action plan including ownership of resolution",
      "Assign risk and controls to issues",
      "Collect evidence for the issue and resolution",
    ],
    link: "https://help.themis.com/en/articles/7169503-issue-management",
  },
  {
    identifier: "marketing",
    name: "Marketing",
    description:
      "The Marketing module is designed to be a central repository of all marketing that needs to be approved by the organization, whether that is internal approvals or approving creative provided by external parties. Instead of managing the back and forth dialogue of marketing approvals over email, all comments are housed in a single location tied to the creative. The marketing module provides the ability to manage version history as changes are made and easily toggle between old and updated creatives.",
    features: [
      "Central repository of all marketing creatives",
      "Improve communication between marketing and legal / compliance teams",
      "Obtain approvals for marketing creative",
      "Maintain a creative version history, historical comments, and approval records on each marketing creative to support compliance",
      "If using a collaborative workspace, improve dialogue across external partners",
    ],
    link: "https://help.themis.com/en/articles/7169505-marketing",
  },
  {
    identifier: "new_product_approval",
    name: "Change Management",
    description:
      "The Change Management module is a designated location to request and receive approval for any changes that are required for items such as ACH Approval Increases, Exceptions, or anything that needs a second layer of approval prior to a change. The Change Management Module is particularly effective for managing change requests that come through on behalf of a partner that needs the Bank/Fintech approval prior to releasing any changes.",
    features: [
      "Central repository of all change requests",
      "Streamline and obtain approvals from necessary parties",
      "Maintain approved record history",
    ],
    link: "https://help.themis.com/en/articles/7169506-change-management",
  },
  {
    identifier: "training",
    name: "Training",
    description:
      'The Training module stores your organization\'s training material, which can be approved for finalization. The Training module also makes sending and tracking who has reviewed your training material simple. Trainings can be sent to users internally or just "contacts" who do not need to sign into the system.',
    features: [
      "Central repository of all training material",
      "Obtain approvals on training material",
      "Send attestations to employees",
      "Historical versions of training",
    ],
    link: "https://help.themis.com/en/articles/7169507-training",
  },
  {
    identifier: "documents",
    name: "Documents",
    description:
      "The Documents module is a hub for storing and collecting documents that don't fall into another module. It is designed as a single spot to collect and store documents that are important to you or your partners, such as legal agreements, financials, and pricing sheets. It allows for the maintenance of all version history, easily viewable in a single repository.",
    features: [
      "Store & collect documents",
      "Approve documents",
      "Maintain version history of documents and approvals",
    ],
    link: "https://help.themis.com/en/articles/7846202-documents",
  },
  {
    identifier: "vendor_due_diligence",
    name: "Vendors",
    description:
      "The Vendors module within Themis is the place to manage your organization's vendors. Vendor details, including contracts, risk assessments, questionnaires, and supplemental documentation, are all stored in a single repository for easy review. Vendor questionnaires can help collect business information, risk governance, use cases, and controls. Leveraging risk assessment templates within the Vendors module allows users to assign a risk to each vendor.",
    features: [
      "Store vendor information: details, contracts, etc.",
      "Create, review, and send questionnaires",
      "Establish risk for each vendor",
      "Collect and store vendor documentation",
    ],
    link: "https://help.themis.com/en/articles/7169509-vendors",
  },
  {
    identifier: "risk_register",
    name: "Risk Register",
    description:
      "The Risk Register module is designed to be a repository of all the potential risks that face the business. These risks could be Privacy Risks, IT Risks, Operational Risks, Personnel Risks, etc. The type and category of risk are customizable.\n\nThe Risk Register module not only allows organizations to keep track of all the associated risks to their business but also to quantify and organize the risks and controls that help address these risks. Customizing and creating a matrix for Inherent Risk and Residual Risk allows for teams to understand the potential impact and recourse if any of these risks were to occur.\n\nBy maintaining a healthy taxonomy and warehouse of all risks the business can face, it is much easier to address problems that arise and ultimately report on these risks when necessary.",
    features: [
      "Define all controls for the organization",
      "Assign key controls",
      "Score control effectiveness",
      "Assign which policies and procedures the controls are associated with",
      "Assign owners of controls",
      "Leverage the Themis control library",
    ],
    link: "https://help.themis.com/en/articles/7169516-risk-register",
  },
  {
    identifier: "complaints",
    name: "Complaints",
    description:
      "The Complaints module is designed to store and collect all complaints that your compliance team needs to review on an ongoing basis. Manage all the details of complaints, such as start date, resolution, and supporting documentation. Verify that the complaints were handled appropriately by the support teams as part of ongoing risk management requirements.",
    features: [
      "Store complaints and details",
      "Validate complaints were handled appropriately",
      "Collect complaints from your 3rd party partners",
    ],
    link: "https://help.themis.com/en/articles/7169510-complaints",
  },
  {
    identifier: "qa_tests_development",
    name: "Monitoring & Testing",
    description:
      "The Monitoring & Testing module is designed to schedule and conduct ongoing evaluations of compliance programs and processes. It is a single hub where users can create tests that validate your or your partner's programs meet necessary requirements. Monitoring & Testing includes a location to track each step of the test, as well as a place to store and categorize findings. Findings that need to be remediated can easily be sent to Issue Management. No longer do users need to handle their testing in disjointed locations and wonder what test is next on the docket.",
    features: [
      "Schedule and conduct ongoing evaluations",
      "Create tests to validate compliance programs",
      "Track steps and store findings",
      "Send findings to Issue Management",
    ],
    link: "https://help.themis.com/en/articles/7169512-monitoring-testing",
  },
  {
    identifier: "audits",
    name: "Audits",
    description:
      "The Audits module is a designated location to store all of the audits the organization has run. Audits can be stored and reviewed for finalized approval/storage, whether those are internal audits or third-party run.",
    features: [
      "Store all audits conducted by the organization",
      "Review audits for approval and storage",
    ],
    link: "https://help.themis.com/en/articles/7169511-audits",
  },
  {
    identifier: "control_mapping",
    name: "Control Mapping",
    description:
      "The Control Mapping module is designed to be a repository of all the operational controls in place to address the risks the organization faces. The module allows organizations to assign key controls, score controls for their effectiveness, and define control classifications. Users can be assigned as owners of these controls to ensure they are up to date and effective.\n\nOperational controls, once set, can be associated directly with the risks in the Risk Register Module, creating a unified approach in Themis to Risk Management for the organization. Themis also maintains a control library that can be easily added to the organization's active controls if they are applicable.",
    features: [
      "Define all controls for the organization",
      "Assign key controls",
      "Score control effectiveness",
      "Assign which policies and procedures the controls are associated to",
      "Assign owners of controls",
      "Leverage the Themis control library",
    ],
    link: "https://help.themis.com/en/articles/7169519-control-mapping",
  },
  {
    identifier: "conflicts_of_interest",
    name: "Conflicts of Interest",
    description:
      "The Conflicts of Interest module is designed to store and collect any conflicts of interest that employees of the organization may need to surface to the compliance and leadership teams internally. Users can submit any conflicts of interest they have through a shareable link that does not require access to the Themis system. Once conflicts are submitted, members of the compliance and leadership teams can review and approve these potential conflicts.\n\nEasily collect conflicts of interest from the organization's employees\nCollect manager and level information to communicate these internally\nApprove conflicts and maintain a record of approval for each conflict.",
    features: [
      "Easily collect conflicts of interest from employees",
      "Collect manager and level information",
      "Approve conflicts and maintain approval records",
    ],
    link: "https://help.themis.com/en/articles/7169513-conflicts-of-interest",
  },
  {
    identifier: "customer_support",
    name: "Customer Support",
    description:
      "The Customer Support module is designed to collect all complaints that the organization receives from customers. The module helps track the progress of resolving customer issues, assign owners to the complaints, and maintain a record of all customer complaints and resolutions.",
    features: [
      "Collect customer complaints",
      "Track progress of complaint resolution",
      "Assign owners to complaints",
      "Maintain a record of all customer complaints and resolutions",
    ],
  },
];

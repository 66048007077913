import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
} from "@themis/ui";
import React from "react";
import { FiMoreVertical } from "react-icons/fi";

export function RiskRegisterActionsCell({
  riskRegisterId,
  onRemove,
}: {
  riskRegisterId: number;
  onRemove: (registerId: number) => void;
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <IconButton
          Icon={FiMoreVertical}
          size="lg"
          color="transparent"
          className="tw-w-[15px]"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="tw-w-[200px]">
        <DropdownMenuItem onClick={() => onRemove(riskRegisterId)}>
          Remove from this grouping
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

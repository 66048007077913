import { lowerCase } from "lodash";
import { observer } from "mobx-react";
import React from "react";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import { Typography } from "../../Elements";
import { formatDate } from "../helpers";

interface Props {
  recordVersion: RecordVersion;
}

const QAReportsTableRow = ({ recordVersion }: Props) => {
  const mainStore = useMainStore();

  const { data, id } = recordVersion;
  const type = mainStore.avroSchemas.valueForField("type", data);
  const testName = mainStore.avroSchemas.valueForField("test_name", data);
  const frequency = mainStore.avroSchemas.valueForField("frequency", data);
  const startDate = mainStore.avroSchemas.valueForField("start_date", data);
  const completionDate = mainStore.avroSchemas.valueForField(
    "target_completion_date",
    data,
  );

  const getFieldData = (fieldName: string, fieldValue: string) => {
    const options = mainStore.fieldOptions.optionsForField(fieldName);
    const selected = options.find(
      (option) => lowerCase(option.name) === lowerCase(fieldValue),
    );
    const colorScheme = selected
      ? mainStore.fieldOptions.findColorScheme(selected.color_scheme_id)
      : null;

    return {
      displayName: selected?.display_name,
      style: colorScheme
        ? {
            border: colorScheme?.border || undefined,
            color: colorScheme?.text_color || undefined,
            background: colorScheme?.background_color || undefined,
          }
        : {},
    };
  };

  const { style: frequencyStyle, displayName: frequencyName } = getFieldData(
    "frequency",
    frequency?.[0],
  );

  return (
    <tr key={id} data-testid="qa-reports-table-row">
      <td>
        <div className="global-id">{recordVersion.global_id}</div>
      </td>
      <td>
        <div className="type-container">
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'typeValue' implicitly has an 'any' type... Remove this comment to see the full error message */}
          {type?.map((typeValue, index) => {
            const { style: typeStyle, displayName: typeName } = getFieldData(
              "type",
              typeValue,
            );
            return (
              <div
                key={index}
                className="selected-option block"
                style={typeStyle}
              >
                {typeName || type}
              </div>
            );
          })}
        </div>
      </td>
      <td>
        <Typography color="generalMidnightDark" label={testName} />
      </td>
      <td>
        <div className="selected-option" style={frequencyStyle}>
          {frequencyName || frequency}
        </div>
      </td>
      <td>
        <Typography color="generalMidnightDark" label={formatDate(startDate)} />
      </td>
      <td>
        <Typography
          color="generalMidnightDark"
          label={formatDate(completionDate)}
        />
      </td>
    </tr>
  );
};

export default observer(QAReportsTableRow);

export const formatDate = (date: Date | string | undefined | null) => {
  if (!date) {
    return "";
  }

  let value = date;
  if (typeof date === "string") {
    value = new Date(date);
  }

  return (value as Date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  });
};

// @ts-expect-error TS(7006) FIXME: Parameter 'conditionFn' implicitly has an 'any' ty... Remove this comment to see the full error message
export const waitForCondition = (conditionFn, interval = 100) =>
  new Promise((resolve) => {
    const checkCondition = () => {
      if (conditionFn()) {
        // @ts-expect-error TS(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
        resolve();
      } else {
        setTimeout(checkCondition, interval);
      }
    };
    checkCondition();
  });

import { zodResolver } from "@hookform/resolvers/zod";
import type { SelectProps } from "@themis/ui";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  TextInput,
  useToast,
} from "@themis/ui";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import type { ApiAuth } from "@/api/gen/models/ApiAuth";
import { useApiAuthCreate, useApiAuthUpdate } from "@/api/queries/apiAuth";
import { StatusTag } from "@/features/website-monitoring/components/StatusTag";

export const STATUS_TYPES: SelectProps["items"] = [
  {
    label: "Active",
    value: "Active",
    Component: ({ value }) => <StatusTag status={value} />,
  },
  {
    label: "Inactive",
    value: "Inactive",
    Component: ({ value }) => <StatusTag status={value} color="grey" />,
  },
];

const formSchema = z.object({
  id: z.number().nullable(),
  company_id: z.number().nullable(),
  api_key: z.string().min(1, "Api Key is required"),
  status: z.string().min(1, "Status is required"),
});

type ApiAuthSchema = z.infer<typeof formSchema>;

function WebMonitoringApiKeyForm({
  defaultValues,
  companyId,
}: {
  defaultValues?: ApiAuth;
  companyId: number;
}) {
  const form = useForm<ApiAuthSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: mapDefaultValuesToForm(defaultValues),
  });

  function mapDefaultValuesToForm(values?: ApiAuth) {
    return {
      id: values?.id || 0,
      company_id: companyId,
      api_key: values?.api_key || "",
      status: values?.status || "",
    };
  }

  const toast = useToast();
  const { mutateAsync: authUpdate } = useApiAuthUpdate({
    id: defaultValues?.id || 0,
    companyId,
    onSuccess: () => {
      toast({ content: "Api Auth saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update result.",
        variant: "error",
      });
    },
  });

  const { mutateAsync: authCreate } = useApiAuthCreate({
    companyId,
    onSuccess: () => {
      toast({ content: "Api Auth saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update result.",
        variant: "error",
      });
    },
  });

  const handleSubmitForm = async (values: Partial<ApiAuthSchema>) => {
    if (!defaultValues) {
      const requestBodyCreate = {
        data: {
          status: values?.status || null,
          api_key: values?.api_key || "",
          company_id: companyId,
        },
      };
      await authCreate(requestBodyCreate);
    } else {
      const requestBody = {
        data: {
          status: values?.status || null,
          api_key: values?.api_key || null,
        },
      };
      await authUpdate(requestBody);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(handleSubmitForm)();
  };

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit}>
        <div className="tw-border-b-1 tw-grid tw-grid-cols-4 tw-gap-x-6 tw-gap-y-3">
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem className="tw-col-span-1 tw-p-2 ">
                <FormLabel>Status</FormLabel>
                <FormControl className="tw-w-40">
                  <Select
                    items={STATUS_TYPES}
                    selected={field.value}
                    onSelect={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            required
            control={form.control}
            name="api_key"
            render={({ field }) => (
              <FormItem className="tw-col-span-3 tw-p-2 tw-text-neutral-500">
                <FormLabel>API Key</FormLabel>
                <FormControl className=" tw-min-w-[500px]">
                  <TextInput {...field} value={field?.value || ""} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="tw-border-b-1 tw-grid ">
          <Button
            className="tw-col-span-2 tw-mt-5 tw-place-self-end"
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
}

export { WebMonitoringApiKeyForm, type ApiAuthSchema };

import type { MiniTagProps } from "@themis/ui";
import { MiniTag } from "@themis/ui";
import React from "react";

import { issueManagementRecordSource } from "@/api";

const SOURCE_COLOR_MAP: Record<string, MiniTagProps["color"]> = {
  [issueManagementRecordSource.compliance]: "turquoise",
  [issueManagementRecordSource.audit]: "pink",
  [issueManagementRecordSource.regulatory]: "yellow",
  [issueManagementRecordSource.internal]: "blue",
  [issueManagementRecordSource.it_incident]: "fuchsia",
} as const;

export const SOURCE_LABEL_MAP: Record<string, string> = {
  [issueManagementRecordSource.compliance]: "Compliance",
  [issueManagementRecordSource.audit]: "Audit",
  [issueManagementRecordSource.regulatory]: "Regulatory",
  [issueManagementRecordSource.internal]: "Internal",
  [issueManagementRecordSource.it_incident]: "IT Incident",
} as const;

export function IssueManagementSourceTag({ source }: { source: string }) {
  return (
    <MiniTag color={SOURCE_COLOR_MAP[source]}>
      {SOURCE_LABEL_MAP[source]}
    </MiniTag>
  );
}

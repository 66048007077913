import { lowerCase } from "lodash";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { tableHeader } from "../../constants";

const TotalCountTiles = ({
  data,
  name,
  useLabelOnly = false,
}: {
  name?: string;
  data: {
    total: number;
    label: string;
    link: string;
  }[];
  useLabelOnly?: boolean;
}) => {
  const history = useHistory();
  const location = useLocation();

  // @ts-expect-error TS(7006) FIXME: Parameter 'link' implicitly has an 'any' type.
  const goToUrl = (link) => {
    history.push(link, { from: location.pathname });
  };
  return (
    <div className="metrics-tiles-container" data-testid="metrics-tiles">
      {data.map((item, index) => (
        <div
          key={index}
          className="metrics-total-tiles"
          onClick={() => goToUrl(item.link)}
          data-testid={`${item.label}-tile`}
        >
          <div
            className="metrics-total"
            data-testid={`${lowerCase(item.label)}-count`}
          >
            {item.total || 0}
          </div>
          <div className="metrics-label">{`${
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            useLabelOnly ? item.label : tableHeader[lowerCase(item.label)]
          } ${name ? lowerCase(name) : ""}`}</div>
        </div>
      ))}
    </div>
  );
};

export default TotalCountTiles;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateProjectMutationRequest,
  CreateProjectMutationResponse,
  CreateProjectPathParams,
} from "../../models/CreateProject";

/**
 * @description Create project
 * @summary Create project
 * @link /companies/:company_id/projects
 */
export async function createProject(
  companyId: CreateProjectPathParams["company_id"],
  data: CreateProjectMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateProjectMutationResponse>["data"]> {
  const res = await client<
    CreateProjectMutationResponse,
    CreateProjectMutationRequest
  >({
    method: "post",
    url: `/companies/${companyId}/projects`,
    data,
    ...options,
  });
  return res.data;
}

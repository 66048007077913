import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

export const FileUploadResetApprovers = ({
  recordVersionID,
  handleNext,
  skipReset = false,
}: {
  recordVersionID: number;
  handleNext: (shouldReset?: boolean) => void;
  skipReset: boolean;
}) => {
  const mainStore = useMainStore();

  const resetAllApprovals = () => {
    if (recordVersionID) {
      mainStore.reviews.delete(recordVersionID, true);
    }
  };

  const onConfirmYes = () => {
    if (skipReset) {
      handleNext(true);
    } else {
      resetAllApprovals();
      handleNext();
    }
  };

  return (
    <div className="table-dropdown">
      <h4>Reset all approvals?</h4>
      <p>
        Are there material changes to the new document that require new
        approvals?
      </p>
      <div className="confirmation">
        <button
          data-testid="replace-file-changes-input-yes"
          onClick={onConfirmYes}
        >
          Yes
        </button>
        <button onClick={() => handleNext()}>No</button>
      </div>
    </div>
  );
};

export default observer(FileUploadResetApprovers);

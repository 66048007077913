export const getRatingColorName = (
  hexColor?: string,
):
  | "green"
  | "yellow"
  | "orange"
  | "pink"
  | "red"
  | "blue"
  | "primary"
  | "grey"
  | "secondary"
  | "lavender"
  | "navy"
  | "purple"
  | "fuchsia"
  | "mint"
  | "turquoise"
  | "black"
  | undefined => {
  switch (hexColor) {
    case "#791779":
      return "fuchsia";
    case "#EB2E4E":
      return "red";
    case "#FF879B":
      return "pink";
    case "#FDB932":
      return "yellow";
    case "#99D959":
      return "green";
    case "#6D8CF9":
      return "blue";
    case "#6161C3":
      return "primary";
    default:
      return "grey";
  }
};

import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  TextInput,
} from "@themis/ui";
import { format, parseISO } from "date-fns";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { type MonitoringGroupAsset } from "@/api";

import {
  ASSET_TYPE,
  STATUS_TYPES,
} from "../../config/monitoringGroupResultStatus";

const formSchema = z.object({
  status: z.string(),
  name: z.string(),
  url: z.string(),
  asset_types: z.string(),
  created_date: z.string(),
});

type MonitoringGroupAssetInfoSchema = z.infer<typeof formSchema>;

function MonitoringGroupAssetInfoForm({
  defaultValues,
}: {
  defaultValues?: MonitoringGroupAsset;
}) {
  const form = useForm<MonitoringGroupAssetInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      status: defaultValues?.asset_status || "",
      name: defaultValues?.asset_name || "",
      url: defaultValues?.data || "",
      asset_types: defaultValues?.asset_type || "",
      created_date: defaultValues?.monitoring_group_asset_created_at
        ? format(
            parseISO(defaultValues?.monitoring_group_asset_created_at),
            "MM/dd/yyyy",
          )
        : "",
    },
  });

  return (
    <Form {...form}>
      <form className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-4">
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status (Generated)</FormLabel>
              <FormControl>
                <Select
                  locked
                  items={STATUS_TYPES}
                  selected={field.value}
                  onSelect={() => {}}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Asset / Job Name</FormLabel>
              <FormControl>
                <TextInput
                  locked
                  className="tw-text-primaryDim-300"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="url"
          render={({ field }) => (
            <FormItem>
              <FormLabel>URL</FormLabel>
              <FormControl>
                <TextInput
                  locked
                  className="tw-text-primaryDim-300"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="asset_types"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Asset Type</FormLabel>
              <FormControl>
                <Select
                  locked
                  className="tw-border-x-0 tw-border-y-0 tw-bg-white"
                  items={ASSET_TYPE}
                  selected={field?.value}
                  onSelect={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div />
      </form>
    </Form>
  );
}

export { MonitoringGroupAssetInfoForm, type MonitoringGroupAssetInfoSchema };

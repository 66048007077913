import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { downloadFile } from "../helpers/AttachmentHelper";
import type { MainStore } from "../Main";

export default class AttachedDocumentsStore {
  mainStore: MainStore;

  // Observable objects
  current = [];
  list = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      current: observable,
      list: observable,

      setCurrent: action,
      setList: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/records/:record_id/attached_documents
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordID' implicitly has an 'any' type.
  async index(recordID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/records/${recordID}/attached_documents`,
        headers: this.mainStore.getHeaders(),
      });

      this.setList(response.data.attached_documents);
    } catch (error) {
      window.console.log(`"AttachedDocuments#index" error ${error}`);
    }
  }

  // GET /api/react/attached_documents/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'attachedDocumentID' implicitly has an '... Remove this comment to see the full error message
  async show(attachedDocumentID) {
    try {
      this.setCurrent({});

      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/attached_documents/${attachedDocumentID}`,
        headers: this.mainStore.getHeaders(),
      });

      const attachedDocument = data.attached_document;
      this.setCurrent(attachedDocument);

      return attachedDocument;
    } catch (error) {
      window.console.log(`"AttachedDocuments#show" error ${error}`);

      return null;
    }
  }

  // POST /api/react/records/:record_id/attached_documents
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordID' implicitly has an 'any' type.
  async create(recordID, params) {
    const data = {
      attached_document: params,
    };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/records/${recordID}/attached_documents`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.index(recordID);
    } catch (error) {
      window.console.log(`"AttachedDocuments#create" error ${error}`);
    }
  }

  // PUT /api/react/record/attached_documents/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async update(id, recordID, params) {
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/attached_documents/${id}`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      this.index(recordID);
    } catch (error) {
      window.console.log(`"AttachedDocuments#update" error ${error}`);
    }
  }

  // GET /api/react/attached_documents/:id/fetch_file_url
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async fetchFileUrl(id) {
    try {
      let response = await legacyApi({
        method: "GET",
        url: `${API_URL}/attached_documents/${id}/file_url`,
        headers: this.mainStore.getHeaders(),
      });

      const { url, filename } = response.data;

      response = await legacyApi({
        method: "GET",
        url,
        responseType: "blob",
      });

      const blob = response.data;
      downloadFile(blob, filename);
    } catch (error) {
      window.console.log(`"AttachedDocuments#fetchFileUrl" error ${error}`);
    }
  }

  // DELETE /api/react/record/attached_documents/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async delete(id, recordID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/attached_documents/${id}`,
        headers: this.mainStore.getHeaders(),
      });

      this.index(recordID);
    } catch (error) {
      window.console.log(`"AttachedDocuments#delete" error ${error}`);
    }
  }

  // GET /api/react/attached_documents/:id/external_file_share_token
  // @ts-expect-error TS(7006) FIXME: Parameter 'attachedDocumentID' implicitly has an '... Remove this comment to see the full error message
  async generateExternalURI(attachedDocumentID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/attached_documents/${attachedDocumentID}/external_file_share_token`,
        headers: this.mainStore.getHeaders(),
      });

      const { token } = data.external_file_share_token;
      const baseURL = `${window.location.origin}${API_URL}`;

      // GET /api/react/shared_blobs/:token
      // const baseURL = `https://680f-178-92-153-58.eu.ngrok.io${API_URL}` // <- for debug on localhost
      return `${baseURL}/shared_blobs/${token}`;
    } catch (error) {
      window.console.log(
        `"AttachedDocuments#generateExternalURI" error ${error}`,
      );

      return null;
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    this.current = value || {};
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setList(value) {
    this.list = value || [];
  }

  // Store Helpers
  cleanup() {
    this.setList([]);
  }
}

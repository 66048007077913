import { LinkButton } from "@themis/ui";
import React from "react";
import { generatePath, useRouteMatch } from "react-router-dom";

import { WEBSITE_MONITORING_ROUTES } from "../pages";

export function ViewButton({
  monitoringGroupAssetId,
}: {
  monitoringGroupAssetId: number;
}) {
  const { url } = useRouteMatch();

  return (
    <LinkButton
      to={generatePath(
        `${url}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildren}`,
        {
          monitoringGroupAssetId,
        },
      )}
      size="lg"
      color="tertiary"
    >
      View
    </LinkButton>
  );
}

export const optionsColorScheme = {
  reminder: {
    text_color: "#FF87A7",
    background_color: "#FDECEF",
  },
  overdue: {
    text_color: "#E63956",
    background_color: "#FDD3DA",
  },
  trigger_column: {
    text_color: "#4CB2DD",
  },
  field_name: {
    text_color: "#4CB2DD",
  },
  default1: {
    text_color: "#21A9D3",
    background_color: "#C5EEFA",
  },
  default2: {
    text_color: "#6D71F9",
    background_color: "#F0F0FE",
  },
  default3: {
    text_color: "#791778",
    background_color: "#FADAF9",
  },
};

import { useQueryClient } from "@tanstack/react-query";
import {
  ConfirmationPopup,
  IconButton,
  LinkIconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiCaretRightBold, PiTrashSimpleBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import type { LinkedFinding } from "@/api";
import { useDeleteFinding } from "@/api/queries/findings";
import { RECORD_VERSION_QUERY_KEYS } from "@/api/queries/recordVersions";
import type { TopLevelModuleIdentifier } from "@/stores/types/module-workspaces-types";

export function PreviewRelatedFindingActionsCell({
  id,
  name,
  url,
  themis_module_identifier,
  isModuleAdded,
}: LinkedFinding & {
  isModuleAdded: (moduleIdentifier: TopLevelModuleIdentifier) => boolean;
}) {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { workspace_id, record_version_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
  }>();

  const { mutate: deleteFinding } = useDeleteFinding({
    workspaceId: Number(workspace_id),
    findingId: id,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: RECORD_VERSION_QUERY_KEYS.detail(Number(record_version_id)),
      });
      toast({
        content: "Related Finding has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove Related Finding!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    deleteFinding();
    setIsConfirmationOpen(false);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  return (
    <>
      {url &&
        isModuleAdded(themis_module_identifier as TopLevelModuleIdentifier) && (
          <LinkIconButton
            aria-label="Edit Related Finding"
            aria-describedby={`related-finding-id-${id}`}
            to={url}
            Icon={PiCaretRightBold}
            size="lg"
            color="tertiary"
          />
        )}
      <span id={`related-finding-id-${id}`} className="tw-hidden">
        Related Finding Name {name}
      </span>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Related Finding"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      >
        <IconButton
          aria-label="Delete Related Finding"
          aria-describedby={`related-finding-id-${id}`}
          Icon={PiTrashSimpleBold}
          disabled={
            !isModuleAdded(themis_module_identifier as TopLevelModuleIdentifier)
          }
          size="lg"
          color="tertiary"
          onClick={handleClickDelete}
        />
      </ConfirmationPopup>
    </>
  );
}

import type { TaskTableColumnKeys } from "../components/table/TasksTable";

export const taskTableColumnKeys: TaskTableColumnKeys = [
  "checkbox",
  "name",
  "assignee_id",
  "collaborator_ids",
  "due_date",
  "status",
  "workspace_id",
  "taskables",
];

export const archivedTaskTableColumnKeys: TaskTableColumnKeys = [
  "name",
  "assignee_id",
  "collaborator_ids",
  "due_date",
  "status",
  "workspace_id",
  "taskables",
];

export const projectTasksTableColumnKeys: TaskTableColumnKeys = [
  "checkbox",
  "name",
  "assignee_id",
  "collaborator_ids",
  "due_date",
  "status",
  "taskables",
];

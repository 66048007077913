import {
  DragDropContext,
  Draggable,
  Droppable,
} from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { arrayMove, getIndexByPosition } from "@/utils/utils";

import menuInactiveIcon from "../../../images/table-image/icon/menu-black.svg";
import menuActiveIcon from "../../../images/table-image/icon/menu.svg";
import { Icon } from "../../Elements";
import { iconForDataType } from "./ModuleTableColumn";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deactivatedFields?: any[];
  isSubModule?: boolean;
  renderContentOnly?: boolean;
}

const TableColumnReorderPopup = ({
  renderContentOnly,
  isSubModule,
  deactivatedFields,
}: Props) => {
  // MobX Store
  const mainStore = useMainStore();

  // Variables
  const { isAdmin, tableID, subModuleTableID } = mainStore.context;
  const fields = mainStore.fields.list || [];
  const currentTableID = isSubModule ? subModuleTableID : tableID;
  // State
  const [showPopup, setShowPopup] = useState(false);

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  function handleToggleHidden(name) {
    mainStore.fields.toggleHidden(
      isSubModule ? subModuleTableID : tableID,
      name,
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderDraggableField = (field) => (
    <Draggable
      key={field.name}
      draggableId={`draggable-${field.name}`}
      index={field.position}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {renderField(field)}
        </div>
      )}
    </Draggable>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderField = (field) => (
    <div className="table-column-row" data-testid="table-column-row">
      <Icon
        name={iconForDataType(field.data_type)}
        color="generalMidnightDark"
      />

      <div title={field.display_name} className="table-column-row-name">
        {field.display_name}
      </div>
      {field.is_required === false && (
        <div
          title={field.is_hidden ? "Show Column" : "Hide Column"}
          data-testid="hide-button"
          onClick={() => handleToggleHidden(field.name)}
        >
          <Icon
            className="hide-icon"
            name={field.is_hidden ? "eyeOff" : "eye"}
            color="generalDark"
          />
        </div>
      )}
    </div>
  );

  const allFields = fields.filter(
    (item) =>
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      !deactivatedFields.includes(item.computed_column_identifier) &&
      item.position > 0,
  );
  const renderFields = allFields
    .filter((item) => !item.is_hidden)
    .map(renderDraggableField);
  const renderHiddenFields = allFields
    .filter((item) => item.is_hidden)
    .map(renderField);
  const hiddenFieldsPresent =
    allFields.filter((item) => item.is_hidden).length > 0;

  // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
  const onDragEnd = async (result) => {
    const { source, destination } = result;
    const sourceIndex = getIndexByPosition(allFields, "position", source.index);
    const destinationIndex = getIndexByPosition(
      allFields,
      "position",
      destination.index,
    );

    const newlyOrderedDecorators = arrayMove(
      allFields,
      sourceIndex,
      destinationIndex,
    );
    const fieldNamesInNewOrder = newlyOrderedDecorators.map(
      (field) => field.name,
    );

    await mainStore.fields.reorder(currentTableID, fieldNamesInNewOrder);
  };

  const renderContent = () => (
    <div
      className="table-dropdown table-column-reorder-popup"
      data-testid="table-column-reorder-popup"
    >
      <div className="reorder-help">
        Drag and drop options below to reorder. (Columns below are showing in
        the order from left to right)
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="table-column-reorder-content"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <div>Visible Columns</div>
              {renderFields}
              {provided.placeholder}
              {hiddenFieldsPresent && (
                <>
                  <br />
                  <div>Hidden Columns</div>
                  {renderHiddenFields}
                </>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );

  if (renderContentOnly) {
    return renderContent();
  }

  return (
    <Popup
      position="bottom left"
      open={showPopup}
      onOpen={() => {
        setShowPopup(true);
      }}
      onClose={() => {
        setShowPopup(false);
      }}
      trigger={
        <button
          className={classNames("table-column-reorder-trigger", {
            active: showPopup,
            hidden: !isAdmin,
          })}
        >
          <img
            src={showPopup ? menuActiveIcon : menuInactiveIcon}
            alt="lightbulb-icon"
          />
        </button>
      }
      keepTooltipInside
      arrow={false}
    >
      {renderContent()}
    </Popup>
  );
};

TableColumnReorderPopup.defaultProps = {
  renderContentOnly: false,
  isSubModule: false,
  deactivatedFields: [],
};

export default TableColumnReorderPopup;

import React from "react";

const Check = (props: { fill?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41359 18.1291C9.16894 18.1291 8.92174 18.0356 8.73473 17.8473L3.57031 12.6842C3.19501 12.3089 3.19501 11.7017 3.57031 11.3265C3.9456 10.9512 4.55273 10.9512 4.92803 11.3265L9.41359 15.8095L19.072 6.15236C19.4473 5.77707 20.0544 5.77707 20.4297 6.15236C20.805 6.52766 20.805 7.13479 20.4297 7.51009L10.0925 17.8473C9.90545 18.0356 9.65952 18.1291 9.41359 18.1291Z"
      fill={props.fill || "#353549"}
    />
  </svg>
);

Check.displayName = "CheckIcon";

export default Check;

import React, { useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";

interface DirectUploadProviderWrapperProps {
  onSuccess: (attachment: { signedId: string; file: File }) => void;
  onError: () => void;
  render: (props: {
    handleUpload: (files: File[]) => void;
    ready: boolean;
    uploads: { id: string; file: File; state: string; progress: number }[];
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }) => React.ReactNode;
}

// Note: Only works with one file at a time, until use case for multiple files is needed
export function DirectUploadProviderWrapper({
  onSuccess,
  onError,
  render,
}: DirectUploadProviderWrapperProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadingFile, setUploadingFile] = useState<File>();

  return (
    <DirectUploadProvider
      onSuccess={(signedIds) => {
        if (uploadingFile) {
          onSuccess({
            signedId: signedIds[0],
            file: uploadingFile,
          });
        }
        setIsOpen(false);
      }}
      onError={() => {
        onError();
        setIsOpen(false);
      }}
      render={({ handleUpload, ready, uploads }) =>
        render({
          handleUpload: (files) => {
            setUploadingFile(files[0]);
            handleUpload(files);
          },
          ready,
          uploads,
          isOpen,
          setIsOpen,
        })
      }
    />
  );
}

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { Training } from "./Training";

export const TRAINING_ROUTES = {
  base: "/trainings",
  details: "/:trainingId",
} as const;

export const TRAINING_LIST_PATH =
  `/workspaces/:workspace_id${TRAINING_ROUTES.base}` as const;

export const TRAINING_DETAILS_PATH =
  `${TRAINING_LIST_PATH}${TRAINING_ROUTES.details}` as const;

export function TrainingRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Training />
      </Route>
    </Switch>
  );
}

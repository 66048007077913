import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const SunSolid = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6129 2.2097L12.7071 2.29289L15.415 5H18C18.5128 5 18.9355 5.38604 18.9933 5.88338L19 6V8.585L21.7071 11.2929C22.0676 11.6534 22.0953 12.2206 21.7903 12.6129L21.7071 12.7071L19 15.414V18C19 18.5128 18.614 18.9355 18.1166 18.9933L18 19H15.414L12.7071 21.7071C12.3466 22.0676 11.7794 22.0953 11.3871 21.7903L11.2929 21.7071L8.585 19H6C5.48716 19 5.06449 18.614 5.00673 18.1166L5 18V15.415L2.29289 12.7071C1.93241 12.3466 1.90468 11.7794 2.2097 11.3871L2.29289 11.2929L5 8.584V6C5 5.48716 5.38604 5.06449 5.88338 5.00673L6 5H8.584L11.2929 2.29289C11.6534 1.93241 12.2206 1.90468 12.6129 2.2097Z"
      fill="#353549"
    />
  </svg>
);

SunSolid.displayName = "SunSolidIcon";

export default SunSolid;

import { find, get, isEqual, keys } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import Drawer from "@/components/Drawer";
import Dropdown from "@/components/Elements/Dropdown/Dropdown";
import MultiDropdown from "@/components/MultiDropdown";
import { useMainStore } from "@/contexts/Store";

import { Button } from "../../../../../Elements";
import { iconForThemisModuleIdentifier } from "../../../../../helpers/iconForThemisModuleIdentifier";
import { nameForThemisModuleIdentifier } from "../../../../../helpers/nameForThemisModuleIdentifier";

export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  mode?: "create" | "edit" | "bulk";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modules?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRoleNameChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionSets?: any[];
  requireInternalWorkspace?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workspaces?: any[];
}

function AssigningDrawer({
  permissionSets,
  modules: accessibleModules,
  initialValues,
  mode,
  requireInternalWorkspace,
  onRoleNameChange,
  onSubmit,
  onClose,
  ...props
}: Props) {
  const mainStore = useMainStore();

  const [selectedPermissionSet, setSelectedPermissionSet] = useState(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);

  const [moduleDropdownError, setModuleDropdownError] = useState("");

  useEffect(() => {
    if (
      moduleDropdownError &&
      (selectedModules.length > 0 ||
        (selectedPermissionSet &&
          // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
          selectedPermissionSet.permission_type === "company"))
    ) {
      setModuleDropdownError("");
    }
  }, [moduleDropdownError, selectedPermissionSet, selectedModules]);

  useEffect(() => {
    if (initialValues) {
      setSelectedPermissionSet(initialValues.permissionSet);
      setSelectedWorkspaces(initialValues.workspaces);
      setSelectedModules(initialValues.modules);
    }
  }, [initialValues]);

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'locked_modules' does not exist on type '... Remove this comment to see the full error message
    if (selectedPermissionSet && selectedPermissionSet.locked_modules) {
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      getPermissionSet(selectedPermissionSet.id);
    }
  }, [selectedPermissionSet]);

  const accessibleWorkspaces = requireInternalWorkspace
    ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      props.workspaces.map((ws) => ({ ...ws, disabled: ws.is_internal }))
    : props.workspaces;

  const isSaveDisabled = useMemo(() => {
    if (
      !selectedPermissionSet ||
      // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
      (selectedPermissionSet.permission_type === "workspace_module" &&
        selectedWorkspaces.length === 0) ||
      // @ts-expect-error TS(2339) FIXME: Property 'locked_modules' does not exist on type '... Remove this comment to see the full error message
      (!selectedPermissionSet.locked_modules && selectedModules.length === 0)
    ) {
      return true;
    }

    if (mode === "edit") {
      if (
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
        (selectedPermissionSet.id === initialValues.permissionSet.id ||
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          selectedPermissionSet.id ===
            initialValues.permissionSet.parent_permission_id) &&
        isEqual(
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          selectedWorkspaces.map((ws) => ws.id).sort(),
          // @ts-expect-error TS(7006) FIXME: Parameter 'ws' implicitly has an 'any' type.
          initialValues.workspaces.map((ws) => ws.id).sort(),
        ) &&
        isEqual(
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          selectedModules.map((module) => module.id).sort(),
          // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
          initialValues.modules.map((module) => module.id).sort(),
        )
      ) {
        return true;
      }
    }

    return false;
  }, [
    selectedPermissionSet,
    selectedWorkspaces,
    selectedModules,
    mode,
    initialValues,
  ]);

  const isSingleWorkspace = useMemo(() => {
    if (
      selectedPermissionSet &&
      // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
      selectedPermissionSet.permission_type === "company"
    ) {
      return false;
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    return accessibleWorkspaces.length === 1;
  }, [selectedPermissionSet, accessibleWorkspaces]);

  const isCompanyPermissionSet =
    selectedPermissionSet &&
    // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
    selectedPermissionSet.permission_type === "company";

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  const getPermissionSet = async (id) => {
    const permissionSet = await mainStore.permissions.getPermissionSet(id);
    const moduleIDs = keys(
      get(
        find(permissionSet.data, (data) => keys(data)[0] === "module"),
        "module.targets",
      ),
    );
    setSelectedModules(
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ id: string; name: any; icon: a... Remove this comment to see the full error message
      moduleIDs.map((moduleID) => ({
        id: moduleID,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        name: nameForThemisModuleIdentifier(moduleID),
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        icon: iconForThemisModuleIdentifier(moduleID),
      })),
    );
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'permissionSet' implicitly has an 'any' ... Remove this comment to see the full error message
  const handleSelectPermissionSet = (permissionSet) => {
    if (permissionSet.permission_type === "company") {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
      setSelectedWorkspaces(accessibleWorkspaces);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    } else if (!isSingleWorkspace || accessibleWorkspaces.length === 1) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
      setSelectedWorkspaces(accessibleWorkspaces);
    }

    if (!permissionSet.locked_modules) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
      setSelectedModules(accessibleModules);
    }

    setSelectedPermissionSet(permissionSet);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onRoleNameChange(permissionSet.name);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaces' implicitly has an 'any' typ... Remove this comment to see the full error message
  const handleSelectWorkspaces = (workspaces, byToggle) => {
    if (workspaces.length === 0) {
      setSelectedModules([]);
    } else if (
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      selectedPermissionSet.permission_type === "workspace_module" &&
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      !selectedPermissionSet.locked_modules &&
      selectedWorkspaces.length === 0
    ) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
      setSelectedModules(accessibleModules);
    }

    if (workspaces.length === 0 && byToggle) {
      setSelectedWorkspaces(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        accessibleWorkspaces.filter((workspace) => workspace.is_internal),
      );
    } else {
      setSelectedWorkspaces(workspaces);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'modules' implicitly has an 'any' type.
  const handleSelectModules = (modules) => {
    if (
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      selectedPermissionSet.permission_type === "workspace_module" &&
      modules.length === 0 &&
      !moduleDropdownError
    ) {
      setModuleDropdownError("Missing");
    }

    setSelectedModules(modules);
  };

  const handleSubmit = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onSubmit({
      selectedPermissionSet,
      selectedWorkspaces: isCompanyPermissionSet ? [] : selectedWorkspaces,
      selectedModules,
    });
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onClose();
  };

  return (
    <Drawer title="Select Roles" closeOutsideClick={false} onClose={onClose}>
      <div className="roles-sidepanel-content">
        <div className="role-group" data-testid="role-group">
          <div className="role-widget">
            <div className="role-widget-caption">User Role</div>
            <div className="role-widget-content">
              <Dropdown
                placeholder="Select Role"
                popupClassName="select-user-role-scrollable"
                options={permissionSets}
                // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | Dr... Remove this comment to see the full error message
                selected={selectedPermissionSet}
                onChange={handleSelectPermissionSet}
              />
            </div>
          </div>

          <div className="role-widget">
            <div className="role-widget-caption">
              {isSingleWorkspace ? "Workspace" : "Accessible Workspaces"}
            </div>
            <div className="role-widget-content">
              {isSingleWorkspace ? (
                <Dropdown
                  placeholder="Select Workspace"
                  options={accessibleWorkspaces}
                  disabled={
                    mode === "edit" ||
                    !selectedPermissionSet ||
                    // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
                    selectedPermissionSet.permission_type === "company" ||
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    accessibleWorkspaces.length === 1
                  }
                  selected={selectedWorkspaces[0]}
                  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
                  onChange={(workspace) => handleSelectWorkspaces([workspace])}
                />
              ) : (
                <MultiDropdown
                  placeholder={
                    isCompanyPermissionSet
                      ? "All Workspaces available"
                      : "Select Workspaces"
                  }
                  disabled={
                    !selectedPermissionSet ||
                    // @ts-expect-error TS(2339) FIXME: Property 'permission_type' does not exist on type ... Remove this comment to see the full error message
                    selectedPermissionSet.permission_type === "company" ||
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    accessibleWorkspaces.length === 1
                  }
                  options={accessibleWorkspaces}
                  selected={isCompanyPermissionSet ? [] : selectedWorkspaces}
                  unit="Workspace"
                  onChange={handleSelectWorkspaces}
                />
              )}
            </div>
          </div>

          <div className="role-widget">
            <div className="role-widget-caption">Accessible Modules</div>
            <div className="role-widget-content">
              <MultiDropdown
                placeholder={
                  (selectedPermissionSet &&
                    // @ts-expect-error TS(2339) FIXME: Property 'locked_modules' does not exist on type '... Remove this comment to see the full error message
                    selectedPermissionSet.locked_modules &&
                    selectedModules.length === 0) ||
                  (mode === "edit" &&
                    selectedPermissionSet &&
                    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
                    selectedPermissionSet.name === "Workspace Admin")
                    ? "All Modules available"
                    : "Select Modules"
                }
                disabled={
                  !selectedPermissionSet ||
                  selectedWorkspaces.length === 0 ||
                  // @ts-expect-error TS(2339) FIXME: Property 'locked_modules' does not exist on type '... Remove this comment to see the full error message
                  selectedPermissionSet.locked_modules ||
                  (mode === "edit" &&
                    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
                    selectedPermissionSet.name === "Workspace Admin")
                }
                error={moduleDropdownError}
                options={accessibleModules}
                selected={selectedModules}
                unit="Module"
                onChange={handleSelectModules}
              />
              {moduleDropdownError && (
                <div
                  className="roles-sidepanel-module-error"
                  data-testid="module-select-error-message"
                >
                  This user does not have any assigned modules, they will not
                  see anything once logged into Themis. Please assign at least 1
                  module to them.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="roles-sidepanel-actions">
        <Button
          label="Save"
          disabled={isSaveDisabled}
          data-testid="roles-sidepanel-save-button"
          onClick={handleSubmit}
        />
      </div>
    </Drawer>
  );
}

AssigningDrawer.defaultProps = {
  workspaces: [],
  modules: [],
  permissionSets: [],
  mode: "bulk",
  requireInternalWorkspace: false,
  onRoleNameChange: () => {},
  onClose: () => {},
  onSubmit: () => {},
};

export default AssigningDrawer;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Attachment = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8908 2.68938C13.1433 0.436874 16.7953 0.436874 19.0478 2.68938C21.3003 4.94188 21.3003 8.59391 19.0478 10.8464L12.5222 17.3721C11.1707 18.7236 8.97945 18.7236 7.62795 17.3721C6.27644 16.0205 6.27644 13.8293 7.62795 12.4778L14.1536 5.9522C14.6041 5.50169 15.3345 5.50169 15.785 5.9522C16.2355 6.4027 16.2355 7.1331 15.785 7.5836L9.25936 14.1092L9.16971 14.2099C8.81115 14.6627 8.84103 15.3223 9.25936 15.7406C9.67768 16.159 10.3373 16.1888 10.7901 15.8303L10.8908 15.7406L17.4164 9.21501C18.7679 7.86351 18.7679 5.67229 17.4164 4.32079C16.0649 2.96928 13.8737 2.96928 12.5222 4.32079L5.99654 10.8464C3.74403 13.0989 3.74403 16.751 5.99654 19.0035C8.18977 21.1967 11.7098 21.2544 13.9729 19.1766L14.1536 19.0035L20.6792 12.4778C21.1297 12.0273 21.8601 12.0273 22.3106 12.4778C22.7611 12.9283 22.7611 13.6587 22.3106 14.1092L15.785 20.6349C12.6315 23.7884 7.51864 23.7884 4.36513 20.6349C1.21162 17.4814 1.21162 12.3685 4.36513 9.21501L10.8908 2.68938Z"
      fill="#353549"
    />
  </svg>
);

Attachment.displayName = "AttachmentIcon";

export default Attachment;

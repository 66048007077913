import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateTaskableMutationRequest,
  CreateTaskableMutationResponse,
  CreateTaskablePathParams,
} from "../../models/CreateTaskable";

/**
 * @description Creates a new taskable for a task.
 * @summary Create a taskable
 * @link /tasks/:task_id/taskables
 */
export async function createTaskable(
  taskId: CreateTaskablePathParams["task_id"],
  data: CreateTaskableMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateTaskableMutationResponse>["data"]> {
  const res = await client<
    CreateTaskableMutationResponse,
    CreateTaskableMutationRequest
  >({
    method: "post",
    url: `/tasks/${taskId}/taskables`,
    data,
    ...options,
  });
  return res.data;
}

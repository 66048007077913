import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import plusBlack from "../../../../images/table-image/icon/plus-black.svg";

interface Props {
  fieldName: string;
  hasErrors: boolean;
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions: any[];
  width: number | string;
  dataTestId?: string;
  errorMessage?: string;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
}

function ProductsSelect({
  fieldName,
  recordVersionID,
  selectedOptions,
  width,
  hasErrors,
  errorMessage,
  locked,
  hasErrorClass,
  dataTestId,
  pinned,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  // Refs
  const titleInputRef = useRef();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selectedOptions);
  const [addNew, setAddNew] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const { company } = mainStore.companies;
  const { products } = mainStore.products;

  // Effects
  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (addNew) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      titleInputRef.current.focus();
    }
  }, [addNew]);

  const refreshProducts = () => {
    if (company?.id) {
      mainStore.products.index(company.id);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createNewProduct();
    }
  };

  const createNewProduct = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'undefined... Remove this comment to see the full error message
    const { value } = titleInputRef.current;
    if (value === 0) {
      return;
    }
    const params = { title: value };

    if (company && company.id) {
      mainStore.products.create(company.id, params);
    }

    setAddNew(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const addItem = (item) => {
    setSelectedItems([item]);
    setShowPopup(false);
  };

  const onClose = () => {
    if (selectedItems !== selectedOptions) {
      mainStore.recordVersions.update({
        fieldName,
        recordVersionID,
        value: mainStore.avroSchemas.serializeValue(fieldName, selectedItems),
      });
    }

    setAddNew(false);
  };

  const handlePopUpOpen = () => {
    refreshProducts();
    setShowPopup(true);
    setIsSelectModalOpen(true);
  };

  const handlePopUpClose = () => {
    onClose();
    setShowPopup(false);
    setIsSelectModalOpen(false);
  };

  const liClassNames = classNames({
    active: isSelectModalOpen,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isReadOnly,
    "table-cell--disabled": isReadOnly,
    pinned,
  });

  const renderTrigger = (
    <li className={liClassNames} style={{ width }} data-testid={dataTestId}>
      <div className="cell-content">
        {errorMessage ? (
          <span>{errorMessage}</span>
        ) : (
          <div className="options">
            {products
              // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
              ?.filter((product) => selectedOptions.includes(product.id))
              .map((product) => (
                // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                <div className="options" key={product.id}>
                  <span className="value value-product">
                    {/* @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'. */}
                    {product.title}
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>
    </li>
  );

  const renderContent = (
    <div className="options-dropdown" data-testid={`${dataTestId}-options`}>
      <div className="options-dropdown-list">
        <ul>
          <div>
            {products.length === 0 && (
              <h4 className="no-result">No result found</h4>
            )}

            {products.map((product) => (
              <li
                // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                key={product.id}
                className={classNames({
                  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                  active: selectedItems.includes(product.id),
                })}
                onClick={() => {
                  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                  addItem(product.id);
                }}
                data-testid="product-select-li"
              >
                {/* @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'. */}
                <span className="item">{product.title}</span>
              </li>
            ))}
          </div>
        </ul>
        {mainStore.userPermissions.canManageTags && (
          <div className="option-link">
            {addNew && (
              <div className="option-link-element option-link-form">
                <input
                  type="text"
                  placeholder="Add New +"
                  // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
                  ref={titleInputRef}
                  onKeyDown={handleKeyDown}
                />
                <button onClick={createNewProduct}>
                  <img src={plusBlack} alt="plus-black" />
                </button>
              </div>
            )}

            {!addNew && (
              <button
                className="option-link-element option-add-new"
                onClick={() => {
                  setAddNew(true);
                }}
              >
                Add New +
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      {renderContent}
    </Popup>
  );
}

ProductsSelect.defaultProps = {
  hasErrors: false,
  hasErrorClass: "has-errors",
  dataTestId: "product-select-input",
};

export default observer(ProductsSelect);

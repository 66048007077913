import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import WebMonitoringApiKey from "@/components/settings/integrations/api-auth/WebMonitoringApiKey";
import SettingsIntegrations from "@/components/settings/integrations/settings-integrations";
import { useMainStore } from "@/contexts/Store";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import CompanyAllUserList from "./CompanyAllUserList";
import CompanyContacts from "./CompanyContacts";
import CompanyHeader from "./CompanyHeader";
import CompanyInfo from "./CompanyInfo";
import CompanyInternalUserList from "./CompanyInternalUserList";
import CompanyUserDetails from "./CompanyUserDetails";
import Configuration from "./configuration/Configuration";
import FigmaIntegrationAuth from "./FigmaIntegrationAuth";
import GoogleIntegrationAuth from "./GoogleIntegrationAuth";
import Modules from "./Modules";
import PermissionDetail from "./Permissions/PermissionDetail";
import PermissionList from "./Permissions/PermissionList";
import WorkspaceCustomIcon from "./WorkspaceCustomIcon";
import Workspaces from "./Workspaces";

function CompanySettingsRoot() {
  const mainStore = useMainStore();

  // Hooks
  const isPermissionsDetailPageMatch = useRouteMatch("/settings/roles/:id");

  // Variables
  const { isIW, isCompanySuperAdmin, isCompanyAdmin } = mainStore.context;
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { loadingWorkspacePermissions, canManageSettings } =
    mainStore.userPermissions;
  const websiteMonitoringFeatureEnabled =
    isCurrentWorkspaceActive &&
    isIW &&
    mainStore.featureFlags.getIsEnabled(FEATURE_FLAG_ID.COM_WEBSITE_MONITORING);

  return (
    <div className="dashboard-content">
      {!isPermissionsDetailPageMatch && <CompanyHeader />}

      {!loadingWorkspacePermissions && (
        <div className="dashboard-content-wrap">
          <Switch>
            {canManageSettings &&
              (isCompanySuperAdmin || isCompanyAdmin) &&
              isIW && (
                <Route exact path="/settings/users">
                  <CompanyAllUserList />
                </Route>
              )}
            {canManageSettings && isIW && (
              <Route exact path="/settings/users-internal">
                <CompanyInternalUserList />
              </Route>
            )}
            {canManageSettings && isIW && (
              <Route exact path="/settings/users/:user_id">
                <CompanyUserDetails />
              </Route>
            )}

            {canManageSettings && !isIW && (
              <Route exact path="/settings/users">
                <CompanyInternalUserList />
              </Route>
            )}
            {canManageSettings && !isIW && (
              <Route exact path="/settings/users/:user_id">
                <CompanyUserDetails />
              </Route>
            )}

            <Route path="/settings/integrations/google/oauth/receive_redirect">
              <GoogleIntegrationAuth />
            </Route>

            <Route path="/settings/integrations/figma/oauth/receive_redirect">
              <FigmaIntegrationAuth />
            </Route>

            {canManageSettings && (
              <Route path="/settings/contacts">
                <CompanyContacts />
              </Route>
            )}
            {canManageSettings && (
              <Route path="/settings/workspace">
                <Workspaces />
              </Route>
            )}
            {canManageSettings && (
              <Route path="/settings/company-info">
                <CompanyInfo />
              </Route>
            )}
            {canManageSettings && (
              <Route path="/settings/roles/:id">
                <PermissionDetail />
              </Route>
            )}
            {canManageSettings && (
              <Route path="/settings/roles">
                <PermissionList />
              </Route>
            )}
            {canManageSettings && (
              <Route path="/settings/modules">
                <Modules />
              </Route>
            )}
            {canManageSettings && (
              <Route path="/settings/workspace-custom-icon">
                <WorkspaceCustomIcon />
              </Route>
            )}
            {canManageSettings && (
              <Route path="/settings/configuration">
                <Configuration />
              </Route>
            )}
            {canManageSettings && isCurrentWorkspaceActive && (
              <Route path="/settings/integrations">
                <SettingsIntegrations />
              </Route>
            )}
            {canManageSettings && websiteMonitoringFeatureEnabled && (
              <Route path="/settings/api_auth">
                <WebMonitoringApiKey />
              </Route>
            )}
            {canManageSettings && (
              <Redirect
                to={isIW ? "/settings/users-internal" : "/settings/users"}
              />
            )}
          </Switch>
        </div>
      )}
    </div>
  );
}

export default observer(CompanySettingsRoot);

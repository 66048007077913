import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import PublishFlow from "../shared/PublishFlow";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import Unlock from "../shared/Unlock";

interface Props {
  recordVersion: RecordVersion;
  isClosed?: boolean;
  withoutBackground?: boolean;
}

function IssueManagementContextMenu({
  recordVersion,
  isClosed,
  withoutBackground,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const isIssueManagementDetailView = useMemo(() => {
    const issueManagementDetailPageRe = /^\/modules\/issue-management\/\d+$/;
    const matches = location.pathname.match(issueManagementDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  // Variables
  const { workspaceID, isIW: isInternalWorkspace } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("issue_management", moduleWorkspaces, true);
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { taskDetail } = mainStore;

  // Functions
  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  async function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.issueManagement.delete(recordVersion.id);
      mainStore.toast.setText("Issue deleted!");

      if (isIssueManagementDetailView) {
        history.push(`/workspaces/${workspaceID}/modules/issue-management/all`);
      }
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsDeleteConfirmationOpen(false);
    setIsOpenModal(false);
  }
  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  function reopen() {
    mainStore.issueManagement.reopen(recordVersion.id);
  }

  function viewIssueDetail() {
    history.push(
      `/workspaces/${workspaceID}/modules/issue-management/${recordVersion.id}`,
    );
  }

  function closeSlideMenu() {
    setShowSlideMenu(false);
  }

  function openSendSlideMenu() {
    setShowSlideMenu(true);
    setIsOpenModal(false);
  }

  // eslint-disable-next-line no-nested-ternary
  const LockOrUnlockButton = hasModuleWriteAccess ? (
    isClosed ? (
      <Unlock
        onYes={() => reopen()}
        tableName="Default"
        moduleIdentifier="issue_management"
        buttonText="Reopen"
      />
    ) : (
      <PublishFlow
        recordVersionID={recordVersion.id}
        moduleIdentifier="issue_management"
        moduleStore={mainStore.issueManagement}
        buttonText="Close"
      />
    )
  ) : null;

  return (
    <div
      className={classNames("list-points", {
        "without-background im-action-bar-buttons": withoutBackground,
      })}
    >
      {LockOrUnlockButton}

      <Popup
        position="bottom right"
        trigger={
          <IconButton
            variant="vertical"
            color="transparent"
            size="md"
            Icon={PiDotsThreeOutlineVerticalFill}
            data-testid="points-button-trigger"
            data-tooltip-id="tooltip"
            data-tooltip-content="More Options"
            data-tooltip-place="bottom"
            className={classNames({
              "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
                isOpenModal,
            })}
          />
        }
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && (
          <div className="table-dropdown policy-dropdown">
            <ul>
              {!isIssueManagementDetailView && (
                <li onClick={viewIssueDetail} data-testid="view-detail">
                  View {recordName} Detail
                </li>
              )}
              {isInternalWorkspace && hasModuleWriteAccess && (
                <ShareToCW
                  recordName={recordName}
                  sendTrigger={openSendSlideMenu}
                />
              )}

              <li onClick={handleCreateTask}>Create Task</li>

              {canDeleteRecords && (
                <>
                  <hr />
                  <li
                    data-testid="issue-management-delete-trigger"
                    onClick={deleteRowConfirmation}
                  >
                    Delete
                  </li>
                </>
              )}
            </ul>
          </div>
        )}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            <h4>Delete Issue</h4>
            <p>
              Are you sure you want to delete this issue? This is not
              reversible.
            </p>
            <div className="confirmation">
              <button onClick={deleteRow}>Yes</button>
              <button onClick={noButtonPressed}>No</button>
            </div>
          </div>
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        <SendRecordVersion
          recordVersionID={recordVersion.id}
          recordName={recordName}
        />
      </SlideMenu>
    </div>
  );
}

IssueManagementContextMenu.defaultProps = {
  withoutBackground: false,
  showOnlyIcon: true,
};

export default observer(IssueManagementContextMenu);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupsPathParams,
  WebsiteMonitoringGroupsQueryResponse,
} from "../../models/WebsiteMonitoringGroups";

/**
 * @description List Website Monitoring - Groups
 * @summary List Website Monitoring
 * @link /companies/:company_id/website_monitoring/monitoring_groups
 */
export async function websiteMonitoringGroups(
  companyId: WebsiteMonitoringGroupsPathParams["company_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<WebsiteMonitoringGroupsQueryResponse>["data"]> {
  const res = await client<WebsiteMonitoringGroupsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/website_monitoring/monitoring_groups`,
    ...options,
  });
  return res.data;
}

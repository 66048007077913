import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import { RISK_RATINGS_COLORS } from "@/components/table/vendor-due-diligence/constants";
import { useMainStore } from "@/contexts/Store";
import { calculateRiskRating } from "@/features/risk-assessment/utils/scoring-helpers";

import ModuleTableColumn from "../shared/ModuleTableColumn";
import ViewLink from "../shared/ViewLink";
import RiskAssessmentContextMenu from "./RiskAssessmentContextMenu";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  riskAssessments?: any; // TODO: PropTypes.instanceOf(Array)
  vendorID?: string;
}

function RiskAssessmentsTable({ riskAssessments, vendorID }: Props) {
  const mainStore = useMainStore();

  // Variables
  const { currentVendor } = mainStore.vendors;
  const { workspaceID } = mainStore.context;
  const status = mainStore.avroSchemas.valueForField(
    "status",
    currentVendor?.data,
  );
  const isVendorActive = status !== "disabled";
  const history = useHistory();

  const fields = [
    {
      id: 1,
      width: 682,
      data_type: "com.askthemis.types.v1.text",
      display_name: "Name",
      name: "name",
      is_user_editable: false,
    },
    {
      id: 2,
      width: 200,
      data_type: "com.askthemis.types.v1.text",
      display_name: "Risk Rating",
      name: "risk_rating",
      is_user_editable: false,
    },
    {
      id: 3,
      width: 200,
      data_type: "com.askthemis.types.v1.text",
      display_name: "Risk Rating Score",
      name: "risk_rating_score",
      is_user_editable: false,
    },
    {
      id: 4,
      width: 200,
      data_type: "com.askthemis.types.v1.text",
      display_name: "Risk Rating Method",
      name: "risk_rating_method",
      is_user_editable: false,
    },
    {
      id: 5,
      width: 200,
      data_type: "com.askthemis.types.v1.integer",
      display_name: "Themes / Categories",
      name: "themes_categories",
      is_user_editable: false,
    },
  ];

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessment' implicitly has an 'any'... Remove this comment to see the full error message
  function getRiskRating(riskAssessment) {
    let correspondingLevel = null;
    if (riskAssessment.rating_calculation_type === "auto") {
      ({ correspondingLevel } = calculateRiskRating(riskAssessment));
    } else {
      correspondingLevel = riskAssessment.risk_assessment_ratings.find(
        // @ts-expect-error TS(7006) FIXME: Parameter 'r' implicitly has an 'any' type.
        (r) => r.active,
      );
    }

    if (!correspondingLevel) {
      return "";
    }
    const { color_index: colorIndex, name: ratingName } = correspondingLevel;

    return (
      <div className="options">
        <span
          className="value value-type"
          style={{ ...RISK_RATINGS_COLORS[colorIndex] }}
        >
          {ratingName}
        </span>
      </div>
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessment' implicitly has an 'any'... Remove this comment to see the full error message
  function getRiskRatingScore(riskAssessment) {
    if (riskAssessment.rating_calculation_type !== "auto") {
      return "";
    }

    const { score } = calculateRiskRating(riskAssessment);
    return score || "";
  }

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'columnTitle' implicitly has an 'any' ty... Remove this comment to see the full error message
  function getCellValue(columnTitle, riskAssessment) {
    if (columnTitle === "Name") {
      return riskAssessment.name;
    }
    if (columnTitle === "Risk Rating") {
      return getRiskRating(riskAssessment);
    }
    if (columnTitle === "Risk Rating Score") {
      return getRiskRatingScore(riskAssessment);
    }
    if (columnTitle === "Risk Rating Method") {
      return `${riskAssessment.rating_calculation_type} Rating`;
    }
    if (columnTitle === "Themes / Categories") {
      return riskAssessment.risk_themes.length;
    }
  }

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessmentId' implicitly has an 'an... Remove this comment to see the full error message
  function handleRiskAssessmentClick(riskAssessmentId) {
    history.push(
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/build_risk_assessment/${riskAssessmentId}/update`,
    );
  }

  // Renderers
  const renderFields = fields.map((field) => (
    <ModuleTableColumn key={field.id} field={field} />
  ));

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderCell = (field, riskAssessment) => {
    const value = getCellValue(field.display_name, riskAssessment);
    const role = `vdd-risk-assessment-cell-${field?.display_name?.replace(
      /\s/g,
      "",
    )}`;

    return (
      <li
        data-testid={role}
        key={field.id}
        onClick={() => handleRiskAssessmentClick(riskAssessment.id)}
        style={{ width: field.width }}
      >
        <div
          className={classNames("cell-content", {
            capitalize: field.name === "risk_rating_method",
          })}
        >
          {value}
        </div>
      </li>
    );
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessment' implicitly has an 'any'... Remove this comment to see the full error message
  const renderActions = (riskAssessment) => (
    <>
      <ViewLink
        url={`/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/build_risk_assessment/${riskAssessment.id}/update`}
        label="Review"
        data-testid="vdd-risk-assessments-button-review"
      />
      <RiskAssessmentContextMenu
        riskAssessmentID={riskAssessment.id}
        name={riskAssessment.name}
        allowDelete={isVendorActive}
        additionalActions={[]}
      />
    </>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'riskAssessment' implicitly has an 'any'... Remove this comment to see the full error message
  const renderRows = riskAssessments.map((riskAssessment) => (
    <div key={riskAssessment.id} className="list-table">
      <ul>
        <div className="list-table-wrap">
          <div className="list-table-block">
            {fields.map((field) => renderCell(field, riskAssessment))}
            <span className="stretch-cell" />
          </div>
          <div
            className="list-points vdd-details"
            data-testid="vdd-risk-assesments-button"
          >
            {renderActions(riskAssessment)}
          </div>
        </div>
      </ul>
    </div>
  ));

  return (
    <div
      className="vdd-templates-wrapper"
      data-testid="vdd-risk-assesments-content"
    >
      <div className="table-list-wrap">
        <h4 className="questionnaires-title">Risk Assessment Created</h4>
        <div className="list-title-table">
          <ul>
            <div className="list-column-wrap">
              <div className="list-title-table-wrap">
                {renderFields}
                <span className="stretch-cell" />
              </div>
              <div className="list-plus">
                <a href="#" />
              </div>
            </div>
          </ul>
        </div>
        {renderRows}
        {isVendorActive && vendorID && (
          <div
            className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
            onClick={() =>
              history.push(
                `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/build_risk_assessment/create`,
              )
            }
            data-testid="add-new-record-button"
          >
            <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
            <div className="tw-neutral-300 tw-text-sm">Add Assessment...</div>
          </div>
        )}
      </div>
    </div>
  );
}

RiskAssessmentsTable.defaultProps = {
  riskAssessments: [],
};

export default observer(RiskAssessmentsTable);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Department = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3C8 1.89543 8.89543 1 10 1H14C15.1046 1 16 1.89543 16 3V7C16 8.10457 15.1046 9 14 9H13.0031L13.001 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H19.0031L19.001 15H20C21.1046 15 22 15.8954 22 17V21C22 22.1046 21.1046 23 20 23H16C14.8954 23 14 22.1046 14 21V17C14 15.8954 14.8954 15 16 15H17.001L17.0031 13H12.0917C12.0612 13.0028 12.0302 13.0042 11.999 13.0041C11.9684 13.0041 11.9382 13.0027 11.9083 13H7.00311L7.00104 15H8C9.10457 15 10 15.8954 10 17V21C10 22.1046 9.10457 23 8 23H4C2.89543 23 2 22.1046 2 21V17C2 15.8954 2.89543 15 4 15H5.00104L5.00311 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H11.001L11.0031 9H10C8.89543 9 8 8.10457 8 7V3ZM12.0037 7C12.0042 7 12.0047 7 12.0052 7L14 7V3H10V7H12.0037ZM5.90829 17H4V21H8V17H6.09173C6.06119 17.0028 6.03024 17.0042 5.99896 17.0042C5.9684 17.0041 5.93816 17.0027 5.90829 17ZM17.9083 17H16V21H20V17H18.0917C18.0612 17.0028 18.0302 17.0042 17.999 17.0042C17.9684 17.0041 17.9382 17.0027 17.9083 17Z"
      fill="#353549"
    />
  </svg>
);

Department.displayName = "DepartmentIcon";

export default Department;

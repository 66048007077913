import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Button } from "../../Elements";
import ConfirmationDialog from "../shared/ConfirmationDialog";

interface Props {
  isNew?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
}

function ResetRiskAssessment({ matrix, isNew }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  async function handleReset() {
    mainStore.vendors.setCurrentRiskAssessment({
      ...matrix,
      // @ts-expect-error TS(7006) FIXME: Parameter 'risk_theme' implicitly has an 'any' typ... Remove this comment to see the full error message
      risk_themes: matrix.risk_themes.map((risk_theme) => ({
        ...risk_theme,
        // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
        risk_descriptions: risk_theme.risk_descriptions.map((item) => ({
          ...item,
          selected: false,
        })),
      })),
    });

    if (!isNew) {
      await mainStore.vendors.resetRiskAssessment(matrix.id);
    }

    onPopupClose();
  }

  const renderTrigger = (
    <div>
      <Button
        disabled={!hasModuleWriteAccess}
        label="Reset"
        size="sm"
        theme="tertiary"
        active={showPopup}
      />
    </div>
  );

  const renderPopupContent = (
    <div className="table-dropdown success-dropdown vdd-risk-assessment__reset-matrix-popup">
      <ConfirmationDialog
        heading="Reset Matrix"
        content="Are you sure you want to reset this current matrix? All selections will be cleared."
        handleConfirm={handleReset}
        handleReject={onPopupClose}
      />
    </div>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      {renderPopupContent}
    </Popup>
  );
}

ResetRiskAssessment.defaultProps = {
  isNew: false,
};

export default observer(ResetRiskAssessment);

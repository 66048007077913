import { map } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import type { ChartProps } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";

import type { RecordVersion } from "@/api";
import { Typography } from "@/components/Elements";
import { COLOR } from "@/config/theme";
import { useMainStore } from "@/contexts/Store";
import emptyDonut from "@/images/home/empty-donut.svg";
import { titleForColor } from "@/stores/helpers/TitleForColorSchemeHelpers";

import innerDonut from "../../../images/inner-donut-shadow.svg";
import { formatFrequency } from "../../settings/notificationsCenter/custom-notifications/data/helpers";
import TileLegendStatusPieChart from "./TileLegendStatusPieChart";

export const DONUT_COLORS = [
  COLOR.extrasSkyBlue,
  COLOR.extrasPersianBlue,
  COLOR.extrasLightNavy,
  COLOR.extrasGrassGreen,
  COLOR.accentsPurple,
  COLOR.accentsPink,
  COLOR.extrasCarmine,
  COLOR.accentsOrange,
  COLOR.accentsSalmon,
  COLOR.extrasFlamingo,
];

interface Props {
  recordVersions: RecordVersion[];
  filtersViewEnabled: boolean;
  setFiltersViewEnabled: (arg: boolean) => void;
}

const StatusPieChart = ({
  recordVersions,
  filtersViewEnabled,
  setFiltersViewEnabled,
}: Props) => {
  const mainStore = useMainStore();
  const { colorSchemes } = mainStore.reports;
  const areRecords = recordVersions.length > 0;

  const listOfStatuses = recordVersions.map((recordVersion) => {
    return mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion?.data,
    );
  });

  const sortListOfStatuses = listOfStatuses
    .filter((value, index, statusArray) => index === statusArray.indexOf(value))
    .sort();

  const OPTIONS = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const DATASET_OPTIONS = {
    borderColor: "white",
    hoverBorderColor: "white",
    cutout: "65%",
    rotation: 0,
    borderWidth: 2,
  };

  const data = map(sortListOfStatuses, (element) => ({
    label: element ? formatFrequency(element) : "",
    count: listOfStatuses.filter((status) => {
      if (status === element) {
        return true;
      }
      return false;
    }).length,
    color: colorSchemes?.find(
      (colorScheme) =>
        titleForColor(colorScheme.title) === titleForColor(element),
    )?.text_color,
  }));

  const dataWithColor = map(data, (elem, index) => ({
    ...elem,
    color: elem.color || DONUT_COLORS[index],
  }));

  const donutData: ChartProps<"doughnut">["data"] = {
    labels: map(dataWithColor, "label"),
    datasets: [
      {
        ...DATASET_OPTIONS,
        animation: false,
        backgroundColor: map(dataWithColor, "color"),
        data: map(dataWithColor, "count"),
      },
    ],
  };

  return areRecords ? (
    <div className="donut-graph">
      <div className="donut-chart-container">
        <Doughnut data={donutData} options={OPTIONS} />
        <img className="inner-donut-shadow" src={innerDonut} />
      </div>
      <TileLegendStatusPieChart
        data={dataWithColor}
        filtersViewEnabled={filtersViewEnabled}
        setFiltersViewEnabled={setFiltersViewEnabled}
      />
    </div>
  ) : (
    <div className="empty-donut-content">
      <img className="empty-donut-image" src={emptyDonut} />
      <Typography
        label="No matching records"
        size="xs"
        weight="medium"
        color="extrasBlueGrayDarker"
        title="No matching records"
        className="empty-donut-message"
      />
    </div>
  );
};

export default observer(StatusPieChart);

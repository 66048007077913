import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Notification = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 20C4.29574 20 3.82474 19.2979 4.05978 18.6586L4.10557 18.5528L5.26099 16.242C5.69839 15.3672 5.94707 14.4115 5.99243 13.4369L6 13.1115V10C6 7.02694 8.16238 4.55893 11.0002 4.08293L11 3C11 2.44772 11.4477 2 12 2C12.5523 2 13 2.44772 13 3L13.0008 4.08309C15.7593 4.54627 17.8794 6.89172 17.995 9.75347L18 10V13.1115C18 14.0895 18.2049 15.0555 18.6002 15.9474L18.739 16.242L19.8944 18.5528C20.2094 19.1827 19.792 19.918 19.1151 19.9936L19 20H14C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20H5ZM12 6C9.85781 6 8.10892 7.68397 8.0049 9.80036L8 10V13.1115C8 14.3922 7.72665 15.6569 7.1998 16.8213L7.04985 17.1364L6.61803 18H17.382L16.9502 17.1364C16.3774 15.9908 16.0563 14.7374 16.0068 13.4603L16 13.1115V10C16 7.79086 14.2091 6 12 6Z"
      fill="#353549"
    />
  </svg>
);

Notification.displayName = "NotificationIcon";

export default Notification;

import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import type { MainStore } from "@/stores/Main";
import type {
  CompanyRiskCategory,
  CompanyRiskCategoryParams,
} from "@/stores/types/key-risk-indicators-types";

export default class KeyRiskIndicatorsCompanyRiskCategoriesStore {
  mainStore: MainStore;

  riskCategories: CompanyRiskCategory[] = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      riskCategories: observable,
      setRiskCategories: action,
    });

    this.mainStore = mainStore;
  }

  async index({ companyID }: { companyID: number }) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}/key_risk_indicators_company_risk_categories`,
        headers: this.mainStore.getHeaders(),
      });

      this.setRiskCategories(response.data);
    } catch (error) {
      window.console.log(
        `"key_risk_indicators#fetchRiskCategories" error ${error}`,
      );
    }
  }

  async create({ companyID, name }: CompanyRiskCategoryParams) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/companies/${companyID}/key_risk_indicators_company_risk_categories`,
        headers: this.mainStore.getHeaders(),
        data: {
          name,
        },
      });
      // @ts-expect-error axios
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response);
        return;
      }
      this.setRiskCategories([...this.riskCategories, response.data]);
    } catch (error) {
      window.console.log(`"key_risk_indicators#create" error ${error}`);
    }
  }

  async destroy({ id, companyID }: { id: number; companyID: number }) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/key_risk_indicators_company_risk_categories/${id}`,
        headers: this.mainStore.getHeaders(),
        params: { company_id: companyID },
      });

      // @ts-expect-error axios
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response);
        return;
      }
      this.setRiskCategories(
        this.riskCategories.filter((category) => category.id !== id),
      );
    } catch (error) {
      window.console.log(`"key_risk_indicators#destroy" error ${error}`);
    }
  }

  setRiskCategories(value: CompanyRiskCategory[]) {
    this.riskCategories = value;
  }
}

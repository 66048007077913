import { observer } from "mobx-react";
import React, { useEffect } from "react";

import mainStore from "@/stores/Main";

function ReviewsCountBadge() {
  useEffect(() => {
    mainStore.reviews.fetchRequestedReviews();
  }, []);

  if (!mainStore.reviews.pendingReviewCount) {
    return null;
  }

  return (
    <span className="tw-h-[15px] tw-rounded-full tw-bg-warning-300 tw-px-1.5 tw-text-center tw-text-[11px] tw-text-white">
      {mainStore.reviews.pendingReviewCount}
    </span>
  );
}

export default observer(ReviewsCountBadge);

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import EditTaskContent from "./components/EditTaskContent";

function EditTask() {
  const mainStore = useMainStore();
  const { task_id } = useParams<{ task_id: string }>();
  const history = useHistory();

  const taskID = Number(task_id);
  const task = mainStore.tasks.allTasks?.find((elem) => elem.id === taskID);

  useEffect(() => {
    if (task?.private && task.module_workspace_id) {
      mainStore.comments.getPrivateUsers(task.module_workspace_id);
    }
  }, [task?.private, task?.module_workspace_id]);

  useEffect(() => {
    if (!mainStore.tasks.allTasks) {
      mainStore.tasks.myTasks();
    }
    return () => {
      mainStore.tasks.cleanup();
      mainStore.comments.cleanup();
    };
  }, []);

  if (
    !mainStore.tasks.allTasks ||
    (task?.private && !mainStore.comments.privateUsers.length)
  ) {
    return <Loading loadingLayout="small-table" showTableHeader={false} />;
  }

  if (!task) {
    history.replace("/home");

    return null;
  }

  return <EditTaskContent task={task} />;
}

export default observer(EditTask);

import themisLogo from "../../images/dashboard/logo-dashboard.svg";
import { workspaceIcons } from "./workspaceIcons";

export const getWorkspaceLogo = (workspace: {
  icon_index: number;
  logo: string | null;
  is_internal: boolean;
}) => {
  const isInternal = workspace.is_internal;
  const icon = workspace.logo
    ? workspace.logo
    : workspaceIcons[workspace.icon_index].url;
  const workspaceLogo = workspace.logo;

  let logo = icon;
  if (isInternal && workspaceLogo) {
    logo = workspaceLogo;
  } else if (isInternal) {
    logo = themisLogo;
  }

  return { logo, isInternal };
};

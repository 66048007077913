import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

import PolicyProcedureContextMenu from "./PolicyProcedureContextMenu";

function PolicyActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();
  const { file_id } = useParams<{
    file_id: string;
  }>();

  // Variables
  const path = location.pathname;
  const policyID = Number(path.substring(path.lastIndexOf("/") + 1));

  const isLibrary =
    file_id && location.pathname.includes("document_library_preview");

  const policy = mainStore.recordVersions.list.find(
    (recordVersion) => recordVersion.id === policyID,
  );
  const moduleWorkspaceID = mainStore.policies.data?.module_workspace_id;

  if (!policy && !isLibrary) {
    return null;
  }

  const approvedByUserIds =
    policy?.reviews?.map((approval) => approval.user_id) || [];

  function downloadFile() {
    if (!isLibrary) {
      return null;
    }
    mainStore.policies.fetchFileUrl(file_id, true);
  }

  return (
    <>
      {policy && (
        <PolicyProcedureContextMenu
          approvedByUserIds={approvedByUserIds}
          moduleWorkspaceID={moduleWorkspaceID}
          recordVersion={policy}
          tableID={policy.table_id}
          tableName={policy.table_name}
          moduleStore={mainStore.policies}
          isPolicy
          withoutBackground
          showOnlyIcon={false}
        />
      )}
      {isLibrary && (
        <button
          className={classNames("library-export-button")}
          data-testid="export-button"
          onClick={downloadFile}
        >
          <Icon
            className="field-icon hover-only"
            name="download"
            color="generalWhite"
          />
          Download
        </button>
      )}
    </>
  );
}

export default observer(PolicyActionBar);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListNotificationsQueryParams,
  ListNotificationsQueryResponse,
} from "../../models/ListNotifications";

/**
 * @description List notifications for frontend
 * @summary List notifications for frontend
 * @link /notifications
 */
export async function listNotifications(
  params?: ListNotificationsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListNotificationsQueryResponse>["data"]> {
  const res = await client<ListNotificationsQueryResponse>({
    method: "get",
    url: `/notifications`,
    params,
    ...options,
  });
  return res.data;
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Clock1 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM12 6C12.5128 6 12.9355 6.38604 12.9933 6.88338L13 7V11.5858L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3466 15.0676 13.7794 15.0953 13.3871 14.7903L13.2929 14.7071L11.2929 12.7071C11.1366 12.5508 11.0374 12.3481 11.0087 12.1314L11 12V7C11 6.44772 11.4477 6 12 6Z"
      fill="#353549"
    />
  </svg>
);

Clock1.displayName = "Clock1Icon";

export default Clock1;

import "./mini-tag.scss";

import classNames from "classnames";
import React from "react";

import { COLOR } from "@/config/theme";

import type { ResidualRisk, RiskRating } from "../../types";

interface MiniTagProps {
  label: string;
  rating?: RiskRating | ResidualRisk;
  color?: string;
  variant?: "default" | "pill";
}

const LABELS: Record<string, string> = {
  finalized: "Completed",
  in_review: "In Review",
  ready_for_review: "Ready For Review",
  in_progress: "In Progress",
  incomplete: "In Progress",
  not_started: "Not Started",
  public: "Public",
  internal: "Internal",
};

const MiniTag = ({
  label,
  rating,
  color,
  variant = "default",
}: MiniTagProps) => {
  let displayText;
  if (label && !rating) {
    displayText = LABELS[label] || label;
  } else if (label && rating) {
    displayText = label;
  }

  return (
    <div
      className={classNames("ra-mini-tag", {
        "ra-mini-tag--completed": LABELS[label] === "Completed",
        "ra-mini-tag--in-review": LABELS[label] === "In Review",
        "ra-mini-tag--ready-for-review": LABELS[label] === "Ready For Review",
        "ra-mini-tag--in-progress": LABELS[label] === "In Progress",
        "ra-mini-tag--not-started": LABELS[label] === "Not Started",
        "ra-mini-tag--internal": LABELS[label] === "Public",
        "ra-mini-tag--public": LABELS[label] === "Internal",
        "ra-mini-tag--rating": !!rating,
        "ra-mini-tag--pill": variant === "pill",
      })}
      {...(rating ? { style: { backgroundColor: rating.color } } : {})}
      {...(color && !rating
        ? { style: { backgroundColor: color, color: COLOR.generalWhite } }
        : {})}
    >
      {displayText}
    </div>
  );
};

export default MiniTag;

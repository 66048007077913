import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const FinraReportContent = () => (
  <ReportsPage
    identifier="finra"
    defaultDateColumn="complaint_date"
    defaultGroupColumn="escalated_status"
    tableName="FINRA Review"
  />
);

export default observer(FinraReportContent);

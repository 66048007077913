import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateNotificationMutationRequest,
  UpdateNotificationMutationResponse,
  UpdateNotificationPathParams,
} from "../../models/UpdateNotification";

/**
 * @description Update notification
 * @summary Update notification
 * @link /notifications/:id
 */
export async function updateNotification(
  id: UpdateNotificationPathParams["id"],
  data: UpdateNotificationMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateNotificationMutationResponse>["data"]> {
  const res = await client<
    UpdateNotificationMutationResponse,
    UpdateNotificationMutationRequest
  >({
    method: "put",
    url: `/notifications/${id}`,
    data,
    ...options,
  });
  return res.data;
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Apple = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 2C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4C14.606 4 13.3696 4.90326 13.0718 6.92867C13.423 6.87684 13.7458 6.79126 14.1109 6.66548L14.3937 6.56351L14.7197 6.4409C15.5463 6.13353 16.1449 6 17 6C20.0546 6 22 7.94663 22 12C22 17.2163 20.1608 22 17 22C15.888 22 15.1044 21.86 14.1748 21.5507L13.8589 21.4412L13.565 21.3349L13.1431 21.189C12.806 21.082 12.5332 21.0252 12.2324 21.0068L12 21C11.5991 21 11.2783 21.0552 10.8569 21.189L10.435 21.3349L10.1411 21.4412C9.06562 21.8271 8.23555 22 7 22C3.83919 22 2 17.2163 2 12C2 7.94663 3.94544 6 7 6C7.85512 6 8.45369 6.13353 9.2803 6.4409L9.60628 6.56351C10.1418 6.76406 10.5647 6.88601 11.0553 6.94825C11.415 3.73398 13.4812 2 17 2ZM17 8C16.4892 8 16.1364 8.06447 15.6394 8.23553L15.4168 8.3155L15.0951 8.43649C14.1398 8.79422 13.3985 8.96724 12.3307 8.99574L12 9C10.7476 9 9.95573 8.82999 8.90492 8.43649L8.58324 8.3155C7.96122 8.0842 7.58378 8 7 8C5.05029 8 4 9.05093 4 12C4 16.1877 5.46573 20 7 20C7.87054 20 8.45036 19.8994 9.18294 19.6566L9.97723 19.3746C10.7086 19.119 11.277 19 12 19L12.3013 19.0073C12.7908 19.0319 13.2246 19.1184 13.7184 19.2734L14.0228 19.3746L14.8171 19.6566C15.5496 19.8994 16.1295 20 17 20C18.5343 20 20 16.1877 20 12C20 9.05093 18.9497 8 17 8Z"
      fill="#353549"
    />
  </svg>
);

Apple.displayName = "AppleIcon";

export default Apple;

import type { Field } from "@/api";

import { formatDateForCellValue } from "../../../helpers/DateFormatters";

interface GenerateDateFilterArgs {
  field?: Field;
  name?: string;
  startDate: Date;
  endDate: Date;
  reverse?: boolean;
}

interface GenerateFilterArgs {
  name: string;
  labels: string[];
  values: string[];
}

export const filterForIsWithinCondition = ({
  field,
  name,
  startDate,
  endDate,
  reverse,
}: GenerateDateFilterArgs) => {
  const start = reverse ? endDate : startDate;
  const end = reverse ? startDate : endDate;

  const startOrEndDateOption = {
    condition: "is within",
    label: "Exact Dates",
    value: formatDateForCellValue(start),
    numOfDays: null,
  };
  const todayOption = {
    condition: "is within",
    label: "Today",
    value: formatDateForCellValue(end),
    numOfDays: null,
  };

  return {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    name: name || field.name,
    condition: "is within",
    options: reverse
      ? [todayOption, startOrEndDateOption]
      : [startOrEndDateOption, todayOption],
  };
};

export const filterForIsCondition = ({
  name,
  labels,
  values,
}: GenerateFilterArgs) => {
  return {
    name,
    condition: "is",
    options: [
      ...labels.map((label, index) => ({
        label,
        value: values[index],
      })),
    ],
  };
};

import { observer } from "mobx-react";
import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import APIKeys from "@/components/settings/api-keys/APIKeys";
import UserIntegrations from "@/components/settings/integrations/user-integrations";
import { useMainStore } from "@/contexts/Store";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import CompanyHeader from "./CompanyHeader";
import CurrentUserSettings from "./CurrentUserSettings";

function UserSettingsRoot() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const isPermissionsDetailPageMatch = useRouteMatch("/settings/roles/:id");

  // Variables
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { loadingWorkspacePermissions } = mainStore.userPermissions;

  const publicApiEnabled =
    isCurrentWorkspaceActive ||
    mainStore.featureFlags.getIsEnabled(FEATURE_FLAG_ID.COM_PUBLIC_API);

  return (
    <div className="dashboard-content">
      {!isPermissionsDetailPageMatch && <CompanyHeader />}

      {!loadingWorkspacePermissions && (
        <div className="dashboard-content-wrap">
          <Switch>
            <Route path="/user/profile">
              <CurrentUserSettings />
            </Route>
            {isCurrentWorkspaceActive && (
              <Route path="/user/integrations">
                <UserIntegrations />
              </Route>
            )}
            {publicApiEnabled && (
              <Route path="/user/api-keys">
                <APIKeys />
              </Route>
            )}
            <Redirect to="/user/profile" />
          </Switch>
        </div>
      )}
    </div>
  );
}

export default observer(UserSettingsRoot);

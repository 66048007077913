import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useLocation, useRouteMatch } from "react-router-dom";
import Popup from "reactjs-popup";

import * as CustomNotificationsAPI from "@/api/legacy/custom-notifications";
import { Button, Flex, IconButton } from "@/components/Elements";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog";
import { useMainStore } from "@/contexts/Store";

function CustomNotificationSaveDeleteButton(props: { isDirty: boolean }) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Location Tracker
  const history = useHistory();
  const location = useLocation();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const hasCreateMatch = Boolean(
    useRouteMatch("/notifications/custom-notifications/create"),
  );
  const customNotificationID = Number(location.pathname.split("/").pop());

  // Functions
  async function handleConfirm() {
    const deleted = await CustomNotificationsAPI.deleteCustomNotification(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      mainStore.context.workspaceID,
      customNotificationID,
    );

    if (deleted) {
      mainStore.toast.setInfoText("The notification was successfully deleted.");
      history.push("/notifications/custom-notifications");
    }

    handlePopUpClose();
  }

  function handlePopUpOpen() {
    setShowPopup(true);
  }

  function handlePopUpClose() {
    setShowPopup(false);
  }

  return (
    <Flex alignCenter columnGap={8}>
      <Button
        size="sm"
        label={hasCreateMatch ? "Save Notification" : "Update Notification"}
        disabled={!props.isDirty}
        type="submit"
      />
      {!hasCreateMatch && (
        <Popup
          position="bottom left"
          trigger={
            <div>
              <IconButton icon="trash" theme="tertiary" />
            </div>
          }
          open={showPopup}
          onOpen={handlePopUpOpen}
          onClose={handlePopUpClose}
          keepTooltipInside
        >
          <div className="table-dropdown success-dropdown back-btn-confirm-popup">
            <ConfirmationDialog
              handleConfirm={handleConfirm}
              handleReject={handlePopUpClose}
              heading="Delete this Notification"
              content={
                "Deleting this notification will lose all the data and you will need to start again if you choose to leave now.\n\nAre you sure you want to proceed?"
              }
            />
          </div>
        </Popup>
      )}
    </Flex>
  );
}

export default observer(CustomNotificationSaveDeleteButton);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Upload = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 16C21.5128 16 21.9355 16.386 21.9933 16.8834L22 17V19C22 20.5977 20.7511 21.9037 19.1763 21.9949L19 22H5C3.40232 22 2.09634 20.7511 2.00509 19.1763L2 19V17C2 16.4477 2.44772 16 3 16C3.51284 16 3.93551 16.386 3.99327 16.8834L4 17V19C4 19.5128 4.38604 19.9355 4.88338 19.9933L5 20H19C19.5128 20 19.9355 19.614 19.9933 19.1166L20 19V17C20 16.4477 20.4477 16 21 16ZM11.8515 2.01102L11.91 2.004L12 2L12.0752 2.00279L12.2007 2.02024L12.3121 2.04974L12.4232 2.09367L12.5207 2.146L12.6254 2.21969L12.7071 2.29289L15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711C15.3166 7.09763 14.6834 7.09763 14.2929 6.70711L13 5.414V15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15V5.414L9.70711 6.70711C9.31658 7.09763 8.68342 7.09763 8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289L11.2929 2.29289C11.3283 2.2575 11.3657 2.22531 11.4047 2.19633L11.5159 2.12467L11.6287 2.07123L11.734 2.03585L11.8515 2.01102Z"
      fill="#353549"
    />
  </svg>
);

Upload.displayName = "UploadIcon";

export default Upload;

import classNames from "classnames";
import React from "react";

// Matching the colors in the design system/tailwind config
// so that the new Avatar component will be consistent with
// the rest of the app
export const USER_COLORS = [
  "#fdb932",
  "#f95939",
  "#cc0e2e",
  "#ff879b",
  "#f042f0",
  "#8f92f9",
  "#aa75f0",
  "#6161c3",
  "#6d8cf9",
  "#55bce7",
];

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  showName?: boolean;
  size?: "sm" | "md";
}

function UserAvatar({
  className,
  user,
  size,
  showName,
  onClick,
  ...rest
}: Props) {
  const color = USER_COLORS[user.icon_color_index];
  const style = {
    background: color,
    borderColor: user.is_contact && color,
  };

  return (
    <div
      className={classNames(
        "user-avatar",
        { contact: user.is_contact, clickable: Boolean(onClick) },
        size && `size-${size}`,
        className,
      )}
      data-testid="user-avatar"
      onClick={onClick}
      {...rest}
    >
      <div key={user.id} className="user-avatar-icon" style={style}>
        {user.initials}
      </div>
      {showName && user.full_name && (
        <div className="user-avatar-name">{user.full_name}</div>
      )}
    </div>
  );
}

UserAvatar.defaultProps = {
  className: "",
  size: "md",
  showName: false,
};

export default UserAvatar;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  IssuesReportMutationResponse,
  IssuesReportPathParams,
  IssuesReportQueryParams,
} from "../../models/IssuesReport";

/**
 * @description Report of issues
 * @summary Report of issues
 * @link /workspaces/:workspace_id/issues/reports
 */
export async function issuesReport(
  workspaceId: IssuesReportPathParams["workspace_id"],
  params?: IssuesReportQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<IssuesReportMutationResponse>["data"]> {
  const res = await client<IssuesReportMutationResponse>({
    method: "post",
    url: `/workspaces/${workspaceId}/issues/reports`,
    params,
    ...options,
  });
  return res.data;
}

import React from "react";
import { Link, NavLink } from "react-router-dom";

export function ModuleControlsHeader({
  workspaceID,
  recordVersionID,
}: {
  workspaceID?: number;
  recordVersionID?: number;
}) {
  return (
    <div className="settings-content-wrap">
      <div className="settings-links-wrap audit-trail-record-wrap">
        <ul>
          <li data-testid="rr-controls-tab-item">
            <Link
              to={`/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}`}
            >
              Record View
            </Link>
          </li>
          <li data-testid="rr-controls-tab-item">
            <Link
              to={`/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}/version-history`}
            >
              Version History
            </Link>
          </li>
          <li data-testid="rr-controls-tab-item">
            <NavLink to="#">Module Controls</NavLink>
          </li>
          <li data-testid="rr-controls-tab-item">
            <Link
              to={`/workspaces/${workspaceID}/modules/risk-register/${recordVersionID}/audit_trail`}
            >
              Activity
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const PriorityHigh = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 1C17.4477 1 17 1.44772 17 2V22C17 22.5523 17.4477 23 18 23H22C22.5523 23 23 22.5523 23 22V2C23 1.44772 22.5523 1 22 1H18ZM9 10C9 9.44772 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10V22C15 22.5523 14.5523 23 14 23H10C9.44772 23 9 22.5523 9 22V10ZM1 14C1 13.4477 1.44772 13 2 13H6C6.55228 13 7 13.4477 7 14V22C7 22.5523 6.55228 23 6 23H2C1.44772 23 1 22.5523 1 22V14Z"
      fill="#353549"
    />
  </svg>
);

PriorityHigh.displayName = "PriorityHighIcon";

export default PriorityHigh;

import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

import type { CustomNotificationRule } from "@/api/legacy/custom-notifications/types";
import { Flex, IconButton, MiniTag } from "@/components/Elements";
import { formatDate, stringToDate } from "@/components/helpers/DateFormatters";
import { formatFrequency } from "@/components/settings/notificationsCenter/custom-notifications/data/helpers";
import { NotificationText } from "@/features/notifications/components/text";

function CustomNotificationRow(props: {
  customNotification: CustomNotificationRule;
}) {
  return (
    <Flex
      alignCenter
      justifySpaceBetween
      className="notifications-container__single"
    >
      <Flex columnGap={6} rowGap={3} column>
        <NotificationText label={props.customNotification.title} />
        <Flex columnGap={6}>
          <NotificationText label="( Send a" small />
          <NotificationText
            label={formatFrequency(props.customNotification.frequency.type)}
            accent
            small
          />
          <NotificationText label="email, starting on" small />
          <NotificationText
            label={
              formatDate(
                stringToDate(props.customNotification.starting_date),
              ) || ""
            }
            small
            accent
          />
          {props.customNotification.frequency.type !== "one_time" &&
            Boolean(props.customNotification.end_date) && (
              <>
                <NotificationText label="ending on" small />
                <NotificationText
                  label={
                    formatDate(
                      stringToDate(props.customNotification.end_date),
                    ) || ""
                  }
                  small
                  accent
                />
              </>
            )}
          <NotificationText label=")" small />
        </Flex>
      </Flex>
      <Flex alignCenter columnGap={8}>
        <MiniTag
          label={props.customNotification.enabled ? "Enabled" : "Disabled"}
          theme={props.customNotification.enabled ? "green" : "gray"}
        />
        <Link
          to={`/notifications/custom-notifications/edit/${props.customNotification.id}`}
        >
          <IconButton icon="edit" transparent />
        </Link>
      </Flex>
    </Flex>
  );
}

export default observer(CustomNotificationRow);

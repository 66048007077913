import React from "react";

import type { RecordVersion } from "@/api";
import { recordTypeForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { MODULE_CHARTS } from "@/components/reports/constants";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import DueDateTableChart from "./DueDateTableChart";
import ProgressChart from "./ProgressChart";
import StatusPieChart from "./StatusPieChart";

interface Props {
  recordVersions: RecordVersion[];
  moduleWorkspaceID: number;
  sectionTagID?: number;
  filtersViewEnabled: boolean;
  setFiltersViewEnabled: (arg: boolean) => void;
  moduleName: ModuleIdentifier;
}

const ModuleSummaryCharts = ({
  recordVersions,
  moduleWorkspaceID,
  sectionTagID,
  filtersViewEnabled,
  setFiltersViewEnabled,
  moduleName,
}: Props) => {
  const recordTypePlural =
    recordTypeForThemisModuleIdentifier(moduleName).plural;

  const STANDARD_TOOLTIP_CONTENT = {
    overview: `Summary of all ${recordTypePlural.toLowerCase()} in this module`,
    status: `Statuses of ${recordTypePlural.toLowerCase()} that match applied filters in this page`,
    dueDate: `${recordTypePlural} with upcoming or past due dates in this page`,
  }; // policy and procedures module

  const STANDARD_TITLE_CONTENT = {
    overview: "Module Status",
    status: `${recordTypePlural} by Status`,
    dueDate: `${recordTypePlural} by Due Date`,
  }; // policy, procedures, documents module

  const TOOLTIP_CONTENT = {
    [MODULE_CHARTS.policy]: STANDARD_TOOLTIP_CONTENT,
    [MODULE_CHARTS.documents]: {
      overview: "Summary of all documents in this module",
      status: "Statuses of documents that match applied filters in this folder",
      dueDate: "Documents with upcoming or past due dates in this folder",
    },
    [MODULE_CHARTS.procedures]: STANDARD_TOOLTIP_CONTENT,
  };

  const TITLE_CONTENT = {
    [MODULE_CHARTS.policy]: STANDARD_TITLE_CONTENT,
    [MODULE_CHARTS.documents]: STANDARD_TITLE_CONTENT,
    [MODULE_CHARTS.procedures]: STANDARD_TITLE_CONTENT,
  };

  return (
    <div className="module-summaries">
      <div className="module-status-section">
        <div
          className="summary-title"
          data-tooltip-id="tooltip"
          data-tooltip-content={TOOLTIP_CONTENT[moduleName].overview}
          data-tooltip-place="top-start"
        >
          {TITLE_CONTENT[moduleName].overview}
        </div>
        <ProgressChart
          recordVersions={recordVersions}
          moduleWorkspaceID={moduleWorkspaceID}
          filtersViewEnabled={filtersViewEnabled}
          setFiltersViewEnabled={setFiltersViewEnabled}
          moduleName={moduleName}
        />
      </div>

      <div className="records-by-status-section">
        <div
          className="summary-title"
          data-tooltip-id="tooltip"
          data-tooltip-content={TOOLTIP_CONTENT[moduleName].status}
          data-tooltip-place="top"
        >
          {TITLE_CONTENT[moduleName].status}
        </div>
        <StatusPieChart
          filtersViewEnabled={filtersViewEnabled}
          setFiltersViewEnabled={setFiltersViewEnabled}
          recordVersions={recordVersions}
        />
      </div>

      <div className="records-by-due-date-section">
        <div
          className="summary-title"
          data-tooltip-id="tooltip"
          data-tooltip-content={TOOLTIP_CONTENT[moduleName].dueDate}
          data-tooltip-place="top"
        >
          {TITLE_CONTENT[moduleName].dueDate}
        </div>
        <DueDateTableChart
          recordVersions={recordVersions}
          moduleWorkspaceID={moduleWorkspaceID}
          sectionTagID={sectionTagID}
          filtersViewEnabled={filtersViewEnabled}
          setFiltersViewEnabled={setFiltersViewEnabled}
          moduleName={moduleName}
        />
      </div>
    </div>
  );
};

export default ModuleSummaryCharts;

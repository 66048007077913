import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  TextInput,
} from "@themis/ui";
import type { TextInputProps } from "packages/ui/src/components/Form/TextInput";
import type { ReactNode } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

interface TextInputFormFieldProps<T extends FieldValues>
  extends Partial<TextInputProps> {
  label?: ReactNode;
  control: Control<T>;
  name: Path<T>;
}

export function TextInputFormField<T extends FieldValues>({
  required,
  label,
  control,
  name,
  ...rest
}: TextInputFormFieldProps<T>) {
  return (
    <FormField
      required={required}
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="tw-flex tw-gap-4">
          <FormControl>
            <TextInput
              required={required}
              {...field}
              value={field.value ?? ""}
              {...rest}
            />
          </FormControl>

          {label && (
            <FormLabel className="tw-cursor-pointer">{label}</FormLabel>
          )}

          <FormMessage />
        </FormItem>
      )}
    />
  );
}

import React from "react";

import LongInputCell from "./LongInputCell";

interface Props {
  fieldName: string;
  hasErrors: boolean;
  recordVersionID: number;
  width: number | string;
  disabled?: boolean;
  errorMessage?: string;
  hasErrorClass?: string;
  initialValue?: number | string;
  isUserEditable?: boolean;
  locked?: boolean;
  pinned?: boolean;
}

function MultilineInputCell({
  fieldName,
  hasErrors,
  recordVersionID,
  width,
  initialValue,
  disabled,
  errorMessage,
  locked,
  pinned,
  hasErrorClass = "has-errors", // Set default prop value here
}: Props) {
  return (
    <LongInputCell
      fieldName={fieldName}
      hasErrors={hasErrors}
      recordVersionID={recordVersionID}
      width={width}
      disabled={disabled}
      errorMessage={errorMessage}
      hasErrorClass={hasErrorClass}
      initialValue={initialValue}
      locked={locked}
      pinned={pinned}
    />
  );
}

export default MultilineInputCell;

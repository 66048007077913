import React from "react";

import googleIMG from "@/images/table-image/icon/google.webp";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Google = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" fill="url(#google-pattern)" />
    <defs>
      <pattern
        id="google-pattern"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#google-icon"
          transform="translate(0 0.0518745) scale(0.00087184)"
        />
      </pattern>
      <image
        id="google-icon"
        width="1147"
        height="1028"
        xlinkHref={googleIMG}
      />
    </defs>
  </svg>
);

Google.displayName = "GoogleIcon";

export default Google;

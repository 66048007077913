import React, { useEffect, useState } from "react";

import { Button, Modal, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import ScoringMatrixRangeRatings from "@/features/risk-register/ScoringMatrixRangeRatings";
import type {
  RiskRangeError,
  ScoringMatrixRating,
} from "@/stores/types/risk-register-types";

interface RiskRegisterScoringModalProps {
  open: boolean;
  moduleWorkspaceID: number | null;
  rangeRatings: ScoringMatrixRating[];
  onClose: () => void;
}

function RiskRegisterScoringModalBase({
  open,
  moduleWorkspaceID,
  rangeRatings,
  onClose,
}: RiskRegisterScoringModalProps) {
  const mainStore = useMainStore();

  const [inherentRiskRatings, setInherentRiskRatings] = useState<
    ScoringMatrixRating[]
  >([]);
  const [residualRiskRatings, setResidualRiskRatings] = useState<
    ScoringMatrixRating[]
  >([]);

  const [inherentErrors, setInherentErrors] = useState<RiskRangeError[]>([]);
  const [residualErrors, setResidualErrors] = useState<RiskRangeError[]>([]);

  useEffect(() => {
    setInherentRiskRatings(
      rangeRatings.filter((rating) => rating.risk_type === "inherent"),
    );
    setResidualRiskRatings(
      rangeRatings.filter((rating) => rating.risk_type === "residual"),
    );
  }, [rangeRatings]);

  function updateMatrixRatings(
    type: "inherent" | "residual",
    values: ScoringMatrixRating[],
  ) {
    mainStore.riskRegisters.updateMatrixRatings(
      moduleWorkspaceID,
      values,
      type,
      true,
    );
  }

  function saveRatings() {
    updateMatrixRatings("inherent", inherentRiskRatings);
    updateMatrixRatings("residual", residualRiskRatings);
    onClose();
  }

  function displayErrors() {
    if (inherentErrors.length > 0) {
      return (
        <Typography className="modal-error">
          {inherentErrors[0].title}
        </Typography>
      );
    } else if (residualErrors.length > 0) {
      return (
        <Typography className="modal-error">
          {residualErrors[0].title}
        </Typography>
      );
    }
    return null;
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="rr-modal-container">
        <ScoringMatrixRangeRatings
          groupTitle="Inherent Risk Rating"
          riskType="inherent"
          riskRatings={inherentRiskRatings}
          setRiskRatings={setInherentRiskRatings}
          errors={inherentErrors}
          setErrors={setInherentErrors}
        />
      </div>
      <div className="rr-modal-container">
        <ScoringMatrixRangeRatings
          groupTitle="Residual Risk Rating"
          riskType="residual"
          riskRatings={residualRiskRatings}
          setRiskRatings={setResidualRiskRatings}
          errors={residualErrors}
          setErrors={setResidualErrors}
        />
      </div>
      <Button
        className="rr-modal-container__button"
        label="Save and Exit"
        onClick={saveRatings}
        disabled={residualErrors.length > 0 || inherentErrors.length > 0}
      />
      {displayErrors()}
    </Modal>
  );
}

function RiskRegisterScoringModal({
  open,
  ...rest
}: RiskRegisterScoringModalProps) {
  if (!open) {
    return null;
  }

  return <RiskRegisterScoringModalBase open={open} {...rest} />;
}

export default RiskRegisterScoringModal;

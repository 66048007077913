import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import checkIcon from "../../../../images/table-image/icon/check-icon.svg";
import deleteIcon from "../../../../images/table-image/icon/close-icon2.svg";
import checkBlueIcon from "../../../../images/table-image/icon/options-check-blue-light.svg";
import plusBlack from "../../../../images/table-image/icon/plus-black.svg";

interface Props {
  fieldName: string;
  hasErrors: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions: any[];
  width: number | string;
  allowDelete?: boolean;
  columnTitle?: string;
  dataTestId?: string;
  dollarAmount?: number | string;
  emptyOptionText?: string;
  errorMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  hasErrorClass?: string;
  isFinraDisputeAmount?: boolean;
  isMultiSelect?: boolean;
  isUserEditable?: boolean;
  locked?: boolean;
  moduleIdentifier?: ModuleIdentifier;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  pinned?: boolean;
  placeholder?: string;
  recordVersionID?: number;
  renderEmptyOption?: boolean;
  constrained?: boolean;
  hasReadWriteAccess?: boolean;
}

function Select({
  allowDelete,
  columnTitle,
  constrained,
  dataTestId,
  dollarAmount,
  emptyOptionText,
  errorMessage,
  exposeData,
  fieldName,
  hasErrorClass,
  hasErrors,
  isFinraDisputeAmount,
  isMultiSelect,
  isUserEditable,
  locked,
  moduleIdentifier,
  onDataChange,
  pinned,
  placeholder,
  recordVersionID,
  renderEmptyOption,
  selectedOptions,
  width,
  hasReadWriteAccess,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const amountInputRef = useRef(null);

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selectedOptions || []);
  const [selectedChangeItems, setSelectedChangeItems] = useState([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [showAmountPopup, setShowAmountPopup] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [valueAmount, setValueAmount] = useState(null);
  const [newOption, setNewOption] = useState("");
  const [moduleWorkspaceID, setModuleWorkspaceID] = useState(
    mainStore.context.moduleWorkspaceID,
  );

  // Variables
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly =
    !hasReadWriteAccess &&
    (!hasModuleWriteAccess || isCurrentWorkspaceArchived);
  const disableAddNew =
    ([
      "pii_provided",
      "renewal",
      "material",
      "dispute_amount",
      "escalate_to_finra",
    ].includes(fieldName) &&
      (moduleIdentifier === "vendor_due_diligence" ||
        moduleIdentifier === "complaints")) ||
    (moduleIdentifier === "risk_register" && fieldName === "control_rating") ||
    constrained;
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const firstOption = selectedItems?.length > 0 ? selectedItems[0] : null;
  const { isAdmin, isUser } = mainStore.context;
  const options = mainStore.fieldOptions.optionsForField(fieldName);
  const filteredOptions = options.filter((option) =>
    option.display_name.toLowerCase().includes(newOption.toLowerCase()),
  );
  // Effects
  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    const recordModuleWorkspaceID = recordVersion?.record?.module_workspace_id;
    let newModuleWorkspaceID;
    if (recordModuleWorkspaceID) {
      newModuleWorkspaceID = recordModuleWorkspaceID;
    } else if (moduleIdentifier === "complaints") {
      newModuleWorkspaceID = mainStore.context.moduleWorkspaceID;
    }

    if (newModuleWorkspaceID) {
      setModuleWorkspaceID(newModuleWorkspaceID);
    }
  }, [recordVersion]);

  const refreshTypes = async () => {
    if (disableAddNew) {
      return;
    }

    if (moduleWorkspaceID) {
      await mainStore.fieldOptions.index(moduleWorkspaceID);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createNewType();
    }
  };

  const createNewType = async () => {
    if (!newOption) {
      return;
    }

    if (moduleWorkspaceID) {
      await mainStore.fieldOptions.create(
        moduleWorkspaceID,
        fieldName,
        newOption,
      );
    }
    setNewOption("");
    refreshTypes();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  const addOption = (name) => {
    if (!name) {
      setSelectedItems([]);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      exposeData([]);
    } else if (isMultiSelect) {
      setSelectedItems((prevState) => {
        const latestState = prevState || [];
        const state = latestState.includes(name)
          ? latestState.filter((option) => option !== name)
          : [...latestState, name];
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        exposeData(state);
        return state;
      });
    } else {
      setSelectedItems((prevState) => {
        const state = prevState.includes(name) ? [] : [name];
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        exposeData(state);
        return state;
      });
    }

    if (name === "dollar_amount") {
      setShowAmountPopup(true);
    } else {
      setShowPopup(Boolean(isMultiSelect));
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleDelete = async (event, optionName) => {
    event.stopPropagation();

    if (!moduleWorkspaceID) {
      return;
    }

    await mainStore.fieldOptions.delete(
      moduleWorkspaceID,
      fieldName,
      optionName,
    );
  };

  const onClose = () => {
    if (selectedItems === selectedOptions && !valueAmount) {
      return;
    }
    if (selectedItems?.[0] === "dollar_amount" && !valueAmount) {
      return;
    }

    let valueToSave = null;

    // Override for FINRA Dispute Amount
    if (isFinraDisputeAmount) {
      const option = selectedItems?.length > 0 ? selectedItems[0] : null;
      const disputeAmount = valueAmount
        ? { option: "dollar_amount", dollarAmount: valueAmount }
        : { option };
      valueToSave = mainStore.avroSchemas.serializeValue(
        fieldName,
        disputeAmount,
      );
    } else {
      valueToSave = mainStore.avroSchemas.serializeValue(
        fieldName,
        selectedItems,
      );
    }

    if (typeof onDataChange === "function") {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setSelectedChangeItems(selectedItems);
      onDataChange(fieldName, valueToSave);
    } else {
      mainStore.recordVersions.update({
        recordVersionID,
        fieldName,
        value: valueToSave,
      });
    }
  };

  const handlePopUpOpen = () => {
    refreshTypes();
    setShowPopup(true);
    setIsSelectModalOpen(true);
    setButtonDisabled(true);
    setValueAmount(null);
  };

  const handlePopUpClose = () => {
    onClose();
    setShowPopup(false);
    setShowAmountPopup(false);
    setIsSelectModalOpen(false);
    setNewOption("");
  };

  const handleChange = () => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    setValueAmount(amountInputRef.current.value);
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    setButtonDisabled(amountInputRef.current.value.length === 0);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const amountHandleKeyDown = (event) => {
    if (event.key === "Enter" && !buttonDisabled) {
      setShowPopup(false);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'changedOptions' implicitly has an 'any'... Remove this comment to see the full error message
  const renderOptionSelect = (changedOptions) =>
    options
      ?.filter((option) => changedOptions?.includes(option.name))
      ?.map((option) => {
        const itemTitle =
          option.name === "dollar_amount"
            ? "Specific Amount"
            : option.display_name;
        const optionsClassNames = classNames(
          "options",
          "column-options-item",
          itemTitle.toLowerCase(),
        );

        const colorScheme = mainStore.fieldOptions.findColorScheme(
          option.color_scheme_id,
        );
        let optionStyling = null;

        if (colorScheme) {
          optionStyling = {
            border: colorScheme?.border,
            color: colorScheme?.text_color,
            background: colorScheme?.background_color,
          };
        }

        return (
          <div
            key={option.name}
            className={optionsClassNames}
            data-testid="column-options-item"
          >
            <span
              className="value value-type"
              data-testid="column-options-value"
              // @ts-expect-error TS(2322) FIXME: Type '{ border: any; color: any; background: any; ... Remove this comment to see the full error message
              style={optionStyling}
            >
              {itemTitle}
            </span>
            {option.name === "dollar_amount" && (
              <span
                className="value-amount"
                data-testid="specific-amount-value"
              >
                {dollarAmount ? `$${dollarAmount}` : ""}
              </span>
            )}
          </div>
        );
      });

  const liClassNames = classNames("cell column-options-cell", columnTitle, {
    active: isSelectModalOpen,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "read-only": !isUserEditable && isCurrentWorkspaceActive,
    illumination: isSelectModalOpen,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isReadOnly,
    "table-cell--disabled": isReadOnly,
    "gray-background": locked,
    open: ["open", "re_opened"].includes(firstOption),
    closed: firstOption === "closed",
    "disabled-li": locked,
    pinned,
  });

  const renderTrigger = (
    <li className={liClassNames} style={{ width }} data-testid={dataTestId}>
      <div className="cell-content">
        <div
          className="options column-options-container"
          data-testid="column-options-container"
        >
          {selectedOptions?.length === 0 &&
            selectedChangeItems?.length === 0 &&
            !locked && (
              <p
                className="options-placeholder nodata"
                data-testid="column-options-empty"
              >
                {hasErrors ? "" : placeholder || `- Select ${columnTitle} -`}
              </p>
            )}

          {selectedOptions?.length === 0 &&
            selectedChangeItems?.length === 0 &&
            locked && (
              <p
                className="options-placeholder nodata"
                data-testid="column-options-empty"
              >
                N/A
              </p>
            )}
          {hasErrors && <div>{errorMessage}</div>}
          {!hasErrors &&
            selectedOptions?.length > 0 &&
            renderOptionSelect(selectedOptions)}
          {!hasErrors &&
            selectedChangeItems.length > 0 &&
            renderOptionSelect(selectedChangeItems)}
        </div>
      </div>
    </li>
  );

  const renderContent = (
    <div
      className={classNames("select", { wide: showAmountPopup })}
      data-testid="column-options-popup-content"
    >
      {!disableAddNew && (isAdmin || isUser) && (
        <div className="select-add-new-form">
          <input
            type="text"
            placeholder={`Create ${columnTitle}...`}
            value={newOption}
            data-testid="column-option-add-new-input"
            onChange={(event) => setNewOption(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={createNewType}>
            <img src={plusBlack} alt="plus-black" />
          </button>
        </div>
      )}
      <div className="select-dropdown">
        {showAmountPopup && (
          <div className="select-amount-popup">
            <h4>Let&apos;s specific the exact amount here</h4>
            <input
              data-testid="specific-amount-input"
              type="number"
              ref={amountInputRef}
              placeholder="eg. $100"
              onChange={handleChange}
              onKeyDown={amountHandleKeyDown}
            />
            <button
              className={classNames({ active: !buttonDisabled })}
              disabled={buttonDisabled}
              onClick={() => setShowPopup(false)}
            >
              Confirm
            </button>
          </div>
        )}

        {!showAmountPopup && (
          <ul
            className="select-options"
            data-testid="column-options-dropdown-list"
          >
            {filteredOptions?.length === 0 && (
              <li data-testid="empty-options-dropdown-list">
                <h4 className="select-no-options-message">
                  You don&apos;t have any selections{" "}
                  {!disableAddNew &&
                    !onDataChange &&
                    `Please click "Create ${columnTitle}" above to create new options`}
                </h4>
              </li>
            )}

            {renderEmptyOption && (
              <li className="select-option" onClick={() => addOption(null)}>
                <div className="select-option-label-wrapper">
                  <span className="select-option-label">{emptyOptionText}</span>
                </div>
              </li>
            )}

            {filteredOptions?.map((option) => {
              const itemTitle =
                option.name === "dollar_amount"
                  ? "Specific Amount"
                  : option.display_name;
              const isSelected = selectedItems?.includes(option.name);

              const colorScheme = mainStore.fieldOptions.findColorScheme(
                option.color_scheme_id,
              );

              let optionStyling = null;
              if (colorScheme) {
                optionStyling = {
                  border: colorScheme?.border,
                  color: colorScheme?.text_color,
                  background: colorScheme?.background_color,
                };
              }

              const selectOptionClasses = classNames("select-option", {
                "select-option-active": !isMultiSelect && isSelected,
                "select-option-active-search": newOption.length > 0,
              });

              return (
                <li
                  key={option.name}
                  data-testid="column-options-dropdown-item"
                  className={selectOptionClasses}
                  onClick={() => {
                    addOption(option.name);
                  }}
                >
                  <div className="select-option-label-wrapper">
                    {isMultiSelect && (
                      <span
                        className={classNames("select-option-checkbox", {
                          checked: isSelected,
                        })}
                      >
                        {isSelected && <img src={checkIcon} alt="check-icon" />}
                      </span>
                    )}
                    <span
                      className="select-option-label"
                      // @ts-expect-error TS(2322) FIXME: Type '{ border: any; color: any; background: any; ... Remove this comment to see the full error message
                      style={optionStyling}
                    >
                      {itemTitle}
                    </span>
                  </div>
                  {!isMultiSelect && isSelected && (
                    <span className="select-option-check-icon">
                      <img src={checkBlueIcon} alt="check-icon" />
                    </span>
                  )}
                  {!option.is_used &&
                    !disableAddNew &&
                    !isSelected &&
                    allowDelete &&
                    (isAdmin || isUser) &&
                    !onDataChange && (
                      <span
                        className="select-option-delete-icon"
                        data-testid="column-option-delete-button"
                        onClick={(event) => handleDelete(event, option.name)}
                      >
                        <img src={deleteIcon} alt="delete-icon" />
                      </span>
                    )}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );

  if (!isUserEditable) {
    return renderTrigger;
  }

  return (
    <Popup
      position="bottom left"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={showPopup}
      keepTooltipInside
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
    >
      {renderContent}
    </Popup>
  );
}

Select.defaultProps = {
  allowDelete: true,
  constrained: false,
  dataTestId: "column-options-trigger",
  emptyOptionText: "N/A",
  hasErrorClass: "has-errors",
  isFinraDisputeAmount: false,
  isUserEditable: false,
  renderEmptyOption: false,
  selectedOptions: [],
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
};

export default observer(Select);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Shredder = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 8H16C16.2652 8 16.5196 7.89464 16.7071 7.70711C16.8946 7.51957 17 7.26522 17 7C17 6.73478 16.8946 6.48043 16.7071 6.29289C16.5196 6.10536 16.2652 6 16 6H10C9.73478 6 9.48043 6.10536 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7C9 7.26522 9.10536 7.51957 9.29289 7.70711C9.48043 7.89464 9.73478 8 10 8V8ZM8 12H16C16.2652 12 16.5196 11.8946 16.7071 11.7071C16.8946 11.5196 17 11.2652 17 11C17 10.7348 16.8946 10.4804 16.7071 10.2929C16.5196 10.1054 16.2652 10 16 10H8C7.73478 10 7.48043 10.1054 7.29289 10.2929C7.10536 10.4804 7 10.7348 7 11C7 11.2652 7.10536 11.5196 7.29289 11.7071C7.48043 11.8946 7.73478 12 8 12ZM8 16H16C16.2652 16 16.5196 15.8946 16.7071 15.7071C16.8946 15.5196 17 15.2652 17 15C17 14.7348 16.8946 14.4804 16.7071 14.2929C16.5196 14.1054 16.2652 14 16 14H8C7.73478 14 7.48043 14.1054 7.29289 14.2929C7.10536 14.4804 7 14.7348 7 15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16ZM20 2H4C3.73478 2 3.48043 2.10536 3.29289 2.29289C3.10536 2.48043 3 2.73478 3 3V21C3 21.1857 3.05171 21.3678 3.14935 21.5257C3.24698 21.6837 3.38668 21.8114 3.55279 21.8944C3.71889 21.9775 3.90484 22.0126 4.08981 21.996C4.27477 21.9793 4.45143 21.9114 4.6 21.8L6.67 20.25L8.73 21.8C8.9031 21.9298 9.11363 22 9.33 22C9.54637 22 9.7569 21.9298 9.93 21.8L12 20.25L14.07 21.8C14.2431 21.9298 14.4536 22 14.67 22C14.8864 22 15.0969 21.9298 15.27 21.8L17.33 20.25L19.4 21.8C19.5493 21.9113 19.7267 21.9786 19.9122 21.9945C20.0977 22.0104 20.284 21.9742 20.45 21.89C20.6149 21.8069 20.7536 21.6798 20.8507 21.5227C20.9478 21.3656 20.9994 21.1847 21 21V3C21 2.73478 20.8946 2.48043 20.7071 2.29289C20.5196 2.10536 20.2652 2 20 2V2ZM19 19L17.93 18.2C17.7569 18.0702 17.5464 18 17.33 18C17.1136 18 16.9031 18.0702 16.73 18.2L14.67 19.75L12.6 18.2C12.4269 18.0702 12.2164 18 12 18C11.7836 18 11.5731 18.0702 11.4 18.2L9.33 19.75L7.27 18.2C7.0969 18.0702 6.88637 18 6.67 18C6.45363 18 6.2431 18.0702 6.07 18.2L5 19V4H19V19Z"
      fill="#353549"
    />
  </svg>
);

Shredder.displayName = "ShredderIcon";

export default Shredder;

import classNames from "classnames";
import React from "react";

interface SwitchProps {
  active?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  disabled?: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
  active,
  checked,
  indeterminate,
  onChange,
  disabled,
}) => (
  <div
    className={classNames("switch-container", {
      active,
    })}
  >
    <label>
      <input
        type="checkbox"
        className={classNames({
          "not-answered": indeterminate,
        })}
        checked={checked}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        data-testid="switch-input"
      />
      <div className="switch">
        <div className="circle" data-testid="switch-checked" />
      </div>
    </label>
  </div>
);

export default Switch;

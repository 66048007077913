import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateTagMutationRequest,
  UpdateTagMutationResponse,
  UpdateTagPathParams,
} from "../../models/UpdateTag";

/**
 * @description Update tag
 * @summary Update tag
 * @link /tags/:tag_id
 */
export async function updateTag(
  tagId: UpdateTagPathParams["tag_id"],
  data: UpdateTagMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateTagMutationResponse>["data"]> {
  const res = await client<UpdateTagMutationResponse, UpdateTagMutationRequest>(
    {
      method: "put",
      url: `/tags/${tagId}`,
      data,
      ...options,
    },
  );
  return res.data;
}

import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";

interface Props {
  sectionTagId?: number;
}

function AddNewButton({ sectionTagId }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const { workspaceID } = mainStore.context;

  // Functions
  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  async function createNewControlMapping() {
    const recordVersion = await mainStore.controlMappings.create(sectionTagId);
    history.push(
      `/workspaces/${workspaceID}/modules/control-mapping/${recordVersion.id}`,
    );
  }

  // elements
  const renderTrigger = (
    <div
      className="tw-sticky tw-left-[19px] tw-ml-[19px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
      data-testid="add-new-record-button"
    >
      <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
      <div className="tw-neutral-300 tw-text-sm">
        Add {getRecordName("control_mapping", moduleWorkspaces, true)}...
      </div>
    </div>
  );

  const url = sectionTagId
    ? `/workspaces/${workspaceID}/modules/control-mapping/templates?sectionTagId=${sectionTagId}`
    : `/workspaces/${workspaceID}/modules/control-mapping/templates`;

  const renderPopupContent = (
    <div
      className="risk-register-add-new-popup"
      data-testid="add-new-control-mapping-popup"
    >
      <Link to={url}>Add From Library</Link>
      <button
        type="button"
        onClick={createNewControlMapping}
        data-testid="add-new-control-mapping-popup-add"
      >
        Add New Control
      </button>
    </div>
  );

  return (
    <Popup
      disabled={!hasModuleWriteAccess}
      position="bottom left"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      {renderPopupContent}
    </Popup>
  );
}

AddNewButton.defaultProps = {
  offset: 122,
};

export default observer(AddNewButton);

import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import type { Data } from "@/stores/types/record-types";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class Procedures implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  data: Data | null = null;
  auditTrails = [];
  historical = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      auditTrails: observable,
      historical: observable,
      setData: action,
      setAuditTrails: action,
      setHistorical: action,
    });

    this.mainStore = mainStore;
  }

  async index({
    tableName,
    sortParams,
    sectionTagId = null,
    fromUseSortingAndPagination = false,
    tableFilters = [],
  }: IndexParams) {
    if (!tableName) {
      window.console.warn(
        `Procedures#index => tableName(${tableName}) should be present!`,
      );
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    const params = {
      table_title: tableName,
      table_filters: tableFilters,
    };

    if (sectionTagId) {
      // @ts-expect-error TS(2339) FIXME: Property 'section_tag_id' does not exist on type '... Remove this comment to see the full error message
      params.section_tag_id = sectionTagId;
    }

    if (sortParams) {
      // @ts-expect-error TS(2339) FIXME: Property 'sort_field_name' does not exist on type ... Remove this comment to see the full error message
      params.sort_field_name = sortParams.field_name;
      // @ts-expect-error TS(2339) FIXME: Property 'sort_direction' does not exist on type '... Remove this comment to see the full error message
      params.sort_direction = sortParams.direction;
    }

    if (fromUseSortingAndPagination && !sectionTagId) {
      // @ts-expect-error TS(2339) FIXME: Property 'top_level_block' does not exist on type ... Remove this comment to see the full error message
      params.top_level_block = true;
    }

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/procedures`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.mainStore.fields.setList(response.data.fields);

      if (!fromUseSortingAndPagination) {
        this.setData(response.data);
        return;
      }

      this.setData({
        ...this.data,
        record_versions: [
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          ...this.data.record_versions.filter(
            (item) => item.section_tag_id !== sectionTagId,
          ),
          ...response.data.record_versions,
        ],
      });
    } catch (error) {
      window.console.warn(`"Procedures#Index for Workspace" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, sectionTagID) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/procedures`,
        headers: this.mainStore.getHeaders(),
        params: {
          section_tag_id: sectionTagID,
        },
      });
    } catch (error) {
      window.console.log(`"Procedures#create" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/procedures/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Procedures#delete" error ${error}`);
    }
  }

  // POST /api/react/procedures/:id/finalize
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/procedures/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      return true;
    } catch (error) {
      window.console.log(`"Procedures#finalize" error ${error}`);
    }
  }

  // POST /api/react/procedures/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/procedures/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });

      return data;
    } catch (error) {
      window.console.log(`"Procedures#unlock" error ${error}`);
    }
  }

  // POST /api/react/procedures/:id/archive
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async archive(recordVersionID) {
    const data = { identifier: "procedures" };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/procedures/${recordVersionID}/archive`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Procedures#archive" error ${error}`);
    }
  }

  // PUT /api/react/procedures/:id/update_section
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async updateSection(recordVersionID, sectionTagID) {
    const data = { section_tag_id: sectionTagID, identifier: "procedures" };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/procedures/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response?.isAxiosError && response?.message.includes("403")) {
        this.mainStore.toast.setErrorText("Cannot reorder a published record.");
      }
    } catch (error) {
      this.mainStore.toast.setErrorText("Error: Unable to reorder record.");
      window.console.log(`"Procedures#updateSection" error ${error}`);
    }
  }

  // GET /api/react/procedures/archived
  async archivedList() {
    const params = { identifier: "procedures" };

    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/procedures/archived`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return data;
    } catch (error) {
      window.console.log(`"Procedures#archivedList" error ${error}`);
    }
  }

  // GET /api/react/procedures/:id/historical
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async historicalList(recordVersionID) {
    try {
      const params = { identifier: "procedures" };
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/procedures/${recordVersionID}/historical`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setHistorical(response.data.record_versions);
    } catch (error) {
      this.setHistorical([]);
      window.console.log(`"Procedures#historicalList" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAuditTrails(value) {
    if (value) {
      this.auditTrails = value;
    } else {
      this.auditTrails = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setHistorical(value) {
    if (value) {
      this.historical = value;
    } else {
      this.historical = [];
    }
  }

  // Store Helpers

  cleanup() {
    this.setData({});
    this.setAuditTrails([]);
    this.setHistorical([]);
  }

  abort() {
    this.controller?.abort();
  }
}

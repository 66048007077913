import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";
import type { RiskType } from "@/stores/types/risk-register-types";

import {
  INHERENT_MATRIX_TITLE,
  RESIDUAL_MATRIX_TITLE,
} from "../../table/risk-register/Constants";

const FIELDS = [
  {
    label: "Inherent Risk",
    name: "inherent_risk",
    title: "Inherent Risk",
    specificMartixTitle: INHERENT_MATRIX_TITLE,
    className: "risk",
  },
  {
    label: "Residual Risk",
    name: "residual_risk",
    title: "Residual Risk",
    specificMartixTitle: RESIDUAL_MATRIX_TITLE,
    className: "risk",
  },
  {
    label: "Impact",
    name: "impact",
    title: "Impact",
    specificMartixTitle: INHERENT_MATRIX_TITLE,
    className: "number",
  },
  {
    label: "Inherent",
    name: "inherent_risk_rating",
    title: "Inherent Risk Rating",
    specificMartixTitle: RESIDUAL_MATRIX_TITLE,
    className: "number",
  },
  {
    label: "Likelihood",
    name: "likelihood",
    title: "Likelihood",
    specificMartixTitle: INHERENT_MATRIX_TITLE,
    className: "number",
  },
  {
    label: "Control",
    name: "control_rating",
    title: "Control Rating",
    specificMartixTitle: RESIDUAL_MATRIX_TITLE,
    className: "number",
  },
  {
    label: "Risk Name",
    name: "risk_event",
    title: "Risk Event",
    className: "text",
  },
  {
    label: "Category",
    name: "main_category",
    title: "Main Category",
    className: "option",
  },
];

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersions?: any[];
}

function RiskRegisterReportMatrixTable({ matrix, recordVersions }: Props) {
  // vars
  const mainStore = useMainStore();
  const { company } = mainStore.companies;

  const filteredFields = FIELDS.filter(
    (item) =>
      !item.specificMartixTitle || item.specificMartixTitle === matrix.name,
  );
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const filteredRecordVersions = recordVersions.filter((rv) => {
    const presentInInherentMatrix = rv.inherent_risk_rating_cell;
    return matrix.name === INHERENT_MATRIX_TITLE
      ? presentInInherentMatrix
      : presentInInherentMatrix && rv.residual_risk_rating_cell;
  });

  useEffect(() => {
    const companyId = company?.id;
    if (!companyId) {
      return;
    }

    if (mainStore.riskTypes.riskTypes?.length === 0) {
      mainStore.riskTypes.index(companyId);
    }
  }, [company]);

  // renders
  const renderFields = (
    <div className="report-rr-matrix-table-columns">
      {filteredFields.map((field) => (
        <div key={field.label} className="report-rr-matrix-table-columns-item">
          {field.label}
        </div>
      ))}
    </div>
  );

  const renderRows = filteredRecordVersions.map((rv) => (
    <div key={rv.id} className="report-rr-matrix-table-row">
      {filteredFields.map((column) => {
        // base
        const categoryID = rv.data.l1_risk_taxonomy?.ids[0];
        const category = mainStore.riskTypes?.riskTypes.find(
          (item: RiskType) => item.id === categoryID,
        );

        let value = "";
        let style = {};
        let matrixCell;
        switch (column.title) {
          case "Impact": {
            const impactOption = rv.data.impact?.options[0];
            value = impactOption
              ? matrix?.scoring_matrix_rows.slice().reverse()[impactOption - 1]
                  ?.title
              : null;
            break;
          }
          case "Likelihood": {
            const likelihoodOption = rv.data.likelihood?.options[0];
            value = likelihoodOption
              ? matrix?.scoring_matrix_rows.slice()[likelihoodOption - 1]?.title
              : null;
            break;
          }
          case "Main Category":
            value = category?.title || "";
            break;
          case "Risk Event":
            value = rv.data.risk_event?.value;
            break;

          // special cases
          case "Inherent Risk Rating":
            matrixCell = rv.inherent_risk_rating_cell;
            break;
          case "Control Rating": {
            const controlOption = rv.data.control_rating?.options[0];
            value = controlOption
              ? matrix?.scoring_matrix_columns.slice()[controlOption - 1]?.title
              : null;
            break;
          }
          case "Inherent Risk":
            matrixCell = rv.inherent_risk_rating_cell;
            break;
          case "Residual Risk":
            matrixCell = rv.residual_risk_rating_cell;
            break;
          default:
            value = "";
            matrixCell = null;
            break;
        }

        if (matrixCell) {
          value = `${matrixCell?.title} - ${matrixCell?.value}`;
          style = {
            backgroundColor: matrixCell.color,
            color: "white",
          };
        }

        return (
          <div key={column.label} className="report-rr-matrix-table-cell">
            {value && (
              <div
                className={classNames(
                  "report-rr-matrix-table-cell-content",
                  column.className,
                )}
                style={style}
              >
                {value}
              </div>
            )}
          </div>
        );
      })}
    </div>
  ));

  if (mainStore.riskTypes.riskTypes?.length === 0) {
    return <div />;
  }

  return (
    <div className="report-rr-matrix-table">
      {renderFields}
      {renderRows}
    </div>
  );
}

RiskRegisterReportMatrixTable.defaultProps = {
  recordVersions: [],
};

export default observer(RiskRegisterReportMatrixTable);

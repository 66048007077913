import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Block = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM18.3207 7.09548L7.09548 18.3207C8.44998 19.3733 10.1518 20 12 20C16.4183 20 20 16.4183 20 12C20 10.1518 19.3733 8.44998 18.3207 7.09548ZM12 4C7.58172 4 4 7.58172 4 12C4 13.8482 4.62674 15.55 5.67927 16.9045L16.9045 5.67927C15.55 4.62674 13.8482 4 12 4Z"
      fill="#353549"
    />
  </svg>
);

Block.displayName = "BlockIcon";

export default Block;

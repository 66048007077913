import "../styles.scss";

import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import type { SubmitErrorHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import Loading from "@/components/Loading";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import {
  MODULE_NOTIFICATIONS_BASE_PATH,
  MODULE_NOTIFICATIONS_CREATE_PATH,
  MODULE_NOTIFICATIONS_EDIT_PATH,
  MODULE_NOTIFICATIONS_TAB_PATH,
} from "@/features/notifications/pages/constants";
import type {
  ModuleNotificationLocationState,
  ModuleNotificationParams,
} from "@/features/notifications/types/types";
import { useLoading } from "@/hooks/useLoading";
import type { NotificationRuleData } from "@/stores/types/notification-rule-types";
import { RELATIVE_TO } from "@/stores/types/notification-rule-types";

import { NotificationDetailsHeader } from "../../../components/header";
import NotificationRecipients from "../../../components/recipients";
import RightActionBar from "../../../components/right-action-bar";
import { UnlockTaskCriteria } from "../../../components/unlock-task-criteria";
import { initialCreateValues } from "./constants";
import { parseNotificationRule, serializeFormData } from "./helpers";
import type { UnlockTabNotificationFormValues } from "./types";

function UnlockTaskSinglePage({
  moduleWorkspaceID,
}: {
  moduleWorkspaceID: number;
}) {
  const mainStore = useMainStore();
  const history = useHistory();
  const location = useLocation<ModuleNotificationLocationState>();
  const params = useParams<ModuleNotificationParams>();
  const createMatch = useRouteMatch(MODULE_NOTIFICATIONS_CREATE_PATH);

  const { moduleIdentifier } = params;

  const isRiskAssessment = moduleIdentifier === "risk-assessment";
  const allowedFields = isRiskAssessment
    ? ["due_date", "status", "respondents", "reviewers"]
    : [];

  const {
    data: { field_options, notification_rules },
    fieldsForSelectedModule,
    currentFieldOptions,
  } = mainStore.notificationsRules;

  useEffect(() => {
    if (moduleWorkspaceID) {
      mainStore.notificationsRules.index(moduleWorkspaceID);
      mainStore.notificationsRules.getFields(moduleWorkspaceID);
      mainStore.notificationsRules.getModuleFieldOptions(moduleWorkspaceID);
    }
  }, [moduleWorkspaceID]);

  useEffect(
    () => () => {
      mainStore.notificationsRules.cleanup();
    },
    [],
  );

  const { watch, control, handleSubmit, reset, formState } =
    useForm<UnlockTabNotificationFormValues>({
      defaultValues: initialCreateValues,
    });

  useEffect(() => {
    const rule = (notification_rules || [])?.find(
      (notificationRule) =>
        params.notificationId === String(notificationRule.id),
    );
    if (rule) {
      reset(parseNotificationRule(rule));
    }
  }, [notification_rules]);

  const getOptions = (
    fieldName: string,
    index?: number,
  ): { value: string; label: string; colorSchemeId?: number }[] => {
    const options: { value: string; label: string }[] = [];

    const filteredFieldsForSelectedModule = allowedFields.length
      ? fieldsForSelectedModule.filter((field) =>
          allowedFields.includes(field.name),
        )
      : fieldsForSelectedModule;

    if (fieldName === "trigger_column") {
      filteredFieldsForSelectedModule.forEach((option) => {
        if (
          option.data_type === "com.askthemis.types.v1.date" &&
          ![
            "created_at",
            "last_updated_at",
            "last_closed_at",
            "last_reopened_at",
          ].includes(option.name)
        ) {
          options.push({ value: option.name, label: option.display_name });
        }
      });

      return options;
    }

    if (fieldName === "criteria_name") {
      filteredFieldsForSelectedModule.forEach((option) => {
        if (option.data_type === "com.askthemis.types.v1.option") {
          options.push({ value: option.name, label: option.display_name });
        }
      });

      return options;
    }

    if (fieldName === "criteria_value") {
      return currentFieldOptions?.[
        // @ts-expect-error TS(2538) FIXME: Type 'readonly (string | number | boolean | string... Remove this comment to see the full error message
        String(watch(`criteria.${index}.fieldName`))
      ]?.map((option) => ({
        value: option.name,
        label: option.display_name,
        colorSchemeId: option.color_scheme_id,
      }));
    }

    if (fieldName === "relative") {
      field_options?.[fieldName].forEach((option) => {
        // Remove `same day of` option from condition options if frequency is
        // daily or weekly
        if (
          ["days", "weeks", "months"].includes(watch("frequency")) &&
          option.name === RELATIVE_TO.ON
        ) {
          return;
        }

        options.push({
          value: option.name,
          label: option.display_name,
        });
      });

      return options;
    }

    return (field_options?.[fieldName] || []).map((option) => ({
      value: option.name,
      label: option.display_name,
    }));
  };

  const onSubmit = async (data: UnlockTabNotificationFormValues) => {
    try {
      const response: AxiosResponse<NotificationRuleData> = await legacyApi({
        method: createMatch ? "POST" : "PATCH",
        url: createMatch
          ? `${API_URL}/module_workspaces/${moduleWorkspaceID}/notification_rules`
          : `${API_URL}/notification_rules/${params.notificationId}`,
        headers: mainStore.getHeaders(),
        data: serializeFormData(
          data,
          isRiskAssessment ? "questionnaire" : "record_version",
        ),
      });

      if (axios.isAxiosError(response)) {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        throw (response as AxiosError<any>).response.data.errors.base;
      }

      reset({}, { keepValues: true });
      mainStore.toast.setInfoText(
        `Unlock Task Notification was successfully ${
          createMatch ? "created" : "updated"
        }.`,
      );

      if (createMatch) {
        history.push(
          generatePath(MODULE_NOTIFICATIONS_EDIT_PATH, {
            ...params,
            notificationId: response.data.id,
          }),
          location.state,
        );
      }
    } catch {
      mainStore.toast.setErrorText(
        `An error occurred. Could not ${
          createMatch ? "create" : "update"
        } notification`,
      );
    }
  };

  const onInvalid: SubmitErrorHandler<UnlockTabNotificationFormValues> = () => {
    mainStore.toast.setErrorText(
      "There is something not right! Please check if all necessary fields have been filled properly!",
    );
  };

  async function handleDelete() {
    const res = mainStore.notificationsRules.delete(
      params.notificationId,
      moduleWorkspaceID,
    );
    return !!res;
  }

  const loading = useLoading(fieldsForSelectedModule);
  const allFields = mainStore.notificationsRules?.fieldsForSelectedModule || [];
  const recipientFields = allFields
    .map((field) => field.name)
    .filter((fieldName) => fieldName !== "last_updated_by");

  return (
    <DashboardContent>
      <form
        onSubmit={handleSubmit(onSubmit, onInvalid)}
        className="notification-details--form"
      >
        <DashboardHeader
          title={`${createMatch ? "Add New" : "Edit"} Notification`}
          onBackClick={() => {
            history.push(
              generatePath(
                location.state?.fromAllTab
                  ? MODULE_NOTIFICATIONS_BASE_PATH
                  : MODULE_NOTIFICATIONS_TAB_PATH,
                {
                  ...params,
                },
              ),
            );
          }}
          RightActionBar={
            <RightActionBar
              isDirty={formState.isDirty}
              onDelete={handleDelete}
            />
          }
        />
        <DashboardContentWrapper>
          {loading ? (
            <Loading loadingLayout="small-table" />
          ) : (
            <div className="notification-details tw-flex tw-flex-col">
              <NotificationDetailsHeader
                headerName="Notification Criteria"
                control={control}
                name="enabled"
              />
              <UnlockTaskCriteria getOptions={getOptions} control={control} />
              <NotificationRecipients
                name="recipients"
                control={control}
                allowedFields={recipientFields}
              />
            </div>
          )}
        </DashboardContentWrapper>
      </form>
    </DashboardContent>
  );
}

export default observer(UnlockTaskSinglePage);

import axios from "axios";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class ChecklistQuestionsStore {
  mainStore: MainStore;

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;
  }

  // PUT /api/react/checklist_questions/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async update(id, params) {
    const data = {
      id,
      checklist_question: params,
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/checklist_questions/${id}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (axios.isAxiosError(response)) {
        this.mainStore.toast.setErrorFromResponse(response);
      }

      return response.data.checklist_question;
    } catch (error) {
      window.console.log(`"ChecklistQuestion#update" error ${error}`);
    }
  }
}

import "./risk-methodology-builder.scss";

import classnames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

interface ColorSelectProps {
  index: number;
  selectedColor: string;
  updateColor: (index: number, value: string) => void;
  availableColors: string[];
  small?: boolean;
  classNameSelected?: string;
}

function ColorSelect({
  index,
  selectedColor,
  updateColor,
  availableColors,
  small = false,
  classNameSelected,
}: ColorSelectProps) {
  const [currentColor, setCurrentColor] = useState(selectedColor);
  // @ts-expect-error TS(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
  const setSelectedColor = (color) => {
    setCurrentColor(color);
    updateColor(index, color);
  };
  // @ts-expect-error TS(7006) FIXME: Parameter 'color' implicitly has an 'any' type.
  const classes = (color) =>
    classnames(
      "risk-methodology-builder__content__risk-rating-color",
      small && "risk-methodology-builder__content__risk-rating-color__small",
      {
        [classNameSelected
          ? classNameSelected
          : "risk-methodology-builder__content__risk-rating-color__selected"]:
          color === currentColor,
      },
    );
  return (
    <span className="risk-area-colors-wrapper">
      {availableColors.map((color) => (
        <div
          className={classes(color)}
          style={{ backgroundColor: color }}
          key={color}
          onClick={() => setSelectedColor(color)}
        />
      ))}
    </span>
  );
}

export default observer(ColorSelect);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Contact = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C10.067 3 8.5 4.567 8.5 6.5C8.5 8.433 10.067 10 12 10C13.933 10 15.5 8.433 15.5 6.5C15.5 4.567 13.933 3 12 3ZM6.5 6.5C6.5 3.46243 8.96243 1 12 1C15.0376 1 17.5 3.46243 17.5 6.5C17.5 9.53757 15.0376 12 12 12C8.96243 12 6.5 9.53757 6.5 6.5ZM8.92926 12.3158L9.03564 12.4295C9.10393 12.5026 9.2029 12.6087 9.32604 12.7413C9.57227 13.0064 9.91544 13.3774 10.3035 13.8016C10.8317 14.379 11.4474 15.0595 12.0159 15.7069L15.057 12.3307C15.2773 12.0861 15.604 11.9656 15.9305 12.0085C16.7371 12.1147 17.5361 12.5846 18.2315 13.1625C18.9486 13.7585 19.6544 14.5516 20.2761 15.4577C21.3362 17.0027 22.2163 18.9728 22.4432 21H23C23.5523 21 24 21.4477 24 22C24 22.5523 23.5523 23 23 23H1C0.447715 23 0 22.5523 0 22C0 21.4477 0.447715 21 1 21H1.55106C1.75169 19.0238 2.5252 17.1711 3.54309 15.6725C4.71761 13.9433 6.30432 12.5614 7.89266 12.0484C8.26082 11.9295 8.66454 12.0336 8.92926 12.3158ZM3.56399 21H20.4272C20.2083 19.4554 19.5157 17.8845 18.627 16.5892C18.0909 15.8078 17.5053 15.1595 16.9532 14.7007C16.6317 14.4335 16.3496 14.2538 16.1177 14.1415L12.743 17.888C12.5497 18.1027 12.2729 18.2232 11.984 18.2186C11.6951 18.214 11.4224 18.0847 11.236 17.8639C10.5369 17.0361 9.59805 15.9935 8.82779 15.1515C8.48519 14.777 8.1778 14.4442 7.94248 14.1906C7.04262 14.6558 6.03856 15.558 5.19754 16.7962C4.36911 18.0159 3.7566 19.4793 3.56399 21Z"
      fill="#353549"
    />
  </svg>
);

Contact.displayName = "ContactIcon";

export default Contact;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";

import type { RecordVersion, TableName } from "@/api";
import SectionTag from "@/components/table/shared/SectionTag";
import { useMainStore } from "@/contexts/Store";
import type { Section } from "@/stores/types/section-tags";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionPopupSettings from "../shared/SectionPopupSettings";
import Sharing from "../shared/Sharing";
import TableMenu from "../shared/tableMenu/TableMenu";

interface Props {
  isDropdownOpen?: boolean;
  moduleWorkspaceID?: number;
  recordVersions?: RecordVersion[];
  sectionTag?: Section;
  showCheckbox?: boolean;
  tableName?: TableName;
}

function PolicySection({
  moduleWorkspaceID,
  recordVersions,
  sectionTag,
  tableName,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);
  const [showCheckbox, setShowCheckbox] = useState(props.showCheckbox);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const { user } = mainStore.users;
  const { activeWorkspace, isAdmin, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const { visibleFields } = mainStore.fields;
  const isDraft = tableName === "Drafts";
  const isFinalized = tableName === "Finalized";
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;

  // Hooks
  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });

  // Hooks
  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.policies,
    sectionTagId: sectionTag?.id,
  });

  useEffect(() => {
    setShowCheckbox(props.showCheckbox);
  }, [props.showCheckbox]);

  // Functions
  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const addNewPolicy = async () => {
    if (selectMode && showCheckbox) {
      return;
    }

    if (!workspaceID) {
      return;
    }

    await mainStore.policies.create(workspaceID, sectionTag?.id);
  };

  const renameClick = () => {
    setIsEditMode(true);
  };

  const deleteSectionTag = () => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  // elements add
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx) => {
    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isLockedRow={recordVersion.table_name !== "Drafts"}
        recordVersion={recordVersion}
        fields={visibleFields}
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={tableName}
        moduleIdentifier="policy"
        inSelectMenuActionMode={selectMode}
        isDraggable={!isFinalized}
        isSorted={!!sortByFieldName}
        order={idx}
        showCheckboxFromSlideMenu={props.showCheckbox}
      />
    );
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderRows = recordVersions.map(renderRow);

  const renderFields = visibleFields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  return (
    <>
      {sectionTag && (
        <div
          className={classNames("tw-flex", {
            "tw-ml-[18px]": isDraft,
          })}
          data-testid="dropdown-section"
        >
          {isDraft && (
            <div
              className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
              onClick={dropdownClick}
            >
              <PiCaretUpBold
                className={classNames(
                  "tw-h-[18px] tw-w-[18px] tw-origin-center tw-text-neutral-500",
                  {
                    "tw-rotate-180": !isDropdownOpen,
                    "tw-rotate-0 ": isDropdownOpen,
                  },
                )}
              />
            </div>
          )}
          {sectionTag?.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag?.editable && !isEditMode && (
            <>
              {isAdmin && isDraft && (
                <Sharing
                  moduleWorkspaceID={moduleWorkspaceID}
                  objectID={sectionTag.id}
                  selectedDepartmentIDs={sectionTag.department_ids}
                  selectedUserIDs={sectionTag.user_ids}
                  tableName={tableName}
                />
              )}

              {canManageSections && (
                <SectionPopupSettings
                  onRename={renameClick}
                  onDelete={deleteSectionTag}
                />
              )}
            </>
          )}
        </div>
      )}

      {isDropdownOpen && isDraft && (
        <div
          className="dropdown-table draggable"
          data-testid="policy-dropdown-table"
          id={sectionTableID}
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="procedures-list-column-first-blocks table-column-reorder">
                  {visibleFields?.length > 0 && tableName === "Drafts" && (
                    <TableMenu
                      setShowCheckbox={setShowCheckbox}
                      resetMenuActions={resetMenuActions}
                      selectMode={selectMode}
                      setSelectMode={setSelectMode}
                    />
                  )}
                </div>
                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              {!(showCheckbox && selectMode) &&
                tableName === "Drafts" &&
                Boolean(
                  activeWorkspace?.is_internal ||
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    user.roles.find(
                      (role) =>
                        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                        (role.company_id || role.permission_set?.company_id) ===
                        activeWorkspace?.company?.id,
                    ),
                ) && <AddColumnButton onAdd={createColumn} />}
              {/* @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message */}
              {tableName === "Finalized" && (
                <div className="title-first-block finalized-block" />
              )}
              {isFinalized && (
                <div className="title-first-block finalized-block" />
              )}
            </ul>
          </div>
          <ReorderableTable
            recordVersions={recordVersions}
            isSorted={!!sortByFieldName}
            renderer={renderRow}
            sectionTag={sectionTag}
            onAddNewRow={addNewPolicy}
            newRowName="Policy"
          />
        </div>
      )}

      {renderRows.length > 0 && isFinalized && (
        <div className="dropdown-table">
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="list-title-table-wrap">
                  {showCheckbox && <div className="checkbox-title-table" />}
                  {renderFields}
                  <span className="stretch-cell" />
                </div>
              </div>
              <div className="title-first-block finalized-block" />
            </ul>
          </div>
          {renderRows}
        </div>
      )}
    </>
  );
}

PolicySection.defaultProps = {
  sectionTag: null,
};

export default observer(PolicySection);

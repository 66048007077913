import "./send-document-content.scss";

import classNames from "classnames";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { copyToModule } from "@/api/legacy/attachement-group";
import { WorkspacesAPI } from "@/api/legacy/workspaces";
import { Button, Typography } from "@/components/Elements";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import moduleIcons from "@/components/helpers/moduleIcons";
import { useMainStore } from "@/contexts/Store";
import closeIcon from "@/images/table-image/icon/close-black-icon.svg";
import type { ModuleWorkspace } from "@/stores/types/workspace-types";

const ALLOWED_MODULES = [
  "training",
  "marketing",
  "policy",
  "procedures",
  "documents",
];

interface Props {
  attachmentGroupID: number;
  closeSlide: () => void;
}

function SendDocumentContent({ attachmentGroupID, closeSlide }: Props) {
  const mainStore = useMainStore();
  const [selectedID, setSelectedIDHelper] = useState(-1);
  const [selectedMWIDs, setSelectedMWIDs] = useState<number[]>([]);
  const [moduleWorkspaces, setModuleWorkspaces] = useState<ModuleWorkspace[]>(
    [],
  );
  const [requesting, setRequesting] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  const handleMWIDSelection = (id: number) => {
    if (selectedMWIDs.includes(id)) {
      return setSelectedMWIDs((prev) => prev.filter((item) => item !== id));
    }
    return setSelectedMWIDs((prev) => [...prev, id]);
  };

  const copyAttachment = async () => {
    if (selectedID === -1) {
      return mainStore.toast.setErrorText("Please select a workspace.");
    }

    if (selectedMWIDs.length === 0) {
      return mainStore.toast.setErrorText(
        "Please select at least one module to send.",
      );
    }

    setIsCopying(true);
    try {
      await copyToModule(attachmentGroupID, selectedID, _.uniq(selectedMWIDs));
      mainStore.toast.setInfoText("Document successfully copied.");
      closeSlide();
    } catch {
      mainStore.toast.setErrorText("Something went wrong.");
    } finally {
      setIsCopying(false);
    }
  };

  const getModuleWorkspaces = async (workspaceID: number) => {
    setRequesting(true);
    setModuleWorkspaces([]);
    setSelectedMWIDs([]);
    const wsModuleWorkspaces =
      await WorkspacesAPI.getModuleWorkspaces(workspaceID);
    setModuleWorkspaces(wsModuleWorkspaces);
    setRequesting(false);
  };

  const setSelectedID = (workspaceID: number) => {
    setSelectedIDHelper(workspaceID);
    getModuleWorkspaces(workspaceID);
  };

  const renderWorkspaces = mainStore.workspaces.list.map((workspace) => {
    const { logo } = getWorkspaceLogo(workspace);
    const isSelected = workspace.id === selectedID;
    return (
      <li
        className={classNames("send-ws-select", {
          "send-ws-select__active": isSelected,
        })}
        key={`workspace-send-${workspace.id}`}
        onClick={() => {
          setSelectedID(workspace.id);
        }}
      >
        <label
          htmlFor={`workspace-select-${workspace.id}`}
          className="send-ws-select__label"
        >
          <img src={logo} alt="" className="send-ws-select__logo" />
          <Typography
            className={classNames({
              "send-ws-select__active": isSelected,
            })}
            label={workspace.name}
            size="md"
            weight="semiBold"
            color="generalMidnightDark"
          />
        </label>
        <div className="send-ws-select__input-wrapper">
          <input
            className="send-ws-select__input"
            type="radio"
            name="workspace"
            id={`workspace-send-${workspace.id}`}
            value={workspace.id}
            checked={isSelected}
            onChange={() => setSelectedID(workspace.id)}
          />
        </div>
      </li>
    );
  });

  const renderModules = moduleWorkspaces
    .filter(
      (moduleWorkspace) =>
        moduleWorkspace.added &&
        ALLOWED_MODULES.includes(moduleWorkspace.themis_module.identifier),
    )
    .map((moduleWorkspace) => {
      const isSelected = selectedMWIDs.includes(moduleWorkspace.id);
      return (
        <li
          className={classNames(
            "send-ws-select",
            "workspace-selection-mw-wrapper",
            { "send-ws-select__active": isSelected },
          )}
          key={`workspace-send-${moduleWorkspace.id}`}
          onClick={() => {
            handleMWIDSelection(moduleWorkspace.id);
          }}
        >
          <span className="send-ws-select__label">
            <img
              src={moduleIcons[moduleWorkspace.themis_module.identifier]}
              alt=""
              className="send-ws-select__logo"
            />
            <Typography
              className={classNames({
                "send-ws-select__active": isSelected,
              })}
              label={moduleWorkspace.name}
              size="md"
              weight="semiBold"
              color="generalMidnightDark"
            />
          </span>
          <span className="send-ws-select__input-wrapper">
            <input
              className={classNames(
                "send-ws-select__input",
                "send-ws-select__checkbox",
              )}
              type="checkbox"
              id={`workspace-send-${moduleWorkspace.id}`}
              value={moduleWorkspace.id}
              checked={isSelected}
              onChange={() => handleMWIDSelection(moduleWorkspace.id)}
            />
          </span>
        </li>
      );
    });

  const getButtonLabel = () => {
    if (requesting) {
      return "Loading";
    }

    if (isCopying) {
      return "Confirming";
    }

    return "Confirm";
  };

  return (
    <div className="table-email-dropdown">
      <div className="email-dropdown-head">
        <h3>Send to Workspace</h3>
        <div onClick={closeSlide}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      <hr />
      <div className="email-dropdown-wrap">
        <div className="creative-wrap">
          <span className="send-doc-helper-text">
            Select 1 workspace to share this document
          </span>
          <div className="workspace-selection-wrapper">
            <ul>{renderWorkspaces}</ul>
          </div>
          {selectedID !== -1 && (
            <div style={{ marginTop: "20px" }}>
              <hr />
              <div className="workspace-selection-wrapper">
                <span className="send-doc-helper-text">
                  Select modules to share this document
                </span>
                <ul>{renderModules}</ul>
              </div>
            </div>
          )}
        </div>
        <hr />
        <Button
          className="send-ws-confirm-button"
          label={getButtonLabel()}
          disabled={requesting || isCopying}
          onClick={copyAttachment}
        />
      </div>
      <hr />
    </div>
  );
}

export default observer(SendDocumentContent);

import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import ConfirmationDialog from "../shared/ConfirmationDialog";

interface Props {
  questionnaireID: number;
  externalID: string;
  additionalActions?: string[];
  allowDelete?: boolean;
  name?: string;
}

function QuestionnaireContextMenu({
  questionnaireID,
  name,
  additionalActions,
  allowDelete,
  externalID,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const { canDeleteRecords } = mainStore.userPermissions;
  const { moduleWorkspaceID, workspaceID } = mainStore.context;

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  async function copyLink() {
    await navigator.clipboard.writeText(
      `${location.host}/forms/vendor-questionnaires/${externalID}`,
    );
    mainStore.toast.setInfoText("Link copied!");
  }

  async function deleteRow() {
    await mainStore.vendors.deleteChecklist(
      moduleWorkspaceID,
      questionnaireID,
      true,
    );
    const toastSuccess = `“${name}” deleted!`;
    mainStore.toast.setInfoText(toastSuccess);
  }

  async function handleReopen() {
    setIsDropdownOpen(false);
    setIsOpenModal(false);

    await mainStore.vendors.reopenChecklist(moduleWorkspaceID, questionnaireID);
    const toastSuccess = `“${name}” reopened!`;
    mainStore.toast.setInfoText(toastSuccess);
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="vdd-questionnaires-context-menu-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown vdd-dropdown"
      data-testid="vdd-questionnaires-context-menu-content"
    >
      <ul>
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {additionalActions.includes("Edit") && (
          <li>
            <Link
              to={`/workspaces/${workspaceID}/modules/vendor-due-diligence/questionnaires/${questionnaireID}/update`}
              className="context-link"
            >
              Edit Questionnaire
            </Link>
          </li>
        )}
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {additionalActions.includes("Review") && (
          <li>
            <Link
              to={`/workspaces/${workspaceID}/modules/vendor-due-diligence/questionnaires/${questionnaireID}/complete`}
              className="context-link"
            >
              Review
            </Link>
          </li>
        )}
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {additionalActions.includes("Shareable") && (
          <li onClick={() => copyLink()}>Copy Link</li>
        )}
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {additionalActions.includes("Reopen") && (
          <li onClick={handleReopen}>Reopen</li>
        )}
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {allowDelete && canDeleteRecords && additionalActions.length > 0 && (
          <hr />
        )}
        {allowDelete && canDeleteRecords && (
          <li
            data-testid="vdd-questionnaires-context-menu-delete"
            onClick={deleteRowConfirmation}
          >
            Delete
          </li>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Questionnaire"
      content="Actions are non-reversable. Once deleted, it will also removed from Vendor side (If shared)."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  return (
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    Boolean(allowDelete || additionalActions.length) && (
      <Popup
        position="bottom right"
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => renderTrigger}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    )
  );
}

QuestionnaireContextMenu.defaultProps = {
  name: "[noname]",
  additionalActions: [],
  allowDelete: true,
};

export default observer(QuestionnaireContextMenu);

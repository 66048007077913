import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const StarSolid = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4435 19.4783L7.11534 21.8562C6.53071 22.1774 5.80762 21.9425 5.50026 21.3316C5.37787 21.0883 5.33564 20.8096 5.3801 20.5387L6.2067 15.5023C6.27324 15.0969 6.14462 14.6833 5.86275 14.3961L2.36122 10.8294C1.88825 10.3476 1.87857 9.55634 2.33961 9.06208C2.52319 8.86526 2.76375 8.73717 3.02403 8.69765L7.86302 7.96285C8.25255 7.9037 8.58928 7.64804 8.76349 7.27918L10.9276 2.69694C11.2199 2.07799 11.937 1.82386 12.5293 2.12933C12.7651 2.25097 12.956 2.45047 13.0724 2.69694L15.2365 7.27918C15.4107 7.64804 15.7474 7.9037 16.137 7.96285L20.9759 8.69765C21.6296 8.7969 22.0825 9.43109 21.9875 10.1141C21.9497 10.3861 21.8271 10.6375 21.6388 10.8294L18.1372 14.3961C17.8554 14.6833 17.7267 15.0969 17.7933 15.5023L18.6199 20.5387C18.7315 21.219 18.2943 21.8651 17.6433 21.9818C17.3841 22.0282 17.1174 21.9841 16.8846 21.8562L12.5565 19.4783C12.2081 19.2869 11.7919 19.2869 11.4435 19.4783Z"
      fill="#353549"
    />
  </svg>
);

StarSolid.displayName = "StarSolidIcon";

export default StarSolid;

import { LinkButton } from "@themis/ui";
import React from "react";
import { generatePath } from "react-router-dom";

import { WEBSITE_MONITORING_ROUTES } from "../pages";

export function ReviewButton({
  monitoringGroupAssetChildId,
}: {
  monitoringGroupAssetChildId: number;
}) {
  return (
    <LinkButton
      to={generatePath(
        `${WEBSITE_MONITORING_ROUTES.base}${WEBSITE_MONITORING_ROUTES.monitoringGroups}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildResults}`,
        {
          monitoringGroupAssetChildId,
        },
      )}
      size="lg"
      color="tertiary"
    >
      Review
    </LinkButton>
  );
}

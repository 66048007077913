import classNames from "classnames";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { userColors } from "@/components/constants";
import { formatDate, stringToDate } from "@/components/helpers/DateFormatters";
import { useMainStore } from "@/contexts/Store";
import closeIcon from "@/images/table-image/icon/close-black-icon.svg";

interface Props {
  emailSlideClose?: () => void;
  latestAttestationDate?: string;
  recordVersionID?: number;
  recordVersionName?: string;
}

function ShareAttestations({
  emailSlideClose,
  recordVersionID,
  recordVersionName,
  latestAttestationDate,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const messageRef = useRef(null);

  // State
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);

  const isAllChecked = useMemo(() => {
    if (selectedUserIDs.length === 0) {
      return false;
    }

    const userIds = mainStore.attestations.notAttestedUsers.map(
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      (user) => user.id,
    );
    return isEqual(selectedUserIDs.sort(), userIds.sort());
  }, [selectedUserIDs]);

  // Hooks
  useEffect(() => {
    if (recordVersionID) {
      mainStore.attestations.fetchNotAttested(recordVersionID);
    }
  }, [recordVersionID]);

  useEffect(() => {
    setButtonDisabled(selectedUserIDs.length === 0);
  }, [selectedUserIDs]);

  function onSend() {
    // Resend Attestation
    if (recordVersionID) {
      const attestationListParams = {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        message: messageRef.current.value,
        user_ids: selectedUserIDs,
      };
      mainStore.attestations.resend(recordVersionID, attestationListParams);
    }
  }

  const buttonClasses = classNames({ active: !buttonDisabled });

  function selectAllUsers(isChecked: boolean) {
    const ids: number[] = isChecked
      ? // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
        mainStore.attestations.notAttestedUsers.map((user) => user.id)
      : [];
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number[]' is not assignable to p... Remove this comment to see the full error message
    setSelectedUserIDs(ids);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function attestedUsersChange(event, id) {
    if (event.target.checked) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: never[]) => any[]' is not... Remove this comment to see the full error message
      setSelectedUserIDs((prev) => [...prev, id]);
    } else {
      const newUsersSelection = selectedUserIDs.filter(
        (element) => element !== id,
      );
      setSelectedUserIDs(newUsersSelection);
    }
  }

  return (
    <div
      className="table-dropdown table-email-dropdown"
      data-testid="resend-attestation"
    >
      <div className="email-dropdown-head">
        <h3>Resend Incomplete Attestations</h3>
        <div onClick={emailSlideClose}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      <div className="table-email-single-select">
        <div className="table-email-block">
          <div className="table-email-element">{recordVersionName}</div>
        </div>
      </div>
      <div className="recent-sent-date">
        <div className="recent-sent">
          <div className="date-attestation">
            Recent Sent Date: {formatDate(stringToDate(latestAttestationDate))}
          </div>
        </div>
      </div>
      <ul className="users-menu-attestation">
        <li>
          <div className="table-checkbox">
            <input
              type="checkbox"
              id="select all"
              onChange={(e) => selectAllUsers(e.target.checked)}
              checked={isAllChecked}
            />
            <label htmlFor="select all" />
          </div>
          <div className="status-users">All Users</div>
        </li>
        {mainStore.attestations.notAttestedUsers.map((user) => {
          const style = {
            // @ts-expect-error TS(2339) FIXME: Property 'is_contact' does not exist on type 'neve... Remove this comment to see the full error message
            background: !user.is_contact && userColors[user.icon_color_index],
            // @ts-expect-error TS(2339) FIXME: Property 'is_contact' does not exist on type 'neve... Remove this comment to see the full error message
            borderColor: user.is_contact && userColors[user.icon_color_index],
          };
          const usersClass = classNames("users-circle", {
            // @ts-expect-error TS(2339) FIXME: Property 'is_contact' does not exist on type 'neve... Remove this comment to see the full error message
            "users-circle-contact": user.is_contact,
          });

          return (
            // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
            <li key={`user-${user.id}`}>
              <div className="table-checkbox">
                <input
                  // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                  checked={selectedUserIDs.includes(user.id)}
                  type="checkbox"
                  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                  id={`user-${user.id}`}
                  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                  onChange={(event) => attestedUsersChange(event, user.id)}
                />
                {/* @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'. */}
                <label htmlFor={`user-${user.id}`} />
              </div>
              <div className="status-users">
                {/* @ts-expect-error TS(2322) FIXME: Type '{ background: string | false; borderColor: a... Remove this comment to see the full error message */}
                <span className={usersClass} style={style}>
                  {/* @ts-expect-error TS(2339) FIXME: Property 'initials' does not exist on type 'never'... Remove this comment to see the full error message */}
                  {user.initials}
                </span>
                {/* @ts-expect-error TS(2339) FIXME: Property 'greeting' does not exist on type 'never'... Remove this comment to see the full error message */}
                {user.greeting}
              </div>
            </li>
          );
        })}
      </ul>
      <hr />
      <div className="email-dropdown-wrap">
        <textarea
          ref={messageRef}
          placeholder="Email / Attestation Notes (Optional)"
        />
        <button
          className={buttonClasses}
          disabled={buttonDisabled}
          onClick={onSend}
        >
          Resend Attestations
        </button>
      </div>
      <hr />
    </div>
  );
}

export default observer(ShareAttestations);

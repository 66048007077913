import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@themis/ui";
import { observer } from "mobx-react";
import type { ChangeEvent } from "react";
import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { PiDownloadSimpleBold, PiUploadSimpleBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import Popup from "reactjs-popup";

import type { RecordType } from "@/api";
import { exportThemisRecords } from "@/api/gen/axios/exportsController/exportThemisRecords";
import { importThemisRecords } from "@/api/gen/axios/importsController/importThemisRecords";
import { Typography } from "@/components/Elements";
import ModalWrapper from "@/components/shared/ModalWrapper";
import { useMainStore } from "@/contexts/Store";

function ImportButton({ recordType }: { recordType: RecordType }) {
  const mainStore = useMainStore();
  const { formatMessage } = useIntl();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { companyID, workspaceID } = mainStore.context;

  const [showPopup, setShowPopup] = useState(false);
  const queryClient = useQueryClient();

  async function downloadTemplate() {
    if (!companyID) {
      return;
    }
    exportThemisRecords(companyID, recordType, { is_template: true });
  }

  async function processExcel(event: ChangeEvent<HTMLInputElement>) {
    setShowPopup(false);
    const { files } = event.target;
    if (!files || files.length === 0 || !companyID || !workspaceID) {
      return;
    }
    await importThemisRecords(
      companyID,
      recordType,
      { file: files[0], workspace_id: workspaceID },
      { headers: { "Content-Type": "multipart/form-data" } },
    );
    queryClient.invalidateQueries({
      queryKey: [recordType],
    });
  }

  const detailedMessage = (() => {
    switch (recordType) {
      case "projects":
        return "Fill in your Project details and then add your tasks details below the field headers.";
      default:
        return "Fill in your record details.";
    }
  })();

  const renderButtonTrigger = (
    <Button
      size="sm"
      color="tertiary"
      LeftIcon={PiUploadSimpleBold}
      data-testid="import-button"
    >
      Import
    </Button>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderButtonTrigger}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
      nested
    >
      <ModalWrapper width={400}>
        <div className="tw-flex tw-items-start">
          <div className="tw-mr-3 tw-flex tw-min-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-400 tw-text-white">
            1
          </div>
          <div>
            <div className="tw-mb-2">
              <Typography
                weight="semiBold"
                size="de"
                label="Download XLSX file"
                color="generalMidnightDark"
              />
            </div>
            <Button
              size="sm"
              color="tertiary"
              LeftIcon={PiDownloadSimpleBold}
              data-testid="import-button"
              onClick={downloadTemplate}
            >
              {formatMessage({ defaultMessage: "Download" })}
            </Button>
          </div>
        </div>
        <div className="tw-mt-5 tw-flex tw-items-start">
          <div className="tw-mr-3 tw-flex tw-min-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-400 tw-text-white">
            2
          </div>
          <div>
            <div className="tw-mb-2">
              <Typography
                weight="semiBold"
                size="de"
                label="Add / edit information in XLSX template"
                color="generalMidnightDark"
                className="tw-mb-2"
              />
            </div>
            <Typography size="de" label={detailedMessage} />
          </div>
        </div>
        <div className="tw-mt-5 tw-flex tw-items-start">
          <div className="tw-mr-3 tw-flex tw-min-h-5 tw-min-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-400 tw-text-white">
            3
          </div>
          <div>
            <div className="tw-mb-2">
              <Typography
                weight="semiBold"
                size="de"
                label="Import the updated file"
                color="generalMidnightDark"
                className="tw-mb-2"
              />
            </div>
            <DirectUploadProvider
              onSuccess={() => {}}
              render={() => (
                <Button
                  size="sm"
                  color="tertiary"
                  LeftIcon={PiUploadSimpleBold}
                  data-testid="import-button"
                  onClick={() => fileInputRef.current?.click()}
                >
                  Import
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={processExcel}
                    className="tw-hidden"
                  />
                </Button>
              )}
            />
          </div>
        </div>
      </ModalWrapper>
    </Popup>
  );
}

export default observer(ImportButton);

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { Link, useHistory, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import starIcon from "../../../images/table-image/icon/star-icon.svg";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ViewLink from "../shared/ViewLink";
import TemplateContextMenu from "./TemplateContextMenu";

const fields = [
  {
    id: 1,
    width: 946,
    data_type: "com.askthemis.types.v1.text",
    display_name: "Name",
    name: "name",
    is_user_editable: false,
  },
  {
    id: 2,
    width: 200,
    data_type: "com.askthemis.types.v1.integer",
    display_name: "Number of Questions",
    name: "number_of_questions",
    is_user_editable: false,
  },
];

interface Props {
  editable?: boolean;
  emptyQuestionnaires?: boolean;
}

function Templates({ editable, emptyQuestionnaires }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const history = useHistory();
  const { vendor_id: vendorID } = useParams<{ vendor_id: string }>();
  const { active_workspace: workspace } = mainStore.users.user;
  const { workspaceID } = mainStore.context;
  const { data } = mainStore.vendors;
  // @ts-expect-error TS(2339) FIXME: Property 'templates' does not exist on type '{}'.
  const templates = data?.templates || [];

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'templateID' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleTemplateClick(templateID) {
    if (editable) {
      history.push(
        `/workspaces/${workspaceID}/modules/vendor-due-diligence/templates/${templateID}/update`,
      );
    } else {
      history.push(
        `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/templates/${templateID}/use`,
      );
    }
  }

  // Renderers
  const renderColumns = fields.map((field) => (
    <ModuleTableColumn key={field.id} field={field} disableResize />
  ));

  const templatesWrapper = classNames(
    "vdd-page-content vdd-templates-wrapper",
    {
      "without-first-block": !editable,
    },
  );
  const templatesListWrap = classNames("table-list-wrap", {
    "questionnaires-templates": !emptyQuestionnaires,
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'template' implicitly has an 'any' type.
  const renderRows = templates.map((template) => (
    <div key={template.id} className="list-table">
      <ul>
        <div className="list-table-wrap">
          <div className="list-table-block">
            <li
              style={{ width: fields[0].width }}
              data-testid="vdd-templates-name-cell"
              onClick={() => handleTemplateClick(template.id)}
            >
              <div className="cell-content">{template.name}</div>
            </li>
            <li
              style={{ width: fields[1].width }}
              data-testid="vdd-templates-questions-cell"
            >
              <div className="cell-content">
                {template.checklist_questions.length}
              </div>
            </li>
            <span className="stretch-cell" />
          </div>
          {editable ? (
            <TemplateContextMenu
              templateID={template.id}
              // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
              moduleWorkspaceID={workspace.id}
            />
          ) : (
            <div className="list-points" data-testid="vdd-templates-use">
              <ViewLink
                url={`/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/templates/${template.id}/use`}
                data-testid="vdd-templates-use"
                label="Use"
              />
            </div>
          )}
        </div>
      </ul>
    </div>
  ));

  return (
    <>
      <AddRecordHeader
        addRecord={() =>
          history.push(
            `/workspaces/${workspaceID}/modules/vendor-due-diligence/templates/create`,
          )
        }
        recordName="Template"
      />
      <div className={templatesWrapper} data-testid="vdd-templates-container">
        <div className={templatesListWrap} data-testid="vdd-templates-table">
          <h4
            className="questionnaires-title"
            data-testid="vendor-view-templates-title"
          >
            Available Templates
          </h4>
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="list-title-table-wrap">
                  {renderColumns}
                  <span className="stretch-cell" />
                </div>
                <div className="list-plus">
                  <a href="#" />
                </div>
              </div>
            </ul>
          </div>
          {renderRows}
          {editable && (
            <div
              className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
              onClick={() =>
                history.push(
                  `/workspaces/${workspaceID}/modules/vendor-due-diligence/templates/create`,
                )
              }
              data-testid="vdd-templates-add-new-button"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">Add Template...</div>
            </div>
          )}
          {!editable && (
            <div
              className="templates-footer"
              data-testid="vdd-templates-add-new-link"
            >
              <img src={starIcon} alt="star-icon" />
              To create new templates,
              <Link
                to={`/workspaces/${workspaceID}/modules/vendor-due-diligence/templates`}
              >
                click here
              </Link>
              to return to Vendor Due Diligence &gt; Templates tab
            </div>
          )}
        </div>
      </div>
    </>
  );
}

Templates.defaultProps = {
  editable: true,
};

export default observer(Templates);

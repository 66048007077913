import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  TextInput,
  useToast,
} from "@themis/ui";
import { snakeCase } from "lodash";
import { useState } from "react";
import { useIntl } from "react-intl";

import { useCreateFieldOption } from "@/api/queries/create-field-option";

interface CreateAccountTypeDialogProps {
  accountTypeFieldConfigId: number;
  onClose: () => void;
  onCreate: (key: string) => void;
}

export function CreateAccountTypeDialog({
  accountTypeFieldConfigId,
  onClose,
  onCreate,
}: CreateAccountTypeDialogProps) {
  const [newItemInputValue, setNewItemInputValue] = useState("");
  const toast = useToast();
  const intl = useIntl();

  const { mutateAsync: createFieldOption, isPending } = useCreateFieldOption(
    accountTypeFieldConfigId,
  );

  const handleCreate = async () => {
    try {
      const newFieldOptionKey = snakeCase(newItemInputValue);

      const { data: createdOption } = await createFieldOption({
        option: {
          value: newItemInputValue,
          key: newFieldOptionKey,
        },
      });

      onCreate(createdOption.key);

      toast({
        variant: "success",
        content: "Account type created successfully.",
      });

      onClose();
    } catch {
      toast({
        variant: "error",
        content: "Something went wrong. Could not create the new account type.",
      });
    }
  };

  return (
    <Dialog open>
      <DialogContent
        className="tw-h-[174px] tw-w-[424px] tw-rounded-md tw-p-4"
        onClose={onClose}
        onEscapeKeyDown={onClose}
      >
        <DialogHeader className="tw-pb-2">
          <DialogTitle>
            {intl.formatMessage({
              defaultMessage: "Create New Account Type",
              description: "Title for the creation dialog",
            })}
          </DialogTitle>
        </DialogHeader>
        <TextInput
          className="tw-mb-3"
          placeholder="Account type examples “Bank” or “SaSS”..."
          value={newItemInputValue}
          onChange={(event) => {
            setNewItemInputValue(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleCreate();
            }
          }}
        />
        <DialogFooter>
          <Button
            size="lg"
            onClick={handleCreate}
            disabled={!newItemInputValue}
            loading={isPending}
          >
            {intl.formatMessage({
              defaultMessage: "Create",
              description: "Create button text",
            })}
          </Button>
          <Button size="lg" color="tertiary" onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: "Cancel",
              description: "Cancel button text",
            })}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import "./Workspaces.scss";

import { observer } from "mobx-react";
import React from "react";

import { Accordion } from "@/components/Elements/Accordion";
import CompanySettingsNavigation from "@/components/settings/CompanySettingsNavigation";
import WorkspaceRow from "@/components/settings/Workspaces/components/WorkspaceRow";
import Table from "@/components/table/Table";
import { useMainStore } from "@/contexts/Store";

function Workspaces() {
  const mainStore = useMainStore();

  const archivedWorkspaces = mainStore.workspaces.list.filter(
    (workspace) => workspace.is_archived,
  );

  const unArchivedWorkspaces = mainStore.workspaces.list.filter(
    (workspace) => !workspace.is_archived,
  );

  return (
    <Table>
      <div className="settings-wrap company-users-settings-container">
        <CompanySettingsNavigation />
        <div className="settings-workspace__content">
          <div className="settings-workspace__grid">
            {unArchivedWorkspaces.map((workspace) => (
              <WorkspaceRow key={workspace.id} workspace={workspace} />
            ))}
          </div>
          {!!archivedWorkspaces.length && (
            <div className="settings-workspace__accordion-wrapper">
              <Accordion title="Archived Workspaces">
                <div className="settings-workspace__grid settings-workspace__grid--no-padding">
                  {archivedWorkspaces.map((workspace) => (
                    <WorkspaceRow key={workspace.id} workspace={workspace} />
                  ))}
                </div>
              </Accordion>
            </div>
          )}
        </div>
      </div>
    </Table>
  );
}

export default observer(Workspaces);

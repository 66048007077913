import { isEqual } from "lodash";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import type { Filter, FilterOption } from "@/components/filters/types";
import { moduleDataLoader } from "@/components/helpers/moduleDataLoader";
import { metricsDashboardUrl } from "@/components/reports/common/MetricsPage/urlPaths";
import { useMainStore } from "@/contexts/Store";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import { useModuleDetection } from "./useModuleDetection";

const NUMBER_OF_FILTERS_ALLOWED = 3;

// @ts-expect-error TS(7006) FIXME: Parameter 'array' implicitly has an 'any' type.
export const arrayToQueryString = (array) => {
  return `tableFilters=${encodeURIComponent(JSON.stringify(array))}`;
};

export const useUpdateFilter = () => {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();
  const location = useLocation();
  const { state } = useLocation<{ from?: string }>();

  // Variables
  const urlParams = new URLSearchParams(location.search);
  const currentFilters = urlParams.get("tableFilters")
    ? // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      JSON.parse(decodeURIComponent(urlParams.get("tableFilters")))
    : [];

  const identifier = useModuleDetection(location);

  const urlWithFiltersParam = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'filters' implicitly has an 'any' type.
    (filters) => {
      let newURL;
      newURL = `${location.pathname}?${arrayToQueryString(filters)}`;
      mainStore.filters.setCurrentFilterURL(
        filters.length ? arrayToQueryString(filters) : "",
      );

      if (filters.length === 0) {
        newURL = location.pathname;
      }

      if (newURL === location.pathname + location.search) {
        return;
      }

      return newURL;
    },
    [location.pathname, location.search],
  );

  const setTableFiltersParam = useCallback(
    (
      // @ts-expect-error TS(7006) FIXME: Parameter 'filters' implicitly has an 'any' type.
      filters,
      fromHomePage = false,
      shouldClear = false,
      forceUpdate = false,
    ) => {
      const newURL = urlWithFiltersParam(filters);
      const fromMetricsPage = state?.from?.includes(metricsDashboardUrl);
      const { activeWorkspace, tableName } = mainStore.context;

      if (shouldClear !== true && filters?.length === 0) {
        return;
      }

      if (!fromHomePage && isEqual(currentFilters, filters)) {
        return;
      }

      if (
        window.location.pathname !== "/" &&
        !window.location.pathname.includes("/modules")
      ) {
        return;
      }

      if (forceUpdate || !(state && fromMetricsPage)) {
        history.replace(newURL as string);
      }

      if (window.location.pathname.includes("/metrics/")) {
        return;
      }

      // issue_management, change_management & audit have no index call in moduleDataLoader
      if (identifier === TopLevelModule.CHANGE_MANAGEMENT) {
        mainStore.changeManagements.index({
          workspaceID: activeWorkspace?.id,
          tableFilters: filters,
        });
        // prevent issue management index call when coming from metrics page
      } else if (
        identifier === TopLevelModule.ISSUE_MANAGEMENT &&
        (forceUpdate || !fromMetricsPage)
      ) {
        mainStore.issueManagement.index({
          workspaceID: activeWorkspace?.id,
          tableFilters: filters,
          pages: { 0: 1 },
        });
      } else if (identifier === TopLevelModule.AUDITS) {
        mainStore.themisAudits.index({
          workspaceID: activeWorkspace?.id,
          tableFilters: filters,
        });
      } else if (identifier) {
        moduleDataLoader(identifier, tableName, mainStore, filters);
      }
    },
    [urlWithFiltersParam, history],
  );

  const getTableFiltersParam = useCallback(() => {
    const tableFilters = new URLSearchParams(location.search).get(
      "tableFilters",
    );

    return tableFilters ? JSON.parse(tableFilters) : [];
  }, [location.search]);

  const clearTableFilters = () => {
    setTableFiltersParam([], false, true);
    mainStore.filters.setCurrentFilterURL("");
  };

  const isFilterOptionPresent = (option: FilterOption, fieldName: string) => {
    const hasDisputeAmountFinra =
      fieldName === "dispute_amount" && identifier === "finra";

    const checkedValue = getTableFiltersParam().find(
      (filter: Filter) => filter.name === fieldName,
    )?.options;

    return checkedValue?.find(
      hasDisputeAmountFinra
        ? (item: FilterOption) => item.label === option.label
        : (item: FilterOption) => item.value === option.value,
    );
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const isFieldGroupPresent = (field) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
    return getTableFiltersParam().find((filter) => filter.name === field.name);
  };

  const canAddFilter = useMemo(() => {
    return getTableFiltersParam().length < NUMBER_OF_FILTERS_ALLOWED;
  }, [getTableFiltersParam]);

  return {
    setTableFiltersParam,
    getTableFiltersParam,
    clearTableFilters,
    isFilterOptionPresent,
    isFieldGroupPresent,
    currentFilters,
    canAddFilter,
  };
};

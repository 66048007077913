import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Share = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 4.8C5.00147 4.8 4.8 5.00147 4.8 5.25V18.75C4.8 18.9985 5.00146 19.2 5.25 19.2H18.75C18.9985 19.2 19.2 18.9985 19.2 18.75V14.4632C19.2 13.9661 19.6029 13.5632 20.1 13.5632C20.5971 13.5632 21 13.9661 21 14.4632V18.75C21 19.9927 19.9927 21 18.75 21H5.25C4.00737 21 3 19.9927 3 18.75V5.25C3 4.00736 4.00736 3 5.25 3H9.3C9.79706 3 10.2 3.40294 10.2 3.9C10.2 4.39706 9.79706 4.8 9.3 4.8H5.25ZM12 3.9C12 3.40294 12.4029 3 12.9 3H20.1C20.5971 3 21 3.40294 21 3.9V11.1C21 11.5971 20.5971 12 20.1 12C19.6029 12 19.2 11.5971 19.2 11.1V6.07279L13.4464 11.8264C13.0949 12.1779 12.5251 12.1779 12.1736 11.8264C11.8221 11.4749 11.8221 10.9051 12.1736 10.5536L17.9272 4.8H12.9C12.4029 4.8 12 4.39706 12 3.9Z"
      fill="#353549"
    />
  </svg>
);

Share.displayName = "ShareIcon";

export default Share;

import { observer } from "mobx-react";
import React from "react";
import { useParams } from "react-router-dom";

import { exportedFilesService } from "@/api";
import logo from "@/images/logo.svg";

function FileExportDownload() {
  const { exported_file_id } = useParams<{ exported_file_id: string }>();

  async function download() {
    const exportedFile = await exportedFilesService.getExportedFile(
      parseInt(exported_file_id),
    );
    const link = document.createElement("a");
    link.href = exportedFile?.url;
    link.download = `themis_file_export.zip`;
    link.click();
  }

  return (
    <div className="attestation-view">
      <img src={logo} alt="logo" className="logo" />
      <div className="attestation-view-wrap">
        <h2>Click the button below to download your file.</h2>
        <button className="table-button" onClick={download}>
          Download
        </button>
      </div>
    </div>
  );
}

export default observer(FileExportDownload);

import { Button } from "@themis/ui";
import React from "react";
import { useForm } from "react-hook-form";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import type { Contract } from "@/api";
import { CONTRACT_DETAILS_PATH } from "@/features/accounts/pages";

import { contractsFields } from "../constants";
import ContractsRowFieldRenderer from "./ContractsRowFieldRenderer";

function ContractRow({ contract }: { contract: Contract }) {
  const { control } = useForm<Contract>({
    defaultValues: {
      account_name: contract.account_name,
      renewal: contract.renewal,
      file: contract.file,
      start_date: contract.start_date,
      end_date: contract.end_date,
      review_date: contract.review_date,
      notes: contract.notes,
      terms: contract.terms,
    },
  });

  const history = useHistory();
  const { pathname } = useLocation();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  function editClick() {
    const contractsPath = generatePath(CONTRACT_DETAILS_PATH, {
      workspace_id,
      accountId: contract.account_id,
      contractId: contract.id,
    });

    const params = new URLSearchParams({
      redirectTo: pathname,
    }).toString();

    history.push(`${contractsPath}?${params}`);
  }

  return (
    <form>
      <div className="list-table">
        <ul>
          <div className="list-table-wrap">
            <div className="list-table-block">
              {contractsFields.map((field) => {
                return (
                  <ContractsRowFieldRenderer
                    key={field.field_name}
                    fieldData={field}
                    control={control}
                  />
                );
              })}
              <span className="stretch-cell" />
            </div>
          </div>

          <div className="list-points">
            <Button
              color="tertiary"
              size="md"
              onClick={editClick}
              data-testid="edit-contract-button-vdd"
            >
              Edit
            </Button>
          </div>
        </ul>
      </div>
    </form>
  );
}

export default ContractRow;

import React from "react";
import { Button } from "../Button/Button";
import { Popover, PopoverAnchor, PopoverContent, PopoverTrigger, } from "./Popover";
export function ConfirmationPopup(_a) {
    var align = _a.align, anchor = _a.anchor, children = _a.children, content = _a.content, open = _a.open, title = _a.title, onCancel = _a.onCancel, onConfirm = _a.onConfirm, confirmText = _a.confirmText, cancelText = _a.cancelText, sideOffset = _a.sideOffset;
    var Component = anchor ? PopoverAnchor : PopoverTrigger;
    return (React.createElement(Popover, { open: open },
        React.createElement(Component, null, children),
        React.createElement(PopoverContent, { align: align, onEscapeKeyDown: onCancel, sideOffset: sideOffset },
            React.createElement("div", { className: " tw-box-border tw-flex tw-min-w-72 tw-max-w-80 tw-flex-col tw-gap-1 tw-px-4 tw-py-3 tw-font-sans" },
                React.createElement("h4", { className: "tw-text-primaryDim-400" }, title),
                React.createElement("div", null, content)),
            React.createElement("div", { className: "tw-h-px tw-bg-neutral-100" }),
            React.createElement("div", { className: "tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-3" },
                React.createElement(Button, { onClick: onConfirm }, confirmText || "Yes"),
                onCancel && (React.createElement(Button, { color: "tertiary", onClick: onCancel }, cancelText || "No"))))));
}

import type { AxiosResponse } from "axios";

import api from "../api";
import type {
  APIKey,
  APIKeyRuleCreateUpdate,
  APIKeys,
  APIKeysSuccessResponse,
} from "./types";

export async function getApiKeys() {
  const response = await api.get<APIKeys>("/api_keys");

  return response.data;
}

export async function createApiKey(title: string) {
  const response = await api.post<
    APIKeyRuleCreateUpdate,
    AxiosResponse<APIKey>
  >("/api_keys", {
    api_key: {
      title,
    },
  });

  return response.data;
}

export async function updateApiKey(apiKeyID: number, title: string) {
  const response = await api.put<APIKeyRuleCreateUpdate, AxiosResponse<APIKey>>(
    `/api_keys/${apiKeyID}`,
    {
      api_key: {
        title,
      },
    },
  );

  return response.data;
}

export async function deleteApiKey(id: number) {
  const response = await api.delete<
    never,
    AxiosResponse<APIKeysSuccessResponse>
  >(`/api_keys/${id}`);

  return response.data.success;
}

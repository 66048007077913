import type { AxiosResponse } from "axios";
import { find } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";

import { workspacesService } from "@/api/gen/axios";
import legacyApi from "@/api/legacy/legacy-api";
import { WorkspacesAPI } from "@/api/legacy/workspaces";
import type { WorkspaceCreateRequest } from "@/api/legacy/workspaces/workspaces";
import { API_URL } from "@/components/constants";
import type { MainStore } from "@/stores/Main";
import type { Workspace } from "@/stores/types/workspace-types";

export default class WorkspacesStore {
  mainStore: MainStore;
  list: Array<Workspace> = [];
  isSwitchingWorkspace: boolean = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cleanWorkspace(workspaceIDData?: number | any) {
    const workspaceID =
      this.mainStore.context.activeWorkspace?.id || workspaceIDData;

    this.mainStore.context.setModuleWorkspaceID(null);
    this.mainStore.context.setTableID(null);
    this.mainStore.complaints.cleanup();
    this.mainStore.creatives.cleanup();

    this.mainStore.moduleWorkspaces.index(workspaceID);
  }

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      isSwitchingWorkspace: observable,
      setList: action,
      isCollaborativeOnly: computed,
      collaborativeWorkspaces: computed,
      isCurrentWorkspaceArchived: computed,
      isCurrentWorkspaceActive: computed,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/workspaces
  async index(): Promise<Workspace[]> {
    const response: AxiosResponse<{
      workspaces: Array<Workspace>;
    }> = await legacyApi({
      method: "GET",
      url: `${API_URL}/workspaces`,
      headers: this.mainStore.getHeaders(),
    });

    const { workspaces } = response.data;

    this.setList(
      workspaces.map((workspace) => ({
        ...workspace,
      })),
    );

    this.mainStore.context.setActiveWorkspace(
      this.list.find(
        (workspace) =>
          workspace.id === this.mainStore.users.user.active_workspace?.id,
      ) || null,
    );

    this.cleanWorkspace();

    return workspaces;
  }

  /**
   * POST /api/react/companies/:company_id/workspaces
   */
  async create(companyID: number, data: WorkspaceCreateRequest) {
    try {
      const response = await WorkspacesAPI.createWorkspace(companyID, data);

      // Reload the list of Workspaces, so the new one shows up
      this.mainStore.workspaces.index();

      // Switch User to newly created Workspace
      this.changeToNewWorkspace(response.workspace);

      return response.workspace;
    } catch (error) {
      window.console.error(`"Workspaces#create" error ${error}`);
    }
  }

  /**
   * PUT /api/react/workspaces/:id
   **/
  async update(workspaceID: number, workspaceParams: Partial<Workspace>) {
    try {
      const response = await WorkspacesAPI.updateWorkspace(
        workspaceID,
        workspaceParams,
      );

      this.mainStore.context.setActiveWorkspace(response.workspace);

      this.index();
    } catch (error) {
      window.console.error(`Workspaces#update error ${error}`);
    }
  }

  /**
   * PUT /api/react/workspaces/:id/archive
   */
  async archive(workspaceID: number) {
    try {
      await workspacesService.archiveWorkspace(workspaceID);

      this.index();
    } catch (error) {
      window.console.error(`Workspaces#archive error ${error}`);
    }
  }

  /**
   * PUT /api/react/workspaces/:id/unarchive
   */
  async unarchive(workspaceID: number) {
    try {
      await workspacesService.unarchiveWorkspace(workspaceID);

      this.index();
    } catch (error) {
      window.console.error(`Workspaces#unarchive error ${error}`);
    }
  }

  // DELETE /api/react/workspaces/:id
  async delete(workspaceID: number) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/workspaces/${workspaceID}`,
        headers: this.mainStore.getHeaders(),
      });

      this.mainStore.context.setActiveWorkspace(null);

      this.index();
    } catch (error) {
      window.console.log(`Workspaces#delete error ${error}`);
    }
  }

  // PUT /api/react/workspaces/:id/switch
  async switch(workspaceID: string | number) {
    this.isSwitchingWorkspace = true;
    try {
      this.mainStore.context.setActiveWorkspace(
        this.list.find((workspace) => workspace.id === Number(workspaceID)) ||
          null,
      );
      const response: AxiosResponse<
        | undefined
        | {
            company: object;
            user: object;
          }
      > = await legacyApi({
        method: "PUT",
        url: `${API_URL}/workspaces/${workspaceID}/switch`,
        headers: this.mainStore.getHeaders(),
      });

      // Set updated User & Company
      if (response.data?.company) {
        this.mainStore.companies.setCompany(response.data?.company);
      }
      if (response.data?.user) {
        this.mainStore.users.setUser(response.data?.user);
      }
      this.cleanWorkspace(workspaceID);
    } catch (error) {
      window.console.log(`"Workspaces#switch" error ${error}`);
    } finally {
      this.isSwitchingWorkspace = false;
    }
  }

  // POST /api/react/workspaces/:id/duplicate
  async duplicate(
    workspaceID: string,
    params: {
      name: string;
      is_template: boolean;
      module_workspaces: { [id: string]: { duplicate_records: boolean } };
    },
  ) {
    try {
      const response: AxiosResponse<{ workspace: Workspace }> = await legacyApi(
        {
          method: "POST",
          url: `${API_URL}/workspaces/${workspaceID}/duplicate`,
          headers: this.mainStore.getHeaders(),
          data: params,
        },
      );

      await this.changeToNewWorkspace(response.data.workspace);
      window.location.reload();
      this.mainStore.toast.setInfoText("Workspace duplicated!");
    } catch (error) {
      this.mainStore.toast.setInfoText(
        "Something went wrong, please try again!",
      );
      window.console.log(`"Workspaces#duplicate" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setList(value) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  // computed
  get isCollaborativeOnly() {
    const hasInternal = find(this.list, (workspace) => workspace.is_internal);
    return this.list.length > 0 && !hasInternal;
  }

  get collaborativeWorkspaces() {
    return this.list.filter(
      (workspace) => !workspace.is_internal && !workspace.is_archived,
    );
  }

  get isCurrentWorkspaceArchived() {
    return this.mainStore.context.activeWorkspace?.is_archived;
  }

  get isCurrentWorkspaceActive() {
    return !this.mainStore.context.activeWorkspace?.is_archived;
  }

  // Store Helpers

  async changeToNewWorkspace(workspace: Workspace) {
    // Switch to newly created workspace
    await this.switch(workspace.id);
  }

  cleanup() {
    this.setList([]);
  }
}

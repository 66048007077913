import { action, makeObservable, observable } from "mobx";

export default class PageLoadingStore {
  mainStore;

  isLoading = false;

  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  constructor(mainStore) {
    makeObservable(this, {
      isLoading: observable,
      startLoading: action,
      endLoading: action,
    });

    this.mainStore = mainStore;
  }

  startLoading() {
    this.isLoading = true;
  }

  endLoading() {
    this.isLoading = false;
  }

  // Store Helpers

  cleanup() {
    this.endLoading();
  }
}

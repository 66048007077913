import type { QuestionRead } from "@/features/risk-assessment/types/questionnaire";
import {
  QuestionnaireStatus,
  QuestionType,
} from "@/features/risk-assessment/types/questionnaire";
import type { DocumentAnswerRead } from "@/features/risk-assessment/types/questionnaire-form";

export function getQuestionnaireStatusLabel(
  status: string | null,
  percentComplete: number,
) {
  switch (status) {
    case QuestionnaireStatus.NOT_STARTED:
      return "Not started";
    case QuestionnaireStatus.INCOMPLETE:
      return percentComplete === 1
        ? "In progress - not submitted"
        : "In progress";
    case QuestionnaireStatus.READY_FOR_REVIEW:
      return "Ready for review";
    case QuestionnaireStatus.IN_REVIEW:
      return "In review";
    case QuestionnaireStatus.FINALIZED:
      return "Finalized";
    default:
      return "- N/A -";
  }
}

export function isQuestionAnswered(question: QuestionRead) {
  switch (question.input_type) {
    case QuestionType.RADIO:
    case QuestionType.YES_NO:
      return !!question.answer;
    case QuestionType.DOCUMENTS:
      return (
        (question.answer as DocumentAnswerRead)?.document_files?.length > 0
      );
    case QuestionType.TEXT:
    default:
      return false;
  }
}

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

interface MatrixCell {
  title: string;
  value: number;
  color: string;
}

interface Props {
  fieldName: string;
  width: number | string;
  locked?: boolean;
  pinned?: boolean;
  matrixCell: MatrixCell;
}

function RiskRegisterScoringValueCell({
  fieldName,
  width,
  locked,
  pinned,
  matrixCell,
}: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const riskType =
    fieldName === "residual_risk_rating" ? "residual" : "inherent";
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Variables
  const style = { width };
  const liClassNames = classNames(
    "cell column-options-cell rr-table-scoring-cell",
    {
      "read-only": isCurrentWorkspaceActive,
      "table-cell--disabled": isReadOnly,
      "locked-cell": locked && isCurrentWorkspaceActive,
      "pointer-events-none": locked || isReadOnly,
      pinned,
    },
  );

  // elements
  const renderError = (
    <Link
      to={`/workspaces/${workspaceID}/modules/risk-register-scoring-${riskType}`}
      className="options-placeholder nodata error-link"
    >
      Invalid Rating for Matrix
    </Link>
  );

  const renderCellContent = () => {
    if (!matrixCell?.title) {
      return renderError;
    }
    const cellStyle = {
      backgroundColor: matrixCell.color,
    };

    return (
      <div
        className="options column-options-item"
        data-testid="column-options-item"
      >
        <span
          className="value value-type rr-table-scoring-cell-content"
          data-testid="column-options-value"
          style={cellStyle}
        >
          {`${matrixCell.title} - ${matrixCell.value}`}
        </span>
      </div>
    );
  };

  return (
    <li
      className={liClassNames}
      style={style}
      data-testid="column-options-trigger"
    >
      <div className="cell-content">
        <div
          className="options column-options-container"
          data-testid="column-options-container"
        >
          {renderCellContent()}
        </div>
      </div>
    </li>
  );
}

export default observer(RiskRegisterScoringValueCell);

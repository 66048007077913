import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListTablesPathParams,
  ListTablesQueryResponse,
} from "../../models/ListTables";

/**
 * @description List tables
 * @summary List tables
 * @link /module_workspaces/:module_workspace_id/tables
 */
export async function listTables(
  moduleWorkspaceId: ListTablesPathParams["module_workspace_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListTablesQueryResponse>["data"]> {
  const res = await client<ListTablesQueryResponse>({
    method: "get",
    url: `/module_workspaces/${moduleWorkspaceId}/tables`,
    ...options,
  });
  return res.data;
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetTaskPathParams,
  GetTaskQueryParams,
  GetTaskQueryResponse,
} from "../../models/GetTask";

/**
 * @description Get task
 * @summary Get task
 * @link /tasks/:id
 */
export async function getTask(
  id: GetTaskPathParams["id"],
  params?: GetTaskQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetTaskQueryResponse>["data"]> {
  const res = await client<GetTaskQueryResponse>({
    method: "get",
    url: `/tasks/${id}`,
    params,
    ...options,
  });
  return res.data;
}

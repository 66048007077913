import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getRecordName } from "@/components/helpers/nameForThemisModuleIdentifier";
import { useMainStore } from "@/contexts/Store";
import { pathMatchesPattern } from "@/utils/routing";

import arrowLeft from "../../../images/table-image/icon/arrow-left-blue.svg";
import { ActionButtonPopup } from "../../table/menu-actions/components/ActionButtonPopup";
import Spinner from "../../table/shared/Spinner";

function SaveVendorOnExit() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Variables
  const { active_workspace: workspace } = mainStore.users.user;
  const { workspaceID } = mainStore.context;
  const { currentVendor } = mainStore.vendors;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName(
    "vendor_due_diligence",
    moduleWorkspaces,
    true,
  );
  const searchParams = new URLSearchParams(location.search);
  const grouping = searchParams.get("grouping");

  const isAddNewVendorPath = pathMatchesPattern(
    location.pathname,
    "/workspaces/:workspace_id/modules/add-new-vendor",
  );

  const onClickTrigger = () => {
    if (!isAddNewVendorPath) {
      history.push(
        `/workspaces/${workspaceID}/modules/vendor-due-diligence/active`,
      );
    }
  };

  const handleCancel = () => {
    setShowPopup(false);
    history.push(
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/active`,
    );
  };

  async function handleSave() {
    setShowPopup(false);
    setIsSaving(true);

    const data = {
      ...currentVendor,
      ...(grouping && { grouping: { options: [grouping] } }),
    };
    // @ts-expect-error TS(2339) FIXME: Property 'other_documents' does not exist on type ... Remove this comment to see the full error message
    data.other_documents = currentVendor.other_documents?.map(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) => item.id,
    );
    const files = [];
    for (const key of Object.keys(data) as Array<keyof typeof data>) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (data[key]?.customFile === true) {
        files.push(data[key]);
        delete data[key];
      }
    }

    const { errors } = await mainStore.vendors.create(
      workspace?.id,
      data,
      true,
    );

    if (errors) {
      mainStore.toast.setErrorText(errors.base[0]);
      setIsSaving(false);
    } else {
      mainStore.toast.setInfoText(`${recordName} saved!`);
      mainStore.vendors.setCurrentVendor({});

      history.push(
        `/workspaces/${workspaceID}/modules/vendor-due-diligence/active`,
      );
    }
  }

  return (
    <ActionButtonPopup
      showPopup={showPopup}
      trigger={
        <div
          className="archive-head-link"
          onMouseUp={onClickTrigger}
          data-testid="vendor-back-button"
        >
          {isSaving ? (
            <Spinner />
          ) : (
            <a>
              <img
                data-testid="vendor-back-button-img"
                src={arrowLeft}
                alt="arrow-left-icon"
              />
            </a>
          )}
        </div>
      }
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      title="Save Now?"
      text="Do you want to save changes?"
      onCancel={handleCancel}
      onConfirm={handleSave}
      height="108px"
      width="272px"
      disabled={!isAddNewVendorPath}
    />
  );
}

export default observer(SaveVendorOnExit);

import { Dashboards, OverdueCharts } from "./types";
import {
  createdDateDashboardUrl,
  overdueDashboardUrl,
  statusDashboardUrl,
  timeToCloseDashboardUrl,
} from "./urlPaths";

export const TIME_RANGE_OPTIONS = {
  "Last 7 Days": 7 - 1,
  "Last 30 Days": 30 - 1,
  "Last 90 Days": 90 - 1,
  "Last 365 Days": 365 - 1,
  "All Time": null,
};

export const INTERVAL_OPTIONS = {
  Daily: "day",
  Weekly: "week",
  Monthly: "month",
  Quarterly: "quarter",
  Yearly: "year",
  Auto: null,
};

export const labelColors = [
  "#2F76B6",
  "#5CB9E9",
  "#E07C44",
  "#BD3F24",
  "#429788",
];

export const tableHeader = {
  open: "New",
  closed: "Closed",
  reopened: "Re-opened",
  it_incident: "IT Incident",
  "N/A": "N/A",
  HR: "HR",
  IT: "IT",
  average_days_open: "Average Days Open",
  issues_closed: "Issues Closed",
};

export const dashboardDisplayNames = {
  [Dashboards.STATUS]: "Issues by Status",
  [Dashboards.CREATED_DATE]: "Issues by Created Date",
  [Dashboards.OVERDUE]: "Overdue Issues",
  [Dashboards.TIME_TO_CLOSE]: "Time to Close",
};
export const mapUrlToDashboard: Record<string, Dashboards> = {
  [statusDashboardUrl]: Dashboards.STATUS,
  [createdDateDashboardUrl]: Dashboards.CREATED_DATE,
  [overdueDashboardUrl]: Dashboards.OVERDUE,
  [timeToCloseDashboardUrl]: Dashboards.TIME_TO_CLOSE,
};

export const naColor = "#BBBBBB";

export const defaultAllTimeStartDate = new Date("01/01/2020");

export const overdueDashboardDays = {
  "90+ days": {
    min: 90,
    max: null,
  },
  "30-90 days": {
    min: 30,
    max: 90,
  },
  "10-30 days": {
    min: 10,
    max: 30,
  },
  "5-10 days": {
    min: 5,
    max: 10,
  },
  "1-5 days": {
    min: 1,
    max: 5,
  },
  "0-1 days": {
    min: 0,
    max: 1,
  },
};

export const chartTitles = {
  [OverdueCharts.COMING_DUE]:
    "At Risk: Issue Count by Number of Days Until Due Date",
  [OverdueCharts.OVERDUE]:
    "Overdue: Issue Count by Number of Days Past Due Date",
  [OverdueCharts.DAYS_OPEN]: "Aging: Issue Count by Length of Time Open",
};

export const chartYAxisTitle = {
  [OverdueCharts.COMING_DUE]: "Days until due date",
  [OverdueCharts.OVERDUE]: "Days past due date",
  [OverdueCharts.DAYS_OPEN]: "Number of days open",
};

export const chartTiles = {
  [OverdueCharts.COMING_DUE]: "At Risk Issues",
  [OverdueCharts.OVERDUE]: "Overdue Issues",
  [OverdueCharts.DAYS_OPEN]: "Aging Issues",
};

export const overdueBarChartColors = [
  "#BD3F24",
  "#BD3F24",
  "#2F76B6",
  "#2F76B6",
  "#2F76B6",
  "#2F76B6",
];

export const allowedGroupings = [
  "source",
  "department",
  "risk_level",
  "control_rating",
  "priority",
];

import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";

import { userColors } from "@/components/constants";
import { useMainStore } from "@/contexts/Store";

interface UsersSearchListProps {
  /**
   * Ids of selected users
   */
  selectedUserIds: number[];
  /**
   * Search query of input field
   */
  query: string;
  /**
   * Method to call when user is clicked
   * @param userId - id of selected user
   */
  onAddUser: (userId: number) => void;
}

const UsersSearchResults: React.FC<UsersSearchListProps> = ({
  query,
  selectedUserIds,
  onAddUser,
}) => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  useEffect(() => {
    if (!workspaceID) {
      return;
    }
    mainStore.users.indexForModuleIfNoUsersPresent(workspaceID);
  }, [workspaceID]);

  const { users } = useMainStore();

  const filteredUsers = useMemo(
    () =>
      users.users.filter(
        (user) =>
          user.full_name?.toLowerCase().includes(query.toLowerCase()) &&
          !selectedUserIds.includes(user.id),
      ),
    [query, users.users, selectedUserIds],
  );

  return (
    <div className="email-list-wrap">
      {filteredUsers.map((user) => {
        const style = { background: userColors[user.icon_color_index] };

        return (
          <li
            key={user.id}
            data-testid="email-list-user-li"
            className="select-option"
            onClick={() => {
              onAddUser(user.id);
            }}
          >
            <div
              className="users select-option-label-wrapper"
              data-testid="user"
            >
              <div className="users-circle" style={style}>
                <span>{user.initials}</span>
              </div>
              <div data-testid="user-name" className="user-full-name">
                {user.full_name}
              </div>
            </div>
          </li>
        );
      })}
    </div>
  );
};

export default observer(UsersSearchResults);

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";

import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { ActionButton } from "./components";

const getModuleRouteFromModuleIdentifier = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleIdentifier' implicitly has an 'an... Remove this comment to see the full error message
  moduleIdentifier,
  subModuleIdentifier = null,
) => {
  if (subModuleIdentifier === "findings") {
    return "qa_test_findings";
  } else if (subModuleIdentifier === "procedures") {
    return "qa_test_procedures";
  } else if (subModuleIdentifier === "samples") {
    return "samples";
  } else if (subModuleIdentifier === "action_plans") {
    return "action_plans";
  }

  switch (moduleIdentifier) {
    case "policy":
      return "policies";
    case "marketing":
      return "creatives";
    case "new_product_approval":
      return "change_management";
    case "training":
      return "trainings";
    case "vendor_due_diligence":
      return "vendors";
    case "risk_register":
      return "risk_registers";
    case "audits":
      return "themis_audits";
    case "control_mapping":
      return "control_mappings";
    case "finra":
      return "finra_complaints";
    case "qa_tests_development":
      return "qa_tests_developments";
    case "zendesk":
      return "modules/zendesk";
    case "key_risk_indicators":
      return "modules/key_risk_indicators";
    // case 'qa_procedures':
    //   return 'qa_test_procedures'
    // case 'qa_findings':
    //   return 'qa_test_findings'
    default: // procedures, issue_management, documents, complaints, conflicts_of_interest, customer_support
      return moduleIdentifier;
  }
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetMenuActions?: (...args: any[]) => any;
}

function MenuActions({ resetMenuActions }: Props) {
  const mainStore = useMainStore();

  const [selectedText, setSelectedText] = useState("");

  const { selectedMenuAction, menuActions } = mainStore.dynamicTable;
  const { activeWorkspace, themisModuleIdentifier, subModuleTableName } =
    mainStore.context;

  const resetSelectedAction = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    resetMenuActions();
  };

  const moveEndpointForModule: {
    [key in ModuleIdentifier]?: (ids: string[]) => Promise<void>;
  } = {
    documents: (ids: string[]) =>
      mainStore.recordVersions.bulkMove({
        workspaceID: activeWorkspace?.id,
        recordVersionIDs: ids,
        folderID:
          mainStore.dynamicTable.selectedFolderID === "dashboard"
            ? null
            : mainStore.dynamicTable.selectedFolderID,
        currentFolderID: mainStore.dynamicTable.currentFolderID,
        themisModuleRoute: "documents",
      }),
  };

  const actions = useMemo(() => {
    switch (selectedMenuAction) {
      // @ts-expect-error TS(2339) FIXME: Property 'selectAll' does not exist on type '{ sel... Remove this comment to see the full error message
      case menuActions.selectAll:
        return [
          // {
          //   icon: isActive('Send to Collaborative Workspace') ? mailIconActive : mailIcon,
          //   text: 'Send to Collaborative Workspace',
          //   alt: 'mail-icon',
          //   onClick: () => {
          //     setSelectedAction('Send to Collaborative Workspace')
          //   }
          // },
        ];
      // @ts-expect-error TS(2678) FIXME: Type 'string' is not comparable to type 'null'.
      case menuActions.selectIndividual:
        return [
          {
            icon: "check",
            text: "Close Complaints",
            alt: "check-icon",
            actionVerb: "close",
            defaultColor: "brandingHighlightViolet",
            cssClass: "action-button",
            showButton: themisModuleIdentifier === "complaints",
            // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
            actionCall: (ids) => mainStore.complaints.bulkClose(ids),
          },
          {
            icon: "switch",
            text: "Move",
            alt: "move-icon",
            defaultColor: "brandingHighlightViolet",
            cssClass: "action-button",
            showButton: themisModuleIdentifier === "documents",
            // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
            actionCall: moveEndpointForModule[themisModuleIdentifier],
            folderList: true,
          },
          {
            icon: "trash",
            text: "Delete",
            alt: "delete-icon",
            actionVerb: "delete",
            defaultColor: "generalError",
            cssClass: "delete-button",
            showButton: true,
            // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
            actionCall: (ids) =>
              mainStore.recordVersions.bulkDelete(
                ids,
                getModuleRouteFromModuleIdentifier(
                  themisModuleIdentifier,
                  subModuleTableName,
                ),
              ),
          },
          // {
          //   icon: 'upload'
          //   text: 'Publish',
          //   alt: 'upload-icon',
          //   onClick: () => {
          //     setSelectedAction('Publish')
          //   },
          // },
          // {
          //   icon: 'mail'
          //   text: 'Send to Collaborative Workspace',
          //   alt: 'mail-icon',
          //   onClick: () => {
          //     setSelectedAction('Send to Collaborative Workspace')
          //   }
          // },
        ];
      default:
        return [];
    }
  }, [selectedMenuAction, menuActions]);

  useEffect(() => {
    switch (selectedMenuAction) {
      // @ts-expect-error TS(2339) FIXME: Property 'selectAll' does not exist on type '{ sel... Remove this comment to see the full error message
      case menuActions.selectAll:
        setSelectedText("All Selected");
        break;
      // @ts-expect-error TS(2678) FIXME: Type 'string' is not comparable to type 'null'.
      case menuActions.selectIndividual:
        setSelectedText(
          `${mainStore.dynamicTable.selectedRecordVersionIDs.length} Selected`,
        );
        break;
      default:
        break;
    }
  }, [mainStore.dynamicTable.selectedRecordVersionIDs]);

  return (
    <div
      data-testid="select-all-menu-actions"
      className="list-title-menu-actions"
    >
      <div data-testid="count-of-selected" className="count-of-selected">
        {selectedText}
      </div>
      <div className="divider" />
      {actions
        .filter((action) => action.showButton)
        .map((action, index) => (
          <ActionButton
            key={index}
            classNames={classNames("action-button", action.cssClass)}
            alt={action.alt}
            icon={action.icon}
            text={action.text}
            reset={resetSelectedAction}
            disabled={
              mainStore.dynamicTable.selectedRecordVersionIDs.length < 1
            }
            actionCall={action.actionCall}
            actionVerb={action.actionVerb}
            defaultColor={action.defaultColor}
            folderList={action.folderList}
          />
        ))}
    </div>
  );
}

export default observer(MenuActions);

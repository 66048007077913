import React from "react";
import { useParams } from "react-router-dom";

import DetailNav from "@/components/detailView/DetailNav";
import { FindingsRoutes } from "@/features/findings/pages";

export function ComplaintsDetailViewFindings() {
  const { record_version_id } = useParams<{
    record_version_id: string;
  }>();

  return (
    <div className="tw-h-full tw-overflow-hidden">
      <DetailNav
        moduleIdentifier="complaints"
        recordVersionID={Number(record_version_id)}
      />
      <FindingsRoutes moduleIdentifier="complaints" />
    </div>
  );
}

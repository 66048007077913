import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupChildResultsPathParams,
  WebsiteMonitoringGroupChildResultsQueryParams,
  WebsiteMonitoringGroupChildResultsQueryResponse,
} from "../../models/WebsiteMonitoringGroupChildResults";

/**
 * @description List Website Monitoring - Groups with children
 * @summary List Website Monitoring
 * @link /website_monitoring/:monitoring_group_asset_child_id/monitoring_group_child_results
 */
export async function websiteMonitoringGroupChildResults(
  monitoringGroupAssetChildId: WebsiteMonitoringGroupChildResultsPathParams["monitoring_group_asset_child_id"],
  params?: WebsiteMonitoringGroupChildResultsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<WebsiteMonitoringGroupChildResultsQueryResponse>["data"]
> {
  const res = await client<WebsiteMonitoringGroupChildResultsQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupAssetChildId}/monitoring_group_child_results`,
    params,
    ...options,
  });
  return res.data;
}

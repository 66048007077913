import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Resize = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L5.15076 20.2635C4.76023 20.654 4.12707 20.654 3.73654 20.2635C3.34602 19.8729 3.34602 19.2398 3.73654 18.8492L19.2929 3.29289ZM19.2327 12.3531C19.3935 12.1923 19.8404 12.3785 20.2309 12.7691C20.6214 13.1596 20.8077 13.6065 20.6469 13.7673L14.2424 20.1718C14.0816 20.3325 13.6347 20.1463 13.2442 19.7558C12.8537 19.3652 12.6674 18.9183 12.8282 18.7575L19.2327 12.3531Z"
      fill="#353549"
    />
  </svg>
);

Resize.displayName = "ResizeIcon";

export default Resize;

import "./KRISummary.scss";

import classNames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo } from "react";

import Loading from "@/components/Loading";
import Table from "@/components/table/Table";
import { useMainStore } from "@/contexts/Store";
import type { SummaryRow } from "@/stores/types/key-risk-indicators-types";

const headers = [
  "Name",
  "Description",
  "Reporting Interval",
  "Min Threshold",
  "Max Threshold",
];
// Non date columns have a fixed width
// Non number columns are left-aligned
const numberOfNonDateColumns = headers.length;
const numberOfNonNumberColumns = 3;

interface GroupedData {
  [sectionTitle: string]: SummaryRow[];
}

const KRISummary = () => {
  const mainStore = useMainStore();

  const data: SummaryRow[] | undefined = toJS(
    mainStore.keyRiskIndicators.summaries?.rows,
  );

  // Keys in summary endpoint response that should not be shown as columns in summary
  const headersToExclude = ["section_tag_title"];
  useEffect(() => {
    mainStore.keyRiskIndicators.fetchKRISummaries("quarter");
  }, []);

  const { dataBySection, tableHeaders } = useMemo(() => {
    const groupedData: GroupedData = {};
    const summaryData = data?.[0] as SummaryRow;
    const remainingHeaders =
      (summaryData?.headers as string[])?.filter(
        (header) =>
          !headers.includes(header) && !headersToExclude.includes(header),
      ) || [];
    data?.forEach((item) => {
      const sectionTitle = item.section_tag_title || "topLevel";
      groupedData[sectionTitle as string] = [
        ...(groupedData[sectionTitle as string] || []),
        item,
      ];
    });
    return {
      dataBySection: groupedData,
      tableHeaders: [...headers, ...remainingHeaders],
    };
  }, [data]);

  const renderSection = (sectionData: SummaryRow[], sectionTitle?: string) => (
    <section key={sectionTitle || "topLevel"}>
      {sectionTitle && <div className="summary-title">{sectionTitle}</div>}
      <table className="summary-table" data-testid="kri-summary-table">
        <thead>
          <tr>
            {tableHeaders.map((header: string, index: number) => (
              <th
                key={header}
                className={classNames("fixed-width", {
                  number: index >= numberOfNonNumberColumns,
                })}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sectionData.map((rowData: SummaryRow, index: number) => (
            <tr key={index} data-testid="kri-summary-table-row">
              {renderRow(rowData)}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );

  const renderRow = useCallback(
    (row: SummaryRow) => {
      const MAX_THRESHOLD = row["Max Threshold"];
      const MIN_THRESHOLD = row["Min Threshold"];

      return tableHeaders.map((header, index) => {
        const value = row[header];
        const isAggregateColumn = index >= numberOfNonDateColumns;
        const green =
          isAggregateColumn && value < MAX_THRESHOLD && value > MIN_THRESHOLD;
        const yellow =
          isAggregateColumn &&
          (value === MAX_THRESHOLD || value === MIN_THRESHOLD);
        const red =
          isAggregateColumn && (value > MAX_THRESHOLD || value < MIN_THRESHOLD);
        return (
          <td
            className={classNames({
              red,
              green,
              yellow,
              number: index >= numberOfNonNumberColumns,
            })}
            key={index}
          >
            {value}
          </td>
        );
      });
    },
    [data],
  );
  const isEmpty = data && data?.length === 0;
  if (!data) {
    return (
      <div className="dashboard-content">
        <div className="dashboard-content-wrap">
          <Table>
            <Loading loadingLayout="table" showTableHeader />
          </Table>
        </div>
      </div>
    );
  }
  if (isEmpty) {
    return (
      <div className="kri-summary">
        <div className="no-summaries">
          <div className="no-summaries-title">There are no matched data!</div>
          <div className="no-summaries-text">
            Please make sure you have added records in the key risk indicator
            values.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="kri-summary">
      {dataBySection.topLevel && renderSection(dataBySection.topLevel)}
      {Object.entries(dataBySection).map(([sectionId, sectionData]) => {
        // Skip the topLevel section as it's already rendered
        if (sectionId === "topLevel") {
          return null;
        }
        return renderSection(sectionData, sectionId);
      })}
    </div>
  );
};
export default observer(KRISummary);

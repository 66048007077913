import { Button } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

interface Props {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  showIcon?: boolean;
}

function ApproveButton({ onClick, disabled }: Props) {
  const mainStore = useMainStore();

  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isDisabled = !hasModuleWriteAccess || disabled;

  return (
    <Button
      color="tertiary"
      size="md"
      data-testid="approve-button"
      disabled={isDisabled}
      onClick={onClick}
      className="tw-w-[86px] tw-max-w-[86px]"
    >
      Approve
    </Button>
  );
}

ApproveButton.defaultProps = {
  disabled: false,
  showIcon: true,
};

export default observer(ApproveButton);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteModuleAssessmentMutationResponse,
  DeleteModuleAssessmentPathParams,
} from "../../models/DeleteModuleAssessment";

/**
 * @description Delete Module Assessment
 * @summary Delete Module Assessment
 * @link /module_assessments/:id
 */
export async function deleteModuleAssessment(
  id: DeleteModuleAssessmentPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteModuleAssessmentMutationResponse>["data"]> {
  const res = await client<DeleteModuleAssessmentMutationResponse>({
    method: "delete",
    url: `/module_assessments/${id}`,
    ...options,
  });
  return res.data;
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ApiAuthCreateMutationRequest,
  ApiAuthCreateMutationResponse,
} from "../../models/ApiAuthCreate";

/**
 * @description Create API Auth
 * @summary Create API Auth
 * @link /integrations/api_auth
 */
export async function apiAuthCreate(
  data: ApiAuthCreateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ApiAuthCreateMutationResponse>["data"]> {
  const res = await client<
    ApiAuthCreateMutationResponse,
    ApiAuthCreateMutationRequest
  >({
    method: "post",
    url: `/integrations/api_auth`,
    data,
    ...options,
  });
  return res.data;
}

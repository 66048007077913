import "./styles.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useRef } from "react";
import type { FieldError } from "react-hook-form";

import { userColors } from "@/components/constants";
import { Flex, Icon, IconButton, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import type { Recipients } from "@/features/notifications/pages/single/types";
import type { ValueLabelOption } from "@/stores/types/field-types";

type SelectedRecipientsTableProps = Recipients & {
  columnOptions: ValueLabelOption[];
  error: FieldError;
  onRemoveRecipient: (
    recipientType: keyof Recipients,
    remove: boolean,
  ) => (changeRecipientId: string | number) => void;
};

const SelectedRecipientsTable: React.FC<SelectedRecipientsTableProps> = ({
  columnOptions,
  columns: selectedColumns,
  department_ids: selectedDepartmentIds,
  user_ids: selectedUserIds,
  onRemoveRecipient,
  error,
}) => {
  const mainStore = useMainStore();
  const lastItemRef = useRef(null);

  const selectedColumnOptions = useMemo(
    () =>
      columnOptions.filter((columnOption) =>
        selectedColumns.includes(columnOption.value),
      ),
    [selectedColumns, columnOptions],
  );

  const selectedDepartments = useMemo(
    () =>
      mainStore.departments.departments.filter((department) =>
        selectedDepartmentIds.includes(department.id),
      ),
    [mainStore.departments.departments, selectedDepartmentIds],
  );

  const selectedUsers = useMemo(
    () =>
      mainStore.users.users.filter((user) => selectedUserIds.includes(user.id)),
    [mainStore.users.users, selectedUserIds],
  );

  const selectedRecipients: (ValueLabelOption<string | number> & {
    icon_color_index?: number;
    initials?: string;
    recipientType: keyof Recipients;
  })[] = useMemo(
    () => [
      ...selectedColumnOptions.map((selectedColumnOption) => ({
        ...selectedColumnOption,
        recipientType: "columns" as keyof Recipients,
      })),
      ...selectedDepartments.map((selectedDepartment) => ({
        value: selectedDepartment.id,
        label: selectedDepartment.title,
        recipientType: "department_ids" as keyof Recipients,
      })),
      ...selectedUsers.map((selectedUser) => ({
        value: selectedUser.id,
        label: selectedUser.full_name || "",
        icon_color_index: selectedUser.icon_color_index,
        initials: selectedUser.initials,
        recipientType: "user_ids" as keyof Recipients,
      })),
    ],
    [selectedColumnOptions, selectedDepartments, selectedUsers],
  );

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'scrollIntoView' does not exist on type '... Remove this comment to see the full error message
    lastItemRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedRecipients]);

  if (!selectedRecipients.length) {
    return (
      <Flex
        justifyCenter
        alignCenter
        className={classNames("no-recipients-container", {
          ["no-recipients-container--errors"]: !!error,
        })}
      >
        <Typography
          label="No Recipients added yet. Click “Select” to add one here."
          color="extrasBlueGrayDarker"
          size="sm"
          weight="semiBold"
        />
      </Flex>
    );
  }

  const handleRemoveRecipient = (
    recipientType: keyof Recipients,
    value: string | number,
  ) => {
    onRemoveRecipient(recipientType, true)(value);
  };

  return (
    <Flex column>
      {selectedRecipients.map(
        (
          { recipientType, label, value, initials, icon_color_index },
          index,
        ) => {
          const style = recipientType === "user_ids" && {
            // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
            background: userColors[icon_color_index],
            marginRight: 0,
          };

          return (
            <Flex
              justifySpaceBetween
              alignCenter
              key={index}
              className="recipient-row"
            >
              <Flex alignCenter columnGap={8}>
                {recipientType === "user_ids" ? (
                  // @ts-expect-error TS(2322) FIXME: Type 'false | { background: any; marginRight: numb... Remove this comment to see the full error message
                  <div className="users-circle" style={style}>
                    <div>{initials}</div>
                  </div>
                ) : (
                  <Flex
                    alignCenter
                    justifyCenter
                    className="recipient-row__icon"
                  >
                    <Icon
                      name={recipientType === "columns" ? "user" : "department"}
                    />
                  </Flex>
                )}
                <Typography
                  size="sm"
                  weight="semiBold"
                  label={label}
                  color="generalMidnightDark"
                />
              </Flex>
              <div>
                <IconButton
                  onClick={() => handleRemoveRecipient(recipientType, value)}
                  icon="trash"
                  transparent
                />
              </div>
            </Flex>
          );
        },
      )}
      <div ref={lastItemRef} />
    </Flex>
  );
};

export default observer(SelectedRecipientsTable);

import { observer } from "mobx-react";
import React from "react";

import { Icon } from "@/components/Elements";

import { iconForDataType } from "../table/shared/ModuleTableColumn";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectField?: (...args: any[]) => any;
}

function FiltersFieldSelect({ fields, onSelectField }: Props) {
  // renders
  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderFieldItem = (field) => (
    <div
      key={field.name}
      className="fields-list-item"
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onClick={() => onSelectField(field)}
      data-testid="filters-field-select-item"
    >
      <Icon name={iconForDataType(field.data_type)} color="generalDark" />
      {field.display_name}
    </div>
  );

  return (
    <div
      className="fields-list filters-popup-scrollable-content"
      data-testid="filters-field-select"
    >
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {fields.map(renderFieldItem)}
    </div>
  );
}

FiltersFieldSelect.defaultProps = {
  fields: [],
  onSelectField: () => {},
};

export default observer(FiltersFieldSelect);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Lightbulb = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 20C15.5523 20 16 20.4477 16 21C16 21.5523 15.5523 22 15 22H9C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20H15ZM11.9997 2C16.7126 2 20 4.84118 20 9.37862C20 11.2295 19.3164 12.7626 18.0131 14.4493L17.7768 14.7486C17.5676 15.0081 16.7725 15.9414 16.5047 16.2716L16.3528 16.4673C16.1422 16.7498 16.033 16.9444 16.004 17.0468L15.9794 17.2022C15.8851 17.6613 15.4794 18 15 18H9C8.52064 18 8.11491 17.6613 8.02058 17.2022L7.99605 17.0468C7.96704 16.9444 7.85775 16.7498 7.64716 16.4673L7.49532 16.2716L6.22323 14.7485L5.98686 14.4492C4.68374 12.7625 4 11.2294 4 9.37868C4 4.84128 7.28731 2 11.9997 2ZM11.9997 4C8.33235 4 6 6.0159 6 9.37868C6 10.7003 6.51274 11.8555 7.56299 13.2179L7.78021 13.4932C7.9488 13.7023 8.63949 14.514 8.98111 14.9294L9.12759 15.1107C9.37961 15.4337 9.57654 15.7224 9.71938 16H14.2806L14.3728 15.8319C14.5044 15.6055 14.6708 15.3691 14.8724 15.1107L15.4446 14.4189C15.763 14.0399 16.1074 13.6327 16.2198 13.4933L16.437 13.2179C17.4873 11.8555 18 10.7004 18 9.37868C18 6.01583 15.6676 4 11.9997 4Z"
      fill="#353549"
    />
  </svg>
);

Lightbulb.displayName = "LightbulbIcon";

export default Lightbulb;

import "./questions-remaining.scss";

import React from "react";

import { MiniTag, Typography } from "@/components/Elements";

interface Props {
  count: number;
}

export default function QuestionsRemaining(props: Props) {
  return (
    <div className="questions-remaining">
      <Typography
        label="Total Remaining"
        color="generalMidnightDark"
        size="sm"
      />
      <MiniTag
        label={String(props.count)}
        theme="turquoise"
        className="questions-remaining__count"
      />
    </div>
  );
}

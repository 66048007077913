import type { MiniTagProps } from "@themis/ui";
import { MiniTag } from "@themis/ui";
import React from "react";
import { PiArrowDownBold, PiArrowUpBold } from "react-icons/pi";

import type { DashboardComponent } from "@/api";

export default function HeaderCalculationSection({
  widgetData,
  title,
  hasComparisonTag,
}: {
  widgetData: DashboardComponent["data"];
  title: string;
  hasComparisonTag?: boolean;
}) {
  function convertToNumber(value: string) {
    return parseFloat(
      value?.replace("%", "").replace("day", "").replace("days", ""),
    );
  }

  function getComparisonTagProps(): {
    comparisonTagColor?: MiniTagProps["color"];
    comparisonTagIcon?: MiniTagProps["LeftIcon"];
  } {
    if (convertToNumber(widgetData?.comparison_value) < 0) {
      return {
        comparisonTagColor: "red",
        comparisonTagIcon: PiArrowDownBold,
      };
    }

    if (convertToNumber(widgetData?.comparison_value) >= 0) {
      return {
        comparisonTagColor: "green",
        comparisonTagIcon: PiArrowUpBold,
      };
    }

    return {
      comparisonTagColor: "grey",
    };
  }

  const { comparisonTagColor, comparisonTagIcon } = getComparisonTagProps();

  return (
    <div className="tw-flex tw-h-[80px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-bg-neutral-50 tw-px-4 tw-text-left tw-shadow-widget">
      <div className="tw-items-left tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-center">
        <div className="tw-flex tw-flex-col tw-justify-center">
          <div className="tw-items-left tw-flex tw-w-full tw-flex-col">
            <div className="tw-flex tw-items-center tw-text-xl tw-font-semibold tw-leading-8 tw-text-neutral-500">
              <div className="tw-items-center tw-pr-1">{widgetData?.value}</div>
              {hasComparisonTag && (
                <MiniTag
                  color={comparisonTagColor}
                  variant="default"
                  size="md"
                  LeftIcon={comparisonTagIcon}
                >
                  {widgetData?.comparison_value}
                </MiniTag>
              )}
            </div>
            <div className="tw-text-xs tw-font-medium tw-leading-4 tw-text-neutral-300">
              {title}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

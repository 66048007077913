import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const MoreHorizontal = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="5.07692" cy="12.0769" r="2.07692" fill="#353549" />
    <circle cx="12.0308" cy="12.0769" r="2.07692" fill="#353549" />
    <circle cx="18.9846" cy="12.0769" r="2.07692" fill="#353549" />
  </svg>
);

MoreHorizontal.displayName = "MoreHorizontalIcon";

export default MoreHorizontal;

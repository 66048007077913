import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class ChecklistQuestions {
  mainStore: MainStore;

  // Observable objects
  defaultQuestions = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      defaultQuestions: observable,

      setDefaultQuestions: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/questions
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async index(moduleWorkspaceID, fieldName) {
    const params = {
      id: moduleWorkspaceID,
      field_name: fieldName,
    };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/questions`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setDefaultQuestions(response.data.questions);
    } catch (error) {
      window.console.log(`"Questions#index" error ${error}`);
    }
  }

  // POST /api/react/module_workspaces/:id/question
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async create(moduleWorkspaceID, fieldName, params) {
    const data = {
      id: moduleWorkspaceID,
      question: params,
      field_name: fieldName,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/question`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setDefaultQuestions([
        ...this.defaultQuestions,
        response.data.question,
      ]);
    } catch (error) {
      window.console.log(`"Questions#create" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setDefaultQuestions(value) {
    if (value) {
      this.defaultQuestions = value;
    } else {
      this.defaultQuestions = [];
    }
  }

  // Store Helpers
  cleanup() {
    this.setDefaultQuestions([]);
  }
}

import React from "react";

import Button from "@/components/form/Button";
import { useMainStore } from "@/contexts/Store";

import emailIcon from "../assets/email-icon.svg";
import Header from "../components/Header";

export function ThanksForSigningUp() {
  // Import MobX stores
  const mainStore = useMainStore();

  function handleResend() {
    mainStore.users.resend_email_confirmation();
  }

  return (
    <div className="check-in">
      <Header />
      <div className="check-in-wrap thanks-wrap">
        <div className="check-in-block">
          <div className="thanks-block">
            <img src={emailIcon} alt="email-icon" />
            <h2>We&apos;ve sent you a confirmation email</h2>
            <p>
              Please check your inbox. If you can&apos;t find the email, check
              your spam folder or click the button below to resend the email.
            </p>
            <Button title="Resend Confirmation Email" onClick={handleResend} />
          </div>
        </div>
      </div>
    </div>
  );
}

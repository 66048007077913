import {
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import React from "react";
import { PiLinkSimpleBold } from "react-icons/pi";

export function ModuleAssessmentTextCell({ text }: { text: string }) {
  return (
    <>
      <p className="tw-w-full tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-sm tw-font-semibold">
        {text}
      </p>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <IconButton
            Icon={PiLinkSimpleBold}
            size="lg"
            color="transparent"
            variant="vertical"
          />
        </TooltipTrigger>
        <TooltipContent
          align="center"
          side="right"
          className="tw-max-h-48 tw-max-w-96 tw-overflow-auto tw-font-semibold "
        >
          <span className="tw-whitespace-wrap tw-flex tw-pb-7 ">{text}</span>
        </TooltipContent>
      </Tooltip>
    </>
  );
}

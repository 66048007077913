import { LinkButton, Table } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import type { Account, ListAccountsQueryParams } from "@/api";
import { useAccounts } from "@/api/queries/accounts";
import { useColumns } from "@/api/queries/columns";
import { useCompany } from "@/api/queries/companies";
import Loading from "@/components/Loading";
import type { FilterFieldData } from "@/hooks/useFilterSort";
import useFilterSort from "@/hooks/useFilterSort";
import { useSearchParams } from "@/hooks/useSearchParams";

import { ErrorContainer } from "../../../components/ErrorContainer";
import { accountTableColumns } from "../config/accountsTable";
import { ACCOUNT_ROUTES } from "../pages";
import type { AccountListView, AccountSearchParams } from "../pages/Accounts";
import { AccountCard } from "./AccountCard";

function CardsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div
      data-testid="cards-container"
      className="tw-flex tw-flex-wrap tw-items-center tw-gap-4 tw-self-stretch tw-py-2"
    >
      {children}
    </div>
  );
}

function CardPlaceholderContainer({ children }: { children: React.ReactNode }) {
  return (
    <section className="tw-flex tw-max-h-[180px] tw-min-h-[180px] tw-flex-wrap tw-items-center tw-justify-center tw-gap-8 tw-overflow-hidden">
      {children}
    </section>
  );
}

function CardPlaceholder() {
  return (
    <div className="tw-h-[180px] tw-w-[180px] tw-rounded-xl tw-bg-neutral-50" />
  );
}

function EmptyStateContainer({
  accountsListView,
  children,
}: {
  accountsListView: AccountListView;
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "tw-inline-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4",
        {
          "tw-pt-4": accountsListView === "grid",
          "tw-pt-48": accountsListView === "table",
        },
      )}
    >
      {children}
    </div>
  );
}

export function AccountList({
  filtersFieldData,
}: {
  filtersFieldData?: FilterFieldData<Account>;
}) {
  const { url } = useRouteMatch();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const [{ view }] = useSearchParams<AccountSearchParams>();
  const { listRequestQueryParams } = useFilterSort<Account>(
    filtersFieldData || {},
  );

  const queryParams: ListAccountsQueryParams | null = filtersFieldData
    ? {
        expand: "main_contact",
        ...listRequestQueryParams,
      }
    : null;

  const {
    data: accounts,
    isPending: isAccountsPending,
    isError: isAccountsError,
  } = useAccounts(Number(workspace_id), queryParams);

  const {
    data: company,
    isPending: isCompanyPending,
    isError: isCompanyError,
  } = useCompany("current");

  const {
    data: columns,
    isPending: isColumnsPending,
    isError: isColumnsError,
  } = useColumns({
    companyId: Number(company?.data.company.id),
    recordClass: "accounts",
  });

  const accountsListView = view || "table";

  if (isAccountsPending || isCompanyPending || isColumnsPending) {
    return <Loading loadingLayout={accountsListView} />;
  }

  if (isAccountsError || isCompanyError || isColumnsError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id,
          }),
        }}
      >
        Could not load accounts.
      </ErrorContainer>
    );
  }

  const accountTypeFieldOptions =
    columns.data.find(
      ({ name: fieldConfigName }) => fieldConfigName === "account_types",
    )?.options || [];

  return (
    <>
      {accountsListView === "grid" ? (
        <CardsContainer>
          {accounts.data.map(({ id, logo, account_types, name, status }) => (
            <AccountCard
              linkTo={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
                accountId: id,
              })}
              key={id}
              id={id}
              isActive={status === "active"}
              logoURL={logo?.file_url}
              name={name}
              accountTypes={account_types}
              accountTypeFieldOptions={accountTypeFieldOptions}
            />
          ))}
        </CardsContainer>
      ) : (
        <Table
          columns={accountTableColumns({
            workspaceId: Number(workspace_id),
            accountTypeFieldOptions,
          })}
          rows={accounts.data}
          width="100%"
        />
      )}

      {!accounts.data.length && (
        <>
          {accountsListView === "grid" && (
            <CardPlaceholderContainer>
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
              <CardPlaceholder />
            </CardPlaceholderContainer>
          )}
          <EmptyStateContainer accountsListView={accountsListView}>
            <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
              Add your first Account
            </h3>
            <p className="tw-w-[370px] tw-text-center tw-text-sm tw-font-medium tw-text-neutral-300">
              {`To get started, add your first Account by clicking 'Add Account' and filling in the details.`}
            </p>
            <LinkButton
              LeftIcon={PiPlusBold}
              to={generatePath(`${url}${ACCOUNT_ROUTES.details}`, {
                accountId: "new",
              })}
            >
              Add Account
            </LinkButton>
          </EmptyStateContainer>
        </>
      )}
    </>
  );
}

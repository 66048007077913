import classNames from "classnames";
import React from "react";

import { iconForThemisModuleIdentifier } from "@/components/helpers/iconForThemisModuleIdentifier";
import { nameForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

export function ThemisModuleCell({
  fieldName,
  value,
  style,
  locked,
}: {
  fieldName: string;
  value: ModuleIdentifier[];
  style?: React.CSSProperties;
  locked?: boolean;
}) {
  const [moduleIdentifier] = value;
  const moduleName = nameForThemisModuleIdentifier(moduleIdentifier);

  return (
    <li
      key={fieldName}
      className={classNames("cell read-only", {
        "locked-cell pointer-events-none": locked,
      })}
      style={style}
    >
      <div className="cell-content tw-flex tw-items-center tw-gap-2">
        {moduleIdentifier && (
          <img
            className="tw-h-5 tw-w-5"
            src={iconForThemisModuleIdentifier(moduleIdentifier)}
            alt={`Module Icon for ${moduleName}`}
          />
        )}
        {moduleName}
      </div>
    </li>
  );
}

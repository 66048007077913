import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import ComplaintsContextMenu from "@/components/table/complaints/ComplaintsContextMenu";
import { useMainStore } from "@/contexts/Store";

function ComplaintsActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();

  // Variables
  const path = location.pathname;
  const complaintID = Number(path.substring(path.lastIndexOf("/") + 1));

  const recordVersionList = mainStore.recordVersions.list.find(
    (rv) => rv.id === complaintID,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;

  if (!recordVersion) {
    return null;
  }

  return (
    <ComplaintsContextMenu recordVersion={recordVersion} showOnlyIcon={false} />
  );
}

export default observer(ComplaintsActionBar);

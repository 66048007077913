import { HeaderTabs } from "@themis/ui";
import React from "react";
import { useIntl } from "react-intl";
import { generatePath, useLocation, useParams } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import {
  ACCOUNTS_LIST_PATH,
  ALL_CONTRACTS_PATH,
} from "@/features/accounts/pages/index";

interface AccountTabsProps {
  RightComponent?: React.ReactNode;
}

function AccountsHeader({ RightComponent }: AccountTabsProps) {
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { pathname } = useLocation();

  const { formatMessage } = useIntl();

  const TABS = [
    {
      name: formatMessage({ defaultMessage: "Accounts" }),
      key: "accounts",
      value: generatePath(ACCOUNTS_LIST_PATH, { workspace_id }),
    },
    {
      name: formatMessage({ defaultMessage: "Contracts" }),
      key: "all-contracts",
      value: generatePath(ALL_CONTRACTS_PATH, { workspace_id }),
    },
  ];

  return (
    <div className="tw-flex tw-h-12 tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5 tw-py-0">
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS, true, pathname)?.value || ""}
        isLink
      />

      {RightComponent}
    </div>
  );
}

export default AccountsHeader;

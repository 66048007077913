import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class Filters {
  mainStore: MainStore;

  controller: AbortController | null = null;

  currentFilterURL = "";

  typeaheadOptions = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      currentFilterURL: observable,
      setCurrentFilterURL: action,
      typeaheadOptions: observable,
      setTypeaheadOptions: action,
      getConditionText: action,
      getFilterOptionsGeneratingModes: action,
    });

    this.mainStore = mainStore;
  }

  async fetchTypeaheadOptions({
    moduleWorkspaceID,
    tableName,
    value,
    fieldName,
    dataType,
    tab,
  }: {
    moduleWorkspaceID: number;
    tableName: string;
    // @ts-expect-error TS(7031) FIXME: Binding element 'value' implicitly has an 'any'... Remove this comment to see the full error message
    value;
    fieldName: string;
    dataType: string;
    tab?: string;
  }) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_name: tableName,
        value,
        field_name: fieldName,
        data_type: dataType,
        tab,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/filters/typeahead_options`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.setTypeaheadOptions(response.data.options);
    } catch (error) {
      window.console.log(
        `"Filters#fetchTypeaheadOptions for ModuleWorkspace" error ${error}`,
      );
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setTypeaheadOptions(value) {
    this.typeaheadOptions = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'dataType' implicitly has an 'any' type.
  getConditionText(dataType, isPositive) {
    switch (dataType) {
      case "com.askthemis.types.v1.integer":
      case "com.askthemis.types.v1.text":
      case "com.askthemis.types.v1.long_text":
      case "com.askthemis.types.v1.multiline_text":
      case "com.askthemis.types.v1.link":
      case "com.askthemis.types.v1.finra_problem_code":
      case "com.askthemis.types.v1.finra_product_code":
      case "com.askthemis.types.v1.finra_brex_category":
      case "com.askthemis.types.v1.finra_brex_sub_category":
      case "com.askthemis.types.v1.finra_dispute_amount":
        return isPositive ? "contains" : "not contains";
      case "com.askthemis.types.v1.date":
        return isPositive ? "after" : "before";
      case "com.askthemis.types.v1.attachment":
        return isPositive ? "is not empty" : "is empty";
      default:
        return isPositive ? "is" : "is not";
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'dataType' implicitly has an 'any' type.
  getFilterOptionsGeneratingModes(dataType) {
    const data = { typeahead: false, calendar: false, default: false };

    switch (dataType) {
      case "com.askthemis.types.v1.integer":
      case "com.askthemis.types.v1.text":
      case "com.askthemis.types.v1.long_text":
      case "com.askthemis.types.v1.multiline_text":
      case "com.askthemis.types.v1.link":
        data.typeahead = true;
        break;
      case "com.askthemis.types.v1.date":
        data.calendar = true;
        break;
      default:
        data.default = true;
        break;
    }

    return data;
  }

  setCurrentFilterURL(value: string) {
    this.currentFilterURL = value;
  }

  // Store Helpers
  cleanup() {
    this.setTypeaheadOptions([]);
  }
}

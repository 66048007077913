import React from "react";
import { useParams } from "react-router-dom";

import DetailNav from "../../../detailView/DetailNav";
import CreativeView from "./CreativeView";

function MarketingDetailView() {
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id } = useParams();

  return (
    <>
      <DetailNav
        moduleIdentifier="marketing"
        recordVersionID={record_version_id}
      />
      <CreativeView className="marketing-details__creative-view" />
    </>
  );
}

export default MarketingDetailView;

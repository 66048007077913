export const TaskVisibility = {
  Private: {
    value: "private",
    label: "Private",
  },
  Internal: {
    value: "internal",
    label: "Internal",
  },
  Public: {
    value: "public",
    label: "Public",
  },
} as const;

export const TASK_VISIBILITIES = Object.values(TaskVisibility);

export const TASK_VISIBILITY_HELPER_TEXT = {
  [TaskVisibility.Private.value]:
    "Creator, assignee, collaborators can view task",
  [TaskVisibility.Internal.value]: "Internal workspace users can view task",
  [TaskVisibility.Public.value]:
    "Internal and selected workspace users can view task",
};

import type { UncompletedCommentsCount } from "@/api";

export function getCommentCount(
  userCanViewPrivateComments?: boolean,
  unCompletedCommentsCount?: UncompletedCommentsCount,
) {
  if (!unCompletedCommentsCount) {
    return 0;
  }

  const publicCount = unCompletedCommentsCount.public;

  const privateCount = userCanViewPrivateComments
    ? unCompletedCommentsCount.private
    : 0;

  return publicCount + privateCount;
}

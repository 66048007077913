import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Triangle = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.87 19.29L12.87 3.71C12.7828 3.5561 12.6563 3.42809 12.5035 3.33903C12.3506 3.24997 12.1769 3.20305 12 3.20305C11.8231 3.20305 11.6494 3.24997 11.4965 3.33903C11.3437 3.42809 11.2172 3.5561 11.13 3.71L2.13001 19.29C2.04224 19.442 1.99603 19.6145 1.99603 19.79C1.99603 19.9655 2.04224 20.138 2.13001 20.29C2.21811 20.4426 2.34498 20.5692 2.49775 20.657C2.65053 20.7448 2.8238 20.7907 3.00001 20.79H21C21.1762 20.7907 21.3495 20.7448 21.5023 20.657C21.655 20.5692 21.7819 20.4426 21.87 20.29C21.9578 20.138 22.004 19.9655 22.004 19.79C22.004 19.6145 21.9578 19.442 21.87 19.29V19.29ZM4.73001 18.79L12 6.21L19.27 18.79H4.73001Z"
      fill="#353549"
    />
  </svg>
);

Triangle.displayName = "TriangleIcon";

export default Triangle;

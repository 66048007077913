import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import arrowDown from "@/images/table-image/icon/arrow-down-black.svg";

const availableDepartments = [
  {
    id: 1,
    title: "Finance",
  },
  {
    id: 2,
    title: "HR",
  },
  {
    id: 3,
    title: "Legal",
  },
  {
    id: 4,
    title: "Compliance",
  },
  {
    id: 5,
    title: "Sales",
  },
  {
    id: 6,
    title: "Engineering",
  },
  {
    id: 7,
    title: "IT",
  },
  {
    id: 8,
    title: "Marketing",
  },
  {
    id: 9,
    title: "Executive",
  },
];

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDepartmentTitle: (...args: any[]) => any;
}

function AuthenticationSelect({ setDepartmentTitle }: Props) {
  // State
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addItemById(id) {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    setSelectedIDs([id]);
    setShowPopup(false);

    const department = availableDepartments.find((elem) => elem.id === id);
    if (department) {
      setDepartmentTitle(department.title);
    }
  }

  const active = classNames({ active: showPopup });

  return (
    <Popup
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => (
        <div className="form-input">
          <li className={active}>
            {selectedIDs.length === 0 ? (
              <span className="no-active">
                Select Department (Optional)
                <img src={arrowDown} alt="arrow-down" />
              </span>
            ) : (
              availableDepartments
                // @ts-expect-error TS(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
                .filter((department) => selectedIDs.includes(department.id))
                .slice(0, 1)
                .map((department) => (
                  <span key={department.id}>
                    {department.title}
                    <img src={arrowDown} alt="arrow-down" />
                  </span>
                ))
            )}
          </li>
        </div>
      )}
      open={showPopup}
      onOpen={() => {
        setShowPopup(true);
      }}
      onClose={() => {
        setShowPopup(false);
      }}
      keepTooltipInside
    >
      <div className="select-parameter authentication-select">
        <ul>
          {availableDepartments.map((department) => (
            <li
              key={department.id}
              onClick={() => {
                addItemById(department.id);
              }}
            >
              {department.title}
            </li>
          ))}
        </ul>
      </div>
    </Popup>
  );
}

export default AuthenticationSelect;

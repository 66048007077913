import "./action-cell.scss";

import React from "react";

type ActionCellProps = React.PropsWithChildren;

const ActionCell = (props: ActionCellProps) => (
  <td className="generic-action-cell">
    <div className="generic-action-cell__content">{props.children}</div>
  </td>
);

export default ActionCell;

import type { ReactNode, ReactPortal } from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children }: { children: ReactNode }) => {
  const mount = document.getElementById("portal-root");
  const el = document.createElement("div");

  useEffect(() => {
    if (mount) {
      if (mount.innerHTML.length > 0) {
        mount.innerHTML = "";
      }

      mount.appendChild(el);
    }

    return () => {
      if (mount) {
        mount.innerHTML = "";
      }
    };
  }, [el, mount]);

  return createPortal(children, el) as ReactPortal;
};

export default Portal;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Home = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 22C5.34314 22 4 20.6569 4 19V13H3C2.10909 13 1.66293 11.9229 2.29289 11.2929L11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L21.7071 11.2929C22.3371 11.9229 21.8909 13 21 13H20V19C20 20.6569 18.6569 22 17 22H7ZM12 4.41421L5.35091 11.0633C5.7301 11.2054 6 11.5712 6 12V19C6 19.5523 6.44771 20 7 20L8.99999 19.999L9 16C9 14.8954 9.89543 14 11 14H13C14.1046 14 15 14.8954 15 16L15 19.999L17 20C17.5523 20 18 19.5523 18 19V12C18 11.5712 18.2699 11.2054 18.6491 11.0633L12 4.41421ZM13 16H11L11 19.999H13L13 16Z"
      fill="#353549"
    />
  </svg>
);

Home.displayName = "HomeIcon";

export default Home;

import { observer } from "mobx-react";
import type { Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";

import type { RecordVersion } from "@/api";
import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import ImportFromWS from "@/components/ImportFromWS/ImportFromWS";
import ModuleSummaryCharts from "@/components/reports/summaryReports/ModuleSummaryCharts";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import HeadSelect from "@/components/table/shared/HeadSelect";
import Table from "@/components/table/Table";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import type { UseFiltersReturn } from "@/hooks/useFilters";

import PoliciesDraft from "../components/PoliciesDraft";
import PoliciesTabHeader from "../components/PoliciesTabHeader";

interface PoliciesDraftPageProps {
  tableName: string;
  setShowCheckbox: Dispatch<SetStateAction<boolean>>;
  buttonDisabled: boolean;
  moduleWorkspaceID: number;
  addNewSection: () => void;
  showCTA: boolean;
  isInternal: boolean;
  showCheckbox: boolean;
  showAddNewSection: boolean;
  canManageSections: boolean;
  filtersData?: UseFiltersReturn | Record<string, never>;
  moduleName: string;
  recordVersions: RecordVersion[];
  onCancelAddNewSection?: () => void;
}

const PoliciesDraftPage = ({
  tableName,
  setShowCheckbox,
  buttonDisabled,
  moduleWorkspaceID,
  addNewSection,
  showCTA,
  isInternal,
  showCheckbox,
  showAddNewSection,
  canManageSections,
  filtersData = {},
  moduleName,
  recordVersions,
  onCancelAddNewSection,
}: PoliciesDraftPageProps) => {
  const mainStore = useMainStore();

  const { workspaceID } = mainStore.context;
  const [isImportSlideoutOpen, setIsImportSlideoutOpen] = useState(false);

  useEffect(() => {
    mainStore.policies.index({
      workspaceID,
      tableName: "Drafts",
    });
  }, []);

  return (
    <DashboardContent>
      <DashboardHeader
        title={moduleName}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper>
        <Table>
          <PoliciesTabHeader
            tableName={tableName}
            setShowCheckbox={setShowCheckbox}
            buttonDisabled={buttonDisabled}
            moduleWorkspaceID={moduleWorkspaceID}
            filtersData={filtersData}
            openImportSlideOut={() => {
              setIsImportSlideoutOpen(true);
            }}
          />

          <AddRecordHeader
            recordName="Policy"
            addRecord={() =>
              mainStore.policies.create(Number(workspaceID), null)
            }
            addSection={addNewSection}
            canAddSection
          />

          <ModuleSummaryCharts
            recordVersions={recordVersions}
            moduleWorkspaceID={moduleWorkspaceID}
            filtersViewEnabled={filtersData.filtersViewEnabled}
            setFiltersViewEnabled={filtersData.setFiltersViewEnabled}
            moduleName="policy"
          />
          <div className="policies-page-content">
            <PoliciesDraft
              addNewSection={addNewSection}
              showCTA={showCTA}
              isInternal={isInternal}
              showCheckbox={showCheckbox}
              showAddNewSection={showAddNewSection}
              moduleWorkspaceID={moduleWorkspaceID}
              canManageSections={canManageSections}
              onCancelAddNewSection={onCancelAddNewSection}
            />
          </div>
        </Table>
        {isImportSlideoutOpen && (
          <SlideMenu
            open={isImportSlideoutOpen}
            closeSlideMenu={() => setIsImportSlideoutOpen(false)}
          >
            <ImportFromWS
              closeSlideOut={() => setIsImportSlideoutOpen(false)}
              refetchData={() =>
                mainStore.policies.index({
                  workspaceID,
                  tableName: "Drafts",
                })
              }
            />
          </SlideMenu>
        )}
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(PoliciesDraftPage);

import React from "react";

import { Typography } from "../../Elements";
import Spinner, { SpinnerSize } from "../../table/shared/Spinner";

const DuplicateWorkspaceLoader = () => (
  <div className="duplicate-workspace-loader">
    <Spinner size={SpinnerSize.small} />
    <Typography label="Loading" weight="semiBold" color="generalDarkGray" />
  </div>
);

export default DuplicateWorkspaceLoader;

import dayjs from "dayjs";
import React from "react";

import { stringToDateAndTime } from "@/components/helpers/DateFormatters";

const UploadDate = ({ date }: { date: string }) => {
  if (!date) {
    return null;
  }

  const dateValue = stringToDateAndTime(date);
  if (!dateValue) {
    return null;
  }

  return (
    <div className="upload-date">
      Uploaded at: {dayjs(dateValue).format("MM/DD/YYYY hh:mm a")}
    </div>
  );
};

export default UploadDate;

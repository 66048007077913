import React from "react";
import Popup from "reactjs-popup";

import themisLogo from "../../../../../../images/dashboard/logo-dashboard.svg";
import userIcon from "../../../../../../images/table-image/icon/user.svg";
import { getWorkspaceLogo } from "../../../../../helpers/getWorkspaceLogo";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  company?: any;
  permissionSetName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles?: any[];
}

function RolesCell({ roles, permissionSetName, company }: Props) {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const count = roles.length;

  const trigger = (
    <div
      className="roles-select-placeholder"
      data-testid="roles-select-placeholder"
    >
      {permissionSetName}
    </div>
  );

  if (count === 0) {
    return trigger;
  }

  return (
    <Popup
      // @ts-expect-error TS(2322) FIXME: Type '"right"' is not assignable to type 'PopupPos... Remove this comment to see the full error message
      position="right"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => trigger}
      on="hover"
      arrow={false}
      keepTooltipInside
    >
      <div className="roles-popup">
        <div className="roles-popup-title">Roles associated to workspaces</div>
        <div className="roles-popup-content">
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {roles.map((role) => (
            <div
              key={`${role.workspace_id}-${role.permission_set_id}`}
              className="roles-popup-role"
              data-testid="roles-popup-role"
            >
              <div className="roles-popup-workspace">
                {role.workspace && (
                  <>
                    <img
                      src={getWorkspaceLogo(role.workspace).logo}
                      alt={role.workspace.name}
                    />
                    <span>{role.workspace.name}</span>
                  </>
                )}
                {!role.workspace && company && (
                  <>
                    <img
                      src={company.logo?.url || themisLogo}
                      alt="logo"
                      className={company.name}
                    />
                    <span>{company.name}</span>
                  </>
                )}
              </div>
              <div className="roles-popup-permission-set">
                <img src={userIcon} alt={role.permission_set.name} />
                <span>{role.permission_set.name}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Popup>
  );
}

RolesCell.defaultProps = {
  roles: [],
  permissionSetName: "",
  company: {},
};

export default RolesCell;

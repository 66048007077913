import "./styles.scss";

import classNames from "classnames";
import React from "react";

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  inlineFlex?: boolean;
  justifySpaceBetween?: boolean;
  justifySpaceAround?: boolean;
  justifyFlexEnd?: boolean;
  justifyFlexStart?: boolean;
  justifyCenter?: boolean;
  alignCenter?: boolean;
  alignStart?: boolean;
  alignEnd?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  wrap?: boolean;
  wrapReverse?: boolean;
  column?: boolean;
  columnReverse?: boolean;
  row?: boolean;
  rowReverse?: boolean;
  noPadding?: boolean;
  stretch?: boolean;
  pointer?: boolean;
  grow?: number | boolean;
  shrink?: number | boolean;
  flexBasis?: string | number;
  columnGap?: number;
  rowGap?: number;
}

export const Flex: React.FC<FlexProps> = ({
  className,
  inlineFlex,
  justifySpaceBetween,
  justifySpaceAround,
  justifyFlexEnd,
  justifyFlexStart,
  justifyCenter,
  alignCenter,
  alignStart,
  alignEnd,
  fullHeight,
  fullWidth,
  wrap,
  wrapReverse,
  column,
  columnReverse,
  row,
  rowReverse,
  noPadding,
  stretch,
  pointer,
  grow = 0,
  shrink = 1,
  flexBasis,
  columnGap,
  rowGap,
  style,
  ...props
}) => (
  <div
    className={classNames("flex-component", className, {
      ["flex-component--inline-flex"]: inlineFlex,
      ["flex-component--space-between"]: justifySpaceBetween,
      ["flex-component--space-around"]: justifySpaceAround,
      ["flex-component--flex-end"]: justifyFlexEnd,
      ["flex-component--flex-start"]: justifyFlexStart,
      ["flex-component--justify-center"]: justifyCenter,
      ["flex-component--align-center"]: alignCenter,
      ["flex-component--align-start"]: alignStart,
      ["flex-component--align-end"]: alignEnd,
      ["flex-component--full-height"]: fullHeight,
      ["flex-component--full-width"]: fullWidth,
      ["flex-component--wrap"]: wrap,
      ["flex-component--wrap-reverse"]: wrapReverse,
      ["flex-component--flex-direction-column"]: column,
      ["flex-component--column-reverse"]: columnReverse,
      ["flex-component--row"]: row,
      ["flex-component--row-reverse"]: rowReverse,
      ["flex-component--no-padding"]: noPadding,
      ["flex-component--stretch"]: stretch,
      ["flex-component--pointer"]: pointer,
    })}
    style={{
      flexGrow: Number(grow),
      flexShrink: Number(shrink),
      columnGap,
      rowGap,
      flexBasis,
      ...style,
    }}
    {...props}
  >
    {props.children}
  </div>
);

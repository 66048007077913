import { observer } from "mobx-react";
import React from "react";
import { useParams } from "react-router-dom";

import PartnerDocumentsWrapper from "../components/Documents/PartnerDocumentsWrapper";
import PartnerTabs from "../components/PartnerTabs";

function PartnerDocumentsPage() {
  const { record_version_id } = useParams<{ record_version_id: string }>();

  return (
    <PartnerDocumentsWrapper
      recordVersionID={record_version_id}
      // eslint-disable-next-line react/no-unstable-nested-components
      Tabs={() => <PartnerTabs recordVersionId={record_version_id} />}
      showActions
    />
  );
}

export default observer(PartnerDocumentsPage);

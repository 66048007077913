import type { AxiosResponse } from "axios";
import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import type { MainStore } from "@/stores/Main";

interface ApprovalStep {
  id?: number;
  position: number;
  users_ids: number[];
}

type ApiResponse = AxiosResponse<{
  approval_config: {
    approval_steps: ApprovalStep[];
  };
}>;

export default class ManageApprovalsStepsStore {
  mainStore: MainStore;

  currentApprovalsSteps: ApprovalStep[] = [];
  approvalsSteps: ApprovalStep[] = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      currentApprovalsSteps: observable,
      approvalsSteps: observable,

      setCurrentApprovalsSteps: action,
      setApprovalsSteps: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/module_workspaces/:module_workspace_id/approval_configs/latest
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async index(moduleWorkspaceID) {
    try {
      const response: ApiResponse = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/approval_configs/latest`,
        headers: this.mainStore.getHeaders(),
      });

      this.setCurrentApprovalsSteps(
        response.data.approval_config.approval_steps,
      );
      this.setApprovalsSteps(response.data.approval_config.approval_steps);
    } catch (error) {
      window.console.log(`"ManageApprovalsSteps#index" error ${error}`);
    }
  }

  // POST /api/react/module_workspaces/:module_workspace_id/approval_configs
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async create(moduleWorkspaceID) {
    const data = {
      approval_config: {
        approval_steps_attributes: this.currentApprovalsSteps,
      },
    };

    try {
      const result: ApiResponse = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/approval_configs`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (result.data) {
        this.setApprovalsSteps(result.data.approval_config.approval_steps);
        this.setCurrentApprovalsSteps(
          result.data.approval_config.approval_steps,
        );
        this.mainStore.toast.setText("Saved!");
      } else {
        this.mainStore.toast.setErrorText("The steps should not be empty!");
      }
    } catch (error) {
      window.console.log(`"ManageApprovalsSteps#create" error ${error}`);
    }
  }

  // POST /api/react/record_versions/:record_version_id/approval_configs/set
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  async addSteppedApprovals(recordVersionId) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionId}/approval_configs/set`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"addSteppedApprovals" error ${error}`);
    }
  }

  // DELETE /api/react/record_versions/:record_version_id/approval_configs/unset
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  async removeSteppedApprovals(recordVersionId) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/record_versions/${recordVersionId}/approval_configs/unset`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"addSteppedApprovals" error ${error}`);
    }
  }

  setCurrentApprovalsSteps(value: ApprovalStep[]) {
    this.currentApprovalsSteps = value;
  }

  setApprovalsSteps(value: ApprovalStep[]) {
    this.approvalsSteps = value;
  }

  cleanup() {
    this.setCurrentApprovalsSteps([]);
    this.setApprovalsSteps([]);
  }
}

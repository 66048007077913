import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetFollowupQuestionsQueryParams,
  GetFollowupQuestionsQueryResponse,
} from "../../models/GetFollowupQuestions";

/**
 * @description Fetch followup question
 * @summary Fetch followup questions
 * @link /followup_questions
 */
export async function getFollowupQuestions(
  params?: GetFollowupQuestionsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetFollowupQuestionsQueryResponse>["data"]> {
  const res = await client<GetFollowupQuestionsQueryResponse>({
    method: "get",
    url: `/followup_questions`,
    params,
    ...options,
  });
  return res.data;
}

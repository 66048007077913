import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import tagIcon from "../../../../images/table-image/icon/check-blue-icon.svg";
import warningIcon from "../../../../images/table-image/icon/warning-icon.svg";

interface Props {
  width: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finraSubmission?: any;
  locked?: boolean;
  pinned?: boolean;
}

function FinraSubmissionCell({
  finraSubmission,
  width,
  locked,
  pinned,
}: Props) {
  const [showPopup, setShowPopup] = useState(false);

  const onPopupOpen = () => {
    setShowPopup(true);
  };

  const onPopupClose = () => {
    setShowPopup(false);
  };

  const style = { width, textTransform: "capitalize" };

  const liClassNames = classNames("long-text-cell", {
    active: showPopup,
    "locked-cell": locked,
    pinned,
  });
  const trigger = (
    <li
      // @ts-expect-error TS(2322) FIXME: Type '{ width: string | number; textTransform: str... Remove this comment to see the full error message
      style={style}
      className={liClassNames}
      onClick={() => {
        onPopupClose();
      }}
    >
      <div className="cell-content">
        {finraSubmission?.status || "Not Sent"}
      </div>
    </li>
  );

  if (!finraSubmission || finraSubmission?.status === "sent") {
    return trigger;
  }

  return (
    <Popup
      position="bottom right"
      on="hover"
      trigger={trigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
    >
      <div
        className="table-dropdown error"
        data-testid="finra-submission-popup-content"
      >
        <ul className="errors">
          <li style={{ whiteSpace: "pre-line" }}>
            <img
              src={
                finraSubmission.status === "submitted" ? tagIcon : warningIcon
              }
              alt="warning-icon"
            />
            {finraSubmission?.response}
          </li>
        </ul>
      </div>
    </Popup>
  );
}

export default FinraSubmissionCell;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ModuleAssessmentDeleteDocumentMutationResponse,
  ModuleAssessmentDeleteDocumentPathParams,
} from "../../models/ModuleAssessmentDeleteDocument";

/**
 * @description Delete document
 * @summary Delete document
 * @link /module_assessments/documents/:id
 */
export async function moduleAssessmentDeleteDocument(
  id: ModuleAssessmentDeleteDocumentPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<ModuleAssessmentDeleteDocumentMutationResponse>["data"]
> {
  const res = await client<ModuleAssessmentDeleteDocumentMutationResponse>({
    method: "delete",
    url: `/module_assessments/documents/${id}`,
    ...options,
  });
  return res.data;
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { ApiAuthCreateMutationRequest } from "@/api/gen/models/ApiAuthCreate";
import type { ApiAuthGetQueryParams } from "@/api/gen/models/ApiAuthGet";
import type { ApiAuthUpdateMutationRequest } from "@/api/gen/models/ApiAuthUpdate";

import {
  apiAuthCreate,
  apiAuthGet,
  apiAuthUpdate,
} from "../gen/axios/apiAuthController";

export const API_AUTH_QUERY_KEY = "api_auth_query";

export function useApiAuthGet(params: ApiAuthGetQueryParams) {
  return useQuery({
    queryKey: [API_AUTH_QUERY_KEY, params.company_id],
    queryFn: () => apiAuthGet(params),
    enabled: !!params,
  });
}

export function useApiAuthCreate({
  companyId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ApiAuthCreateMutationRequest) => apiAuthCreate(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_AUTH_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

export function useApiAuthUpdate({
  id,
  companyId,
  onSuccess = () => {},
  onError = () => {},
}: {
  id: number;
  companyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ApiAuthUpdateMutationRequest) => apiAuthUpdate(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [API_AUTH_QUERY_KEY, id],
      });
      queryClient.invalidateQueries({
        queryKey: [API_AUTH_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}

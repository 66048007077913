export const QUARTERS = {
  Q1: "Q1",
  Q2: "Q2",
  Q3: "Q3",
  Q4: "Q4",
  FISCAL_YEAR: "fiscal",
} as const;
export type Quarter = (typeof QUARTERS)[keyof typeof QUARTERS];
export function isQuarter(value: string) {
  return Object.values(QUARTERS).includes(value as Quarter);
}
export function calculateQuarterDate(value: Quarter) {
  const currentYear = new Date().getFullYear();
  const quarterDates = {
    Q1: { start: [0, 1], end: [2, 31] },
    Q2: { start: [3, 1], end: [5, 30] },
    Q3: { start: [6, 1], end: [8, 30] },
    Q4: { start: [9, 1], end: [11, 31] },
    fiscal: { start: [0, 1], end: [11, 31] },
  };
  const { start, end } = quarterDates[value] || { start: [0, 1], end: [0, 1] };
  const start_date = new Date(currentYear, start[0], start[1]);
  const end_date = new Date(currentYear, end[0], end[1]);
  return { start_date, end_date };
}

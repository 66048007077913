import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListControlsModulesPathParams,
  ListControlsModulesQueryResponse,
} from "../../models/ListControlsModules";

/**
 * @description Controls Modules
 * @summary Controls Modules
 * @link /record_versions/:record_version_id/controls/modules
 */
export async function listControlsModules(
  recordVersionId: ListControlsModulesPathParams["record_version_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListControlsModulesQueryResponse>["data"]> {
  const res = await client<ListControlsModulesQueryResponse>({
    method: "get",
    url: `/record_versions/${recordVersionId}/controls/modules`,
    ...options,
  });
  return res.data;
}

import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import IntegerCell from "../cell-type/IntegerCell";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  fieldName?: string;
}

function ViewIntegerCell({ recordVersion, fieldName }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const value = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion.data,
  );

  return (
    <div className="integer-view">
      {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
      <IntegerCell fieldName={fieldName} value={value} width="100%" />
    </div>
  );
}

export default observer(ViewIntegerCell);

import { useEffect } from "react";

import { useMainStore } from "../../contexts/Store";

// @ts-expect-error TS(7006) FIXME: Parameter 'workspaceId' implicitly has an 'any' ty... Remove this comment to see the full error message
export const useIndexForFieldTypes = (workspaceId, fieldTypes) => {
  const { records } = useMainStore();
  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'fieldType' implicitly has an 'any' type... Remove this comment to see the full error message
    fieldTypes.forEach((fieldType) => {
      switch (fieldType) {
        case "com.askthemis.types.v1.record_policy":
          records.index("record:policy", workspaceId);
          break;
        case "com.askthemis.types.v1.record_procedure":
          records.index("record:procedure", workspaceId);
          break;
        default:
          break;
      }
    });
    // we dont want to re-execute when the array reference changes
    // string passes identity based check
  }, [workspaceId, fieldTypes.join(",")]);
};

import React from "react";
import { FadeLoader } from "react-spinners";

import { Icon } from "@/components/Elements";

export function FileUploadLoading({
  dataTestID,
  icon,
}: {
  dataTestID: string;
  icon: "figma" | "google" | "sharePoint";
}) {
  if (icon === "figma") {
    return (
      <>
        <li data-testid={dataTestID} className="disabled-item">
          <FadeLoader
            width={3}
            height={5}
            color="#6D71F9"
            margin={-10}
            cssOverride={{ transform: "translate(-36px, 10px) scaleX(-1)" }}
          />
          <span>File loading</span>
          <Icon spaceLeft name={icon} size="lg" />
        </li>
        <hr />
      </>
    );
  }

  return (
    <div
      data-testid={dataTestID}
      className="drag-drop-wrap g-drive-uploader file-select-figma-loading"
    >
      <FadeLoader
        width={3}
        height={5}
        color="#6D71F9"
        margin={-10}
        cssOverride={{ transform: "translate(-36px, 10px) scaleX(-1)" }}
      />
      <span>File loading</span>
      <Icon spaceLeft name={icon} size="lg" />
    </div>
  );
}

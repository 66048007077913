import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import placeholderUser from "../../../images/table-image/icon/user-placeholder.svg";
import { userColors } from "../../constants";
import { Icon } from "../../Elements";
import UsersCircle from "../../table/shared/UsersCircle";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleConfiguration: any;
}

function ListDefaultApprovers({ moduleConfiguration }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const moduleWorkspaceID = moduleConfiguration?.module_workspace_id;
  const moduleConfigurationID = moduleConfiguration?.id;
  const defaultApproversIDs =
    moduleConfiguration?.default_approvers?.map(
      // @ts-expect-error TS(7006) FIXME: Parameter 'defaultApprover' implicitly has an 'any... Remove this comment to see the full error message
      (defaultApprover) => defaultApprover.user_id,
    ) || [];
  const defaultApprovers = mainStore.users.users.filter((user) =>
    defaultApproversIDs.includes(user.id),
  );
  const notDefaultApprovers = mainStore.users.users.filter(
    (user) => !defaultApproversIDs.includes(user.id),
  );

  // Helper functions
  async function fetchModuleConfiguration() {
    if (moduleConfigurationID) {
      await mainStore.moduleConfigurations.fetch(moduleWorkspaceID);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async function createDefaultApprover(userID) {
    if (!moduleConfigurationID) {
      return;
    }

    await mainStore.defaultApprovers.create(userID, moduleConfigurationID);
    fetchModuleConfiguration();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async function deleteDefaultApprover(userID) {
    const existingDefaultApprovers =
      moduleConfiguration?.default_approvers || [];
    const defaultApprover = existingDefaultApprovers.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'approver' implicitly has an 'any' type.
      (approver) => approver.user_id === userID,
    );
    if (!defaultApprover?.id) {
      return;
    }

    await mainStore.defaultApprovers.delete(
      defaultApprover.id,
      // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
      moduleConfigurationID,
    );
    fetchModuleConfiguration();
    setShowPopup(false);
  }

  // Rendering
  const triggerClasses = classNames({ active: showPopup });

  const renderTrigger = (
    <li className={triggerClasses}>
      <img src={placeholderUser} alt="placeholder-user" />
      <span className="select-assignee-user" data-testid="select-assignee-user">
        - Select -
      </span>
      <Icon name="plus" color="generalDark" size="de" />
    </li>
  );

  return (
    <div className="edit-parameters-element">
      <div className="due-date-wrap">
        <div>
          <p className="approvals-info">Approvers List</p>
          <p className="approvals-context">
            Default approvers added for each workspace
          </p>
        </div>
        <div
          className="edit-parameters-element select-assignee"
          data-testid="select-assignee"
        >
          {notDefaultApprovers.length > 0 && (
            <Popup
              position="bottom left"
              trigger={renderTrigger}
              open={showPopup}
              onOpen={() => setShowPopup(true)}
              onClose={() => setShowPopup(false)}
              keepTooltipInside
              nested
            >
              <div className="users-dropdown time-popup-wrap">
                {notDefaultApprovers.map((user) => (
                  <div
                    key={user.id}
                    className="time-element assignee-user"
                    data-testid={`assignee-user-${user.id}`}
                    onClick={() => {
                      createDefaultApprover(user.id);
                    }}
                  >
                    <UsersCircle
                      key={user.id}
                      user={user}
                      additionalStyles={{
                        background: userColors[user.icon_color_index],
                      }}
                      showFullName
                    />
                  </div>
                ))}
              </div>
            </Popup>
          )}
        </div>
      </div>

      <div className="edit-assignees-wrap">
        {defaultApprovers.length > 0 ? (
          defaultApprovers.map((user) => (
            <div
              className="edit-assignee-element"
              key={user.id}
              data-testid="assignees-block"
            >
              <span
                className={classNames("edit-task-initials", {
                  "awaiting-verification-circle": user?.awaiting_verification,
                })}
                style={{
                  background: !user?.awaiting_verification
                    ? userColors[user.icon_color_index]
                    : "inherit",
                }}
              >
                <span>{user?.initials}</span>
              </span>
              <span className="edit-task-fullname">{user?.full_name}</span>
              <div className="cancel-button-assignee">
                {user?.awaiting_verification && (
                  <span className="awaiting-verification-status">
                    Awaiting Verification
                  </span>
                )}
                <Icon
                  name="close"
                  color="generalDark"
                  size="de"
                  data-testid={`remove-default-approver-${user.id}`}
                  onClick={() => {
                    deleteDefaultApprover(user.id);
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <div className="no-assignees">
            No default approver added yet. Click “Select” to add one here.
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(ListDefaultApprovers);

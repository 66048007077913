import classNames from "classnames";
import { noop } from "lodash";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import React, { useState } from "react";

import type { Field, RecordVersion, TableName } from "@/api";
import { useMainStore } from "@/contexts/Store";
import { isLockedRecord } from "@/stores/helpers/RecordVersionHelper";
import type { TopLevelModuleIdentifier } from "@/stores/types/module-workspaces-types";
import type { Section } from "@/stores/types/section-tags";

import { Icon } from "../../Elements";
import AddNewButton from "../control-mapping/AddNewButton";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "./custom-columns/CustomColumns";
import ModuleRecordVersion from "./ModuleRecordVersion";
import ModuleTableColumn from "./ModuleTableColumn";
import ReorderableTable from "./ReorderableTable";
import SectionDropdownTitle from "./SectionDropdownTitle";
import TableMenu from "./tableMenu/TableMenu";

interface Props {
  addNewButton?: (sectionTagId: number) => JSX.Element;
  approvalFieldName?: string;
  archived?: boolean;
  archivedWord?: string;
  createRecord: (sectionTagId?: number | null) => void;
  dataTestId?: string;
  dropdownTitle?: string;
  editable?: boolean;
  setCustomColumns?: boolean;
  fields?: Field[];
  isDropdownOpen?: boolean;
  isTopLevelSection?: boolean;
  maxDateFunction?: (name: string, recordVersion: RecordVersion) => Date | null;
  minDateFunction?: (name: string, recordVersion: RecordVersion) => Date | null;
  moduleIdentifier?: TopLevelModuleIdentifier;
  moduleWorkspaceID?: number;
  page?: number;
  paginated?: boolean;
  recordName?: string;
  recordVersions?: RecordVersion[];
  rolePrefix?: string;
  sectionTag?: Section;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPages?: (...args: any[]) => any;
  showCheckbox?: boolean;
  tableName?: TableName;
  total?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortByDirection?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSectionTagId?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortByFieldName?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortBy?: (...args: any[]) => any;
  sortByFieldName?: string;
  sortByDirection?: string;
  isDraggable?: boolean;
}

function SectionDropdown({
  archivedWord,
  approvalFieldName,
  dataTestId,
  archived,
  createRecord,
  dropdownTitle,
  editable,
  fields,
  moduleIdentifier,
  moduleWorkspaceID,
  recordName,
  recordVersions,
  rolePrefix,
  tableName,
  isTopLevelSection,
  maxDateFunction,
  minDateFunction,
  sectionTag,
  setPages,
  paginated,
  page,
  total,
  setCustomColumns,
  setSectionTagId,
  setSortByDirection,
  setSortByFieldName,
  sortByFieldName,
  sortByDirection,
  isDraggable,
  isDropdownOpen: isDropdownOpenProp,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(isDropdownOpenProp);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const { themisModuleIdentifier } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const PLACEHOLDER_SECTION_TAG_ID = 0; // The top section doesn't have an ID, so we use 0 as a placeholder
  const sectionTableID = `section-table-${
    sectionTag?.id || PLACEHOLDER_SECTION_TAG_ID
  }`;

  // Hooks
  const { renameColumn, createColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'newPage' implicitly has an 'any' type.
  const onPageChange = async (newPage) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setPages((prev) => ({
      ...prev,
      [sectionTag?.id || PLACEHOLDER_SECTION_TAG_ID]: newPage,
    }));
    setSectionTagId?.(sectionTag?.id);
  };

  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const addNewRecord = () => {
    createRecord(sectionTag?.id);
  };

  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  const deleteSection = () => {
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag?.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const renderRow = (recordVersion: RecordVersion, idx: number) => {
    const isApproved =
      (archivedWord?.length === 0 && archived) ||
      Boolean(
        mainStore.avroSchemas.valueForField(
          approvalFieldName,
          recordVersion.data,
        )?.verifier_id,
      ) ||
      (!!archivedWord?.length && recordVersion?.status === archivedWord);

    const isLockedTable =
      themisModuleIdentifier === "control_mapping" &&
      isLockedRecord(recordVersion, themisModuleIdentifier);

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isArchivedRow={isApproved}
        recordVersion={recordVersion}
        fields={mainStore.fields.visibleFields}
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={tableName}
        moduleIdentifier={moduleIdentifier}
        inSelectMenuActionMode={selectMode}
        maxDateFunction={(columnTitle) =>
          maxDateFunction && maxDateFunction(columnTitle, recordVersion)
        }
        minDateFunction={(columnTitle) =>
          minDateFunction && minDateFunction(columnTitle, recordVersion)
        }
        isLockedRow={!!isLockedTable}
        isDraggable={isDraggable}
        order={idx}
        isSorted={!!sortByFieldName}
      />
    );
  };

  const renderFields = fields?.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      onDelete={deleteColumn}
      onRename={renameColumn}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
    />
  ));

  return (
    <div data-testid={dataTestId}>
      {!isTopLevelSection && (
        <SectionDropdownTitle
          archived={archived}
          canManageSections={canManageSections}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          isEditable={editable}
          isOpen={!!isDropdownOpen}
          moduleWorkspaceId={moduleWorkspaceID?.toString()}
          onDelete={deleteSection}
          onToggleOpen={dropdownClick}
          tableName={tableName}
          title={dropdownTitle ?? ""}
          rolePrefix={rolePrefix}
          sectionTag={sectionTag}
        />
      )}

      {isDropdownOpen && (
        <>
          <div
            className={classNames("dropdown-table", { draggable: isDraggable })}
            data-testid="section-table"
            id={sectionTableID}
          >
            <div className="list-title-table">
              <ul>
                <div className="list-column-wrap">
                  <div className="procedures-list-column-first-blocks table-column-reorder">
                    {Boolean(fields?.length) && (
                      <TableMenu
                        setShowCheckbox={setShowCheckbox}
                        resetMenuActions={resetMenuActions}
                        selectMode={selectMode}
                        setSelectMode={setSelectMode}
                      />
                    )}
                  </div>
                  {showCheckbox && selectMode ? (
                    <div>
                      <MenuActions resetMenuActions={resetMenuActions} />
                    </div>
                  ) : (
                    <div className="list-title-table-wrap">
                      {renderFields}
                      <span className="stretch-cell" />
                    </div>
                  )}
                </div>
                {!setCustomColumns ? (
                  <AddColumnButton onAdd={createColumn} />
                ) : (
                  <div className="title-first-block finalized-block training" />
                )}
              </ul>
            </div>
            <ReorderableTable
              recordVersions={recordVersions}
              isSorted={!!sortByFieldName}
              renderer={renderRow}
              sectionTag={sectionTag}
              newRowName={recordName}
              onAddNewRow={
                moduleIdentifier !== "control_mapping" && !archived
                  ? addNewRecord
                  : undefined
              }
            />
            {moduleIdentifier === "control_mapping" && !archived && (
              <AddNewButton sectionTagId={sectionTag?.id} />
            )}
          </div>
          {paginated && (
            <div className="tw-mb-5 tw-ml-[18px]">
              <Pagination
                onChange={onPageChange}
                current={page}
                total={total}
                prevIcon={arrowLeftImage}
                nextIcon={arrowRightImage}
                showPrevNextJumpers
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

SectionDropdown.defaultValue = {
  editable: true,
  isTopLevelSection: false,
  paginated: false,
  total: 0,
  page: 1,
  setPages: noop,
  maxDateFunction: noop,
  minDateFunction: noop,
};

export default observer(SectionDropdown);

import type { AxiosResponse } from "axios";

import type {
  AssessmentDetail,
  AssessmentIndex,
  AssessmentSummary,
  AssessmentUpsert,
} from "@/features/risk-assessment";

import api from "../api";

export const AssessmentAPI = {
  // GET /workspaces/123/assessments
  async getAll(
    workspaceId: number,
    params?: { partner_id?: string; templates?: true },
  ): Promise<AssessmentIndex[]> {
    const response = await api.get<{ assessments: AssessmentIndex[] }>(
      `/workspaces/${workspaceId}/assessments`,
      {
        params,
      },
    );

    return response.data.assessments;
  },
  async get(
    workspaceId: number,
    assessmentId: number | string,
  ): Promise<AssessmentDetail> {
    const response = await api.get<{ assessment: AssessmentDetail }>(
      `/workspaces/${workspaceId}/assessments/${assessmentId}`,
    );
    return response.data.assessment;
  },
  async addQuestionnaires(
    workspaceId: number,
    assessmentId: number,
    questionnaireIds: number[],
  ): Promise<AssessmentIndex> {
    const response = await api.put<
      { assessment: AssessmentUpsert },
      AxiosResponse<{ assessment: AssessmentIndex }>
    >(`/workspaces/${workspaceId}/assessments/${assessmentId}`, {
      assessment: {
        questionnaire_ids: questionnaireIds,
      },
    });

    return response.data.assessment;
  },
  async renameAssessment(
    workspaceId: number,
    assessmentId: number,
    name: string,
  ): Promise<AssessmentIndex> {
    const response = await api.put<
      { assessment: AssessmentUpsert },
      AxiosResponse<{ assessment: AssessmentIndex }>
    >(`/workspaces/${workspaceId}/assessments/${assessmentId}`, {
      assessment: {
        name,
      },
    });

    return response.data.assessment;
  },

  // @route PUT /api/react/workspaces/:workspace_id/assessments/:id
  async updateSummaryAssessment(
    workspaceId: number,
    assessmentId: number,
    summary: string,
  ): Promise<AssessmentIndex> {
    const response = await api.put<
      { assessment: AssessmentSummary },
      AxiosResponse<{ assessment: AssessmentIndex }>
    >(`/workspaces/${workspaceId}/assessments/${assessmentId}`, {
      assessment: {
        summary,
      },
    });

    return response.data.assessment;
  },

  async create(workspaceId: number, request: { assessment: AssessmentUpsert }) {
    const response = await api.post<
      { assessment: AssessmentUpsert },
      AxiosResponse<{ assessment: AssessmentIndex }>
    >(`/workspaces/${workspaceId}/assessments/templates`, request);

    return response.data.assessment;
  },
  async delete(workspaceId: number, assessmentId: number): Promise<boolean> {
    const response = await api.delete<
      null,
      AxiosResponse<{ success: boolean }>
    >(`/workspaces/${workspaceId}/assessments/${assessmentId}`);
    return response.data.success;
  },
  async downloadAssessment(
    workspaceId: number,
    assessmentId: number,
    questionnaireId?: number,
    filename?: string,
  ) {
    const response = await api.get(
      `/workspaces/${workspaceId}/assessments/${assessmentId}/export${
        questionnaireId ? `?questionnaire_id=${questionnaireId}` : ""
      }`,
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    if (filename) {
      link.download = filename;
    } else {
      link.download = questionnaireId
        ? "questionnaire_export.xlsx"
        : `assessment_export_.zip`;
    }

    link.click();
  },
  async downloadOverview(workspaceId: number, assessmentTemplateId?: number) {
    const response = await api.get(
      `/workspaces/${workspaceId}/assessments/export${
        assessmentTemplateId
          ? `?assessment_template_id=${assessmentTemplateId}`
          : ""
      }`,
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });
    const filename = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .replace(/"/g, "");
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename || "assessment_overview_export.xlsx";
    link.click();
  },
  async duplicateAssessment(workspaceId: number, assessmentId: number) {
    const response = await api.post<
      null,
      AxiosResponse<{ assessment: AssessmentIndex }>
    >(`/workspaces/${workspaceId}/assessments/${assessmentId}/duplicate`);
    return response.data.assessment;
  },
  async generateAndOpenPDFReportRA(
    workspaceId: number,
    assessmentTemplateId?: number,
  ) {
    const response = await api.get(
      `/workspaces/${workspaceId}/assessments/export_pdf${
        assessmentTemplateId
          ? `?assessment_template_id=${assessmentTemplateId}`
          : ""
      }`,
      {
        responseType: "arraybuffer",
      },
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .replace(/"/g, "");
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename || "assessment_overview_export_pdf.pdf";
    link.click();
  },
};

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import check from "../../../images/table-image/icon/check-two-icon.svg";
import SubmitComplaint from "./SubmitComplaint";

function TopFinraComplaintsContextMenu() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const { tableName } = mainStore.context;

  // Hooks
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const userID = mainStore.users.user.id;

    if (userID && tableName === "FINRA Review") {
      mainStore.finraContacts.show(userID);
    }
  }, [mainStore.users.user, tableName]);

  // Variables
  const path = location.pathname;
  const complaintID = Number(path.substring(path.lastIndexOf("/") + 1));

  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === complaintID,
  );

  const canComplete = recordVersion?.table_name === "FINRA Escalated";
  const isReview = recordVersion?.table_name === "FINRA Review";
  const escalateToFinra = mainStore.avroSchemas.firstValueForField(
    "escalate_to_finra",
    recordVersion?.data,
  ); // "yes", "no"
  const escalatedStatus = mainStore.avroSchemas.firstValueForField(
    "escalated_status",
    recordVersion?.data,
  ); // "open", "complete"
  const reviewStatus = mainStore.avroSchemas.firstValueForField(
    "review_status",
    recordVersion?.data,
  ); // "reviewing", "submitted", "complete", "closed", "re_opened"

  const canEscalate = escalateToFinra === "yes";
  const canNotEscalate = !canEscalate;
  const isClosed = reviewStatus === "closed";
  const isSubmitted = reviewStatus === "submitted";
  const isComplete = escalatedStatus === "complete";
  const isDisabled = isClosed || isSubmitted || isComplete;

  const buttonClasses = classNames("submit", { "big-active": showPopup });
  const renderPopup = (
    <Popup
      position="bottom right"
      trigger={
        <button className={buttonClasses}>
          <img src={check} alt="check-icon" />
          Submit
        </button>
      }
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
    >
      <SubmitComplaint submitPressed={submitPressed} />
    </Popup>
  );

  async function completePressed() {
    await mainStore.finraComplaints.complete(complaintID);
    if (mainStore.recordVersions.cellsErrors.length === 0) {
      history.goBack();
    }
  }

  async function submitPressed(fullName = null, phone = null) {
    setShowPopup(false);

    if (fullName && phone) {
      await mainStore.finraComplaints.submitToFINRA(
        complaintID,
        fullName,
        phone,
      );
      if (mainStore.recordVersions.cellsErrors.length === 0) {
        history.goBack();
      }
    }
  }

  async function closeComplaint() {
    await mainStore.finraComplaints.close(complaintID);
    if (mainStore.recordVersions.cellsErrors.length === 0) {
      history.goBack();
    }
  }

  if (isDisabled) {
    return null;
  }

  return (
    <div className="buttons-complaint-wrap">
      {canComplete && (
        <button
          className="submit"
          onClick={completePressed}
          data-testid="complaint-button-complete"
        >
          Complete
        </button>
      )}

      {isReview && canEscalate && !isDisabled && renderPopup}
      {isReview && canNotEscalate && !isDisabled && (
        <button
          className="submit"
          onClick={closeComplaint}
          data-testid="complaint-button-close"
        >
          Close
        </button>
      )}
    </div>
  );
}

export default observer(TopFinraComplaintsContextMenu);

import { Avatar, Popover, PopoverContent, PopoverTrigger } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";

import { useMainStore } from "@/contexts/Store";
import type { User } from "@/stores/types/user-types";

const DEFAULT_USER_COUNT = 3;

function ViewModuleUsers() {
  const mainStore = useMainStore();
  const { themisModuleIdentifier } = mainStore.context;
  const { list: workspaces } = mainStore.moduleWorkspaces;

  const [users, setUsers] = useState<User[]>([]);

  const moduleWorkspace = useMemo(
    () =>
      workspaces.find(
        (mws) => mws.themis_module.identifier === themisModuleIdentifier,
      ),
    [workspaces, themisModuleIdentifier],
  );

  useEffect(() => {
    getUsers();
  }, [moduleWorkspace]);

  async function getUsers() {
    if (!moduleWorkspace?.id) {
      return;
    }

    try {
      const res = await mainStore.permissions.getUsersAccessibleToModule(
        moduleWorkspace?.id,
      );
      setUsers(res);
    } catch {
      setUsers([]);
    }
  }

  const popup = (
    <Popover>
      <PopoverTrigger asChild>
        <div>
          <Avatar
            size="lg"
            variant="basic"
            color="gray"
            className="tw-absolute tw-cursor-pointer tw-text-neutral-500"
            style={{ left: `${24 * DEFAULT_USER_COUNT}px` }}
          >
            +{users.length - DEFAULT_USER_COUNT}
          </Avatar>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <div className="tw-w-32 tw-rounded-lg tw-p-3">
          <div className="tw-mb-3 tw-text-xs tw-font-bold tw-tracking-wide tw-text-primary-500">
            Module Users
          </div>
          {users.slice(DEFAULT_USER_COUNT, users.length).map((user) => (
            <div
              key={`view-module-users-${user.id}`}
              className="tw-flex tw-items-center tw-gap-1"
            >
              <div>
                <Avatar colorIndex={user.icon_color_index} size="lg">
                  {user.initials}
                </Avatar>
              </div>
              <div className="tw-text-xs tw-font-semibold tw-text-neutral-500">
                {user.full_name}
              </div>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );

  return (
    <div
      className="tw-relative tw-h-7"
      style={{
        width:
          users.length > DEFAULT_USER_COUNT ? "92px" : `${23 * users.length}px`,
      }}
      data-testid="view-module-users"
    >
      {users.slice(0, DEFAULT_USER_COUNT).map((user, index) => (
        <Avatar
          key={`view-module-users-${user.id}`}
          colorIndex={user.icon_color_index}
          size="lg"
          className="tw-absolute tw-cursor-default"
          style={{ left: `${24 * index}px` }}
        >
          {user.initials}
        </Avatar>
      ))}
      {users.length > DEFAULT_USER_COUNT && popup}
    </div>
  );
}

export default observer(ViewModuleUsers);

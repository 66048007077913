import type { AxiosResponse } from "axios";
import { action, makeObservable, observable } from "mobx";

import type { Field, RecordVersion } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class Creatives implements ModuleStore {
  mainStore: MainStore;
  PLACEHOLDER_SECTION_TAG_ID = 0; // marketing has a "sectionless" section which im giving the placeholder SectionTagID of 0

  // Observable objects
  data = {};
  fields: Array<Field> = [];
  lastTab = "Active";
  pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 }; // Pages should be {section_tag_id: page_number} eg {0: 1, 13: 1, 14: 2} if the third section is on the second page.

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      fields: observable,
      lastTab: observable,
      pages: observable,

      setData: action,
      setFields: action,
      setDefaultPages: action,
      setLastTab: action,
      setPages: action,
      clearPages: action,
    });

    this.mainStore = mainStore;
  }

  async index({
    workspaceID,
    tab = this.lastTab,
    sortParams,
    sectionTagId = null,
    fromUseSortingAndPagination = false,
    pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 },
    tableFilters = [],
  }: IndexParams) {
    if (tab !== this.lastTab) {
      this.mainStore.fields.cleanup();
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        tab,
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        section_tag_id: sectionTagId,
      };
      const mergedPages = { ...this.pages, ...pages };
      const pages_string = JSON.stringify(mergedPages);
      // @ts-expect-error TS(2339) FIXME: Property 'pages' does not exist on type '{ tab: st... Remove this comment to see the full error message
      params.pages = pages_string;

      if (fromUseSortingAndPagination && !sectionTagId) {
        // @ts-expect-error TS(2339) FIXME: Property 'top_level_block' does not exist on type ... Remove this comment to see the full error message
        params.top_level_block = true;
      }

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/creatives`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.mainStore.fields.setList(response.data.fields);

      this.setPages(mergedPages);
      this.setLastTab(tab);

      if (!fromUseSortingAndPagination) {
        this.setData(response.data);
        return;
      }

      this.setData({
        ...this.data,
        record_versions: [
          // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
          ...this.data.record_versions.filter(
            // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
            (item) => item.section_tag_id !== sectionTagId,
          ),
          ...response.data.record_versions,
        ],
      });
    } catch (error) {
      window.console.log(`"Creatives#Index for Workspace" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, sectionTagId = null) {
    const data = { section_tag_id: sectionTagId };

    try {
      const response: AxiosResponse<{ record_version: RecordVersion }> =
        await legacyApi({
          method: "POST",
          url: `${API_URL}/workspaces/${workspaceID}/creatives`,
          headers: this.mainStore.getHeaders(),
          data,
        });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"Creatives#create" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/creatives/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Creatives#delete" error ${error}`);
    }
  }

  /**
   * POST /api/react/creatives/:creative_id/review
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async approve(recordVersionID) {
    const params = { review_type: "approval" };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/creatives/${recordVersionID}/review`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError && response.response?.data?.cells_errors) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          response.response.data.cells_errors,
        );
        return null;
      }

      this.mainStore.recordVersions.setCurrent(response.data.record_version);

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"Approvals#create" error ${error}`);

      return null;
    }
  }

  /**
   * POST /api/react/creatives/:id/review
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async deny(recordVersionID) {
    const params = { review_type: "denial" };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/creatives/${recordVersionID}/review`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return null;
      }

      this.mainStore.recordVersions.setCurrent(response.data.record_version);

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"creatives#reject" error ${error}`);

      return null;
    }
  }

  // POST /api/react/creatives/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID, removeFromList = false) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/creatives/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          response.response,
          // @ts-expect-error TS(2304) FIXME: Cannot find name 'defaultMessage'.
          (defaultMessage =
            "Something went wrong. Please contact support@themis.com"),
        );
      } else {
        this.mainStore.recordVersions.setCurrent(response.data.record_version);
        if (removeFromList) {
          this.mainStore.recordVersions.setList(
            // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
            this.data.record_versions.filter(
              // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
              (item) => item.id !== recordVersionID,
            ),
          );
        }
        this.mainStore.toast.setText("Creative unlocked successfully");
      }
    } catch (error) {
      window.console.log(`"creatives#unlock" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async generateAndOpenPDFReport(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/creatives/${recordVersionID}/pdf_report`,
        headers: this.mainStore.getHeaders(),
        responseType: "blob",
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        const text = await response.response.data.text();
        const errors = JSON.parse(text).errors?.join(",");
        const { error } = JSON.parse(text);
        this.mainStore.toast.setErrorText(errors || error);
        return;
      }

      const link = document.createElement("a");
      link.href = URL.createObjectURL(response.data);
      link.target = "_blank";
      link.click();
    } catch (error) {
      window.console.log(
        `"Creatives#generateAndOpenPDFReport for Creative" error ${error}`,
      );
    }
  }

  // # POST /api/react/creatives/:id/push_to_approvers
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async pushToApprovers(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/creatives/${recordVersionID}/push_to_approvers`,
        headers: this.mainStore.getHeaders(),
      });

      this.mainStore.recordVersions.setCurrent(response.data.record_version);
    } catch (error) {
      window.console.log(`"creatives#pushToApprovers" error ${error}`);
    }
  }

  // # POST /api/react/creatives/:id/back_to_submitter
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async backToSubmitter(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/creatives/${recordVersionID}/back_to_submitter`,
        headers: this.mainStore.getHeaders(),
      });

      this.mainStore.recordVersions.setCurrent(response.data.record_version);
    } catch (error) {
      window.console.log(`"creatives#backToSubmitter" error ${error}`);
    }
  }

  // POST /api/react/creatives/:creative_id/attachment-groups/:file_id/approve
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  async approveFile(recordVersionId, fileSignedId) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/creatives/${recordVersionId}/attachment-groups/${fileSignedId}/approve`,
        headers: this.mainStore.getHeaders(),
      });

      this.mainStore.recordVersions.setCurrent(response.data.record_version);
    } catch (error) {
      window.console.log(`"Reviews#create" error ${error}`);
    }
  }

  // DELETE /api/react/creatives/:creative_id/attachment-groups/:file_id/unapprove
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
  async unapproveFile(recordVersionId, fileSignedId) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/creatives/${recordVersionId}/attachment-groups/${fileSignedId}/unapprove`,
        headers: this.mainStore.getHeaders(),
      });

      this.mainStore.recordVersions.setCurrent(response.data.record_version);
    } catch (error) {
      window.console.log(`"Reviews#create" error ${error}`);
    }
  }

  // PUT /api/react/creatives/:id/update_section
  async updateSection(
    recordVersionID: string | number,
    sectionTagID: string | number,
  ) {
    const data = { section_tag_id: sectionTagID, identifier: "marketing" };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/creatives/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Creatives#update-section" error ${error}`);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setFields(value) {
    this.fields = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLastTab(value) {
    this.lastTab = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPages(value) {
    const merged_pages = { ...this.pages, ...value };
    this.pages = merged_pages;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTags' implicitly has an 'any' ty... Remove this comment to see the full error message
  setDefaultPages(sectionTags) {
    const new_pages = sectionTags?.reduce(
      // @ts-expect-error TS(7006) FIXME: Parameter 'object' implicitly has an 'any' type.
      (object, section) => ((object[section?.id] = 1), object),
      {},
    );
    const merged_pages = { ...this.pages, ...new_pages };
    this.pages = merged_pages;
  }

  clearPages() {
    this.pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 };
  }

  // Store Helpers

  cleanup() {
    this.setData({});
    this.setLastTab("Active");
    this.clearPages();
  }

  abort() {
    this.controller?.abort();
  }
}

import { Avatar, Tooltip, TooltipContent, TooltipTrigger } from "@themis/ui";
import React from "react";

import type { Account, FieldOption } from "@/api";

import { AccountTypeTagsList } from "./account-type-tags-list";

function RemainingAccountTypesAvatar({ count }: { count: number }) {
  return (
    <Avatar size="lg" variant="basic" color="gray">
      +{count}
    </Avatar>
  );
}

export function AccountTypesTags({
  accountTypes: allAccountTypes,
  accountTypeFieldOptions,
  numberOfAccountTypesToShow,
  isTableTooltip,
}: {
  accountTypes: Account["account_types"];
  accountTypeFieldOptions: FieldOption[];
  numberOfAccountTypesToShow: number;
  /**
   * If the tags are in a table tooltip, the tooltip will be rendered by AG Grid.
   * It is defined in the AG Grid column definition.
   */
  isTableTooltip?: boolean;
}) {
  const accountTypesToShow = allAccountTypes.slice(
    0,
    numberOfAccountTypesToShow,
  );
  const remainingAccountTypes = allAccountTypes.slice(
    numberOfAccountTypesToShow,
  );

  return (
    <div className="tw-flex tw-gap-1">
      <AccountTypeTagsList
        types={accountTypesToShow}
        accountTypeFieldOptions={accountTypeFieldOptions}
      />
      {remainingAccountTypes.length > 0 &&
        (isTableTooltip ? (
          <RemainingAccountTypesAvatar count={remainingAccountTypes.length} />
        ) : (
          <Tooltip>
            <TooltipTrigger>
              <RemainingAccountTypesAvatar
                count={remainingAccountTypes.length}
              />
            </TooltipTrigger>
            <TooltipContent>
              <div className="tw-flex tw-flex-col tw-gap-1">
                <AccountTypeTagsList
                  isInTooltip
                  types={remainingAccountTypes}
                  accountTypeFieldOptions={accountTypeFieldOptions}
                />
              </div>
            </TooltipContent>
          </Tooltip>
        ))}
    </div>
  );
}

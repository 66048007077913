import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

// TODO: Dan says add tests
export default class AuditTrailsStore {
  mainStore: MainStore;

  auditTrails = [];
  actions = [];
  count = 0;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      auditTrails: observable,
      setAuditTrails: action,
      setActions: action,
      setCount: action,
      count: observable,
    });

    this.mainStore = mainStore;
  }
  // GET /api/react/records/:id/audit_trail
  // @ts-expect-error TS(7031) FIXME: Binding element 'recordID' implicitly has an 'any'... Remove this comment to see the full error message
  async fetchRecordAuditTrail({ recordID, actionName = null, page = 1 }) {
    const params = { action_name: actionName, record_id: recordID, page };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/records/${recordID}/audit_trail`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
      } else {
        if (!actionName) {
          this.setActions(response.data.actions);
        }
        this.setAuditTrails(response.data.audit_trails);
        this.setCount(response.data.count);
      }
    } catch (error) {
      window.console.log(`"Record#audit_trail" error ${error}`);
    }
  }

  async fetchModuleAuditTrail({
    // @ts-expect-error TS(7031) FIXME: Binding element 'moduleIdentifier' implicitly has ... Remove this comment to see the full error message
    moduleIdentifier,
    actionName = null,
    page = 1,
  }) {
    this.setAuditTrails([]);
    const params = {
      action_name: actionName,
      identifier: moduleIdentifier,
      page,
    };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/modules/${moduleIdentifier}/audit_trail`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      const { data } = response;
      const audit_trails = data?.audit_trails ?? [];

      if (!audit_trails.length) {
        this.mainStore.toast.setErrorFromResponse(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          response.response,
          // @ts-expect-error TS(2304) FIXME: Cannot find name 'defaultMessage'.
          (defaultMessage = "Audit trail not found"),
        );
        return;
      }

      if (!actionName) {
        this.setActions(data.actions);
      }

      this.setAuditTrails(audit_trails);
      this.setCount(data?.count);
    } catch (error) {
      window.console.log(`"Activities#index" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAuditTrails(value) {
    this.auditTrails = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCount(value) {
    this.count = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setActions(value) {
    this.actions = value;
  }

  // Store Helpers

  cleanup() {
    this.setAuditTrails([]);
    this.setActions([]);
  }
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateAccountContactMutationRequest,
  CreateAccountContactMutationResponse,
  CreateAccountContactPathParams,
} from "../../models/CreateAccountContact";

/**
 * @description Create account contact
 * @summary Create account contact
 * @link /accounts/:account_id/contacts
 */
export async function createAccountContact(
  accountId: CreateAccountContactPathParams["account_id"],
  data: CreateAccountContactMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateAccountContactMutationResponse>["data"]> {
  const res = await client<
    CreateAccountContactMutationResponse,
    CreateAccountContactMutationRequest
  >({
    method: "post",
    url: `/accounts/${accountId}/contacts`,
    data,
    ...options,
  });
  return res.data;
}

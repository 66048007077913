import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import RiskRegisterReportMatrixContainer from "./RiskRegisterReportMatrixContainer";

function RiskRegisterReportContent() {
  // Import MobX stores
  const mainStore = useMainStore();

  // vars
  const { data } = mainStore.riskRegisters;
  const { company } = mainStore.companies;
  const matrices = data?.scoring_matrices || [];

  // Effects
  useEffect(() => {
    if (!mainStore.reports.selectedWorkspaceIDs.length) {
      return;
    }

    mainStore.riskRegisters.index({
      tab: "Completed",
      workspaceID: mainStore.reports.selectedWorkspaceIDs[0] as number,
    });
  }, [mainStore.reports.selectedWorkspaceIDs, company]);

  if (!matrices?.length) {
    return null;
  }

  return (
    <div className="report-dashboard-wrap report-rr-matrices-wrap">
      {matrices.map((matrix) => (
        <RiskRegisterReportMatrixContainer key={matrix.id} matrix={matrix} />
      ))}
    </div>
  );
}

export default observer(RiskRegisterReportContent);

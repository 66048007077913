// Check if the clicked node or any of its parent nodes have the attribute "data-radix-popper-content-wrapper" ie. is a dropdown popup
const isInAPopup = (clickedNode: HTMLElement | null): boolean => {
  let node = clickedNode;

  while (node instanceof HTMLElement) {
    if (node.hasAttribute("data-radix-popper-content-wrapper")) {
      return true;
    }
    node = node.parentNode as HTMLElement | null;
  }
  return false;
};

export { isInAPopup };

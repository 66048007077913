import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { useMainStore } from "@/contexts/Store";
import ScoringMatrixRatingRow from "@/features/risk-register/ScoringMatrixRatingRow";
import TableInputGroup from "@/features/risk-register/TableInputGroup";
import type { ScoringMatrixRating } from "@/stores/types/risk-register-types";

interface ScoringMatrixRatingsProps {
  groupTitle: string;
  description?: string;
  dataTestID?: string;
  riskType: string;
  riskRatings: ScoringMatrixRating[];
  setRiskRatings: (ratings: ScoringMatrixRating[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEditSubmit: (...args: any[]) => any;
  setEditingMatrixChanged: (changed: boolean) => void;
}

function ScoringMatrixRatings({
  description,
  groupTitle,
  dataTestID,
  riskType,
  riskRatings,
  setRiskRatings,
  handleEditSubmit,
  setEditingMatrixChanged,
}: ScoringMatrixRatingsProps) {
  const mainStore = useMainStore();
  const [errors, setErrors] = useState<number[]>([]);

  const { moduleWorkspaceID } = mainStore.context;

  const removeRating = (index: number) => {
    const riskRating = riskRatings.find((subRiskRating, i) => i === index);

    if (riskRating?.id) {
      riskRating._destroy = true;
      const newList = [...riskRatings];
      setRiskRatings(newList);
    } else {
      const newList = riskRatings.filter((subRiskRating, i) => i !== index);
      setRiskRatings(newList);
    }
  };

  const updateTitle = (index: number, value: string) => {
    riskRatings[index].title = value;
    setRiskRatings([...riskRatings]);
    checkError(value, riskRatings[index].value, index);
    setEditingMatrixChanged(true);
  };

  const updateValue = (index: number, value: number) => {
    riskRatings[index].value = value;
    setRiskRatings([...riskRatings]);
    checkError(riskRatings[index].title, value, index);
    setEditingMatrixChanged(true);
  };

  const updateColor = (index: number, value: string) => {
    riskRatings[index].color = value;
    setRiskRatings([...riskRatings]);
    setEditingMatrixChanged(true);
  };

  const checkError = (title: string, value: number, index: number): boolean => {
    if (title.length === 0) {
      mainStore.toast.setErrorText("The title cannot be blank");
      if (!errors.includes(index)) {
        setErrors((prev) => [...prev, index]);
      }
      return true;
    }

    if (value > 10) {
      if (!errors.includes(index)) {
        mainStore.toast.setErrorText("Value must not be greater than 10");
        setErrors((prev) => [...prev, index]);
      }
      return true;
    }

    for (let subIndex = 0; subIndex < riskRatings.length; ++subIndex) {
      if (subIndex !== index && riskRatings[subIndex].title === title) {
        mainStore.toast.setErrorText("The title is taken");
        if (!errors.includes(index)) {
          setErrors((prev) => [...prev, index]);
        }
        return true;
      }
    }

    if (errors.includes(index)) {
      setErrors(errors.filter((i) => i !== index));
    }

    return false;
  };

  async function saveRatings() {
    if (errors.length === 0) {
      await mainStore.riskRegisters.updateMatrixRatings(
        moduleWorkspaceID,
        riskRatings,
        riskType,
        false,
      );
      await handleEditSubmit();
    }
  }

  const rows = riskRatings.map((riskRating, index) =>
    riskRating._destroy ? null : (
      <div
        key={`Risk-Rating-Row-${riskRating.id}`}
        className="input-group-row"
        data-testid={`${dataTestID}-row-${index}`}
      >
        <ScoringMatrixRatingRow
          id={riskRating.id}
          text={riskRating.title}
          value={riskRating.value}
          index={index}
          color={riskRating.color}
          riskType={riskType}
          updateTitle={updateTitle}
          updateColor={updateColor}
          updateValue={updateValue}
          dataTestID={dataTestID}
          removeRating={removeRating}
          hasError={errors.includes(index)}
          riskRatingsCount={riskRatings.filter((rr) => !rr._destroy).length}
        />
      </div>
    ),
  );

  return (
    <TableInputGroup
      description={description}
      title={groupTitle}
      addNewInputText={`Add New ${capitalize(riskType)} Rating`}
      dataTestID={dataTestID}
      addNewInput={() =>
        setRiskRatings([
          ...riskRatings,
          { title: "", value: 0, color: "#66AA22", risk_type: riskType },
        ])
      }
      saveRatings={saveRatings}
      showAddNew={riskRatings.filter((rr) => !rr._destroy).length < 5}
      hasError={errors.length > 0}
    >
      {rows}
    </TableInputGroup>
  );
}

export default observer(ScoringMatrixRatings);

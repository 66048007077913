import {
  IconButton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import dayjs from "dayjs";
import React from "react";
import { PiInfoBold } from "react-icons/pi";

import type { LinkedFinding } from "@/api";
import { DATE_FORMAT } from "@/constants";

export function PreviewRelatedFindingsNameCell({
  date_identified,
  description,
  due_date,
  id,
  name,
  risk_level,
}: LinkedFinding) {
  return (
    <div className="tw-flex tw-min-w-0 tw-grow tw-items-center tw-justify-between tw-gap-2">
      <p className="tw-truncate">{name}</p>
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            size="sm"
            color="transparent"
            Icon={PiInfoBold}
            aria-label={`Related Finding Info for ${name}`}
            aria-describedby={`related-finding-${id}`}
          />
        </TooltipTrigger>
        <TooltipContent>
          <article className="tw-max-w-72" id={`related-finding-${id}`}>
            <p>Description: {description}</p>
            <br />
            <p>Risk Level: {risk_level}</p>
            <br />
            <p>Date Identified: {dayjs(date_identified).format(DATE_FORMAT)}</p>
            <br />
            <p>Due Date: {dayjs(due_date).format(DATE_FORMAT)}</p>
          </article>
        </TooltipContent>
      </Tooltip>
    </div>
  );
}

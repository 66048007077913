import type { ComponentType } from "react";
import type { Control } from "react-hook-form";

import type { ValidationSchema } from "../use-validation-schema/use-validation-schema";
import { DropdownColumnControls } from "./dropdown-column-controls/dropdown-column-controls";
import { MultipleColumnControls } from "./multiple-column-controls/multiple-column-controls";
import { SingleColumnControls } from "./single-column-controls/single-column-controls";

type BaseColumnComponent = ComponentType<{
  control: Control<ValidationSchema>;
}>;

const columns = {
  attachement: MultipleColumnControls,
  checklist: SingleColumnControls,
  date: SingleColumnControls,
  dropdown: DropdownColumnControls,
  link: SingleColumnControls,
  text: SingleColumnControls,
  user: MultipleColumnControls,
} satisfies Record<ValidationSchema["columnType"], BaseColumnComponent>;

interface CustomColumnFieldProps<T extends keyof typeof columns> {
  columnType: T;
  control: Control<ValidationSchema>;
}

export const CustomColumnControls = <T extends keyof typeof columns>({
  columnType,
  control,
}: CustomColumnFieldProps<T>) => {
  const ColumnComponent: BaseColumnComponent = columns[columnType];

  return <ColumnComponent control={control} />;
};

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@/components/form/Button";
import { useMainStore } from "@/contexts/Store";
import { formatErrors } from "@/utils/errors";

import Header from "../components/Header";
import PasswordFields from "../components/PasswordFields";

function CreateNewPassword() {
  // Import MobX stores
  const mainStore = useMainStore();

  const history = useHistory();
  const location = useLocation();

  // State
  const [passwordResetToken, setPasswordResetToken] = useState(null);
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const [passwordValid, setPasswordValid] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setPasswordResetToken(token);
    } else {
      setPasswordResetToken(null);
    }
  }, [location.search]);

  useEffect(() => {
    const shouldEnableForm = passwordResetToken && passwordValid;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setIsFormEnabled(shouldEnableForm);
  }, [passwordResetToken, passwordValid]);

  useEffect(() => {
    if (mainStore.users.newPasswordIsSet) {
      history.push("/sign-in");
    }
  }, [mainStore.users.newPasswordIsSet]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleSubmit(event) {
    event.preventDefault();

    mainStore.users.newPassword(password, passwordResetToken);
  }

  return (
    <div className="check-in">
      <Header />
      <div className="check-in-wrap">
        <div className="password-wrap">
          <form onSubmit={handleSubmit}>
            <h2>Create New Password</h2>
            <p className="password-text">
              Enter your email address and we’ll send you a one-time URL to
              reset
              <br /> your password. The link will expire after 10 minutes.
            </p>
            <div className="password-form-wrap">
              {!passwordResetToken ? (
                <p className="error-input">
                  {passwordResetToken
                    ? ""
                    : "Password reset token should be present"}
                </p>
              ) : (
                ""
              )}
              {mainStore.users.userErrors ? (
                <p className="error-input">
                  {formatErrors(mainStore.users.userErrors)}
                </p>
              ) : (
                ""
              )}
              <PasswordFields
                // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<undefined>>' is not ... Remove this comment to see the full error message
                onValidityChange={setPasswordValid}
                // @ts-expect-error TS(2322) FIXME: Type 'Dispatch<SetStateAction<undefined>>' is not ... Remove this comment to see the full error message
                onPasswordChange={setPassword}
              />
              <Button title="Continue" enabled={isFormEnabled} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default observer(CreateNewPassword);

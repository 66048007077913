import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import ConfirmationDialog from "../shared/ConfirmationDialog";

interface Props {
  documentID: number;
  recordVersionID: number;
}

function AuditDetailViewDocumentsActions({
  recordVersionID,
  documentID,
}: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // state
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const { canDeleteRecords, hasModuleWriteAccess } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;

  // funcs
  function noButtonPressed() {
    handlePopUpClose();
  }

  function deleteRowConfirmation() {
    setIsDeleteConfirmationOpen(true);
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  function handleDelete() {
    mainStore.themisAudits.destroyDocument(recordVersionID, documentID);
  }

  // Elements
  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div className="table-dropdown policy-dropdown">
      <ul>
        <Link
          to={`/workspaces/${workspaceID}/modules/audits/${recordVersionID}/documents/${documentID}/update`}
        >
          Edit
        </Link>
        {canDeleteRecords && <li onClick={deleteRowConfirmation}>Delete</li>}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Document"
      content="Are you sure you want to delete this document? Actions are not reversible."
      handleConfirm={handleDelete}
      handleReject={noButtonPressed}
    />
  );

  const renderPopup = () => (
    <div className="list-points detail-view-documents-actions">
      <Popup
        position="bottom right"
        trigger={() => renderTrigger}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
      >
        {!isDeleteConfirmationOpen && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );

  return hasModuleWriteAccess && renderPopup();
}

export default observer(AuditDetailViewDocumentsActions);

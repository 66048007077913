import "./styles.scss";

import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

import { Button, Flex, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import zendeskIcon from "@/images/table-image/icon/zen-desk.svg";

function ZendeskConnect() {
  const mainStore = useMainStore();

  if (!mainStore.userPermissions.canManageSettings) {
    return (
      <Typography
        className="welcome-zendesk__description"
        color="extrasBlueGrayDarker"
        size="sm"
        weight="semiBold"
        label="Please contact workspace admin to set up connection to Zendesk account."
      />
    );
  }

  return (
    <Flex className="welcome-zendesk-connect" alignCenter columnGap={40}>
      <Flex alignCenter columnGap={16}>
        <img src={zendeskIcon} />
        <Flex column rowGap={4}>
          <Typography
            weight="bold"
            label="Zendesk"
            color="extrasDarkGunmetal"
          />
          <Typography
            size="xs"
            weight="semiBold"
            color="extrasBlueGrayDarker"
            label="Connect your company's Zendesk account and automate the import of Zendesk tickets needed to be reviewed by your team."
          />
        </Flex>
      </Flex>
      <Link to="/settings/integrations/zendesk/connect">
        <Button label="Connect" />
      </Link>
    </Flex>
  );
}

export default observer(ZendeskConnect);

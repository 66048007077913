import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class CustomerSupportStore implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  data = {};
  page = 1;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      page: observable,
    });

    this.mainStore = mainStore;
  }

  async index({
    tableName,
    sortParams,
    tableFilters = [],
    page = 1,
  }: IndexParams) {
    if (!tableName) {
      window.console.warn(
        `CustomerSupport#index => tableName(${tableName}) should be present!`,
      );
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    this.setPage(page);

    const params = {
      table_title: tableName,
      page,
      table_filters: tableFilters,
    };

    if (sortParams) {
      // @ts-expect-error TS(2339) FIXME: Property 'sort_field_name' does not exist on type ... Remove this comment to see the full error message
      params.sort_field_name = sortParams.field_name;
      // @ts-expect-error TS(2339) FIXME: Property 'sort_direction' does not exist on type '... Remove this comment to see the full error message
      params.sort_direction = sortParams.direction;
    }

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/customer_support`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.mainStore.fields.setList(response.data.fields);
      this.setData(response.data);
    } catch (error) {
      window.console.log(`CustomerSupport#index error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'tableName' implicitly has an 'any' type... Remove this comment to see the full error message
  async create(tableName) {
    if (!tableName) {
      window.console.warn(
        `CustomerSupport#create => tableName(${tableName}) should be present!`,
      );
      return;
    }

    let data = { table_title: tableName };
    if (tableName === "Customer Support") {
      data = { ...data, ...{ owner: this.mainStore.users.user.id } };
    }

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/customer_support`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"CustomerSupport#create" error ${error}`);
      return null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/customer_support/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"CustomerSupport#delete" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async reopen(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/customer_support/${recordVersionID}/reopen`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"CustomerSupport#reopen" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async close(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/customer_support/${recordVersionID}/close`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"CustomerSupport#close" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async complete(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/customer_support/${recordVersionID}/complete`,
        headers: this.mainStore.getHeaders(),
      });
      this.mainStore.recordVersions.setCellsErrors([]);
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"CustomerSupport#complete" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
  setPage(page) {
    this.page = page;
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // Store Helpers

  cleanup() {
    this.setData({});
  }

  abort() {
    this.controller?.abort();
  }
}

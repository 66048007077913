import React from "react";
import { PiDownloadSimpleBold, PiThumbsUpBold, PiWarningBold, PiXBold, } from "react-icons/pi";
import { toast as sonnerToast } from "sonner";
import { cn } from "../../lib/utils";
import { IconButton } from "../Button/IconButton";
var variantIconMap = {
    success: {
        Icon: PiThumbsUpBold,
        gradientStyle: "tw-fill-[url(#gradientV3)]",
    },
    error: {
        Icon: PiWarningBold,
        gradientStyle: "tw-fill-[url(#gradientLavender)]",
    },
    download: {
        Icon: PiDownloadSimpleBold,
        gradientStyle: "tw-fill-[url(#gradientV3)]",
    },
};
function Gradients() {
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { className: "tw-absolute tw-h-0 tw-w-0" },
            React.createElement("linearGradient", { id: "gradientV3", x1: "0%", y1: "0%", x2: "0%", y2: "100%" },
                React.createElement("stop", { stopColor: "#E3AEFF", offset: "0%" }),
                React.createElement("stop", { stopColor: "#36B5FF", offset: "100%" }))),
        React.createElement("svg", { className: "tw-absolute tw-h-0 tw-w-0" },
            React.createElement("linearGradient", { id: "gradientLavender", x1: "100%", y1: "0%", x2: "100%", y2: "100%" },
                React.createElement("stop", { stopColor: "#9B9BED", offset: "0%" }),
                React.createElement("stop", { stopColor: "#FF77A8", offset: "100%" })))));
}
export function useToast() {
    function toast(_a) {
        var content = _a.content, variant = _a.variant, hideCloseButton = _a.hideCloseButton, onClick = _a.onClick;
        return sonnerToast.custom(function (toastId) {
            var _a = variantIconMap[variant], Icon = _a.Icon, gradientStyle = _a.gradientStyle;
            function handleClose(event) {
                event.stopPropagation();
                sonnerToast.dismiss(toastId);
            }
            return (React.createElement("div", { onClick: function () {
                    if (hideCloseButton) {
                        sonnerToast.dismiss(toastId);
                    }
                    onClick && onClick();
                }, className: cn("tw-inline-flex tw-max-w-xl tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-500 tw-px-6 tw-py-4 tw-font-sans tw-text-sm tw-font-medium tw-text-neutral-25 tw-shadow-dropdown", { "tw-cursor-pointer": onClick }) },
                React.createElement(Gradients, null),
                React.createElement(Icon, { className: cn("tw-h-5 tw-w-5 tw-shrink-0", gradientStyle) }),
                content,
                !hideCloseButton && (React.createElement(IconButton, { size: "sm", color: "transparentWhite", Icon: PiXBold, onClick: function (e) { return handleClose(e); } }))));
        });
    }
    return toast;
}

import React from "react";
import { useParams } from "react-router-dom";

import {
  DATE_TAB,
  EVENT_TAB,
  UNLOCK_TASK_TAB,
} from "@/features/notifications/pages/constants";
import DateNotificationsSinglePage from "@/features/notifications/pages/single/date";
import EventNotificationsSinglePage from "@/features/notifications/pages/single/event";
import UnlockTaskPage from "@/features/notifications/pages/single/unlock-task";
import type { ModuleNotificationParams } from "@/features/notifications/types/types";

export function ModuleNotificationsSinglePage({
  moduleWorkspaceID,
}: {
  moduleWorkspaceID: number;
}) {
  const params = useParams<ModuleNotificationParams>();

  const pageFactory = () => {
    const { tab } = params;
    if (tab === DATE_TAB) {
      return (
        <DateNotificationsSinglePage moduleWorkspaceID={moduleWorkspaceID} />
      );
    }

    if (tab === EVENT_TAB) {
      return (
        <EventNotificationsSinglePage moduleWorkspaceID={moduleWorkspaceID} />
      );
    }

    if (tab === UNLOCK_TASK_TAB) {
      return <UnlockTaskPage moduleWorkspaceID={moduleWorkspaceID} />;
    }
  };

  return pageFactory();
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListTagsPathParams,
  ListTagsQueryResponse,
} from "../../models/ListTags";

/**
 * @description List tags
 * @summary List tags
 * @link /companies/:company_id/tags
 */
export async function listTags(
  companyId: ListTagsPathParams["company_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListTagsQueryResponse>["data"]> {
  const res = await client<ListTagsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/tags`,
    ...options,
  });
  return res.data;
}

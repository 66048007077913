import { useLayoutEffect, useState } from "react";
export function useResizeObserver(element) {
    var _a = useState(), entry = _a[0], setEntry = _a[1];
    useLayoutEffect(function () {
        if (!element) {
            // Trigger re-render with null entry when element is undefined
            setEntry(null);
            return;
        }
        var observer = new ResizeObserver(function (_a) {
            var observerEntry = _a[0];
            setEntry(observerEntry);
        });
        observer.observe(element);
        return function () {
            observer.disconnect();
        };
    }, [element]);
    return entry;
}

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const TrainingReportContent = () => (
  <ReportsPage
    identifier="training"
    defaultDateColumn="created_at"
    defaultGroupColumn="status"
  />
);

export default observer(TrainingReportContent);

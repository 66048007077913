import {
  IconButton,
  TextInput,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import React, { useCallback, useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";

import { useControlMappingsLibrary } from "@/api/queries/controlMappings";

interface LibraryItem {
  id: number;
  title: string;
  description: string;
  control_category: string;
  frameworks: Array<LibraryItemFramework>;
}

interface LibraryItemFramework {
  title: string;
  tag_type: string;
}

function LibraryControlMappingMenu({
  recordVersionId,
  onSelectLibraryItem,
}: {
  recordVersionId: number;
  onSelectLibraryItem: (item: LibraryItem) => void;
}) {
  const [filter, setFilter] = useState("");
  const [library, setLibrary] = useState<{
    [key: string]: LibraryItem[];
  }>({});

  const libraryItems = useControlMappingsLibrary(recordVersionId, "themis")
    ?.data?.data;

  const mutateData = useCallback(() => {
    const mutatedItems: {
      [key: string]: Array<LibraryItem>;
    } = {};
    const filteredItems = libraryItems?.library_items?.filter(
      (li: { title: string }) =>
        li.title.toLowerCase().includes(filter.toLowerCase()),
    );
    if (filteredItems) {
      filteredItems.forEach((item: LibraryItem) => {
        item.frameworks.forEach((framework: LibraryItemFramework) => {
          if (framework.tag_type === "framework") {
            if (mutatedItems[framework.title]) {
              mutatedItems[framework.title].push(item);
            } else {
              mutatedItems[framework.title] = [item];
            }
          }
        });
      });
    }

    setLibrary(mutatedItems);
  }, [libraryItems, filter]);

  useEffect(() => {
    mutateData();
  }, [libraryItems, filter]);

  return (
    <div className="tw-flex tw-flex-col tw-px-5">
      <TextInput
        size="lg"
        placeholder="Search Controls"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFilter(e.target.value)
        }
      />
      <div>
        {Object.keys(library).map((framework) => (
          <div key={framework}>
            <div className="tw-flex tw-items-center tw-border-0 tw-border-b tw-border-solid tw-border-generals-underline tw-pb-2 tw-pt-4">
              <div className="tw-text-sm tw-font-semibold tw-text-neutral-500">
                {framework}
              </div>
            </div>
            {library[framework].map((item) => (
              <div
                key={`${item.id}-${item.title}`}
                className="tw-flex tw-items-center tw-gap-2 tw-border-0 tw-border-b tw-border-solid tw-border-generals-underline tw-py-2"
              >
                <div className="tw-flex tw-flex-col">
                  <div className="tw-text-sm tw-font-medium tw-text-neutral-500">
                    {item.title}
                  </div>
                  {item.description && (
                    <div className="tw-line-clamp-2 tw-text-xs tw-font-medium tw-text-neutral-300">
                      {item.description}
                    </div>
                  )}
                </div>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <IconButton
                      color="transparent"
                      size="sm"
                      Icon={PiPlusBold}
                      onClick={() => onSelectLibraryItem(item)}
                    />
                  </TooltipTrigger>
                  <TooltipContent align="center" side="bottom">
                    Create Control
                  </TooltipContent>
                </Tooltip>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LibraryControlMappingMenu;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateModuleAssessmentStatusMutationResponse,
  UpdateModuleAssessmentStatusPathParams,
  UpdateModuleAssessmentStatusQueryParams,
} from "../../models/UpdateModuleAssessmentStatus";

/**
 * @description Update module assessment status
 * @summary Update module assessment status
 * @link /module_assessments/:module_assessment_id/status
 */
export async function updateModuleAssessmentStatus(
  moduleAssessmentId: UpdateModuleAssessmentStatusPathParams["module_assessment_id"],
  params?: UpdateModuleAssessmentStatusQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<UpdateModuleAssessmentStatusMutationResponse>["data"]
> {
  const res = await client<UpdateModuleAssessmentStatusMutationResponse>({
    method: "put",
    url: `/module_assessments/${moduleAssessmentId}/status`,
    params,
    ...options,
  });
  return res.data;
}

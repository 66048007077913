import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Close = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4143 12.0003L18.7072 6.70725C19.0982 6.31625 19.0982 5.68425 18.7072 5.29325C18.3162 4.90225 17.6843 4.90225 17.2933 5.29325L12.0002 10.5863L6.70725 5.29325C6.31625 4.90225 5.68425 4.90225 5.29325 5.29325C4.90225 5.68425 4.90225 6.31625 5.29325 6.70725L10.5862 12.0003L5.29325 17.2933C4.90225 17.6843 4.90225 18.3163 5.29325 18.7073C5.48825 18.9023 5.74425 19.0003 6.00025 19.0003C6.25625 19.0003 6.51225 18.9023 6.70725 18.7073L12.0002 13.4143L17.2933 18.7073C17.4883 18.9023 17.7442 19.0003 18.0002 19.0003C18.2562 19.0003 18.5122 18.9023 18.7072 18.7073C19.0982 18.3163 19.0982 17.6843 18.7072 17.2933L13.4143 12.0003Z"
      fill="#353549"
    />
  </svg>
);

Close.displayName = "CloseIcon";

export default Close;

import { MiniTag } from "@themis/ui";
import React from "react";

import type { FieldOption } from "@/api";

export function AccountTypeTagsList({
  types,
  accountTypeFieldOptions,
  isInTooltip,
}: {
  types: string[];
  accountTypeFieldOptions: FieldOption[];
  isInTooltip?: boolean;
}) {
  return types.map((accountType) => {
    const accountTypeOptionIndex = accountTypeFieldOptions.findIndex(
      ({ key }) => key === accountType,
    );

    return (
      <MiniTag
        key={accountType}
        size="lg"
        colorIndex={accountTypeOptionIndex}
        className={isInTooltip ? "" : "tw-max-w-32"}
      >
        <div
          className={isInTooltip ? "" : "tw-truncate"}
          title={accountTypeFieldOptions[accountTypeOptionIndex].value}
        >
          {accountTypeFieldOptions[accountTypeOptionIndex].value}
        </div>
      </MiniTag>
    );
  });
}

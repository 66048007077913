import React from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  placeHolder?: string;
  datatestid?: string;
  defaultValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: (...args: any[]) => any;
  type?: string;
}

function TextInput({
  name,
  className,
  label,
  datatestid,
  defaultValue,
  register,
  type,
  placeHolder,
  ...rest
}: TextInputProps) {
  return (
    <div className="form-input">
      <input
        className={className}
        type={type}
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        {...register(name, { required: `${label || name} is required` })}
        placeholder={placeHolder || name}
        name={name}
        autoComplete="off"
        defaultValue={defaultValue}
        data-testid={datatestid}
        {...rest}
      />
    </div>
  );
}

export default TextInput;

import React from "react";

import sharepointImage from "@/images/table-image/icon/sharepoint.webp";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const SharePoint = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" fill="url(#sharepoint-pattern)" />
    <defs>
      <pattern
        id="sharepoint-pattern"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#sharepoint-icon"
          transform="matrix(0.000423901 0 0 0.000433831 0.0590236 0.0565643)"
        />
      </pattern>
      <image
        id="sharepoint-icon"
        width="2097"
        height="2049"
        xlinkHref={sharepointImage}
      />
    </defs>
  </svg>
);

SharePoint.displayName = "SharePointIcon";

export default SharePoint;

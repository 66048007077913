import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Reply = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 19C10 19.8897 8.93919 20.3192 8.31901 19.733L8.24074 19.6508L2.24074 12.6508C1.9465 12.3075 1.92198 11.8146 2.16718 11.4463L2.24074 11.3492L8.24074 4.3492C8.81977 3.67367 9.9047 4.03794 9.99411 4.88659L10 4.99999V7.99999L11.038 8.08649C17.1341 8.5945 21.8459 13.6166 21.9963 19.7L22 20L19.3412 18.4807C16.6386 16.9363 13.5976 16.0874 10.491 16.0064L10 16V19Z"
      fill="#353549"
    />
  </svg>
);

Reply.displayName = "ReplyIcon";

export default Reply;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ShowContactPathParams,
  ShowContactQueryResponse,
} from "../../models/ShowContact";

/**
 * @description Contact data
 * @summary contact
 * @link /contacts/:id
 */
export async function showContact(
  id: ShowContactPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ShowContactQueryResponse>["data"]> {
  const res = await client<ShowContactQueryResponse>({
    method: "get",
    url: `/contacts/${id}`,
    ...options,
  });
  return res.data;
}

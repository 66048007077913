import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class FinraComplaintsStore implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  data = {};
  page = 1;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      page: observable,
    });

    this.mainStore = mainStore;
  }

  async index({
    tableName,
    sortParams,
    tableFilters = [],
    page = 1,
  }: IndexParams) {
    if (!tableName) {
      window.console.warn(
        `FinraComplaints#index => tableName(${tableName}) should be present!`,
      );
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    this.setPage(page);

    const params = {
      table_title: tableName,
      page,
      table_filters: tableFilters,
    };

    if (sortParams) {
      // @ts-expect-error TS(2339) FIXME: Property 'sort_field_name' does not exist on type ... Remove this comment to see the full error message
      params.sort_field_name = sortParams.field_name;
      // @ts-expect-error TS(2339) FIXME: Property 'sort_direction' does not exist on type '... Remove this comment to see the full error message
      params.sort_direction = sortParams.direction;
    }

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/finra_complaints`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.mainStore.fields.setList(response.data.fields);

      this.setData(response.data);
    } catch (error) {
      window.console.log(`FinraComplaints#index error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'tableName' implicitly has an 'any' type... Remove this comment to see the full error message
  async create(tableName) {
    if (!tableName) {
      window.console.warn(
        `FinraComplaints#create => tableName(${tableName}) should be present!`,
      );
      return;
    }

    const data = { table_title: tableName };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/finra_complaints`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"FinraComplaints#create" error ${error}`);
      return null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/finra_complaints/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"FinraComplaints#delete" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async reopen(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/finra_complaints/${recordVersionID}/reopen`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"FinraComplaints#reopen" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async close(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/finra_complaints/${recordVersionID}/close`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"FinraComplaints#close" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async complete(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/finra_complaints/${recordVersionID}/complete`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"FinraComplaints#complete" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async submitToFINRA(recordVersionID, fullName, phone) {
    if (!recordVersionID || !fullName || !phone) {
      window.console.warn(
        `FinraComplaints#submitToFINRA => recordVersionID(${recordVersionID}) fullName(${fullName}) phone(${phone}) should be present!`,
      );
      return;
    }

    const userID = this.mainStore.users.user.id;

    const contactParams = {
      full_name: fullName,
      phone,
    };

    const complaintsSetResponse = await this.mainStore.finraContacts.set(
      userID,
      contactParams,
    );
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (complaintsSetResponse.status !== 200) {
      return;
    }

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/finra_complaints/${recordVersionID}/submit_to_finra`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"FirnaComplaints#submitToFINRA" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  async bulkSubmitToFINRA(params, fullName, phone) {
    if (!params.record_version_ids || !fullName || !phone) {
      window.console.warn(
        `FinraComplaints#bulkSubmitToFINRA => recordVersionIDs(${params.record_version_ids}) fullName(${fullName}) phone(${phone}) should be present!`,
      );
      return;
    }

    const userID = this.mainStore.users.user.id;

    const contactParams = {
      full_name: fullName,
      phone,
    };

    const complaintsSetResponse = await this.mainStore.finraContacts.set(
      userID,
      contactParams,
    );
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (complaintsSetResponse.status !== 200) {
      return;
    }

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/finra_complaints/bulk`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "There are validation errors with your submission. Please check the cell data to see what changes are needed.",
        );
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      this.mainStore.toast.setText("Complaints have been submitted");
      this.mainStore.dynamicTable.clearSelectedRecordVersionIDs();
    } catch (error) {
      window.console.log(`"FinraComplaints#bulkSubmitToFINRA" error ${error}`);
    }
  }

  async selectComplaintsReadyForSubmission() {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/finra_complaints/select_complaints_ready_for_submission`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }
      this.mainStore.dynamicTable.clearSelectedRecordVersionIDs();
      this.mainStore.dynamicTable.selectAllRecordVersions(
        response.data.record_version_ids,
      );
    } catch (error) {
      window.console.log(`FinraComplaints#index error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
  setPage(page) {
    this.page = page;
  }

  // Store Helpers

  cleanup() {
    this.setData({});
  }

  abort() {
    this.controller?.abort();
  }
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateAccountMutationRequest,
  CreateAccountMutationResponse,
  CreateAccountPathParams,
} from "../../models/CreateAccount";

/**
 * @description Create account
 * @summary Create account
 * @link /workspaces/:workspace_id/accounts
 */
export async function createAccount(
  workspaceId: CreateAccountPathParams["workspace_id"],
  data: CreateAccountMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateAccountMutationResponse>["data"]> {
  const res = await client<
    CreateAccountMutationResponse,
    CreateAccountMutationRequest
  >({
    method: "post",
    url: `/workspaces/${workspaceId}/accounts`,
    data,
    ...options,
  });
  return res.data;
}

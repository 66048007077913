import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "../../../contexts/Store";
import { Icon } from "../../Elements";

interface Props {
  recordVersionID?: number;
  showSlideMenu?: boolean;
  status?: string;
}

function AttestationStatus({ recordVersionID, status, showSlideMenu }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // funcs
  function handleFetch() {
    mainStore.attestations.fetchStatuses(recordVersionID);
  }

  // Hooks
  useEffect(() => {
    if (showSlideMenu && recordVersionID) {
      handleFetch();
    }
  }, [showSlideMenu]);

  // elements
  const renderCellContent = (
    <div className="status-cell">
      <span className={status?.toLowerCase()}>{status}</span>
      <Icon name="eye" color="generalDark" size="de" />
    </div>
  );

  return renderCellContent;
}

AttestationStatus.defaultProps = {
  status: "",
};

export default observer(AttestationStatus);

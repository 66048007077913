import classNames from "classnames";
import React from "react";

const DEFAULT_WIDTH = 272;

interface Props {
  children?: React.ReactNode;
  width?: number;
  className?: string;
}

// Provides standard styling for the content of a <Popup />
const ModalWrapper = ({ children, width, className }: Props) => (
  <div
    className={classNames("modal-wrapper", className)}
    style={{ width: `${width || DEFAULT_WIDTH}px` }}
  >
    {children}
  </div>
);

export default ModalWrapper;

import { Button } from "@themis/ui";
import React from "react";

interface EmptyStateProps {
  onAddGroup: () => void;
}

function EmptyState(props: EmptyStateProps) {
  return (
    <div className="tw-flex tw-w-full tw-max-w-[1092px] tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-rounded-md tw-bg-neutral-50 tw-py-[40px]">
      <p className="tw-text-center tw-font-sans tw-font-semibold tw-text-neutral-300">
        Want to create a new scoring group? Click “Add New Group” to get
        started.
      </p>
      <Button
        id="add-new-risk-register-scoring-group-btn"
        onClick={props.onAddGroup}
      >
        Add New Group
      </Button>
    </div>
  );
}

export default EmptyState;

import { observer } from "mobx-react";
import React, { useState } from "react";

import { ConfirmPopup } from "@/components/confirm-popup";
import { Flex, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import { Divider, IconMenu, IconMenuItem } from "@/components/IconMenu";
import { UsersCircle } from "@/components/users-circle";
import { useMainStore } from "@/contexts/Store";
import type { UserComment } from "@/stores/types/comment-types";

export function ReplyHeader(props: {
  comment: UserComment;
  onEditCommentClick(id: number): void;
  onConfirmDelete(id: number): void;
}) {
  const mainStore = useMainStore();
  const { taskDetail } = mainStore;

  const { isCurrentWorkspaceActive } = mainStore.workspaces;

  const [showDeleteCommentPopup, setShowDeleteCommentPopup] = useState(false);

  function handleConfirmDelete() {
    props.onConfirmDelete(props.comment.id);
  }

  function handleClickDelete() {
    setShowDeleteCommentPopup(true);
  }

  function handleClickEdit() {
    props.onEditCommentClick(props.comment.id);
  }

  function handleCopyLink() {
    mainStore.comments.copyCommentLink(props.comment);
  }

  const formattedCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(props.comment.created_at),
    { removeAt: true, monthFormat: "short" },
  );

  return (
    <Flex alignCenter justifySpaceBetween>
      <Flex alignCenter columnGap={8}>
        <UsersCircle
          initials={props.comment.author.initials}
          iconColorIndex={props.comment.author.icon_color_index}
          small
        />
        <Flex column rowGap={2}>
          <Typography
            label={props.comment.author.full_name}
            weight="semiBold"
            color="generalMidnightDark"
            size="sm"
          />
          <Typography
            label={formattedCreateDate}
            weight="semiBold"
            color="extrasBlueGrayDarker"
            size="xs"
          />
        </Flex>
      </Flex>
      {isCurrentWorkspaceActive && (
        <Flex alignCenter>
          <IconMenu
            triggerTestId={`comment-context-menu-${props.comment.id}`}
            icon="moreHorizontal"
          >
            {props.comment.user_can_update_content && (
              <IconMenuItem label="Edit" onClick={handleClickEdit} />
            )}
            <IconMenuItem label="Copy Link" onClick={handleCopyLink} />
            <IconMenuItem
              label="Create Task"
              onClick={() => taskDetail.openFromComment(props.comment)}
            />
            {props.comment.user_can_destroy && (
              <>
                <Divider />
                <IconMenuItem label="Delete" onClick={handleClickDelete} />
              </>
            )}
          </IconMenu>
          <ConfirmPopup
            showPopup={showDeleteCommentPopup}
            setShowPopup={setShowDeleteCommentPopup}
            title="Deleting Comment"
            content="Are you sure you wish to delete this comment? Actions are non-reversible"
            onConfirmClick={handleConfirmDelete}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default observer(ReplyHeader);

export const stylesForUserAddedOptions = [
  {
    borderRadius: "4px",
    border: "1px solid #5C5FC8",
    color: "#5C5FC8",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #97DAEE",
    color: "#2A96C4",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #FDD08C",
    color: "#FF9F4D",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #FF9A8B",
    color: "#F95B46",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #F8899C",
    color: "#E63956",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #FFBECA",
    color: "#FF87A7",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #D685D3",
    color: "#791778",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #BEDFFF",
    color: "#39B6EB",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #C5D2FF",
    color: "#6D8CF9",
    background: "white",
  },
  {
    borderRadius: "4px",
    border: "1px solid #C6C6FF",
    color: "#6D71F9",
    background: "white",
  },
];

import { Button, useToast } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

import type { RecordType } from "@/api";
import { useCloneRecord } from "@/api/queries/clones";
import { useWorkspacesModulesList } from "@/api/queries/moduleWorkspaces";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import closeIcon from "@/images/table-image/icon/close-black-icon.svg";

interface Props {
  recordType: RecordType;
  modelDisplayName?: string;
  recordID: number;
  closeSlideMenu: (...args: unknown[]) => void;
}

function SendThemisRecord({
  recordID,
  recordType,
  modelDisplayName,
  closeSlideMenu,
}: Props) {
  const mainStore = useMainStore();
  const toast = useToast();

  const [selectedWorkspaceIDs, setSelectedWorkspaceIDs] = useState<number[]>(
    [],
  );

  const { mutateAsync: cloneRecord } = useCloneRecord({
    recordId: recordID,
    recordType,
  });

  const workspacesModulesList = useWorkspacesModulesList([]);
  const isPendingWorkspacesModulesList = workspacesModulesList.some(
    (workspace) => workspace.isPending,
  );

  async function onSend() {
    selectedWorkspaceIDs.forEach(async (workspaceId) => {
      try {
        await cloneRecord({ target_workspace_id: workspaceId });
        toast({
          content: "Record sent successfully!",
          variant: "success",
        });
      } catch {
        toast({
          content: "Could not send record.",
          variant: "error",
        });
      }
    });
    closeSlideMenu();
  }

  function handleClickWorkspace(id: number) {
    if (selectedWorkspaceIDs.includes(id)) {
      setSelectedWorkspaceIDs(
        selectedWorkspaceIDs.filter((workspaceId) => workspaceId !== id),
      );
      return;
    }
    setSelectedWorkspaceIDs([...selectedWorkspaceIDs, id]);
  }

  const buttonDisabled = !selectedWorkspaceIDs.length;

  const renderWorkspaces = mainStore.workspaces.collaborativeWorkspaces.map(
    (collaborativeWorkspace) => {
      const { logo, isInternal } = getWorkspaceLogo(collaborativeWorkspace);
      return (
        <li
          data-testid={`send-workspace-block-${collaborativeWorkspace.id}`}
          aria-labelledby={`workspace-${collaborativeWorkspace.id}`}
          key={collaborativeWorkspace.id}
          className={classNames("tw-cursor-pointer", {
            active: selectedWorkspaceIDs.includes(collaborativeWorkspace.id),
          })}
          onClick={() => {
            handleClickWorkspace(collaborativeWorkspace.id);
          }}
        >
          <img src={logo} alt="image" />
          <div className="creative-block">
            <h3
              role="presentation"
              id={`workspace-${collaborativeWorkspace.id}`}
            >
              {isInternal
                ? collaborativeWorkspace.company.name
                : collaborativeWorkspace.name}
            </h3>
            <span>{collaborativeWorkspace.members_count} members</span>
          </div>
        </li>
      );
    },
  );

  return (
    <div className="table-dropdown table-email-dropdown">
      <div className="email-dropdown-head">
        <h3>Send {modelDisplayName}</h3>
        <div onClick={closeSlideMenu}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      <hr />
      {isPendingWorkspacesModulesList ? (
        <Loading loadingLayout="table-no-add-new" />
      ) : (
        <div className="email-dropdown-wrap">
          <div className="creative-wrap">
            <h4>
              {renderWorkspaces.length ? (
                <>
                  Select a Collaborative Workspace below to send{" "}
                  <blockquote>{modelDisplayName}</blockquote>
                </>
              ) : (
                "No Collaborative Workspace available to send to"
              )}
            </h4>
            <ul>{renderWorkspaces}</ul>
          </div>
          <Button
            color="primary"
            onClick={onSend}
            disabled={buttonDisabled}
            data-testid="send-button"
          >
            Send
          </Button>
        </div>
      )}
      <hr />
    </div>
  );
}

export default observer(SendThemisRecord);

import { capitalize, isEqual, uniq } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import useDidMountEffect from "@/hooks/useDidMount";
import { useLoading } from "@/hooks/useLoading";
import useMetricsData from "@/hooks/useMetricsData";
import usePrevious from "@/hooks/usePrevious";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import RecordsByMonthHorizontalBar from "../../../sharedGraphs/records-by-month-horizontal-bar";
import RecordsByMonthStackedBar from "../../../sharedGraphs/records-by-month-stacked-bar";
import { INTERVAL_OPTIONS, TIME_RANGE_OPTIONS } from "../constants";
import { mapHeader, moveNAPositionToLast, sortData } from "../helpers";
import type { MetricsPageProps } from "../types";
import { Dashboards } from "../types";
import DashboardOptions from "./components/DashboardOptions";
import ExportDashboard from "./components/ExportDashboard";
import NumberOfCountsTable from "./components/NumberOfCountsTable";

const CreatedDateDashboard = ({
  name,
  defaultDateField,
  identifier,
}: MetricsPageProps) => {
  const mainStore = useMainStore();
  const activeWorkspaceId = mainStore.context.activeWorkspace?.id;

  const [data, setData] = useState({});
  const [dataLabels, setDataLabels] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);
  const apiData = toJS(mainStore.reports.recordsForMetrics);

  const {
    timeRange,
    handleChangeTimeRange,
    dateField,
    startDate,
    endDate,
    customCalendarOptions,
    onCalendarSelect,
    updateCalendar,
    interval,
    handleChangeInterval,
    intervalDisplayName,
  } = useMetricsData({ defaultDateField });

  const loading = useLoading(dataLabels);

  useEffect(() => {
    return () => {
      mainStore.reports.setRecordsForMetrics([]);
    };
  }, []);

  const { getTableFiltersParam } = useUpdateFilter();
  const prevFilter = usePrevious(getTableFiltersParam());
  useEffect(() => {
    if (isEqual(prevFilter, getTableFiltersParam())) {
      return;
    }
    getRecords();
  }, [getTableFiltersParam()]);

  useDidMountEffect(() => {
    getRecords();
  }, [
    activeWorkspaceId,
    dateField,
    startDate,
    interval,
    mainStore.reports.imSourceGroupName,
  ]);

  useEffect(() => {
    if (
      mainStore.fields.list.length === 0 &&
      mainStore.context.activeWorkspace
    ) {
      mainStore.issueManagement.index({
        workspaceID: activeWorkspaceId,
        tab: "All",
      });
    }
  }, [activeWorkspaceId, mainStore.fields.list]);

  useEffect(() => {
    if (activeWorkspaceId) {
      mainStore.reports.setSelectedWorkspaceIDs([activeWorkspaceId]);
    }
  }, [activeWorkspaceId]);

  const prevData = usePrevious(apiData);

  useEffect(() => {
    if (isEqual(prevData, apiData)) {
      return;
    }

    const labels = uniq(Object.keys(apiData));
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
    setDataLabels(labels);

    const firstStatusData = apiData?.[labels?.[0]]?.data;
    if (firstStatusData) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      setXAxisLabels(uniq(Object.keys(firstStatusData)));
    }
  }, [apiData]);

  const getDataForLabel = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'dataLabel' implicitly has an 'any' type... Remove this comment to see the full error message
    (dataLabel) => {
      return apiData[dataLabel]?.data;
    },
    [apiData, mainStore.reports.recordsForMetrics],
  );

  useEffect(() => {
    const updatedData = {};

    dataLabels.forEach((label) => {
      // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
      updatedData[label] = getDataForLabel(label);
    });

    setData(updatedData);
  }, [
    dataLabels,
    mainStore.reports.recordsForMetrics,
    mainStore.reports.imSourceGroupName,
  ]);

  async function getRecords() {
    if (!activeWorkspaceId) {
      return;
    }

    await mainStore.reports.getRecordsForCreatedDateDashboard({
      identifier,
      from_value: startDate,
      to_value: endDate,
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      table_name: mainStore.context.tableName,
      interval,
      filter_params: getTableFiltersParam(),
    });
  }

  const totalData = useMemo(() => {
    // @ts-expect-error TS(7034) FIXME: Variable 'result' implicitly has type 'any[]' in s... Remove this comment to see the full error message
    const result = [];
    const objValues = Object.values(apiData);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objValues.forEach((value: any) => {
      result.push(value.total);
    });

    // @ts-expect-error TS(7005) FIXME: Variable 'result' implicitly has an 'any[]' type.
    return result;
  }, [apiData, mainStore.reports.imSourceGroupName]);

  const sortedData = sortData(dataLabels, totalData);
  const formattedData = moveNAPositionToLast({
    labels: sortedData.labels,
    data: sortedData.data,
  });

  const chartData = useMemo(
    () => ({
      // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
      labels: formattedData.labels.map((label) =>
        mapHeader(
          mainStore.fieldOptions.all,
          mainStore.reports.imSourceGroupName,
          label,
        ),
      ),
      datasets: [
        {
          data: formattedData.data,
          stack: "source",
          maxBarThickness: 150,
          borderSkipped: false,
        },
      ],
    }),
    [dataLabels, formattedData, interval, mainStore.reports.imSourceGroupName],
  );

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    barThickness: 10,
    borderRadius: 0,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    stack: "source",
    elements: {
      bar: {
        barThickness: 500,
        backgroundColor: "#2F76B6",
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        min: 0,
        ticks: {
          stepSize: 1,
        },
        grid: {
          borderDash: [6],
        },
      },
    },
    indexAxis: "y",
  };

  const getTableData = useCallback(() => {
    const tableData = new Map();

    xAxisLabels.forEach((month) => {
      dataLabels.forEach((label) => {
        const count = apiData[label]?.data[month];

        const currentValue = tableData.get(month);

        if (Number(count) >= 0) {
          tableData.set(month, {
            [label]: count,
            ...currentValue,
          });
        }
      });
    });

    return tableData;
  }, [
    apiData,
    xAxisLabels,
    dataLabels,
    interval,
    mainStore.reports.imSourceGroupName,
  ]);

  const getTotals = useCallback(() => {
    // @ts-expect-error TS(7034) FIXME: Variable 'result' implicitly has type 'any[]' in s... Remove this comment to see the full error message
    const result = [];
    dataLabels.forEach((label) => {
      const total = apiData[label]?.total;

      result.push({
        total,
        label: label || "n/a",
      });
    });

    // @ts-expect-error TS(7005) FIXME: Variable 'result' implicitly has an 'any[]' type.
    return result;
  }, [
    dataLabels,
    interval,
    mainStore.reports.recordsForMetrics,
    mainStore.reports.imSourceGroupName,
  ]);

  const currentGroupDisplayName = mainStore.fields.list.find(
    (field) => field.name === mainStore.reports.imSourceGroupName,
  )?.display_name;
  const totalByChartTitle = `Total Number of ${name} by ${currentGroupDisplayName}`;
  const perIntervalChartTitle = `Number of ${name} by ${currentGroupDisplayName} per ${capitalize(
    interval,
  )}`;

  if (loading) {
    return <Loading loadingLayout="sections" showTableHeader={false} />;
  }

  return (
    <div className="metrics-container" data-testid="source-dashboard">
      <div className="metrics-header">{`${name} by Created Date`}</div>
      <DashboardOptions
        timeRangeValue={timeRange}
        timeRangeOptions={[
          ...Object.keys(TIME_RANGE_OPTIONS),
          ...Object.keys(customCalendarOptions),
        ]}
        handleChangeTimeRange={handleChangeTimeRange}
        intervalDisplayName={intervalDisplayName}
        intervalOptions={Object.keys(INTERVAL_OPTIONS)}
        handleChangeIntervalDisplayName={handleChangeInterval}
        onCalendarSelect={onCalendarSelect}
        onDateChange={updateCalendar}
        dashboard={Dashboards.CREATED_DATE}
      />
      <div className="chart-section">
        <RecordsByMonthHorizontalBar
          chartData={chartData}
          chartOptions={chartOptions}
          index={0}
          chartTitle={totalByChartTitle}
        />
      </div>
      <div className="chart-section stacked-bar">
        <RecordsByMonthStackedBar
          shouldSortData
          showYAxisLine={false}
          identifier={identifier}
          dateColumnDefault={dateField}
          groupColumnDefault={mainStore.reports.imSourceGroupName}
          // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
          tableName={mainStore.context.tableName}
          showSelectFields={false}
          showDownload={false}
          showDatePicker={false}
          maintainAspectRatio={false}
          labelPosition="top"
          startDate={startDate}
          endDate={endDate}
          dashboard={Dashboards.CREATED_DATE}
          labels={dataLabels}
          xAxisLabels={xAxisLabels}
          chartData={data}
          chartTitle={`${perIntervalChartTitle}`}
        />
      </div>

      <div className="chart-section counts-table">
        <div className="title-container">
          <div className="graph-title">{perIntervalChartTitle}</div>
          <ExportDashboard
            fileDownloadName={perIntervalChartTitle}
            chartId="number-of-issues-table"
            forTables
          />
        </div>
        <div className="table-container">
          <NumberOfCountsTable
            columnHeaders={dataLabels}
            data={getTableData()}
            name={name}
            timeRange={timeRange}
            totalData={getTotals()}
            dashboard={Dashboards.CREATED_DATE}
            intervalDisplayName={intervalDisplayName}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(CreatedDateDashboard);

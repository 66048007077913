import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const PriorityMedium = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 21V3H21V21H19ZM17 2C17 1.44772 17.4477 1 18 1H22C22.5523 1 23 1.44772 23 2V22C23 22.5523 22.5523 23 22 23H18C17.4477 23 17 22.5523 17 22V2ZM9 10C9 9.44772 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10V22C15 22.5523 14.5523 23 14 23H10C9.44772 23 9 22.5523 9 22V10ZM1 14C1 13.4477 1.44772 13 2 13H6C6.55228 13 7 13.4477 7 14V22C7 22.5523 6.55228 23 6 23H2C1.44772 23 1 22.5523 1 22V14Z"
      fill="#353549"
    />
  </svg>
);

PriorityMedium.displayName = "PriorityMediumIcon";

export default PriorityMedium;

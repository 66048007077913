import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Help = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 8C10.8954 8 10 8.89543 10 10C10 10.5523 9.55228 11 9 11C8.44772 11 8 10.5523 8 10C8 7.79086 9.79086 6 12 6C14.2317 6 16.0061 7.60488 16.0061 9.7981C16.0061 10.9964 15.5855 11.7302 14.6873 12.5033C14.595 12.5826 13.5555 13.4266 13.5167 13.4576C13.1002 13.7907 13.013 13.9413 13.013 14.4391C13.013 14.9914 12.5653 15.4391 12.013 15.4391C11.4607 15.4391 11.013 14.9914 11.013 14.4391C11.013 13.269 11.4123 12.5796 12.2677 11.8956C12.2342 11.9224 13.3076 11.0519 13.3826 10.9874C13.8771 10.5618 14.0061 10.3368 14.0061 9.7981C14.0061 8.75673 13.1695 8 12 8ZM12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18Z"
      fill="#353549"
    />
  </svg>
);

Help.displayName = "HelpIcon";

export default Help;

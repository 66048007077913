import "./import-questionnaire-templates.scss";

import React, { useRef, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import Popup from "reactjs-popup";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";
import { Button, Typography } from "@/components/Elements";
import ModalWrapper from "@/components/shared/ModalWrapper";
import { useMainStore } from "@/contexts/Store";

const ImportQuestionnaireTemplates = () => {
  const mainStore = useMainStore();
  const risk_methodologies = mainStore.riskMethodologies.list || [];
  const fileInputRef = useRef<HTMLInputElement>();
  const [step, setStep] = useState(0);
  const [riskMethodologyID, setRiskMethodologyID] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [file, setFile] = useState(null);

  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
    setStep(0);
  }

  const processImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    setFile(event.target.files[0]);
    setStep(1);
  };

  const onRiskMethodologySelection = async (riskMethodologyIDParam: number) => {
    setRiskMethodologyID(riskMethodologyIDParam);
    setStep(2);
  };

  const onCreation = async (isPublic: boolean) => {
    onPopupClose();
    if (!file) {
      return;
    }
    await mainStore.riskAssessmentQuestionnaires.importQuestionnaireTemplate(
      file,
      riskMethodologyID,
      isPublic,
    );
  };

  return (
    <Popup
      on="click"
      position="bottom right"
      open={showPopup}
      onOpen={() => onPopupOpen()}
      onClose={() => onPopupClose()}
      trigger={
        <div>
          <Button label="Import" theme="tertiary" />
        </div>
      }
    >
      {step === 0 && (
        <ModalWrapper width={400}>
          <div className="import-questionnaire-templates__step">
            <div className="import-questionnaire-templates__number">1</div>
            <div>
              <Typography
                weight="semiBold"
                size="de"
                label="Download XLSX file"
                color="generalMidnightDark"
                className="import-questionnaire-templates__step-title"
              />
              <Button
                icon="download"
                label="Download"
                onClick={() =>
                  QuestionnaireAPI.downloadTemplate(
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
                    mainStore.context.workspaceID,
                  )
                }
              />
            </div>
          </div>
          <div className="import-questionnaire-templates__step">
            <div className="import-questionnaire-templates__number">2</div>
            <div>
              <Typography
                weight="semiBold"
                size="de"
                label="Add / edit information in XLSX template"
                color="generalMidnightDark"
                className="import-questionnaire-templates__step-title"
              />
              <Typography
                size="de"
                label="Please follow the instructions on the Getting Started tab in the downloadable template."
              />
            </div>
          </div>
          <div className="import-questionnaire-templates__step">
            <div className="import-questionnaire-templates__number">3</div>
            <div>
              <Typography
                weight="semiBold"
                size="de"
                label="Import the updated file"
                color="generalMidnightDark"
                className="import-questionnaire-templates__step-title"
              />
              <DirectUploadProvider
                // @ts-expect-error TS(2769) FIXME: No overload matches this call.
                onSuccess={null}
                render={() => (
                  <div>
                    <Button
                      icon="upload"
                      label="Import XLSX"
                      onClick={() => fileInputRef.current?.click()}
                    />
                    <input
                      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLInputElement | undefine... Remove this comment to see the full error message
                      ref={fileInputRef}
                      type="file"
                      onChange={processImport}
                      className="import-questionnaire-templates__input"
                    />
                  </div>
                )}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
      {step === 1 && (
        <ModalWrapper
          width={400}
          className="import-questionnaire-templates__methodology-select-dropdown"
        >
          <div>
            <h4>
              Select 1 Risk Methodology below before importing your template
            </h4>
            <ul>
              {risk_methodologies.map((risk_methodology) => (
                <li
                  key={risk_methodology.id}
                  onClick={() =>
                    onRiskMethodologySelection(Number(risk_methodology.id))
                  }
                >
                  {risk_methodology.name}
                </li>
              ))}
            </ul>
          </div>
        </ModalWrapper>
      )}
      {step === 2 && (
        <ModalWrapper
          width={400}
          className="import-questionnaire-templates__methodology-select-dropdown"
        >
          <div>
            <h4>
              Select the visibility of questionnaire. You cannot change this
              after creation.
            </h4>
            <ul>
              <li onClick={() => onCreation(false)}>Sharing externally</li>
              <li onClick={() => onCreation(true)}>Completing internally</li>
            </ul>
          </div>
        </ModalWrapper>
      )}
    </Popup>
  );
};

export default ImportQuestionnaireTemplates;

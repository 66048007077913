import { IconButton, useToast } from "@themis/ui";
import React from "react";
import { PiThumbsDownBold, PiThumbsUpBold } from "react-icons/pi";
import { useParams } from "react-router-dom";

import { useMonitoringGroupResultUpdate } from "@/api/queries/websiteMonitoring";

import CustomTooltip from "./CustomTooltip";

export function ThumbsStatus({
  monitoringGroupResultId,
  disable,
}: {
  monitoringGroupResultId: number;
  disable: boolean;
}) {
  const { monitoringGroupAssetChildId } = useParams<{
    monitoringGroupAssetChildId: string;
  }>();

  const toast = useToast();

  const { mutateAsync: updateResult } = useMonitoringGroupResultUpdate({
    monitoringGroupAssetChildId: Number(monitoringGroupAssetChildId),
    monitoringGroupResultId,
    onSuccess: () => {
      toast({ content: "Result saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update result.",
        variant: "error",
      });
    },
  });

  async function handleSubmitForm(status: string) {
    const requestBody = {
      data: {
        status: status || null,
      },
    };

    await updateResult(requestBody);
  }

  return (
    <div className="tw-flex">
      <div className="tw-px-2 tw-pt-2">
        <CustomTooltip value="Mark as Remediate">
          <IconButton
            disabled={disable}
            onClick={() => handleSubmitForm("Remediated")}
            Icon={PiThumbsUpBold}
            size="lg"
            color="transparent"
          />
        </CustomTooltip>
      </div>
      <div className="tw-px-2 tw-pt-2">
        <CustomTooltip value="Mark as False Positive">
          <IconButton
            disabled={disable}
            onClick={() => handleSubmitForm("False Positive")}
            Icon={PiThumbsDownBold}
            size="lg"
            color="transparent"
          />
        </CustomTooltip>
      </div>
    </div>
  );
}

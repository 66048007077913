import "./score-cell.scss";

import React from "react";

import { Typography } from "@/components/Elements";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";

import type { ResidualRisk, RiskRating } from "../../../types/risk-methodology";
import MiniTag from "../../MiniTag/MiniTag";

interface ScoreCellProps {
  score?: number;
  rating?: RiskRating | ResidualRisk;
  isFirstDataCell?: boolean;
  isLastDataCell?: boolean;
}

export const ScoreCell = (props: ScoreCellProps) => (
  <ContentCell
    isLastDataCell={props.isLastDataCell}
    isFirstDataCell={props.isFirstDataCell}
    content={
      <>
        {!props.rating && (
          <Typography
            weight="semiBold"
            label="N/A"
            color="extrasBlueGrayDarker"
          />
        )}
        {props.rating && (
          <>
            <MiniTag rating={props.rating} label={props.rating.text} />
            <Typography
              weight="semiBold"
              label={props.score}
              color="extrasBlueGrayDarker"
            />
          </>
        )}
      </>
    }
  />
);

import { without } from "lodash";

function getFollowingRecordVersion(
  recordVersions: Array<{
    record_id: number;
  }>,
  sourceIndex: number,
  destinationIndex: number,
) {
  if (destinationIndex === recordVersions.length - 1) {
    return null;
  }

  if (destinationIndex < sourceIndex) {
    return recordVersions[destinationIndex].record_id;
  }

  return recordVersions[destinationIndex + 1].record_id;
}

function getPrecedingRecordVersion(
  recordVersions: Array<{
    record_id: number;
  }>,
  sourceIndex: number,
  destinationIndex: number,
) {
  if (destinationIndex === 0) {
    return null;
  }

  if (destinationIndex < sourceIndex) {
    return recordVersions[destinationIndex - 1].record_id;
  }

  return recordVersions[destinationIndex].record_id;
}

export function getRepositionPayloadFromIndices(
  recordVersions: Array<{
    record_id: number;
  }>,
  sourceIndex: number,
  destinationIndex: number,
) {
  return {
    preceding_record_id: getPrecedingRecordVersion(
      recordVersions,
      sourceIndex,
      destinationIndex,
    ),
    following_record_id: getFollowingRecordVersion(
      recordVersions,
      sourceIndex,
      destinationIndex,
    ),
  };
}

export function moveElement<T>(arr: Array<T>, from: number, to: number) {
  if (from === to) {
    return arr;
  }
  const element = arr[from];
  const rearrangedArr = without(arr, element);
  rearrangedArr.splice(to, 0, element);

  return rearrangedArr;
}

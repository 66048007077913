import React from "react";

import { Icon, Typography } from "../Elements";

const REASONS = [
  "You are not in the correct workspace",
  "You do not have permission to view this record",
  "The record does not exist",
];

const RecordNotFound = () => (
  <div className="record-not-found">
    <Icon size="lg" color="accentsLavender" name="warning" />
    <Typography
      color="generalDark"
      weight="bold"
      label="Record detail is not accessible"
    />
    <div>
      <Typography
        size="sm"
        color="generalDarkGray"
        label="There are a few reasons why this could be the case:"
        weight="semiBold"
      />
      <ul className="record-not-found__list">
        {REASONS.map((reason, index) => (
          <li key={index}>
            <Typography
              size="sm"
              color="generalDarkGray"
              label={reason}
              weight="semiBold"
            />
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default RecordNotFound;

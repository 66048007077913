import { isEqual, uniq } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";

import RecordsByMonthLineChart from "@/components/reports/sharedGraphs/records-by-month-line-chart";
import { useMainStore } from "@/contexts/Store";
import useDidMountEffect from "@/hooks/useDidMount";
import useMetricsData from "@/hooks/useMetricsData";
import usePrevious from "@/hooks/usePrevious";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import {
  INTERVAL_OPTIONS,
  tableHeader,
  TIME_RANGE_OPTIONS,
} from "../constants";
import { createTileLink } from "../helpers";
import type { MetricsPageProps } from "../types";
import { Dashboards } from "../types";
import DashboardOptions from "./components/DashboardOptions";
import ExportDashboard from "./components/ExportDashboard";
import NumberOfCountsTable from "./components/NumberOfCountsTable";
import TotalCountTiles from "./components/TotalCountTiles";

const TimeToCloseDashboard = ({
  name,
  identifier,
  defaultDateField,
}: MetricsPageProps) => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const apiData = toJS(mainStore.reports.recordsForMetrics);

  const [data, setData] = useState({});
  const [dataLabels, setDataLabels] = useState([]);
  const [xAxisLabels, setXAxisLabels] = useState([]);

  const {
    timeRange,
    handleChangeTimeRange,
    dateField,
    startDate,
    endDate,
    customCalendarOptions,
    onCalendarSelect,
    updateCalendar,
    interval,
    handleChangeInterval,
    intervalDisplayName,
  } = useMetricsData({ defaultDateField });

  useEffect(() => {
    return () => {
      mainStore.reports.setRecordsForMetrics([]);
    };
  }, []);

  const { getTableFiltersParam } = useUpdateFilter();
  const prevFilter = usePrevious(getTableFiltersParam());
  useEffect(() => {
    if (isEqual(prevFilter, getTableFiltersParam())) {
      return;
    }
    getRecords();
  }, [getTableFiltersParam()]);

  useDidMountEffect(() => {
    getRecords();
  }, [workspaceID, dateField, startDate, interval]);

  const prevData = usePrevious(apiData);

  useEffect(() => {
    if (isEqual(prevData, apiData)) {
      return;
    }

    const labels = uniq(Object.keys(apiData));
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
    setDataLabels(labels);

    const firstItemData = apiData?.[labels?.[0]]?.data;
    if (firstItemData) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      setXAxisLabels(uniq(Object.keys(firstItemData)));
    }
  }, [apiData, location.pathname]);

  useEffect(() => {
    const firstItemData = apiData?.[dataLabels?.[0]]?.data;
    if (firstItemData) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      setXAxisLabels(uniq(Object.keys(firstItemData)));
    }
  }, [mainStore.reports.recordsForMetrics, dataLabels]);

  async function getRecords() {
    if (!workspaceID) {
      return;
    }

    await mainStore.reports.getRecordsForTimeToCloseDashboard({
      identifier,
      from_value: startDate,
      to_value: endDate,
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      table_name: mainStore.context.tableName,
      interval,
      filter_params: getTableFiltersParam(),
    });
  }

  const getTableData = useCallback(() => {
    const tableData = new Map();

    xAxisLabels.forEach((month) => {
      dataLabels.forEach((label) => {
        const count = apiData[label]?.data[month];

        const currentValue = tableData.get(month);

        tableData.set(month, {
          [label]: count,
          ...currentValue,
        });
      });
    });

    return tableData;
  }, [apiData, xAxisLabels, dataLabels]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
  const getTileLink = (label) =>
    createTileLink({
      workspaceID,
      label,
      startDate,
      endDate,
      identifier,
      fields: mainStore.fields.allFields,
      // @ts-expect-error TS(2322) FIXME: Type 'Dashboards.TIME_TO_CLOSE' is not assignable ... Remove this comment to see the full error message
      dashboard: Dashboards.TIME_TO_CLOSE,
      departments: [],
    });

  const getTotalTilesData = useCallback(() => {
    // @ts-expect-error TS(7034) FIXME: Variable 'result' implicitly has type 'any[]' in s... Remove this comment to see the full error message
    const result = [];
    dataLabels.forEach((label) => {
      const total = apiData[label]?.total;

      result.push({
        total,
        label: tableHeader[label] || "n/a",
        link: getTileLink(label),
      });
    });

    // @ts-expect-error TS(7005) FIXME: Variable 'result' implicitly has an 'any[]' type.
    return result;
  }, [dataLabels, mainStore.reports.recordsForMetrics, apiData]);

  const getDataForLabel = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'dataLabel' implicitly has an 'any' type... Remove this comment to see the full error message
    (dataLabel) => {
      return apiData[dataLabel]?.data;
    },
    [apiData, mainStore.reports.recordsForMetrics],
  );

  useEffect(() => {
    const updatedData = {};

    dataLabels.forEach((label) => {
      // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
      updatedData[label] = getDataForLabel(label);
    });

    setData(updatedData);
  }, [dataLabels, mainStore.reports.recordsForMetrics]);

  const chartTitle = "Number of Issues Closed vs Average Days Open";

  return (
    <div className="metrics-container" data-testid="time-to-close-dashboard">
      <div className="metrics-header">Time to Close</div>
      <DashboardOptions
        timeRangeValue={timeRange}
        timeRangeLabel="Closed Date"
        timeRangeOptions={[
          ...Object.keys(TIME_RANGE_OPTIONS),
          ...Object.keys(customCalendarOptions),
        ]}
        handleChangeTimeRange={handleChangeTimeRange}
        intervalDisplayName={intervalDisplayName}
        intervalOptions={Object.keys(INTERVAL_OPTIONS)}
        handleChangeIntervalDisplayName={handleChangeInterval}
        onCalendarSelect={onCalendarSelect}
        onDateChange={updateCalendar}
        dashboard={Dashboards.TIME_TO_CLOSE}
      />
      <TotalCountTiles useLabelOnly data={getTotalTilesData()} />
      <div className="chart-section">
        <div className="title-container">
          <div className="graph-title">{chartTitle}</div>
          <div>
            {" "}
            <ExportDashboard
              chartId={chartTitle}
              fileDownloadName={chartTitle}
              forTables={false}
            />
          </div>
        </div>
        <RecordsByMonthLineChart
          chartID={chartTitle}
          chartDataLabels={dataLabels}
          chartData={data}
          xAxisLabels={xAxisLabels}
          labelColors={["#2F76B6", "#5CB9E9"]}
          yAxesLabels={["Number of Issues Closed", "Average Days Open"]}
        />
      </div>
      <div className="chart-section">
        <div className="title-container">
          <div className="graph-title" />
          <ExportDashboard
            fileDownloadName={chartTitle}
            chartId="number-of-issues-table"
            forTables
          />
        </div>
        <NumberOfCountsTable
          columnHeaders={dataLabels}
          data={getTableData()}
          name={name}
          timeRange={timeRange}
          totalData={getTotalTilesData()}
          dashboard={Dashboards.TIME_TO_CLOSE}
          intervalDisplayName={intervalDisplayName}
        />
      </div>
    </div>
  );
};

export default observer(TimeToCloseDashboard);

import React, { useState } from "react";
import Popup from "reactjs-popup";

import { Flex, Icon } from "@/components/Elements";
import AttachmentOverlay from "@/components/shared/attachment-overlay/AttachmentOverlay";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import ListCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ListCell/ListCell";

import type { DocumentAnswerRead } from "../../../types/questionnaire-form";
import type { AnswerReviewProps } from "./AnswerReview";
import AnswerReview from "./AnswerReview";

export default function AttachmentAnswerReview(
  props: Omit<AnswerReviewProps, "AnswerCell" | "RiskRatingCell">,
) {
  const [viewedAttachmentUrl, setViewedAttachmentUrl] = useState<string | null>(
    null,
  );
  const attachmentAnswer = props.question.answer as DocumentAnswerRead;

  if (viewedAttachmentUrl) {
    return (
      <Popup modal open onClose={() => setViewedAttachmentUrl(null)}>
        <AttachmentOverlay
          fileUrl={viewedAttachmentUrl}
          onClose={() => setViewedAttachmentUrl(null)}
        />
      </Popup>
    );
  }
  return (
    <AnswerReview
      {...props}
      AnswerCell={
        attachmentAnswer?.document_files &&
        attachmentAnswer.document_files.length > 0 ? (
          <ListCell>
            {attachmentAnswer.document_files.map((document) => (
              <Flex
                columnGap={5}
                style={{ cursor: "pointer" }}
                key={document.id}
                onClick={() => setViewedAttachmentUrl(document.file_url)}
              >
                <Icon name="attachment" />
                {document.file_name}
              </Flex>
            ))}
          </ListCell>
        ) : (
          <ContentCell
            content={
              <div
                data-tooltip-id="tooltip"
                data-tooltip-content="This question hasn't been answered yet"
                data-tooltip-place="bottom"
              >
                N/A
              </div>
            }
          />
        )
      }
      RiskRatingCell={
        <ContentCell
          content={
            <div
              data-tooltip-id="tooltip"
              data-tooltip-content="Attachment questions require an override score"
              data-tooltip-place="bottom"
            >
              N/A
            </div>
          }
        />
      }
    />
  );
}

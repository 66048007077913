import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import Spinner from "../../table/shared/Spinner";

function BuildVendorSaveButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isSaving, setIsSaving] = useState(false);

  // Hooks
  const history = useHistory();
  const { search } = useLocation();

  // Variables
  const { active_workspace: workspace } = mainStore.users.user;
  const { currentVendor } = mainStore.vendors;
  const { workspaceID } = mainStore.context;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName(
    "vendor_due_diligence",
    moduleWorkspaces,
    true,
  );
  const searchParams = new URLSearchParams(search);
  const grouping = searchParams.get("grouping");

  // Functions
  async function createVendor() {
    setIsSaving(true);

    const data = {
      ...currentVendor,
      ...(grouping && { grouping: { options: [grouping] } }),
    };
    // @ts-expect-error TS(2339) FIXME: Property 'other_documents' does not exist on type ... Remove this comment to see the full error message
    data.other_documents = currentVendor.other_documents?.map(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) => item.id,
    );
    const files = [];
    for (const key of Object.keys(data) as Array<keyof typeof data>) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (data[key]?.customFile === true) {
        files.push(data[key]);
        delete data[key];
      }
    }

    const { errors } = await mainStore.vendors.create(
      workspace?.id,
      data,
      true,
    );

    if (errors) {
      mainStore.toast.setErrorText(errors.base[0]);
      setIsSaving(false);
    } else {
      mainStore.toast.setInfoText(`${recordName} saved!`);
      mainStore.vendors.setCurrentVendor({});

      history.push(
        `/workspaces/${workspaceID}/modules/vendor-due-diligence/active`,
      );
    }
  }

  return (
    <button
      className="table-button header-action"
      disabled={isSaving}
      onClick={createVendor}
    >
      Save {recordName}
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(BuildVendorSaveButton);

import classNames from "classnames";
import { observer } from "mobx-react";
import type { ReactNode } from "react";
import React from "react";

import { Icon } from "@/components/Elements";
import type { DropdownOption } from "@/components/Elements/Dropdown/Dropdown";

interface Props {
  disabled?: boolean;
  selected?: DropdownOption;
  helperText?: string;
  placeholder?: ReactNode;
  hasTableView?: boolean;
}

function DropdownTrigger({
  disabled,
  selected,
  helperText,
  hasTableView,
  placeholder,
}: Props) {
  return (
    <>
      <div
        className={classNames("dropdown-trigger", {
          placeholder: disabled || !selected,
          "has-selection": !disabled && selected,
        })}
        data-testid="dropdown-trigger"
      >
        {selected && !selected.element && (
          <>
            {selected.icon && !hasTableView && (
              <img src={selected.icon} alt="icon" />
            )}{" "}
            {selected.name}
          </>
        )}
        {(selected && selected.element) ?? null}
        {!selected && placeholder}
      </div>

      {helperText && !hasTableView && (
        <span className="helper-text-on-dropdown">{helperText}</span>
      )}
      <Icon
        name="arrowDownBlack"
        className="terms-dropdown-field__arrow-down"
      />
    </>
  );
}

export default observer(DropdownTrigger);

import { useCallback, useLayoutEffect, useState } from "react";

// Given a ref this hook attaches a resize observer to the element
// Similar to adding a 'resize' listener but works on any element
// It returns the width and height of the element
// @ts-expect-error TS(7006) FIXME: Parameter 'ref' implicitly has an 'any' type.
export const useSize = (ref) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const handleResize = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'entries' implicitly has an 'any' type.
    (entries) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const [entry] = entries;
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);
    },
    [],
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let observer = new ResizeObserver(handleResize);
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'ResizeObser... Remove this comment to see the full error message
      observer = null;
    };
  }, [ref]);

  return [width, height];
};

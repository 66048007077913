import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CountByStatusMutationRequest,
  CountByStatusMutationResponse,
} from "../../models/CountByStatus";

/**
 * @description The count of records by open and closed status
 * @summary records count by open and closed status
 * @link /reports/count_by_status
 */
export async function countByStatus(
  data: CountByStatusMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CountByStatusMutationResponse>["data"]> {
  const res = await client<
    CountByStatusMutationResponse,
    CountByStatusMutationRequest
  >({
    method: "put",
    url: `/reports/count_by_status`,
    data,
    ...options,
  });
  return res.data;
}

import React from "react";
import Popup from "reactjs-popup";

interface Props {
  trigger: React.ReactNode;
  caption?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any[];
}

const ViewRolesPopup = ({ caption, trigger, options }: Props) => (
  <Popup
    // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'Elemen... Remove this comment to see the full error message
    trigger={trigger}
    on="hover"
    position="bottom left"
    arrow={false}
    keepTooltipInside
  >
    <div className="user-roles-detail-popup">
      <div className="user-roles-detail-popup-content">
        <div className="user-roles-detail-popup-caption">{caption}</div>
        <div className="user-roles-detail-popup-options">
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {options.map((option) => (
            <div key={option.id} className="user-roles-detail-popup-option">
              <img src={option.icon} />
              <span>{option.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Popup>
);

export default ViewRolesPopup;

import React from "react";

export function Subtitle({ children }: { children: React.ReactNode }) {
  return (
    <>
      <span className="tw-text-xl tw-font-medium tw-text-neutral-200">/</span>
      {children || "Untitled"}
    </>
  );
}

import { IconButton } from "@themis/ui";
import React from "react";
import { PiCheckBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { useBoolean } from "../../../hooks/useBoolean";

function UpdateConfirmationDialog(props: {
  model: string;
  showConfirmation: boolean;
  countLabel: string;
  message: string;
  onYes: VoidFunction;
  onNo?: VoidFunction;
}) {
  const showPopup = useBoolean({ defaultValue: false });

  const popupMessage =
    props.message ||
    `Updating ${props.model} name will result in name changes to all ${props.countLabel} attached records. Are you sure you want to proceed?`;

  const trigger = (
    <IconButton
      data-testid="update-confirmation-trigger"
      color="tertiary"
      size="lg"
      Icon={PiCheckBold}
    />
  );

  if (!props.showConfirmation) {
    return <div onClick={props.onYes}>{trigger}</div>;
  }

  const handleYesClick = () => {
    showPopup.onFalse();
    props.onYes();
  };
  const handleNoClick = () => {
    showPopup.onFalse();
    props.onNo?.();
  };

  return (
    <Popup
      position="bottom right"
      trigger={trigger}
      keepTooltipInside
      open={showPopup.value}
      onOpen={showPopup.onTrue}
      onClose={showPopup.onFalse}
    >
      <div
        className="table-dropdown success-dropdown"
        style={{ width: 200 }}
        data-testid="confirmation-dialog"
      >
        <h4>Update {props.model}</h4>
        <p>{popupMessage}</p>
        <div className="confirmation">
          <button
            onClick={handleYesClick}
            data-testid="confirmation-dialog-confirm"
          >
            Yes
          </button>
          <button
            onClick={handleNoClick}
            data-testid="confirmation-dialog-reject"
          >
            No
          </button>
        </div>
      </div>
    </Popup>
  );
}

export default UpdateConfirmationDialog;

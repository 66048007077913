export const COMPLIANCE_TEMPLATES_PREVIEWABLE = [
  {
    title: "Marketing and Advertising Policy",
    description:
      "The Marketing and Advertising Policy applies to all types of collateral, including social media, issued, released, shared or promoted by the Company. Provided By: Alt Compliance, LLC",
    attachmentName: "marketing_advertising_policy.pdf",
    previewableTemplateName: "marketing_advertising_policy",
  },
  {
    title: "UDAAP Policy",
    description:
      "Unfair, Deceptive or Abusive Acts or Practices Policy outines best practices for advertising and marketing material to avoid practices that mislead or is likley to mislead consumers.",
    attachmentName: "udaap_policy.pdf",
    previewableTemplateName: "udaap_policy",
  },
  {
    title: "Servicemembers Civil Relief Act Policy",
    description:
      "The Servicemembers' Civil Relief Act (SCRA) postpones or suspends certain civil obligations to enable service members to devote their full attention to duty and relieve stress on their families. The SCRA covers all active duty service members, reservists, and members of the National Guard while on active duty.",
    attachmentName: "scra_policy.pdf",
    previewableTemplateName: "scra_policy",
  },
] as const;

export const COMPLIANCE_TEMPLATES = [
  {
    title: "AML Policy",
    description:
      "The Anti-Money Launder Policy and Procedures inlcudes a framework to assess AML risk along with identification requirements when opening customer accounts. The Policy also include red flags that signal possible money laundering or terrorist financing. Provided by: VACC, LLC",
    attachmentName: "aml_policy.pdf",
  },
  {
    title: "ACH Policy and Procedures",
    description:
      "This comprehensive ACH Policy and Procedures outlines procedures of the Automated Clearing House (“ACH”) service operation and includes industry standards relevant to consumer protections and efficient ACH processig and settlement. Management is encouraged to revisit this document as changes are made to the current NACHA Operating Rules & Guidelines 1 (“ACH Rules”). Provided By: Alt Compliance, LLC",
    attachmentName: "ach_policy_procedure.pdf",
  },
  {
    title: "UDAAP Best Practices Matrix",
    description:
      "The comprehensive Unfair, Deceptive, or Abusive Acts or Practices (UDAAP) matrix provides a systematic method to assess and manage UDAAP risk taking into account inherent and control factors. A low, moderate or high score can be assessed based on various factors.",
    attachmentName: "udaap_matrix.pdf",
  },
  {
    title: "Equal Credit Opportunity Act (Reg B) ",
    description:
      "The Reg B - ECOA policy includes crtieria for evaluating applications and notices / disclosures to stay compliant with Regulation B. Regulation B prohibits creditors from requesting and collecting specific personal information about an applicant that has no bearing on the applicant's ability or willingness to repay the credit requested and could be used to discriminate against the applicant.",
    attachmentName: "equal_opportunity.pdf",
  },
  {
    title: "Can Spam Act Policy",
    description:
      "The Can Spam Policy helps set the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have you stop emailing them, and spells out tough penalties for violations.",
    attachmentName: "can_spam_act_policy.pdf",
  },
  {
    title: "Complaint Policy",
    description:
      "The Complaint Policy outlines details to capture for incomig complaints, time frames and processes to track and store complaint documentation.",
    attachmentName: "complaint_policy.pdf",
  },
  {
    title: "Compliance Management System Policy",
    description:
      "The Compliance Management System Policy outlines the minimum requirements for an effective compliance framework - Policies, Procedures, Issue Tracking, Risk Assessments, Complaint Management. Supplemental procedures can be provided by Themis.",
    attachmentName: "compliance_management_policy.pdf",
  },
  {
    title: "E-Sign Policy",
    description:
      "The E-Sign Act allows the use of electronic records to satisfy any statute, regulation, or rule of law requiring that such information be provided in writing, if the consumer has affirmatively consented to such use and has not withdrawn such consent.",
    attachmentName: "esign_policy.pdf",
  },
  {
    title: "Electronic Funds Transfer Act (Reg E) ",
    description:
      "Regulation E provides a basic framework that establishes the rights, liabilities, and responsibilities of participants in electronic fund transfer systems such as automated teller machine transfers, telephone bill-payment services, point-of-sale (POS) terminal transfers in stores, and preauthorized transfers from or to a consumer's account",
    attachmentName: "eft_reg_e.pdf",
  },
  {
    title: "Ethical Conduct Policy",
    description:
      "A code of ethics and professional conduct outlines the ethical principles that govern decisions and behavior at a company or organization.",
    attachmentName: "ethical_conduct_policy.pdf",
  },
  {
    title: "Fair Credit Reporting Act (FCRA) Policy",
    description:
      "The FCRA Policy governs access to consumer credit report records and promotes accuracy, fairness, and the privacy of personal information assembled by Credit Reporting Agencies (CRAs).",
    attachmentName: "fcra_policy.pdf",
  },
  {
    title: "Foreign Corrupt Practices Act Policy",
    description:
      "U.S. Foreign Corrupt Practices Act. FCPA makes it unlawful for a U.S. person or company to offer, pay, or promise to pay money to any foreign official for the purpose of obtaining or retaining business.",
    attachmentName: "foreign_corrupt_practices_policy.pdf",
  },
  {
    title: "ID Theft Prevention and Red Flags Policy",
    description:
      "The Red Flags Rule requires specified firms to create a written Identity Theft Prevention Program (ITPP) designed to identify, detect and respond to “red flags”—patterns, practices or specific activities—that could indicate identity theft.",
    attachmentName: "id_theft_policy.pdf",
  },
  {
    title: "Military Lending Act Policy",
    description:
      "The Military Lending Act1 (MLA), enacted in 2006 and implemented by the Department of Defense (DoD), protects active duty members of the military, their spouses, and their dependents from certain lending practices. restricts loan rollovers, renewals, and refinanc- ings by some types of creditors.",
    attachmentName: "mla_policy.pdf",
  },
  {
    title: "Reg CC Funds Availability",
    description:
      "Regulation CC sets forth the requirements that credit unions make funds deposited into transaction accounts available according to specified time schedules and that they disclose their funds availability policies to their members. It also establishes rules designed to speed the collection and return of unpaid checks.",
    attachmentName: "reg_cc_policy.pdf",
  },
  {
    title: "Reg E Policy",
    description:
      "Regulation E provides a basic framework that establishes the rights, liabilities, and responsibilities of participants in electronic fund transfer systems such as automated teller machine transfers, telephone bill-payment services, point-of-sale (POS) terminal transfers in stores, and preauthorized transfers from or to a consumer's account.",
    attachmentName: "reg_e_policy.pdf",
  },
  {
    title: "Telephone Consumer Protection Act Policy",
    description:
      "The TCPA restricts telemarketing calls made to: Any residential telephone subscriber before the hour of 8 a.m. or after 9 p.m. (called party's local time) A residential telephone number on the national do-not-call registry.",
    attachmentName: "tcpa_policy.pdf",
  },
  {
    title: "Vendor Due Diligence / Third Party Oversight Policy",
    description:
      "The Third Party Oversight Policy discusses the importance of establishing a program which identifies, measures, monitors and controls risk with third parties.",
    attachmentName: "vdd_policy.pdf",
  },
  {
    title: "Truth in Lending Act Policy",
    description:
      "The Truth in Lending Act (TILA) protects you against inaccurate and unfair credit billing and credit card practices.",
    attachmentName: "tila_policy.pdf",
  },
  {
    title: "Unlawful Internet Gambling Enforcement Act (Reg GG)",
    description:
      "Prohibits any person engaged in the business of betting or wagering (as defined in the Act) from knowingly accepting payments in connection with the participation of another person in unlawful Internet gambling.",
    attachmentName: "unlawful_gambling_policy.pdf",
  },
  {
    title: "New Product Approval Policy",
    description:
      "The New Product Approval Policy and Procedures establish appropriate risk management processes for new activity development and effectively measure, monitor, and control the risks associated with new activities. Potential risks include, but are not limited to, strategic, reputational, credit, operational, compliance, and liquidity risk. Provided By: Alt Compliance, LLC",
    attachmentName: "new_product_approval_policy.pdf",
  },
  {
    title: "Reg B ECOA",
    description:
      "This comprehenisve Reg B Policy outlines various procedures and regulatory requirements for companies providing credit related products. Provided By: Alt Compliance, LLC",
    attachmentName: "reg_b_ecoa_policy.pdf",
  },
  {
    title: "Fraud Policy and Procedure",
    description:
      "The Fraud Policy and Procedures apply to any irregularity, or suspected irregularity, involving employees, contractors, temporary workers, as well as shareholders and other third parties contracted to do business with the Company. Best practices for investigations are also included.",
    attachmentName: "fraud_policy_procedure.pdf",
  },
  {
    title: "BSA / AML Risk Assessment Template",
    description:
      "The BSA/AML Risk Assessment provides a matrix to help identify the inherent and residual risk associated with Products, Customers, Geographies, BSA Operations/Administration, and Third-Party Vendor Management. Provided by: VACC, LLC",
    attachmentName: "bsa_aml_template.pdf",
  },
  {
    title: "Transaction Monitoring Policy",
    description:
      "The Transaction Monitoring Policy includes procesess to monitor customer transactions, including assessing historical/current customer information and interactions to provide a complete picture of customer activity. This can include transfers, deposits, and withdrawals. Provided by: VACC, LLC",
    attachmentName: "tmp_policy.pdf",
  },
  {
    title: "Ongoing Monitoring Policy",
    description:
      "The Ongoing Monitoring Policy means both taking steps to ensure that the firm's knowledge of their business relationships remains current and scrutinizing transactions to make sure that they are consistent with the expected behavior and purpose of the business relationship. Provided by: VACC, LLC",
    attachmentName: "ongoing_monitoring_policy.pdf",
  },
  {
    title: "OFAC Sanctions Screening",
    description:
      "The OFAC Sancton Screening involves checking entities against the denied party lists maintained by the United States Treasury Department's Office of Foreign Assets Control (OFAC). Additionally, OFAC compliance can also include identifying sanctions and embargoes placed on nations and political jurisdictions. Provided by: VACC, LLC",
    attachmentName: "ofac_sactions_screening.pdf",
  },
] as const;

import "./risk-methodology-builder.scss";

import React from "react";

import { Button, Flex, Typography } from "@/components/Elements";

interface TableInputGroupProps {
  title: string;
  addNewInputText?: string;
  dataTestID?: string;
  children: React.ReactNode;
  description?: string;
  addNewInput?: () => void;
}

function TableInputGroup({
  title,
  addNewInputText,
  description,
  dataTestID,
  addNewInput,
  children,
}: TableInputGroupProps) {
  return (
    <div className="table-input-group">
      <Flex justifySpaceBetween alignEnd fullWidth data-testid={dataTestID}>
        <div>
          <Typography
            label={title}
            size="lg"
            color="generalMidnightDark"
            weight="semiBold"
            data-testid={`${dataTestID}-title`}
          />
          <Typography
            label={description}
            size="sm"
            weight="semiBold"
            data-testid={`${dataTestID}-desc`}
          />
        </div>
        {addNewInputText && (
          <Button
            className="input-group-row__button"
            data-testid={`${dataTestID}-add-new`}
            label={addNewInputText}
            onClick={addNewInput}
          />
        )}
      </Flex>
      <div className="table-input-group__list">{children}</div>
    </div>
  );
}

export default TableInputGroup;

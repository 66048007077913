import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  Stack,
} from "@themis/ui";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";

import { ColumnsTypeSelectField } from "./columns-type-select-field/columns-type-select-field";
import { CustomColumnControls } from "./custom-column-controls/custom-column-controls";
import type { ValidationSchema } from "./use-validation-schema/use-validation-schema";
import { useValidationSchema } from "./use-validation-schema/use-validation-schema";

interface CustomDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function CustomColumnDialog({ open, onOpenChange }: CustomDialogProps) {
  const { formatMessage } = useIntl();

  const validationSchema = useValidationSchema();

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      columnType: "text",
      columnData: {
        requiredColumn: false,
        name: "",
        multipleSelections: false,
      },
    },
  });

  const { columnType } = useWatch({ control: form.control });

  useEffect(() => {
    form.resetField("columnData");
  }, [columnType, form]);

  const handleSaveColumn = (data: ValidationSchema) => {
    console.debug(data); // eslint-disable-line no-console
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {formatMessage({ defaultMessage: "Create Custom Column" })}
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSaveColumn)}>
            <Stack spacing="3">
              <ColumnsTypeSelectField
                control={form.control}
                name="columnType"
              />

              {columnType && (
                <CustomColumnControls
                  columnType={columnType}
                  control={form.control}
                />
              )}
            </Stack>

            <DialogFooter>
              <Button type="submit" color="primary">
                {formatMessage({ defaultMessage: "Confirm" })}
              </Button>

              <Button color="tertiary" onClick={() => onOpenChange(false)}>
                {formatMessage({ defaultMessage: "Cancel" })}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

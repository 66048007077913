import "./styles.scss";

import classnames from "classnames";
import React from "react";

interface Props {
  containerClass?: string;
  content?: string;
  handleConfirm?: () => void;
  handleReject?: () => void;
  heading?: string;
  isConfirmDisabled?: boolean;
  dialogClass?: string;
  hideActionButtons?: boolean;
}

function ConfirmationDialog({
  containerClass,
  heading,
  content,
  handleConfirm,
  handleReject,
  isConfirmDisabled,
  dialogClass,
  hideActionButtons,
}: Props) {
  return (
    <div className={containerClass} data-testid="confirmation-dialog">
      <h4>{heading}</h4>
      <p className="pre-wrap-confirmation-content">{content}</p>
      {!hideActionButtons && (
        <div className={classnames("confirmation", dialogClass)}>
          <button
            onClick={handleConfirm}
            disabled={isConfirmDisabled}
            data-testid="confirmation-dialog-confirm"
          >
            Yes
          </button>
          <button
            onClick={handleReject}
            data-testid="confirmation-dialog-reject"
          >
            No
          </button>
        </div>
      )}
    </div>
  );
}

ConfirmationDialog.defaultProps = {
  containerClass: "",
  dialogClass: "",
  heading: "",
  content: "",
  handleConfirm: () => {},
  handleReject: () => {},
  isConfirmDisabled: false,
};

export default ConfirmationDialog;

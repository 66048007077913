import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Clock2 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1C5.93417 1 1 5.93305 1 12C1 12.5606 1.04284 13.1222 1.12641 13.67C1.23172 14.3654 1.88121 14.8618 2.59414 14.7456C3.29428 14.6364 3.77347 13.9811 3.66753 13.2818C3.60358 12.8627 3.5719 12.4334 3.5719 12C3.5719 7.35283 7.35299 3.57086 12 3.57086C16.6471 3.57086 20.4291 7.35291 20.4291 12C20.4291 16.6481 16.6471 20.4291 12 20.4291C9.36249 20.4291 6.92912 19.2309 5.31736 17.1384L5.3162 17.137C4.88395 16.5799 4.07995 16.4687 3.51517 16.9049C2.95257 17.3373 2.84832 18.1452 3.28059 18.7081C5.38057 21.4337 8.56139 23 12 23C18.0659 23 23 18.0659 23 12C23 5.93305 18.0658 1 12 1ZM11.645 6.35271C10.9351 6.35271 10.3595 6.92922 10.3595 7.63918V12.7285C10.3595 13.1818 10.5984 13.5996 10.9838 13.8319L10.9859 13.8332L14.9464 16.1948C15.1521 16.3171 15.3803 16.3753 15.6039 16.3753C16.039 16.3753 16.4675 16.1521 16.7075 15.7489C17.0717 15.1385 16.8711 14.35 16.2619 13.9863L12.9304 11.9994V7.63918C12.9304 6.92922 12.3548 6.35271 11.645 6.35271Z"
      fill="#353549"
    />
  </svg>
);

Clock2.displayName = "Clock2Icon";

export default Clock2;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../../images/table-image/icon/options-check.svg";

interface Props {
  hasErrors: boolean;
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions: any[];
  width: number | string;
  errorMessage?: string;
  fieldName?: string;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  renderEmptyOption?: boolean;
}

function ControlMappingTypesSelect({
  fieldName,
  recordVersionID,
  selectedOptions,
  width,
  hasErrors,
  errorMessage,
  renderEmptyOption,
  hasErrorClass,
  pinned,
  locked,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const titleInputRef = useRef();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selectedOptions);
  const [addNew, setAddNew] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  // Variables
  const { company } = mainStore.companies;
  const { controlMappingTypes } = mainStore.controlMappingTypes;
  const liClassNames = classNames({
    active: isSelectModalOpen,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    pinned,
    "locked-cell": locked,
    "pointer-events-none": locked,
  });

  // Effects
  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (addNew) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      titleInputRef.current.focus();
    }
  }, [addNew]);

  const refreshcontrolMappingTypes = async () => {
    mainStore.controlMappingTypes.index(company.id);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  const addItem = (id) => {
    setSelectedItems([id]);
    setShowPopup(false);
  };

  const onClose = () => {
    if (selectedItems !== selectedOptions) {
      mainStore.recordVersions.update({
        fieldName,
        recordVersionID,
        value: mainStore.avroSchemas.serializeValue(fieldName, selectedItems),
      });
    }

    setAddNew(false);
  };

  const handlePopUpOpen = () => {
    refreshcontrolMappingTypes();
    setShowPopup(true);
    setIsSelectModalOpen(true);
  };

  const handlePopUpClose = () => {
    onClose();
    setShowPopup(false);
    setIsSelectModalOpen(false);
  };

  const optionsPlaceholder = (
    <p
      className="options-placeholder nodata"
      data-testid="column-options-empty"
    >
      Select Type
    </p>
  );

  const renderTrigger = (
    <li
      className={liClassNames}
      style={{ width }}
      data-testid="column-options-trigger"
    >
      <div className="cell-content">
        <div
          className="options column-options-container"
          data-testid="column-options-container"
        >
          {selectedOptions.length === 0 && optionsPlaceholder}

          {hasErrors && <div>{errorMessage}</div>}

          {!hasErrors &&
            selectedOptions.length > 0 &&
            controlMappingTypes
              // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
              ?.filter((item) => selectedOptions.includes(item.id))
              .map((item) => (
                <div
                  className="options column-options-item"
                  data-testid="column-options-item"
                  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                  key={item.id}
                >
                  <span
                    className="value value-type risk-type-option-item"
                    data-testid="column-options-value"
                  >
                    {/* @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'. */}
                    {item.title}
                  </span>
                </div>
              ))}
        </div>
      </div>
    </li>
  );

  const renderContent = (
    <div
      className="options-dropdown"
      data-testid="column-options-popup-content"
    >
      <div
        className="options-dropdown-list"
        data-testid="column-options-dropdown-list"
      >
        <ul>
          <div>
            {controlMappingTypes?.length === 0 && (
              <h4 className="no-result">No result found</h4>
            )}

            {renderEmptyOption && (
              <li
                className="colored empty-option"
                data-testid="column-options-dropdown-item"
                onClick={() => addItem(null)}
              >
                <span className="item empty-column-option-item">N/A</span>
              </li>
            )}

            {controlMappingTypes?.map((item) => {
              const optionClasses = classNames({
                colored: true,
                // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                active: selectedItems.includes(item.id),
              });

              return (
                <li
                  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                  key={item.id}
                  className={optionClasses}
                  data-testid="column-options-dropdown-item"
                  onClick={() => {
                    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                    addItem(item.id);
                  }}
                >
                  <span className="item risk-type-option-item">
                    {/* @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'. */}
                    {item.title}
                  </span>
                  <span className="check">
                    <img src={checkIcon} alt="check-icon" />
                  </span>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom left"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      {renderContent}
    </Popup>
  );
}

ControlMappingTypesSelect.defaultProps = {
  renderEmptyOption: true,
  hasErrorClass: "has-errors",
};

export default observer(ControlMappingTypesSelect);

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

import pointsIconActive from "../../../images/table-image/icon/points-icon-active.svg";
import pointsIcon from "../../../images/table-image/icon/points-icon.svg";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import Unlock from "../shared/Unlock";

interface Props {
  recordVersion: RecordVersion;
}

function ZendeskContextMenu({ recordVersion }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  // Variables
  const recordVersionID = recordVersion.id;
  const { canDeleteRecords, canPublishOrLockRecords } =
    mainStore.userPermissions;
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isClosed = status === "verified";
  const { taskDetail } = mainStore;

  // Functions
  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    if (isClosed) {
      handlePopUpClose();
    } else {
      setIsDropdownOpen(false);
      setIsDeleteConfirmationOpen(true);
    }
  }

  function deleteRow() {
    const { company } = mainStore.companies;
    if (company && company.id) {
      mainStore.zendesk.delete(recordVersionID);
      mainStore.toast.setText("Zendesk deleted!");
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDropdownOpen(true);
    setIsDeleteConfirmationOpen(false);
  }

  function unlockZendesk() {
    mainStore.zendesk.unlock(recordVersionID);
    handlePopUpClose();
  }

  const handleCreateTask = () => {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  };

  // Verify
  async function submitPressed() {
    await mainStore.zendesk.verify(recordVersionID);
  }

  const renderTrigger = (
    <button
      className={classNames("points-button", { "big-active": isOpenModal })}
      data-testid="complaints-context-menu-trigger"
    >
      <img
        className="points-icon"
        src={pointsIcon}
        alt="points-icon"
        data-testid="points-button-trigger"
      />
      <img
        className="points-icon-active"
        src={pointsIconActive}
        alt="points-icon-active"
      />
    </button>
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="zendesk-context-menu-dropdown"
    >
      <ul>
        <li onClick={handleCreateTask}>Create Task</li>

        {canDeleteRecords && (
          <li
            onClick={deleteRowConfirmation}
            className={classNames({ disabled: isClosed })}
            data-testid="zendesk-context-menu-dropdown-delete"
          >
            Delete
          </li>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Zendesk"
      content="Are you sure you want to delete this zendesk? Actions are not reversable."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  const renderButton = isClosed ? (
    <Unlock
      onYes={unlockZendesk}
      tableName="Default"
      moduleIdentifier="zendesk"
      buttonText="Unlock"
      canEveryoneUnlockForInternalWorkspace={false}
    />
  ) : (
    <button
      className="finalize-active"
      data-testid="verify-button"
      onClick={submitPressed}
      disabled={!canPublishOrLockRecords}
    >
      <Icon
        name="check"
        className="icon-right-indent"
        color={!canPublishOrLockRecords ? "generalDark" : "generalWhite"}
        size="de"
      />
      Verify
    </button>
  );

  return (
    <div className="list-points" data-testid="zendesk-context-menu">
      {renderButton}

      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && renderBaseContent}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

export default observer(ZendeskContextMenu);

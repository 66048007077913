import { observer } from "mobx-react";
import React from "react";
import { generatePath, NavLink } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { ROUTES } from "../pages";
import ModuleTabs from "./ModuleTabs/ModuleTabs";

function CWPartnerTabs() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  return (
    <div className="risk-assessment-tabs">
      <ModuleTabs>
        <NavLink
          exact
          to={generatePath(ROUTES.DASHBOARD_PATH, {
            workspace_id: Number(workspaceID),
          })}
        >
          Questionnaires
        </NavLink>
        <NavLink
          exact
          to={generatePath(ROUTES.CW_PARTNERS_DOCUMENTS_PATH, {
            workspace_id: Number(workspaceID),
          })}
        >
          Documents
        </NavLink>
      </ModuleTabs>
    </div>
  );
}

export default observer(CWPartnerTabs);

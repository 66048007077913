import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";
import TextAreaInput from "../../form/TextAreaInput";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emailSlideClose?: (...args: any[]) => any;
  recordVersionID?: number;
}

function RequestMoreInformation({ emailSlideClose, recordVersionID }: Props) {
  const mainStore = useMainStore();
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  const messageRef = React.createRef();

  function onSend() {
    mainStore.conflicts.requestMoreInformation({
      recordVersionID,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      message: messageRef.current.value,
    });
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    emailSlideClose();
  }

  return (
    <div className="table-dropdown table-email-dropdown">
      <div className="email-dropdown-head">
        <h3>Request More Info</h3>
        <div onClick={emailSlideClose}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      <hr />
      <hr />
      <div className="email-dropdown-wrap">
        <TextAreaInput
          // @ts-expect-error TS(2322) FIXME: Type '{ type: string; placeholder: string; name: s... Remove this comment to see the full error message
          type="text"
          placeholder="Notes to employee"
          name="Message"
          inputRef={messageRef}
        />
        <div className="creative-wrap" />
        <button
          data-testid="send-request"
          onClick={onSend}
          disabled={!hasModuleWriteAccess}
        >
          Send
        </button>
      </div>
      <hr />
    </div>
  );
}

export default observer(RequestMoreInformation);

import { zodResolver } from "@hookform/resolvers/zod";
import type { SelectProps } from "@themis/ui";
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  TextArea,
  TextInput,
} from "@themis/ui";
import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import type { Project } from "@/api";
import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { useMainStore } from "@/contexts/Store";
import type { Workspace } from "@/stores/types/workspace-types";

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  description: z.string().optional(),
  workspace_id: z.number().min(1, "Workspace is required"),
  visibility: z.enum(["public", "internal"]),
});

export type ProjectDetailsSchema = z.infer<typeof formSchema>;

function ProjectForm({
  onSubmit,
  defaultValues,
}: {
  onSubmit: (values: ProjectDetailsSchema) => void;
  defaultValues?: Partial<Project>;
}) {
  const { workspace_id: currentWorkspaceId, project_id: projectId } =
    useParams<{
      workspace_id: string;
      project_id: string;
    }>();
  const mainStore = useMainStore();
  const { list: workspaces }: { list: Workspace[] } = mainStore.workspaces;
  const activeCompanyWorkspaces = workspaces.filter(
    (ws) =>
      !ws.is_archived && ws.company_id === mainStore.companies.company?.id,
  );
  const currentWorkspace: Workspace | undefined = workspaces.find(
    (ws) => ws.id === Number(currentWorkspaceId),
  );
  const isInternalWorkspace = currentWorkspace?.is_internal;
  const internalWorkspaceId = workspaces.find((ws) => ws.is_internal)?.id;
  const isNewProject = isNaN(Number(projectId));
  const isArchived = Boolean(defaultValues?.archived_at);

  function mapDefaultValuesToForm(values?: Partial<Project>) {
    return {
      name: values?.name || "",
      description: values?.description || "",
      // Default to undefined workspace_id if in internal workspace because defaulting to the current workspace (Internal)
      // would require private visibility which is anti-collaboration; otherwise default to the current workspace
      workspace_id:
        values?.workspace_id ||
        (isInternalWorkspace ? undefined : Number(currentWorkspaceId)),
      visibility: (values?.visibility as "public" | "internal") || "public",
    };
  }
  const form = useForm<ProjectDetailsSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: mapDefaultValuesToForm(defaultValues),
  });

  const watchedValues = form.watch();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  }

  const workspaceItems: SelectProps["items"] = activeCompanyWorkspaces.map(
    (workspace) => ({
      label: workspace.name,
      value: String(workspace.id),
      // eslint-disable-next-line react/no-unstable-nested-components
      Component: () => (
        <div className="tw-flex tw-items-center tw-gap-2">
          <img
            className="tw-w-[20px] tw-rounded"
            src={getWorkspaceLogo(workspace).logo}
          />
          <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
            {workspace.name}
          </span>
        </div>
      ),
    }),
  );

  return (
    <Form {...form}>
      <form
        onSubmit={handleSubmit}
        className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3"
      >
        <FormField
          required
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Project Name</FormLabel>
              <FormControl>
                <TextInput
                  {...field}
                  autoComplete="off"
                  readOnly={isArchived}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <TextArea {...field} readOnly={isArchived} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="tw-flex tw-flex-col">
          <FormField
            control={form.control}
            name="visibility"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Visibility</FormLabel>
                <FormControl>
                  <Select
                    locked={internalWorkspaceId === watchedValues.workspace_id}
                    readOnly={isArchived}
                    items={[
                      { label: "Public", value: "public" },
                      { label: "Internal", value: "internal" },
                    ]}
                    selected={field.value ? String(field.value) : null}
                    onSelect={(value) => {
                      field.onChange(value);
                    }}
                  />
                </FormControl>
                <FormMessage>
                  <span className="tw-text-neutral-300">
                    {watchedValues.visibility === "internal"
                      ? "Project is visible to internal workspace users"
                      : "Project is visible to internal and selected workspace users"}
                  </span>
                </FormMessage>
              </FormItem>
            )}
          />
        </div>

        <FormField
          required
          control={form.control}
          name="workspace_id"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Workspace</FormLabel>
              <FormControl>
                <Select
                  readOnly={isArchived}
                  items={workspaceItems}
                  selected={field.value ? String(field.value) : null}
                  onSelect={(value) => {
                    field.onChange(Number(value));
                    // Set visibility to internal if the selected workspace is the internal workspace; visibility field should lock when this happens because we don't allow public visibility and internal workspace combination
                    if (Number(value) === internalWorkspaceId) {
                      form.setValue("visibility", "internal");
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!isArchived && (
          <Button
            className="tw-col-span-2 tw-mt-5 tw-place-self-end"
            type="submit"
          >
            {isNewProject ? "Create Project" : "Save Project"}
          </Button>
        )}
      </form>
    </Form>
  );
}

export default ProjectForm;

import {
  Button,
  Checkbox,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@themis/ui";
import React from "react";
import { PiFadersBold } from "react-icons/pi";

import { CHILD_STATUSES } from "../config/status";
import { useChildrenFilterSort } from "../hooks/useChildrenFilterSort";
import { FilterOperand } from "../hooks/useFilterSort";

export default function StatusFilterButton() {
  const { filters, addFilter } = useChildrenFilterSort();

  function handleStatusToggle(status: string, checked: boolean | string) {
    if (!filters || !filters.child_status) {
      throw new Error("Status filter should always be present");
    }

    if (filters?.child_status?.value?.length === 1 && checked === false) {
      return;
    }

    if (checked) {
      addFilter("child_status", {
        operand: FilterOperand.ANY,
        value: [...(filters.child_status.value as string[]), status],
      });
    } else {
      addFilter("child_status", {
        operand: FilterOperand.ANY,
        value: (filters.child_status.value as string[]).filter(
          (v) => v !== status,
        ),
      });
    }
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button LeftIcon={PiFadersBold}>Filters</Button>
      </PopoverTrigger>
      <PopoverContent align="start">
        <div className="tw-box-border tw-flex tw-w-72 tw-cursor-default tw-flex-col tw-gap-1 tw-px-4 tw-py-3 tw-font-medium tw-text-neutral-500">
          {CHILD_STATUSES.map((status) => (
            <div
              className="tw-flex tw-items-center tw-gap-2 tw-pr-4 hover:tw-bg-primary-25"
              key={status.value}
            >
              <div className="tw-flex tw-cursor-not-allowed tw-items-center tw-gap-2 tw-px-4 tw-py-2.5 tw-text-neutral-300">
                <Checkbox
                  color="secondary"
                  className="data-[state=on]:tw-bg-secondary-300 data-[state=on]:tw-text-white data-[state=on]:hover:tw-bg-secondary-500"
                  checked={filters?.child_status?.value?.includes(status.value)}
                  onCheckedChange={(checked) =>
                    handleStatusToggle(status.value, checked)
                  }
                />
              </div>
              <Label className="tw-text-nowrap tw-pr-12 tw-text-sm tw-text-neutral-500">
                {status.label}
              </Label>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}

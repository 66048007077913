var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isArray } from "lodash";
import React from "react";
import { filterCellProps } from "../../../lib/utils";
import { Select } from "../../Form/Select";
import { MiniTag } from "../../MiniTag/MiniTag";
var UsersCell = function (_a) {
    var _b;
    var users = _a.users, value = _a.value, onValueChange = _a.onValueChange, defaultOpen = _a.defaultOpen, multiple = _a.multiple, props = __rest(_a, ["users", "value", "onValueChange", "defaultOpen", "multiple"]);
    var selected = props.selected, onSelect = props.onSelect, restProps = __rest(props, ["selected", "onSelect"]);
    var filteredProps = filterCellProps(__assign({ value: value, onValueChange: onValueChange }, restProps));
    var editable = Boolean((_b = props.colDef) === null || _b === void 0 ? void 0 : _b.editable);
    var userOptions = users && users.length > 0
        ? users.map(function (user) { return ({
            label: user.full_name,
            value: user.id.toString(),
            initials: user.initials,
            colorIndex: user.icon_color_index,
        }); })
        : [];
    var selectedValues = isArray(value) ? value.map(String) : value === null || value === void 0 ? void 0 : value.toString();
    return (React.createElement(Select, __assign({ className: "tw-h-full tw-rounded-none tw-border-none tw-bg-transparent read-only:tw-bg-transparent group-hover/row:tw-bg-neutral-50", selected: selectedValues, items: userOptions, onSelect: onValueChange, multiple: multiple, defaultOpen: !!(defaultOpen && editable), onPopupClosed: function () { return props.stopEditing && props.stopEditing(); }, hideCaret: true, readOnly: !editable, placeholder: editable ? "- Select User -" : "", renderSelected: function (selectedUser) {
            var thisUser = selectedUser;
            if (multiple) {
                return (React.createElement("div", { className: "tw-mr-1", key: thisUser.value },
                    React.createElement(MiniTag, { colorIndex: thisUser.colorIndex }, thisUser.initials)));
            }
            return (React.createElement("div", { className: "tw-mr-1 tw-flex tw-gap-1", key: thisUser.value },
                React.createElement(MiniTag, { colorIndex: thisUser.colorIndex }, thisUser.initials),
                React.createElement("div", null, thisUser.label)));
        } }, filteredProps)));
};
export default UsersCell;

import { isEmpty } from "lodash";

import type { DashboardComponent } from "@/api";

/**
 * Returns the selected workspace IDs.
 * If no selected workspace IDs are provided,
 * it returns the current workspace ID.
 */
export function getSelectedWorkspaces({
  selectedWorkspaceIds,
  currentWorkspaceId,
}: {
  selectedWorkspaceIds?: string[];
  currentWorkspaceId: number;
}) {
  if (isEmpty(selectedWorkspaceIds)) {
    return [currentWorkspaceId];
  }

  return selectedWorkspaceIds?.map(Number) || [];
}

const CHART_COLORS = [
  "#8F92F9",
  "#95D5F0",
  "#F042F0",
  "#9C001A",
  "#17B575",
  "#6161C3",
  "#6EC8F9",
  "#6D8CF9",
  "#F95939",
  "#FF879B",
  "#FEB3FE",
  "#791779",
  "#E7F8FF",
] as const;

const LOW_MEDIUM_HIGH_COLORS = ["#66AA22", "#FF9900", "#EB2E4E"] as const;

export function getChartColor({
  index,
  hasLowMediumHigh,
}: {
  index: number;
  hasLowMediumHigh?: boolean;
}) {
  const colors = hasLowMediumHigh
    ? [...LOW_MEDIUM_HIGH_COLORS, ...CHART_COLORS]
    : CHART_COLORS;

  return colors[index % colors.length];
}

export function formatMultiKeyBarChartData({
  widgetData,
  essentialSeriesKeys,
  hasLowMediumHigh,
}: {
  widgetData: DashboardComponent["data"];
  essentialSeriesKeys: string[];
  hasLowMediumHigh?: boolean;
}) {
  if (!widgetData) {
    return {
      keys: [],
      data: [],
      colors: [],
    };
  }

  const ranges = Object.keys(widgetData);
  const otherSourceKeys = new Set<string>();

  const mutatedData = ranges.map((range) => {
    const sources = Object.keys(widgetData[range]);

    const total = sources.reduce((acc, source) => {
      if (!essentialSeriesKeys.includes(source)) {
        otherSourceKeys.add(source);
      }

      return acc + widgetData[range][source];
    }, 0);

    return {
      name: range,
      total,
      ...widgetData[range],
    };
  });

  const otherKeys = Array.from(otherSourceKeys).sort((a, b) => {
    const totalA = mutatedData.reduce((acc, data) => acc + (data[a] || 0), 0);
    const totalB = mutatedData.reduce((acc, data) => acc + (data[b] || 0), 0);
    return totalB - totalA;
  });

  const keys = [...essentialSeriesKeys, ...otherKeys];

  const colors = keys.map((key, index) => ({
    key,
    color: getChartColor({ index, hasLowMediumHigh }),
  }));

  return {
    keys,
    data: mutatedData,
    colors,
  };
}

export function formatSingleKeyToMultiKeyBarChartData(
  widgetData: DashboardComponent["data"],
) {
  if (!widgetData) {
    return;
  }

  const transformedObj = Object.entries(widgetData).reduce<
    Record<string, unknown | undefined>
  >((acc, [key, value]) => {
    acc[key] = { [key]: value };
    return acc;
  }, {});

  return transformedObj;
}

export function formatKey(key: string) {
  return key
    .replace(/_/gi, " ")
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

export function formatSingleKeyBarChartData(
  widgetData: DashboardComponent["data"],
) {
  if (!widgetData) {
    return { data: [] };
  }

  const mutatedData = Object.keys(widgetData).map((range) => ({
    name: range,
    value: widgetData[range],
    color: "#8F92F9",
    ...widgetData[range],
  }));

  return { data: mutatedData };
}

export function formatPieChartData({
  widgetData,
  moduleWorkspacesNames,
}: {
  widgetData: DashboardComponent["data"];
  moduleWorkspacesNames?: { [key: string]: string };
}) {
  return Object.entries(widgetData || {})
    .sort(([, valueA], [, valueB]) => valueB - valueA)
    .map(([key, value], index) => ({
      name: moduleWorkspacesNames?.[key] || key,
      value,
      color: getChartColor({ index }),
    }));
}

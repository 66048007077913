import classNames from "classnames";
import React from "react";

import type { Account } from "@/api";
import CustomOptionsSelect from "@/components/table/shared/CustomOptionsSelect";

const accountsOptions = [
  {
    name: "partner",
    display_name: "Partner",
  },
  {
    name: "vendor",
    display_name: "Vendor",
  },
  {
    name: "customer",
    display_name: "Customer",
  },
  {
    name: "other",
    display_name: "Other",
  },
];

interface Props {
  label: string;
  isReadOnly?: boolean;
  selectedAccount: Account | undefined;
}

function VendorTypeField({ label, isReadOnly, selectedAccount }: Props) {
  return (
    <div className="field-block">
      <label>{label}</label>
      <div
        className={classNames(
          "custom-column-option-container",
          "vendor-view-disabled",
          {
            "table-cell--disabled": isReadOnly,
          },
        )}
      >
        <CustomOptionsSelect
          fieldName="type"
          selectedOptions={selectedAccount?.account_types || []}
          availableOptions={accountsOptions}
          titlePlaceholder="- Select -"
          disabled
        />
      </div>
    </div>
  );
}

export default VendorTypeField;

import { action, computed, makeObservable, observable } from "mobx";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";

import type { QuestionnaireRead } from "../../features/risk-assessment";
import type { MainStore } from "../Main";

export default class RiskAssessmentQuestionnaires {
  mainStore: MainStore;

  list: Array<QuestionnaireRead> = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      setAllQuestionnaires: action,
      questionnaires: computed,
      questionnaireTemplates: computed,
    });

    this.mainStore = mainStore;
  }

  async indexQuestionnaires(workspaceId: number) {
    this.controller = new AbortController();
    const data = await QuestionnaireAPI.getAll(
      workspaceId,
      this.controller.signal,
      true,
    );

    this.setAllQuestionnaires(data.questionnaires as QuestionnaireRead[]);
    this.mainStore.fields.setList(data.fields);
  }

  get questionnaires() {
    return this.list.filter((questionnaire) => !questionnaire.is_template);
  }

  get questionnaireTemplates() {
    return this.list.filter((questionnaire) => questionnaire.is_template);
  }

  setAllQuestionnaires(allQuestionnaires: Array<QuestionnaireRead>) {
    this.list = allQuestionnaires;
  }

  deleteQuestionnaire(id: number) {
    this.list = this.list.filter((questionnaire) => questionnaire.id !== id);
  }

  cleanup() {
    this.setAllQuestionnaires([]);
  }

  async importQuestionnaireTemplate(
    file: File,
    risk_methodology_id: number,
    isPublic: boolean = false,
  ) {
    try {
      const newTemplate = await QuestionnaireAPI.importQuestionnaireTemplate(
        Number(this.mainStore.context.workspaceID),
        file,
        risk_methodology_id,
        isPublic,
      );
      this.setAllQuestionnaires([newTemplate, ...this.list]);
      this.mainStore.toast.setInfoText(
        "Successfully imported questionnaire template",
      );
      return newTemplate;
    } catch (err) {
      this.mainStore.toast.setErrorFromResponse(err);
    }
  }

  abort() {
    this.controller?.abort();
  }
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ThumbsDown = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C3.34314 2 2 3.34315 2 5L2 11C2 12.6569 3.34315 14 5 14L6.381 14L9.55279 20.3416C10.0311 21.2982 10.9794 21.9232 12.0366 21.9934L12.2361 22L12.5 22C13.8255 22 14.91 20.9685 14.9947 19.6644L15 19.5L15 14L18.9983 14C19.1958 14 19.3929 13.9805 19.5866 13.9417C21.1533 13.6284 22.1889 12.1476 21.9696 10.5855L21.94 10.4116L20.74 4.41165C20.4712 3.0678 19.3293 2.0848 17.9759 2.00521L17.7983 2L5 2ZM6 12L5 12C4.44771 12 4 11.5523 4 11L4 5C4 4.44772 4.44771 4 5 4L6 4L6 12ZM12.5 20L12.2361 20C11.8994 20 11.5884 19.8309 11.4045 19.5554L11.3416 19.4472L8.10557 12.9751C8.0535 12.8709 8.02015 12.7588 8.00673 12.6437L8 12.5279L8 4L17.7983 4C18.2352 4 18.6165 4.28272 18.749 4.68987L18.7788 4.80388L19.9788 10.8039C20.0871 11.3454 19.7359 11.8723 19.1944 11.9806L19.0968 11.9951L18.9983 12L15 12C13.9456 12 13.0818 12.8159 13.0055 13.8507L13 14L13 19.5C13 19.7455 12.8231 19.9496 12.5899 19.9919L12.5 20Z"
      fill="#353549"
    />
  </svg>
);

ThumbsDown.displayName = "ThumbsDownIcon";

export default ThumbsDown;

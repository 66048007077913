import type { Recipients } from "@/features/notifications/pages/single/types";
import type {
  DAYS_OF_WEEK,
  NOTIFICATION_TYPE,
  RELATIVE_TO,
} from "@/stores/types/notification-rule-types";

export enum NOTIFICATION_FREQUENCY {
  DAILY = "days",
  WEEKLY = "weeks",
  MONTHLY = "months",
  ONE_TIME = "one_time",
}

export interface DateNotificationFormValues {
  enabled: boolean;
  frequency: NOTIFICATION_FREQUENCY;
  type: NOTIFICATION_TYPE;
  timePeriod: TimePeriod;
  condition: RELATIVE_TO;
  triggerColumn: string;
  criteria: Criteria[];
  recipients: Recipients;
}

export interface TimePeriod {
  numberOfDailyDays?: number;
  numberOfOneTimeDays: number[];
  numberOfWeeks?: number;
  numberOfMonths?: number;
  excludeWeekends: boolean;
  recurringWeekdays: DAYS_OF_WEEK[];
}

export interface Criteria {
  fieldName: string;
  fieldValues: string[];
}

import { filter } from "lodash";
import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { useMainStore } from "@/contexts/Store";

import ManageWorkspaces from "./ManageWorkspaces";
import WorkspaceItem from "./WorkspaceItem";

export const NUM_OF_WS_DISPLAYED = 6;

const WorkspaceSelection = () => {
  const mainStore = useMainStore();

  const selectedWorkspaces = useMemo(
    () =>
      filter(mainStore.workspaces.list, (workspace) =>
        mainStore.reports.selectedWorkspaceIDs.includes(workspace.id),
      ),
    [mainStore.reports.selectedWorkspaceIDs, mainStore.workspaces.list],
  );

  const numOfRemainingWorkspace =
    selectedWorkspaces.length - NUM_OF_WS_DISPLAYED;

  const hideWorkspaceSelection =
    mainStore.reports.displayedWorkspace !== "All Workspace";

  if (
    !mainStore.context.activeWorkspace?.is_internal ||
    hideWorkspaceSelection
  ) {
    return null;
  }

  return (
    <div className="workspace-selection-container">
      <div className="workspace-selection">
        <div className="workspace-selection-header">
          <div className="workspace-selection-title">
            Data used from these workspaces
          </div>
          <ManageWorkspaces />
        </div>
        <div className="selected-workspaces">
          {selectedWorkspaces.slice(0, NUM_OF_WS_DISPLAYED).map((workspace) => (
            <WorkspaceItem key={workspace.id} workspace={workspace} />
          ))}
          {selectedWorkspaces.length > NUM_OF_WS_DISPLAYED && (
            <div className="additional-workspaces-container">
              <div className="additional-workspaces">
                {numOfRemainingWorkspace}
              </div>
              <span className="workspace-item-title">
                {numOfRemainingWorkspace} More Workspace
                {numOfRemainingWorkspace > 1 ? "s" : ""}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(WorkspaceSelection);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateFindingMutationRequest,
  UpdateFindingMutationResponse,
  UpdateFindingPathParams,
} from "../../models/UpdateFinding";

/**
 * @description Update finding
 * @summary Update finding
 * @link /findings/:id
 */
export async function updateFinding(
  id: UpdateFindingPathParams["id"],
  data: UpdateFindingMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateFindingMutationResponse>["data"]> {
  const res = await client<
    UpdateFindingMutationResponse,
    UpdateFindingMutationRequest
  >({
    method: "put",
    url: `/findings/${id}`,
    data,
    ...options,
  });
  return res.data;
}

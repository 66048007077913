import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import TrainingContextMenu from "@/components/table/training/TrainingContextMenu";
import { useMainStore } from "@/contexts/Store";

function TrainingActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();

  // Variables
  const path = location.pathname;
  const trainingID = Number(path.substring(path.lastIndexOf("/") + 1));
  const recordVersionList = mainStore.recordVersions.list.find(
    (rv) => rv.id === trainingID,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = mainStore.trainings.data?.module_workspace_id;

  if (!recordVersion) {
    return null;
  }

  return (
    <TrainingContextMenu
      moduleWorkspaceID={moduleWorkspaceID}
      recordVersionID={recordVersion.id}
      tableName={recordVersion.table_name}
      showOnlyIcon={false}
    />
  );
}

export default observer(TrainingActionBar);

import "react-circular-progressbar/dist/styles.css";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import type { RecordVersion } from "@/api";
import type { Filter } from "@/components/filters/types";
import {
  DOCUMENTS_CLOSED_STATUS,
  DOCUMENTS_OPEN_STATUS,
  MODULE_CHARTS,
  POLICIES_CLOSED_STATUS,
  POLICIES_OPEN_STATUS,
  PROCEDURES_CLOSED_STATUS,
  PROCEDURES_OPEN_STATUS,
} from "@/components/reports/constants";
import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import semiCircleShadow from "@/images/semi-circle-with-shadow.svg";

interface Props {
  recordVersions: RecordVersion[];
  moduleWorkspaceID: number;
  filtersViewEnabled: boolean;
  setFiltersViewEnabled: (arg: boolean) => void;
  moduleName: string;
}

interface CountRecords {
  completed_count: number;
  draft_count: number;
}

const ProgressChart = ({
  recordVersions,
  moduleWorkspaceID,
  filtersViewEnabled,
  setFiltersViewEnabled,
  moduleName,
}: Props) => {
  const mainStore = useMainStore();
  const { setTableFiltersParam, getTableFiltersParam } = useUpdateFilter();
  const [countRecords, setCountRecords] = useState<CountRecords>({
    completed_count: 0,
    draft_count: 0,
  });
  const filters = getTableFiltersParam();

  const STATUS_VALUES = {
    closed: "closed",
    open: "open",
  };
  const MODULE_STATUSES = {
    [MODULE_CHARTS.policy]: {
      [STATUS_VALUES.open]: POLICIES_OPEN_STATUS,
      [STATUS_VALUES.closed]: POLICIES_CLOSED_STATUS,
    },
    [MODULE_CHARTS.documents]: {
      [STATUS_VALUES.open]: DOCUMENTS_OPEN_STATUS,
      [STATUS_VALUES.closed]: DOCUMENTS_CLOSED_STATUS,
    },
    [MODULE_CHARTS.procedures]: {
      [STATUS_VALUES.open]: PROCEDURES_OPEN_STATUS,
      [STATUS_VALUES.closed]: PROCEDURES_CLOSED_STATUS,
    },
  };

  // Effects
  useEffect(() => {
    fetchDays();
  }, [recordVersions]);

  async function fetchDays() {
    if (recordVersions) {
      const promises = Object.keys(STATUS_VALUES).map((key) => {
        return mainStore.reports.recordsCountByStatuses(
          moduleWorkspaceID,
          STATUS_VALUES[key as keyof typeof STATUS_VALUES],
        );
      });

      try {
        const results = await Promise.all(promises);

        const resultsObject = {
          completed_count: results[0],
          draft_count: results[1],
        };
        setCountRecords({ ...resultsObject });
      } catch (error) {
        window.console.error("Error:", error);
      }
    }
  }

  const draftDocuments = countRecords.draft_count;
  const completedDocuments = countRecords.completed_count;
  const allDocuments = draftDocuments + completedDocuments;
  const percent = Math.round((completedDocuments / allDocuments) * 100) || 0;

  function addFilter(status: string) {
    let options;
    const currentFiltersWithoutStatus = filters.filter(
      (filter: Filter) => filter.name !== "status",
    );

    if (status === "open") {
      options = MODULE_STATUSES[moduleName].open;
    } else {
      options = MODULE_STATUSES[moduleName].closed;
    }

    return setTableFiltersParam([
      ...currentFiltersWithoutStatus,
      {
        name: "status",
        condition: mainStore.filters.getConditionText(
          "com.askthemis.types.v1.option",
          true,
        ),
        options,
      },
    ]);
  }

  function handleClick(status: string) {
    if (!filtersViewEnabled) {
      setFiltersViewEnabled(true);
    }

    addFilter(status);
  }

  return (
    <div className="progressbar-with-legend">
      <div className="progressbar-with-shadow-container">
        <CircularProgressbar
          circleRatio={0.5}
          value={percent}
          text={`${percent}%`}
          backgroundPadding={1}
          styles={buildStyles({
            rotation: 0.75,
            textColor: "#353549",
            pathColor: "#55BCE7",
            trailColor: "#f62b24",
          })}
        />
        <img src={semiCircleShadow} />
      </div>
      <div className="summary-content">
        <div className="documents-list">
          <div className="record-status" onClick={() => handleClick("closed")}>
            Completed
          </div>
          <p
            className="record-status-count"
            onClick={() => handleClick("closed")}
          >
            {completedDocuments}
          </p>
        </div>
        <div className="documents-list">
          <div className="record-status" onClick={() => handleClick("open")}>
            Draft
          </div>
          <p
            className="record-status-count"
            onClick={() => handleClick("open")}
          >
            {draftDocuments}
          </p>
        </div>
        <div className="documents-list not-active">
          <div className="record-status">Total</div>
          <p className="record-status-count">{allDocuments}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(ProgressChart);

import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";
import * as XLSX from "xlsx";

import { useMainStore } from "@/contexts/Store";

import excel from "../../../../images/excel.svg";
import uploadIcon from "../../../../images/table-image/icon/upload-icon.svg";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAddNewChecklistQuestions?: (...args: any[]) => any;
  moduleWorkspaceID?: number;
}

function ChecklistExcelExport({
  handleAddNewChecklistQuestions,
  moduleWorkspaceID,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const [showPopup, setShowPopup] = useState(false);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleImportChecklistQuestions(event) {
    setShowPopup(false);
    if (!moduleWorkspaceID) {
      return;
    }
    const [file] = event.target.files;
    const fileExtention = file.name.split(".").pop();
    const supportedFileTypes = ["xlsx", "xls", "csv"];
    if (supportedFileTypes.includes(fileExtention)) {
      handleExcelFile(file);
    } else {
      mainStore.toast.setErrorText(
        `${fileExtention} is not a currently supported file type.`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
  function handleExcelFile(file) {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const wb = XLSX.read(e.target.result, { type: "buffer" });
      const ws = wb.Sheets[wb.SheetNames[0]];
      const json_data = XLSX.utils.sheet_to_json(ws, {
        raw: true,
        header: 1,
        blankrows: false,
      });
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      const newListItems = json_data.map((row) => row[0]);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      handleAddNewChecklistQuestions(newListItems);

      mainStore.toast.setInfoText(
        "Items have been imported. Make sure to save your changes.",
      );
    };
  }

  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  return (
    <Popup
      position="bottom right"
      trigger={
        <div className="import-button-container">
          <button className="import-button">
            <img src={excel} alt="download-icon" />
            Import from Excel
          </button>
        </div>
      }
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
    >
      <div className="table-dropdown import-list-dropdown">
        <h3>Format file with a list of questions in the first column</h3>
        <p>XLSX, XLS & CSV files are supported</p>
        <div className="import-blurb">
          No column header is needed. Each row will be imported as a question
          from the first column.
        </div>
        <div className="dropdown-section">
          <div className="import-button-container">
            <button className="import-button">
              <img className="upload-img" src={uploadIcon} alt="upload-icon" />
              Import File
            </button>
            <input
              type="file"
              onChange={(event) => handleImportChecklistQuestions(event)}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default observer(ChecklistExcelExport);

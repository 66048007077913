import type { Review } from "@/api";

import type {
  BaseQuestionnaire,
  Question,
  QuestionChoice,
  QuestionGroup,
  QuestionGroupRead,
  QuestionRead,
} from "./questionnaire";
import { QuestionType } from "./questionnaire";
import type { AnswerReview } from "./review";

interface BaseAnswer {
  id?: number;
  comment: string;
  answer_review?: AnswerReview;
  additional_files: DocumentRead[];
  approved_by?: Review[];
  is_completed?: boolean;
}

export type RadioAnswer = BaseAnswer & {
  question_choice_ids: QuestionChoice["id"][];
};

export function isRadioAnswer(
  answer: Answer | AnswerRead,
): answer is RadioAnswer {
  return answer && "question_choice_ids" in answer;
}

export type TextAnswer = BaseAnswer & {
  value: string;
};

export function isTextAnswer(answer: Answer): answer is TextAnswer {
  return answer && "value" in answer;
}

export type FormQuestionRead = QuestionRead & {
  answer?: AnswerRead;
};

export interface AnswerReviewRequest {
  answer: {
    answer_review_attributes: AnswerReview;
  };
}

interface BaseDocument {
  file_name: string;
}

export type DocumentRead = BaseDocument & {
  id: number;
  created_at: string;
  file_url: string;
  file_size: string;
};

export type DocumentNew = BaseDocument & {
  externalFileId: string;
};

export function isDocumentNew(document: Document): document is DocumentNew {
  return "externalFileId" in document;
}

export type DocumentExisting = DocumentRead & {
  _destroy?: boolean;
};

export type Document = DocumentExisting | DocumentNew;

export type DocumentAnswerRead = BaseAnswer & {
  document_files: DocumentRead[];
};

export function isDocumentAnswerRead(
  answer: AnswerRead,
): answer is DocumentAnswerRead {
  return answer && "document_files" in answer;
}

export type DocumentAnswer = BaseAnswer & {
  documents: Document[];
};

export function isDocumentAnswer(answer: Answer): answer is DocumentAnswer {
  return answer && "documents" in answer;
}

interface DocumentDestroy {
  id: number;
  _destroy: true;
}

type DocumentAnswerUpsert = BaseAnswer & {
  attach_document_files: string[];
  answer_attachment_groups_attributes: DocumentDestroy[];
};

export type Answer = RadioAnswer | TextAnswer | DocumentAnswer;

export type AnswerRead = RadioAnswer | TextAnswer | DocumentAnswerRead;

export type AnswerUpsert = RadioAnswer | TextAnswer | DocumentAnswerUpsert;

export type FormQuestion<TQuestionType extends QuestionType = QuestionType> =
  Omit<Question<TQuestionType>, "triggerQuestions"> & {
    answer: Answer;
    triggerQuestions: FormQuestion[];
  };

export type RadioFormQuestion = FormQuestion<QuestionType.RADIO> & {
  answer: RadioAnswer;
};

export function isRadioFormQuestion(
  formQuestion: Question,
): formQuestion is RadioFormQuestion {
  return [QuestionType.RADIO, QuestionType.YES_NO].includes(
    formQuestion.input_type,
  );
}

export type TextFormQuestion = FormQuestion<QuestionType.YES_NO> & {
  answer: TextAnswer;
};

export function isTextFormQuestion(
  formQuestion: FormQuestion,
): formQuestion is TextFormQuestion {
  return formQuestion.input_type === QuestionType.TEXT;
}

export type DocumentFormQuestion = FormQuestion<QuestionType.DOCUMENTS> & {
  answer: DocumentAnswer;
};

export function isDocumentFormQuestion(
  formQuestion: FormQuestion,
): formQuestion is DocumentFormQuestion {
  return formQuestion.input_type === QuestionType.DOCUMENTS;
}

export interface FormQuestionUpsert {
  id: number;
  answer_attributes: AnswerUpsert;
  position?: number;
}

type BaseQuestionnaireForm = BaseQuestionnaire & {
  company_logo_url: string;
  download_slug: string;
  password_protected: boolean;
};

export type QuestionnaireFormRead = BaseQuestionnaireForm & {
  questions: QuestionRead[];
  question_groups: QuestionGroupRead[];
};

export interface GetQuestionnaireFormReadReponse {
  questionnaire: QuestionnaireFormRead;
}

export type FormQuestionGroup = Omit<QuestionGroup, "questions" | "isNew"> & {
  questions: FormQuestion[];
};

export type QuestionnaireForm = BaseQuestionnaireForm & {
  groups: FormQuestionGroup[];
};

export interface QuestionnaireAnswerPostRequest {
  completed?: boolean;
  questionnaire: {
    questions_attributes: FormQuestionUpsert[];
  };
}

export type AutoSaveOptions = "no changes" | "saving" | "saved" | "failed";

export interface FlattedAnswer {
  id: number;
  answer_attributes: AnswerUpsert;
}

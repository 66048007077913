import type { ColDef } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { MiniTag } from "@themis/ui";
import React from "react";
import { PiChatCircleDotsBold } from "react-icons/pi";
import { generatePath } from "react-router-dom";

import type { Training } from "@/api";
import { TRAINING_DETAILS_PATH } from "@/features/training/pages";

import { ThemisRecordActionsCell } from "../components/ThemisRecordActionsCell";

const recordType = "trainings";

export function trainingTableColumns(workspaceId: number): ColDef<Training>[] {
  return [
    {
      headerName: "Name",
      field: "name",
      editable: false,
      minWidth: 250,
      cellRenderer: "identifierCellRenderer",
      cellRendererParams: (props: CustomCellRendererProps) => {
        const trainingId = props.data.id;
        return {
          to: generatePath(TRAINING_DETAILS_PATH, {
            workspace_id: workspaceId,
            trainingId,
          }),
          ...props,
        };
      },
    },
    {
      headerName: "",
      width: 56,
      sortable: false,
      cellRenderer: "iconButtonCell",
      cellRendererParams: () => {
        return {
          Icon: PiChatCircleDotsBold,
          onClick: () => {
            // TODO: Open comments side panel
          },
          // / TODO: Replace with actual comment count
          // when we find out what property from backend will provide that
          text: "1",
          color: "transparent",
        };
      },
    },
    {
      // TODO: PLACEHOLDER (NEED GENERIC STATUS COMPONENT)
      headerName: "Status",
      field: "status",
      minWidth: 200,
      cellRenderer: (props: CustomCellRendererProps) => {
        return <MiniTag>{props.value}</MiniTag>;
      },
    },
    {
      // TODO: PLACEHOLDER (NEED GENERIC FIELD_OPTION COMPONENT)
      headerName: "Type",
      field: "training_type",
      minWidth: 200,
      cellRenderer: (props: CustomCellRendererProps) => {
        return <MiniTag>{props.value}</MiniTag>;
      },
    },
    {
      headerName: "Notes",
      field: "notes",
      minWidth: 200,
      cellRenderer: "textRenderCell",
    },
    // TODO: Remaining fields

    {
      headerName: "",
      minWidth: 40,
      width: 40,
      sortable: false,
      resizable: false,
      pinned: "right" as const,
      cellStyle: () => {
        return { justifyContent: "center" };
      },
      cellRenderer: (props: CustomCellRendererProps) => {
        return (
          <ThemisRecordActionsCell
            recordType={recordType}
            recordId={props.data.id}
          />
        );
      },
    },
  ];
}

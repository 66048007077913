import type { ICountry, IState } from "country-state-city";
import { Country, State } from "country-state-city";
import { useMemo } from "react";

export const useCountryStateCity = (
  countryIso?: ICountry["isoCode"],
): { countries: ICountry[]; states: IState[] } => {
  const countries = Country.getAllCountries();

  const states = useMemo(() => {
    if (!countryIso) {
      return [];
    }

    return State.getStatesOfCountry(countryIso);
  }, [countries, countryIso]);

  return { countries, states };
};

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListAccountsWithLimitedResponsePathParams,
  ListAccountsWithLimitedResponseQueryResponse,
} from "../../models/ListAccountsWithLimitedResponse";

/**
 * @description List accounts with limited response for dropdowns
 * @summary List accounts with limited response for dropdowns
 * @link /module_workspaces/:module_workspace_id/accounts
 */
export async function listAccountsWithLimitedResponse(
  moduleWorkspaceId: ListAccountsWithLimitedResponsePathParams["module_workspace_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<ListAccountsWithLimitedResponseQueryResponse>["data"]
> {
  const res = await client<ListAccountsWithLimitedResponseQueryResponse>({
    method: "get",
    url: `/module_workspaces/${moduleWorkspaceId}/accounts`,
    ...options,
  });
  return res.data;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";
var CommandEmpty = React.forwardRef(function (props, ref) { return (React.createElement(CommandPrimitive.Empty, __assign({ ref: ref, className: "tw-py-6 tw-text-center tw-font-sans tw-text-sm tw-text-neutral-400" }, props))); });
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;
export { CommandEmpty };

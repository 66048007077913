import { Table } from "@themis/ui";
import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import type { Account, FieldOption } from "@/api";

import AddNewAccount from "../../components/AccountsTable/AddNewAccount";
import { accountTableColumns } from "../../config/accountsTable";

export function ChildAccounts({
  childAccounts,
  accountTypeFieldOptions,
}: {
  childAccounts: Account[];
  accountTypeFieldOptions: FieldOption[];
}) {
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const { url } = useRouteMatch();

  return (
    <div>
      <Table
        columns={accountTableColumns({
          workspaceId: Number(workspace_id),
          accountTypeFieldOptions,
        })}
        rows={childAccounts}
        width="100%"
      />
      <AddNewAccount recordName="Account" to={`${url}/new`} />
    </div>
  );
}

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import type { Field, RecordVersion } from "@/api";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import type { Section } from "@/stores/types/section-tags";

import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import RiskRegisterSection from "./RiskRegisterSection";

interface Props {
  moduleWorkspaceID: number;
  fields?: Field[];
  initialSection?: Section | null;
  recordVersions: RecordVersion[];
  loading: boolean;
  forcedShowNewSectionDetails?: boolean;
}

function RiskRegistersTable({
  fields = [],
  recordVersions = [],
  moduleWorkspaceID,
  initialSection,
  loading,
  forcedShowNewSectionDetails,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const sectionTags = mainStore.sectionTags.orderedList;

  // State
  const [showNewSectionDetails, setShowNewSectionDetails] = useState(false);

  // Effects
  useEffect(() => {
    // Hide "Add New Section" button when on a section
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowNewSectionDetails(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    setShowNewSectionDetails(forcedShowNewSectionDetails === true);
  }, [forcedShowNewSectionDetails]);

  // Variables
  const { canManageSections } = mainStore.userPermissions;
  const currentSection =
    initialSection || mainStore.riskRegisters.currentSection;
  const onAllRisksPage = !currentSection?.id;

  // Functions
  function addNewSectionClick() {
    setShowNewSectionDetails(!showNewSectionDetails);
  }

  const onSectionChange = (recordVersionId: number, sectionId: number) => {
    mainStore.creatives.updateSection(recordVersionId, sectionId);
  };

  return (
    <div
      className="tw-px-4 tw-pb-6 tw-pt-3"
      data-testid="module-sections-container"
    >
      <SectionDragContext onSectionChange={onSectionChange}>
        {sectionTags.map((sectionTag) => {
          // If on a section page (currentSection exists), skip other sections
          if (currentSection?.id && currentSection.id !== sectionTag.id) {
            return null;
          }

          const filteredRecordVersions = recordVersions.filter(
            (recordVersion) => recordVersion.section_tag_id === sectionTag.id,
          );

          return (
            <RiskRegisterSection
              key={sectionTag.id}
              isDropdownOpen
              tableName=""
              fields={fields}
              recordVersions={filteredRecordVersions}
              moduleWorkspaceID={moduleWorkspaceID}
              isDragAndDrop={false}
              sectionTag={sectionTag}
              currentScreen=""
              loading={loading}
              pageID={sectionTag.id}
            />
          );
        })}

        {showNewSectionDetails && (
          <NewSectionTag
            moduleWorkspaceID={moduleWorkspaceID}
            onCancel={() => setShowNewSectionDetails(false)}
          />
        )}

        {fields.length > 0 && onAllRisksPage && canManageSections && (
          <div className="tw-sticky tw-left-[7px] tw-ml-[7px]">
            <AddNewSectionButton
              onClick={addNewSectionClick}
              title="Add Register"
            />
          </div>
        )}
      </SectionDragContext>
    </div>
  );
}

export default observer(RiskRegistersTable);

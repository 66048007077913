import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Pointer = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.15 2.86001C20.7679 2.47057 20.2835 2.19723 19.7526 2.07158C19.2217 1.94594 18.6661 1.97313 18.15 2.15001L3.99999 6.88001C3.4293 7.06681 2.93068 7.42616 2.57294 7.90845C2.21521 8.39073 2.016 8.97218 2.00283 9.57251C1.98966 10.1728 2.16318 10.7625 2.49942 11.26C2.83566 11.7575 3.31804 12.1384 3.87999 12.35L9.11999 14.35C9.23931 14.3956 9.34795 14.4654 9.43913 14.5548C9.53032 14.6443 9.6021 14.7516 9.64999 14.87L11.65 20.12C11.8536 20.674 12.223 21.1518 12.708 21.4882C13.1929 21.8246 13.7698 22.0033 14.36 22H14.43C15.031 21.9891 15.6135 21.7903 16.0959 21.4317C16.5783 21.073 16.9364 20.5724 17.12 20L21.85 5.83001C22.022 5.31895 22.0476 4.76995 21.9239 4.2451C21.8002 3.72024 21.5321 3.24046 21.15 2.86001V2.86001ZM20 5.20001L15.22 19.38C15.1645 19.5595 15.053 19.7165 14.9019 19.828C14.7507 19.9396 14.5678 19.9999 14.38 20C14.1932 20.0031 14.01 19.9492 13.8546 19.8456C13.6992 19.742 13.579 19.5936 13.51 19.42L11.51 14.17C11.365 13.7885 11.1414 13.4418 10.8537 13.1524C10.566 12.863 10.2206 12.6373 9.83999 12.49L4.58999 10.49C4.41288 10.4251 4.26064 10.3062 4.15477 10.1501C4.0489 9.99399 3.99476 9.80856 3.99999 9.62001C4.00014 9.43215 4.0604 9.24928 4.17196 9.09813C4.28352 8.94699 4.44052 8.83551 4.61999 8.78001L18.8 4.05001C18.9628 3.98366 19.1413 3.96588 19.314 3.99883C19.4867 4.03179 19.6462 4.11406 19.7731 4.2357C19.9 4.35734 19.989 4.51314 20.0293 4.68427C20.0695 4.8554 20.0594 5.03453 20 5.20001V5.20001Z"
      fill="#353549"
    />
  </svg>
);

Pointer.displayName = "PointerIcon";

export default Pointer;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ControlMappingsLibraryQueryParams,
  ControlMappingsLibraryQueryResponse,
} from "../../models/ControlMappingsLibrary";

/**
 * @description Control Mappings Library
 * @summary Control Mappings Library
 * @link /control_mappings/library
 */
export async function controlMappingsLibrary(
  params?: ControlMappingsLibraryQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ControlMappingsLibraryQueryResponse>["data"]> {
  const res = await client<ControlMappingsLibraryQueryResponse>({
    method: "get",
    url: `/control_mappings/library`,
    params,
    ...options,
  });
  return res.data;
}

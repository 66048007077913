import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import arrowUp from "../../../images/table-image/icon/arrow-up.svg";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";

const IGNORED_FIELD_NAMES = [
  "deactivate_date",
  "business_name",
  "type",
  "company_contact",
];

interface Props {
  moduleWorkspaceID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any[];
  isActiveScreen?: boolean;
  sectionId?: string;
  title?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vendors?: any[];
  canCreateNewVendors: boolean;
}

function VendorsTable({
  vendors,
  fields,
  moduleWorkspaceID,
  title,
  isActiveScreen,
  sectionId,
  canCreateNewVendors,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // States
  const [isOpened, setIsOpened] = useState(true);
  const [selectMode, setSelectMode] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  // Variables
  const history = useHistory();
  const tableName = isActiveScreen ? "Active" : "Inactive";
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderedFields = fields.filter(
    (field) => !IGNORED_FIELD_NAMES.includes(field.name),
  );
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const { workspaceID } = mainStore.context;
  const sectionTableID = `section-table-${sectionId || 0}`;
  const addNewDefaultPath = `/workspaces/${workspaceID}/modules/add-new-vendor`;
  const addNewPath = sectionId
    ? `${addNewDefaultPath}?grouping=${sectionId}`
    : addNewDefaultPath;

  // Hooks
  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });

  const {
    sortByFieldName,
    sortByDirection,
    setSortByDirection,
    setSortByFieldName,
    setSortBy,
  } = useSortingAndPagination({ store: mainStore.vendors });

  // Functions
  const createNewVendor = () => {
    if (showCheckbox && selectMode) {
      return;
    }

    history.push(addNewPath);
  };

  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const toggleDropdown = () => setIsOpened((prevState) => !prevState);

  // Elements
  const renderFields = renderedFields.map((field) => {
    return (
      <ModuleTableColumn
        key={field.name}
        field={field}
        setSortBy={setSortBy}
        // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
        sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
        setSortByDirection={setSortByDirection}
        setSortByFieldName={setSortByFieldName}
        onDelete={deleteColumn}
        onRename={renameColumn}
      />
    );
  });

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderRows = vendors.map((vendor) => (
    <ModuleRecordVersion
      key={vendor.id}
      moduleWorkspaceID={moduleWorkspaceID}
      fields={renderedFields}
      recordVersion={vendor}
      tableID={vendor.table_id}
      tableName={vendor.table_name}
      moduleIdentifier="vendor_due_diligence"
      currentTableName={tableName}
      inSelectMenuActionMode={selectMode}
    />
  ));

  return (
    <div className="vdd-section">
      {title && (
        <div
          className="vdd-table-title-container"
          data-testid="vdd-table-header"
        >
          <div className="vdd-title">
            <div className="vdd-arrow-button" onClick={toggleDropdown}>
              <img src={arrowUp} alt="arrow" />
            </div>
            {title}
          </div>
        </div>
      )}
      {isOpened && (
        <div
          className="vdd-content inactive-table"
          data-testid="vdd-table-container"
          id={sectionTableID}
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="title-first-block table-column-reorder" />
                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              {!(showCheckbox && selectMode) && isActiveScreen ? (
                <AddColumnButton onAdd={createColumn} />
              ) : (
                <div className="vdd-header-ending" />
              )}
            </ul>
          </div>
          {renderRows}
          {isActiveScreen && canCreateNewVendors && (
            <div
              className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
              onClick={createNewVendor}
              data-testid="vdd-table-button"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">
                Add{" "}
                {getRecordName("vendor_due_diligence", moduleWorkspaces, true)}
                ...
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

VendorsTable.defaultProps = {
  isActiveScreen: false,
  vendors: [],
};

export default observer(VendorsTable);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListModuleAssessmentsPathParams,
  ListModuleAssessmentsQueryParams,
  ListModuleAssessmentsQueryResponse,
} from "../../models/ListModuleAssessments";

/**
 * @description List record Module Assessments
 * @summary List record Module Assessments
 * @link /record_versions/:record_version_id/module_assessments
 */
export async function listModuleAssessments(
  recordVersionId: ListModuleAssessmentsPathParams["record_version_id"],
  params?: ListModuleAssessmentsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListModuleAssessmentsQueryResponse>["data"]> {
  const res = await client<ListModuleAssessmentsQueryResponse>({
    method: "get",
    url: `/record_versions/${recordVersionId}/module_assessments`,
    params,
    ...options,
  });
  return res.data;
}

import type { CustomTooltipProps } from "@ag-grid-community/react";
import { TableTooltipContainer } from "@themis/ui";
import React from "react";

import type { Account, FieldOption } from "@/api";

import { AccountTypeTagsList } from "./account-type-tags/account-type-tags-list";

export function AccountTypeCellTooltip({
  value,
  accountTypeFieldOptions,
}: CustomTooltipProps<Account, Account["account_types"]> & {
  accountTypeFieldOptions: FieldOption[];
}) {
  if (!value || value.length < 3) {
    return null;
  }

  return (
    <TableTooltipContainer>
      <div className="tw-flex tw-flex-col tw-gap-1">
        <AccountTypeTagsList
          isInTooltip
          types={value.slice(2)}
          accountTypeFieldOptions={accountTypeFieldOptions}
        />
      </div>
    </TableTooltipContainer>
  );
}

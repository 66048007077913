import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Photo1 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H19ZM20 12.302L15.8321 18.5547C15.5243 19.0163 14.9063 19.1338 14.454 18.838L14.3598 18.7682L9.1875 14.458L5.228 20H19C19.5523 20 20 19.5523 20 19V12.302ZM19 4H5C4.44772 4 4 4.44772 4 5V18.278L8.18627 12.4188C8.50017 11.9793 9.09949 11.8737 9.54124 12.159L9.64018 12.2318L14.7853 16.5193L20 8.696V5C20 4.44772 19.5523 4 19 4ZM8 6C9.10457 6 10 6.89543 10 8C10 9.10457 9.10457 10 8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6Z"
      fill="#353549"
    />
  </svg>
);

Photo1.displayName = "Photo1Icon";

export default Photo1;

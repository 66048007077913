import type { ValueLabelOption } from "@/stores/types/field-types";
import { DAYS_OF_WEEK } from "@/stores/types/notification-rule-types";

import type { DateNotificationFormValues } from "./types";

export const initialCreateValues: DateNotificationFormValues = {
  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'RELATIVE_TO... Remove this comment to see the full error message
  condition: null,
  criteria: [{ fieldName: "", fieldValues: [] }],
  enabled: true,
  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'NOTIFICATIO... Remove this comment to see the full error message
  frequency: null,
  recipients: { columns: [], department_ids: [], user_ids: [] },
  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'NOTIFICATIO... Remove this comment to see the full error message
  type: null,
  timePeriod: {
    numberOfOneTimeDays: [],
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number'.
    numberOfDailyDays: null,
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number'.
    numberOfWeeks: null,
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number'.
    numberOfMonths: null,
    excludeWeekends: true,
    recurringWeekdays: [],
  },
  triggerColumn: "",
};

export const numberOfDaysOptions = (() => {
  const options: ValueLabelOption<number>[] = [];

  for (let i = 1; i <= 10; i++) {
    options.push({ value: i, label: String(i) });
  }

  return options;
})();

export const numberOfDaysOptionsForUnlock = [
  { value: 15, label: `15 Days` },
  { value: 30, label: `30 Days` },
  { value: 60, label: `60 Days` },
  { value: 90, label: `90 Days` },
];

export const numberOfWeeksOptions = [
  {
    value: 1,
    label: "1 Week",
  },
  {
    value: 2,
    label: "2 Weeks",
  },
  {
    value: 3,
    label: "3 Weeks",
  },
  {
    value: 4,
    label: "4 Weeks",
  },
];

export const numberOfMonthsOptions = [
  {
    value: 1,
    label: "1 Month",
  },
  {
    value: 2,
    label: "2 Months",
  },
  {
    value: 3,
    label: "3 Months",
  },
  {
    value: 4,
    label: "4 Months",
  },
  {
    value: 5,
    label: "5 Months",
  },
  {
    value: 6,
    label: "6 Months",
  },
  {
    value: 7,
    label: "7 Months",
  },
  {
    value: 8,
    label: "8 Months",
  },
  {
    value: 9,
    label: "9 Months",
  },
  {
    value: 10,
    label: "10 Months",
  },
  {
    value: 11,
    label: "11 Months",
  },
  {
    value: 12,
    label: "12 Months",
  },
];

export const daysOfTheWeekOptions = [
  {
    value: DAYS_OF_WEEK.MONDAY,
    label: "MO",
  },
  {
    value: DAYS_OF_WEEK.TUESDAY,
    label: "TU",
  },
  {
    value: DAYS_OF_WEEK.WEDNESDAY,
    label: "WE",
  },
  {
    value: DAYS_OF_WEEK.THURSDAY,
    label: "TH",
  },
  {
    value: DAYS_OF_WEEK.FRIDAY,
    label: "FR",
  },
];

import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { cva } from "cva";
import React, { forwardRef, useEffect, useRef, useState, } from "react";
import { createPortal } from "react-dom";
import { ImAttachment } from "react-icons/im";
import { LuFilePlus2 } from "react-icons/lu";
import { PiArrowsCounterClockwiseBold, PiDownloadSimpleBold, PiSpinnerBold, PiTrashSimpleBold, } from "react-icons/pi";
import { cn, handleDownload } from "../../lib/utils";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import { FileViewer } from "../FileViewer/FileViewer";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import { Tooltip, TooltipContent, TooltipProvider } from "../Tooltip/Tooltip";
var readOnlyStyles = "tw-bg-primaryDim-25 focus-visible:tw-border-neutral-100 focus:tw-border-transparent focus:tw-ring-0 tw-text-neutral-300";
var lockedStyles = "tw-bg-primary-25 focus-visible:tw-border-neutral-100 focus:tw-border-transparent read-only:focus:tw-ring-0 tw-text-primaryDim-300";
var filePickerInputVariants = cva({
    base: [
        "tw-box-border tw-inline-flex tw-w-full tw-pl-3 tw-pr-1 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-transition-colors",
        "tw-text-sm tw-text-neutral-200 tw-font-semibold tw-font-sans",
        "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300",
    ],
    variants: {
        size: {
            sm: "tw-h-7",
            md: "tw-h-8",
            lg: "tw-h-9",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});
var Loading = function (_a) {
    var percentage = _a.percentage;
    return (React.createElement("div", { className: "tw-flex tw-h-full tw-items-center tw-justify-center" },
        React.createElement("span", { className: "tw-w-6" }, percentage),
        React.createElement(PiSpinnerBold, { className: "tw-h-6 tw-w-6 tw-animate-spin tw-text-primary-300" })));
};
var Dropzone = function (_a) {
    var onSelect = _a.onSelect;
    var inputFileRef = useRef(null);
    var _b = useState(false), dragging = _b[0], setDragging = _b[1];
    var handleFileChange = function (event) {
        var _a, _b;
        if (!((_a = event.target.files) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        onSelect((_b = event.target.files) === null || _b === void 0 ? void 0 : _b[0]);
    };
    var handleFileDrop = function (event) {
        var _a;
        event.preventDefault();
        setDragging(false);
        if (!event.dataTransfer.files) {
            return;
        }
        onSelect((_a = event.dataTransfer.files) === null || _a === void 0 ? void 0 : _a[0]);
    };
    var handleDragOver = function (event) {
        event.preventDefault();
        setDragging(true);
    };
    var onClickSelect = function () {
        var _a;
        (_a = inputFileRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (React.createElement("div", { className: cn("tw-box-border tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-transition-colors", {
            "tw-rounded-md tw-border-solid tw-border-secondary-300 tw-bg-secondary-50 tw-text-neutral-500": dragging,
        }), onDrop: handleFileDrop, onDragOver: handleDragOver, onDragExit: function () { return setDragging(false); } },
        React.createElement(LuFilePlus2, { className: "tw-h-6 tw-w-6" }),
        React.createElement("div", { className: "tw-text-center tw-font-sans tw-text-sm" },
            dragging ? React.createElement("p", null, "Drop file here") : React.createElement("p", null, "Drag & drop into this box"),
            !dragging && React.createElement("p", null, "- or -")),
        !dragging && (React.createElement(Button, { className: "tw-mt-1", onClick: onClickSelect }, "Select File")),
        React.createElement("input", { hidden: true, type: "file", ref: inputFileRef, onChange: handleFileChange })));
};
var FilePicker = forwardRef(function (_a, ref) {
    var _b;
    var size = _a.size, locked = _a.locked, readOnly = _a.readOnly, _c = _a.placeholder, placeholder = _c === void 0 ? "- Add Attachment -" : _c, file = _a.file, _d = _a.onSelectFile, onSelectFile = _d === void 0 ? function () { } : _d, _e = _a.onRemoveFile, onRemoveFile = _e === void 0 ? function () { } : _e, isLoading = _a.isLoading, className = _a.className, _f = _a.percentage, percentage = _f === void 0 ? 0 : _f, trigger = _a.trigger, _g = _a.alignPopover, alignPopover = _g === void 0 ? "start" : _g, isOpen = _a.isOpen, onOpenChange = _a.onOpenChange, _h = _a.backwardCompatible, backwardCompatible = _h === void 0 ? false : _h;
    var disabled = locked || readOnly;
    var _j = useState(false), isViewerVisible = _j[0], setViewerVisible = _j[1];
    var _k = useState(false), showTooltip = _k[0], setShowTooltip = _k[1];
    var textRef = useRef(null);
    null;
    var isRemoteFile = file && "url" in file;
    var isContainerTrigger = trigger || !file;
    var containerClasses = cn(filePickerInputVariants({ size: size }), (_b = {
            "tw-border-neutral-100": !isOpen,
            "tw-border-primary-300": isOpen,
            "tw-border-none tw-p-0 tw-bg-transparent": backwardCompatible,
            "tw-text-secondary-200": file && !disabled
        },
        _b[lockedStyles] = locked,
        _b[readOnlyStyles] = readOnly,
        _b));
    var handleFileSelected = function (selectedFile) {
        onSelectFile(selectedFile);
    };
    useEffect(function () {
        if (textRef.current) {
            setShowTooltip(textRef.current.scrollWidth > textRef.current.clientWidth);
        }
    }, [file]);
    var renderTrigger = function () {
        if (trigger) {
            return trigger;
        }
        return (React.createElement("button", { "aria-expanded": isOpen, className: cn(containerClasses, className) },
            React.createElement("div", { className: cn("tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap") }, (file === null || file === void 0 ? void 0 : file.name) || placeholder),
            React.createElement(ImAttachment, { className: cn("tw-ml-auto tw-mr-2 tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-500 tw-transition-transform", {
                    "tw-text-neutral-200": disabled,
                }) })));
    };
    var renderContainerEl = function () {
        if (isContainerTrigger) {
            return (React.createElement(PopoverTrigger, { disabled: disabled, className: className, ref: ref, asChild: true }, renderTrigger()));
        }
        return (React.createElement("div", { "aria-expanded": isOpen, className: cn(containerClasses, className, {
                "tw-bg-transparent": backwardCompatible,
            }) },
            React.createElement(TooltipProvider, null,
                React.createElement(Tooltip, null,
                    React.createElement(TooltipTrigger, { asChild: true },
                        React.createElement("div", { ref: textRef, className: cn("tw-cursor-pointer tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap", {
                                "tw-text-primaryDim-300": backwardCompatible,
                                "tw-cursor-pointer": file.name,
                            }), onClick: function () {
                                if (file) {
                                    setViewerVisible(true);
                                }
                            } }, file.name || placeholder)),
                    showTooltip && file.name && (React.createElement(TooltipContent, { side: "bottom", className: "tw-box-border tw-inline-flex tw-w-72 tw-flex-col tw-items-start tw-gap-1 tw-px-6 tw-py-4" },
                        React.createElement("p", { className: "tw-break-all tw-text-sm tw-text-white" }, file.name))))),
            React.createElement("div", { className: "tw-ml-auto tw-flex tw-flex-row" },
                React.createElement(IconButton, { onClick: function () {
                        return handleDownload(isRemoteFile ? file.url : URL.createObjectURL(file), file.name);
                    }, Icon: function () { return (React.createElement(PiDownloadSimpleBold, { className: cn({
                            "tw-fill-primaryDim-300": backwardCompatible,
                        }) })); }, color: "transparent", size: "lg" }),
                !disabled && (React.createElement(React.Fragment, null,
                    React.createElement(PopoverTrigger, { asChild: true },
                        React.createElement(IconButton, { ref: ref, Icon: PiArrowsCounterClockwiseBold, color: "transparent", size: "lg" })),
                    React.createElement(IconButton, { onClick: onRemoveFile, Icon: PiTrashSimpleBold, color: "transparent", size: "lg" }))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Popover, { open: isOpen, onOpenChange: onOpenChange },
            renderContainerEl(),
            React.createElement(PopoverContent, { className: cn("tw-h-52 tw-w-80 tw-p-2 tw-font-sans"), align: isContainerTrigger ? alignPopover : "end" },
                React.createElement("div", { className: "tw-h-full tw-rounded-md tw-bg-neutral-50 tw-text-neutral-300" }, isLoading ? (React.createElement(Loading, { percentage: percentage })) : (React.createElement(Dropzone, { onSelect: handleFileSelected }))))),
        file &&
            isViewerVisible &&
            createPortal(React.createElement(FileViewer, { file: isRemoteFile ? file.url : file, type: file.type, onClose: function () { return setViewerVisible(false); } }), document.body)));
});
FilePicker.displayName = "FilePicker";
export { FilePicker };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cva } from "cva";
import React from "react";
import { cn } from "../../lib/utils";
function idToColor(id) {
    return [
        "primary",
        "lavender",
        "navy",
        "purple",
        "fuchsia",
        "pink",
        "red",
        "orange",
        "yellow",
        "green",
        "mint",
        "secondary",
        "turquoise",
        "blue",
        "black",
        "grey",
    ][id % 16];
}
var defaultStyles = "tw-rounded-3xl tw-box-border tw-font-semibold tw-font-sans tw-inline-flex tw-gap-1 tw-items-center tw-justify-center tw-whitespace-nowrap";
var miniTagVariants = cva({
    base: [defaultStyles],
    variants: {
        color: {
            primary: "",
            lavender: "",
            navy: "",
            purple: "",
            fuchsia: "",
            pink: "",
            red: "",
            orange: "",
            yellow: "",
            green: "",
            mint: "",
            secondary: "",
            turquoise: "",
            blue: "",
            black: "",
            grey: "",
        },
        variant: {
            default: "",
            outline: "tw-border tw-border-solid",
            solid: "tw-text-neutral-25",
        },
        size: {
            sm: "tw-px-1.5 tw-py-0.5 tw-text-xs",
            md: "tw-px-1.5 tw-py-0.5 tw-text-xs",
            lg: "tw-px-2 tw-py-1 tw-text-sm",
        },
    },
    compoundVariants: [
        {
            color: "primary",
            variant: "default",
            className: "tw-bg-primary-50 tw-text-primaryDim-400",
        },
        {
            color: "primary",
            variant: "outline",
            className: "tw-border-primaryDim-400 tw-text-primaryDim-400",
        },
        {
            color: "primary",
            variant: "solid",
            className: "tw-bg-primaryDim-400",
        },
        {
            color: "lavender",
            variant: "default",
            className: "tw-bg-primary-25 tw-text-primary-200",
        },
        {
            color: "lavender",
            variant: "outline",
            className: "tw-border-primary-200 tw-text-primary-200",
        },
        {
            color: "lavender",
            variant: "solid",
            className: "tw-bg-primary-200",
        },
        {
            color: "navy",
            variant: "default",
            className: "tw-bg-blue-1 tw-text-blue-4",
        },
        {
            color: "navy",
            variant: "outline",
            className: "tw-border-blue-4 tw-text-blue-4",
        },
        {
            color: "navy",
            variant: "solid",
            className: "tw-bg-blue-4",
        },
        {
            color: "purple",
            variant: "default",
            className: "tw-bg-purple-1 tw-text-purple-3",
        },
        {
            color: "purple",
            variant: "outline",
            className: "tw-border-purple-3 tw-text-purple-3",
        },
        {
            color: "purple",
            variant: "solid",
            className: "tw-bg-purple-3",
        },
        {
            color: "fuchsia",
            variant: "default",
            className: "tw-bg-fuchsia-1 tw-text-fuchsia-3",
        },
        {
            color: "fuchsia",
            variant: "outline",
            className: "tw-border-fuchsia-3 tw-text-fuchsia-3",
        },
        {
            color: "fuchsia",
            variant: "solid",
            className: "tw-bg-fuchsia-3",
        },
        {
            color: "pink",
            variant: "default",
            className: "tw-bg-warning-25 tw-text-warning-200",
        },
        {
            color: "pink",
            variant: "outline",
            className: "tw-border-warning-200 tw-text-warning-200",
        },
        {
            color: "pink",
            variant: "solid",
            className: "tw-bg-warning-200",
        },
        {
            color: "red",
            variant: "default",
            className: "tw-bg-warning-100 tw-text-warning-300",
        },
        {
            color: "red",
            variant: "outline",
            className: "tw-border-warning-300 tw-text-warning-300",
        },
        {
            color: "red",
            variant: "solid",
            className: "tw-bg-warning-300",
        },
        {
            color: "orange",
            variant: "default",
            className: "tw-bg-orange-1 tw-text-orange-3",
        },
        {
            color: "orange",
            variant: "outline",
            className: "tw-border-orange-3 tw-text-orange-3",
        },
        {
            color: "orange",
            variant: "solid",
            className: "tw-bg-orange-3",
        },
        {
            color: "yellow",
            variant: "default",
            className: "tw-bg-yellow-1 tw-text-yellow-4",
        },
        {
            color: "yellow",
            variant: "outline",
            className: "tw-border-yellow-4 tw-text-yellow-4",
        },
        {
            color: "yellow",
            variant: "solid",
            className: "tw-bg-yellow-4",
        },
        {
            color: "green",
            variant: "default",
            className: "tw-bg-green-1 tw-text-green-3",
        },
        {
            color: "green",
            variant: "outline",
            className: "tw-border-green-3 tw-text-green-3",
        },
        {
            color: "green",
            variant: "solid",
            className: "tw-bg-green-3",
        },
        {
            color: "mint",
            variant: "default",
            className: "tw-bg-mint-1 tw-text-mint-3",
        },
        {
            color: "mint",
            variant: "outline",
            className: "tw-border-mint-3 tw-text-mint-3",
        },
        {
            color: "mint",
            variant: "solid",
            className: "tw-bg-mint-3",
        },
        {
            color: "secondary",
            variant: "default",
            className: "tw-bg-secondary-50 tw-text-secondary-400",
        },
        {
            color: "secondary",
            variant: "outline",
            className: "tw-border-secondary-400 tw-text-secondary-400",
        },
        {
            color: "secondary",
            variant: "solid",
            className: "tw-bg-secondary-400",
        },
        {
            color: "turquoise",
            variant: "default",
            className: "tw-bg-secondary-25 tw-text-secondary-200",
        },
        {
            color: "turquoise",
            variant: "outline",
            className: "tw-border-secondary-200 tw-text-secondary-200",
        },
        {
            color: "turquoise",
            variant: "solid",
            className: "tw-bg-secondary-200",
        },
        {
            color: "blue",
            variant: "default",
            className: "tw-bg-blue-1 tw-text-blue-3",
        },
        {
            color: "blue",
            variant: "outline",
            className: "tw-border-blue-3 tw-text-blue-3",
        },
        {
            color: "blue",
            variant: "solid",
            className: "tw-bg-blue-3",
        },
        {
            color: "black",
            variant: "default",
            className: "tw-bg-neutral-100 tw-text-neutral-500",
        },
        {
            color: "black",
            variant: "outline",
            className: "tw-border-neutral-400 tw-text-neutral-500",
        },
        {
            color: "black",
            variant: "solid",
            className: "tw-bg-neutral-400",
        },
        {
            color: "grey",
            variant: "default",
            className: "tw-bg-neutral-100 tw-text-neutral-300",
        },
        {
            color: "grey",
            variant: "outline",
            className: "tw-border-neutral-300 tw-text-neutral-300",
        },
        {
            color: "grey",
            variant: "solid",
            className: "tw-bg-neutral-300",
        },
    ],
    defaultVariants: {
        color: "primary",
        variant: "solid",
        size: "md",
    },
});
var MiniTag = React.forwardRef(function (_a, ref) {
    var className = _a.className, variant = _a.variant, size = _a.size, color = _a.color, colorIndex = _a.colorIndex, children = _a.children, LeftIcon = _a.LeftIcon, RightIcon = _a.RightIcon, props = __rest(_a, ["className", "variant", "size", "color", "colorIndex", "children", "LeftIcon", "RightIcon"]);
    var tagColor = typeof colorIndex === "number" ? idToColor(colorIndex) : color;
    return (React.createElement("div", __assign({ className: cn(miniTagVariants({ variant: variant, size: size, className: className, color: tagColor })), ref: ref }, props),
        LeftIcon && React.createElement(LeftIcon, { className: "tw-h-4 tw-w-4" }),
        children,
        RightIcon && React.createElement(RightIcon, { className: "tw-h-4 tw-w-4" })));
});
MiniTag.displayName = "MiniTag";
export { MiniTag, miniTagVariants };

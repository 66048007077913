import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import RegularLibraryTaxonomySelect from "../shared/RegularLibraryTaxonomySelect";
import RegularTextareaInput from "../shared/RegularTextareaInput";
import RegularTextInput from "../shared/RegularTextInput";

function LibraryItemDetailView() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'item_id' does not exist on type '{}'.
  const { item_id } = useParams();
  const { libraryRecords, libraryItem } = mainStore.riskRegisters;

  // effects
  useEffect(() => {
    if (!item_id) {
      return;
    }
    mainStore.riskRegisters.setLibraryItem(
      libraryRecords.find((item) => item.id === parseInt(item_id, 10)),
    );
  }, [item_id]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function handleSave(value, field) {
    mainStore.riskRegisters.setLibraryItem({ ...libraryItem, [field]: value });
  }

  // elements
  const renderTabs = (
    <div className="settings-links-wrap">
      <ul>
        <li>
          <NavLink to="">Record View</NavLink>
        </li>
      </ul>
    </div>
  );

  return (
    <div
      data-testid="detail-view rr-library-detail-view"
      className="rr-library-detail-view"
    >
      {renderTabs}
      <div className="detail-view template">
        <section>
          <div className="section-row two-cols">
            <div className="column column-input" role="custom-column">
              <h4>L1 Risk Taxonomy</h4>
              <RegularLibraryTaxonomySelect
                storeName="riskTypes"
                selectedOptions={
                  libraryItem.risk_type?.id ? [libraryItem.risk_type.id] : []
                }
                columnTitle="L1 Risk Taxonomy"
                handleOnClose={(selectedItems) =>
                  handleSave({ id: selectedItems[0] }, "risk_type")
                }
              />
            </div>
            <div className="column column-input" role="custom-column">
              <h4>L2 Risk Taxonomy</h4>
              <RegularLibraryTaxonomySelect
                storeName="mainCategories"
                selectedOptions={
                  libraryItem.main_category?.id
                    ? [libraryItem.main_category.id]
                    : []
                }
                columnTitle="L2 Risk Taxonomy"
                handleOnClose={(selectedItems) =>
                  handleSave({ id: selectedItems[0] }, "main_category")
                }
                scope={libraryItem.risk_type?.id}
                emptyScopeText="Please select L1 Risk Taxonomy"
              />
            </div>
            <div className="column column-input" role="custom-column">
              <h4>Framework</h4>
              <RegularLibraryTaxonomySelect
                storeName="tags"
                selectedOptions={
                  libraryItem.tags?.id
                    ? libraryItem.tags?.id
                    : // @ts-expect-error TS(2339) FIXME: Property 'frameworks' does not exist on type 'Libr... Remove this comment to see the full error message
                      libraryItem?.frameworks?.map((item) => item.id)
                }
                columnTitle="tags"
                handleOnClose={(selectedItems) =>
                  handleSave({ id: selectedItems }, "tags")
                }
                isMultiSelect
                scope={libraryItem.risk_type?.id}
                emptyScopeText="Please select Framework"
              />
            </div>

            <div className="column column-input" role="custom-column">
              <h4>Risk Events</h4>
              <RegularTextInput
                initialValue={libraryItem.risk_events}
                width="100%"
                handleOnBlur={(value) => handleSave(value, "risk_events")}
              />
            </div>
            <div className="column column-textarea" role="custom-column">
              <h4>Description</h4>
              <RegularTextareaInput
                initialValue={libraryItem.description}
                width="100%"
                handleOnBlur={(value) => handleSave(value, "description")}
              />
            </div>
            <div className="column column-textarea" role="custom-column">
              <h4>Reference</h4>
              <RegularTextareaInput
                initialValue={libraryItem.reference}
                width="100%"
                handleOnBlur={(value) => handleSave(value, "reference")}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default observer(LibraryItemDetailView);

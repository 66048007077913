import type {
  ChecklistQuestion,
  VendorQuestionnaireFile,
} from "@/stores/types/vendor-types";

export function isQuestionAnswered(cq?: ChecklistQuestion): boolean {
  return Boolean(
    cq?.answer || cq?.comment || (cq?.file as VendorQuestionnaireFile)?.id,
  );
}

export function canCompleteChecklist(
  checklistQuestions: ChecklistQuestion[],
): boolean {
  return checklistQuestions.every(isQuestionAnswered);
}

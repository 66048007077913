import axios from "axios";
import { action, makeObservable, observable } from "mobx";

export default class Clearbit {
  mainStore;

  // Observable objects
  suggestions = [];
  currentLogo = {};

  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  constructor(mainStore) {
    makeObservable(this, {
      suggestions: observable,
      currentLogo: observable,
      setSuggestions: action,
      setCurrentLogo: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'domain' implicitly has an 'any' type.
  async fetchSuggestedLogos(domain) {
    if (!domain) {
      return;
    }

    try {
      const result = await axios({
        method: "GET",
        url: `https://autocomplete.clearbit.com/v1/companies/suggest?query=${domain}`,
      });

      this.setSuggestions(result.data);
    } catch (error) {
      window.console.log(`clearbit: Logo not found for ${domain}`, error);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'domain' implicitly has an 'any' type.
  async fetchLogo(domain) {
    if (!domain) {
      return;
    }

    try {
      const result = await axios({
        method: "GET",
        url: `https://logo.clearbit.com/${domain}?size=512`,
      });

      this.setCurrentLogo(result);
    } catch (error) {
      window.console.log(`clearbit: Logo not found for ${domain}`, error);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSuggestions(value) {
    if (value) {
      this.suggestions = value;
    } else {
      this.suggestions = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentLogo(value) {
    if (value) {
      this.currentLogo = value;
    } else {
      this.currentLogo = {};
    }
  }

  // Store Helpers

  cleanup() {
    this.setSuggestions([]);
    this.setCurrentLogo({});
  }
}

import {
  DragDropContext,
  Draggable,
  Droppable,
} from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { getIndexByPosition } from "@/utils/utils";

import menuInactiveIcon from "../../../images/table-image/icon/menu-black.svg";
import menuActiveIcon from "../../../images/table-image/icon/menu.svg";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deactivatedFields?: any[];
  isSubModule?: boolean;
  renderContentOnly?: boolean;
}

const SectionReorderPopup = ({ renderContentOnly }: Props) => {
  // MobX Store
  const mainStore = useMainStore();

  // Variables
  const { isAdmin } = mainStore.context;
  const sectionTags = mainStore.sectionTags.orderedList;

  // State
  const [showPopup, setShowPopup] = useState(false);

  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTag' implicitly has an 'any' typ... Remove this comment to see the full error message
  const renderDraggableSectionTag = (sectionTag) => (
    <Draggable
      key={sectionTag.title}
      draggableId={`draggable-${sectionTag.id}`}
      index={sectionTag.position}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {renderSectionTag(sectionTag)}
        </div>
      )}
    </Draggable>
  );
  const renderSectionTags = sectionTags.map(renderDraggableSectionTag);

  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTag' implicitly has an 'any' typ... Remove this comment to see the full error message
  const renderSectionTag = (sectionTag) => (
    <div className="table-column-row" data-testid="table-column-row">
      <div title={sectionTag.title} className="table-column-row-name">
        {sectionTag.title}
      </div>
    </div>
  );
  // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
  const onDragEnd = async (result) => {
    const { destination, source } = result;
    const sourceIndex = getIndexByPosition(
      sectionTags,
      "position",
      source.index,
    );
    const destinationIndex = getIndexByPosition(
      sectionTags,
      "position",
      destination.index,
    );
    const sectionTagID = sectionTags[sourceIndex].id;
    const followingSectionTag =
      // eslint-disable-next-line no-nested-ternary
      destinationIndex < sourceIndex
        ? sectionTags[destinationIndex]
        : destinationIndex === sectionTags.length - 1
        ? null
        : sectionTags[destinationIndex + 1];

    const precedingSectionTag =
      // eslint-disable-next-line no-nested-ternary
      destinationIndex < sourceIndex
        ? destinationIndex === 0
          ? null
          : sectionTags[destinationIndex - 1]
        : sectionTags[destinationIndex];

    await mainStore.sectionTags.reorder({
      sectionTagID,
      preceding_section_tag_id: precedingSectionTag?.id,
      following_section_tag_id: followingSectionTag?.id,
    });
  };

  const renderContent = () => (
    <div
      className="table-dropdown table-column-reorder-popup"
      data-testid="section-tag-reorder-popup"
    >
      <div className="reorder-help">
        Drag and drop sections below to reorder. The top level base section
        cannot be moved.
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="table-column-reorder-content"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <div>Sections</div>
              {renderSectionTags}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );

  if (renderContentOnly) {
    return renderContent();
  }
  return (
    <Popup
      position="bottom left"
      open={showPopup}
      onOpen={() => {
        setShowPopup(true);
      }}
      onClose={() => {
        setShowPopup(false);
      }}
      trigger={
        <button
          className={classNames("table-column-reorder-trigger", {
            active: showPopup,
            hidden: !isAdmin,
          })}
        >
          <img
            src={showPopup ? menuActiveIcon : menuInactiveIcon}
            alt="lightbulb-icon"
          />
        </button>
      }
      keepTooltipInside
      arrow={false}
    >
      {renderContent()}
    </Popup>
  );
};

SectionReorderPopup.defaultProps = {
  renderContentOnly: false,
  isSubModule: false,
  deactivatedFields: [],
};

export default SectionReorderPopup;

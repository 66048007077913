import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/check-icon.svg";
import deleteIcon from "../../../images/table-image/icon/close-icon2.svg";
import checkBlueIcon from "../../../images/table-image/icon/options-check-blue-light.svg";
import plusBlack from "../../../images/table-image/icon/plus-black.svg";

interface Props {
  columnTitle: string;
  storeName: string;
  emptyScopeText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOnClose?: (...args: any[]) => any;
  isMultiSelect?: boolean;
  scope?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions?: any[];
  width?: string;
}

function RegularLibraryTaxonomySelect({
  storeName,
  selectedOptions,
  isMultiSelect,
  columnTitle,
  scope,
  emptyScopeText,
  width,
  handleOnClose,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState(selectedOptions);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [newOption, setNewOption] = useState("");

  // Variables
  const { company } = mainStore.companies;
  const locked = emptyScopeText && !scope && storeName !== "tags";
  const { isAdmin } = mainStore.context;
  const { isUser } = mainStore.context;
  const availableOptions =
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    mainStore[storeName][storeName]?.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) => !emptyScopeText || item.l1_ids?.includes(scope),
    ) || [];
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const availableOptionsTags = mainStore[storeName][storeName];
  const liClassNames = classNames("cell column-options-cell", columnTitle, {
    active: isSelectModalOpen,
    "read-only": locked,
    illumination: isSelectModalOpen,
    "locked-cell": locked,
    "pointer-events-none": locked,
  });

  const options =
    storeName === "tags" ? availableOptionsTags : availableOptions;
  // Effects
  useEffect(() => {
    setSelectedItems(selectedOptions);
  }, [selectedOptions]);

  // funcs
  const refreshItems = async () => {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    mainStore[storeName].index(company.id);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createNewItem();
    }
  };

  const createNewItem = async () => {
    if (!newOption) {
      return;
    }

    if (storeName === "tags") {
      await mainStore[storeName].create(company.id as number, newOption);
    } else {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      await mainStore[storeName].create({
        companyID: company.id,
        scope,
        params: { title: newOption },
      });
    }
    setNewOption("");
    refreshItems();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  const addItem = (id) => {
    if (!id) {
      setSelectedItems([]);
    } else if (isMultiSelect) {
      setSelectedItems((prevState) =>
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        prevState.includes(id)
          ? // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            prevState.filter((item) => item !== id)
          : // @ts-expect-error TS(2461) FIXME: Type 'any[] | undefined' is not an array type.
            [...prevState, id],
      );
    } else {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      setSelectedItems((prevState) => (prevState.includes(id) ? [] : [id]));
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
    setShowPopup(isMultiSelect);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const handleDelete = async (e, id) => {
    e.stopPropagation();

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    await mainStore[storeName].delete(company.id, id);
    refreshItems();
  };

  const onClose = () => {
    if (selectedItems === selectedOptions) {
      return;
    }

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleOnClose(selectedItems);
  };

  const handlePopUpOpen = () => {
    refreshItems();
    setShowPopup(true);
    setIsSelectModalOpen(true);
  };

  const handlePopUpClose = () => {
    onClose();
    setShowPopup(false);
    setIsSelectModalOpen(false);
    setNewOption("");
  };

  // renders
  const renderTrigger = (
    <li className={liClassNames} style={{ width }}>
      <div className="cell-content">
        <div
          className="options column-options-container"
          data-testid="column-options-container"
        >
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {selectedOptions.length === 0 || (locked && emptyScopeText) ? (
            <p
              className="options-placeholder nodata"
              data-testid="column-options-empty"
            >
              {locked && emptyScopeText
                ? emptyScopeText
                : `- Select ${columnTitle} -`}
            </p>
          ) : (
            options
              // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
              ?.filter((item) => selectedOptions.includes(item.id))
              // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
              ?.map((item) => {
                const itemTitle = item.title;
                const optionsClassNames = classNames(
                  "options",
                  "column-options-item",
                  storeName,
                  itemTitle.toLowerCase(),
                );

                return (
                  <div
                    className={optionsClassNames}
                    data-testid="column-options-item"
                    key={item.id}
                  >
                    <span
                      className="value value-type"
                      data-testid="column-options-value"
                      style={{
                        border: item.border,
                        color: item.text_color,
                        background: item.background_color,
                      }}
                    >
                      {itemTitle}
                    </span>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </li>
  );

  const renderContent = (
    <div className="select" data-testid="column-options-popup-content">
      {(isAdmin || isUser) && (
        <div className="select-add-new-form">
          <input
            type="text"
            placeholder={`Create ${columnTitle}...`}
            value={newOption}
            data-testid="column-option-add-new-input"
            onChange={(event) => setNewOption(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={createNewItem}>
            <img src={plusBlack} alt="plus-black" />
          </button>
        </div>
      )}
      <div className="select-dropdown">
        <ul
          className="select-options"
          data-testid="column-options-dropdown-list"
        >
          {options?.length === 0 && (
            <li data-testid="empty-options-dropdown-list">
              <h4 className="select-no-options-message">
                You don&apos;t have any selections{" "}
                {`Please click "Create ${columnTitle}" above to create new options`}
              </h4>
            </li>
          )}

          {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
          {options?.map((item) => {
            const itemTitle = item.title;
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            const isSelected = selectedItems.includes(item.id);

            const selectOptionClasses = classNames("select-option colored", {
              "select-option-active": !isMultiSelect && isSelected,
              "select-option-active-search": newOption.length > 0,
            });

            return (
              <li
                key={item.id}
                data-testid="column-options-dropdown-item"
                className={selectOptionClasses}
                onClick={() => {
                  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
                  addItem(item.id, item.title);
                }}
              >
                <div className="select-option-label-wrapper">
                  {isMultiSelect && (
                    <span
                      className={classNames("select-option-checkbox", {
                        checked: isSelected,
                      })}
                    >
                      {isSelected && <img src={checkIcon} alt="check-icon" />}
                    </span>
                  )}
                  <span
                    className="select-option-label risk-type-option-item"
                    style={{
                      border: item.border,
                      color: item.text_color,
                      background: item.background_color,
                    }}
                  >
                    {itemTitle}
                  </span>
                </div>
                {!isMultiSelect && isSelected && (
                  <span className="select-option-check-icon">
                    <img src={checkBlueIcon} alt="check-icon" />
                  </span>
                )}
                {item.used_count < 1 && !isSelected && (isAdmin || isUser) && (
                  <span
                    className="select-option-delete-icon"
                    data-testid="column-option-delete-button"
                    onClick={(e) => handleDelete(e, item.id)}
                  >
                    <img src={deleteIcon} alt="delete-icon" />
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  if (locked) {
    return renderTrigger;
  }

  return (
    <Popup
      position="bottom left"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={showPopup}
      keepTooltipInside
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
    >
      {renderContent}
    </Popup>
  );
}

RegularLibraryTaxonomySelect.defaultProps = {
  width: "100%",
  selectedOptions: [],
  isMultiSelect: false,
  handleOnClose: () => {},
};

export default observer(RegularLibraryTaxonomySelect);

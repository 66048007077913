import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";

export default class Auth0 {
  mainStore;

  // Observable objects
  auth0Data = {};

  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  constructor(mainStore) {
    makeObservable(this, {
      auth0Data: observable,
      setAuth0Data: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'uuid' implicitly has an 'any' type.
  async fetchOrganization(uuid) {
    if (!uuid) {
      return;
    }

    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/auth0/organizations/${uuid}`,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "SSO Organization was not found. Please try again or contact Themis support if the problem persists.",
        );
        return;
      }

      const {
        organization_auth0_id: organizationAuth0ID,
        domain,
        client_id: clientId,
      } = result.data;
      const data = { organizationAuth0ID, domain, clientId };

      this.setAuth0Data(data);
      return data;
    } catch (error) {
      window.console.log(`"Auth0#fetchOrganization" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'uuid' implicitly has an 'any' type.
  async callback(uuid, token) {
    if (!uuid || !token) {
      return;
    }

    try {
      const res = await legacyApi({
        method: "GET",
        url: `${API_URL}/auth0/callback?uuid=${uuid}&token=${token}`,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (res.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorText(res.response.data.errors?.join(","));
        return;
      }

      return true;
    } catch (error) {
      window.console.log(`"Auth0#callback" error ${error}`);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAuth0Data(value) {
    this.auth0Data = value || {};
  }

  // Store Helpers

  cleanup() {
    this.setAuth0Data({});
  }
}

import api from "../api";
import type { AuthResponse } from "./types";

export async function getAuthenticationOptions(email: string) {
  const params = {
    email,
  };

  const response = await api.get<AuthResponse>(
    `/users/authentication_options`,
    { params },
  );

  return response.data;
}

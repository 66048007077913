import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Marker = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 10.203C19 11.9048 18.4277 13.4642 17.473 14.6941L12 20.9914L6.527 14.6941C5.57235 13.4642 5 11.9048 5 10.203C5 6.20111 8.15748 3 12 3C15.8425 3 19 6.20111 19 10.203Z"
      stroke="#353549"
      strokeWidth="2"
    />
    <circle cx="12" cy="10" r="2" fill="#353549" />
  </svg>
);

Marker.displayName = "MarkerIcon";

export default Marker;

import "./submitted-form.scss";

import axios from "axios";
import React from "react";

import { QuestionnaireFormApi } from "@/api/legacy/risk-assessment/QuestionnaireFormApi";
import { Button, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import ThemisLogo from "@/images/logo.svg";

interface Props {
  downloadSlug?: string;
  passwordProtected: boolean;
}

export default function SubmittedForm(props: Props) {
  const mainStore = useMainStore();
  const downloadQuestionnaire = async () => {
    try {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      return await QuestionnaireFormApi.download(props.downloadSlug);
    } catch (error) {
      window.console.log(error);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (axios.isAxiosError(error) && error.response.status === 404) {
        mainStore.toast.setErrorText(
          "The link to download the questionnaire has expired.",
        );
      } else {
        mainStore.toast.setErrorText("An unexpected error occured.");
      }
    }
  };

  return (
    <div className="submitted-form">
      <div className="submitted-form__content">
        <img src={ThemisLogo} alt="themis-logo" />
        <Typography
          className="submitted-form__main-message"
          label="This questionnaire has been submitted. Please reach out to an administrator to change responses."
          color="generalMidnightDark"
          weight="semiBold"
          size="lg"
        />
        <Typography
          label="This page can now be safely closed."
          color="generalDarkGray"
          size="sm"
          weight="semiBold"
        />
        {props.downloadSlug && (
          <div>
            <Button
              icon="download"
              label="Download Responses"
              onClick={() => downloadQuestionnaire()}
            />
            {!props.passwordProtected && (
              <Typography
                className="submitted-form__sub-message"
                label="This link will expire in an hour."
                color="generalDarkGray"
                size="sm"
                weight="semiBold"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";

import Reviews from "./Reviews";
import Tasks from "./Tasks";

export const ActionItems = () => (
  <div className="action-items">
    <Reviews />
    <Tasks />
  </div>
);

import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import SendRecordVersion from "../shared/SendRecordVersion";
import ViewLink from "../shared/ViewLink";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  template: any;
  editable?: boolean;
  vendorID?: number;
}

function RiskAssessmentTemplateActions({
  template,
  editable,
  vendorID,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);

  const { canDeleteRecords } = mainStore.userPermissions;

  const { isIW, workspaceID } = mainStore.context;

  // Functions
  function closeSlideMenu() {
    setShowSlideMenu(false);
  }

  function openSlideMenu() {
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
    setShowSlideMenu(true);
  }

  async function onCopy(riskAssessmentId: number, workspaceIds: number[]) {
    try {
      await mainStore.vendors.copyRiskAssessment(
        riskAssessmentId,
        workspaceIds,
      );
    } catch {
      mainStore.toast.setErrorText("Something went wrong.");
    }
  }

  function noButtonPressed() {
    handlePopUpClose();
  }

  function deleteRowConfirmation() {
    setIsDeleteConfirmationOpen(true);
  }

  function deleteRow() {
    mainStore.vendors.deleteRiskAssessment(template.id);
    mainStore.toast.setText("Risk Assessment Template deleted!");
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  // elements
  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="vdd-active-actions"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="cm-context-menu-dropdown"
    >
      <ul>
        {isIW && (
          <li
            onClick={openSlideMenu}
            data-testid="vdd-risk-assessment-send-to-cw"
          >
            Copy to Collaborative Workspace
          </li>
        )}
        <hr />
        <li onClick={deleteRowConfirmation} data-testid="vdd-delete">
          Delete
        </li>
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Risk Assessment Template"
      content="Are you sure you want to delete this template? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  return (
    <>
      {editable && (
        <div className="list-points">
          <ViewLink
            url={`/workspaces/${workspaceID}/modules/vendor-due-diligence/build_risk_assessment/templates/${template.id}/update`}
            label="Edit"
          />
          <Popup
            position="bottom right"
            // eslint-disable-next-line react/no-unstable-nested-components
            trigger={() => renderTrigger}
            disabled={!canDeleteRecords}
            onOpen={handlePopUpOpen}
            onClose={handlePopUpClose}
            open={isOpenModal}
          >
            {!isDeleteConfirmationOpen && renderBaseContent}
            {isDeleteConfirmationOpen && (
              <div className="table-dropdown success-dropdown">
                {renderDeleteConfirmation}
              </div>
            )}
          </Popup>
        </div>
      )}
      {!editable && (
        <div className="list-points">
          <ViewLink
            url={`/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendorID}/build_risk_assessment/${template.id}/templates/use`}
            label="Use"
          />
        </div>
      )}
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        <SendRecordVersion
          recordVersionID={template?.id}
          recordName="Risk Assessment Template"
          sendFunction={onCopy}
        />
      </SlideMenu>
    </>
  );
}

RiskAssessmentTemplateActions.defaultProps = {
  editable: true,
};

export default observer(RiskAssessmentTemplateActions);

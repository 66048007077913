import React from "react";
import { useHistory } from "react-router-dom";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import PolicyActionBar from "@/components/table/policy/PolicyActionBar";
import CreativeView from "@/components/table/shared/creative-view/CreativeView";

interface PoliciesCreativeViewPageProps {
  title?: string;
}

const PoliciesCreativeViewPage = (props: PoliciesCreativeViewPageProps) => {
  const history = useHistory();

  return (
    <DashboardContent>
      <DashboardHeader
        title={props.title ? props.title : "View Document"}
        onBackClick={() => history.goBack()}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<PolicyActionBar />}
      />
      <DashboardContentWrapper>
        <div className="policies-page-content">
          <CreativeView />
        </div>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default PoliciesCreativeViewPage;

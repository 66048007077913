import React from "react";

export const IconMenuContext = React.createContext<{
  open: boolean;
  setOpen(open: boolean): void;
}>({ open: false, setOpen: () => {} });

export function useIconMenuContext() {
  const context = React.useContext(IconMenuContext);
  if (!context) {
    throw new Error(
      "IconMenu compound components cannot be rendered outside the IconMenu component",
    );
  }
  return context;
}

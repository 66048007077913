import { useToast } from "@themis/ui";
import React from "react";

import {
  useControlMappingsDetails,
  useCreateAndFinalizeControlMappings,
} from "@/api/queries/controlMappings";

import type { CreateControlMappingSchema } from "../CreateControlMappingForm";
import CreateControlMappingForm from "../CreateControlMappingForm";

function CreateControlMappingMenu({
  recordVersionId,
  defaultTitle,
  defaultDescription,
  onClose,
}: {
  recordVersionId: number;
  defaultTitle?: string;
  defaultDescription?: string;
  onClose: () => void;
}) {
  const {
    data: moduleWorkspaceDetails,
    isPending,
    isError,
  } = useControlMappingsDetails(recordVersionId);

  const toast = useToast();

  const { mutateAsync: createResult } = useCreateAndFinalizeControlMappings({
    recordVersionId,
    onSuccess: () => {
      onClose();
      toast({ content: "Control saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update control.",
        variant: "error",
      });
    },
  });

  const handleSubmitForm = async (
    values: Partial<CreateControlMappingSchema>,
  ) => {
    const requestBody = {
      data: {
        original_record_version: recordVersionId,
        record_version: {
          data: {
            control_objective: values?.control_objective || null,
            control_classification: values?.control_classification || null,
            control_placement: values?.control_placement || null,
            control_frequency: values?.control_frequency || null,
            key_control: values?.key_control || null,
            control_category: values?.control_category
              ? [Number(values?.control_category)]
              : [],
            type: values?.type ? [Number(values?.type)] : [],
            control_title: values?.control_title || null,
            department: values?.department ? [Number(values?.department)] : [],
            description: values?.description || null,
            control_type: values?.control_type || null,
            control_effectiveness_rating:
              values?.control_effectiveness_rating || null,
            control_owners: values?.control_owners || null,
            policies_controls: values?.policies_controls || null,
            procedures_controls: values?.procedures_controls || null,
            approvers: values?.approvers || null,
            created_at: values?.created_at || null,
            created_by: values?.created_by || null,
            last_updated_at: values?.last_updated_at || null,
            last_updated_by: values?.last_updated_by || null,
            last_closed_at: values?.last_closed_at || null,
            last_closed_by: values?.last_closed_by || null,
            last_reopened_at: values?.last_reopened_at || null,
            frameworks: values?.frameworks || null,
          },
        },
      },
    };

    await createResult(requestBody);
  };

  if (isPending || isError) {
    return null;
  }

  return (
    <CreateControlMappingForm
      onSubmit={handleSubmitForm}
      moduleWorkspaceDetails={moduleWorkspaceDetails?.data}
      defaultTitle={defaultTitle}
      defaultDescription={defaultDescription}
    />
  );
}

export default CreateControlMappingMenu;

import type { Location } from "history";

import { pathMatchesPattern } from "@/utils/routing";

interface ModuleTabRoutePattern {
  [key: string]: string;
}

const moduleTabRoutePatterns: ModuleTabRoutePattern = {
  // complaints/completed : -> closed status
  // complaints : -> open status
  "/workspaces/:workspace/modules/complaints/completed": "closed",
  "/workspaces/:workspace/modules/complaints": "open",
  // control-mapping : -> all statuses
  // control-mapping/completed : -> closed status
  "/workspaces/:workspace/modules/control-mapping/completed": "closed",
  // issue-management : -> all statuses
  // issue-management/drafts : -> open status
  // issue-management/completed : -> closed status
  "/workspaces/:workspace/modules/issue-management/drafts": "open",
  "/workspaces/:workspace/modules/issue-management/completed": "closed",
  // risk-register : -> all statuses
  // risk-register/active : -> open status
  // risk-register/completed : -> closed status
  "/workspaces/:workspace/modules/risk-register/active": "open",
  "/workspaces/:workspace/modules/risk-register/completed": "closed",
  // training : -> all statuses
  // training/published : -> closed status
  "/workspaces/:workspace/modules/training/published": "closed",
  // marketing/completed : -> closed status
  // marketing : -> open statuses
  "/workspaces/:workspace/modules/marketing/completed": "closed",
  "/workspaces/:workspace/modules/marketing": "open",
  // qa-tests/completed : -> closed status
  // qa-tests : -> open statuses
  "/workspaces/:workspace/modules/qa-tests/completed": "closed",
  "/workspaces/:workspace/modules/qa-tests": "open",
};

function getTabNameFromPath(path: string): string | undefined {
  const pattern = Object.keys(moduleTabRoutePatterns).find((routePattern) => {
    const match = pathMatchesPattern(path, routePattern);
    if (match) {
      return routePattern;
    }

    return undefined;
  });

  if (pattern) {
    return moduleTabRoutePatterns[pattern];
  }

  return undefined;
}

export function useTabDetection(location: Location) {
  return getTabNameFromPath(location.pathname);
}

import React from "react";

import { Typography } from "../../Elements";

interface Props {
  children?: React.ReactNode;
}

const SectionTitle = ({ children }: Props) => (
  <div className="section-title">
    <Typography weight="bold" color="generalWhite" label={children} />
  </div>
);

export { SectionTitle };

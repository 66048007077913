import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Send = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6785 12.7356L14.9013 18.1338L18.7117 6.70236L12.6785 12.7356ZM17.2975 5.28833L5.86613 9.0988L11.2643 11.3216L17.2975 5.28833ZM14.0753 21.3807L10.734 13.266L2.61924 9.92464C1.76247 9.57185 1.80475 8.34428 2.68376 8.05128L20.6838 2.05128C21.4655 1.79069 22.2093 2.53443 21.9487 3.31619L15.9487 21.3162C15.6557 22.1952 14.4281 22.2375 14.0753 21.3807Z"
      fill="#353549"
    />
  </svg>
);

Send.displayName = "SendIcon";

export default Send;

import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

function TopCustomerSupportComplaintsContextMenu() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();
  const history = useHistory();

  // Variables
  const path = location.pathname;
  const complaintID = Number(path.substring(path.lastIndexOf("/") + 1));
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === complaintID,
  );
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const customerSupportStatus = mainStore.avroSchemas.firstValueForField(
    "customer_support_status",
    recordVersion?.data,
  );
  const isClosed = status === "closed";
  const isComplete = customerSupportStatus === "complete";

  // State
  const [buttonDisabled, setButtonDisabled] = useState(false);

  async function completePressed() {
    setButtonDisabled(true);
    await mainStore.customerSupport.complete(complaintID);
    setButtonDisabled(false);
    if (mainStore.recordVersions.cellsErrors.length === 0) {
      history.goBack();
    }
  }

  if (isComplete || isClosed) {
    return null;
  }

  return recordVersion?.table_name === "Customer Support" ? (
    <div className="buttons-complaint-wrap">
      <button
        className="submit"
        disabled={buttonDisabled}
        onClick={completePressed}
        data-testid="complaint-button-complete"
      >
        Send to Compliance
      </button>
    </div>
  ) : null;
}

export default observer(TopCustomerSupportComplaintsContextMenu);

import React from "react";
import { useParams } from "react-router-dom";

import { useQaTests } from "@/api/queries/qaTests";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";
import RelatedIssueManagementRecord from "@/features/monitoring-and-testing/components/RelatedIssueManagementRecord";

export function PreviewRelatedIssueManagementRecords({
  isIssueManagementModuleAdded,
}: {
  isIssueManagementModuleAdded: boolean;
}) {
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const recordVersionId = Number(record_version_id);
  const { data: qaTests, isPending, isError } = useQaTests();

  if (isPending || isError) {
    return null;
  }

  const linkedIssueManagementRecords = qaTests.data.record_versions.find(
    ({ id }) => id === recordVersionId,
  )?.linked_finding_record_links;

  if (!linkedIssueManagementRecords) {
    return null;
  }

  return (
    <InfoPreviewSection
      title="Related Issue Management Records"
      countDescription="Issue"
      count={linkedIssueManagementRecords.length}
      content={
        Boolean(linkedIssueManagementRecords.length) && (
          <div className="tw-w-full">
            {linkedIssueManagementRecords.map((linkedRecord) => {
              return (
                <RelatedIssueManagementRecord
                  key={linkedRecord.finding_id}
                  linkedRecord={linkedRecord}
                  isIssueManagementModuleAdded={isIssueManagementModuleAdded}
                />
              );
            })}
          </div>
        )
      }
    />
  );
}

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { useIndexForFieldTypes } from "@/hooks/data-fetching/useIndexForFieldTypes";
import { useIsModuleCustomDataPage } from "@/hooks/useIsModuleCustomDataPage";
import { useModuleDetection } from "@/hooks/useModuleDetection";
import usePermissionIndexer from "@/hooks/usePermissionIndexer";
import { routeWorkspaceID } from "@/utils/routing";

// Any paths where we want / need to force a data reload
const LOCATION_INIT_PATHS = ["/home"];
const LOCATION_NO_LOADING = ["/multi-factor-auth"];

const useDataInit = () => {
  const [loading, setLoading] = useState(true);

  const mainStore = useMainStore();

  const location = useLocation();

  const { user } = mainStore.users;
  const { company } = mainStore.companies;
  const { workspaceID } = mainStore.context;
  const { list: moduleWorkspaces } = mainStore.moduleWorkspaces;

  const themisModuleIdentifier = useModuleDetection(location);
  const isModuleCustomDataPage = useIsModuleCustomDataPage();
  useIndexForFieldTypes(workspaceID, mainStore.fields.fieldTypesRequiringIndex);
  usePermissionIndexer();

  useEffect(() => {
    const activeWorkspaceID = user?.active_workspace?.id;

    if (activeWorkspaceID) {
      const pathWorkspaceID = routeWorkspaceID(location.pathname);

      if (
        pathWorkspaceID &&
        Number(pathWorkspaceID) !== Number(activeWorkspaceID)
      ) {
        // Need to switch active_workspace to :workspace_id in path
        mainStore.workspaces.switch(pathWorkspaceID);
      } else {
        // @ts-expect-error TS(2345)
        mainStore.context.setActiveWorkspace(user.active_workspace);
      }
    }
  }, [user]);

  useEffect(() => {
    mainStore.context.setThemisModuleIdentifier(themisModuleIdentifier);
    if (!themisModuleIdentifier) {
      mainStore.context.setModuleWorkspaceID(null);
      mainStore.context.setTableID(null);
    }
  }, [themisModuleIdentifier]);

  useEffect(() => {
    const moduleWorkspace = moduleWorkspaces.find(
      (mws) => mws.themis_module.identifier === themisModuleIdentifier,
    );

    if (!moduleWorkspace) {
      return;
    }

    mainStore.context.setModuleWorkspaceID(moduleWorkspace.id);

    if (isModuleCustomDataPage) {
      return;
    }
    // Pinned field name & field options are stored in ModuleWorkspace
    // This is initial place where they are getting cached
    mainStore.fields.setPinnedFieldName(moduleWorkspace?.pinned_field_name);
    mainStore.fieldOptions.setAll(moduleWorkspace?.field_options);
  }, [
    mainStore.moduleWorkspaces.list,
    themisModuleIdentifier,
    isModuleCustomDataPage,
  ]);

  // This should only happen once on render since the company context only changes across user sessions
  useEffect(() => {
    const { id: companyID } = company;
    if (companyID) {
      mainStore.departments.index(companyID);
      mainStore.products.index(companyID);
      mainStore.tags.index(companyID);
    }
  }, [company]);

  useEffect(() => {
    if (workspaceID) {
      mainStore.moduleWorkspaces.index(workspaceID);
    }
  }, [workspaceID]);

  useEffect(() => {
    async function fetch() {
      if (LOCATION_NO_LOADING.includes(location.pathname)) {
        setLoading(false);
      } else if (LOCATION_INIT_PATHS.includes(location.pathname) || loading) {
        setLoading(true);
        await mainStore.workspaces.index();
        await mainStore.permissions.getPermissionGroups();
        setLoading(false);
      }
    }
    fetch();
  }, [location.pathname]);

  return { loading };
};

export { useDataInit };

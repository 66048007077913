import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import { useMainStore } from "../../../contexts/Store";
import logo from "../../../images/logo.svg";
import checkBlack from "../../../images/table-image/icon/check-black-icon.svg";
import check from "../../../images/table-image/icon/check-two-icon.svg";
import checkViolet from "../../../images/table-image/icon/check-violet-icon.svg";
import documentsBlack from "../../../images/table-image/icon/documents-black.svg";
import documentsWhite from "../../../images/table-image/icon/documents-white.svg";
import documents from "../../../images/table-image/icon/documents.svg";
import warning from "../../../images/table-image/icon/warning-black-icon.svg";
import { recordTypeForThemisModuleIdentifier } from "../../helpers/nameForThemisModuleIdentifier";
import Spinner from "../shared/Spinner";

function AttestationView() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [selectedDocumentID, setSelectedDocumentID] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [isActionDisabled, setIsActionDisabled] = useState(true);

  // Variables
  const isAttestationsMobile = useMediaQuery({ query: "(max-width: 576px)" });
  const { attestedDocuments, moduleName } = mainStore.attestations;
  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
  const documentID = attestedDocuments[0]?.id;
  const selectedDocument = mainStore.attestations.attestedDocuments.find(
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
    (document) => document.id === selectedDocumentID,
  );
  // @ts-expect-error TS(2339) FIXME: Property 'file_url' does not exist on type 'never'... Remove this comment to see the full error message
  const isPDF = selectedDocument?.file_url.includes(".pdf");
  const isImg = /(\.png|\.jpg|\.jpeg|\.gif|\.svg)/.test(
    // @ts-expect-error TS(2339) FIXME: Property 'file_url' does not exist on type 'never'... Remove this comment to see the full error message
    selectedDocument?.file_url,
  );
  // @ts-expect-error TS(2339) FIXME: Property 'file_url' does not exist on type 'never'... Remove this comment to see the full error message
  const isVideo = /(\.mp4|\.mov)/.test(selectedDocument?.file_url);
  const pdf = {
    // @ts-expect-error TS(2339) FIXME: Property 'file_url' does not exist on type 'never'... Remove this comment to see the full error message
    url: selectedDocument?.file_url,
    fileType: "application/pdf",
  };
  const isError = mainStore.attestations.attestedDocumentsError;
  const isNotFound = mainStore.attestations.attestedDocumentsNotFoundError;
  const hasDocuments = mainStore.attestations.attestedDocuments.length > 0;
  const isLoading = !hasDocuments && !isError && !isNotFound;
  const notAttestedDocumentsCount =
    mainStore.attestations.attestedDocuments.filter(
      // @ts-expect-error TS(2339) FIXME: Property 'attested' does not exist on type 'never'... Remove this comment to see the full error message
      (document) => !document.attested,
    ).length;
  const isEverythingAttested = hasDocuments && notAttestedDocumentsCount === 0;
  const blockClasses = classNames("view-page-button", {
    // @ts-expect-error TS(2339) FIXME: Property 'attested' does not exist on type 'never'... Remove this comment to see the full error message
    active: !isActionDisabled && selectedDocument?.attested === false,
  });
  const buttonClasses = classNames({
    // @ts-expect-error TS(2339) FIXME: Property 'attested' does not exist on type 'never'... Remove this comment to see the full error message
    active: !isActionDisabled && selectedDocument?.attested === false,
  });
  const attestationsClasses = classNames("attestation-view", {
    "attestation-view-documents": hasDocuments && !isEverythingAttested,
  });

  const timeRef = useRef(0);
  const handleVideo = useCallback((node: HTMLVideoElement) => {
    if (!node) {
      return;
    }
    node.ontimeupdate = () => {
      setTimeout(() => (timeRef.current = node.currentTime), 500);
    };

    node.onseeking = () => {
      if (node.currentTime > timeRef.current) {
        node.currentTime = timeRef.current;
      }
    };

    node.onended = () => setIsActionDisabled(false);
  }, []);

  // Hooks
  // @ts-expect-error TS(2339) FIXME: Property 'attestation_id' does not exist on type '... Remove this comment to see the full error message
  const { attestation_id } = useParams();

  // effects
  useEffect(() => {
    mainStore.attestations.show(attestation_id);
  }, [attestation_id]);

  useEffect(() => {
    setSelectedDocumentID(documentID);
  }, [documentID]);

  useEffect(() => {
    if (isImg) {
      setIsActionDisabled(false);
    }
  }, [isImg]);

  // Functions
  // @ts-expect-error TS(7031) FIXME: Binding element 'newNumPages' implicitly has an 'a... Remove this comment to see the full error message
  function onDocumentLoadSuccess({ numPages: newNumPages }) {
    setNumPages(newNumPages);
    if (!isVideo) {
      setIsActionDisabled(false);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function selectDocument(event, attestedDocumentID) {
    event.preventDefault();

    // Reset pages
    if (attestedDocumentID !== selectedDocumentID) {
      setNumPages(null);
    }

    // Apply Document selection
    setSelectedDocumentID(attestedDocumentID);
  }

  function onAttestClick() {
    if (attestation_id && selectedDocumentID) {
      mainStore.attestations.attest(attestation_id, selectedDocumentID);
    }
  }

  // elements
  const renderPdf = (
    <div className="pdf-wrap">
      <Document file={pdf.url} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={isAttestationsMobile ? 600 : 816}
          />
        ))}
      </Document>
    </div>
  );

  const renderImg = (
    <img
      // @ts-expect-error TS(2339) FIXME: Property 'file_url' does not exist on type 'never'... Remove this comment to see the full error message
      src={selectedDocument?.file_url}
      alt="document"
      className="attestation-view-item-img"
    />
  );

  const renderVideo = (
    <video
      ref={handleVideo}
      controls
      controlsList="noplaybackrate"
      style={{ width: 800 }}
      // @ts-expect-error TS(2339) FIXME: Property 'file_url' does not exist on type 'never'... Remove this comment to see the full error message
      src={selectedDocument?.file_url}
    />
  );

  return (
    <div className={attestationsClasses} data-testid="attestation-view">
      <img src={logo} alt="logo" className="logo" />
      <div className="attestation-view-wrap">
        {isLoading && (
          <div className="attestation-view-loading">
            <Spinner />
            <h4>Please wait for confirmation from the system.</h4>
          </div>
        )}

        {!isLoading && isEverythingAttested && (
          <div className="attestation-view-success">
            <img src={checkBlack} alt="check" />
            <h4>
              You have Successfully Attested the{" "}
              {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message */}
              {recordTypeForThemisModuleIdentifier(moduleName?.toLowerCase())
                ?.plural || "records"}{" "}
              below:
            </h4>
            <ul>
              {mainStore.attestations.attestedDocuments.map(
                (attestedDocument) => (
                  // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                  <li key={attestedDocument.id}>
                    <img src={documents} alt="documents" />
                    {/* @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'. */}
                    {attestedDocument.name}
                  </li>
                ),
              )}
            </ul>
            <h5>This page can now be safely closed.</h5>
          </div>
        )}

        {hasDocuments && !isEverythingAttested && (
          <div className="attestation-view-page">
            <div className="view-page-block">
              <h3>{moduleName} Name</h3>
              <p>
                Click the {moduleName?.toLowerCase()} names below to review each
                document. Once ready, click the “Attest Document” button at the
                bottom of document.
              </p>
              <ul className="view-page-link">
                {mainStore.attestations.attestedDocuments.map(
                  (attestedDocument) => {
                    const linkClassNames = classNames({
                      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                      active: attestedDocument.id === selectedDocumentID,
                      // @ts-expect-error TS(2339) FIXME: Property 'attested' does not exist on type 'never'... Remove this comment to see the full error message
                      done: attestedDocument.attested,
                    });
                    return (
                      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                      <li key={attestedDocument.id}>
                        <a
                          href="#"
                          className={linkClassNames}
                          onClick={(event) => {
                            // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                            selectDocument(event, attestedDocument.id);
                          }}
                        >
                          <img
                            src={documentsWhite}
                            className="active-image"
                            alt="documents"
                          />
                          <img
                            src={documentsBlack}
                            className="no-active-image"
                            alt="documents"
                          />
                          <img
                            src={documents}
                            className="done-image"
                            alt="documents"
                          />
                          {/* @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'. */}
                          {attestedDocument.name}
                          {/* @ts-expect-error TS(2339) FIXME: Property 'attested' does not exist on type 'never'... Remove this comment to see the full error message */}
                          {attestedDocument.attested && (
                            <>
                              <img
                                src={check}
                                className="check-done check-active-done"
                                alt="check"
                              />
                              <img
                                src={checkViolet}
                                className="check-done check-done-done"
                                alt="check"
                              />
                            </>
                          )}
                        </a>
                      </li>
                    );
                  },
                )}
              </ul>
            </div>
            <div className="view-page-file">
              {selectedDocumentID && isPDF && renderPdf}
              {selectedDocumentID && isImg && renderImg}
              {selectedDocumentID && isVideo && renderVideo}
              <div className={blockClasses}>
                <button
                  disabled={
                    // @ts-expect-error TS(2339) FIXME: Property 'attested' does not exist on type 'never'... Remove this comment to see the full error message
                    isActionDisabled || selectedDocument?.attested === true
                  }
                  className={buttonClasses}
                  onClick={onAttestClick}
                  data-testid="attest-button"
                >
                  {/* @ts-expect-error TS(2339) FIXME: Property 'attested' does not exist on type 'never'... Remove this comment to see the full error message */}
                  {selectedDocument?.attested === false
                    ? "Attest Document"
                    : "Document Attested"}
                </button>
              </div>
            </div>
          </div>
        )}

        {isNotFound && (
          <div className="attestation-view-failed">
            <img src={warning} alt="warning" />
            <h4>
              This attestation request cannot be found or your attestation may
              no longer be required.
              <br />
              <br />
              Check with your Themis Admin for more information.
            </h4>
          </div>
        )}

        {isError && (
          <div className="attestation-view-failed">
            <img src={warning} alt="warning" />
            <h4>
              Something went wrong!
              <br />
              Please try again
            </h4>
            <button
              onClick={() => {
                window.location.reload();
              }}
            >
              Retry Attestation
            </button>
            <h5>
              If the issue remains please contact
              <br />
              support@askthemis.zendesk.com
            </h5>
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(AttestationView);

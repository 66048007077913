export const NUMBER_OF_DAYS = ["# of Days Ago", "# of Days from Now"];
export const NUMBER_OF_DAYS_WITHIN = [
  "The Next # of Days",
  "The Past # of Days",
];

export const EMPTY_OPTIONS = ["is empty", "is not empty"];

export const CONDITIONS = [
  "is",
  "is within",
  "is before",
  "is after",
  "is on or before",
  "is on or after",
  ...EMPTY_OPTIONS,
];
export const TIME_RANGE = [
  "Today",
  "Tomorrow",
  "Yesterday",
  "One Week Ago",
  "One Month Ago",
  "One Month from Now",
  ...NUMBER_OF_DAYS,
  "Exact Date",
];
export const IS_WITHIN_RANGE = [
  "The Past Week",
  "The Past Month",
  "The Past Year",
  "The Next Week",
  "The Next Month",
  "The Next Year",
  ...NUMBER_OF_DAYS_WITHIN,
  "Exact Dates",
];

export const CONDITIONS_INTEGER = [
  "equal",
  "does not equal",
  "greater than",
  "less than",
  "greater or equal than",
  "less than or equal to",
  "is between",
  ...EMPTY_OPTIONS,
];

export const IGNORED_FIELDS_FOR_EMPTY_OPTIONS = [
  "policy_owner",
  "policy_changes",
];
export const IGNORED_DATA_TYPES_FOR_EMPTY_OPTIONS = [
  "com.askthemis.types.v1.tag_user",
  "com.askthemis.types.v1.tag_user_contact",
  "com.askthemis.types.v1.tag_verifier",
];

import React from "react";

import zoomInIcon from "../../../../images/table-image/icon/zoom-in.svg";
import zoomOutIcon from "../../../../images/table-image/icon/zoom-out.svg";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  zoomIn?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  zoomOut?: (...args: any[]) => any;
}

function CreativeViewZoomIcons({ zoomIn, zoomOut }: Props) {
  return (
    <section
      className="creative-zoom-container"
      data-testid="creative-zoom-container"
    >
      <img
        src={zoomInIcon}
        alt="zoom-in"
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClick={() => zoomIn(1)}
        data-testid="creative-zoom-in"
      />
      <img
        src={zoomOutIcon}
        alt="zoom-out"
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClick={() => zoomOut(1)}
        data-testid="creative-zoom-out"
      />
    </section>
  );
}

CreativeViewZoomIcons.defaultProps = {
  zoomIn: () => {},
  zoomOut: () => {},
};

export default CreativeViewZoomIcons;

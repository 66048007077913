import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import ConfirmationDialog from "../shared/ConfirmationDialog";
import ViewLink from "../shared/ViewLink";

interface Props {
  currentTableName: string;
  recordVersion: RecordVersion;
}

type PopupContentType = "default" | "delete";

function TableActions({ recordVersion, currentTableName }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const history = useHistory();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalPoints, setIsOpenModalPoints] = useState(false);
  const [popupContentType, setPopupContentType] =
    useState<PopupContentType>("default");

  // Variables
  const { canDeleteRecords, hasModuleWriteAccess } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;
  const { taskDetail } = mainStore;

  function noButtonPressed() {
    handlePopUpClose();
  }

  function deleteRowConfirmation() {
    setPopupContentType("delete");
  }

  function deleteRow() {
    mainStore.vendors.delete(recordVersion.id);
    mainStore.toast.setText("Vendor deleted!");
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setPopupContentType("default");
  }
  function handleCreateTask() {
    setIsOpenModal(false);
    taskDetail.openFromRecord(recordVersion);
  }

  function sendToViewPage() {
    history.push(
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/${recordVersion.id}`,
    );
    setIsOpenModal(false);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="vdd-active-actions"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal || isOpenModalPoints,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="cm-context-menu-dropdown"
    >
      <ul>
        <li onClick={handleCreateTask}>Create Task</li>
        {canDeleteRecords && (
          <li onClick={deleteRowConfirmation} data-testid="vdd-delete">
            Delete
          </li>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Vendor"
      content="Are you sure you want to delete this vendor? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  if (currentTableName === "Inactive") {
    return (
      <div className="vdd-row-right" data-testid="vdd-inactive-actions">
        <Popup
          // @ts-expect-error TS(2322) FIXME: Type '"left"' is not assignable to type 'PopupPosi... Remove this comment to see the full error message
          position="left"
          trigger={renderTrigger}
          open={isOpenModalPoints}
          onOpen={() => setIsOpenModalPoints(true)}
          onClose={() => setIsOpenModalPoints(false)}
          keepTooltipInside
        >
          {popupContentType === "default" && (
            <div
              className="select-link select-link-width"
              onClick={() => setIsOpenModal(false)}
            >
              <ul>
                <li>
                  <button
                    onClick={sendToViewPage}
                    className="select-link-option"
                  >
                    View
                  </button>
                </li>
                {canDeleteRecords && (
                  <li>
                    <button
                      onClick={deleteRowConfirmation}
                      className="select-link-option"
                    >
                      Delete
                    </button>
                  </li>
                )}
              </ul>
            </div>
          )}
          {popupContentType === "delete" && (
            <div className="table-dropdown">{renderDeleteConfirmation}</div>
          )}
        </Popup>
      </div>
    );
  }

  return (
    <div className="list-points" data-testid="cm-context-menu">
      <ViewLink
        url={`/workspaces/${workspaceID}/modules/vendor-due-diligence/${recordVersion.id}`}
      />
      <Popup
        position="bottom right"
        trigger={hasModuleWriteAccess ? renderTrigger : <div />}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
      >
        {popupContentType === "default" && renderBaseContent}
        {popupContentType === "delete" && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

export default observer(TableActions);

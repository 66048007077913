import { observer } from "mobx-react";
import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import ManageApprovalsStepsPage from "@/components/shared/ManageApprovalsPage";
import Zendesk from "@/components/table/zendesk/Zendesk";
import { useMainStore } from "@/contexts/Store";
import { ComplaintsDetailViewFindings } from "@/features/complaints/pages/ComplaintsDetailViewFindings";
import { ModuleAssessmentRoutes } from "@/features/control-mapping/pages";
import QAPage from "@/features/qa/pages/QAPage";
import { ModuleControlsPage } from "@/features/risk-register/pages/ModuleControlsPage";
import VendorContractsRouter from "@/features/vendor-due-diligence-contracts/pages/VendorContractsRouter";
import useDragAndDropRecords from "@/hooks/useDragAndDropRecords";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import AuditsDetailViewFindings from "../../features/audits/pages/AuditsDetailViewFindings";
import DetailView from "../detailView/DetailView";
import { getRecordName } from "../helpers/nameForThemisModuleIdentifier";
import AuditDetailViewDocuments from "../table/audit/AuditDetailViewDocuments";
import ChangeManagement from "../table/change-management/ChangeManagement";
import ChangeManagementsSectionDetailView from "../table/change-management/new-product-section/ChangeManagementsSectionDetailView";
import SectionChangeManagementCreativeView from "../table/change-management/new-product-section/SectionChangeManagementCreativeView";
import DefaultComplaints from "../table/complaints/DefaultComplaints";
import ConflictsOfInterest from "../table/conflicts-of-interest/ConflictsOfInterest";
import ControlMapping from "../table/control-mapping/ControlMapping";
import CMLibraryItemDetailView from "../table/control-mapping/LibraryItemDetailView";
import CustomerSupport from "../table/customer-support/CustomerSupportComplaints";
import ViewComplaintCS from "../table/customer-support/ViewComplaintCS";
import Documents from "../table/documents/Documents";
import FinraComplaints from "../table/finra-complaints/FinraComplaints";
import ViewComplaint from "../table/finra-complaints/ViewComplaint";
import Marketing from "../table/marketing/Marketing";
import Archived from "../table/policy/Archived";
import Procedures from "../table/procedure/Procedures";
import LibraryItemDetailView from "../table/risk-register/LibraryItemDetailView";
import OperationalControls from "../table/risk-register/OperationalControls";
import OperationalControlsAddNew from "../table/risk-register/OperationalControlsAddNew";
import RiskRegister from "../table/risk-register/RiskRegister";
import RiskRegisterVersionHistory from "../table/risk-register/RiskRegisterVersionHistory";
import BuildChecklist from "../table/shared/checklist/BuildChecklist";
import CreativeView from "../table/shared/creative-view/CreativeView";
import MarketingDetailView from "../table/shared/creative-view/MarketingDetailView";
import BuildDocument from "../table/shared/documents/BuildDocument";
import ViewBuildChecklist from "../table/shared/dynamic-form/ViewBuildChecklist";
import LegacyAuditTrail from "../table/shared/LegacyAuditTrail";
import SectionDragContext from "../table/shared/SectionDragContext";
import SectionPage from "../table/shared/SectionPage";
import HistoricalVersions from "../table/shared/views/HistoricalVersions";
import Training from "../table/training/Training";
import BuildRiskAssessment from "../table/vendor-due-diligence/BuildRiskAssessment";
import BuildVendor from "../table/vendor-due-diligence/BuildVendor";
import QuestionnaireComplete from "../table/vendor-due-diligence/QuestionnaireComplete";
import {
  activeVDDUrl,
  inactiveVDDUrl,
  RATemplatesVDDUrl,
  templatesVDDUrl,
} from "../table/vendor-due-diligence/urlPaths";
import VendorDueDiligence from "../table/vendor-due-diligence/VendorDueDiligence";
import ViewVendor from "../table/vendor-due-diligence/ViewVendor";
import DashboardHeader from "./DashboardHeader";

function DashboardContent() {
  // Import MobX stores
  const mainStore = useMainStore();
  const history = useHistory();

  // Variables
  const { isAdmin, isUser, viewPermissions, workspaceID } = mainStore.context;
  const { canManageAccounts } = mainStore.userPermissions;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  const isMonitoringAndTestingModuleAdded =
    mainStore.moduleWorkspaces.isModuleAdded("qa_tests_development");

  const {
    onSectionChange: onSectionChangeAudit,
    onRecordMoved: onRecordMovedAudit,
  } = useDragAndDropRecords({
    themisIdentifier: TopLevelModule.AUDITS,
    mainStore,
  });

  return (
    <div className="dashboard-content">
      <DashboardHeader />

      <div className="dashboard-content-wrap">
        <Switch>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/:moduleIdentifier/audit_trail"
          >
            <LegacyAuditTrail />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/:moduleIdentifier/:record_version_id/audit_trail"
          >
            <LegacyAuditTrail isRecordView />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/documents/:record_version_id/audit_trail"
          >
            <LegacyAuditTrail isRecordView />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/marketing/attachment_view/:record_version_id/audit_trail"
          >
            <div style={{ paddingTop: "15px" }}>
              <LegacyAuditTrail isRecordView />
            </div>
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/:moduleIdentifier/:tab/:record_version_id/audit_trail"
          >
            <LegacyAuditTrail isRecordView />
          </Route>

          <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:record_id/create/:field_name">
            <ViewBuildChecklist />
          </Route>
          <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:checklist_id/update">
            <ViewBuildChecklist editingMode />
          </Route>
          <Route path="/workspaces/:workspace_id/modules/:module_identifier/custom-checklists-templates/:checklist_id/update">
            <ViewBuildChecklist editingMode isTemplate />
          </Route>

          <Route
            key="/modules/change-management/attachment_view"
            path="/workspaces/:workspace_id/modules/change-management/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          {isMonitoringAndTestingModuleAdded && (
            <Route
              key="/modules/qa-tests-development/attachment_view"
              path="/workspaces/:workspace_id/modules/qa-tests-development/attachment_view/:record_version_id/:file_id"
            >
              <CreativeView />
            </Route>
          )}
          {isMonitoringAndTestingModuleAdded && (
            <Route
              key="/modules/qa-tests/attachment_view"
              path="/workspaces/:workspace_id/modules/qa-tests/attachment_view/:record_version_id/:file_id"
            >
              <CreativeView />
            </Route>
          )}
          {isMonitoringAndTestingModuleAdded && (
            <Route
              path={[
                "/workspaces/:workspace_id/modules/qa-tests/",
                "/workspaces/:workspace_id/modules/qa/",
                "/workspaces/:workspace_id/modules/qa-tests-development/",
              ]}
            >
              <QAPage />
            </Route>
          )}
          <Route
            exact
            path="/workspaces/:workspace_id/modules/procedures/drafts"
          >
            <Procedures isDragAndDrop />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/procedures/finalized"
          >
            <Procedures />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/procedures/attestation"
          >
            <Procedures />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/procedures/archived"
          >
            <Archived
              moduleIdentifier="procedures"
              tableName="ProceduresArchived"
            />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/procedures/:record_version_id"
          >
            <DetailView
              moduleIdentifier="procedures"
              ignoredFields={["approvers"]}
            />
          </Route>
          <Route
            exact
            key="/modules/marketing"
            path="/workspaces/:workspace_id/modules/marketing"
          >
            <Marketing isDragAndDrop />
          </Route>
          <Route
            exact
            key="/modules/marketing/manage-approvals-steps"
            path="/workspaces/:workspace_id/modules/marketing/manage-approvals-steps"
          >
            <ManageApprovalsStepsPage moduleIdentifier="marketing" />
          </Route>
          <Route
            exact
            key="/modules/marketing/review-process"
            path="/workspaces/:workspace_id/modules/marketing/review-process"
          >
            <Marketing />
          </Route>
          <Route
            exact
            key="/modules/marketing/completed"
            path="/workspaces/:workspace_id/modules/marketing/completed"
          >
            <Marketing isDragAndDrop />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/marketing/:record_version_id"
          >
            <DetailView
              moduleIdentifier="marketing"
              ignoredFields={["approvers"]}
            />
          </Route>
          <Route
            exact
            key="/modules/training"
            path="/workspaces/:workspace_id/modules/training"
          >
            <Training defaultScreen="All" />
          </Route>
          <Route
            exact
            key="/modules/training/published"
            path="/workspaces/:workspace_id/modules/training/published"
          >
            <Training defaultScreen="Published" />
          </Route>
          <Route
            exact
            key="/modules/training/attestations"
            path="/workspaces/:workspace_id/modules/training/attestations"
          >
            <Training defaultScreen="Attestations" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/training/archived"
          >
            <Archived moduleIdentifier="training" tableName="Default" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/training/:record_version_id"
          >
            <DetailView
              moduleIdentifier="training"
              ignoredFields={["approvers"]}
            />
          </Route>

          {(isAdmin || isUser || viewPermissions.complaints) && (
            <Route
              exact
              key="/modules/complaints"
              path="/workspaces/:workspace_id/modules/complaints"
            >
              <DefaultComplaints />
            </Route>
          )}

          <Route
            exact
            key="/modules/complaints/completed"
            path="/workspaces/:workspace_id/modules/complaints/completed"
          >
            <DefaultComplaints />
          </Route>

          {mainStore.context.activeWorkspace?.is_internal && (
            <Route
              exact
              key="/modules/finra/escalated"
              path="/workspaces/:workspace_id/modules/finra/escalated"
            >
              <FinraComplaints />
            </Route>
          )}

          {mainStore.context.activeWorkspace?.is_internal && (
            <Route
              exact
              key="/modules/finra/review"
              path="/workspaces/:workspace_id/modules/finra/review"
            >
              <FinraComplaints />
            </Route>
          )}

          <Route
            exact
            key="/modules/customer-support/support"
            path="/workspaces/:workspace_id/modules/customer-support/support"
          >
            <CustomerSupport />
          </Route>

          <Route
            exact
            key="/modules/customer-support/compliance"
            path="/workspaces/:workspace_id/modules/customer-support/compliance"
          >
            <CustomerSupport />
          </Route>

          <Route
            exact
            path="/workspaces/:workspace_id/modules/complaints/:record_version_id"
          >
            <DetailView moduleIdentifier="complaints" showExportButton />
          </Route>

          <Route path="/workspaces/:workspace_id/modules/complaints/:record_version_id/findings">
            <ComplaintsDetailViewFindings />
          </Route>

          <Route
            exact
            path="/workspaces/:workspace_id/modules/customer-support/:record_version_id"
          >
            <ViewComplaintCS />
          </Route>

          <Route
            exact
            path="/workspaces/:workspace_id/modules/finra/:record_version_id"
          >
            <ViewComplaint />
          </Route>

          <Route
            exact
            key="/modules/audits"
            path="/workspaces/:workspace_id/modules/audits"
          >
            <SectionDragContext
              onSectionChange={onSectionChangeAudit}
              onRecordMoved={onRecordMovedAudit}
            >
              <SectionPage
                isDraggable
                approvalFieldName="approval"
                createNewRecord={async (sectionTagId?: number | null) => {
                  const recordVersion =
                    await mainStore.themisAudits.createAuditIssue(sectionTagId);
                  history.push(
                    `/workspaces/${workspaceID}/modules/audits/${recordVersion.id}`,
                  );
                }}
                CTASubject="Audit"
                rolePrefix="audits"
                recordName={getRecordName("audits", moduleWorkspaces, true)}
                moduleIdentifier="audits"
                wrapperClass="audits-list-wrap"
              />
            </SectionDragContext>
          </Route>
          <Route
            exact
            key="/modules/qa-testing"
            path="/workspaces/:workspace_id/modules/qa-testing"
          >
            <SectionDragContext>
              <SectionPage
                approvalFieldName="validator"
                CTASubject="QA Test"
                rolePrefix="qa-tests"
                recordName={getRecordName(
                  "qa_tests_development",
                  moduleWorkspaces,
                  true,
                )}
                moduleIdentifier="qa_tests_development"
                wrapperClass="qa-test-list-wrap"
              />
            </SectionDragContext>
          </Route>
          <Route
            exact
            key="/modules/conflicts-of-interest"
            path="/workspaces/:workspace_id/modules/conflicts-of-interest"
          >
            <ConflictsOfInterest />
          </Route>
          <Route
            key="/modules/complaints/attachment_view"
            path="/workspaces/:workspace_id/modules/complaints/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/procedures/attachment_view"
            path="/workspaces/:workspace_id/modules/procedures/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/documents/attachment_view"
            path="/workspaces/:workspace_id/modules/documents/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/change-management/section-view/:section_id/attachment_view/:file_id"
            path="/workspaces/:workspace_id/modules/change-management/section-view/:section_id/attachment_view/:file_id"
          >
            <SectionChangeManagementCreativeView />
          </Route>

          <Route
            key="/modules/marketing/attachment_view/file"
            path="/workspaces/:workspace_id/modules/marketing/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>

          <Route
            key="/modules/marketing/attachment_view"
            path="/workspaces/:workspace_id/modules/marketing/attachment_view/:record_version_id/:file_id"
          >
            <MarketingDetailView />
          </Route>
          <Route
            key="/modules/marketing/attachment_view"
            path="/workspaces/:workspace_id/modules/marketing/:record_version_id"
          >
            <MarketingDetailView />
          </Route>
          <Route
            key="/modules/audits/attachment_view"
            path="/workspaces/:workspace_id/modules/audits/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/qa-testing/attachment_view"
            path="/workspaces/:workspace_id/modules/qa-testing/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/attachment_view"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/attachment_preview"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/attachment_preview/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/checklist_questions/attachment_view"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/checklist_questions/:checklist_question_id/attachment_view"
          >
            {/* @ts-expect-error TS(2322) FIXME: Type '{ moduleIdentifier: string; }' is not assign... Remove this comment to see the full error message */}
            <CreativeView moduleIdentifier="vendor_due_diligence" />
          </Route>
          <Route
            key="/modules/conflicts-of-interest/attachment_view"
            path="/workspaces/:workspace_id/modules/conflicts-of-interest/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            key="/modules/change-management/:record_id/checklists/create"
            path="/workspaces/:workspace_id/modules/change-management/:record_id/checklists/create"
          >
            <BuildChecklist />
          </Route>
          <Route
            key="/modules/change-management/checklists/:checklist_id/update"
            path="/workspaces/:workspace_id/modules/change-management/checklists/:checklist_id/update"
          >
            <BuildChecklist editingMode />
          </Route>
          <Route
            key="/modules/change-management/checklists-templates/:checklist_id/update"
            path="/workspaces/:workspace_id/modules/change-management/checklists-templates/:checklist_id/update"
          >
            <BuildChecklist editingMode isTemplate />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/templates/create"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/templates/create"
          >
            <BuildChecklist isTemplate type="VDD" />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/templates/:checklist_id/update"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/templates/:checklist_id/update"
          >
            <BuildChecklist editingMode isTemplate type="VDD" />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/:record_id/templates/:checklist_id/use"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:record_id/templates/:checklist_id/use"
          >
            <BuildChecklist editingMode usingMode type="VDD" />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/:record_id/questionnaires/create"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:record_id/questionnaires/create"
          >
            <BuildChecklist type="VDD" />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/questionnaires/:checklist_id/update"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/questionnaires/:checklist_id/update"
          >
            <BuildChecklist editingMode type="VDD" />
          </Route>
          <Route
            key="/modules/vendor-due-diligence/questionnaires/:checklist_id/complete"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/questionnaires/:checklist_id/complete"
          >
            <QuestionnaireComplete />
          </Route>
          <Route
            key="/modules/change-management/add-new-approval"
            path="/workspaces/:workspace_id/modules/change-management/add-new-approval"
          >
            <ChangeManagementsSectionDetailView isCreate />
          </Route>
          <Route
            key="/modules/change-management/edit-approval/:section_id"
            path="/workspaces/:workspace_id/modules/change-management/edit-approval/:section_id"
          >
            <ChangeManagementsSectionDetailView />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/change-management/:record_version_id"
          >
            <DetailView
              moduleIdentifier="new_product_approval"
              ignoredFields={["approvers"]}
            />
          </Route>
          <Route
            key="/modules/change-management"
            path="/workspaces/:workspace_id/modules/change-management"
          >
            <ChangeManagement />
          </Route>

          <Route exact path="/workspaces/:workspace_id/modules/control-mapping">
            <ControlMapping defaultScreen="active" />
          </Route>
          <Route
            exact
            key="/modules/control-mapping/completed"
            path="/workspaces/:workspace_id/modules/control-mapping/completed"
          >
            <ControlMapping defaultScreen="completed" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/control-mapping/archived"
          >
            <Archived moduleIdentifier="control_mapping" tableName="Default" />
          </Route>

          <Route
            exact
            path="/workspaces/:workspace_id/modules/control-mapping/status"
          >
            <ControlMapping defaultScreen="status" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/control-mapping/company-library"
          >
            <ControlMapping defaultScreen="company-library" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/control-mapping/themis-control-library"
          >
            <ControlMapping defaultScreen="themis-control-library" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/control-mapping/templates/:item_id"
          >
            <CMLibraryItemDetailView />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/control-mapping/:record_version_id"
          >
            <DetailView
              moduleIdentifier="control_mapping"
              ignoredFields={["approvers", "control_owners"]}
              disabledFields={[]}
            />
          </Route>
          <Route path="/workspaces/:workspace_id/modules/control-mapping/:record_version_id/module_assessments">
            <ModuleAssessmentRoutes />
          </Route>
          <Route
            key="/modules/control-mapping/attachment_view"
            path="/workspaces/:workspace_id/modules/control-mapping/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/:identifier/:record_version_id/historical-versions"
          >
            <HistoricalVersions />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/conflicts-of-interest/:record_version_id"
          >
            <DetailView
              moduleIdentifier="conflicts_of_interest"
              showContactsApprovers
              disabledFields={["additional_info", "status"]}
              ignoredFields={["approvers"]}
            />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/audits/:record_version_id"
          >
            <DetailView
              moduleIdentifier="audits"
              disabledFields={["approval"]}
              ignoredFields={[]}
            />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/audits/:record_version_id/documents"
          >
            <AuditDetailViewDocuments />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/audits/:record_version_id/documents/create"
          >
            <BuildDocument moduleIdentifier="audits" isNew />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/audits/:record_version_id/documents/:document_id/update"
          >
            <BuildDocument moduleIdentifier="audits" />
          </Route>
          <Route path="/workspaces/:workspace_id/modules/audits/:record_version_id/findings">
            <AuditsDetailViewFindings />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/qa-testing/:record_version_id"
          >
            <DetailView
              moduleIdentifier="qa_tests_development"
              ignoredFields={["testers"]}
              disabledFields={["validator"]}
            />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/qa-testing/:record_version_id/documents/create"
          >
            <BuildDocument moduleIdentifier="qa_tests_development" isNew />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/qa-testing/:record_version_id/documents/:document_id/update"
          >
            <BuildDocument moduleIdentifier="qa_tests_development" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/documents/archived"
          >
            <Archived moduleIdentifier="documents" tableName="Default" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/documents/search"
          >
            <Documents />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/documents/:record_version_id"
          >
            <DetailView
              moduleIdentifier="documents"
              disabledFields={["status", "upload_date"]}
              ignoredFields={["approvers"]}
            />
          </Route>
          <Route exact path="/workspaces/:workspace_id/modules/documents">
            <Documents />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/documents/folder/:folder_id"
          >
            <Documents />
          </Route>
          <Route exact path="/workspaces/:workspace_id/modules/add-new-vendor">
            <BuildVendor isNew />
          </Route>
          <Route
            exact
            path={[
              activeVDDUrl,
              templatesVDDUrl,
              RATemplatesVDDUrl,
              inactiveVDDUrl,
            ]}
          >
            <VendorDueDiligence />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id"
          >
            <ViewVendor defaultScreen="ActiveVendors" />
          </Route>
          {canManageAccounts && (
            <Route path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/contracts">
              <VendorContractsRouter />
            </Route>
          )}
          <Route
            exact
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/questionnaires"
          >
            <ViewVendor defaultScreen="Questionnaires" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/risk_assessment"
          >
            <ViewVendor defaultScreen="RiskAssessment" />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/documents"
          >
            <ViewVendor defaultScreen="Documents" />
          </Route>
          <Route
            exact
            key="/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/:risk_assessment_id/update"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/:risk_assessment_id/update"
          >
            <BuildRiskAssessment />
          </Route>
          <Route
            exact
            key="/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/create"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/create"
          >
            <BuildRiskAssessment isNew />
          </Route>
          <Route
            exact
            key="/modules/vendor-due-diligence/build_risk_assessment/templates/:risk_assessment_id/update"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/build_risk_assessment/templates/:risk_assessment_id/update"
          >
            <BuildRiskAssessment isTemplate />
          </Route>
          <Route
            exact
            key="/modules/vendor-due-diligence/build_risk_assessment/templates/create"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/build_risk_assessment/templates/create"
          >
            <BuildRiskAssessment isTemplate isNew />
          </Route>
          <Route
            exact
            key="/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/:risk_assessment_id/templates/use"
            path="/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/build_risk_assessment/:risk_assessment_id/templates/use"
          >
            <BuildRiskAssessment isNew usingTemplate />
          </Route>
          <Route exact path="/workspaces/:workspace_id/modules/risk-register">
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/active"
          >
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/completed"
          >
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register-scoring-inherent"
          >
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register-scoring-residual"
          >
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/templates"
          >
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/scoring"
          >
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/themis-risk-library"
          >
            <RiskRegister />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/templates/new"
          >
            <LibraryItemDetailView />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/templates/:item_id"
          >
            <LibraryItemDetailView />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/:record_version_id"
          >
            <DetailView
              moduleIdentifier="risk_register"
              ignoredFields={["approvers", "assignee", "control_description"]}
            />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/:record_version_id/version-history"
          >
            <RiskRegisterVersionHistory />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/:record_version_id/controls"
          >
            <ModuleControlsPage />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/:record_version_id/operational-controls"
          >
            <OperationalControls />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/risk-register/:record_version_id/operational-controls-add-new"
          >
            <OperationalControlsAddNew />
          </Route>
          <Route
            key="/modules/risk-register/attachment_view"
            path="/workspaces/:workspace_id/modules/risk-register/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>

          <Route exact path="/workspaces/:workspace_id/modules/zendesk">
            <Zendesk />
          </Route>
          <Route
            exact
            path="/workspaces/:workspace_id/modules/zendesk/completed"
          >
            <Zendesk />
          </Route>
          <Route
            key="/modules/zendesk/attachment_view"
            path="/workspaces/:workspace_id/modules/zendesk/attachment_view/:record_version_id/:file_id"
          >
            <CreativeView />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default observer(DashboardContent);

import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class ConflictsStore implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  data = {};
  conflictErrors = {};
  page = 1;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      conflictErrors: observable,
      setConflictErrors: action,
      page: observable,
    });

    this.mainStore = mainStore;
  }

  async index({
    workspaceID,
    sortParams,
    tableFilters = [],
    page = 1,
  }: IndexParams) {
    try {
      const controller = new AbortController();
      this.controller = controller;
      const params = {
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
      };

      this.setPage(page);

      // @ts-expect-error TS(2339) FIXME: Property 'page' does not exist on type '{ table_fi... Remove this comment to see the full error message
      params.page = page;
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/conflicts_of_interest`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.setData(response.data);
      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(
        `"conflicts_of_interest#Index for Workspace" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceId' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceId, params) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceId}/conflicts_of_interest`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      return response;
    } catch (error) {
      window.console.log(`"conflicts_of_interest#create" error ${error}`);
    }
  }

  async create_blank() {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/conflicts_of_interest/create_blank`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Conflicts#create_blank" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  async requestMoreInformation(params) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/conflicts_of_interest/${params.recordVersionID}/request_more_information`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "There was an error. Please contact support ",
        );
        return;
      }
      this.mainStore.toast.setText("Request Sent Successfully");
      return response;
    } catch (error) {
      this.mainStore.toast.setText(
        "There was an error. Please contact support.",
      );
      window.console.log(`"conflicts_of_interest#update" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  async submitRequestMoreInformation(params) {
    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/conflicts_of_interest/${params.external_token}`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorText(
          "There was an error. Please contact support ",
        );
        return;
      }
      this.mainStore.toast.setText("Submission Successful!");
      return response;
    } catch (error) {
      this.mainStore.toast.setText(
        "There was an error. Please contact support.",
      );
      window.console.log(`"conflicts_of_interest#update" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/conflicts_of_interest/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      return true;
    } catch (error) {
      window.console.log(`"conflicts_of_interest#finalize" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/conflicts_of_interest/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"conflicts_of_interest#unlock" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
  setPage(page) {
    this.page = page;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setConflictErrors(value) {
    if (value) {
      this.conflictErrors = value;
    } else {
      this.conflictErrors = {};
    }
  }

  // Store Helpers

  cleanup() {
    this.setData({});
    this.setConflictErrors({});
  }

  abort() {
    this.controller?.abort();
  }
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetExportedFilePathParams,
  GetExportedFileQueryResponse,
} from "../../models/GetExportedFile";

/**
 * @description Get exported file
 * @summary Get exported file
 * @link /exported_files/:exported_file_id
 */
export async function getExportedFile(
  exportedFileId: GetExportedFilePathParams["exported_file_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetExportedFileQueryResponse>["data"]> {
  const res = await client<GetExportedFileQueryResponse>({
    method: "get",
    url: `/exported_files/${exportedFileId}`,
    ...options,
  });
  return res.data;
}

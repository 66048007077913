import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Loading = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2V4C11 4.55228 11.4477 5 12 5C12.5523 5 13 4.55228 13 4V2Z"
      fill="#353549"
    />
    <path
      d="M17.866 3.83985C18.1422 3.36156 17.9783 2.74997 17.5 2.47382C17.0217 2.19768 16.4101 2.36155 16.134 2.83984L15.134 4.57187C14.8578 5.05016 15.0217 5.66175 15.5 5.9379C15.9783 6.21404 16.5899 6.05017 16.866 5.57188L17.866 3.83985Z"
      fill="#353549"
    />
    <path
      d="M21.5263 6.5C21.8024 6.97829 21.6386 7.58988 21.1603 7.86603L19.4282 8.86603C18.9499 9.14217 18.3383 8.97829 18.0622 8.5C17.7861 8.02171 17.9499 7.41012 18.4282 7.13397L20.1603 6.13397C20.6386 5.85783 21.2502 6.02171 21.5263 6.5Z"
      fill="#353549"
    />
    <path
      d="M20 11C19.4477 11 19 11.4477 19 12C19 12.5523 19.4477 13 20 13H22C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11H20Z"
      fill="#353549"
    />
    <path
      d="M18.0622 15.5C18.3383 15.0217 18.9499 14.8578 19.4282 15.134L21.1603 16.134C21.6386 16.4101 21.8024 17.0217 21.5263 17.5C21.2502 17.9783 20.6386 18.1422 20.1603 17.866L18.4282 16.866C17.9499 16.5899 17.7861 15.9783 18.0622 15.5Z"
      fill="#353549"
    />
    <path
      d="M16.866 18.4282C16.5899 17.9499 15.9783 17.7861 15.5 18.0622C15.0217 18.3383 14.8578 18.9499 15.134 19.4282L16.134 21.1603C16.4101 21.6386 17.0217 21.8024 17.5 21.5263C17.9783 21.2502 18.1422 20.6386 17.866 20.1603L16.866 18.4282Z"
      fill="#353549"
    />
    <path
      d="M12 19C12.5523 19 13 19.4477 13 20V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V20C11 19.4477 11.4477 19 12 19Z"
      fill="#353549"
    />
    <path
      d="M8.86603 19.4282C9.14217 18.9499 8.97829 18.3383 8.5 18.0622C8.02171 17.7861 7.41012 17.9499 7.13397 18.4282L6.13397 20.1603C5.85783 20.6386 6.02171 21.2502 6.5 21.5263C6.97829 21.8024 7.58988 21.6386 7.86603 21.1603L8.86603 19.4282Z"
      fill="#353549"
    />
    <path
      d="M5.93777 15.5C6.21392 15.9783 6.05005 16.5899 5.57176 16.866L3.83973 17.866C3.36143 18.1422 2.74984 17.9783 2.4737 17.5C2.19755 17.0217 2.36143 16.4101 2.83972 16.134L4.57175 15.134C5.05004 14.8578 5.66163 15.0217 5.93777 15.5Z"
      fill="#353549"
    />
    <path
      d="M2 11C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13H4C4.55228 13 5 12.5523 5 12C5 11.4477 4.55228 11 4 11H2Z"
      fill="#353549"
    />
    <path
      d="M2.4737 6.5C2.74984 6.0217 3.36143 5.85783 3.83973 6.13398L5.57176 7.13398C6.05005 7.41012 6.21392 8.02171 5.93777 8.5C5.66163 8.9783 5.05004 9.14217 4.57175 8.86602L2.83972 7.86602C2.36143 7.58988 2.19755 6.97829 2.4737 6.5Z"
      fill="#353549"
    />
    <path
      d="M7.86602 2.83984C7.58988 2.36155 6.97829 2.19768 6.5 2.47382C6.0217 2.74997 5.85783 3.36156 6.13398 3.83985L7.13398 5.57188C7.41012 6.05017 8.02171 6.21404 8.5 5.9379C8.9783 5.66175 9.14217 5.05016 8.86602 4.57187L7.86602 2.83984Z"
      fill="#353549"
    />
  </svg>
);

Loading.displayName = "LoadingIcon";

export default Loading;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Crop = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99327 2.88338C7.93551 2.38604 7.51284 2 7 2C6.44772 2 6 2.44772 6 3V6H3C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H6V17L6.00673 17.1166C6.06449 17.614 6.48716 18 7 18H16V21C16 21.5523 16.4477 22 17 22C17.5523 22 18 21.5523 18 21V18H21L21.1166 17.9933C21.614 17.9355 22 17.5128 22 17L21.9933 16.8834C21.9355 16.386 21.5128 16 21 16H8V3L7.99327 2.88338ZM18 7C18 6.44772 17.5523 6 17 6H11C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8H16V13C16 13.5523 16.4477 14 17 14C17.5523 14 18 13.5523 18 13V7Z"
      fill="#353549"
    />
  </svg>
);

Crop.displayName = "CropIcon";

export default Crop;

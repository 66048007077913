import { observer } from "mobx-react";
import type { ReactNode } from "react";
import React from "react";

import NoAccess from "@/components/NoAccess";
import { useMainStore } from "@/contexts/Store";
import type { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

function FeatureFlagOrAccessWrapper(props: {
  noAccessMessage: string;
  featureFlagId?: FEATURE_FLAG_ID;
  enabled?: boolean;
  children: ReactNode;
}) {
  const mainStore = useMainStore();

  const isEnabled =
    // @ts-expect-error TS(2345) FIXME: Argument of type 'FEATURE_FLAG_ID | undefined' is ... Remove this comment to see the full error message
    props.enabled || mainStore.featureFlags.getIsEnabled(props.featureFlagId);

  if (isEnabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- required for valid return type
    return <>{props.children}</>;
  }

  return <NoAccess message={props.noAccessMessage} />;
}

export default observer(FeatureFlagOrAccessWrapper);

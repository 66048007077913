import { LOADING_DELAY } from "../components/constants";

export function scrollToCustomColumn(
  sectionTableID?: string,
  inlinePosition: ScrollLogicalPosition = "center",
) {
  setTimeout(() => {
    const elements = sectionTableID
      ? document.querySelectorAll(`#${sectionTableID} .list-column-wrap li`)
      : document.querySelectorAll(".list-column-wrap li");
    if (elements.length > 0) {
      elements[elements.length - 1].scrollIntoView({
        behavior: "smooth",
        inline: inlinePosition,
        block: "nearest",
      });
    }
  }, LOADING_DELAY);
}

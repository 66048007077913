import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListThemisCustomAuditsForThemisRecordPathParams,
  ListThemisCustomAuditsForThemisRecordQueryParams,
  ListThemisCustomAuditsForThemisRecordQueryResponse,
} from "../../models/ListThemisCustomAuditsForThemisRecord";

/**
 * @description Audit Trail for a Themis Record
 * @summary Audit Trail for a Themis Record
 * @link /:record_type/:record_id/audits
 */
export async function listThemisCustomAuditsForThemisRecord(
  recordType: ListThemisCustomAuditsForThemisRecordPathParams["record_type"],
  recordId: ListThemisCustomAuditsForThemisRecordPathParams["record_id"],
  params?: ListThemisCustomAuditsForThemisRecordQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<ListThemisCustomAuditsForThemisRecordQueryResponse>["data"]
> {
  const res = await client<ListThemisCustomAuditsForThemisRecordQueryResponse>({
    method: "get",
    url: `/${recordType}/${recordId}/audits`,
    params,
    ...options,
  });
  return res.data;
}

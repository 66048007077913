import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ImportContactsMutationRequest,
  ImportContactsMutationResponse,
  ImportContactsPathParams,
} from "../../models/ImportContacts";

/**
 * @description Imports contacts into a specified workspace from an uploaded file (CSV or XLSX).
 * @summary Import contacts
 * @link /workspaces/:workspace_id/contacts/import
 */
export async function importContacts(
  workspaceId: ImportContactsPathParams["workspace_id"],
  data?: ImportContactsMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ImportContactsMutationResponse>["data"]> {
  const res = await client<
    ImportContactsMutationResponse,
    ImportContactsMutationRequest
  >({
    method: "post",
    url: `/workspaces/${workspaceId}/contacts/import`,
    data,
    ...options,
  });
  return res.data;
}

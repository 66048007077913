import classNames from "classnames";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";

import Button from "@/components/form/Button";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import Header from "../components/Header";

const DIGITS_COUNT = 6; // code length
const CODE_LIFETIME = 5; // minutes
const COPY_FOR_MFA_TYPES = {
  email: {
    resendTrigger: "Didn’t get your code? Click here to resend one!",
    resendSuccessToast: "A new code has been sent to your email address!",
  },
  otp: {
    resendTrigger: "Click here to refresh!",
    resendSuccessToast: "Code has been refreshed!",
  },
};

function MultiFactorAuth() {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [digits, setDigits] = useState([...Array(DIGITS_COUNT)]);
  const [expireTime, setExpireTime] = useState("05:00");
  const [isExpired, setIsExpired] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [submitInProcess, setSubmitInProcess] = useState(false);
  const [error, setError] = useState(null);
  const [otpData, setOtpData] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);

  // refs
  const internal = useRef();

  // vars
  let codeSentTime;
  const mfaType = "otp"; // hardcoded for now
  const shouldRenderTimer = mfaType !== "otp";

  // effects
  // @ts-expect-error TS(2345) FIXME: Argument of type '(isResend?: boolean) => Promise<... Remove this comment to see the full error message
  useEffect(handleEnroll, []);

  useEffect(() => {
    setSubmitEnabled(
      !isExpired &&
        !submitInProcess &&
        digits.every((item) => Number(item) > -1),
    );
  }, [digits, isExpired, submitInProcess]);

  // funcs
  function handleInitInterval() {
    if (!shouldRenderTimer) {
      return;
    }

    const timeout = 1000;
    codeSentTime = dayjs();
    const expireAt = dayjs().add(CODE_LIFETIME, "minutes");
    let duration = dayjs(expireAt).diff(dayjs(codeSentTime));

    // @ts-expect-error TS(2322) FIXME: Type 'Timer' is not assignable to type 'undefined'... Remove this comment to see the full error message
    internal.current = setInterval(() => {
      duration -= timeout;

      if (duration >= 0) {
        const minutes = Math.floor(duration / (60 * 1000));
        const seconds = Math.floor((duration % (60 * 1000)) / 1000);
        const text = `${minutes > 9 ? "" : "0"}${minutes}:${
          seconds > 9 ? "" : "0"
        }${seconds}`;
        return setExpireTime(text);
      }

      setIsExpired(true);
      clearInterval(internal.current);
    }, timeout);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleDigitChange(e, index) {
    const { data } = e.nativeEvent;
    const digit = Number(data);

    setDigits(digits.map((item, idx) => (idx === index ? digit : item)));

    e.target.value = digit; // bugfix for 0X
    const nextElem = document.querySelector(
      `.mfa-digits-row input:nth-child(${index + 2})`,
    );
    if (nextElem) {
      // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type 'Element'.
      nextElem.focus();
    } else {
      (document.activeElement as HTMLElement).blur();
    }

    if (error) {
      setError(null);
    }
  }

  async function handleSubmit() {
    setSubmitInProcess(true);
    const code = digits.map((item) => item.toString()).join("");

    // @ts-expect-error TS(2461) FIXME: Type 'any[] | undefined' is not an array type.
    const [success, data] = await mainStore.users.validateMFACode(code);

    if (success) {
      return (window.location.href = "/");
    }

    setSubmitInProcess(false);
    handleError(data);
  }

  async function handleEnroll(isResend = false) {
    setSubmitInProcess(true);

    // @ts-expect-error TS(2461) FIXME: Type 'any[] | undefined' is not an array type.
    const [success, data] = await mainStore.users.enrollMFA();
    setSubmitInProcess(false);

    if (initialLoad) {
      setInitialLoad(false);
    }
    if (!success) {
      return handleError(data);
    }
    if (isResend) {
      mainStore.toast.setInfoText(
        COPY_FOR_MFA_TYPES[mfaType].resendSuccessToast,
      );
    }
    if (data.secret) {
      setOtpData(data);
    }
    if (!shouldRenderTimer) {
      return;
    }

    setIsExpired(false);
    clearInterval(internal.current);
    handleInitInterval();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  function handleError(data) {
    if (data.invalid_mfa_token) {
      mainStore.toast.setErrorText(data.error);
      return handleSignOut();
    }

    if (data.not_challenged) {
      return (window.location.href = "/");
    }

    return setError(data.error);
  }

  function handleSignOut() {
    mainStore.webSessions.delete();
  }

  // renders
  const renderHeading = () => {
    if (mfaType === "otp") {
      if (otpData) {
        return <h3>Setup your Two-Factor Authentication</h3>;
      }
      return <h3>Enter your Two-Factor code</h3>;
    }

    return (
      <>
        <h5>Two-Factor Authentication</h5>
        <h6>Enter the code sent to your email address</h6>
      </>
    );
  };

  // @ts-expect-error TS(2339) FIXME: Property 'barcode_uri' does not exist on type 'nev... Remove this comment to see the full error message
  const renderQRCode = otpData?.barcode_uri && (
    <div className="qr-code-container">
      {/* @ts-expect-error TS(2339) FIXME: Property 'barcode_uri' does not exist on type 'nev... Remove this comment to see the full error message */}
      <QRCode value={otpData.barcode_uri} />
    </div>
  );

  // @ts-expect-error TS(2339) FIXME: Property 'secret' does not exist on type 'never'.
  const renderQRCodeSecret = otpData?.secret && (
    <div className="qr-code-secret">
      {/* @ts-expect-error TS(2339) FIXME: Property 'secret' does not exist on type 'never'. */}
      {otpData.secret}
    </div>
  );

  const renderInputsRow = (
    <div className={classNames("mfa-digits-row", { error })}>
      {[...Array(DIGITS_COUNT)].map((_, index) => (
        <input
          key={index}
          autoFocus={index === 0}
          type="number"
          pattern="[0-9]"
          min="0"
          max="9"
          onChange={(e) => handleDigitChange(e, index)}
          value={digits[index]}
          placeholder="•"
        />
      ))}
    </div>
  );

  const renderExpireAt = (
    <div className="mfa-expire-at">Code Expire Time: {expireTime}</div>
  );

  const renderSubmit = (
    <div className="mfa-submit">
      <Button title="Verify" enabled={submitEnabled} onClick={handleSubmit} />
    </div>
  );

  const renderResend = (
    <div
      className={classNames("mfa-resend", { disabled: submitInProcess })}
      onClick={() => handleEnroll(true)}
    >
      {COPY_FOR_MFA_TYPES[mfaType].resendTrigger}
    </div>
  );

  const renderSignOut = (
    <span onClick={handleSignOut} className="sign-out">
      Sign Out
    </span>
  );

  if (initialLoad) {
    return <Loading loadingLayout="grid" />;
  }

  return (
    <div className={classNames("check-in mfa-container", mfaType)}>
      <Header />
      {renderHeading()}
      {mfaType === "otp" && otpData && (
        <>
          {renderQRCode}
          <p>
            To setup your two-factor authentication, scan the QR code above, or
            enter the secret below in the Google Authenticator app.
          </p>
          {renderQRCodeSecret}
          <p>
            Once you’ve scanned the QR code or entered the secret, complete the
            setup by entering the code listed in your Google Authenticator app.
          </p>
        </>
      )}
      {renderInputsRow}
      {shouldRenderTimer && renderExpireAt}
      {renderSubmit}
      {(mfaType !== "otp" || otpData) && renderResend}
      {error && <div className="mfa-error">{error}</div>}
      {renderSignOut}
    </div>
  );
}

export default observer(MultiFactorAuth);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Congrats = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3C21.5523 3 22 2.55228 22 2C22 1.44772 21.5523 1 21 1C20.4477 1 20 1.44772 20 2C20 2.55228 20.4477 3 21 3ZM9.99999 3.5C10.5523 3.5 11 3.05228 11 2.5C11 1.94772 10.5523 1.5 9.99999 1.5C9.44771 1.5 8.99999 1.94772 8.99999 2.5C8.99999 3.05228 9.44771 3.5 9.99999 3.5ZM22 13.5C22 14.0523 21.5523 14.5 21 14.5C20.4477 14.5 20 14.0523 20 13.5C20 12.9477 20.4477 12.5 21 12.5C21.5523 12.5 22 12.9477 22 13.5ZM19.5 19C20.0523 19 20.5 18.5523 20.5 18C20.5 17.4477 20.0523 17 19.5 17C18.9477 17 18.5 17.4477 18.5 18C18.5 18.5523 18.9477 19 19.5 19ZM15.2071 2.79289C14.8166 2.40237 14.1834 2.40237 13.7929 2.79289C13.4024 3.18342 13.4024 3.81658 13.7929 4.20711C14.1204 4.53458 14.1647 4.7758 14.135 4.98358C14.0958 5.2583 13.8916 5.69422 13.2929 6.29289L10.7929 8.79289C10.4024 9.18342 10.4024 9.81658 10.7929 10.2071C11.1834 10.5976 11.8166 10.5976 12.2071 10.2071L14.7071 7.70711C15.4418 6.97245 15.9875 6.15837 16.1149 5.26642C16.2519 4.30753 15.8796 3.46542 15.2071 2.79289ZM8.05366 7.93438C7.81218 7.68626 7.45954 7.58071 7.12146 7.65535C6.78337 7.72999 6.50795 7.9742 6.39338 8.30093L2.05633 20.6689C1.93087 21.0267 2.01854 21.4247 2.28272 21.6966C2.54691 21.9686 2.94217 22.0677 3.30344 21.9527L15.8034 17.9719C16.1337 17.8668 16.3859 17.5981 16.4701 17.2619C16.5542 16.9257 16.4583 16.57 16.2166 16.3216L8.05366 7.93438ZM4.6072 19.4385L7.74645 10.4863L13.655 16.5571L4.6072 19.4385ZM19.5 9.75C19.0254 9.75 18.4314 9.98277 17.7071 10.7071L15.2071 13.2071C14.8166 13.5976 14.1834 13.5976 13.7929 13.2071C13.4024 12.8166 13.4024 12.1834 13.7929 11.7929L16.2929 9.29289C17.2352 8.35056 18.3079 7.75 19.5 7.75C20.6921 7.75 21.7648 8.35056 22.7071 9.29289C23.0976 9.68342 23.0976 10.3166 22.7071 10.7071C22.3166 11.0976 21.6834 11.0976 21.2929 10.7071C20.5686 9.98277 19.9746 9.75 19.5 9.75Z"
      fill="#353549"
    />
  </svg>
);

Congrats.displayName = "CongratsIcon";

export default Congrats;

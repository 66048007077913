import React from "react";

import InputTable from "../InputTable";
import ItemsSelect from "../ItemsSelect";

interface Props {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...args: any[]) => any;
  disabled?: boolean;
  external?: boolean;
}

function MembershipSelect({ name, onChange, external, disabled }: Props) {
  // Variables
  const selectedValue = external ? "external" : "internal";
  const options = [
    {
      value: "internal",
      label: "Internal",
    },
    {
      value: "external",
      label: "External",
    },
  ];

  return disabled ? (
    <InputTable
      name={name}
      value={options.find((option) => option.value === selectedValue)?.label}
      disabled
    />
  ) : (
    <ItemsSelect
      name={name}
      value={selectedValue}
      options={options}
      onChange={onChange}
    />
  );
}

MembershipSelect.defaultProps = {
  external: false,
};

export default MembershipSelect;

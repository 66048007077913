import { Button } from "@themis/ui";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

import type { Task } from "@/api";
import { useProject } from "@/api/queries/projects";
import { useMainStore } from "@/contexts/Store";
import type { PROJECT_ID_PARAM } from "@/features/projects/pages/ProjectRoutes";
import { MY_TASKS_ROUTES } from "@/features/tasks/pages/index";

import moduleIcons from "../../../images/modules.webp";

export function TasksEmptyState() {
  const {
    taskDetail,
    users: { user },
  } = useMainStore();
  const { pathname } = useLocation();
  const { workspace_id, project_id } = useParams<{
    workspace_id: string;
    [PROJECT_ID_PARAM]: string;
  }>();

  const { data: project } = useProject({
    workspaceId: Number(workspace_id),
    projectId: Number(project_id),
  });

  const isAssignedToMeView = pathname === MY_TASKS_ROUTES.assignedToMeView;
  const isProjectView = Boolean(project_id);
  const newTaskParams: Partial<Task> = {};

  if (isAssignedToMeView) {
    newTaskParams.assignee_id = user.id;
  }

  if (isProjectView) {
    newTaskParams.parent_id = Number(project_id);
    newTaskParams.workspace_id = Number(project?.data.workspace_id);
    newTaskParams.visibility = project?.data.visibility;
  }

  return (
    <div className="tw--my-[25px] tw-flex tw-flex-col tw-items-center tw-gap-3 tw-text-center tw-font-semibold tw-text-neutral-500">
      <img src={moduleIcons} />
      <p>Tasks are core items that users complete to achieve success</p>
      <Button size="sm" onClick={() => taskDetail.open(newTaskParams)}>
        Add New Task
      </Button>
    </div>
  );
}

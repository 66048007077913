import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import { Icon } from "../Elements";
import {
  formatDateForCellValue,
  stringToDate,
} from "../helpers/DateFormatters";
import { EMPTY_OPTIONS, NUMBER_OF_DAYS } from "./constants";
import FiltersDateNew from "./FiltersDateNew";
import type { FilterOption, Step } from "./types";

interface FiltersDateAppliedProps {
  filterOption: FilterOption;
  fieldName: string;
}

const FiltersDateApplied = ({
  filterOption,
  fieldName,
}: FiltersDateAppliedProps) => {
  const mainStore = useMainStore();

  const { getTableFiltersParam, setTableFiltersParam } = useUpdateFilter();
  const dateFilter = getTableFiltersParam().find(
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    (item) => item.name === fieldName,
  );
  const displayName = mainStore.avroSchemas.displayNameForField(fieldName);

  const [editMode, setEditMode] = useState(false);
  const [selectedStep, setSelectedStep] = useState<Step>();
  const [exactDates, setExactDates] = useState({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (showExactDates) {
      setExactDates({
        from: dateFilter.options[0].value,
        to: dateFilter.options[1].value,
      });
    }
  }, [getTableFiltersParam]);

  const { condition = "", label = "", numOfDays, value } = filterOption;

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const handleRemoveFilterOption = (e) => {
    e.stopPropagation();

    const existedFilter = getTableFiltersParam().find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) => item.name === fieldName,
    );

    setTableFiltersParam(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      getTableFiltersParam().filter((item) => item.name !== existedFilter.name),
      false,
      true,
      true,
    );
  };

  const setConditionStep = () => {
    setSelectedStep("condition");
    setEditMode(true);
  };

  const setRangeStep = () => {
    setSelectedStep(condition === "is within" ? "withinRange" : "timeRange");
    setEditMode(true);
  };

  const setNumOfDaysStep = () => {
    setSelectedStep("numOfDays");
    setEditMode(true);
  };

  const setExactDateStep = () => {
    setSelectedStep("exactDate");
    setEditMode(true);
  };

  const setExactDatesStep = () => {
    setSelectedStep("exactDates");
    setEditMode(true);
  };

  const isWithinSelected = condition === "is within";
  const isEmptyCondition = EMPTY_OPTIONS.includes(condition);

  const showExactDate =
    condition && !isEmptyCondition && label === "Exact Date";
  const showExactDates = isWithinSelected && label === "Exact Dates";
  const showLabel =
    condition &&
    !isEmptyCondition &&
    !showExactDate &&
    !NUMBER_OF_DAYS.includes(label) &&
    !showExactDates;
  const showNumOfDays =
    condition &&
    !isEmptyCondition &&
    !showExactDate &&
    NUMBER_OF_DAYS.includes(label);

  const numOfDaysDisplayed = numOfDays
    ? label.replace("# of", numOfDays.toString())
    : "...";

  return editMode ? (
    <FiltersDateNew
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      fieldName={displayName}
      clearField={() => setEditMode(false)}
      existingFilterOption={filterOption}
      otherExistingFilterOption={dateFilter.options?.[1]}
      selectedStep={selectedStep}
    />
  ) : (
    <div
      className="date-filter-container filter-date-applied"
      data-testid="filter-date-applied"
    >
      <div className="date-filter">{displayName}</div>
      <div onClick={setConditionStep} className="date-filter">
        {filterOption.condition}
      </div>

      {showLabel && (
        <div
          onClick={setRangeStep}
          className={classNames("date-filter label", {
            "final-selection": !!label,
          })}
        >
          {isWithinSelected && numOfDays ? numOfDaysDisplayed : label}
        </div>
      )}
      {showNumOfDays && (
        <div
          onClick={setNumOfDaysStep}
          className={classNames("date-filter numOfDays", {
            "final-selection": !!numOfDays,
          })}
        >
          {numOfDaysDisplayed}
        </div>
      )}
      {showExactDate && (
        <div
          onClick={setExactDateStep}
          className={classNames("date-filter exactDate", {
            "final-selection": !!value,
          })}
        >
          {/* @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message */}
          {formatDateForCellValue(stringToDate(value), true)}
        </div>
      )}
      {showExactDates && exactDates.from && (
        <div
          onClick={setExactDatesStep}
          className={classNames("date-filter exactDates", {
            "final-selection": !!(exactDates.to || exactDates.from),
          })}
        >
          {`${formatDateForCellValue(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
            stringToDate(exactDates.from),
            true,
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
          )} - ${formatDateForCellValue(stringToDate(exactDates.to), true)}`}
        </div>
      )}

      <div onClick={handleRemoveFilterOption} className="close date-filter">
        <Icon name="close" color="generalDark" size="de" />
      </div>
    </div>
  );
};

export default FiltersDateApplied;

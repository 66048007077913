import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import { Button, TextButton, Typography } from "../../Elements";
import DuplicateWorkspaceLoader from "./DuplicateWorkspaceLoader";
import { compareModules } from "./helpers";
import ModuleSelectionItem from "./ModuleSelectionItem";

interface ModuleSelectionProps {
  sourceWorkspaceId: string;
  newWorkspaceName: string;
  onContinue: () => void;
  onBack: () => void;
  onCancel: () => void;
  loading: boolean;
  handleSwitch: (identifier: string) => void;
  handleRecordsIncluded: (identifier: string) => void;
  selectedModules: string[];
  modulesWithRecords: string[];
  defaultModules: string[];
}

const MoudleSelection = (props: ModuleSelectionProps) => {
  const mainStore = useMainStore();

  const isLoading =
    mainStore.workspaceSummary.currentWorkspaceId !== props.sourceWorkspaceId ||
    mainStore.workspaceSummary.modules.length === 0;

  useEffect(() => {
    mainStore.workspaceSummary.index(props.sourceWorkspaceId);
  }, [props.sourceWorkspaceId]);

  return (
    <div className="duplicate-preview">
      <Typography
        label="Review your Workspace template below"
        weight="semiBold"
        color="generalLightBlue"
      />
      <Typography
        label={props.newWorkspaceName}
        size="smh"
        weight="semiBold"
        color="generalMidnightDark"
        className="duplicate-preview__name"
      />

      {isLoading && <DuplicateWorkspaceLoader />}
      {!isLoading && (
        <div className="duplicate-preview__modules">
          <Typography
            label="Included Internal Modules"
            color="brandingDimViolet"
            size="sm"
            weight="semiBold"
            className="duplicate-preview__modules-title"
          />
          {[...mainStore.workspaceSummary.modules]
            .sort((a, b) => compareModules(a, b, props.defaultModules))
            .map((module) => {
              const isEnabled = props.selectedModules.includes(
                module.identifier,
              );
              const areRecordsIncluded = props.modulesWithRecords.includes(
                module.identifier,
              );
              return (
                <ModuleSelectionItem
                  key={module.id}
                  module={module}
                  isEnabled={isEnabled}
                  handleSwitch={(identifier) => props.handleSwitch(identifier)}
                  areRecordsIncluded={areRecordsIncluded}
                  handleRecordsIncluded={(identifier) =>
                    props.handleRecordsIncluded(identifier)
                  }
                  defaultModules={props.defaultModules}
                />
              );
            })}
        </div>
      )}

      <div className="duplicate-preview__actions">
        <Button
          label="Back"
          theme="tertiary"
          onClick={props.onBack}
          disabled={props.loading}
        />
        <Button
          label="Continue"
          onClick={props.onContinue}
          disabled={isLoading || props.loading}
          active={props.loading}
        />
      </div>
      <TextButton
        label="Cancel"
        theme="destructive"
        onClick={props.onCancel}
        disabled={props.loading}
      />
    </div>
  );
};

export default observer(MoudleSelection);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateTagMutationRequest,
  CreateTagMutationResponse,
  CreateTagPathParams,
} from "../../models/CreateTag";

/**
 * @description Create tag
 * @summary Create tag
 * @link /companies/:company_id/tags
 */
export async function createTag(
  companyId: CreateTagPathParams["company_id"],
  data: CreateTagMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateTagMutationResponse>["data"]> {
  const res = await client<CreateTagMutationResponse, CreateTagMutationRequest>(
    {
      method: "post",
      url: `/companies/${companyId}/tags`,
      data,
      ...options,
    },
  );
  return res.data;
}

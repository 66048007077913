// @ts-expect-error TS(7006) FIXME: Parameter 'el' implicitly has an 'any' type.
export const scrollIntoViewIfNeeded = (el) => {
  if (!el) {
    return;
  }

  if (typeof el.scrollIntoViewIfNeeded === "function") {
    el.scrollIntoViewIfNeeded();
  } else if (typeof el.scrollIntoView === "function") {
    el.scrollIntoView();
  }
};

import React from "react";
import { useHistory } from "react-router-dom";
import { cn } from "../../lib/utils";
import { Tabs, TabsList, TabsTrigger } from "./Tabs";
export function HeaderTabs(_a) {
    var tabs = _a.tabs, tabButtons = _a.tabButtons, selectedTab = _a.selectedTab, onSelectTab = _a.onSelectTab, isLink = _a.isLink, tabsClassName = _a.tabsClassName, tabsListClassName = _a.tabsListClassName, dataTestId = _a.dataTestId, _b = _a.border, border = _b === void 0 ? false : _b;
    var history = useHistory();
    return (React.createElement(Tabs, { value: selectedTab, onValueChange: onSelectTab
            ? onSelectTab
            : function (location) {
                if (isLink) {
                    history.push(location);
                }
            }, className: cn("tw-flex tw-h-full tw-items-end tw-border-0", tabsClassName) },
        React.createElement(TabsList, { className: cn("tw-h-[34px] tw-gap-1 tw-rounded-none tw-border-0 tw-pb-0.5", {
                "tw-border-b tw-border-solid tw-border-primaryDim-100": border,
            }, tabsListClassName), "data-testid": dataTestId }, tabs.map(function (tab, tabIndex) {
            var tabButton = tabButtons === null || tabButtons === void 0 ? void 0 : tabButtons.find(function (btn) { return btn.tabIndex === tabIndex; });
            if (tab.hasPermission === false) {
                return null;
            }
            return (React.createElement(TabsTrigger, { key: "".concat(tab.name, " trigger"), value: tab.value, className: "tw-mb-[1px] tw-rounded-none tw-p-0 tw-px-3 tw-text-sm tw-font-medium tw-text-neutral-500 hover:tw-bg-primaryDim-25 data-[state=active]:tw-mb-0 data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2 data-[state=active]:tw-border-solid data-[state=active]:tw-border-primary-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-font-semibold data-[state=active]:tw-text-primary-300", "data-testid": tab.dataTestId },
                tab.name,
                tabButton && tabButton.button));
        }))));
}

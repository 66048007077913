import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteFavoriteModuleWorkspaceMutationResponse,
  DeleteFavoriteModuleWorkspacePathParams,
} from "../../models/DeleteFavoriteModuleWorkspace";

/**
 * @description Delete a favorite module workspace
 * @summary Deleta a favorite module workspace
 * @link /favorites/:id
 */
export async function deleteFavoriteModuleWorkspace(
  id: DeleteFavoriteModuleWorkspacePathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<DeleteFavoriteModuleWorkspaceMutationResponse>["data"]
> {
  const res = await client<DeleteFavoriteModuleWorkspaceMutationResponse>({
    method: "delete",
    url: `/favorites/${id}`,
    ...options,
  });
  return res.data;
}

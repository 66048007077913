import classNames from "classnames";
import { sortBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import { Button, Icon } from "../Elements";
import {
  formatDateForCellValue,
  stringToDate,
} from "../helpers/DateFormatters";
import {
  CONDITIONS,
  EMPTY_OPTIONS,
  IS_WITHIN_RANGE,
  NUMBER_OF_DAYS,
  NUMBER_OF_DAYS_WITHIN,
  TIME_RANGE,
} from "./constants";
import { getSelectedDate } from "./dateFilterHelper";
import FilterCalendar from "./FilterCalendar";
import type { FilterOption, Step } from "./types";

interface FiltersDateNewProps {
  fieldName: string;
  onAddFilter?: () => void;
  clearField: () => void;
  toggleShowFilter?: (value?: boolean) => void;
  existingFilterOption?: FilterOption;
  otherExistingFilterOption?: FilterOption;
  selectedStep?: Step;
}

const FiltersDateNew = ({
  fieldName,
  clearField,
  onAddFilter,
  toggleShowFilter,
  existingFilterOption,
  otherExistingFilterOption,
  selectedStep,
}: FiltersDateNewProps) => {
  const mainStore = useMainStore();

  const inputRef = useRef(null);

  const { getTableFiltersParam, setTableFiltersParam } = useUpdateFilter();

  const getCurrentStep = () => {
    if (!existingFilterOption) {
      return "condition";
    }

    if (existingFilterOption && selectedStep) {
      return selectedStep;
    }

    if (existingFilterOption.label) {
      return "timeRange";
    }

    if (existingFilterOption.numOfDays) {
      return "numOfDays";
    }

    if (existingFilterOption.value) {
      return "exactDate";
    }

    return "condition";
  };

  const initialFromDate = existingFilterOption
    ? // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
      formatDateForCellValue(stringToDate(existingFilterOption.value), true)
    : "";
  const initialToDate = otherExistingFilterOption
    ? formatDateForCellValue(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
        stringToDate(otherExistingFilterOption.value),
        true,
      )
    : "";

  const [open, setOpen] = useState(true);
  const [condition, setCondition] = useState<(typeof CONDITIONS)[number]>(
    existingFilterOption?.condition || "is",
  );
  const [timeRange, setTimeRange] = useState<(typeof TIME_RANGE)[number]>(
    existingFilterOption?.label || "",
  );
  const [withinRange, setWithinRange] = useState<
    (typeof IS_WITHIN_RANGE)[number]
  >(existingFilterOption?.label || "");

  const [numOfDays, setNumOfDays] = useState<number>(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
    existingFilterOption?.numOfDays || null,
  );
  const [disabled, setDisabled] = useState(true);
  const [exactDate, setExactDate] = useState(existingFilterOption?.value || "");
  const [exactDates, setExactDates] = useState({
    from: initialFromDate,
    to: initialToDate,
  });

  const [currentStep, setCurrentStep] = useState<Step>(getCurrentStep());

  const isEmptyCondition = EMPTY_OPTIONS.includes(condition);
  const isWithinSelected = condition === "is within";

  const showExactDate =
    condition && !isEmptyCondition && timeRange === "Exact Date";
  const showExactDates = isWithinSelected && withinRange === "Exact Dates";
  const showTimeRange =
    condition &&
    !isEmptyCondition &&
    !showExactDate &&
    !NUMBER_OF_DAYS.includes(timeRange) &&
    !isWithinSelected;
  const showWithinRange =
    condition &&
    !isEmptyCondition &&
    !showExactDates &&
    !NUMBER_OF_DAYS_WITHIN.includes(withinRange) &&
    isWithinSelected;
  const showNumOfDays =
    condition &&
    !isEmptyCondition &&
    !showExactDate &&
    (NUMBER_OF_DAYS.includes(timeRange) ||
      NUMBER_OF_DAYS_WITHIN.includes(withinRange));

  const field = mainStore.fields.list.find(
    (fieldData) => fieldData.display_name === fieldName,
  );

  const timeRangeSelected = () => {
    const dateValue =
      timeRange === "Exact Date"
        ? new Date(exactDate)
        : getSelectedDate(timeRange, numOfDays);

    let filter;
    if (isEmptyCondition) {
      filter = {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        name: field.name,
        condition,
      };
    } else {
      filter = {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        name: field.name,
        condition,
        value: formatDateForCellValue(dateValue) || null,
        label: timeRange,
        numOfDays: numOfDays || null,
      };
    }

    if (existingFilterOption) {
      updateExistingFilter(filter);
    } else {
      handleAddDateFilterOption(filter);
      onAddFilter?.();
    }
  };

  const withinRangeSelected = () => {
    const fromDate = exactDates.from ? new Date(exactDates.from) : new Date();
    const toDate = exactDates.to ? new Date(exactDates.to) : new Date();

    const dateValue =
      withinRange === "Exact Dates" ? fromDate : getSelectedDate();
    const otherDateValue =
      withinRange === "Exact Dates"
        ? toDate
        : getSelectedDate(withinRange, numOfDays);

    const sharedFilterValues = {
      condition,
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      name: field.name,
    };

    const filter = {
      ...sharedFilterValues,
      label: "Today",
      value: formatDateForCellValue(dateValue) || null,
      numOfDays: numOfDays ? 0 : null,
    };

    const otherFilter = {
      ...sharedFilterValues,
      label: withinRange,
      value: formatDateForCellValue(otherDateValue) || null,
      numOfDays: numOfDays || null,
    };

    if (existingFilterOption) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ label: string; value: string |... Remove this comment to see the full error message
      updateExistingFilter(filter, otherFilter);
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ label: string; value: string |... Remove this comment to see the full error message
      handleAddDateFilterOption(filter, otherFilter);
      onAddFilter?.();
    }
  };

  useEffect(() => {
    if (!open) {
      toggleShowFilter?.(true);
    }

    if (open || !condition) {
      return;
    }

    if (!isWithinSelected && (timeRange || isEmptyCondition)) {
      timeRangeSelected();
    } else if (withinRange) {
      withinRangeSelected();
    }
  }, [open]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'newFilter' implicitly has an 'any' type... Remove this comment to see the full error message
  const updateExistingFilter = (newFilter, otherFilter = undefined) => {
    setTableFiltersParam(
      // @ts-expect-error TS(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
      getTableFiltersParam().map((filter) =>
        filter.name === newFilter.name
          ? {
              name: newFilter.name,
              condition: newFilter.condition,
              options: otherFilter
                ? sortBy(
                    [newFilter, otherFilter],
                    (filterItem) => filterItem?.value,
                  )
                : [newFilter],
            }
          : filter,
      ),
    );
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'filter' implicitly has an 'any' type.
  const handleAddDateFilterOption = (filter, otherFilter = undefined) => {
    const filterField = getTableFiltersParam().find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'filterItem' implicitly has an 'any' typ... Remove this comment to see the full error message
      (filterItem) => filterItem.name === filter.name,
    );

    if (!filterField) {
      return setTableFiltersParam([
        ...getTableFiltersParam(),
        {
          name: filter.name,
          condition: filter.condition,
          options: otherFilter
            ? sortBy([filter, otherFilter], (filterItem) => filterItem?.value)
            : [filter],
        },
      ]);
    }

    const filterFieldOptionIsPresent = filterField.options.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) =>
        item.data_type === filter.data_type &&
        item.condition === filter.condition &&
        item.label === filter.label,
    );

    if (!filterFieldOptionIsPresent) {
      setTableFiltersParam(
        getTableFiltersParam().map(
          // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
          (item) =>
            item.name === filter.name
              ? {
                  ...item,
                  options: otherFilter ? [filter, otherFilter] : [filter],
                }
              : item,
        ),
      );
    }
  };

  const handleSelectTimeRange = (item: (typeof TIME_RANGE)[number]) => {
    setTimeRange(item);

    if (item === "Exact Date") {
      setCurrentStep("exactDate");
    } else if (NUMBER_OF_DAYS.includes(item)) {
      setCurrentStep("numOfDays");
    } else {
      setOpen(false);
    }
  };

  const handleSelectWithinTimeRange = (item: (typeof TIME_RANGE)[number]) => {
    setWithinRange(item);

    if (item === "Exact Dates") {
      setCurrentStep("exactDates");
    } else if (NUMBER_OF_DAYS_WITHIN.includes(item)) {
      setCurrentStep("numOfDays");
    } else {
      setOpen(false);
    }
  };

  const handleSelectNumOfDays = () => {
    if (!inputRef.current) {
      return;
    }

    const { value } = inputRef.current;

    if (value && Number(value)) {
      setNumOfDays(Number(value));
      setOpen(false);
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setNumOfDays(null);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const handleSelectCondition = (item) => {
    setCondition(item);

    if (EMPTY_OPTIONS.includes(item)) {
      setOpen(false);
    } else if (item === "is within") {
      setCurrentStep("withinRange");
    } else {
      setCurrentStep("timeRange");
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSelectNumOfDays();
    }

    const currentValue = event.target.value;
    const skipDisable = currentValue.length > 0 && Number(currentValue);

    if (skipDisable || Number(event.key)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
  const handleSelectExactDate = (date) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    setExactDate(formatDateForCellValue(date, true));
    setOpen(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
  const handleSelectExactDates = (date) => {
    setExactDates({
      from: formatDateForCellValue(date[0], true),
      to: formatDateForCellValue(date[1], true),
    });
  };

  const handleCloseFilter = () => {
    setCondition("is");
    setTimeRange("");
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setNumOfDays(null);
    setExactDate("");
    setCurrentStep("condition");
    setExactDates({
      from: "",
      to: "",
    });

    clearField();
    setOpen(false);

    toggleShowFilter?.(true);

    setTableFiltersParam(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      getTableFiltersParam().filter((item) => item.name !== field.name),
    );
  };

  const goBackToSelectCondition = () => {
    setCondition("is");
    setTimeRange("");
    setCurrentStep("condition");
  };

  const goBackToSelectTimeRange = () => {
    setTimeRange("");
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setNumOfDays(null);
    setExactDate("");
    setCurrentStep("timeRange");
  };

  const goBackToSelectWithinTimeRange = () => {
    setWithinRange("");
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setNumOfDays(null);
    setExactDate("");
    setCurrentStep("withinRange");
  };

  const onOpen = (clickedStep: Step) => {
    setCurrentStep(clickedStep);
    setOpen(true);
  };

  const range = isWithinSelected ? withinRange : timeRange;
  const numOfDaysDisplayed = numOfDays
    ? range.replace("# of", numOfDays.toString())
    : "...";

  return (
    <Popup
      trigger={
        <div
          className="date-filter-container"
          data-testid="date-filter-container"
        >
          <div
            className="date-filter no-pointer"
            onClick={(e) => e.stopPropagation()}
          >
            {fieldName || "..."}
          </div>
          <div className="date-filter" onClick={() => onOpen("condition")}>
            {condition || "..."}
          </div>

          {showTimeRange && (
            <div
              className={classNames("date-filter timeRange", {
                "final-selection": !!timeRange,
              })}
              onClick={() => onOpen("timeRange")}
            >
              {timeRange || "..."}
            </div>
          )}
          {showWithinRange && (
            <div
              className={classNames("date-filter withinRange", {
                "final-selection": !!withinRange,
              })}
              onClick={() => onOpen("withinRange")}
            >
              {withinRange || "..."}
            </div>
          )}

          {showNumOfDays && (
            <div
              className={classNames("date-filter numOfDays", {
                "final-selection": !!numOfDays,
              })}
              onClick={() => onOpen("numOfDays")}
            >
              {numOfDaysDisplayed || "..."}
            </div>
          )}
          {showExactDate && (
            <div
              className={classNames("date-filter exactDate", {
                "final-selection": !!exactDate,
              })}
              onClick={() => onOpen("exactDate")}
            >
              {exactDate.toString() || "..."}
            </div>
          )}
          {showExactDates && (
            <div
              className={classNames("date-filter exactDates", {
                "final-selection": !!(exactDates.to || exactDates.from),
              })}
              onClick={() => onOpen("exactDates")}
            >
              {exactDates.from
                ? `${exactDates.from} - ${exactDates.to}`
                : "..."}
            </div>
          )}

          <div onClick={handleCloseFilter} className="close date-filter">
            <Icon name="close" color="generalDark" size="de" />
          </div>
        </div>
      }
      position="bottom left"
      open={open}
      onClose={() => setOpen(false)}
    >
      <div
        className={classNames("table-dropdown success-dropdown filters-popup", {
          "date-container": currentStep === "exactDate",
        })}
        data-testid="filters-date-popup"
      >
        {currentStep === "condition" && (
          <>
            <div className="select-item">
              <Icon
                onClick={handleCloseFilter}
                className="go-back"
                name="chevronLeft"
                color="generalLightBlue"
              />
              Select Conditions
            </div>
            <div className="date-popup-content">
              {CONDITIONS.map((item) => (
                <div
                  className={classNames("item", {
                    selected: condition === item,
                  })}
                  key={item}
                  onClick={() => handleSelectCondition(item)}
                  data-testid={item}
                >
                  {item}
                </div>
              ))}
            </div>
          </>
        )}
        {currentStep === "timeRange" && (
          <>
            <div className="select-item">
              <Icon
                onClick={goBackToSelectCondition}
                className="go-back"
                name="chevronLeft"
                color="generalLightBlue"
              />
              Select Time Range
            </div>
            <div className="date-popup-content">
              {TIME_RANGE.map((item) => (
                <div
                  className={classNames("item", {
                    selected: condition === item,
                  })}
                  key={item}
                  onClick={() => handleSelectTimeRange(item)}
                  data-testid={item}
                >
                  {item}
                </div>
              ))}
            </div>
          </>
        )}
        {currentStep === "withinRange" && (
          <>
            <div className="select-item">
              <Icon
                onClick={goBackToSelectCondition}
                className="go-back"
                name="chevronLeft"
                color="generalLightBlue"
              />
              Select Time Range
            </div>
            <div className="date-popup-content">
              {IS_WITHIN_RANGE.map((item) => (
                <div
                  className={classNames("item", {
                    selected: condition === item,
                  })}
                  key={item}
                  onClick={() => handleSelectWithinTimeRange(item)}
                  data-testid={item}
                >
                  {item}
                </div>
              ))}
            </div>
          </>
        )}
        {currentStep === "numOfDays" && (
          <>
            <div className="select-item">
              <Icon
                onClick={
                  isWithinSelected
                    ? goBackToSelectWithinTimeRange
                    : goBackToSelectTimeRange
                }
                className="go-back"
                name="chevronLeft"
                color="generalLightBlue"
              />
              Enter Dates
            </div>
            <div className="input-container">
              <div className="num-of-days">Number of days</div>
              <input
                autoFocus
                data-testid="numOfDays-input"
                type="number"
                placeholder="- Type here -"
                ref={inputRef}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="confirm-button">
              <Button
                label="Confirm"
                disabled={disabled}
                onClick={handleSelectNumOfDays}
              />
            </div>
          </>
        )}
        {currentStep === "exactDate" && (
          <>
            <div className="select-item">
              <Icon
                onClick={goBackToSelectTimeRange}
                className="go-back"
                name="chevronLeft"
                color="generalLightBlue"
              />
              Select Exact Date
            </div>
            <div className="input-container no-margin">
              <div className="selected-date">
                Selected Date
                <div className="exact-date">{exactDate.toString()}</div>
              </div>
            </div>
            <FilterCalendar onDateChange={handleSelectExactDate} />
          </>
        )}
        {currentStep === "exactDates" && (
          <>
            <div className="select-item">
              <Icon
                onClick={goBackToSelectWithinTimeRange}
                className="go-back"
                name="chevronLeft"
                color="generalLightBlue"
              />
              Select Date Range
            </div>
            <FilterCalendar
              isDateRange
              onDateChange={handleSelectExactDates}
              onContinue={() => setOpen(false)}
            />
          </>
        )}
      </div>
    </Popup>
  );
};

export default observer(FiltersDateNew);

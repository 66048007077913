import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

export interface MetricsPageProps {
  name: string;
  identifier: ModuleIdentifier;
  defaultDateField: string;
}

export enum OverdueCharts {
  COMING_DUE = "coming_due",
  OVERDUE = "overdue",
  DAYS_OPEN = "days_open",
}

export enum Dashboards {
  STATUS = "status",
  CREATED_DATE = "source",
  OVERDUE = "overdue",
  TIME_TO_CLOSE = "time_to_close",
}

export enum IssueManagementTabs {
  METRICS = "Metrics",
  COMPLETED = "Completed",
  ACTIVE = "Active",
  ALL = "All",
}

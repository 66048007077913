import React from "react";

import type { DashboardComponent } from "@/api";

export default function IssuesWithDueDatesApproachingStat({
  widgetData,
  overdueData,
}: {
  widgetData: DashboardComponent["data"];
  overdueData: DashboardComponent["data"];
}) {
  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Issues with Due Dates Approaching
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div className="tw-w-44 tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {widgetData?.value}
            </div>
            <div className="tw-w-44 tw-text-sm tw-font-medium tw-text-neutral-300">
              Approaching Due Dates
            </div>
          </div>
        </div>

        <div className="tw-ml-4 tw-w-48 tw-border-0 tw-border-t tw-border-solid tw-border-generals-underline" />
        <div className="tw-flex tw-w-full tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div className="tw-w-44 tw-text-6xl tw-font-semibold tw-text-neutral-500">
              {overdueData?.value}
            </div>
            <div className="tw-w-44 tw-text-sm tw-font-medium tw-text-neutral-300">
              Overdue Issues
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

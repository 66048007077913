import classnames from "classnames";
import { capitalize } from "lodash";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useModuleDetection } from "../../../../hooks/useModuleDetection";
import { useTableDetection } from "../../../../hooks/useTableDetection";
import { Icon } from "../../../Elements";
import { moduleDataLoader } from "../../../helpers/moduleDataLoader";
import { ActionButtonPopup } from "./ActionButtonPopup";
import { ActionButtonPopupWithFolder } from "./ActionButtonPopupWithFolder";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionCall?: (...args: any[]) => any;
  actionVerb?: string;
  alt?: string;
  classNames?: string;
  defaultColor?: string;
  disabled?: boolean;
  folderList?: boolean;
  icon?: string;
  isForRecords?: boolean;
  itemName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reset?: (...args: any[]) => any;
  text?: string;
}

export const ActionButton = ({
  icon,
  text,
  alt,
  classNames,
  reset,
  disabled,
  actionCall,
  actionVerb,
  defaultColor,
  itemName,
  isForRecords,
  folderList,
}: Props) => {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isActive, setIsActive] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // Location Tracker
  const location = useLocation();

  // Variables
  const tableName = useTableDetection(location.pathname);
  const themisModuleIdentifier = useModuleDetection(location);

  const handleClose = () => {
    setIsActive(false);
  };

  const handleOpen = () => {
    setIsActive(true);
  };

  const handleCancel = () => {
    setShowPopup(false);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    reset();
  };

  const handleConfirm = async () => {
    setShowPopup(false);
    const selectedIDs = isForRecords
      ? mainStore.dynamicTable.selectedRecordVersionIDs
      : mainStore.dynamicTable.selectedUserIDs;
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    await actionCall(selectedIDs);

    if (folderList) {
      helperFolder(selectedIDs);
    }
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    reset();
    if (themisModuleIdentifier) {
      moduleDataLoader(themisModuleIdentifier, tableName, mainStore);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'selectedIDs' implicitly has an 'any' ty... Remove this comment to see the full error message
  function helperFolder(selectedIDs) {
    const folderID = mainStore.dynamicTable.selectedFolderID;
    const sectionTitle =
      mainStore.sectionTags.list?.find((item) => item.id === folderID)?.title ||
      "Unsectioned Files";

    mainStore.toast.setInfoText(
      `${selectedIDs.length} records have been moved to "${sectionTitle}"!`,
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ showThumbsUp: boolean; }' is n... Remove this comment to see the full error message
      { showThumbsUp: true },
    );
  }

  const disabledIconColor = disabled ? "generalDarkGrey" : defaultColor;
  const iconColor = isActive ? "generalWhite" : disabledIconColor;

  return (
    <div>
      {folderList ? (
        <ActionButtonPopupWithFolder
          showPopup={showPopup}
          trigger={
            <button
              className={classnames(classNames, { active: isActive })}
              data-testid="action-btn-popup-trigger"
              disabled={disabled}
            >
              <Icon
                // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                name={icon}
                alt={alt}
                // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                color={iconColor}
                data-testid="action-btn-popup-trigger-icon"
              />
              {text}
            </button>
          }
          onClick={handleConfirm}
          onClose={handleClose}
          onOpen={handleOpen}
        />
      ) : (
        <ActionButtonPopup
          showPopup={showPopup}
          trigger={
            <button
              className={classnames(classNames, { active: isActive })}
              data-testid="action-btn-popup-trigger"
              disabled={disabled}
            >
              <Icon
                // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                name={icon}
                alt={alt}
                // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                color={iconColor}
                data-testid="action-btn-popup-trigger-icon"
              />
              {text}
            </button>
          }
          title={`${capitalize(actionVerb)} ${capitalize(itemName)}`}
          text={`Are you sure you want to ${actionVerb} these ${itemName}? Actions are not reversible.`}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          onClose={handleClose}
          onOpen={handleOpen}
        />
      )}
    </div>
  );
};

ActionButton.defaultProps = {
  itemName: "record version",
  isForRecords: true,
};

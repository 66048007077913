import { HeaderTabs } from "@themis/ui";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import { getSelectedTab } from "../helpers/Tabs";

interface Props {
  moduleIdentifier: ModuleIdentifier;
  recordVersionID: number;
  children?: React.ReactNode;
  showActiveDetailLink?: boolean;
  tab?: string;
  settingsExtraClassNames?: string;
}

const activeModuleIdentifiersForDocuments: ModuleIdentifier[] = ["audits"];

function DetailNav({
  moduleIdentifier,
  recordVersionID,
  children,
  tab,
}: Props) {
  const mainStore = useMainStore();

  const {
    parent_record_version_id,
    moduleIdentifier: moduleIdentifierFromPath,
  } = useParams<{
    moduleIdentifier: string;
    parent_record_version_id: string;
  }>();

  const disabledModules: ModuleIdentifier[] = [
    "qa_tests_development",
    "qa_procedures",
    "qa_findings",
    "risk_assessment",
    "samples",
  ];
  const { workspaceID } = mainStore.context;
  const shouldRenderDetailNav = !disabledModules.includes(moduleIdentifier);
  const isIssueManagement =
    moduleIdentifier === "issue_management" &&
    !(moduleIdentifierFromPath === "action-plans"); // for IM we have to render more tabs (Action Plans / Controls)
  const isRiskRegister = moduleIdentifier === "risk_register"; // for IM we have to render more tabs (Action Plans / Controls)
  const isAudits = moduleIdentifier === "audits";
  const isComplaints = moduleIdentifier === "complaints";
  const isActionPlan =
    moduleIdentifier === "action_plans" ||
    moduleIdentifierFromPath === "action-plans"; // for Action Plans we have another URL, because it is a 'child' module, we need to take into account parent_record_version_id
  const isChangeManagement =
    moduleIdentifier === TopLevelModule.CHANGE_MANAGEMENT; // Change Management URIs 'change-management' can be different than the module identifier 'new-product-approval'
  const moduleIdentifierPath = kebabCase(moduleIdentifier);
  const [auditLink, detailLink] = setLinksUrls();
  const isControlMapping = moduleIdentifier === "control_mapping";

  const hideComments = Boolean(
    useRouteMatch({
      path: [
        "/workspaces/:workspace_id/modules/issue-management/:record_version_id/action-plans/",
        "/workspaces/:workspace_id/modules/issue-management/:record_version_id/audit_trail",
        "/workspaces/:workspace_id/modules/issue-management/:parent_record_version_id/action-plans/:record_version_id/audit_trail",
      ],
      exact: true,
    }),
  );

  const recordVersion =
    mainStore.recordVersions.list.find((r) => r.id === recordVersionID) ||
    mainStore.recordVersions.current;

  // funcs
  function setLinksUrls() {
    if (isActionPlan) {
      return [
        `/workspaces/${workspaceID}/modules/issue-management/${parent_record_version_id}/${moduleIdentifierFromPath}/${recordVersionID}/audit_trail`,
        `/workspaces/${workspaceID}/modules/issue-management/${parent_record_version_id}/${moduleIdentifierFromPath}/${recordVersionID}`,
      ];
    }

    if (isChangeManagement) {
      return [
        `/workspaces/${workspaceID}/modules/change-management/${recordVersionID}/audit_trail`,
        `/workspaces/${workspaceID}/modules/change-management/${recordVersionID}`,
      ];
    }

    if (tab) {
      return [
        `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${kebabCase(
          tab.toLowerCase(),
        )}/${recordVersionID}/audit_trail`,
        `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${kebabCase(
          tab.toLowerCase(),
        )}/${recordVersionID}`,
      ];
    }

    return [
      `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/audit_trail`,
      `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}`,
    ];
  }

  const getTabs = () => {
    const tabs = [];

    tabs.push({
      name: "Record View",
      key: "",
      value: detailLink,
    });

    if (isControlMapping) {
      tabs.push({
        name: "Control Assessment",
        key: "module-assessments",
        value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/module_assessments`,
      });
    }
    if (activeModuleIdentifiersForDocuments.includes(moduleIdentifier)) {
      tabs.push({
        name: "Documents",
        key: "documents",
        value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/documents`,
      });
    }
    if (isRiskRegister) {
      tabs.push({
        name: "Version History",
        key: "version-history",
        value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/version-history`,
      });
    }
    if (isIssueManagement) {
      tabs.push({
        name: "Action Plans",
        key: "action-plans",
        value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/action-plans`,
      });
    }
    if (isIssueManagement || isRiskRegister) {
      tabs.push({
        name: "Module Controls",
        key: "module-controls",
        value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/controls`,
      });
    }
    if (isIssueManagement) {
      tabs.push(
        {
          name: "Operational Controls",
          key: "operational-controls",
          value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/operational-controls`,
        },
        {
          name: "Related Risks",
          key: "related-risks",
          value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/related-risks`,
        },
      );
    }
    if (isAudits || isComplaints) {
      tabs.push({
        name: "Findings",
        key: "findings",
        value: `/workspaces/${workspaceID}/modules/${moduleIdentifierPath}/${recordVersionID}/findings`,
      });
    }
    tabs.push({
      name: "Activity",
      key: "audit_trail",
      value: auditLink,
    });

    return tabs;
  };

  return (
    shouldRenderDetailNav && (
      <div className="tw-flex tw-h-12 tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5 tw-py-0">
        <HeaderTabs
          tabs={getTabs()}
          selectedTab={getSelectedTab(getTabs())?.value || ""}
          isLink
        />
        {children}
        {!hideComments && (
          <CommentsSlideMenu
            renderCommentsButton
            recordID={recordVersion?.record_id}
            globalID={recordVersion?.global_id}
            uncompletedCommentsCount={recordVersion?.uncompleted_comments_count}
            taskableType="Record"
          />
        )}
      </div>
    )
  );
}

export default observer(DetailNav);

import { action, makeObservable, observable } from "mobx";

import { IntegrationsAPI } from "@/api/legacy/integrations";
import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import type { MainStore } from "@/stores/Main";
import type { Data } from "@/stores/types/record-types";

import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class Zendesk implements ModuleStore {
  mainStore: MainStore;

  // Observable objects
  data: Data | null = null;
  disconnected: boolean | null = null;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      disconnected: observable,
      setData: action,
      cleanup: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/modules/zendesk
  async index({ tableName, sortParams, tableFilters = [] }: IndexParams) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      // Need to check if zendesk is connected to avoid 500 from `/modules/zendesk`
      const integrationsResponse = await IntegrationsAPI.getIntegrations(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        this.mainStore.context.workspaceID,
      );

      const zendeskDisconnected = !integrationsResponse.integrations.zendesk.id;

      this.disconnected = zendeskDisconnected;

      if (zendeskDisconnected) {
        return;
      }

      const params = {
        table_title: tableName,
        table_filters: tableFilters,
        ...(sortParams && {
          sort_field_name: sortParams.field_name,
          sort_direction: sortParams.direction,
        }),
      };

      const response = await legacyApi.get<Data>(`${API_URL}/modules/zendesk`, {
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.mainStore.fields.setList(response.data.fields);
      this.setData(response.data);
    } catch (error) {
      window.console.log(`"zendesk#Index for Workspace" error ${error}`);
    }
  }

  // DELETE /api/react/modules/zendesk/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/modules/zendesk/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Zendesk#delete" error ${error}`);
    }
  }

  // PUT /api/react/modules/zendesk/:id/verify
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async verify(recordVersionID) {
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/modules/zendesk/${recordVersionID}/verify`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Zendesk#verify" error ${error}`);
    }
  }

  // PUT /api/react/modules/zendesk/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/modules/zendesk/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Zendesk#unlock" error ${error}`);
    }
  }

  // POST /api/react/integrations/zendesk/configurations/:id/synchronize
  // @ts-expect-error TS(7006) FIXME: Parameter 'zendeskConfigurationId' implicitly has ... Remove this comment to see the full error message
  async synchronize(zendeskConfigurationId) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/integrations/zendesk/configurations/${zendeskConfigurationId}/synchronize`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Zendesk#synchronize" error ${error}`);
    }
  }

  // Actions
  setData(value: Data) {
    this.data = value;
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  cleanup() {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    this.setData(null);
  }
}

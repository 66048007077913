import { ConfirmationPopup, TextInput } from "@themis/ui";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { PiPencilSimpleLine } from "react-icons/pi";

export function ScoringWeightInputCell({
  rowIndex,
  registerId,
  weight,
  isErrorState,
  onUpdateWeight,
}: {
  rowIndex: number;
  registerId: number;
  weight: number;
  isErrorState?: boolean;
  onUpdateWeight: (id: number, value: number, remove?: boolean) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputFocused, setInputFocused] = useState(false);
  const [intWeight, setIntWeight] = useState<number | string>(weight);
  const [intErrorState, setIntErrorState] = useState(isErrorState);
  const [submitted, setSubmitted] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const [confirmChange, setConfirmChange] = useState({
    open: false,
    prevChangeValue: null as number | null,
  });

  const handleWeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace("%", "");
    if (value === "" && value !== intWeight) {
      setIntWeight("");
    }
    if (!isNaN(parseInt(value))) {
      if (Number(value) >= 0 && Number(value) !== intWeight) {
        setIntWeight(value);
      }
    }
  };

  useEffect(() => {
    setIntErrorState(isErrorState);
  }, [isErrorState]);

  const reset = () => {
    if (!isInvalid && !intErrorState) {
      setIntWeight(confirmChange.prevChangeValue || weight);
      onUpdateWeight(registerId, confirmChange.prevChangeValue || weight, true);
    } else {
      setIntWeight(weight);
      onUpdateWeight(registerId, weight, true);
    }
    setIsInvalid(false);
    setSubmitted(false);
    setInputFocused(false);
    setIntErrorState(false);
    setConfirmChange((prev) => ({ ...prev, open: false }));
  };

  const resetGroupError = () => {
    setSubmitted(false);
    setInputFocused(false);
    setIntErrorState(false);
  };

  const handleBlur = () => {
    if (!isInvalid) {
      setInputFocused(false);
      if (intWeight !== weight && intWeight !== confirmChange.prevChangeValue) {
        setValueChanged(true);
        setConfirmChange((prev) => ({ ...prev, open: true }));
      }
    }
  };

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.stopPropagation();
      e.preventDefault();
      if (intWeight !== weight) {
        setValueChanged(true);
        setConfirmChange((prev) => ({ ...prev, open: true }));
      }
    }
  };

  const saveChange = () => {
    if (intWeight !== weight) {
      if (Number(intWeight) > 100) {
        setIsInvalid(true);
        setInputFocused(false);
      } else {
        setIsInvalid(false);
        setIntWeight(Number(intWeight));
        setSubmitted(true);
        setInputFocused(false);
        onUpdateWeight(registerId, Number(intWeight) || 0);
      }
      setConfirmChange({ open: false, prevChangeValue: Number(intWeight) });
    } else {
      setConfirmChange((prev) => ({ ...prev, open: false }));
    }
  };

  const handleFocus = () => {
    setInputFocused(true);
    if (inputRef.current) {
      inputRef.current.setSelectionRange(
        intWeight.toString().length,
        intWeight.toString().length,
      );
    }
  };

  return (
    <div
      className={classNames(
        "tw-full tw-relative tw-flex  tw-items-center tw-justify-between",
        {
          "tw-border tw-border-solid tw-border-primary-300":
            inputFocused && !isInvalid,
          "tw-shadow-[0px_4px_12px_0px_rgba(109,113,249,0.24)]":
            submitted || isInvalid,
          "tw-bg-warning-50": isErrorState || isInvalid,
        },
      )}
      style={{ height: "100%" }}
    >
      <TextInput
        size="sm"
        value={`${intWeight}%`}
        onChange={handleWeightChange}
        className={classNames(
          "tw-border-none focus-visible:tw-border-none focus-visible:tw-outline-none focus-visible:tw-ring-0",
          {
            "tw-text-warning-300": isErrorState || isInvalid,
          },
        )}
        onFocus={handleFocus}
        onClick={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleSubmit}
        ref={inputRef}
      />
      {valueChanged && (
        <PiPencilSimpleLine
          className={classNames(
            "tw-mx-[10px] tw-h-6 tw-w-6 tw-fill-neutral-500",
            {
              "tw-fill-warning-300": isErrorState || isInvalid,
            },
          )}
        />
      )}

      <ConfirmationPopup
        align="start"
        title="Customizing Individual Weighting"
        content={
          <div>
            <p>
              You are entering a custom weighting for this record. Are you sure
              you want to save this new weighting?
            </p>
          </div>
        }
        confirmText="Yes"
        cancelText="No"
        onConfirm={saveChange}
        onCancel={reset}
        open={
          confirmChange.open &&
          confirmChange.prevChangeValue !== intWeight &&
          valueChanged
        }
        sideOffset={-20}
        anchor
      >
        <div />
      </ConfirmationPopup>
      <ConfirmationPopup
        align="start"
        title="Invalid Custom Weighting"
        content={
          <div>
            <p>Maximum weight for a cell is 100%.</p>
            <br />
            <p>Please adjust again.</p>
          </div>
        }
        confirmText="Ok"
        onConfirm={reset}
        open={isInvalid}
        sideOffset={-20}
        anchor
      >
        <div />
      </ConfirmationPopup>
      <ConfirmationPopup
        align="start"
        title="Invalid Custom Weighting"
        content={
          <div>
            <p>Weighting across entire group must not be over 100%.</p>
            <br />
            <p>Please adjust again.</p>
          </div>
        }
        confirmText="Ok"
        onConfirm={resetGroupError}
        open={!!intErrorState && rowIndex === 0}
        sideOffset={-20}
        anchor
      >
        <div />
      </ConfirmationPopup>
    </div>
  );
}

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { useModuleDetection } from "@/hooks/useModuleDetection";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import Table from "../../Table";
import ModuleRecordVersion from "../ModuleRecordVersion";
import ModuleTableColumn from "../ModuleTableColumn";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleStore?: any;
}

function HistoricalVersions(props: Props) {
  const { record_version_id } = useParams<{
    record_version_id: string;
    identifier: string;
  }>();
  // Import MobX stores
  const mainStore = useMainStore();
  const location = useLocation();
  const themisModuleIdentifier = useModuleDetection(location);

  // Variables
  const { moduleWorkspaceID } = mainStore.context;

  const moduleStore = themisModuleIdentifier
    ? mainStore.getStore(themisModuleIdentifier)
    : props.moduleStore;

  // Hooks
  useEffect(() => {
    if (record_version_id) {
      moduleStore.historicalList(record_version_id);
    }
  }, [record_version_id]);

  const { visibleFields } = mainStore.fields;
  const recordVersions = moduleStore.historical;

  const renderFields = visibleFields.map((field) => (
    <ModuleTableColumn key={field.name} field={field} />
  ));

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRows = recordVersions.map((recordVersion) => (
    <ModuleRecordVersion
      key={recordVersion.id}
      isLockedRow
      fields={visibleFields}
      recordVersion={recordVersion}
      // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
      moduleWorkspaceID={moduleWorkspaceID}
      tableID={recordVersion.table_id}
      tableName={recordVersion.table_name}
      moduleIdentifier={themisModuleIdentifier as ModuleIdentifier}
    />
  ));

  return (
    <Table>
      <div
        className="table-list-wrap historical-list-wrap"
        data-testid="record-historical-version"
      >
        <div className="list-title-table">
          <ul>
            <div className="list-column-wrap">
              <div className="list-title-table-wrap">
                {renderFields}
                <span className="stretch-cell" />
              </div>
            </div>
          </ul>
        </div>
        {renderRows}
      </div>
    </Table>
  );
}

export default observer(HistoricalVersions);

import mainStore from "@/stores/Main";

import api from "../api";
import type {
  IntegrationDeleteResponse,
  SharePointTenantDetailsResponse,
  WorkspaceIntegrationsResponse,
} from "./types";

export async function getIntegrations(workspaceId: number) {
  const response = await api.get<WorkspaceIntegrationsResponse>(
    `/workspaces/${workspaceId}/integrations`,
  );

  return response.data;
}

export async function disconnectZendesk(zendeskConfigId: number) {
  const response = await api.delete<IntegrationDeleteResponse>(
    `/integrations/zendesk/configurations/${zendeskConfigId}`,
  );

  return response.data;
}

export async function disconnectIntegrationAuth(
  type: string,
  workspaceID?: number,
) {
  const params = workspaceID ? { workspace_id: workspaceID } : null;
  const response = await api.delete<IntegrationDeleteResponse>(
    `/integrations/${type}/oauth/destroy`,
    { params },
  );
  if (response.status === 200 && type !== "share_point") {
    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'Integratio... Remove this comment to see the full error message
    mainStore.users.setUser(response.data.user);
  }

  return response.data;
}

export async function sharePointTenantDetails() {
  const response = await api.get<SharePointTenantDetailsResponse>(
    `/integrations/share_point/file_picker/tenant_details`,
  );

  return response.data;
}

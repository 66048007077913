import {
  DragDropContext,
  Draggable,
  Droppable,
} from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo } from "react";

import { IconButton, Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

import { userColors } from "../constants";
import { Button, Icon } from "../Elements";
import ApprovalsStepsUsersSelect from "./ApprovalsStepsUsersSelect";
import ManageApprovalsStepsNavigation from "./ManageApprovalsStepsNavigation";

function ManageApprovalsSteps() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { currentApprovalsSteps } = mainStore.manageApprovalsSteps;
  const { moduleWorkspaceID, themisModuleIdentifier: moduleIdentifier } =
    mainStore.context;
  const { users: allUsers } = mainStore.users;
  const { list: moduleWorkspaces } = mainStore.moduleWorkspaces;
  const currentModuleWorkspace = moduleWorkspaces.find(
    (moduleWorkspace) =>
      moduleWorkspace?.themis_module?.identifier === moduleIdentifier,
  );

  const allowedUsers = useMemo(() => {
    const defaultApprovers =
      currentModuleWorkspace?.module_configuration?.default_approvers || [];

    if (defaultApprovers.length > 0) {
      const defaultApproversIDs = defaultApprovers.map(
        (defaultApprover) => defaultApprover.user_id,
      );
      return allUsers.filter((user) => defaultApproversIDs.includes(user.id));
    }

    return allUsers;
  }, [allUsers, currentModuleWorkspace]);

  // Hooks
  useEffect(() => {
    if (moduleWorkspaceID) {
      mainStore.manageApprovalsSteps.cleanup();
      mainStore.manageApprovalsSteps.index(moduleWorkspaceID);
    }
  }, [moduleWorkspaceID]);

  const removeUser = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
    (user, stepsUserIndex) => {
      const updatedValue = [...currentApprovalsSteps];
      const userId = user.id;

      updatedValue[stepsUserIndex].users_ids = updatedValue[
        stepsUserIndex
      ].users_ids.filter((id) => id !== userId);

      mainStore.manageApprovalsSteps.setCurrentApprovalsSteps(updatedValue);
    },
    [
      currentApprovalsSteps,
      mainStore.manageApprovalsSteps.setCurrentApprovalsSteps,
    ],
  );

  // Functions
  function addNewSteps() {
    const newStep = {
      position: currentApprovalsSteps.length + 1,
      users_ids: [],
    };
    const updatedSteps = currentApprovalsSteps.map((step) => ({ ...step }));
    updatedSteps.push(newStep);
    mainStore.manageApprovalsSteps.setCurrentApprovalsSteps(updatedSteps);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
  function removeStep(index) {
    const updatedSteps = [...currentApprovalsSteps];
    updatedSteps.splice(index, 1);

    updatedSteps.forEach((step, i) => {
      step.position = i + 1;
    });

    mainStore.manageApprovalsSteps.setCurrentApprovalsSteps(updatedSteps);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
  function handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const sourceStepsIndex = parseInt(result.source.droppableId);
    const destinationStepsIndex = parseInt(result.destination.droppableId);

    if (sourceStepsIndex === destinationStepsIndex) {
      const updatedUsers = Array.from(
        currentApprovalsSteps[sourceStepsIndex].users_ids,
      );
      const [removedUser] = updatedUsers.splice(sourceIndex, 1);
      updatedUsers.splice(destinationIndex, 0, removedUser);

      const updatedSteps = [...currentApprovalsSteps];
      updatedSteps[sourceStepsIndex].users_ids = updatedUsers;

      mainStore.manageApprovalsSteps.setCurrentApprovalsSteps(updatedSteps);
    } else {
      const sourceUsers = Array.from(
        currentApprovalsSteps[sourceStepsIndex].users_ids,
      );
      const destinationUsers = Array.from(
        currentApprovalsSteps[destinationStepsIndex].users_ids,
      );

      const [removedUser] = sourceUsers.splice(sourceIndex, 1);

      const isUserPresentInSource = sourceUsers.some(
        (userId) => userId === removedUser,
      );
      const isUserPresentInDestination = destinationUsers.some(
        (userId) => userId === removedUser,
      );

      if (!isUserPresentInSource && !isUserPresentInDestination) {
        destinationUsers.splice(destinationIndex, 0, removedUser);

        const updatedSteps = [...currentApprovalsSteps];
        updatedSteps[sourceStepsIndex].users_ids = sourceUsers;
        updatedSteps[destinationStepsIndex].users_ids = destinationUsers;

        mainStore.manageApprovalsSteps.setCurrentApprovalsSteps(updatedSteps);
      }
    }
  }

  return (
    <div className="manage-approvals">
      {/* @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message */}
      <ManageApprovalsStepsNavigation moduleIdentifier={moduleIdentifier} />

      <div className="manage-approvals-steps-wrap">
        <div className="steps-title-wrap">
          <Typography
            label="Stepped Approvals"
            className="steps-title"
            size="lg"
            weight="semiBold"
            color="generalMidnightDark"
          />
          <Button
            data-testid="add-new-steps-btn"
            label="Add New Steps"
            onClick={addNewSteps}
          />
        </div>

        <DragDropContext onDragEnd={handleDragEnd}>
          {currentApprovalsSteps.length > 0 ? (
            currentApprovalsSteps.map((stepsItem, stepsIndex) => {
              const userIDsForCurrentStep =
                currentApprovalsSteps[stepsIndex].users_ids;
              const sortedUsers = allUsers
                .filter((user) => userIDsForCurrentStep?.includes(user.id))
                .sort((a, b) => {
                  const indexA = userIDsForCurrentStep.indexOf(a.id);
                  const indexB = userIDsForCurrentStep.indexOf(b.id);
                  return indexA - indexB;
                });

              return (
                <Droppable droppableId={stepsIndex.toString()} key={stepsIndex}>
                  {(provided, snapshot) => (
                    <div
                      className={classNames("steps-wrap", {
                        "steps-dragging-over": snapshot.isDraggingOver,
                      })}
                      data-testid="steps"
                    >
                      <div className="steps-block">
                        <Typography
                          label={`Step ${stepsIndex + 1}`}
                          className="steps-title"
                          size="md"
                          weight="semiBold"
                          color="generalMidnightDark"
                        />
                        <ApprovalsStepsUsersSelect
                          allowedUsers={allowedUsers}
                          stepsIndex={stepsIndex}
                        />
                        <IconButton
                          icon="trash"
                          className="delete-step-or-user"
                          data-testid="delete-step-btn"
                          onClick={() => removeStep(stepsIndex)}
                        />
                      </div>
                      <div
                        className="steps-users"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {sortedUsers.length > 0 ? (
                          sortedUsers.map((item, index) => {
                            const style = {
                              background: userColors[item.icon_color_index],
                              marginRight: "6px",
                            };
                            return (
                              <Draggable
                                draggableId={`${item.id}-${stepsIndex}`}
                                index={index}
                                key={item.id}
                              >
                                {(providedDraggable) => (
                                  <div
                                    {...providedDraggable.draggableProps}
                                    ref={providedDraggable.innerRef}
                                    className="list-block"
                                  >
                                    <div
                                      className="procedures-popup-element"
                                      data-testid="user-step"
                                    >
                                      <div
                                        {...providedDraggable.dragHandleProps}
                                        className="user-reorder"
                                      >
                                        <Icon name="reorder" />
                                      </div>
                                      <div
                                        className="users-circle"
                                        style={style}
                                      >
                                        <div>{item.initials}</div>
                                      </div>
                                      {item.full_name}
                                    </div>
                                    <IconButton
                                      icon="trash"
                                      className="delete-step-or-user"
                                      data-testid="delete-user-btn"
                                      onClick={() =>
                                        removeUser(item, stepsIndex)
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })
                        ) : (
                          <div className="no-users-wrap" data-testid="no-users">
                            <Typography
                              label="Add or Drag Approvers here"
                              size="sm"
                              color="extrasBlueGrayDarker"
                            />
                          </div>
                        )}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              );
            })
          ) : (
            <div className="no-steps" data-testid="no-steps">
              <Typography
                label="There are no stepped approvals"
                className="no-steps-title"
                size="md"
                weight="semiBold"
                color="generalDark"
              />
              <Typography label="Create a new step approval by clicking “Add New Approvals”" />
            </div>
          )}
        </DragDropContext>
      </div>
    </div>
  );
}

export default observer(ManageApprovalsSteps);

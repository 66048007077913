import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import Button from "@/components/form/Button";
import EmailInput from "@/components/form/EmailInput";
import TextInput from "@/components/form/TextInput";
import { useMainStore } from "@/contexts/Store";
import arrowDown from "@/images/table-image/icon/arrow-down-black.svg";

import Header from "../components/Header";
import InviteExpired from "../components/InviteExpired";
import PasswordFields from "../components/PasswordFields";

function InviteSignUp() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [fullNamePresent, setFullNamePresent] = useState(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const [isFormEnabled, setIsFormEnabled] = useState(false);

  // effects
  useEffect(() => {
    const shouldEnableForm = fullNamePresent && passwordValid;

    setIsFormEnabled(shouldEnableForm);
  }, [fullNamePresent, passwordValid]);

  useEffect(() => {
    const fetchUser = async () => {
      const fetchedUser = await mainStore.users.fetchInvitedUser(invite_token);
      setUser(fetchedUser?.user);
      setSelectedDepartment(fetchedUser?.user?.department);
      setDepartments(fetchedUser?.departments);
      validateFullName(fetchedUser?.user?.full_name);
      setLoading(false);
    };

    fetchUser();
  }, []);

  // Refs
  const fullNameRef = useRef();

  // functions
  function handlePopUpOpen() {
    setShowPopup(true);
  }

  function handlePopUpClose() {
    setShowPopup(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  function handleSelectDepartment(item) {
    setSelectedDepartment(item);
    handlePopUpClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "full_name") {
      validateFullName(value);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function validateFullName(value) {
    setFullNamePresent(Boolean(value?.length));
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  async function handleSubmit(event) {
    event.preventDefault();

    const data = {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      full_name: fullNameRef.current.value,
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      department_id: selectedDepartment?.id,
      password,
    };

    await mainStore.users.signUpInvitedUser(invite_token, data);
    history.push("/legals");
  }

  // Variables
  const history = useHistory();
  // @ts-expect-error TS(2339) FIXME: Property 'invite_token' does not exist on type '{}... Remove this comment to see the full error message
  const { invite_token } = useParams();

  const renderDepartmentsSelect = departments && (
    <Popup
      trigger={
        <div className="form-input invite-departments-select">
          <li className={classNames({ active: showPopup })}>
            {selectedDepartment ? (
              // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
              <span className="active" key={selectedDepartment.id}>
                {/* @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'. */}
                {selectedDepartment.title}
                <img src={arrowDown} alt="arrow-down" />
              </span>
            ) : (
              <span className="no-active">
                Select Department (Optional)
                <img src={arrowDown} alt="arrow-down" />
              </span>
            )}
          </li>
        </div>
      }
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      <div className="select-parameter authentication-select">
        <ul>
          {departments.map((item) => (
            <li
              // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
              key={item.id}
              onClick={() => {
                handleSelectDepartment(item);
              }}
            >
              {/* @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'. */}
              {item.title}
            </li>
          ))}
        </ul>
      </div>
    </Popup>
  );

  return (
    <div>
      {!loading && (
        <div>
          {!user && <InviteExpired />}
          {user && (
            <div className="container">
              <Header />
              <div className="invite-team invite-sign-up">
                <h3>Create Account</h3>
                <form onSubmit={handleSubmit}>
                  {/* @ts-expect-error TS(2339) FIXME: Property 'email' does not exist on type 'never'. */}
                  <EmailInput defaultValue={user?.email} disabled />
                  <p className="sign-up-text">
                    For security purposes, you cannot change this email address
                    until account has been officially created. This will also be
                    the main contact email of this company.
                  </p>
                  <TextInput
                    placeholder="Your Full Name"
                    name="full_name"
                    inputRef={fullNameRef}
                    // @ts-expect-error TS(2339) FIXME: Property 'full_name' does not exist on type 'never... Remove this comment to see the full error message
                    defaultValue={user?.full_name}
                    onChange={handleChange}
                  />
                  {renderDepartmentsSelect}
                  <PasswordFields
                    onValidityChange={setPasswordValid}
                    onPasswordChange={setPassword}
                  />
                  <Button title="Confirm" enabled={isFormEnabled} />
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default observer(InviteSignUp);

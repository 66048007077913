import { action, makeObservable, observable } from "mobx";

import type { AttachmentGroup } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";
import { buildUploadPayload } from "@/components/helpers/AttachmentGroupsHelper";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class AttachmentGroupsStore {
  mainStore: MainStore;

  list: Array<AttachmentGroup> = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,

      setList: action,
    });

    this.mainStore = mainStore;
  }

  // API

  /**
   * POST /api/react/record_versions/:record_version_id/attachment_groups
   */
  async create({
    recordVersionID,
    payload,
  }: {
    recordVersionID: number;
    payload: object;
  }) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/attachment_groups`,
        headers: this.mainStore.getHeaders(),
        data: payload,
      });

      return response.data?.attachment_group;
    } catch (error) {
      window.console.log(`"AttachmentGroups#create" error ${error}`);
      return null;
    }
  }

  /**
   * PUT/PATCH /api/react/attachment_groups/:id
   */
  // @ts-expect-error TS(7031) FIXME: Binding element 'attachmentGroupID' implicitly has... Remove this comment to see the full error message
  async update({ attachmentGroupID, params }) {
    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/attachment_groups/${attachmentGroupID}`,
        headers: this.mainStore.getHeaders(),
        data: { attachment_group: params },
      });

      this.setList(
        this.list.map((ag) =>
          ag.id === attachmentGroupID ? response.data.attachment_group : ag,
        ),
      );
    } catch (error) {
      window.console.log(`"AttachmentGroups#update" error ${error}`);
    }
  }

  /**
   * POST /api/react/record_versions/:record_version_id/attachment_groups/bulk
   */
  // @ts-expect-error TS(7031) FIXME: Binding element 'recordVersionID' implicitly has a... Remove this comment to see the full error message
  async bulkCreate({ recordVersionID, fieldName, signedIDs }) {
    const params = {
      attachment_group: { field_name: fieldName },
      signed_ids: signedIDs,
    };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/attachment_groups/bulk`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });
    } catch (error) {
      window.console.log(`"AttachmentGroups#bulkCreate" error ${error}`);
    }
  }

  // Actions

  setList(value?: Array<AttachmentGroup>) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  // Store Helpers

  cleanup() {
    this.setList([]);
  }

  async uploadFiles({
    // @ts-expect-error TS(7031) FIXME: Binding element 'recordVersionID' implicitly has a... Remove this comment to see the full error message
    recordVersionID,
    // @ts-expect-error TS(7031) FIXME: Binding element 'fieldName' implicitly has an 'any... Remove this comment to see the full error message
    fieldName,
    // @ts-expect-error TS(7031) FIXME: Binding element 'signedIDs' implicitly has an 'any... Remove this comment to see the full error message
    signedIDs,
    // @ts-expect-error TS(7031) FIXME: Binding element 'fileType' implicitly has an 'any'... Remove this comment to see the full error message
    fileType,
    // @ts-expect-error TS(7031) FIXME: Binding element 'url' implicitly has an 'any' type... Remove this comment to see the full error message
    url,
    // @ts-expect-error TS(7031) FIXME: Binding element 'isMultiselectField' implicitly ha... Remove this comment to see the full error message
    isMultiselectField,
    replacedAttachmentID = -1,
  }) {
    if (!recordVersionID || !fieldName || !fileType) {
      window.console.error(
        'AttachmentGroupsStore#uploadFiles error: "recordVersionID", "fieldName" & "fileType" should be present!',
      );
      return;
    }

    const signedID = signedIDs?.[0];
    const multipleDirectUploadsPresent = signedIDs?.length > 1;

    if (replacedAttachmentID && replacedAttachmentID !== -1 && signedID) {
      const attachmentID = replacedAttachmentID;
      if (signedID) {
        this.mainStore.attachments.replace({
          recordVersionID,
          attachmentID,
          signedID,
        });
      }
      return;
    }

    if (isMultiselectField && multipleDirectUploadsPresent) {
      // Bulk files upload
      await this.bulkCreate({ recordVersionID, fieldName, signedIDs });
    } else {
      // Single file upload
      const payload = buildUploadPayload(fieldName, fileType, signedID, url);
      if (payload) {
        await this.create({ recordVersionID, payload });
      }
    }
  }
}

import "./terms-dropdown-field.scss";

import React, { useEffect, useState } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { DropdownOption } from "@/components/Elements/Dropdown";
import Dropdown from "@/components/Elements/Dropdown/Dropdown";
import { TERMS } from "@/components/table/vendor-due-diligence/contracts/constants";
import CustomTerminationDateContent from "@/components/table/vendor-due-diligence/contracts/CustomTerminationDateContent";

type Props<T extends FieldValues> = UseControllerProps<T> & {
  hasTableView?: boolean;
  hasErrors?: boolean;
  width?: number | string;
  hasErrorClass?: string;
  locked?: boolean;
};

function TermsDropdownField<T extends FieldValues>({
  hasTableView,
  hasErrors,
  width,
  hasErrorClass = "has-errors",
  locked,
  ...controller
}: Props<T>) {
  // Hooks
  const { field } = useController(controller);

  // State
  const [terms, setTerms] = useState<string>("");

  // Effects
  useEffect(() => {
    if (field.value) {
      setTerms(field.value);
    }
  }, [field.value]);

  // Variables
  const newTerms = [...TERMS];
  if (!newTerms.find((term) => term.id.toString() === terms)) {
    newTerms.push({
      id: parseInt(terms),
      name: terms,
    });
  }

  // Effects
  useEffect(() => {
    field.onChange(terms);
  }, [terms]);

  return (
    <Dropdown
      options={newTerms}
      selected={newTerms.find((at) => at.id === parseInt(terms || "0"))}
      onChange={(option: DropdownOption) => setTerms(option.id.toString())}
      helperText="days before termination"
      disabled={false}
      hasTableView={hasTableView}
      CustomValueContent={
        <CustomTerminationDateContent
          onCustomValueAdded={(val: string) => setTerms(val)}
          initialValue={terms}
        />
      }
      width={width}
      hasErrorClass={hasErrorClass}
      hasErrors={hasErrors}
      locked={locked}
      hasTriggerForTable
    />
  );
}

export default TermsDropdownField;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Edit = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 20C21.5523 20 22 20.4477 22 21C22 21.5523 21.5523 22 21 22H3C2.44772 22 2 21.5523 2 21C2 20.4477 2.44772 20 3 20H21ZM4.29289 13.2929L15.2929 2.29289C15.6534 1.93241 16.2206 1.90468 16.6129 2.2097L16.7071 2.29289L19.7071 5.29289C20.0676 5.65338 20.0953 6.22061 19.7903 6.6129L19.7071 6.70711L8.70711 17.7071C8.55083 17.8634 8.34815 17.9626 8.13144 17.9913L8 18H5C4.48716 18 4.06449 17.614 4.00673 17.1166L4 17V14C4 13.779 4.07316 13.5655 4.20608 13.392L4.29289 13.2929L15.2929 2.29289L4.29289 13.2929ZM16 4.41421L6 14.4142V16H7.58579L17.5858 6L16 4.41421Z"
      fill="#353549"
    />
  </svg>
);

Edit.displayName = "EditIcon";

export default Edit;

import classNames from "classnames";
import React, { useRef, useState } from "react";
import type { SuggestionDataItem } from "react-mentions";
import { Mention, MentionsInput } from "react-mentions";

import { userColors } from "@/components/constants";
import { Button, Flex, MiniTag } from "@/components/Elements";
import {
  formatTimeAfterDate,
  stringToDateAndTime,
} from "@/components/helpers/DateFormatters";
import PortalSlideMenu from "@/components/slideMenu/PortalSlideMenu";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useMainStore } from "@/contexts/Store";
import type { UserComment } from "@/stores/types/comment-types";
import type { DisplayUser } from "@/stores/types/user-types";

import ImmutableTaskComment from "../../ImmutableTaskComment";

function EditTaskContent({ task }: { task: UserComment }) {
  const mainStore = useMainStore();

  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;
  const creator = mainStore.users.users.find(
    (user) => user?.id === task?.author?.id,
  );

  // Refs
  const usersList = useRef();
  const inputRef = useRef<HTMLTextAreaElement>();

  // State
  const [showCommentsMenu, setShowCommentsMenu] = useState(false);
  const [description, setDescription] = useState(task.content);
  const displayUserListData = task.private
    ? mainStore.comments.privateUsers
    : mainStore.users.allDisplayUsers;

  const taskClasses = classNames("task-name", "edit-task-description", {
    active: description?.length > 0,
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function mentionsHandleChange(event) {
    setDescription(event.target.value);
  }

  function handleSubmit() {
    if (description && description !== "") {
      mainStore.comments.update(
        task.id,
        { content: description },
        task.module_workspace_id,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      inputRef.current.blur();
    }
  }

  function handleBlur() {
    handleSubmit();
  }

  function renderSuggestion(suggestion: SuggestionDataItem) {
    const user = suggestion as DisplayUser;
    const optionClasses = classNames("option", {
      "awaiting-verification": user.awaiting_verification,
    });

    const style = { background: userColors[user.icon_color_index] };

    return (
      <div className={optionClasses}>
        <span className="users-circle" style={style}>
          {user.initials}
        </span>
        {user.full_name}
        {user.awaiting_verification && (
          <span className="awaiting-verification-status">{user.status}</span>
        )}
      </div>
    );
  }

  // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const creatorStyle = { background: userColors[creator?.icon_color_index] };

  return (
    <div className="all-tasks-wrap edit-task-wrap">
      <div className="associated-record-wrap">
        <Flex alignCenter justifySpaceBetween fullWidth>
          <span>
            Associated Record:
            <MiniTag label={task?.global_id} theme="navy" />
            <span className="associated-record-element">
              {task?.record_name ? (
                task?.record_name
              ) : (
                <span className="record-element-na">N/A</span>
              )}
            </span>
          </span>
          <Button
            size="sm"
            type="button"
            className="comments-slide-menu__comments-button"
            label={`Reply (${task.replies.length})`}
            onClick={() => setShowCommentsMenu((state) => !state)}
          />
        </Flex>
      </div>
      <h3>Other Comment Details</h3>
      <div className="edit-parameters-block">
        <ul>
          <li>
            <h5>Creation Date</h5>
            <div className="edit-task-attribute">
              {formatTimeAfterDate(stringToDateAndTime(task?.created_at))}
            </div>
          </li>
          <li>
            <h5>Creator</h5>
            <div className="edit-task-attribute">
              <span
                className="edit-task-initials attribute-initials"
                style={creatorStyle}
              >
                {creator?.initials}
              </span>
              {creator?.full_name}
            </div>
          </li>
        </ul>
      </div>
      <h3>Comment Description</h3>
      <div className={taskClasses}>
        <MentionsInput
          disabled={isReadOnly}
          value={description}
          className={classNames("mentions", {
            "table-cell--disabled": isReadOnly,
          })}
          onChange={mentionsHandleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          placeholder="Type here"
          suggestionsPortalHost={usersList.current}
          // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLTextAreaElement | undef... Remove this comment to see the full error message
          inputRef={inputRef}
          data-testid="edit-task-description"
        >
          <Mention
            trigger="@"
            data={displayUserListData}
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>
      </div>
      <PortalSlideMenu>
        {/* @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
        <div ref={usersList} className="suggestion-wrap" />
      </PortalSlideMenu>
      <SlideMenu open={showCommentsMenu} singleSlideMenu>
        <ImmutableTaskComment
          skipCleanupOfPrivateUsers
          taskID={task.id}
          closeSlideMenu={() => setShowCommentsMenu(false)}
          workspaceID={task.workspace_id}
        />
      </SlideMenu>
    </div>
  );
}
export default EditTaskContent;

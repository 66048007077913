import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Filters = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11C8.97969 11 9.81314 11.6261 10.122 12.5001L14.75 12.5C15.1642 12.5 15.5 12.8358 15.5 13.25C15.5 13.6642 15.1642 14 14.75 14L10.1217 14.0007C9.81266 14.8742 8.97941 15.5 8 15.5C7.02059 15.5 6.18734 14.8742 5.87827 14.0007L1.25 14C0.835786 14 0.5 13.6642 0.5 13.25C0.5 12.8358 0.835786 12.5 1.25 12.5L5.87801 12.5001C6.18686 11.6261 7.02031 11 8 11ZM8 12.5C7.58579 12.5 7.25 12.8358 7.25 13.25C7.25 13.6642 7.58579 14 8 14C8.41421 14 8.75 13.6642 8.75 13.25C8.75 12.8358 8.41421 12.5 8 12.5ZM13.25 5.75C14.4926 5.75 15.5 6.75736 15.5 8C15.5 9.24264 14.4926 10.25 13.25 10.25C12.2706 10.25 11.4373 9.62422 11.1283 8.75068L1.25 8.75C0.835786 8.75 0.5 8.41421 0.5 8C0.5 7.58579 0.835786 7.25 1.25 7.25L11.128 7.25007C11.4369 6.37614 12.2703 5.75 13.25 5.75ZM13.25 7.25C12.8358 7.25 12.5 7.58579 12.5 8C12.5 8.41421 12.8358 8.75 13.25 8.75C13.6642 8.75 14 8.41421 14 8C14 7.58579 13.6642 7.25 13.25 7.25ZM2.75 0.5C3.73385 0.5 4.57021 1.13147 4.8759 2.01121C4.91599 2.00352 4.95758 2 5 2H14.75C15.1642 2 15.5 2.33579 15.5 2.75C15.5 3.16421 15.1642 3.5 14.75 3.5H5C4.95758 3.5 4.91599 3.49648 4.87549 3.48971C4.57021 4.36853 3.73385 5 2.75 5C1.50736 5 0.5 3.99264 0.5 2.75C0.5 1.50736 1.50736 0.5 2.75 0.5ZM2.75 2C2.33579 2 2 2.33579 2 2.75C2 3.16421 2.33579 3.5 2.75 3.5C3.16421 3.5 3.5 3.16421 3.5 2.75C3.5 2.33579 3.16421 2 2.75 2Z"
      fill="#353549"
    />
  </svg>
);

Filters.displayName = "FiltersIcon";

export default Filters;

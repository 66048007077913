import type { ReactNode } from "react";
import React from "react";
import type { Control } from "react-hook-form";

import type { Contract } from "@/api";

import type { ContractsFieldsTypes } from "../types";
import DateField from "./date-field/DateField";
import DualStateSelectField from "./dual-state-select-field/DualStateSelectField";
import FileField from "./file-field/FileField";
import LongTextField from "./long-text-field/LongTextField";
import TermsDropdownField from "./terms-dropdown-field/TermsDropdownField";

interface Props {
  control: Control<Contract>;
  fieldData: ContractsFieldsTypes;
}

function ContractsRowFieldRenderer({ control, fieldData }: Props) {
  const width = fieldData.width || 200;
  const fieldComponents: { [key: string]: ReactNode } = {
    account_name: (
      <LongTextField
        width={width}
        name="account_name"
        control={control}
        locked
      />
    ),
    renewal: (
      <DualStateSelectField
        negativeText="Manual Renewal"
        positiveText="Automatic Renewal"
        width={width}
        name="renewal"
        control={control}
        locked
      />
    ),
    file: <FileField width={width} name="file" control={control} locked />,
    start_date: (
      <DateField width={width} name="start_date" control={control} locked />
    ),
    review_date: (
      <DateField width={width} name="review_date" control={control} locked />
    ),
    end_date: (
      <DateField width={width} name="end_date" control={control} locked />
    ),
    terms: (
      <TermsDropdownField
        width={width}
        name="terms"
        control={control}
        hasTableView
        locked
      />
    ),
    notes: (
      <LongTextField width={width} name="notes" control={control} locked />
    ),
  };

  const selectedComponent = fieldComponents[fieldData.field_name];

  return (
    selectedComponent || (
      <li className="locked-cell pointer-events-none" style={{ width }}>
        <div className="cell-content">N/A</div>
      </li>
    )
  );
}

export default ContractsRowFieldRenderer;

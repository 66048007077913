import { Button, FilePicker, useToast } from "@themis/ui";
import React from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useParams } from "react-router-dom";

import { useCreateDocument } from "@/api/queries/moduleAssessments";

function DocumentUploadButton() {
  const toast = useToast();

  const { moduleAssessmentId } = useParams<{
    moduleAssessmentId: string;
  }>();

  const { mutateAsync: createDocument } = useCreateDocument({
    moduleAssessmentId: Number(moduleAssessmentId),
    onSuccess: () => {
      toast({ content: "Document has been created!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not create document.",
        variant: "error",
      });
    },
  });

  async function handleFileChange(signedIds: string[]) {
    const requestBody = { data: { file: signedIds[0] } };

    await createDocument(requestBody);
  }

  return (
    <DirectUploadProvider
      onSuccess={handleFileChange}
      render={({ handleUpload, ready, uploads }) => {
        return (
          <FilePicker
            isLoading={["uploading", "waiting"].includes(uploads[0]?.state)}
            percentage={Math.round(uploads[0]?.progress) || 0}
            readOnly={!ready}
            onSelectFile={(selectedFile) => handleUpload([selectedFile])}
            trigger={<Button size="sm">Upload</Button>}
            alignPopover="end"
          />
        );
      }}
    />
  );
}

export default DocumentUploadButton;

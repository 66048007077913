import React from "react";
import { useHistory } from "react-router-dom";

import ArrowLeftIcon from "../../../images/table-image/icon/arrow-left.svg";

const TITLE = {
  create: "Add New Role",
  edit: "Change Permissions",
};

interface Props {
  rightSideElement?: React.ReactNode;
  type?: "create" | "edit";
}

function PermissionHeader({ type, rightSideElement }: Props) {
  // Variables
  const history = useHistory();

  // Functions
  const handleGoBack = () => history.push("/settings/roles");

  return (
    <div className="permission-header">
      <div className="permission-header-content">
        <div className="permission-header-title">
          {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
          {["create", "edit"].includes(type) && (
            <button
              className="permission-header-back-button"
              data-testid="permission-back-button"
              onClick={handleGoBack}
            >
              <img src={ArrowLeftIcon} alt="arrow-left-icon" />
            </button>
          )}
          {/* @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
          {TITLE[type]}
        </div>
        {rightSideElement}
      </div>
    </div>
  );
}

export default PermissionHeader;

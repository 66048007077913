import { HeaderTabs } from "@themis/ui";
import React from "react";
import { generatePath, useLocation, useParams } from "react-router-dom";

import { COMPANY_RETRO_PATH, HOME_PATH, MY_WORK_PATH } from "../pages";

export function HomeTabs() {
  const { pathname } = useLocation();

  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const tabs = [
    {
      value: generatePath(HOME_PATH, {
        workspace_id,
      }),
      name: "Company Outlook",
      key: "company-outlook",
    },
    {
      value: generatePath(MY_WORK_PATH, {
        workspace_id,
      }),
      name: "My Work",
      key: "my-work",
    },
    {
      value: generatePath(COMPANY_RETRO_PATH, {
        workspace_id,
      }),
      name: "Company Retro",
      key: "company-retro",
    },
  ];

  return (
    <HeaderTabs
      tabs={tabs}
      selectedTab={pathname}
      tabsListClassName="tw-bg-neutral-25 tw-px-3"
      dataTestId="home-tabs"
      isLink
    />
  );
}

import { observer } from "mobx-react";
import React from "react";

import ViewLink from "@/components/table/shared/ViewLink";
import QuestionnaireApproval from "@/components/table/vendor-due-diligence/QuestionnaireApproval";
import type { VendorQuestionnaireStatus } from "@/components/table/vendor-due-diligence/VendorQuestionnairesTable/helpers";
import { useMainStore } from "@/contexts/Store";

function VendorQuestionnaireTableActionButton({
  questionnaire,
  isActive,
  isUserInternal,
  status,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  questionnaire: any; // TODO: type questionnaire in vendor-due-diligence
  isActive: boolean;
  isUserInternal: boolean;
  status: VendorQuestionnaireStatus;
}) {
  const mainStore = useMainStore();

  const { isIW: isInternalWorkspace, workspaceID } = mainStore.context;

  if (
    !isActive ||
    (isUserInternal && ["Shared", "Incomplete", "Not Started"].includes(status))
  ) {
    return (
      <ViewLink
        url={`/workspaces/${workspaceID}/modules/vendor-due-diligence/questionnaires/${questionnaire.id}/complete`}
        label="Review"
      />
    );
  }

  if (status === "Complete" && isInternalWorkspace) {
    return <QuestionnaireApproval questionnaire={questionnaire} />;
  }

  return null;
}

export default observer(VendorQuestionnaireTableActionButton);

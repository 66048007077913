import React from "react";

import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { Typography } from "../Elements";
import { iconForThemisModuleIdentifier } from "../helpers/iconForThemisModuleIdentifier";

interface Props {
  moduleIdentifier: string;
  moduleDisplayName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  color?: any; // TODO: PropTypes.oneOf(THEMIS_COLORS)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  size?: any; // TODO: PropTypes.oneOf(THEMIS_TYPOGRAPHY_SIZES)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  weight?: any; // TODO: PropTypes.oneOf(THEMIS_TYPOGRAPHY_WEIGHTS)
}

const ModuleLabel = ({
  moduleIdentifier,
  moduleDisplayName,
  color,
  size,
  weight,
}: Props) => {
  return (
    <div className="policy-label">
      <img
        className="policy-label__icon"
        alt={`${moduleDisplayName} logo`}
        src={iconForThemisModuleIdentifier(
          moduleIdentifier as ModuleIdentifier,
        )}
      />
      <Typography
        label={moduleDisplayName}
        color={color}
        size={size}
        weight={weight}
      />
    </div>
  );
};

ModuleLabel.defaultProps = {
  color: "generalMidnightDark",
  size: "xs",
  weight: "semiBold",
};

export default ModuleLabel;

export const supportedViewFileTypes = [
  "doc",
  "docx",
  "ppt",
  "pptx",
  "xls",
  "xlsx",
  "pdf",
  "jpg",
  "jpeg",
  "png",
  "mp4",
  "mov",
  "pptx",
  "mp3",
  "wav",
  "gif",
];

// @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
export function isSupportedViewFileType(filename) {
  if (!filename) {
    return false;
  }
  return supportedViewFileTypes.includes(
    getFileExtension(filename).toLowerCase(),
  );
}

export const supportedViewFileTypesString = supportedViewFileTypes.join(", ");

// @ts-expect-error TS(7006) FIXME: Parameter 'fileName' implicitly has an 'any' type.
export const getFileExtension = (fileName) => {
  const splitedExtension = fileName.split(".");
  const lenghtOfSplitedExtension = splitedExtension.length;
  if (lenghtOfSplitedExtension <= 1) {
    return "";
  }

  return splitedExtension[lenghtOfSplitedExtension - 1];
};

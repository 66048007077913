import { IconButton, MiniTag } from "@themis/ui";
import React from "react";
import { generatePath, Link, useParams } from "react-router-dom";

import Edit from "@/assets/svgs/edit";
import { MiniTag as OldMiniTag } from "@/components/Elements";
import "@/features/notifications/pages";
import {
  MODULE_NOTIFICATIONS_EDIT_PATH,
  UNLOCK_TASK_TAB,
} from "@/features/notifications/pages/constants";
import type { ModuleNotificationParams } from "@/features/notifications/types/types";
import type NotificationsRulesStore from "@/stores/data/NotificationsRules";
import type { NotificationRuleData } from "@/stores/types/notification-rule-types";
import { ENABLED } from "@/stores/types/notification-rule-types";

import { NotificationText } from "../text";

export default function AutoUnlockNotificationRow(props: {
  notificationRule: NotificationRuleData;
  fieldsForSelectedModule: NotificationsRulesStore["fieldsForSelectedModule"];
}) {
  const params = useParams<ModuleNotificationParams>();
  const { scheduled } = props.notificationRule.data;

  const isEnabled = props.notificationRule.data.enabled.options.includes(
    ENABLED.TRUE,
  );

  const xDaysBefore =
    props.notificationRule.data.scheduled?.interval?.join(", ");
  const triggerColumnText = props.fieldsForSelectedModule.find(
    (columnOption) =>
      columnOption.name === scheduled?.trigger_column?.options?.[0],
  )?.display_name;

  return (
    <div className="notifications-container__single tw-flex tw-items-center tw-justify-between">
      <div className="tw-flex tw-gap-1.5">
        <NotificationText label="Status" accent />
        <NotificationText label="is" />

        <div className="tw-flex tw-items-center tw-gap-2">
          <MiniTag color="primary" size="md">
            Completed
          </MiniTag>
        </div>
        <NotificationText label="email (" />
        <NotificationText label={`${xDaysBefore} days before`} accent />
        <NotificationText label="the" />
        <NotificationText label={String(triggerColumnText)} accent />
        <NotificationText label=")" />
      </div>
      <div className="tw-flex tw-items-center tw-gap-2">
        <OldMiniTag
          label={isEnabled ? "Enabled" : "Disabled"}
          theme={isEnabled ? "green" : "gray"}
        />
        <Link
          className="tw-flex"
          to={{
            pathname: generatePath(MODULE_NOTIFICATIONS_EDIT_PATH, {
              ...params,
              tab: UNLOCK_TASK_TAB,
              notificationId: props.notificationRule.id,
            }),
            state: { fromAllTab: !params.tab },
          }}
        >
          <IconButton
            // eslint-disable-next-line react/no-unstable-nested-components
            Icon={() => <Edit width="16px" />}
            color="transparent"
            size="sm"
          />
        </Link>
      </div>
    </div>
  );
}

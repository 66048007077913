import { useMutation, useQueryClient } from "@tanstack/react-query";

import {
  createFavoriteModuleWorkspace,
  deleteFavoriteModuleWorkspace,
} from "../gen/axios/favoritesController";

export const WORKPSACE_QUERY_KEY = "workspace_id";

export function useCreateFavoriteModuleWorkspace(
  workspaceID: number,
  onSuccess?: () => void,
  onError?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (moduleWorkspaceID: number) =>
      createFavoriteModuleWorkspace(workspaceID, {
        module_workspace_id: moduleWorkspaceID,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          WORKPSACE_QUERY_KEY,
          { success: true, type: "create" },
          workspaceID,
        ],
      });
      onSuccess?.();
    },
    onError,
  });
}

export function useDeleteFavoriteModuleWorkspace(
  workspaceID: number,
  onSuccess?: () => void,
  onError?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (favoriteModuleWorkpsaceID: number) =>
      deleteFavoriteModuleWorkspace(favoriteModuleWorkpsaceID),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          WORKPSACE_QUERY_KEY,
          { success: true, type: "delete" },
          workspaceID,
        ],
      });
      onSuccess?.();
    },
    onError,
  });
}

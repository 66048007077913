import React from "react";
import { Route } from "react-router-dom";

import { AuthRoutes } from "@/features/auth";
import {
  ExternalReview,
  SubmitMoreInformation,
} from "@/features/conflict-of-interest";
import { ROUTES as RA_ROUTES } from "@/features/risk-assessment";
import QuestionnaireFormGuard from "@/features/risk-assessment/components/Questionnaires/QuestionnaireForm/QuestionnaireFormGuard";
import VendorQuestionnaireForm from "@/features/vendor-due-diligence/pages/form/ExternalQuestionnaireForm";

import DetailView from "../components/detailView/DetailView";
import FileExportDownload from "../components/settings/FileExportDownload";
import AttestationView from "../components/table/policy/AttestationView";

export default function UnauthenticatedRoutes() {
  return (
    <>
      <AuthRoutes />
      <Route path="/exported_files/:exported_file_id">
        <FileExportDownload />
      </Route>
      <Route path="/attestations/:attestation_id">
        <AttestationView />
      </Route>
      <Route
        exact
        path={`${RA_ROUTES.QUESTIONNAIRE_FORM_PATH}/:questionnaireExternalId`}
      >
        <QuestionnaireFormGuard />
      </Route>
      <Route exact path="/forms/vendor-questionnaires/:questionnaireExternalId">
        <VendorQuestionnaireForm />
      </Route>
      <Route path="/:company_name/submit_conflict_of_interest/:token">
        <DetailView
          moduleIdentifier="conflicts_of_interest"
          ignoredFields={[
            "status",
            "approvers",
            "public_compliance_notes",
            "submission_date",
            "additional_info",
            "compliance_notes",
            "attachments",
          ]}
          isShareDetailView
        />
      </Route>
      <Route path="/:company_name/submit_complaints/:token">
        <DetailView
          moduleIdentifier="complaints"
          showContactsApprovers
          ignoredFields={["reviewer", "status", "verifier"]}
          isShareDetailView
        />
      </Route>
      <Route path="/:company_name/submit_customer_support/:token">
        <DetailView
          moduleIdentifier="customer_support"
          showContactsApprovers
          ignoredFields={[
            "product",
            "customer_support_status",
            "owner",
            "main_category",
            "date_closed",
            "complaint_details",
            "risk_level",
            "resolution",
            "verifier",
            "reviewer",
            "response",
            "priority",
            "response_document",
            "status",
            "secondary_category",
          ]}
          isShareDetailView
        />
      </Route>
      <Route path="/conflicts_of_interest/submit_more_information/:token">
        <SubmitMoreInformation />
      </Route>
      <Route path="/conflicts_of_interest/:externalToken/review/:userToken/:reviewType">
        <ExternalReview />
      </Route>
    </>
  );
}

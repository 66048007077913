import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../Elements";
import { iconForThemisModuleIdentifier } from "../../helpers/iconForThemisModuleIdentifier";
import { nameFromModuleWorkspace } from "../../helpers/nameForThemisModuleIdentifier";
import CompanySettingsNavigation from "../CompanySettingsNavigation";
import ShowModuleConfiguration from "./ShowModuleConfiguration";

function Configuration() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [activeIdentifiers, setActiveIdentifiers] = useState([]);
  const [showModuleSelection, setShowModuleSelection] = useState(false);

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'moduleName' does not exist on type '{}'.
  const moduleIdentifier = useParams().moduleName;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  useEffect(() => {
    setActiveIdentifiers(
      // @ts-expect-error TS(2345) FIXME: Argument of type '("documents" | "marketing" | "au... Remove this comment to see the full error message
      moduleWorkspaces
        ?.filter((moduleWorkspace) => moduleWorkspace.added)
        ?.map((module) => module.themis_module.identifier),
    );
  }, [moduleWorkspaces]);

  const ALL_MODULES = [
    {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      active: activeIdentifiers.includes("marketing"),
      identifier: "marketing",
    },
    {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      active: activeIdentifiers.includes("documents"),
      identifier: "documents",
    },
  ];

  const activeModules = ALL_MODULES.filter((module) => module.active === true);

  const [selectedModule, setSelectedModule] = useState(
    ALL_MODULES.find((module) => module.identifier === moduleIdentifier) ||
      ALL_MODULES[0],
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'module' implicitly has an 'any' type.
  const handleModuleChange = (module) => {
    setSelectedModule(module);
    setShowModuleSelection(false);
  };

  return (
    <div className="table-wrap">
      <div className="settings-wrap">
        <CompanySettingsNavigation />
        <div className="configuration-wrap reports-content">
          <div className="actions select-module">
            <p className="cell-description-wrap">Select Module</p>
            <Popup
              position="bottom right"
              trigger={
                <button
                  className={classNames(
                    "reports-module-dropdown select-button",
                    {
                      opened: showModuleSelection,
                    },
                  )}
                >
                  <div className="module-item">
                    <img
                      className="icon-image"
                      src={iconForThemisModuleIdentifier(
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        selectedModule.identifier,
                      )}
                      alt={`${selectedModule.identifier}-icon`}
                    />
                    <span>
                      {nameFromModuleWorkspace(
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        selectedModule.identifier,
                        moduleWorkspaces,
                      )}
                    </span>
                  </div>
                  <Icon name="chevronDown" color="generalDark" size="de" />
                </button>
              }
              open={showModuleSelection}
              onOpen={() => setShowModuleSelection(true)}
              onClose={() => setShowModuleSelection(false)}
              keepTooltipInside
            >
              <ul className="table-dropdown reports-dropdown-popup">
                {activeModules.map((module) => (
                  <li
                    key={module.identifier}
                    className={classNames({
                      active: selectedModule === module,
                    })}
                    onClick={() => handleModuleChange(module)}
                  >
                    <div className="module-item">
                      <img
                        className="icon-image"
                        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                        src={iconForThemisModuleIdentifier(module.identifier)}
                        alt={`${module.identifier}-icon`}
                      />
                      <span>
                        {nameFromModuleWorkspace(
                          // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                          module.identifier,
                          moduleWorkspaces,
                        )}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </Popup>
          </div>
          <p className="records-info">Records</p>
          <ShowModuleConfiguration
            moduleIdentifier={selectedModule.identifier}
          />
        </div>
      </div>
    </div>
  );
}
export default observer(Configuration);

import React from "react";

interface Props {
  subject?: "Creative" | "Complaint" | "Audit";
}

export default function CreateCTACW({ subject }: Props) {
  function h3Title() {
    const sentences = {
      Creative: "Add / Share Marketing Creative",
      Complaint: "Add / Share Complaints",
      Audit: "Add / Share Audit",
    };

    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (!sentences[subject]) {
      return null;
    }

    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    return <h3>{sentences[subject]}</h3>;
  }

  function mainText() {
    const sentences = {
      Creative: [
        'Create Marketing Creative for the collaborative workspace by clicking "Add New Marketing Creative +" on the top to start.',
        "Shared Marketing Creative will be visible here.",
      ],
      Complaint: [
        'Create Complaints for the collaborative workspace by clicking "Add New Complaints" on the top to start.',
        "Shared Complaints are visible here.",
      ],
      Audit: [
        'Create Audit records for the collaborative workspace by clicking "Add New Report +" on the top to start.',
        "Shared Audit records will be visible here.",
      ],
    };

    // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (!sentences[subject]) {
      return null;
    }

    return (
      // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
      sentences[subject].map((item, index) => (
        <p key={index} style={{ justifyContent: "center" }}>
          {item}
        </p>
      ))
    );
  }

  return (
    <div className="no-column-wrap" data-testid="create-first-cta">
      <div className="no-column-block">
        <div className="no-column-procedure">
          <div className="no-column-title">{h3Title()}</div>
          {mainText()}
        </div>
      </div>
    </div>
  );
}

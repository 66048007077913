import { useEffect, useRef } from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'effect' implicitly has an 'any' type.
export const useEffectExceptOnMount = (effect, dependencies) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => unmount && unmount();
    }
    mounted.current = true;
  }, dependencies);

  // Reset on unmount for the next mount.
  // @ts-expect-error TS(2322) FIXME: Type '() => boolean' is not assignable to type 'vo... Remove this comment to see the full error message
  useEffect(() => () => (mounted.current = false), []);
};

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import smartAssistance from "../../../images/table-image/icon/smart-assistance-blue.svg";
import thumbsUpColorIcon from "../../../images/table-image/icon/thumbs-up-color.svg";
import thumbsUpIcon from "../../../images/table-image/icon/thumbs-up.svg";
import warningIcon from "../../../images/table-image/icon/warning-icon.svg";

function Toast() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { text } = mainStore.toast;
  const { icon } = mainStore.toast;

  const toastClasses = classNames("toast", {
    "success-user-dropdown": icon === "info",
    "error-user-dropdown": icon === "warning-icon",
    black: icon === "thumbs-up-color",
  });

  function renderToastIcon() {
    switch (icon) {
      case "thumbs-up":
        return <img src={thumbsUpIcon} alt="thumbs-up" />;
      case "info":
        return <img src={smartAssistance} alt="thumbs-up" />;
      case "thumbs-up-color":
        return <img src={thumbsUpColorIcon} alt="thumbs-up-color" />;
      case "warning-icon":
        return <img src={warningIcon} alt="warning-icon" />;
      default:
        return <img src={thumbsUpIcon} alt="thumbs-up" />;
    }
  }

  return (
    text && (
      <div className={toastClasses} data-testid="toast-container">
        {renderToastIcon()}
        <span>{text}</span>
      </div>
    )
  );
}

export default observer(Toast);

import { useQueries } from "@tanstack/react-query";

import { getModuleWorkspaces } from "../legacy/workspaces/workspaces";

export const MODULE_WORKSPACES_QUERY_KEY = "module_workspaces";

/**
 * @param workspaceIds - The workspace ids for which the modules data is required.
 * @returns The modules data of the workspaces.
 * Order of the data is same as the order of the workspace ids.
 */
export function useWorkspacesModulesList(workspaceIds: number[]) {
  return useQueries({
    queries: workspaceIds.map((workspaceId) => ({
      queryKey: [MODULE_WORKSPACES_QUERY_KEY, workspaceId],
      queryFn: () => getModuleWorkspaces(workspaceId),
    })),
  });
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const CommentLinear = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11.3625C2 6.54329 6.6578 3 12.001 3C17.3442 3 22.0012 6.54339 22 11.3627C22 11.3706 21.9999 11.3784 21.9997 11.3863C21.975 12.4115 21.7463 13.4216 21.3269 14.3574C20.9775 15.137 20.5017 15.8521 19.9196 16.4742L20.3608 20.2618C20.4013 20.6098 20.255 20.9534 19.9761 21.1654C19.6972 21.3774 19.327 21.4264 19.0025 21.2942L14.4732 19.449C13.6617 19.6482 12.8273 19.7411 11.991 19.7249C6.64665 19.7205 2 16.1783 2 11.3625ZM12.001 4.98198C7.36 4.98198 3.98198 7.99287 3.98198 11.3625C3.98198 14.7323 7.35541 17.7429 12.001 17.7429L12.0212 17.7431C12.7808 17.7586 13.5384 17.6608 14.2692 17.453C14.4821 17.3925 14.7091 17.4049 14.9141 17.4884L18.1982 18.8264L17.8956 16.2279C17.8592 15.9155 17.9732 15.6045 18.2028 15.3896C18.7593 14.8689 19.2065 14.2424 19.5183 13.5468C19.8284 12.8549 19.9982 12.1084 20.018 11.3505C20.011 7.98577 16.6366 4.98198 12.001 4.98198Z"
      fill="#353549"
    />
  </svg>
);

CommentLinear.displayName = "CommentLinearIcon";

export default CommentLinear;

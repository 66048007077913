import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Spinner from "../../table/shared/Spinner";

function IMOperationalControlsSaveButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSaving, setIsSaving] = useState(false);

  // Variables
  const history = useHistory();
  const location = useLocation();
  const { changed } = mainStore.issueManagement.operationalControlsData;
  const { workspaceID } = mainStore.context;
  const disabled = isSaving || !changed;

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const id = parseInt(
      location.pathname
        .split("/issue-management/")[1]
        .split("/operational-controls-add-new")[0],
      10,
    );
    await mainStore.issueManagement.saveOperationalControls(id);

    setIsSaving(false);

    history.push(
      `/workspaces/${workspaceID}/modules/issue-management/${id}/operational-controls`,
    );
  }

  return (
    <button
      className="table-button header-action"
      disabled={disabled}
      onClick={handleClick}
    >
      Apply Controls
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(IMOperationalControlsSaveButton);

import "./generic-text-area-cell.scss";

import classNames from "classnames";
import React, { useRef, useState } from "react";

import { useAddTextareaHeightBehavior } from "@/components/detailView/helpers";

import LongTextTableCell from "../LongTextTableCell";
import TableCell from "../TableCell";

type GenericTextAreaCellProps = Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  "onChange" | "onInput"
> & {
  onChange: (value: string) => void;
  isFirstDataCell?: boolean;
  isLastDataCell?: boolean;
  minWidth?: number;
};

const GenericTextAreaCell = ({
  onChange,
  className,
  onBlur,
  onFocus,
  minWidth,
  isFirstDataCell,
  isLastDataCell,
  ...rest
}: GenericTextAreaCellProps) => {
  const ref = useRef(null);
  const [focused, setFocused] = useState(false);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'MutableRefObject<null>' is not a... Remove this comment to see the full error message
  const onInput = useAddTextareaHeightBehavior(ref);

  return rest.disabled ? (
    <LongTextTableCell>{rest.value}</LongTextTableCell>
  ) : (
    <TableCell
      minWidth={minWidth}
      noPadding
      isFocused={focused}
      isEditable={!rest.disabled}
      isFirstDataCell={isFirstDataCell}
      isLastDataCell={isLastDataCell}
    >
      <textarea
        spellCheck={false}
        className={classNames(className, "generic-text-area-cell")}
        {...rest}
        onChange={(ev) => onChange(ev.target.value)}
        ref={ref}
        onFocus={(...args) => {
          setFocused(true);
          onFocus?.(...args);
        }}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
        onInput={onInput}
      />
    </TableCell>
  );
};

export default GenericTextAreaCell;

import { z } from "zod";

export const useValidationSchema = () => {
  const commonColumnData = {
    name: z.string().min(1),
    requiredColumn: z.optional(z.boolean()),
    multipleSelections: z.optional(z.boolean()),
  };

  return z.discriminatedUnion("columnType", [
    z.object({
      columnType: z.literal("text"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("dropdown"),
      columnData: z.object({
        ...commonColumnData,
        options: z.array(z.string()),
      }),
    }),
    z.object({
      columnType: z.literal("link"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("date"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("checklist"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("attachement"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("user"),
      columnData: z.object({ ...commonColumnData }),
    }),
  ]);
};

export type ValidationSchema = z.infer<ReturnType<typeof useValidationSchema>>;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteContactMutationResponse,
  DeleteContactPathParams,
} from "../../models/DeleteContact";

/**
 * @description Delete contact
 * @summary Delete contact
 * @link /contacts/:id
 */
export async function deleteContact(
  id: DeleteContactPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteContactMutationResponse>["data"]> {
  const res = await client<DeleteContactMutationResponse>({
    method: "delete",
    url: `/contacts/${id}`,
    ...options,
  });
  return res.data;
}

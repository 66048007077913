import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@/components/form/Button";
import Header from "@/components/header/Header";
import { MODULE_LIST } from "@/config/modules";

import ModulesBlock from "../components/ModulesBlock";

export const PickModules = () => {
  // Variables
  const history = useHistory();

  // State
  const [allModulesSelected, setAllModulesSelected] = useState(false);

  // Functions
  async function goToNextPage() {
    history.push("/invite-team");
  }

  return (
    <div className="modules-wrap">
      <div className="container">
        <Header createName="Enter Company Info" value={100} />
        <div className="modules-wrap-block">
          <div className="modules-wrap-element">
            <div />
            <h3>Pre-Selected Modules</h3>
            {MODULE_LIST.filter((module) => module.isIncluded).map((module) => (
              <ModulesBlock
                key={module.identifier}
                identifier={module.identifier}
                title={module.title}
                description={module.description}
                isIncluded={module.isIncluded}
                isComingSoon={module.isComingSoon}
              />
            ))}
          </div>
          <div className="modules-wrap-element">
            <div className="header-block">
              <h3>Other Modules</h3>
              <button
                onClick={() => setAllModulesSelected(!allModulesSelected)}
                style={{ color: "#6D71F9" }}
                data-testid="pick-modules-select-all"
              >
                {allModulesSelected ? "Unselect All" : "Select All"}
              </button>
            </div>
            {MODULE_LIST.filter((module) => module.isIncluded === false).map(
              (module) => (
                <ModulesBlock
                  key={module.identifier}
                  identifier={module.identifier}
                  title={module.title}
                  description={module.description}
                  isIncluded={module.isIncluded}
                  isComingSoon={module.isComingSoon}
                  isSelected={allModulesSelected}
                />
              ),
            )}
          </div>
          <div className="pick-modules-submit-container">
            <Button title="Done" onClick={goToNextPage} />
          </div>
        </div>
      </div>
    </div>
  );
};

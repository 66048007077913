import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListThemisRecordFieldsPathParams,
  ListThemisRecordFieldsQueryResponse,
} from "../../models/ListThemisRecordFields";

/**
 * @description Fetch all fields for specific ThemisRecord in Workspace
 * @summary Fetch fields for ThemisRecord
 * @link /companies/:company_id/:record_type/fields
 */
export async function listThemisRecordFields(
  companyId: ListThemisRecordFieldsPathParams["company_id"],
  recordType: ListThemisRecordFieldsPathParams["record_type"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListThemisRecordFieldsQueryResponse>["data"]> {
  const res = await client<ListThemisRecordFieldsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/${recordType}/fields`,
    ...options,
  });
  return res.data;
}

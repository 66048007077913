// @ts-expect-error TS(7006) FIXME: Parameter 'questionnaire' implicitly has an 'any' ... Remove this comment to see the full error message
export function getStatus(questionnaire): VendorQuestionnaireStatus {
  if (questionnaire.completed_at) {
    return "Complete";
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'cq' implicitly has an 'any' type.
  if (questionnaire.checklist_questions.some((cq) => cq.answer)) {
    return "Incomplete";
  }
  if (questionnaire.shared) {
    return "Shared";
  }
  return "Not Started";
}

export type VendorQuestionnaireStatus =
  | "Complete"
  | "Incomplete"
  | "Shared"
  | "Not Started";

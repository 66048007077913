import { format, parseISO } from "date-fns";
import type { PreviewTableProps } from "packages/ui/src/components/PreviewTable";
import React from "react";

import type { ModuleAssessment } from "@/api";

import { ModuleAssessmentAssigneeCell } from "../components/ModuleAssessmentTable/ModuleAssessmentAssigneeCell";
import { ModuleAssessmentControlEffectivenessRating } from "../components/ModuleAssessmentTable/ModuleAssessmentControlEffectivenessRating";
import { ModuleAssessmentStatusTag } from "../components/ModuleAssessmentTable/ModuleAssessmentStatusTag";
import { ModuleAssessmentTextCell } from "../components/ModuleAssessmentTable/ModuleAssessmentTextCell";

export const MODULE_ASSESSMENT_TABLE_COMPONENT_OVERRIDES: PreviewTableProps<ModuleAssessment>["componentOverrides"] =
  {
    status: (moduleAssessment) => (
      <ModuleAssessmentStatusTag status={moduleAssessment.status} />
    ),
    compliance_status: (moduleAssessment) => (
      <ModuleAssessmentStatusTag
        status={moduleAssessment.compliance_status?.toString()}
        isDefaultValue={false}
      />
    ),
    summary: (moduleAssessment) => (
      <ModuleAssessmentTextCell text={moduleAssessment.summary || ""} />
    ),

    control_effectiveness_rating: (moduleAssessment) => (
      <ModuleAssessmentControlEffectivenessRating
        moduleAssessment={moduleAssessment}
      />
    ),
    due_date: (moduleAssessment) =>
      moduleAssessment.due_date
        ? format(parseISO(moduleAssessment.due_date), "MM/dd/yyyy")
        : null,
    assessment_date: (moduleAssessment) =>
      moduleAssessment.assessment_date
        ? format(parseISO(moduleAssessment.assessment_date), "MM/dd/yyyy")
        : null,
    assignee: (moduleAssessment) =>
      moduleAssessment.assignee ? (
        <ModuleAssessmentAssigneeCell owner={moduleAssessment.assignee} />
      ) : null,
    evidences: (moduleAssessment) => (
      <ul>
        {moduleAssessment?.evidences?.map((evidence) => (
          <li key={evidence?.id}>
            {evidence?.file ? evidence.file.file_name : null}
          </li>
        )) || null}
      </ul>
    ),
  } as const;

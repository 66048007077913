"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import React from "react";
import { cn } from "../../lib/utils";
var ScrollArea = React.forwardRef(function (_a, ref) {
    var className = _a.className, children = _a.children, props = __rest(_a, ["className", "children"]);
    return (React.createElement(ScrollAreaPrimitive.Root, __assign({ ref: ref, className: cn("tw-relative tw-overflow-hidden", className) }, props),
        React.createElement(ScrollAreaPrimitive.Viewport, { className: "tw-h-full tw-w-full tw-rounded-[inherit]" }, children),
        React.createElement(ScrollBar, null),
        React.createElement(ScrollAreaPrimitive.Corner, null)));
});
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;
var ScrollBar = React.forwardRef(function (_a, ref) {
    var className = _a.className, _b = _a.orientation, orientation = _b === void 0 ? "vertical" : _b, props = __rest(_a, ["className", "orientation"]);
    return (React.createElement(ScrollAreaPrimitive.ScrollAreaScrollbar, __assign({ ref: ref, orientation: orientation, className: cn("tw-flex tw-touch-none tw-select-none tw-transition-colors tw-animate-in tw-fade-in", orientation === "vertical" &&
            "tw-h-full tw-w-1.5 tw-border-l tw-border-l-transparent tw-p-[2px]", orientation === "horizontal" &&
            "tw-h-1.5 tw-flex-col tw-border-t tw-border-t-transparent tw-p-[2px]", className) }, props),
        React.createElement(ScrollAreaPrimitive.ScrollAreaThumb, { className: "tw-relative tw-flex-1 tw-rounded-full tw-bg-neutral-500" })));
});
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;
export { ScrollArea, ScrollBar };

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

interface Props {
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: any[];
  locked?: boolean;
  pinned?: boolean;
  width?: number;
}

function ActionPlans({ width, recordVersionID, items, pinned, locked }: Props) {
  const mainStore = useMainStore();
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Variables
  const history = useHistory();
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const count = items.length;
  const { workspaceID } = mainStore.context;
  const liClassNames = classNames("controls-cell", {
    pinned,
    "table-cell--disabled": isReadOnly,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isReadOnly,
  });

  // Functions
  const handleCellClick = () => {
    history.push(
      `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/action-plans`,
    );
  };

  return (
    <li
      className={liClassNames}
      style={{ width }}
      data-testid="action-plans-cell"
      onClick={handleCellClick}
    >
      <div className="cell-content">
        <span
          className={classNames("controls-cell-value", {
            "with-value": count > 0,
          })}
        >
          {count > 0 ? `${count} Action Plans` : "- Add Action Plan -"}
        </span>
      </div>
    </li>
  );
}

ActionPlans.defaultProps = {
  width: 200,
  items: [],
};

export default observer(ActionPlans);

import type { PreviewTableColumn } from "@themis/ui";
import React from "react";
import { PiBookOpenBold, PiTextTBold } from "react-icons/pi";

import type { ThemisDocument } from "@/api";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";

import DocumentsActionCell from "../components/Documents/DocumentsActionCell";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
export const PREVIEW_DOCUMENTS_COLUMNS: PreviewTableColumn<ThemisDocument>[] = [
  {
    title: "Name",
    type: "locked",
    width: 276,
    Icon: PiTextTBold,
    Component: (document) => document?.file?.file_name,
  },
  {
    title: "Uploaded at",
    type: "locked",
    width: 250,
    Icon: PiBookOpenBold,
    Component: (document) =>
      `Uploaded at: ${formatTimeAfterLongDate(
        stringToDateAndTimeSSSZ(document?.file?.created_at),
        { removeAt: true, monthFormat: "short" },
      )}`,
  },
  {
    type: "actions",
    Component: (document) => (
      <DocumentsActionCell
        documentId={document?.id || 0}
        documentFile={document?.file || null}
      />
    ),
  },
] as const;

import dayjs from "dayjs";

export const getSelectedDate = (
  timeRange?: string | undefined,
  days?: number | undefined,
) => {
  let result = dayjs().startOf("day");
  switch (timeRange) {
    case "Today":
      break;
    case "Tomorrow":
      result = result.add(1, "day");
      break;
    case "Yesterday":
      result = result.subtract(1, "day");
      break;
    case "One Week Ago":
    case "The Past Week":
      result = result.subtract(1, "week");
      break;
    case "The Next Week":
      result = result.add(1, "week");
      break;
    case "One Month Ago":
    case "The Past Month":
      result = result.subtract(1, "month");
      break;
    case "One Month from Now":
    case "The Next Month":
      result = result.add(1, "month");
      break;
    case "The Past Year":
      result = result.subtract(1, "year");
      break;
    case "The Next Year":
      result = result.add(1, "year");
      break;
    case "# of Days Ago":
    case "The Past # of Days":
      if (days) {
        result = result.subtract(days, "days");
      }
      break;
    case "# of Days from Now":
    case "The Next # of Days":
      if (days) {
        result = dayjs().add(days, "days");
      }
      break;
    default:
      break;
  }

  return result.toDate();
};

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// IMPORTANT: without this import, CSS will not compile
import "./stylesheets/application.scss";
import "./stylesheets/tailwind.css";

import * as ActiveStorage from "@rails/activestorage";
import Rails from "@rails/ujs";
import ahoy from "ahoy.js";
// Add polyfills for wide compatibility: https://github.com/rails/webpacker/tree/5-x-stable#usage
// More discussion here: https://babeljs.io/docs/en/usage#polyfill
import "core-js/stable";
// Render our React app
import React from "react";
import { createRoot } from "react-dom/client";
import "regenerator-runtime/runtime";

import App from "@/app/App";
import { initLogRocket } from "@/lib/logrocket";
import { init as initSentry } from "@/lib/sentry";

Rails.start();
ActiveStorage.start();
initLogRocket();
initSentry();
ahoy.trackClicks("a");

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class ControlMappingTypesStore {
  mainStore: MainStore;

  // Observable objects
  controlMappingTypes = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      controlMappingTypes: observable,
      setControlMappingTypes: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async index(companyID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}/control_mapping_types`,
        headers: this.mainStore.getHeaders(),
      });

      this.setControlMappingTypes(response.data.control_mapping_types);
    } catch (error) {
      window.console.log(
        `"ControlMappingTypes#Index for Company" error ${error}`,
      );
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setControlMappingTypes(value) {
    this.controlMappingTypes = value || [];
  }

  // Store Helpers

  cleanup() {
    this.setControlMappingTypes([]);
  }
}

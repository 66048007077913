import React from "react";
import { useHistory } from "react-router-dom";

import Button from "@/components/form/Button";

import Header from "../components/Header";

function EmailVerificationSuccess() {
  const history = useHistory();

  return (
    <div className="check-in">
      <Header />
      <div className="check-in-wrap">
        <div className="email-verification">
          <h2>Email Verification Success</h2>
          <p>
            Your email address has been successfully verified. Please click the
            button below to continue the sign up onboarding flow.
          </p>
          <Button
            title="Continue Sign Up Onboarding"
            onClick={() => {
              history.push("/create-company");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationSuccess;

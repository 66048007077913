import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { workspaceIcons } from "../helpers/workspaceIcons";

function WorkspaceCustomIcon() {
  // State
  const [iconID, setIconID] = useState(20);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  // Effects
  useEffect(() => {
    setButtonDisabled(false);
  }, []);

  const workspaceIconLogo = workspaceIcons
    .filter((element) => element.id === iconID)
    .map((icon) => (
      <div className="big-logo-block" key={icon.id}>
        <img src={icon.url} alt={`workspace-big-logo${icon.id}`} />
      </div>
    ));

  const buttonClass = classNames({ active: !buttonDisabled });

  return (
    <div className="custom-icon-wrap">
      <p className="setup-workspace-text">
        Select a workspace icon below.
        <br />
        You can always change in the future.
      </p>
      <div className="workspace-big-logo">
        {workspaceIconLogo}
        <p>(Current selected icon)</p>
      </div>
      <div className="workspace-logo-wrap">
        {workspaceIcons.map((icon) => {
          const iconClasses = classNames("workspace-logo", {
            active: icon.id === iconID,
          });
          return (
            <div
              key={icon.id}
              className={iconClasses}
              onClick={() => {
                setIconID(icon.id);
              }}
            >
              <img src={icon.url} alt={`workspace-logo${icon.id}`} />
            </div>
          );
        })}
      </div>
      <button className={buttonClass} disabled={buttonDisabled}>
        Save Icon
      </button>
    </div>
  );
}

export default WorkspaceCustomIcon;

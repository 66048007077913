import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import RiskRegisterControlRatingCell from "../../risk-register/RiskRegisterControlRatingCell";

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  isEditable?: boolean;
  locked?: boolean;
}

function ViewRiskRegisterControlRatingCell({
  recordVersion,
  fieldName,
  recordVersionID,
  locked,
  isEditable,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const value = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <RiskRegisterControlRatingCell
      value={value}
      fieldName={fieldName || "NO TITLE"}
      recordVersionID={recordVersionID}
      locked={locked}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      hasErrorClass="detail-view-has-errors"
      // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
      isUserEditable={isEditable}
    />
  );
}

ViewRiskRegisterControlRatingCell.defaultProps = {
  isEditable: true,
};

export default observer(ViewRiskRegisterControlRatingCell);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateContractMutationRequest,
  UpdateContractMutationResponse,
  UpdateContractPathParams,
} from "../../models/UpdateContract";

/**
 * @description Update contract
 * @summary Update contract
 * @link /contracts/:id
 */
export async function updateContract(
  id: UpdateContractPathParams["id"],
  data: UpdateContractMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateContractMutationResponse>["data"]> {
  const res = await client<
    UpdateContractMutationResponse,
    UpdateContractMutationRequest
  >({
    method: "put",
    url: `/contracts/${id}`,
    data,
    ...options,
  });
  return res.data;
}

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const MarketingReportContent = () => (
  <ReportsPage
    identifier="marketing"
    defaultDateColumn="submission_date"
    defaultGroupColumn="status"
  />
);

export default observer(MarketingReportContent);

import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import { Button } from "../Elements";

function StepsSaveButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { moduleWorkspaceID } = mainStore.context;
  const { currentApprovalsSteps, approvalsSteps } =
    mainStore.manageApprovalsSteps;
  const isDisabled = isEqual(currentApprovalsSteps, approvalsSteps);

  // Functions
  async function handleSubmit() {
    await mainStore.manageApprovalsSteps.create(moduleWorkspaceID);
  }

  return (
    <Button
      label="Save Approvals"
      onClick={handleSubmit}
      disabled={isDisabled}
      data-testid="save-approvals-btn"
    />
  );
}

export default observer(StepsSaveButton);

// @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
export const compareModules = (a, b, modules) => {
  const aIndex = modules.indexOf(a.identifier);
  const bIndex = modules.indexOf(b.identifier);

  if (aIndex === -1 && bIndex === -1) {
    return 0;
  }
  if (aIndex === -1) {
    return 1;
  }
  if (bIndex === -1) {
    return -1;
  }

  return aIndex - bIndex;
};

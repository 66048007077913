import { observer } from "mobx-react";
import React from "react";
import { generatePath, useHistory } from "react-router";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";
import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import type { QuestionnaireRead } from "@/features/risk-assessment";
import { QuestionnaireStatus, ROUTES } from "@/features/risk-assessment";
import type { Option } from "@/features/risk-assessment/components/RowOptions/RowOptions";
import RowOptions from "@/features/risk-assessment/components/RowOptions/RowOptions";
import ActionCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ActionCell";
import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";
import Table from "@/features/risk-assessment/components/Table/GenericTable/Table";
import TableCellSpacer from "@/features/risk-assessment/components/Table/GenericTable/TableCellSpacer";
import TableHeaderCell from "@/features/risk-assessment/components/Table/GenericTable/TableHeaderCell";
import TableRow from "@/features/risk-assessment/components/Table/GenericTable/TableRow";
import { getQuestionnaireStatusLabel } from "@/features/risk-assessment/utils/questionnaire-helpers";
import { getDefinitionsFromScores } from "@/features/risk-assessment/utils/scoring-helpers";

import { ScoreCell } from "../../Assessment/AssessmentScoring/ScoreCell";

interface SubmittedQuestionnaireListProps {
  questionnaires: Array<QuestionnaireRead>;
  onRefreshQuestionnaire: () => void;
}

const SubmittedQuestionnaireList = (props: SubmittedQuestionnaireListProps) => {
  const history = useHistory();
  const mainStore = useMainStore();
  const { id: userID } = mainStore.users.user;
  const { workspaceID, isAdmin } = mainStore.context;
  const riskMethodologies = mainStore.riskMethodologies.list || [];

  const downloadQuestionnaire = (id: number) => {
    return QuestionnaireAPI.downloadQuestionnaire(id);
  };

  const onReview = async (questionnaire: QuestionnaireRead) => {
    if (questionnaire.status === QuestionnaireStatus.READY_FOR_REVIEW) {
      await QuestionnaireAPI.setInReview(questionnaire.id);
      props.onRefreshQuestionnaire();
    }

    history.push(
      generatePath(ROUTES.QUESTIONNAIRE_REVIEW_PATH, {
        questionnaireId: questionnaire.id,
        workspace_id: Number(workspaceID),
      }),
    );
  };

  const onUnlock = async (questionnaire: QuestionnaireRead) => {
    await QuestionnaireAPI.setInReview(questionnaire.id);
    props.onRefreshQuestionnaire();
  };

  const generateOptions = (questionnaire: QuestionnaireRead): Option[] => {
    const options: Option[] = [];
    if (questionnaire.status === QuestionnaireStatus.FINALIZED) {
      options.push(
        {
          label: "View",
          onClick: () =>
            history.push(
              generatePath(ROUTES.QUESTIONNAIRE_REVIEW_PATH, {
                questionnaireId: questionnaire.id,
                workspace_id: Number(workspaceID),
              }),
            ),
        },
        {
          label: "Unlock Questionnaire",
          onClick: () => onUnlock(questionnaire),
        },
      );
    } else {
      const canAnswer =
        isAdmin ||
        questionnaire.reviewer_ids?.length === 0 ||
        (userID && questionnaire.reviewer_ids.includes(userID));
      options.push({
        label: "Review",
        disabled: !canAnswer,
        disabledTooltip: !canAnswer
          ? "You cannot review this internal questionnaire"
          : undefined,
        onClick: () => onReview(questionnaire),
      });
    }

    options.push({
      label: "Export",
      onClick: () => downloadQuestionnaire(questionnaire.id),
    });

    return options;
  };

  return (
    <Table
      className="submitted-questionnaires-table"
      header={
        <>
          <TableHeaderCell
            title="Status"
            leftIcon={<Icon color="extrasBlueGrayDarker" name="folderLinear" />}
            firstDataHeader
          />
          <TableHeaderCell
            title="Name"
            leftIcon={<Icon color="extrasBlueGrayDarker" name="book1" />}
          />
          <TableHeaderCell
            title="Overall Risk"
            leftIcon={
              <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
            }
          />
          <TableHeaderCell
            title="Inherent Questions"
            leftIcon={<Icon color="extrasBlueGrayDarker" name="number" />}
          />
          <TableHeaderCell
            title="Inherent Risk"
            leftIcon={
              <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
            }
          />
          <TableHeaderCell
            title="Control Questions"
            leftIcon={<Icon color="extrasBlueGrayDarker" name="number" />}
          />
          <TableHeaderCell
            title="Control Risk"
            leftIcon={
              <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
            }
          />
          <TableHeaderCell
            title="Residual Risk"
            leftIcon={
              <Icon color="extrasBlueGrayDarker" name="priorityMedium" />
            }
            lastDataHeader
          />
          <TableCellSpacer />
        </>
      }
    >
      {props.questionnaires.map((questionnaire, index) => {
        const {
          overallRiskRating,
          controlRiskRating,
          inherentRiskRating,
          residualRiskRating,
        } = getDefinitionsFromScores(
          questionnaire.scoring,
          riskMethodologies.find(
            (value) => value.id === questionnaire.risk_methodology_id,
          ),
        );
        return (
          <TableRow key={questionnaire.id} rowIndex={index}>
            <ContentCell
              content={getQuestionnaireStatusLabel(questionnaire.status, 1)}
              isFirstDataCell
            />
            <ContentCell content={questionnaire.name} />
            <ScoreCell
              rating={overallRiskRating}
              score={questionnaire.scoring?.overall_risk_score}
            />
            <ContentCell
              content={questionnaire.inherent_questions_count || "0"}
            />
            <ScoreCell
              rating={inherentRiskRating}
              score={questionnaire.scoring?.inherent_risk_score}
            />
            <ContentCell
              content={questionnaire.control_questions_count || "0"}
            />
            <ScoreCell
              rating={controlRiskRating}
              score={questionnaire.scoring?.control_risk_score}
            />
            <ScoreCell rating={residualRiskRating} isLastDataCell />

            <ActionCell>
              <RowOptions options={generateOptions(questionnaire)} />
            </ActionCell>
          </TableRow>
        );
      })}
    </Table>
  );
};

export default observer(SubmittedQuestionnaireList);

import { LinkButton } from "@themis/ui";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

import type { Account } from "@/api";
import VendorInputField from "@/components/table/vendor-due-diligence/VendorInputField";
import VendorLogoField from "@/components/table/vendor-due-diligence/VendorLogoField";
import VendorTypeField from "@/components/table/vendor-due-diligence/VendorTypeField";

function VendorDetailAccountInfoContainer({
  accountId,
  canManageAccounts,
  children,
  isNew,
}: {
  accountId: number;
  canManageAccounts: boolean;
  children: React.ReactNode;
  isNew: boolean;
}) {
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const { pathname } = useLocation();
  const workspaceId = Number(workspace_id);

  return (
    <div className="build-vendor-block company-info-block">
      <div className="title tw-flex tw-items-center tw-justify-between tw-gap-5">
        Account Info
        {canManageAccounts && !isNew && (
          <LinkButton
            size="sm"
            color="tertiary"
            to={`/workspaces/${workspaceId}/accounts/${accountId}?redirectTo=${pathname}`}
          >
            Edit
          </LinkButton>
        )}
      </div>
      {children}
    </div>
  );
}

export function VendorDetailAccountInfo({
  canManageAccounts,
  isNew,
  isReadOnly,
  selectedAccount,
}: {
  canManageAccounts: boolean;
  isNew: boolean;
  isReadOnly?: boolean;
  selectedAccount: Account;
}) {
  if (!canManageAccounts) {
    return (
      <VendorDetailAccountInfoContainer
        accountId={selectedAccount.id}
        canManageAccounts={canManageAccounts}
        isNew={isNew}
      >
        <article className="tw-box-border tw-flex tw-h-9 tw-flex-col tw-items-center tw-justify-center tw-gap-6 tw-self-stretch tw-rounded-lg tw-bg-neutral-50 tw-px-3 tw-py-2.5 tw-text-xs tw-text-neutral-300">
          You do not have access to view account details
        </article>
      </VendorDetailAccountInfoContainer>
    );
  }

  return (
    <VendorDetailAccountInfoContainer
      accountId={selectedAccount.id}
      canManageAccounts={canManageAccounts}
      isNew={isNew}
    >
      <div className="row">
        <VendorInputField
          label="Account Name"
          value={selectedAccount.name || ""}
          isReadOnly={isReadOnly}
        />
        <VendorInputField
          label="Legal Entity Name"
          value={selectedAccount.legal_name || ""}
          isReadOnly={isReadOnly}
        />
      </div>
      <div className="row">
        <VendorInputField
          label="Company Website"
          value={selectedAccount.website || ""}
          isReadOnly={isReadOnly}
        />
        <VendorLogoField
          label="Logo Display"
          placeholder="N/A"
          isReadOnly={isReadOnly}
          selectedAccount={selectedAccount}
        />
      </div>
      <div className="row">
        <VendorTypeField
          label="Type"
          isReadOnly={isReadOnly}
          selectedAccount={selectedAccount}
        />
      </div>
    </VendorDetailAccountInfoContainer>
  );
}

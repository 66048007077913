import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteAccountMutationResponse,
  DeleteAccountPathParams,
} from "../../models/DeleteAccount";

/**
 * @description Delete account
 * @summary Delete account
 * @link /workspaces/:workspace_id/accounts/:id
 */
export async function deleteAccount(
  workspaceId: DeleteAccountPathParams["workspace_id"],
  id: DeleteAccountPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteAccountMutationResponse>["data"]> {
  const res = await client<DeleteAccountMutationResponse>({
    method: "delete",
    url: `/workspaces/${workspaceId}/accounts/${id}`,
    ...options,
  });
  return res.data;
}

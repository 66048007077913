import { LinkButton, Table } from "@themis/ui";
import pluralize from "pluralize";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { useTrainings } from "@/api/queries/trainings";
import Loading from "@/components/Loading";

import { ErrorContainer } from "../../../components/ErrorContainer";
import { trainingTableColumns } from "../config/trainingTable";
import { TRAINING_ROUTES } from "../pages";

function EmptyStateContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-inline-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4">
      {children}
    </div>
  );
}

export function TrainingList() {
  const { url } = useRouteMatch();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const { isPending, data, isError } = useTrainings(Number(workspace_id));

  const trainings = data?.data;
  const moduleDisplayName = "Training";

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isError || !trainings) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id,
          }),
        }}
      >
        {`Could not load ${pluralize(moduleDisplayName.toLowerCase())}.`}
      </ErrorContainer>
    );
  }

  return (
    <>
      <Table
        columns={trainingTableColumns(Number(workspace_id))}
        rows={trainings}
        width="100%"
      />
      {!trainings.length && (
        <EmptyStateContainer>
          <h3 className="tw-text-base tw-font-semibold tw-text-neutral-500">
            {`Add your first ${moduleDisplayName}`}
          </h3>
          <p className="tw-w-[370px] tw-text-center tw-text-sm tw-font-medium tw-text-neutral-300">
            {`To get started, add your first ${moduleDisplayName} by clicking 'Add ${moduleDisplayName}' and filling in the details.`}
          </p>
          <LinkButton
            LeftIcon={PiPlusBold}
            to={generatePath(`${url}${TRAINING_ROUTES.details}`, {
              trainingId: "new",
            })}
          >
            {`Add ${moduleDisplayName}`}
          </LinkButton>
        </EmptyStateContainer>
      )}
    </>
  );
}

import {
  activeVDDUrl,
  inactiveVDDUrl,
  RATemplatesVDDUrl,
  templatesVDDUrl,
} from "./urlPaths";

export const RISK_LEVELS_COLORS: string[] = [
  "#6D8CF9",
  "#FDBD32",
  "#FF9F4D",
  "#E63956",
  "#FF87A7",
];
export const RISK_RATINGS_COLORS: { background: string; color: string }[] = [
  {
    background: "#EB2E4E",
    color: "white",
  },
  {
    background: "#FF879B",
    color: "white",
  },
  {
    background: "#FF9900",
    color: "white",
  },
  {
    background: "#FDB932",
    color: "white",
  },
  {
    background: "#66AA22",
    color: "white",
  },
  {
    background: "#6D8CF9",
    color: "white",
  },
  {
    background: "#6161C3",
    color: "white",
  },
];

export const urlToScreen: { [key: string]: string } = {
  [activeVDDUrl]: "ActiveVendors",
  [templatesVDDUrl]: "Templates",
  [RATemplatesVDDUrl]: "RiskAssessmentTemplates",
  [inactiveVDDUrl]: "InactiveVendors",
};

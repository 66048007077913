import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

import Button from "../form/Button";
import Switch from "../table/shared/Switch";
import CompanySettingsNavigation from "./CompanySettingsNavigation";
import RemoveCompanyButton from "./RemoveCompanyButton";

function CompanyInfo() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { company } = mainStore.companies;
  const { currentLogo } = mainStore.clearbit;
  const companyLogo = company?.logo;

  // State
  const [logo, setLogo] = useState(null);
  const [companyName, setCompanyName] = useState(company?.name);
  const [companyWebsite, setCompanyWebsite] = useState(company?.website);
  // @ts-expect-error TS(2339) FIXME: Property 'mfa_enabled' does not exist on type 'Com... Remove this comment to see the full error message
  const [mfaEnabled, setMfaEnabled] = useState(company?.mfa_enabled);

  // Effects
  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    setLogo(companyLogo);
  }, [companyLogo]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "company-name") {
      setCompanyName(value);
    }
    if (name === "company-website") {
      setCompanyWebsite(value);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleSubmit(event) {
    event.preventDefault();
    const companyID = mainStore.companies.company?.id;

    const params = {
      company: {
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
        logo: logo?.id || logo,
        // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
        suggested_logo: currentLogo?.config?.url,
        name: companyName,
        website: companyWebsite,
        mfa_enabled: mfaEnabled,
      },
    };

    mainStore.companies.update(companyID, params);
  }

  return (
    <div className="settings-wrap company-info-settings-container">
      <CompanySettingsNavigation />

      <div className="company-info-content-wrap">
        <h4>Company Name</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-input">
            <input
              type="text"
              name="company-name"
              placeholder=""
              onChange={handleChange}
              autoComplete="off"
              defaultValue={companyName}
            />
          </div>
          <h4>Company Website</h4>
          <div className="form-input">
            <input
              type="text"
              name="company-website"
              placeholder=""
              onChange={handleChange}
              autoComplete="off"
              defaultValue={companyWebsite}
            />
          </div>
          <div
            className={classNames("form-input mfa-block", {
              active: mfaEnabled,
            })}
          >
            <p>Two-factor authentication app</p>
            <Switch
              data-tooltip-id="tooltip"
              data-tooltip-content="Please contact us for details about multi-factor authorization"
              data-tooltip-place="bottom"
              active
              disabled
              checked={mfaEnabled}
              onChange={() => setMfaEnabled(!mfaEnabled)}
            />
          </div>
          <Button title="Save" />
        </form>
        <RemoveCompanyButton />
      </div>
    </div>
  );
}

export default observer(CompanyInfo);

import "../../stylesheets/components/shared/popup/reload-popup.scss";

import React from "react";

import { Button, IconButton, Typography } from "../Elements";

interface ReloadPopupProps {
  text: string;
  title: string;
  onClose?: () => void;
}

function ReloadPopup({ title, text, onClose }: ReloadPopupProps) {
  return (
    <div className="themis-reload-popup" data-testid="reload-popup">
      <div className="themis-reload-popup_header">
        <Typography
          color="generalMidnightDark"
          weight="bold"
          label={title}
          data-testid="reload-popup_title"
        />
        {onClose && (
          <IconButton
            transparent
            icon="close"
            onClick={onClose}
            data-testid="reload-popup_close-button"
          />
        )}
      </div>
      <Typography
        className="themis-reload-popup_text"
        color="generalDarkGray"
        weight="semiBold"
        size="xs"
        label={text}
        data-testid="reload-popup_text"
      />
      <Button
        label="Reload Page"
        onClick={() => window.location.reload()}
        data-testid="reload-popup_reload-button"
      />
    </div>
  );
}

export default ReloadPopup;

import {
  ConfirmationPopup,
  IconButton,
  LinkIconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiPencilSimpleLineBold, PiTrashSimpleBold } from "react-icons/pi";
import { useParams, useRouteMatch } from "react-router-dom";

import { useDeleteContact } from "@/api/queries/contacts";

export function ContactsActionsCell({
  contactId,
  fullName,
}: {
  contactId: number;
  fullName: string;
}) {
  const { url } = useRouteMatch();

  const toast = useToast();
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const { mutate: deleteContact } = useDeleteContact({
    accountId: Number(accountId),
    onSuccess: () => {
      toast({
        content: "Contact has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove contact!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    setIsConfirmationOpen(false);
    deleteContact(contactId);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  return (
    <>
      <LinkIconButton
        aria-label="Edit Contact"
        // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description
        // NOTE: aria-description is still in W3C Editor's Draft for ARIA 1.3. For the time being,
        // continue to use aria-describedby, which has been supported since ARIA 1.1.
        aria-describedby={`contact-id-${contactId}`}
        to={(location) => ({
          pathname: `${url}/${contactId}`,
          state: { from: location.pathname },
        })}
        Icon={PiPencilSimpleLineBold}
        size="lg"
        color="tertiary"
      />
      <span id={`contact-id-${contactId}`} className="tw-hidden">
        Contact Name {fullName}
      </span>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Contact"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      >
        <IconButton
          aria-label="Delete Contact"
          aria-describedby={`contact-id-${contactId}`}
          Icon={PiTrashSimpleBold}
          size="lg"
          color="tertiary"
          onClick={handleClickDelete}
        />
      </ConfirmationPopup>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Drawer from "@/components/Drawer";
import UserAvatar from "@/components/UserAvatar";
import { useMainStore } from "@/contexts/Store";
import type { User } from "@/stores/types/user-types";

import { Button } from "../../Elements";

interface Props {
  moduleWorkspaceID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => any;
}

function ViewUsers({ moduleWorkspaceID, onClose }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // States
  const [users, setUsers] = useState<User[]>([]);

  // Hooks
  useEffect(() => {
    if (moduleWorkspaceID) {
      getUsers();
    }
  }, [moduleWorkspaceID]);

  // Functions
  async function getUsers() {
    if (!moduleWorkspaceID) {
      return;
    }

    try {
      const res =
        await mainStore.permissions.getUsersAccessibleToModule(
          moduleWorkspaceID,
        );
      setUsers(res);
    } catch {
      setUsers([]);
    }
  }

  return (
    <Drawer title="View Module Users" onClose={onClose}>
      <div className="module-users-panel">
        <div className="module-users-list">
          {users.map((user) => (
            <UserAvatar
              className="module-users-user"
              key={user.id}
              user={user}
              size="sm"
              showName
            />
          ))}
        </div>

        <div className="module-users-description">
          To edit users, please head back to the user listing tab
        </div>

        <div className="module-users-button-wrapper">
          <Link to="/settings/users">
            <Button
              label="Back to Users"
              data-testid="back"
              onClick={onClose}
            />
          </Link>
        </div>
      </div>
    </Drawer>
  );
}

export default ViewUsers;

import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { useHistory, useParams } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import { useLoading } from "../../../hooks/useLoading";
import { Icon } from "../../Elements";
import Loading from "../../Loading";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";

function IMRelatedRisks() {
  const mainStore = useMainStore();
  const history = useHistory();

  const [fields, setFields] = useState([]);
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const { company } = mainStore.companies;

  const { workspaceID } = mainStore.context;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === Number(recordVersionID),
  );
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isLocked = status === "closed";
  const filteredRows = rows.filter((row) => {
    return fields.find((field) => {
      const value = mainStore.avroSchemas.valueForField(
        // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
        field.name,
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
        row.data,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
        fields,
      );
      if (!value) {
        return false;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
      if (field.data_type === "com.askthemis.types.v1.option") {
        const firstOption = mainStore.avroSchemas.firstValueForField(
          // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
          field.name,
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
          row.data,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
          fields,
        );
        return firstOption
          ? Boolean(
              firstOption
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase()),
            )
          : false;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
      if (field.data_type === "com.askthemis.types.v1.tag_department") {
        const departments_ids = mainStore.avroSchemas.valueForField(
          // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
          field.name,
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
          row.data,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
          fields,
        );
        return Boolean(
          departments_ids.find(
            // @ts-expect-error TS(7006) FIXME: Parameter 't' implicitly has an 'any' type.
            (t) =>
              mainStore.departments.departments
                .find((o) => o.id === t)
                ?.title?.toLowerCase()
                ?.includes(search.toLowerCase()),
          ),
        );
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
      if (field.data_type === "com.askthemis.types.v1.tag_user") {
        const users_ids = mainStore.avroSchemas.valueForField(
          // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
          field.name,
          // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
          row.data,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'never[]' is not assignable to pa... Remove this comment to see the full error message
          fields,
        );
        return Boolean(
          users_ids.find(
            // @ts-expect-error TS(7006) FIXME: Parameter 't' implicitly has an 'any' type.
            (t) =>
              mainStore.users.allUsers
                .find((o) => o.id === t)
                ?.full_name?.toLowerCase()
                ?.includes(search.toLowerCase()),
          ),
        );
      }

      return value?.toString()?.toLowerCase()?.includes(search.toLowerCase());
    });
  });

  const loading = useLoading(fields);

  useEffect(() => {
    (async () => {
      if (!workspaceID || !company?.id) {
        return;
      }

      if (!recordVersion) {
        await mainStore.issueManagement.index({ workspaceID });
      }
      const data =
        await mainStore.issueManagement.fetchRelatedRisksData(recordVersionID);

      mainStore.riskTypes.index(company.id);
      mainStore.mainCategories.index(company.id);

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const filteredFields = data.rr_fields.filter(
        (item) => item.position > 0 && !item.is_hidden,
      );

      // @ts-expect-error TS(2345) FIXME: Argument of type 'Field[]' is not assignable to pa... Remove this comment to see the full error message
      setFields(filteredFields);
      setRows(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        data.rr_records.filter((item) =>
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          data.selected_records_ids.includes(item.record.id),
        ),
      );

      mainStore.issueManagement.setRelatedRisksData(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        data.selected_records_ids,
        false,
      );
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      mainStore.fieldOptions.setAll(data.rr_field_options);
    })();
  }, [company, workspaceID]);

  useEffect(() => {
    if (!rows.length) {
      return;
    }

    setRows(
      rows.filter((item) =>
        mainStore.issueManagement.relatedRisksData.selectedIDs.includes(
          // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          item.record.id,
        ),
      ),
    );
  }, [mainStore.issueManagement.relatedRisksData.selectedIDs]);

  const renderSearch = (
    <div className="rr-operational-controls-search-container">
      <input
        type="text"
        placeholder="Search Risk"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Icon name="search" color="generalDark" size="de" />
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderField = (field) => (
    <ModuleTableColumn key={field.name} field={field} />
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'rv' implicitly has an 'any' type.
  const renderRow = (rv) => (
    <div
      className="rr-operational-controls-row-container tw-mr-[70px]"
      key={rv.id}
    >
      <ModuleRecordVersion
        fields={fields}
        isLockedRow
        recordVersion={rv}
        tableID={rv.table_id}
        tableName={rv.table_name}
        currentTableName="RelatedRisks"
        moduleIdentifier="risk_register"
      />
    </div>
  );

  const renderAddNewButton = (
    <div
      className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
      onClick={() =>
        history.push(
          `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/related-risks-add-new`,
        )
      }
      data-testid="add-new-record-button"
    >
      <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
      <div className="tw-neutral-300 tw-text-sm">Add Related Risk...</div>
    </div>
  );

  const TABS = [
    {
      name: "Record View",
      key: "",
      value: `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}`,
      dataTestId: "im-controls-tab-item",
    },
    {
      name: "Action Plans",
      key: "action-plans",
      value: `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/action-plans`,
      dataTestId: "im-controls-tab-item",
    },
    {
      name: "Module Controls",
      key: "controls",
      value: `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/controls`,
      dataTestId: "im-controls-tab-item",
    },
    {
      name: "Operational Controls",
      key: "operational-controls",
      value: `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/operational-controls`,
      dataTestId: "im-controls-tab-item",
    },
    {
      name: "Related Risks",
      key: "related-risks",
      value: `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/related-risks`,
      dataTestId: "im-controls-tab-item",
    },
    {
      name: "Activity",
      key: "audit-trail",
      value: `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/audit_trail`,
      dataTestId: "im-controls-tab-item",
    },
  ];

  return (
    <div className="settings-wrap company-users-settings-container detail-view-documents-wrap">
      <div className="tw-flex tw-h-12 tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5 tw-py-0">
        <HeaderTabs
          tabs={TABS}
          selectedTab={getSelectedTab(TABS)?.value || ""}
          isLink
        />
      </div>
      {!loading && hasModuleWriteAccess && (
        <AddRecordHeader
          recordName="Action Plan"
          addRecord={() =>
            history.push(
              `/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/related-risks-add-new`,
            )
          }
        />
      )}
      {loading ? (
        <Loading loadingLayout="table" showTableHeader={false} />
      ) : (
        <div
          className={classNames(
            "rr-operational-controls-wrapper rr-library-wrapper",
            { locked: isLocked },
          )}
        >
          <div
            className="rr-library-container"
            data-testid="im-related-risks-container"
          >
            {renderSearch}
            <div
              className="rr-library-table-container"
              data-testid="im-related-risks-table"
            >
              <div className="rr-library-table-columns">
                {fields.map(renderField)}
                <div className="rr-library-columns-end-filler" />
              </div>
              <div className="rr-library-table-rows">
                {filteredRows.map(renderRow)}
              </div>
            </div>
            {hasModuleWriteAccess && renderAddNewButton}
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(IMRelatedRisks);

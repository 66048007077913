import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { Icon } from "../../Elements";

interface Props {
  label: string;
  isNew?: boolean;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  accounts?: {
    id: number;
    name: string;
  }[];
  disabled?: boolean;
  placeholder?: string;
  selectedAccountId: number | null;
  setSelectedAccountId: React.Dispatch<React.SetStateAction<number | null>>;
}

function VendorAccountSelect({
  label,
  isNew,
  isDisabled,
  isReadOnly,
  placeholder,
  accounts,
  disabled,
  selectedAccountId,
  setSelectedAccountId,
}: Props) {
  // State
  const [showPopup, setShowPopup] = useState(false);
  const [findOption, setFindOption] = useState("");

  // Variables
  const filteredAccounts = accounts?.filter((account) =>
    account.name.toLowerCase().includes(findOption.toLowerCase()),
  );
  const triggerName = accounts?.find(
    (account) => selectedAccountId === account.id,
  )?.name;

  const addItem = (id: number) => {
    setSelectedAccountId(id);
    handlePopUpClose();
  };

  const handlePopUpOpen = () => {
    setShowPopup(true);
  };

  const handlePopUpClose = () => {
    setShowPopup(false);
  };

  const renderTrigger = (
    <li
      className={classNames("cell column-options-cell", {
        active: showPopup,
      })}
      data-testid="vdd-account-select-trigger"
    >
      <div className="options column-options-container">
        <div className="cell-content">
          {!selectedAccountId ? (
            <p className="options-placeholder nodata">{placeholder}</p>
          ) : (
            <div className="options column-options-item">
              <span className="tw-font-semibold">{triggerName}</span>
            </div>
          )}
        </div>
        {!disabled && <Icon name="chevronDown" />}
      </div>
    </li>
  );

  const renderContent = (
    <div className="select">
      <div className="select-add-new-form">
        <input
          type="text"
          placeholder="Search Account"
          value={findOption}
          onChange={(event) => setFindOption(event.target.value)}
        />
        <Icon name="search" />
      </div>
      <div className="select-dropdown">
        <ul className="select-options">
          {filteredAccounts?.length === 0 && (
            <li>
              <h4 className="select-no-options-message">No results found</h4>
            </li>
          )}

          {filteredAccounts?.map((account) => {
            const isSelected = selectedAccountId === account.id;

            return (
              <li
                key={account.id}
                className={classNames("select-option", {
                  "select-option-active": isSelected,
                })}
                onClick={() => addItem(account.id)}
              >
                <div className="select-option-label-wrapper">
                  <span className="select-option-label">{account.name}</span>
                </div>
                {isSelected && (
                  <Icon name="check" color="brandingHighlightTurquoise" />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  return (
    <div>
      <label>{label}</label>
      <div
        className={classNames("custom-column-option-container", {
          "vdd-filled-input": !!selectedAccountId && isNew,
          "vendor-view-disabled": isDisabled || !isNew,
          "table-cell--disabled": isReadOnly,
        })}
      >
        <Popup
          position="bottom left"
          trigger={renderTrigger}
          open={showPopup}
          keepTooltipInside
          disabled={disabled}
          onOpen={handlePopUpOpen}
          onClose={handlePopUpClose}
        >
          {renderContent}
        </Popup>
      </div>
    </div>
  );
}

export default observer(VendorAccountSelect);

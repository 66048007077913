import "./content-cell.scss";

import React from "react";

import { Icon, Typography } from "@/components/Elements";

import TableCell from "../TableCell";

interface ContentCellProps {
  content: React.ReactNode;
  isFirstDataCell?: boolean;
  isLastDataCell?: boolean;
  isLinkCell?: boolean;
  minWidth?: number;
  showRedFlag?: boolean;
  redFlagTooltip?: string;
  onClick?: () => void;
  hasErrors?: boolean;
  className?: string;
  textClassName?: string;
}

const ContentCell = ({
  content,
  isFirstDataCell,
  isLastDataCell,
  isLinkCell,
  minWidth,
  showRedFlag,
  redFlagTooltip,
  onClick,
  textClassName,
  ...rest
}: ContentCellProps) => {
  return (
    <TableCell
      minWidth={minWidth}
      isFirstDataCell={isFirstDataCell}
      isLastDataCell={isLastDataCell}
      isLinkCell={isLinkCell}
      onClick={onClick}
      {...rest}
    >
      {showRedFlag && (
        <Icon
          size="lg"
          name="flagSolid"
          color="generalError"
          className="content-cell-red-flag"
          data-tooltip-id="tooltip"
          data-tooltip-place="top"
          data-tooltip-content={redFlagTooltip}
        />
      )}
      <Typography
        label={content}
        weight="semiBold"
        size="sm"
        className={`generic-content-cell ${textClassName}`}
      />
    </TableCell>
  );
};

export default ContentCell;

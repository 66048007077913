import "./risk-methodology-builder.scss";

import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "../../../../../contexts/Store";
import type { RiskRating as RiskRatingInputProps } from "../../../types/risk-methodology";
import RiskRatingRow from "./RiskRatingRow";
import TableInputGroup from "./TableInputGroup";

interface RiskRatingProps {
  groupTitle: string;
  addNewText: string;
  description?: string;
  riskRatings: Array<RiskRatingInputProps>;
  updateRiskRatings: (riskRatings: Array<RiskRatingInputProps>) => void;
  dataTestID?: string;
  globalHigherRange: number;
  setSaveable: (canSave: boolean) => void;
}

function RiskRating({
  description,
  groupTitle,
  addNewText,
  riskRatings,
  updateRiskRatings,
  dataTestID,
  globalHigherRange,
  setSaveable,
}: RiskRatingProps) {
  const mainStore = useMainStore();

  const removeRating = (index: number) => {
    const riskRating = riskRatings.find((subRiskRating, i) => i === index);
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (riskRating.id) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      riskRating._destroy = true;
      const newList = [...riskRatings];
      updateRiskRatings(newList);
    } else {
      const newList = riskRatings.filter((subRiskRating, i) => i !== index);
      updateRiskRatings(newList);
    }
  };

  const updateUpperValue = (index: number, value: number) => {
    const newRatings = riskRatings.map((riskRating, i) => {
      if (i === index) {
        return { ...riskRating, higher_range: value };
      }
      return riskRating;
    });
    updateRiskRatings(newRatings);
  };

  const updateText = (index: number, value: string) => {
    riskRatings[index].text = value;
    updateRiskRatings([...riskRatings]);
  };

  const updateColor = (index: number, value: string) => {
    riskRatings[index].color = value;
    updateRiskRatings([...riskRatings]);
  };

  const checkError = (text: string, index: number): boolean => {
    if (text.length === 0) {
      setSaveable(false);
      mainStore.toast.setErrorText("The text cannot be blank");
      return true;
    }

    for (let subIndex = 0; subIndex < riskRatings.length; ++subIndex) {
      if (subIndex !== index && riskRatings[subIndex].text === text) {
        mainStore.toast.setErrorText("The text is taken");
        setSaveable(false);
        return true;
      }
    }
    setSaveable(true);
    return false;
  };

  return (
    <TableInputGroup
      description={description}
      title={groupTitle}
      addNewInputText={addNewText}
      dataTestID={dataTestID}
      addNewInput={() =>
        updateRiskRatings([
          ...riskRatings,
          { text: "", lower_range: 1, color: "#66AA22", higher_range: 2 },
        ])
      }
    >
      {riskRatings.map(
        (riskRating, index) =>
          !riskRating._destroy && (
            <div key={riskRating.id} data-testid={`${dataTestID}-row-${index}`}>
              <RiskRatingRow
                text={riskRating.text}
                lower_range={riskRating.lower_range}
                higher_range={riskRating.higher_range}
                index={index}
                color={riskRating.color}
                disabled={index === riskRatings.length - 1}
                globalHigherRange={globalHigherRange}
                updateText={updateText}
                updateUpperValue={updateUpperValue}
                dataTestID={dataTestID}
                updateColor={updateColor}
                removeRating={
                  riskRatings.filter((rr) => !rr._destroy).length > 1
                    ? removeRating
                    : undefined
                }
                hasError={checkError(riskRating.text, index)}
              />
            </div>
          ),
      )}
    </TableInputGroup>
  );
}

export default observer(RiskRating);

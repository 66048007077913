import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const ProceduresReportContent = () => (
  <ReportsPage
    identifier="procedures"
    defaultDateColumn="effective_date"
    defaultGroupColumn="status"
  />
);

export default observer(ProceduresReportContent);

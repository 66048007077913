import React, { useState } from "react";

import { Button } from "@/components/Elements";

interface CustomTerminationDateContentProps {
  handlePopupClose?: () => void;
  onCustomValueAdded: (val: string) => void;
  initialValue: string;
}

function CustomTerminationDateContent({
  handlePopupClose,
  onCustomValueAdded,
  initialValue,
}: CustomTerminationDateContentProps) {
  const [customValue, setCustomValue] = useState(initialValue);
  const handleConfirm = () => {
    onCustomValueAdded(customValue);
    handlePopupClose?.();
  };

  const handleChange = (inputValue: string) => {
    const sanitizedValue = inputValue.replace(/[^\d]/g, "");
    setCustomValue(sanitizedValue);
  };

  return (
    <div className="dropdown-popup dropdown-popup__custom-value">
      <div className="dropdown-custom-value-title">Custom Value</div>
      <div className="dropdown-custom-value-desc">
        Please enter an integer value
      </div>
      <input
        data-testid="dropdown-search"
        type="text"
        placeholder="- Type Value -"
        value={customValue}
        onChange={(val) => handleChange(val.target.value)}
        className="dropdown-custom-value-input"
      />
      <Button
        label="Confirm"
        disabled={customValue?.length === 0 || parseInt(customValue) <= 0}
        onClick={handleConfirm}
      />
    </div>
  );
}

export default CustomTerminationDateContent;

import "react-day-picker/dist/style.css";
import "stylesheets/dashboard/reports/datepicker.scss";

import { format, isAfter, isBefore, isValid, parse } from "date-fns";
import React, { useState } from "react";
import { DayPicker } from "react-day-picker";

import calendarIcon from "../../images/table-image/icon/calendar-black-icon.svg";

export function DateFilter() {
  const [displayRange, setDisplayRange] = useState("Past 12 Months");
  const [fromValue, setFromValue] = useState();
  const [toValue, setToValue] = useState();
  const [selectedRange, setSelectedRange] = useState({
    from: fromValue,
    to: toValue,
  });
  const validRange =
    // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    isValid(parse(fromValue, "y-MM-dd", new Date())) &&
    // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    isValid(parse(toValue, "y-MM-dd", new Date()));

  // Functions
  function handleDisplayRange() {
    if (validRange) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      const from = parse(fromValue, "y-MM-dd", new Date());
      // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
      const to = parse(toValue, "y-MM-dd", new Date());
      setDisplayRange(`${format(from, "MM/dd/y")} to ${format(to, "MM/dd/y")}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleFromChange(e) {
    setFromValue(e.target.value);
    const date = parse(e.target.value, "y-MM-dd", new Date());
    if (!isValid(date)) {
      return setSelectedRange({ from: undefined, to: undefined });
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      // @ts-expect-error TS(2322) FIXME: Type 'Date' is not assignable to type 'undefined'.
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'Date' is not assignable to type 'undefined'.
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleToChange(e) {
    const date = parse(e.target.value, "y-MM-dd", new Date());

    if (!isValid(date)) {
      return setSelectedRange({ from: selectedRange?.from, to: undefined });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      // @ts-expect-error TS(2322) FIXME: Type 'Date' is not assignable to type 'undefined'.
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      // @ts-expect-error TS(2322) FIXME: Type 'Date' is not assignable to type 'undefined'.
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'range' implicitly has an 'any' type.
  function handleRangeSelect(range) {
    setSelectedRange(range);
    if (range?.from) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setFromValue(format(range.from, "y-MM-dd"));
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
      setFromValue("");
    }
    if (range?.to) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setToValue(format(range.to, "y-MM-dd"));
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '""' is not assignable to paramet... Remove this comment to see the full error message
      setToValue("");
    }
  }

  // Rendering
  const dayPickerTrigger = (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-content="Select the range of dates to include in the report."
      data-tooltip-place="bottom"
      className="report-header-item"
    >
      <img className="report-header-image" src={calendarIcon} />
      {displayRange}
    </div>
  );

  const renderDayPicker = (
    <DayPicker
      className="legacy-rdp"
      mode="range"
      selected={selectedRange}
      onSelect={handleRangeSelect}
      footer={
        <form className="ma2">
          <input
            size={10}
            placeholder="From Date"
            value={fromValue}
            onChange={handleFromChange}
            className="input-reset pa2 ma bg-white black ba"
          />
          {" – "}
          <input
            size={10}
            placeholder="To Date"
            value={toValue}
            onChange={handleToChange}
            className="input-reset pa2 bg-white black ba"
          />
        </form>
      }
    />
  );

  return {
    handleDisplayRange,
    validRange,
    fromValue,
    toValue,
    dayPickerTrigger,
    renderDayPicker,
  };
}

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import type { TableName } from "@/api";
import { useMainStore } from "@/contexts/Store";

import ApproveButton from "../shared/ApproveButton";
import PublishFlow from "../shared/PublishFlow";
import Unlock from "../shared/Unlock";
import PolicyContextMenu from "./PolicyContextMenu";

interface Props {
  isPolicy: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleStore: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  tableID: number;
  tableName: TableName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approvedByUserIds?: any[];
  archived?: boolean;
  moduleWorkspaceID?: number;
  showOnlyIcon?: boolean;
  withoutBackground?: boolean;
}

function PolicyProcedureContextMenu({
  archived,
  approvedByUserIds,
  moduleWorkspaceID,
  recordVersion,
  tableID,
  tableName,
  moduleStore,
  isPolicy,
  showOnlyIcon,
  withoutBackground,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks

  // Variables
  const history = useHistory();
  const location = useLocation();
  const approversIds = mainStore.avroSchemas.valueForField(
    "approvers",
    recordVersion?.data,
  );
  const currentUserID = mainStore.users.user.id;
  const { workspaceID } = mainStore.context;
  const canApprove = currentUserID && approversIds?.includes(currentUserID);
  const hasApproved =
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    currentUserID && approvedByUserIds.includes(currentUserID);

  const approvalStatus = recordVersion.approval_status;
  const approvalStepId = approvalStatus?.approval_step_id;
  const approvalSteps = recordVersion.approval_config?.approval_steps.find(
    // @ts-expect-error TS(7006) FIXME: Parameter 'step' implicitly has an 'any' type.
    (step) => step.id === approvalStepId,
  );
  const hasStepApprovalStatus = approvalSteps && !approvalStatus.completed;
  const hasStepApproval =
    hasStepApprovalStatus &&
    currentUserID &&
    approvalSteps.users_ids.includes(currentUserID);

  const isPolicyDetailPage = useMemo(() => {
    // /modules/policy/1234 :  ✔
    // /modules/policy/drafts :  ✖
    // /modules/policy/1234/drafts :  ✖
    const policyDetailPageRe = /^\/workspaces\/\d+\/modules\/policy\/\d+$/;
    const matches = location.pathname.match(policyDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  const isProceduresDetailPage = useMemo(() => {
    // /modules/procedures/1234 :  ✔
    // /modules/procedures/drafts :  ✖
    // /modules/procedures/1234/drafts :  ✖
    const proceduresDetailPageRe =
      /^\/workspaces\/\d+\/modules\/procedures\/\d+$/;
    const matches = location.pathname.match(proceduresDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  async function unlockRecordVersion() {
    mainStore.toast.setInfoText(
      "Unlocking record... this may take a few seconds",
    );
    let result = null;
    if (isPolicy) {
      result = await mainStore.policies.unlock(recordVersion.id);
      if (isPolicyDetailPage) {
        history.push(
          `/workspaces/${workspaceID}/modules/policy/${result.record_version.id}`,
        );
      }
    } else {
      result = await mainStore.procedures.unlock(recordVersion.id);
      if (isProceduresDetailPage) {
        history.push(
          `/workspaces/${workspaceID}/modules/procedures/${result.record_version.id}`,
        );
      }
    }

    if (result) {
      mainStore.toast.setText("Record unlocked");
    }
  }

  function onApprove() {
    if (recordVersion.id) {
      mainStore.reviews.createApproval(recordVersion.id);
    }
  }

  const canRenderUnlock = (name: string, pathname: string) =>
    ["Finalized", "ProceduresFinalized"].includes(name) &&
    (pathname.endsWith("/modules/policy/drafts") ||
      pathname.endsWith("/modules/procedures/drafts") ||
      isPolicyDetailPage ||
      isProceduresDetailPage);

  if (archived) {
    return null;
  }

  return (
    <div
      className={classNames("list-points", {
        "without-background": withoutBackground,
      })}
    >
      {(!canApprove || hasApproved) &&
        (!hasStepApproval || hasApproved) &&
        ["Drafts", "ProceduresDrafts"].includes(tableName) && (
          <PublishFlow
            recordVersionID={recordVersion.id}
            tableName={tableName}
            moduleStore={moduleStore}
          />
        )}

      {canRenderUnlock(tableName, location.pathname) && (
        <Unlock onYes={unlockRecordVersion} tableName={tableName} />
      )}

      {((canApprove && !hasApproved) || (hasStepApproval && !hasApproved)) &&
        !["Finalized", "ProceduresFinalized"].includes(tableName) && (
          <ApproveButton onClick={onApprove} />
        )}

      <PolicyContextMenu
        moduleWorkspaceID={moduleWorkspaceID}
        recordID={recordVersion.record_id}
        recordVersion={recordVersion}
        tableID={tableID}
        tableName={tableName}
        isPolicy={isPolicy}
        // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
        showOnlyIcon={showOnlyIcon}
      />
    </div>
  );
}

PolicyProcedureContextMenu.defaultProps = {
  withoutBackground: false,
  showOnlyIcon: true,
};

export default observer(PolicyProcedureContextMenu);

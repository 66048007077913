import { MiniTag } from "@themis/ui";
import React from "react";

export function ContractRenewalTag({ renewal }: { renewal: boolean }) {
  return (
    <MiniTag color={renewal ? "secondary" : "primary"}>
      {renewal ? "Automatic" : "Manual"}
    </MiniTag>
  );
}

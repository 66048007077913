import { action, makeObservable, observable } from "mobx";

export default class DynamicTableStore {
  mainStore;

  selectedRecordVersionIDs: number[] = [];
  selectedUserIDs = [];
  selectedFolderID = null;
  currentFolderID = null;
  selectedMenuAction = null;
  activeSlideMenuID = null;
  menuActions = {
    // selectAll: 'Select All Records',
    selectIndividual: "Select Individual Records",
    manageColumns: "Manage Columns",
    manageSections: "Manage Sections",
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  constructor(mainStore) {
    makeObservable(this, {
      menuActions: observable,
      setSelectedMenuAction: action,
      selectedRecordVersionIDs: observable,
      currentFolderID: observable,
      selectedFolderID: observable,
      activeSlideMenuID: observable,
      setSelectedFolderID: action,
      setCurrentFolderID: action,
      addSelectedRecordVersionID: action,
      selectAllRecordVersions: action,
      removeSelectedRecordVersionID: action,
      clearSelectedRecordVersionIDs: action,
      selectedUserIDs: observable,
      addSelectedUserID: action,
      removeSelectedUserID: action,
      clearSelectedUserIDs: action,
      setActiveSlideMenuID: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSelectedMenuAction(value) {
    this.selectedMenuAction = value;
  }

  addSelectedRecordVersionID(value: number) {
    this.selectedRecordVersionIDs = [...this.selectedRecordVersionIDs, value];
  }

  selectAllRecordVersions(allIDs: number[]) {
    this.selectedRecordVersionIDs = [...allIDs];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSelectedFolderID(value) {
    this.selectedFolderID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentFolderID(value) {
    this.currentFolderID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  removeSelectedRecordVersionID(value) {
    this.selectedRecordVersionIDs = this.selectedRecordVersionIDs.filter(
      (id) => id !== value,
    );
  }

  clearSelectedRecordVersionIDs() {
    // @ts-expect-error TS(2339) FIXME: Property 'clear' does not exist on type 'never[]'.
    this.selectedRecordVersionIDs.clear();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  addSelectedUserID(value) {
    // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
    this.selectedUserIDs = [...this.selectedUserIDs, value];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  removeSelectedUserID(value) {
    this.selectedUserIDs = this.selectedUserIDs.filter((id) => id !== value);
  }

  setActiveSlideMenuID(value = null) {
    this.activeSlideMenuID = value;
  }

  clearSelectedUserIDs() {
    // @ts-expect-error TS(2339) FIXME: Property 'clear' does not exist on type 'never[]'.
    this.selectedUserIDs.clear();
  }

  // Store Helpers

  cleanup() {
    this.clearSelectedRecordVersionIDs();
    this.clearSelectedUserIDs();
    this.setActiveSlideMenuID();
  }
}

import React from "react";
import { NavLink } from "react-router-dom";

import lightbulbIcon from "../../../images/table-image/icon/lightbulb-icon.svg";
import plusIcon from "../../../images/table-image/icon/plus.svg";
import settingsIcon from "../../../images/table-image/icon/settings-icon.svg";

function CreateCTA() {
  return (
    <div className="create-cta-wrap" data-testid="create-cta">
      <div className="create-cta-block">
        <img
          className="create-cta-image"
          src={lightbulbIcon}
          alt="file-add-icon"
        />
        <h3>
          Setup company information for
          <br /> your policies
        </h3>
        <NavLink to="/tags">
          <img src={settingsIcon} alt="settings-icon" />
          Set Departments
        </NavLink>
        <NavLink to="/settings">
          <img src={plusIcon} alt="plus-icon" />
          Invite Team Members
        </NavLink>
      </div>
    </div>
  );
}

export default CreateCTA;

import "./styles.scss";

import classNames from "classnames";
import React from "react";
import type { FieldValues, Path, UseControllerProps } from "react-hook-form";
import { useController, useWatch } from "react-hook-form";
import Popup from "reactjs-popup";

import { Flex, Toggle, Typography } from "@/components/Elements";
import {
  daysOfTheWeekOptions,
  numberOfDaysOptions,
  numberOfDaysOptionsForUnlock,
  numberOfMonthsOptions,
  numberOfWeeksOptions,
} from "@/features/notifications/pages/single/date/constants";
import type { TimePeriod } from "@/features/notifications/pages/single/date/types";
import checkIcon from "@/images/table-image/icon/check-icon.svg";
import checkBlueIcon from "@/images/table-image/icon/options-check-blue-light.svg";

export function TimePeriodSelectField<T extends FieldValues>({
  control,
  name,
  frequencyFieldName,
  isUnlockDaily,
}: UseControllerProps<T> & {
  frequencyFieldName: Path<T>;
  isUnlockDaily?: boolean;
}) {
  const frequency = useWatch({ control, name: frequencyFieldName });
  const isDaily = frequency === "days";
  const isWeekly = frequency === "weeks";
  const isMonthly = frequency === "months";
  const isOneTime = frequency === "one_time";

  const getIsEmptyValue = (timePeriodValues: TimePeriod) => {
    if (!frequency) {
      return true;
    }

    if (isOneTime) {
      return !timePeriodValues.numberOfOneTimeDays.length;
    }

    if (isDaily) {
      return !timePeriodValues.numberOfDailyDays;
    }

    if (isWeekly) {
      return !timePeriodValues.numberOfWeeks;
    }

    if (isMonthly) {
      return !timePeriodValues.numberOfMonths;
    }
  };

  const { field, fieldState } = useController<T>({
    name,
    control,
    rules: { validate: (values) => !getIsEmptyValue(values) },
  });

  const fieldValue = field.value as TimePeriod;

  const handleUpdateDefaultRecurringWeekdays = () => {
    if (
      isWeekly &&
      fieldValue.numberOfWeeks &&
      !fieldValue.recurringWeekdays.length
    ) {
      field.onChange({
        ...fieldValue,
        recurringWeekdays: daysOfTheWeekOptions.map((day) => day.value),
      });
    }
  };

  const handleChange = <K extends keyof TimePeriod>(
    key: K,
    value: TimePeriod[K],
  ) => {
    field.onChange({ ...fieldValue, [key]: value });
  };

  // Label will not update until `Save Updates` button is clicked.
  const { popupSelectTitle, options, labelText } = (() => {
    if (isOneTime) {
      const oneTimeDaysFieldValue = fieldValue.numberOfOneTimeDays;
      const numberOfDaysText = oneTimeDaysFieldValue.join(",");
      const plural = oneTimeDaysFieldValue.some((day) => day > 1);

      return {
        popupSelectTitle: "Number of Days",
        options: isUnlockDaily
          ? numberOfDaysOptionsForUnlock
          : numberOfDaysOptions,
        labelText: oneTimeDaysFieldValue.length
          ? `${numberOfDaysText} day${plural ? "s" : ""}`
          : "",
      };
    }

    if (isDaily) {
      const dailyDaysFieldValue = fieldValue.numberOfDailyDays;
      const numberOfDaysText = dailyDaysFieldValue;
      const plural = Number(dailyDaysFieldValue) > 1;

      return {
        popupSelectTitle: "Number of Days",
        options: numberOfDaysOptions,
        labelText:
          dailyDaysFieldValue && `${numberOfDaysText} day${plural ? "s" : ""}`,
      };
    }

    if (isWeekly) {
      const weeksFieldValue = fieldValue.numberOfWeeks;

      const label =
        weeksFieldValue &&
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        numberOfWeeksOptions.find(
          (weekOption) => weekOption.value === weeksFieldValue,
        ).label;

      return {
        popupSelectTitle: "Number of Weeks",
        options: numberOfWeeksOptions,
        labelText: label,
      };
    }

    if (isMonthly) {
      const monthsFieldValue = field.value.numberOfMonths;

      const label =
        monthsFieldValue &&
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        numberOfMonthsOptions.find(
          (monthOption) => monthOption.value === monthsFieldValue,
        ).label;

      return {
        popupSelectTitle: "Number of Months",
        options: numberOfMonthsOptions,
        labelText: label,
      };
    }

    return {
      popupSelectTitle: undefined,
      options: undefined,
      labelText: undefined,
    };
  })();

  const getNewValue = (updateValue: number, remove?: boolean) => {
    if (isDaily || isWeekly || isMonthly || (isOneTime && isUnlockDaily)) {
      return isOneTime && isUnlockDaily ? [updateValue] : updateValue;
    }

    if (isOneTime && remove) {
      return fieldValue.numberOfOneTimeDays.filter(
        (currentValue) => currentValue !== updateValue,
      );
    }

    if (isOneTime && !remove) {
      return [...fieldValue.numberOfOneTimeDays, updateValue];
    }
  };

  const getIsSelected = (optionValue: number) => {
    if (isOneTime) {
      return fieldValue.numberOfOneTimeDays.includes(optionValue);
    }

    if (isDaily) {
      return fieldValue.numberOfDailyDays === optionValue;
    }

    if (isWeekly) {
      return fieldValue.numberOfWeeks === optionValue;
    }

    if (isMonthly) {
      return fieldValue.numberOfMonths === optionValue;
    }
  };

  const showPlaceholder = getIsEmptyValue(fieldValue);

  return (
    <Popup
      keepTooltipInside
      disabled={!frequency}
      onClose={handleUpdateDefaultRecurringWeekdays}
      trigger={
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content="Time Period is dependent on Frequency. Please select a frequency (daily/weekly/one time) before editing this field."
          data-tooltip-hidden={!!frequency}
          data-tooltip-place="bottom"
          className={classNames("notification-select", {
            ["notification-select--error"]: fieldState.error,
            ["notification-select--disabled"]: !frequency,
          })}
        >
          <Typography
            className={classNames({
              "notification-select__placeholder": showPlaceholder,
              "notification-select__placeholder--error": fieldState.error,
            })}
            label={showPlaceholder ? "- Time Period -" : labelText}
            size="md"
            color={showPlaceholder ? "extrasBlueGrayDarker" : "accentsSkyBlue"}
          />
        </div>
      }
    >
      <div className="select">
        <div
          className={classNames("select-dropdown", "time-period-select__popup")}
        >
          <div
            className={classNames({
              "time-period-select__options-container--no-border": isMonthly,
              "time-period-select__options-container": !isUnlockDaily,
            })}
          >
            {!isUnlockDaily && (
              <Typography
                size="sm"
                weight="semiBold"
                color="extrasSlateBlue"
                className="time-period-select__popup-title"
                label={popupSelectTitle}
              />
            )}
            {options?.map((option) => {
              const isSelected = getIsSelected(option.value);

              // @ts-expect-error TS(2322) FIXME: Type '"numberOfOneTimeDays" | "numberOfDailyDays" ... Remove this comment to see the full error message
              const changeKey: keyof TimePeriod = (() => {
                if (isOneTime) {
                  return "numberOfOneTimeDays";
                }

                if (isDaily) {
                  return "numberOfDailyDays";
                }

                if (isWeekly) {
                  return "numberOfWeeks";
                }

                if (isMonthly) {
                  return "numberOfMonths";
                }
              })();

              return (
                <li
                  key={option.value}
                  className={classNames("select-option", {
                    "select-option-active": isSelected,
                  })}
                  onClick={() => {
                    handleChange(
                      changeKey,
                      getNewValue(option.value, isSelected),
                    );
                  }}
                >
                  <div className="select-option-label-wrapper">
                    {/* Render checkbox for One Time Frequency as the user should
                    be able to select multiple days */}
                    {isOneTime && !isUnlockDaily && (
                      <span
                        className={classNames("select-option-checkbox", {
                          checked: isSelected,
                        })}
                      >
                        {isSelected && <img src={checkIcon} alt="check-icon" />}
                      </span>
                    )}
                    <Typography
                      size="sm"
                      label={option.label}
                      weight="semiBold"
                    />
                  </div>
                  {isSelected && (
                    <span className="select-option-check-icon">
                      <img src={checkBlueIcon} alt="check-icon" />
                    </span>
                  )}
                </li>
              );
            })}
          </div>
          {/* Only render Exclude Weekends Toggle for Daily frequency */}
          {isDaily && (
            <Flex
              justifySpaceBetween
              alignCenter
              className="time-period-select__exclude-weekends-container"
            >
              <Flex column>
                <Typography
                  label="Exclude Weekends"
                  color="generalMidnightDark"
                  size="sm"
                />
                <Typography
                  label="(SAT & SUN)"
                  color="generalMidnightDark"
                  size="sm"
                />
              </Flex>
              <Toggle
                size="xs"
                active={fieldValue.excludeWeekends}
                onChange={(newValue) => {
                  handleChange("excludeWeekends", newValue);
                }}
              />
            </Flex>
          )}
          {/* Only render Recurring Weekdays Select for Weekly Frequency */}
          {isWeekly && (
            <Flex
              column
              rowGap={8}
              className={classNames(
                "time-period-select__exclude-weekends-container",
              )}
            >
              <Typography
                label="Recurring Weekdays"
                size="sm"
                weight="semiBold"
                color="extrasSlateBlue"
              />
              <Flex columnGap={8}>
                {daysOfTheWeekOptions.map((day) => {
                  const isSelected = fieldValue.recurringWeekdays.includes(
                    day.value,
                  );

                  return (
                    <Flex
                      justifyCenter
                      alignCenter
                      key={day.value}
                      className={classNames("time-period-select__week-day", {
                        ["time-period-select__week-day--selected"]: isSelected,
                      })}
                      onClick={() => {
                        handleChange(
                          "recurringWeekdays",
                          isSelected
                            ? fieldValue.recurringWeekdays.filter(
                                (weekday) => weekday !== day.value,
                              )
                            : [...fieldValue.recurringWeekdays, day.value],
                        );
                      }}
                    >
                      {day.label}
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
          )}
        </div>
      </div>
    </Popup>
  );
}

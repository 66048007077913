import {
  IconButton,
  ToggleButtonGroup,
  ToggleButtonGroupItem,
} from "@themis/ui";
import classNames from "classnames";
import React, { useState } from "react";
import {
  PiCaretLeftBold,
  PiCaretRightBold,
  PiDotsThreeOutlineFill,
} from "react-icons/pi";

export interface TablePagingProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const CHUNK_SIZE = 5;

function TablePaging({
  page,
  totalPages,
  onPageChange,
  className,
}: TablePagingProps) {
  const [chunkStart, setChunkStart] = useState(
    page - (page % CHUNK_SIZE || CHUNK_SIZE) + 1,
  );
  const [position, setPosition] = useState(page % CHUNK_SIZE || CHUNK_SIZE);

  const handleSetPage = (newPage: number) => {
    setChunkStart(newPage - (newPage % CHUNK_SIZE || CHUNK_SIZE) + 1);
    if (totalPages - newPage < CHUNK_SIZE) {
      setPosition(CHUNK_SIZE - (totalPages - newPage));
    } else {
      setPosition(newPage % CHUNK_SIZE || CHUNK_SIZE);
    }
    if (newPage !== page) {
      onPageChange(newPage);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      return handleSetPage(page - 1);
    }
    return handleSetPage(1);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      return handleSetPage(page + 1);
    }
    return handleSetPage(totalPages);
  };

  const handleNextPageChunk = () => {
    const t = CHUNK_SIZE - (position - 1);
    handleSetPage(page + t);
  };

  const handlePrevPageChunk = () => {
    if (totalPages - page < CHUNK_SIZE) {
      const pos = CHUNK_SIZE - (totalPages - page);
      handleSetPage(page - pos);
    } else {
      handleSetPage(page - position);
    }

    setPosition(CHUNK_SIZE);
  };

  function createPageButtons() {
    const buttons = [];
    if (totalPages <= CHUNK_SIZE) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <ToggleButtonGroupItem
            key={i}
            value={i.toString()}
            onClick={() => handleSetPage(i)}
            aria-label={`Page ${i}`}
          >
            {i}
          </ToggleButtonGroupItem>,
        );
      }
    } else {
      if (page <= CHUNK_SIZE) {
        for (let i = 1; i <= CHUNK_SIZE; i++) {
          buttons.push(
            <ToggleButtonGroupItem
              key={i}
              value={i.toString()}
              onClick={() => handleSetPage(i)}
              aria-label={`Page ${i}`}
            >
              {i}
            </ToggleButtonGroupItem>,
          );
        }
        buttons.push(
          <IconButton
            key="dots-right"
            Icon={PiDotsThreeOutlineFill}
            color="transparent"
            onClick={handleNextPageChunk}
            aria-label="Next Chunk"
          />,
        );
        buttons.push(
          <ToggleButtonGroupItem
            key="last-page"
            value={totalPages.toString()}
            onClick={() => handleSetPage(totalPages)}
            aria-label="Last Page"
          >
            {totalPages}
          </ToggleButtonGroupItem>,
        );
      } else if (page >= totalPages - 4) {
        buttons.push(
          <ToggleButtonGroupItem
            key="first-page"
            value="1"
            onClick={() => handleSetPage(1)}
            aria-label="First Page"
          >
            1
          </ToggleButtonGroupItem>,
        );
        buttons.push(
          <IconButton
            key="dots-left"
            Icon={PiDotsThreeOutlineFill}
            color="transparent"
            onClick={handlePrevPageChunk}
            aria-label="Previous Chunk"
          />,
        );
        for (let i = totalPages - (CHUNK_SIZE - 1); i <= totalPages; i++) {
          buttons.push(
            <ToggleButtonGroupItem
              key={i}
              value={i.toString()}
              onClick={() => handleSetPage(i)}
              aria-label={`Page ${i}`}
            >
              {i}
            </ToggleButtonGroupItem>,
          );
        }
      } else {
        buttons.push(
          <ToggleButtonGroupItem
            key="first-page"
            value="1"
            onClick={() => handleSetPage(1)}
            aria-label="First Page"
          >
            1
          </ToggleButtonGroupItem>,
        );
        buttons.push(
          <IconButton
            key="dots-left"
            Icon={PiDotsThreeOutlineFill}
            color="transparent"
            onClick={handlePrevPageChunk}
            aria-label="Previous Chunk"
          />,
        );
        for (let i = chunkStart; i <= chunkStart + (CHUNK_SIZE - 1); i++) {
          buttons.push(
            <ToggleButtonGroupItem
              key={i}
              value={i.toString()}
              onClick={() => handleSetPage(i)}
              aria-label={`Page ${i}`}
            >
              {i}
            </ToggleButtonGroupItem>,
          );
        }
        buttons.push(
          <IconButton
            key="dots-right"
            Icon={PiDotsThreeOutlineFill}
            color="transparent"
            onClick={handleNextPageChunk}
            aria-label="Next Chunk"
          />,
        );
        buttons.push(
          <ToggleButtonGroupItem
            key="last-page"
            value={totalPages.toString()}
            onClick={() => handleSetPage(totalPages)}
            aria-label="Last Page"
          >
            {totalPages}
          </ToggleButtonGroupItem>,
        );
      }
    }
    return buttons;
  }

  if (totalPages < 1) {
    return <div />;
  }

  return (
    <div className={classNames("tw-flex tw-gap-2", { className })}>
      <IconButton
        Icon={PiCaretLeftBold}
        onClick={handlePrevPage}
        disabled={page === 1}
        color="tertiary"
        aria-label="Previous Page"
      />

      <ToggleButtonGroup value={page.toString()} size="md" type="single">
        {createPageButtons()}
      </ToggleButtonGroup>

      <IconButton
        Icon={PiCaretRightBold}
        onClick={handleNextPage}
        disabled={page === totalPages}
        color="tertiary"
        aria-label="Next Page"
      />
    </div>
  );
}

export default TablePaging;

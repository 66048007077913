import type { SVGProps } from "react";
import React from "react";

const Pause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 3H15C14.6022 3 14.2206 3.15804 13.9393 3.43934C13.658 3.72065 13.5 4.10217 13.5 4.5V19.5C13.5 19.8978 13.658 20.2794 13.9393 20.5606C14.2206 20.8419 14.6022 21 15 21H18.75C19.1478 21 19.5294 20.8419 19.8106 20.5606C20.0919 20.2794 20.25 19.8978 20.25 19.5V4.5C20.25 4.10217 20.0919 3.72065 19.8106 3.43934C19.5294 3.15804 19.1478 3 18.75 3ZM18.75 19.5H15V4.5H18.75V19.5ZM9 3H5.25C4.85217 3 4.47065 3.15804 4.18934 3.43934C3.90804 3.72065 3.75 4.10217 3.75 4.5V19.5C3.75 19.8978 3.90804 20.2794 4.18934 20.5606C4.47065 20.8419 4.85217 21 5.25 21H9C9.39783 21 9.77935 20.8419 10.0607 20.5606C10.342 20.2794 10.5 19.8978 10.5 19.5V4.5C10.5 4.10217 10.342 3.72065 10.0607 3.43934C9.77935 3.15804 9.39783 3 9 3ZM9 19.5H5.25V4.5H9V19.5Z"
      fill="#353549"
    />
  </svg>
);

Pause.displayName = "PauseIcon";

export default Pause;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Module = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2C14.5977 2 15.9037 3.24892 15.9949 4.82373L16 5V8H19C20.5977 8 21.9037 9.24892 21.9949 10.8237L22 11V19C22 20.5977 20.7511 21.9037 19.1763 21.9949L19 22H11C9.40232 22 8.09634 20.7511 8.00509 19.1763L8 19V16H5C3.40232 16 2.09634 14.7511 2.00509 13.1763L2 13V5C2 3.40232 3.24892 2.09634 4.82373 2.00509L5 2H13ZM19 10H16V13C16 14.5977 14.7511 15.9037 13.1763 15.9949L13 16H10V19C10 19.5128 10.386 19.9355 10.8834 19.9933L11 20H19C19.5128 20 19.9355 19.614 19.9933 19.1166L20 19V11C20 10.4872 19.614 10.0645 19.1166 10.0067L19 10ZM13 4H5C4.48716 4 4.06449 4.38604 4.00673 4.88338L4 5V13C4 13.5128 4.38604 13.9355 4.88338 13.9933L5 14H8V11C8 9.40232 9.24892 8.09634 10.8237 8.00509L11 8H14V5C14 4.48716 13.614 4.06449 13.1166 4.00673L13 4ZM14 10H11C10.4872 10 10.0645 10.386 10.0067 10.8834L10 11V14H13C13.5128 14 13.9355 13.614 13.9933 13.1166L14 13V10Z"
      fill="#353549"
    />
  </svg>
);

Module.displayName = "ModuleIcon";

export default Module;

import type { AttachmentFile } from "./AttachmentFile";

export const attachmentFileType = {
  direct_upload: "direct_upload",
  figma: "figma",
  google_drive: "google_drive",
  share_point: "share_point",
  url_upload: "url_upload",
} as const;
export type AttachmentFileType =
  (typeof attachmentFileType)[keyof typeof attachmentFileType];
export type Attachment = {
  /**
   * @description AttachmentGroup ID
   * @type integer
   */
  attachment_group_id: number;
  /**
   * @description Created at
   * @type string
   */
  created_at: string;
  /**
   * @description File type
   * @type string
   */
  file_type: AttachmentFileType;
  /**
   * @description Attachment Group ID
   * @type integer
   */
  id: number;
  /**
   * @description Original file
   */
  original: AttachmentFile | null;
  /**
   * @description Preview file
   */
  preview: AttachmentFile | null;
  /**
   * @description Processed type
   */
  processed: AttachmentFile | null;
  /**
   * @description Uploaded at
   * @type string
   */
  upload_date: string;
  /**
   * @description URL
   * @type string
   */
  url: string | null;
  /**
   * @description Version
   * @type integer
   */
  version: number;
};

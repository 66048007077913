import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteDocumentMutationResponse,
  DeleteDocumentPathParams,
} from "../../models/DeleteDocument";

/**
 * @description Delete document
 * @summary Delete document
 * @link /documents/:id
 */
export async function deleteDocument(
  id: DeleteDocumentPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteDocumentMutationResponse>["data"]> {
  const res = await client<DeleteDocumentMutationResponse>({
    method: "delete",
    url: `/documents/${id}`,
    ...options,
  });
  return res.data;
}

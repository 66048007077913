import classNames from "classnames";
import React from "react";

interface ButtonProps {
  title: string;
  className?: string;
  enabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
}

function Button({ title, onClick, enabled, className }: ButtonProps) {
  const buttonClassNames = classNames("sign-up-submit", {
    "button-active": enabled,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [className]: className,
  });

  return (
    <button className={buttonClassNames} onClick={onClick} disabled={!enabled}>
      {title}
    </button>
  );
}

Button.defaultProps = {
  enabled: true,
};

export default Button;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../../Elements";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOpen?: (...args: any[]) => any;
  showPopup?: boolean;
  trigger?: React.ReactNode;
}

export const ActionButtonPopupWithFolder = ({
  showPopup,
  trigger,
  onClose,
  onOpen,
  onClick,
}: Props) => {
  // Import MobX stores
  const mainStore = useMainStore();
  // @ts-expect-error TS(2339) FIXME: Property 'folder_id' does not exist on type '{}'.
  const { folder_id: folderID } = useParams();

  const [selectedFolder, setSelectedFolder] = useState(null);

  const allFolders = [
    ...(folderID ? [{ id: "dashboard", title: "Unsectioned Files" }] : []),
    ...mainStore.sectionTags.list,
  ].filter((folder) => String(folder.id) !== folderID);

  // @ts-expect-error TS(7006) FIXME: Parameter 'folder' implicitly has an 'any' type.
  function setFolderForMove(folder) {
    setSelectedFolder(folder);
    mainStore.dynamicTable.setSelectedFolderID(folder.id);
    mainStore.dynamicTable.setCurrentFolderID(folderID || null);
  }

  return (
    <Popup
      position="bottom left"
      className="action-menu"
      open={showPopup}
      // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'Elemen... Remove this comment to see the full error message
      trigger={trigger}
      keepTooltipInside
      arrow
      onClose={onClose}
      onOpen={onOpen}
    >
      <div
        className="table-dropdown documents-context-menu-move"
        data-testid="menu-move-bulk"
      >
        <span className="move-description">Select location to move to</span>
        <ul className="move-folders" data-testid="move-folders-bulk">
          {allFolders.map((folder) => (
            <li
              key={folder.id}
              className={classNames({
                "move-folder": folder.id !== "dashboard",
                "move-folder-dashboard": folder.id === "dashboard",
                // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
                active: folder.id === selectedFolder?.id,
              })}
              data-testid={`move-folder-${folder.id}`}
              onClick={() => setFolderForMove(folder)}
            >
              <span
                className="move-folder-icon"
                data-testid="move-folder-icon"
              />
              {folder.title}
              {/* @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'. */}
              {folder.id === selectedFolder?.id && (
                <div className="move-folder-check-icon">
                  <Icon
                    name="check"
                    color="brandingHighlightTurquoise"
                    size="de"
                  />
                </div>
              )}
            </li>
          ))}
        </ul>

        <button
          className="move-button"
          disabled={!selectedFolder}
          data-testid="move-folder-button-bulk"
          onClick={onClick}
        >
          Continue
        </button>
      </div>
    </Popup>
  );
};

export default observer(ActionButtonPopupWithFolder);

import { observer } from "mobx-react";
import React from "react";

import { Button } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

import { iconForThemisModuleIdentifier } from "../../helpers/iconForThemisModuleIdentifier";

const EXTERNAL_IDENTIFIERS = [
  "policy",
  "marketing",
  "vendor_due_diligence",
  "risk_register",
  "control_mapping",
  "documents",
  "audits",
  "complaints",
  "issue_management",
  "qa_tests_development",
  "new_product_approval",
  "training",
  "risk_assessment",
  "key_risk_indicators",
];

interface NewModuleProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  add: (...args: any[]) => any;
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  themisModule?: any;
}

const NewModule = ({ themisModule, add, name }: NewModuleProps) => {
  const mainStore = useMainStore();
  const { identifier } = themisModule;
  const { moduleInfo } = mainStore.moduleWorkspaces;
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;

  return (
    <div className="new-module">
      <div className="tw-flex tw-items-center">
        <div className="icon-container">
          <img
            src={iconForThemisModuleIdentifier(identifier)}
            alt="logo"
            className="module-icon"
          />
        </div>
        <div className="content">
          <div className="module-name">{name || themisModule.name}</div>
          <div className="module-description">
            {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {moduleInfo[identifier].description}
          </div>
        </div>
      </div>
      <Button
        size="sm"
        label="Add"
        theme="secondary"
        onClick={add}
        disabled={isCurrentWorkspaceArchived}
      />
    </div>
  );
};

const AddModules = () => {
  const mainStore = useMainStore();
  const { moduleInfo } = mainStore.moduleWorkspaces;
  const isInternal = mainStore.users.user.active_workspace?.is_internal;
  const modules = mainStore.moduleWorkspaces.list.filter((moduleWorkspace) => {
    if (isInternal) {
      return (
        !moduleWorkspace.added &&
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        moduleInfo[moduleWorkspace.themis_module.identifier]
      );
    }
    return (
      !moduleWorkspace.added &&
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      moduleInfo[moduleWorkspace.themis_module.identifier] &&
      EXTERNAL_IDENTIFIERS.includes(moduleWorkspace.themis_module.identifier)
    );
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleIdentifier' implicitly has an 'an... Remove this comment to see the full error message
  const addModule = (moduleIdentifier) => {
    mainStore.moduleWorkspaces.addModule(moduleIdentifier);
  };

  if (modules.length === 0) {
    return null;
  }

  return (
    <div className="add-modules">
      <div className="title">Add New Modules</div>
      <div className="grid-wrap">
        {modules.map((themisModule) => (
          <NewModule
            key={themisModule.themis_module.identifier}
            name={themisModule.name}
            themisModule={themisModule.themis_module}
            add={() => addModule(themisModule.themis_module.identifier)}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(AddModules);

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const ChangeManagementReportContent = () => (
  <ReportsPage
    identifier="new_product_approval"
    defaultDateColumn="submission_date"
    defaultGroupColumn="status"
  />
);

export default observer(ChangeManagementReportContent);

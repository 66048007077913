import React from "react";
import { Link } from "react-router-dom";

import UnlinkButtonWithTooltip from "@/components/related-record/UnlinkButtonWithTooltip";

interface Props {
  id: number;
  url: string | null;
  isModuleAdded: boolean;
  name: string | null;
  unlinkRecord: () => void;
}

function RelatedRecord({ id, url, isModuleAdded, name, unlinkRecord }: Props) {
  return (
    <div className="tw-border-y-1 tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-x-4 tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-y-generals-underline tw-py-1.5 last:tw-border-b">
      <div className="tw-text-sm tw-font-medium tw-text-neutral-500">
        {url && isModuleAdded ? (
          <Link
            aria-label="Related Record"
            aria-describedby={`related-record-id-${id}`}
            className="tw-text-neutral-500 hover:tw-underline"
            to={url}
          >
            {name}
          </Link>
        ) : (
          name
        )}
        <span id={`related-record-id-${id}`} className="tw-hidden">
          Related Record Name {name}
        </span>
      </div>
      <UnlinkButtonWithTooltip onClick={unlinkRecord} />
    </div>
  );
}

export default RelatedRecord;

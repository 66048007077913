import React from "react";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";

import { ACCOUNT_DETAILS_PATH } from "../../pages";

export function AccountIdLinkCell({ accountId }: { accountId: number }) {
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const location = useLocation();

  return (
    <Link
      className="tw-w-full hover:tw-underline"
      to={{
        pathname: generatePath(ACCOUNT_DETAILS_PATH, {
          workspace_id,
          accountId,
        }),
        state: { from: location.pathname },
      }}
    >
      {accountId}
    </Link>
  );
}

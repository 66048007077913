import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Plus = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0002 11.0004L13.0002 3.51493C13.0002 2.96198 12.5533 2.51508 12.0003 2.51508C11.4474 2.51508 11.0005 2.96197 11.0005 3.51493L11.0005 11.0004L3.51505 11.0004C2.9621 11.0004 2.5152 11.4473 2.5152 12.0002C2.5152 12.5532 2.9621 13.0001 3.51505 13.0001L11.0005 13.0001L11.0005 20.4855C11.0005 21.0385 11.4474 21.4853 12.0003 21.4853C12.2761 21.4853 12.5264 21.3736 12.7074 21.1926C12.8885 21.0116 13.0002 20.7613 13.0002 20.4855L13.0002 13.0001L20.4856 13.0001C20.7614 13.0001 21.0117 12.8883 21.1927 12.7073C21.3737 12.5263 21.4855 12.276 21.4855 12.0002C21.4855 11.4473 21.0386 11.0004 20.4856 11.0004L13.0002 11.0004Z"
      fill="#353549"
    />
  </svg>
);

Plus.displayName = "PlusIcon";

export default Plus;

import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

function CopyToCompanyKRILibrary() {
  const mainStore = useMainStore();
  const history = useHistory();
  const { company } = mainStore.companies;
  const { checkedRows } = mainStore.keyRiskIndicatorsThemisLibrary;
  const { canManageTags } = mainStore.userPermissions;
  const buttonEnabled =
    canManageTags &&
    mainStore.keyRiskIndicatorsThemisLibrary.checkedRows.length > 0;
  async function handleClick() {
    if (!company.id) {
      return;
    }

    await mainStore.keyRiskIndicatorsThemisLibrary.copyToCompanyKriLibrary({
      companyID: company.id,
      ids: checkedRows,
    });
    mainStore.keyRiskIndicatorsThemisLibrary.setSelectedSectionTagID(null);
    mainStore.keyRiskIndicatorsThemisLibrary.setCheckedRows([]);
    history.push(`./templates`);
  }

  return (
    <button
      disabled={!buttonEnabled}
      className="table-button header-action"
      onClick={handleClick}
    >
      Add to Company KRI Library
    </button>
  );
}

export default observer(CopyToCompanyKRILibrary);

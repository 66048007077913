import React from "react";
import { Route, Switch } from "react-router-dom";

import CreateCTA from "../../../components/table/policy/CreateCTA";
import Policies from "../../../components/table/policy/Policies";
import CWCreateFirstCTA from "../../../components/table/shared/create-first-cta/CWCreateFirstCTA";
import lightbulb from "../../../images/table-image/icon/lightbulb-icon.svg";

interface Props {
  isInternal?: boolean;
  recordNamePlural?: string;
  recordVersionsLength?: number;
  showCTA?: boolean;
  showCheckbox?: boolean;
}

function PoliciesFinalized({
  recordVersionsLength,
  showCTA,
  isInternal,
  showCheckbox,
  recordNamePlural,
}: Props) {
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const recordNamePluralLowerCase = recordNamePlural.toLowerCase();

  return (
    <div className="policies-table-section" data-testid="policies-finalized">
      <Switch>
        <Route exact path="/workspaces/:workspace_id/modules/policy/finalized">
          <div className="table-list-wrap no-margin table-list-wrap-wider policy-table finalized">
            <Policies showCheckbox={showCheckbox} />

            {recordVersionsLength === 0 && (
              <div className="review-attested-policies">
                <h4>
                  <img src={lightbulb} alt="lightbulb-icon" />
                  Review your published {recordNamePluralLowerCase}
                </h4>
                <p>
                  Published {recordNamePluralLowerCase} are stored here. Publish
                  draft {recordNamePluralLowerCase} are visible here. These{" "}
                  {recordNamePluralLowerCase} can be shared with partners and
                  attested to by employees.
                </p>
              </div>
            )}
            {showCTA && isInternal && <CreateCTA />}

            {showCTA && !isInternal && (
              <CWCreateFirstCTA
                title={`Add / Share ${recordNamePlural}`}
                sentences={[
                  `Create ${recordNamePlural} for the collaborative workspace by clicking "Add New Policy" on the top to start.`,
                  `Shared ${recordNamePluralLowerCase} are visible here.`,
                ]}
              />
            )}
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default PoliciesFinalized;

import "./styles.scss";

import classNames from "classnames";
import React, { useState } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import Popup from "reactjs-popup";

import { Flex, MiniTag, Typography } from "@/components/Elements";
import type { THEMIS_MINITAG_THEMES } from "@/config/theme";
import arrowDownIcon from "@/images/table-image/icon/arrow-down-black.svg";
import type { ValueLabelOption } from "@/stores/types/field-types";

interface NewSelectInputProps<T extends FieldValues>
  extends UseControllerProps<T> {
  options: ValueLabelOption<FieldValues[keyof FieldValues]>[];
  disabledOptions?: FieldValues[keyof FieldValues][];
  placeholder: string;
  multiple?: boolean;
  fullWidth?: boolean;
  className?: string;
  tooltipError?: boolean;
  miniTagTheme?: (typeof THEMIS_MINITAG_THEMES)[number];
}

export const SelectInputField = <T extends FieldValues>({
  options,
  disabledOptions,
  placeholder,
  multiple,
  miniTagTheme,
  fullWidth,
  className,
  tooltipError,
  ...controller
}: NewSelectInputProps<T>) => {
  const [open, setOpen] = useState(false);
  const { field, fieldState } = useController(controller);
  const selected =
    !multiple && options.find((option) => option.value === field.value);

  // @ts-expect-error TS(7006) FIXME: Parameter 'newValue' implicitly has an 'any' type.
  const handleChange = (newValue) => {
    if (multiple) {
      field.onChange([...field.value, newValue]);

      return;
    }

    field.onChange(newValue);

    setOpen(false);
  };

  const filteredOptions = multiple
    ? options.filter((option) => !field.value.includes(option.value))
    : options;

  return (
    <Popup
      position="bottom left"
      trigger={
        <div
          data-testid={controller.name}
          className={classNames(className, {
            "select-form-input__container--full-width": fullWidth,
          })}
        >
          <Flex
            tabIndex={0}
            alignCenter
            data-tooltip-id="tooltip"
            data-tooltip-hidden={!tooltipError}
            data-tooltip-content={fieldState.error?.message}
            data-tooltip-place="bottom"
            justifySpaceBetween
            className={classNames("select-form-input", className, {
              "select-form-input--opened": open,
              "select-form-input--error": fieldState.error,
            })}
          >
            <Flex alignCenter columnGap={8}>
              {/* @ts-expect-error TS(2339) FIXME: Property 'icon' does not exist on type 'false | Va... Remove this comment to see the full error message */}
              {selected?.icon && (
                <img
                  className="select-form-input__icon-image"
                  // @ts-expect-error TS(2339) FIXME: Property 'icon' does not exist on type 'false | Va... Remove this comment to see the full error message
                  src={selected?.icon}
                  // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'false | V... Remove this comment to see the full error message
                  alt={`${selected?.label}-icon`}
                />
              )}
              <Typography
                // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'false | V... Remove this comment to see the full error message
                label={selected?.label || placeholder}
                size="xs"
                weight="semiBold"
                // @ts-expect-error TS(2339) FIXME: Property 'label' does not exist on type 'false | V... Remove this comment to see the full error message
                color={selected?.label ? "generalMidnightDark" : undefined}
              />
            </Flex>
            <img src={arrowDownIcon} alt="open" />
          </Flex>
        </div>
      }
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      keepTooltipInside
    >
      <ul className={classNames("table-dropdown", "select-form-input__popup")}>
        {filteredOptions.map((option) => {
          const active =
            // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'false | V... Remove this comment to see the full error message
            selected?.value === option.value ||
            disabledOptions?.includes(option.value);

          return (
            <li
              key={option.value}
              data-testid={option.value}
              className={classNames("select-form-input__option", {
                "select-form-input__option--active": active,
              })}
              onClick={() => {
                if (active) {
                  return;
                }

                handleChange(option.value);
              }}
            >
              <Flex alignCenter columnGap={6}>
                {option.icon && (
                  <img
                    className="select-form-input__icon-image"
                    src={option.icon}
                    alt={`${option.label}-icon`}
                  />
                )}
                {miniTagTheme ? (
                  <MiniTag theme={miniTagTheme} label={option.label} />
                ) : (
                  option.label
                )}
              </Flex>
            </li>
          );
        })}
      </ul>
    </Popup>
  );
};

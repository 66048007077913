import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class ContactsStore {
  mainStore: MainStore;

  // Observable objects
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: any[] = [];
  current = {};
  contactErrors = {};

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      current: observable,
      contactErrors: observable,
      setList: action,
      setCurrent: action,
      setContactErrors: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'sortParams' implicitly has an 'an... Remove this comment to see the full error message
  async index({ sortParams }) {
    try {
      const params = {};

      if (sortParams) {
        // @ts-expect-error TS(2339) FIXME: Property 'sortBy' does not exist on type '{}'.
        params.sortBy = sortParams.field_name;
        // @ts-expect-error TS(2339) FIXME: Property 'sortByDirection' does not exist on type ... Remove this comment to see the full error message
        params.sortByDirection = sortParams.direction;
      }

      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/contacts`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setList(result.data.users);
    } catch (error) {
      window.console.log(`"Contacts#index" error ${error}`);
    }
  }

  async export() {
    this.mainStore.toast.setInfoText("Processing contact list download...");

    const result = await legacyApi({
      method: "GET",
      url: `${API_URL}/contacts/export`,
      headers: this.mainStore.getHeaders(),
    });
    // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
    if (result.isAxiosError) {
      this.mainStore.toast.setInfoText(
        "An error occurred during export. Please try again or contact Themis support if the problem persists.",
      );
      return;
    }

    return result.data;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userParams' implicitly has an 'any' typ... Remove this comment to see the full error message
  async create(userParams) {
    if (!userParams.email) {
      window.console.warn(
        `Contacts#create => email(${userParams.email}) should be present in params for User!`,
      );
      return;
    }

    const data = { user: userParams };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/contacts`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.setContactErrors(result.response.data.errors);
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result.response);
        return;
      }

      this.setCurrent(result.data.user);

      // Reload list of Contacts
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ sortBy: null; sortByDirection:... Remove this comment to see the full error message
      this.index({ sortBy: null, sortByDirection: null });
      return result.data.user;
    } catch (error) {
      window.console.log(`"Contacts#create" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async update(userID, userParams) {
    if (!userID) {
      window.console.warn(
        `Contacts#update => userID(${userID}) should be present!`,
      );
      return;
    }

    const data = { user: userParams };

    try {
      const result = await legacyApi({
        method: "PUT",
        url: `${API_URL}/contacts/${userID}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.setContactErrors(result.response.data.errors);
        return;
      }

      this.setCurrent(result.data.user);

      // Reload list of Contacts
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ sortBy: null; sortByDirection:... Remove this comment to see the full error message
      this.index({ sortBy: null, sortByDirection: null });
    } catch (error) {
      window.console.log(`"Contacts#update" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async delete(userID) {
    if (!userID) {
      window.console.warn(
        `Contacts#destroy => userID(${userID}) should be present!`,
      );
      return;
    }

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/contacts/${userID}`,
        headers: this.mainStore.getHeaders(),
      });

      this.setCurrent({});

      // Reload list of Contacts
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ sortBy: null; sortByDirection:... Remove this comment to see the full error message
      this.index({ sortBy: null, sortByDirection: null });
    } catch (error) {
      window.console.log(`"Contacts#destroy" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userIDs' implicitly has an 'any' type.
  async bulkDelete(userIDs, workspaceID) {
    if (!userIDs) {
      window.console.warn(
        `Contacts#bulk_delete => userIDs(${userIDs}) should be present!`,
      );
      return;
    }
    const data = { user_ids: userIDs, workspace_id: workspaceID };

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/contacts/bulk_delete_contacts`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // Reload list of Contacts
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ sortBy: null; sortByDirection:... Remove this comment to see the full error message
      this.index({ sortBy: null, sortByDirection: null });
    } catch (error) {
      window.console.log(`"Contacts#destroy" error ${error}`);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setList(value) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    if (value) {
      this.current = value;
    } else {
      this.current = {};
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setContactErrors(value) {
    if (value) {
      this.contactErrors = value;
    } else {
      this.contactErrors = {};
    }
  }

  // Store Helpers

  cleanup() {
    this.setList([]);
    this.setCurrent({});
    this.setContactErrors({});
  }
}

import { useContext, useEffect, useState } from "react";

import type {
  IndexParams,
  ModuleStore,
} from "@/stores/types/module-store-types";

import { StoreContext } from "../contexts/Store";

type UseSortingAndPaginationProps = IndexParams & {
  store?: ModuleStore;
};

export function useSortingAndPagination({
  store,
  ...indexParams
}: UseSortingAndPaginationProps) {
  const mainStore = useContext(StoreContext);
  const { workspaceID: activeWorkspaceID, tableName: databaseTableName } =
    mainStore.context;

  const [sortByFieldName, setSortByFieldName] = useState<string | null>(null);
  const [sortByDirection, setSortByDirection] = useState<SortOptions>(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const setSortBy = (fieldName: string) => {
    if (!sortByFieldName) {
      setSortByFieldName(fieldName);
      setSortByDirection("ASC");
    } else if (fieldName === sortByFieldName && sortByDirection === "ASC") {
      setSortByDirection("DESC");
    } else if (fieldName === sortByFieldName && sortByDirection === "DESC") {
      setSortByFieldName(null);
      setSortByDirection(null);
    } else if (fieldName !== sortByFieldName) {
      setSortByFieldName(fieldName);
      setSortByDirection("ASC");
    }
  };

  useEffect(() => {
    indexParams.workspaceID = activeWorkspaceID || undefined;
    indexParams.tableName = databaseTableName || undefined;
    indexParams.fromUseSortingAndPagination = true;

    if (sortByFieldName && sortByDirection) {
      indexParams.sortParams = {
        field_name: sortByFieldName,
        direction: sortByDirection,
      };
    }

    // Make sure it does not fire initially, when there is no `sortParams`
    if (
      store &&
      indexParams.workspaceID &&
      (indexParams.sortParams || !initialLoad)
    ) {
      store.index(indexParams);
      setInitialLoad(false);
    }
  }, [sortByFieldName, sortByDirection]);

  return {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
    setSortBy,
  };
}

import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

interface Props {
  description: string;
  identifier: string;
  isComingSoon: boolean;
  isIncluded: boolean;
  title: string;
  isSelected?: boolean;
}

function ModulesBlock({
  description,
  identifier,
  isComingSoon,
  isIncluded,
  title,
  ...props
}: Props) {
  const mainStore = useMainStore();

  // State
  const [isSelected, setIsSelected] = useState(false);
  const [buttonText, setButtonText] = useState("Add Module");

  const buttonDisabled = isComingSoon || isIncluded;
  const modulePlateClasses = classNames("modules-plate", `${identifier}`);
  const moduleButtonClasses = classNames("modules-button", {
    selected: isSelected && !isComingSoon,
    disabled: buttonDisabled,
  });

  function handleClick() {
    setIsSelected(!isSelected);
  }

  useEffect(() => {
    setIsSelected(props.isSelected === true);
  }, [props.isSelected]);

  useEffect(() => {
    if (isIncluded === true) {
      setButtonText("Included");
      return;
    }

    if (isComingSoon === true) {
      setButtonText("Coming Soon");
    }

    if (isSelected && !isComingSoon) {
      setButtonText("Unselect");
      mainStore.moduleWorkspaces.addModule(identifier);
    } else {
      if (isComingSoon !== true) {
        setButtonText("Add Module");
      }
      mainStore.moduleWorkspaces.removeModule(identifier);
    }
  }, [isSelected]);

  return (
    <div className="modules-block">
      <div className="modules-wrap-plate">
        <div className={modulePlateClasses}>{title}</div>
        <p>{description}</p>
      </div>
      <button
        className={moduleButtonClasses}
        onClick={handleClick}
        disabled={buttonDisabled}
      >
        {buttonText}
      </button>
    </div>
  );
}

export default ModulesBlock;

import { useToast } from "@themis/ui";
import React from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import type {
  CreateProjectMutationRequest,
  CreateProjectMutationResponse,
  Project,
  TaskReference,
  UpdateProjectMutationRequest,
  UpdateProjectMutationResponse,
  User,
} from "@/api";
import { useCompanyUsers } from "@/api/queries/users";
import UserBadge from "@/components/shared/UserBadge";
import { useMainStore } from "@/contexts/Store";
import type { ProjectDetailsSchema } from "@/features/projects/components/ProjectForm";
import ProjectForm from "@/features/projects/components/ProjectForm";
import { TaskStatus } from "@/features/tasks/config/status";

import { PROJECTS_LIST_PATH } from "../pages/ProjectRoutes";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-h-screen tw-min-w-96 tw-flex-col tw-gap-8 tw-bg-neutral-25 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5 tw-text-xs tw-font-semibold tw-text-neutral-500">
      {children}
    </div>
  );
}

interface ProjectInfoProps {
  data: Project | undefined;
  createProject: (
    project: CreateProjectMutationRequest,
  ) => Promise<CreateProjectMutationResponse>;
  updateProject: (
    project: UpdateProjectMutationRequest,
  ) => Promise<UpdateProjectMutationResponse>;
  companyID: number | null;
}

export default function ProjectInfo({
  data,
  createProject,
  updateProject,
  companyID,
}: ProjectInfoProps) {
  const toast = useToast();
  const mainStore = useMainStore();
  const history = useHistory();

  const { workspace_id, project_id } = useParams<{
    workspace_id: string;
    project_id: string;
  }>();
  const isNewProject = isNaN(Number(project_id));

  const { data: users } = useCompanyUsers(Number(companyID));

  async function handleSubmitForm(values: ProjectDetailsSchema) {
    if (isNewProject) {
      try {
        const response = await createProject({ project: values });

        history.push(
          generatePath(PROJECTS_LIST_PATH, {
            workspace_id,
          }),
        );

        toast({
          content: `"${response.data.name}" has been added!`,
          variant: "success",
        });
      } catch {
        toast({
          content: "Something went wrong. Could not create project.",
          variant: "error",
        });
      }
    } else {
      try {
        const response = await updateProject({ project: values });

        history.push(
          generatePath(PROJECTS_LIST_PATH, {
            workspace_id,
          }),
        );

        toast({
          content: `"${response.data.name}" has been updated!`,
          variant: "success",
        });
      } catch {
        toast({
          content: "Something went wrong. Could not update project.",
          variant: "error",
        });
      }
    }
  }

  function getStatus(taskRefs: TaskReference[]) {
    const doneCount = taskRefs.filter(
      (taskRef) => taskRef.status === TaskStatus.done.value,
    ).length;

    if (doneCount === 0 || taskRefs.length === 0) {
      return TaskStatus.not_started.Component();
    } else if (doneCount === taskRefs.length) {
      return TaskStatus.done.Component();
    }
    return TaskStatus.in_progress.Component();
  }

  return (
    <FormContainer>
      <div className="tw-flex tw-gap-2">
        <StatusContainer>
          Status
          {isNewProject
            ? TaskStatus.not_started.Component()
            : getStatus(
                (data?.tasks || []).filter(
                  (task): task is TaskReference => task && "status" in task,
                ),
              )}
        </StatusContainer>
        <UserBadge
          text="Created by"
          user={
            isNewProject
              ? mainStore.users.user
              : users?.data.find(
                  (user: User) => user.id === data?.created_by_id,
                ) || {
                  full_name: "Not Found",
                  initials: "NF",
                  icon_color_index: 0,
                }
          }
        />
      </div>
      <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
        Project Details
      </h2>
      <ProjectForm defaultValues={data} onSubmit={handleSubmitForm} />
    </FormContainer>
  );
}

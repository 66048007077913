import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";

import { useMainStore } from "@/contexts/Store";

import Tile from "../common/Tile";
import RecordsByMonthStackedBar from "../sharedGraphs/records-by-month-stacked-bar";
import QAReportSelectFields from "./QAReportSelectFields";
import QAReportsTable from "./QAReportsTable";
import QAReportTimeline from "./QAReportTimeline";

const title = {
  "Status and Detail Report": "Testing Counts & Status",
  "Timeline Report": "Testing Timeline",
};

interface Props {
  currentReport?: string;
  double?: boolean;
  showTiles?: boolean;
}

function QAReportContent({ currentReport, showTiles, double }: Props) {
  const mainStore = useMainStore();
  const { activeWorkspace } = mainStore.context;
  const moduleWorkspace = mainStore.moduleWorkspaces.list?.find(
    (mw) => mw.themis_module?.identifier === "qa_tests_development",
  );
  const moduleWorkspaceID = moduleWorkspace?.id;

  useEffect(() => {
    mainStore.qa.index({ workspaceID: activeWorkspace?.id });
  }, []);

  useEffect(() => {
    if (!moduleWorkspaceID) {
      return;
    }

    mainStore.reports.getFields(moduleWorkspaceID);
  }, [moduleWorkspaceID, mainStore.moduleWorkspaces]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      mainStore.fields.getAllFieldsByDataType({
        workspaceIDs: activeWorkspace ? [activeWorkspace.id] : [],
        identifier,
      });
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [activeWorkspace]);

  const { count } = mainStore.reports.qa;

  const optionFields = useMemo(
    () =>
      mainStore.reports.currentFields?.filter(
        // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
        (field) => field.data_type === "com.askthemis.types.v1.option",
      ),
    [mainStore.reports.currentFields],
  );
  const identifier = "qa_tests_development";
  const defaultDateColumn = "start_date";
  const defaultGroupByField = { name: "status", display_name: "Status" };
  const defaultGroupColumn = "status";
  const renderReport = () => {
    switch (currentReport) {
      case "Status and Detail Report":
        return (
          <RecordsByMonthStackedBar
            identifier={identifier}
            dateColumnDefault={defaultDateColumn}
            groupColumnDefault={defaultGroupColumn}
          />
        );
      case "Timeline Report":
        return (
          <QAReportTimeline
            groupColumnDefault="status"
            selectComponents={
              <QAReportSelectFields
                optionFields={optionFields}
                defaultGroupedBy={defaultGroupByField}
              />
            }
          />
        );
      default:
        return null;
    }
  };

  const headerClass = classNames("report-dashboard-header", {
    double,
    "no-bottom-padding": !showTiles,
  });

  return (
    <div className="report-dashboard-wrap">
      <div className={headerClass}>
        <div className="report-dashboard-header-block">
          <div className="report-dashboard-header-left">
            {/* @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type. */}
            {title[currentReport]}
          </div>
        </div>
      </div>{" "}
      {showTiles && currentReport === "Status and Detail Report" && (
        <div className="tile-container">
          <Tile label="Total Tests" count={count?.total} />
          <Tile label="New" count={count?.new} />
          <Tile label="In Progress" count={count?.inProgress} />
          <Tile label="Completed" count={count?.completed} />
        </div>
      )}
      {renderReport()}
      {currentReport === "Status and Detail Report" && (
        <>
          <div className="qa-divider" />
          <div className="qa-reports-table">
            <QAReportsTable />
          </div>
        </>
      )}
    </div>
  );
}

QAReportContent.defaultProps = {
  showTiles: true,
};

export default observer(QAReportContent);

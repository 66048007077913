import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListSearchRecordVersionsPathParams,
  ListSearchRecordVersionsQueryParams,
  ListSearchRecordVersionsQueryResponse,
} from "../../models/ListSearchRecordVersions";

/**
 * @description List of Record Version after searching
 * @summary List of Record Version after search
 * @link /workspaces/:workspace_id/searches
 */
export async function listSearchRecordVersions(
  workspaceId: ListSearchRecordVersionsPathParams["workspace_id"],
  params?: ListSearchRecordVersionsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListSearchRecordVersionsQueryResponse>["data"]> {
  const res = await client<ListSearchRecordVersionsQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/searches`,
    params,
    ...options,
  });
  return res.data;
}

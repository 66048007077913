export const getFileExtension = (filename: string) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);

  return !ext ? "" : ext[1];
};

export const createDownload = (
  data: Iterable<number> | undefined,
  filename: string,
) => {
  if (data) {
    const link = document.createElement("a");
    const blob = new Blob([new Uint8Array(data)], {
      type: "application/octet-stream",
    });
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.xls`;
    link.click();
  }
};

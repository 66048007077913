import classNames from "classnames";
import type { KeyboardEventHandler, MouseEventHandler } from "react";
import React from "react";

import { Button, Icon } from "@/components/Elements";

import { CONDITIONS_INTEGER } from "./constants";
import type { ConditionInteger } from "./types";

export const FiltersIntegerConditions = ({
  handleCloseFilter,
  handleSelectCondition,
  condition,
  showBackButton = true,
}: {
  handleCloseFilter?: () => void;
  handleSelectCondition: (condition: ConditionInteger) => void;
  condition: ConditionInteger;
  showBackButton: boolean;
}) => {
  return (
    <>
      {showBackButton && (
        <div className="select-item">
          <Icon
            onClick={handleCloseFilter}
            className="go-back"
            name="chevronLeft"
            color="generalLightBlue"
          />
          Select Conditions
        </div>
      )}
      <div className="date-popup-content">
        {CONDITIONS_INTEGER.map((item) => (
          <div
            className={classNames("item", {
              selected: condition === item,
            })}
            key={item}
            onClick={() => handleSelectCondition(item)}
            data-testid={item}
          >
            {item}
          </div>
        ))}
      </div>
    </>
  );
};

export const FiltersIntegerValue = ({
  goBackToSelectCondition,
  setValue,
  handleKeyDown,
  onlyContent,
  showBackButton = true,
  value,
}: {
  goBackToSelectCondition?: () => void;
  setValue: (value: string) => void;
  handleKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onlyContent: boolean;
  showBackButton: boolean;
  value: string | number;
}) => {
  const content = (
    <>
      <div className="select-item">
        {showBackButton && (
          <Icon
            onClick={goBackToSelectCondition}
            className="go-back"
            name="chevronLeft"
            color="generalLightBlue"
          />
        )}
        Enter Number
      </div>
      <div className="input-integer-type">
        <input
          type="number"
          value={value}
          placeholder="- Type Here -"
          onKeyDown={handleKeyDown}
          onChange={(event) => setValue(event.target.value)}
        />
      </div>
    </>
  );
  return onlyContent ? (
    content
  ) : (
    <div className="table-dropdown success-dropdown filters-popup">
      {content}
    </div>
  );
};

export const FiltersIntegerRangeValues = ({
  goBackToSelectCondition,
  setValueFrom,
  setValueTo,
  skipDisable,
  betweenIntegerRangeSelected,
  onlyContent,
  disabled,
  showBackButton = true,
  valueFrom,
  valueTo,
}: {
  goBackToSelectCondition?: () => void;
  setValueFrom?: (value: string) => void;
  setValueTo?: (value: string) => void;
  skipDisable?: (value: string) => void;
  betweenIntegerRangeSelected: MouseEventHandler<HTMLButtonElement>;
  onlyContent: boolean;
  disabled: boolean;
  showBackButton: boolean;
  valueFrom: string | number;
  valueTo: string | number;
}) => {
  const content = (
    <>
      {showBackButton && (
        <div className="select-item">
          <Icon
            onClick={goBackToSelectCondition}
            className="go-back"
            name="chevronLeft"
            color="generalLightBlue"
          />
          Enter Numbers
        </div>
      )}
      <div className="input-container">
        <div className="input-integer-type">
          from
          <input
            autoFocus
            data-testid="betweenIntegerRange-input"
            type="number"
            value={valueFrom}
            placeholder="- Type here -"
            onChange={(event) => {
              setValueFrom?.(event.target.value);
              skipDisable?.(event.target.value);
            }}
          />
        </div>
        <div className="input-integer-type">
          to
          <input
            autoFocus
            data-testid="betweenIntegerRange-input"
            type="number"
            value={valueTo}
            placeholder="- Type here -"
            onChange={(event) => {
              setValueTo?.(event.target.value);
              skipDisable?.(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="confirm-button">
        <Button
          label="Confirm"
          onClick={betweenIntegerRangeSelected}
          disabled={disabled}
        />
      </div>
    </>
  );
  return onlyContent ? (
    content
  ) : (
    <div className="table-dropdown success-dropdown filters-popup">
      {content}
    </div>
  );
};

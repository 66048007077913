import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { Link } from "react-router-dom";

interface AddNewAccountProps {
  recordName: string;
  to: string;
}

function AddNewAccount({ recordName, to }: AddNewAccountProps) {
  return (
    <Link
      className="tw-sticky tw-left-[0px] tw-ml-[0px]"
      data-testid={`${recordName}-table-add-new`}
      to={to}
    >
      <div className="tw-flex tw-w-fit tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px] tw-text-neutral-300">
        <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
        <div className="tw-text-sm">Add {recordName}...</div>
      </div>
    </Link>
  );
}

export default AddNewAccount;

import { observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Spinner from "../../table/shared/Spinner";

function IMControlsSaveButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isSaving, setIsSaving] = useState(false);

  // Variables
  const { changed } = mainStore.issueManagement.controlsData;
  const disabled = isSaving || !changed;
  const location = useLocation();

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const id = parseInt(
      location.pathname.split("/issue-management/")[1].split("/controls")[0],
      10,
    );
    await mainStore.issueManagement.saveControlsData(id);

    setIsSaving(false);
  }

  return (
    <button
      className="table-button header-action"
      disabled={disabled}
      onClick={handleClick}
    >
      Save Controls
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(IMControlsSaveButton);

import { Label, MiniTag } from "@themis/ui";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

function MarketingCreativeViewStages() {
  const mainStore = useMainStore();

  const location = useLocation();
  const recordVersion = mainStore.recordVersions.current;
  const status = recordVersion?.status;
  const dataOptions = recordVersion?.data;
  const isOnAudit = location.pathname.endsWith("audit_trail");
  const diffDays = calculateDaysDifference(dataOptions?.reviewing_date?.value);
  const currentUserID = mainStore.users.user.id;
  const reviews = recordVersion?.reviews?.find(
    (review) =>
      review.user_id === currentUserID && review.review_type === "approval",
  );
  const approvedAt = reviews?.approved_at;

  function calculateDaysDifference(lastUpdatedDate: string) {
    const now = dayjs();
    const dateFrom = dayjs(lastUpdatedDate);

    const daysDifference = now.diff(dateFrom, "day");

    const label =
      daysDifference > 1 ? `${daysDifference} days ` : `${daysDifference} day `;
    return label;
  }

  return (
    <div className="tw-flex tw-w-full tw-items-start tw-pr-[120px] tw-text-lg tw-font-semibold">
      {!isOnAudit && (
        <>
          {status === "new" && (
            <MiniTag color="grey" size="lg">
              New
            </MiniTag>
          )}
          {!reviews && status === "reviewing" && (
            <div>
              <MiniTag color="primary" size="lg">
                In Review
              </MiniTag>
              <Label className="tw-px-4">{diffDays}in review</Label>
            </div>
          )}
          {!reviews && status === "request_change" && (
            <div>
              <MiniTag color="orange" size="lg">
                Change Required
              </MiniTag>
              <Label className="tw-px-4">{diffDays}pending changes</Label>
            </div>
          )}
          {(status === "approved" || approvedAt) && (
            <MiniTag color="secondary" size="lg">
              Approved
            </MiniTag>
          )}
          {status === "rejected" && (
            <MiniTag color="red" size="lg">
              Denied
            </MiniTag>
          )}
        </>
      )}
    </div>
  );
}

export default observer(MarketingCreativeViewStages);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListPoliciesPathParams,
  ListPoliciesQueryParams,
  ListPoliciesQueryResponse,
} from "../../models/ListPolicies";

/**
 * @description List policies
 * @summary List policies
 * @link /workspaces/:workspace_id/policies
 */
export async function listPolicies(
  workspaceId: ListPoliciesPathParams["workspace_id"],
  params?: ListPoliciesQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListPoliciesQueryResponse>["data"]> {
  const res = await client<ListPoliciesQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/policies`,
    params,
    ...options,
  });
  return res.data;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { cva } from "cva";
import * as React from "react";
import { cn } from "../../lib/utils";
import { Label } from "../Label/Label";
import { MiniTag } from "../MiniTag/MiniTag";
var switchVariants = cva({
    base: [
        "tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors",
        "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-primaryDim-50",
        "disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-primary-300",
    ],
    variants: {
        size: {
            sm: "tw-h-5 tw-w-9",
            md: "tw-h-6 tw-w-[42px]",
            lg: "tw-h-7 tw-w-12",
        },
    },
    defaultVariants: {
        size: "md",
    },
});
var SwitchLabel = function (_a) {
    var selected = _a.selected, color = _a.color, type = _a.type, children = _a.children;
    return type === "text" ? (React.createElement(Label, { className: cn("tw-font-semibold tw-transition-colors", {
            "tw-text-neutral-500": selected,
            "tw-text-neutral-200 tw-opacity-50": !selected,
        }) }, children)) : (React.createElement(MiniTag, { className: "tw-transition-colors", color: selected ? color : "grey", variant: selected ? "solid" : "default", size: "lg" }, children));
};
var Switch = React.forwardRef(function (_a, ref) {
    var className = _a.className, size = _a.size, left = _a.left, right = _a.right, _b = _a.labelType, labelType = _b === void 0 ? "text" : _b, onCheckedChange = _a.onCheckedChange, checked = _a.checked, defaultChecked = _a.defaultChecked, onValueChange = _a.onValueChange, defaultValue = _a.defaultValue, props = __rest(_a, ["className", "size", "left", "right", "labelType", "onCheckedChange", "checked", "defaultChecked", "onValueChange", "defaultValue"]);
    if (right && !left) {
        throw new Error("Switch: 'right' prop requires 'left' prop to be set.");
    }
    var _c = React.useState(Boolean(checked ||
        (defaultValue && defaultValue === (right === null || right === void 0 ? void 0 : right.value)) ||
        defaultChecked)), internalChecked = _c[0], setInternalChecked = _c[1];
    var handleSwitchChange = function (isChecked) {
        setInternalChecked(isChecked);
        // If no value is provided, acts the same as onCheckedChange
        onValueChange === null || onValueChange === void 0 ? void 0 : onValueChange(isChecked ? (right === null || right === void 0 ? void 0 : right.value) || true : Boolean(left === null || left === void 0 ? void 0 : left.value));
        onCheckedChange === null || onCheckedChange === void 0 ? void 0 : onCheckedChange(isChecked);
    };
    return (React.createElement("div", { className: "tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-text-sm" },
        left && (React.createElement(SwitchLabel, { type: labelType, selected: 
            // If no right prop undefined and labelType is text, always show solid label
            right ? !internalChecked : labelType === "text" || internalChecked, color: "primary" }, left.label)),
        React.createElement(SwitchPrimitives.Root, __assign({ className: cn(switchVariants({ size: size }), {
                "data-[state=checked]:tw-bg-primary-300 data-[state=unchecked]:tw-bg-primary-300": right,
                "data-[state=unchecked]:tw-bg-primaryDim-50": !right,
            }, className), onCheckedChange: handleSwitchChange, checked: checked, defaultChecked: internalChecked }, props, { ref: ref }),
            React.createElement(SwitchPrimitives.Thumb, { className: cn("tw-pointer-events-none tw-block tw-rounded-full tw-bg-neutral-25 tw-shadow-lg tw-ring-0 tw-transition-transform data-[state=unchecked]:tw-translate-x-1", {
                    "tw-h-3.5 tw-w-3.5 data-[state=checked]:tw-translate-x-[1.125rem]": size === "sm",
                    "tw-h-4 tw-w-4 data-[state=checked]:tw-translate-x-[1.375rem]": size === "md",
                    "tw-h-5 tw-w-5 data-[state=checked]:tw-translate-x-6": !size || size === "lg",
                }) })),
        right && (React.createElement(SwitchLabel, { type: labelType, selected: Boolean(internalChecked), color: "secondary" }, right.label))));
});
Switch.displayName = SwitchPrimitives.Root.displayName;
export { Switch };

import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";
import { useMemo } from "react";

import {
  listThemisRecordFields,
  updateField,
} from "../gen/axios/fieldsController";
import type {
  RecordType,
  UpdateFieldMutationRequest,
  UpdateFieldMutationResponse,
} from "../gen/models";

export const COLUMNS_QUERY_KEY = "columns";

export function useColumns({
  companyId,
  recordClass,
}: {
  companyId: number;
  recordClass: RecordType;
}) {
  const query = useQuery({
    queryKey: [COLUMNS_QUERY_KEY, recordClass, companyId],
    queryFn: () => listThemisRecordFields(companyId, recordClass),
    enabled: !!companyId && !!recordClass,
  });

  const sortedColumns = useMemo(() => {
    return _.sortBy(query.data?.data, (column) => column.position);
  }, [query.data]);

  return {
    ...query,
    sortedColumns,
  };
}

/**
 * @todo precise type when update many question is resolved
 */
type QueryFnData = UpdateFieldMutationResponse[];

export function useUpdateColumns({
  companyId,
  recordClass,
  options,
}: {
  companyId: number;
  recordClass: RecordType;
  options?: Partial<
    UseMutationOptions<
      QueryFnData,
      unknown,
      Record<number, UpdateFieldMutationRequest>
    >
  >;
}) {
  /**
   * @todo Change batched updates to a single update many call?
   */
  return useMutation({
    mutationFn: (fieldUpdates: Record<number, UpdateFieldMutationRequest>) => {
      const updates = Object.entries(fieldUpdates).map(([id, data]) =>
        updateField(companyId, recordClass, Number(id), data),
      );

      return Promise.all(updates);
    },
    mutationKey: [COLUMNS_QUERY_KEY],
    ...options,
  });
}

import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

import { MAX_CONTROL_COUNT } from "@/components/detailView/constants";
import { Button, MiniTag } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

interface Props {
  recordVersionID: number;
  isLocked: boolean;
}

function ViewActionPlansSummary({ recordVersionID, isLocked }: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // Variables
  const listRecordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const { workspaceID } = mainStore.context;
  const recordVersion = listRecordVersion || mainStore.recordVersions.current;
  const actionPlans = recordVersion?.record?.action_plans || [];

  return (
    <section className="section-approval">
      <div className="section-header">
        <h3>Action Plans</h3>
        <Link
          to={`/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/action-plans`}
        >
          <Button theme="tertiary" size="sm" label="Edit Action Plans" />
        </Link>
      </div>
      <div className="section-content">
        {!actionPlans?.length && (
          <div className="table-row empty">
            {isLocked
              ? "No Action Plans."
              : "No Action Plans added yet. Click “Action Plans” on top / “Edit Action Plans” button to start with."}
          </div>
        )}

        {actionPlans?.slice(0, MAX_CONTROL_COUNT)?.map((item) => (
          <div key={item.global_id} className="table-row">
            <div className="dv-rr-controls-row">
              <MiniTag label={item.global_id} theme="navy" />
              <span>{item.name}</span>
            </div>
          </div>
        ))}

        {actionPlans?.length > MAX_CONTROL_COUNT && (
          <div className="table-row">
            <div className="dv-rr-controls-row">
              {`+ ${actionPlans.length - MAX_CONTROL_COUNT} more action plans`}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default observer(ViewActionPlansSummary);

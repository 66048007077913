import React from "react";
import { Route, Switch } from "react-router-dom";

import ViewVendor from "@/components/table/vendor-due-diligence/ViewVendor";

export const ROUTES = {
  BASE_CONTRACTS_PATH:
    "/workspaces/:workspace_id/modules/vendor-due-diligence/:vendor_id/contracts",
};

function VendorContractsRouter() {
  return (
    <Switch>
      <Route exact path={ROUTES.BASE_CONTRACTS_PATH}>
        <ViewVendor defaultScreen="Contracts" />
      </Route>
    </Switch>
  );
}

export default VendorContractsRouter;

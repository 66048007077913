import type {
  AuthError,
  Configuration,
  PublicClientApplication,
} from "@azure/msal-browser";

import { SHAREPOINT_CLIENT_ID } from "@/components/constants";
import type {
  PickData,
  SPItem,
} from "@/components/table/shared/cell-type/file-select/SharePointFilePicker/types";

/**
 * Combines an arbitrary set of paths ensuring and normalizes the slashes
 *
 * @param paths 0 to n path parts to combine
 */
function combine(...paths: (string | null | undefined)[]): string {
  return paths
    .filter((path) => typeof path === "string" && path !== null)
    .map((path) => path!.replace(/^[\\|/]/, "").replace(/[\\|/]$/, ""))
    .join("/")
    .replace(/\\/g, "/");
}

export function generateRandomHex(size: number) {
  return [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join("");
}

export function msalConfig(tenantID: string): Configuration {
  return {
    auth: {
      authority: `https://login.microsoftonline.com/${tenantID}/`,
      clientId: SHAREPOINT_CLIENT_ID,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "localStorage",
    },
  };
}

export function filePickerOptions(channelID: string) {
  return {
    sdk: "8.0",
    entry: {
      sharePoint: {},
    },
    // Applications must pass this empty `authentication` option
    // in order to obtain details item data from the picker,
    // or when embedding the picker in an iframe.
    authentication: {},
    messaging: {
      origin: window.location.origin,
      // Always use a unique id for the channel when hosting the picker.
      channelId: channelID,
    },
  };
}

export function establishMessaging({
  sharePointWindow,
  msalInstance,
  channelID,
  onPickFile,
  onPickerClose,
}: {
  sharePointWindow: Window;
  msalInstance: PublicClientApplication;
  channelID: string;
  onPickFile: (item: SPItem) => void;
  onPickerClose: () => void;
}) {
  let port: MessagePort;

  function initializeMessageListener(event: MessageEvent): void {
    if (event.source && event.source === sharePointWindow) {
      const message = event.data;

      if (message.type === "initialize" && message.channelId === channelID) {
        // grab the port from the event
        [port] = event.ports;

        // add an event listener to the port (example implementation is in the next section)
        port.addEventListener("message", channelMessageListener);

        // start ("open") the port
        port.start();

        // tell the picker to activate
        port.postMessage({
          type: "activate",
        });
      }
    }
  }

  window.addEventListener("message", initializeMessageListener);

  async function channelMessageListener(message: MessageEvent): Promise<void> {
    const payload = message.data;

    switch (payload.type) {
      case "notification": {
        const notification = payload.data;

        if (notification.notification === "page-loaded") {
          // here we know that the picker page is loaded and ready for user interaction
        }

        break;
      }
      case "command": {
        // all commands must be acknowledged
        port.postMessage({
          type: "acknowledge",
          id: message.data.id,
        });

        // this is the actual command specific data from the message
        const command = payload.data;

        // command.command is the string name of the command
        switch (command.command) {
          case "authenticate":
            // the first command to handle is authenticate. This command will be issued any time the picker requires a token
            // 'getToken' represents a method that can take a command and return a valid auth token for the requested resource
            try {
              const token = await getToken(command.resource, msalInstance);

              if (!token) {
                throw new Error("Unable to obtain a token.");
              }

              // we report a result for the authentication via the previously established port
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "token",
                  token,
                },
              });
            } catch (error) {
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "error",
                  error: {
                    code: "unableToObtainToken",
                    message: (error as AuthError).message,
                  },
                },
              });
            }
            break;

          case "close":
            // in the base of popup this is triggered by a user request to close the window
            onPickerClose();
            break;

          case "pick":
            try {
              // let the picker know that the pick command was handled (required)
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "success",
                },
              });

              onPickFile((command as PickData).items[0]);
            } catch (error) {
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "error",
                  error: {
                    code: "unusableItem",
                    message: (error as AuthError).message,
                  },
                },
              });
            }
            break;

          default:
            // Always send a reply, if if that reply is that the command is not supported.
            port.postMessage({
              type: "result",
              id: message.data.id,
              data: {
                result: "error",
                error: {
                  code: "unsupportedCommand",
                  message: command.command,
                },
              },
            });
            break;
        }

        break;
      }
      default:
    }
  }
}

export async function getToken(
  baseURL: string,
  msalInstance: PublicClientApplication,
): Promise<string> {
  let accessToken = "";
  await msalInstance.initialize();

  const authParams = {
    scopes: [`${combine(baseURL, ".default")}`],
  };

  try {
    const resp = await msalInstance.acquireTokenSilent(authParams);

    // eslint-disable-next-line prefer-destructuring
    accessToken = resp.accessToken;
  } catch (error) {
    const resp = await msalInstance.loginPopup(authParams);

    msalInstance.setActiveAccount(resp.account);

    if (resp.idToken) {
      const resp2 = await msalInstance.acquireTokenSilent(authParams!);

      // eslint-disable-next-line prefer-destructuring
      accessToken = resp2.accessToken;
    } else {
      throw error;
    }
  }

  return accessToken;
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ReadAllNotificationsMutationRequest,
  ReadAllNotificationsMutationResponse,
} from "../../models/ReadAllNotifications";

/**
 * @description Mark all notifications as read
 * @summary Mark all notifications as read
 * @link /notifications/read_all
 */
export async function readAllNotifications(
  data: ReadAllNotificationsMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ReadAllNotificationsMutationResponse>["data"]> {
  const res = await client<
    ReadAllNotificationsMutationResponse,
    ReadAllNotificationsMutationRequest
  >({
    method: "put",
    url: `/notifications/read_all`,
    data,
    ...options,
  });
  return res.data;
}

import type { ValueFormatterParams } from "@ag-grid-community/core";
import type { CustomCellRendererProps } from "@ag-grid-community/react";
import { MiniTag } from "@themis/ui";
import React from "react";
import { generatePath } from "react-router-dom";

import type { RiskLevel } from "@/components/shared/risk-badge/risk-badge";
import RiskBadge from "@/components/shared/risk-badge/risk-badge";
import type { User } from "@/stores/types/user-types";

import { QUESTIONNAIRE_DETAILS_PATH } from "../..";

const statusOptions = [
  {
    label: "Not Started",
    value: "not_started",
    Component: ({ label }: { label: string }) => (
      <MiniTag color="blue">{label}</MiniTag>
    ),
  },
  {
    label: "Incomplete",
    value: "incomplete",
    Component: ({ label }: { label: string }) => (
      <MiniTag color="secondary">{label}</MiniTag>
    ),
  },
  {
    label: "Ready for Review",
    value: "ready_for_review",
    Component: ({ label }: { label: string }) => (
      <MiniTag color="yellow">{label}</MiniTag>
    ),
  },
  {
    label: "In Review",
    value: "in_review",
    Component: ({ label }: { label: string }) => (
      <MiniTag color="green">{label}</MiniTag>
    ),
  },
  {
    label: "Completed",
    value: "finalized",
    Component: ({ label }: { label: string }) => (
      <MiniTag color="primary">{label}</MiniTag>
    ),
  },
];

const visibilityOptions = [
  {
    label: "Internal",
    value: "internal",
    Component: ({ label }: { label: string }) => (
      <MiniTag color="primary">{label}</MiniTag>
    ),
  },
  {
    label: "Public",
    value: "public",
    Component: ({ label }: { label: string }) => (
      <MiniTag color="secondary">{label}</MiniTag>
    ),
  },
];

const columns = (users: User[] | [], workspaceId: string | number) => [
  {
    headerName: "Title",
    field: "name",
    editable: false,
    minWidth: 250,
    cellRenderer: "identifierCellRenderer",
    cellRendererParams: (props: CustomCellRendererProps) => {
      return {
        to: generatePath(QUESTIONNAIRE_DETAILS_PATH, {
          workspace_id: workspaceId,
          questionnaireId: props.data.id,
        }),
        ...props,
      };
    },
  },
  {
    headerName: "Status",
    field: "status",
    cellRenderer: "selectCell",
    cellRendererParams: (props: CustomCellRendererProps) => {
      return {
        items: statusOptions,
        selected: props.value,
        ...props,
      };
    },
    minWidth: 200,
  },
  {
    headerName: "Overall Score",
    field: "scoring.overall_risk_score",
    minWidth: 200,
    cellRenderer: (props: CustomCellRendererProps) => {
      const risk: RiskLevel = props.data.overallRiskRating?.text;
      const score = props.data.scoring?.overall_risk_score;
      return <RiskBadge riskLevel={risk} score={score} />;
    },
  },
  {
    headerName: "Inherent Risk",
    field: "scoring.inherent_risk_score",
    minWidth: 200,
    cellRenderer: (props: CustomCellRendererProps) => {
      const risk: RiskLevel = props.data.inherentRiskRating?.text;
      const score = props.data.scoring?.inherent_risk_score;
      return <RiskBadge riskLevel={risk} score={score} />;
    },
  },
  {
    headerName: "Control Risk",
    field: "scoring.control_risk_score",
    minWidth: 200,
    cellRenderer: (props: CustomCellRendererProps) => {
      const risk: RiskLevel = props.data.controlRiskRating?.text;
      const score = props.data.scoring?.control_risk_score;
      return <RiskBadge riskLevel={risk} score={score} />;
    },
  },
  {
    headerName: "Due Date",
    field: "due_date",
    cellRenderer: "datePickerCell",
    minWidth: 200,
  },
  {
    headerName: "Visibility",
    field: "is_internal",
    cellRenderer: "selectCell",
    cellRendererParams: (props: CustomCellRendererProps) => {
      return {
        items: visibilityOptions,
        value: props.value ? "internal" : "public",
      };
    },
    minWidth: 200,
  },
  {
    headerName: "Percentage Answered",
    field: "completion_percentage",
    cellRenderer: "textRenderCell",
    valueFormatter: (params: ValueFormatterParams) => {
      const scored_control_count =
        params.data.scoring?.scored_control_question_count || 0;
      const scored_inherent_count =
        params.data.scoring?.scored_inherent_question_count || 0;
      const control_count = params.data.control_questions_count || 0;
      const inherent_count = params.data.inherent_questions_count || 0;
      const percentage =
        ((scored_control_count + scored_inherent_count) /
          (control_count + inherent_count)) *
        100;

      return `${percentage}%`;
    },
    minWidth: 200,
  },
  {
    headerName: "# Inherent Questions",
    field: "inherent_questions_count",
    cellRenderer: "textRenderCell",
    minWidth: 200,
  },
  {
    headerName: "# Control Questions",
    field: "control_questions_count",
    cellRenderer: "textRenderCell",
    minWidth: 200,
  },
  {
    headerName: "Respondents",
    field: "responder_ids",
    minWidth: 200,
    cellRenderer: "usersCell",
    cellRendererParams: (props: CustomCellRendererProps) => {
      return {
        users,
        multiple: true,
        ...props,
      };
    },
    valueFormatter: ({ value }: ValueFormatterParams) => {
      return Array.isArray(value) ? value.map(String) : value;
    },
  },
  {
    headerName: "Reviewers",
    field: "reviewer_ids",
    minWidth: 200,
    cellRenderer: "usersCell",
    cellRendererParams: (props: CustomCellRendererProps) => {
      return {
        users,
        multiple: true,
        ...props,
      };
    },
    valueFormatter: ({ value }: ValueFormatterParams) => {
      return Array.isArray(value) ? value.map(String) : value;
    },
  },
];

export { columns };

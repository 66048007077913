import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AllContracts } from "@/features/accounts/pages/AllContracts";

import { AccountDetails } from "./AccountDetails";
import { Accounts } from "./Accounts";
import { ContactDetails } from "./ContactDetails";
import { ContractDetails } from "./ContractDetails";
import { NewChildAccountDetails } from "./NewChildAccountDetails";

export const ACCOUNT_ID_PARAM = "accountId";
export const CONTRACT_ID_PARAM = "contractId";
export const CONTACT_ID_PARAM = "contactId";
export const QUESTIONNAIRE_ID_PARAM = "questionnaireId";

export const ACCOUNT_ROUTES = {
  base: "/accounts",
  allContracts: "/all-contracts",
  details: "/:accountId",
  contactDetails: `/:${ACCOUNT_ID_PARAM}/contacts/:${CONTACT_ID_PARAM}`,
  contractDetails: `/:${ACCOUNT_ID_PARAM}/contracts/:${CONTRACT_ID_PARAM}`,
  newChildAccountDetails: `/:${ACCOUNT_ID_PARAM}/sub-accounts/new`,
  contractsList: `/:${ACCOUNT_ID_PARAM}/contracts`,
  contactsList: `/:${ACCOUNT_ID_PARAM}/contacts`,
} as const;

export const QUESTIONNAIRE_ROUTES = {
  base: "/modules/risk-assessment",
  review: `/questionnaires/:${QUESTIONNAIRE_ID_PARAM}/review`,
} as const;

export const ACCOUNTS_LIST_PATH =
  `/workspaces/:workspace_id${ACCOUNT_ROUTES.base}` as const;

export const QUESTIONNAIRES_LIST_PATH =
  `/workspaces/:workspace_id${QUESTIONNAIRE_ROUTES.base}` as const;

export const CONTACT_LIST_PATH =
  `${ACCOUNTS_LIST_PATH}${ACCOUNT_ROUTES.contactsList}` as const;

export const CONTRACT_LIST_PATH =
  `${ACCOUNTS_LIST_PATH}${ACCOUNT_ROUTES.contractsList}` as const;

export const ACCOUNT_DETAILS_PATH =
  `${ACCOUNTS_LIST_PATH}${ACCOUNT_ROUTES.details}` as const;

export const CONTRACT_DETAILS_PATH =
  `${ACCOUNTS_LIST_PATH}${ACCOUNT_ROUTES.contractDetails}` as const;

export const ALL_CONTRACTS_PATH =
  `${ACCOUNTS_LIST_PATH}${ACCOUNT_ROUTES.allContracts}` as const;

export const QUESTIONNAIRE_DETAILS_PATH =
  `${QUESTIONNAIRES_LIST_PATH}${QUESTIONNAIRE_ROUTES.review}` as const;

export function AccountsRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}${ACCOUNT_ROUTES.allContracts}`}>
        <AllContracts />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.contractDetails}`}>
        <ContractDetails />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.contactDetails}`}>
        <ContactDetails />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.newChildAccountDetails}`}>
        <NewChildAccountDetails />
      </Route>

      <Route path={`${path}${ACCOUNT_ROUTES.details}`}>
        <AccountDetails />
      </Route>

      <Route exact path={path}>
        <Accounts />
      </Route>
    </Switch>
  );
}

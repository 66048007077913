import { Popover, PopoverContent, PopoverTrigger } from "@themis/ui";
import React from "react";
import { PiCaretRightBold, PiProhibit } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import type { Task, Taskable } from "@/api";
import { taskableStatus } from "@/api";

export default function AssociatedRecordCell({ task }: { task: Task }) {
  const history = useHistory();

  const associatedRecords: Taskable[] = task.taskables;
  const hasNoAssociatedRecords = !associatedRecords?.length;

  async function handleRecordClick(recordDetailPath: string) {
    history.push(recordDetailPath);
  }

  function renderInaccessibleOrDeletedRecordText(status: string) {
    return (
      <span className="tw-flex tw-items-center tw-text-neutral-200">
        <PiProhibit className="tw-mr-1" />
        {status === taskableStatus.deleted ? "Record Deleted" : "No Access"}
      </span>
    );
  }

  function renderSingleAssociatedRecord(record: Taskable) {
    return (
      <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-px-2.5 tw-py-1 hover:tw-bg-neutral-50">
        {record.status === taskableStatus.active ? (
          <div
            className="tw-flex tw-w-full tw-items-center tw-justify-between"
            onClick={() =>
              record.target && handleRecordClick(record.target.path)
            }
          >
            <span className="tw-truncate">
              {associatedRecords[0].target?.name || "No Name"}
            </span>
            <PiCaretRightBold className="tw-min-w-3.5 " />
          </div>
        ) : (
          renderInaccessibleOrDeletedRecordText(record.status)
        )}
      </div>
    );
  }

  function renderMultipleAssociatedRecords(records: Taskable[]) {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-flex tw-cursor-pointer tw-items-center tw-gap-1 tw-truncate tw-px-2.5 tw-py-1 hover:tw-bg-neutral-50">
            {records.length} associated records
          </div>
        </PopoverTrigger>
        <PopoverContent>
          <div className="tw-w-72  tw-flex-col tw-space-y-0">
            <h4 className="tw-px-4 tw-py-3 tw-text-primaryDim-300">
              {records.length} associated records
            </h4>
            <div className="tw-cursor-default tw-font-medium tw-text-neutral-500">
              {records.map((record) =>
                record.status === taskableStatus.active ? (
                  <div
                    key={record.id}
                    className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 hover:tw-bg-primary-25"
                    onClick={() =>
                      record.target && handleRecordClick(record.target.path)
                    }
                  >
                    <span className="tw-truncate">
                      {record.target?.name || "No Name"}
                    </span>
                    <PiCaretRightBold className="tw-min-w-3.5 tw-text-neutral-500" />
                  </div>
                ) : (
                  <div
                    key={record.id}
                    className="tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-2 hover:tw-bg-primary-25"
                  >
                    {renderInaccessibleOrDeletedRecordText(record.status)}
                  </div>
                ),
              )}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <>
      {hasNoAssociatedRecords && (
        <span className="tw-font-semibold tw-text-neutral-200">N/A</span>
      )}
      {associatedRecords.length === 1 &&
        renderSingleAssociatedRecord(associatedRecords[0])}
      {associatedRecords.length > 1 &&
        renderMultipleAssociatedRecords(associatedRecords)}
    </>
  );
}

export const initialCreateValues = {
  enabled: true,
  starting_date: "",
  end_date: "",
  title: "",
  message: "",
  frequency: null,
  recipients: { department_ids: [], user_ids: [] },
  timePeriod: {
    numberOfOneTimeDays: [],
    numberOfDailyDays: null,
    numberOfWeeks: null,
    numberOfMonths: null,
    excludeWeekends: true,
    recurringWeekdays: [],
  },
};

export const frequencyOptions = [
  {
    value: "days",
    label: "Daily",
  },
  {
    value: "weeks",
    label: "Weekly",
  },
  {
    value: "months",
    label: "Monthly",
  },
  {
    value: "one_time",
    label: "One Time",
  },
];

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateAndFinalizeControlMappingsMutationRequest,
  CreateAndFinalizeControlMappingsMutationResponse,
} from "../../models/CreateAndFinalizeControlMappings";

/**
 * @description Create and Finalize a control mappings
 * @summary Create and Finalize a control mappings
 * @link /control_mappings/create_and_finalize
 */
export async function createAndFinalizeControlMappings(
  data: CreateAndFinalizeControlMappingsMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<CreateAndFinalizeControlMappingsMutationResponse>["data"]
> {
  const res = await client<
    CreateAndFinalizeControlMappingsMutationResponse,
    CreateAndFinalizeControlMappingsMutationRequest
  >({
    method: "post",
    url: `/control_mappings/create_and_finalize`,
    data,
    ...options,
  });
  return res.data;
}

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";

import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import { useSortingAndPagination } from "../../hooks/useSortingAndPagination";
import MenuActionsUsers from "../table/menu-actions/MenuActionsUsers";
import ListSettings from "../table/settings/ListSettings";
import ListTitleSettings from "../table/settings/ListTitleSettings";
import TableMenu from "../table/shared/tableMenu/TableMenu";
import Table from "../table/Table";
import CompanySettingsNavigation from "./CompanySettingsNavigation";

const headers = [
  {
    field_name: "email",
    title: "Email Address",
    disabled: true,
  },
  {
    field_name: "full_name",
    title: "Full Name",
  },
  {
    field_name: "department",
    title: "Department",
  },
];

function CompanyContacts() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [newRecord, setNewRecord] = useState(null);
  const { setSortBy, sortByDirection, setSortByDirection, sortByFieldName } =
    useSortingAndPagination({ store: mainStore.contacts });
  const [selectMode, setSelectMode] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  // Variables
  const { list: contacts } = mainStore.contacts;

  // Hooks
  useEffect(() => {
    setSortBy("email");
    setSortByDirection("ASC");
  }, []);

  // Functions
  function addNewUser() {
    if (newRecord) {
      mainStore.toast.setErrorText(
        "There is an error when adding a new contact. Please try again.",
      );
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
      setNewRecord({});
    }
  }

  function removeNewUser() {
    setNewRecord(null);
  }

  function resetMenuActions() {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedUserIDs();
  }

  // Rendering

  const renderHeaders = headers.map((header) => (
    <ListTitleSettings
      key={header.field_name}
      // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type '{ field_n... Remove this comment to see the full error message
      width={header.width}
      title={header.title}
      fieldName={header.field_name}
      // @ts-expect-error TS(2322) FIXME: Type '"ASC" | "DESC" | null' is not assignable to ... Remove this comment to see the full error message
      sortDirection={
        header.field_name && sortByFieldName === header.field_name
          ? sortByDirection
          : null
      }
      onClick={() => setSortBy(header.field_name)}
    />
  ));

  const renderSelectModeHeader = (
    <>
      {mainStore.dynamicTable.selectedUserIDs.length === 0 && (
        <div className="list-title-menu-actions">
          Click on empty checkboxes to select contacts
        </div>
      )}
      {mainStore.dynamicTable.selectedUserIDs.length !== 0 && (
        <div>
          <MenuActionsUsers resetMenuActions={resetMenuActions} />
        </div>
      )}
    </>
  );

  const renderRows = contacts.map((item) => (
    <ListSettings
      key={item.id}
      headers={headers}
      data={item}
      model="Contact"
      showCheckbox={showCheckbox}
      extraMargin
    />
  ));

  return (
    <Table>
      <div className="settings-wrap company-users-settings-container contact-settings-wrap">
        <CompanySettingsNavigation />
        <AddRecordHeader recordName="Contact" addRecord={addNewUser} />
        <div className="settings-content-wrap">
          <div className="settings-explainer">
            Who are contacts? <br />
            Contacts do NOT have login credentials to Themis. They can perform
            actions via email that will be recorded in Themis.
          </div>
          <div className="list-title-table">
            <ul>
              <div className="list-title-table-wrap">
                <div className="title-first-block table-column-reorder">
                  <TableMenu
                    setShowCheckbox={setShowCheckbox}
                    selectMode={selectMode}
                    setSelectMode={setSelectMode}
                    onlyIncludeOptions={["selectIndividual"]}
                  />
                </div>
                {selectMode ? renderSelectModeHeader : renderHeaders}
                <span className="stretch-cell" />
              </div>
              <div className="list-title-table-block" />
            </ul>
          </div>

          {newRecord && (
            <ListSettings
              key="new_record"
              headers={headers}
              data={newRecord}
              model="Contact"
              isNewRecord
              removeNewEntry={removeNewUser}
              extraMargin
            />
          )}

          {renderRows}
          <div
            className="tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
            onClick={addNewUser}
            data-testid="new-user-button"
          >
            <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
            <div className="tw-neutral-300 tw-text-sm">Add Contact...</div>
          </div>
        </div>
      </div>
    </Table>
  );
}

export default observer(CompanyContacts);

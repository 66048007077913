import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Table = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 22C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5ZM8 13H4V19C4 19.5523 4.44772 20 5 20H8V13ZM14 13H10V20H14V13ZM20 13H16V20H19C19.5523 20 20 19.5523 20 19V13ZM8 4H5C4.44772 4 4 4.44772 4 5V11H8V4ZM14 4H10V11H14V4ZM19 4H16V11H20V5C20 4.44772 19.5523 4 19 4Z"
      fill="#353549"
    />
  </svg>
);

Table.displayName = "TableIcon";

export default Table;

import { observer } from "mobx-react";
import React from "react";

import type { RecordVersion } from "@/api";
import DeleteConfirmationDialog from "@/components/table/settings/DeleteConfirmationDialog";
import { useMainStore } from "@/contexts/Store";

const KRIValuesContextMenu = ({
  recordVersion,
}: {
  recordVersion: RecordVersion;
}) => {
  const mainStore = useMainStore();

  const onDeleteNo = () => {};

  const onDeleteYes = () => {
    mainStore.keyRiskIndicators.deleteKRIValue(recordVersion.id);
  };
  return (
    <div className="list-points" data-testid="context-menu">
      <DeleteConfirmationDialog
        showSubtext={false}
        model="Key Risk Indicator Value"
        title={`${recordVersion.global_id}`}
        onYes={onDeleteYes}
        onNo={onDeleteNo}
      />
    </div>
  );
};

export default observer(KRIValuesContextMenu);

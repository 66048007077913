import { MiniTag } from "@themis/ui";
import type { MiniTagProps } from "packages/ui/src/components/MiniTag/MiniTag";
import React from "react";

export type RiskLevel =
  | "Low"
  | "Medium"
  | "High"
  | "Improvements Needed"
  | "Strong";

const riskColors: Record<RiskLevel, MiniTagProps["color"]> = {
  Low: "green",
  Strong: "green",
  Medium: "yellow",
  High: "red",
  "Improvements Needed": "red",
};

const RiskBadge = ({
  riskLevel,
  score,
}: {
  riskLevel: RiskLevel | undefined;
  score: number | undefined;
}) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-1 tw-p-2">
      {riskLevel && score !== undefined && (
        <MiniTag className="tw-h-min" color={riskColors[riskLevel]}>
          {riskLevel}
        </MiniTag>
      )}
      <p className="tw-font-medium tw-text-neutral-300">
        {score !== undefined ? score : "N/A"}
      </p>
    </div>
  );
};

export default RiskBadge;

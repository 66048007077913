import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UnlinkFindingRecordMutationResponse,
  UnlinkFindingRecordPathParams,
} from "../../models/UnlinkFindingRecord";

/**
 * @description This endpoint allows the user to unlink a record from a finding.Once unlinked, the record will no longer be associated with the specified finding.The user must have the necessary permissions to perform this action.
 * @summary Unlink record from finding
 * @link /findings/:finding_id/records/:record_id/link
 */
export async function unlinkFindingRecord(
  findingId: UnlinkFindingRecordPathParams["finding_id"],
  recordId: UnlinkFindingRecordPathParams["record_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UnlinkFindingRecordMutationResponse>["data"]> {
  const res = await client<UnlinkFindingRecordMutationResponse>({
    method: "delete",
    url: `/findings/${findingId}/records/${recordId}/link`,
    ...options,
  });
  return res.data;
}

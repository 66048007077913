import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import { useDrag } from "react-dnd";

import { useMainStore } from "@/contexts/Store";

import draggableBlueIcon from "../../../../images/table-image/icon/draggable-blue-icon.svg";
import draggableBlueLightIcon from "../../../../images/table-image/icon/draggable-blue-light-icon.svg";
import homeIcon from "../../../../images/table-image/icon/home-icon.svg";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  question: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checklistQuestions?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleAddQuestion?: (...args: any[]) => any;
}

function ChecklistDefaultQuestion({
  question,
  handleAddQuestion,
  checklistQuestions,
}: Props) {
  const mainStore = useMainStore();

  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  // D&D
  const funcRef = useRef();
  // @ts-expect-error TS(2322) FIXME: Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
  funcRef.current = handleAddQuestion;
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "question",
    item: question.name,
    canDrag: isCurrentWorkspaceActive,
    end: (item, monitor) => {
      if (item && monitor.getDropResult()) {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        funcRef.current(question);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const checklistQuestionsName = checklistQuestions.find(
    (checklist) => checklist.name === question.name,
  );
  const draggingClasses = classNames(`default-question ${question.category}`, {
    dragging: isDragging,
    "dragging-active-name": checklistQuestionsName,
  });

  return (
    <div
      ref={drag}
      data-testid="checklist-default-question"
      className={draggingClasses}
    >
      <img
        className="draggable-icon"
        src={
          checklistQuestionsName ? draggableBlueLightIcon : draggableBlueIcon
        }
        alt="draggable-icon"
      />
      <span>{question.name}</span>
      {checklistQuestionsName && (
        <img className="home-icon" src={homeIcon} alt="home-icon" />
      )}
    </div>
  );
}

ChecklistDefaultQuestion.defaultProps = {
  handleAddQuestion: () => {},
};

export default observer(ChecklistDefaultQuestion);

import classNames from "classnames";
import type {
  MouseEventHandler,
  MutableRefObject,
  ReactEventHandler,
  ReactNode,
  Ref,
} from "react";
import React from "react";
import PrismaZoom from "react-prismazoom";

const PRISMA_ZOOM_ANIMATION_DURATION = 0.4;

interface Props {
  commentsMode?: boolean;
  fileLoading?: boolean;
  fileRef?: Ref<HTMLImageElement>;
  handleFileClick?: MouseEventHandler;
  handleFileLoaded?: ReactEventHandler<HTMLImageElement>;
  handleMouseDownPosition?: (x: number, y: number) => void;
  isDragging?: boolean;
  renderCommentsOverlay?: ReactNode;
  renderTempCircleOverlay?: ReactNode;
  setZoomLevel?: (zoom: number) => void;
  url: string;
  zoomComponentRef?: MutableRefObject<null>;
}

function CreativeViewImage({
  zoomComponentRef,
  isDragging,
  setZoomLevel,
  url,
  fileRef,
  handleFileLoaded,
  commentsMode,
  handleMouseDownPosition,
  handleFileClick,
  fileLoading,
  renderCommentsOverlay,
  renderTempCircleOverlay,
}: Props) {
  return (
    <PrismaZoom
      ref={zoomComponentRef}
      animDuration={PRISMA_ZOOM_ANIMATION_DURATION}
      scrollVelocity={2}
      className={classNames("prisma-container", {
        "is-comment-dragging": isDragging,
      })}
      onZoomChange={setZoomLevel}
    >
      <img
        key={url}
        src={url}
        ref={fileRef}
        onLoad={handleFileLoaded}
        alt="file"
        className={classNames("main-file", {
          "commments-mode": commentsMode,
        })}
        onMouseDown={(event) =>
          handleMouseDownPosition &&
          handleMouseDownPosition(event.clientX, event.clientY)
        }
        onMouseUp={handleFileClick}
        data-testid="cv-image"
      />
      {commentsMode && !fileLoading && renderCommentsOverlay}
      {renderTempCircleOverlay}
    </PrismaZoom>
  );
}

export default CreativeViewImage;

import React from "react";
import {
  generatePath,
  Link,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { MODULE_ASSESSMENT_ROUTES } from "../../pages";

export function ModuleAssessmentIdLinkCell({
  moduleAssessmentId,
}: {
  moduleAssessmentId: number;
}) {
  const { url } = useRouteMatch();
  const location = useLocation();

  return (
    <Link
      className="tw-w-full tw-text-inherit hover:tw-underline"
      to={{
        pathname: generatePath(
          generatePath(`${url}${MODULE_ASSESSMENT_ROUTES.details}`, {
            moduleAssessmentId,
          }),
        ),
        state: { from: location.pathname },
      }}
    >
      {moduleAssessmentId}
    </Link>
  );
}

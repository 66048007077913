import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";

import { graphColors } from "@/components/constants";
import { Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import { titleForColor } from "@/stores/helpers/TitleForColorSchemeHelpers";

import { mapHeader } from "../common/MetricsPage/helpers";
import { lineChartScaleOptions } from "./chartHelpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface Props {
  chartDataLabels: Array<string>;
  xAxisLabels: Array<string>;
  chartData: Record<string, Array<number>>;
  chartID: string;
  labelColors?: string[];
  yAxesLabels?: string[];
}

const RecordsByMonthLineChart = ({
  chartDataLabels,
  xAxisLabels,
  chartData,
  chartID,
  yAxesLabels,
  labelColors,
}: Props) => {
  const mainStore = useMainStore();

  const isEmpty = chartDataLabels.length === 0;
  const { colorSchemes } = mainStore.reports;

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    spanGaps: true,
    scales: lineChartScaleOptions(yAxesLabels),
  };

  const data = useMemo(() => {
    const renderDataSets = chartDataLabels.map((label, index) => ({
      label:
        mapHeader(
          mainStore.fieldOptions.all,
          mainStore.reports.imSourceGroupName,
          label,
        ) || "n/a",
      data: chartData[label],
      backgroundColor:
        labelColors?.[index] ||
        colorSchemes?.find(
          (colorScheme) =>
            titleForColor(colorScheme.title) === titleForColor(label),
        )?.text_color ||
        graphColors[index],
      borderColor:
        labelColors?.[index] ||
        colorSchemes?.find(
          (colorScheme) =>
            titleForColor(colorScheme.title) === titleForColor(label),
        )?.text_color ||
        graphColors[index],
      pointRadius: 0,
      yAxisID: yAxesLabels ? `y${index > 0 ? index : ""}` : undefined,
    }));

    return {
      datasets: renderDataSets,
      labels: xAxisLabels,
    };
  }, [chartDataLabels, xAxisLabels, chartData, colorSchemes]);

  return (
    <div>
      <div className="metrics-line-chart">
        {isEmpty ? (
          <div className="no-chart-data">
            <div>
              <Typography
                size="sm"
                color="generalMidnightDark"
                label="No Data Yet"
                weight="semiBold"
              />
            </div>
          </div>
        ) : (
          <div>
            <Line id={chartID} options={options} data={data} />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(RecordsByMonthLineChart);

import React, { useState } from "react";

import Button from "@/components/form/Button";
import { useMainStore } from "@/contexts/Store";

import Header from "../components/Header";

function EmailVerificationExpired() {
  // Import MobX stores
  const mainStore = useMainStore();

  const [isRequestSent, setIsRequestSent] = useState(false);

  function handleResend() {
    mainStore.users.resend_email_confirmation();
    setIsRequestSent(true);
  }

  return (
    <div className="check-in">
      <Header />
      <div className="check-in-wrap">
        <div className="email-verification">
          <h2>Request New verification link</h2>
          <p>
            Seems like your email verification link has expired, please contact
            your sender with the details below.
          </p>
          <div className="invite-expired">
            <p>
              Please contact your workspace administrator or request to a new
              verification sent
            </p>
            <Button
              title={
                isRequestSent ? "Request Sent" : "Request New verification link"
              }
              onClick={handleResend}
              enabled={!isRequestSent}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationExpired;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import { workspaceIcons } from "@/components/helpers/workspaceIcons";
import { useMainStore } from "@/contexts/Store";
import { CompanyNameAutocompleteInput } from "@/features/company";

interface Props {
  iconID?: number | null;
  setIconID?: (iconID: number | null) => void;
  setWorkspaceStep?: (step: number) => void;
  workspaceIconLogo?: React.ReactNode;
  setLogo?: (logo: React.ReactNode[]) => void;
}

const SelectWorkspaceIcon: React.FC<Props> = ({
  workspaceIconLogo,
  iconID,
  setIconID,
  setWorkspaceStep,
  setLogo,
}) => {
  const mainStore = useMainStore();

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
    if (mainStore.clearbit.currentLogo?.config?.url) {
      // Fallback Icon
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setIconID(1);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setLogo(mainStore.clearbit.currentLogo?.config);
    }
  }, [mainStore.clearbit.currentLogo, setIconID]);

  const handleAfterSelect = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'url' does not exist on type '{}'.
    if (mainStore.clearbit.currentLogo?.url) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setIconID(1);
    }
  };

  return (
    <>
      <div className="setting-workspace-progressbar">
        <div className="progressbar">
          <CircularProgressbar value={75} strokeWidth={15} />
        </div>
        <p>Select workspace icon</p>
      </div>

      <p className="setup-workspace-text">
        Select a workspace icon below.
        <br />
        You can always change it in the future.
      </p>
      <div className="workspace-big-logo">
        {workspaceIconLogo}

        <p>(Current selected icon)</p>
      </div>
      <div className="company-logo-container">
        <p className="setup-workspace-text">
          Or enter a company name below to search for their logo. The name you
          search for will not be saved, just the icon.
        </p>
        <CompanyNameAutocompleteInput handleAfterSelect={handleAfterSelect} />
      </div>
      <div className="workspace-logo-wrap">
        {workspaceIcons.map((icon) => {
          const iconClasses = classNames("workspace-logo", {
            active: icon.id === iconID,
          });
          return (
            <div
              key={icon.id}
              className={iconClasses}
              onClick={() => {
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                setIconID(icon.id);
                mainStore.clearbit.cleanup();
              }}
            >
              <img src={icon.url} alt={`workspace-logo${icon.id}`} />
            </div>
          );
        })}
      </div>
      <div className="workspace-buttons">
        {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        <button className="back" onClick={() => setWorkspaceStep(3)}>
          Back
        </button>
        {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        <button className="active" onClick={() => setWorkspaceStep(5)}>
          Continue
        </button>
      </div>
    </>
  );
};

export default observer(SelectWorkspaceIcon);

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import type { SubmitErrorHandler } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import * as CustomNotificationsAPI from "@/api/legacy/custom-notifications";
import { Flex, Typography } from "@/components/Elements";
import LoadingWrapper from "@/components/loading-wrapper";
import CompanyHeader from "@/components/settings/CompanyHeader";
import {
  frequencyOptions,
  initialCreateValues,
} from "@/components/settings/notificationsCenter/custom-notifications/data/contstans";
import {
  deserializeFormData,
  serializeFormData,
} from "@/components/settings/notificationsCenter/custom-notifications/data/helpers";
import type { CustomNotificationFormValues } from "@/components/settings/notificationsCenter/custom-notifications/data/types";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import { useMainStore } from "@/contexts/Store";
import NotificationsDateSelect from "@/features/notifications/components/date-select";
import { NotificationDetailsHeader } from "@/features/notifications/components/header";
import MessageField from "@/features/notifications/components/message-field";
import { PSText } from "@/features/notifications/components/ps-text";
import NotificationRecipients from "@/features/notifications/components/recipients";
import SelectField from "@/features/notifications/components/select-field";
import { TimePeriodSelectField } from "@/features/notifications/components/time-period-select";
import type { DateNotificationFormValues } from "@/features/notifications/pages/single/date/types";
import type { ModuleNotificationParams } from "@/features/notifications/types/types";
import { useLoadableData } from "@/hooks/useLoadableData";

function CustomNotificationsSinglePage() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();
  const { custom_notification_id } = useParams<ModuleNotificationParams>();
  const customNotificationID = Number(custom_notification_id);
  const { watch, control, handleSubmit, reset, formState, setError } =
    useForm<CustomNotificationFormValues>({
      // @ts-expect-error TS(2322) FIXME: Type '{ enabled: boolean; starting_date: string; t... Remove this comment to see the full error message
      defaultValues: initialCreateValues,
    });

  // Variables
  const hasCreateMatch = Boolean(
    useRouteMatch("/notifications/custom-notifications/create"),
  );
  const startingDate = watch("starting_date");
  const endDate = watch("end_date");
  const hasValidDateRange =
    Boolean(startingDate) && Boolean(endDate) && startingDate > endDate;

  // Hooks
  const customNotifications = useLoadableData({
    request: () =>
      CustomNotificationsAPI.getCustomNotifications(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        mainStore.context.workspaceID,
      ),
    enabled: !!mainStore.context.workspaceID,
    onError: () =>
      "There was an issue loading the notifications data. Please refresh to try again.",
  });

  // Effects
  useEffect(() => {
    if (!hasCreateMatch && customNotifications.data) {
      const matchingNotification = customNotifications.data?.find(
        (customNotification) => customNotification.id === customNotificationID,
      );

      if (matchingNotification) {
        reset(deserializeFormData(matchingNotification));
      }
    }
  }, [customNotifications.data]);

  function handleCreate(data: CustomNotificationFormValues) {
    return CustomNotificationsAPI.createCustomNotification(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      mainStore.context.workspaceID,
      serializeFormData(data),
    );
  }

  function handleUpdate(data: CustomNotificationFormValues) {
    return CustomNotificationsAPI.updateCustomNotification(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
      mainStore.context.workspaceID,
      customNotificationID,
      serializeFormData(data),
    );
  }

  // Functions
  const onSubmit = async (data: CustomNotificationFormValues) => {
    if (hasValidDateRange) {
      setError("end_date", {});
      mainStore.toast.setErrorText(
        "The end date must be greater than the start date!",
      );

      return;
    }

    try {
      const response = await (hasCreateMatch
        ? handleCreate(data)
        : handleUpdate(data));

      reset({}, { keepValues: true });

      mainStore.toast.setInfoText(
        `Custom Notification was successfully ${
          hasCreateMatch ? "created" : "updated"
        }.`,
      );

      if (hasCreateMatch) {
        history.push(
          `/notifications/custom-notifications/edit/${response.custom_notification_rule.id}`,
        );
      }
    } catch (error) {
      mainStore.toast.setErrorText(
        `An error occurred. Could not ${
          hasCreateMatch ? "create" : "update"
        } notification`,
      );
      window.console.log(error);
    }
  };

  const onInvalid: SubmitErrorHandler<DateNotificationFormValues> = () => {
    mainStore.toast.setErrorText(
      "There is something not right! Please check if all necessary fields have been filled properly!",
    );
  };

  return (
    <div className="dashboard-content">
      <form
        onSubmit={handleSubmit(onSubmit, onInvalid)}
        className="notification-details--form"
      >
        <CompanyHeader isDirty={formState.isDirty} />
        <div className="dashboard-content-wrap">
          <DashboardContentWrapper>
            <LoadingWrapper
              loadingLayout="small-table"
              errorText={customNotifications.errorText}
              loading={customNotifications.loading}
            >
              {customNotifications.data && (
                <Flex column className="notification-details">
                  <NotificationDetailsHeader control={control} name="enabled" />
                  <Flex
                    column
                    rowGap={12}
                    className="notification-details--box"
                  >
                    <Flex alignCenter columnGap={12}>
                      <Typography
                        label="Send a"
                        size="md"
                        color="generalMidnightDark"
                      />
                      <SelectField
                        control={control}
                        name="frequency"
                        placeholder="- Daily/Weekly/Monthly/One Time -"
                        rules={{ required: true }}
                        options={frequencyOptions}
                      />
                      <Typography
                        label="email"
                        size="md"
                        color="generalMidnightDark"
                      />
                    </Flex>
                    <Flex alignCenter columnGap={12}>
                      <Typography
                        label="("
                        size="md"
                        color="generalMidnightDark"
                      />
                      {watch("frequency") !== "one_time" && (
                        <>
                          <Typography
                            label="Every"
                            size="md"
                            color="generalMidnightDark"
                          />
                          <TimePeriodSelectField
                            control={control}
                            name="timePeriod"
                            frequencyFieldName="frequency"
                          />
                          <Typography
                            label="."
                            size="md"
                            color="generalMidnightDark"
                          />
                        </>
                      )}
                      <Typography
                        label="Starting on"
                        size="md"
                        color="generalMidnightDark"
                      />
                      <NotificationsDateSelect
                        control={control}
                        name="starting_date"
                        placeholder="– Start Date –"
                        rules={{ required: true }}
                      />
                      {watch("frequency") !== "one_time" && (
                        <>
                          <Typography
                            label="Ending on"
                            size="md"
                            color="generalMidnightDark"
                          />
                          <NotificationsDateSelect
                            control={control}
                            name="end_date"
                            placeholder="– End Date –"
                            hasClearButtonVisible
                          />
                        </>
                      )}
                      <Typography
                        label=")"
                        size="md"
                        color="generalMidnightDark"
                      />
                    </Flex>
                    <PSText control={control} />
                  </Flex>
                  <Flex className="notification-details--header">
                    <Typography
                      label="Email Context"
                      color="generalMidnightDark"
                      size="lg"
                      weight="semiBold"
                    />
                  </Flex>
                  <Flex
                    column
                    rowGap={12}
                    className="notification-details--box"
                  >
                    <MessageField
                      control={control}
                      name="title"
                      placeholder="– Email Title –"
                      rules={{ required: true }}
                    />
                    <MessageField
                      control={control}
                      name="message"
                      placeholder="– Customize your own message here –"
                      rules={{ required: true }}
                    />
                  </Flex>
                  <NotificationRecipients
                    name="recipients"
                    control={control}
                    isDisableColumn
                  />
                </Flex>
              )}
            </LoadingWrapper>
          </DashboardContentWrapper>
        </div>
      </form>
    </div>
  );
}

export default observer(CustomNotificationsSinglePage);

import "./empty-message.scss";

import classNames from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";

import { Typography } from "@/components/Elements";

type EmptyMessageProps = PropsWithChildren<{
  className?: string;
}>;

function EmptyMessage(props: EmptyMessageProps) {
  return (
    <div className={classNames("empty-message", props.className)}>
      <Typography label={props.children} color="extrasBlueGrayDarker" />
    </div>
  );
}

export default EmptyMessage;

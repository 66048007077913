import { useEffect, useRef } from "react";

import type { UserComment } from "@/stores/types/comment-types";

export function useScrollToSelectedCommentRef(privateComments: UserComment[]) {
  const selectedCommentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedCommentRef.current) {
      selectedCommentRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [privateComments]);

  return selectedCommentRef;
}

import { action, makeObservable, observable } from "mobx";

import type { RecordVersion } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class ComplaintsStore implements ModuleStore {
  mainStore: MainStore;

  PLACEHOLDER_SECTION_TAG_ID = 0;
  // Observable objects
  data = {};
  lastTab = "Active";
  // I think we need to get rid of page, because now its returning a json object "counts"
  page = 1;
  pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 }; // Pages should be {section_tag_id: page_number} eg {base: 1, 13: 1, 14: 2} if the third section is on the second page.

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      page: observable,
      lastTab: observable,
      setLastTab: action,
      setPage: action,
      setPages: action,
      setDefaultPages: action,
      clearPages: action,
    });

    this.mainStore = mainStore;
  }

  async index({
    tableName,
    tab = this.lastTab,
    sortParams,
    pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 },
    sectionTagId = null,
    fromUseSortingAndPagination = false,
    tableFilters = [],
  }: IndexParams) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_title: tableName,
        tab,
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        section_tag_id: sectionTagId,
      };
      const mergedPages = { ...this.pages, ...pages };
      const pages_string = JSON.stringify(mergedPages);

      // @ts-expect-error TS(2339) FIXME: Property 'pages' does not exist on type '{ table_t... Remove this comment to see the full error message
      params.pages = pages_string;

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/complaints`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.mainStore.fields.setList(response.data.fields);

      this.setPages(mergedPages);
      this.setLastTab(tab);

      if (!fromUseSortingAndPagination) {
        this.setData(response.data);
        return;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
      if (this.data?.record_versions) {
        this.setData({
          ...this.data,
          record_versions: [
            // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
            ...this.data.record_versions.filter(
              // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
              (item) => item.section_tag_id !== sectionTagId,
            ),
            ...response.data.record_versions.filter(
              // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
              (item) => item.section_tag_id === sectionTagId,
            ),
          ],
        });
      } else {
        this.setData(response.data);
      }
    } catch (error) {
      window.console.log(`Complaints#index error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'tableName' implicitly has an 'any' type... Remove this comment to see the full error message
  async create(tableName, sectionTagId = null) {
    if (!tableName) {
      window.console.warn(
        `Complaints#create => tableName(${tableName}) should be present!`,
      );
      return;
    }

    const data = { table_title: tableName, section_tag_id: sectionTagId };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/complaints`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"Complaints#create" error ${error}`);
      return null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/complaints/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Complaints#delete" error ${error}`);
    }
  }

  // POST /api/react/complaints/:complaint_id/reopen {format: "json"} (api_react_complaint_reopen)
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async reopen(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/complaints/${recordVersionID}/reopen`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"Complaints#reopen" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/complaints/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"Complaints#finalize" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionIDs' implicitly has an 'an... Remove this comment to see the full error message
  async bulkClose(recordVersionIDs) {
    const data = { record_version_ids: recordVersionIDs };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/complaints/bulk_close`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
      if (result.response.data.errors) {
        this.mainStore.toast.setErrorText("An unexpected error occured.");
      } else {
        this.mainStore.toast.setText("Successfully closed record(s).");
      }
    } catch (error) {
      window.console.log(`"Complaints#close" error ${error}`);
    }
  }

  async export_pdf(
    recordVersionID: number,
    checkboxCheckedDetails?: boolean,
    checkboxCheckedCommentsSummary?: boolean,
  ) {
    const params = {};
    if (checkboxCheckedDetails) {
      // @ts-expect-error TS(2339) FIXME: Property 'checkboxCheckedDetails' does not exist on type '{}'... Remove this comment to see the full error message
      params.details_report = checkboxCheckedDetails;
    }

    if (checkboxCheckedCommentsSummary) {
      // @ts-expect-error TS(2339) FIXME: Property 'checkboxCheckedCommentsSummary' does not exist on type '{}'... Remove this comment to see the full error message
      params.comments_summary_report = checkboxCheckedCommentsSummary;
    }

    const fullParams = Object.keys(params)
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.... Remove this comment to see the full error message
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");

    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/complaints/${recordVersionID}/export_pdf${
          fullParams ? `?${fullParams}` : ""
        }`,
        headers: this.mainStore.getHeaders(),
        responseType: "arraybuffer",
      });

      const blob = new Blob([new Uint8Array(result.data)], {
        type: "application/pdf",
      });
      const filename = result.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0]
        .replace(/"/g, "");
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename || "complaints_export_pdf.pdf";
      link.click();
    } catch (error) {
      window.console.log(`"Complaints#close" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async complete(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/complaints/${recordVersionID}/complete`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
      }
    } catch (error) {
      window.console.log(`"Complaints#complete" error ${error}`);
    }
  }

  async updateSection(recordVersionID: RecordVersion, sectionTagID: number) {
    const data = {
      section_tag_id: sectionTagID,
      identifier: "complaints",
    };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/complaints/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Complaints#update-section" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLastTab(value) {
    this.lastTab = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPages(value) {
    const merged_pages = { ...this.pages, ...value };
    this.pages = merged_pages;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTags' implicitly has an 'any' ty... Remove this comment to see the full error message
  setDefaultPages(sectionTags) {
    const new_pages = sectionTags?.reduce(
      // @ts-expect-error TS(7006) FIXME: Parameter 'object' implicitly has an 'any' type.
      (object, section) => ((object[section?.id] = 1), object),
      {},
    );
    const merged_pages = { ...this.pages, ...new_pages };
    this.pages = merged_pages;
  }

  clearPages() {
    this.pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 };
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
  setPage(page) {
    this.page = page;
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // Store Helpers

  cleanup() {
    this.setData({});
    this.setLastTab("Active");
    this.clearPages();
  }

  abort() {
    this.controller?.abort();
  }
}

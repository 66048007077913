import React from "react";

import figma from "@/images/table-image/icon/figma.webp";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Figma = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" fill="url(#figma-pattern)" />
    <defs>
      <pattern
        id="figma-pattern"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#figma-icon" transform="scale(0.000954198)" />
      </pattern>
      <image id="figma-icon" width="1048" height="1048" xlinkHref={figma} />
    </defs>
  </svg>
);

Figma.displayName = "FigmaIcon";

export default Figma;

import React from "react";

interface props {
  textColor?: string;
  bgColor?: string;
  children: React.ReactNode;
}

function SidebarTag({ textColor, bgColor, children }: props) {
  return (
    <div
      className={`tw-flex tw-h-4 tw-w-[43px] tw-items-center tw-justify-center tw-rounded tw-bg-${
        bgColor || "neutral-100"
      } tw-text-[8px] tw-font-bold tw-text-${textColor || "neutral-500"}
    `}
    >
      {children}
    </div>
  );
}

export default SidebarTag;

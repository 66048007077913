import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

import {
  copyGraph,
  copyTableData,
  copyTableImage,
  downloadGraph,
  downloadTableImage,
  downloadTableXlsx,
} from "../../../../sharedGraphs/chartHelpers";

const ExportDashboard = ({
  chartId,
  fileDownloadName,
  forTables,
}: {
  chartId: string;
  fileDownloadName: string;
  forTables: boolean;
}) => {
  const mainStore = useMainStore();

  const [open, setOpen] = useState(false);
  const { workspaceID } = mainStore.context;

  const handleDownloadTableXlsx = async () => {
    setOpen(false);
    if (workspaceID) {
      downloadTableXlsx(mainStore, chartId, fileDownloadName, workspaceID);
    }
  };

  const handleDownloadTableImage = async () => {
    setOpen(false);
    downloadTableImage(mainStore, chartId, fileDownloadName);
  };

  const handleCopyTableImage = async () => {
    setOpen(false);
    copyTableImage(mainStore, chartId);
  };

  const handleCopyTableData = async () => {
    setOpen(false);
    copyTableData(mainStore, chartId);
  };

  const handleGraphDownload = async () => {
    setOpen(false);
    downloadGraph(mainStore, chartId, fileDownloadName);
  };

  const handleGraphCopy = async () => {
    setOpen(false);
    copyGraph(mainStore, chartId);
  };
  return (
    <Popup
      open={open}
      nested
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <button
          className={classNames("export-dashboard", { active: open })}
          data-testid="export-dashboard-button"
        >
          <Icon
            name="download"
            color={open ? "generalWhite" : "generalMidnightDark"}
          />
          Export
        </button>
      }
      position="bottom right"
    >
      {forTables && (
        <ul className="table-dropdown reports-dropdown-popup export-dashboard-file-container">
          <li
            className="dashboard-download"
            data-testid="download-table-xlsx"
            onClick={handleDownloadTableXlsx}
          >
            Download as XLSX
          </li>
          <li
            className="dashboard-download"
            data-testid="download-table-image"
            onClick={handleDownloadTableImage}
          >
            Download as JPEG
          </li>
          <li
            className="dashboard-copy"
            data-testid="copy-table-image"
            onClick={handleCopyTableImage}
          >
            Copy Table Image
          </li>
          <li
            className="dashboard-copy"
            data-testid="copy-table-data"
            onClick={handleCopyTableData}
          >
            Copy Table Data
          </li>
        </ul>
      )}
      {!forTables && (
        <ul className="table-dropdown reports-dropdown-popup export-dashboard-file-container">
          <li
            className="dashboard-download"
            onClick={handleGraphDownload}
            data-testid="download-png"
          >
            Download as JPEG
          </li>
          <li
            className="dashboard-copy"
            onClick={handleGraphCopy}
            data-testid="copy-png"
          >
            Copy Chart Image
          </li>
        </ul>
      )}
    </Popup>
  );
};

export default observer(ExportDashboard);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateAccountDocumentMutationRequest,
  CreateAccountDocumentMutationResponse,
  CreateAccountDocumentPathParams,
} from "../../models/CreateAccountDocument";

/**
 * @description Create account document
 * @summary Create account document
 * @link /accounts/:account_id/documents
 */
export async function createAccountDocument(
  accountId: CreateAccountDocumentPathParams["account_id"],
  data: CreateAccountDocumentMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateAccountDocumentMutationResponse>["data"]> {
  const res = await client<
    CreateAccountDocumentMutationResponse,
    CreateAccountDocumentMutationRequest
  >({
    method: "post",
    url: `/accounts/${accountId}/documents`,
    data,
    ...options,
  });
  return res.data;
}

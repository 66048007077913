import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Crown = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11.5C10.3432 11.5 9 12.8432 9 14.5C9 16.1568 10.3432 17.5 12 17.5C13.6568 17.5 15 16.1568 15 14.5C15 12.8432 13.6568 11.5 12 11.5ZM11 14.5C11 13.9477 11.4477 13.5 12 13.5C12.5523 13.5 13 13.9477 13 14.5C13 15.0523 12.5523 15.5 12 15.5C11.4477 15.5 11 15.0523 11 14.5Z"
      fill="#353549"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8137 3.41876C12.626 3.15597 12.3229 3 12 3C11.677 3 11.374 3.15597 11.1863 3.41876L6.68293 9.72343L2.44721 7.60557C2.10291 7.43342 1.69036 7.47418 1.3864 7.71038C1.08245 7.94658 0.941048 8.33627 1.02282 8.71243L3.52282 20.2124C3.62275 20.6721 4.02957 21 4.5 21H19.5C19.9704 21 20.3772 20.6721 20.4772 20.2124L22.9772 8.71243C23.0589 8.33627 22.9175 7.94658 22.6136 7.71038C22.3096 7.47418 21.8971 7.43342 21.5528 7.60557L17.3171 9.72343L12.8137 3.41876ZM7.81373 11.5812L12 5.72046L16.1863 11.5812C16.4723 11.9817 17.0071 12.1145 17.4472 11.8944L20.5791 10.3285L18.694 19H5.30596L3.42085 10.3285L6.55278 11.8944C6.99291 12.1145 7.52772 11.9817 7.81373 11.5812Z"
      fill="#353549"
    />
  </svg>
);

Crown.displayName = "CrownIcon";

export default Crown;

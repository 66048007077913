import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateIssueManagementRecordMutationRequest,
  CreateIssueManagementRecordMutationResponse,
  CreateIssueManagementRecordPathParams,
} from "../../models/CreateIssueManagementRecord";

/**
 * @description Create new record in Issue Management module by submitting ThemisUI form
 * @summary Create Issue
 * @link /workspaces/:workspace_id/issue_management_records
 */
export async function createIssueManagementRecord(
  workspaceId: CreateIssueManagementRecordPathParams["workspace_id"],
  data: CreateIssueManagementRecordMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<CreateIssueManagementRecordMutationResponse>["data"]
> {
  const res = await client<
    CreateIssueManagementRecordMutationResponse,
    CreateIssueManagementRecordMutationRequest
  >({
    method: "post",
    url: `/workspaces/${workspaceId}/issue_management_records`,
    data,
    ...options,
  });
  return res.data;
}

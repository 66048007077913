import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";

import { MAX_CONTROL_COUNT } from "@/components/detailView/constants";
import { Button } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";

interface Props {
  recordVersionID: number;
  isLocked: boolean;
}

function ViewRelatedRisksSummary({ recordVersionID, isLocked }: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // Variables
  const listRecordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const recordVersion = listRecordVersion || mainStore.recordVersions.current;
  const linkedRecords = recordVersion?.record?.owned_control?.linked_records;
  const mwList = mainStore.moduleWorkspaces.list;
  const { workspaceID } = mainStore.context;
  const filteredControls = linkedRecords?.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    (item) =>
      mwList.find((mw) => mw.id === item.module_workspace_id)?.themis_module
        .identifier === "risk_register",
  );

  return (
    <section className="section-approval">
      <div className="section-header">
        <h3>Related Risks</h3>
        <Link
          to={`/workspaces/${workspaceID}/modules/issue-management/${recordVersionID}/related-risks`}
        >
          <Button theme="tertiary" size="sm" label="Edit Related Risks" />
        </Link>
      </div>
      <div className="section-content">
        {!filteredControls?.length && (
          <div className="table-row empty">
            {isLocked
              ? "No Related Risks."
              : "No Related Risks added yet. Click “Related Risks” on top / “Edit Related Risks” button to start with."}
          </div>
        )}

        {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
        {filteredControls?.slice(0, MAX_CONTROL_COUNT)?.map((item) => (
          <div key={item.id} className="table-row">
            <div className="dv-rr-controls-row">
              <span>{item.meta?.name}</span>
            </div>
          </div>
        ))}

        {filteredControls?.length > MAX_CONTROL_COUNT && (
          <div className="table-row">
            <div className="dv-rr-controls-row">
              {`+ ${
                filteredControls.length - MAX_CONTROL_COUNT
              } more related risks`}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default observer(ViewRelatedRisksSummary);

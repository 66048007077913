import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const RadioUnchecked = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
      fill="#353549"
    />
  </svg>
);

RadioUnchecked.displayName = "RadioUncheckedIcon";

export default RadioUnchecked;

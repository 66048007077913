import type { PropsWithChildren } from "react";
import React, { useState } from "react";

import { ControlMappingTabPage } from "../pages/ControlMappingTabPage";

const CreateControlMappingSidebarContext = React.createContext<{
  recordVersionId: number | undefined;
  openSidebar: (
    recordId: number,
    tab: string,
    dataType?: string,
    linkedRecords?: number[],
  ) => void;
  closeSidebar: () => void;
}>(undefined!);

function ControlMappingsSidebarProvider(props: PropsWithChildren) {
  const [recordVersionId, setRecordVersionId] = useState<number | undefined>();
  const [tabName, setTabName] = useState<string>("create_new_control_mapping");
  const [moduleDataType, setModuleDataType] = useState<string>();
  const [linkedModuleRecords, setLinkedModuleRecords] = useState<number[]>();
  const value = {
    recordVersionId,
    openSidebar: (
      recordId: number,
      tab: string,
      dataType?: string,
      linkedRecords?: number[],
    ) => {
      setRecordVersionId(recordId);
      setTabName(tab);
      setModuleDataType(dataType);
      setLinkedModuleRecords(linkedRecords);
    },
    closeSidebar: () => {
      setRecordVersionId(undefined);
      setTabName("create_new_control_mapping");
      setModuleDataType(undefined);
    },
  };

  return (
    <CreateControlMappingSidebarContext.Provider value={value}>
      {props.children}
      {recordVersionId && (
        <ControlMappingTabPage
          recordVersionId={recordVersionId}
          tab={tabName}
          dataType={moduleDataType}
          linkedRecords={linkedModuleRecords}
        />
      )}
    </CreateControlMappingSidebarContext.Provider>
  );
}
const useControlMappingSidebar = () =>
  React.useContext(CreateControlMappingSidebarContext);

export { ControlMappingsSidebarProvider, useControlMappingSidebar };

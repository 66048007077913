var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as TabsPrimitive from "@radix-ui/react-tabs";
import React, { forwardRef } from "react";
import { cn } from "../../lib/utils";
var Tabs = forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(TabsPrimitive.Root, __assign({ ref: ref }, props, { className: cn("tw-border-0 tw-border-b tw-border-solid tw-border-neutral-100", className) })));
});
Tabs.displayName = TabsPrimitive.Root.displayName;
var TabsList = forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(TabsPrimitive.List, __assign({ ref: ref, className: cn("tw-box-border tw-inline-flex tw-h-10 tw-w-full tw-items-center tw-justify-start tw-gap-2 tw-rounded-md tw-bg-neutral-25 tw-p-1", className) }, props)));
});
TabsList.displayName = TabsPrimitive.List.displayName;
var TabsTrigger = forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(TabsPrimitive.Trigger, __assign({ ref: ref, className: cn("tw-inline-flex tw-h-8 tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-3xl tw-px-3 tw-transition-all", "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 ", "tw-font-sans tw-text-sm tw-font-semibold tw-text-primaryDim-300", "disabled:tw-pointer-events-none disabled:tw-opacity-50", "hover:tw-bg-primaryDim-25 data-[state=active]:tw-bg-primaryDim-300 data-[state=active]:tw-text-neutral-25", className) }, props)));
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;
var TabsContent = forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(TabsPrimitive.Content, __assign({ ref: ref, className: cn("tw-ring-offset-background focus-visible:tw-ring-ring tw-mt-2 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2", className) }, props)));
});
TabsContent.displayName = TabsPrimitive.Content.displayName;
export { Tabs, TabsList, TabsTrigger, TabsContent };

import "./table.scss";

import classNames from "classnames";
import React from "react";
import { PiPlusBold } from "react-icons/pi";

type TableProps = React.PropsWithChildren<{
  header: JSX.Element;
  actionRow?: {
    text: React.ReactNode;
    onClick: () => void;
    hasTableCellSpacer?: boolean;
  };
  className?: string;
  tableClassName?: string;
  fullWidth?: boolean;
}>;

const Table = (props: TableProps) => {
  return (
    <div
      className={classNames("generic-table-wrapper", props.className)}
      data-testid="generic-table"
    >
      <table
        className={classNames(
          "generic-table",
          props.tableClassName,
          props.fullWidth && "generic-table--full-width",
        )}
        cellPadding={0}
      >
        <thead>
          <tr>{props.header}</tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
      {props.actionRow && (
        <div
          className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
          onClick={props.actionRow.onClick}
          data-testid="add-new-record-button"
        >
          <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
          <div className="tw-neutral-300 tw-text-sm">
            {props.actionRow.text}...
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;

import "./questionnaire-form-question.scss";

import classNames from "classnames";
import React, { useMemo, useRef } from "react";

import { useAddTextareaHeightBehavior } from "@/components/detailView/helpers";
import { Flex } from "@/components/Elements";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import FileInput from "@/components/table/shared/FileInput";
import { useMainStore } from "@/contexts/Store";

import {
  Button,
  RadioButton,
  Typography,
} from "../../../../../../components/Elements";
import { QuestionType } from "../../../../types/questionnaire";
import type {
  Document,
  DocumentAnswer,
  FormQuestion,
  RadioFormQuestion,
  TextAnswer,
} from "../../../../types/questionnaire-form";
import {
  isDocumentAnswer,
  isDocumentNew,
  isRadioAnswer,
} from "../../../../types/questionnaire-form";
import UserAssignment from "../../../UserAssignment";
import QuestionnairePartnerReviewRespondents from "../../QuestionairePartnerReviewRespondents/QuestionnairePartnerReviewRespondents";
import QuestionnaireBuilderUserAssignment from "../../QuestionnaireBuilderUserAssignment/QuestionnaireBuilderUserAssignment";
import QuestionAttachment from "./QuestionnaireAttachment/QuestionAttachment";

interface Props {
  question: FormQuestion;
  order: string;
  onChange?: (question: FormQuestion) => void;
  depth?: number;
  hideResponderChange?: boolean;
  disableAnswering?: boolean;
  onChangeAssignees?: (ids: number[]) => void;
  onChangeResponders?: (
    assignableID: number,
    ids: number[],
    isQuestion: boolean,
  ) => void;
  cwOnlyUserIDs?: number[];
  isInternalQuestionnaire?: boolean;
  groupResponders?: number[];
  showResponderList?: boolean;
  isExternalId?: boolean;
}

function QuestionnaireFormQuestion({
  question,
  order,
  onChange,
  depth = 0,
  disableAnswering = false,
  onChangeAssignees,
  onChangeResponders,
  cwOnlyUserIDs = [],
  isInternalQuestionnaire = false,
  groupResponders = [],
  hideResponderChange = true,
  showResponderList = false,
  isExternalId,
}: Props) {
  const mainStore = useMainStore();
  const { id: userID } = mainStore.users.user;
  const { isAdmin } = mainStore.context;
  const respondersForQuestionUserIds = groupResponders.concat(
    question.responders.map((i) => i.user_id),
  );
  const assignedUsers = mainStore.users.users.filter((user) =>
    respondersForQuestionUserIds.includes(user.id),
  );

  const explanationRef = useRef<HTMLTextAreaElement | null>(null);

  const onRadioChoiceSelected = (id: number): void => {
    if (disabledAnswerForQuestion) {
      return;
    }

    const radioQuestion = question as RadioFormQuestion;

    if (radioQuestion.answer.question_choice_ids?.includes(id)) {
      return;
    }

    onChange!({
      ...question,
      answer: {
        ...question.answer,
        question_choice_ids: [id],
      },
    });
  };

  const disableQuestionForUser = (): boolean => {
    if (!isInternalQuestionnaire) {
      return false;
    }

    if (isAdmin) {
      return false;
    }

    if (!userID) {
      return false;
    }

    if (groupResponders.includes(userID)) {
      return false;
    }

    const responentUserIDs = question.responders.map(
      (assignment) => assignment.user_id,
    );
    if (responentUserIDs.length === 0 && groupResponders.length === 0) {
      return false;
    }

    return !responentUserIDs.includes(userID);
  };

  const disabledAnswerForQuestion =
    disableAnswering || disableQuestionForUser();

  const onTextChanged = (text: string): void => {
    onChange!({
      ...question,
      answer: {
        ...question.answer,
        [question.input_type === QuestionType.TEXT ? "value" : "comment"]:
          text.trim(),
      },
    });
  };

  const onTriggerQuestionChanged = (changedTriggerQuestion: FormQuestion) => {
    onChange!({
      ...question,
      triggerQuestions: question.triggerQuestions.map((triggerQuestion) => {
        return triggerQuestion.id === changedTriggerQuestion.id
          ? changedTriggerQuestion
          : triggerQuestion;
      }),
    });
  };

  const onAttachmentAdded = (signedIds: string[], file: File) => {
    const answer = question.answer as DocumentAnswer;

    onChange!({
      ...question,
      answer: {
        ...(question.answer || {
          comment: "",
          additional_files: [],
        }),
        documents: [
          ...(answer && (answer.documents ?? [])),
          {
            file_name: file.name,
            externalFileId: signedIds[0],
          },
        ],
      },
    });
  };

  const onAttachmentDeleted = (documentToDelete: Document) => {
    const answer = question.answer as DocumentAnswer;
    const filteredDocuments = isDocumentNew(documentToDelete)
      ? answer.documents.filter((doc) => {
          return (
            !isDocumentNew(doc) ||
            doc.externalFileId !== documentToDelete.externalFileId
          );
        })
      : answer.documents.map((doc) => {
          return isDocumentNew(doc) || doc.id !== documentToDelete.id
            ? doc
            : {
                ...doc,
                _destroy: true,
              };
        });

    onChange!({
      ...question,
      answer: {
        ...answer,
        documents: filteredDocuments,
      },
    });
  };

  const activeTriggerQuestions = useMemo(() => {
    return question.triggerQuestions.filter((triggerQuestion) => {
      if (!question.answer) {
        return false;
      }

      return triggerQuestion.triggeredBy.some((triggerChoice) => {
        if (isRadioAnswer(question.answer)) {
          return question.answer.question_choice_ids.includes(triggerChoice.id);
        }

        return false;
      });
    });
  }, [question]);

  return (
    <>
      <div
        className={classNames("questionnaire-form-question", {
          "questionnaire-form-question_trigger": depth > 0,
        })}
        data-testid={`question-${question.id}`}
      >
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="questionnaire-form-question__order">{order}</div>
          {!isExternalId && (
            <div>
              <CommentsSlideMenu
                // eslint-disable-next-line react/no-unstable-nested-components
                SubHeader={() => (
                  <div className="section-commentary-subheader">
                    {question.text}
                  </div>
                )}
                taskableType="Question"
                recordID={question.id}
                uncompletedCommentsCount={question.uncompleted_comments_count}
                title="Summary"
                hideEmptyState
                isIconButton
              />
            </div>
          )}
        </div>
        <div className="questionnaire-form-question__content">
          <Flex
            justifySpaceBetween
            fullWidth
            className={classNames({
              "question-title-margin-with-margin": Boolean(onChangeResponders),
            })}
          >
            <Typography
              className="questionnaire-form-question__heading-text"
              label={question.text}
              color="generalMidnightDark"
              weight="bold"
              size="md"
            />

            {!hideResponderChange && onChangeResponders && (
              <UserAssignment
                assignedUsers={mainStore.users.allUsers.filter((user) =>
                  (question.responders || [])
                    .map((u) => u.user_id)
                    .includes(user.id),
                )}
                assigneeWord="Respondents"
              >
                <QuestionnaireBuilderUserAssignment
                  userIDsToInclude={cwOnlyUserIDs}
                  selectedIDs={(question.responders || []).map(
                    (a) => a.user_id,
                  )}
                  setSelectedIDs={async (ids: number[]) => {
                    await onChangeResponders(question.id, ids, true);
                  }}
                />
              </UserAssignment>
            )}
            {onChangeAssignees && (
              <UserAssignment
                assignedUsers={mainStore.users.allUsers.filter((user) =>
                  question.assignments.some((a) => a.user_id === user.id),
                )}
              >
                <QuestionnaireBuilderUserAssignment
                  selectedIDs={question.assignments.map((a) => a.user_id)}
                  setSelectedIDs={onChangeAssignees}
                />
              </UserAssignment>
            )}
          </Flex>
          <Typography
            className="questionnaire-form-question__heading-text"
            label={question.description}
            size="sm"
            color="extrasBlueGrayDarker"
          />
          {showResponderList && (
            <QuestionnairePartnerReviewRespondents
              assignedUsers={assignedUsers}
            />
          )}
          {[QuestionType.RADIO, QuestionType.YES_NO].includes(
            question.input_type,
          ) && (
            <div className="questionnaire-form-question__choices">
              {question.choices.map((choice) => (
                <div
                  key={choice.id}
                  className="questionnaire-form-question__choice-radio"
                  onClick={() => onRadioChoiceSelected(choice.id)}
                >
                  <RadioButton
                    value={choice.id}
                    id={String(choice.id)}
                    name={`question-${question.id}`}
                    checked={(
                      question as RadioFormQuestion
                    ).answer?.question_choice_ids?.includes(choice.id)}
                    disabled={disabledAnswerForQuestion}
                  />
                  <label htmlFor={String(choice.id)}>{choice.text}</label>
                </div>
              ))}
            </div>
          )}
          {question.input_type === QuestionType.DOCUMENTS && (
            <div className="questionnaire-form-question__attachment-header">
              <Typography label="Attachment:" color="generalDarkGray" />
              <FileInput
                trigger={
                  disabledAnswerForQuestion ? null : (
                    <Button label="Upload Attachment" />
                  )
                }
                handleAttachment={onAttachmentAdded}
              />
            </div>
          )}
          {isDocumentAnswer(question.answer) &&
            question.answer.documents.length > 0 && (
              <div className="questionnaire-form-question__attachments">
                {question.answer.documents
                  .filter((document) => {
                    return isDocumentNew(document) || !document._destroy;
                  })
                  .map((document) => (
                    <QuestionAttachment
                      key={
                        isDocumentNew(document)
                          ? document.externalFileId
                          : document.id
                      }
                      fileName={document.file_name}
                      onDelete={
                        disabledAnswerForQuestion
                          ? undefined
                          : () => onAttachmentDeleted(document)
                      }
                    />
                  ))}
              </div>
            )}
          <label
            htmlFor={`explanation-${order}`}
            className="questionnaire-form-question__explanation-label"
          >
            {question.input_type === QuestionType.TEXT
              ? "Response"
              : "Explanation"}
          </label>
          <textarea
            ref={explanationRef}
            id={`explanation-${order}`}
            className={classNames(
              "questionnaire-form-question__explanation-textarea",
              { "questionnaire-form-disabled": disabledAnswerForQuestion },
            )}
            // @ts-expect-error TS(2345) FIXME: Argument of type 'MutableRefObject<HTMLTextAreaEle... Remove this comment to see the full error message
            onInput={useAddTextareaHeightBehavior(explanationRef, 80)}
            defaultValue={
              (question.answer as TextAnswer)?.value ||
              question.answer?.comment ||
              ""
            }
            onBlur={(e) => onTextChanged(e.target.value)}
            disabled={disabledAnswerForQuestion}
          />
        </div>
      </div>

      {activeTriggerQuestions.map((triggerQuestion, i) => (
        <QuestionnaireFormQuestion
          key={triggerQuestion.id}
          question={triggerQuestion}
          depth={depth + 1}
          order={`${order}${
            depth % 2 === 0 ? String.fromCharCode(i + 65) : i + 1
          }`}
          onChange={onTriggerQuestionChanged}
          disableAnswering={disableAnswering}
          onChangeAssignees={onChangeAssignees}
          onChangeResponders={onChangeResponders}
          cwOnlyUserIDs={cwOnlyUserIDs}
          hideResponderChange={hideResponderChange}
          groupResponders={groupResponders}
        />
      ))}
    </>
  );
}

export default QuestionnaireFormQuestion;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cva } from "cva";
import React from "react";
import { cn } from "../../lib/utils";
var defaultStyles = "tw-box-border tw-flex tw-font-sans tw-shrink-0 tw-flex-col tw-items-center tw-justify-center tw-rounded-full tw-border-transparent tw-font-semibold tw-text-neutral-25";
function idToColor(id) {
    return [
        "yellow",
        "orange",
        "red",
        "pink",
        "fuchsia",
        "lavender",
        "purple",
        "navy",
        "blue",
        "turquoise",
    ][id % 10];
}
var avatarVariants = cva({
    base: [defaultStyles],
    variants: {
        size: {
            sm: "tw-h-5 tw-w-5 tw-text-[8px]",
            md: "tw-h-6 tw-w-6 tw-text-[10px]",
            lg: "tw-h-7 tw-w-7 tw-text-xs",
        },
        color: {
            yellow: "tw-bg-yellow-3",
            orange: "tw-bg-orange-3",
            red: "tw-bg-warning-400",
            pink: "tw-bg-warning-200",
            fuchsia: "tw-bg-fuchsia-3",
            lavender: "tw-bg-primary-200",
            purple: "tw-bg-purple-3",
            navy: "tw-bg-primaryDim-400",
            blue: "tw-bg-blue-3",
            turquoise: "tw-bg-secondary-200",
            gray: "tw-bg-generals-underline",
        },
        variant: {
            pending: "tw-border tw-border-dashed tw-border-neutral-300 tw-bg-transparent tw-text-neutral-300",
            basic: "tw-bg-generals-underline tw-text-neutral-300",
        },
    },
    defaultVariants: {
        size: "md",
        color: "yellow",
    },
});
var Avatar = React.forwardRef(function (_a, ref) {
    var className = _a.className, size = _a.size, color = _a.color, colorIndex = _a.colorIndex, children = _a.children, variant = _a.variant, props = __rest(_a, ["className", "size", "color", "colorIndex", "children", "variant"]);
    var avatarColor = typeof colorIndex === "number" ? idToColor(colorIndex) : color;
    return (React.createElement("div", __assign({ className: cn(avatarVariants({ size: size, color: avatarColor, variant: variant, className: className })), ref: ref }, props), children));
});
Avatar.displayName = "Avatar";
export { Avatar };

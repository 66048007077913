import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreatePolicyMutationRequest,
  CreatePolicyMutationResponse,
  CreatePolicyPathParams,
} from "../../models/CreatePolicy";

/**
 * @description Create policy
 * @summary Create policy
 * @link /workspaces/:workspace_id/policies
 */
export async function createPolicy(
  workspaceId: CreatePolicyPathParams["workspace_id"],
  data: CreatePolicyMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreatePolicyMutationResponse>["data"]> {
  const res = await client<
    CreatePolicyMutationResponse,
    CreatePolicyMutationRequest
  >({
    method: "post",
    url: `/workspaces/${workspaceId}/policies`,
    data,
    ...options,
  });
  return res.data;
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateModuleAssessmentDocumentMutationRequest,
  CreateModuleAssessmentDocumentMutationResponse,
  CreateModuleAssessmentDocumentPathParams,
} from "../../models/CreateModuleAssessmentDocument";

/**
 * @description Create module assessment document
 * @summary Create module assessment document
 * @link /module_assessments/:module_assessment_id/documents
 */
export async function createModuleAssessmentDocument(
  moduleAssessmentId: CreateModuleAssessmentDocumentPathParams["module_assessment_id"],
  data: CreateModuleAssessmentDocumentMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<CreateModuleAssessmentDocumentMutationResponse>["data"]
> {
  const res = await client<
    CreateModuleAssessmentDocumentMutationResponse,
    CreateModuleAssessmentDocumentMutationRequest
  >({
    method: "post",
    url: `/module_assessments/${moduleAssessmentId}/documents`,
    data,
    ...options,
  });
  return res.data;
}

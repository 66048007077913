import {
  Button,
  IconButton,
  Label,
  MiniTag,
  Select,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import { kebabCase } from "lodash";
import React from "react";
import { PiBookOpenBold, PiLinkSimpleBold, PiPlusBold } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";

import { UnlinkButtonWithTooltip } from "@/components/related-record";
import type { ScoringMatrixRating } from "@/stores/types/risk-register-types";

import { getRatingColorName } from "../../config";
import { useControlMappingSidebar } from "../../providers/ControlMappingsSidebarProvider";

interface ModuleControlsSectionProps {
  index: number;
  isLocked?: boolean;
  module: { title: string; identifier: string; data_type: string };
  moduleRating?: { [key: string]: string | number };
  controlRatings: {
    title: string;
    column_scoring_matrix_ratings: { color: string };
  }[];
  riskRatings?: ScoringMatrixRating[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  records: { [key: string]: string | number | any }[];
  linkedRecords: number[];
  onUnlink: (
    recordId: number,
    rowsNumber: number,
    moduleIdentifier: string,
  ) => void;
  onRatingChange: (value: string, moduleIdentifier: string) => void;
}

const ModuleControlsSection = ({
  index,
  isLocked = false,
  module,
  moduleRating,
  controlRatings,
  records,
  linkedRecords,
  onUnlink,
  onRatingChange,
}: ModuleControlsSectionProps) => {
  const { record_version_id: recordVersionId, workspace_id: workspaceID } =
    useParams<{
      record_version_id: string;
      workspace_id: string;
    }>();
  const { openSidebar } = useControlMappingSidebar();

  return (
    <div className="tw-mb-6 tw-flex tw-flex-col">
      <div className="tw-mb-2 tw-flex tw-w-full tw-items-baseline tw-gap-2">
        <div className="tw-min-w-fit tw-text-base tw-font-semibold tw-text-neutral-500">
          {module.title}
        </div>
        {records?.length > 0 ? (
          <div>
            <Select
              locked={isLocked}
              readOnly={isLocked}
              size="sm"
              selected={moduleRating?.value?.toString() || "0"}
              onSelect={(value: string) =>
                onRatingChange(value, module.identifier)
              }
              items={controlRatings.map(
                (
                  rating: {
                    title: string;
                    column_scoring_matrix_ratings: { color: string };
                  },
                  idx: number,
                ) => ({
                  label: rating?.title,
                  value: idx.toString(),
                  // eslint-disable-next-line react/no-unstable-nested-components
                  Component: () => (
                    <MiniTag
                      color={
                        idx === 0
                          ? "primary"
                          : getRatingColorName(
                              rating.column_scoring_matrix_ratings?.color,
                            )
                      }
                      size="sm"
                      className="tw-mr-2"
                    >
                      {rating?.title}
                    </MiniTag>
                  ),
                }),
              )}
            />
          </div>
        ) : (
          <div className="tw-min-w-fit tw-text-xs tw-font-medium tw-text-neutral-300">
            No Controls
          </div>
        )}
        <div className="tw-grow" />
        {index === 0 ? (
          <div className="tw-flex">
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton
                  disabled={isLocked}
                  Icon={PiPlusBold}
                  color="transparent"
                  size="md"
                  onClick={() =>
                    openSidebar(
                      Number(recordVersionId),
                      "create_new_control_mapping",
                      undefined,
                      linkedRecords,
                    )
                  }
                />
              </TooltipTrigger>
              <TooltipContent align="center" side="bottom">
                Create new control
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton
                  disabled={isLocked}
                  Icon={PiBookOpenBold}
                  color="transparent"
                  size="md"
                  onClick={() =>
                    openSidebar(
                      Number(recordVersionId),
                      "add_control_mapping_from_library",
                      undefined,
                      linkedRecords,
                    )
                  }
                />
              </TooltipTrigger>
              <TooltipContent align="center" side="bottom">
                Add from library
              </TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton
                  disabled={isLocked}
                  Icon={PiLinkSimpleBold}
                  color="transparent"
                  size="md"
                  onClick={() =>
                    openSidebar(
                      Number(recordVersionId),
                      "link_existing_control_mapping",
                      undefined,
                      linkedRecords,
                    )
                  }
                />
              </TooltipTrigger>
              <TooltipContent align="center" side="bottom">
                Select controls to link
              </TooltipContent>
            </Tooltip>
          </div>
        ) : (
          <Button
            disabled={isLocked}
            color="transparent"
            size="sm"
            LeftIcon={PiPlusBold}
            onClick={() =>
              openSidebar(
                Number(recordVersionId),
                "link_module_record",
                module.data_type,
                linkedRecords,
              )
            }
          >
            Select
          </Button>
        )}
      </div>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      {records?.map((record: { [key: string]: string | number | any }) => {
        const title = record?.meta?.name
          ? record?.meta?.name
          : record?.global_id;
        const description = record?.record_versions?.data?.description?.value;
        const controlEffectivenessRating =
          record?.record_versions?.data?.control_effectiveness_rating;
        const controlEffectivenessRatingColor = getRatingColorName(
          controlEffectivenessRating?.color,
        );
        const recordLink = `/workspaces/${workspaceID}/modules/${kebabCase(
          module.identifier,
        )}/${record?.latest_record_version_id}`;
        const controlIssues = record?.monitoring_testing_issues || [];

        return (
          <div
            key={`section-record-${record.id}`}
            className="tw-flex tw-w-full tw-items-center tw-gap-2 tw-border-0 tw-border-t tw-border-solid tw-border-generals-underline tw-py-[10px] "
          >
            <div className="tw-flex tw-flex-col">
              <Link to={recordLink}>
                <div className="tw-text-sm tw-font-medium tw-text-neutral-500 hover:tw-underline">
                  {title}
                </div>
              </Link>
              {description && (
                <div className="tw-line-clamp-2 tw-max-w-[600px] tw-text-xs tw-font-medium tw-text-neutral-300">
                  {description}
                </div>
              )}
              {controlIssues.length > 0 && (
                <ul className="tw-ml-5 tw-list-[disc]">
                  {controlIssues?.map(
                    (controlIssue: { [key: string]: string | number }) => {
                      return (
                        <li key={`section-issue-${controlIssue.id}`}>
                          <Label>{controlIssue.name}</Label>
                        </li>
                      );
                    },
                  )}
                </ul>
              )}
            </div>
            <div className="tw-grow" />
            {index === 0 && (
              <MiniTag color={controlEffectivenessRatingColor} size="md">
                {controlEffectivenessRating?.value
                  ? controlEffectivenessRating.title
                  : "Not rated"}
              </MiniTag>
            )}
            <UnlinkButtonWithTooltip
              disabled={isLocked}
              onClick={() =>
                onUnlink(record.id!, records?.length, module.identifier)
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default ModuleControlsSection;

import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import ConflictsOfInterestContextMenu from "@/components/table/conflicts-of-interest/ConflictsOfInterestContextMenu";
import { useMainStore } from "@/contexts/Store";

function ConflictsOfInterestActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();

  // Variables
  const path = location.pathname;
  const conflictsOfInterestID = Number(
    path.substring(path.lastIndexOf("/") + 1),
  );
  const recordVersionList = mainStore.recordVersions.list.find(
    (rv) => rv.id === conflictsOfInterestID,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;
  const approvedByUserIds =
    recordVersion?.reviews.map((approval) => approval.user_id) || [];
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );

  if (!recordVersion) {
    return null;
  }

  return (
    <ConflictsOfInterestContextMenu
      moduleStore={mainStore.conflicts}
      recordVersion={recordVersion}
      reviewedByUserIds={approvedByUserIds}
      status={status}
      moduleIdentifier="conflicts_of_interest"
      showOnlyIcon={false}
    />
  );
}

export default observer(ConflictsOfInterestActionBar);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ArrowDownBlack = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0002 5.33346C14.0002 5.50413 13.9348 5.67479 13.8048 5.80479L8.4715 11.1381C8.21083 11.3988 7.7895 11.3988 7.52883 11.1381L2.1955 5.80479C1.93483 5.54413 1.93483 5.12279 2.1955 4.86213C2.45617 4.60146 2.8775 4.60146 3.13817 4.86213L8.00017 9.72413L12.8622 4.86213C13.1228 4.60146 13.5442 4.60146 13.8048 4.86213C13.9348 4.99213 14.0002 5.16279 14.0002 5.33346Z"
      fill="#353549"
    />
  </svg>
);

ArrowDownBlack.displayName = "ArrowDownBlackIcon";

export default ArrowDownBlack;

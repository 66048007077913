import { useQuery } from "@tanstack/react-query";

import { getWorkspaces } from "../legacy/workspaces/workspaces";

export const WORKSPACES_QUERY_KEY = "workspaces";

export function useWorkspaces() {
  return useQuery({
    queryKey: [WORKSPACES_QUERY_KEY],
    queryFn: () => getWorkspaces(),
  });
}

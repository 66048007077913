import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteTaskableMutationResponse,
  DeleteTaskablePathParams,
} from "../../models/DeleteTaskable";

/**
 * @description Deletes a taskable from a task.
 * @summary Delete a taskable
 * @link /tasks/:task_id/taskables/:id
 */
export async function deleteTaskable(
  taskId: DeleteTaskablePathParams["task_id"],
  id: DeleteTaskablePathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteTaskableMutationResponse>["data"]> {
  const res = await client<DeleteTaskableMutationResponse>({
    method: "delete",
    url: `/tasks/${taskId}/taskables/${id}`,
    ...options,
  });
  return res.data;
}

import classNames from "classnames";
import React, { useMemo } from "react";

import closeIcon from "../../../../../images/table-image/icon/close-icon3.svg";
import menuInactiveIcon from "../../../../../images/table-image/icon/menu-black.svg";
import menuActiveIcon from "../../../../../images/table-image/icon/menu.svg";

interface Props {
  hasPermissionToSeeButton?: boolean;
  inSelectMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
  showPopup?: boolean;
  testId?: string;
}

export const ButtonTrigger = ({
  testId,
  showPopup,
  inSelectMode,
  onClick,
  hasPermissionToSeeButton,
}: Props) => {
  const imgSrc = useMemo(() => {
    if (inSelectMode) {
      return { icon: closeIcon, alt: "close-icon" };
    }

    return showPopup
      ? { icon: menuActiveIcon, alt: "menu-active-icon" }
      : { icon: menuInactiveIcon, alt: "menu-inactive-icon" };
  }, [inSelectMode, showPopup]);

  return (
    <button
      data-testid={testId}
      className={classNames("table-column-reorder-trigger", {
        active: showPopup,
        hidden: !hasPermissionToSeeButton,
        "select-mode": inSelectMode,
      })}
      onClick={onClick}
    >
      <img
        data-testid="table-menu-btn-trigger-img"
        src={imgSrc.icon}
        alt={imgSrc.alt}
      />
    </button>
  );
};

ButtonTrigger.defaultProps = {
  testId: "table-menu-btn-trigger",
};

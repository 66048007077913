import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import Loading from "@/components/Loading";
import HeadSelect from "@/components/table/shared/HeadSelect";
import { useMainStore } from "@/contexts/Store";

import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import DashboardContent from "../../../components/shared/DashboardContent";
import DashboardContentWrapper from "../../../components/shared/DashboardContentWrapper";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import ModuleHeader from "../components/ModuleHeader/ModuleHeader";
import Partners from "../components/Partners/Partners";
import RiskAssessmentTabs from "../components/RiskAssessmentTabs";

function PartnersPage() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { moduleWorkspaceID, tableID } = mainStore.context;
  const { list: tables } = mainStore.tables;
  const currentTable = tables.find((table) => table.title === "Partners");

  // State
  const [loading, setLoading] = useState(true);

  // Functions
  const indexPartners = async () => {
    setLoading(true);
    if (!moduleWorkspaceID) {
      setLoading(false);
      return;
    }

    try {
      await mainStore.sectionTags.index(moduleWorkspaceID);
    } catch {
      mainStore.toast.setErrorText("Something went wrong");
    }
    setLoading(false);
  };

  const fetchTablesData = async () => {
    if (!moduleWorkspaceID) {
      return;
    }

    await mainStore.tables.index(moduleWorkspaceID);
  };

  // Effects
  useEffect(() => {
    if (moduleWorkspaceID) {
      indexPartners();
      fetchTablesData();
    }
    return () => mainStore.riskAssessmentPartners.abort();
  }, [moduleWorkspaceID]);

  useEffect(() => {
    if (currentTable?.id && tableID !== currentTable.id) {
      mainStore.context.setTableID(currentTable?.id);
    }
  }, [tables]);

  if (loading) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <DashboardContent>
      <DashboardHeader
        title="Questionnaires"
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper>
        <ModuleHeader>
          <RiskAssessmentTabs />
        </ModuleHeader>
        <Partners />
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(PartnersPage);

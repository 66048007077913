import "./vendor-questionnaire-highlights.scss";

import { observer } from "mobx-react";
import React from "react";

import { isQuestionAnswered } from "@/features/vendor-due-diligence/helpers";
import type {
  ChecklistQuestion,
  VendorQuestionnaireFile,
} from "@/stores/types/vendor-types";

interface Props {
  checklistQuestions: Array<ChecklistQuestion>;
}

function VendorQuestionnaireHighlights({ checklistQuestions }: Props) {
  const completedQuestions = checklistQuestions.filter(isQuestionAnswered);
  const questionsWithAttachments = checklistQuestions.filter(
    (cq) => (cq.file as VendorQuestionnaireFile)?.id,
  );
  return (
    <div className="vdd-questionnaire-highlights">
      <h4>Questionnaire Highlights</h4>
      <div className="content">
        <span>
          {completedQuestions.length}/{checklistQuestions.length} Questions
          Completed
        </span>
        <span>{questionsWithAttachments?.length} Questions attached file</span>
      </div>
      <ul>
        <li>
          If questionnaire has been shared, the related workspace will be shown
          on the top row.
        </li>
        <li>
          You can leave anytime before completing the whole questionnaire,
          answered questions will be auto-saved.
        </li>
      </ul>
    </div>
  );
}

export default observer(VendorQuestionnaireHighlights);

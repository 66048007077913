import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Donut = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C10.4679 1.99723 8.95565 2.34654 7.57996 3.02097C6.20428 3.69541 5.00196 4.67692 4.0658 5.88976C3.12964 7.10261 2.48469 8.51434 2.18072 10.016C1.87675 11.5177 1.92189 13.0691 2.31266 14.5505C2.70344 16.032 3.42938 17.4038 4.43448 18.5602C5.43957 19.7165 6.69693 20.6265 8.10951 21.2198C9.52208 21.8131 11.0521 22.0739 12.5815 21.982C14.1108 21.8901 15.5986 21.4481 16.93 20.69H17H17.05C18.9753 19.594 20.4821 17.8898 21.334 15.8447C22.1858 13.7997 22.3345 11.5297 21.7566 9.39103C21.1788 7.25237 19.9071 5.36614 18.1412 4.02842C16.3754 2.6907 14.2153 1.97723 12 2ZM13 4.07C14.76 4.2932 16.3959 5.0951 17.6504 6.34959C18.9049 7.60408 19.7068 9.23998 19.93 11H16.9424C16.5419 9.02665 14.9793 7.47527 13 7.09134V4.07ZM11 7.10853V4.07C8.99216 4.33702 7.16033 5.35573 5.87421 6.92056C4.58808 8.4854 3.94342 10.4798 4.07033 12.5014C4.19723 14.523 5.08624 16.4211 6.55796 17.8128C8.02968 19.2045 9.97453 19.9862 12 20C13.0554 19.9996 14.0998 19.7853 15.07 19.37L13.5545 16.767C13.0774 16.9182 12.5694 16.9997 12.0424 16.9997C9.28093 16.9997 7.04236 14.7611 7.04236 11.9997C7.04236 9.59575 8.73891 7.58802 11 7.10853ZM15.3302 15.7668L16.83 18.36C17.677 17.7174 18.3874 16.9124 18.9196 15.9921C19.4519 15.0718 19.7954 14.0546 19.93 13H16.9423C16.72 14.0945 16.1403 15.0592 15.3302 15.7668ZM9.04236 11.9997C9.04236 10.3429 10.3855 8.99973 12.0424 8.99973C13.6992 8.99973 15.0424 10.3429 15.0424 11.9997C15.0424 13.6566 13.6992 14.9997 12.0424 14.9997C10.3855 14.9997 9.04236 13.6566 9.04236 11.9997Z"
      fill="#353549"
    />
  </svg>
);

Donut.displayName = "DonutIcon";

export default Donut;

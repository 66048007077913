import "./risk-methodology-builder.scss";

import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "../../../../../contexts/Store";
import type {
  ResidualRisk as ResidualRiskItem,
  ResidualRiskMatrix as ResidualRiskMatrixItem,
  RiskRating,
} from "../../../types/risk-methodology";
import ResidualRiskMatrix from "./ResidualRiskMatrix";
import ResidualRiskRow from "./ResidualRiskRow";
import TableInputGroup from "./TableInputGroup";

interface ResidualRisksProps {
  groupTitle: string;
  addNewText: string;
  description?: string;
  residualRisks: Array<ResidualRiskItem>;
  updateResidualRisks: (residualRisks: Array<ResidualRiskItem>) => void;
  controlRiskRatings: Array<RiskRating>;
  inherentRiskRatings: Array<RiskRating>;
  residualRiskMatrices: Array<ResidualRiskMatrixItem>;
  updateResidualRiskMatrices: (
    residualRiskMatrices: Array<ResidualRiskMatrixItem>,
  ) => void;
  dataTestID?: string;
  setSaveable: (canSave: boolean) => void;
}

function ResidualRisk({
  description,
  groupTitle,
  addNewText,
  residualRisks,
  updateResidualRisks,
  dataTestID,
  controlRiskRatings,
  inherentRiskRatings,
  residualRiskMatrices,
  updateResidualRiskMatrices,
  setSaveable,
}: ResidualRisksProps) {
  const mainStore = useMainStore();

  const removeResidualRisk = (index: number) => {
    const residualRisk = residualRisks.find(
      (subresidualRisk, i) => i === index,
    );
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (residualRisk.id) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      residualRisk._destroy = true;
      const newList = [...residualRisks];
      updateResidualRisks(newList);
    } else {
      const newList = residualRisks.filter((subresidualRisk, i) => i !== index);
      updateResidualRisks(newList);
    }
  };

  const updateText = (index: number, value: string) => {
    residualRisks[index].text = value;
    updateResidualRisks([...residualRisks]);
  };

  const updateColor = (index: number, value: string) => {
    residualRisks[index].color = value;
    updateResidualRisks([...residualRisks]);
  };

  const checkError = (text: string, index: number): boolean => {
    for (let subIndex = 0; subIndex < residualRisks.length; ++subIndex) {
      if (subIndex !== index && residualRisks[subIndex].text === text) {
        mainStore.toast.setErrorText("The text is taken");
        setSaveable(false);
        return true;
      }
    }
    setSaveable(true);
    return false;
  };

  function handleRemoveResidualRisk(index: number) {
    if (residualRisks.filter((rr) => !rr._destroy).length > 1) {
      removeResidualRisk(index);
    }
  }

  const rows = residualRisks.map((residualRisk, index) =>
    residualRisk._destroy ? null : (
      <div
        key={index}
        className="input-group-row"
        data-testid={`${dataTestID}-row-${index}`}
      >
        <ResidualRiskRow
          text={residualRisk.text}
          index={index}
          color={residualRisk.color}
          updateText={updateText}
          dataTestID={dataTestID}
          updateColor={updateColor}
          removeResidualRisk={handleRemoveResidualRisk}
          hasError={checkError(residualRisk.text, index)}
        />
      </div>
    ),
  );

  return (
    <>
      <TableInputGroup
        description={description}
        title={groupTitle}
        addNewInputText={addNewText}
        dataTestID={dataTestID}
        addNewInput={() => {
          updateResidualRisks([
            ...residualRisks,
            { text: "New Residual Risk", color: "#EB2E4E" },
          ]);
        }}
      >
        {rows}
      </TableInputGroup>
      <ResidualRiskMatrix
        controlRiskRatings={controlRiskRatings}
        inherentRiskRatings={inherentRiskRatings}
        residualRisks={residualRisks}
        residualRiskMatrices={residualRiskMatrices}
        setResidualRiskMatrices={updateResidualRiskMatrices}
      />
    </>
  );
}

export default observer(ResidualRisk);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListShowRecordsQueryParams,
  ListShowRecordsQueryResponse,
} from "../../models/ListShowRecords";

/**
 * @description List of Records
 * @summary List of Records
 * @link /records
 */
export async function listShowRecords(
  params: ListShowRecordsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListShowRecordsQueryResponse>["data"]> {
  const res = await client<ListShowRecordsQueryResponse>({
    method: "get",
    url: `/records`,
    params,
    ...options,
  });
  return res.data;
}

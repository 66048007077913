import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import arrowLeft from "../../../images/table-image/icon/arrow-left-blue.svg";
import ConfirmationDialog from "../../table/shared/ConfirmationDialog";

function RiskRegisterControlsBackButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const history = useHistory();
  const { changed } = mainStore.riskRegisters.controlsData;
  const { workspaceID } = mainStore.context;
  const disabled = !changed;

  // funcs
  function handlePopUpOpen() {
    setShowPopup(true);
  }

  function handlePopUpClose() {
    setShowPopup(false);
  }

  async function handleConfirm() {
    handlePopUpClose();
    const id = parseInt(
      window.location.pathname
        .split("/risk-register/")[1]
        .split("/controls")[0],
      10,
    );
    await mainStore.riskRegisters.saveControlsData(id);

    history.push(`/workspaces/${workspaceID}/modules/risk-register`);
  }

  function handleReject() {
    handlePopUpClose();
    history.push(`/workspaces/${workspaceID}/modules/risk-register`);
  }

  // elements
  const renderTrigger = (
    <button>
      <img src={arrowLeft} alt="arrow-left-icon" />
    </button>
  );

  if (!changed) {
    return (
      <div className="archive-head-link">
        <button onClick={handleReject}>
          <img src={arrowLeft} alt="arrow-left-icon" />
        </button>
      </div>
    );
  }

  return (
    <div className="archive-head-link">
      <Popup
        position="bottom left"
        trigger={renderTrigger}
        open={showPopup}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        <div className="table-dropdown success-dropdown back-btn-confirm-popup">
          <ConfirmationDialog
            handleConfirm={handleConfirm}
            handleReject={handleReject}
            heading="Save Now?"
            content="Do you want to save changes?"
            isConfirmDisabled={disabled}
          />
        </div>
      </Popup>
    </div>
  );
}

export default observer(RiskRegisterControlsBackButton);

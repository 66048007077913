import { useQuery } from "@tanstack/react-query";

import { listShowRecords } from "../gen/axios/recordsController";
import type { ListShowRecordsQueryParams } from "../gen/models/ListShowRecords";

export const LIST_SHOW_RECORDS_QUERY_KEY = "list_show_records";

export function useListShowRecords(params: ListShowRecordsQueryParams) {
  return useQuery({
    queryKey: [LIST_SHOW_RECORDS_QUERY_KEY, params],
    queryFn: () => listShowRecords(params),
    enabled: !!params?.data_type,
  });
}

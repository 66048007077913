import { filter } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { REVIEWER, ROUTES } from "@/features/risk-assessment";
import FlexDashboardContent from "@/features/risk-assessment/components/FlexDashboardContent/FlexDashboardContent";
import { usePreventUnsavedChanges } from "@/hooks/usePreventUnsavedChanges";
import {
  areGroupsValidForSubmit,
  copyQuestion,
  createNewGroup,
  prepareQuestionnaireUpsertRequest,
} from "@/stores/helpers/RiskAssessmentHelpers";

import { QuestionnaireAPI } from "../../../api/legacy/risk-assessment/QuestionnaireApi";
import ViewModuleUsers from "../../../components/dashboard/ViewModuleUsers";
import DashboardHeader from "../../../components/shared/DashboardHeader";
import { useMainStore } from "../../../contexts/Store";
import FlexDashboardContentWrapper from "../components/FlexDashboardContentWrapper/FlexDashboardContentWrapper";
import QuestionnaireBuilder from "../components/Questionnaires/QuestionnaireBuilder/QuestionnaireBuilder";
import QuestionnaireSaveButton from "../components/QuestionnaireSaveButton";
import type {
  Question,
  QuestionGroup,
  ResponderType,
  ReviewerType,
} from "../types/questionnaire";

function CreateQuestionnaireTemplatePage() {
  const mainStore = useMainStore();
  const { workspace_id: workspaceID } = useParams<{ workspace_id: string }>();

  const history = useHistory();
  const [name, setName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [riskMethodologyId, setRiskMethodologyId] = useState<number | null>(
    null,
  );
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [isPublic, setIsPublic] = useState(true);
  const [groups, setGroups] = useState<QuestionGroup[]>([
    createNewGroup("Company Details", 0),
  ]);
  const [activeGroupId, setActiveGroupId] = useState(groups[0].id);
  const activeGroup = groups.find((group) => group.id === activeGroupId);

  const setAssignedIDs = (
    ids: number[],
    assignmentType: ReviewerType | ResponderType,
  ) => {
    if (!activeGroup) {
      return;
    }
    activeGroup[assignmentType === REVIEWER ? "assignments" : "responders"] =
      ids.map((id) => ({
        user_id: id,
        question_group_id: activeGroupId,
        assignment_type: assignmentType,
      }));
    const index = groups.findIndex((group) => group.id === activeGroupId);
    const newGroups = [...groups];
    newGroups[index] = activeGroup;
    setGroups(newGroups);
  };

  const unblock = usePreventUnsavedChanges(
    [
      name !== "",
      groups.some((group) =>
        group.questions.some((question) => !question._destroy),
      ),
    ],
    [name, groups],
  );

  const onSave = async () => {
    try {
      if (!name.trim()) {
        return mainStore.toast.setErrorText("Name required");
      } else if (!areGroupsValidForSubmit(groups)) {
        return mainStore.toast.setErrorText(
          "Names for each question and question choice are required",
        );
      }

      setIsSaving(true);

      const createdQuestionnaire = await QuestionnaireAPI.create(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        workspaceID,
        prepareQuestionnaireUpsertRequest(
          name,
          Number(riskMethodologyId),
          groups,
          !isPublic,
          true,
          false,
          false,
          dueDate,
        ),
      );
      mainStore.toast.setInfoText("Template saved!");
      unblock();
      history.push(
        generatePath(ROUTES.QUESTIONNAIRE_TEMPLATE_EDIT_PATH, {
          workspace_id: Number(workspaceID),
          templateId: createdQuestionnaire.id,
        }),
      );
    } catch (error: unknown) {
      mainStore.toast.setErrorFromResponse(error);
    } finally {
      setIsSaving(false);
    }
  };

  const onQuestionChange = (changedQuestion: Question) => {
    setGroups(
      groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.map((question) => {
                return question.id === changedQuestion.id
                  ? changedQuestion
                  : question;
              }),
            }
          : group;
      }),
    );
  };

  const onQuestionReorder = (questions: Question[]) => {
    setGroups(
      groups.map((group) =>
        group.id === activeGroupId ? { ...group, questions } : group,
      ),
    );
  };

  const onQuestionGroupChange = (newGroupId: number, question: Question) => {
    setGroups(
      groups.map((group) => {
        if (group.id === activeGroupId) {
          return {
            ...group,
            questions: filter(group.questions, (q) => q.id !== question.id),
          };
        } else if (group.id === newGroupId) {
          return {
            ...group,
            questions: [...group.questions, question],
          };
        }
        return group;
      }),
    );
  };

  const onQuestionCopy = (questionToCopy: Question) => {
    setGroups(
      groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.flatMap((question) => {
                return question.id === questionToCopy.id
                  ? [question, copyQuestion(question)]
                  : question;
              }),
            }
          : group;
      }),
    );
  };

  const onQuestionDelete = (questionToDelete: Question) => {
    setGroups(
      groups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.filter((question) => {
                return question.id !== questionToDelete.id;
              }),
            }
          : group;
      }),
    );
  };

  const onQuestionAdd = (question: Question) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) => {
        return group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.concat(structuredClone(question)),
            }
          : group;
      }),
    );
  };

  const onGroupAdd = (group: QuestionGroup) => {
    setGroups(groups.concat(group));
  };

  const onGroupDelete = (groupId: number) => {
    setGroups(groups.filter((group) => group.id !== groupId));
  };

  const onGroupNameChange = (groupId: number, groupName: string) => {
    setGroups(
      groups.map((group) => {
        return group.id === groupId
          ? {
              ...group,
              name: groupName,
            }
          : group;
      }),
    );
  };

  return (
    <FlexDashboardContent>
      <DashboardHeader
        title="Add New Questionnaire Template"
        onBackClick={() =>
          history.push(
            generatePath(ROUTES.QUESTIONNAIRE_TEMPLATE_PATH, {
              workspace_id: Number(workspaceID),
            }),
          )
        }
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={
          <QuestionnaireSaveButton
            buttonLabel={isSaving ? "Saving Template" : "Save Template"}
            onSave={onSave}
            isDisabled={isSaving}
            isPublic={isPublic}
            setIsPublic={setIsPublic}
          />
        }
      />
      <FlexDashboardContentWrapper>
        <QuestionnaireBuilder
          groups={groups}
          activeGroupId={activeGroupId}
          setActiveGroupId={setActiveGroupId}
          onGroupAdd={onGroupAdd}
          onGroupDelete={onGroupDelete}
          onGroupNameChange={onGroupNameChange}
          onQuestionAdd={onQuestionAdd}
          onQuestionCopy={onQuestionCopy}
          onQuestionChange={onQuestionChange}
          onQuestionDelete={onQuestionDelete}
          onQuestionReorder={onQuestionReorder}
          onQuestionGroupChange={onQuestionGroupChange}
          name={name}
          onNameChange={setName}
          riskMethodologyId={riskMethodologyId}
          onRiskMethodologyChange={setRiskMethodologyId}
          setAssignedIDs={setAssignedIDs}
          isInternal={!isPublic}
          setIsPublic={setIsPublic}
          isNew
          dueDate={dueDate}
          setDueDate={setDueDate}
        />
      </FlexDashboardContentWrapper>
    </FlexDashboardContent>
  );
}

export default observer(CreateQuestionnaireTemplatePage);

import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import Button from "@/components/form/Button";
import { useMainStore } from "@/contexts/Store";

import { TermsAndPolicy } from "../components/TermsAndPolicy";

const Legals = () => {
  const history = useHistory();
  const mainStore = useMainStore();
  const workspaceID = mainStore.users.user?.active_workspace?.id;

  // functions
  function handleButtonClick() {
    history.push(`/workspaces/${workspaceID}/home`);
  }

  return (
    <div className="auth-legals">
      <TermsAndPolicy />
      <Button title="Continue" onClick={handleButtonClick} />
    </div>
  );
};

export default observer(Legals);

import classNames from "classnames";
import { observer } from "mobx-react";
import { default as React, useEffect, useState } from "react";

import { useMainStore } from "@/contexts/Store";

interface Props {
  fieldName: string;
  width: number | string;
  value?: number;
  pinned?: boolean;
  locked?: boolean;
}

function IntegerCell({ fieldName, value, width, pinned, locked }: Props) {
  const mainStore = useMainStore();

  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  const [showZero, setShowZero] = useState("-");

  // Effects
  useEffect(() => {
    if (value === 0) {
      setShowZero("0");
    }
  }, [value]);

  return (
    <li
      key={fieldName}
      className={classNames("cell computed-columns", {
        pinned,
        "read-only": isCurrentWorkspaceActive,
        "locked-cell": locked && isCurrentWorkspaceActive,
        "pointer-events-none": locked || isReadOnly,
        "table-cell--disabled": isReadOnly,
      })}
      style={{ width }}
    >
      <div className="cell-content integer-cell">
        <span>{value || showZero}</span>
      </div>
    </li>
  );
}

IntegerCell.defaultProps = {
  width: 200,
};

export default observer(IntegerCell);

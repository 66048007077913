import { useQuery } from "@tanstack/react-query";

import { listOfCompanyControlCategories } from "../gen/axios/controlCategoriesController";

export const LIST_CONTROL_CATEGORY = "list_control_categories";

export function useListControlCategories(company_id: number) {
  return useQuery({
    queryKey: [LIST_CONTROL_CATEGORY, company_id],
    queryFn: () => listOfCompanyControlCategories(company_id),
    enabled: !!company_id,
  });
}

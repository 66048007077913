import { startCase } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Popup from "reactjs-popup";

import FilterCalendar from "@/components/filters/FilterCalendar";
import { useMainStore } from "@/contexts/Store";
import { useFilters } from "@/hooks/useFilters";
import useMetricsParams from "@/hooks/useMetricsParams";

import SingleSelectField from "../../../SingleSelectField";
import { allowedGroupings } from "../../constants";
import { Dashboards } from "../../types";

interface DashboardOptionsProps {
  timeRangeValue: string;
  timeRangeLabel?: string;
  timeRangeOptions: string[];
  handleChangeTimeRange: (option: string) => void;
  intervalDisplayName?: string;
  intervalOptions?: string[];
  handleChangeIntervalDisplayName?: (option: string) => void;
  onCalendarSelect: () => void;
  onDateChange: (date: Date) => void;
  dashboard?: Dashboards;
}

const DashboardOptions = ({
  timeRangeValue,
  timeRangeLabel,
  timeRangeOptions,
  handleChangeTimeRange,
  intervalDisplayName,
  intervalOptions,
  handleChangeIntervalDisplayName,
  onCalendarSelect,
  onDateChange,
  dashboard,
}: DashboardOptionsProps) => {
  const mainStore = useMainStore();
  const { visibleFields } = mainStore.fields;

  const { setMetricsParam, groupedByParam, clearMetricsParam } =
    useMetricsParams();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
    skipCleanup: true,
  });

  useEffect(() => {
    if (dashboard !== Dashboards.CREATED_DATE) {
      clearMetricsParam("groupedBy");
      return;
    }

    if (groupedByParam) {
      mainStore.reports.setIMSourceGroupName(groupedByParam);
    } else if (!groupedByParam && mainStore.reports.imSourceGroupName) {
      setMetricsParam("groupedBy", mainStore.reports.imSourceGroupName);
    }
  }, []);

  if (!timeRangeValue) {
    return null;
  }

  const groupedByOptions = mainStore.fields.list.filter(
    (field) =>
      allowedGroupings.includes(field.name) ||
      (field.is_custom_field &&
        field.data_type === "com.askthemis.types.v1.option"),
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const handleChangeGroupedBy = (field) => {
    mainStore.reports.setIMSourceGroupName(field.name);
    setMetricsParam("groupedBy", field.name);
  };

  return (
    <>
      <div className="metrics-select-fields-container">
        <div className="metrics-select-field">
          <SingleSelectField
            label={timeRangeLabel || "Created Date"}
            value={timeRangeValue}
            options={timeRangeOptions}
            handleChange={handleChangeTimeRange}
            testId="metrics-select-date-field"
          />
          {timeRangeValue === "Custom" && (
            <Popup
              open
              keepTooltipInside
              trigger={<div style={{ visibility: "hidden" }} />}
              position="bottom right"
            >
              <div className="table-dropdown">
                <FilterCalendar
                  isDateRange
                  onDateChange={onDateChange}
                  onContinue={onCalendarSelect}
                />
              </div>
            </Popup>
          )}
        </div>
        {mainStore.reports.groupFieldName !== "overdue" && (
          <div className="metrics-select-field">
            <SingleSelectField
              label="Interval"
              value={intervalDisplayName}
              options={intervalOptions}
              handleChange={handleChangeIntervalDisplayName}
              testId="metrics-select-interval"
            />
          </div>
        )}
        {mainStore.reports.groupFieldName === Dashboards.CREATED_DATE && (
          <div className="metrics-select-field">
            <SingleSelectField
              label="Grouped By"
              value={startCase(mainStore.reports.imSourceGroupName)}
              options={groupedByOptions}
              handleChange={handleChangeGroupedBy}
              testId="metrics-select-grouped-by-field"
            />
          </div>
        )}
        <div style={{ marginTop: "24px" }}>{filtersTrigger}</div>
      </div>
      {filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersContent}
        </div>
      )}
    </>
  );
};

export default observer(DashboardOptions);

import React from "react";
import Popup from "reactjs-popup";

import { TermsAndPolicy } from "./TermsAndPolicy";

export const LegalPopup = () => {
  const renderTrigger = (
    <p>
      <a href="/" onClick={(event) => event.preventDefault()}>
        Terms & Conditions
      </a>{" "}
      &
      <a href="/" onClick={(event) => event.preventDefault()}>
        {" "}
        Privacy Policy.
      </a>
    </p>
  );

  return (
    <div>
      <Popup className="modal-politics" trigger={renderTrigger} modal>
        <TermsAndPolicy />
      </Popup>
    </div>
  );
};

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useScrollToSelectedCommentRef } from "@/components/settings/notificationsCenter/tasks/hooks/useScrollToSelectedCommentRef";
import { useSwitchToSelectedCommentTab } from "@/components/settings/notificationsCenter/tasks/hooks/useSwitchToSelectedCommentTab";
import SubpageTabs from "@/components/subpage-tabs/SubPageTabs";
import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useMainStore } from "@/contexts/Store";
import { useSearchParams } from "@/hooks/useSearchParams";
import {
  getTopLevelComments,
  isNotResolvedComment,
} from "@/stores/helpers/CommentsHelpers";
import type { UserComment } from "@/stores/types/comment-types";

import markerIcon from "../../../../images/table-image/icon/marker.svg";
import UserMentionsInput from "../UserMentionsInput";
import CreativeViewComment from "./CreativeViewComment";

export interface CreativeViewSidebarProps {
  attachmentGroupID: number;
  hasFigmaAttachmentGroup?: boolean;
  isFigma?: boolean;
  fetchFile(attachmentID: number): Promise<void>;
  setCommentsMode(commentsMode: boolean): void;
}

function CreativeViewSidebar({
  attachmentGroupID,
  fetchFile,
  setCommentsMode,
  isFigma,
}: CreativeViewSidebarProps) {
  const mainStore = useMainStore();
  const [searchParams, setSearchParams] = useSearchParams<{
    comments_tab?: CommentTab;
    comment_id?: string;
  }>();
  const selectedCommentID = Number(searchParams.comment_id);

  // Effects
  useEffect(() => {
    if (mainStore.context.moduleWorkspaceID && attachmentGroupID) {
      refreshComments();
    }

    return () => {
      mainStore.comments.cleanup();
      setSearchParams({ ...searchParams, comments_tab: undefined }, true);
    };
  }, [attachmentGroupID, mainStore.context.moduleWorkspaceID]);

  useSwitchToSelectedCommentTab(mainStore.comments.privateComments);

  const selectedCommentRef = useScrollToSelectedCommentRef(
    mainStore.comments.privateComments,
  );

  const { isCurrentWorkspaceActive } = mainStore.workspaces;

  // Variables
  const isInternalTab = searchParams.comments_tab === "internal";

  const mainComments = getTopLevelComments(searchParams.comments_tab, {
    comments: mainStore.comments.comments,
    privateComments: mainStore.comments.privateComments,
  });
  const { canAddComments } = mainStore.userPermissions;

  // State
  const [commentInput, setCommentInput] = useState("");

  // functions
  function refreshComments() {
    mainStore.comments.index({ attachment_group_id: attachmentGroupID });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleAddComment(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      mainStore.comments.create({
        content: commentInput,
        private: isInternalTab,
      });
      setCommentInput("");
    }
  }

  const handleCommentClick = (comment: UserComment) => async () => {
    if (isFigma && comment.file.id && attachmentGroupID !== comment.file.id) {
      mainStore.comments.setComments([]);
      await fetchFile(comment.file.id);
    }

    setCommentsMode(true);
    setSearchParams({ ...searchParams, comment_id: String(comment.id) }, true);
  };

  const renderContent = (
    <div className="comments" data-testid="cv-sidebar-content">
      <ul>
        {mainComments.map((comment) => {
          const { id } = comment;
          const active = selectedCommentID === id;

          return (
            <li key={id} data-testid="comments-item">
              <div
                data-testid="comments-item-trigger"
                className={classNames("comments-item", { active })}
                onClick={handleCommentClick(comment)}
                ref={active ? selectedCommentRef : undefined}
              >
                <CreativeViewComment
                  comment={comment}
                  selectedCommentID={selectedCommentID}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const renderEmptyContent = canAddComments ? (
    <div className="empty" data-testid="cv-sidebar-empty-content">
      <img src={markerIcon} alt="marker" />
      <p>
        You don’t have any comments pinned in this file yet! Click anywhere in
        the file to start leaving a comment.
      </p>
    </div>
  ) : (
    <div className="empty" data-testid="cv-sidebar-empty-content">
      <img src={markerIcon} alt="marker" />
      <p>There are no comments pinned in this file yet.</p>
    </div>
  );

  const hasComments = mainComments.length > 0;

  return (
    <div className="creative-comments-sidebar" data-testid="cv-sidebar">
      {mainStore.comments.showPrivateComments && (
        <SubpageTabs<{ comments_tab: CommentTab }>
          infoText={
            "Collaborative comments can be seen by all users with access to this workspace.\nInternal comments can only be seen by users with access to the internal workspace."
          }
          searchParamName="comments_tab"
          options={[
            {
              activeValue: "collaborative",
              label: "Collaborative",
              count:
                mainStore.comments.comments.filter(isNotResolvedComment).length,
            },
            {
              activeValue: "internal",
              label: "Internal",
              count:
                mainStore.comments.privateComments.filter(isNotResolvedComment)
                  .length,
            },
          ]}
        />
      )}
      <div className="creative-comments-sidebar__content">
        <div className="comment-textarea-field">
          {canAddComments && isCurrentWorkspaceActive && (
            <UserMentionsInput
              singleLine={false}
              editable
              content={commentInput}
              placeholder="Add a comment. Use @ to mention a user."
              onChange={(e) => setCommentInput(e.target.value)}
              onKeyDown={handleAddComment}
              dataTestID="comment-sidebar-input"
            />
          )}
        </div>
        <div className="content">
          {hasComments ? renderContent : renderEmptyContent}
        </div>
      </div>
    </div>
  );
}

export default observer(CreativeViewSidebar);

import React from "react";

import { useMainStore } from "@/contexts/Store";

function ExportRiskAssessment() {
  // stores
  const mainStore = useMainStore();

  // functions
  async function handleClick() {
    const pathnameArray = window.location.pathname.split("/");
    const id = pathnameArray[pathnameArray.length - 2];
    const spreadSheetData = await mainStore.vendors.exportRiskAssessment(id);

    if (!spreadSheetData) {
      return;
    }

    const blob = new Blob([new Uint8Array(spreadSheetData.content)], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = spreadSheetData.filename;
    link.click();
  }
  return (
    <button className="gray-button" onClick={() => handleClick()}>
      {" "}
      Export Questionnaires{" "}
    </button>
  );
}

export default ExportRiskAssessment;

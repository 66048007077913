import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import * as pdfjs from "pdfjs-dist";
import React, { useCallback, useEffect, useState } from "react";
import { PiCaretLeftBold, PiCaretRightBold, PiXBold } from "react-icons/pi";
import { Document, Page } from "react-pdf";
import { cn } from "../../lib/utils";
import { IconButton } from "../Button/IconButton";
pdfjs.GlobalWorkerOptions.workerSrc = "//unpkg.com/pdfjs-dist@".concat(pdfjs.version, "/build/pdf.worker.min.mjs");
var PaginationContainer = function (_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-3 tw-rounded tw-bg-white tw-py-2" }, children));
};
var CloseButtonContainer = function (_a) {
    var children = _a.children;
    return (React.createElement("div", { className: "tw-absolute tw-right-0 tw-top-1.5 tw-z-50 tw-box-border tw-flex tw-w-full tw-justify-end tw-px-2" }, children));
};
var options = {
    cMapUrl: "/cmaps/",
    standardFontDataUrl: "/standard_fonts/",
};
var PAGE_SCALE = 0.9;
var PdfViewer = function (_a) {
    var pdf = _a.pdf, pageHeight = _a.pageHeight, _b = _a.onClose, onClose = _b === void 0 ? function () { } : _b;
    var _c = useState(1), numPages = _c[0], setNumPages = _c[1];
    var _d = useState(1), pageNumber = _d[0], setPageNumber = _d[1];
    var _e = useState(), renderedPageNumber = _e[0], setRenderedPageNumber = _e[1];
    var isLoading = renderedPageNumber !== pageNumber;
    function handleDocumentLoadSuccess(_a) {
        var nextNumPages = _a.numPages;
        setNumPages(nextNumPages);
    }
    var handleNextPage = useCallback(function () {
        if (numPages === 1) {
            return;
        }
        setPageNumber(function (prev) { return (prev === numPages ? 1 : prev + 1); });
    }, [numPages]);
    var handlePreviousPage = useCallback(function () {
        if (numPages === 1) {
            return;
        }
        setPageNumber(function (prev) { return (prev === 1 ? numPages : prev - 1); });
    }, [numPages]);
    useEffect(function () {
        var handleKeyboardInput = function (event) {
            if (event.key === "ArrowDown" || event.key === "ArrowRight") {
                handleNextPage();
            }
            if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
                handlePreviousPage();
            }
        };
        document.addEventListener("keydown", handleKeyboardInput, true);
        return function () {
            document.removeEventListener("keydown", handleKeyboardInput, true);
        };
    }, [handleNextPage, handlePreviousPage]);
    return (React.createElement(Document, { className: "tw-relative", file: pdf, onLoadSuccess: handleDocumentLoadSuccess, options: options },
        React.createElement(CloseButtonContainer, null,
            React.createElement(IconButton, { Icon: PiXBold, size: "lg", color: "tertiary", onClick: onClose })),
        isLoading && renderedPageNumber ? (React.createElement(Page, { key: renderedPageNumber, pageNumber: renderedPageNumber, height: pageHeight, scale: PAGE_SCALE, loading: null })) : null,
        React.createElement(Page
        // Hiding the current page until it's finished rendering helps avoid flickering
        , { 
            // Hiding the current page until it's finished rendering helps avoid flickering
            className: cn({ "tw-hidden": isLoading }), key: pageNumber, pageNumber: pageNumber, height: pageHeight, scale: PAGE_SCALE, loading: null, onRenderSuccess: function () { return setRenderedPageNumber(pageNumber); } }),
        React.createElement(PaginationContainer, null,
            React.createElement(IconButton, { disabled: numPages === 1, Icon: PiCaretLeftBold, color: "transparent", onClick: handlePreviousPage }),
            React.createElement("span", { className: "tw-flex tw-items-center tw-text-center tw-text-neutral-500" },
                pageNumber,
                " of ",
                numPages),
            React.createElement(IconButton, { disabled: numPages === 1, Icon: PiCaretRightBold, color: "transparent", onClick: handleNextPage }))));
};
export { PdfViewer };

import classNames from "classnames";
import React, { useState } from "react";

import { FOLDER_COLORS } from "../colors";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (...args: any[]) => any;
}

const FolderCreateForm = ({ onSubmit }: Props) => {
  const [title, setTitle] = useState("");
  const [selectedColor, setSelectedColor] = useState(null);

  const isSubmitDisabled = !title || !selectedColor;

  const handleSubmit = () => onSubmit({ title, color_name: selectedColor });

  return (
    <div
      className="table-dropdown folder-form"
      data-testid="folder-create-form"
    >
      <div className="form-row">
        <div className="form-label">Folder Name</div>
        <div className="form-element">
          <input
            type="text"
            placeholder="Enter a name"
            value={title}
            data-testid="folder-name-input"
            onChange={(evt) => setTitle(evt.target.value)}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-label">Select Folder Color</div>
        <div className="form-element">
          <ul
            className="folder-color-selector"
            data-testid="folder-color-selector"
          >
            {FOLDER_COLORS.map((color) => (
              <li
                key={color}
                data-testid="folder-color-selector-option"
                className={classNames(color, {
                  active: selectedColor === color,
                })}
                // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                onClick={() => setSelectedColor(color)}
              />
            ))}
          </ul>
        </div>
      </div>

      <div className="form-row">
        <button
          className="form-submit-button"
          data-testid="form-submit-button"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
        >
          Create Folder
        </button>
      </div>
    </div>
  );
};

export default FolderCreateForm;

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const CustomerSupportReportContent = () => (
  <ReportsPage
    identifier="customer_support"
    defaultDateColumn="date_received"
    defaultGroupColumn="customer_support_status"
    tableName="Customer Support"
  />
);

export default observer(CustomerSupportReportContent);

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Header from "../components/Header";

function ExternalReview() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [requestErrorMessage, setRequestErrorMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);

  // @ts-expect-error TS(2339) FIXME: Property 'externalToken' does not exist on type '{... Remove this comment to see the full error message
  const { externalToken } = useParams();
  // @ts-expect-error TS(2339) FIXME: Property 'reviewType' does not exist on type '{}'.
  const { reviewType } = useParams();
  // @ts-expect-error TS(2339) FIXME: Property 'userToken' does not exist on type '{}'.
  const { userToken } = useParams();
  const dictionary = { approval: "approved", denial: "denied" };
  const params = { userToken, externalToken, reviewType };

  // Hooks
  useEffect(() => {
    mainStore.reviews
      .publicCreate(params)
      .then((response) => {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (response.status === 201) {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
          setIsSuccess(true);
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        } else if (response.status === 422) {
          handleFailure(
            "You already submitted a review for this conflict of interest. Please contact your Compliance Officer if you need to change it.",
          );
        } else {
          handleFailure(
            "Something went wrong. Please try again or contact support@themis.com",
          );
        }
      })
      .catch((error) => {
        if (error.status === 422) {
          handleFailure(
            "You already submitted a review for this conflict of interest. Please contact your Compliance Officer if you need to change it.",
          );
        } else {
          handleFailure(
            "Something went wrong. Please try again or contact support@themis.com",
          );
        }
      });
  }, []);

  // @ts-expect-error TS(7006) FIXME: Parameter 'errorMessage' implicitly has an 'any' t... Remove this comment to see the full error message
  function handleFailure(errorMessage) {
    window.console.log(errorMessage);
    setRequestErrorMessage(errorMessage);
  }

  return (
    <div className="submit-conflict">
      <div className="check-in">
        <Header />
        <div className="check-in-wrap">
          <div className="password-wrap">
            <h2>Review Submission</h2>
            {requestErrorMessage && <p>{requestErrorMessage}</p>}

            {isSuccess && (
              <p>
                {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                You have successfully {dictionary[reviewType]} this request. If
                you need to change your review, please contact your compliance
                team.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ExternalReview);

export enum TopLevelModule {
  AUDITS = "audits",
  CHANGE_MANAGEMENT = "new_product_approval",
  COMPLAINTS = "complaints",
  CONFLICTS_OF_INTEREST = "conflicts_of_interest",
  CONTROL_MAPPING = "control_mapping",
  CUSTOMER_SUPPORT = "customer_support",
  DOCUMENTS = "documents",
  FINRA_COMPLAINTS = "finra",
  ISSUE_MANAGEMENT = "issue_management",
  MARKETING = "marketing",
  MONITORING_AND_TESTING = "qa_tests_development",
  POLICY = "policy",
  PROCEDURES = "procedures",
  RISK_ASSESSMENT = "risk_assessment",
  RISK_REGISTER = "risk_register",
  TRAINING = "training",
  VENDOR_DUE_DILIGENCE = "vendor_due_diligence",
  ZENDESK = "zendesk",
  KEY_RISK_INDICATORS = "key_risk_indicators",
  WEBSITE_MONITORING = "website_monitoring",
  ACCOUNTS = "accounts",
}
export enum Submodule {
  ACTION_PLANS = "action_plans",
  LINKED_DOCUMENTS = "linked_documents",
  QA_FINDINGS = "qa_findings",
  QA_PROCEDURES = "qa_procedures",
  SAMPLES = "samples",
  QUESTIONNAIRES = "questionnaires",
  VALUES = "values",
}

export type ModuleWorkspaceIdentifier =
  | "policy"
  | "procedures"
  | "marketing"
  | "new_product_approval"
  | "documents"
  | "vendor_due_diligence"
  | "complaints"
  | "audits"
  | "qa_tests_development"
  | "training"
  | "issue_management"
  | "conflicts_of_interest"
  | "risk_register"
  | "control_mapping"
  | "risk_assessment"
  | "zendesk"
  | "key_risk_indicators"
  | "finra"
  | "customer_support"
  | "change_management"
  | "monitoring_and_testing"
  | "record_retention"
  | "action_plans"
  | "linked_documents"
  | "qa_findings"
  | "qa_procedures"
  | "samples"
  | "questionnaires"
  | "values"
  | "accounts"
  | "website_monitoring";

export type TopLevelModuleIdentifier = `${TopLevelModule}`;
export type ModuleIdentifier = `${TopLevelModule | Submodule}`;
export type SubmoduleIdentifier = `${Submodule}`;

export interface ModuleInfo {
  url: string;
  basePath?: string;
  name: string;
  description: string;
  badge?: boolean;
}

export type TopLevelModuleIdentifierToModuleInfoMap = Record<
  TopLevelModuleIdentifier,
  ModuleInfo
>;

export type SubModuleIdentifierToModuleInfoMap = Record<
  SubmoduleIdentifier,
  ModuleInfo
>;

export type TopAndSubModuleToModuleInfoMap =
  TopLevelModuleIdentifierToModuleInfoMap & SubModuleIdentifierToModuleInfoMap;

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const IssueManagementReportContent = () => (
  <ReportsPage
    identifier="issue_management"
    defaultDateColumn="date_identified"
    defaultGroupColumn="status"
  />
);

export default observer(IssueManagementReportContent);

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const PoliciesReportContent = () => (
  <ReportsPage
    identifier="policy"
    defaultDateColumn="effective_date"
    defaultGroupColumn="status"
  />
);

export default observer(PoliciesReportContent);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListRecordFindingsPathParams,
  ListRecordFindingsQueryParams,
  ListRecordFindingsQueryResponse,
} from "../../models/ListRecordFindings";

/**
 * @description List Findings for Record
 * @summary List record findings
 * @link /records/:record_id/findings
 */
export async function listRecordFindings(
  recordId: ListRecordFindingsPathParams["record_id"],
  params?: ListRecordFindingsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListRecordFindingsQueryResponse>["data"]> {
  const res = await client<ListRecordFindingsQueryResponse>({
    method: "get",
    url: `/records/${recordId}/findings`,
    params,
    ...options,
  });
  return res.data;
}

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setName?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setWorkspaceStep?: (...args: any[]) => any;
}

const NameWorkspace = ({ setName, setWorkspaceStep }: Props) => {
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const buttonActive = classNames({ active: isFormEnabled });

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function onNameChange(event) {
    const newName = event.target.value;
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setName(newName);
    setIsFormEnabled(newName.length > 0);
  }

  return (
    <div className="workspace-name-wrap">
      <p>Name your new workspace</p>
      <input
        onChange={onNameChange}
        placeholder="eg. Bank + Fintech, Company X, Due diligence docs"
      />
      <button
        className={buttonActive}
        disabled={!isFormEnabled}
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        onClick={() => setWorkspaceStep(2)}
      >
        Continue
      </button>
    </div>
  );
};

export default observer(NameWorkspace);

export const contractsFields = [
  {
    field_name: "account_name",
    display_name: "Account name",
    data_type: "string",
    sortable: false,
  },
  {
    field_name: "renewal",
    display_name: "Renewal Option",
    data_type: "select",
    sortable: true,
  },
  {
    field_name: "file",
    display_name: "Vendor Contract",
    data_type: "file",
    sortable: false,
  },
  {
    field_name: "start_date",
    display_name: "Contract Start Date",
    data_type: "date",
    sortable: true,
  },
  {
    field_name: "review_date",
    display_name: "Contract Review Date",
    data_type: "date",
    sortable: true,
  },
  {
    field_name: "end_date",
    display_name: "Contract End Date",
    data_type: "date",
    sortable: true,
  },
  {
    field_name: "terms",
    display_name: "Terms of Termination Notice",
    data_type: "select",
    width: 250,
    sortable: true,
  },
  {
    field_name: "notes",
    display_name: "Vendor Notes",
    data_type: "textarea",
    sortable: true,
  },
];

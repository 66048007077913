import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";

interface DynamicSizeInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  defaultWidth: number | string;
  maxLength?: number;
  dataTestID?: string;
  className?: string;
}

const DynamicSizeInput = (props: DynamicSizeInputProps) => {
  const [width, setWidth] = useState(props.defaultWidth);
  const widthCheck = useRef<HTMLSpanElement>(null);
  const inputClasses = classnames("dynamic-size-input__input", props.className);

  useEffect(() => {
    if (widthCheck) {
      setWidth(
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        props.value ? widthCheck.current.clientWidth : props.defaultWidth,
      );
    }
  }, [props.value]);

  return (
    <>
      <input
        className={inputClasses}
        placeholder={props.placeholder}
        onChange={(ev) => props.onChange(ev.target.value)}
        value={props.value || ""}
        style={{ width: typeof width === "number" ? `${width}px` : width }}
        spellCheck={false}
        autoFocus
        maxLength={props.maxLength}
        data-testid={props.dataTestID}
      />
      <span
        className="dynamic-size-input__width-check"
        ref={widthCheck}
        aria-hidden
      >
        {/* Used to dynamically determine how wide the input field should be */}
        {/* Drawn off screen and hidden from screen readers */}
        {props.value}
      </span>
    </>
  );
};

export default DynamicSizeInput;

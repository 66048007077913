import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import ChecklistQuestion from "@/features/vendor-due-diligence/components/VendorChecklistQuestion";
import VendorQuestionnaireHighlights from "@/features/vendor-due-diligence/components/VendorQuestionnaireHighlights";
import { canCompleteChecklist } from "@/features/vendor-due-diligence/helpers";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

const SUCCESS_MSG_TIMEOUT = 2000;

function QuestionnaireComplete() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [checklist, setChecklist] = useState(null);
  const [moduleWorkspaceID, setModuleWorkspaceID] = useState(null);
  const [savedItem, setSavedItem] = useState(null);

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'checklist_id' does not exist on type '{}... Remove this comment to see the full error message
  const { checklist_id } = useParams();
  const { active_workspace: workspace } = mainStore.users.user;
  const { workspaceID } = mainStore.context;
  const { currentVendor } = mainStore.vendors;
  const history = useHistory();
  const status = mainStore.avroSchemas.valueForField(
    "status",
    currentVendor?.data,
  );
  const isVendorActive = status !== "disabled";
  // @ts-expect-error TS(2339) FIXME: Property 'completed_at' does not exist on type 'ne... Remove this comment to see the full error message
  const isQuestionnaireCompleted = !isVendorActive || checklist?.completed_at;
  const isButtonEnabled = canCompleteChecklist(
    // @ts-expect-error TS(2339) FIXME: Property 'checklist_questions' does not exist on t... Remove this comment to see the full error message
    checklist?.checklist_questions || [],
  );

  // Effects
  useEffect(() => {
    getQuestionnaire();
  }, [checklist_id, mainStore.moduleWorkspaces.list]);

  async function getQuestionnaire() {
    if (!checklist_id) {
      return;
    }

    const id = mainStore.moduleWorkspaces.list?.find(
      (mw) =>
        mw.themis_module?.identifier === TopLevelModule.VENDOR_DUE_DILIGENCE,
    )?.id;
    const newChecklist = await mainStore.vendors.fetchChecklist(
      id,
      checklist_id,
    );

    setChecklist(newChecklist);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    setModuleWorkspaceID(id);
  }

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async function handleUpdateChecklistQuestion(id, data) {
    const updatedChecklistQuestion =
      await mainStore.vendors.updateChecklistQuestion(
        moduleWorkspaceID,
        id,
        data,
      );
    if (updatedChecklistQuestion) {
      setChecklist({
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        ...checklist,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        checklist_questions: checklist.checklist_questions.map((cq) =>
          cq.id === id ? updatedChecklistQuestion : cq,
        ),
      });
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function handleSwitchClick(id, currentAnswer) {
    let answer = null;

    if (!currentAnswer) {
      answer = "Yes";
    } else if (currentAnswer === "Yes") {
      answer = "No";
    }

    const data = { answer };

    handleUpdateChecklistQuestion(id, data);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function handleNAClick(id, e) {
    const { checked } = e.target;
    const answer = checked ? "N/A" : null;
    const data = { answer };

    handleUpdateChecklistQuestion(id, data);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleLocalOnBlur(e, item) {
    const { value } = e.target;
    const { id, comment } = item;
    const data = { comment: value };
    if (value === comment) {
      return;
    }

    setSavedItem(id);
    setTimeout(() => setSavedItem(null), SUCCESS_MSG_TIMEOUT);
    handleUpdateChecklistQuestion(id, data);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
  async function handleAttachment(file, id) {
    const data = { file };

    handleUpdateChecklistQuestion(id, data);
  }

  async function handleComplete() {
    await mainStore.vendors.completeChecklist(moduleWorkspaceID, checklist_id);

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const redirectURL = workspace.is_internal
      ? // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        `/workspaces/${workspaceID}/modules/vendor-due-diligence/${checklist.vendor_id}/questionnaires`
      : `/workspaces/${workspaceID}/modules/vendor-due-diligence/active`;
    history.push(redirectURL);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function downloadFile(id, filename) {
    mainStore.vendors.fetchChecklistQuestionAttachment({
      checklistQuestionID: id,
      moduleWorkspaceID,
      filename,
      downloadMode: true,
    });
  }

  // Elements
  const renderHeading = (
    <div className="heading" data-testid="checklist-complete-sidebar-header">
      {/* @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'. */}
      <h3>{checklist?.name}</h3>
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const renderChecklistQuestionItem = (item) => (
    <ChecklistQuestion
      item={item}
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
      savedItem={savedItem}
      isQuestionnaireCompleted={isQuestionnaireCompleted}
      handleSwitchClick={handleSwitchClick}
      handleNAClick={handleNAClick}
      handleLocalOnBlur={handleLocalOnBlur}
      handleAttachment={handleAttachment}
      downloadFile={downloadFile}
    />
  );

  if (!checklist) {
    return null;
  }

  return (
    <section className="vdd-complete-questionnaire">
      <VendorQuestionnaireHighlights
        // @ts-expect-error TS(2339) FIXME: Property 'checklist_questions' does not exist on t... Remove this comment to see the full error message
        checklistQuestions={checklist?.checklist_questions || []}
      />
      <section
        className="checklists-sidebar"
        data-testid="checklist-complete-sidebar"
      >
        {renderHeading}
        {/* @ts-expect-error TS(2339) FIXME: Property 'checklist_questions' does not exist on t... Remove this comment to see the full error message */}
        {checklist.checklist_questions.map(renderChecklistQuestionItem)}
        {!isQuestionnaireCompleted && (
          <button
            className="table-button vdd-complete-questionnaire-submit"
            onClick={handleComplete}
            disabled={!isButtonEnabled}
          >
            Complete
          </button>
        )}
      </section>
    </section>
  );
}

export default observer(QuestionnaireComplete);

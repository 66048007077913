import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const SunLinear = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7071 2.29289L12.6129 2.2097L12.5114 2.14038C12.1275 1.91161 11.6233 1.96245 11.2929 2.29289L8.584 5H6L5.88338 5.00673C5.38604 5.06449 5 5.48716 5 6V8.584L2.29289 11.2929L2.2097 11.3871C1.90468 11.7794 1.93241 12.3466 2.29289 12.7071L5 15.415V18L5.00673 18.1166C5.06449 18.614 5.48716 19 6 19H8.585L11.2929 21.7071L11.3871 21.7903C11.7794 22.0953 12.3466 22.0676 12.7071 21.7071L15.414 19H18L18.1166 18.9933C18.614 18.9355 19 18.5128 19 18V15.414L21.7071 12.7071L21.7903 12.6129C22.0953 12.2206 22.0676 11.6534 21.7071 11.2929L19 8.585V6L18.9933 5.88338C18.9355 5.38604 18.5128 5 18 5H15.415L12.7071 2.29289ZM7 12C7 9.23858 9.23858 7 12 7C14.7614 7 17 9.23858 17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12Z"
      fill="#353549"
    />
  </svg>
);

SunLinear.displayName = "SunLinearIcon";

export default SunLinear;

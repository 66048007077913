import "./styles.scss";

import classNames from "classnames";
import React from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";

type SelectFieldProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string;
};

function MessageField<T extends FieldValues>({
  placeholder,
  ...controller
}: SelectFieldProps<T>) {
  // Variables
  const { field, fieldState } = useController(controller);

  return (
    <div className="notification-message">
      {field.name === "title" && (
        <input
          value={field.value}
          placeholder={placeholder}
          onChange={field.onChange}
          className={classNames("notification-message__field", {
            "notification-message__error": fieldState.error,
          })}
        />
      )}
      {field.name === "message" && (
        <TextareaAutosize
          value={field.value}
          placeholder={placeholder}
          onChange={field.onChange}
          className={classNames(
            "notification-message__field notification-message__textarea-field",
            {
              "notification-message__error": fieldState.error,
            },
          )}
          minRows={1}
          maxRows={12}
        />
      )}
    </div>
  );
}

export default MessageField;

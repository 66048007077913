import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "@/components/form/Button";
import EmailInput from "@/components/form/EmailInput";
import Header from "@/components/header/Header";
import { useMainStore } from "@/contexts/Store";
import gmailIcon from "@/images/auth/gmail.svg";
import letterIcon from "@/images/auth/letter.svg";

function InviteTeam() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [emails, setEmails] = useState([null, null, null]);
  const [emailErrors, setEmailErrors] = useState([null, null, null]);

  // functions
  function enterLegalAgreement() {
    history.push("/legals");
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleEmailChange(e, index) {
    e.preventDefault();
    const { value } = e.target;

    const newEmails = emails.map((email, i) => (i === index ? value : email));
    setEmails(newEmails);

    validateEmail(value, index);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function validateEmail(value, index) {
    const validEmailRegex = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    );
    const newValue =
      validEmailRegex.test(value) || value === ""
        ? null
        : "Should be valid email";
    const newErrors = emailErrors.map((error, i) =>
      i === index ? newValue : error,
    );

    // @ts-expect-error TS(2345) FIXME: Argument of type '("Should be valid email" | null)... Remove this comment to see the full error message
    setEmailErrors(newErrors);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleSubmit(e) {
    e.preventDefault();
    if (emailErrors.some((item) => item)) {
      return;
    }

    const filledEmails = emails.filter((item) => item);
    mainStore.users.inviteUsers(filledEmails, company.id);
    enterLegalAgreement();
  }

  // Variables
  const { company } = mainStore.companies;
  const history = useHistory();
  const isSubmitEnabled =
    emailErrors.every((item) => !item) && emails.some((item) => item);

  // elements
  const renderSelectFrom = (
    <div className="select-from">
      <p>Select from</p>
      <div>
        <img src={letterIcon} alt="letter" />
      </div>
      <div>
        <img src={gmailIcon} alt="gmail" />
      </div>
    </div>
  );

  const renderEmailInputs = [0, 1, 2].map((index) => (
    <React.Fragment key={index}>
      <EmailInput
        placeholder={`Work Email Address #${index + 1}`}
        name={`email-${index + 1}`}
        onChange={(e) => handleEmailChange(e, index)}
      />
      {emailErrors[index] && (
        <p className="error-input">{emailErrors[index]}</p>
      )}
    </React.Fragment>
  ));

  return (
    <div className="invite-team-wrap">
      <div className="container">
        <Header createName="Invite Team Member" value={100} />
        <div className="invite-team">
          <h3>Invite Team Member</h3>
          <p>
            Invite team members to create, edit and share work with you during
            your free trial.
          </p>
          {renderSelectFrom}

          <form onSubmit={handleSubmit}>
            {renderEmailInputs}

            <Button title="Confirm" enabled={isSubmitEnabled} />
            <Button title="Skip This" onClick={enterLegalAgreement} />
          </form>
        </div>
      </div>
    </div>
  );
}

export default observer(InviteTeam);

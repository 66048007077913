export const tableName = {
  Drafts: "Drafts",
  Finalized: "Finalized",
  Archived: "Archived",
  PoliciesAttestation: "PoliciesAttestation",
  ProceduresDrafts: "ProceduresDrafts",
  ProceduresFinalized: "ProceduresFinalized",
  ProceduresArchived: "ProceduresArchived",
  ProceduresAttestation: "ProceduresAttestation",
  Default: "Default",
  Completed: "Completed",
  "FINRA Review": "FINRA Review",
  "FINRA Escalated": "FINRA Escalated",
  "Customer Support": "Customer Support",
  Compliance: "Compliance",
  Partners: "Partners",
  RelatedRisks: "RelatedRisks",
  OperationalControls: "OperationalControls",
  Active: "Active",
  Inactive: "Inactive",
} as const;
export type TableName = (typeof tableName)[keyof typeof tableName];

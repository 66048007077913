import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, } from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis, } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy, } from "@dnd-kit/sortable";
import React, { useState } from "react";
import { SortableItem } from "./SortableItem";
function SortableList(_a) {
    var items = _a.items, classes = _a.classes, className = _a.className, renderItem = _a.renderItem, onReorder = _a.onReorder;
    var _b = useState(items), localItems = _b[0], setLocalItems = _b[1];
    var sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    }));
    function handleDragEnd(event) {
        var active = event.active, over = event.over;
        if (active.id !== (over === null || over === void 0 ? void 0 : over.id)) {
            setLocalItems(function (prev) {
                var oldIndex = prev.findIndex(function (it) { return it.id === active.id; });
                var newIndex = prev.findIndex(function (it) { return it.id === (over === null || over === void 0 ? void 0 : over.id); });
                var newArray = arrayMove(prev, oldIndex, newIndex);
                onReorder === null || onReorder === void 0 ? void 0 : onReorder(newArray);
                return newArray;
            });
        }
    }
    return (React.createElement(DndContext, { autoScroll: true, modifiers: [restrictToVerticalAxis, restrictToParentElement], sensors: sensors, collisionDetection: closestCenter, onDragEnd: handleDragEnd },
        React.createElement(SortableContext, { items: localItems, strategy: verticalListSortingStrategy },
            React.createElement("div", { className: className }, localItems.map(function (item) { return (React.createElement(SortableItem, { key: item.id, className: classes === null || classes === void 0 ? void 0 : classes.item, id: item.id }, renderItem(item))); })))));
}
export { SortableList };

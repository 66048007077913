import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteContractMutationResponse,
  DeleteContractPathParams,
} from "../../models/DeleteContract";

/**
 * @description Delete contract
 * @summary Delete contract
 * @link /contracts/:id
 */
export async function deleteContract(
  id: DeleteContractPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteContractMutationResponse>["data"]> {
  const res = await client<DeleteContractMutationResponse>({
    method: "delete",
    url: `/contracts/${id}`,
    ...options,
  });
  return res.data;
}

import { useMutation, useQuery } from "@tanstack/react-query";
import { useToast } from "@themis/ui";

import api from "@/api/legacy/api";

export function useListRegisters(workspaceId: number | string) {
  return useQuery({
    queryKey: ["risk_registers", workspaceId],
    queryFn: () =>
      api
        .get(`/workspaces/${workspaceId}/risk_registers/list_registers`)
        .then((res) => res.data?.data),
  });
}

export function useListRiskRegisterGroups(workspaceId: number | string) {
  return useQuery({
    queryKey: ["risk_register_groups", workspaceId],
    queryFn: () =>
      api
        .get(`/workspaces/${workspaceId}/risk_registers/risk_register_groups`)
        .then((res) => res.data?.data),
  });
}

export function useCreateRiskRegisterGroup(workspaceId: number | string) {
  return useMutation({
    mutationFn: (newRiskRegisterGroup: {
      name: string;
      riskRegisterIds: number[];
    }) =>
      api
        .post(
          `/workspaces/${workspaceId}/risk_registers/risk_register_groups`,
          {
            name: newRiskRegisterGroup.name,
            risk_register_ids: newRiskRegisterGroup.riskRegisterIds,
          },
        )
        .then((res) => res.data),
  });
}

export function useUpdateRiskRegisterGroup(workspaceId: number | string) {
  return useMutation({
    mutationFn: (update: {
      id: number;
      name?: string;
      risk_register_group_items_attributes?: {
        id: number;
        name?: string;
        weight?: number;
      }[];
    }) =>
      api
        .put(
          `/workspaces/${workspaceId}/risk_registers/risk_register_groups/${update.id}`,
          {
            ...update,
          },
        )
        .then((res) => res.data),
  });
}

export function useAddRiskRegistersToRegisterGroup(
  workspaceId: number | string,
) {
  return useMutation({
    mutationFn: (params: { groupId: number; registerIds: number[] }) =>
      api
        .post(
          `/workspaces/${workspaceId}/risk_registers/risk_register_groups/${params.groupId}/risk_register_group_items/`,
          {
            risk_register_ids: params.registerIds,
          },
        )
        .then((res) => res.data),
  });
}

export function useDeleteRegisterFromGroup(workspaceId: number | string) {
  return useMutation({
    mutationFn: (params: { groupId: number; registerId: number }) =>
      api.delete(
        `/workspaces/${workspaceId}/risk_registers/risk_register_groups/${params.groupId}/risk_register_group_items/${params.registerId}`,
      ),
  });
}

export function useDeleteRiskRegisterGroup(workspaceId: number | string) {
  return useMutation({
    mutationFn: (id: number) =>
      api.delete(
        `/workspaces/${workspaceId}/risk_registers/risk_register_groups/${id}`,
      ),
  });
}

export function exportRiskRegisterGroups(
  workspaceID: number | string,
  exportedGroupId?: number,
) {
  api
    .get(
      `/workspaces/${workspaceID}/risk_registers/risk_register_groups/export_scores`,
      {
        params: {
          exported_group_id: exportedGroupId,
        },
      },
    )
    .then((response) => {
      const blob = new Blob([new Uint8Array(response.data)], {
        type: "application/octet-stream",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "risk_register_group_scores.xlsx";
      link.click();
    })
    .catch((error) => {
      const errorMessage =
        error.response?.data?.errors?.base?.[0] ||
        "Something went wrong. Please try again or contact support@themis.com";

      const toast = useToast();
      toast({
        content: errorMessage,
        variant: "error",
      });
    });
}

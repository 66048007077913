import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import Table from "../../table/Table";
import CompanySettingsNavigation from "../CompanySettingsNavigation";
import TableContents from "./TableContents";

function Modules() {
  // Import MobX stores
  const mainStore = useMainStore();

  return (
    <Table>
      <div className="settings-wrap">
        <CompanySettingsNavigation />
        {mainStore.users.user.active_workspace && <TableContents />}
      </div>
    </Table>
  );
}

export default observer(Modules);

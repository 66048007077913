import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateFollowupQuestionMutationRequest,
  CreateFollowupQuestionMutationResponse,
} from "../../models/CreateFollowupQuestion";

/**
 * @description Create followup question
 * @summary Create followup question
 * @link /followup_questions
 */
export async function createFollowupQuestion(
  data: CreateFollowupQuestionMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateFollowupQuestionMutationResponse>["data"]> {
  const res = await client<
    CreateFollowupQuestionMutationResponse,
    CreateFollowupQuestionMutationRequest
  >({
    method: "post",
    url: `/followup_questions`,
    data,
    ...options,
  });
  return res.data;
}

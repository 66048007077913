import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Forward = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 19C14 19.8897 15.0608 20.3192 15.681 19.733L15.7593 19.6508L21.7593 12.6508C22.0535 12.3075 22.078 11.8146 21.8328 11.4463L21.7593 11.3492L15.7593 4.3492C15.1802 3.67367 14.0953 4.03794 14.0059 4.88659L14 4.99999V7.99999L12.962 8.08649C6.86586 8.5945 2.15411 13.6166 2.00371 19.7L2 20L4.65878 18.4807C7.36138 16.9363 10.4024 16.0874 13.509 16.0064L14 16V19Z"
      fill="#353549"
    />
  </svg>
);

Forward.displayName = "ForwardIcon";

export default Forward;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import type { Field, RecordVersion } from "@/api";
import { attachmentFileType } from "@/api";
import { Icon } from "@/components/Elements";
import {
  buildUploadPayload,
  getAllAttachments,
  maxAttachmentVersion,
} from "@/components/helpers/AttachmentGroupsHelper";
import UploadDate from "@/components/table/shared/UploadDate";
import { useMainStore } from "@/contexts/Store";

import editActiveIcon from "../../../../images/table-image/icon/edit-icon-blue.svg";
import editIcon from "../../../../images/table-image/icon/edit-icon.svg";
import resyncActiveIcon from "../../../../images/table-image/icon/resync-blue.svg";
import resyncIcon from "../../../../images/table-image/icon/resync.svg";
import trashActiveIcon from "../../../../images/table-image/icon/trash-icon-blue.svg";
import trashIcon from "../../../../images/table-image/icon/trash-icon.svg";
import DirectUpload from "../DirectUpload";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchFile: (...args: any[]) => any;
  handleChangeVersion: (
    newAttachmentID: number,
    newCommentsMode?: boolean,
  ) => Promise<void>;
  field?: Field;
  recordVersion?: RecordVersion;
  isMarketingModule: boolean;
}

function CreativeViewFooter({
  recordVersion,
  field,
  isMarketingModule,
  handleChangeVersion,
  fetchFile,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [upload, setUpload] = useState("");
  const [isReplaceModalOpen, setIsReplaceModalOpen] = useState(false);
  const [isUploadNewModalOpen, setIsUploadNewModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // vars
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { attachmentID, attachmentGroupID } = mainStore.files;
  const { list: attachmentGroups } = mainStore.attachmentGroups;
  const { workspaceID } = mainStore.context;
  const history = useHistory();
  const allAttachments = getAllAttachments(attachmentGroups);
  const currentAttachment = allAttachments.find(
    (attachment) => attachment.id === attachmentID,
  );
  const currentAttachmentGroup = attachmentGroups.find(
    (attachmentGroup) => attachmentGroup.id === attachmentGroupID,
  );
  const latestAttachmentVersion = maxAttachmentVersion(
    currentAttachmentGroup?.attachments,
  );
  const isLatestVersion =
    currentAttachment?.version === latestAttachmentVersion;

  const currentUserID = mainStore.users.user.id;

  const approversIDs = mainStore.avroSchemas.valueForField(
    "approvers",
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    recordVersion.data,
  );
  const isApprover = approversIDs?.includes(currentUserID);

  const submittersIDs = mainStore.avroSchemas.valueForField(
    "submitter",
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    recordVersion.data,
  );
  const isSubmitter = submittersIDs?.includes(currentUserID);
  const showAdditionalButtonsInMarketing =
    isMarketingModule &&
    field?.name === "creative" &&
    !(!isApprover && recordVersion?.status === "reviewing") &&
    !(!isSubmitter && recordVersion?.status !== "reviewing");

  const status = recordVersion?.status;
  const isLocked = Boolean(
    status && ["approved", "rejected", "completed"].includes(status),
  );
  const shouldRenderAdditionalButtons =
    isCurrentWorkspaceActive &&
    isLatestVersion &&
    (showAdditionalButtonsInMarketing ||
      (!isMarketingModule && field?.is_file_versioning_enabled));

  const documentUploadDate = currentAttachment?.created_at;
  // functions
  function onReplaceModalOpen() {
    setIsReplaceModalOpen(true);
  }

  function onReplaceModalClose() {
    setUpload("");
    setIsReplaceModalOpen(false);
  }

  function onUploadNewModalOpen() {
    setIsUploadNewModalOpen(true);
  }

  function onUploadNewModalClose() {
    setUpload("");
    setIsUploadNewModalOpen(false);
  }

  function onDeleteModalOpen() {
    setIsDeleteModalOpen(true);
  }

  function onDeleteModalClose() {
    setIsDeleteModalOpen(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'signedIDs' implicitly has an 'any' type... Remove this comment to see the full error message
  async function handleAttachment(signedIDs, replacedAttachmentID) {
    const [signedID] = signedIDs;
    const recordVersionID = recordVersion?.id;
    if (!recordVersionID) {
      return;
    }

    if (!replacedAttachmentID || replacedAttachmentID === -1) {
      // Replace existing Attachment
      const fileType = attachmentFileType.direct_upload;

      if (!field) {
        return;
      }

      const payload = buildUploadPayload(field?.name, fileType, signedID);
      if (!payload) {
        return;
      }

      const attachmentGroup = await mainStore.attachmentGroups.create({
        recordVersionID,
        payload,
      });

      const newAttachmentID = attachmentGroup?.attachments[0]?.id;
      if (newAttachmentID) {
        await handleChangeVersion(newAttachmentID);
      }
    } else {
      // Create new Attachment within the same AttachmentGroup
      if (attachmentGroupID) {
        const newAttachment = await mainStore.attachments.create({
          attachmentGroupID,
          signedID,
        });
        await handleChangeVersion(newAttachment.id);
      }
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  async function handleReplaceAttachment(ids) {
    await handleAttachment(ids, attachmentID);
    onReplaceModalClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  async function handleUploadNewAttachment(ids) {
    await handleAttachment(ids, -1);
    onUploadNewModalClose();
  }

  async function removeFileHandler() {
    onDeleteModalClose();

    const currentAttachments = currentAttachmentGroup
      ? getAllAttachments([currentAttachmentGroup])
      : [];

    if (attachmentID) {
      await mainStore.attachments.delete(attachmentID);
    }

    // Go to the last Attachment before the one that was deleted
    if (currentAttachments.length > 1) {
      const nextAttachmentID =
        currentAttachments[currentAttachments.length - 2].id;
      return fetchFile(nextAttachmentID);
    }

    // Go to Attachment from a different AttachmentGroup if the whole AttachmentGroups was deleted
    if (allAttachments.length > 1) {
      const nextAttachmentID = allAttachments[0].id;
      return fetchFile(nextAttachmentID);
    }

    history.push(`/workspaces/${workspaceID}/modules/marketing`);
  }

  // elements
  const renderDownloadButton = (
    <div
      className="cv-footer-button-popup-trigger"
      data-testid="cv-footer-button-delete-creative"
    >
      <a
        target="_blank"
        href={currentAttachment?.original?.url}
        rel="noreferrer"
        className="integration-icon-link"
        data-testid="download-attachment-link"
      >
        <Icon name="download" spaceRight />
        <span className="text">Download</span>
      </a>
    </div>
  );

  const renderReplaceButtonTrigger = (
    <div
      className={classNames("cv-footer-button-popup-trigger", {
        active: isReplaceModalOpen,
      })}
      data-testid="cv-footer-button-replace-creative"
    >
      <img src={resyncIcon} alt="resync" />
      <img src={resyncActiveIcon} className="active" alt="resync-active" />
      <span className="text">Upload New Version</span>
    </div>
  );

  const renderUploadNewButtonTrigger = (
    <div
      className={classNames("cv-footer-button-popup-trigger", {
        active: isUploadNewModalOpen,
      })}
      data-testid="cv-footer-button-upload-new-attachment"
    >
      <img src={editIcon} alt="edit" />
      <img src={editActiveIcon} className="active" alt="edit-active" />
      <span className="text">Upload New File</span>
    </div>
  );

  const renderDeleteButtonTrigger = (
    <div
      className={classNames("cv-footer-button-popup-trigger", {
        active: isDeleteModalOpen,
      })}
      data-testid="cv-footer-button-delete-creative"
    >
      <img src={trashIcon} alt="trash" />
      <img src={trashActiveIcon} className="active" alt="trash-active" />
      <span className="text">Delete Attachment</span>
    </div>
  );

  return (
    <section
      className="creative-view-footer"
      data-testid="creative-view-footer"
    >
      {/* @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
      <UploadDate date={documentUploadDate} />
      {shouldRenderAdditionalButtons && !isLocked && (
        <>
          {renderDownloadButton}
          {field?.is_multiselect && (
            <Popup
              position="top center"
              trigger={renderUploadNewButtonTrigger}
              open={isUploadNewModalOpen}
              onOpen={onUploadNewModalOpen}
              onClose={onUploadNewModalClose}
              keepTooltipInside
            >
              <DirectUpload
                attachmentUpload={handleUploadNewAttachment}
                upload={upload}
                setUpload={setUpload}
              />
            </Popup>
          )}

          <Popup
            position="top center"
            trigger={renderReplaceButtonTrigger}
            open={isReplaceModalOpen}
            onOpen={onReplaceModalOpen}
            onClose={onReplaceModalClose}
            keepTooltipInside
          >
            <DirectUpload
              attachmentUpload={handleReplaceAttachment}
              upload={upload}
              setUpload={setUpload}
            />
          </Popup>

          <Popup
            position="top center"
            trigger={renderDeleteButtonTrigger}
            open={isDeleteModalOpen}
            onOpen={onDeleteModalOpen}
            onClose={onDeleteModalClose}
            keepTooltipInside
          >
            <div
              className="select-link select-link-minimum-witdh delete-confirmation"
              data-testid="cv-footer-delete-confirmation-popup"
            >
              <h3>You are about to remove the version</h3>
              <p>
                After removing the version, you cannot undo this action. The
                comments and replies from the version will be wiped off.
              </p>
              <div className="confirmation">
                <button onClick={onDeleteModalClose}>No</button>
                <button
                  onClick={removeFileHandler}
                  data-testid="cv-footer-delete-confirmation-popup-confirm"
                >
                  Yes
                </button>
              </div>
            </div>
          </Popup>
        </>
      )}
    </section>
  );
}

CreativeViewFooter.defaultProps = {
  recordVersion: {},
};

export default observer(CreativeViewFooter);

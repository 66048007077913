import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ArrowDown = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C12.5523 2 13 2.44772 13 3V18.5858L17.2929 14.2929C17.6834 13.9024 18.3166 13.9024 18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929C5.68342 13.9024 6.31658 13.9024 6.70711 14.2929L11 18.5858V3C11 2.44772 11.4477 2 12 2Z"
      fill="#353549"
    />
  </svg>
);

ArrowDown.displayName = "ArrowDownIcon";

export default ArrowDown;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Pin1 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 7C18 10.0425 15.7355 12.556 12.8 12.9471V22.2C12.8 22.6418 12.4418 23 12 23C11.5582 23 11.2 22.6418 11.2 22.2V12.9471C8.26452 12.556 6 10.0425 6 7C6 3.68629 8.68629 1 12 1C15.3137 1 18 3.68629 18 7ZM14.5666 6.84286C15.395 6.84286 16.0666 6.17129 16.0666 5.34286C16.0666 4.51444 15.395 3.84286 14.5666 3.84286C13.7382 3.84286 13.0666 4.51444 13.0666 5.34286C13.0666 6.17129 13.7382 6.84286 14.5666 6.84286Z"
      fill="#353549"
    />
  </svg>
);

Pin1.displayName = "Pin1Icon";

export default Pin1;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateTaskMutationRequest,
  UpdateTaskMutationResponse,
  UpdateTaskPathParams,
} from "../../models/UpdateTask";

/**
 * @description Update task
 * @summary Update task
 * @link /tasks/:id
 */
export async function updateTask(
  id: UpdateTaskPathParams["id"],
  data: UpdateTaskMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateTaskMutationResponse>["data"]> {
  const res = await client<
    UpdateTaskMutationResponse,
    UpdateTaskMutationRequest
  >({
    method: "put",
    url: `/tasks/${id}`,
    data,
    ...options,
  });
  return res.data;
}

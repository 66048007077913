import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const MinusRect = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 19.7C1 21.5225 2.47746 23 4.3 23H19.7C21.5225 23 23 21.5225 23 19.7V4.3C23 2.47746 21.5225 1 19.7 1H4.3C2.47746 1 1 2.47746 1 4.3V19.7ZM4.3 20.8C3.69249 20.8 3.2 20.3075 3.2 19.7V4.3C3.2 3.69249 3.69249 3.2 4.3 3.2H19.7C20.3075 3.2 20.8 3.69249 20.8 4.3V19.7C20.8 20.3075 20.3075 20.8 19.7 20.8H4.3ZM6.44811 10.9C5.8406 10.9 5.34811 11.3925 5.34811 12C5.34811 12.6075 5.8406 13.1 6.44811 13.1H17.5452C18.1527 13.1 18.6452 12.6075 18.6452 12C18.6452 11.3925 18.1527 10.9 17.5452 10.9H6.44811Z"
      fill="#353549"
    />
  </svg>
);

MinusRect.displayName = "MinusRectIcon";

export default MinusRect;

import { useQueryClient } from "@tanstack/react-query";
import { useToast } from "@themis/ui";
import React from "react";

import { useUnlinkFindingRecord } from "@/api/queries/findings";
import { QA_TESTS_QUERY_KEY } from "@/api/queries/qaTests";
import { RelatedRecord } from "@/components/related-record";
import type { LinkedFindingRecordLink } from "@/features/monitoring-and-testing/types";

function RelatedIssueManagementRecord({
  linkedRecord,
  isIssueManagementModuleAdded,
}: {
  linkedRecord: LinkedFindingRecordLink;
  isIssueManagementModuleAdded: boolean;
}) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutate: unlinkFindingRecord } = useUnlinkFindingRecord({
    linkedRecordId: linkedRecord.record_id,
    findingId: linkedRecord.finding_id,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QA_TESTS_QUERY_KEY] });

      toast({
        content: "Record unlinked successfully!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to unlink record!",
        variant: "error",
      });
    },
  });

  return (
    <RelatedRecord
      id={linkedRecord.record_id}
      url={linkedRecord.record_url}
      isModuleAdded={isIssueManagementModuleAdded}
      name={linkedRecord.record_name}
      unlinkRecord={unlinkFindingRecord}
    />
  );
}

export default RelatedIssueManagementRecord;

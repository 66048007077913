import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupResultUpdateMutationRequest,
  WebsiteMonitoringGroupResultUpdateMutationResponse,
  WebsiteMonitoringGroupResultUpdatePathParams,
} from "../../models/WebsiteMonitoringGroupResultUpdate";

/**
 * @description Update Website Monitoring - Group result
 * @summary Update Website Monitoring group result
 * @link /website_monitoring/:monitoring_group_result_id/monitoring_group_result_update
 */
export async function websiteMonitoringGroupResultUpdate(
  monitoringGroupResultId: WebsiteMonitoringGroupResultUpdatePathParams["monitoring_group_result_id"],
  data: WebsiteMonitoringGroupResultUpdateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<WebsiteMonitoringGroupResultUpdateMutationResponse>["data"]
> {
  const res = await client<
    WebsiteMonitoringGroupResultUpdateMutationResponse,
    WebsiteMonitoringGroupResultUpdateMutationRequest
  >({
    method: "put",
    url: `/website_monitoring/${monitoringGroupResultId}/monitoring_group_result_update`,
    data,
    ...options,
  });
  return res.data;
}

import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";

import { useMainStore } from "@/contexts/Store";

import { Button } from "../../../Elements";
import RiskAssessmentHeading from "../RiskAssessmentHeading";
import RiskAssessmentMethodology from "./RiskAssessmentMethodology";
import RiskAssessmentRiskLevels from "./RiskAssessmentRiskLevels";
import RiskAssessmentThemes from "./RiskAssessmentThemes";

interface Props {
  canDelete?: boolean;
  isActive?: boolean;
  isNew?: boolean;
  isTemplate?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSave?: (...args: any[]) => any;
  vendorID?: string;
}

const RiskAssessmentBulkView = ({
  canDelete,
  vendorID,
  isActive,
  isNew,
  isTemplate,
  onCancel,
  onSave,
  ...props
}: Props) => {
  const mainStore = useMainStore();

  const [matrix, setMatrix] = useState(null);
  const [isMethodologyModalOpened, setIsMethodologyModalOpened] =
    useState(false);

  useEffect(() => {
    setMatrix(props.matrix);
  }, [props.matrix]);

  const isDisabled = useMemo(() => {
    if (!matrix) {
      return true;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'risk_levels' does not exist on type 'nev... Remove this comment to see the full error message
    if (matrix.risk_levels.some((riskLevel) => !riskLevel.title)) {
      return true;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'risk_themes' does not exist on type 'nev... Remove this comment to see the full error message
    if (matrix.risk_themes.some((riskTheme) => !riskTheme.title)) {
      return true;
    }

    if (
      // @ts-expect-error TS(2339) FIXME: Property 'risk_themes' does not exist on type 'nev... Remove this comment to see the full error message
      matrix.risk_themes.some((riskTheme) =>
        // @ts-expect-error TS(7006) FIXME: Parameter 'description' implicitly has an 'any' ty... Remove this comment to see the full error message
        riskTheme.risk_descriptions.some((description) => !description.content),
      )
    ) {
      return true;
    }

    return false;
  }, [isNew, matrix, props.matrix]);

  const handleAddRiskLevel = () => {
    const data = { title: "", color_index: 4 };
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const newRiskLevels = [...matrix.risk_levels, data];
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const newRiskThemes = matrix.risk_themes.map((riskTheme) => ({
      ...riskTheme,
      risk_descriptions: [
        ...riskTheme.risk_descriptions,
        { content: `Select ${data.title}` },
      ],
    }));

    setMatrix((prevState) => ({
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...prevState,
      risk_levels: newRiskLevels,
      risk_themes: newRiskThemes,
    }));
  };

  const handleAddTheme = () => {
    const newRiskThemes = [
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      ...matrix.risk_themes,
      {
        title: "",
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        risk_descriptions: matrix.risk_levels.map((item) => ({
          content: `Select ${item.title}`,
        })),
      },
    ];

    // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
    setMatrix((prevState) => ({ ...prevState, risk_themes: newRiskThemes }));
  };

  const handleSave = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
    if (!matrix?.name) {
      mainStore.toast.setErrorText(
        "Risk Assessment Template Name cannot be empty.",
      );
      return;
    }
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onSave(matrix);
  };

  if (!matrix) {
    return null;
  }

  return (
    <div className="vdd-risk-assessment" data-testid="vdd-risk-assessment">
      <RiskAssessmentHeading
        vendorID={vendorID}
        matrix={matrix}
        isActive={isActive}
        isNew={isNew}
        isTemplate={isTemplate}
        isBulkEdit
        actions={
          <div className="vdd-risk-assessment__bulk-edit-actions">
            <div>
              <Button
                theme="secondary"
                label="Add Theme"
                size="sm"
                onClick={handleAddTheme}
              />
              <Button
                theme="secondary"
                label="Add Level"
                size="sm"
                onClick={handleAddRiskLevel}
              />
              <Button
                theme="secondary"
                label="Edit Scoring"
                size="sm"
                onClick={() => setIsMethodologyModalOpened(true)}
              />
            </div>
            <div>
              <Button
                icon="edit"
                theme="primary"
                label="Save and Exit"
                size="sm"
                disabled={isDisabled}
                onClick={handleSave}
              />
              <Button
                theme="tertiary"
                label="Cancel"
                size="sm"
                onClick={onCancel}
              />
            </div>
          </div>
        }
      />
      <div
        className="vdd-risk-assessment__content"
        data-testid="vdd-risk-assessment__content"
      >
        <RiskAssessmentRiskLevels
          matrix={matrix}
          canDelete={canDelete}
          onChange={setMatrix}
        />
        <RiskAssessmentThemes
          matrix={matrix}
          canDelete={canDelete}
          onChange={setMatrix}
        />
      </div>

      <RiskAssessmentMethodology
        matrix={matrix}
        isMethodologyModalOpened={isMethodologyModalOpened}
        onClose={() => setIsMethodologyModalOpened(false)}
        onSave={onSave}
      />
    </div>
  );
};

export default observer(RiskAssessmentBulkView);

import "./create-scoring-group-menu.scss";

import React, { useState } from "react";

import { Button, Icon } from "@/components/Elements";
import TextInput from "@/components/form/TextInput";

interface RenameGroupMenuProps {
  onClose: (refetch?: boolean) => void;
  onRenameGroup: (assessmentId: number, name: string) => void;
  name: string;
  assessmentId: number;
}

function RenameGroupMenu(props: RenameGroupMenuProps) {
  const [updatedName, setUpdatedName] = useState(props.name);

  return (
    <div className="create-scoring-group-menu">
      <div className="section title">
        <p>Rename grouping</p>
        <Icon name="close" size="lg" onClick={() => props.onClose()} />
      </div>
      <div className="section group-name-input">
        <p>Group Name</p>
        <TextInput
          placeholder="- Type here -"
          name="groupNameInput"
          inputRef={null}
          defaultValue={updatedName}
          onChange={(e) => setUpdatedName(e.target.value)}
        />
      </div>
      <div className="section">
        <Button
          label="Confirm"
          disabled={updatedName === props.name || !updatedName}
          onClick={() => props.onRenameGroup(props.assessmentId, updatedName)}
        />
      </div>
    </div>
  );
}

export default RenameGroupMenu;

import { useCallback } from "react";
import { useToggle } from "usehooks-ts";

interface OpenState {
  open: () => void;
  close: () => void;
  toggle: () => void;
  isOpen: boolean;
}

export const useOpen = (defaultOpen: boolean = false): OpenState => {
  const [isOpen, toggle, setIsOpen] = useToggle(defaultOpen);

  const open = useCallback(() => setIsOpen(true), []);

  const close = useCallback(() => setIsOpen(false), []);

  return { close, isOpen, open, toggle };
};

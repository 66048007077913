import type { AxiosError, AxiosResponse } from "axios";
import { action, makeObservable, observable } from "mobx";

import type { Table } from "@/api";
import { tablesService } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { getTableFiltersParam } from "../helpers/getTableFiltersParam";
import type { MainStore } from "../Main";

export default class TablesStore {
  mainStore: MainStore;

  list: Table[] = [];

  errorMessage =
    "An error occurred. Please try again or contact Themis support if the problem persists.";

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      setList: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/frontend/module_workspaces/:module_workspace_id/tables
  async index(moduleWorkspaceID: number) {
    const tables = await tablesService.listTables(moduleWorkspaceID);
    this.setList(tables);
  }

  // GET /api/react/tables/:table_id/export
  async export(
    tableID: number,
    isAttestations: boolean = false,
    parentRecordVersionID: number | undefined = undefined,
    selectedIDs: number[] | undefined = undefined,
    specificStatuses: string[] | undefined = undefined,
  ) {
    const params = {
      table_filters: getTableFiltersParam(),
      selected_ids: selectedIDs,
      specific_statuses: specificStatuses,
    };

    try {
      this.mainStore.toast.setText("Processing table download...");

      const result: AxiosResponse & AxiosError = await legacyApi({
        method: "GET",
        url: `${API_URL}/tables/${tableID}/export?is_attestations=${isAttestations}&parent_record_id=${parentRecordVersionID}`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      if (result.isAxiosError) {
        const { response } = result as AxiosError<{ errors: { base: string } }>;

        if (response && response.data.errors.base.includes("sandbox")) {
          this.errorMessage = response.data.errors.base;
        }

        this.mainStore.toast.setErrorText(this.errorMessage);
        return;
      }

      return result.data;
    } catch (error) {
      window.console.log(`"Tables#export" error ${error}`);
    }
  }

  // POST /api/react/tables/:table_id/export_version_history
  async exportVersionHistory(tableID: number, recordID: number) {
    const data = { record_id: recordID };

    try {
      const result: AxiosResponse & AxiosError = await legacyApi({
        method: "POST",
        url: `${API_URL}/tables/${tableID}/export_version_history`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (result.isAxiosError) {
        const { response } = result as AxiosError<{ errors: { base: string } }>;

        if (response && response.data.errors.base.includes("sandbox")) {
          this.errorMessage = response.data.errors.base;
        }

        this.mainStore.toast.setErrorText(this.errorMessage);
        return;
      }

      return result.data;
    } catch (error) {
      window.console.log(`"Tables#exportVersionHistory" error ${error}`);
    }
  }

  // GET /api/react/tables/:id/generate_template
  async generateTemplate(tableID: number) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/tables/${tableID}/generate_template`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "An error occurred. Please try again or contact Themis support if the problem persists.",
        );
        return;
      }

      return result.data;
    } catch (error) {
      window.console.log(`"Tables#generateTemplate" error ${error}`);
    }
  }

  // POST /api/react/tables/:table_id/import_records
  async importRecords(
    file: File,
    tableID: number | null,
    sectionID: number | null,
    userID: number | null,
    parentRecordVersionId: number = -1,
  ) {
    const formData = new FormData();
    formData.append("file", file);
    if (tableID) {
      formData.append("table_id", tableID.toString());
    }
    if (userID) {
      formData.append("user_id", userID.toString());
    }
    if (sectionID) {
      formData.append("section_id", sectionID.toString());
    }
    formData.append(
      "parent_record_version_id",
      parentRecordVersionId.toString(),
    );

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/tables/${tableID}/import_records`,
        headers: {
          ...this.mainStore.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        if (result.response.data.errors) {
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          this.mainStore.toast.setErrorText(result.response.data.errors);
        } else {
          this.mainStore.toast.setErrorText(
            "An error occurred during import. Double check your file to make sure it's structured properly.",
          );
        }
      }
    } catch (error) {
      window.console.log(`"Tables#import_records" error ${error}`);
    }
  }

  // POST /api/react/tables/:table_id/import_files
  async importFiles(
    file_ids: string[],
    tableID: number | null,
    userID: number | null,
    sectionID: number | null = null,
    parentRecordVersionId: number = -1,
  ) {
    const importParams = {
      file_ids,
      table_id: tableID,
      user_id: userID,
      section_id: sectionID,
    };

    if (parentRecordVersionId && parentRecordVersionId > 0) {
      // @ts-expect-error TS(2339) FIXME: Property 'parent_record_version_id' does not exist... Remove this comment to see the full error message
      importParams.parent_record_version_id = parentRecordVersionId;
    }

    try {
      this.mainStore.toast.setInfoText("Files are being imported...");
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/tables/${tableID}/import_files`,
        headers: this.mainStore.getHeaders(),
        data: importParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setErrorText("An error occurred during import.");
        return;
      }
      this.mainStore.toast.setInfoText(
        "Records have successfully been created.",
      );
    } catch (error) {
      window.console.log(`"Tables#import_files" error ${error}`);
    }
  }

  // GET /api/react/tables/:table_id/export_files
  async exportFiles(tableID: number, userID: number, sectionTagID?: number) {
    const data = {
      user_id: userID,
      section_tag_id: sectionTagID,
      table_filters: getTableFiltersParam(),
    };
    try {
      this.mainStore.toast.setInfoText(
        "You will receive an email with a link to your file download shortly.",
      );
      const result: AxiosResponse & AxiosError = await legacyApi({
        method: "GET",
        url: `${API_URL}/tables/${tableID}/export_files`,
        headers: this.mainStore.getHeaders(),
        params: data,
      });

      if (result.isAxiosError) {
        const { response } = result as AxiosError<{ errors: { base: string } }>;

        if (response && response.data.errors.base.includes("sandbox")) {
          this.errorMessage = response.data.errors.base;
        }

        this.mainStore.toast.setErrorText(this.errorMessage);
        return;
      }

      return result.data;
    } catch (error) {
      window.console.log(`"Tables#export_files" error ${error}`);
    }
  }

  setList(value: Table[]) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  // Store Helpers

  cleanup() {
    this.setList([]);
  }
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListThemisCustomAuditsForThemisModulePathParams,
  ListThemisCustomAuditsForThemisModuleQueryParams,
  ListThemisCustomAuditsForThemisModuleQueryResponse,
} from "../../models/ListThemisCustomAuditsForThemisModule";

/**
 * @description Audit Trail for a Themis Module
 * @summary Audit Trail for a Themis Module
 * @link /companies/:company_id/:record_type/audits
 */
export async function listThemisCustomAuditsForThemisModule(
  companyId: ListThemisCustomAuditsForThemisModulePathParams["company_id"],
  recordType: ListThemisCustomAuditsForThemisModulePathParams["record_type"],
  params?: ListThemisCustomAuditsForThemisModuleQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<ListThemisCustomAuditsForThemisModuleQueryResponse>["data"]
> {
  const res = await client<ListThemisCustomAuditsForThemisModuleQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/${recordType}/audits`,
    params,
    ...options,
  });
  return res.data;
}

import { Button } from "@themis/ui";
import { upperFirst } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import Popup from "reactjs-popup";

import type { TableName } from "@/api";
import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import warningIcon from "../../../images/table-image/icon/warning-icon.svg";

const ACTION_NAMES = {
  Policy: {
    present: "finalize",
    past: "finalized",
  },
  Procedure: {
    present: "finalize",
    past: "finalized",
  },
  Conflict: {
    present: "close",
    past: "closed",
  },
  Creative: {
    present: "finalize",
    past: "finalized",
  },
  Document: {
    present: "publish",
    past: "published",
  },
  "Change Management": {
    present: "publish",
    past: "published",
  },
  Training: {
    present: "finalize",
    past: "finalized",
  },
  "Control Mapping": {
    present: "publish",
    past: "published",
  },
  Issue: {
    present: "close",
    past: "closed",
  },
  "Risk Register": {
    present: "complete",
    past: "completed",
  },
};

interface Props {
  recordVersionID: number;
  buttonText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleStore?: any;
  moduleIdentifier?: ModuleIdentifier;
  tableName?: TableName;
}

function PublishFlow({
  recordVersionID,
  buttonText,
  moduleStore,
  moduleIdentifier,
  tableName,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [step, setStep] = useState(1);

  // Variables
  const { canPublishOrLockRecords } = mainStore.userPermissions;
  const modelName = useMemo(() => {
    if (tableName === "Drafts") {
      return "Policy";
    }
    if (tableName === "ProceduresDrafts") {
      return "Procedure";
    }
    if (moduleIdentifier === "training") {
      return "Training";
    }
    if (moduleIdentifier === "conflicts_of_interest") {
      return "Conflict";
    }
    if (moduleIdentifier === "marketing") {
      return "Creative";
    }
    if (moduleIdentifier === "documents") {
      return "Document";
    }
    if (moduleIdentifier === "linked_documents") {
      return "Document";
    }
    if (moduleIdentifier === "new_product_approval") {
      return "Change Management";
    }
    if (moduleIdentifier === "control_mapping") {
      return "Control Mapping";
    }
    if (moduleIdentifier === "issue_management") {
      return "Issue";
    }
    if (moduleIdentifier === "risk_register") {
      return "Risk Register";
    }
    return "Unknown Model Name";
  }, [tableName, moduleIdentifier]);

  function onPopupOpen() {
    if (canPublishOrLockRecords) {
      setShowPopup(true);
      setStep(1);
    }
  }

  function onPopupClose() {
    setShowPopup(false);
    setStep(1);
  }

  function getCellsErrors() {
    return (
      mainStore.recordVersions.cellsErrors?.filter(
        (error) => error.record_version_id === recordVersionID,
      ) || []
    );
  }

  async function onConfirm() {
    let store = moduleStore;

    if (modelName === "Policy") {
      store = mainStore.policies;
    } else if (modelName === "Procedure") {
      store = mainStore.procedures;
    }

    const response = await store.finalize(recordVersionID);
    setStep(2);
    if (getCellsErrors().length === 0 && !response?.isAxiosError) {
      mainStore.toast.setText(
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        `${modelName} has been ${ACTION_NAMES[modelName].past}`,
      );
    } else if (response.isAxiosError) {
      mainStore.toast.setText(`There was an error finalizing ${modelName}.`);
    }
  }

  function onDeny() {
    setShowPopup(false);
    setStep(1);
  }

  // @ts-expect-error TS(7034) FIXME: Variable 'closePopup' implicitly has type 'any' in... Remove this comment to see the full error message
  let closePopup;

  useEffect(() => {
    if (step === 2 && getCellsErrors().length > 0) {
      closePopup = setTimeout(() => {
        onPopupClose();
      }, 5000);
    }
    return () => {
      // @ts-expect-error TS(7005) FIXME: Variable 'closePopup' implicitly has an 'any' type... Remove this comment to see the full error message
      clearTimeout(closePopup);
    };
  }, [step, getCellsErrors]);

  return (
    <Popup
      position="bottom right"
      trigger={
        <div data-testid="publish-button-trigger">
          <Button
            color="secondary"
            size="md"
            data-testid="publish-button"
            disabled={!canPublishOrLockRecords}
            className="tw-w-[86px] tw-max-w-[86px]"
          >
            {buttonText}
          </Button>
        </div>
      }
      open={showPopup}
      disabled={!canPublishOrLockRecords}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
    >
      {step === 1 && (
        <div className="table-dropdown" data-testid="publish-record-popup">
          <h4>{`${upperFirst(
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ACTION_NAMES[modelName].present,
          )} ${modelName}`}</h4>
          {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          <p>{`Do you want to ${ACTION_NAMES[modelName].present} this ${modelName}?`}</p>
          {modelName === "Conflict" && (
            <p>
              {" "}
              An email wil be sent to the submitter notifying them that their
              request is approved/denied.{" "}
            </p>
          )}
          <div className="confirmation">
            <button data-testid="confirm-button" onClick={onConfirm}>
              Yes
            </button>
            <button onClick={onDeny}>No</button>
          </div>
        </div>
      )}

      {step === 2 && getCellsErrors().length !== 0 && (
        <div className="table-dropdown error">
          <ul className="errors">
            {getCellsErrors().map((error) => (
              // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
              <li key={`${error.record_version_id}-${error.column_id}`}>
                <img src={warningIcon} alt="warning-icon" />
                {error.description}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Popup>
  );
}

PublishFlow.defaultProps = {
  buttonText: "Publish",
};

export default observer(PublishFlow);

import React from "react";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";

import { Flex, Icon, MiniTag } from "@/components/Elements";
import { getTimeSensitiveGreeting } from "@/components/helpers/time";
import { useMainStore } from "@/contexts/Store";

const pages = [
  { path: "/notifications/tasks/", header: "Edit Comment" },
  {
    path: "/notifications/custom-notifications/edit",
    header: "Edit Notification",
    pathBack: "/notifications/custom-notifications",
  },
  {
    path: "/notifications/custom-notifications/create",
    header: "Add New Notification",
    pathBack: "/notifications/custom-notifications",
  },
  {
    path: "/notifications/custom-notifications",
    header: "All Custom Notifications",
  },
];

function CompanyHeaderContent() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams<{ task_id: string }>();
  const taskID = Number(params.task_id);
  const task = mainStore.tasks.allTasks?.find((elem) => elem.id === taskID);

  // Variables
  const { user } = mainStore.users;
  const currentPage = pages.find((page) => pathname.startsWith(page.path));

  // Functions
  function navigateBack(currentPageData: (typeof pages)[number]) {
    if (currentPageData?.pathBack) {
      history.push(currentPageData?.pathBack);

      return;
    }

    history.goBack();
  }

  return (
    <div className="dashboard-header-company">
      {currentPage ? (
        <>
          <div className="archive-head-link">
            <button
              type="button"
              onClick={() => navigateBack(currentPage)}
              data-testid="go-back-button"
            >
              <Icon name="chevronLeft" color="generalMidnightDark" />
            </button>
          </div>
          <Flex alignCenter columnGap={12}>
            <h3>{currentPage.header}</h3>
            {currentPage.header === "Edit Task" && task?.private && (
              <MiniTag fontSize="de" label="Internal" />
            )}
          </Flex>
        </>
      ) : (
        <h3>
          {getTimeSensitiveGreeting()} <span>{user.full_name}</span>
        </h3>
      )}
    </div>
  );
}

export default CompanyHeaderContent;

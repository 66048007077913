import classNames from "classnames";
import dayjs from "dayjs";
import { noop } from "lodash";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import arrowLeft from "../../../../images/table-image/icon/arrow-left.svg";
import arrowRight from "../../../../images/table-image/icon/arrow-right.svg";
import warningIcon from "../../../../images/table-image/icon/warning-icon.svg";
import { formatDate } from "../../../helpers/DateFormatters";

interface Props {
  fieldName: string;
  hasErrors: boolean;
  width: number | string;
  columnTitle?: string;
  dataTestId?: string;
  errorMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  hasErrorClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any;
  isLockedRow?: boolean;
  isUserEditable?: boolean;
  locked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDate?: any; // TODO: PropTypes.instanceOf(Date)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDateFunction?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDate?: any; // TODO: PropTypes.instanceOf(Date)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDateFunction?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange?: (...args: any[]) => any;
  pinned?: boolean;
  recordVersionID?: number;
  onlyMonthYearPicker?: boolean;
  hasReadWriteAccess?: boolean;
}

function DateSelect({
  columnTitle,
  dataTestId,
  errorMessage,
  exposeData,
  fieldName,
  hasErrorClass,
  hasErrors,
  initialValue,
  isLockedRow,
  isUserEditable,
  locked,
  maxDate,
  maxDateFunction,
  minDate,
  minDateFunction,
  onDataChange,
  pinned,
  recordVersionID,
  width,
  onlyMonthYearPicker = false,
  hasReadWriteAccess,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly =
    !hasReadWriteAccess &&
    (!hasModuleWriteAccess || isCurrentWorkspaceArchived);
  const dateFormatOptions = onlyMonthYearPicker
    ? {
        month: "short",
        year: "numeric",
      }
    : undefined;

  // State
  const [date, setDateHelper] = useState(initialValue || new Date());
  const [activeStartDate, setActiveStartDate] = useState(null);
  const [dateString, setDateString] = useState(
    formatDate(date, dateFormatOptions),
  );
  const [dateChangeString, setDateChangeString] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);

  // hooks
  useEffect(() => {
    if (initialValue) {
      setDate(initialValue);
      setDateString(formatDate(initialValue, dateFormatOptions));
    }
  }, [initialValue]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'newDate' implicitly has an 'any' type.
  const onCalendarChange = (newDate) => {
    // Set date
    setDate(newDate);
    setDateString(formatDate(newDate, dateFormatOptions));

    // Persist changes
    persistDate(newDate);

    // Close popup
    onClose();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'newDate' implicitly has an 'any' type.
  const setDate = (newDate) => {
    setDateHelper(newDate);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    exposeData(newDate);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const onInputChange = (event) => {
    const newDateString = event.target.value;

    // Set dateString without any conditions, since it defines the value in input
    setDateString(newDateString);

    // Try to parse a date
    if (newDateString !== null && newDateString !== "") {
      const dateParts = newDateString.split("/");
      const month = Number(dateParts[0]);
      const day = Number(dateParts[1]);
      const year = Number(dateParts[2]);

      if (year >= 2000 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
        const newDate = new Date(year, month - 1, day);

        // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
        if (!isNaN(newDate)) {
          setDate(newDate);
          setActiveStartDate(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
            new Date(newDate.getFullYear(), newDate.getMonth(), 1),
          );
          persistDate(newDate);
        }
      }
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const onActiveStartDateChange = (value) => {
    setActiveStartDate(value.activeStartDate);
  };

  const clearDate = (event: React.MouseEvent) => {
    onCalendarChange(null);
    event?.stopPropagation();
  };

  const setToday = () => {
    const today = new Date();
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
    setActiveStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
    onCalendarChange(today);
  };

  const onOpen = () => {
    setShowPopup(true);
  };

  const onClose = () => {
    setShowPopup(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'newDate' implicitly has an 'any' type.
  const persistDate = (newDate) => {
    const serializeValue = mainStore.avroSchemas.serializeValue(
      fieldName,
      newDate,
    );

    if (onlyMonthYearPicker && serializeValue) {
      serializeValue.value = dayjs(serializeValue.value)
        .startOf("month")
        .format("YYYY-MM-Dd");
    }

    if (typeof onDataChange === "function") {
      setDateChangeString(newDate);
      onDataChange(fieldName, serializeValue, newDate);
    } else {
      mainStore.recordVersions.update({
        fieldName,
        recordVersionID,
        value: serializeValue,
      });
    }
  };

  const errorClick = () => {
    if (errorMessage !== undefined) {
      setShowPopupError(false);
      setShowPopup(true);
    }
  };

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  let pastDate = false;
  if (fieldName === "review_date") {
    pastDate = yesterday > date;
  } else if (fieldName === "due_date") {
    pastDate = new Date() > date;
  }

  const triggerClasses = classNames("set-date-button", {
    active: showPopup || showPopupError,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "has-past-date-error": initialValue && pastDate && !isLockedRow,
    illumination: showPopup || showPopupError,
    "pointer-events-none": locked || !isUserEditable || isReadOnly,
    "table-cell--disabled": isReadOnly,
    "locked-cell": locked && isCurrentWorkspaceActive,
    pinned,
    "computed-columns": !columnTitle,
  });

  const datePlaceholderClasses = classNames("date-placeholder", {
    "placeholder-locked": locked,
  });

  const modalTrigger = (
    <li
      className={triggerClasses}
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'number'.
      tabIndex="0"
      style={{ width }}
      data-testid={dataTestId}
      onClick={errorClick}
    >
      <div className="cell-content">
        {!initialValue && !errorMessage && !dateChangeString && (
          <span className={datePlaceholderClasses}>
            {locked || !isUserEditable ? "N/A" : "- Select -"}
          </span>
        )}
        {initialValue || dateChangeString ? (
          dateString
        ) : (
          <span>{errorMessage}</span>
        )}
      </div>
    </li>
  );

  const popup = (
    <Popup
      trigger={modalTrigger}
      position="bottom left"
      open={showPopup}
      onOpen={onOpen}
      onClose={onClose}
      keepTooltipInside
    >
      <div
        className="select-small-wrap calendar-wrap"
        data-testid="date-cell-popup"
      >
        <input
          className="input-date"
          data-testid="date-cell-input"
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          value={dateString}
          onChange={onInputChange}
        />
        <Calendar
          // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Date | unde... Remove this comment to see the full error message
          activeStartDate={activeStartDate}
          onActiveStartDateChange={onActiveStartDateChange}
          formatShortWeekday={(_, weekday) =>
            ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][weekday.getDay()]
          }
          value={date}
          onChange={onCalendarChange}
          prevLabel={<img src={arrowLeft} alt="image" />}
          nextLabel={<img src={arrowRight} alt="image" />}
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          maxDate={maxDateFunction(fieldName) || maxDate}
          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          minDate={minDateFunction(fieldName) || minDate}
          maxDetail={onlyMonthYearPicker ? "year" : undefined}
        />
        <div className="manage-date">
          <button
            className="table-button set-today"
            data-testid="date-cell-set-today"
            onClick={setToday}
          >
            Set Today
          </button>
          <button className="table-button clear-button" onClick={clearDate}>
            Clear
          </button>
        </div>
      </div>
    </Popup>
  );

  if (errorMessage !== undefined) {
    return showPopup ? (
      popup
    ) : (
      <Popup
        position="bottom right"
        trigger={modalTrigger}
        on="hover"
        open={showPopupError}
        onOpen={() => setShowPopupError(true)}
        onClose={() => setShowPopupError(false)}
        keepTooltipInside
      >
        <div className="table-dropdown error">
          <ul className="errors">
            <li>
              <img src={warningIcon} alt="warning-icon" />
              {errorMessage}
            </li>
          </ul>
        </div>
      </Popup>
    );
  }

  return popup;
}

DateSelect.defaultProps = {
  dataTestId: "date-cell-trigger",
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
  hasErrorClass: "has-errors",
  hasErrors: false,
  isUserEditable: true,
  maxDateFunction: noop,
  minDateFunction: noop,
  pinned: false,
};

export default DateSelect;

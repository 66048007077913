import { MiniTag } from "@themis/ui";
import type { ListControlsModules } from "app/javascript/api/gen/models/ListControlsModules";
import React from "react";

export function ModuleControlsGeneralInfo({
  controlsModules,
}: {
  controlsModules?: ListControlsModules;
}) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-border tw-border-dashed tw-border-neutral-200 tw-px-2 tw-py-3">
      <div className="tw-flex ">
        <div className="tw-min-w-[120px] tw-text-xs tw-font-medium tw-text-neutral-300">
          Framework:
        </div>
        <div className="tw-text-xs tw-font-medium tw-text-neutral-500">
          {controlsModules?.l1_risk_taxonomy?.value || ""}
        </div>
      </div>

      <div className="tw-flex tw-items-center">
        <div className="tw-min-w-[120px] tw-text-xs tw-font-medium tw-text-neutral-300">
          Taxonomy:
        </div>
        <MiniTag className="tw-bg-purple-3">
          {controlsModules?.l2_risk_taxonomy?.value || ""}
        </MiniTag>
      </div>

      <div className="tw-flex ">
        <div className="tw-min-w-[120px] tw-text-xs tw-font-medium tw-text-neutral-300">
          Event:
        </div>
        <div className="tw-text-xs tw-font-medium tw-text-neutral-500">
          {controlsModules?.risk_event?.value || ""}
        </div>
      </div>
      <div className="tw-flex">
        <div className="tw-min-w-[120px] tw-text-xs tw-font-medium tw-text-neutral-300">
          Description:
        </div>
        <div className="tw-text-wrap tw-text-xs tw-font-medium tw-text-neutral-500">
          {controlsModules?.event_description?.value || ""}
        </div>
      </div>
    </div>
  );
}

import classnames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { IconButton, Typography } from "../../components/Elements";
import { RISK_RATING_COLORS } from "../risk-assessment";
import ColorSelect from "../risk-assessment/components/RiskMethodology/RiskMethodologyBuilder/ColorSelect";

interface ScoringMatrixRatingRowProps {
  index: number;
  text: string;
  color: string;
  value: number;
  previousValue: number;
  updateTitle: (index: number, value: string) => void;
  updateColor: (index: number, value: string) => void;
  updateValue: (index: number, value: number) => void;
  removeRating: (index: number) => void;
  dataTestID?: string;
  hasError: boolean;
}

function ScoringMatrixRatingRow({
  index,
  dataTestID,
  text,
  color,
  value,
  previousValue,
  updateTitle,
  updateValue,
  removeRating,
  updateColor,
  hasError,
}: ScoringMatrixRatingRowProps) {
  const [clonedName, setClonedName] = useState(text);
  const [lastValidValue, setLastValidValue] = useState<number>(value);

  // @ts-expect-error TS(7006) FIXME: Parameter 'localDisabled' implicitly has an 'any' ... Remove this comment to see the full error message
  const classes = (localDisabled) =>
    classnames(
      "risk-methodology-builder__content__risk-rating-value",
      "risk-methodology-builder__content__risk-rating-value__range",
      {
        "risk-methodology-builder__content__risk-rating-value__disabled":
          localDisabled,
        "ra-risk-rating-error": hasError && previousValue === lastValidValue,
      },
    );

  const updateValidValue = (val: string) => {
    const parsedValue = parseInt(val);
    if (!Number.isNaN(parsedValue)) {
      setLastValidValue(parsedValue);
      updateValue(index, parsedValue);
    } else {
      setLastValidValue(0);
      updateValue(index, 0);
    }
  };

  return (
    <div className="risk-methodology-builder__content__risk-rating-input-area">
      <span className="risk-methodology-builder__content__input-container">
        <textarea
          className={classnames(
            "risk-methodology-builder__content__risk-rating-name",
            { "ra-risk-rating-error": hasError || clonedName.length === 0 },
          )}
          onChange={(e) => setClonedName(e.target.value)}
          onBlur={(e) => updateTitle(index, e.target.value)}
          value={clonedName}
          data-testid={`${dataTestID}-row-${index}-name`}
        />
        <ColorSelect
          index={index}
          selectedColor={color}
          updateColor={updateColor}
          availableColors={RISK_RATING_COLORS}
          small
        />
      </span>
      <span
        className={classnames(
          "risk-methodology-builder__content__risk-rating-value-area",
          "risk-methodology-builder__content__risk-rating-modal-range-label",
        )}
      >
        <span className="risk-rating-range-label">
          <Typography className="risk-rating-range-label__title">
            Range
          </Typography>
        </span>
        <input
          className={classes(true)}
          onChange={(e) => {
            updateValidValue(e.target.value);
          }}
          value={index > 0 ? `> ${previousValue}` : previousValue}
          data-testid={`${dataTestID}-row-${index}-lower-range`}
          type="text"
          disabled
        />
        <span className="risk-rating-range-label">
          <Typography className="risk-rating-range-label__range">-</Typography>
        </span>
        <input
          className={classes(false)}
          onChange={(e) => {
            updateValidValue(e.target.value);
          }}
          value={lastValidValue}
          data-testid={`${dataTestID}-row-${index}-upper-range`}
          type="text"
        />
        <span className="risk-rating-range-button">
          <IconButton
            icon="trash"
            data-testid={`${dataTestID}-row-${index}-remove`}
            transparent
            onClick={() => removeRating(index)}
          />
        </span>
      </span>
    </div>
  );
}

export default observer(ScoringMatrixRatingRow);

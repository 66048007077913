import React from "react";
import { cn } from "../../../lib/utils";
import { buttonBaseVariants } from "../../Button/ButtonBase";
var ButtonCell = function (_a) {
    var _b = _a.label, label = _b === void 0 ? "Click" : _b, color = _a.color, onClick = _a.onClick;
    return (React.createElement("button", { onClick: onClick, className: cn(buttonBaseVariants({
            color: color,
        }), "tw-h-full tw-w-full tw-justify-start tw-rounded-none tw-px-2") }, label));
};
export default ButtonCell;

export const MODULE_NOTIFICATIONS_BASE_PATH =
  "/workspaces/:workspace_id/modules/:moduleIdentifier/:subModule?/notifications";

export const TAB_PARAM = ":tab(date|event|unlock_task)";

export const MODULE_NOTIFICATIONS_TAB_PATH = [
  MODULE_NOTIFICATIONS_BASE_PATH,
  TAB_PARAM,
].join("/");

export const DATE_TAB = "date";
export const EVENT_TAB = "event";
export const UNLOCK_TASK_TAB = "unlock_task";
export const NOTIFICATION_ID_PARAM = ":notificationId";
export const CREATE_PATH = "create";

export const MODULE_NOTIFICATIONS_EDIT_PATH = [
  MODULE_NOTIFICATIONS_TAB_PATH,
  NOTIFICATION_ID_PARAM,
].join("/");

export const MODULE_NOTIFICATIONS_CREATE_PATH = [
  MODULE_NOTIFICATIONS_TAB_PATH,
  CREATE_PATH,
].join("/");

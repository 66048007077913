import { flatMap, map } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { getPathFromModuleIdentifier } from "@/components/helpers/getPathFromModuleIdentifier";
import NoAccess from "@/components/NoAccess";
import { useMainStore } from "@/contexts/Store";

import { Typography } from "../../Elements";
import LoadingTile from "./tiles/LoadingTile";
import Tile from "./tiles/Tile";
import TileSet from "./tiles/TileSet";

const HomeContent = () => {
  const { context, home, workspaces } = useMainStore();
  const { workspaceID } = context;
  const { hasLoaded, sections } = home;
  const isEmpty = flatMap(sections, "tiles").length === 0;

  useEffect(() => {
    if (workspaceID && !workspaces.isSwitchingWorkspace) {
      home.fetchSections(workspaceID);
    }
  }, [workspaceID, workspaces.isSwitchingWorkspace]);

  return (
    <>
      {!hasLoaded && (
        <TileSet>
          {Array(12)
            .fill(0)
            .map((val, index) => (
              <LoadingTile key={index} />
            ))}
        </TileSet>
      )}
      {hasLoaded &&
        map(sections, ({ title, tiles }, sectionIndex) => (
          <div className="home-content__section" key={sectionIndex}>
            <Typography
              label={title}
              color="generalMidnightDark"
              weight="bold"
              className="home-content__section__title"
            />
            <TileSet>
              {map(tiles, (tile, tileIndex) => {
                // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                const homeParam = tile.link?.includes("?")
                  ? "&fromHome=true"
                  : "?fromHome=true";
                return (
                  <Link
                    className="home-content__tile-link"
                    to={
                      `${
                        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                        tile.link
                      }${homeParam}` ||
                      `/workspaces/${workspaceID}${getPathFromModuleIdentifier(
                        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
                        tile.identifier,
                      )}`
                    }
                    key={tileIndex}
                  >
                    <Tile tile={tile} />
                  </Link>
                );
              })}
            </TileSet>
          </div>
        ))}
      {hasLoaded && isEmpty && (
        <NoAccess message="You do not have access to any modules supported by this dashboard" />
      )}
    </>
  );
};

export default observer(HomeContent);

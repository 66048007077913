import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateModuleAssessmentMutationRequest,
  CreateModuleAssessmentMutationResponse,
  CreateModuleAssessmentPathParams,
} from "../../models/CreateModuleAssessment";

/**
 * @description Create Module Assessment
 * @summary Create Module Assessment
 * @link /record_versions/:record_version_id/module_assessments
 */
export async function createModuleAssessment(
  recordVersionId: CreateModuleAssessmentPathParams["record_version_id"],
  data: CreateModuleAssessmentMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateModuleAssessmentMutationResponse>["data"]> {
  const res = await client<
    CreateModuleAssessmentMutationResponse,
    CreateModuleAssessmentMutationRequest
  >({
    method: "post",
    url: `/record_versions/${recordVersionId}/module_assessments`,
    data,
    ...options,
  });
  return res.data;
}

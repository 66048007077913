function linkToEdit(
  workspaceID: number,
  modulePath: string,
  id: number,
  isCustomField: boolean,
) {
  if (isCustomField) {
    return `/workspaces/${workspaceID}${modulePath}/custom-checklists/${id}/update`;
  }
  return `/workspaces/${workspaceID}/modules/change-management/checklists/${id}/update`;
}

function linkToCreate(
  workspaceID: number,
  modulePath: string,
  id: number,
  isCustomField: boolean,
  fieldName: string,
) {
  if (isCustomField) {
    return `/workspaces/${workspaceID}${modulePath}/custom-checklists/${id}/create/${fieldName}`;
  }
  return `/workspaces/${workspaceID}/modules/change-management/${id}/checklists/create`;
}

function linkToEditTemplate(
  workspaceID: number,
  modulePath: string,
  id: number,
  isCustomField: boolean,
  fieldName: string,
) {
  if (isCustomField) {
    return `/workspaces/${workspaceID}${modulePath}/custom-checklists-templates/${id}/update/${fieldName}`;
  }
  return `/workspaces/${workspaceID}/modules/change-management/checklists-templates/${id}/update`;
}

export function useLinkToEdit(
  workspaceID: number,
  modulePath: string,
  id: number,
  isCustomField: boolean,
) {
  return linkToEdit(workspaceID, modulePath, id, isCustomField);
}

export function useLinkToCreate(
  workspaceID: number,
  modulePath: string,
  id: number,
  isCustomField: boolean,
  fieldName: string,
) {
  return linkToCreate(workspaceID, modulePath, id, isCustomField, fieldName);
}

export function useLinkToEditTemplate(
  workspaceID: number,
  modulePath: string,
  id: number,
  isCustomField: boolean,
  fieldName: string,
) {
  return linkToEditTemplate(
    workspaceID,
    modulePath,
    id,
    isCustomField,
    fieldName,
  );
}

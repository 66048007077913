import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import TagSelect from "../cell-type/TagSelect";

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  title: string;
  dataTestId?: string;
}

function ViewTagsSelect({
  recordVersion,
  fieldName,
  title,
  recordVersionID,
  dataTestId,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const value = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <TagSelect
      fieldName={fieldName}
      // @ts-expect-error TS(2322) FIXME: Type '{ fieldName: string; columnTitle: string; er... Remove this comment to see the full error message
      columnTitle={title}
      errorMessage={cellError?.description}
      hasErrors={hasErrors}
      isUserEditable
      recordVersionID={recordVersionID}
      selectedOptions={value}
      width="100%"
      isMultiSelect={false}
      hasErrorClass="detail-view-has-errors"
      dataTestId={dataTestId}
    />
  );
}

export default observer(ViewTagsSelect);

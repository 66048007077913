import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DismissAllNotificationsMutationRequest,
  DismissAllNotificationsMutationResponse,
} from "../../models/DismissAllNotifications";

/**
 * @description Mark all notifications as dismissed
 * @summary Mark all notifications as dismissed
 * @link /notifications/dismiss_all
 */
export async function dismissAllNotifications(
  data: DismissAllNotificationsMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DismissAllNotificationsMutationResponse>["data"]> {
  const res = await client<
    DismissAllNotificationsMutationResponse,
    DismissAllNotificationsMutationRequest
  >({
    method: "put",
    url: `/notifications/dismiss_all`,
    data,
    ...options,
  });
  return res.data;
}

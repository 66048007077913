import { observer } from "mobx-react";
import React from "react";
import { Route, Switch } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import CreatedDateDashboard from "../common/MetricsPage/dashboards/CreatedDateDashboard";
import OverdueDashboard from "../common/MetricsPage/dashboards/OverdueDashboard";
import StatusDashboard from "../common/MetricsPage/dashboards/StatusDashboard";
import TimeToCloseDashboard from "../common/MetricsPage/dashboards/TimeToCloseDashboard";
import {
  createdDateDashboardUrl,
  overdueDashboardUrl,
  statusDashboardUrl,
  timeToCloseDashboardUrl,
} from "../common/MetricsPage/urlPaths";

const IssueManagementMetrics = () => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const name = "Issues";
  const defaultDateField = "created_at";
  const identifier = TopLevelModule.ISSUE_MANAGEMENT;

  return (
    <Switch>
      <Route exact path={`/workspaces/${workspaceID}${statusDashboardUrl}`}>
        <StatusDashboard
          name={name}
          identifier={identifier}
          defaultDateField={defaultDateField}
        />
      </Route>
      <Route
        exact
        path={`/workspaces/${workspaceID}${createdDateDashboardUrl}`}
      >
        <CreatedDateDashboard
          name={name}
          identifier={identifier}
          defaultDateField={defaultDateField}
        />
      </Route>
      <Route exact path={`/workspaces/${workspaceID}${overdueDashboardUrl}`}>
        <OverdueDashboard
          name={name}
          identifier={identifier}
          defaultDateField={defaultDateField}
        />
      </Route>
      <Route
        exact
        path={`/workspaces/${workspaceID}${timeToCloseDashboardUrl}`}
      >
        <TimeToCloseDashboard
          name={name}
          identifier={identifier}
          defaultDateField="last_closed_at"
        />
      </Route>
    </Switch>
  );
};

export default observer(IssueManagementMetrics);

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import IconButton from "../../../Elements/IconButton";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import Textarea from "../../shared/dynamic-form/view-textarea/Textarea";
import type { EditingDescriptionProps, RenamingItemProps } from "./types";

interface Props {
  isNew?: boolean;
  isTemplate?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix?: any;
}

function RiskAssessmentThemes({ matrix, isNew, isTemplate }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [contextMenuOpened, setContextMenuOpened] = useState(null);
  const [renamingItem, setRenamingItem] = useState<RenamingItemProps>(
    {} as RenamingItemProps,
  );
  const [editingDescriptionItem, setEditingDescriptionItem] =
    useState<EditingDescriptionProps>({} as EditingDescriptionProps);
  const [deletingItem, setDeletingItem] = useState(null);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newThemeTitle, setNewThemeTitle] = useState("");

  // Variables
  const { canDeleteRecords } = mainStore.userPermissions;
  const {
    risk_levels: riskLevels,
    risk_themes: riskThemes,
    rating_calculation_type: ratingCalculationType,
  } = matrix;
  const { currentVendor } = mainStore.vendors;
  const status = mainStore.avroSchemas.valueForField(
    "status",
    currentVendor?.data,
  );
  const isActive = !currentVendor || status !== "disabled";
  const allowSelect = !editingDescriptionItem?.title && !isTemplate;
  const isAutoCalc = ratingCalculationType === "auto";

  // effects
  useEffect(() => {
    if (!riskThemes?.length) {
      return;
    }
    if (!isNew) {
      return;
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    const theme = riskThemes.find((item) => !item.title);
    if (!theme) {
      return;
    }

    setRenamingItem({ ...theme, index: 0 });
  }, [isNew, riskThemes]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleRenameKeyDown(e) {
    if (e.key === "Enter") {
      handleRenameSubmit();
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleCreateKeyDown(e) {
    if (e.key === "Enter") {
      handleAddNewClick();
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
  function handleRenameClick(theme) {
    setRenamingItem(theme);
    handlePopupClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleTitleChange(evt) {
    setRenamingItem({ ...renamingItem, title: evt.target.value });
  }

  function handleRenameSubmit() {
    const { title, index } = renamingItem;
    if (!title) {
      return;
    }

    const data = { title };

    // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'null | un... Remove this comment to see the full error message
    handleLocalMatrixUpdate({ mode: "update", index, data });
    if (!isNew) {
      mainStore.vendors.updateRiskTheme(matrix.id, riskThemes[index].id, data);
    }

    handleCancelRename();
  }

  function handleCancelRename() {
    setRenamingItem({} as RenamingItemProps);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
  function handleSelectDescription(theme, descriptionIndex) {
    if (!allowSelect) {
      return;
    }

    const data = {
      ...theme,
      // @ts-expect-error TS(7006) FIXME: Parameter 'rd' implicitly has an 'any' type.
      risk_descriptions: theme.risk_descriptions.map((rd, i) => ({
        ...rd,
        selected: i === descriptionIndex ? !rd.selected : false,
      })),
    };

    handleLocalMatrixUpdate({ mode: "update", index: theme.index, data });
    if (!isNew) {
      mainStore.vendors.updateRiskTheme(matrix.id, theme.id, {
        ...data,
        risk_descriptions_attributes: data.risk_descriptions,
        risk_descriptions: undefined,
      });
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
  function handleEditDescriptionsClick(theme) {
    setEditingDescriptionItem(theme);
    handlePopupClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleDescriptionChange(evt, index) {
    setEditingDescriptionItem({
      ...editingDescriptionItem,
      risk_descriptions: editingDescriptionItem.risk_descriptions.map(
        (item, idx) =>
          idx === index ? { ...item, content: evt.target.value } : item,
      ),
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleCommentChange(evt, theme, index) {
    const data = { ...theme, comment: evt.target.value };

    handleLocalMatrixUpdate({ mode: "update", index, data });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleCommentSave(evt, theme) {
    if (isNew) {
      return;
    }

    const data = { ...theme, comment: evt.target.value };
    mainStore.vendors.updateRiskTheme(matrix.id, theme.id, data);
  }

  function handleEditDescriptionsSubmit() {
    const { index, risk_descriptions } = editingDescriptionItem;

    handleLocalMatrixUpdate({
      mode: "update",
      // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'null | un... Remove this comment to see the full error message
      index,
      // @ts-expect-error TS(2322) FIXME: Type '{ risk_descriptions: RiskDescriptionProps[];... Remove this comment to see the full error message
      data: { risk_descriptions },
    });

    if (!isNew) {
      mainStore.vendors.updateRiskTheme(matrix.id, riskThemes[index].id, {
        risk_descriptions_attributes: risk_descriptions,
      });
    }

    setEditingDescriptionItem({} as EditingDescriptionProps);
  }

  function handleDeleteSubmit() {
    handleLocalMatrixUpdate({ mode: "delete", index: deletingItem });
    if (!isNew) {
      // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
      mainStore.vendors.deleteRiskTheme(matrix.id, riskThemes[deletingItem].id);
    }

    handleDeleteConfirmationClose();
  }

  function handleDeleteConfirmationClose() {
    handlePopupClose();
    setDeletingItem(null);
  }

  function handleAddNewClick() {
    if (!isAddingNew) {
      setIsAddingNew(true);
      return;
    }

    if (!newThemeTitle) {
      return;
    }

    const data = { title: newThemeTitle };
    // @ts-expect-error TS(2322) FIXME: Type '{ title: string; }' is not assignable to typ... Remove this comment to see the full error message
    handleLocalMatrixUpdate({ mode: "create", data });

    if (!isNew) {
      mainStore.vendors.createRiskTheme(matrix.id, data);
    }

    handleCancelAddNewClick();
  }

  function handleCancelAddNewClick() {
    setIsAddingNew(false);
    setNewThemeTitle("");
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'evt' implicitly has an 'any' type.
  function handleNewThemeTitleChange(evt) {
    setNewThemeTitle(evt.target.value);
  }

  function handlePopupClose() {
    setContextMenuOpened(null);
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'mode' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleLocalMatrixUpdate({ mode, index = null, data = null }) {
    let newRiskThemes = riskThemes;

    if (mode === "create") {
      newRiskThemes = [
        ...riskThemes,
        {
          // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...data,
          // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
          risk_descriptions: riskLevels.map((item) => ({
            content: `Select ${item.title}`,
          })),
        },
      ];
    } else if (mode === "update") {
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      newRiskThemes = riskThemes.map((item, i) =>
        // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        i === index ? { ...item, ...data } : item,
      );
    } else if (mode === "delete") {
      // @ts-expect-error TS(7006) FIXME: Parameter '_' implicitly has an 'any' type.
      newRiskThemes = riskThemes.filter((_, i) => i !== index);
    }

    mainStore.vendors.setCurrentRiskAssessment({
      ...matrix,
      risk_themes: newRiskThemes,
    });
  }

  // elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'theme' implicitly has an 'any' type.
  const renderThemeItem = (theme, index) => {
    const active = contextMenuOpened === index;
    const isRenaming = renamingItem?.index === index;
    const isEditingDescription = editingDescriptionItem?.index === index;
    const isDeleting = deletingItem === index;
    const hasSelectedDescription = theme.risk_descriptions.some(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) => item.selected,
    );

    const popupTrigger = (
      <div>
        <IconButton
          icon="moreHorizontal"
          size="sm"
          transparent
          data-testid="vdd-risk-assessment__risk-theme-context-menu-trigger"
          active={active}
        />
      </div>
    );

    const renderRegularPopupContent = (
      <div
        className="vdd-risk-assessment__risk-level-context-menu"
        data-testid="vdd-risk-assessment__risk-theme-context-menu"
      >
        <ul>
          <li
            data-testid="vdd-risk-assessment__risk-theme-context-menu-rename"
            onClick={() => handleRenameClick({ ...theme, index })}
          >
            Rename Theme
          </li>
          <li
            data-testid="vdd-risk-assessment__risk-theme-context-menu-edit-descrpitions"
            onClick={() => handleEditDescriptionsClick({ ...theme, index })}
          >
            Edit Theme Descriptions
          </li>
          {canDeleteRecords && (
            <li
              data-testid="vdd-risk-assessment__risk-theme-context-menu-delete"
              onClick={() => setDeletingItem(index)}
            >
              Delete Theme
            </li>
          )}
        </ul>
      </div>
    );

    const renderDeleteConfirmation = (
      <div className="vdd-risk-assessment__popup">
        <div className="table-dropdown success-dropdown">
          <ConfirmationDialog
            heading="Delete Theme"
            content="Are you sure you want to delete this Theme? This action is not reversible."
            handleConfirm={handleDeleteSubmit}
            handleReject={handleDeleteConfirmationClose}
          />
        </div>
      </div>
    );

    const renderConfirm = (
      <IconButton
        icon="check"
        size="sm"
        theme="secondary"
        data-testid="vdd-risk-assessment__risk-theme-confirm-change"
        onClick={isRenaming ? handleRenameSubmit : handleEditDescriptionsSubmit}
      />
    );

    const renderHeading = (
      <div className="vdd-risk-assessment__risk-theme-header">
        {isRenaming && (
          <>
            <input
              type="text"
              data-testid="vdd-risk-assessment__risk-theme-title-input"
              autoFocus
              value={isRenaming ? renamingItem.title : theme.title}
              placeholder="eg. Supplier Viability"
              onChange={handleTitleChange}
              onKeyDown={handleRenameKeyDown}
            />
            <IconButton
              icon="check"
              size="sm"
              theme="secondary"
              data-testid="vdd-risk-assessment__risk-theme-title-save"
              disabled={!renamingItem.title}
              onClick={handleRenameSubmit}
            />
            {theme.title && (
              <IconButton icon="close" size="sm" onClick={handleCancelRename} />
            )}
          </>
        )}
        {!isRenaming && (
          <h5>
            {theme.title}
            {isAutoCalc && (
              <div className="weighting">
                Weighting: {theme.weighting || 0}%
              </div>
            )}
          </h5>
        )}
        {isEditingDescription && renderConfirm}
        {!isEditingDescription && !isRenaming && (
          <Popup
            position="bottom left"
            trigger={popupTrigger}
            open={active}
            onOpen={() => setContextMenuOpened(index)}
            onClose={handlePopupClose}
            keepTooltipInside
          >
            {isDeleting ? renderDeleteConfirmation : renderRegularPopupContent}
          </Popup>
        )}
      </div>
    );

    // @ts-expect-error TS(7006) FIXME: Parameter 'description' implicitly has an 'any' ty... Remove this comment to see the full error message
    const renderDescriptionItem = (description, idx) => {
      const { content, selected } = description;

      return (
        <div
          key={idx}
          onClick={() =>
            isActive && handleSelectDescription({ ...theme, index }, idx)
          }
          className={classNames("vdd-risk-assessment__risk-theme-description", {
            editing: isEditingDescription,
            selected: selected && allowSelect,
            placeholder: !theme.title && !theme.id,
          })}
          data-testid="vdd-risk-assessment__risk-theme-description"
        >
          {isEditingDescription ? (
            <Textarea
              rows="1"
              onChange={(event) => handleDescriptionChange(event, idx)}
              dataTestID="risk-assessment-risk-description-item-textarea-active"
              defaultValue={content}
            />
          ) : (
            <Textarea
              rows="1"
              className="custom-textarea"
              disabled
              dataTestID="risk-assessment-risk-description-item-textarea"
              value={content}
            />
          )}
        </div>
      );
    };

    const renderDescriptionPlaceholder = (
      <div
        className="vdd-risk-assessment__risk-theme-description placeholder"
        data-testid="vdd-risk-assessment__risk-theme-description-placeholder"
      />
    );

    const renderComment = (
      <div
        className="vdd-risk-assessment__risk-theme-description"
        data-testid="vdd-risk-assessment__risk-theme-comment"
      >
        <Textarea
          rows="1"
          dataTestID="risk-assessment-risk-theme-comment-textarea"
          onChange={(event) => handleCommentChange(event, theme, index)}
          onBlur={(event) => handleCommentSave(event, theme)}
          value={theme.comment || ""}
          placeholder="-Type here-"
        />
      </div>
    );

    const renderDescriptions = (
      <div
        className={classNames("vdd-risk-assessment__risk-theme-descriptions", {
          "has-selected-description":
            (hasSelectedDescription && allowSelect) || !isActive,
        })}
      >
        {theme.risk_descriptions.map(renderDescriptionItem)}
        {isActive && renderDescriptionPlaceholder}
        {!isTemplate && renderComment}
      </div>
    );

    return (
      <div
        key={index}
        className="vdd-risk-assessment__risk-theme"
        data-testid="vdd-risk-assessment__risk-theme"
      >
        {renderHeading}
        {renderDescriptions}
      </div>
    );
  };

  const renderNewThemePlaceholder = (
    <div
      className="vdd-risk-assessment__risk-theme placeholder"
      data-testid="vdd-risk-assessment__risk-theme-add-new-placeholder"
    >
      <div className="vdd-risk-assessment__risk-theme-header">
        {isAddingNew && (
          <>
            <input
              type="text"
              data-testid="vdd-risk-assessment__risk-theme-title-input"
              autoFocus
              placeholder="eg. IT Security / Project Delivery / Governance"
              onChange={handleNewThemeTitleChange}
              onKeyDown={handleCreateKeyDown}
            />
            <IconButton
              icon="check"
              size="sm"
              theme="secondary"
              data-testid="vdd-risk-assessment__risk-theme-title-save"
              disabled={!newThemeTitle}
              onClick={handleAddNewClick}
            />
            <IconButton
              icon="close"
              size="sm"
              theme="tertiary"
              data-testid="vdd-risk-assessment__risk-theme-title-cancel"
              onClick={handleCancelAddNewClick}
            />
          </>
        )}
        {!isAddingNew && (
          <>
            <h5>Add New Theme</h5>
            <IconButton
              icon="plus"
              size="sm"
              transparent
              data-testid="vdd-risk-assessment__risk-theme-add-new-trigger"
              onClick={handleAddNewClick}
            />
          </>
        )}
      </div>
      <div className="vdd-risk-assessment__risk-theme-descriptions">
        {[...Array(riskLevels.length + 1)].map((_, index) => (
          <div
            key={index}
            className="vdd-risk-assessment__risk-theme-description placeholder"
          />
        ))}
      </div>
    </div>
  );

  return (
    <section
      className="vdd-risk-assessment__risk-themes"
      data-testid="vdd-risk-assessment__risk-themes"
    >
      {riskThemes.map(renderThemeItem)}
      {isActive && renderNewThemePlaceholder}
    </section>
  );
}

RiskAssessmentThemes.defaultProps = {
  isNew: false,
  isTemplate: false,
};

export default observer(RiskAssessmentThemes);

import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import ApproveButton from "./ApproveButton";
import Unlock from "./Unlock";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  approversIDs: any[];
  moduleIdentifier: ModuleIdentifier;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onApprove: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUnapprove: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reviews: any[];
}

export function ReviewerApproveButton({
  approversIDs,
  moduleIdentifier,
  onApprove,
  onUnapprove,
  reviews,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // Variables
  const userID = mainStore.users.user.id;
  const canApprove = approversIDs?.includes(userID) && hasModuleWriteAccess;
  const didApprove = reviews.some((review) => review.user_id === userID);

  return (
    <div data-testid="reviewer-approve-button">
      {canApprove && (
        <>
          {didApprove && (
            <Unlock
              onYes={onUnapprove}
              buttonText="Unapprove"
              showIcon={false}
              moduleIdentifier={moduleIdentifier}
              canEveryoneUnlock
            />
          )}

          {!didApprove && <ApproveButton onClick={onApprove} />}
        </>
      )}

      {!canApprove && <ApproveButton onClick={() => {}} disabled />}
    </div>
  );
}

ReviewerApproveButton.defaultProps = {
  approversIDs: [],
  reviews: [],
  showIcon: true,
};

export default observer(ReviewerApproveButton);

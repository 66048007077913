import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetProjectPathParams,
  GetProjectQueryResponse,
} from "../../models/GetProject";

/**
 * @description Get project
 * @summary Get project
 * @link /projects/:id
 */
export async function getProject(
  id: GetProjectPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetProjectQueryResponse>["data"]> {
  const res = await client<GetProjectQueryResponse>({
    method: "get",
    url: `/projects/${id}`,
    ...options,
  });
  return res.data;
}

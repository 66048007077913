import { action, makeObservable, observable } from "mobx";

import { RiskMethodologyAPI } from "@/api/legacy/risk-assessment/RiskMethodologiesApi";

import type { RiskMethodology } from "../../features/risk-assessment";
import type { MainStore } from "../Main";

export default class RiskMethodologies {
  mainStore: MainStore;
  list: Array<RiskMethodology> | null = null;
  indexedWorkspaceID: number | null = null;
  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      set: action,
      delete: action,
      setIndexedWorkspace: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/workspaces/:workspace_id/risk_methodologies
  async index(workspaceId: number) {
    if (this.indexedWorkspaceID === workspaceId) {
      return;
    }

    this.controller = new AbortController();

    const riskMethodologies = await RiskMethodologyAPI.getAll(
      workspaceId,
      this.controller.signal,
    );
    this.set(riskMethodologies);
    this.setIndexedWorkspace(workspaceId);
  }

  set(riskMethodologies: Array<RiskMethodology>) {
    this.list = riskMethodologies;
  }

  setIndexedWorkspace(workspaceID: number | null) {
    this.indexedWorkspaceID = workspaceID;
  }

  delete(id: number) {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    this.list = this.list.filter(
      (riskMethodology) => riskMethodology.id !== id,
    );
  }

  replaceWith(riskMethodology: RiskMethodology) {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    this.list = this.list.map((item) =>
      item.id === riskMethodology.id ? riskMethodology : item,
    );
  }

  cleanup() {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    this.set(null);
  }

  abort() {
    this.controller?.abort();
  }
}

import { orderBy } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import type { Folder, Section } from "@/stores/types/section-tags";
import { formatErrors } from "@/utils/errors";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class SectionTagStore {
  mainStore: MainStore;

  // Observable objects
  list: Section[] = [];
  current: Section | null = null;
  currentFolder: Folder | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      current: observable,
      currentFolder: observable,
      setList: action,
      setCurrent: action,
      setCurrentFolder: action,
      replaceSectionTag: action,
      orderedList: computed,
    });

    this.mainStore = mainStore;
  }

  // Computed property
  get orderedList() {
    return orderBy(this.list, "position", ["asc"]);
  }

  // GET /api/react/module_workspaces/:module_workspace_id/section_tags
  async index(
    moduleWorkspaceID: number,
    params = {},
    useCurrentModuleWorkspaceID = false,
  ) {
    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/section_tags`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      const { moduleWorkspaceID: currentModuleWorspceID } =
        this.mainStore.context;
      if (
        useCurrentModuleWorkspaceID &&
        currentModuleWorspceID &&
        currentModuleWorspceID !== moduleWorkspaceID
      ) {
        return;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'parent_section_tag_id' does not exist on... Remove this comment to see the full error message
      if (!params.parent_section_tag_id) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        this.setCurrentFolder(null);
      }

      this.setList(data.section_tags);
    } catch (error) {
      window.console.log(`"SectionTags#index" error ${error}`);
    }
  }

  // GET /api/react/module_workspaces/:module_workspace_id/section_tags/tree
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async getTree(moduleWorkspaceID, params = {}) {
    const { data } = (await legacyApi({
      method: "GET",
      url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/section_tags/tree`,
      headers: this.mainStore.getHeaders(),
      params,
    })) as { data: { tree: Section[] } };

    return data.tree;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async show(id): Promise<Folder> {
    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/section_tags/${id}`,
        headers: this.mainStore.getHeaders(),
      });

      this.setCurrentFolder(data.section_tag);

      return data.section_tag;
    } catch (error) {
      window.console.log(`"SectionTags#show" error ${error}`);
    }
  }

  // POST /api/react/module_workspaces/:module_workspace_id/section_tags
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async create(moduleWorkspaceID, sectionTag) {
    const indexParams = {
      parent_section_tag_id: sectionTag.parent_section_tag_id,
    };
    const params = {
      section_tag: sectionTag,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/section_tags`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });
      // @ts-expect-error ...
      const data = response?.response?.data || response?.data;
      const errors = data?.errors;
      if (errors) {
        this.mainStore.toast.setErrorText(`${formatErrors(errors)}`);
        return;
      }
      this.setCurrent(data?.section_tag);

      // Re-fetch index as well
      await this.index(moduleWorkspaceID, indexParams);
    } catch (error) {
      window.console.log(`"SectionTags#create" error ${error}`);
    }
  }

  // PUT/PATCH /api/react/section_tags/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async update(moduleWorkspaceID, sectionTagID, sectionTag) {
    const indexParams = {
      parent_section_tag_id: sectionTag.parent_section_tag_id,
    };
    const params = {
      section_tag: sectionTag,
    };

    try {
      const { data } = await legacyApi({
        method: "PUT",
        url: `${API_URL}/section_tags/${sectionTagID}`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      this.setCurrent(data.section_tag);

      // Re-fetch index as well
      await this.index(moduleWorkspaceID, indexParams);

      return data;
    } catch (error) {
      window.console.log(`"SectionTags#update" error ${error}`);
      throw new Error("Update error");
    }
  }

  // DELETE /api/react/section_tags/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async delete(moduleWorkspaceID, sectionTagID) {
    const sectionTag = this.currentFolder;
    const indexParams = { parent_section_tag_id: sectionTag?.id };

    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/section_tags/${sectionTagID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast?.setErrorText(response.response.data.errors);
        return null;
      }
      // Refetch the list of Section Tags
      this.index(moduleWorkspaceID, indexParams);
    } catch (error) {
      window.console.log(`"SectionTags#delete" error ${error}`);
    }
  }

  // POST /api/react/section_tags/:section_tag_id/files/:id/external_file_share_token
  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionID' implicitly has an 'any' type... Remove this comment to see the full error message
  async generateDocumentURI(sectionID, id) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/section_tags/${sectionID}/files/${id}/external_file_share_token`,
        headers: this.mainStore.getHeaders(),
      });

      const { token } = data.external_file_share_token;
      const baseURL = `${window.location.origin}${API_URL}`;

      return `${baseURL}/shared_blobs/${token}`;
    } catch (error) {
      window.console.log(`"SectionTags#generateDocumentURI" error ${error}`);

      return null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  async reorder(params) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/section_tags/${params.sectionTagID}/reposition`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      if (response.data?.section_tag) {
        this.replaceSectionTag(
          response.data.section_tag,
          response.data.section_tag,
        );
      }

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast?.setErrorText(response.response.data.errors);
      }
    } catch (error) {
      window.console.log(`"SectionTag#reposition" error ${error}`);
    }
  }

  // PUT/PATCH /api/react/section_tags/:id
  async updatePermissions(
    // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
    moduleWorkspaceID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTagID' implicitly has an 'any' t... Remove this comment to see the full error message
    sectionTagID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'userIDs' implicitly has an 'any' type.
    userIDs,
    // @ts-expect-error TS(7006) FIXME: Parameter 'departmentIDs' implicitly has an 'any' ... Remove this comment to see the full error message
    departmentIDs,
    parentSectionTagID = null,
  ) {
    if (!userIDs && !departmentIDs) {
      return;
    }

    const sectionTag = {};

    if (parentSectionTagID) {
      // @ts-expect-error TS(2339) FIXME: Property 'parent_section_tag_id' does not exist on... Remove this comment to see the full error message
      sectionTag.parent_section_tag_id = parentSectionTagID;
    }

    if (userIDs) {
      // @ts-expect-error TS(2339) FIXME: Property 'user_ids' does not exist on type '{}'.
      sectionTag.user_ids = userIDs;
    }

    if (departmentIDs) {
      // @ts-expect-error TS(2339) FIXME: Property 'department_ids' does not exist on type '... Remove this comment to see the full error message
      sectionTag.department_ids = departmentIDs;
    }

    const params = {
      section_tag: sectionTag,
    };

    try {
      const { data } = await legacyApi({
        method: "PUT",
        url: `${API_URL}/section_tags/${sectionTagID}`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      this.setCurrent(data.section_tag);

      this.index(moduleWorkspaceID, sectionTag);
    } catch (error) {
      window.console.log(`"SectionTags#update" error ${error}`);
    }
  }

  // Actions

  setList(value: Section[] = []) {
    this.list = value;
  }

  setCurrent(value?: Section) {
    // @ts-expect-error TS(2322) FIXME: Type 'Section | undefined' is not assignable to ty... Remove this comment to see the full error message
    this.current = value;
  }

  setCurrentFolder(value?: Folder) {
    // @ts-expect-error TS(2322) FIXME: Type 'Folder | undefined' is not assignable to typ... Remove this comment to see the full error message
    this.currentFolder = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'previousSectionTag' implicitly has an '... Remove this comment to see the full error message
  replaceSectionTag(previousSectionTag, newSectionTag) {
    this.list = this.list.map((sectionTag) =>
      sectionTag.id === previousSectionTag.id ? newSectionTag : sectionTag,
    );
  }

  // Store Helpers

  cleanup() {
    this.setList();
    this.setCurrent();
  }
}

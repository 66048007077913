import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const PushLeft = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33268 16.5001C2.33268 16.9603 1.95959 17.3334 1.49935 17.3334C1.03911 17.3334 0.666016 16.9603 0.666016 16.5001V1.50008C0.666016 1.03984 1.03911 0.666748 1.49935 0.666748C1.95959 0.666748 2.33268 1.03984 2.33268 1.50008V16.5001ZM3.99935 9.00008L4.00268 8.93175L4.01622 8.83281L4.0408 8.73996L4.07741 8.6474L4.12101 8.56616L4.16294 8.50412L4.24343 8.41083L7.57676 5.07749C7.9022 4.75206 8.42983 4.75206 8.75527 5.07749C9.05568 5.3779 9.07878 5.85059 8.8246 6.1775L8.75527 6.256L6.84518 8.16675H16.4994C16.9596 8.16675 17.3327 8.53984 17.3327 9.00008C17.3327 9.46032 16.9596 9.83342 16.4994 9.83342H6.84518L8.75527 11.7442C9.05568 12.0446 9.07878 12.5173 8.8246 12.8442L8.75527 12.9227C8.45487 13.2231 7.98218 13.2462 7.65527 12.992L7.57676 12.9227L4.24343 9.58934L4.20893 9.5527L4.16296 9.49617L4.10324 9.40347L4.07739 9.35266L4.04925 9.28479L4.02922 9.22174L4.00504 9.09798L3.99935 9.00008Z"
      fill="white"
    />
  </svg>
);

PushLeft.displayName = "PushLeftIcon";

export default PushLeft;

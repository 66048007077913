import React, { useEffect, useState } from "react";

interface Props {
  name: string;
  countLabel?: number;
  disabled?: boolean;
  model?: string;
  multiline?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBlur?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onKeyDown?: (...args: any[]) => any;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textInput?: any;
  value?: string | number;
  updateParameterValue?: (name: string, value: string | number) => void;
}

function InputTable({
  name,
  placeholder,
  value,
  onChange,
  disabled,
  countLabel,
  model,
  onKeyDown,
  onBlur,
  multiline,
  textInput,
  updateParameterValue,
}: Props) {
  // State
  const [initialValue, setInitialValue] = useState(null);

  const valueData = value ?? "";
  // Variables
  const defaultValue = valueData;

  // Effects
  useEffect(() => {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
    setInitialValue(defaultValue);
  }, [initialValue]);

  useEffect(() => {
    if (updateParameterValue) {
      updateParameterValue(name, defaultValue);
    }
  }, [defaultValue]);

  return multiline ? (
    <div
      data-name={name}
      onInput={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      // @ts-expect-error TS(2322) FIXME: Type 'false | "plaintext-only"' is not assignable ... Remove this comment to see the full error message
      contentEditable={!disabled && "plaintext-only"}
      disabled={disabled}
      suppressContentEditableWarning
      data-testid="input-cell"
      className="cell-multiline-text"
      ref={textInput}
    >
      {initialValue}
    </div>
  ) : (
    <>
      <input
        type="text"
        name={name}
        placeholder={placeholder}
        value={valueData}
        onChange={onChange}
        disabled={disabled}
        autoComplete="off"
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={textInput}
        className="input-table"
      />
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {model !== "User" && disabled && countLabel > 0 && (
        <span className="right-label">{countLabel} Records using</span>
      )}
    </>
  );
}

InputTable.defaultProps = {
  placeholder: "",
  onChange: () => {},
  disabled: false,
  countLabel: 0,
  multiline: false,
  textInput: null,
};

export default InputTable;

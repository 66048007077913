import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const CommentSolid = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 11.3625C2 6.54329 6.6578 3 12.001 3C17.3442 3 22.0012 6.5434 22 11.3627C22 11.3706 21.9999 11.3784 21.9997 11.3863C21.975 12.4115 21.7463 13.4216 21.3269 14.3574C20.9775 15.137 20.5017 15.8521 19.9196 16.4742L20.3608 20.2618C20.4013 20.6098 20.255 20.9534 19.9761 21.1654C19.6972 21.3774 19.327 21.4264 19.0025 21.2942L14.4732 19.449C13.6617 19.6482 12.8273 19.7411 11.991 19.7249C6.64665 19.7205 2 16.1783 2 11.3625Z"
      fill="#353549"
    />
  </svg>
);

CommentSolid.displayName = "CommentSolidIcon";

export default CommentSolid;

import type {
  CommentTypeProperty,
  UserComment,
} from "@/stores/types/comment-types";
import type { User } from "@/stores/types/user-types";

import api from "../api";
import type {
  CommentCreateData,
  CommentsGetResponse,
  CommentUpdateData,
  CommentUrlParams,
  CommentUrlParamsNoID,
  UserTasksQueryParams,
} from "./types";

function commentURL(urlParams: CommentUrlParams) {
  const { moduleWorkspaceID, commentID } = urlParams;

  return `/module_workspaces/${moduleWorkspaceID}/comments${
    commentID ? `/${commentID}` : ""
  }`;
}

export async function getAllComments(
  urlParams: CommentUrlParamsNoID,
  queryParams: CommentTypeProperty,
) {
  const response = await api.get<CommentsGetResponse>(commentURL(urlParams), {
    params: queryParams,
  });

  return response.data;
}

export async function getComment(urlParams: CommentUrlParams) {
  const response = await api.get(commentURL(urlParams));

  return response.data;
}

export async function createComment(
  urlParams: CommentUrlParamsNoID,
  data: CommentTypeProperty & { comment: CommentCreateData },
) {
  const response = await api.post(commentURL(urlParams), {
    ...data,
    comment: data.comment,
  });

  return response.data;
}

export async function putComment(
  urlParams: CommentUrlParams,
  data: { comment: CommentUpdateData },
) {
  const response = await api.put(commentURL(urlParams), data);

  return response.data;
}

export async function deleteComment(urlParams: CommentUrlParams) {
  const response = await api.delete(commentURL(urlParams));

  return response.data;
}

export async function userTasks(queryParams: UserTasksQueryParams) {
  const response = await api.get<{ comments: UserComment[] }>("/user_tasks", {
    params: queryParams,
  });

  return response.data;
}

export async function getPrivateUsers(urlParams: CommentUrlParamsNoID) {
  const response = await api.get<{ users: User[] }>(
    `${commentURL(urlParams)}/private_commenters`,
  );

  return response.data;
}

export async function updateSubscription(commentID: number, enabled: boolean) {
  const response = await api.put(`/comments/${commentID}/subscriptions`, {
    enabled,
  });

  return response.data;
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Export = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V9C20 9.55228 19.5523 10 19 10H13C12.4872 10 12.0645 9.61396 12.0067 9.11662L12 9L11.999 4H7C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44772 20 7 20H9C9.55228 20 10 20.4477 10 21C10 21.5523 9.55228 22 9 22H7C5.34315 22 4 20.6569 4 19V5C4 3.34315 5.34315 2 7 2H14ZM13.999 4.414L14 8H17.586L13.999 4.414ZM17.7071 21.7071C17.3166 22.0976 16.6834 22.0976 16.2929 21.7071L12.0858 17.5C11.6953 17.1095 11.6953 16.4763 12.0858 16.0858C12.4763 15.6953 13.1095 15.6953 13.5 16.0858L16 18.5858V13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13V18.5858L20.5 16.0858C20.8905 15.6953 21.5237 15.6953 21.9142 16.0858C22.3047 16.4763 22.3047 17.1095 21.9142 17.5L17.7071 21.7071Z"
      fill="#353549"
    />
  </svg>
);

Export.displayName = "ExportIcon";

export default Export;

import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import IssueManagementContextMenu from "./IssueManagementContextMenu";

function IssueManagementActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();

  // Variables
  const path = location.pathname;
  const issueManagementID = Number(path.substring(path.lastIndexOf("/") + 1));
  const recordVersionList = mainStore.recordVersions.list.find(
    (rv) => rv.id === issueManagementID,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isClosed = status === "closed";

  if (!recordVersion) {
    return null;
  }

  return (
    <IssueManagementContextMenu
      recordVersion={recordVersion}
      isClosed={isClosed}
      showOnlyIcon={false}
      withoutBackground
    />
  );
}

export default observer(IssueManagementActionBar);

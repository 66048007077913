import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

interface Props {
  fieldName: string;
  recordVersionID: number;
  width: number | string;
  defaultValue?: string;
  locked?: boolean;
  pinned?: boolean;
}

function Checkbox({
  fieldName,
  recordVersionID,
  defaultValue,
  width,
  locked,
  pinned,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const checked = defaultValue === "true";

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleChange = (event) => {
    mainStore.recordVersions.update({
      recordVersionID,
      fieldName,
      value: mainStore.avroSchemas.serializeValue(
        fieldName,
        event.target.checked,
      ),
    });
  };

  return (
    <li
      className={classNames({
        "locked-cell pointer-events-none": locked,
        pinned,
      })}
      style={{ width }}
    >
      <div className="cell-content">
        <input
          type="checkbox"
          className="cell-checkbox"
          defaultChecked={checked}
          onChange={handleChange}
        />
      </div>
    </li>
  );
}

export default observer(Checkbox);

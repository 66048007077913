import { observer } from "mobx-react";
import React, { useEffect } from "react";

import type { Attachment, AttachmentFile, AttachmentGroup } from "@/api";
import { attachmentFileType } from "@/api";

import { useMainStore } from "../../../contexts/Store";
import ListSettings from "../settings/ListSettings";
import ListTitleSettings from "../settings/ListTitleSettings";
import Table from "../Table";

const headers = [
  {
    field_name: "indicator",
    title: "",
    disabled: true,
    width: 50,
    minWidth: 0,
  },
  {
    field_name: "title",
    title: "Title",
    disabled: true,
    width: 600,
  },
  {
    field_name: "contributor",
    title: "Contributor",
    disabled: true,
    width: 190,
  },
  {
    field_name: "contributor",
    title: "Contributor",
    disabled: true,
    width: 200,
  },
  {
    field_name: "original",
    title: "Document",
    disabled: false,
    width: 670,
  },
];

function PoliciesLibraryList() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { moduleWorkspaceID, workspaceID } = mainStore.context;

  const load = () => {
    if (moduleWorkspaceID) {
      mainStore.policies.policies_library(moduleWorkspaceID);
    }
  };

  // Effects
  useEffect(() => {
    load();
  }, [workspaceID, moduleWorkspaceID]);

  const renderHeaders = (
    <>
      {headers.map((header) => (
        <ListTitleSettings
          key={header.field_name}
          title={header.title}
          fieldName={header.field_name}
          width={header.width}
          minWidth={header.minWidth}
        />
      ))}
    </>
  );

  const renderRows = mainStore.policies.libraryRecords.map((item) => {
    const item_original = item.original;
    const attachmentFile: AttachmentFile = {
      content_type: item_original.content_type,
      filename: item_original.file_name,
      signed_id: item_original.id,
      url: item_original.url,
    };

    const attachmentData: Attachment = {
      original: attachmentFile,
      preview: null,
      processed: null,
      created_at: item.created_at,
      upload_date: item.created_at,
      url: attachmentFile.url,
      attachment_group_id: item.id,
      id: item.id,
      file_type: attachmentFileType.direct_upload,
      version: 1,
    };

    const attachmentGroupData: AttachmentGroup = {
      attachments: [attachmentData],
      field_name: headers[1].field_name,
      id: attachmentData.id,
    };

    return (
      <ListSettings
        key={item.id}
        headers={headers}
        data={item}
        model="PoliciesLibrary"
        disableStretchCell
        attachmentGroups={[attachmentGroupData]}
        attachmentViewText="View Policy Template"
        attachmentDownloadText="Download Template"
      />
    );
  });

  return (
    <Table>
      <div className="dropdown-table">
        <div className="settings-wrap policy-library-content-wrap">
          <div className="policies_library_text">
            <div className="policies_library_title">
              <br />
              <b>What are policy templates?</b>
            </div>
            <div className="policies_library_content">
              <b>
                The templates are not legal advice and should only be used as
                starting precedents and operational best practices. Each product
                and company is unique, and you should consult with an
                experienced lawyer licensed in the relevant jurisdiction(s) to
                tailor the agreement as needed.
              </b>
            </div>
          </div>
          {renderRows.length > 0 && (
            <div className="settings-content-wrap policy-library-content-wrap">
              <div className="policy_library-table">
                <ul>
                  <div className="list-column-wrap">
                    <div className="policy_library-table-wrap">
                      {renderHeaders}
                    </div>
                  </div>
                  <div className="policy_library-table-block" />
                </ul>
              </div>
              {renderRows}
            </div>
          )}
        </div>
      </div>
    </Table>
  );
}

export default observer(PoliciesLibraryList);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const UploadPolicy = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 11C11.4477 11 11 10.5523 11 9.99999V3.99999H5.99549L5.99953 12H8.99709C9.55099 12 10 12.451 10 13.0043V16.9957C10 17.5504 9.55148 18 8.99709 18H5.99999L6.00001 20L17.9979 20L18.0001 11H12ZM18 8.99999L13 3.99999V8.99999H18ZM4 18C3.44744 17.9984 3 17.548 3 16.9957V13.0043C3 12.4506 3.44695 12.0016 4 12V4.00001C4 2.89544 4.89588 2 5.99547 2H13.3028C13.5787 2 13.9618 2.15949 14.1533 2.3511L19.649 7.84885C19.8429 8.04276 20 8.42796 20 8.69925V20.0007C20 21.1049 19.1055 22 17.9979 22H6.00213C4.89638 22 4 21.1018 4 20V18Z"
      fill="#353549"
    />
  </svg>
);

UploadPolicy.displayName = "UploadPolicyIcon";

export default UploadPolicy;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Book1 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.51151 19.4983C5.54988 19.4983 3.6292 20.7771 3.5864 20.8081C2.92528 21.2869 2 20.8146 2 19.9983V5.9997C2 5.78664 2.06806 5.57914 2.19426 5.40747C2.44206 5.07037 4.14786 3 7.49945 3C9.1192 3 10.7019 3.71926 11.999 5.23267C13.2757 3.7431 14.8788 3 16.4986 3C19.7838 3 21.556 5.07037 21.8038 5.40747C21.93 5.57914 21.998 5.78664 21.998 5.9997V19.9983C21.998 20.8146 21.0727 21.2869 20.4116 20.8081C20.3688 20.7771 18.6052 19.4983 16.4865 19.4983C14.3774 19.4983 12.5622 20.8252 12.539 20.84C12.3679 20.9507 12.1813 21.0007 11.999 21C11.8167 21.0007 11.6301 20.9507 11.459 20.84C11.4358 20.8252 9.68962 19.4983 7.51151 19.4983ZM3.99977 6.38986C3.99977 6.59195 3.99977 17.9198 3.99977 18.2884C5.12962 17.7784 6.26358 17.5134 7.51136 17.4986C8.74583 17.5134 9.87238 17.8263 10.9991 18.3368C10.9991 17.8739 10.9991 7.26497 10.9991 7.26497C10.9991 7.26497 9.82066 4.99982 7.49942 4.99982C5.31952 4.99982 4.17432 6.20154 3.99977 6.38986ZM19.9982 6.38986C19.8236 6.20154 18.6455 4.99982 16.4985 4.99982C14.2228 4.99982 12.9989 7.26497 12.9989 7.26497C12.9989 7.26497 12.9989 17.8739 12.9989 18.3368C14.1256 17.8263 15.2521 17.5134 16.4866 17.4986C17.7344 17.5134 18.8683 17.7784 19.9982 18.2884C19.9982 17.9198 19.9982 6.59195 19.9982 6.38986Z"
      fill="#353549"
    />
  </svg>
);

Book1.displayName = "Book1Icon";

export default Book1;

import dayjs from "dayjs";

export function getTimeSensitiveGreeting() {
  const currentHour = dayjs().hour();

  if (currentHour >= 0 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour < 17) {
    return "Good Afternoon";
  } else if (currentHour < 21) {
    return "Good Evening";
  }
  return "Good Night";
}

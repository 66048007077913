import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { CreateFieldOptionMutationRequest } from "../gen";
import { createFieldOption } from "../gen/axios/fieldsController";
import { COLUMNS_QUERY_KEY } from "./columns";

export function useCreateFieldOption(fieldId: number) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateFieldOptionMutationRequest) =>
      createFieldOption(fieldId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [COLUMNS_QUERY_KEY],
      });
    },
  });
}

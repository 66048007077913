import React from "react";

import type { DashboardComponent } from "@/api";
import chartEmptyState from "@/images/dashboard/chart_empty_state.svg";

import { formatSingleKeyBarChartData } from "../../utils";
import { BarChart } from "../charts/BarChart";

export default function PublishedMarketingRecords({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const mutated = formatSingleKeyBarChartData(widgetData);

  const valueSum = mutated.data.reduce(
    (acc, curr) => acc + Number(curr.value),
    0,
  );

  return valueSum < 1 ? (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Marketing Records
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <img
            src={chartEmptyState}
            alt="close-icon"
            className="tw-h-[212px] tw-w-full"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Marketing Records
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <BarChart data={mutated.data} isMultiXLines />
        </div>
      </div>
    </div>
  );
}

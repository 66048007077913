import "./module-header.scss";

import type { ReactNode } from "react";
import React from "react";

interface ModuleHeaderProps {
  children: ReactNode;
}

export default function ModuleHeader(props: ModuleHeaderProps) {
  return <div className="module-header">{props.children}</div>;
}

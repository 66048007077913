import { observer } from "mobx-react";
import React, { useContext, useState } from "react";

import { StoreContext } from "../../../contexts/Store";
import Spinner from "../shared/Spinner";

type ScreenType = "company-library";

interface Props {
  handleScreenChange: (screen: ScreenType, fromDialog?: boolean) => void;
}

function ControlMappingLibraryTemplateButton({ handleScreenChange }: Props) {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // state
  const [isSaving, setIsSaving] = useState(false);
  // Variables
  const { libraryRecords } = mainStore.controlMappings;
  const disabled = !libraryRecords.some((item) => item.checked) || isSaving;

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const ids = libraryRecords
      .filter((item) => item.checked)
      .map((item) => item.id);

    mainStore.pageLoading.startLoading();
    await mainStore.controlMappings.createBulkCompanyLibraryItems(ids);
    mainStore.pageLoading.endLoading();
    handleScreenChange("company-library");
  }

  return (
    <button
      className="table-button header-action blue-light"
      onClick={handleClick}
      disabled={disabled}
      data-testid="cm-library-apply"
    >
      Add To Company Library
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(ControlMappingLibraryTemplateButton);

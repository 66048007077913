import { Button, IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { Link, useHistory, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import type { TableName } from "@/api";
import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SlideMenu from "../../slideMenu/SlideMenu";
import EmailAttest from "../policy/EmailAttest";
import ApproveButton from "../shared/ApproveButton";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import MoveToSectionPopup from "../shared/MoveToSectionPopup";
import PublishFlow from "../shared/PublishFlow";
import SendRecordVersion from "../shared/SendRecordVersion";
import ShareToCW from "../shared/ShareToCW";
import Unlock from "../shared/Unlock";

interface Props {
  moduleWorkspaceID: number;
  recordVersionID: number;
  tableName: TableName;
  approvedByUserIds?: number[];
  showOnlyIcon?: boolean;
}

function TrainingContextMenu({
  recordVersionID,
  approvedByUserIds,
  tableName,
  moduleWorkspaceID,
  showOnlyIcon = true,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // vars
  const {
    canDeleteRecords,
    canPublishOrLockRecords,
    canUnpublishOrUnlockRecords,
  } = mainStore.userPermissions;
  const { workspaceID, isIW } = mainStore.context;
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  )!;
  const approversIds =
    mainStore.avroSchemas.valueForField("approvers", recordVersion?.data) || [];
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const currentUserID = mainStore.users.user.id;
  const canApprove = currentUserID && approversIds.includes(currentUserID);
  const hasApproved =
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    currentUserID && approvedByUserIds.includes(currentUserID);
  const isCompleted = status === "completed";
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName("training", moduleWorkspaces, true);
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { taskDetail } = mainStore;

  // State
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("menu");

  const history = useHistory();
  const location = useLocation();
  const isTrainingDetailView = useMemo(() => {
    const trainingDetailPageRe = /^\/modules\/training\/\d+$/;
    const matches = location.pathname.match(trainingDetailPageRe);

    return Boolean(matches && matches.length > 0);
  }, [location.pathname]);

  // effects
  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
    }
  }, [showPopup]);

  // funcs
  function onApprove() {
    if (recordVersionID) {
      mainStore.reviews.createApproval(recordVersionID);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'rvID' implicitly has an 'any' type.
  const handleMove = async (rvID, sectionTagID) => {
    await mainStore.trainings.updateSection(rvID, sectionTagID);
  };

  function openAttestSlideMenu() {
    setIsDropdownOpen(false);
    // @ts-expect-error TS(2345) FIXME: Argument of type '"attest"' is not assignable to p... Remove this comment to see the full error message
    setShowSlideMenu("attest");
  }

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDeleteConfirmationOpen(true);
  }

  async function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.trainings.delete(recordVersionID);
      mainStore.toast.setText("Training deleted!");

      if (isTrainingDetailView) {
        history.push(`/workspaces/${workspaceID}/modules/training`);
      }
    }
  }

  async function unlockRecordVersion() {
    mainStore.toast.setInfoText(
      "Unlocking record... this may take a few seconds",
    );
    const success = await mainStore.trainings.unlock(recordVersionID);

    if (success) {
      mainStore.toast.setText("Record unlocked");
    }
  }

  async function handleArchive() {
    const success = await mainStore.trainings.archive(recordVersionID);
    handlePopUpClose();

    let tab = "All";
    if (location.pathname.endsWith("published")) {
      tab = "Published";
    }
    if (success) {
      mainStore.trainings.index({
        workspaceID,
        tab,
      });
      mainStore.toast.setText("Record archived");
    }
  }

  function handlePopUpOpen() {
    setIsDropdownOpen(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(false);
    setViewPopup("menu");
  }

  function closeSlideMenu() {
    setShowSlideMenu(null);
  }

  function openSendRecordsSlideMenu() {
    // @ts-expect-error TS(2345) FIXME: Argument of type '"send"' is not assignable to par... Remove this comment to see the full error message
    setShowSlideMenu("send");
  }

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isDropdownOpen,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="training-context-menu-dropdown"
    >
      <ul>
        {!isTrainingDetailView && (
          <Link
            to={`/workspaces/${workspaceID}/modules/training/${recordVersionID}`}
            data-testid="view-detail"
          >
            View {recordName} Detail
          </Link>
        )}
        {!isCompleted && (
          <>
            <li
              className={classNames({
                // @ts-expect-error TS(2339) FIXME: Property 'length' does not exist on type 'SectionT... Remove this comment to see the full error message
                disabled: mainStore.sectionTags.length === 0,
              })}
              onClick={() => setViewPopup("move")}
              data-testid="training-context-menu-dropdown-move"
            >
              Move to
            </li>
            {isIW && hasModuleWriteAccess && (
              <ShareToCW
                recordName={recordName}
                sendTrigger={openSendRecordsSlideMenu}
              />
            )}
          </>
        )}
        {isCompleted && (
          <>
            <Link
              to={`/workspaces/${workspaceID}/modules/training/${recordVersionID}/historical-versions`}
            >
              View Previous Versions
            </Link>
            <li onClick={openAttestSlideMenu}>Email / Attest Training</li>
            <li onClick={() => setViewPopup("archive")}>Archive</li>
          </>
        )}
        <li onClick={handleCreateTask}>Create Task</li>
        <hr />
        <li
          onClick={deleteRowConfirmation}
          data-testid="training-context-menu-dropdown-delete"
        >
          Delete
        </li>
      </ul>
    </div>
  );

  const renderArchiveConfirmation = (
    <div
      className="table-dropdown success-dropdown archive"
      style={{ width: 230 }}
    >
      <div>
        <h4>Archive Training</h4>
        <p>
          Please read carefully and make sure this Training qualifies the
          following points before you confirm archive:
        </p>
        <ul className="ul-square">
          <li>Training is no longer enforced</li>
          <li>No further updates can be made</li>
          <li>Open versions will be deleted with archive</li>
        </ul>
        <div className="confirmation">
          <button type="button" onClick={handleArchive}>
            Yes
          </button>
          <button type="button" onClick={handlePopUpClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Training"
      content="Are you sure you want to delete this training? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  const renderActionButton = () => {
    if (isCompleted && canUnpublishOrUnlockRecords) {
      return <Unlock onYes={unlockRecordVersion} tableName={tableName} />;
    }

    if (status === "in_review" && canApprove && !hasApproved) {
      return <ApproveButton onClick={onApprove} />;
    }

    if (status === "ready_to_finalize" && canPublishOrLockRecords) {
      return (
        <PublishFlow
          recordVersionID={recordVersion.id}
          tableName={tableName}
          moduleStore={mainStore.trainings}
          moduleIdentifier="training"
        />
      );
    }

    if (status === "pending_attachment" && isTrainingDetailView) {
      return null;
    }

    return (
      <Button
        color="tertiary"
        size="md"
        className="tw-w-[86px] tw-max-w-[86px]"
        onClick={() =>
          history.push(
            `/workspaces/${workspaceID}/modules/training/${recordVersionID}`,
          )
        }
      >
        View
      </Button>
    );
  };

  return (
    <div
      className={classNames("list-points", {
        "without-background": !showOnlyIcon,
      })}
      data-testid="training-context-menu"
    >
      {renderActionButton()}
      <Popup
        position="bottom right"
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => renderTrigger}
        disabled={!canDeleteRecords}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        open={isDropdownOpen}
        keepTooltipInside
      >
        {viewPopup === "move" && (
          <MoveToSectionPopup
            moduleName="Trainings"
            recordVersionID={recordVersionID}
            onMove={handleMove}
            onClose={() => setShowPopup(false)}
          />
        )}
        {!isDeleteConfirmationOpen &&
          !showSlideMenu &&
          viewPopup === "menu" &&
          renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
        {viewPopup === "archive" && renderArchiveConfirmation}
      </Popup>
      <SlideMenu open={!!showSlideMenu} closeSlideMenu={closeSlideMenu}>
        {showSlideMenu === "send" && (
          <SendRecordVersion
            recordVersionID={recordVersionID}
            recordName={recordName}
          />
        )}
        {showSlideMenu === "attest" && (
          <EmailAttest
            tableName="Default"
            moduleWorkspaceID={moduleWorkspaceID}
            recordVersionID={recordVersionID}
            moduleIdentifier="training"
          />
        )}
      </SlideMenu>
    </div>
  );
}

TrainingContextMenu.defaultProps = {
  approvedByUserIds: [],
};

export default observer(TrainingContextMenu);

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import { formatDate } from "../../../helpers/DateFormatters";
import UsersCircle from "../UsersCircle";

interface Props {
  verifierID: number;
  width: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date?: any; // TODO: PropTypes.instanceOf(Date)
  locked?: boolean;
  pinned?: boolean;
}

function Verifier({ date, verifierID, width, locked, pinned }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Variables
  const verifier = mainStore.users.allUsers.find(
    (user) => verifierID === user.id,
  );

  return (
    <li
      className={classNames("cell", {
        "read-only": isCurrentWorkspaceActive,
        "locked-cell": locked && isCurrentWorkspaceActive,
        "pointer-events-none": locked || isReadOnly,
        "table-cell--disabled": isReadOnly,
        pinned,
      })}
      style={{ width }}
    >
      <div className="cell-content">
        {verifier ? (
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          <UsersCircle user={verifier} additionalText={formatDate(date)} />
        ) : (
          <span className="na-verifier">N/A</span>
        )}
      </div>
    </li>
  );
}

export default observer(Verifier);

import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import {
  downloadFile,
  getIntegrationName,
} from "@/stores/helpers/AttachmentHelper";
import type { MainStore } from "@/stores/Main";

import type { IntegrationAttachmentTypes } from "../types/attachment-types";

interface AdditionalFileType {
  content_type: string;
  file_name: string;
  url: string;
}

export default class FilesStore {
  mainStore: MainStore;

  // Observable objects
  attachmentGroupID?: number | null = null;
  attachmentID?: number | null = null;
  contentType?: string | null = null;
  fileByteSize = null;
  fileName?: string | null = null;
  preview?: AdditionalFileType | null = null;
  processed?: AdditionalFileType | null = null;
  url?: string | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      attachmentGroupID: observable,
      attachmentID: observable,
      contentType: observable,
      fileByteSize: observable,
      fileName: observable,
      preview: observable,
      processed: observable,
      url: observable,

      setAttachmentGroupID: action,
      setAttachmentID: action,
      setContentType: action,
      setFileByteSize: action,
      setFileName: action,
      setPreview: action,
      setProcessed: action,
      setURL: action,
    });

    this.mainStore = mainStore;
  }

  // API

  // GET /api/react/record_versions/:record_version_id/:field_name/files/aws_url
  async fetchFile({
    recordVersionID,
    fieldName,
    attachmentGroupID = null,
    attachmentID = null,
    downloadMode = false,
  }: {
    recordVersionID: number;
    fieldName: string;
    attachmentGroupID?: number | null;
    attachmentID?: number | null;
    downloadMode?: boolean;
  }) {
    // Reset all cellsErrors
    this.mainStore.recordVersions.setCellsErrors([]);

    interface FetchParams {
      attachment_group_id?: number;
      attachment_id?: number;
    }

    const params: FetchParams = {};
    if (attachmentGroupID) {
      params.attachment_group_id = attachmentGroupID;
    }
    if (attachmentID) {
      params.attachment_id = attachmentID;
    }

    try {
      if (!downloadMode) {
        this.cleanup();
      }

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/${fieldName}/files/aws_url`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      const {
        attachment_group_id,
        attachment_groups,
        attachment_id,
        byte_size,
        content_type,
        file_name,
        preview,
        processed,
        url,
      } = response.data;

      this.mainStore.attachmentGroups.setList(attachment_groups);
      this.setAttachmentGroupID(attachment_group_id);
      this.setAttachmentID(attachment_id);
      this.setPreview(preview);
      this.setProcessed(processed);

      if (!downloadMode) {
        this.setContentType(content_type);
        this.setFileName(file_name);
        this.setFileByteSize(byte_size);
        return this.setURL(url);
      }

      const downloadResponse = await legacyApi({
        method: "GET",
        url,
        responseType: "blob",
      });

      const blob = downloadResponse.data;
      downloadFile(blob, file_name);
    } catch (error) {
      window.console.log(`"Files#fetchFile" error ${error}`);
    }
  }

  // DELETE /api/react/record_versions/:record_version_id/:field_name/files/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async deleteSingleFile(recordVersionID, fieldName, fileID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/record_versions/${recordVersionID}/${fieldName}/files/${fileID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Files#deleteSingleFile" error ${error}`);
    }
  }

  // DELETE /api/react/record_versions/:record_version_id/:field_name/files
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async deleteAllFiles(recordVersionID, fieldName) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/record_versions/${recordVersionID}/${fieldName}/files`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Files#deleteAllFiles" error ${error}`);
    }
  }

  // POST /api/react/record_versions/:record_version_id/:field_name/files/resync
  async resync(
    recordVersionID: number,
    fieldName: string,
    fileType: IntegrationAttachmentTypes,
    attachmentID?: number | null,
  ) {
    try {
      const data = {
        file_type: fileType,
        ...(attachmentID && { attachment_id: attachmentID }),
      };
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/${fieldName}/files/resync`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        const integrationName = getIntegrationName(fileType);

        this.mainStore.toast.setErrorText(
          `Unable to resync this file. It's likely your ${integrationName} account isn't connected or you are not the file owner.`,
        );
        return false;
      }

      this.mainStore.toast.setText(
        "The resync request is being processed. It might take a few minutes for the changes to take effect.",
      );
      return true;
    } catch (error) {
      window.console.log(`"Files#resync" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  async googleDriveUploader(value) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/integrations/google/actions/check_file_permissions?file_url=${value}`,
        headers: this.mainStore.getHeaders(),
      });

      return response;
    } catch (error) {
      window.console.log(`"Files#googleDriveUploader" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  extension(url) {
    const extensionLogo = new Image();
    extensionLogo.src = url;

    return new Promise((resolve) => {
      extensionLogo.onload = () => resolve(true);
      extensionLogo.onerror = () => resolve(false);
    });
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAttachmentGroupID(value) {
    this.attachmentGroupID = value || null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAttachmentID(value) {
    this.attachmentID = value || null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setContentType(value) {
    this.contentType = value || null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setFileByteSize(value) {
    this.fileByteSize = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setFileName(value) {
    this.fileName = value || null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPreview(value) {
    this.preview = value || null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setProcessed(value) {
    this.processed = value || null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setURL(value) {
    this.url = value || null;
  }

  // Store Helpers

  cleanup() {
    this.setContentType(null);
    this.setAttachmentGroupID(null);
    this.setFileName(null);
    this.setURL(null);
  }
}

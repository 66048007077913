import React, { useState } from "react";
import Popup from "reactjs-popup";

import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog";

interface PermissionPopupProps {
  trigger: JSX.Element;
  disabled: boolean;
}

function PermissionPopup({ trigger, disabled }: PermissionPopupProps) {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <Popup
      trigger={trigger}
      position="bottom right"
      arrow={false}
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      disabled={disabled}
      offsetY={9}
    >
      <div className="table-dropdown success-dropdown back-btn-confirm-popup">
        <ConfirmationDialog
          heading="You don't have access to this record"
          content="You do not have permission to view this record. Please contact your administrator to request access."
          hideActionButtons
        />
      </div>
    </Popup>
  );
}

export default PermissionPopup;

/* eslint-disable react/no-unstable-nested-components */
import type {
  NewValueParams,
  ValueGetterParams,
  ValueSetterParams,
} from "@ag-grid-community/core";
import type { SelectItem } from "@themis/ui";
import { Avatar, getInitials, TableComponent, useToast } from "@themis/ui";
import classNames from "classnames";
import React from "react";
import { generatePath } from "react-router-dom";

import type { MonitoringGroupAsset } from "@/api";
import { useCompanyUsers } from "@/api/queries/users";
import {
  useMonitoringGroupAssetUpdate,
  useMonitoringGroups,
} from "@/api/queries/websiteMonitoring";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import LoadingWrapper from "@/components/loading-wrapper";
import { useMainStore } from "@/contexts/Store";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";

import { WEBSITE_MONITORING_ROUTES } from ".";
import MonitoringGroupSection from "../components/MonitoringGroupSection";
import { StatusTag } from "../components/StatusTag";
import { ViewButton } from "../components/ViewButton";

function WebsiteMonitoringContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div
      className={classNames(
        "tw-flex tw-h-full tw-min-w-96 tw-flex-col tw-gap-4",
      )}
    >
      {children}
    </div>
  );
}

export function MonitoringGroups() {
  const mainStore = useMainStore();
  const { companyID } = mainStore.context;
  const { isLoading, data, error } = useMonitoringGroups(Number(companyID));
  const { data: users, isError: isUsersError } = useCompanyUsers(
    Number(companyID),
  );
  const toast = useToast();

  const { mutateAsync: updateResult } = useMonitoringGroupAssetUpdate({
    companyId: Number(companyID),
    onSuccess: () => {
      toast({ content: "Asset saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update asset.",
        variant: "error",
      });
    },
  });

  const userOptions =
    users?.data.map((user) => ({
      label: user.full_name || "No Name",
      value: String(user.id),
      Component: () => (
        <div className="tw-flex tw-items-center tw-gap-1">
          <Avatar size="md" colorIndex={user.icon_color_index}>
            {user.initials}
          </Avatar>
          <span>{user.full_name}</span>
        </div>
      ),
    })) || [];

  const MONITORING_GROUP_ASSET_COLUMNS_TABLE = [
    {
      headerName: "",
      width: 30,
      rowDrag: true,
      resizable: false,
    },
    {
      headerName: "Asset / Job Name",
      field: "asset_name",
      cellRenderer: "identifierCellRenderer",
      cellRendererParams: (props: { data: MonitoringGroupAsset }) => {
        return {
          onClick: () => {
            const monitoringGroupAssetId = props.data.id;

            const link = generatePath(
              `monitoring_groups${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildren}`,
              {
                monitoringGroupAssetId,
              },
            );
            window.location.replace(link);
          },
          ...props,
        };
      },
      editable: false,
      minWidth: 280,
      flex: 2,
    },
    {
      headerName: "Asset Owner",
      minWidth: 180,
      flex: 2,
      cellEditor: TableComponent.selectCell,
      valueGetter: (params: ValueGetterParams<MonitoringGroupAsset>) =>
        String(params.data?.assignee_id) || [],
      valueSetter: (params: ValueSetterParams<MonitoringGroupAsset>) => {
        params.data.assignee_id = params.newValue;
        return true;
      },
      cellRenderer: TableComponent.selectCell,
      editable: true,
      onCellValueChanged: async (
        params: NewValueParams<MonitoringGroupAsset, number>,
      ) => {
        try {
          const monitoringGroupAssetId = params.data.id;
          await updateResult({
            monitoringGroupAssetId,
            data: { data: { assignee_id: params.data.assignee_id || null } },
          });
        } catch {
          toast({
            content: "Something went wrong.",
            variant: "error",
          });
        }
      },
      cellRendererParams: (props: { data: MonitoringGroupAsset }) => {
        return {
          items: userOptions,
          selected: props.data.assignee_id,
          renderSelected: ({
            label,
            value,
            Component,
          }: Partial<SelectItem>) => {
            return (
              <div className="tw-mr-1" key={value}>
                {Component &&
                  Component({
                    label: getInitials(label || ""),
                    value: value || "",
                  })}
              </div>
            );
          },
          ...props,
        };
      },
      cellEditorParams: (props: { data: MonitoringGroupAsset }) => {
        return {
          items: userOptions,
          defaultOpen: true,
          renderSelected: ({
            label,
            value,
            Component,
          }: Partial<SelectItem>) => {
            return (
              <div className="tw-mr-1" key={value}>
                {Component &&
                  Component({
                    label: getInitials(label || ""),
                    value: value || "",
                  })}
              </div>
            );
          },
          ...props,
        };
      },
    },
    {
      headerName: "URL",
      field: "data",
      editable: false,
      minWidth: 320,
      cellRenderer: "linkRenderCell",
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      tooltipValueGetter: (params: any) => {
        return (
          <div className="tw-whitespace-wrap tw-max-w-[400px] tw-overflow-hidden">
            {params.value}
          </div>
        );
      },
    },
    {
      headerName: "Status (Generated)",
      cellRenderer: (props: { data: MonitoringGroupAsset }) => {
        return (
          <div className="tw-pl-2">
            <StatusTag status={props.data.asset_status || ""} />
          </div>
        );
      },
      editable: false,
      minWidth: 170,
      flex: 3,
    },
    {
      headerName: "Asset Type",
      cellRenderer: (props: { data: MonitoringGroupAsset }) => {
        return (
          <div className="tw-pl-2">
            <StatusTag status={props.data.asset_type || ""} />
          </div>
        );
      },
      editable: false,
      minWidth: 150,
      flex: 2,
    },
    {
      headerName: "Latest Update",
      cellRenderer: "datePickerCell",
      field: "monitoring_group_asset_updated_at",
      minWidth: 150,
    },
    {
      headerName: "Total Findings",
      field: "asset_children_results_count",
      cellRenderer: "textRenderCell",
      type: "integer",
      editable: false,
      minWidth: 140,
      flex: 2,
    },
    {
      headerName: "Unresolved Findings",
      field: "asset_children_unresolved_results_count",
      cellRenderer: "textRenderCell",
      type: "integer",
      editable: false,
      minWidth: 180,
      flex: 2,
    },
    {
      headerName: "Created Date",
      cellRenderer: "datePickerCell",
      field: "monitoring_group_asset_created_at",
      minWidth: 150,
    },
    {
      cellRenderer: (props: { data: MonitoringGroupAsset }) => {
        return (
          <div className="tw-flex tw-w-[90px] tw-items-center tw-justify-center">
            <ViewButton monitoringGroupAssetId={props.data.id} />
          </div>
        );
      },
      minWidth: 90,
      flex: 2,
      type: "boolean",
      pinned: "right",
    },
  ];

  return (
    <PageLayout>
      <Header
        title={
          <>
            Website Monitoring
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === "website_monitoring",
                )?.id
              }
            />
          </>
        }
      />
      <PageContent>
        <LoadingWrapper
          loadingLayout="table"
          loading={isLoading || isUsersError}
          errorText={
            error ? "There was an issue loading the monitoring group data." : ""
          }
        >
          <WebsiteMonitoringContainer>
            {data?.data?.map((monitoring_group) => {
              return (
                <MonitoringGroupSection
                  isDropdownOpen
                  key={monitoring_group.id}
                  monitoringGroup={monitoring_group}
                  rows={monitoring_group?.assets || []}
                  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
                  columns={MONITORING_GROUP_ASSET_COLUMNS_TABLE}
                />
              );
            })}
          </WebsiteMonitoringContainer>
        </LoadingWrapper>
      </PageContent>
    </PageLayout>
  );
}

import React from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import type { SendFindingSearchParams } from "@/features/findings/components/FindingsList";
import { FindingsList } from "@/features/findings/components/FindingsList";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useSearchParams } from "@/hooks/useSearchParams";

import { FINDING_ROUTES } from ".";
import { SendToIssueManagementSlideOut } from "../components/SendToIssueManagementSlideOut";

function FindingsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-4">{children}</div>
  );
}

export function Findings({ recordId }: { recordId: number | undefined }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const [{ send_finding }] = useSearchParams<SendFindingSearchParams>();

  return (
    <PageLayout>
      <AddRecordHeader
        recordName="finding"
        addRecord={() =>
          history.push(
            generatePath(`${url}${FINDING_ROUTES.details}`, {
              findingId: "new",
            }),
          )
        }
      />
      <PageContent>
        <FindingsContainer>
          <FindingsList recordId={recordId} />
          {send_finding && recordId && (
            <SendToIssueManagementSlideOut recordId={recordId} />
          )}
        </FindingsContainer>
      </PageContent>
    </PageLayout>
  );
}

import classNames from "classnames";
import { kebabCase } from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useMemo, useState } from "react";
import Popup from "reactjs-popup";

import { Toggle } from "@/components/Elements";
import arrowDownIcon from "@/images/table-image/icon/arrow-down-black.svg";
import arrowDownRedIcon from "@/images/table-image/icon/arrow-down-red.svg";
import checkIcon from "@/images/table-image/icon/check-icon.svg";
import closeIcon from "@/images/table-image/icon/close-icon2.svg";

interface Props {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any[];
  placeholder?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selected?: any[];
  unit?: string;
}

function MultiDropdown({
  options,
  selected,
  unit,
  disabled,
  placeholder,
  error,
  onChange,
}: Props) {
  // States
  const [showPopup, setShowPopup] = useState(false);

  // Hooks
  const caption = useMemo(() => {
    if (error) {
      return error;
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (selected.length === 0) {
      return placeholder;
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (selected.length === options.length) {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (options.length > 1) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return `All ${pluralize(unit)} available`;
      }

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      return options[0].name;
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return `${pluralize(unit, selected.length, true)} available`;
  }, [error, selected, unit, placeholder]);

  const isAllSelected = useMemo(
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    () => selected.length === options.length,
    [selected, options],
  );

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const handleChange = (option) => {
    if (option.disabled) {
      return;
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const remove = Boolean(selected.find((elem) => elem.id === option.id));

    if (remove) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        selected.filter((elem) => elem.id !== option.id),
        false,
      );
    } else {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange([...selected, option], false);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'isAll' implicitly has an 'any' type.
  const handleSelectAllChange = (isAll) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onChange(isAll ? options : [], true);
  };

  const isOptionSelected = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
    (option) => Boolean(selected.find((elem) => elem.id === option.id)),
    [selected],
  );

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const canShowPopup = disabled || options.length === 0;

  return (
    <>
      <Popup
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => (
          <div
            className={classNames("multi-dropdown", {
              disabled: canShowPopup,
              error,
            })}
            data-testid="multi-dropdown"
          >
            <div
              className={classNames("multi-dropdown-trigger", {
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                placeholder: disabled || (!error && selected.length === 0),
                error: !disabled && Boolean(error),
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                "has-selection": !disabled && !error && selected.length > 0,
              })}
              data-testid="multi-dropdown-trigger"
            >
              {caption}
            </div>

            <img
              className="multi-dropdown-caret"
              src={error ? arrowDownRedIcon : arrowDownIcon}
            />
          </div>
        )}
        position="bottom left"
        open={showPopup}
        arrow={false}
        disabled={canShowPopup}
        keepTooltipInside
        onOpen={() => setShowPopup(true)}
        onClose={() => setShowPopup(false)}
      >
        <div
          className="multi-dropdown-popup"
          data-testid="multi-dropdown-popup"
        >
          <div className="multi-dropdown-select-all">
            {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
            <span>All {pluralize(unit)}</span>
            <Toggle
              data-testid="toggle"
              active={isAllSelected}
              onChange={handleSelectAllChange}
            />
          </div>
          {!isAllSelected && (
            <div className="multi-dropdown-options">
              {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
              {options.map((option) => (
                <div
                  key={option.id}
                  className={classNames("multi-dropdown-option", {
                    "multi-dropdown-option--disabled": option.disabled,
                  })}
                  data-testid={`multi-dropdown-option-${kebabCase(
                    option.name,
                  )}`}
                  onClick={() => handleChange(option)}
                >
                  <div
                    className={classNames("multi-dropdown-option-checkbox", {
                      checked: isOptionSelected(option),
                    })}
                  >
                    <img src={checkIcon} />
                  </div>
                  <div className="multi-dropdown-option-label">
                    {option.icon && <img src={option.icon} />} {option.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Popup>
      {!showPopup &&
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        selected.length > 0 &&
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        selected.length !== options.length && (
          <div className="multi-dropdown-selected-options">
            <div className="multi-dropdown-selected-options-caption">
              {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
              {pluralize(unit)} Included
            </div>
            {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
            {selected.map((option) => (
              <div key={option.id} className="multi-dropdown-selected-option">
                <div
                  className={classNames(
                    "multi-dropdown-selected-option-label",
                    { disabled },
                  )}
                >
                  {option.icon && <img src={option.icon} />} {option.name}
                </div>
                {!disabled && !option.disabled && (
                  <div
                    className="multi-dropdown-selected-option-deselect"
                    data-testid={`multi-dropdown-selected-option-deselect-${kebabCase(
                      option.name,
                    )}`}
                    onClick={() => handleChange(option)}
                  >
                    <img src={closeIcon} />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
    </>
  );
}

MultiDropdown.defaultProps = {
  options: [],
  selected: [],
  unit: "option",
  error: "",
  onChange: () => {},
};

export default MultiDropdown;

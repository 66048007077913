import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ShieldSafe = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.594 2.08736C11.8155 1.98891 12.064 1.97485 12.2932 2.04517L12.406 2.08736L21.406 6.08614C21.768 6.24697 21.9974 6.60402 22 6.99296L21.9939 7.11043L21.4215 12.2621C21.1289 14.8953 19.6919 17.2604 17.5025 18.7341L17.2465 18.9002L12.53 21.848C12.2418 22.0281 11.8846 22.0482 11.5811 21.908L11.47 21.848L6.75349 18.9002C4.50675 17.496 2.99738 15.1764 2.61734 12.5647L2.57852 12.2621L2.00612 7.11043C1.96238 6.71676 2.15493 6.33854 2.48945 6.14011L2.59397 6.08614L11.594 2.08736ZM12 4.09548L4.07469 7.61677L4.56629 12.0412C4.79233 14.0756 5.89661 15.9042 7.58032 17.0519L7.81349 17.2042L12 19.8208L16.1865 17.2042C17.9223 16.1193 19.0922 14.332 19.3975 12.3173L19.4337 12.0412L19.9253 7.61677L12 4.09548ZM14.2136 8.38222C14.5549 7.94795 15.1835 7.87251 15.6178 8.21372C16.0186 8.52869 16.1138 9.08857 15.8577 9.51441L15.7863 9.61786L11.7863 14.6178C11.4424 15.0555 10.815 15.1229 10.3877 14.7908L10.2929 14.7071L8.2929 12.7071C7.90237 12.3166 7.90237 11.6834 8.2929 11.2929C8.65338 10.9324 9.22061 10.9047 9.6129 11.2097L9.70711 11.2929L10.9101 12.4959L14.2136 8.38222Z"
      fill="#353549"
    />
  </svg>
);

ShieldSafe.displayName = "ShieldSafeIcon";

export default ShieldSafe;

import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import ControlMappingActions from "@/components/table/control-mapping/ControlMappingActions";
import { useMainStore } from "@/contexts/Store";

function ControlMappingActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const location = useLocation();

  // Variables
  const path = location.pathname;
  const controlMappingID = Number(path.substring(path.lastIndexOf("/") + 1));
  const recordVersionList = mainStore.recordVersions.list.find(
    (rv) => rv.id === controlMappingID,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;
  const approvedByUserIds =
    recordVersion?.reviews.map((approval) => approval.user_id) || [];
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = mainStore.controlMappings.data?.module_workspace_id;

  if (!recordVersion) {
    return null;
  }

  return (
    <ControlMappingActions
      moduleWorkspaceID={moduleWorkspaceID}
      recordVersion={recordVersion}
      tableName={recordVersion.table_name}
      approvedByUserIds={approvedByUserIds}
      showOnlyIcon={false}
    />
  );
}

export default observer(ControlMappingActionBar);

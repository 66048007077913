import "./attachment-overlay.scss";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import classNames from "classnames";
import React from "react";

import { IconButton } from "@/components/Elements";

interface Props {
  onClose: VoidFunction;
  fileUrl: string;
  requestHeaders?: Record<string, string>;
  preventDownload?: boolean;
}

export default function AttachmentOverlay({
  onClose,
  fileUrl,
  requestHeaders = {},
  preventDownload = false,
}: Props) {
  return (
    <div className="attachment-overlay">
      <IconButton
        className="attachment-overlay__close"
        icon="close"
        onClick={onClose}
      />
      <DocViewer
        className={classNames(preventDownload && "prevent-dowload")}
        documents={[{ uri: fileUrl }]}
        prefetchMethod="GET"
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
          },
          pdfVerticalScrollByDefault: true,
        }}
        requestHeaders={requestHeaders}
      />
    </div>
  );
}

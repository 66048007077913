import { startCase } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { useMainStore } from "@/contexts/Store";

import { Button } from "../Elements";
import { createDownload } from "../helpers/Files";

interface Props {
  filename: string;
}

function ExportUsers({ filename }: Props) {
  // Import MobX stores
  const {
    users,
    contacts,
    context: { companyID, workspaceID },
  } = useMainStore();

  const [isDisabled, setIsDisabled] = useState(false);

  async function download() {
    try {
      if (isDisabled) {
        return;
      }

      setIsDisabled(true);

      let data;
      if (filename === "users") {
        data = await users.exportWorkspaceUsers(workspaceID);
      } else if (filename === "contacts") {
        data = await contacts.export();
      } else if (filename === "allUsers") {
        data = await users.exportCompanyUsers(companyID);
      }
      createDownload(data, startCase(filename));
    } catch (error) {
      window.console.error(error);
    } finally {
      setIsDisabled(false);
    }
  }

  return (
    <Button
      label={`Export ${startCase(filename)}`}
      className="export-button export-users-button"
      onClick={download}
      disabled={isDisabled}
    />
  );
}

export default observer(ExportUsers);

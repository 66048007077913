import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import SlideMenu from "../../slideMenu/SlideMenu";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import SendRecordVersion from "../shared/SendRecordVersion";
import ViewLink from "../shared/ViewLink";

interface Props {
  moduleWorkspaceID: number;
  templateID: number;
}

function TemplateContextMenu({ templateID, moduleWorkspaceID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);

  const { isIW, workspaceID } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;

  function closeSlideMenu() {
    setShowSlideMenu(false);
  }

  function openSlideMenu() {
    setIsDropdownOpen(false);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
    setShowSlideMenu(true);
  }

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  async function deleteRow() {
    mainStore.vendors.deleteChecklist(moduleWorkspaceID, templateID);
    mainStore.toast.setText("Template deleted!");
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsDropdownOpen(true);
    setIsOpenModal(false);
    setIsDeleteConfirmationOpen(false);
  }

  async function onCopy(templateId: number, workspaceIds: number[]) {
    try {
      await mainStore.vendors.copyTemplate(
        moduleWorkspaceID,
        templateId,
        workspaceIds,
      );
    } catch {
      mainStore.toast.setErrorText("Something went wrong.");
    }
  }

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      disabled={!canDeleteRecords}
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="vdd-templates-popup-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div className="table-dropdown policy-dropdown">
      <ul>
        {isIW && (
          <li onClick={openSlideMenu} data-testid="vdd-templates-send-to-cw">
            Copy to Collaborative Workspace
          </li>
        )}
        <li onClick={deleteRowConfirmation} data-testid="vdd-templates-delete">
          Delete
        </li>
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Template"
      content="Actions are non-reversible. You will need to re-create a new template on your own."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );
  return (
    <div className="list-points">
      <ViewLink
        url={`/workspaces/${workspaceID}/modules/vendor-due-diligence/templates/${templateID}/update`}
        label="Edit"
      />
      <Popup
        position="bottom right"
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => renderTrigger}
        disabled={!canDeleteRecords}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
      <SlideMenu open={showSlideMenu} closeSlideMenu={closeSlideMenu}>
        <SendRecordVersion
          recordVersionID={templateID}
          recordName="Questoinnaire Template"
          sendFunction={onCopy}
        />
      </SlideMenu>
    </div>
  );
}

export default observer(TemplateContextMenu);

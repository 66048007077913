import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListTasksPathParams,
  ListTasksQueryParams,
  ListTasksQueryResponse,
} from "../../models/ListTasks";

/**
 * @description List tasks. Archived tasks are filtered out by default, but can be queried by adding a presence filter on archived_at.
 * @summary List tasks
 * @link /companies/:company_id/tasks
 */
export async function listTasks(
  companyId: ListTasksPathParams["company_id"],
  params?: ListTasksQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListTasksQueryResponse>["data"]> {
  const res = await client<ListTasksQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/tasks`,
    params,
    ...options,
  });
  return res.data;
}

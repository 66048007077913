import "./questionnaire-builder.scss";

import { DragDropContext, Droppable } from "@akojic27/react-beautiful-dnd";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  Switch,
} from "@themis/ui";
import classNames from "classnames";
import dayjs from "dayjs";
import { compact, uniq } from "lodash";
import { observer } from "mobx-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrop } from "react-dnd";
import { useForm } from "react-hook-form";
import { generatePath, useHistory } from "react-router-dom";
import { z } from "zod";

import { RiskAreaApi } from "@/api/legacy/risk-assessment/RiskAreaApi";
import { MiniTag, Toggle, Typography } from "@/components/Elements";
import type {
  DropdownOption,
  DropdownOptions,
} from "@/components/Elements/Dropdown/Dropdown";
import Dropdown from "@/components/Elements/Dropdown/Dropdown";
import {
  getQuestionIdFromDraggableId,
  getQuestionnaireGroupIdFromDroppableId,
  QUESTION_LIST_DROPPABLE_ID,
} from "@/components/helpers/draggable";
import { moveElement } from "@/components/helpers/reordering";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import { RESPONDER, REVIEWER, ROUTES } from "@/features/risk-assessment";
import { buildRiskRatingOptions } from "@/stores/helpers/RiskAssessmentHelpers";

import DynamicSizeInput from "../../../../../components/shared/DynamicSizeInput";
import type {
  Question,
  QuestionGroup,
  ResponderType,
  ReviewerType,
} from "../../../types/questionnaire";
import EmptyMessage from "../../EmptyMessage/EmptyMessage";
import UserAssignment from "../../UserAssignment";
import QuestionnaireBuilderPanel from "../QuestionnaireBuilderPanel/QuestionnaireBuilderPanel";
import QuestionnaireBuilderQuestion from "../QuestionnaireBuilderQuestion/QuestionnaireBuilderQuestion";
import QuestionnaireBuilderUserAssignment from "../QuestionnaireBuilderUserAssignment/QuestionnaireBuilderUserAssignment";
import QuestionnaireGroups from "../QuestionnaireGroups/QuestionnaireGroups";

const formSchema = z.object({
  due_date: z
    .string()
    .nullable()
    .refine((val) => {
      if (!val) {
        return true;
      }
      const date = dayjs(val);
      return !date.isBefore(new Date());
    }),
});

type DueDateSchema = z.infer<typeof formSchema>;

interface QuestionnaireBuilderProps {
  name: string;
  onNameChange: (name: string) => void;
  riskMethodologyId: number | null;
  onRiskMethodologyChange: (riskMethodologyId: number) => void;
  groups: QuestionGroup[];
  activeGroupId: number;
  setActiveGroupId: (id: number) => void;
  onQuestionAdd: (question: Question) => void;
  onQuestionChange: (question: Question) => void;
  onQuestionCopy: (question: Question) => void;
  onQuestionDelete: (question: Question) => void;
  onQuestionReorder: (questions: Question[]) => void;
  onQuestionGroupChange: (newGroupId: number, question: Question) => void;
  onGroupDelete: (groupId: number) => void;
  onGroupNameChange: (groupId: number, name: string) => void;
  onGroupAdd: (group: QuestionGroup) => void;
  setAssignedIDs: (
    ids: number[],
    assignmentType: ReviewerType | ResponderType,
  ) => void;
  isNew?: boolean;
  isInternal: boolean;
  isPasswordProtected?: boolean;
  setIsPublic?: (val: boolean) => void;
  setIsPasswordProtected?: (val: boolean) => void;
  dueDate: Date | null;
  setDueDate: (val: null | Date) => void;
}
function QuestionnaireBuilder(props: QuestionnaireBuilderProps) {
  const mainStore = useMainStore();
  const groupsRef = useRef<HTMLDivElement | null>(null);
  const form = useForm<DueDateSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      due_date: props.dueDate ? props.dueDate.toString() : null,
    },
  });

  const { workspaceID } = mainStore.context;
  const riskMethodologies = mainStore.riskMethodologies.list || [];

  const riskMethodologyOptions: DropdownOption[] = riskMethodologies.map(
    (methodology) => ({
      id: String(methodology.id),
      name: methodology.name,
    }),
  );
  const selectedRiskMethodology = riskMethodologies?.find(
    (rm) => rm.id === props.riskMethodologyId,
  );
  const riskRatingOptions: DropdownOption[] = useMemo(() => {
    if (!selectedRiskMethodology) {
      return [];
    }
    return buildRiskRatingOptions(
      selectedRiskMethodology.lower_range,
      selectedRiskMethodology.higher_range,
    );
  }, [props.riskMethodologyId, riskMethodologies]);
  const [riskAreaOptions, setRiskAreaOptions] =
    useState<Array<DropdownOption> | null>(null);
  const [collapse, setCollapse] = useState<{
    state: "allCollapsed" | "allExpanded" | "singleExpanded";
    expandedQuestionId?: number;
  }>({
    state: "allCollapsed",
  });

  const groupedRiskAreaOptions: DropdownOptions = useMemo(() => {
    if (!riskAreaOptions) {
      return [];
    }

    const riskAreaIds = props.groups.flatMap((group) =>
      group.questions.map((q) => q.risk_area_id),
    );
    const usedRiskAreaIds = compact(uniq(riskAreaIds));

    if (usedRiskAreaIds.length === 0) {
      return riskAreaOptions;
    }

    if (usedRiskAreaIds.length === riskAreaOptions.length) {
      return ["Recently Used", ...riskAreaOptions];
    }

    return [
      "Recently Used",
      ...riskAreaOptions.filter((option) =>
        usedRiskAreaIds.includes(Number(option.id)),
      ),
      "All",
      ...riskAreaOptions.filter(
        (option) => !usedRiskAreaIds.includes(Number(option.id)),
      ),
    ];
  }, [riskAreaOptions, props.groups]);
  const history = useHistory();
  const activeGroup =
    props.groups.find((group) => group.id === props.activeGroupId) ||
    props.groups[0];
  const reviewerUserIDs = activeGroup.assignments
    .filter((assignment) => !assignment._destroy)
    .map((assignment) => assignment.user_id);
  const responderUserIDs = activeGroup.responders
    .filter((assignment) => !assignment._destroy)
    .map((assignment) => assignment.user_id);
  const activeGroupQuestions = useMemo(() => {
    return activeGroup.questions.filter((question) => !question._destroy);
  }, [activeGroup.questions]);

  const [{ droppableActive }, dropMessage] = useDrop(() => ({
    accept: "question",
    drop: () => ({
      name: "question-list",
    }),
    collect: (monitor) => ({
      droppableActive: monitor.isOver() && monitor.canDrop(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: "question",
    drop: () => ({
      name: "question-list",
    }),
  }));

  useEffect(() => {
    if (!workspaceID) {
      return;
    }

    Promise.all([
      RiskAreaApi.getAll(workspaceID),
      mainStore.riskMethodologies.index(workspaceID),
      mainStore.users.indexForModuleIfNoUsersPresent(workspaceID),
    ])
      .then(([riskAreas]) => {
        setRiskAreaOptions(
          riskAreas.map((riskArea) => ({
            ...riskArea,
            id: String(riskArea.id),
          })),
        );
      })
      .catch(() => {
        mainStore.toast.setErrorText(
          "Unable to load required data. Please retry.",
        );
        history.replace(
          generatePath(ROUTES.BASE_PATH, { workspace_id: Number(workspaceID) }),
        );
      });
  }, []);

  const stickyTrigger = useCallback(
    (stickyDiv: HTMLDivElement) => {
      if (!stickyDiv || !groupsRef.current) {
        return;
      }

      const intObserver = new IntersectionObserver(([e]) => {
        if (!groupsRef.current) {
          return;
        }
        if (e.intersectionRatio < 1) {
          groupsRef.current.classList.add("sticky");
        } else {
          groupsRef.current.classList.remove("sticky");
        }
      });

      intObserver.observe(stickyDiv);

      return () => {
        intObserver.disconnect();
      };
    },
    [groupsRef],
  );

  const onGroupDelete = (groupId: number) => {
    if (groupId === props.activeGroupId) {
      const firstNonactiveGroup = props.groups.find(
        (group) => group.id !== props.activeGroupId,
      );
      props.setActiveGroupId(firstNonactiveGroup?.id || 0);
    }

    props.onGroupDelete(groupId);
  };

  const onQuestionCollapseChange = (
    questionId: number,
    isCollapsed: boolean,
  ) => {
    setCollapse(
      isCollapsed
        ? {
            state: "allCollapsed",
          }
        : {
            state: "singleExpanded",
            expandedQuestionId: questionId,
          },
    );
  };

  const handleQuestionAdded = (question: Question) => {
    props.onQuestionAdd(question);
    if (collapse.state === "allExpanded") {
      return;
    }

    setCollapse({
      state: "singleExpanded",
      expandedQuestionId: question.id,
    });
  };

  if (!riskAreaOptions) {
    return <Loading loadingLayout="small-table" />;
  }

  return (
    <div className="questionnaire-builder">
      <QuestionnaireBuilderPanel onAddQuestion={handleQuestionAdded} />
      <div className="questionnaire-builder__content">
        <DynamicSizeInput
          value={props.name}
          onChange={props.onNameChange}
          placeholder="Enter Questionnaire Name"
          defaultWidth={400}
        />
        <div className="tw-flex tw-gap-3">
          <div className="questionnaire-builder__risk-methodology tw-w-[200px]">
            {props.groups.some((group) => !group.questions.length) ? (
              <Dropdown
                className="questionnaire-builder__risk-methodology-dropdown"
                options={riskMethodologyOptions}
                onChange={(selected) =>
                  props.onRiskMethodologyChange(Number(selected.id))
                }
                selected={String(props.riskMethodologyId)}
                placeholder="- Select Risk Methodology -"
              />
            ) : (
              <div
                data-tooltip-id="tooltip"
                data-tooltip-content="Selected risk methodology cannot be edited after questions have been added"
                data-tooltip-place="bottom"
              >
                <MiniTag
                  label={
                    riskMethodologies.find(
                      (methodology) =>
                        methodology.id === props.riskMethodologyId,
                    )?.name
                  }
                  theme="blue"
                />
              </div>
            )}
          </div>
          <div className="questionnaire-builder__risk-methodology tw-w-[200px]">
            <Form {...form}>
              <FormField
                control={form.control}
                name="due_date"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <DatePicker
                        size="lg"
                        placeholder="– Due Date –"
                        calendarProps={{
                          mode: "single",
                          selected: field.value
                            ? dayjs(field.value).toDate()
                            : undefined,
                          onSelect: (date) => {
                            props.setDueDate?.(date || null);

                            if (date) {
                              field.onChange(date.toISOString() || null);
                            }
                            form.trigger();
                          },
                        }}
                        showHeader
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Form>
          </div>
        </div>
        {props.isNew && (
          <div className="creation-public-internal-selection">
            <Switch
              size="md"
              labelType="text"
              checked={!props.isInternal}
              onCheckedChange={(checked) =>
                props.setIsPublic && props.setIsPublic(checked)
              }
              left={{ label: "Sharing externally" }}
            />
            {props.setIsPasswordProtected && (
              <>
                <div className="tw-h-6 tw-w-[1px] tw-bg-generals-underline" />
                <Switch
                  size="md"
                  labelType="text"
                  checked={props.isPasswordProtected}
                  onCheckedChange={(checked) =>
                    props.setIsPasswordProtected &&
                    props.setIsPasswordProtected(checked)
                  }
                  left={{ label: "Password protected" }}
                />
              </>
            )}
          </div>
        )}
        {props.riskMethodologyId && (
          <DragDropContext
            onDragEnd={({ source, destination, draggableId }) => {
              if (!source || !destination) {
                return;
              }

              if (destination.droppableId === QUESTION_LIST_DROPPABLE_ID) {
                props.onQuestionReorder(
                  moveElement(
                    activeGroupQuestions,
                    source.index - 1,
                    destination.index - 1,
                  ),
                );
              } else {
                const groupId = getQuestionnaireGroupIdFromDroppableId(
                  destination.droppableId,
                );
                const questionId = getQuestionIdFromDraggableId(draggableId);
                if (groupId !== props.activeGroupId) {
                  props.onQuestionGroupChange(
                    groupId,
                    activeGroupQuestions.find((q) => q.id === questionId)!,
                  );
                }
              }
            }}
          >
            <div className="questionnaire-builder__groups-and-questions">
              <div
                className="questionnaire-builder__groups"
                data-testid="questionnaire-builder-groups"
              >
                <div ref={stickyTrigger} />
                <div ref={groupsRef}>
                  <QuestionnaireGroups
                    groups={props.groups}
                    activeGroupId={props.activeGroupId}
                    onActiveGroupChanged={props.setActiveGroupId}
                    canEdit
                    onDelete={onGroupDelete}
                    onNameChange={props.onGroupNameChange}
                    onGroupAdded={props.onGroupAdd}
                  />
                </div>
              </div>
              <div
                className="questionnaire-builder__questions-wrapper"
                data-testid="questionnaire-builder-questions"
              >
                <div className="questionnaire-builder__questions-header">
                  <Typography
                    label={activeGroup.name}
                    color="generalMidnightDark"
                    weight="semiBold"
                  />
                  <div className="questionnaire-builder__expand-all">
                    {props.isInternal && (
                      <UserAssignment
                        assigneeWord="Respondents"
                        tooltip={
                          props.isNew &&
                          "This will only appear in internal questionnaires"
                        }
                        assignedUsers={mainStore.users.allUsers.filter((user) =>
                          responderUserIDs.includes(user.id),
                        )}
                      >
                        <QuestionnaireBuilderUserAssignment
                          selectedIDs={responderUserIDs || []}
                          setSelectedIDs={(ids: number[]) =>
                            props.setAssignedIDs(ids, RESPONDER)
                          }
                        />
                      </UserAssignment>
                    )}
                    <UserAssignment
                      assignedUsers={mainStore.users.allUsers.filter((user) =>
                        reviewerUserIDs.includes(user.id),
                      )}
                    >
                      <QuestionnaireBuilderUserAssignment
                        selectedIDs={reviewerUserIDs || []}
                        setSelectedIDs={(ids: number[]) =>
                          props.setAssignedIDs(ids, REVIEWER)
                        }
                      />
                    </UserAssignment>
                    <div className="vertical-line" />
                    <Toggle
                      active={collapse.state !== "allExpanded"}
                      onLabel="Collapse"
                      offLabel="Expand"
                      onChange={(isCollapsed) =>
                        setCollapse(
                          isCollapsed
                            ? { state: "allCollapsed" }
                            : { state: "allExpanded" },
                        )
                      }
                    />
                  </div>
                </div>
                {activeGroupQuestions.length > 0 && (
                  <Droppable droppableId={QUESTION_LIST_DROPPABLE_ID}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <div
                          className="questionnaire-builder__questions"
                          ref={drop}
                        >
                          {activeGroupQuestions.map((question, i) => (
                            <QuestionnaireBuilderQuestion
                              isNew={!!props.isNew}
                              isInternal={!!props.isInternal}
                              key={question.id}
                              question={question}
                              sectionReviewerIDs={reviewerUserIDs}
                              sectionResponderIDs={responderUserIDs}
                              riskAreaOptions={groupedRiskAreaOptions}
                              riskRatingOptions={riskRatingOptions}
                              order={String(i + 1)}
                              onChange={(newValue) =>
                                props.onQuestionChange(newValue)
                              }
                              onCopy={() => props.onQuestionCopy(question)}
                              onDelete={() => props.onQuestionDelete(question)}
                              isCollapsed={
                                collapse.state === "allCollapsed" ||
                                (collapse.state === "singleExpanded" &&
                                  collapse.expandedQuestionId !== question.id)
                              }
                              onCollapseChange={onQuestionCollapseChange}
                              isWeightingEnabled={Boolean(
                                selectedRiskMethodology?.weighting_enabled,
                              )}
                              isScoringEnabled={Boolean(
                                selectedRiskMethodology?.inherent_risk_ratings
                                  .length,
                              )}
                            />
                          ))}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                )}
                <div
                  className={classNames(
                    "questionnaire-builder__empty-message",
                    droppableActive && "droppable-active",
                  )}
                  ref={dropMessage}
                >
                  <EmptyMessage>
                    Start building here by dragging questions from the left
                  </EmptyMessage>
                </div>
              </div>
            </div>
          </DragDropContext>
        )}
      </div>
    </div>
  );
}

export default observer(QuestionnaireBuilder);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetDashboardsPathParams,
  GetDashboardsQueryParams,
  GetDashboardsQueryResponse,
} from "../../models/GetDashboards";

/**
 * @description Get dashboards
 * @summary Get dashboards
 * @link /workspaces/:workspace_id/dashboards
 */
export async function getDashboards(
  workspaceId: GetDashboardsPathParams["workspace_id"],
  params: GetDashboardsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetDashboardsQueryResponse>["data"]> {
  const res = await client<GetDashboardsQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/dashboards`,
    params,
    ...options,
  });
  return res.data;
}

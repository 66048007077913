import { useQuery } from "@tanstack/react-query";

import type { WorkspacesSearchParams } from "@/features/home/components/ManageWorkspacesModal";
import { isQuarter } from "@/features/home/help/utils";
import type { DashboardTimeFrameParams } from "@/features/home/hooks/use-dashboard-time-frame-params";
import { CUSTOM_TIME_FRAME } from "@/features/home/pages/CompanyRetro";
import { getSelectedWorkspaces } from "@/features/home/utils";

import type { GetDashboardsQueryParamsSystemName } from "../gen";
import { getDashboards } from "../gen/axios/dashboardsController";

export const DASHBOARD_COMPONENTS_QUERY_KEY = "dashboard_components";

export function useDashboardComponents({
  workspaceId,
  systemName,
  ...params
}: {
  workspaceId: string;
  systemName: GetDashboardsQueryParamsSystemName;
} & WorkspacesSearchParams &
  DashboardTimeFrameParams) {
  const {
    days,
    start_date,
    end_date,
    quarter_start_date,
    quarter_end_date,
    workspace_ids,
  } = params;

  return useQuery({
    queryKey: [
      DASHBOARD_COMPONENTS_QUERY_KEY,
      workspaceId,
      systemName,
      { params },
    ],
    queryFn: () => {
      function getTimeRangeParams() {
        if (days === CUSTOM_TIME_FRAME) {
          return { start_date, end_date };
        }

        if (isQuarter(days || "")) {
          return { start_date: quarter_start_date, end_date: quarter_end_date };
        }

        if (!days) {
          return { days: 30 };
        }

        return { days: Number(days) };
      }

      return getDashboards(Number(workspaceId), {
        ...getTimeRangeParams(),

        filtered_workspace_ids: getSelectedWorkspaces({
          selectedWorkspaceIds: workspace_ids,
          currentWorkspaceId: Number(workspaceId),
        }),
        system_name: systemName,
      });
    },
    enabled: !!workspaceId && !!systemName,
  });
}

import classNames from "classnames";
import React, { useState } from "react";
import { Calendar } from "react-calendar";

import arrowLeft from "../../images/table-image/icon/arrow-left.svg";
import arrowRight from "../../images/table-image/icon/arrow-right.svg";

interface FilterCalendarProps {
  onDateChange: (date: Date) => void;
  isDateRange?: boolean;
  onContinue?: () => void;
}
const FilterCalendar = ({
  onDateChange,
  isDateRange,
  onContinue,
}: FilterCalendarProps) => {
  // State
  const [date, setDate] = useState<Date | [Date, Date]>(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
    isDateRange ? null : new Date(),
  );

  const [activeStartDate, setActiveStartDate] = useState(null);
  const [isDisabled, setIsDisabled] = useState(!!isDateRange);

  // @ts-expect-error TS(7006) FIXME: Parameter 'newDate' implicitly has an 'any' type.
  const onCalendarChange = (newDate) => {
    setDate(newDate);

    if (isDateRange) {
      setIsDisabled(false);
    }

    onDateChange(newDate);
  };

  const setToday = () => {
    const today = new Date();
    setDate(today);
    onDateChange(today);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
    setActiveStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
  };

  return (
    <div
      className="select-small-wrap calendar-wrap filter-date"
      data-testid="filter-date-select"
    >
      <Calendar
        // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Date | unde... Remove this comment to see the full error message
        activeStartDate={activeStartDate}
        onActiveStartDateChange={(value) =>
          // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
          setActiveStartDate(value.activeStartDate)
        }
        formatShortWeekday={(locale, weekday) =>
          ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][weekday.getDay()]
        }
        value={date}
        onChange={onCalendarChange}
        prevLabel={<img src={arrowLeft} alt="image" />}
        nextLabel={<img src={arrowRight} alt="image" />}
        selectRange={isDateRange}
        goToRangeStartOnSelect={false}
      />
      <button
        className={classNames("table-button set-today filter-date", {
          disabled: isDisabled,
        })}
        onClick={isDateRange ? () => onContinue?.() : setToday}
        disabled={isDisabled}
      >
        {isDateRange ? "Continue" : "Set Today"}
      </button>
    </div>
  );
};

export default FilterCalendar;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Cintagon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.59 9.17001L12.59 2.63001C12.4187 2.50486 12.2121 2.43741 12 2.43741C11.7879 2.43741 11.5812 2.50486 11.41 2.63001L2.40997 9.17001C2.2393 9.29469 2.11253 9.47024 2.04785 9.67147C1.98317 9.87269 1.98391 10.0892 2.04997 10.29L5.48997 20.87C5.55778 21.0789 5.69244 21.2596 5.87317 21.3843C6.0539 21.509 6.27065 21.5707 6.48997 21.56H17.56C17.7793 21.5707 17.996 21.509 18.1768 21.3843C18.3575 21.2596 18.4922 21.0789 18.56 20.87L22 10.29C22.061 10.084 22.0541 9.86391 21.9802 9.66219C21.9064 9.46047 21.7695 9.28789 21.59 9.17001ZM16.84 19.56H7.15997L4.15997 10.36L12 4.68001L19.82 10.36L16.84 19.56Z"
      fill="#353549"
    />
  </svg>
);

Cintagon.displayName = "CintagonIcon";

export default Cintagon;

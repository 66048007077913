import React from "react";

import { trimToLength } from "@/utils/utils";

interface Props {
  toLength: number;
  text: string;
  displayTooltip?: boolean;
  tooltipPosition?: "top" | "bottom";
}

export default function TruncateText({
  toLength,
  text,
  displayTooltip = true,
  tooltipPosition = "bottom",
}: Props) {
  const trimmedText = trimToLength(text, toLength);
  const tooltipProp =
    displayTooltip && trimmedText !== text
      ? {
          [tooltipPosition === "top" ? "data-tooltip-top" : "data-tooltip"]:
            text,
        }
      : {};

  return <div {...tooltipProp}>{trimmedText}</div>;
}

import { Droppable } from "@akojic27/react-beautiful-dnd";
import { orderBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { GroupedVirtuoso } from "react-virtuoso";

import type { RecordVersion } from "@/api";
import type { Section } from "@/stores/types/section-tags";

import { getDroppableIdFromSectionId } from "../../helpers/draggable";

interface Props {
  isSorted?: boolean;
  virtualized?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDragEnd?: (...args: any[]) => any;
  recordVersions?: RecordVersion[];
  renderer?: (rv: RecordVersion, index: number) => React.ReactNode;
  sectionTag?: Section;
  header?: React.ReactNode;
  onAddNewRow?: () => void;
  newRowName?: string;
  newRowOverride?: React.ReactNode;
}

function ReorderableTable({
  renderer,
  sectionTag,
  isSorted,
  virtualized,
  header,
  ...props
}: Props) {
  const [recordVersions, setRecordVersions] = useState<RecordVersion[]>([]);
  const droppableWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSorted) {
      setRecordVersions(props.recordVersions ?? []);
    } else {
      setRecordVersions(orderBy(props.recordVersions, "position", ["asc"]));
    }
  }, [isSorted, props.recordVersions]);

  /**
   * Creates the height used for the virtual table based on the number of records.
   * If less than 5: mulitply by 75 (row height) and add 100 for the header
   * If 5 or greater: multiply 75 (row height) by 5 (max rows displayed) and add 100 for the header
   * @returns {number} Used for height
   */
  const getVirtualTableHeight = () => {
    return recordVersions.length < 5
      ? recordVersions.length * 75 + 100
      : 75 * 5 + 100;
  };

  return (
    <div ref={droppableWrapperRef}>
      <Droppable
        droppableId={getDroppableIdFromSectionId(sectionTag?.id)}
        mode={virtualized ? "virtual" : "standard"}
        isDropDisabled={virtualized}
        {...(virtualized ? { renderClone: () => <div /> } : {})}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="droppable-wrap"
          >
            {virtualized ? (
              <div>
                {recordVersions.length > 0 ? (
                  <div className="virtualized-list">
                    <GroupedVirtuoso
                      style={{
                        height: getVirtualTableHeight(),
                      }}
                      groupCounts={[recordVersions.length]}
                      groupContent={() => header}
                      itemContent={(index: number) => {
                        return renderer?.(recordVersions[index], index);
                      }}
                    />
                  </div>
                ) : (
                  <div className="empty-table-header">{header}</div>
                )}
              </div>
            ) : (
              recordVersions.map((item, index) => renderer?.(item, index))
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {props.newRowOverride
        ? props.newRowOverride
        : props.onAddNewRow && (
            <div
              className="tw-sticky tw-left-[19px] tw-ml-[19px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
              onClick={props.onAddNewRow}
              data-testid="add-new-record-button"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">
                Add {props.newRowName ? props.newRowName : "New Record"}...
              </div>
            </div>
          )}
    </div>
  );
}

export default ReorderableTable;

import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

const Title = ({ name }: { name: string }) => {
  const mainStore = useMainStore();
  return (
    <div className="metrics-header">{`${name} by ${capitalize(
      mainStore.reports.groupFieldName,
    )}`}</div>
  );
};

export default observer(Title);

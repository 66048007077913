import { Button, useToast } from "@themis/ui";
import React from "react";

import { useMonitoringGroupChildResultsUpdate } from "@/api/queries/websiteMonitoring";

export function MarkAllStatus({
  monitoringGroupAssetId,
  monitoringGroupAssetChildId,
  status,
  resultCreatedAt,
  complianceHashGroupUid,
}: {
  monitoringGroupAssetId: number;
  monitoringGroupAssetChildId: number;
  status: string;
  resultCreatedAt: string;
  complianceHashGroupUid?: string;
}) {
  const toast = useToast();

  const { mutateAsync: updateResult } = useMonitoringGroupChildResultsUpdate({
    monitoringGroupAssetChildId: Number(monitoringGroupAssetChildId),
    monitoringGroupAssetId,
    onSuccess: () => {
      toast({ content: "Result saved successfully!", variant: "success" });
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not update result.",
        variant: "error",
      });
    },
  });

  async function handleSubmitForm() {
    const requestBody = {
      data: {
        monitoring_group_asset_child_result_created_at: resultCreatedAt,
        status,
        compliance_hash_group_uid: complianceHashGroupUid,
      },
    };
    await updateResult(requestBody);
  }

  return (
    <Button
      className="risk-rating-top-button gray-button"
      color="transparent"
      onClick={() => handleSubmitForm()}
    >
      Mark rest as Remediated
    </Button>
  );
}

import React from "react";

import type { DashboardComponent } from "@/api";
import chartEmptyState from "@/images/dashboard/chart_empty_state.svg";

import { formatPieChartData } from "../../utils";
import { PieChart } from "../charts/PieChart";

export default function PublishedMarketingByType({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const mutated = formatPieChartData({ widgetData });

  const valueSum = mutated.reduce((acc, curr) => acc + curr.value, 0);

  return valueSum === 0 ? (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Material Types
        </div>
      </div>
      <div>
        <div className="tw-h-[212px] tw-w-full">
          <img
            src={chartEmptyState}
            alt="close-icon"
            className="tw-h-[212px] tw-w-full"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Published Material Types
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div className="tw-relative tw-left-[-60px] tw-top-[80px] tw-z-[100] tw-inline-block tw-text-center tw-text-2xl tw-font-semibold tw-text-neutral-500">
              {valueSum}
            </div>
            <div className="tw-flex tw-flex-col">
              <PieChart data={mutated} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

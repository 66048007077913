import { useEffect, useRef } from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'func' implicitly has an 'any' type.
const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      func();
    } else {
      didMount.current = true;
    }
  }, deps);
};

export default useDidMountEffect;

import { Button } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import ExportPdf from "../settings/ExportPdf";
import ExportTable from "../settings/ExportTable";
import SectionSelection from "../shared/SectionSelection";
import ExportFiles from "./ExportFiles";
import SubExportComplaintButton from "./SubExportComplaintButton";

interface Props {
  isAttestation?: boolean;
  isScoringGroup?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onActiveStateChange?: (...args: any[]) => any;
  parentRecordVersionID?: number;
  specificStatuses?: string[];
  subModuleDownload?: boolean;
  isExportFilesEnabled?: boolean;
  customFileExportFunction?: () => void;
  customTableExportFunction?: () => void;
  customPDFExportFunction?: () => void;
  isExportSheetEnabled?: boolean;
  isPDFEnabled?: boolean;
  isRenderSectionSelectionPDF?: boolean;
  moduleIdentifier?: string;
  recordVersion?: number;
}

function ExportBulk({
  isAttestation,
  isScoringGroup,
  onActiveStateChange,
  parentRecordVersionID,
  specificStatuses,
  subModuleDownload,
  isExportFilesEnabled,
  customFileExportFunction,
  customTableExportFunction,
  customPDFExportFunction,
  isExportSheetEnabled = true,
  isPDFEnabled = false,
  isRenderSectionSelectionPDF = false,
  moduleIdentifier,
  recordVersion,
}: Props) {
  const mainStore = useMainStore();

  // Location Tracker
  const location = useLocation();

  const { moduleWorkspaceID } = mainStore.context;
  const sections = mainStore.sectionTags.list.filter(
    (sectionTag) => sectionTag.module_workspace_id === moduleWorkspaceID,
  );
  const attestationTable = location.pathname.endsWith("/attestation");

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [exportType, setExportType] = useState(""); // One of 'files' or 'sheet' or 'pdf'
  const [step, setStep] = useState(0);
  const [selectedSection, setSelectedSection] = useState<
    number | "unassigned" | null
  >(null);

  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
    setStep(0);
    setSelectedSection(null);
    setExportType("");
  }

  function onChooseSheet() {
    setExportType("sheet");
    setStep(2);
  }

  function onChooseFiles() {
    setExportType("files");
    setStep(2);
  }

  function onChoosePDF() {
    setExportType("pdf");
    if (isRenderSectionSelectionPDF && moduleIdentifier === "complaints") {
      setStep(3);
    } else {
      setStep(2);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'section_id' implicitly has an 'any' typ... Remove this comment to see the full error message
  function onSelectSection(section_id) {
    setSelectedSection(section_id);
  }
  function onContinue() {
    setStep(2);
  }

  const renderSectionSelectionPDF = (
    <SubExportComplaintButton recordVersion={recordVersion} />
  );

  const renderSectionSelection = (
    <SectionSelection
      title="Select a section to export your files"
      sections={sections}
      selectedSection={selectedSection}
      onSelectSection={onSelectSection}
      handleContinue={onContinue}
    />
  );

  const renderButtonTrigger = (
    <Button
      color="tertiary"
      LeftIcon={PiDownloadSimpleBold}
      data-testid="export-button"
    >
      Export
    </Button>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderButtonTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      closeOnDocumentClick={!(step === 2 && onActiveStateChange)}
      nested
    >
      {step === 0 && (
        <div className="select-small-wrap">
          <ul>
            {isExportSheetEnabled && (
              <li onClick={onChooseSheet}>Download Spreadsheet</li>
            )}
            {isExportFilesEnabled && !attestationTable && (
              <li onClick={onChooseFiles}>
                {isScoringGroup
                  ? "Download Group Records"
                  : "Download Bulk Files"}
              </li>
            )}
            {isPDFEnabled && <li onClick={onChoosePDF}>Download PDF</li>}
            {!isExportSheetEnabled &&
              !isExportFilesEnabled &&
              !isPDFEnabled && <li>No export options available</li>}
          </ul>
        </div>
      )}
      {step === 1 && renderSectionSelection}
      {step === 2 && (
        <div>
          {exportType === "files" && (
            <ExportFiles
              customFileExportFunction={customFileExportFunction}
              setShowPopup={setShowPopup}
            />
          )}
          {exportType === "sheet" && (
            <ExportTable
              setShowPopup={setShowPopup}
              parentRecordVersionID={parentRecordVersionID}
              subModuleDownload={subModuleDownload}
              onActiveStateChange={onActiveStateChange}
              specificStatuses={specificStatuses}
              isAttestation={isAttestation}
              customTableExportFunction={customTableExportFunction}
            />
          )}
          {exportType === "pdf" && (
            <ExportPdf
              setShowPopup={setShowPopup}
              parentRecordVersionID={parentRecordVersionID}
              subModuleDownload={subModuleDownload}
              onActiveStateChange={onActiveStateChange}
              specificStatuses={specificStatuses}
              isAttestation={isAttestation}
              customTableExportFunction={customPDFExportFunction}
            />
          )}
        </div>
      )}
      {step === 3 && renderSectionSelectionPDF}
    </Popup>
  );
}

ExportBulk.defaultProps = {
  isAttestation: false,
  parentRecordVersionID: -1,
  subModuleDownload: false,
};

export default observer(ExportBulk);

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useMainStore } from "@/contexts/Store";

import EmptyState from "../components/EmptyState";
import ScoringGroup from "../components/Scoring/ScoringGroup";
import AddNewGroupMenu from "../components/SlideMenu/AddNewGroupMenu";
import AddRegisterMenu from "../components/SlideMenu/AddRegisterMenu";
import {
  useAddRiskRegistersToRegisterGroup,
  useCreateRiskRegisterGroup,
  useDeleteRegisterFromGroup,
  useDeleteRiskRegisterGroup,
  useListRegisters,
  useListRiskRegisterGroups,
} from "../hooks";
import type { GroupScoringRegister } from "../types/GroupScoringRegister";
import type { RiskRegisterGroup } from "../types/RiskRegisterGroup";

function GroupScoringPage() {
  const mainStore = useMainStore();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const [addNewGroupMenuData, setAddNewGroupMenuData] = useState({
    open: false,
  });
  const [addRegisterMenuData, setAddRegisterMenuData] = useState<{
    open: boolean;
    groupId: number | null | undefined;
  }>({
    open: false,
    groupId: null,
  });

  const [registers, setRegisters] = useState<GroupScoringRegister[]>();
  const [groups, setGroups] = useState<RiskRegisterGroup[]>();

  const {
    isPending: isRegistersPending,
    error: registersError,
    data: listRegisters,
  } = useListRegisters(workspace_id);

  const {
    isPending: isGroupsPending,
    error: groupsError,
    data: riskRegisterGroups,
    refetch: refetchRegisterGroups,
  } = useListRiskRegisterGroups(workspace_id);

  const scoringGroupMutation = useCreateRiskRegisterGroup(workspace_id);
  const deleteGroupRegisterMutation = useDeleteRegisterFromGroup(workspace_id);
  const deleteGroupMutation = useDeleteRiskRegisterGroup(workspace_id);
  const addRegistersToGroupMutation =
    useAddRiskRegistersToRegisterGroup(workspace_id);

  useEffect(() => {
    if (registersError) {
      return mainStore.toast.setErrorFromResponse(registersError);
    }
    if (!isRegistersPending) {
      setRegisters(listRegisters);
    }
  }, [listRegisters, registersError]);

  useEffect(() => {
    if (groupsError) {
      return mainStore.toast.setErrorFromResponse(groupsError);
    }
    if (!isGroupsPending) {
      setGroups(riskRegisterGroups.map((rrg: RiskRegisterGroup) => rrg));
    }
  }, [riskRegisterGroups, groupsError]);

  const handleNewGroupSubmit = async (
    name: string,
    riskRegisterIds: number[],
  ) => {
    try {
      await scoringGroupMutation.mutateAsync({
        name,
        riskRegisterIds,
      });
      refetchRegisterGroups();
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
    setAddNewGroupMenuData({ open: false });
  };

  const handleRemoveRegisterFromGroup = async (
    groupId: number,
    registerId: number,
  ) => {
    await deleteGroupRegisterMutation.mutateAsync({ groupId, registerId });
    refetchRegisterGroups();
  };

  const handleDeleteGroup = async (groupId: number) => {
    await deleteGroupMutation.mutateAsync(groupId);
    refetchRegisterGroups();
  };

  const handleWeightsUpdated = () => {
    refetchRegisterGroups();
  };

  const handleAddRegistersToGroup = async (
    groupId: number,
    registerIds: number[],
  ) => {
    try {
      await addRegistersToGroupMutation.mutateAsync({ groupId, registerIds });
      refetchRegisterGroups();
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
  };

  return (
    <>
      <SlideMenu
        open={addNewGroupMenuData.open}
        closeSlideMenu={() => {
          setAddNewGroupMenuData({ open: false });
        }}
        singleSlideMenu
      >
        <AddNewGroupMenu
          onClose={() => setAddNewGroupMenuData({ open: false })}
          registers={registers}
          onSubmit={handleNewGroupSubmit}
        />
      </SlideMenu>
      <SlideMenu
        open={addRegisterMenuData.open}
        closeSlideMenu={() => {
          setAddRegisterMenuData((prev) => ({ ...prev, open: false }));
        }}
        singleSlideMenu
      >
        <AddRegisterMenu
          onClose={() =>
            setAddRegisterMenuData((prev) => ({ ...prev, open: false }))
          }
          registers={registers?.filter((r) => r.aggregate_scores !== null)}
          onSubmit={handleAddRegistersToGroup}
          groupId={addRegisterMenuData.groupId!}
          disabledIds={groups
            ?.find((group) => group.id === addRegisterMenuData.groupId)
            ?.risk_register_group_items.map((rrgi) => rrgi.section_tag_id)}
        />
      </SlideMenu>
      <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-8 tw-p-6">
        {registers &&
          groups?.map((group) => (
            <ScoringGroup
              key={`scoring-group-${group.id}`}
              group={group}
              groupRegisters={group.risk_register_group_items}
              registers={registers}
              onAddRegisters={() =>
                setAddRegisterMenuData({ open: true, groupId: group.id })
              }
              onRemoveRegister={handleRemoveRegisterFromGroup}
              onDelete={handleDeleteGroup}
              onWeightsUpdated={handleWeightsUpdated}
            />
          ))}

        <EmptyState onAddGroup={() => setAddNewGroupMenuData({ open: true })} />
      </div>
    </>
  );
}

export default observer(GroupScoringPage);

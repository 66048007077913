import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Link = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.47186 13.4214L6.31458 10.5786C6.70707 10.1861 7.34343 10.1861 7.73593 10.5786C8.10039 10.9431 8.12643 11.5178 7.81403 11.9123L7.73593 12L4.89322 14.8427C3.71573 16.0202 3.71573 17.9293 4.89322 19.1068C6.02866 20.2422 7.84437 20.2828 9.0284 19.2284L9.15729 19.1068L12 16.2641C12.3925 15.8716 13.0289 15.8716 13.4214 16.2641C13.7858 16.6285 13.8119 17.2033 13.4995 17.5978L13.4214 17.6854L10.5786 20.5281C8.61616 22.4906 5.43435 22.4906 3.47186 20.5281C1.56102 18.6173 1.51074 15.5505 3.32101 13.5788L3.47186 13.4214ZM13.4214 3.47186C15.3838 1.50938 18.5657 1.50938 20.5281 3.47186C22.4906 5.43435 22.4906 8.61616 20.5281 10.5786L17.6854 13.4214C17.2929 13.8139 16.6566 13.8139 16.2641 13.4214C15.8716 13.0289 15.8716 12.3925 16.2641 12L19.1068 9.15729C20.2843 7.9798 20.2843 6.07071 19.1068 4.89322C17.9293 3.71573 16.0202 3.71573 14.8427 4.89322L12 7.73593C11.6075 8.12843 10.9711 8.12843 10.5786 7.73593C10.1861 7.34343 10.1861 6.70707 10.5786 6.31458L13.4214 3.47186ZM13.4214 9.15729C13.8139 8.76479 14.4502 8.76479 14.8427 9.15729C15.2352 9.54978 15.2352 10.1861 14.8427 10.5786L10.5786 14.8427C10.1861 15.2352 9.54978 15.2352 9.15729 14.8427C8.76479 14.4502 8.76479 13.8139 9.15729 13.4214L13.4214 9.15729Z"
      fill="#353549"
    />
  </svg>
);

Link.displayName = "LinkIcon";

export default Link;

import "./styles.scss";

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController, useWatch } from "react-hook-form";
import Popup from "reactjs-popup";

import { Typography } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import checkIcon from "@/images/table-image/icon/check-icon.svg";
import checkBlueIcon from "@/images/table-image/icon/options-check-blue-light.svg";

import { optionsColorScheme } from "./constants";

interface MultiSelectFieldProps<T extends FieldValues>
  extends UseControllerProps<T> {
  placeholder: string;
  disabled?: boolean;
  /**
   * If passed will render tooltip on hover
   */
  tooltipText?: string;
  /**
   * Will always show placeholder. Selected values will only be seen in
   * dropdown
   */
  hideSelectedValues?: boolean;
  outlined?: boolean;
  options?: { value: string; label: string; colorSchemeId?: number }[];
}

function MultiSelectField<T extends FieldValues>({
  placeholder,
  options,
  hideSelectedValues,
  outlined,
  disabled,
  tooltipText,
  ...props
}: MultiSelectFieldProps<T>) {
  const { fieldOptions } = useMainStore();
  const watch = useWatch({ name: props.name, control: props.control });
  const { field } = useController(props);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getOptionStyling = (colorSchemeId: number, index: number) => {
    const colorScheme =
      fieldOptions.findColorScheme(colorSchemeId) ||
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      optionsColorScheme?.[`default${(index % 3) + 1}`];

    return {
      border: colorScheme.border,
      color: colorScheme.text_color,
      background: colorScheme.background_color,
      borderRadius: colorScheme.border_radius,
    };
  };

  const fieldValues = watch as string[];

  const handleChange = (changeValue: string, isSelected: boolean) => {
    field.onChange(
      isSelected
        ? fieldValues.filter((singleValue) => singleValue !== changeValue)
        : [...fieldValues, changeValue],
    );
  };

  return (
    <Popup
      keepTooltipInside
      disabled={disabled}
      open={dropdownOpen}
      onOpen={() => setDropdownOpen(true)}
      trigger={
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={tooltipText}
          data-tooltip-place="bottom"
          className={classNames({
            ["notification-select"]: !outlined,
            ["column column-select"]: outlined,
            ["notification-select--disabled"]: disabled,
          })}
        >
          <li
            className={classNames({
              ["cell column-options-cell outlined"]: outlined,
            })}
          >
            {(hideSelectedValues || !fieldValues.length) && (
              <Typography
                className={classNames({
                  ["notification-select__placeholder"]: !fieldValues.length,
                })}
                label={placeholder}
                size={outlined ? "xs" : "md"}
                color="extrasBlueGrayDarker"
              />
            )}
            {!hideSelectedValues && !!fieldValues?.length && (
              <div className={classNames("options", "column-options-item")}>
                {fieldValues.map((fieldValue, index) => {
                  // @ts-expect-error TS(2339) FIXME: Property 'colorSchemeId' does not exist on type '{... Remove this comment to see the full error message
                  const { colorSchemeId, label } = options.find(
                    (option) => option.value === fieldValue,
                  );

                  return (
                    <span
                      key={fieldValue}
                      className="value value-type"
                      style={getOptionStyling(colorSchemeId, index)}
                    >
                      {label}
                    </span>
                  );
                })}
              </div>
            )}
          </li>
        </div>
      }
    >
      <div className="select">
        <div className="select-dropdown">
          {options?.map((option, index) => {
            const isSelected = fieldValues?.includes(option.value);

            return (
              <li
                key={option.value}
                className={classNames("select-option", {
                  "select-option-active": isSelected,
                })}
                onClick={() => handleChange(option.value, isSelected)}
              >
                <div className="select-option-label-wrapper">
                  <span
                    className={classNames("select-option-checkbox", {
                      checked: isSelected,
                    })}
                  >
                    {isSelected && <img src={checkIcon} alt="check-icon" />}
                  </span>
                  <span
                    className="select-option-label"
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                    style={getOptionStyling(option.colorSchemeId, index)}
                  >
                    {option.label}
                  </span>
                </div>
                {isSelected && (
                  <span className="select-option-check-icon">
                    <img src={checkBlueIcon} alt="check-icon" />
                  </span>
                )}
              </li>
            );
          })}
        </div>
      </div>
    </Popup>
  );
}

export default observer(MultiSelectField);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ListBullet = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 18C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H7C6.44772 20 6 19.5523 6 19C6 18.4477 6.44772 18 7 18H21ZM21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H21ZM21 4C21.5523 4 22 4.44772 22 5C22 5.55228 21.5523 6 21 6H7C6.44772 6 6 5.55228 6 5C6 4.44772 6.44772 4 7 4H21ZM2 5C2 4.44772 2.44772 4 3 4C3.55228 4 4 4.44772 4 5C4 5.55228 3.55228 6 3 6C2.44772 6 2 5.55228 2 5ZM2 12C2 11.4477 2.44772 11 3 11C3.55228 11 4 11.4477 4 12C4 12.5523 3.55228 13 3 13C2.44772 13 2 12.5523 2 12ZM2 19C2 18.4477 2.44772 18 3 18C3.55228 18 4 18.4477 4 19C4 19.5523 3.55228 20 3 20C2.44772 20 2 19.5523 2 19Z"
      fill="#353549"
    />
  </svg>
);

ListBullet.displayName = "ListBulletIcon";

export default ListBullet;

import { observer } from "mobx-react";
import React from "react";

import { ProblemProductCodes } from "@/components/table/finra-complaints/finnra-complaints-cells";
import { useMainStore } from "@/contexts/Store";

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
  isProductCodes?: boolean;
}

function ViewProblemProductCodes({
  recordVersion,
  fieldName,
  recordVersionID,
  isProductCodes,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const value = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion.data,
  );
  const cellError = mainStore.recordVersions.errorsForField(
    recordVersion.id,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  return (
    <ProblemProductCodes
      fieldName={fieldName}
      recordVersionID={recordVersionID}
      // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
      isProductCodes={isProductCodes}
      width="100%"
      selectedCode={value}
      hasErrors={hasErrors}
      errorMessage={cellError?.description}
      hasErrorClass="detail-view-has-errors"
    />
  );
}

export default observer(ViewProblemProductCodes);

import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";

export function init() {
  const history = createBrowserHistory();
  const environment = (
    document.querySelector('meta[name="environment"]') as HTMLMetaElement | null
  )?.content;
  const release = (
    document.querySelector('meta[name="release"]') as HTMLMetaElement | null
  )?.content;

  Sentry.init({
    dsn: "https://82303514262747a5b795c01e110abcb2@o4505324405981184.ingest.sentry.io/4505324408864768",
    environment,
    release,
    enabled: environment !== "development" && environment !== "test",
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications.",
      "ResizeObserver loop limit exceeded",
    ],
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        enableInp: true,
      }),
    ],
    tracesSampleRate: 0.01,
  });
}

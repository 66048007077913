import React from "react";
import { Route, Switch } from "react-router-dom";

import PoliciesLibraryList from "../../../components/table/policy/PoliciesLibraryList";

function PoliciesLibrary() {
  return (
    <div className="policies-table-section" data-testid="policies-library">
      <Switch>
        <Route
          exact
          path="/workspaces/:workspace_id/modules/policy/policies_library"
        >
          <div className="table-list-wrap no-margin table-list-wrap-wider policy-table">
            <PoliciesLibraryList />
          </div>
        </Route>
      </Switch>
    </div>
  );
}

export default PoliciesLibrary;

import _ from "lodash";
import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import Popup from "reactjs-popup";

import { attachmentFileType } from "@/api";
import { buildUploadPayload } from "@/components/helpers/AttachmentGroupsHelper";
import { useMainStore } from "@/contexts/Store";

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion: any;
  recordVersionID: number;
}

function ViewCompanyWebsiteCell({
  fieldName,
  recordVersion,
  recordVersionID,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs
  const textInput = useRef(null);

  // Variables
  const initialValue = mainStore.avroSchemas.valueForField(
    fieldName,
    recordVersion?.data,
  );
  const { suggestions } = mainStore.clearbit;

  // State
  const [value, setValue] = useState(initialValue || "");
  const [logo, setLogo] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'enteredValue' implicitly has an 'any' t... Remove this comment to see the full error message
  function handleWebsiteChange(enteredValue) {
    mainStore.clearbit.fetchSuggestedLogos(enteredValue);
  }
  const debouncedHandleWebsiteChange = _.debounce(handleWebsiteChange, 150);

  // @ts-expect-error TS(7006) FIXME: Parameter 'suggestion' implicitly has an 'any' typ... Remove this comment to see the full error message
  function handleSelectSuggestion(suggestion) {
    mainStore.clearbit.fetchLogo(suggestion.domain);
    setValue(suggestion.domain);
    setLogo(suggestion.logo);
    setShowPopup(false);
  }

  function onClose() {
    // @ts-expect-error TS(2339) FIXME: Property 'blur' does not exist on type 'never'.
    textInput.current?.blur();
    mainStore.clearbit.setSuggestions([]);
    setShowPopup(false);
    persistData();
  }

  function persistData() {
    if (value === initialValue) {
      return;
    }

    mainStore.recordVersions.update({
      recordVersionID,
      fieldName,
      value: mainStore.avroSchemas.serializeValue(fieldName, value),
    });

    if (logo?.length > 0) {
      const logoFieldName = "company_logo";
      const fileType = attachmentFileType.url_upload;
      const signedID = null;
      const url = logo;
      const deleteExistingAttachments = true;

      const payload = buildUploadPayload(
        logoFieldName,
        fileType,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        signedID,
        url,
        deleteExistingAttachments,
      );
      if (recordVersionID && payload) {
        mainStore.attachmentGroups.create({ recordVersionID, payload });
      }
    }

    setLogo("");
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleKeyDown(event) {
    if (event.key === "Enter") {
      setShowPopup(false);
    }
  }

  return (
    <Popup
      position="bottom left"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => (
        <li onClick={() => setShowPopup(true)}>
          <input
            ref={textInput}
            type="text"
            autoComplete="off"
            name="website"
            placeholder="Type here"
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
              debouncedHandleWebsiteChange(event.target.value);
            }}
            onKeyDown={handleKeyDown}
            data-testid="input-cell"
          />
        </li>
      )}
      on="focus"
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={onClose}
    >
      {suggestions?.length > 0 && (
        <div
          className="vdd-logo-suggestions-popup"
          data-testid="clearbit-suggestions"
          onMouseDown={(event) => event.preventDefault()}
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          onClick={() => textInput.current.focus()}
        >
          {suggestions.map((suggestion, index) => (
            <div
              // @ts-expect-error TS(2339) FIXME: Property 'domail' does not exist on type 'never'.
              key={`${suggestion.domail}-${index}`}
              className="item"
              onClick={() => handleSelectSuggestion(suggestion)}
              data-testid="clearbit-suggestion-item"
            >
              {/* @ts-expect-error TS(2339) FIXME: Property 'logo' does not exist on type 'never'. */}
              <img src={suggestion.logo} alt="logo" />
              {/* @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'. */}
              <span>{suggestion.name}</span>
              {/* @ts-expect-error TS(2339) FIXME: Property 'domain' does not exist on type 'never'. */}
              <p>{suggestion.domain}</p>
            </div>
          ))}
        </div>
      )}
    </Popup>
  );
}

export default observer(ViewCompanyWebsiteCell);

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { Icon, IconButton, Typography } from "@/components/Elements";
import Checkbox from "@/components/table/shared/Checkbox";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog";
import SectionDropdownTitle from "@/components/table/shared/SectionDropdownTitle";
import { useMainStore } from "@/contexts/Store";

import type {
  KRICompanyLibraryRecord,
  KRILibraryRecord,
  KRIThemisLibraryRecord,
} from "../pages/types";

interface Props {
  type: "company" | "themis";
  sectionTitle: string;
  rows: KRILibraryRecord[];
  handleRowCheckboxChange: (id: number, checked: boolean) => void;
  handleSectionCheckboxChange: (ids: number[], checked: boolean) => void;
  checkedRows: number[];
}

const KRIThemisLibrarySection = ({
  sectionTitle,
  rows,
  handleRowCheckboxChange,
  handleSectionCheckboxChange,
  checkedRows,
  type,
}: Props) => {
  const COLUMNS = ["Risk Category", "Name"];
  const [isOpen, setIsOpen] = useState(true);
  const [openedDeletePopupID, setOpenedDeletePopupID] = useState<number | null>(
    null,
  );

  const allSelected = rows.every((item) => checkedRows.includes(item.id));

  const mainStore = useMainStore();
  const { companyID, workspaceID } = mainStore.context;

  const { canDeleteRecords, canManageTags, hasModuleWriteAccess } =
    mainStore.userPermissions;
  const isInternalWorkspace = mainStore.context.isIW;
  const isCompanyLibrary = type === "company";
  const isThemisLibrary = type === "themis";
  const history = useHistory();
  const handleDelete = async (id: number) => {
    if (!companyID) {
      return;
    }
    await mainStore.keyRiskIndicatorsCompanyLibrary.destroy({ id, companyID });
  };

  const isEditable = isCompanyLibrary && canManageTags;
  async function createNew() {
    history.push(
      `/workspaces/${workspaceID}/modules/key-risk-indicators/templates/new`,
    );
  }

  const renderColumnHeader = (column: string) => (
    <div
      key={column}
      className={classNames(
        "kri-library-table-column",
        { "risk-category": column === "Risk Category" },
        { "risk-name": column === "Name" },
      )}
      data-testid="kri-library-column"
    >
      <Icon
        name={column === "Risk Category" ? "listBullet" : "text"}
        color="extrasBlueGrayDarker"
        size="de"
      />
      <Typography
        label={column}
        size="xs"
        color="extrasBlueGrayDarker"
        className="column-title-text"
      />
    </div>
  );

  const renderRow = (row: KRILibraryRecord) => {
    let riskCategoryName: string;

    if (isThemisLibrary) {
      riskCategoryName = (row as KRIThemisLibraryRecord)
        .key_risk_indicators_themis_risk_categories[0].name;
    } else {
      riskCategoryName = (row as KRICompanyLibraryRecord)
        .key_risk_indicators_company_risk_categories[0].name;
    }

    return (
      <div
        key={row.id}
        className="kri-library-table-row"
        data-testid="kri-library-row"
      >
        <Checkbox
          checked={checkedRows.includes(row.id)}
          onChange={(e) => handleRowCheckboxChange(row.id, e.target.checked)}
          disabled={!hasModuleWriteAccess}
          padding
        />
        <span className="kri-library-table-cell risk-category">
          <Typography
            label={riskCategoryName}
            size="xs"
            color="extrasDarkNavy"
            className="kri-risk-category"
          />
        </span>
        <span className="kri-library-table-cell risk-name">
          <Typography label={row.name} size="xs" color="extrasDim300" />
        </span>
        {isEditable && (
          <div className="kri-library-table-row-actions">
            <Link
              to={`/workspaces/${workspaceID}/modules/key-risk-indicators/templates/${row.id}`}
            >
              <IconButton icon="edit" color="generalDark" size="de" />
            </Link>
            <Popup
              position="bottom right"
              open={openedDeletePopupID === row.id}
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => (
                <button
                  type="button"
                  disabled={!canDeleteRecords || !isInternalWorkspace}
                  onClick={() => setOpenedDeletePopupID(row.id)}
                >
                  <IconButton icon="trash" color="generalDark" size="de" />
                </button>
              )}
              onClose={() => setOpenedDeletePopupID(null)}
            >
              <div className="table-dropdown success-dropdown">
                <ConfirmationDialog
                  heading="Delete Library Item"
                  content="Are you sure you want to delete this library item? This action cannot be undone."
                  handleConfirm={() => {
                    handleDelete(row.id);
                    setOpenedDeletePopupID(null);
                  }}
                  handleReject={() => setOpenedDeletePopupID(null)}
                />
              </div>
            </Popup>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="kri-library-section">
      <SectionDropdownTitle
        title={sectionTitle}
        isOpen={isOpen}
        onToggleOpen={() => setIsOpen((value) => !value)}
      />
      {isOpen && (
        <div className="kri-library-section-table">
          <div className="kri-library-table-columns-wrapper">
            <Checkbox
              checked={allSelected}
              onChange={() => {
                handleSectionCheckboxChange(
                  rows.map((row) => row.id),
                  !allSelected,
                );
              }}
              data-testid="kri-library-main-checkbox"
              disabled={!hasModuleWriteAccess}
              padding
            />
            {COLUMNS.map(renderColumnHeader)}
          </div>

          <div className="kri-library-rows">{rows.map(renderRow)}</div>
          {isEditable && (
            <div
              className="tw-sticky tw-left-[7px] tw-ml-[7px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-4 tw-px-[7px] tw-py-[6px]"
              onClick={createNew}
              data-testid="library-add-new"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">
                Add Library Item...
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(KRIThemisLibrarySection);

import React, { useEffect, useState } from "react";
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Loading from "@/components/Loading";
import LoadingError from "@/components/loading-error";
import CreativeView from "@/components/table/shared/creative-view/CreativeView";
import { useMainStore } from "@/contexts/Store";

import CreateQuestionnaireTemplatePage from "./CreateQuestionnaireTemplatePage";
import CreateRiskMethodology from "./CreateRiskMethodology";
import CustomerQuestionnaireDetailPage from "./cw/CustomerQuestionnaireDetailPage";
import CustomerQuestionnaireScoringPage from "./cw/CustomerQuestionnaireScoringPage";
import CWPartnerDocumentsPage from "./cw/CWPartnerDocumentsPage";
import PartnerQuestionnairePage from "./cw/PartnerQuestionnairePage";
import PartnerQuestionnaireReview from "./cw/PartnerQuestionnaireReview";
import EditQuestionnaireTemplatePage from "./EditQuestionnaireTemplatePage";
import EditRiskMethodology from "./EditRiskMethodology";
import PartnerDetailsPage from "./PartnerDetailsPage";
import PartnerDocumentsPage from "./PartnerDocumentsPage";
import PartnersPage from "./PartnersPage";
import PartnersQuestionnaireCreatePage from "./PartnersQuestionnaireCreatePage";
import PartnersQuestionnairesPage from "./PartnersQuestionnairesPage";
import PartnersScoringPage from "./PartnersScoringPage";
import QuestionnaireReviewPage from "./QuestionnaireReviewPage/QuestionnaireReviewPage";
import QuestionnaireScoringPage from "./QuestionnaireScoringPage";
import QuestionnaireTemplatesPage from "./QuestionnaireTemplatesPage";
import RADashboardPage from "./RADashboardPage";
import RiskAreasPage from "./RiskAreasPage/RiskAreasPage";
import RiskMethodologyPage from "./RiskMethodologyPage/RiskMethodologyPage";

export const ROUTES = {
  QUESTIONNAIRE_FORM_PATH: "/forms/questionnaires",
  BASE_PATH: "/workspaces/:workspace_id/modules/risk-assessment",
  DASHBOARD_PATH: "/workspaces/:workspace_id/modules/risk-assessment/dashboard",
  DETAIL_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:assessmentId",
  CREATE_ATTACHMENT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/attachment_view/:record_version_id/:file_id",

  PARTNERS_PATH: "/workspaces/:workspace_id/modules/risk-assessment/partners",
  PARTNERS_DETAILS_NEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/add-new-partners",
  PARTNERS_DETAILS_EDIT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id",
  PARTNERS_QUESTIONNAIRES_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires",
  PARTNERS_QUESTIONNAIRES_CREATE_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires/create",
  PARTNERS_QUESTIONNAIRES_CREATE_WITH_TEMPLATE_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires/create/:template_id",
  PARTNERS_SCORING_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/scoring",
  PARTNERS_ASSESSMENTS_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/assessments",
  PARTNERS_DOCUMENTS_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/documents",
  CW_PARTNERS_DOCUMENTS_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/documents",

  QUESTIONNAIRE_TEMPLATE_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/questionnaire-template",
  QUESTIONNAIRE_TEMPLATE_CREATION_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/questionnaire-template/create",
  QUESTIONNAIRE_TEMPLATE_EDIT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/questionnaire-template/:templateId",
  PARTNERS_QUESTIONNAIRE_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/partner-questionnaire/review/:questionnaireId",
  INTERNAL_QUESTIONNAIRE_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/internal-questionnaire/review/:questionnaireId",

  QUESTIONNAIRE_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/questionnaires/:questionnaireId/review",
  QUESTIONNAIRE_SCORING_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires/:questionnaireId/scoring",

  CUSTOMER_QUESTIONNAIRE_DETAIL_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/partner-questionnaire/:questionnaireId/review",
  CUSTOMER_QUESTIONNAIRE_SCORING_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/partner-questionnaire/:questionnaireId/scoring",

  METHODOLOGY_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-methodology",
  METHODOLOGY_CREATION_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-methodologies/create",
  METHODOLOGY_EDIT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-methodologies/:riskMethodologyId",

  RISK_AREA_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-area",
};

function InternalRoutes() {
  const { workspace_id } = useParams<{ workspace_id: string }>();

  return (
    <Route path={ROUTES.BASE_PATH}>
      <Switch>
        <Route exact path={ROUTES.DASHBOARD_PATH}>
          <RADashboardPage />
        </Route>
        <Route exact path={ROUTES.PARTNERS_PATH}>
          <PartnersPage />
        </Route>
        <Route path={ROUTES.QUESTIONNAIRE_TEMPLATE_PATH}>
          <Switch>
            <Route exact path={ROUTES.QUESTIONNAIRE_TEMPLATE_CREATION_PATH}>
              <CreateQuestionnaireTemplatePage />
            </Route>
            <Route exact path={ROUTES.QUESTIONNAIRE_TEMPLATE_EDIT_PATH}>
              <EditQuestionnaireTemplatePage />
            </Route>
            <Route path="*">
              <QuestionnaireTemplatesPage />
            </Route>
          </Switch>
        </Route>

        <Route exact path={ROUTES.METHODOLOGY_PATH}>
          <RiskMethodologyPage />
        </Route>
        <Route exact path={ROUTES.RISK_AREA_PATH}>
          <RiskAreasPage />
        </Route>
        <Route exact path={ROUTES.METHODOLOGY_CREATION_PATH}>
          <CreateRiskMethodology />
        </Route>
        <Route exact path={ROUTES.METHODOLOGY_EDIT_PATH}>
          <EditRiskMethodology />
        </Route>
        <Route exact path={ROUTES.PARTNERS_DETAILS_NEW_PATH}>
          <PartnerDetailsPage isNew />
        </Route>
        <Route exact path={ROUTES.PARTNERS_DETAILS_EDIT_PATH}>
          <PartnerDetailsPage />
        </Route>
        <Route
          exact
          path={ROUTES.PARTNERS_QUESTIONNAIRES_CREATE_WITH_TEMPLATE_PATH}
        >
          <PartnersQuestionnaireCreatePage />
        </Route>
        <Route exact path={ROUTES.PARTNERS_QUESTIONNAIRES_CREATE_PATH}>
          <PartnersQuestionnaireCreatePage />
        </Route>
        <Route exact path={ROUTES.PARTNERS_QUESTIONNAIRES_PATH}>
          <PartnersQuestionnairesPage />
        </Route>
        <Route exact path={ROUTES.PARTNERS_SCORING_PATH}>
          <PartnersScoringPage />
        </Route>
        <Route exact path={ROUTES.PARTNERS_DOCUMENTS_PATH}>
          <PartnerDocumentsPage />
        </Route>
        <Route exact path={ROUTES.INTERNAL_QUESTIONNAIRE_REVIEW_PATH}>
          <PartnerQuestionnaireReview
            hideResponderChange
            showResponderList
            showRespondentFilter
          />
        </Route>
        <Route exact path={ROUTES.QUESTIONNAIRE_REVIEW_PATH}>
          <QuestionnaireReviewPage />
        </Route>
        <Route exact path={ROUTES.QUESTIONNAIRE_SCORING_PATH}>
          <QuestionnaireScoringPage />
        </Route>
        <Route path={ROUTES.CREATE_ATTACHMENT_PATH}>
          <CreativeView />
        </Route>
        <Route path="*">
          <Redirect
            to={generatePath(ROUTES.DASHBOARD_PATH, { workspace_id })}
          />
        </Route>
      </Switch>
    </Route>
  );
}

function PartnerRoutes() {
  const { workspace_id } = useParams<{ workspace_id: string }>();
  return (
    <Route path={ROUTES.BASE_PATH}>
      <Switch>
        <Route exact path={ROUTES.DASHBOARD_PATH}>
          <PartnerQuestionnairePage />
        </Route>
        <Route exact path={ROUTES.CW_PARTNERS_DOCUMENTS_PATH}>
          <CWPartnerDocumentsPage />
        </Route>
        <Route exact path={ROUTES.PARTNERS_QUESTIONNAIRE_REVIEW_PATH}>
          <PartnerQuestionnaireReview />
        </Route>

        <Route path={ROUTES.CUSTOMER_QUESTIONNAIRE_DETAIL_REVIEW_PATH}>
          <CustomerQuestionnaireDetailPage />
        </Route>
        <Route path={ROUTES.CUSTOMER_QUESTIONNAIRE_SCORING_PATH}>
          <CustomerQuestionnaireScoringPage />
        </Route>

        <Route path={ROUTES.QUESTIONNAIRE_REVIEW_PATH}>
          <QuestionnaireReviewPage
            redirectPathAfterCompletion={ROUTES.DASHBOARD_PATH}
            isPartner
          />
        </Route>
        <Route path="*">
          <Redirect
            to={generatePath(ROUTES.DASHBOARD_PATH, { workspace_id })}
          />
        </Route>
      </Switch>
    </Route>
  );
}

export function RiskAssessmentRouter() {
  const mainStore = useMainStore();
  const isInternal = mainStore.context.activeWorkspace?.is_internal;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { workspaceID } = mainStore.context;
  const location = useLocation();
  const isRouterMatched = Boolean(
    useRouteMatch({
      path: `${ROUTES.BASE_PATH}/:record_version_id`,
    }),
  );
  const isMainPage = Boolean(
    useRouteMatch({
      path: ROUTES.PARTNERS_ASSESSMENTS_PATH,
      exact: true,
    }),
  );

  const fetchData = async () => {
    if (workspaceID) {
      try {
        if (isMainPage || isRouterMatched) {
          await mainStore.riskAssessmentPartners.index({ workspaceID });
        }
        await mainStore.riskMethodologies.index(workspaceID);
      } catch (err) {
        setError("There was an issue loading Risk Assessment module.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [workspaceID, location.pathname]);

  if (error) {
    return <LoadingError loadingError="error" />;
  }

  if (loading) {
    return <Loading loadingLayout="table" />;
  }

  return isInternal ? <InternalRoutes /> : <PartnerRoutes />;
}

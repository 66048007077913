import "./partners-questionnaires.scss";

import { TextInput } from "@themis/ui";
import { debounce } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import {
  PartnersAPI,
  QuestionnaireAPI,
  RiskMethodologyAPI,
} from "@/api/legacy/risk-assessment";
import DashboardHeader from "@/components/shared/DashboardHeader";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import EmailAttest from "@/components/table/policy/EmailAttest";
import { useMainStore } from "@/contexts/Store";
import type { User } from "@/stores/types/user-types";

import { ROUTES } from ".";
import FlexDashboardContent from "../components/FlexDashboardContent/FlexDashboardContent";
import FlexDashboardContentWrapper from "../components/FlexDashboardContentWrapper/FlexDashboardContentWrapper";
import PartnerQuestionnaireSection from "../components/Partners/PartnerQuestionnaireSection";
import PartnerSendToCwMenu from "../components/Partners/PartnerSendToCwMenu";
import PartnerTabs from "../components/PartnerTabs";
import UploadQuestionnaireModal from "../components/Questionnaires/QuestionnaireList/UploadQuestionnaireModal";
import TablePaging from "../components/Table/TablePaging";
import type {
  QuestionnaireMeta,
  QuestionnaireRead,
  RAPartner,
  RiskMethodology,
} from "../types";

const PartnersQuestionnairesPage = () => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const { active_workspace } = mainStore.users.user;

  const history = useHistory();
  const { record_version_id } = useParams<{ record_version_id: string }>();

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useMemo(() => debounce(setSearchQuery, 250), []);
  const [questionnairePage, setQuestionnairePage] = useState(1);
  const [questionnairePageMeta, setQuestionnairePageMeta] =
    useState<QuestionnaireMeta>();

  const [partnerQuestionnaires, setPartnerQuestionnaires] = useState<
    Array<QuestionnaireRead>
  >([]);
  const [riskMethodologies, setRiskMethodologies] =
    useState<RiskMethodology[]>();
  const [questionnaireTemplates, setQuestionnaireTemplates] =
    useState<Array<QuestionnaireRead>>();

  const [partners, setPartners] = useState<Array<RAPartner>>();

  const [activeQuestionnaireId, setActiveQuestionnaireId] = useState<
    number | null
  >(null);
  const [uploadQuestionnaireAnswersOpen, setUploadQuestionnaireAnswersOpen] =
    useState(false);
  const [sendQuestionnaireModalOpen, setSendQuestionnaireModalOpen] =
    useState(false);
  const [sendQuestionnaireToCwModalOpen, setSendQuestionnaireToCwModalOpen] =
    useState(false);
  const [moduleAccessUsers, setModuleAccessUsers] = useState<User[]>();

  async function fetch(page = 1) {
    if (!workspaceID) {
      return;
    }
    const abortController = new AbortController();
    try {
      const [
        questionnaireData,
        partnerData,
        questionnaireTemplateData,
        methodologiesData,
        accessUsersData,
      ] = await Promise.all([
        PartnersAPI.getQuestionnaires(
          workspaceID,
          Number(record_version_id),
          searchQuery,
          page,
        ),
        PartnersAPI.list(workspaceID, abortController.signal),
        QuestionnaireAPI.getAll(workspaceID, abortController.signal, true),
        RiskMethodologyAPI.getAll(workspaceID, abortController.signal),
        mainStore.permissions.getUsersAccessibleToModule(
          mainStore.context.moduleWorkspaceID!,
        ),
      ]);
      setPartnerQuestionnaires(questionnaireData.questionnaires);
      setQuestionnairePageMeta(questionnaireData.meta);
      setPartners(partnerData.record_versions);
      setQuestionnaireTemplates(questionnaireTemplateData.questionnaires);
      setRiskMethodologies(methodologiesData);
      setQuestionnairePage(page);
      setModuleAccessUsers(accessUsersData);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(
        err,
        "There was an issue loading questionnaire data. Please try again",
      );
      history.replace(
        generatePath(ROUTES.PARTNERS_PATH, {
          workspace_id: Number(workspaceID),
        }),
      );
    }
  }

  const setNewDueDate = (date: Date | undefined, questionnaireID: number) => {
    if (date === undefined) {
      return;
    }

    setPartnerQuestionnaires((prev) => {
      return prev.map((subQuestionnaire) => {
        if (subQuestionnaire.id === questionnaireID) {
          subQuestionnaire.due_date = date.toISOString();
        }
        return subQuestionnaire;
      });
    });
  };

  useEffect(() => {
    fetch();
  }, [workspaceID, searchQuery]);

  const handleCopyLink = async (externalId: string) => {
    await navigator.clipboard.writeText(
      `${location.host}${ROUTES.QUESTIONNAIRE_FORM_PATH}/${externalId}`,
    );
    mainStore.toast.setInfoText("Link copied!");
  };

  const handleDeleteQuestionnaire = async (questionnaireId: number) => {
    await QuestionnaireAPI.delete(questionnaireId);
    await fetch();
  };

  const handleCopyPassword = async (password: string) => {
    await navigator.clipboard.writeText(password);
    mainStore.toast.setInfoText("Password copied!");
  };

  const handleUnlockReviewQuestionnaire = async (
    id: number,
    status: string,
  ) => {
    switch (status) {
      case "finalized":
        await QuestionnaireAPI.setInReview(id);
        await fetch();
        break;
      case "ready_for_review":
        await QuestionnaireAPI.setInReview(id);
        history.push(
          generatePath(ROUTES.QUESTIONNAIRE_REVIEW_PATH, {
            questionnaireId: id,
            workspace_id: Number(workspaceID),
          }),
        );
        break;
      default:
        history.push(
          generatePath(ROUTES.QUESTIONNAIRE_REVIEW_PATH, {
            questionnaireId: id,
            workspace_id: Number(workspaceID),
          }),
        );
        break;
    }
  };

  return (
    <>
      <UploadQuestionnaireModal
        isOpen={uploadQuestionnaireAnswersOpen}
        onClose={() => {
          setUploadQuestionnaireAnswersOpen(false);
          setActiveQuestionnaireId(null);
        }}
        onFileAdded={async (file) => {
          try {
            await QuestionnaireAPI.importQuestionnaire(
              activeQuestionnaireId!,
              file,
            );
            window.location.reload();
          } catch (err) {
            mainStore.toast.setErrorFromResponse(err);
          } finally {
            setActiveQuestionnaireId(null);
          }
        }}
      />
      <SlideMenu
        open={sendQuestionnaireModalOpen}
        closeSlideMenu={() => {
          setSendQuestionnaireModalOpen(false);
          setActiveQuestionnaireId(null);
        }}
      >
        <EmailAttest
          moduleWorkspaceID={mainStore.context.moduleWorkspaceID!}
          questionnaireID={activeQuestionnaireId!}
          hideDepartment
        />
      </SlideMenu>

      <SlideMenu open={sendQuestionnaireToCwModalOpen} singleSlideMenu>
        <PartnerSendToCwMenu
          questionnaireId={activeQuestionnaireId!}
          onClose={() => setSendQuestionnaireToCwModalOpen(false)}
        />
      </SlideMenu>

      <FlexDashboardContent>
        <DashboardHeader
          title="Questionnaires"
          onBackClick={() =>
            history.push(
              generatePath(ROUTES.PARTNERS_PATH, {
                workspace_id: Number(workspaceID),
              }),
            )
          }
        />
        <FlexDashboardContentWrapper
          ModuleHeaderContent={
            <PartnerTabs
              recordVersionId={record_version_id}
              questionnaireTemplates={questionnaireTemplates}
            />
          }
        >
          <div className="partner-questionnaires-content tw-flex tw-flex-col tw-gap-2">
            {(searchQuery || partnerQuestionnaires) && (
              <TextInput
                placeholder="Search"
                size="lg"
                className="tw-h-[36px] tw-min-h-[36px] tw-w-[calc(100%-36px)]"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  debouncedSearchQuery(event.target.value)
                }
              />
            )}
            <PartnerQuestionnaireSection
              setNewDueDate={setNewDueDate}
              partnerQuestionnaires={partnerQuestionnaires}
              riskMethodologies={riskMethodologies}
              activeWorkspace={active_workspace}
              partners={partners}
              onUploadQuestionnaireAnswers={(questionnaireId: number) => {
                setActiveQuestionnaireId(questionnaireId);
                setUploadQuestionnaireAnswersOpen(true);
              }}
              onCopyLink={handleCopyLink}
              onDeleteQuestionnaire={handleDeleteQuestionnaire}
              onSendQuestionnaire={(questionnaireId: number) => {
                setActiveQuestionnaireId(questionnaireId);
                setSendQuestionnaireModalOpen(true);
              }}
              onSendQuestionnaireToCW={(questionnaireId: number) => {
                setActiveQuestionnaireId(questionnaireId);
                setSendQuestionnaireToCwModalOpen(true);
              }}
              onUnlockReviewQuestionnaire={handleUnlockReviewQuestionnaire}
              onCopyPassword={handleCopyPassword}
              accessUsers={moduleAccessUsers}
            />
            {partnerQuestionnaires && (
              <TablePaging
                totalPages={
                  questionnairePageMeta
                    ? Math.ceil(
                        questionnairePageMeta.total_count /
                          questionnairePageMeta.limit,
                      )
                    : 0
                }
                page={questionnairePage}
                onPageChange={(page) => fetch(page)}
              />
            )}
          </div>
        </FlexDashboardContentWrapper>
      </FlexDashboardContent>
    </>
  );
};

export default observer(PartnersQuestionnairesPage);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const PlusRect = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 5.4C12.6075 5.4 13.1 5.89249 13.1 6.5V10.9H17.5C18.1075 10.9 18.6 11.3925 18.6 12C18.6 12.6075 18.1075 13.1 17.5 13.1H13.1V17.5C13.1 18.1075 12.6075 18.6 12 18.6C11.3925 18.6 10.9 18.1075 10.9 17.5V13.1H6.5C5.89249 13.1 5.4 12.6075 5.4 12C5.4 11.3925 5.89249 10.9 6.5 10.9H10.9V6.5C10.9 5.89249 11.3925 5.4 12 5.4Z"
      fill="#353549"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.3 23C2.47746 23 1 21.5225 1 19.7V4.3C1 2.47746 2.47746 1 4.3 1H19.7C21.5225 1 23 2.47746 23 4.3V19.7C23 21.5225 21.5225 23 19.7 23H4.3ZM3.2 19.7C3.2 20.3075 3.69249 20.8 4.3 20.8H19.7C20.3075 20.8 20.8 20.3075 20.8 19.7V4.3C20.8 3.69249 20.3075 3.2 19.7 3.2H4.3C3.69249 3.2 3.2 3.69249 3.2 4.3V19.7Z"
      fill="#353549"
    />
  </svg>
);

PlusRect.displayName = "PlusRectIcon";

export default PlusRect;

import * as GoogleAPI from "@/api/legacy/google-access-token";

export async function googleFilePicker(
  saveGoogleDriveUrl: (url: string) => Promise<void>,
) {
  try {
    const { auth_token } = await GoogleAPI.getGoogleAccessToken();
    gapi.load("picker", { callback: () => createPicker(auth_token) });
  } catch (error) {
    window.console.log(`"Google File Picker" error ${error}`);
    throw error;
  }

  function createPicker(authToken: string) {
    const picker = new google.picker.PickerBuilder()
      .addView(
        new google.picker.DocsView().setParent("root").setIncludeFolders(true),
      )
      .addView(new google.picker.DocsView().setOwnedByMe(false))
      .setOAuthToken(authToken)
      .setCallback(pickerCallback)
      .setMaxItems(1)
      .build();
    picker.setVisible(true);
  }

  function pickerCallback(data: google.picker.ResponseObject) {
    if (data.action === google.picker.Action.PICKED) {
      saveGoogleDriveUrl(data.docs[0].url);
    }
  }
}

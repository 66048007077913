import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import Button from "@/components/form/Button";
import EmailInput from "@/components/form/EmailInput";
import { useMainStore } from "@/contexts/Store";

import Header from "../components/Header";

function ForgotPassword() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [emailError, setEmailError] = useState(null);
  const [emailIsPresent, setEmailIsPresent] = useState(false);
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const [showMemo, setShowMemo] = useState(false);

  useEffect(() => {
    const shouldEnableForm = emailIsPresent && !emailError;
    setIsFormEnabled(shouldEnableForm);
  }, [emailIsPresent, emailError]);

  useEffect(() => {
    if (mainStore.users.resetPasswordIsSent) {
      setShowMemo(true);
    }
  }, [mainStore.users.resetPasswordIsSent]);

  // Refs
  const emailRef = React.createRef<HTMLInputElement>();

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleChange(event) {
    event.preventDefault();

    // Get field name and the value
    const { name, value } = event.target;

    // Validate email as user types
    if (name === "email") {
      setEmailIsPresent(value.length > 0);
      validateEmail(value);
    }

    // Reset the memo and email sent state
    mainStore.users.setResetPasswordIsSent(false);
    setShowMemo(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function validateEmail(value) {
    const validEmailRegex = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    );

    if (validEmailRegex.test(value)) {
      setEmailError(null);
    } else if (value === "") {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"Should be present"' is not assi... Remove this comment to see the full error message
      setEmailError("Should be present");
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '"Should be valid email"' is not ... Remove this comment to see the full error message
      setEmailError("Should be valid email");
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleSubmit(event) {
    event.preventDefault();

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    mainStore.users.resetPassword(emailRef.current.value);
  }

  return (
    <div className="check-in">
      <Header />
      <div className="check-in-wrap">
        <div className="password-wrap">
          <form onSubmit={handleSubmit}>
            <h2>Forgot Password?</h2>
            <p className="password-text">
              Enter your email address and we’ll send you a one-time URL to
              reset
              <br /> your password. The link will expire after 10 minutes.
            </p>
            <div className="password-form-wrap">
              <EmailInput
                placeholder="Company Email Address"
                name="email"
                onChange={handleChange}
                ref={emailRef}
                autoComplete="email"
              />
              {emailError ? <p className="error-input">{emailError}</p> : ""}
              <Button title="Send" enabled={isFormEnabled} />
            </div>
          </form>
          <br />
          {showMemo && (
            <p className="memo">
              You have successfully submitted your email address.
              <br />
              If this address is a Themis account an email will be sent with
              further password reset instructions.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(ForgotPassword);

import type { PreviewTableColumn } from "@themis/ui";
import { format, parseISO } from "date-fns";
import React from "react";
import { PiBookOpenBold, PiTextTBold } from "react-icons/pi";

import type { MonitoringGroupAssetChildResult } from "@/api";

import { StatusTag } from "../components/StatusTag";
import ViewSlideButton from "../components/ViewSlideButton";

export const MONITORING_GROUP_ASSET_CHILD_RESULT_COLUMNS: PreviewTableColumn<MonitoringGroupAssetChildResult>[] =
  [
    {
      title: "Date",
      type: "locked",
      width: 100,
      Component: (monitoring_group_result) =>
        format(
          parseISO(
            monitoring_group_result.monitoring_group_asset_child_result_created_at ||
              "",
          ),
          "MM/dd/yyyy",
        ),
      Icon: PiBookOpenBold,
      noCellPadding: true,
    },
    {
      key: "document_type",
      title: "Sub Type",
      type: "locked",
      width: 170,
      Component: (monitoring_group_result) => (
        <StatusTag status={monitoring_group_result.document_type || ""} />
      ),
    },
    {
      key: "copy_title_data",
      title: "Data",
      type: "locked",
      width: 500,
      Icon: PiTextTBold,
    },
    {
      title: "Status",
      type: "locked",
      isRowEnd: true,
      width: 140,
      Component: (monitoring_group_result) => (
        <StatusTag status={monitoring_group_result.status || ""} />
      ),
    },
    {
      type: "actions",
      Component: (monitoring_group_result) => (
        <ViewSlideButton monitoringGroupResultId={monitoring_group_result.id} />
      ),
    },
  ] as const;

import type { FieldOption } from "../types/field-types";

export const additionalFields = [
  {
    name: "relative",
    data_type: "com.askthemis.types.v1.option",
    display_name: "Notify User at / until",
    options: ["Before / prior", "On", "After"],
    is_multiselect: false,
    is_user_editable: true,
    tables_positions: {
      Default: 4,
    },
  },
  {
    name: "interval",
    data_type: "com.askthemis.types.v1.text",
    display_name: "",
    is_multiselect: false,
    is_user_editable: true,
    tables_positions: {
      Default: 4,
    },
  },
  {
    name: "trigger_column",
    data_type: "com.askthemis.types.v1.option",
    display_name: "Trigger Column",
    options: ["Due Date", "Review Date", "Expiry Date", "Complaint Date"],
    is_multiselect: false,
    is_user_editable: false,
    tables_positions: {
      Default: 4,
    },
  },
];

export const additionalFieldOptions: Record<string, FieldOption[]> = {
  relative: [
    {
      color_scheme_id: 1,
      display_name: "before",
      is_used: true,
      name: "before",
    },
    {
      color_scheme_id: 1,
      display_name: "after",
      is_used: true,
      name: "after",
    },
    {
      color_scheme_id: 1,
      display_name: "same day of",
      is_used: true,
      name: "on",
    },
  ],
  scale: [
    {
      color_scheme_id: 1,
      display_name: "Daily",
      is_used: true,
      name: "days",
    },
    {
      color_scheme_id: 1,
      display_name: "Weekly",
      is_used: true,
      name: "weeks",
    },
    {
      color_scheme_id: 1,
      display_name: "Monthly",
      is_used: true,
      name: "months",
    },
    {
      color_scheme_id: 1,
      display_name: "One Time",
      is_used: true,
      name: "one_time",
    },
  ],
  interval_scale: [
    {
      color_scheme_id: 1,
      display_name: "Days",
      is_used: true,
      name: "Day",
    },
    {
      color_scheme_id: 1,
      display_name: "Weeks",
      is_used: true,
      name: "Week",
    },
    {
      color_scheme_id: 1,
      display_name: "Months",
      is_used: true,
      name: "Month",
    },
  ],
  week_interval: [
    {
      color_scheme_id: 1,
      display_name: "1 week",
      is_used: true,
      name: "1",
    },
    {
      color_scheme_id: 1,
      display_name: "2 weeks",
      is_used: true,
      name: "2",
    },
    {
      color_scheme_id: 1,
      display_name: "3 weeks",
      is_used: true,
      name: "3",
    },
    {
      color_scheme_id: 1,
      display_name: "4 weeks",
      is_used: true,
      name: "4",
    },
  ],
  day: [
    {
      color_scheme_id: 1,
      display_name: "SU",
      is_used: true,
      name: "Sunday",
    },
    {
      color_scheme_id: 1,
      display_name: "MO",
      is_used: true,
      name: "Monday",
    },
    {
      color_scheme_id: 1,
      display_name: "TU",
      is_used: true,
      name: "Tuesday",
    },
    {
      color_scheme_id: 1,
      display_name: "WE",
      is_used: true,
      name: "Wednesday",
    },
    {
      color_scheme_id: 1,
      display_name: "TH",
      is_used: true,
      name: "Thursday",
    },
    {
      color_scheme_id: 1,
      display_name: "FR",
      is_used: true,
      name: "Friday",
    },
    {
      color_scheme_id: 1,
      display_name: "SA",
      is_used: true,
      name: "Saturday",
    },
  ],
};

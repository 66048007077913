import React from "react";

import { Flex, Typography } from "@/components/Elements";
import themisLogo from "@/images/logo.svg";

interface HeaderProps {
  title?: string;
}

export default function Header({ title }: HeaderProps) {
  return (
    <div className="header-sign-up">
      <Flex column justifyCenter alignCenter className="header-login-wrap">
        <a href="/">
          <img src={themisLogo} alt="themis-logo" />
        </a>
        {!!title && (
          <Typography
            className="header-login-wrap__title"
            label={title}
            size="xl"
            color="generalMidnightDark"
            weight="semiBold"
          />
        )}
      </Flex>
    </div>
  );
}

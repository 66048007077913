import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Eye = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9178 17.0022C21.7568 15.4193 23 13.4184 23 12C23 10.5814 21.7588 8.5804 19.9216 6.99799C17.7522 5.12935 15.0005 4 12 4C8.9999 4 6.2485 5.13109 4.07807 7.00221C2.24207 8.58501 1.00002 10.586 1.00002 12C1.00002 13.4137 2.24403 15.4147 4.08194 16.998C6.25348 18.8688 9.00414 20 12 20C14.9963 20 17.7472 18.8705 19.9178 17.0022ZM5.38732 15.4828C3.94709 14.242 3.00001 12.7187 3.00001 12C3.00001 11.2802 3.9454 9.75717 5.38396 8.517C7.20787 6.94462 9.50569 5.99999 12 5.99999C14.4953 5.99999 16.7935 6.94322 18.6164 8.51335C20.0557 9.75309 21 11.2754 21 12C21 12.7235 20.054 14.2461 18.613 15.4864C16.7888 17.0566 14.4911 18 12 18C9.50995 18 7.21254 17.0552 5.38732 15.4828ZM12 8.00001C14.2092 8.00001 16 9.79087 16 12C16 14.2091 14.2092 16 12 16C9.79089 16 8.00003 14.2091 8.00003 12C8.00003 9.79087 9.79089 8.00001 12 8.00001ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C13.8981 12 13.6747 12 13.5 12C12.6716 12 12 11.3284 12 10.5C12 10.3253 12 10.058 12 10Z"
      fill="#353549"
    />
  </svg>
);

Eye.displayName = "EyeIcon";

export default Eye;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupResultsPathParams,
  WebsiteMonitoringGroupResultsQueryResponse,
} from "../../models/WebsiteMonitoringGroupResults";

/**
 * @description List Website Monitoring - Groups with results
 * @summary List Website Monitoring
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_results
 */
export async function websiteMonitoringGroupResults(
  monitoringGroupAssetId: WebsiteMonitoringGroupResultsPathParams["monitoring_group_asset_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<WebsiteMonitoringGroupResultsQueryResponse>["data"]> {
  const res = await client<WebsiteMonitoringGroupResultsQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_results`,
    ...options,
  });
  return res.data;
}

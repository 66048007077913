import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Photo2 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 9C14 8.17157 14.6716 7.5 15.5 7.5C16.3284 7.5 17 8.17157 17 9C17 9.82843 16.3284 10.5 15.5 10.5C14.6716 10.5 14 9.82843 14 9Z"
      fill="#353549"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.26753 4.65855C10.4166 4.38481 13.5834 4.38481 16.7325 4.65855L18.2427 4.78983C19.6878 4.91545 20.8585 6.0151 21.0742 7.44949C21.5278 10.4662 21.5278 13.5338 21.0742 16.5505C20.8585 17.9849 19.6878 19.0845 18.2427 19.2102L16.7325 19.3414C13.5834 19.6152 10.4166 19.6152 7.26753 19.3414L5.7573 19.2102C4.31223 19.0845 3.14156 17.9849 2.92586 16.5505C2.47222 13.5338 2.47222 10.4662 2.92586 7.44949C3.14156 6.0151 4.31223 4.91545 5.7573 4.78983L7.26753 4.65855ZM16.6026 6.15292C13.54 5.88669 10.46 5.88669 7.39743 6.15292L5.8872 6.2842C5.13287 6.34977 4.52178 6.92379 4.40919 7.67254C4.15165 9.38516 4.04786 11.115 4.0978 12.8415L7.96967 8.96967C8.11572 8.82361 8.31556 8.74426 8.52203 8.75032C8.7285 8.75639 8.92332 8.84734 9.06055 9.00173L12.732 13.1321L15.2628 12.2885C15.5459 12.1941 15.8579 12.2765 16.0575 12.4983L19.5766 16.4084C19.582 16.3817 19.5867 16.3547 19.5908 16.3274C20.0222 13.4586 20.0222 10.5414 19.5908 7.67254C19.4782 6.92379 18.8671 6.34977 18.1128 6.2842L16.6026 6.15292ZM18.6202 17.588L15.2711 13.8669L12.7372 14.7115C12.4525 14.8064 12.1388 14.7225 11.9394 14.4983L8.46788 10.5928L4.2242 14.8365C4.27298 15.3346 4.33464 15.8317 4.40919 16.3274C4.52178 17.0762 5.13287 17.6502 5.8872 17.7158L7.39744 17.8471C10.46 18.1133 13.54 18.1133 16.6026 17.8471L18.1128 17.7158C18.2915 17.7003 18.4622 17.6562 18.6202 17.588Z"
      fill="#353549"
    />
  </svg>
);

Photo2.displayName = "Photo2Icon";

export default Photo2;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import deleteIcon from "@/images/table-image/icon/close-icon2.svg";

import plusBlack from "../../../images/table-image/icon/plus-black.svg";
import { Icon } from "../../Elements";

interface SelectedOptionProps {
  name?: string;
  displayName?: string;
  styles?: object;
}

interface availableOptionProps {
  name?: string;
  displayName?: string;
}

interface Props {
  selectedOption?: SelectedOptionProps;
  availableOptions?: availableOptionProps[];
  disabled?: boolean;
  placeholder?: string;
  handleSelect?: (option: object) => void;
  enableAddNew?: boolean;
  onAddNew?: (name: string) => void;
  enableDelete?: boolean;
  onDelete?: (name: string) => void;
}

// component for selecting simple options, which are NOT related to table fields.
function SimpleOptionSelect({
  selectedOption,
  availableOptions,
  disabled,
  placeholder,
  handleSelect,
  enableAddNew,
  onAddNew,
  onDelete,
  enableDelete,
}: Props) {
  // state
  const [open, setOpen] = useState(false);
  const [newOption, setNewOption] = useState("");

  // funcs
  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  function handleLocalSelect(option) {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleSelect(option);
    handleClose();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleNewOptionChange(e) {
    const { value } = e.target;

    setNewOption(value);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleAddNew();
    }
  }

  function handleAddNew() {
    if (!newOption || !onAddNew) {
      return;
    }

    onAddNew(newOption);
    setNewOption("");
  }

  // elements
  const renderTrigger = (
    <div
      data-testid="simple-option-select-trigger"
      className={classNames("simple-option-select-trigger", {
        active: open,
      })}
    >
      <div
        className="simple-option-select-value"
        style={selectedOption?.styles}
      >
        {selectedOption?.displayName || selectedOption?.name || placeholder}
      </div>
      <Icon name="arrowDownBlack" />
    </div>
  );

  const renderPopupContent = (
    <div
      className="table-dropdown single-workspace-dropdown-popup simple-option-select-content"
      data-testid="simple-option-select-popup-content"
    >
      {enableAddNew && (
        <div className="select-add-new-form">
          <input
            type="text"
            placeholder="Add New +"
            value={newOption}
            data-testid="simple-option-select-add-new-input"
            onChange={handleNewOptionChange}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleAddNew} className="add-new-option">
            <img src={plusBlack} alt="plus-black" />
          </button>
        </div>
      )}
      <ul>
        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {availableOptions.map((option) => {
          const selected = selectedOption?.name === option.name;

          return (
            <div
              key={option.name}
              className={classNames("single-workspace-name-container", {
                selected,
              })}
              onClick={() => handleLocalSelect(option)}
            >
              <div className="single-workspace-name">
                <li>{option.displayName || option.name}</li>
              </div>
              {selected && <Icon name="checkBlue" className="check" />}
              {!selected && enableDelete && (
                <span
                  className="select-option-delete-icon"
                  data-testid="column-option-delete-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                    onDelete(option.name);
                  }}
                >
                  <img src={deleteIcon} alt="delete-icon" />
                </span>
              )}
            </div>
          );
        })}
      </ul>
    </div>
  );

  return (
    <Popup
      trigger={renderTrigger}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      disabled={disabled}
    >
      {renderPopupContent}
    </Popup>
  );
}

export default observer(SimpleOptionSelect);

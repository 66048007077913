import "./Checkbox.scss";

import classNames from "classnames";
import type { ComponentPropsWithoutRef } from "react";
import React from "react";

import checkboxActive from "@/images/table-image/icon/custom-checkbox-active.svg";
import checkbox from "@/images/table-image/icon/custom-checkbox.svg";
import roundCheckboxActive from "@/images/table-image/icon/round-checkbox-active.svg";
import roundCheckbox from "@/images/table-image/icon/round-checkbox.svg";

type Props = ComponentPropsWithoutRef<"input"> & {
  small?: boolean;
  padding?: boolean;
  marginTop?: boolean;
  rounded?: boolean;
};

export default function Checkbox({
  small,
  padding,
  marginTop,
  rounded,
  ...props
}: Props) {
  return (
    <div
      className={classNames("checkbox-container", {
        "checkbox-container--padding": padding,
        "tw-mt-2": marginTop,
        "checkbox-container--disabled": props.disabled,
      })}
    >
      <label
        className={classNames("checkbox-container__label", {
          "checkbox-container__label--disabled": props.disabled,
        })}
        data-testid="checkbox"
      >
        <input
          className="checkbox-container__input"
          type="checkbox"
          {...props}
        />
        {rounded ? (
          <img
            className={classNames({ "checkbox-container__icon--small": small })}
            src={props.checked ? roundCheckboxActive : roundCheckbox}
            alt="checkbox"
          />
        ) : (
          <img
            className={classNames({ "checkbox-container__icon--small": small })}
            src={props.checked ? checkboxActive : checkbox}
            alt="checkbox"
          />
        )}
      </label>
    </div>
  );
}

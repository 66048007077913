import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupResultPathParams,
  WebsiteMonitoringGroupResultQueryResponse,
} from "../../models/WebsiteMonitoringGroupResult";

/**
 * @description Website Monitoring - Result
 * @summary Website Monitoring Result
 * @link /website_monitoring/:monitoring_group_result_id/monitoring_group_result
 */
export async function websiteMonitoringGroupResult(
  monitoringGroupResultId: WebsiteMonitoringGroupResultPathParams["monitoring_group_result_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<WebsiteMonitoringGroupResultQueryResponse>["data"]> {
  const res = await client<WebsiteMonitoringGroupResultQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupResultId}/monitoring_group_result`,
    ...options,
  });
  return res.data;
}

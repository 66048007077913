import "./import-from-ws.scss";

import axios from "axios";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { getWorkspaceLogo } from "@/components/helpers/getWorkspaceLogo";
import { useMainStore } from "@/contexts/Store";
import closeIcon from "@/images/table-image/icon/close-black-icon.svg";

import NestedSectionDisplayer from "./NestedSectionDisplayer";

interface ImportFromWSProps {
  closeSlideOut: () => void;
  refetchData: () => void;
  showFolderIcon?: boolean;
}

function ImportFromWS({
  closeSlideOut,
  refetchData,
  showFolderIcon,
}: ImportFromWSProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [sourceWorkspaceId, setSourceWorkspaceID] = useState<number | null>(
    null,
  );
  const [selectedSectionID, setSelectedSectionID] = useState<number | null>(-1);
  const [isCopying, setIsCopying] = useState(false);
  const isButtonDisabled =
    isCopying || selectedSectionID === -1 || !sourceWorkspaceId;

  const { moduleWorkspaceID } = mainStore.context;

  async function onCopy() {
    if (isButtonDisabled) {
      return;
    }

    setIsCopying(true);
    mainStore.toast.setInfoText("The records are being imported.");
    try {
      await mainStore.moduleWorkspaces.importRecordsFromWS(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message
        moduleWorkspaceID,
        sourceWorkspaceId,
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        selectedSectionID > 0 ? selectedSectionID : null,
      );
      await refetchData();
      closeSlideOut();
      mainStore.toast.setInfoText("Records imported successfully.");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'AxiosRes... Remove this comment to see the full error message
        const { status } = error.response;
        if (status === 401) {
          mainStore.toast.setErrorText(
            "You do not have permission to import records.",
          );
        } else {
          mainStore.toast.setErrorText("Something went wrong.");
        }
      } else {
        mainStore.toast.setErrorText("Something went wrong.");
      }
    } finally {
      setIsCopying(false);
    }
  }

  const buttonClasses = classNames({ active: !isButtonDisabled });

  const renderWorkspaces = mainStore.workspaces.list
    .filter(
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      (workspace) => workspace.id !== mainStore.context.activeWorkspace.id,
    )
    .map((workspace) => {
      const { logo, isInternal } = getWorkspaceLogo(workspace);
      const isSelected = sourceWorkspaceId === workspace.id;
      const workspaceActive = classNames("import-ws-workspace-selection", {
        active: isSelected,
      });

      return (
        <li
          data-testid={`send-workspace-block-${workspace.id}`}
          key={workspace.id}
          className={workspaceActive}
          onClick={() => {
            setSourceWorkspaceID(workspace.id);
          }}
        >
          <img src={logo} alt="image" />
          <div className="creative-block">
            <h3>{isInternal ? workspace.company.name : workspace.name}</h3>
          </div>
        </li>
      );
    });

  return (
    <div className="table-dropdown table-email-dropdown">
      <div className="email-dropdown-head">
        <h3>Import data from other workspace</h3>
        <div>
          <img src={closeIcon} alt="close-icon" onClick={closeSlideOut} />
        </div>
      </div>
      <hr />
      <div className="email-dropdown-wrap">
        <div className="import-ws-note-wrapper">
          Records will import from the same Module of the Workspace you select.
        </div>
        <div className="import-ws-selection-wrapper">
          <div className="creative-wrap creative-wrap-sections">
            <h4>Select the section you wish to import records to.</h4>
            <ul>
              <NestedSectionDisplayer
                sections={mainStore.sectionTags.list}
                setSelectedSectionID={setSelectedSectionID}
                // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
                selectedSectionID={selectedSectionID}
                // @ts-expect-error TS(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
                showFolderIcon={showFolderIcon}
              />
            </ul>
          </div>
          <div className="creative-wrap seperate-ws-section-title">
            <h4>Select the Workspace to import records from.</h4>
            <ul>{renderWorkspaces}</ul>
          </div>
          <button
            className={buttonClasses}
            disabled={isButtonDisabled}
            onClick={onCopy}
            data-testid="send-button"
          >
            Import
          </button>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default observer(ImportFromWS);

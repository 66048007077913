import React from "react";
import { Link } from "react-router-dom";

import arrowLeft from "../../images/dashboard/arrow-left.svg";
import logo from "../../images/logo.svg";
import SetupWorkspace from "./SetupWorkspace";

function SettingWorkspace() {
  return (
    <div className="setting-workspace-wrap">
      <div className="setting-workspace-logo">
        <Link to="/user/profile">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <Link to="/user/profile" className="exit-workspace">
          <img src={arrowLeft} alt="arrow-left" />
          Exit workspace setup flow
        </Link>
      </div>
      <SetupWorkspace />
    </div>
  );
}

export default SettingWorkspace;

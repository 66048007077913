import { observer } from "mobx-react";
import React from "react";

import { Button } from "../../Elements";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (...args: any[]) => any;
  active?: boolean;
}

function ModuleUsers({ active, onClick }: Props) {
  return (
    <div className="module-users" data-testid="module-users">
      <Button
        theme="tertiary"
        size="sm"
        label="View Module Users"
        active={active}
        onClick={onClick}
      />
    </div>
  );
}

export default observer(ModuleUsers);

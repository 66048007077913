import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../Elements";

function ExportRiskAssessmentData() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  async function processTableDownload() {
    const [id] = window.location.pathname.split("/templates/")[1].split("/");
    const spreadSheetData =
      await mainStore.vendors.exportRiskAssessmentData(id);
    if (spreadSheetData) {
      const link = document.createElement("a");
      const blob = new Blob([new Uint8Array(spreadSheetData)], {
        type: "application/octet-stream",
      });
      link.href = URL.createObjectURL(blob);
      link.download = "risk_register_library.xlsx";
      link.click();
    }
    setShowPopup(false);
  }

  // elements
  const renderTrigger = (
    <button
      className={classNames("import-button library-export", {
        active: showPopup,
      })}
    >
      <Icon name="export" color={showPopup ? "generalWhite" : "generalDark"} />
      Export Template
    </button>
  );

  const renderPopupContent = (
    <div
      className="table-dropdown export-table-dropdown"
      data-testid="rr-library-export-popup"
    >
      <h3>Export Questionnaires to Excel</h3>
      <p>
        Click the button below to export the questionnaires to an Excel file.
      </p>
      <button
        className="export-table-button"
        onClick={processTableDownload}
        data-testid="rr-library-export-submit"
      >
        <Icon name="download" color="generalWhite" size="de" />
        Export to XLSX File
      </button>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      {renderPopupContent}
    </Popup>
  );
}

export default observer(ExportRiskAssessmentData);

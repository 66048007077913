import classNames from "classnames";
import type { ReactNode } from "react";
import React from "react";

interface Props {
  children: ReactNode;
  dataTestID?: string;
  disableScroll?: boolean;
}

const DashboardContentWrapper = ({
  children,
  dataTestID,
  disableScroll,
}: Props) => (
  <div
    className={classNames(
      "new-dashboard-content-wrap",
      disableScroll && "new-dashboard-content-wrap--scroll-disabled",
    )}
    data-testid={dataTestID}
  >
    {children}
  </div>
);

export default DashboardContentWrapper;

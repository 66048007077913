import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const BookmarkLinear = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.65079 21.7593C5.00212 22.3153 4 21.8544 4 21V5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V21C20 21.8544 18.9979 22.3153 18.3492 21.7593L12 16.3171L5.65079 21.7593ZM18 18.8258V5C18 4.44772 17.5523 4 17 4H7C6.44772 4 6 4.44772 6 5V18.8258L11.3492 14.2407C11.7237 13.9198 12.2763 13.9198 12.6508 14.2407L18 18.8258Z"
      fill="#353549"
    />
  </svg>
);

BookmarkLinear.displayName = "BookmarkLinearIcon";

export default BookmarkLinear;

import { observer } from "mobx-react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Button } from "@/components/Elements";
import CompanyHeaderContent from "@/components/settings/CompanyHeaderContent";
import CustomNotificationSaveDeleteButton from "@/components/settings/notificationsCenter/custom-notifications/CustomNotificationSaveDeleteButton";
import { useMainStore } from "@/contexts/Store";

import CompanyUsersHeader from "./CompanyUsersHeader";

function CompanyHeader(props: { isDirty?: boolean }) {
  const mainStore = useMainStore();
  const { pathname } = useLocation();
  const history = useHistory();

  const { canSeeNotifications } = mainStore.userPermissions;

  const isTasksOrReviewsPage = [
    "/notifications/tasks",
    "/notifications/reviews",
  ].includes(pathname);

  const isCustomNotificationEditOrCreate = [
    "/notifications/custom-notifications/edit/",
    "/notifications/custom-notifications/create",
  ].some((path) => location.pathname.startsWith(path));

  const isUserDetailView = RegExp(/\/settings\/users\/\d+/g).test(pathname);

  if (isUserDetailView) {
    return <CompanyUsersHeader />;
  }

  return (
    <div className="dashboard-header company-header">
      <CompanyHeaderContent />
      <div className="tw-flex tw-justify-end">
        {isTasksOrReviewsPage && canSeeNotifications && (
          <Button
            label="Custom Notifications"
            onClick={() => history.push("/notifications/custom-notifications")}
            size="sm"
            type="button"
            className="tw-mr-2"
          />
        )}
        {isCustomNotificationEditOrCreate && (
          <CustomNotificationSaveDeleteButton
            isDirty={Boolean(props.isDirty)}
          />
        )}
      </div>
    </div>
  );
}

export default observer(CompanyHeader);

import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import ApproveButton from "../shared/ApproveButton";
import ConfirmationDialog from "../shared/ConfirmationDialog";
import PublishFlow from "../shared/PublishFlow";
import Unlock from "../shared/Unlock";

interface Props {
  recordVersion: RecordVersion;
  showOnlyIcon?: boolean;
}

function ChangeManagementContextMenu({
  recordVersion,
  showOnlyIcon = true,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const userID = mainStore.users.user.id;

  const history = useHistory();

  // Variables
  const { tableName, workspaceID } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isPendingFinalization = status === "pending_finalization";
  const isPublished = status === "published";
  const isCanApproved = !isPendingFinalization && !isPublished;
  const { taskDetail } = mainStore;

  // State
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const isDetailView = /\/modules\/[^/]+\/\d+$/.test(location.pathname);

  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  async function deleteRow() {
    // Delete RecordVersion
    const { company } = mainStore.companies;
    if (company && company.id) {
      if (isDetailView) {
        history.push(`/workspaces/${workspaceID}/modules/change-management`);
      }
      await mainStore.changeManagements.delete(recordVersion.id);
    }
  }

  function handlePopUpClose() {
    setIsDropdownOpen(false);
    setIsDeleteConfirmationOpen(false);
  }

  function onApprove() {
    mainStore.reviews.createApproval(recordVersion.id);
  }

  function onUnlock() {
    mainStore.changeManagements.unlock(recordVersion.id);
  }

  function handleCreateTask() {
    setIsDropdownOpen(false);
    taskDetail.openFromRecord(recordVersion);
  }

  const trigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="points-button-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isDropdownOpen,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="cm-context-menu-dropdown"
    >
      <ul>
        <li onClick={handleCreateTask}>Create Task</li>
        <li
          onClick={deleteRowConfirmation}
          data-testid="cm-context-menu-dropdown-delete"
        >
          Delete
        </li>
      </ul>
    </div>
  );

  const approveButton = () => {
    const approverIds = mainStore.avroSchemas.valueForField(
      "approvers",
      recordVersion?.data,
    );
    const canApprove = approverIds?.includes(userID) && hasModuleWriteAccess;
    return <ApproveButton onClick={onApprove} disabled={!canApprove} />;
  };

  const finalizeButton = (
    <PublishFlow
      recordVersionID={recordVersion.id}
      tableName={tableName || undefined}
      moduleStore={mainStore.changeManagements}
      moduleIdentifier="new_product_approval"
    />
  );

  const unlockButton = (
    <Unlock
      onYes={onUnlock}
      tableName="Default"
      moduleIdentifier="new_product_approval"
      buttonText="Unlock"
      canEveryoneUnlock
    />
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Reviewer"
      content="Are you sure you want to delete this reviewer? Actions are not reversible."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  return (
    <div
      className={classNames("list-points", {
        "without-background": !showOnlyIcon,
      })}
      data-testid="cm-context-menu"
    >
      {isPendingFinalization && finalizeButton}
      {isCanApproved && approveButton()}
      {isPublished && unlockButton}
      <Popup
        position="bottom right"
        // eslint-disable-next-line react/no-unstable-nested-components
        trigger={() => trigger}
        disabled={!canDeleteRecords}
        onClose={handlePopUpClose}
        onOpen={() => setIsDropdownOpen(true)}
      >
        {renderBaseContent}
        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

export default observer(ChangeManagementContextMenu);

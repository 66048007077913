import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import CreateNewPassword from "./CreateNewPassword";
import EmailVerificationExpired from "./EmailVerificationExpired";
import EmailVerificationSuccess from "./EmailVerificationSuccess";
import ForgotPassword from "./ForgotPassword";
import InviteSignUp from "./InviteSignUp";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

export const ROUTES = {
  signUp: "/sign-up",
  signIn: "/sign-in",
  forgotPassword: "/forgot-password",
  createNewPassword: "/create-new-password",
  emailVerificationSuccess: "/email-verification-success",
  emailVerificationExpired: "/email-verification-expired",
  inviteSignUp: "/invite-sign-up/:invite_token/:user_id",
} as const;

export const AuthRoutes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={ROUTES.signIn} />
      </Route>
      <Route path={ROUTES.signIn}>
        <SignIn />
      </Route>
      <Route path={ROUTES.signUp}>
        <SignUp />
      </Route>
      <Route path={ROUTES.forgotPassword}>
        <ForgotPassword />
      </Route>
      <Route path={ROUTES.createNewPassword}>
        <CreateNewPassword />
      </Route>
      <Route path={ROUTES.emailVerificationExpired}>
        <EmailVerificationExpired />
      </Route>
      <Route path={ROUTES.emailVerificationSuccess}>
        <EmailVerificationSuccess />
      </Route>
      <Route path={ROUTES.inviteSignUp}>
        <InviteSignUp />
      </Route>
    </Switch>
  );
};

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const ComplaintsReportContent = () => (
  <ReportsPage
    identifier="complaints"
    defaultDateColumn="complaint_start_date"
    defaultGroupColumn="status"
  />
);

export default observer(ComplaintsReportContent);

import React from "react";

import { Icon, Typography } from "@/components/Elements";

interface NoAccessProps {
  message: string;
}

export default function NoAccess(props: NoAccessProps) {
  return (
    <div className="home-content__empty">
      <Icon name="warning" color="accentsLavender" size="md" />
      <Typography
        label={props.message}
        weight="bold"
        color="generalDark"
        className="home-content__emptyWarning"
      />
    </div>
  );
}

import React from "react";

import { useDataInit } from "../hooks/useDataInit";
import Spinner from "./table/shared/Spinner";

interface NewDataLoaderProps {
  children: React.ReactNode;
}

function DataLoading({ children }: NewDataLoaderProps) {
  const { loading } = useDataInit();

  const loader = (
    <div
      className="page-loading-overlay no-opacity"
      data-testid="page-loading-overlay"
    >
      <Spinner />
    </div>
  );

  return !loading ? children : loader;
}

export default DataLoading;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Warning = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1784 2.27883C13.6885 2.53387 14.1021 2.94747 14.3571 3.45754L21.7212 18.1856C22.3721 19.4876 21.8444 21.0708 20.5425 21.7217C20.1765 21.9047 19.7729 22 19.3638 22H4.63567C3.18003 22 2 20.82 2 19.3643C2 18.9552 2.09527 18.5516 2.27826 18.1856L9.64229 3.45754C10.2933 2.15558 11.8765 1.62785 13.1784 2.27883ZM11.2372 4.47154L4.09002 18.766C4.03082 18.8844 4 19.0149 4 19.1473C4 19.6182 4.38176 20 4.85269 20H19.1471C19.2795 20 19.4101 19.9692 19.5285 19.91C19.9497 19.6994 20.1204 19.1872 19.9098 18.766L12.7626 4.47154C12.6801 4.30652 12.5463 4.17272 12.3812 4.09021C11.96 3.8796 11.4478 4.05033 11.2372 4.47154ZM12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16ZM12 8C12.5523 8 13 8.44772 13 9V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V9C11 8.44772 11.4477 8 12 8Z"
      fill="#353549"
    />
  </svg>
);

Warning.displayName = "WarningIcon";

export default Warning;

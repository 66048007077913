import "./table-row.scss";

import classNames from "classnames";
import React from "react";

import { RowContext } from "./Context/RowContext";

type TableRowProps = React.PropsWithChildren<{
  rowIndex: number;
  isHighlighted?: boolean;
  onClick?: () => void;
  extraClassName?: string;
}>;

const TableRow = (props: TableRowProps) => (
  <RowContext.Provider
    value={{
      rowIndex: props.rowIndex,
    }}
  >
    <tr
      className={classNames("generic-table-row", {
        [props.extraClassName as string]: props.extraClassName,
      })}
      onClick={props.onClick}
    >
      {props.children}
    </tr>
  </RowContext.Provider>
);

export default TableRow;

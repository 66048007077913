import { cn } from "@themis/ui";
import React from "react";

import type { DashboardComponent } from "@/api";

export default function CloseRecords({
  widgetData,
}: {
  widgetData: DashboardComponent["data"];
}) {
  const [total_close, operator, total_records] =
    widgetData?.value?.split(" ") || [];

  const isEmptyState = total_close === "0" && total_records === "0";

  return (
    <div className="tw-flex tw-h-[260px] tw-min-w-[320px] tw-flex-grow tw-basis-0 tw-flex-col tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[10px] tw-shadow-widget">
      <div className="tw-flex  tw-w-full">
        <div className="tw-grow tw-text-sm tw-font-semibold tw-text-neutral-500">
          Close Records
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-2">
        <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
            <div
              className={cn(
                "tw-flex tw-items-baseline tw-text-6xl tw-font-semibold",
                {
                  "tw-text-neutral-500": !isEmptyState,
                  "tw-text-neutral-200": isEmptyState,
                },
              )}
            >
              <div className="tw-relative tw-text-7xl">{total_close}</div>
              <div className="tw-relative tw-text-3xl tw-tracking-wide tw-text-neutral-300">
                {operator}
              </div>
              <div
                className={cn("tw-relative tw-text-3xl tw-tracking-wide", {
                  "tw-text-neutral-500": !isEmptyState,
                  "tw-text-neutral-200": isEmptyState,
                })}
              >
                {total_records}
              </div>
            </div>
            <div className="tw-pt-4 tw-text-sm tw-font-medium tw-text-neutral-300">
              Close Records
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

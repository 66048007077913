import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/check-icon.svg";
import unlockIcon from "../../../images/unlock.svg";

function QaTestDetailViewHeaderButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const recordVersionID = parseInt(
    window.location.pathname.split("/qa-testing/")[1].split("/")[0],
    10,
  );
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const verifierID = mainStore.avroSchemas.valueForField(
    "validator",
    recordVersion?.data,
  )?.verifier_id;
  const isValidated = Boolean(verifierID);

  // Functions
  function reopen() {
    // @ts-expect-error TS(2339) FIXME: Property 'qaTests' does not exist on type 'MainSto... Remove this comment to see the full error message
    mainStore.qaTests.reopenQaRecord(recordVersionID);
  }

  function approve() {
    // @ts-expect-error TS(2339) FIXME: Property 'qaTests' does not exist on type 'MainSto... Remove this comment to see the full error message
    mainStore.qaTests.validateQaRecord(recordVersionID);
  }

  const renderButton = () => {
    if (isValidated) {
      return (
        <button className="table-button unlock" onClick={reopen}>
          <img src={unlockIcon} alt="unlock-icon" />
          Reopen
        </button>
      );
    }

    return (
      <button className="table-button approve" onClick={approve}>
        <img src={checkIcon} alt="upload-icon" />
        Validate
      </button>
    );
  };

  return (
    <div className="header-action approve-button-container">
      {renderButton()}
    </div>
  );
}

export default observer(QaTestDetailViewHeaderButton);

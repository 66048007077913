import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

interface Props {
  recordName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendTrigger: (...args: any[]) => any;
}

function ShareToCW({ recordName, sendTrigger }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { collaborativeWorkspaces } = mainStore.workspaces;

  function sendRecord() {
    if (!collaborativeWorkspaces.length) {
      return;
    }
    sendTrigger();
  }

  const liClasses = classNames({
    disabled: !collaborativeWorkspaces.length,
  });

  return (
    <li
      data-testid="send-to-cw-button"
      onClick={sendRecord}
      className={liClasses}
    >
      Send {recordName} to Collaborative Workspace
    </li>
  );
}

export default observer(ShareToCW);

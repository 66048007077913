import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class DefaultApproversStore {
  mainStore: MainStore;

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;
  }

  // POST /api/react/module_configurations/:module_configuration_id/default_approvers
  async create(userID: number, moduleConfigurationID: number) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/module_configurations/${moduleConfigurationID}/default_approvers`,
        headers: this.mainStore.getHeaders(),
        data: {
          user_id: userID,
          module_configuration_id: moduleConfigurationID,
        },
      });
    } catch (error) {
      window.console.log(`"default_approvers#create" error ${error}`);
    }
  }

  // DELETE /api/react/default_approvers/:id
  async delete(id: number) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/default_approvers/${id}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"default_approvers#delete" error ${error}`);
    }
  }

  // Store Helpers
  cleanup() {}
}

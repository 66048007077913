import React from "react";
import { NavLink } from "react-router-dom";

import { pathMatchesPattern } from "@/utils/routing";

import SidebarTag from "../SidebarTag";

interface NavigationLinkProps {
  url: string;
  basePath?: string;
  name: string;
  badge?: boolean;
  moduleIdentifier: string;
}

export function NavigationLink(props: NavigationLinkProps) {
  return (
    <li key={props.url}>
      <NavLink
        to={props.url}
        data-testid={`${props.moduleIdentifier.replace(/_/g, "-")}-nav`}
        isActive={(match, location) => {
          if (location.pathname.startsWith(props.url)) {
            return true;
          } else if (
            props.basePath &&
            pathMatchesPattern(location.pathname, props.basePath)
          ) {
            return true;
          }
          return false;
        }}
      >
        <div className="flex">
          <span>{props.name}</span>
          {props.badge && <SidebarTag>Beta</SidebarTag>}
        </div>
      </NavLink>
    </li>
  );
}

import classNames from "classnames";
import { lowerCase } from "lodash";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import type { COLOR } from "@/config/theme";

import arrowDownIcon from "../../../images/table-image/icon/arrow-down-black.svg";
import { Typography } from "../../Elements";

interface Props {
  color?: keyof typeof COLOR;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: (...args: any[]) => any;
  isDisabled?: boolean;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any[];
  testId?: string;
  tooltip?: string;
  value?: string;
  canSelectSameOption?: boolean;
}

function SingleSelectField({
  isDisabled,
  testId,
  value,
  handleChange,
  options,
  color,
  tooltip,
  label,
  canSelectSameOption = true,
}: Props) {
  // State
  const [open, setOpen] = useState(false);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const onChange = (option) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleChange(option);
    setOpen(false);
  };

  return (
    <div>
      <Typography
        size="sm"
        color="generalDarkGray"
        data-tooltip-select={tooltip}
        label={label}
        weight="semiBold"
      />
      <Popup
        position="bottom right"
        trigger={
          <button
            disabled={isDisabled}
            className={classNames("reports-module-dropdown qa-select", {
              disabled: isDisabled,
              opened: open,
            })}
            data-testid={testId}
          >
            <div className="module-item">
              <Typography
                data-testid="selected-item"
                size="sm"
                color={color || "generalMidnightDark"}
                label={isDisabled ? "Please select column" : value}
                weight="semiBold"
              />
            </div>
            <img src={arrowDownIcon} alt="open" />
          </button>
        }
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        keepTooltipInside
      >
        <ul className="table-dropdown reports-dropdown-popup single-select">
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {options.map((option, index) => {
            const active =
              value === (option.name || option) ||
              lowerCase(value).includes(option.name);
            return (
              <li
                key={index}
                data-testid={option.name || option}
                className={classNames("flex", { active })}
                onClick={() =>
                  active && !canSelectSameOption
                    ? setOpen(false)
                    : onChange(option)
                }
              >
                {option.display_name || option}
              </li>
            );
          })}
        </ul>
      </Popup>
    </div>
  );
}

SingleSelectField.defaultProps = {
  options: [],
};

export default SingleSelectField;

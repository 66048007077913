import "./create-scoring-group-menu.scss";

import classNames from "classnames";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { AssessmentAPI } from "@/api/legacy/risk-assessment";
import { Button, Icon } from "@/components/Elements";
import TextInput from "@/components/form/TextInput";
import { useMainStore } from "@/contexts/Store";
import type { QuestionnaireRead } from "@/features/risk-assessment/types";

interface AddQuestionnaireToGroupMenuProps {
  questionnaires?: QuestionnaireRead[];
  assessmentId: number;
  defaultSelectedIds?: number[];
  onSearch: (value: string) => void;
  onClose: (refetch?: boolean) => void;
}

function AddQuestionnaireToGroupMenu(props: AddQuestionnaireToGroupMenuProps) {
  const mainStore = useMainStore();
  const { workspace_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
  }>();

  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
    number[]
  >(props.defaultSelectedIds || []);

  const submit = async () => {
    try {
      await AssessmentAPI.addQuestionnaires(
        Number(workspace_id),
        props.assessmentId,
        selectedQuestionnaires,
      );
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
    props.onClose(true);
  };

  const handleQuestionnaireSelect = (selected: boolean, id: number) => {
    if (selected) {
      setSelectedQuestionnaires((prev) => [...prev, id]);
    } else {
      setSelectedQuestionnaires((prev) => prev.filter((pid) => pid !== id));
    }
  };
  return (
    <div className="create-scoring-group-menu">
      <div className="section title">
        <p>Add New Questionnaire</p>
        <Icon name="close" size="lg" onClick={() => props.onClose()} />
      </div>
      <div className="section group-name-input">
        <p>Select Questionnaires based from this Methodology</p>
        <TextInput
          placeholder="- Search -"
          name="filterInput"
          inputRef={null}
          onChange={(e) => props.onSearch(e.target.value)}
        />
      </div>
      <div className="section item-select">
        {props.questionnaires?.length === 0 && (
          <p className="emptystate">
            You do not have any questionnaires under this Risk Methodology
          </p>
        )}
        {props.questionnaires &&
          props.questionnaires.map((questionnaire) => (
            <label
              key={`risk-method-checkbox-select-${questionnaire.id}`}
              className={classNames("checkbox", {
                "option-selected": selectedQuestionnaires?.includes(
                  questionnaire.id,
                ),
              })}
            >
              <input
                name="checkbox"
                type="checkbox"
                checked={selectedQuestionnaires?.includes(questionnaire.id)}
                disabled={props.defaultSelectedIds?.includes(questionnaire.id)}
                onChange={(event) =>
                  handleQuestionnaireSelect(
                    event.target.checked,
                    questionnaire.id!,
                  )
                }
              />
              <span
                className="tw-overflow-hidden tw-truncate"
                data-tooltip-id="tooltip"
                data-tooltip-content={questionnaire.name}
                data-tooltip-place="left"
                data-tooltip-offset={25}
              >
                {questionnaire.name}
              </span>
            </label>
          ))}
      </div>
      <div className="section ">
        <Button
          label="Confirm"
          disabled={
            selectedQuestionnaires.filter(
              (sid) => !props.defaultSelectedIds?.includes(sid),
            ).length === 0
          }
          onClick={submit}
        />
      </div>
    </div>
  );
}

export default AddQuestionnaireToGroupMenu;

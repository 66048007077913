import React from "react";

import { Button, Flex, Typography } from "../../components/Elements";

interface TableInputGroupProps {
  title: string;
  addNewInputText?: string;
  dataTestID?: string;
  children: React.ReactNode;
  description?: string;
  addNewInput?: () => void;
  saveRatings: () => void;
  showAddNew: boolean;
  hasError: boolean;
}

function TableInputGroup({
  title,
  addNewInputText,
  description,
  dataTestID,
  addNewInput,
  children,
  saveRatings,
  showAddNew,
  hasError,
}: TableInputGroupProps) {
  return (
    <div className="table-input-group">
      <Flex justifySpaceBetween alignEnd fullWidth data-testid={dataTestID}>
        <div>
          <Typography
            label={title}
            size="lg"
            color="generalMidnightDark"
            weight="semiBold"
            data-testid={`${dataTestID}-title`}
          />
          <Typography
            label={description}
            size="sm"
            weight="semiBold"
            data-testid={`${dataTestID}-desc`}
          />
        </div>

        <Button
          className="input-group-row__button"
          data-testid={`${dataTestID}-save`}
          label="Save"
          onClick={saveRatings}
          disabled={hasError}
        />
      </Flex>
      <div className="table-input-group__list">{children}</div>
      {showAddNew && addNewInputText && (
        <>
          <div style={{ paddingTop: "10px" }} />
          <Button
            className="input-group-row__button"
            data-testid={`${dataTestID}-add-new`}
            label={addNewInputText}
            onClick={addNewInput}
          />
        </>
      )}
    </div>
  );
}

export default TableInputGroup;

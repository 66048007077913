import "./questionnaire-groups.scss";

import classNames from "classnames";
import type { ReactNode } from "react";
import React, { useState } from "react";

import { Icon, Typography } from "@/components/Elements";
import { createNewGroup } from "@/stores/helpers/RiskAssessmentHelpers";

import type { QuestionGroup } from "../../../types/questionnaire";
import type { FormQuestionGroup } from "../../../types/questionnaire-form";
import QuestionnaireGroup from "./QuestionnaireGroup";

interface Props {
  groups: Array<QuestionGroup | FormQuestionGroup>;
  activeGroupId: number;
  onActiveGroupChanged: (groupId: number) => void;
  onNameChange?: (groupId: number, name: string) => void;
  onDelete?: (groupId: number) => void;
  onGroupAdded?: (group: QuestionGroup) => void;
  canEdit?: boolean;
  className?: string;
  GroupsHeader?: ReactNode;
}

export default function QuestionnaireGroups(props: Props) {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className={classNames("questionnaire-groups", props.className)}>
      <div
        className={classNames(
          "questionnaire-groups__header",
          !props.GroupsHeader && "questionnaire-groups__header-default",
        )}
      >
        {props.GroupsHeader || (
          <Typography
            label="Sections"
            color="generalMidnightDark"
            weight="semiBold"
          />
        )}
        {props.canEdit && (
          <Icon
            className="questionnaire-groups__edit-button"
            name={isEditing ? "check" : "edit"}
            onClick={() => setIsEditing(!isEditing)}
          />
        )}
      </div>
      <div className="questionnaire-groups__list">
        {props.groups.map((group) => (
          <QuestionnaireGroup
            key={group.id}
            isActive={group.id === props.activeGroupId}
            group={group}
            isEditing={isEditing}
            onSelect={props.onActiveGroupChanged}
            onDelete={props.groups.length > 1 ? props.onDelete : undefined}
            // @ts-expect-error TS(2322) FIXME: Type '((groupId: number, name: string) => void) | ... Remove this comment to see the full error message
            onNameChange={props.onNameChange}
            isDroppable={props.canEdit}
          />
        ))}
      </div>
      {props.canEdit && (
        <div className="questionnaire-groups__add-section">
          <button
            onClick={() =>
              // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              props.onGroupAdded(
                createNewGroup(
                  `Section ${props.groups.length + 1}`,
                  props.groups.length + 1,
                ),
              )
            }
          >
            <Icon name="plus" color="brandingHighlightViolet" />
            <Typography
              label="Add Section"
              color="brandingHighlightViolet"
              size="sm"
            />
          </button>
        </div>
      )}
    </div>
  );
}

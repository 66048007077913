import {
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiDotsThreeOutlineFill, PiPlaceholderBold } from "react-icons/pi";
import { Link, useParams } from "react-router-dom";

import type { Account, FieldOption } from "@/api";
import { useDeleteAccount } from "@/api/queries/accounts";

import { AccountTypesTags } from "./account-type-tags/account-types-tags";
import { ActiveTag } from "./ActiveTag";

export function AccountCard({
  id,
  isActive,
  logoURL,
  name,
  accountTypes,
  accountTypeFieldOptions,
  linkTo = "",
}: {
  id: number;
  isActive: boolean;
  logoURL?: string;
  name: string;
  accountTypes: Account["account_types"];
  accountTypeFieldOptions: FieldOption[];
  linkTo?: string;
}) {
  const toast = useToast();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const { mutate: deleteAccount } = useDeleteAccount({
    workspaceId: Number(workspace_id),
    onSuccess: () => {
      toast({
        content: "Account has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove account!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setIsConfirmationOpen(false);
    deleteAccount(id);
  }

  function handleClickDelete(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete(
    event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent,
  ) {
    event.stopPropagation();
    setIsConfirmationOpen(false);
  }

  return (
    <div className="tw-group tw-flex-col">
      <div className="tw-relative tw-h-40 tw-w-56 tw-cursor-pointer tw-rounded-xl tw-border tw-border-solid tw-border-neutral-100 tw-py-2.5 tw-transition-colors group-hover:tw-border-neutral-200">
        <Link
          to={linkTo}
          className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
        >
          <div className="tw-absolute tw-left-2 tw-top-2">
            {isActive && (
              <AccountTypesTags
                accountTypes={accountTypes}
                accountTypeFieldOptions={accountTypeFieldOptions}
                numberOfAccountTypesToShow={1}
              />
            )}
            {!isActive && <ActiveTag isActive={isActive} />}
          </div>
          {logoURL ? (
            <img
              alt={`${name} Account Logo`}
              className="tw-max-h-16 tw-max-w-40"
              src={logoURL}
            />
          ) : (
            <PiPlaceholderBold
              title="Logo Placeholder"
              className="tw-h-6 tw-w-6 tw-text-neutral-200"
            />
          )}
        </Link>
        <div className="tw-absolute tw-right-2 tw-top-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <IconButton
                color="transparent"
                size="sm"
                Icon={PiDotsThreeOutlineFill}
              />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleClickDelete}>
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <ConfirmationPopup
            anchor
            title="Delete Account"
            content="Actions are non-reversible. Are you sure you want to proceed?"
            open={isConfirmationOpen}
            onCancel={handleCancelDelete}
            onConfirm={handleDelete}
          />
        </div>
      </div>
      <Link
        to={linkTo}
        className="tw-mt-2 tw-block tw-text-center tw-text-base tw-font-semibold tw-text-neutral-500"
      >
        {name}
      </Link>
    </div>
  );
}

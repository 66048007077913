import "./Status.scss";

import { observer } from "mobx-react";
import React from "react";

import InputTable from "@/components/table/settings/columns/InputTable";
import { useMainStore } from "@/contexts/Store";

const COMPANY_SUPER_ADMIN_ACCESS_LEVEL = "company_super_admin";
const COMPANY_ADMIN_ACCESS_LEVEL = "company_admin";
const AWAITING_APPROVAL_STATUS = "Awaiting Approval";
const AWAITING_VERIFICATION_STATUS = "Awaiting Verification";

interface Props {
  canAuthenticateWithEmailAndPassword: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleReinvite: (...args: any[]) => any;
  handleStatusChange: (status: string) => void;
  name: string;
  displayName?: string;
  value?: string;
}

function Status({
  canAuthenticateWithEmailAndPassword,
  displayName,
  handleReinvite,
  handleStatusChange,
  name,
  value,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { user } = mainStore.users;
  const { company } = mainStore.companies;
  const isAdmin = user.roles?.find(
    (role) =>
      [COMPANY_SUPER_ADMIN_ACCESS_LEVEL, COMPANY_ADMIN_ACCESS_LEVEL].includes(
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        role.access_level,
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      ) && role.company_id === company?.id,
  );
  const clickToApprove = isAdmin && value === AWAITING_APPROVAL_STATUS;
  const toastText = `${
    displayName || "User"
  } will receive an email to complete their sign up.`;

  // Functions
  function handleApprove() {
    handleStatusChange(AWAITING_VERIFICATION_STATUS);
    mainStore.toast.setInfoText(toastText);
  }

  function handleReinviteClick() {
    handleReinvite();
    mainStore.toast.setInfoText(toastText);
  }

  // elements
  const renderCell = (
    <div className="no-hover-only user-settings-status-cell__container">
      <InputTable name={name} value={value || "N/A"} disabled />
    </div>
  );

  if (clickToApprove) {
    return (
      <div
        className="company-users-btn user-settings-status-cell__click-to-approve"
        onClick={handleApprove}
      >
        Click to Approve User
      </div>
    );
  }

  if (
    value === AWAITING_VERIFICATION_STATUS &&
    canAuthenticateWithEmailAndPassword
  ) {
    return (
      <div className="hover-container click-to-reinvite">
        <div
          className="hover-only company-users-btn"
          onClick={handleReinviteClick}
        >
          Click to Reinvite User
        </div>
        {renderCell}
      </div>
    );
  }

  return renderCell;
}

export default observer(Status);

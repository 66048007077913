import { action, makeObservable, observable } from "mobx";

import { formatErrors } from "@/utils/errors";

import type { MainStore } from "../Main";

export default class ToastStore {
  // @ts-expect-error TS(2564) FIXME: Property 'icon' has no initializer and is not defi... Remove this comment to see the full error message
  icon: string; // this should be keyof typeof THEMIS_ICONS but required change to toast.tsx
  mainStore: MainStore;
  text = "";
  timeout = 5000;
  newVersion = false;
  // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Timeout'.
  timeoutId: ReturnType<typeof setTimeout> = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      text: observable,
      timeout: observable,
      newVersion: observable,
      setText: action,
      setInfoText: action,
      setErrorText: action,
      setTimeout: action,
      setNewVersion: action,
    });

    this.mainStore = mainStore;
  }

  setNewVersion() {
    this.newVersion = true;

    this.setTimer(() => (this.newVersion = false));
  }

  setText(value: string) {
    this.text = value;
    this.icon = "thumbs-up";

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.setTimer(() => (this.text = ""));
  }

  setInfoText(value: string, showThumbsUp = false) {
    if (showThumbsUp) {
      this.icon = "thumbs-up-color";
    } else {
      this.icon = "info";
    }

    this.text = value;

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.setTimer(() => (this.text = ""));
  }

  setErrorText(value: string) {
    if (value) {
      this.text = value;
    } else {
      this.text =
        "Something went wrong. Please try again or contact support@themis.com";
    }

    this.icon = "warning-icon";

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.setTimer(() => (this.text = ""));
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
  setErrorFromResponse(response, defaultMessage = "") {
    const errorMessage =
      formatErrors(
        response?.data?.errors || response?.response?.data?.errors,
      ) || defaultMessage;
    this.setErrorText(errorMessage);
  }

  setTimeout(value: number) {
    this.timeout = value;
  }

  clearTimeoutId() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  setTimer(callback: () => void) {
    this.clearTimeoutId();
    this.timeoutId = setTimeout(callback, this.timeout);
  }

  // Store Helpers

  cleanup() {
    this.newVersion = false;
  }
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Refresh = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0043 10C15.451 10 15 9.55228 15 9C15 8.44386 15.4496 8 16.0043 8H17.6855C16.159 5.96688 14.3334 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.5403 19 18.5121 16.3567 18.9459 12.8755C19.0142 12.3275 19.5138 11.9386 20.0618 12.0068C20.6099 12.0751 20.9988 12.5748 20.9305 13.1228C20.3724 17.6022 16.5523 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C14.861 3 17.1555 4.16101 19 6.4366V5.00427C19 4.45098 19.4477 4 20 4C20.5561 4 21 4.44962 21 5.00427C21 5.00427 21 8.99716 21 9C21 9.27736 20.8882 9.52679 20.7057 9.70675C20.5249 9.88783 20.2754 10 19.9957 10H16.0043Z"
      fill="#353549"
    />
  </svg>
);

Refresh.displayName = "RefreshIcon";

export default Refresh;

import { clsx } from "clsx";
import omit from "lodash/omit";
import { extendTailwindMerge } from "tailwind-merge";
// tailwind-merge was not working correctly because we did not include the prefix.
var twMerge = extendTailwindMerge({
    prefix: "tw-",
});
export function cn() {
    var inputs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        inputs[_i] = arguments[_i];
    }
    return twMerge(clsx(inputs));
}
export function getInitials(name) {
    return name
        .split(" ")
        .map(function (n) { return n[0]; })
        .join("");
}
export function filterCellProps(props) {
    // List of props to omit
    var omittedProps = [
        "api",
        "columnApi",
        "context",
        "node",
        "column",
        "rowIndex",
        "cellStartedEdit",
        "charPress",
        "eventKey",
        "valueFormatted",
        "getValue",
        "setValue",
        "formatValue",
        "initialValue",
        "parseValue",
        "refreshCell",
        "colDef",
        "eGridCell",
        "eParentOfValue",
        "registerRowDragger",
        "setTooltip",
        "stopEditing",
        "onValueChange",
        "selected",
        "onSelect",
        "useFormatter",
        "initialValue",
    ];
    return omit(props, omittedProps);
}
export function handleDownload(url, fileName) {
    fetch(url)
        .then(function (response) { return response.blob(); })
        .then(function (blob) {
        var localUrl = window.URL.createObjectURL(new Blob([blob]));
        var link = document.createElement("a");
        link.style.display = "none";
        link.href = localUrl;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    });
}

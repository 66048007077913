import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { useWorkspacesModulesList } from "@/api/queries/moduleWorkspaces";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";

import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";
import { getWorkspaceLogo } from "../../helpers/getWorkspaceLogo";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emailSlideClose?: (...args: any[]) => any;
  /**
   * If true, only show workspaces with the Monitoring & Testing module added
   * to the workspace. Only allow for sharing to a single workspace.
   */
  isFromMonitoringAndTesting?: boolean;
  recordName?: string;
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendFunction?: (...args: any[]) => any;
}

function SendRecordVersion({
  emailSlideClose,
  isFromMonitoringAndTesting,
  recordVersionID,
  recordName,
  sendFunction,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [selectedRecordVersionIDs, setSelectedRecordVersionIDs] = useState<
    number[]
  >([]);
  const [selectedWorkspaceIDs, setSelectedWorkspaceIDs] = useState<number[]>(
    [],
  );

  const collaborativeWorkspaceIds =
    mainStore.workspaces.collaborativeWorkspaces.map(
      (workspace) => workspace.id,
    );

  const workspacesModulesList = useWorkspacesModulesList(
    isFromMonitoringAndTesting ? collaborativeWorkspaceIds : [],
  );

  const isPendingWorkspacesModulesList = workspacesModulesList.some(
    (workspace) => workspace.isPending,
  );

  useEffect(() => {
    // Copy selection from DynamicTable store
    const newSelection =
      mainStore.dynamicTable.selectedRecordVersionIDs.slice();

    // Add single selection if any
    if (recordVersionID) {
      newSelection.push(recordVersionID);
    }

    setSelectedRecordVersionIDs(newSelection);
  }, [recordVersionID, mainStore.dynamicTable.selectedRecordVersionIDs]);

  function onSend() {
    if (!selectedWorkspaceIDs) {
      return;
    }

    if (!sendFunction) {
      mainStore.recordVersions.send(recordVersionID, selectedWorkspaceIDs);

      return;
    }

    sendFunction(recordVersionID, selectedWorkspaceIDs);
    emailSlideClose?.();
  }

  function handleClickWorkspace(id: number) {
    if (isFromMonitoringAndTesting) {
      setSelectedWorkspaceIDs([id]);

      return;
    }

    if (selectedWorkspaceIDs.includes(id)) {
      setSelectedWorkspaceIDs(
        selectedWorkspaceIDs.filter((workspaceId) => workspaceId !== id),
      );

      return;
    }

    setSelectedWorkspaceIDs([...selectedWorkspaceIDs, id]);
  }

  const buttonDisabled = !selectedWorkspaceIDs.length;

  const selectedRecordVersions = mainStore.recordVersions.list
    .filter((recordVersion) =>
      selectedRecordVersionIDs.includes(recordVersion.id),
    )
    .map((recordVersion) => {
      const description = mainStore.avroSchemas.valueForField(
        "description",
        recordVersion.data,
      );

      return (
        <div key={recordVersion.id} className="table-email-element">
          {recordVersion.meta?.name || description}
        </div>
      );
    });

  const renderWorkspaces = mainStore.workspaces.collaborativeWorkspaces
    .filter((_, index) => {
      if (!isFromMonitoringAndTesting) {
        return true;
      }

      const isMonitoringAndTestingModuleAdded = workspacesModulesList[
        index
      ].data?.find(
        (module) => module.themis_module.identifier === "qa_tests_development",
      )?.added;

      return isMonitoringAndTestingModuleAdded;
    })
    .map((collaborativeWorkspace) => {
      const { logo, isInternal } = getWorkspaceLogo(collaborativeWorkspace);

      return (
        <li
          data-testid={`send-workspace-block-${collaborativeWorkspace.id}`}
          aria-labelledby={`workspace-${collaborativeWorkspace.id}`}
          key={collaborativeWorkspace.id}
          className={classNames("tw-cursor-pointer", {
            active: selectedWorkspaceIDs.includes(collaborativeWorkspace.id),
          })}
          onClick={() => {
            handleClickWorkspace(collaborativeWorkspace.id);
          }}
        >
          <img src={logo} alt="image" />
          <div className="creative-block">
            <h3
              role="presentation"
              id={`workspace-${collaborativeWorkspace.id}`}
            >
              {isInternal
                ? collaborativeWorkspace.company.name
                : collaborativeWorkspace.name}
            </h3>
            <span>{collaborativeWorkspace.members_count} members</span>
          </div>
        </li>
      );
    });

  return (
    <div className="table-dropdown table-email-dropdown">
      <div className="email-dropdown-head">
        <h3>Send {recordName}</h3>
        <div onClick={emailSlideClose}>
          <img src={closeIcon} alt="close-icon" />{" "}
        </div>
      </div>
      {selectedRecordVersions.length > 0 && (
        <div className="table-email-single-select">
          <div className="table-email-block">{selectedRecordVersions}</div>
        </div>
      )}
      <hr />
      {isPendingWorkspacesModulesList ? (
        <Loading loadingLayout="table-no-add-new" />
      ) : (
        <div className="email-dropdown-wrap">
          <div className="creative-wrap">
            <h4>
              {renderWorkspaces.length ? (
                <>
                  Select a Collaborative Workspace below to send{" "}
                  <blockquote>{recordName}</blockquote>
                </>
              ) : (
                "No Collaborative Workspace available to send to"
              )}
            </h4>
            <ul>{renderWorkspaces}</ul>
          </div>
          <button
            className={classNames({ active: !buttonDisabled })}
            disabled={buttonDisabled}
            onClick={onSend}
            data-testid="send-button"
          >
            Send
          </button>
        </div>
      )}
      <hr />
    </div>
  );
}

export default observer(SendRecordVersion);

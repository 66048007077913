import React from "react";

const cursor = {
  both: "nwse-resize",
  vertical: "ns-resize",
  horizontal: "ew-resize",
};

// @ts-expect-error TS(7006) FIXME: Parameter 'elemRef' implicitly has an 'any' type.
export const useResize = (elemRef, options, originalWidth) => {
  const ref = elemRef || {};
  const { step = 1, axis = "both" } = options || {};
  const [coords, setCoords] = React.useState({ x: Infinity, y: Infinity });
  const [dims, setDims] = React.useState({ width: Infinity, height: Infinity });
  const [size, setSize] = React.useState({
    width: originalWidth,
    height: Infinity,
  });

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const initResize = (event) => {
    if (event.nativeEvent.which === 1) {
      if (!ref.current) {
        return;
      }
      setCoords({ x: event.clientX, y: event.clientY });
      const { width, height } = window.getComputedStyle(ref.current);
      setDims({ width: parseInt(width, 10), height: parseInt(height, 10) });
      document.body.classList.add("resize");
    }
  };

  React.useEffect(() => {
    // Round the size based to `props.step`.
    // @ts-expect-error TS(7006) FIXME: Parameter 'input' implicitly has an 'any' type.
    const getValue = (input) => Math.ceil(input / step) * step;

    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    const doDrag = (event) => {
      if (!ref.current) {
        return;
      }

      // Calculate the box size.
      const widthValue = getValue(dims.width + event.clientX - coords.x);
      const width = widthValue < 100 ? 100 : widthValue;
      const height = getValue(dims.height + event.clientY - coords.y);

      // Set the box size.
      if (axis === "both") {
        ref.current.style.width = `${width}px`;
        ref.current.style.height = `${height}px`;
      }
      if (axis === "horizontal") {
        ref.current.style.width = `${width}px`;
      }
      if (axis === "vertical") {
        ref.current.style.height = `${height}px`;
      }
      if (width && height) {
        setSize({ width, height });
      }
    };

    const stopDrag = () => {
      document.removeEventListener("mousemove", doDrag, false);
      document.removeEventListener("mouseup", stopDrag, false);
      document.body.classList.remove("resize");
    };

    document.addEventListener("mousemove", doDrag, false);
    document.addEventListener("mouseup", stopDrag, false);
  }, [dims, coords, step, ref, axis]);

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return { initResize, size, cursor: cursor[axis] };
};

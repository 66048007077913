import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { CloneObjectMutationRequest, RecordType } from "../gen";
import { cloneObject } from "../gen/axios/functionsController";

export function useCloneRecord({
  recordId,
  recordType,
  onSuccess = () => {},
  onError = () => {},
}: {
  recordId: number;
  recordType: RecordType;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (cloneObjectMutationRequest: CloneObjectMutationRequest) =>
      cloneObject(recordType, recordId, cloneObjectMutationRequest),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [recordType],
      });
      onSuccess();
    },
    onError,
  });
}

import classNames from "classnames";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import ExportBulk from "@/components/dashboard/ExportBulk";
import ImportBulk from "@/components/dashboard/ImportBulk";
import { useMainStore } from "@/contexts/Store";
import { useFilters } from "@/hooks/useFilters";
import { patternToRoute } from "@/utils/routing";

import {
  kriCompanyLibraryUrl,
  kriInputUrl,
  kriSummaryUrl,
  kriThemisLibraryUrl,
} from "../urlPaths";
import CopyToCompanyKRIsButton from "./CopyToCompanyKRIsButton";
import CopyToKRIsButton from "./CopyToKRIsButton";
import ImportKRILibraryButton from "./ImportKRILibraryButton";
import KeyRiskIndicatorsTabs from "./KeyRiskIndicatorsTabs";

const KRITabsHeader = () => {
  const mainStore = useMainStore();
  const { workspaceID, moduleWorkspaceID } = mainStore.context;

  const location = useLocation();

  const { visibleFields } = mainStore.fields;

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  const inSummaryPage =
    location.pathname ===
    patternToRoute(kriSummaryUrl, { workspace_id: workspaceID });

  const inInputPage =
    location.pathname ===
    patternToRoute(kriInputUrl, { workspace_id: workspaceID });

  const inThemisLibraryPage =
    location.pathname ===
    patternToRoute(kriThemisLibraryUrl, { workspace_id: workspaceID });

  const inCompanyLibraryPage =
    location.pathname ===
    patternToRoute(kriCompanyLibraryUrl, { workspace_id: workspaceID });

  const type = () => {
    if (inThemisLibraryPage) {
      return "themis";
    }
    return "company";
  };
  return (
    <>
      <div
        className={classNames("table-header-wrap", {
          "header-with-filters": filtersViewEnabled,
        })}
      >
        <KeyRiskIndicatorsTabs />
        <div className="import-export-buttons-container">
          {!inSummaryPage && !inThemisLibraryPage && !inCompanyLibraryPage && (
            <>
              <ExportBulk />
              <ImportBulk topLevelSection />
              {!inInputPage && filtersTrigger}
            </>
          )}

          {(inThemisLibraryPage || inCompanyLibraryPage) && (
            <CopyToKRIsButton
              type={type()}
              moduleWorkspaceID={moduleWorkspaceID}
            />
          )}

          {inCompanyLibraryPage && <ImportKRILibraryButton />}
          {inThemisLibraryPage && <CopyToCompanyKRIsButton />}
        </div>
      </div>

      {filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersContent}
        </div>
      )}
    </>
  );
};

export default observer(KRITabsHeader);

import React from "react";

import { Typography } from "@/components/Elements";
import { useIconMenuContext } from "@/components/IconMenu/context";

export interface IconMenuItemProps {
  nested?: boolean;
  label: string;
  onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

export function IconMenuItem(props: IconMenuItemProps) {
  const { setOpen } = useIconMenuContext();

  return (
    <div
      role="menuitem"
      data-testid={`dropdown-option-${props.label}`}
      className="dropdown__option"
      onClick={(event) => {
        if (!props.nested) {
          setOpen(false);
        }

        props.onClick(event);
      }}
    >
      <Typography
        label={props.label}
        size="sm"
        weight="semiBold"
        color="generalMidnightDark"
      />
    </div>
  );
}

import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "@/components/Elements";
import { getSelectedTab } from "@/components/helpers/Tabs";
import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useScrollToError } from "@/hooks/useScrollToError";
import { useTableData } from "@/hooks/useTableData";

import ExportBulk from "../../dashboard/ExportBulk";
import ImportBulk from "../../dashboard/ImportBulk";
import Loading from "../../Loading";
import SlideMenu from "../../slideMenu/SlideMenu";
import EmailAttest from "../policy/EmailAttest";
import CreateFirstCTA from "../shared/CreateFirstCTA";
import NewSectionTag from "../shared/NewSectionTag";
import SectionDragContext from "../shared/SectionDragContext";
import Table from "../Table";
import AttestationsList from "./AttestationsList";
import TrainingSection from "./TrainingSection";

const TABS = [
  {
    name: "All",
    key: "all",
    value: `/modules/training`,
    dataTestId: "training-tab-all-trigger",
  },
  {
    name: "Published",
    key: "published",
    value: `/modules/training/published`,
    dataTestId: "training-tab-published-trigger",
  },
  {
    name: "Attestations",
    key: "attestations",
    value: `/modules/training/attestations`,
    dataTestId: "training-tab-attestations-trigger",
  },
];
function Training() {
  const mainStore = useMainStore();

  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);

  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { visibleFields } = mainStore.fields;
  const recordVersions = mainStore.recordVersions.list;
  const { data } = mainStore.trainings;
  // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
  const moduleWorkspaceID = data?.module_workspace_id;
  const sectionTags = mainStore.sectionTags.orderedList;
  const { tableName, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;

  const history = useHistory();

  const selectedTab = getSelectedTab(TABS);

  function onTabClick(tab: string) {
    mainStore.trainings.index({
      workspaceID,
      tab,
    });
    history.push(`/workspaces/${workspaceID}${tab}`);
  }

  const isAllScreen = selectedTab?.key === "all";
  const isPublishedScreen = selectedTab?.key === "published";
  const isAttestationsScreen = selectedTab?.key === "attestations";

  const loading = useLoading(visibleFields);
  useScrollToError({ block: "center" });
  useTableData();

  const { filtersTrigger, filtersViewEnabled, filtersContent } = useFilters({
    fields: visibleFields,
  });

  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  function addNewSection() {
    setShowAddNewSection(!showAddNewSection);
  }

  const onSectionChange = (recordVersionId: string, sectionId: string) => {
    mainStore.creatives.updateSection(recordVersionId, sectionId);
  };

  return (
    <Table>
      {loading && <Loading loadingLayout="table" showTableHeader={false} />}

      {!loading && (
        <>
          <div className={classNames("table-header-wrap")}>
            <HeaderTabs
              tabs={TABS}
              selectedTab={selectedTab?.value || ""}
              onSelectTab={onTabClick}
              dataTestId="training-main-tabs"
            />
            <div className="import-export-buttons-container">
              <ExportBulk
                onActiveStateChange={
                  isAttestationsScreen ? setShowCheckbox : undefined
                }
                // @ts-expect-error TS(2339) FIXME: Property 'specific_statuses' does not exist on typ... Remove this comment to see the full error message
                specificStatuses={data?.specific_statuses}
                isAttestation={isAttestationsScreen}
              />
              {isAllScreen && <ImportBulk topLevelSection />}
              {isPublishedScreen && (
                <SlideMenu
                  closeSlideMenu={() => setShowCheckbox(false)}
                  trigger={
                    <Button
                      label="Send Email / Attestation"
                      size="sm"
                      fontWeight="medium"
                      disabled={isCurrentWorkspaceArchived}
                      onClick={() => setShowCheckbox(true)}
                    >
                      Send Email / Attestation
                    </Button>
                  }
                >
                  <EmailAttest
                    // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                    tableName={tableName}
                    moduleWorkspaceID={moduleWorkspaceID}
                    moduleIdentifier="training"
                    checkboxSelection
                  />
                </SlideMenu>
              )}
              {!isAttestationsScreen && filtersTrigger}
            </div>
          </div>
          {isAllScreen && (
            <AddRecordHeader
              recordName="Training"
              addRecord={() =>
                mainStore.trainings.create(Number(workspaceID), null)
              }
              addSection={addNewSection}
              canAddSection
            />
          )}
          {filtersViewEnabled && (
            <div className="filters-wrap">
              <div className="switch-table-wrap" />
              {filtersContent}
            </div>
          )}
          <div
            className="table-list-wrap table-training-wrap"
            data-testid={`training-table-${selectedTab?.key}`}
          >
            {isAttestationsScreen ? (
              <AttestationsList showCheckbox={showCheckbox} />
            ) : (
              <SectionDragContext onSectionChange={onSectionChange}>
                <TrainingSection
                  archived={isPublishedScreen}
                  isDropdownOpen
                  // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                  tableName={tableName}
                  fields={visibleFields}
                  recordVersions={recordVersions.filter(
                    (recordVersion) => !recordVersion.section_tag_id,
                  )}
                  moduleWorkspaceID={moduleWorkspaceID}
                  workspaceID={workspaceID}
                  isDragAndDrop
                  pageID={0}
                  showCheckbox={showCheckbox}
                  setShowCheckbox={setShowCheckbox}
                />

                {sectionTags.map((sectionTag) => {
                  const filteredRecordVersions = recordVersions.filter(
                    (recordVersion) =>
                      recordVersion.section_tag_id === sectionTag.id,
                  );
                  return (
                    <TrainingSection
                      key={sectionTag.id}
                      archived={isPublishedScreen}
                      isDropdownOpen
                      // @ts-expect-error TS(2322) FIXME: Type '"Drafts" | "Finalized" | "Archived" | "Polic... Remove this comment to see the full error message
                      tableName={tableName}
                      fields={visibleFields}
                      recordVersions={filteredRecordVersions}
                      moduleWorkspaceID={moduleWorkspaceID}
                      workspaceID={workspaceID}
                      isDragAndDrop
                      sectionTag={sectionTag}
                      pageID={sectionTag.id}
                      showCheckbox={showCheckbox}
                      setShowCheckbox={setShowCheckbox}
                    />
                  );
                })}

                {showAddNewSection && (
                  <NewSectionTag
                    moduleWorkspaceID={moduleWorkspaceID}
                    onCancel={() => setShowAddNewSection(false)}
                  />
                )}

                {canManageSections && isAllScreen && (
                  <div
                    className="tw-sticky tw-left-[25px] tw-ml-[25px]"
                    data-testid="training-add-new-section"
                  >
                    <AddNewSectionButton onClick={addNewSection} />
                  </div>
                )}
                {recordVersions.length < 3 && isAllScreen && (
                  <CreateFirstCTA subject="Training" />
                )}
              </SectionDragContext>
            )}
          </div>
          <div />
        </>
      )}
    </Table>
  );
}

Training.defaultProps = {
  defaultScreen: "All",
};

export default observer(Training);

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";

import Button from "@/components/form/Button";
import EmailInput from "@/components/form/EmailInput";
import TextInput from "@/components/form/TextInput";
import Header from "@/components/header/Header";
import Spinner from "@/components/table/shared/Spinner";
import { useMainStore } from "@/contexts/Store";
import uploadIcon from "@/images/upload-image/upload-icon.svg";

import AuthenticationSelect from "../components/AuthenticationSelect";
import CompanyNameAutocompleteInput from "../components/CompanyNameAutocompleteInput";

function CreateCompany() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [logo, setLogo] = useState(null);

  const [fullNameIsPresent, setFullNameIsPresent] = useState(false);
  const [companyNameIsPresent, setCompanyNameIsPresent] = useState(false);
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const [departmentTitle, setDepartmentTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Variables
  const { currentLogo } = mainStore.clearbit;

  // Effects
  useEffect(() => {
    const shouldEnableForm = fullNameIsPresent && companyNameIsPresent;
    setIsFormEnabled(shouldEnableForm);
  }, [fullNameIsPresent, companyNameIsPresent]);

  // Refs
  const fullNameRef: React.RefObject<HTMLInputElement> = React.createRef();
  const jobTitleRef: React.RefObject<HTMLInputElement> = React.createRef();
  const companyNameRef: React.RefObject<HTMLInputElement> = React.createRef();
  const companyWebsiteRef: React.RefObject<HTMLInputElement> =
    React.createRef();

  // Functions
  function handleTextChange() {
    if (fullNameRef.current) {
      setFullNameIsPresent(fullNameRef.current.value.length > 0);
    }
    if (companyNameRef.current) {
      setCompanyNameIsPresent(companyNameRef.current.value.length > 0);
    }
  }

  function showPreview(event: React.ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    const file = event.target.files && event.target.files[0];

    if (file) {
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'signedIds' implicitly has an 'any' type... Remove this comment to see the full error message
  function handleAttachment(signedIds) {
    setLogo(signedIds[0]);
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const params = {
      company: {
        logo,
        // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
        suggested_logo: currentLogo?.config?.url,
        name: companyNameRef.current?.value,
        website: companyWebsiteRef.current?.value,
      },
      role: {
        department_title: departmentTitle,
        job_title: jobTitleRef.current?.value,
      },
      user: {
        full_name: fullNameRef.current?.value,
      },
    };

    mainStore.companies.create(params);
    setIsLoading(true);
  }

  function handleAfterSelectSuggestion(companyName: string) {
    if (companyNameRef.current) {
      companyNameRef.current.value = companyName;
    }
    setImagePreviewUrl(null);
    setLogo(null);
  }

  return (
    <div className="upload-wrap">
      <div className="container">
        <Header createName="Enter Company Info" value={66} />
        <div className="upload-block">
          <DirectUploadProvider
            multiple={false}
            onSuccess={handleAttachment}
            render={({ handleUpload, uploads }) => (
              <div>
                <div className="upload">
                  <div className="upload-image">
                    {/* @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'. */}
                    {(imagePreviewUrl || currentLogo?.config) && (
                      <label htmlFor="upload-photo1">
                        <img
                          // @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'.
                          src={imagePreviewUrl || currentLogo?.config?.url}
                          alt="logo-preview"
                          className="upload-avatar"
                        />
                        <input
                          id="upload-photo1"
                          type="file"
                          name="logo"
                          accept="image/*"
                          onChange={(event) => {
                            event.preventDefault();
                            showPreview(event);
                            handleUpload(event.currentTarget.files);
                          }}
                        />
                      </label>
                    )}

                    {/* @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'. */}
                    {!imagePreviewUrl && !currentLogo?.config && (
                      <div className="upload-photo">
                        <label htmlFor="upload-photo2">
                          <span>
                            <img src={uploadIcon} alt="image" />
                          </span>
                        </label>
                        <input
                          id="upload-photo2"
                          type="file"
                          name="logo"
                          accept="image/*"
                          onChange={(event) => {
                            event.preventDefault();
                            showPreview(event);
                            handleUpload(event.currentTarget.files);
                          }}
                        />
                      </div>
                    )}

                    {/* @ts-expect-error TS(2339) FIXME: Property 'config' does not exist on type '{}'. */}
                    {imagePreviewUrl || currentLogo?.config ? (
                      <h4>Re-upload Company Icon</h4>
                    ) : (
                      <h4>Upload Company Icon</h4>
                    )}

                    {
                      // @ts-expect-error TS(7006) FIXME: Parameter 'upload' implicitly has an 'any' type.
                      uploads.map((upload) => {
                        switch (upload.state) {
                          case "waiting":
                            return (
                              <p key={upload.id}>
                                Waiting to upload {upload.file.name}
                              </p>
                            );
                          case "uploading":
                            return (
                              <p key={upload.id}>
                                Uploading {upload.file.name} :{" "}
                                {parseFloat(upload.progress).toFixed(2)}%
                              </p>
                            );
                          case "error":
                            return (
                              <p key={upload.id}>
                                Error uploading {upload.file.name} :{" "}
                                {upload.error}
                              </p>
                            );
                          case "finished":
                            return (
                              <p key={upload.id}>
                                Finished uploading {upload.file.name}
                              </p>
                            );
                          default:
                            return null;
                        }
                      })
                    }
                  </div>
                  <p>(Best to upload asset with min. 500x500 px)</p>
                </div>
              </div>
            )}
          />
          <form onSubmit={handleSubmit}>
            <EmailInput
              placeholder="Company Email Address"
              defaultValue={
                mainStore.users.user ? mainStore.users.user.email : ""
              }
              disabled
            />
            <p>
              For security purposes, you cannot change this email address until
              account has been officially created. This will also be the main
              contact email of this company.
            </p>
            <div className="upload-form-wrap">
              <div className="upload-form-block">
                <h4>Company Info</h4>
                <CompanyNameAutocompleteInput
                  inputRef={companyNameRef}
                  handleChange={handleTextChange}
                  handleAfterSelect={handleAfterSelectSuggestion}
                />
                <TextInput
                  name="company-website"
                  placeholder="Company Website (Optional)"
                  inputRef={companyWebsiteRef}
                />
                <TextInput
                  name="title"
                  placeholder="Your Role At Company (Optional)"
                  inputRef={jobTitleRef}
                  onChange={handleTextChange}
                />
              </div>
              <div className="upload-form-block">
                <h4>Personal Info</h4>
                <TextInput
                  name="full_name"
                  placeholder="Your Full Name"
                  inputRef={fullNameRef}
                  onChange={handleTextChange}
                />
                <AuthenticationSelect setDepartmentTitle={setDepartmentTitle} />
              </div>
            </div>
            {!isLoading ? (
              <Button title="Continue" enabled={isFormEnabled} />
            ) : (
              <div className="loading-your-company">
                <Spinner />
                Setting up your Company
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default observer(CreateCompany);

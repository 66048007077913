import axios from "axios";

import type {
  AssessmentIndex,
  GetCWQuestionnairesResponse,
  GetPartnersResponse,
  GetQuestionnairesResponse,
  RAPartner,
  RAPartnerDocument,
} from "@/features/risk-assessment";

import api from "../api";

export const PartnersAPI = {
  // GET /api/react/workspaces/:workspace_id/partners
  async list(
    workspaceId: number,
    signal: AbortSignal,
    sortParams: { direction?: string; field_name?: string } | null = null,
  ) {
    const body: {
      signal: AbortSignal;
      params?: {
        sort_field_name?: string;
        sort_direction?: string;
      };
    } = {
      signal,
    };
    if (sortParams) {
      body.params = {
        sort_field_name: sortParams.field_name,
        sort_direction: sortParams.direction,
      };
    }
    const response = await api.get<
      { signal: AbortSignal; table_filters: [] },
      { data: GetPartnersResponse }
    >(`/workspaces/${workspaceId}/partners`, body);
    return response.data;
  },
  // GET /api/react/workspaces/:workspace_id/partners/:partner_record_version_id/questionnaires
  async getQuestionnaires(
    workspaceId: number | string,
    partnerRecordVersionId: number | string,
    searchQuery?: string,
    page?: number,
    sortParams: { direction?: string; field_name?: string } | null = null,
  ) {
    const body: {
      params?: {
        sort_field_name?: string;
        sort_direction?: string;
        page?: number;
        limit?: number;
        name?: string;
      };
    } = {
      params: {
        page,
        name: searchQuery,
      },
    };

    if (sortParams) {
      body.params = {
        ...body.params,
        sort_field_name: sortParams.field_name,
        sort_direction: sortParams.direction,
      };
    }

    const response = await api.get<
      { table_filters: [] },
      {
        data: GetQuestionnairesResponse;
      }
    >(
      `/workspaces/${workspaceId}/partners/${partnerRecordVersionId}/questionnaires`,
      body,
    );
    return response.data;
  },
  // GET /api/react/workspaces/:workspace_id/partners/:partner_record_version_id/questionnaires
  async getCWQuestionnaires(
    workspaceId: number,
    partnerRecordVersionId: number,
    sortParams: { direction?: string; field_name?: string } | null = null,
  ) {
    const body: {
      params?: { sort_field_name?: string; sort_direction?: string };
    } = {};

    if (sortParams) {
      body.params = {
        sort_field_name: sortParams.field_name,
        sort_direction: sortParams.direction,
      };
    }
    const response = await api.get<
      { table_filters: [] },
      {
        data: GetCWQuestionnairesResponse;
      }
    >(
      `/workspaces/${workspaceId}/partners/${partnerRecordVersionId}/questionnaires`,
      body,
    );
    return response.data;
  },
  // POST /api/react/workspaces/:workspace_id/partners?section_tag_id
  async create(
    moduleWorkspaceId: number,
    accountId: number,
    section_tag_id?: number,
  ) {
    const response = await api.post(
      `/workspaces/${moduleWorkspaceId}/partners`,
      {
        account_id: accountId,
      },
      {
        params: {
          section_tag_id: section_tag_id === 0 ? undefined : section_tag_id,
        },
      },
    );

    if (axios.isAxiosError(response)) {
      return response.response?.data;
    }

    return response.data as RAPartner;
  },

  // Delete /api/react/workspaces/:workspace_id/partners/:partner_id
  delete(workspaceId: number, recordVerionId?: number) {
    return api.delete(`/workspaces/${workspaceId}/partners/${recordVerionId}`);
  },

  // POST /api/react/workspaces/:workspace_id/partners/:partner_id/assessments
  async createAssessment(
    workspaceId: number | string,
    partnerId: number | string,
    name: string,
    riskMethodologyId: number,
  ) {
    const response = await api.post<
      { assessment_name: string; risk_methodology_id: number },
      { data: { assessment: AssessmentIndex } }
    >(`/workspaces/${workspaceId}/partners/${partnerId}/assessments`, {
      assessment_name: name,
      risk_methodology_id: riskMethodologyId,
    });
    return response.data.assessment;
  },
  async attachAssessmentTemplate(
    workspaceId: number,
    partnerId: number | string,
    templateId: number,
    isPasswordProtected: boolean,
  ) {
    const response = await api.post<
      { assessment_template_id: number; password_protected: boolean },
      { data: { assessment: AssessmentIndex } }
    >(`/workspaces/${workspaceId}/partners/${partnerId}/assessments`, {
      assessment_template_id: templateId,
      password_protected: isPasswordProtected,
    });
    return response.data.assessment;
  },
  // GET /api/react/workspaces/:workspace_id/partners/:id/documents
  async listDocuments(
    workspaceId: number,
    partnerId: number | string,
    sortParams: { field_name: string; direction: SortOptions } | null = null,
  ) {
    let body:
      | {
          params?: { sort_by: { [key: string]: SortOptions } };
        }
      | undefined;
    if (sortParams) {
      body = {
        params: {
          sort_by: {
            [sortParams.field_name]: sortParams?.direction,
          },
        },
      };
    }
    const response = await api.get<
      undefined,
      { data: { documents: RAPartnerDocument[] } }
    >(`/workspaces/${workspaceId}/partners/${partnerId}/documents`, body);
    return response.data.documents;
  },

  async movePartner(
    workspaceId: number,
    sectionTagID: number,
    partnerId: number,
  ) {
    const data = { section_tag_id: sectionTagID, identifier: "policy" };
    await api.put(
      `/workspaces/${workspaceId}/partners/${partnerId}/update_section`,
      data,
    );
  },

  async exportPartnerDocuments(workspaceID: number, partnerID: number) {
    const result = await api.get(
      `/workspaces/${workspaceID}/partners/${partnerID}/documents/download_documents`,
    );
    return result.data;
  },

  async exportPartnerDocumentTable(workspaceID: number, partnerID: number) {
    const result = await api.get(
      `/workspaces/${workspaceID}/partners/${partnerID}/documents/download_document_list`,
    );
    return result.data;
  },

  async updatePartnerDocumentName(
    workspaceID: number,
    partnerID: number,
    documentID: number,
    documentName: string,
  ) {
    const result = await api.put(
      `/workspaces/${workspaceID}/partners/${partnerID}/documents/${documentID}`,
      { partner_document: { document_identifier: documentName } },
    );
    return result.data;
  },
  // GET /api/react/workspaces/:workspace_id/partners/:id/export_partner_pdf
  async generatePartnerPDFReportRA(
    workspaceId: number,
    recordVerionId?: number,
    checkboxCheckedPartner?: boolean,
    checkboxCheckedEnhanced?: boolean,
    checkboxCheckedDocuments?: boolean,
  ) {
    const params = {};
    if (checkboxCheckedPartner) {
      // @ts-expect-error TS(2339) FIXME: Property 'checkboxCheckedPartner' does not exist on type '{}'... Remove this comment to see the full error message
      params.partner_questionnaire_report = checkboxCheckedPartner;
    }

    if (checkboxCheckedEnhanced) {
      // @ts-expect-error TS(2339) FIXME: Property 'checkboxCheckedEnhanced' does not exist on type '{}'... Remove this comment to see the full error message
      params.enhanced_questionnaire_report = checkboxCheckedEnhanced;
    }

    if (checkboxCheckedDocuments) {
      // @ts-expect-error TS(2339) FIXME: Property 'checkboxCheckedDocuments' does not exist on type '{}'... Remove this comment to see the full error message
      params.documents_questionnaire_report = checkboxCheckedDocuments;
    }

    const fullParams = Object.keys(params)
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.... Remove this comment to see the full error message
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");

    const response = await api.get(
      `/workspaces/${workspaceId}/partners/${recordVerionId}/export_partner_pdf${
        fullParams ? `?${fullParams}` : ""
      }`,
      {
        responseType: "arraybuffer",
      },
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/pdf",
    });
    const filename = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .replace(/"/g, "");
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename || "assessment_partner_export_pdf.pdf";
    link.click();
  },
};

import "./partners-tabs.scss";

import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { Button } from "@/components/Elements";
import { getSelectedTab } from "@/components/helpers/Tabs";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import { useMainStore } from "@/contexts/Store";

import { ROUTES } from "../pages";
import type { QuestionnaireRead } from "../types";
import PopupMenu from "./PopupMenu/PopupMenu";
import PopupMenuItem from "./PopupMenu/PopupMenuItem";

interface PartnerTabsProps {
  recordVersionId: string;
  questionnaireTemplates?: Array<QuestionnaireRead>;
  onAddNewScoringGroupClick?: () => void;
}

function PartnerTabs(props: PartnerTabsProps) {
  const mainStore = useMainStore();
  const history = useHistory();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const basePath = `${ROUTES.BASE_PATH}/${props.recordVersionId}`;
  const TABS = [
    {
      name: "Partner Info",
      key: "",
      value: generatePath(ROUTES.PARTNERS_DETAILS_EDIT_PATH, {
        record_version_id: props.recordVersionId,
        workspace_id,
      }),
    },
    {
      name: "Questionnaires",
      key: "questionnaires",
      value: generatePath(ROUTES.PARTNERS_QUESTIONNAIRES_PATH, {
        record_version_id: props.recordVersionId,
        workspace_id,
      }),
    },
    {
      name: "Scoring Group",
      key: "scoring",
      value: generatePath(ROUTES.PARTNERS_SCORING_PATH, {
        record_version_id: props.recordVersionId,
        workspace_id,
      }),
    },
    {
      name: "Documents",
      key: "documents",
      value: generatePath(ROUTES.PARTNERS_DOCUMENTS_PATH, {
        record_version_id: props.recordVersionId,
        workspace_id,
      }),
    },
  ];

  const isPartnerInfoMatch = Boolean(
    useRouteMatch({ path: basePath, exact: true }),
  );
  const isQuestionnairesMatch = Boolean(
    useRouteMatch({
      path: generatePath(ROUTES.PARTNERS_QUESTIONNAIRES_PATH, {
        workspace_id,
        record_version_id: props.recordVersionId,
      }),
    }),
  );
  const isScoringMatch = Boolean(
    useRouteMatch({
      path: generatePath(ROUTES.PARTNERS_SCORING_PATH, {
        workspace_id,
        record_version_id: props.recordVersionId,
      }),
    }),
  );
  const recordVersion =
    isPartnerInfoMatch &&
    mainStore.recordVersions.list.find(
      (rv) => rv.id === Number(props.recordVersionId),
    );

  const [showAddQuestionnairePopupOpen, setShowAddQuestionnairePopupOpen] =
    React.useState(false);
  const [showQuestionnairePopupSubmenu, setShowQuestionnairePopupSubmenu] =
    React.useState(false);

  const handleAddQuestionnaireClick = () => {
    setShowAddQuestionnairePopupOpen(true);
  };

  return (
    <div className="ra-partners-dashboard">
      <div className="dashboard-group tw-h-full">
        <HeaderTabs
          tabs={TABS}
          selectedTab={getSelectedTab(TABS, true)?.value || ""}
          isLink
        />
      </div>
      <div className="dashboard-group">
        {isQuestionnairesMatch && (
          <PopupMenu
            anchorNode={
              <Button
                label="Add Questionnaire"
                size="de"
                theme={showAddQuestionnairePopupOpen ? "active" : "primary"}
                onClick={handleAddQuestionnaireClick}
              />
            }
            open={showAddQuestionnairePopupOpen}
            placement="bottom right"
            onClose={() => {
              setShowAddQuestionnairePopupOpen(false);
              setShowQuestionnairePopupSubmenu(false);
            }}
            onGoBack={() => setShowQuestionnairePopupSubmenu(false)}
            goBackHeaderLabel="Select from Template"
            showSubMenu={showQuestionnairePopupSubmenu}
            mainMenu={
              <>
                {props.questionnaireTemplates &&
                  props.questionnaireTemplates.length > 0 && (
                    <PopupMenuItem
                      label="Create from template"
                      onClick={() => setShowQuestionnairePopupSubmenu(true)}
                    />
                  )}
                <PopupMenuItem
                  label="Create from scratch"
                  onClick={() =>
                    history.push(
                      generatePath(ROUTES.PARTNERS_QUESTIONNAIRES_CREATE_PATH, {
                        record_version_id: props.recordVersionId,
                        workspace_id,
                      }),
                    )
                  }
                />
              </>
            }
            subMenu={props.questionnaireTemplates?.map((template) => {
              return (
                <PopupMenuItem
                  key={`assessment-template-${template.id}`}
                  label={template.name}
                  onClick={() =>
                    history.push(
                      generatePath(
                        ROUTES.PARTNERS_QUESTIONNAIRES_CREATE_WITH_TEMPLATE_PATH,
                        {
                          record_version_id: props.recordVersionId,
                          workspace_id,
                          template_id: template.id,
                        },
                      ),
                    )
                  }
                />
              );
            })}
          />
        )}
        {isScoringMatch && (
          <Button
            onClick={props.onAddNewScoringGroupClick}
            label="Add New Group"
          />
        )}

        {recordVersion && (
          <CommentsSlideMenu
            renderCommentsButton
            globalID={recordVersion.global_id}
            recordID={recordVersion.record_id}
            taskableType="Record"
            uncompletedCommentsCount={recordVersion.uncompleted_comments_count}
          />
        )}
      </div>
    </div>
  );
}

export default observer(PartnerTabs);

import "./partners-questionnaires.scss";

import { debounce } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import {
  AssessmentAPI,
  PartnersAPI,
  RiskMethodologyAPI,
} from "@/api/legacy/risk-assessment";
import Loading from "@/components/Loading";
import DashboardHeader from "@/components/shared/DashboardHeader";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useMainStore } from "@/contexts/Store";

import { ROUTES } from ".";
import FlexDashboardContent from "../components/FlexDashboardContent/FlexDashboardContent";
import FlexDashboardContentWrapper from "../components/FlexDashboardContentWrapper/FlexDashboardContentWrapper";
import AddQuestionnaireToGroupMenu from "../components/Partners/GroupScoring/AddQuestionnaireToGroupMenu";
import CreateScoringGroupMenu from "../components/Partners/GroupScoring/CreateScoringGroupMenu";
import GroupScoringEmptyState from "../components/Partners/GroupScoring/GroupScoringEmptyState";
import GroupScoringSection from "../components/Partners/GroupScoring/GroupScoringSection";
import RenameGroupMenu from "../components/Partners/GroupScoring/RenameGroupMenu";
import PartnerTabs from "../components/PartnerTabs";
import type {
  AssessmentIndex,
  QuestionnaireRead,
  RiskMethodology,
} from "../types";

const PartnersScoringPage = () => {
  const mainStore = useMainStore();
  const { workspaceID, activeWorkspace } = mainStore.context;

  const history = useHistory();
  const { record_version_id } = useParams<{ record_version_id: string }>();

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useMemo(() => debounce(setSearch, 250), []);
  const [riskMethodologies, setRiskMethodologies] =
    useState<RiskMethodology[]>();
  const [partnerQuestionnaires, setPartnerQuestionnaires] =
    useState<Array<QuestionnaireRead>>();

  const [assessments, setAssessments] = useState<AssessmentIndex[]>();

  const [createScoringGroupMenuOpen, setCreateScoringGroupMenuOpen] =
    useState(false);
  const [
    scoringGroupQuestionnaireMenuData,
    setScoringGroupQuestionnaireMenuData,
  ] = useState<{
    open: boolean;
    assessmentId?: number;
    riskMethodologyId?: number;
  }>({
    open: false,
  });
  const [renameGroupMenuData, setRenameGroupMenuData] = useState<{
    open: boolean;
    assessmentId?: number;
    name?: string;
  }>({
    open: false,
  });

  async function fetch() {
    if (!workspaceID) {
      return;
    }
    try {
      const abortController = new AbortController();
      const assessmentData = await AssessmentAPI.getAll(workspaceID, {
        partner_id: record_version_id,
      });
      const methodologyData = await RiskMethodologyAPI.getAll(
        Number(workspaceID),
        abortController.signal,
      );
      const questionnairesData = await PartnersAPI.getQuestionnaires(
        workspaceID,
        record_version_id,
        search,
      );
      setAssessments(assessmentData);
      setRiskMethodologies(methodologyData);
      setPartnerQuestionnaires(questionnairesData.questionnaires);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(
        err,
        "There was an issue loading required data. Please try again",
      );
      history.replace(
        generatePath(ROUTES.PARTNERS_PATH, {
          workspace_id: Number(workspaceID),
        }),
      );
    }
    setLoading(false);
  }

  useEffect(() => {
    fetch();
  }, [workspaceID, search]);

  const handleScoringGroupMenuClose = (refetch?: boolean) => {
    setCreateScoringGroupMenuOpen(false);
    if (refetch) {
      fetch();
    }
  };

  const handleAddQuestionnaireMenuClose = (refetch?: boolean) => {
    setScoringGroupQuestionnaireMenuData({ open: false });
    if (refetch) {
      fetch();
    }
  };

  const handleDeleteGroup = async (assessmentId: number) => {
    try {
      await AssessmentAPI.delete(Number(workspaceID), assessmentId);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(
        err,
        "There was an issue deleting the group. Please try again",
      );
    }
    fetch();
  };
  const handleAddQuestionnaireToGroupMenuOpen = (
    assessmentId: number,
    riskMethodologyId: number,
  ) => {
    setScoringGroupQuestionnaireMenuData({
      open: true,
      assessmentId,
      riskMethodologyId,
    });
  };

  const handleRemoveQuestionnaire = async (
    assessmentId: number,
    questionnaireId: number,
  ) => {
    try {
      const assessmentData = await AssessmentAPI.get(
        Number(workspaceID),
        assessmentId,
      );
      const filteredIds = assessmentData.questionnaires
        .filter((questionnaire) => questionnaire.id !== questionnaireId)
        .map((questionnaire) => questionnaire.id);
      await AssessmentAPI.addQuestionnaires(
        Number(workspaceID),
        assessmentId,
        filteredIds,
      );
      fetch();
    } catch (err) {
      mainStore.toast.setErrorFromResponse(
        err,
        "There was an issue removing the questionnaire. Please try again.",
      );
    }
  };

  const handleRenameGroup = async (assessmentId: number, name: string) => {
    setRenameGroupMenuData({ open: false });
    await AssessmentAPI.renameAssessment(
      Number(workspaceID),
      assessmentId,
      name,
    );
    fetch();
  };

  const handleGroupExport = (assessmentId: number, name: string) => {
    if (activeWorkspace) {
      return AssessmentAPI.downloadAssessment(
        activeWorkspace.id,
        assessmentId,
        undefined,
        name,
      );
    }
  };

  return (
    <>
      <SlideMenu
        open={renameGroupMenuData.open}
        closeSlideMenu={() => {
          setRenameGroupMenuData({ open: false });
        }}
        singleSlideMenu
      >
        <RenameGroupMenu
          assessmentId={renameGroupMenuData.assessmentId!}
          name={renameGroupMenuData.name!}
          onClose={() => setRenameGroupMenuData({ open: false })}
          onRenameGroup={handleRenameGroup}
        />
      </SlideMenu>
      <SlideMenu
        open={createScoringGroupMenuOpen}
        closeSlideMenu={() => {
          setCreateScoringGroupMenuOpen(false);
        }}
        singleSlideMenu
      >
        <CreateScoringGroupMenu
          riskMethodologies={riskMethodologies}
          onClose={handleScoringGroupMenuClose}
        />
      </SlideMenu>

      <SlideMenu
        open={scoringGroupQuestionnaireMenuData.open}
        closeSlideMenu={() => {
          setScoringGroupQuestionnaireMenuData({ open: false });
        }}
        singleSlideMenu
      >
        <AddQuestionnaireToGroupMenu
          assessmentId={scoringGroupQuestionnaireMenuData.assessmentId!}
          questionnaires={partnerQuestionnaires?.filter(
            (questionnaire) =>
              questionnaire.risk_methodology_id ===
              scoringGroupQuestionnaireMenuData.riskMethodologyId,
          )}
          defaultSelectedIds={
            assessments?.find(
              (ass) =>
                ass.id === scoringGroupQuestionnaireMenuData.assessmentId,
            )?.questionnaire_ids
          }
          onSearch={(val) => debouncedSearch(val)}
          onClose={handleAddQuestionnaireMenuClose}
        />
      </SlideMenu>

      <FlexDashboardContent>
        <DashboardHeader
          title="Questionnaires"
          onBackClick={() =>
            history.push(
              generatePath(ROUTES.PARTNERS_PATH, {
                workspace_id: Number(workspaceID),
              }),
            )
          }
        />
        <FlexDashboardContentWrapper
          ModuleHeaderContent={
            <PartnerTabs
              recordVersionId={record_version_id}
              // showAddQuestionnaire
              onAddNewScoringGroupClick={() =>
                setCreateScoringGroupMenuOpen(true)
              }
            />
          }
        >
          <div className="partner-questionnaires-content">
            {loading ? (
              <Loading loadingLayout="table" />
            ) : (
              <>
                {assessments?.map((assessment) => (
                  <GroupScoringSection
                    key={`group-scoring-section-${assessment.id}`}
                    assessment={assessment}
                    questionnaireIds={assessment.questionnaire_ids}
                    riskMethodologyId={assessment.risk_methodology_id}
                    name={assessment.name}
                    onDelete={handleDeleteGroup}
                    onAddQuestionnaire={handleAddQuestionnaireToGroupMenuOpen}
                    onRemoveQuestionnaire={handleRemoveQuestionnaire}
                    onRenameGroup={(assessmentId: number, name: string) =>
                      setRenameGroupMenuData({ open: true, assessmentId, name })
                    }
                    onExportGroup={handleGroupExport}
                  />
                ))}
                {assessments?.length === 0 && (
                  <GroupScoringEmptyState
                    onAddGroup={() => setCreateScoringGroupMenuOpen(true)}
                  />
                )}
              </>
            )}
          </div>
        </FlexDashboardContentWrapper>
      </FlexDashboardContent>
    </>
  );
};

export default observer(PartnersScoringPage);

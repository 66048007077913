import React from "react";

import { ActionItems } from "@/components/home/action-items/ActionItems";
import HomeContent from "@/components/home/content/HomeContent";

import { HomeHeader } from "../components/HomeHeader";
import { HomeTabs } from "../components/HomeTabs";

export function MyWork() {
  return (
    <div className="tw-flex tw-h-full tw-w-full">
      <div className="tw-flex tw-flex-grow tw-flex-col tw-overflow-auto tw-bg-neutral-25">
        <HomeHeader />
        <div>
          <HomeTabs />
        </div>
        <div className="tw-overflow-auto tw-px-6 tw-pb-10 tw-pt-6">
          <HomeContent />
        </div>
      </div>
      <ActionItems />
    </div>
  );
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import { IconButton } from "../Button/IconButton";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, } from "../DropdownMenu/DropdownMenu";
function AddColumnButton(_a) {
    var options = _a.options, onClick = _a.onClick, args = __rest(_a, ["options", "onClick"]);
    return (React.createElement(DropdownMenu, __assign({}, args),
        React.createElement(DropdownMenuTrigger, { asChild: true },
            React.createElement(IconButton, { Icon: PiPlusBold, color: "transparent", className: "tw-rounded-none" })),
        React.createElement(DropdownMenuContent, { align: "end" }, options.map(function (option) { return (React.createElement(DropdownMenuItem, { key: option.value, onClick: function () { return onClick(option.value); }, className: "tw-flex tw-w-[208px] tw-items-center tw-justify-start tw-gap-1 tw-px-4 tw-py-[10px]" },
            option.icon,
            React.createElement("span", { className: "tw-text-sm tw-font-medium" }, option.value))); }))));
}
export default AddColumnButton;

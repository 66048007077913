import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const ConflictsOfInterestReportContent = () => (
  <ReportsPage
    identifier="conflicts_of_interest"
    defaultDateColumn="start_date"
    defaultGroupColumn="status"
  />
);

export default observer(ConflictsOfInterestReportContent);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Status = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.99996 8.66602H1.33329C1.15648 8.66602 0.986912 8.73625 0.861888 8.86128C0.736864 8.9863 0.666626 9.15587 0.666626 9.33268V14.666C0.666626 14.8428 0.736864 15.0124 0.861888 15.1374C0.986912 15.2624 1.15648 15.3327 1.33329 15.3327H3.99996C4.17677 15.3327 4.34634 15.2624 4.47136 15.1374C4.59639 15.0124 4.66663 14.8428 4.66663 14.666V9.33268C4.66663 9.15587 4.59639 8.9863 4.47136 8.86128C4.34634 8.73625 4.17677 8.66602 3.99996 8.66602ZM3.33329 13.9993H1.99996V9.99935H3.33329V13.9993ZM14.6666 5.99935H12C11.8231 5.99935 11.6536 6.06959 11.5286 6.19461C11.4035 6.31963 11.3333 6.4892 11.3333 6.66602V14.666C11.3333 14.8428 11.4035 15.0124 11.5286 15.1374C11.6536 15.2624 11.8231 15.3327 12 15.3327H14.6666C14.8434 15.3327 15.013 15.2624 15.138 15.1374C15.2631 15.0124 15.3333 14.8428 15.3333 14.666V6.66602C15.3333 6.4892 15.2631 6.31963 15.138 6.19461C15.013 6.06959 14.8434 5.99935 14.6666 5.99935ZM14 13.9993H12.6666V7.33268H14V13.9993ZM9.33329 0.666016H6.66663C6.48981 0.666016 6.32025 0.736253 6.19522 0.861278C6.0702 0.986302 5.99996 1.15587 5.99996 1.33268V14.666C5.99996 14.8428 6.0702 15.0124 6.19522 15.1374C6.32025 15.2624 6.48981 15.3327 6.66663 15.3327H9.33329C9.5101 15.3327 9.67967 15.2624 9.8047 15.1374C9.92972 15.0124 9.99996 14.8428 9.99996 14.666V1.33268C9.99996 1.15587 9.92972 0.986302 9.8047 0.861278C9.67967 0.736253 9.5101 0.666016 9.33329 0.666016ZM8.66663 13.9993H7.33329V1.99935H8.66663V13.9993Z"
      fill="#8080A3"
    />
  </svg>
);

Status.displayName = "StatusIcon";

export default Status;

import React from "react";

import starIcon from "../../../images/table-image/icon/star-icon.svg";

export default function CreateFirstOBICTA() {
  return (
    <div className="no-column-wrap" data-testid="create-first-cta">
      <div className="no-column-block">
        <div className="no-column-procedure">
          <div className="no-column-title">
            <h3>Potential Conflicts Will Appear Here</h3>
          </div>
          <p>
            <img src={starIcon} alt="star-icon" />
            Click “Share Link” button on the top right of the screen
          </p>
          <p>
            <img src={starIcon} alt="star-icon" />
            Send the link to your employees.
          </p>
          <p>
            <img src={starIcon} alt="star-icon" />
            As soon as they submit, the data will appear here
          </p>
          <p>
            <img src={starIcon} alt="star-icon" />
            You can look at the form or the column titles above to see the data
            that will be submitted.
          </p>
          <p>
            <img src={starIcon} alt="star-icon" />
            To add conflicts directly, click &quot;Add New Conflict +&quot;
          </p>
        </div>
      </div>
    </div>
  );
}

import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { getSelectedTab } from "../helpers/Tabs";
import ExportUsers from "./ExportUsers";
import ImportUsers from "./ImportUsers";

function CompanySettingsNavigation() {
  const mainStore = useMainStore();

  const { pathname } = useLocation();

  const { isIW, isCompanySuperAdmin, isCompanyAdmin } = mainStore.context;
  const isAllUsers = pathname === "/settings/users" && isIW;
  const isUsers = pathname.startsWith("/settings/users") || isAllUsers;
  const isContacts = pathname.startsWith("/settings/contacts");
  const displayImportButton = isContacts || isUsers;

  let tabName;

  if (isAllUsers) {
    tabName = "allUsers";
  } else if (isUsers) {
    tabName = "users";
  } else if (isContacts) {
    tabName = "contacts";
  }

  const TABS = [
    {
      name: "Internal Users",
      key: "internalUsers",
      value: "/settings/users-internal",
      hasPermission: isIW,
    },
    {
      name: isIW ? "All Users" : "Users",
      key: "users",
      value: "/settings/users",
      hasPermission: !isIW || (isIW && (isCompanySuperAdmin || isCompanyAdmin)),
    },
    {
      name: "Contacts",
      key: "contacts",
      value: "/settings/contacts",
    },
    {
      name: "Workspace",
      key: "workspace",
      value: "/settings/workspace",
      hasPermission: isIW,
    },
    {
      name: "Company Info",
      key: "company-info",
      value: "/settings/company-info",
      hasPermission: isIW,
    },
    {
      name: "Roles",
      key: "roles",
      value: "/settings/roles",
    },
    {
      name: "Modules",
      key: "modules",
      value: "/settings/modules",
      dataTestId: "workspace-settings-modules",
    },
    {
      name: "Configuration",
      key: "configuration",
      value: "/settings/configuration",
    },
    {
      name: "Integrations",
      key: "integrations",
      value: "/settings/integrations",
    },
    {
      name: "Sei Integration",
      key: "api_auth",
      value: "/settings/api_auth",
    },
  ];

  return (
    <div
      className="tw-flex tw-h-10 tw-w-auto tw-items-center tw-justify-between tw-overflow-hidden tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5"
      data-testid="company-settings-navigation"
    >
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS, true)?.value || ""}
        isLink
        dataTestId="company-settings-tabs"
      />
      {displayImportButton && (
        <div className="tw-flex tw-h-8 tw-gap-1">
          <ExportUsers filename={tabName || ""} />
          <ImportUsers isUsers={isUsers} />
        </div>
      )}
    </div>
  );
}

export default observer(CompanySettingsNavigation);

import classNames from "classnames";
import React from "react";

import type { Account } from "@/api";

interface Props {
  label: string;
  placeholder: string;
  isReadOnly?: boolean;
  selectedAccount: Account | undefined;
}

function VendorLogoField({
  label,
  placeholder,
  isReadOnly,
  selectedAccount,
}: Props) {
  return (
    <div className="field-block">
      <label>{label}</label>
      <div
        className={classNames("drag-drop-wrap", "vendor-view-disabled", {
          "table-cell--disabled": isReadOnly,
        })}
      >
        <div className="drag-drop-block">
          <div className="input-container">
            {selectedAccount?.logo?.file_url ? (
              <img
                className="logo-img"
                data-testid="build-vendor-uploaded-logo"
                src={selectedAccount?.logo?.file_url}
                alt="logo"
              />
            ) : (
              <p className="placeholder-text">{placeholder}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorLogoField;

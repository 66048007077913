import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useMetricsParams = () => {
  const history = useHistory();
  const location = useLocation();
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const interval = params.get("interval");
  const timeRange = params.get("timeRange");
  const groupedBy = params.get("groupedBy");

  const [timeRangeState, setTimeRangeState] = useState(timeRange);
  const [intervalState, setIntervalState] = useState(interval);
  const [groupedByState, setGroupedByState] = useState(groupedBy);

  useEffect(() => {
    if (interval !== intervalState) {
      setIntervalState(interval);
    }

    if (timeRange !== timeRangeState) {
      setTimeRangeState(timeRange);
    }

    if (groupedBy !== groupedByState) {
      setGroupedByState(groupedBy);
    }
  }, [timeRange, interval, groupedBy, history.replace]);

  const shouldUpdateParam = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'paramName' implicitly has an 'any' type... Remove this comment to see the full error message
    (paramName, paramValue) => {
      switch (paramName) {
        case "interval":
          return interval ? interval !== paramValue : true;
        case "timeRange":
          return timeRange ? timeRange !== paramValue : true;
        case "groupedBy":
          return groupedBy ? groupedBy !== paramValue : true;
        default:
          break;
      }
    },
    [timeRange, interval, groupedBy],
  );

  const setMetricsParam = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
    (name, value) => {
      if (shouldUpdateParam(name, value)) {
        params.delete(name);
        params.set(name, value);

        history.replace({
          search: params.toString(),
        });
      }
    },
    [location.pathname, search],
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  const clearMetricsParam = useCallback((name) => {
    params.delete(name);

    history.replace({
      search: params.toString(),
    });
  }, []);

  return {
    intervalParam: intervalState,
    timeRangeParam: timeRangeState,
    groupedByParam: groupedByState,
    setMetricsParam,
    clearMetricsParam,
  };
};

export default useMetricsParams;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { useModuleDetection } from "@/hooks/useModuleDetection";
import { useTableDetection } from "@/hooks/useTableDetection";

import { moduleDataLoader } from "../components/helpers/moduleDataLoader";

const useTableData = (doNotClearRecordVersionAndFieldData?: boolean) => {
  const mainStore = useMainStore();

  const location = useLocation();

  const { list: tables } = mainStore.tables;
  const { workspaceID, tableID, moduleWorkspaceID } = mainStore.context;
  const { folderID } = mainStore.documents;
  const themisModuleIdentifier = useModuleDetection(location);
  const tableName = useTableDetection(location.pathname);

  useEffect(() => {
    if (!moduleWorkspaceID) {
      return;
    }

    mainStore.tables.index(moduleWorkspaceID);

    if (!workspaceID) {
      return;
    }

    mainStore.users.indexForModules({ workspaceID });
  }, [moduleWorkspaceID]);

  useEffect(() => {
    if (!tableName) {
      return;
    }

    mainStore.context.setTableName(tableName);
    mainStore.comments.setRecordID(null);
  }, [tableName]);

  useEffect(() => {
    const currentTable = tables.find((table) => table.title === tableName);

    if (!currentTable?.id || tableID === currentTable.id) {
      return;
    }

    mainStore.context.setTableID(currentTable?.id);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  }, [tables, tableName]);

  // Fetch Table details and RecordVersions when tableID is changing
  useEffect(() => {
    if (doNotClearRecordVersionAndFieldData) {
      return;
    }

    mainStore.abort();
    mainStore.recordVersions.setList([]);
    mainStore.fields.setList([]);

    if (!themisModuleIdentifier) {
      return;
    }

    moduleDataLoader(themisModuleIdentifier, tableName, mainStore);
  }, [themisModuleIdentifier, tableName, tableID, folderID]);
};

export { useTableData };

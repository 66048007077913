import React from "react";
import { Popover, PopoverAnchor, PopoverContent } from "../../Popover/Popover";
var CustomTooltip = function (_a) {
    var value = _a.value, defaultOpen = _a.defaultOpen, children = _a.children;
    var _b = React.useState(!children || !!defaultOpen), isOpen = _b[0], setIsOpen = _b[1];
    return (React.createElement(Popover, { open: isOpen },
        children ? (React.createElement(PopoverAnchor, null,
            React.createElement("div", { onMouseEnter: function () { return setIsOpen(true); }, onMouseLeave: function () { return setIsOpen(false); } }, children))) : (React.createElement(PopoverAnchor, null)),
        React.createElement(PopoverContent, { align: "center", side: "bottom" },
            React.createElement("div", { className: "tw-max-w-[200px] tw-overflow-hidden tw-break-words tw-rounded-md tw-bg-neutral-500 tw-px-4 tw-py-2 tw-font-sans tw-text-xs tw-text-neutral-25 tw-shadow-dropdown" }, value))));
};
export default CustomTooltip;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  listControlsModules,
  saveControlsModules,
} from "../gen/axios/controlsModulesController";
import type { SaveControlsModulesMutationRequest } from "../gen/models/SaveControlsModules";

export const LIST_CONTROLS_MODULES_QUERY_KEY = "list_controls_modules";
export const SAVE_CONTROLS_MODULES = "save_controls_modules";

export function useControlsModules(recordVersionId: number) {
  return useQuery({
    queryKey: [LIST_CONTROLS_MODULES_QUERY_KEY, recordVersionId],
    queryFn: () => listControlsModules(recordVersionId),
    enabled: !!recordVersionId,
  });
}

export function useSaveControlsModules({
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: SaveControlsModulesMutationRequest) =>
      saveControlsModules(recordVersionId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [SAVE_CONTROLS_MODULES, recordVersionId],
      });
      queryClient.invalidateQueries({
        queryKey: [LIST_CONTROLS_MODULES_QUERY_KEY, recordVersionId],
      });
      onSuccess();
    },
    onError,
  });
}

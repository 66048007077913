import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

import { Typography } from "@/components/Elements";
import { COLOR } from "@/config/theme";

interface QuestionnairesCellProps {
  completed: number;
  length: number;
}

export const QuestionnairesCell = ({
  completed,
  length,
}: QuestionnairesCellProps) => {
  const value = length > 0 ? (completed / length) * 100 : 0;

  return (
    <div
      className="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-p-2"
      data-testid={`questionnaires-cell-progress-${value}%`}
    >
      <Typography color="generalMidnightDark">
        {completed}/{length} Complete
      </Typography>

      <CircularProgressbar
        styles={buildStyles({
          pathColor: COLOR.brandingHighlightViolet,
        })}
        className="tw-h-6 tw-w-6"
        value={value}
        strokeWidth={15}
      />
    </div>
  );
};

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ApiAuthGetQueryParams,
  ApiAuthGetQueryResponse,
} from "../../models/ApiAuthGet";

/**
 * @description List API Auth for a Company
 * @summary List API Auth for a Company
 * @link /integrations/api_auth
 */
export async function apiAuthGet(
  params: ApiAuthGetQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ApiAuthGetQueryResponse>["data"]> {
  const res = await client<ApiAuthGetQueryResponse>({
    method: "get",
    url: `/integrations/api_auth`,
    params,
    ...options,
  });
  return res.data;
}

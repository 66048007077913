import React from "react";

import warningIcon from "../../../../images/table-image/icon/warning-icon.svg";
import Button from "../../../form/Button";
import { supportedViewFileTypesString } from "../helpers";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDownload?: (...args: any[]) => any;
}

function CreativeViewTypeError({ handleDownload }: Props) {
  return (
    <div className="view-error" data-testid="cv-error-block">
      <ul className="view-error-message">
        <li>
          <img src={warningIcon} alt="warning-icon" />
        </li>
        <li>
          <div>
            This is not a supported file type for viewing.
            <br />
            If you need to view your file, please try one of the following file
            types:
            <br />
            {supportedViewFileTypesString}
          </div>
          <Button
            title="Download File"
            onClick={(event) => {
              event.stopPropagation();
              // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              handleDownload();
            }}
          />
        </li>
      </ul>
    </div>
  );
}

export default CreativeViewTypeError;

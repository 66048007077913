import { Button, HeaderTabs, useToast } from "@themis/ui";
import classNames from "classnames";
import { kebabCase, noop } from "lodash";
import { observer } from "mobx-react";
import pluralize from "pluralize";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ViewActionPlansSummary,
  ViewModuleControlSummary,
  ViewOperationalControlSummary,
  ViewRelatedRisksSummary,
} from "@/components/detailView/components";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import ViewControlEffectivenessRating from "@/components/table/shared/dynamic-form/ViewControlEffectivenessRating";
import ViewFinraBrexCategories from "@/components/table/shared/dynamic-form/ViewFinraBrexCategories";
import ViewMainCategoriesSelect from "@/components/table/shared/dynamic-form/ViewMainCategoriesSelect";
import ViewProblemProductCodes from "@/components/table/shared/dynamic-form/ViewProblemProductCodes";
import ViewRiskRegisterControlRatingCell from "@/components/table/shared/dynamic-form/ViewRiskRegisterControlRatingCell";
import ViewRiskRegisterInherentValuesCell from "@/components/table/shared/dynamic-form/ViewRiskRegisterInherentValuesCell";
import ViewRiskRegisterScoringValueCell from "@/components/table/shared/dynamic-form/ViewRiskRegisterScoringValueCell";
import ViewRiskTypesSelect from "@/components/table/shared/dynamic-form/ViewRiskTypesSelect";
import { PreviewRelatedFindingList } from "@/features/issue-management/components/PreviewRelatedFindings/PreviewRelatedFindingsList";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useTableData } from "@/hooks/useTableData";
import type {
  ModuleIdentifier,
  TopLevelModuleIdentifier,
} from "@/stores/types/module-workspaces-types";

import { useMainStore } from "../../contexts/Store";
import { useLoading } from "../../hooks/useLoading";
import logo from "../../images/logo.svg";
import { isLockedRecord } from "../../stores/helpers/RecordVersionHelper";
import ExportBulk from "../dashboard/ExportBulk";
import { MiniTag } from "../Elements";
import { nameFromModuleWorkspace } from "../helpers/nameForThemisModuleIdentifier";
import { getSelectedTab } from "../helpers/Tabs";
import Loading from "../Loading";
import RecordNotFound from "../shared/RecordNotFound";
import SectionTagViewDocument from "../table/change-management/new-product-section/SectionTagViewDocument";
import RecordSelect from "../table/shared/cell-type/RecordSelect";
import ViewTextareaCell from "../table/shared/dynamic-form/view-textarea/ViewTextareaCell";
import ViewActionPlanOwners from "../table/shared/dynamic-form/ViewActionPlanOwners";
import ViewApprovalNew from "../table/shared/dynamic-form/ViewApprovalNew";
import ViewApprovedUsers from "../table/shared/dynamic-form/ViewApprovedUsers";
import ViewAssignedUsers from "../table/shared/dynamic-form/ViewAssignedUsers";
import ViewChecklist from "../table/shared/dynamic-form/ViewChecklist";
import ViewCompanyWebsiteCell from "../table/shared/dynamic-form/ViewCompanyWebsiteCell";
import ViewControlCategoriesSelect from "../table/shared/dynamic-form/ViewControlCategoriesSelect";
import ViewControlMappingTypesSelect from "../table/shared/dynamic-form/ViewControlMappingTypesSelect";
import ViewDateSelect from "../table/shared/dynamic-form/ViewDateSelect";
import ViewDepartmentSelect from "../table/shared/dynamic-form/ViewDepartmentSelect";
import ViewDocuments from "../table/shared/dynamic-form/ViewDocuments";
import ViewFileUploader from "../table/shared/dynamic-form/ViewFileUploader";
import ViewFloat from "../table/shared/dynamic-form/ViewFloat";
import ViewInputCell from "../table/shared/dynamic-form/ViewInputCell";
import ViewIntegerCell from "../table/shared/dynamic-form/ViewIntegerCell";
import ViewLinkCell from "../table/shared/dynamic-form/ViewLinkCell";
import ViewLogoCell from "../table/shared/dynamic-form/ViewLogoCell";
import ViewProductsSelect from "../table/shared/dynamic-form/ViewProductsSelect";
import ViewSelect from "../table/shared/dynamic-form/ViewSelect";
import ViewTagsSelect from "../table/shared/dynamic-form/ViewTagsSelect";
import ViewUsersSelect from "../table/shared/dynamic-form/ViewUsersSelect";
import DetailNav from "./DetailNav";

const REENABLE_GENERATE_PDF_TIME = 6000; // 6secs

const AUTOFILL_FIELDS = {
  company_website: {
    component: ViewCompanyWebsiteCell,
    type: "input",
  },

  month_picker: {
    component: ViewDateSelect,
    type: "date",
    additionalProps: {
      onlyMonthYearPicker: true,
    },
  },

  company_logo: {
    component: ViewLogoCell,
    type: "logo",
  },

  impact: {
    component: ViewRiskRegisterInherentValuesCell,
    type: "select",
  },

  likelihood: {
    component: ViewRiskRegisterInherentValuesCell,
    type: "select",
  },

  inherent_risk_rating: {
    component: ViewRiskRegisterScoringValueCell,
    type: "select",
  },

  residual_risk_rating: {
    component: ViewRiskRegisterScoringValueCell,
    type: "select",
  },

  control_rating: {
    component: ViewRiskRegisterControlRatingCell,
    type: "select",
  },
};

const SKIP_FIELDS = [
  "created_by",
  "created_at",
  "last_updated_by",
  "last_updated_at",
  "last_closed_by",
  "last_closed_at",
  "last_reopened_at",
  "id",
];

interface Props {
  moduleIdentifier: ModuleIdentifier;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  disabledFields?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldList?: any[];
  ignoredFields?: string[];
  isHistory?: boolean;
  isShareDetailView?: boolean;
  onCreateRecord?: (data: unknown, files: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDateFunction?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDateFunction?: (...args: any[]) => any;
  programName?: string | null;
  showContactsApprovers?: boolean;
  showCommentsButton?: boolean;
  showExportButton?: boolean;
  isLoadingDisabled?: boolean;
  isDataFetchingDisabled?: boolean;
  isNewRecord?: boolean;
  customTitle?: string;
  bottomComponentSlot?: React.ReactNode;
}

const TABS = [
  {
    name: "Record View",
    key: "record",
    value: "",
  },
];

function DetailView({
  disabledFields,
  fieldList,
  ignoredFields = [],
  onCreateRecord,
  maxDateFunction,
  minDateFunction,
  moduleIdentifier,
  programName,
  showContactsApprovers,
  isShareDetailView = false,
  showCommentsButton,
  showExportButton,
  isLoadingDisabled,
  isDataFetchingDisabled,
  isNewRecord,
  customTitle,
  bottomComponentSlot,
}: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();
  useTableData(isDataFetchingDisabled);
  // State
  const [altFields, setAltFields] = useState([]);
  const [canGeneratePdf, setCanGeneratePdf] = useState(true);
  const [shareModuleName, setShareModuleName] = useState("");
  const [data, setData] = useState({});

  const [files, setFiles] = useState({});
  const [parentRecordVersion, setParentRecordVersion] = useState(null);

  // Variable
  const toast = useToast();
  const fields = useMemo(() => {
    if (altFields?.length > 0) {
      // @ts-expect-error TS(2339) FIXME: Property 'is_hidden' does not exist on type 'never... Remove this comment to see the full error message
      return altFields.filter((field) => !field.is_hidden);
    }

    return mainStore.fields.list.filter((field) => !field.is_hidden);
  }, [mainStore.fields.list, altFields]);

  const fieldNames = fields.map((field) => field.name);

  const { record_version_id, parent_record_version_id, token } = useParams<{
    record_version_id?: string;
    parent_record_version_id?: string;
    token?: string;
  }>();

  const [{ table_id }] = useSearchParams<{
    table_id?: string;
  }>();

  const recordVersionID = Number(record_version_id);
  const isPolicies = moduleIdentifier === "policy";
  const isProcedures = moduleIdentifier === "procedures";
  const isAudits = moduleIdentifier === "audits";
  const isQA = moduleIdentifier === "qa_tests_development";
  const isControlMapping = moduleIdentifier === "control_mapping";
  const isTestProcedures = moduleIdentifier === "qa_procedures";
  const isIM = moduleIdentifier === "issue_management";
  const isRR = moduleIdentifier === "risk_register";

  const { list: tables } = mainStore.tables;
  const moduleAccessTokensStore = mainStore.moduleAccessTokens;
  const listRecordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );

  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const recordVersion = listRecordVersion || mainStore.recordVersions.current;
  const isReadOnly =
    !isShareDetailView &&
    !isNewRecord &&
    (!hasModuleWriteAccess || isCurrentWorkspaceArchived);

  useEffect(() => {
    const fetchFields = async () => {
      const { module_name } = await moduleAccessTokensStore.fetch_fields(token);
      setShareModuleName(module_name);
    };

    const fetchFieldsForNewRecord = async () => {
      const response = await mainStore.fields.index(Number(table_id));

      mainStore.fields.setList(response);
    };

    if (token) {
      fetchFields();
      return;
    }

    if (isNewRecord) {
      fetchFieldsForNewRecord();
    }
  }, []);

  const loading = useLoading(fields);
  const { moduleWorkspaceID, subModuleTableName } = mainStore.context;

  const mwList = mainStore.moduleWorkspaces.list;
  const moduleWorkspace = mwList.find((mw) => mw.id === moduleWorkspaceID);
  let { identifier } = moduleWorkspace?.themis_module || {
    identifier: "policy",
  };
  if (parent_record_version_id && subModuleTableName === "action_plans") {
    identifier = subModuleTableName;
  }
  if (Array.isArray(ignoredFields)) {
    // we always hide column with ThemisID, field_name => id
    ignoredFields?.push("id");
  }

  useEffect(() => {
    if (!parent_record_version_id || isDataFetchingDisabled) {
      return;
    }

    (async () => {
      const res = await mainStore.recordVersions.fetch(
        Number(parent_record_version_id),
        true,
      );
      if (res) {
        setParentRecordVersion(res);
      }
    })();
  }, [parent_record_version_id]);

  // Preload altFields for Policy & Procedures Modules
  useEffect(() => {
    if (fieldList) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setAltFields(fieldList);
    }
  }, [fieldList]);

  // Clean up errors when data or files are changed
  useEffect(() => {
    mainStore.recordVersions.setNewRecordVersionErrors([]);
  }, [data, files]);

  useEffect(() => {
    let mounted = true;
    const tableID = recordVersion?.table_id;

    if (tableID && (isPolicies || isProcedures) && !isDataFetchingDisabled) {
      (async () => {
        const newFields = await mainStore.fields.index(tableID);

        if (mounted) {
          setAltFields(newFields);
        }
      })();
    }

    return () => {
      mounted = false;
    };
  }, [isPolicies, isProcedures, recordVersion]);

  const classes = classNames("detail-view", moduleIdentifier, "template", {
    "order-qa": isQA,
  });

  const showApprovers =
    fieldNames.includes("approvers") || fieldNames.includes("testers");
  const showAssignee = fieldNames.includes("assignee");
  const showControlOwner = fieldNames.includes("control_owners");
  const isLocked =
    // @ts-expect-error TS(2345) FIXME: Argument of type 'RecordVersion<string> | null | u... Remove this comment to see the full error message
    isLockedRecord(recordVersion, moduleIdentifier) &&
    !isShareDetailView &&
    !isNewRecord;
  const disabledAttachmentFields = ["company_logo"];
  const attachmentFields = fields.filter(
    (field) =>
      field.data_type === "com.askthemis.types.v1.attachment" &&
      !disabledAttachmentFields.includes(field.name),
  );

  useEffect(() => {
    const fetchData = async () => {
      await mainStore.recordVersions.getLatestRecordVersion(recordVersionID);
    };

    // Make sure tables are loaded before fetching RecordVersion
    if (recordVersionID && tables.length && !isDataFetchingDisabled) {
      fetchData();
    }
  }, [tables, record_version_id]);

  useEffect(() => {
    return () => {
      mainStore.recordVersions.cleanup();
    };
  }, []);

  const COLUMN_INFO = {
    "com.askthemis.types.v1.option": {
      component: ViewSelect,
      type: "select",
      additionalProps: {
        hasReadWriteAccess: isShareDetailView || isNewRecord,
      },
    },

    "com.askthemis.types.v1.constrained_option": {
      component: ViewSelect,
      type: "select",
      additionalProps: {
        constrained: true,
      },
    },

    "com.askthemis.types.v1.text": {
      component: ViewInputCell,
      type: "input",
      additionalProps: {
        hasReadWriteAccess: isShareDetailView || isNewRecord,
      },
    },

    "com.askthemis.types.v1.link": {
      component: ViewLinkCell,
      type: "link",
    },

    "com.askthemis.types.v1.date": {
      component: ViewDateSelect,
      type: "date",
      additionalProps: {
        hasReadWriteAccess: isShareDetailView || isNewRecord,
      },
    },

    "com.askthemis.types.v1.long_text": {
      component: ViewTextareaCell,
      type: "textarea",
      additionalProps: {
        hasReadWriteAccess: isShareDetailView || isNewRecord,
        locked: isLocked,
      },
    },

    "com.askthemis.types.v1.multiline_text": {
      component: ViewInputCell,
      type: "input",
      additionalProps: {
        hasReadWriteAccess: isShareDetailView || isNewRecord,
      },
    },

    "com.askthemis.types.v1.tag_user_contact": {
      component: ViewUsersSelect,
      type: "select",
      additionalProps: {
        hasReadWriteAccess: isShareDetailView || isNewRecord,
      },
    },

    "com.askthemis.types.v1.tag_verifier": {
      component: ViewUsersSelect,
      type: "select",
    },

    "com.askthemis.types.v1.tag_user": {
      component: ViewUsersSelect,
      type: "select",
      additionalProps: {
        hasReadWriteAccess: isShareDetailView || isNewRecord,
      },
      isHidden: isShareDetailView,
    },

    "com.askthemis.types.v1.tag_department": {
      component: ViewDepartmentSelect,
      type: "select",
    },

    "com.askthemis.types.v1.review": {
      component: ViewApprovalNew,
    },

    "com.askthemis.types.v1.record_policy": {
      component: RecordSelect,
      type: "select",
      additionalProps: {
        dataType: "record:policy",
        singleSelection: false,
        width: "100%",
      },
    },

    "com.askthemis.types.v1.record_procedure": {
      component: RecordSelect,
      type: "select",
      additionalProps: {
        dataType: "record:procedure",
        singleSelection: false,
        width: "100%",
      },
    },

    "com.askthemis.types.v1.tag_control_mapping_type": {
      component: ViewControlMappingTypesSelect,
      type: "select",
    },

    "com.askthemis.types.v1.tag_control_category": {
      component: ViewControlCategoriesSelect,
      type: "select",
    },

    "com.askthemis.types.v1.checklist": {
      component: ViewChecklist,
      type: "checklist",
      isHidden: isShareDetailView,
    },

    "com.askthemis.types.v1.integer": {
      component: ViewIntegerCell,
      type: "integer",
    },

    "com.askthemis.types.v1.float": {
      component: ViewFloat,
      type: "input",
    },

    action_plan_owners: {
      component: ViewActionPlanOwners,
      type: "select",
    },

    "com.askthemis.types.v1.tag_product": {
      component: ViewProductsSelect,
      type: "select",
    },

    "com.askthemis.types.v1.tag": {
      component: ViewTagsSelect,
      type: "select",
      isHidden: isNewRecord,
    },

    "com.askthemis.types.v1.finra_brex_category": {
      component: ViewFinraBrexCategories,
      type: "select",
      additionalProps: {
        isBrexCategory: true,
      },
    },

    "com.askthemis.types.v1.finra_brex_sub_category": {
      component: ViewFinraBrexCategories,
      type: "select",
      additionalProps: {
        isBrexCategory: false,
      },
    },

    "com.askthemis.types.v1.finra_dispute_amount": {
      component: ViewSelect,
      type: "select",
      additionalProps: {
        isFinraDisputeAmount: true,
      },
    },

    "com.askthemis.types.v1.finra_product_code": {
      component: ViewProblemProductCodes,
      type: "select",
      additionalProps: {
        isProductCodes: true,
      },
    },

    "com.askthemis.types.v1.finra_problem_code": {
      component: ViewProblemProductCodes,
      type: "select",
      additionalProps: {
        isProductCodes: false,
      },
    },

    "com.askthemis.types.v1.tag_risk_type": {
      component: ViewRiskTypesSelect,
      type: "select",
    },

    "com.askthemis.types.v1.tag_main_category": {
      component: ViewMainCategoriesSelect,
      type: "select",
    },

    "com.askthemis.types.v1.control_effectiveness_rating": {
      component: ViewControlEffectivenessRating,
      type: "select",
    },

    record_version: {
      component: ViewInputCell,
      type: "input",
      computedTitle: "Version",
      additionalProps: {
        disabled: true,
        initialValue: recordVersion?.version,
      },
    },
  };

  // Functions
  function isModuleAdded(themisModuleIdentifier: TopLevelModuleIdentifier) {
    return mainStore.moduleWorkspaces.isModuleAdded(themisModuleIdentifier);
  }

  function generateReport() {
    if (!canGeneratePdf) {
      return;
    }

    setCanGeneratePdf(false);
    setTimeout(() => setCanGeneratePdf(true), REENABLE_GENERATE_PDF_TIME);
    return mainStore.qa.generateReport(recordVersionID);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  function renderField(field) {
    if (
      ["qa_tests_development", "risk_register"].includes(moduleIdentifier) &&
      [
        "impact",
        "likelihood",
        "inherent_risk_rating",
        "residual_risk_rating",
        "control_rating",
        "company_website",
        "company_logo",
      ].includes(field.name)
    ) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return AUTOFILL_FIELDS[field.name];
    } else if (
      moduleIdentifier === "key_risk_indicators" &&
      ["start_date", "end_date"].includes(field.name)
    ) {
      return AUTOFILL_FIELDS.month_picker;
    }

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return COLUMN_INFO[field.computed_column_identifier || field.data_type];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  function onDataChange(fieldName, serializeValue) {
    setData((prevData) => {
      const newData = { ...prevData };

      if (serializeValue.value !== "") {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newData[fieldName] = serializeValue;
      } else {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        delete newData[fieldName];
      }

      return newData;
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  function handleAddFile(ids, fileNames, fileData, fieldName) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    const newFiles = ids.map((id, index) => ({
      id,
      name: fileNames[index].name,
    }));

    setFiles((prevFiles) => {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const updatedFiles = [...(prevFiles[fieldName] || []), ...newFiles];
      const updatedData = {
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
        ...prevFiles.data,
        [fieldName]: updatedFiles.map((file) => file.id),
      };

      return {
        ...prevFiles,
        [fieldName]: updatedFiles,
        data: updatedData,
      };
    });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fileId' implicitly has an 'any' type.
  function handleRemoveFile(fileId, fieldName) {
    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };

      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      updatedFiles[fieldName] = updatedFiles[fieldName].filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
        (file) => file.id !== fileId,
      );

      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (updatedFiles[fieldName].length === 0) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        delete updatedFiles[fieldName];
      }

      // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
      const updatedData = { ...updatedFiles.data };

      if (updatedData[fieldName]) {
        updatedData[fieldName] = updatedData[fieldName].filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
          (id) => id !== fileId,
        );

        if (updatedData[fieldName].length === 0) {
          delete updatedData[fieldName];
        }
      }

      if (Object.keys(updatedData).length === 0) {
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
        delete updatedFiles.data;
      } else {
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
        updatedFiles.data = updatedData;
      }

      return updatedFiles;
    });
  }

  function handleCreateNewRecord(recordData: unknown) {
    if (isNewRecord && onCreateRecord) {
      // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
      return onCreateRecord(recordData, files.data);
    }

    if (isShareDetailView) {
      return mainStore.moduleAccessTokens.create(
        recordData,
        // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type '{}'.
        files.data,
        token,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  async function onSubmit(event) {
    event.preventDefault();

    // Make request
    const response = await handleCreateNewRecord(data);
    if (response?.success) {
      // Request is successful
      toast({
        content: "The record has been successfully created!",
        variant: "success",
      });
      window.location.reload();
    } else if (response?.errors) {
      // There are some validation errors
      mainStore.recordVersions.setNewRecordVersionErrors(response?.errors);
      toast({
        content:
          "There is something not right! Please check if all necessary fields have been filled properly!",
        variant: "error",
      });
    }
  }

  // elements
  const triggerAction = isQA ? (
    <button
      data-testid="upload-document-button"
      className="upload-document"
      onMouseDown={generateReport}
      disabled={!canGeneratePdf}
    >
      Generate Final Report
    </button>
  ) : null;

  const testProceduresTitle = (
    <div className="associated-record">
      Associated Record: {programName ? <span>{programName}</span> : "N/A"}
    </div>
  );

  const tableHeader = document.querySelector(".table-header-wrap");
  const classesMainDetailView = classNames("main-detail-view", {
    "main-wrap-detail-view": tableHeader,
    "detail-add-new": !tableHeader,
  });
  const sectionTitle = useMemo(
    () =>
      mainStore.sectionTags.list?.find(
        (item) => item.id === recordVersion?.section_tag_id,
      )?.title,
    [mainStore.sectionTags.list, recordVersion],
  );

  const requiresRecordVersionData = !isAudits && !isQA && !isControlMapping;
  const hasData =
    (recordVersion?.id &&
      (!requiresRecordVersionData || recordVersion?.data)) ||
    isShareDetailView ||
    isNewRecord;

  const getModuleName = () => {
    switch (identifier) {
      case "qa_tests_development":
        return "Test";
      case "procedures":
        return "Procedure";
      default:
        return nameFromModuleWorkspace(identifier, mwList);
    }
  };

  const renderParentRecordDetails = () => {
    if (!parentRecordVersion) {
      return;
    }

    return (
      <div className="detail-view-parent-record-details">
        <div className="detail-view-parent-record-details-heading">
          <div className="detail-view-parent-record-details-heading-item">
            <span className="text title">Record</span>
            {/* @ts-expect-error TS(2339) FIXME: Property 'global_id' does not exist on type 'never... Remove this comment to see the full error message */}
            <MiniTag label={parentRecordVersion.global_id} theme="navy" />
            <span className="text record-name">
              {/* @ts-expect-error TS(2339) FIXME: Property 'meta' does not exist on type 'never'. */}
              {parentRecordVersion.meta.name || "-Untitled-"}{" "}
            </span>
          </div>

          {/* @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'never'. */}
          {parentRecordVersion.status && (
            <div className="detail-view-parent-record-details-heading-item">
              <span className="text title">Status</span>
              <span className="text status">
                {/* @ts-expect-error TS(2339) FIXME: Property 'status' does not exist on type 'never'. */}
                {parentRecordVersion.status.replace("_", "-")}
              </span>
            </div>
          )}
        </div>

        <div className="detail-view-parent-record-details-description">
          {/* @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'. */}
          {parentRecordVersion.data?.description?.value || "-No Description-"}
        </div>
      </div>
    );
  };

  return (
    <div data-testid="detail-view" className={classesMainDetailView}>
      {!isShareDetailView && !isNewRecord && (
        <DetailNav
          moduleIdentifier={moduleIdentifier}
          recordVersionID={recordVersionID}
        >
          {showExportButton && (
            <div className="import-export-buttons-container margin-import-export-buttons-container">
              <ExportBulk
                isPDFEnabled
                isExportSheetEnabled={false}
                isRenderSectionSelectionPDF
                recordVersion={recordVersionID}
                moduleIdentifier={moduleIdentifier}
              />
            </div>
          )}
        </DetailNav>
      )}

      {showCommentsButton && (
        <div className="tw-flex">
          <div className="tw-flex tw-h-11 tw-w-full tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-b-neutral-100 tw-px-8">
            <HeaderTabs
              tabs={TABS}
              selectedTab={getSelectedTab(TABS, true)?.value || ""}
            />
            {recordVersion && (
              <CommentsSlideMenu
                renderCommentsButton
                globalID={recordVersion.global_id}
                recordID={recordVersion.record_id}
                taskableType="Record"
                uncompletedCommentsCount={
                  recordVersion.uncompleted_comments_count
                }
              />
            )}
          </div>
        </div>
      )}

      {!loading && !hasData && !isNewRecord && <RecordNotFound />}

      {loading && !isLoadingDisabled && (
        <Loading loadingLayout="small-table" showTableHeader={false} />
      )}

      {((!loading && hasData) || isLoadingDisabled) && (
        <div className="below-nav-content-wrapper">
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: (false | Element | (Element | nu... Remove this comment to see the full error message */}
          <div className={classes} disabled={isLocked}>
            {isTestProcedures && testProceduresTitle}
            <section>
              {(isAudits || isQA || isControlMapping) && (
                <div className="associated-section">
                  <p>Associated Section:</p>
                  <span>{sectionTitle ? sectionTitle : "NA"}</span>
                </div>
              )}

              {parentRecordVersion && renderParentRecordDetails()}

              <div className="section-header">
                {customTitle && <div className="title">{customTitle}</div>}
                {isShareDetailView && !customTitle && (
                  <div className="header-share">
                    <img src={logo} alt="logo" className="logo" />
                    <div className="title">
                      {" "}
                      Submit a {pluralize.singular(shareModuleName)} Form{" "}
                    </div>
                  </div>
                )}
                {!isShareDetailView && !customTitle && (
                  <div className="title">{`${getModuleName()} Details`}</div>
                )}
              </div>

              <div className="section-row two-cols">
                {fields.map((field) => {
                  if (!field) {
                    return null;
                  }

                  const dataType = renderField(field);

                  if (!dataType) {
                    return null;
                  }

                  const { name: fieldName, display_name: displayName } = field;
                  const {
                    component: Component,
                    type,
                    additionalProps,
                    computedTitle,
                    isHidden,
                  } = dataType;

                  if (
                    isHidden ||
                    ignoredFields.includes(
                      fieldName || String(field.computed_column_identifier),
                    ) ||
                    ((Object.values(SKIP_FIELDS).includes(fieldName) ||
                      field.is_computed_column) &&
                      (isShareDetailView || isNewRecord))
                  ) {
                    return null;
                  }

                  const disabled =
                    isReadOnly ||
                    !field.is_user_editable ||
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    disabledFields.includes(fieldName);
                  const defaultField = field.is_default_field;

                  const classList = classNames(`column column-${type}`, {
                    disabled:
                      (isAudits && fieldName === "approval") ||
                      (isQA && fieldName === "validator"),
                  });

                  function getSelectedIds() {
                    if (recordVersion?.data?.[fieldName]?.ids) {
                      return recordVersion?.data?.[fieldName]?.ids;
                    }
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    if (Array.isArray(data[fieldName])) {
                      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                      return data[fieldName];
                    }
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    if (data[fieldName]?.options) {
                      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                      return data[fieldName]?.options;
                    }
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    if (data[fieldName]?.ids) {
                      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                      return data[fieldName]?.ids;
                    }

                    return undefined;
                  }

                  return (
                    <div
                      key={fieldName}
                      className={classList}
                      data-testid={kebabCase(displayName)}
                    >
                      <h4>{displayName || computedTitle}</h4>
                      <Component
                        fieldName={fieldName}
                        recordVersion={recordVersion}
                        title={displayName}
                        disabled={disabled}
                        isDefaultField={defaultField}
                        recordVersionID={recordVersion?.id}
                        isEditable={!disabled}
                        isUserEditable={!disabled}
                        // @ts-expect-error TS(7006) FIXME: Parameter 'columnTitle' implicitly has an 'any' ty... Remove this comment to see the full error message
                        minDateFunction={(columnTitle) =>
                          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                          minDateFunction(columnTitle, recordVersion)
                        }
                        // @ts-expect-error TS(7006) FIXME: Parameter 'columnTitle' implicitly has an 'any' ty... Remove this comment to see the full error message
                        maxDateFunction={(columnTitle) =>
                          // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                          maxDateFunction(columnTitle, recordVersion)
                        }
                        moduleIdentifier={moduleIdentifier}
                        selectedIDs={getSelectedIds()}
                        onDataChange={
                          (isShareDetailView || isNewRecord) && onDataChange
                        }
                        {...additionalProps}
                      />
                    </div>
                  );
                })}
              </div>
            </section>

            {attachmentFields.map((field) => {
              if (
                ignoredFields.includes(
                  field.name || String(field.computed_column_identifier),
                )
              ) {
                return null;
              }

              return (
                <section className="section-upload" key={field.name}>
                  <div className="section-header">
                    <h3>{field.display_name}</h3>

                    {!isLocked && (
                      <div>
                        <ViewFileUploader
                          recordVersion={recordVersion}
                          fieldName={field.name}
                          label="Upload Attachment"
                          isMultiple={field.is_multiselect}
                          triggerAction={
                            isQA && field.name === "final_report"
                              ? triggerAction
                              : null
                          }
                          // @ts-expect-error TS(2322) FIXME: Type 'false | ((ids: any, fileNames: any, fileData... Remove this comment to see the full error message
                          handleAddFile={
                            (isShareDetailView || isNewRecord) && handleAddFile
                          }
                          hasReadWriteAccess={isShareDetailView || isNewRecord}
                        />
                      </div>
                    )}
                  </div>

                  <div className="section-content">
                    {isShareDetailView || isNewRecord ? (
                      <SectionTagViewDocument
                        fieldName={field.name}
                        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                        files={files[field.name] || []}
                        handleRemoveFile={handleRemoveFile}
                      />
                    ) : (
                      <ViewDocuments
                        recordVersion={recordVersion}
                        fieldName={field.name}
                        description="No attachments added yet."
                        descriptionIcon
                        // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
                        isRecordLocked={isLocked}
                        moduleIdentifier={moduleIdentifier}
                      />
                    )}
                  </div>
                </section>
              );
            })}

            {isIM && !isNewRecord && (
              <ViewActionPlansSummary
                recordVersionID={recordVersionID}
                isLocked={isLocked as boolean}
              />
            )}

            {(isIM || isRR) && !isNewRecord && (
              <ViewModuleControlSummary
                moduleIdentifier={moduleIdentifier}
                recordVersionID={recordVersionID}
                isLocked={isLocked as boolean}
                isSummaryAndOperational={isRR}
              />
            )}

            {isIM && !isNewRecord && (
              <>
                <ViewOperationalControlSummary
                  moduleIdentifier={moduleIdentifier}
                  recordVersionID={recordVersionID}
                  isLocked={isLocked as boolean}
                />
                <ViewRelatedRisksSummary
                  recordVersionID={recordVersionID}
                  isLocked={isLocked as boolean}
                />
                <PreviewRelatedFindingList isModuleAdded={isModuleAdded} />
              </>
            )}

            {showApprovers && !isShareDetailView && !isNewRecord && (
              <section className="section-approval">
                <div className="section-header">
                  <h3>{isQA ? "Testers" : "Approvers"}</h3>
                  {!isLocked && (
                    <div className="column-select">
                      <ViewUsersSelect
                        recordVersion={recordVersion}
                        fieldName={isQA ? "testers" : "approvers"}
                        recordVersionID={recordVersionID}
                        withContacts={showContactsApprovers}
                      />
                    </div>
                  )}
                </div>
                <div className="section-content">
                  <ViewApprovedUsers
                    fieldName={isQA ? "testers" : "approvers"}
                    recordVersion={recordVersion}
                    title={isQA ? "Testers" : "Approvers"}
                    // @ts-expect-error TS(2322) FIXME: Type 'false | "No Tester added yet. Click “Select”... Remove this comment to see the full error message
                    description={
                      isQA &&
                      "No Tester added yet. Click “Select” to add users here."
                    }
                    descriptionIcon={isQA && true}
                    // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
                    isLocked={isLocked}
                    showApprovalStatus={!isQA}
                  />
                </div>
              </section>
            )}

            {showAssignee && (
              <section className="section-approval">
                <div className="section-header">
                  <h3>Assignee</h3>
                  {!isLocked && (
                    <div className="column-select">
                      <ViewUsersSelect
                        recordVersion={recordVersion}
                        fieldName="assignee"
                        recordVersionID={recordVersionID}
                        withContacts
                      />
                    </div>
                  )}
                </div>
                <div className="section-content">
                  <ViewAssignedUsers
                    recordVersion={recordVersion}
                    title="Assignee"
                    fieldName="assignee"
                    // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
                    isLocked={isLocked}
                  />
                </div>
              </section>
            )}

            {showControlOwner && (
              <section className="section-approval">
                <div className="section-header">
                  <h3>Control Owners</h3>
                  {!isLocked && (
                    <div className="column-select">
                      <ViewUsersSelect
                        recordVersion={recordVersion}
                        fieldName="control_owners"
                        recordVersionID={recordVersionID}
                        withContacts
                      />
                    </div>
                  )}
                </div>
                <div className="section-content">
                  <ViewAssignedUsers
                    recordVersion={recordVersion}
                    title="Control Owners"
                    fieldName="control_owners"
                    // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
                    isLocked={isLocked}
                  />
                </div>
              </section>
            )}

            {bottomComponentSlot && (
              <section className="tw-order-3">{bottomComponentSlot}</section>
            )}

            {(isShareDetailView || isNewRecord) && (
              <Button
                className="share-detail-view-button"
                onClick={(event) => {
                  onSubmit(event);
                }}
              >
                {isNewRecord ? "Create" : "Submit"}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

DetailView.defaultProps = {
  disabledFields: [],
  fieldList: null,
  ignoredFields: [],
  isHistory: false,
  maxDateFunction: noop,
  minDateFunction: noop,
  showContactsApprovers: false,
};

export default observer(DetailView);

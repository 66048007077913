import React from "react";

function Accordion({
  collapsed,
  children,
}: {
  collapsed: boolean;
  children: React.ReactNode;
}) {
  return (
    <div
      className={`tw-overflow-hidden tw-transition-all tw-ease-in-out ${
        collapsed ? "tw-max-h-0" : "tw-max-h-[100%]"
      }`}
    >
      {children}
    </div>
  );
}

export default Accordion;

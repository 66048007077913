export const issueManagementRecordSource = {
  compliance: "compliance",
  audit: "audit",
  regulatory: "regulatory",
  internal: "internal",
  it_incident: "it_incident",
} as const;
export type IssueManagementRecordSource =
  (typeof issueManagementRecordSource)[keyof typeof issueManagementRecordSource];
export const issueManagementRecordRiskLevel = {
  high: "high",
  medium: "medium",
  low: "low",
} as const;
export type IssueManagementRecordRiskLevel =
  (typeof issueManagementRecordRiskLevel)[keyof typeof issueManagementRecordRiskLevel];
export type IssueManagementRecord = {
  /**
   * @description Title of the Issue
   * @type string
   */
  title: string;
  /**
   * @description Description of the Issue
   * @type string | undefined
   */
  description?: string;
  /**
   * @description Where Issue is coming from
   * @type string | undefined
   */
  source?: IssueManagementRecordSource;
  /**
   * @description Risk level
   * @type string | undefined
   */
  risk_level?: IssueManagementRecordRiskLevel;
  /**
   * @type string | undefined, date
   */
  date_identified?: string;
  /**
   * @type string | undefined, date
   */
  due_date?: string;
  /**
   * @description Array of signed IDS for files directly uploaded to S3
   * @type array | undefined
   */
  supporting_evidence?: string[];
  /**
   * @description ID of SectionTag
   * @type integer | undefined
   */
  section_tag_id?: number;
  /**
   * @description ID of related Issue (ThemisRecord)
   * @type integer | undefined
   */
  related_issue_id?: number;
  /**
   * @description ID of related Finding (ThemisRecord)
   * @type integer | undefined
   */
  related_finding_id?: number;
};

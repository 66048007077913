import React from "react";

import { useMainStore } from "@/contexts/Store";

const VDD_MODULE_WORKSPACE_IDENTIFIER = "vendor_due_diligence";

function ExportQuestionnaire() {
  // stores
  const mainStore = useMainStore();

  // Variables
  const moduleWorkspaceID = mainStore.moduleWorkspaces.list?.find(
    (mw) => mw.themis_module?.identifier === VDD_MODULE_WORKSPACE_IDENTIFIER,
  )?.id;

  // functions
  async function handleClick() {
    const pathnameArray = window.location.pathname.split("/");
    const id = pathnameArray[pathnameArray.length - 2];
    const spreadSheetData = await mainStore.vendors.exportQuestionnaire(
      moduleWorkspaceID,
      id,
    );
    if (!spreadSheetData) {
      return;
    }

    const { file_name: fileName, file_url: fileURL } = spreadSheetData;
    if (!fileName) {
      return;
    }
    if (!fileURL) {
      return;
    }

    const link = document.createElement("a");
    link.href = fileURL;
    link.download = fileName;
    link.click();
  }

  return (
    <button className="gray-button" onClick={() => handleClick()}>
      {" "}
      Export Questionnaire{" "}
    </button>
  );
}

export default ExportQuestionnaire;

import { IconButton } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import type { RecordVersion } from "@/api";
import ConfirmationDialog from "@/components/table/shared/ConfirmationDialog";
import { useMainStore } from "@/contexts/Store";

interface Props {
  recordVersion: RecordVersion;
}

function KeyRiskIndicatorsContextMenu({ recordVersion }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  const history = useHistory();

  // State
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  // Variables
  const recordVersionID = recordVersion.id;
  const { canDeleteRecords } = mainStore.userPermissions;
  const { workspaceID } = mainStore.context;
  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion?.data,
  );
  const isClosed = status === "verified";
  const { taskDetail } = mainStore;

  // Functions
  function noButtonPressed() {
    handlePopUpClose();
    setIsDropdownOpen(false);
  }

  function deleteRowConfirmation() {
    if (isClosed) {
      handlePopUpClose();
    } else {
      setIsDropdownOpen(false);
      setIsDeleteConfirmationOpen(true);
    }
  }

  function handleCreateTask() {
    handlePopUpClose();
    taskDetail.openFromRecord(recordVersion);
  }

  async function deleteRow() {
    const { company } = mainStore.companies;
    if (company && company.id) {
      await mainStore.keyRiskIndicators.delete(recordVersionID);
      await mainStore.keyRiskIndicators.index({
        workspaceID,
      });
    }
  }

  function handlePopUpOpen() {
    setIsOpenModal(true);
  }

  function handlePopUpClose() {
    setIsOpenModal(false);
    setIsDropdownOpen(true);
    setIsDeleteConfirmationOpen(false);
  }

  const goToDetailView = () => {
    history.push(
      `/workspaces/${workspaceID}/modules/key-risk-indicators/${recordVersionID}`,
    );
  };

  const renderTrigger = (
    <IconButton
      variant="vertical"
      color="transparent"
      size="md"
      Icon={PiDotsThreeOutlineVerticalFill}
      data-testid="context-menu-trigger"
      data-tooltip-id="tooltip"
      data-tooltip-content="More Options"
      data-tooltip-place="bottom"
      className={classNames({
        "tw-bg-neutral-500 tw-text-neutral-25 hover:tw-bg-neutral-500":
          isOpenModal,
      })}
    />
  );

  const renderBaseContent = (
    <div
      className="table-dropdown policy-dropdown"
      data-testid="context-menu-dropdown"
    >
      <ul>
        <li onClick={goToDetailView}>View Details</li>
        <li onClick={handleCreateTask}>Create Task</li>
        {canDeleteRecords && (
          <li
            onClick={deleteRowConfirmation}
            className={classNames({ disabled: isClosed })}
            data-testid="context-menu-dropdown-delete"
          >
            Delete
          </li>
        )}
      </ul>
    </div>
  );

  const renderDeleteConfirmation = (
    <ConfirmationDialog
      heading="Delete Key Risk Indicator"
      content="Are you sure you want to delete this key risk indicator? Actions are not reversable."
      handleConfirm={deleteRow}
      handleReject={noButtonPressed}
    />
  );

  return (
    <div className="list-points" data-testid="context-menu">
      <Popup
        position="bottom right"
        trigger={renderTrigger}
        open={isOpenModal}
        onOpen={handlePopUpOpen}
        onClose={handlePopUpClose}
        keepTooltipInside
      >
        {isDropdownOpen && renderBaseContent}

        {isDeleteConfirmationOpen && (
          <div className="table-dropdown success-dropdown">
            {renderDeleteConfirmation}
          </div>
        )}
      </Popup>
    </div>
  );
}

export default observer(KeyRiskIndicatorsContextMenu);

import classNames from "classnames";
import { orderBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import type { Folder as FolderProp } from "@/stores/types/section-tags";

import arrowDownIcon from "../../../images/table-image/icon/arrow-down-black.svg";
import FolderCard from "./FolderCard";
import FolderCreateForm from "./forms/Create";

const SORT_OPTIONS = [
  { name: "A-Z", value: "ascending" },
  { name: "Z-A", value: "descending" },
  { name: "Last Modified", value: "last-modified" },
  { name: "Date Created", value: "date-created" },
];

const maxFolderCountForNonPremiumUser = 75;

interface Props {
  isPremium: boolean;
  folderID?: number;
  moduleWorkspaceID?: number;
  createFolderPopupFromParent?: boolean;
}

const Folders = ({
  isPremium,
  moduleWorkspaceID,
  folderID,
  createFolderPopupFromParent,
}: Props) => {
  const mainStore = useMainStore();

  const list: FolderProp[] = mainStore.sectionTags.list as FolderProp[];
  const { canManageSections } = mainStore.userPermissions;

  const [showSort, setShowSort] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(
    createFolderPopupFromParent === true,
  );
  const [selectedSort, setSelectedSort] = useState(SORT_OPTIONS[0]);

  const folders: FolderProp[] = useMemo(() => {
    if (selectedSort.value === "ascending") {
      return orderBy(list, ["title"], ["asc"]);
    }

    if (selectedSort.value === "descending") {
      return orderBy(list, ["title"], ["desc"]);
    }

    if (selectedSort.value === "last-modified") {
      return orderBy(list, ["updated_at"], ["desc"]);
    }

    return orderBy(list, ["created_at"], ["asc"]);
  }, [list, selectedSort]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'sortOption' implicitly has an 'any' typ... Remove this comment to see the full error message
  const handleSortChange = (sortOption) => {
    setSelectedSort(sortOption);
    setShowSort(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'params' implicitly has an 'any' type.
  const handleCreate = async (params) => {
    params.parent_section_tag_id = folderID || undefined;
    await mainStore.sectionTags.create(moduleWorkspaceID, params);
    setShowCreatePopup(false);
  };

  const isCreateDisabled =
    !isPremium && folders.length >= maxFolderCountForNonPremiumUser;

  useEffect(() => {
    setShowCreatePopup(Boolean(createFolderPopupFromParent));
  }, [createFolderPopupFromParent]);

  return (
    <div className="folders-content">
      <div className="folders-header">
        <div className="title">Folders</div>
        <div className="actions">
          <Popup
            position="bottom right"
            trigger={
              <button
                className={classNames("folder-sorter", { opened: showSort })}
                data-testid="folder-sorter"
              >
                <span>Sort by: {selectedSort.name}</span>
                <img src={arrowDownIcon} alt="open" />
              </button>
            }
            open={showSort}
            onOpen={() => setShowSort(true)}
            onClose={() => setShowSort(false)}
            keepTooltipInside
          >
            <ul className="table-dropdown folders-sort-popup">
              {SORT_OPTIONS.map((sortOption) => (
                <li
                  key={sortOption.value}
                  data-testid={sortOption.value}
                  className={classNames({
                    active: selectedSort.value === sortOption.value,
                  })}
                  onClick={() => handleSortChange(sortOption)}
                >
                  {sortOption.name}
                </li>
              ))}
            </ul>
          </Popup>
          {canManageSections && (
            <Popup
              position="bottom right"
              trigger={
                <button
                  className="folder-create-button"
                  data-testid="folder-create-button"
                >
                  Create Folder
                </button>
              }
              open={showCreatePopup}
              onOpen={() => setShowCreatePopup(true)}
              onClose={() => setShowCreatePopup(false)}
              keepTooltipInside
            >
              {isCreateDisabled ? (
                <div
                  className="table-dropdown folder-create-premium-notice"
                  data-testid="folder-create-premium-notice"
                >
                  <div>Folder Limitation</div>
                  <div>
                    {`You have reached the maximum limit of ${maxFolderCountForNonPremiumUser} folders with your current Themis subscription. Please contact Themis (support@themis.com) to upgrade.`}
                  </div>
                </div>
              ) : (
                <FolderCreateForm onSubmit={handleCreate} />
              )}
            </Popup>
          )}
        </div>
      </div>

      {folders.length === 0 && canManageSections && (
        <div className="folder-cards-empty" data-testid="folder-cards-empty">
          No Folders created yet. Click “Create Folder” to add one here.
        </div>
      )}

      {folders.length > 0 && (
        <div className="folder-cards">
          {folders.map((folder) => (
            <FolderCard
              key={folder.id}
              // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              moduleWorkspaceID={moduleWorkspaceID}
              parentFolderID={folderID}
              folder={folder}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Folders.defaultProps = {
  isPremium: false,
};

export default observer(Folders);

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { deleteTraining } from "../gen/axios/trainingsController/deleteTraining";
import { listTrainings } from "../gen/axios/trainingsController/listTrainings";

export const TRAININGS_QUERY_KEY = {
  all: ["trainings"],
  detail: (trainingId: number) => [...TRAININGS_QUERY_KEY.all, trainingId],
} as const;

export function useTrainings(workspaceId: number) {
  return useQuery({
    queryKey: [...TRAININGS_QUERY_KEY.all],
    queryFn: () => listTrainings(workspaceId),
    enabled: !!workspaceId,
  });
}

export function useDeleteTraining({
  onSuccess = () => {},
  onError = () => {},
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteTraining(id as unknown as string),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: TRAININGS_QUERY_KEY.all,
      });
      onSuccess();
    },
    onError,
  });
}

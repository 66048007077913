import { useQuery } from "@tanstack/react-query";

import type { RecordType } from "@/api/gen/models";

import {
  listThemisCustomAuditsForThemisModule,
  listThemisCustomAuditsForThemisRecord,
} from "../gen/axios/auditsController";

export const AUDIT_TRAIL_QUERY_KEY = "audit_trail";
export const PAGE_SIZE = 10;
export const getOffset = (page: number) => (page - 1) * PAGE_SIZE;

interface AuditTrailParams {
  isRecordView: boolean;
  recordType: string;
  page: number;
  companyID?: number;
  recordID?: number;
  currentAction?: string;
}

export function useAuditTrail({
  isRecordView,
  companyID,
  recordType,
  recordID,
  page,
  currentAction,
}: AuditTrailParams) {
  const recordQuery = useQuery({
    queryKey: [
      AUDIT_TRAIL_QUERY_KEY,
      recordType,
      recordID,
      currentAction,
      page,
    ],
    queryFn: () =>
      listThemisCustomAuditsForThemisRecord(
        recordType as RecordType,
        recordID!,
        {
          offset: getOffset(page),
          action_name: currentAction,
        },
      ),
    enabled: isRecordView && !!recordID,
  });
  const moduleQuery = useQuery({
    queryKey: [
      AUDIT_TRAIL_QUERY_KEY,
      companyID,
      recordType,
      currentAction,
      page,
    ],
    queryFn: () =>
      listThemisCustomAuditsForThemisModule(
        companyID!,
        recordType as RecordType,
        {
          offset: getOffset(page),
          action_name: currentAction,
        },
      ),
    enabled: !isRecordView && !!companyID,
  });
  return isRecordView ? recordQuery : moduleQuery;
}

import "./group-scoring.scss";

import React from "react";

import { Button } from "@/components/Elements";

interface GroupScoringEmptyStateProps {
  onAddGroup: () => void;
}

function GroupScoringEmptyState(props: GroupScoringEmptyStateProps) {
  return (
    <div className="emptystate-wrapper">
      <div className="emptystate">
        <p>
          You do not have any scoring groups. Create a new scoring group to get
          started.
        </p>
        <Button label="Add New Group" onClick={props.onAddGroup} />
      </div>
    </div>
  );
}

export default GroupScoringEmptyState;

import { useEffect } from "react";

export function useClickAwayListener(
  ref: React.RefObject<HTMLElement>,
  onClickAway?: () => void,
) {
  useEffect(() => {
    if (!onClickAway) {
      return;
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        onClickAway &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        onClickAway();
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref.current, onClickAway]);
}

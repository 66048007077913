import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import RiskRegisterReportMatrixContent from "./RiskRegisterReportMatrixContent";
import RiskRegisterReportMatrixHeader from "./RiskRegisterReportMatrixHeader";
import RiskRegisterReportMatrixTable from "./RiskRegisterReportMatrixTable";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matrix: any;
}

function RiskRegisterReportMatrixContainer({ matrix }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { data } = mainStore.riskRegisters;
  const { currentSection } = mainStore.riskRegisters;
  const recordVersions = currentSection?.id
    ? data?.record_versions.filter(
        (rv) => rv.section_tag_id === currentSection.id,
      )
    : data?.record_versions;

  return (
    <div className="report-rr-matrix-container">
      <RiskRegisterReportMatrixHeader name={matrix.name} />
      <RiskRegisterReportMatrixContent
        matrix={matrix}
        recordVersions={recordVersions}
      />
      <RiskRegisterReportMatrixTable
        matrix={matrix}
        recordVersions={recordVersions}
      />
    </div>
  );
}

export default observer(RiskRegisterReportMatrixContainer);

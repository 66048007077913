import type { TableFilters, TableName } from "@/api";
import {
  kriListUrl,
  kriSummaryUrl,
} from "@/features/key-risk-indicators/urlPaths";
import { getTableFiltersParam } from "@/stores/helpers/getTableFiltersParam";
import type { MainStore } from "@/stores/Main";
import type { ModuleFieldOptions } from "@/stores/types/field-types";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";
import { pathMatchesPattern } from "@/utils/routing";

export function moduleDataLoader(
  themisModuleIdentifier: ModuleIdentifier,
  tableName: TableName | null,
  mainStore: MainStore,
  tableFilters?: TableFilters,
) {
  // Variables
  const { workspaceID, moduleWorkspaceID } = mainStore.context;
  const { folderID } = mainStore.documents;
  const { page } = mainStore.finraComplaints;

  let tab = null;
  let documentParams;

  const filters = tableFilters || getTableFiltersParam();

  // Helpers
  function loadSectionTags(params = {}) {
    if (moduleWorkspaceID) {
      mainStore.sectionTags.index(moduleWorkspaceID, params, true);
    }
  }

  function loadFieldData() {
    if (moduleWorkspaceID) {
      const fieldOptions: ModuleFieldOptions =
        mainStore.moduleWorkspaces.list.find(
          (mws) => mws.themis_module.identifier === themisModuleIdentifier,
        )?.field_options;
      mainStore.fieldOptions.setAll(fieldOptions);
    }
  }

  switch (themisModuleIdentifier) {
    case "policy":
      if (
        tableName &&
        [
          "Drafts",
          "Finalized",
          "PoliciesAttestation",
          "PoliciesLibrary",
        ].includes(tableName)
      ) {
        const databaseTableName =
          tableName === "PoliciesAttestation" ? "Finalized" : tableName;
        mainStore.policies.index({
          workspaceID,
          tableName: databaseTableName,
          tableFilters: filters,
        });
        loadSectionTags();
      }
      break;

    case "procedures":
      if (
        tableName &&
        [
          "ProceduresDrafts",
          "ProceduresFinalized",
          "ProceduresAttestation",
        ].includes(tableName)
      ) {
        const databaseTableName =
          tableName === "ProceduresAttestation"
            ? "ProceduresFinalized"
            : tableName;
        mainStore.procedures.index({
          workspaceID,
          tableName: databaseTableName,
          tableFilters: filters,
        });
        loadSectionTags();
      }
      break;

    case "issue_management":
      loadSectionTags();
      loadFieldData();
      break;

    case "marketing":
      tab = "Active";
      if (location.pathname.endsWith("review-process")) {
        tab = "ReviewProcess";
      }
      if (location.pathname.endsWith("completed")) {
        tab = "Completed";
      }

      mainStore.creatives.index({
        workspaceID,
        tab,
        tableFilters: filters,
      });
      loadSectionTags();
      break;

    case "new_product_approval":
      mainStore.changeManagements.index({
        workspaceID,
        tableFilters: filters,
      });
      loadSectionTags();
      break;

    case "conflicts_of_interest":
      mainStore.conflicts.index({
        workspaceID,
        tableFilters: filters,
      });
      break;

    case "training":
      tab = "All";
      if (location.pathname.endsWith("published")) {
        tab = "Published";
      }

      mainStore.trainings.index({
        workspaceID,
        tab,
        tableFilters: filters,
      });
      loadSectionTags();
      break;

    case "documents":
      documentParams = {
        workspaceID,
        tableFilters: filters,
        folderID: folderID || undefined,
      };

      if (location.pathname.endsWith("search")) {
        mainStore.documents.search(documentParams);
        loadSectionTags();
      } else {
        mainStore.documents.index(documentParams);
        loadSectionTags({ parent_section_tag_id: folderID || null });
      }
      break;

    case "complaints":
      if (!tableName) {
        break;
      }

      if (location.pathname.endsWith("completed")) {
        tab = "Completed";
      }

      mainStore.complaints.index({
        workspaceID,
        tableName,
        tab: tab || undefined,
        tableFilters: filters,
      });
      loadSectionTags();
      break;

    case "audits":
      mainStore.themisAudits.index({
        workspaceID,
      });
      loadSectionTags();
      break;

    case "vendor_due_diligence":
      mainStore.vendors.index({
        workspaceID,
        tableFilters: filters,
      });
      loadSectionTags();
      break;

    case "risk_register":
      mainStore.riskRegisters.index({
        workspaceID,
        tableFilters: filters,
      });
      loadSectionTags();
      break;

    case "finra":
      if (!tableName) {
        break;
      }

      mainStore.finraComplaints.index({
        workspaceID,
        tableName,
        page,
        tableFilters: filters,
      });
      break;

    case "customer_support":
      if (!tableName) {
        break;
      }

      mainStore.customerSupport.index({
        workspaceID,
        tableName,
        tableFilters: filters,
      });
      break;

    case "qa_tests_development":
      if (
        location.pathname.endsWith("procedures") ||
        location.pathname.includes("view-issues") ||
        location.pathname.includes("view-procedures") ||
        location.pathname.includes("samples")
      ) {
        return;
      }

      mainStore.qa.index({
        workspaceID,
        tableFilters: filters,
      });
      break;

    case "control_mapping":
      tab = "Active";
      if (location.pathname.endsWith("completed")) {
        tab = "Completed";
      }

      mainStore.controlMappings.index({
        workspaceID,
        tab,
        tableFilters: filters,
      });

      loadSectionTags();
      break;

    case "risk_assessment":
      break;

    case "zendesk":
      if (!tableName) {
        break;
      }

      mainStore.zendesk.index({
        workspaceID,
        tableName,
        tableFilters: filters,
      });

      loadSectionTags();
      break;
    case "key_risk_indicators":
      if (
        pathMatchesPattern(location.pathname, kriListUrl) ||
        pathMatchesPattern(location.pathname, kriSummaryUrl)
      ) {
        mainStore.keyRiskIndicators.index({
          workspaceID,
          tableFilters: filters,
        });
      }
      loadSectionTags();
      break;
    default:
      window.console.warn(
        `WARNING: Unhandled data loading for Module "${themisModuleIdentifier}"`,
      );
      break;
  }
}

import React from "react";

export const PageContent = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => {
  return (
    <div
      ref={ref}
      className="tw-flex tw-h-full tw-flex-grow tw-flex-col tw-overflow-auto tw-bg-neutral-25 tw-px-6 tw-pb-10 tw-pt-6"
    >
      {children}
    </div>
  );
});

PageContent.displayName = "PageContent";

import "../../stylesheets/components/shared/popup/announcement-popup.scss";

import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { Button, Icon, IconButton, TextButton, Typography } from "../Elements";

function AnnouncementPopup() {
  const mainStore = useMainStore();
  const announcement = mainStore.announcements.announcement.forPopup;
  const history = useHistory();

  const handleShowMeClick = (): void => {
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    history.push(announcement.pathToContent);
    dismissAnnouncement();
  };

  const dismissAnnouncement = () => {
    // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
    mainStore.announcements.dismissAnnouncement(announcement.localStorageKey);
  };

  return (
    <div className="announcement-popup">
      <div className="announcement-popup_header">
        <div className="announcement-popup_header-text">
          <Icon
            className="announcement-popup_header-announcement-icon"
            name="congrats"
            color="generalWhite"
          />
          <Typography label="Announcement" color="generalWhite" size="xs" />
        </div>
        <IconButton
          data-testid="close-button"
          className="announcement-popup_header-close-button"
          icon="close"
          transparent
          color="generalWhite"
          onClick={dismissAnnouncement}
        />
      </div>
      <Typography
        // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
        label={announcement.title}
        color="generalWhite"
        className="announcement-popup_title"
        size="md"
        weight="bold"
      />
      <Typography
        // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
        label={announcement.textContent}
        color="generalWhite"
        className="announcement-popup_text-content"
        size="sm"
        weight="medium"
      />
      <div className="announcement-popup_footer">
        {/* @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'. */}
        {announcement.pathToContent && (
          <Button
            label="Show Me"
            theme="secondary"
            onClick={handleShowMeClick}
          />
        )}
        <TextButton label="Skip" theme="white" onClick={dismissAnnouncement} />
      </div>
    </div>
  );
}

export default observer(AnnouncementPopup);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const CheckedLinear = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H19ZM19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4ZM9.73754 16.683C9.94793 16.8948 10.226 17 10.5013 17C10.7779 17 11.0546 16.8948 11.265 16.683L18.1039 9.84409C18.5261 9.42189 18.5261 8.73886 18.1039 8.31666C17.6817 7.89445 16.9986 7.89445 16.5764 8.31666L10.5013 14.3904L7.84409 11.7361C7.42189 11.3139 6.73886 11.3139 6.31666 11.7361C5.89445 12.1583 5.89445 12.8413 6.31666 13.2635L9.73754 16.683Z"
      fill="#353549"
    />
  </svg>
);

CheckedLinear.displayName = "CheckedLinearIcon";

export default CheckedLinear;

import React, { useState } from "react";
import { useParams } from "react-router-dom";

import Button from "@/components/form/Button";
import { useMainStore } from "@/contexts/Store";
import themisLogo from "@/images/logo.svg";

function InviteExpired() {
  // Import MobX stores
  const mainStore = useMainStore();

  const [email, setEmail] = useState("");
  const [isInviteSent, setIsInviteSent] = useState(false);

  const { invite_token, user_id } = useParams<{
    invite_token: string;
    user_id: string;
  }>();

  function handleReinvite() {
    mainStore.users.resend_invite_confirmation(invite_token, user_id, email);
    setIsInviteSent(true);
  }

  return (
    <div className="check-in" style={{ marginTop: "10%" }}>
      <div className="check-in-wrap">
        <div className="email-verification">
          <div className="invite-expired">
            <div className="header-sign-up">
              <a href="/">
                <img src={themisLogo} alt="themis-logo" />
              </a>
            </div>
            <h2 className="experation-title">Email Verification Expired</h2>
            <p className="experied-invitation-text">
              Seems like your email link has expired. Please enter your email
              address below and click the Request new invitation link button
              below.
            </p>
            <p className="experied-invitation-text">
              If a user matches that email address, we will send you a new link.
            </p>
            {isInviteSent && (
              <p className="experied-invitation-text">
                An email sent to the address provided.
              </p>
            )}
            <div className="email-body">
              {!isInviteSent && (
                <>
                  <p className="expired-invitation-input-label">
                    Company Email Address
                  </p>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="expired-invitation-email-input"
                    data-testid="invitation-expired-email-input"
                  />
                </>
              )}
              <Button
                className="reinvite-button"
                title={
                  isInviteSent
                    ? "Verification Link Requested"
                    : "Request new verification link"
                }
                onClick={handleReinvite}
                enabled={email?.length > 0 && !isInviteSent}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteExpired;

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteThemisDocumentMutationResponse,
  DeleteThemisDocumentPathParams,
} from "../../models/DeleteThemisDocument";

/**
 * @description Delete document
 * @summary Delete document
 * @link /themis_documents/:id
 */
export async function deleteThemisDocument(
  id: DeleteThemisDocumentPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteThemisDocumentMutationResponse>["data"]> {
  const res = await client<DeleteThemisDocumentMutationResponse>({
    method: "delete",
    url: `/themis_documents/${id}`,
    ...options,
  });
  return res.data;
}

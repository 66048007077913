import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmPopup } from "@/components/confirm-popup";
import { Flex, Icon, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import { Divider, IconMenu, IconMenuItem } from "@/components/IconMenu";
import Checkbox from "@/components/table/shared/Checkbox";
import { UsersCircle } from "@/components/users-circle";
import { useMainStore } from "@/contexts/Store";
import type { TaskableType, UserComment } from "@/stores/types/comment-types";

function CommentHeader({
  comment,
  commentChecked,
  onCommentChecked,
  setEditCommentMode,
  onDeleteComment,
  taskableType,
}: {
  comment: UserComment;
  commentChecked: boolean;
  editCommentMode: boolean;
  onCommentChecked: React.ChangeEventHandler<HTMLInputElement>;
  setEditCommentMode(value: boolean): void;
  onDeleteComment(): void;
  taskableType: TaskableType;
}) {
  const mainStore = useMainStore();
  const history = useHistory();
  const { taskDetail } = mainStore;

  const [showDeleteCommentPopup, setShowDeleteCommentPopup] = useState(false);

  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const currentUserID = mainStore.users.user.id;
  const isDeleteOnly = [
    "Question",
    "QuestionGroup",
    "QuestionnaireSummary",
  ].includes(taskableType);
  const isUserAuthor = comment.author.id === currentUserID;

  const showViewTask = !!currentUserID && isUserAuthor;

  const formattedCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(comment.created_at),
    { removeAt: true, monthFormat: "short" },
  );

  const subscribeLabel = comment.is_subscribed_to_thread
    ? "Unsubscribe"
    : "Subscribe";

  function handleClickEdit() {
    setEditCommentMode(true);
  }

  function handleClickDelete() {
    setShowDeleteCommentPopup(true);
  }

  function handleUpdateSubscription() {
    mainStore.comments.updateSubscription(
      comment.id,
      comment.is_subscribed_to_thread,
    );
  }

  function handleViewTask() {
    history.push(`/notifications/tasks/${comment.id}/edit`);
  }

  function handleCopyLink() {
    mainStore.comments.copyCommentLink(comment);
  }

  return (
    <Flex justifySpaceBetween>
      <Flex alignCenter columnGap={8}>
        <UsersCircle
          initials={comment.author.initials}
          iconColorIndex={comment.author.icon_color_index}
        />
        <Flex column>
          <Typography
            label={comment.author.full_name}
            weight="semiBold"
            color="generalMidnightDark"
            size="sm"
          />
          <Typography
            label={formattedCreateDate}
            weight="semiBold"
            color="extrasBlueGrayDarker"
            size="xs"
          />
        </Flex>
      </Flex>
      <Flex alignCenter>
        {!isDeleteOnly && (
          <Checkbox
            small
            padding
            disabled={isCurrentWorkspaceArchived || !comment.user_can_update}
            data-testid={`resolve-task-${comment.id}`}
            checked={commentChecked}
            onChange={onCommentChecked}
          />
        )}
        {isDeleteOnly && <Icon name="trash" onClick={handleClickDelete} />}
        {!isDeleteOnly && isCurrentWorkspaceActive && (
          <IconMenu triggerTestId="comment-context-menu" icon="moreHorizontal">
            {comment.user_can_update_content && (
              <IconMenuItem label="Edit" onClick={handleClickEdit} />
            )}
            {showViewTask && (
              <IconMenuItem label="View Comment" onClick={handleViewTask} />
            )}
            <IconMenuItem label="Copy Link" onClick={handleCopyLink} />
            <IconMenuItem
              label="Create Task"
              onClick={() => taskDetail.openFromComment(comment)}
            />
            <Divider />
            <IconMenuItem
              label={subscribeLabel}
              onClick={handleUpdateSubscription}
            />
            {comment.user_can_destroy && (
              <>
                <Divider />
                <IconMenuItem label="Delete" onClick={handleClickDelete} />
              </>
            )}
          </IconMenu>
        )}
        <ConfirmPopup
          title="Delete comment alert"
          content="Are you sure you want to remove this comment?"
          showPopup={showDeleteCommentPopup}
          setShowPopup={setShowDeleteCommentPopup}
          onConfirmClick={onDeleteComment}
        />
      </Flex>
    </Flex>
  );
}

export default observer(CommentHeader);

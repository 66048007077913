import React, { useContext } from "react";

import mainStore from "../stores/Main";

/**
 * Prefer useMainStore()
 */
export const StoreContext = React.createContext(mainStore);

export const useMainStore = () => useContext(StoreContext);

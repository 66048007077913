import "../../stylesheets/components/shared/popup/app-popup.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import AnnouncementPopup from "./AnnouncementPopup";
import ReloadPopup from "./ReloadPopup";

function AppPopup() {
  const mainStore = useMainStore();
  const [disconnected, setDisconnected] = useState(false);
  const location = useLocation();
  const { newVersion } = mainStore.toast;

  const token = mainStore.webSessions.getAccessToken();

  const handleVisibilityChange = () => {
    const { socket } = mainStore.users;

    // @ts-expect-error TS(2339) FIXME: Property 'connected' does not exist on type 'never... Remove this comment to see the full error message
    if (!document.hidden && !socket?.connected && token?.length > 0) {
      setDisconnected(true);
      window.console.log("Broadcasting Disconnected");
    } else {
      setDisconnected(false);
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  let extraClass = "";
  let renderedPopup: JSX.Element | null = null;
  if (newVersion) {
    renderedPopup = (
      <ReloadPopup
        title="New Version Available"
        text="An improved version of Themis is now available. Please reload this window now to update."
      />
    );
  } else if (disconnected) {
    renderedPopup = (
      <ReloadPopup
        title="Could not connect to Themis"
        text="You need to reload the page to continue having a great experience."
        onClose={() => setDisconnected(false)}
      />
    );
  } else if (
    mainStore.announcements.announcement.forPopup &&
    ["home", "modules", "workspace-reports", "settings", "notifications"].some(
      (path) => location.pathname.includes(path),
    )
  ) {
    renderedPopup = <AnnouncementPopup />;
    extraClass = "themis-app-popup--announcement";
  }

  return token && renderedPopup ? (
    <div className={`themis-app-popup ${extraClass}`}>{renderedPopup}</div>
  ) : null;
}

export default observer(AppPopup);

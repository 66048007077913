import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Dashboard = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="3" y="13" width="8" height="8" rx="2" fill="#353549" />
    <rect x="3" y="3" width="8" height="8" rx="2" fill="#353549" />
    <rect x="13" y="3" width="8" height="8" rx="2" fill="#353549" />
    <rect x="13" y="13" width="8" height="8" rx="2" fill="#353549" />
  </svg>
);

Dashboard.displayName = "DashboardIcon";

export default Dashboard;

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import {
  MonitoringGroupChildrenDetails,
  MonitoringGroupChildResults,
  MonitoringGroupDetails,
} from "./MonitoringGroupDetails";
import { MonitoringGroups } from "./MonitoringGroups";

export const WEBSITE_MONITORING_ROUTES = {
  base: "/website_monitoring",
  monitoringGroups: "/monitoring_groups",
  monitoringGroupResults: "/:monitoringGroupAssetId/monitoring_group_results",
  monitoringGroupAssetChildren:
    "/:monitoringGroupAssetId/monitoring_group_children",
  monitoringGroupAssetChildResults:
    "/:monitoringGroupAssetChildId/monitoring_group_child_results",
} as const;

export function WebsiteMonitoringRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${WEBSITE_MONITORING_ROUTES.monitoringGroups}${WEBSITE_MONITORING_ROUTES.monitoringGroupResults}`}
      >
        <MonitoringGroupDetails />
      </Route>
      <Route
        exact
        path={`${path}${WEBSITE_MONITORING_ROUTES.monitoringGroups}`}
      >
        <MonitoringGroups />
      </Route>
      <Route
        path={`${path}${WEBSITE_MONITORING_ROUTES.monitoringGroups}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildren}`}
      >
        <MonitoringGroupChildrenDetails />
      </Route>
      <Route
        path={`${path}${WEBSITE_MONITORING_ROUTES.monitoringGroups}${WEBSITE_MONITORING_ROUTES.monitoringGroupAssetChildResults}`}
      >
        <MonitoringGroupChildResults />
      </Route>
    </Switch>
  );
}

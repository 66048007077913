import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";

export default class FinraContactsStore {
  mainStore;

  // Observable objects
  current = {};

  // @ts-expect-error TS(7006) FIXME: Parameter 'mainStore' implicitly has an 'any' type... Remove this comment to see the full error message
  constructor(mainStore) {
    makeObservable(this, {
      current: observable,
      setCurrent: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/finra_contacts/:user_id
  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async show(userID) {
    if (!userID) {
      window.console.warn(
        `FinraContacts#show => userID(${userID}) should be present!`,
      );
      return;
    }

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/finra_contacts/${userID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.setCurrent({});
        return;
      }
      this.setCurrent(response.data.finra_contact);
    } catch (error) {
      this.setCurrent({});
      window.console.log(`FinraContacts#show => error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  async set(userID, finraContactParams) {
    if (!userID || !finraContactParams) {
      window.console.warn(
        `FinraContacts#set => userID(${userID}) and finraContactParams(${JSON.stringify(
          finraContactParams,
        )}) should be present!`,
      );
      return;
    }

    const data = {
      finra_contact: finraContactParams,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/finra_contacts/${userID}/set`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.setCurrent(response.data.finra_contact);

      return response;
    } catch (error) {
      this.setCurrent({});
      window.console.log(`FinraContacts#set => error ${error}`);
    }
  }

  // Actions

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    if (value) {
      this.current = value;
    } else {
      this.current = {};
    }
  }

  // Store Helpers

  cleanup() {
    this.setCurrent({});
  }
}

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

interface Props {
  name: string;
  onChange: (name: string, value: string | number) => void;
  onPopUpOpen?: () => void;
  options: { value: string | number; label: string }[];
  placeholder?: string;
  value?: string | number;
}

function ItemsSelect({
  name,
  value,
  options,
  onChange,
  placeholder,
  onPopUpOpen,
}: Props) {
  // State
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | number | undefined>(
    value,
  );

  // Effects
  useEffect(() => {
    setSelectedItem(value);
    if (value) {
      onChange(name, value);
    }
  }, [value]);

  // functions
  function handlePopUpOpen() {
    onPopUpOpen?.();
    setShowPopup(true);
  }

  function handlePopUpClose() {
    setShowPopup(false);
  }

  function selectItem(item: number | string) {
    setSelectedItem(item);
    onChange(name, item);
    handlePopUpClose();
  }

  // Variables
  const currentOption = options.find((item) => item.value === selectedItem);

  // elements
  const renderTrigger = (
    <div
      className="options custom-select"
      data-testid={`items-select-trigger-${name}`}
    >
      {currentOption ? (
        <div className="options" key={currentOption.value}>
          <span className="value value-product">{currentOption.label}</span>
        </div>
      ) : (
        placeholder
      )}
    </div>
  );

  const renderContent = (
    <div
      className="options-dropdown custom-select-dropdown"
      data-testid="items-select-dropdown"
    >
      <div className="options-dropdown-list">
        <ul>
          <div>
            {options.map((item) => {
              const optionClasses = classNames({
                active: item.value === selectedItem,
              });

              return (
                <li
                  key={item.value}
                  className={optionClasses}
                  onClick={() => {
                    selectItem(item.value);
                  }}
                >
                  <span className="item">{item.label}</span>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      {renderContent}
    </Popup>
  );
}

ItemsSelect.defaultProps = {
  options: [],
  placeholder: "",
  onPopUpOpen: () => {},
};

export default ItemsSelect;

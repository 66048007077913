var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from "react";
import { filterCellProps } from "../../../lib/utils";
import { Checkbox } from "../../Form/Checkbox";
var CheckboxCell = function (_a) {
    var _b;
    var value = _a.value, onValueChange = _a.onValueChange, props = __rest(_a, ["value", "onValueChange"]);
    var filteredProps = filterCellProps(__assign({ value: value, onValueChange: onValueChange }, props));
    var editable = Boolean((_b = props.colDef) === null || _b === void 0 ? void 0 : _b.editable);
    useEffect(function () {
        if (onValueChange && editable) {
            onValueChange(!value);
            props.stopEditing();
        }
    }, []);
    return (React.createElement(Checkbox, __assign({}, filteredProps, { className: "tw-m-auto", checked: !!value })));
};
CheckboxCell.displayName = "CheckboxCell";
export default CheckboxCell;

import "./row-options.scss";

import { IconButton } from "@themis/ui";
import classnames from "classnames";
import React, { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

import PopupMenu from "../PopupMenu/PopupMenu";
import PopupMenuItem from "../PopupMenu/PopupMenuItem";

export interface Option {
  label?: string;
  onClick?: () => void;
  submenu?: {
    label: string;
    menu: React.ReactNode;
  };
  disabled?: boolean;
  hide?: boolean;
  disabledTooltip?: string;
  divider?: boolean;
}

interface RowOptionsProps {
  options: Array<Option>;
  disabled?: boolean;
  disabledTooltip?: string;
  iconOverrideClass?: string;
}

const RowOptions = ({
  options,
  disabled = false,
  disabledTooltip = "",
}: RowOptionsProps) => {
  const [open, setOpen] = useState(false);
  const [submenu, setSubmenu] = useState<{
    label: string;
    menu: React.ReactNode;
  } | null>(null);

  if (options.length === 0) {
    return null;
  }

  if (disabled) {
    return (
      <div
        data-tooltip-id="tooltip"
        data-tooltip-place="right"
        data-tooltip-content={disabledTooltip}
      >
        <IconButton
          variant="vertical"
          color="transparent"
          size="md"
          Icon={PiDotsThreeOutlineVerticalFill}
          disabled
          className={classnames({
            "tw-bg-neutral-500 tw-text-neutral-25": open,
          })}
        />
      </div>
    );
  }
  return (
    <div className="row-options">
      <PopupMenu
        anchorNode={
          <IconButton
            variant="vertical"
            color="transparent"
            size="md"
            Icon={PiDotsThreeOutlineVerticalFill}
            onClick={() => setOpen(true)}
            className={classnames({
              "tw-bg-neutral-500 tw-text-neutral-25": open,
            })}
          />
        }
        open={open}
        placement="left top"
        onClose={() => {
          setOpen(false);
          setSubmenu(null);
        }}
        goBackHeaderLabel={submenu?.label}
        onGoBack={() => setSubmenu(null)}
        showSubMenu={!!submenu}
        subMenu={submenu?.menu}
        mainMenu={options
          .filter((option) => !option.hide && option.divider !== false)
          .map((option, index) => (
            <PopupMenuItem
              key={`popup-menu-item-${index}`}
              label={option.label}
              onClick={() => {
                if (option.submenu) {
                  setSubmenu(option.submenu);
                } else {
                  setSubmenu(null);
                }
                if (option.onClick) {
                  option.onClick();

                  setOpen(false);
                }
              }}
              divider={option.divider}
            />
          ))}
      />
    </div>
  );
};

export default RowOptions;

import type { AxiosResponse } from "axios";
import axios from "axios";
import { uniqBy } from "lodash";
import { action, makeObservable, observable } from "mobx";

import type { Field } from "@/api";
import { tablesService } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import {
  additionalFieldOptions,
  additionalFields,
} from "@/stores/constants/NotificationsRulesConstants";

import type { MainStore } from "../Main";
import type { IndexResponse, ModuleFieldOptions } from "../types/field-types";
import type {
  NotificationRuleData,
  NotificationsRulesStoreData,
} from "../types/notification-rule-types";

export default class NotificationsRulesStore {
  mainStore: MainStore;

  data: NotificationsRulesStoreData = {};
  currentFieldOptions: ModuleFieldOptions = {};
  fieldsForSelectedModule: Field[] = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      fieldsForSelectedModule: observable,
      currentFieldOptions: observable,
      setData: action,
      setFieldsForSelectedModule: action,
      setCurrentFieldOptions: action,
    });

    this.mainStore = mainStore;
  }

  /**
   * GET /api/react/module_workspaces/:id/notification_rules
   */
  async index(moduleWorkspaceId: number) {
    try {
      const response = await legacyApi.get<
        IndexResponse<{ notification_rules: NotificationRuleData[] }>
      >(
        `${API_URL}/module_workspaces/${moduleWorkspaceId}/notification_rules`,
        {
          headers: this.mainStore.getHeaders(),
        },
      );

      const newResponse = { ...response.data };

      newResponse.field_options = {
        ...newResponse.field_options,
        ...additionalFieldOptions,
      };

      this.setData(newResponse);

      this.mainStore.fieldOptions.setAll(newResponse.field_options);
    } catch (error) {
      window.console.error(
        `"NotificationsRules#index for Company" error ${error}`,
      );
    }
  }

  async delete(id: string, moduleWorkspaceId: number) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any> = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/notification_rules/${id}`,
        headers: this.mainStore.getHeaders(),
      });

      if (axios.isAxiosError(response)) {
        throw response.data.errors.base;
      }

      this.index(moduleWorkspaceId);

      return true;
    } catch (error) {
      this.mainStore.toast.setErrorText(
        "An error occurred. Could not delete notification",
      );
      window.console.log(`"NotificationRules#destroy" error ${error}`);
    }
  }

  /**
   * GET /api/react/module_workspaces/:module_workspace_id/field_options
   * Sets value for `currentFieldOptions` in store
   */
  async getModuleFieldOptions(moduleWorkspaceID: number) {
    try {
      const response = await legacyApi.get<{
        field_options: ModuleFieldOptions;
      }>(`${API_URL}/module_workspaces/${moduleWorkspaceID}/field_options`, {
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setCurrentFieldOptions(response.data.field_options);
    } catch (error) {
      window.console.log(
        `"NotificationRules#getModuleFieldOptions" error ${error}`,
      );
    }
  }

  /**
   * Sets value for `fieldsForSelectedModule` in store
   */
  async getFields(moduleWorkspaceID: number) {
    const tables = await tablesService.listTables(moduleWorkspaceID);
    this.setFieldsForSelectedModule(
      uniqBy(tables.map((table) => table.fields).flat(), (field) => field.name),
    );
  }

  setData(value: NotificationsRulesStoreData) {
    this.data = value;

    if (this.data?.fields && Object.keys(this.data?.fields).length > 0) {
      this.mainStore.fields.setList([
        ...(this.data.fields || []).filter((field) => field !== null),
        ...additionalFields,
      ]);
    }
    if (
      this.data?.field_options &&
      Object.keys(this.data?.field_options).length > 0
    ) {
      this.mainStore.fieldOptions.setAll({
        ...this.data.field_options,
        ...additionalFieldOptions,
      });
    }
  }

  setFieldsForSelectedModule(value: Field[]) {
    this.fieldsForSelectedModule = value;
  }

  setCurrentFieldOptions(value: ModuleFieldOptions) {
    this.currentFieldOptions = value;
  }

  cleanup() {
    this.setData({});
    this.setFieldsForSelectedModule([]);
    this.setCurrentFieldOptions({});
    this.mainStore.fields.cleanup();
  }
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetTagPathParams,
  GetTagQueryResponse,
} from "../../models/GetTag";

/**
 * @description Get tag
 * @summary Get tag
 * @link /tags/:tag_id
 */
export async function getTag(
  tagId: GetTagPathParams["tag_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetTagQueryResponse>["data"]> {
  const res = await client<GetTagQueryResponse>({
    method: "get",
    url: `/tags/${tagId}`,
    ...options,
  });
  return res.data;
}

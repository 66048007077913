import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import PolicyProcedureContextMenu from "@/components/table/policy/PolicyProcedureContextMenu";
import { useMainStore } from "@/contexts/Store";

function ProceduresActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();
  const { current: currentRecordVersion } = mainStore.recordVersions;

  // Hooks
  const location = useLocation();

  // Variables
  const path = location.pathname;
  const procedureID = Number(path.substring(path.lastIndexOf("/") + 1));

  const moduleWorkspaceID = mainStore.procedures.data?.module_workspace_id;
  const procedure =
    currentRecordVersion?.id === procedureID
      ? currentRecordVersion
      : mainStore.recordVersions.list.find(
          (recordVersion) => recordVersion.id === procedureID,
        );

  if (!procedure) {
    return null;
  }

  const approvedByUserIds =
    procedure.reviews?.map((approval) => approval.user_id) || [];

  return (
    <PolicyProcedureContextMenu
      approvedByUserIds={approvedByUserIds}
      moduleWorkspaceID={moduleWorkspaceID}
      recordVersion={procedure}
      tableID={procedure.table_id}
      tableName={procedure.table_name}
      moduleStore={mainStore.procedures}
      isPolicy={false}
      withoutBackground
      showOnlyIcon={false}
    />
  );
}

export default observer(ProceduresActionBar);

import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import Popup from "reactjs-popup";

export default function IdleTimer() {
  const [showIdle, setShowIdle] = useState(false);

  const onPrompt = () => {
    setShowIdle(true);
  };

  const onActive = () => {
    setShowIdle(false);
  };

  const onAction = () => {
    setShowIdle(false);
    if (idleTimer.isPrompted() && showIdle) {
      idleTimer.reset();
    }
  };

  const idleTimer = useIdleTimer({
    onPrompt,
    onActive,
    onAction,
    timeout: 1000 * 60 * 60,
    promptTimeout: 1000 * 60 * 20,
  });

  return (
    <div>
      {showIdle && (
        <Popup position="center center" open={showIdle} keepTooltipInside>
          <div className="idle-dropdown">
            <p className="idle-dropdown-block">
              You have not interacted with this page in a while. You will be
              logged out if the session stays idle.
            </p>
          </div>
        </Popup>
      )}
    </div>
  );
}

import type { FilterFieldData } from "../hooks/useFilterSort";
import { CHILD_STATUSES } from "./status";

export const childrenFilterData: FilterFieldData<Record<string, string>> = {
  child_status: {
    displayName: "Status",
    type: "string",
    options: CHILD_STATUSES,
  },
};

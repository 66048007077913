import { useEffect, useRef } from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
export default usePrevious;

import { observer } from "mobx-react";
import React from "react";

import ReportsPage from "../common/ReportsPage";

const ControlMappingReportContent = () => (
  <ReportsPage
    identifier="control_mapping"
    defaultDateColumn="created_at"
    defaultGroupColumn="control_status"
  />
);

export default observer(ControlMappingReportContent);

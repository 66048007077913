import "./external-questionnaire-form.scss";

import { observer } from "mobx-react";
import type { ChangeEvent, FocusEvent } from "react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ExternalVendorQuestionnaireApi } from "@/api/legacy/external-vendor-questionnaire";
import { Button, Typography } from "@/components/Elements";
import PageErroredReload from "@/components/errors/PageErroredReload";
import Loading from "@/components/Loading";
import ChecklistQuestion from "@/features/vendor-due-diligence/components/VendorChecklistQuestion";
import VendorQuestionnaireHighlights from "@/features/vendor-due-diligence/components/VendorQuestionnaireHighlights";
import { canCompleteChecklist } from "@/features/vendor-due-diligence/helpers";
import ThemisLogo from "@/images/logo.svg";
import type {
  ChecklistQuestion as TChecklistQuestion,
  Template,
} from "@/stores/types/vendor-types";

function VendorQuestionnaireForm() {
  const { questionnaireExternalId } = useParams<{
    questionnaireExternalId: string;
  }>();
  const [form, setForm] = useState<Template | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState("");
  const [isQuestionnaireCompleted, setIsQuestionnaireCompleted] =
    useState(false);

  function handleSwitchClick(id: number, currentAnswer: string) {
    let answer: string | null = null;

    if (!currentAnswer) {
      answer = "Yes";
    } else if (currentAnswer === "Yes") {
      answer = "No";
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: Template | null) => { app... Remove this comment to see the full error message
    setForm((prev) => {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const new_questions = [...prev.checklist_questions];
      const question = new_questions.find((item) => item.id === id);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      question.answer = answer;
      const newForm = { ...prev };
      newForm.checklist_questions = new_questions;
      return newForm;
    });
  }

  function handleNAClick(id: number, e: ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: Template | null) => { app... Remove this comment to see the full error message
    setForm((prev) => {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const new_questions = [...prev.checklist_questions];
      const question = new_questions.find((item) => item.id === id);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      question.answer = checked ? "N/A" : null;
      const newForm = { ...prev };
      newForm.checklist_questions = new_questions;
      return newForm;
    });
  }

  function handleLocalOnBlur(
    e: FocusEvent<HTMLTextAreaElement, Element>,
    checklistQuestion: TChecklistQuestion,
  ) {
    // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: Template | null) => { app... Remove this comment to see the full error message
    setForm((prev) => {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const new_questions = [...prev.checklist_questions];
      const question = new_questions.find(
        (item) => item.id === checklistQuestion.id,
      );
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      question.comment = e.target.value;
      const newForm = { ...prev };
      newForm.checklist_questions = new_questions;
      return newForm;
    });
  }

  function handleAttachment(file: string, itemId: number) {
    // @ts-expect-error TS(2345) FIXME: Argument of type '(prev: Template | null) => { app... Remove this comment to see the full error message
    setForm((prev) => {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      const new_questions = [...prev.checklist_questions];
      const question = new_questions.find((item) => item.id === itemId);
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      question.file = file;
      const newForm = { ...prev };
      newForm.checklist_questions = new_questions;
      return newForm;
    });
  }

  function downloadFile(id: number, name?: string) {
    return ExternalVendorQuestionnaireApi.fetchAttachment(
      questionnaireExternalId,
      id,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      name,
    );
  }

  async function fetchExternalQuestionnaire() {
    try {
      const res = await ExternalVendorQuestionnaireApi.get(
        questionnaireExternalId,
      );
      setForm(res);
      setLoadingError("");
      setIsQuestionnaireCompleted(Boolean(res.completed_at));
    } catch {
      setLoadingError("Something went wrong, please reload the page.");
      setForm(null);
    }
  }

  useEffect(() => {
    fetchExternalQuestionnaire();
  }, [questionnaireExternalId]);

  async function onSave(): Promise<void> {
    setIsSaving(true);

    try {
      const data = {
        checklist: {
          // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
          checklist_questions_attributes: form.checklist_questions.map(
            (item) => ({
              id: item.id,
              answer: item.answer,
              comment: item.comment,
              file: item.file,
            }),
          ),
        },
      };
      await ExternalVendorQuestionnaireApi.update(
        questionnaireExternalId,
        data,
      );
      window.location.reload();
    } finally {
      setIsSaving(false);
    }
  }

  const onSubmit = async (): Promise<void> => {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    if (!canCompleteChecklist(form.checklist_questions)) {
      return;
    }

    setIsSaving(true);
    const data = {
      checklist: {
        completed_at: Date(),
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        checklist_questions_attributes: form.checklist_questions.map(
          (item) => ({
            id: item.id,
            answer: item.answer,
            comment: item.comment,
            file: item.file,
          }),
        ),
      },
    };
    await ExternalVendorQuestionnaireApi.update(questionnaireExternalId, data);
    setIsSaving(false);

    window.location.reload();
  };

  if (loadingError) {
    return <PageErroredReload loadingError={loadingError} />;
  }

  return (
    <div className="vendor-questionnaire-form">
      {form && (
        <>
          <img src={ThemisLogo} alt="themis-logo" />
          <Typography
            label="Review your Questionnaire Below"
            size="md"
            color="generalMidnightDark"
            className="questionnaire-form__heading-text"
          />
          <Typography
            label={form.name}
            className="questionnaire-form__name"
            size="lg"
            color="generalMidnightDark"
            weight="bold"
          />
          <div className="vendor-questionnaire-main-container">
            <VendorQuestionnaireHighlights
              checklistQuestions={form.checklist_questions}
            />
            <div className="questionnaire-form__questions">
              {form.checklist_questions.map((question, i) => (
                <ChecklistQuestion
                  key={i}
                  item={question}
                  isQuestionnaireCompleted={isQuestionnaireCompleted}
                  handleSwitchClick={handleSwitchClick}
                  handleNAClick={handleNAClick}
                  handleLocalOnBlur={handleLocalOnBlur}
                  handleAttachment={handleAttachment}
                  downloadFile={downloadFile}
                  isExternal
                />
              ))}
            </div>
          </div>
          <div className="questionnaire-form__save-buttons">
            {!isQuestionnaireCompleted && (
              <>
                <Button
                  disabled={isSaving}
                  label="Save Responses"
                  onClick={onSave}
                  theme="tertiary"
                />
                <Button
                  label="Submit Questionnaire"
                  onClick={onSubmit}
                  disabled={
                    !canCompleteChecklist(form.checklist_questions || [])
                  }
                />
              </>
            )}
          </div>
        </>
      )}
      {!form && <Loading loadingLayout="small-table" />}
    </div>
  );
}

export default observer(VendorQuestionnaireForm);

import classNames from "classnames";
import type { ChangeEventHandler } from "react";
import React from "react";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any;
  name: string;
  id?: string;
  placeholder: string;
  datatestid?: string;
  defaultValue?: string;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

function TextInput({
  inputRef,
  name,
  id,
  placeholder,
  datatestid,
  defaultValue,
  onBlur,
  onChange,
  className,
}: Props) {
  return (
    <div className={classNames("form-input", className)}>
      <input
        type="text"
        id={id}
        ref={inputRef}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
        defaultValue={defaultValue}
        data-testid={datatestid}
      />
    </div>
  );
}

export default TextInput;

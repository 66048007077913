import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import { useMainStore } from "@/contexts/Store";

import closeIcon from "../../../images/table-image/icon/close-black-icon.svg";
import searchIcon from "../../../images/table-image/icon/search-black-two.svg";
import { userColors } from "../../constants";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedUserIDs?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectedUserIDs?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setWorkspaceStep?: (...args: any[]) => any;
}

const InviteUsersToWorkspace = ({
  selectedUserIDs,
  setSelectedUserIDs,
  setWorkspaceStep,
}: Props) => {
  // Import MobX stores
  const mainStore = useMainStore();
  const defaultUsersIDs = mainStore.users.users
    .filter((u) => u.is_company_admin)
    .map((u) => u.id);

  // State
  const [query, setQuery] = useState("");
  const [searchUser, setSearchUser] = useState(false);

  // Effects
  // Pre-load users
  useEffect(() => {
    const activeWorkspaceID = mainStore.users.user.active_workspace?.id;
    if (activeWorkspaceID) {
      mainStore.users.indexForSettings({ workspaceID: activeWorkspaceID });
    }
  }, [mainStore.users.user.active_workspace]);

  // Refs
  const userRef = useRef(null);

  // Variables
  const filteredUsers = mainStore.users.users
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    .filter((user) => !selectedUserIDs.includes(user.id))
    .filter((user) => !defaultUsersIDs.includes(user.id))
    .filter((user) => user.id !== mainStore.users.user.id)
    .filter(
      (searchItems) =>
        searchItems.full_name?.toLowerCase().includes(query.toLowerCase()),
    );
  const buttonClasses = classNames({ active: true });

  // Hooks
  useEffect(() => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    function handleClickOutside(event) {
      // @ts-expect-error TS(2339) FIXME: Property 'contains' does not exist on type 'never'... Remove this comment to see the full error message
      if (userRef.current && !userRef.current.contains(event.target)) {
        setSearchUser(false);
      }
    }

    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, []);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addUserById(id) {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setSelectedUserIDs([...selectedUserIDs, id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function removeUserById(id) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const newUsersSelection = selectedUserIDs.filter(
      (element) => element !== id,
    );
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setSelectedUserIDs(newUsersSelection);
    setSearchUser(false);
  }

  // Renderers
  const listUsersElements = filteredUsers.slice(0, 1).map((elem) => (
    <div key={elem.id}>
      {filteredUsers
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        .filter((element) => !selectedUserIDs.includes(element.id))
        .map((user) => {
          const style = { background: userColors[user.icon_color_index] };
          return (
            <div
              className="list-block"
              key={user.id}
              onClick={() => {
                addUserById(user.id);
              }}
            >
              <div className="users">
                <div className="users-circle" style={style}>
                  <span>{user.initials}</span>
                </div>
                <div className="user-full-name">{user.full_name}</div>
              </div>
            </div>
          );
        })}
    </div>
  ));

  return (
    <>
      <div className="setting-workspace-progressbar">
        <div className="progressbar">
          <CircularProgressbar value={25} strokeWidth={15} />
        </div>
        <p>Invite Users to Workspace</p>
      </div>
      <p className="setup-workspace-text">
        Add team members to setup the workspace, you can
        <br /> always add / remove users later.
        <br /> You are already a member of this workspace.
        <br /> Company Super Admins and Company Admins are already members of
        this workspace.
      </p>
      <div className="workspace-add-users">
        <div className="add-users-input">
          <input
            placeholder="Add Internal Users"
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onClick={() => setSearchUser(true)}
          />
          {query.length > 0 ? (
            <span>
              <img
                src={closeIcon}
                alt="close-icon"
                onClick={() => setQuery("")}
              />
            </span>
          ) : (
            <span>
              <img src={searchIcon} alt="search-icon" />
            </span>
          )}
        </div>
        {searchUser && filteredUsers.length > 0 && (
          <div className="email-popup-wrap" ref={userRef}>
            {listUsersElements}
          </div>
        )}
      </div>
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {selectedUserIDs.length > 0 && (
        <div className="list-wrap-block">
          {mainStore.users.users
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            .filter((element) => selectedUserIDs.includes(element.id))
            .map((user) => {
              const style = { background: userColors[user.icon_color_index] };
              return (
                <div key={user.id} className="list-block">
                  <div className="users">
                    <div className="users-circle" style={style}>
                      <span>{user.initials}</span>
                    </div>
                    <div className="user-full-name">{user.full_name}</div>
                  </div>
                  <div
                    onClick={() => {
                      removeUserById(user.id);
                    }}
                  >
                    <img src={closeIcon} alt="close-icon" />{" "}
                  </div>
                </div>
              );
            })}
        </div>
      )}
      <div className="green-block">
        If you wish to create a new user this must be done in your internal
        workspace. Any new users can be added to the collaborative workspace
        once they join.
      </div>
      <div className="workspace-buttons">
        {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        <button className="back" onClick={() => setWorkspaceStep(1)}>
          Back
        </button>
        {/* @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
        <button className={buttonClasses} onClick={() => setWorkspaceStep(3)}>
          Continue
        </button>
      </div>
    </>
  );
};

export default observer(InviteUsersToWorkspace);

import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import Popup from "reactjs-popup";

import { Button, Flex, Typography } from "@/components/Elements";
import {
  formatDate,
  formatDateForCellValue,
  stringToDate,
} from "@/components/helpers/DateFormatters";
import arrowLeft from "@/images/table-image/icon/arrow-left.svg";
import arrowRight from "@/images/table-image/icon/arrow-right.svg";

type SelectDateProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string;
  hasClearButtonVisible?: boolean;
};

function NotificationsDateSelect<T extends FieldValues>({
  placeholder,
  hasClearButtonVisible,
  ...controller
}: SelectDateProps<T>) {
  // State
  const [showPopup, setShowPopup] = useState(false);
  const [date, setDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(null);
  const [dateString, setDateString] = useState(formatDate(date));

  // Hooks
  const { field, fieldState } = useController(controller);

  useEffect(() => {
    if (field.value) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null' is not assignable t... Remove this comment to see the full error message
      setDate(stringToDate(field.value));
      setDateString(formatDate(stringToDate(field.value)));
    }
  }, [field.value]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'newDate' implicitly has an 'any' type.
  const onCalendarChange = (newDate) => {
    setDate(newDate);
    setDateString(formatDate(newDate));
    setShowPopup(false);
    field.onChange(formatDateForCellValue(newDate));
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const onActiveStartDateChange = (value) => {
    setActiveStartDate(value.activeStartDate);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const onInputChange = (event) => {
    const newDateString = event.target.value;

    // Set dateString without any conditions, since it defines the value in input
    setDateString(newDateString);

    // Try to parse a date
    if (newDateString !== null && newDateString !== "") {
      const dateParts = newDateString.split("/");
      const month = Number(dateParts[0]);
      const day = Number(dateParts[1]);
      const year = Number(dateParts[2]);

      if (year >= 2000 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
        const newDate = new Date(year, month - 1, day);

        if (!isNaN(newDate.getTime())) {
          setDate(newDate);
          setActiveStartDate(
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
            new Date(newDate.getFullYear(), newDate.getMonth(), 1),
          );
        }
      }
    }
  };

  const setToday = () => {
    const today = new Date();
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
    setActiveStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
    onCalendarChange(today);
  };

  const clearDate = () => {
    onCalendarChange(null);
  };

  // Elements
  const modalTrigger = (
    <div
      className={classNames("notification-select", {
        ["notification-select--error"]: fieldState.error,
      })}
    >
      <Typography
        className={classNames({
          ["notification-select__placeholder"]: !field.value,
          ["notification-select__placeholder--error"]: fieldState.error,
        })}
        label={
          field.value ? formatDate(stringToDate(field.value)) : placeholder
        }
        size="md"
        color={field.value ? "accentsSkyBlue" : "extrasBlueGrayDarker"}
      />
    </div>
  );

  return (
    <Popup
      trigger={modalTrigger}
      position="bottom left"
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
    >
      <div
        className="select-small-wrap calendar-wrap"
        data-testid="date-cell-popup"
      >
        <input
          className="input-date"
          data-testid="date-cell-input"
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          value={dateString}
          onChange={onInputChange}
        />
        <Calendar
          // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Date | unde... Remove this comment to see the full error message
          activeStartDate={activeStartDate}
          onActiveStartDateChange={onActiveStartDateChange}
          formatShortWeekday={(_, weekday) =>
            ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][weekday.getDay()]
          }
          value={date}
          onChange={onCalendarChange}
          prevLabel={<img src={arrowLeft} alt="image" />}
          nextLabel={<img src={arrowRight} alt="image" />}
          minDate={new Date()}
        />
        <Flex
          justifyCenter
          columnGap={14}
          className="calendar-wrap__buttons-container"
        >
          <Button
            label="Set Today"
            data-testid="date-cell-set-today"
            onClick={setToday}
            type="button"
            size="sm"
          />
          {hasClearButtonVisible && (
            <Button
              label="Clear"
              onClick={clearDate}
              type="button"
              theme="tertiary"
              size="sm"
            />
          )}
        </Flex>
      </div>
    </Popup>
  );
}

export default NotificationsDateSelect;

import { Button, TextArea } from "@themis/ui";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { PiChatCircleDotsBold, PiPlusBold } from "react-icons/pi";

import type {
  AssessmentIndex,
  BaseQuestionnaire,
  QuestionGroupRead,
} from "@/features/risk-assessment/types";

type SummaryTypeKeys = "assessment" | "questionGroup" | "questionnaire";
interface SummaryContentProps {
  assessment?: AssessmentIndex;
  questionGroup?: QuestionGroupRead;
  questionnaire?: BaseQuestionnaire;
  summary: string;
  onChangeSummary: (parentSummaryId: number, summary: string) => void;
  summaryType: SummaryTypeKeys;
}
export default function SummaryContent(props: SummaryContentProps) {
  const [summaryValue, setSummaryValue] = useState(props.summary);
  const [activeTextArea, setActiveTextArea] = useState(false);
  const [height, setHeight] = useState(28);
  const [focusHeight, setFocusHeight] = useState(28);
  const [isFocused, setIsFocused] = useState(false);

  const summaryTypes: { [key in SummaryTypeKeys]: number | undefined } = {
    assessment: props.assessment?.id,
    questionGroup: props.questionGroup?.id,
    questionnaire: props.questionnaire?.id,
  };

  const parentSummaryId = summaryTypes[props.summaryType];
  const smallTableClass = props.summaryType === "assessment";
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    calculateHeight();
  }, []);

  function setSummaries() {
    if (parentSummaryId) {
      props.onChangeSummary(parentSummaryId, summaryValue);
      setActiveTextArea(false);
    }
  }

  const calculateHeight = () => {
    const lineHeight = 20;
    const lineCount = Math.floor(
      (textAreaRef.current?.scrollHeight || lineHeight) / lineHeight,
    );

    const newHeight = lineCount >= 2 ? 43 : 28;
    const newFocusHeight = lineCount >= 4 ? 27 + 15 * 4 : 13 + 15 * lineCount;

    setHeight(newHeight);
    setFocusHeight(newFocusHeight);
  };

  const textAreaClass =
    "tw-left-5 tw-mr-[25px] tw-relative tw-resize-none tw-overflow-hidden tw-p-1 tw-border-none";
  const iconClass = "tw-left-3 tw-relative tw-top-[7px]";

  const summaryButton = summaryValue ? (
    <PiChatCircleDotsBold
      className={classNames(
        iconClass,
        "tw-h-5 tw-w-5 tw-items-center tw-justify-between",
      )}
    />
  ) : (
    <PiPlusBold className={classNames(iconClass, "w-flex tw-h-4 tw-w-4")} />
  );

  return (
    <div
      className={classNames(
        "tw-relative tw-flex tw-min-h-2.5 tw-max-w-[98%] tw-flex-row tw-items-start",
        {
          "tw-pb-2.5": smallTableClass,
          "tw-left-[15px]": !smallTableClass,
          "tw-pt-4": props.summaryType !== "questionnaire",
          "tw-bottom-3": props.summaryType === "questionnaire",
        },
      )}
    >
      {summaryButton}
      <TextArea
        key="summary-textarea"
        ref={textAreaRef}
        name="summary"
        placeholder="Add Summary"
        onChange={(element) => {
          setSummaryValue(element.target.value);
          setActiveTextArea(true);
          calculateHeight();
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          textAreaRef.current?.scrollTo(0, 0);
        }}
        value={summaryValue}
        style={{
          height: isFocused ? `${focusHeight}px` : `${height}px`,
          minHeight: isFocused ? `${focusHeight}px` : `${height}px`,
        }}
        data-testid="summary-form"
        className={classNames(textAreaClass, {
          "tw-mr-[85px]": !activeTextArea,
          "tw-line-clamp-2": !isFocused,
        })}
      />
      {activeTextArea && (
        <Button
          className={classNames("tw-h-7 tw-min-h-7", {
            "tw-mr-[-12px]": !smallTableClass,
          })}
          color="tertiary"
          data-testid="save-summary"
          onClick={() => setSummaries()}
        >
          Save
        </Button>
      )}
    </div>
  );
}

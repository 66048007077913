import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  QuestionnairePdfPathParams,
  QuestionnairePdfQueryResponse,
} from "../../models/QuestionnairePdf";

/**
 * @description PDF of questionnaire
 * @summary PDF of questionnaire
 * @link /questionnaires_export_pdfs/:id
 */
export async function questionnairePdf(
  id: QuestionnairePdfPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<QuestionnairePdfQueryResponse>["data"]> {
  const res = await client<QuestionnairePdfQueryResponse>({
    method: "get",
    url: `/questionnaires_export_pdfs/${id}`,
    ...options,
  });
  return res.data;
}

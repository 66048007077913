import "./styles.scss";

import React, { useState } from "react";
import Popup from "reactjs-popup";
import type { PopupProps } from "reactjs-popup/dist/types";

import { Button, Flex, Typography } from "@/components/Elements";

export function ConfirmPopup(props: {
  trigger?: PopupProps["trigger"];
  title: string;
  content: React.ReactNode;
  showPopup?: boolean;
  setShowPopup?(value: boolean): void;
  onConfirmClick(): void;
}) {
  const controlled = !!props.setShowPopup;

  const [localShowPopup, localSetShowPopup] = useState(false);

  const { showPopup, setShowPopup } = controlled
    ? {
        showPopup: props.showPopup,
        setShowPopup: props.setShowPopup,
      }
    : {
        showPopup: localShowPopup,
        setShowPopup: localSetShowPopup,
      };

  function handleOpenPopup() {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setShowPopup(true);
  }

  function handleClosePopup() {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setShowPopup(false);
  }

  return (
    <Popup
      trigger={props.trigger || <div />}
      position="bottom right"
      open={showPopup}
      onOpen={handleOpenPopup}
      onClose={handleClosePopup}
      keepTooltipInside
    >
      <div className="confirm-popup__container" role="alertdialog">
        <Flex column rowGap={8}>
          <Typography
            label={props.title}
            size="xs"
            weight="bold"
            color="generalLightBlue"
          />
          <Typography
            label={props.content}
            size="xs"
            weight="bold"
            color="generalDarkGray"
          />
          <Flex alignCenter columnGap={8}>
            <Button
              label="Yes"
              onClick={props.onConfirmClick}
              data-testid="confirm-popup-confirm-button"
            />
            <Button
              label="No"
              theme="tertiary"
              onClick={handleClosePopup}
              data-testid="confirm-popup-cancel-button"
            />
          </Flex>
        </Flex>
      </div>
    </Popup>
  );
}

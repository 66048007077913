/**
 * Creates a comparator function for sorting based on a specified property of objects with an `id` field.
 *
 * @param data - The array of objects to be used for comparison.
 * @param propertyKey - The key of the property to compare.
 * @returns A comparator function that can be used in a column definition.
 */
export function createComparatorById(_a) {
    var data = _a.data, propertyKey = _a.propertyKey;
    var getText = function (id) { var _a; return String(((_a = data.find(function (item) { return item.id === Number(id); })) === null || _a === void 0 ? void 0 : _a[propertyKey]) || ""); };
    return function (idA, idB) { return getText(idA).localeCompare(getText(idB)); };
}

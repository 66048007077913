import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CompanyModuleRecordsReportMutationResponse,
  CompanyModuleRecordsReportPathParams,
  CompanyModuleRecordsReportQueryParams,
} from "../../models/CompanyModuleRecordsReport";

/**
 * @description Generate report for records in all company workspaces for specific module
 * @summary Report for records in all company workspaces for specific module
 * @link /companies/:company_id/modules/:module_identifier/export
 */
export async function companyModuleRecordsReport(
  companyId: CompanyModuleRecordsReportPathParams["company_id"],
  moduleIdentifier: CompanyModuleRecordsReportPathParams["module_identifier"],
  params?: CompanyModuleRecordsReportQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CompanyModuleRecordsReportMutationResponse>["data"]> {
  const res = await client<CompanyModuleRecordsReportMutationResponse>({
    method: "post",
    url: `/companies/${companyId}/modules/${moduleIdentifier}/export`,
    params,
    ...options,
  });
  return res.data;
}

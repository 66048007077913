var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import React from "react";
import { cn } from "../../lib/utils";
var DropdownMenu = DropdownMenuPrimitive.Root;
var DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
var dropdownMenuContentDefaultStyles = "tw-shadow-dropdown tw-font-sans tw-z-50 tw-min-w-32 tw-overflow-hidden tw-rounded-md tw-bg-neutral-25 tw-py-2";
var dropdownMenuContentAnimationStyles = "data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2";
var DropdownMenuContent = React.forwardRef(function (_a, ref) {
    var className = _a.className, _b = _a.sideOffset, sideOffset = _b === void 0 ? 4 : _b, props = __rest(_a, ["className", "sideOffset"]);
    return (React.createElement(DropdownMenuPrimitive.Portal, null,
        React.createElement(DropdownMenuPrimitive.Content, __assign({ ref: ref, sideOffset: sideOffset, className: cn([dropdownMenuContentDefaultStyles, dropdownMenuContentAnimationStyles], className) }, props))));
});
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;
var dropdownMenuItemDefaultStyles = "tw-relative tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-rounded-sm tw-px-4 tw-py-1 tw-outline-none tw-transition-colors";
var dropdownMenuItemFontStyles = "tw-text-sm tw-font-medium tw-text-neutral-500";
var dropdownMenuItemFocusStyles = "focus:tw-bg-primary-25 focus:tw-text-primary-300";
var dropdownMenuItemDisabledStyles = "data-[disabled]:tw-pointer-events-none data-[disabled]:tw-opacity-50";
var DropdownMenuItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, inset = _a.inset, props = __rest(_a, ["className", "inset"]);
    return (React.createElement(DropdownMenuPrimitive.Item, __assign({ ref: ref, className: cn([
            dropdownMenuItemDefaultStyles,
            dropdownMenuItemFontStyles,
            dropdownMenuItemFocusStyles,
            dropdownMenuItemDisabledStyles,
        ], inset && "pl-8", className) }, props)));
});
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;
var dropdownMenuSeparatorStyles = "-tw-mx-1 tw-my-2 tw-h-px tw-bg-neutral-100";
var DropdownMenuSeparator = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement(DropdownMenuPrimitive.Separator, __assign({ ref: ref, className: cn(dropdownMenuSeparatorStyles, className) }, props)));
});
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;
export { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, };

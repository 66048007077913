import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateAccountMutationRequest,
  UpdateAccountMutationResponse,
  UpdateAccountPathParams,
} from "../../models/UpdateAccount";

/**
 * @description Update account
 * @summary Update account
 * @link /workspaces/:workspace_id/accounts/:id
 */
export async function updateAccount(
  workspaceId: UpdateAccountPathParams["workspace_id"],
  id: UpdateAccountPathParams["id"],
  data: UpdateAccountMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateAccountMutationResponse>["data"]> {
  const res = await client<
    UpdateAccountMutationResponse,
    UpdateAccountMutationRequest
  >({
    method: "put",
    url: `/workspaces/${workspaceId}/accounts/${id}`,
    data,
    ...options,
  });
  return res.data;
}

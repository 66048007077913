import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";

interface Props {
  sectionTagID?: number;
  workspaceID?: number | null;
  showPopupParent: boolean;
}

function AddNewButtonPopup({
  workspaceID,
  sectionTagID,
  showPopupParent,
}: Props) {
  const mainStore = useMainStore();
  const history = useHistory();
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  const [showPopup, setShowPopup] = useState(showPopupParent);

  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  async function createNewRiskRegister() {
    if (workspaceID) {
      const recordVersion = await mainStore.riskRegisters.create(
        workspaceID,
        sectionTagID,
      );
      history.push(
        `/workspaces/${workspaceID}/modules/risk-register/${recordVersion.id}`,
      );
    }

    onPopupClose();
  }

  function setSelectedSectionTagID() {
    mainStore.riskRegisters.setSelectedSectionTagID(sectionTagID);
  }

  useEffect(() => {
    setShowPopup(showPopupParent);
  }, [showPopupParent]);

  return (
    <Popup
      trigger={<div className="rr-hidden-trigger" />}
      position="bottom left"
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      <div
        className="risk-register-add-new-popup"
        data-testid="risk-register-table-add-new-popup"
      >
        <Link
          to={`/workspaces/${workspaceID}/modules/risk-register/templates?sectionTagID=${sectionTagID}`}
          onClick={setSelectedSectionTagID}
          data-testid="risk-register-table-add-new-popup-add-from-library"
        >
          Add From Library
        </Link>
        <button
          type="button"
          onClick={createNewRiskRegister}
          data-testid="risk-register-table-add-new-popup-add"
        >
          Add New {getRecordName("risk_register", moduleWorkspaces, true)}
        </button>
      </div>
    </Popup>
  );
}

export default observer(AddNewButtonPopup);

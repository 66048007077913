import "./review-questionnaire-user-assignment.scss";

import React from "react";

import { Button } from "@/components/Elements";

import QuestionnaireBuilderUserAssignment from "../QuestionnaireBuilderUserAssignment/QuestionnaireBuilderUserAssignment";

interface ReviewQuestionnaireUserAssignmentProps {
  selectedIDs: number[];
  setSelectedIDs: (ids: number[]) => void;
  isSaving: boolean;
}

function ReviewQuestionnaireUserAssignment(
  props: ReviewQuestionnaireUserAssignmentProps,
) {
  // eslint-disable-next-line react/no-unstable-nested-components
  const ActionButton = () => {
    return (
      <div className="questionnare-reivew-button-row">
        <Button
          label={props.isSaving ? "Saving" : "Save"}
          size="de"
          disabled={props.isSaving}
        />
      </div>
    );
  };

  return (
    <QuestionnaireBuilderUserAssignment
      selectedIDs={props.selectedIDs}
      setSelectedIDs={props.setSelectedIDs}
      ActionButton={<ActionButton />}
      isSaving={props.isSaving}
    />
  );
}
export default ReviewQuestionnaireUserAssignment;

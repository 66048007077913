import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const NotificationOff = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99995 20C4.29569 20 3.82468 19.2979 4.05973 18.6586L4.10552 18.5528L5.26094 16.242C5.69833 15.3672 5.94701 14.4115 5.99238 13.4369L5.99995 13.1115V10C5.99995 9.81116 6.00867 9.62436 6.02574 9.43998L2.70708 6.12132C2.31655 5.7308 2.31655 5.09763 2.70708 4.70711C3.0976 4.31658 3.73077 4.31658 4.12129 4.70711L19.6776 20.2635C20.0682 20.654 20.0682 21.2871 19.6776 21.6777C19.2871 22.0682 18.6539 22.0682 18.2634 21.6777L16.5858 20H13.9999C13.9999 21.1046 13.1045 22 11.9999 22C10.8954 22 9.99995 21.1046 9.99995 20H4.99995ZM14.5858 18L7.99995 11.4142V13.1115C7.99995 14.3922 7.7266 15.6569 7.19975 16.8213L7.04979 17.1364L6.61798 18H14.5858ZM9.07333 7.27328L7.65853 5.85848C8.53322 4.94183 9.69528 4.3018 11.0001 4.08293L10.9999 3C10.9999 2.44772 11.4477 2 11.9999 2C12.5522 2 12.9999 2.44772 12.9999 3L13.0008 4.08309C15.7593 4.54627 17.8793 6.89172 17.995 9.75347L17.9999 10V13.1115C17.9999 14.0895 18.2049 15.0555 18.6002 15.9474L18.739 16.242L19.4359 17.6359L16.0755 14.2755C16.0403 14.0054 16.0173 13.7334 16.0067 13.4603L15.9999 13.1115V10C15.9999 7.79086 14.2091 6 11.9999 6C10.8445 6 9.80353 6.48988 9.07333 7.27328Z"
      fill="#353549"
    />
  </svg>
);

NotificationOff.displayName = "NotificationOffIcon";

export default NotificationOff;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Email = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 13.4143L12.7071 14.7072C12.3166 15.0977 11.6834 15.0977 11.2929 14.7072L10 13.4143L4.411 19.0033L19.5859 19.0002L14 13.4143ZM21.0001 6.41416L15.4142 12L20.9998 17.5855L21.0001 6.41416ZM8.5858 12L3.00027 6.41445L2.99997 17.5858L8.5858 12ZM12 12.6189L19.5935 4.99683L4.4096 4.99994L12 12.6189ZM3.00091 3H20.9991C22.1042 3 23 3.89262 23 4.99509V19.0049C23 20.1068 22.1035 21 20.9991 21H3.00091C1.89584 21 1.00001 20.1074 1.00001 19.0049V4.99509C1.00001 3.89323 1.89655 3 3.00091 3Z"
      fill="#353549"
    />
  </svg>
);

Email.displayName = "EmailIcon";

export default Email;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Text = (props) => (
  <svg
    width="178"
    height="200"
    viewBox="0 0 178 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.63089 77.6288L109.554 140.514V113.32L49.2052 78.4786L166.957 10.4948C166.957 10.4948 160.856 6.96834 154.682 3.44168C146.792 -1.14723 137.074 -1.14723 129.188 3.44168C94.4399 23.4969 0.63089 77.6288 0.63089 77.6288ZM177.261 16.4442V164.904C177.261 173.997 172.4 182.41 164.514 186.999C158.336 190.525 152.239 194.052 152.239 194.052V58.0843L91.8906 92.926L68.3384 79.3292L177.261 16.4442ZM141.935 74.2299V200C141.935 200 48.1303 145.868 13.3778 125.813C5.49173 121.224 0.63089 112.811 0.63089 103.718V89.5262L118.383 157.51V87.8266L141.935 74.2299Z"
      fill="#070810"
    />
  </svg>
);

Text.displayName = "ThemisLogoIcon";

export default Text;

import { stylesForUserAddedOptions } from "./stylesForUserAddedOptions";

export const getStylesForUserAddedOptions = (itemIndex = 1) => {
  const userAddedOptionsStyles = stylesForUserAddedOptions;

  let chosenStyle = null;
  let index = itemIndex;

  // reduce item's index if it is greater than userAddedOptionsStyles length,
  do {
    chosenStyle = userAddedOptionsStyles[index];

    if (!chosenStyle) {
      index -= userAddedOptionsStyles.length;
      chosenStyle = userAddedOptionsStyles[index];
    }
  } while (!chosenStyle);

  return chosenStyle;
};

export const FOLDER_COLORS_LIGHT = [
  "silver",
  "pink",
  "pastel-blue",
  "yellow",
  "orange",
];
export const FOLDER_COLORS_DARK = [
  "blue",
  "purple",
  "violet",
  "lavender",
  "light-purple",
];
export const FOLDER_COLORS = [...FOLDER_COLORS_LIGHT, ...FOLDER_COLORS_DARK];

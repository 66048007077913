import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Equal = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 16C5 15.4477 5.44772 15 6 15L18 15C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17L6 17C5.44772 17 5 16.5523 5 16ZM5 8C5 7.44771 5.44771 7 6 7L18 7C18.5523 7 19 7.44771 19 8C19 8.55228 18.5523 9 18 9L6 9C5.44771 9 5 8.55228 5 8Z"
      fill="#353549"
    />
  </svg>
);

Equal.displayName = "EqualIcon";

export default Equal;

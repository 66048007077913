import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Tag = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4C3 3.44772 3.44772 3 4 3H13C13.2652 3 13.5196 3.10536 13.7071 3.29289L21.7938 11.3795C22.8794 12.4716 22.8794 14.2355 21.7938 15.3276L21.7917 15.3297L15.3391 21.7822C14.8139 22.3078 14.101 22.6035 13.3581 22.6035C12.6149 22.6035 11.9023 22.308 11.3771 21.7823L3.29329 13.7075C3.10551 13.5199 3 13.2654 3 13V4ZM5 5V12.5855L12.792 20.3688C12.9421 20.5191 13.1457 20.6035 13.3581 20.6035C13.5704 20.6035 13.774 20.519 13.924 20.3689L20.3754 13.9175L20.3762 13.9166C20.6852 13.6049 20.6852 13.1022 20.3762 12.7905L20.3754 12.7896L12.5858 5H5Z"
      fill="#353549"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25 10.5C9.94035 10.5 10.5 9.94035 10.5 9.25C10.5 8.55965 9.94035 8 9.25 8C8.55965 8 8 8.55965 8 9.25C8 9.94035 8.55965 10.5 9.25 10.5Z"
      fill="#353549"
    />
  </svg>
);

Tag.displayName = "TagIcon";

export default Tag;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Download = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9958 2C12.5481 2 12.9958 2.44772 12.9958 3V13.5899L15.7929 10.7929C16.1834 10.4024 16.8166 10.4024 17.2071 10.7929C17.5976 11.1834 17.5976 11.8166 17.2071 12.2071L12.7071 16.7071C12.5113 16.9029 12.2545 17.0005 11.9979 17L11.9958 17C11.6921 17 11.4199 16.8645 11.2365 16.6507L6.79289 12.2071C6.40237 11.8166 6.40237 11.1834 6.79289 10.7929C7.18342 10.4024 7.81658 10.4024 8.20711 10.7929L10.9958 13.5816V3C10.9958 2.44772 11.4436 2 11.9958 2Z"
      fill="#353549"
    />
    <path
      d="M21.9933 16.8834C21.9355 16.386 21.5128 16 21 16C20.4477 16 20 16.4477 20 17V19L19.9933 19.1166C19.9355 19.614 19.5128 20 19 20H5L4.88338 19.9933C4.38604 19.9355 4 19.5128 4 19V17L3.99327 16.8834C3.93551 16.386 3.51284 16 3 16C2.44772 16 2 16.4477 2 17V19L2.00509 19.1763C2.09634 20.7511 3.40232 22 5 22H19L19.1763 21.9949C20.7511 21.9037 22 20.5977 22 19V17L21.9933 16.8834Z"
      fill="#353549"
    />
  </svg>
);

Download.displayName = "DownloadIcon";

export default Download;

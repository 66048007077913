import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { MY_TASKS_ROUTES } from "../pages";

export function TaskDetailView() {
  const { task_id } = useParams<{ task_id: string }>();
  const { taskDetail } = useMainStore();

  useEffect(() => {
    taskDetail.open({ id: Number(task_id) });
  }, [task_id]);

  return <Redirect to={MY_TASKS_ROUTES.assignedToMeView} />;
}

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import Dropdown from "react-dropdown";
import { NavLink, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import RegularLibraryTaxonomySelect from "../shared/RegularLibraryTaxonomySelect";
import RegularTextareaInput from "../shared/RegularTextareaInput";
import RegularTextInput from "../shared/RegularTextInput";

function LibraryItemDetailView() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  // @ts-expect-error TS(2339) FIXME: Property 'item_id' does not exist on type '{}'.
  const { item_id } = useParams();
  const { libraryRecords, libraryItem } = mainStore.controlMappings;

  const categoryTitles = mainStore.controlCategories.controlCategories.map(
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'.
    (cc) => cc.title,
  );

  const controlTypeTitles =
    // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'.
    mainStore.controlMappingTypes.controlMappingTypes.map((ct) => ct.title);

  // effects
  useEffect(() => {
    if (!item_id) {
      return;
    }

    mainStore.controlMappings.setLibraryItem(
      libraryRecords.find((item) => item.id === parseInt(item_id, 10)),
    );
  }, [item_id]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function handleSave(value, field) {
    mainStore.controlMappings.setLibraryItem({
      ...libraryItem,
      [field]: value,
    });
  }

  // elements
  const renderTabs = (
    <div className="settings-links-wrap">
      <ul>
        <li>
          <NavLink to="">Record View</NavLink>
        </li>
      </ul>
    </div>
  );

  if (!libraryItem?.id) {
    return null;
  }

  return (
    <div
      data-testid="detail-view rr-library-detail-view"
      className="rr-library-detail-view"
    >
      {renderTabs}
      <div className="detail-view template">
        <section>
          <div className="section-row two-cols">
            <div className="column column-input" role="custom-column">
              <h4>Framework</h4>
              <RegularLibraryTaxonomySelect
                storeName="tags"
                selectedOptions={libraryItem?.frameworks?.map(
                  (item) => item?.id,
                )}
                columnTitle="tags"
                handleOnClose={(selectedItems) =>
                  handleSave(
                    mainStore.tags.tags.filter((tag) =>
                      selectedItems.includes(tag.id),
                    ),
                    "frameworks",
                  )
                }
                isMultiSelect
                emptyScopeText="Please select Framework"
              />
            </div>
            <div className="column column-input" role="custom-column">
              <h4>Control Category</h4>
              <Dropdown
                className="category-dropdown"
                options={categoryTitles}
                onChange={(value) =>
                  handleSave(value.value, "control_category")
                }
                value={libraryItem.control_category}
              />
            </div>
            <div className="column column-input" role="custom-column">
              <h4>Type</h4>
              <Dropdown
                className="type-dropdown"
                options={controlTypeTitles}
                onChange={(value) =>
                  handleSave(value.value, "control_mapping_type")
                }
                value={libraryItem.control_mapping_type}
              />
            </div>
            <div className="column column-input" role="custom-column">
              <h4>Control Title</h4>
              <RegularTextInput
                initialValue={libraryItem.title}
                width="100%"
                handleOnBlur={(value) => handleSave(value, "title")}
              />
            </div>
            <div className="column column-textarea" role="custom-column">
              <h4>Description</h4>
              <RegularTextareaInput
                initialValue={libraryItem.description}
                width="100%"
                handleOnBlur={(value) => handleSave(value, "description")}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default observer(LibraryItemDetailView);

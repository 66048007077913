import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import type { PermissionSet } from "@/api";

import { Icon } from "../../Elements";

interface Props {
  onDeletePermission: (permissionSetID: string) => void;
  onEditPermission: (permissionSetID: string, clone?: boolean) => void;
  permissionSet: PermissionSet;
}

function PermissionItem({
  permissionSet,
  onEditPermission,
  onDeletePermission,
}: Props) {
  // States
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("main");

  // Hooks
  useEffect(() => {
    if (!showPopup) {
      setViewPopup("main");
    }
  }, [showPopup]);

  const isSuperAdmin = permissionSet.name === "Super Admin";

  // Functions
  const handleShowDeleteConfirm = () => {
    setViewPopup("delete");
  };

  const handleDelete = (permissionSetID: string) => {
    setShowPopup(false);
    onDeletePermission(permissionSetID);
  };

  return (
    <div
      key={permissionSet.id}
      className="permission"
      data-testid="permission-item"
    >
      <div className="permission-name" data-testid="permission-name">
        {permissionSet.name}
      </div>

      <div className="permission-actions">
        {!permissionSet.read_only && (
          <span
            className="permission-tag"
            data-testid="permission-item-custom-tag"
          >
            Custom
          </span>
        )}

        <div className="permission-buttons">
          {!permissionSet.read_only && (
            <Popup
              position="bottom right"
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => (
                <button data-testid="permission-item-popup-trigger">
                  <Icon name="moreHorizontal" />
                </button>
              )}
              open={showPopup}
              keepTooltipInside
              onOpen={() => setShowPopup(true)}
              onClose={() => setShowPopup(false)}
            >
              <div className="permission-popup">
                {viewPopup === "main" && (
                  <div className="permission-popup-menu">
                    <div
                      className="permission-popup-menuitem duplicate"
                      data-testid="permission-clone-menuitem"
                      onClick={() => onEditPermission(permissionSet.id, true)}
                    >
                      <Icon name="duplicate" />
                      Duplicate Role
                    </div>
                    <div
                      className="permission-popup-menuitem delete"
                      data-testid="permission-delete-menuitem"
                      onClick={handleShowDeleteConfirm}
                    >
                      <Icon name="trash" />
                      Delete
                    </div>
                  </div>
                )}

                {viewPopup === "delete" && (
                  <div className="permission-popup-delete-confirm">
                    <div className="permission-popup-delete-confirm-title">
                      Remove Role
                    </div>

                    <div className="permission-popup-delete-confirm-description">
                      Are you sure you want to remove this role? Once removed,
                      your card info will forever removed from this platform.
                    </div>

                    <div className="permission-popup-delete-confirm-actions">
                      <button
                        data-testid="permission-delete-confirm-button"
                        onClick={() => handleDelete(permissionSet.id)}
                      >
                        Yes
                      </button>
                      <button
                        data-testid="permission-delete-cancel-button"
                        onClick={() => setShowPopup(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Popup>
          )}

          {!isSuperAdmin && permissionSet.read_only && (
            <button
              data-testid="permission-clone-button"
              onClick={() => onEditPermission(permissionSet.id, true)}
            >
              <Icon name="duplicate" />
            </button>
          )}

          <button
            data-testid="permission-edit-button"
            onClick={() => onEditPermission(permissionSet.id)}
          >
            <Icon name="chevronRight" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default observer(PermissionItem);

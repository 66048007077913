import classNames from "classnames";
import { flatten } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/check-icon.svg";
import deleteIcon from "../../../images/table-image/icon/close-icon2.svg";
import { Icon } from "../../Elements";
import { getStylesForUserAddedOptions } from "./helpers";

const ERROR_MESSAGES = {
  required: "Missing",
};

// Colors
export const COLORS = {
  Empty: {
    background: "#F5F5F5",
    color: "#8C8C97",
  },
  Vendor: {
    background: "#E5D3FD",
    color: "#3A14A7",
  },
  Partner: {
    background: "#DDFDF0",
    color: "#30A071",
  },
  High: {
    background: "#FDD3DA",
    color: "#E63956",
  },
  Medium: {
    background: "#FBF1DC",
    color: "#FF9F4D",
  },
  Low: {
    background: "#E9EEFF",
    color: "#6D8CF9",
  },
  "Category 1": {
    background: "#E9EEFF",
    color: "#6D8CF9",
  },
  "Category 2": {
    background: "#FBF1DC",
    color: "#FF9F4D",
  },
  "Category 4": {
    background: "#FDD3DA",
    color: "#E63956",
  },
  Weak: {
    background: "#E9EEFF",
    color: "#6D8CF9",
  },
  Minimal: {
    background: "#EBF4FD",
    color: "#4CB2DD",
  },
  Satisfactory: {
    background: "#FBF1DC",
    color: "#FF9F4D",
  },
  Strong: {
    background: "#FDD3DA",
    color: "#E63956",
  },
  default: {
    background: "#E1E1E3",
    color: "#353549",
  },
};

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedOptions: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableOptions?: any[];
  disabled?: boolean;
  emptyOptionText?: string;
  enableAdd?: boolean;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exposeData?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelect?: (...args: any[]) => any;
  helperText?: string;
  isMultiSelect?: boolean;
  titlePlaceholder?: string;
  transformZeroText?: string;
}

function CustomOptionsSelect({
  fieldName,
  selectedOptions,
  handleSelect,
  titlePlaceholder,
  enableAdd,
  helperText,
  isMultiSelect,
  transformZeroText,
  emptyOptionText,
  availableOptions,
  disabled,
  error,
  exposeData,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Refs

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [newOption, setNewOption] = useState("");

  // Variables
  const fieldOptions =
    availableOptions || mainStore.fieldOptions.optionsForField(fieldName);
  const { moduleWorkspaceID } = mainStore.context;

  // Effects
  useEffect(() => {
    const validOptions = fieldOptions.filter((option) =>
      selectedOptions.includes(option.name),
    );
    const validValue = validOptions.length === selectedOptions.length;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSelectedItems(validValue ? selectedOptions : []);
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    exposeData(selectedOptions);
  }, [selectedOptions]);

  const refreshTypes = async () => {
    if (!enableAdd || !moduleWorkspaceID) {
      return;
    }
    await mainStore.fieldOptions.index(moduleWorkspaceID);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      createNewType();
    }
  };

  const createNewType = async () => {
    if (!moduleWorkspaceID) {
      return;
    }

    await mainStore.fieldOptions.create(
      moduleWorkspaceID,
      fieldName,
      newOption,
    );
    setNewOption("");
    refreshTypes();
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  const addItem = (id) => {
    if (!id) {
      setSelectedItems([]);
    } else if (isMultiSelect) {
      // @ts-expect-error TS(2345) FIXME: Argument of type '(prevState: never[]) => any[]' i... Remove this comment to see the full error message
      setSelectedItems((prevState) =>
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        prevState.includes(id)
          ? prevState.filter((item) => item !== id)
          : [...prevState, id],
      );
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type '(prevState: never[]) => any[]' i... Remove this comment to see the full error message
      setSelectedItems((prevState) => (prevState.includes(id) ? [] : [id]));
    }

    // @ts-expect-error TS(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
    setShowPopup(isMultiSelect);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const handleDeleteItemClick = async (e, optionName) => {
    e.stopPropagation();
    if (!moduleWorkspaceID) {
      return;
    }

    await mainStore.fieldOptions.delete(
      moduleWorkspaceID,
      fieldName,
      optionName,
    );
  };

  const onClose = () => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleSelect(selectedItems);
  };

  const handlePopUpOpen = () => {
    refreshTypes();
    setShowPopup(true);
    setIsSelectModalOpen(true);
  };

  const handlePopUpClose = () => {
    onClose();
    setShowPopup(false);
    setIsSelectModalOpen(false);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const getStylesForOption = (item, itemIndex) =>
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    COLORS[item.title] || getStylesForUserAddedOptions(itemIndex);

  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const getStyleForSelectedOption = (option) => {
    if (
      Object.keys(COLORS).includes(option.display_name) ||
      ["new", "completed"].includes(option.display_name.toLowerCase())
    ) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return COLORS[option.display_name];
    }

    const styleIndex = fieldOptions.indexOf(option);

    return styleIndex > -1
      ? getStylesForUserAddedOptions(styleIndex)
      : COLORS.default;
  };

  const renderTrigger = (
    <li
      className={classNames("cell column-options-cell", {
        active: isSelectModalOpen,
        multiselect: isMultiSelect,
      })}
      data-testid="vdd-options-select-trigger"
    >
      <div className="options column-options-container">
        {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
        {error && ERROR_MESSAGES[error] ? (
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          <span>{ERROR_MESSAGES[error]}</span>
        ) : (
          <div className="cell-content">
            {selectedOptions.length === 0 && (
              <p className="options-placeholder nodata">{titlePlaceholder}</p>
            )}

            {selectedOptions.length > 0 &&
              fieldOptions
                .filter((option) =>
                  flatten(selectedOptions).includes(option.name),
                )
                .map((option) => (
                  <div
                    className="options column-options-item"
                    key={option.name}
                  >
                    <span
                      className="value value-type"
                      style={getStyleForSelectedOption(option)}
                    >
                      {option.display_name === "0" && transformZeroText
                        ? transformZeroText
                        : option.display_name}
                    </span>
                  </div>
                ))}

            {helperText && selectedOptions.length > 0 && (
              <span className="vdd-helper-text options">{helperText}</span>
            )}
          </div>
        )}
        {!disabled && (
          <Icon
            name="chevronDown"
            // @ts-expect-error TS(2322) FIXME: Type '"" | "generalError" | undefined' is not assi... Remove this comment to see the full error message
            color={error && "generalError"}
            data-testid="arrow-down-custom-column"
          />
        )}
      </div>
    </li>
  );

  const renderContent = (
    <div className="select" data-testid="vdd-options-select-popup-content">
      {enableAdd && (
        <div className="select-add-new-form">
          <input
            type="text"
            placeholder="Add New +"
            value={newOption}
            data-testid="column-option-add-new-input"
            onChange={(event) => setNewOption(event.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={createNewType}>
            <Icon name="plus" />
          </button>
        </div>
      )}
      <div className="select-dropdown">
        <ul
          className="select-options"
          data-testid="column-options-dropdown-list"
        >
          {fieldOptions?.length === 0 && (
            <li>
              <h4 className="select-no-options-message">No results found</h4>
            </li>
          )}

          {emptyOptionText && (
            // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
            <li className="select-option" onClick={() => addItem(null, "N/A")}>
              <div className="select-option-label-wrapper">
                <span
                  className="select-option-label"
                  // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
                  style={getStylesForOption({})}
                >
                  {emptyOptionText}
                </span>
              </div>
            </li>
          )}

          {fieldOptions?.map((option, index) => {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            const isSelected = selectedItems.includes(option.name);

            return (
              <li
                key={option.name}
                data-testid="vdd-options-select-item"
                className={classNames("select-option", {
                  "select-option-active": !isMultiSelect && isSelected,
                })}
                onClick={() => addItem(option.name)}
              >
                <div className="select-option-label-wrapper">
                  {isMultiSelect && (
                    <span
                      className={classNames("select-option-checkbox", {
                        checked: isSelected,
                      })}
                    >
                      {isSelected && <img src={checkIcon} alt="check-icon" />}
                    </span>
                  )}
                  <span
                    className="select-option-label"
                    style={getStylesForOption(option, index)}
                  >
                    {option.display_name === "0" && transformZeroText
                      ? transformZeroText
                      : option.display_name}
                  </span>
                </div>
                {!isMultiSelect && isSelected && (
                  <Icon name="check" color="brandingHighlightTurquoise" />
                )}
                {!option.is_used && enableAdd && !isSelected && (
                  <span
                    className="select-option-delete-icon"
                    data-testid="column-option-delete-button"
                    onClick={(event) =>
                      handleDeleteItemClick(event, option.name)
                    }
                  >
                    <img src={deleteIcon} alt="delete-icon" />
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom left"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={showPopup}
      keepTooltipInside
      disabled={disabled}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
    >
      {renderContent}
    </Popup>
  );
}

CustomOptionsSelect.defaultProps = {
  handleSelect: () => {},
  enableAdd: false,
  isMultiSelect: false,
  availableOptions: null,
  disabled: false,
  error: "",
  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  exposeData: (val) => val,
};

export default observer(CustomOptionsSelect);

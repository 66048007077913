import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import DetailView from "@/components/detailView/DetailView";
import { useMainStore } from "@/contexts/Store";

const ViewComplaint: React.FC = () => {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const complaintID = Number(record_version_id);
  const recordVersion = mainStore.recordVersions.current;

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      if (complaintID === recordVersion?.id) {
        return;
      }

      await mainStore.recordVersions.fetch(complaintID);
    };

    fetchData();
  }, [complaintID]);

  if (recordVersion?.table_name === "FINRA Escalated") {
    return (
      <DetailView
        moduleIdentifier="finra"
        ignoredFields={[
          "security_symbol_description",
          "notes",
          "finra_submitter",
          "escalate_to_finra",
          "security_symbol",
          "finra_complaint",
          "related_to",
          "review_status",
          "extra_document",
          "problem_code",
          "product_code",
          "dispute_amount",
        ]}
      />
    );
  }

  return (
    <DetailView
      moduleIdentifier="finra"
      ignoredFields={["escalated_status", "finra_submitter"]}
    />
  );
};

export default observer(ViewComplaint);

import type { AxiosResponse } from "axios";

import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";
import type {
  ModuleWorkspace,
  Workspace,
} from "@/stores/types/workspace-types";

import api from "../api";

interface WorkspacesResponse {
  workspaces: Workspace[];
}

export type WorkspaceCreate = Pick<Workspace, "icon_index" | "name"> & {
  suggested_logo?: string;
  user_ids: number[];
};

export interface WorkspaceCreateRequest {
  workspace: WorkspaceCreate;
  themis_module_identifiers: ModuleIdentifier[];
}

interface WorkspaceRequest {
  workspace: Partial<Workspace>;
}

interface WorkspaceResponse {
  workspace: Workspace;
}

export async function getWorkspaces() {
  const response = await api.get<WorkspacesResponse>("/workspaces");

  return response.data;
}

export async function createWorkspace(
  companyID: number,
  data: WorkspaceCreateRequest,
) {
  const response = await api.post<
    WorkspaceResponse,
    AxiosResponse<WorkspaceResponse>,
    WorkspaceCreateRequest
  >(`/companies/${companyID}/workspaces`, data);

  return response.data;
}

export async function updateWorkspace(
  workspaceId: number,
  data: Partial<Workspace>,
) {
  const response = await api.patch<
    WorkspaceResponse,
    AxiosResponse<WorkspaceResponse>,
    WorkspaceRequest
  >(`/workspaces/${workspaceId}`, { workspace: data });

  return response.data;
}

export async function getModuleWorkspaces(
  workspaceId: number,
): Promise<ModuleWorkspace[]> {
  const response = await api.get<{ module_workspaces: ModuleWorkspace[] }>(
    `/workspaces/${workspaceId}/module_workspaces`,
  );

  return response.data.module_workspaces;
}

import type {
  CustomNotificationRule,
  CustomNotificationSelectedFrequency,
} from "@/api/legacy/custom-notifications/types";
import type { CustomNotificationFormValues } from "@/components/settings/notificationsCenter/custom-notifications/data/types";
import type { TimePeriod } from "@/features/notifications/pages/single/date/types";
import type { DAYS_OF_WEEK } from "@/stores/types/notification-rule-types";

export const serializeFormData = (formData: CustomNotificationFormValues) => {
  const getFrequency = (
    data: CustomNotificationFormValues,
    // @ts-expect-error TS(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
  ): Partial<CustomNotificationSelectedFrequency> => {
    if (data.frequency === "days") {
      return {
        interval: [Number(data.timePeriod.numberOfDailyDays)],
        type: "daily",
      };
    } else if (data.frequency === "weeks") {
      return {
        interval: [Number(data.timePeriod.numberOfWeeks)],
        type: "weekly",
        days: data.timePeriod.recurringWeekdays,
      };
    } else if (data.frequency === "months") {
      return {
        interval: [Number(data.timePeriod.numberOfMonths)],
        type: "monthly",
      };
    } else if (data.frequency === "one_time") {
      return {
        type: "one_time",
      };
    }
  };

  return {
    enabled: formData.enabled,
    frequency: getFrequency(formData),
    recipients: formData.recipients,
    starting_date: formData.starting_date,
    end_date: formData.end_date,
    title: formData.title,
    message: formData.message,
  };
};

const getFrequencyType = (serializedData: Partial<CustomNotificationRule>) => {
  if (!serializedData) {
    return "one_time";
  }

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  if (serializedData.frequency.type === "daily") {
    return "days";
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  } else if (serializedData.frequency.type === "weekly") {
    return "weeks";
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  } else if (serializedData.frequency.type === "monthly") {
    return "months";
  }

  return "one_time";
};

const getTimePeriod = (serializedData: Partial<CustomNotificationRule>) => {
  const timePeriod: TimePeriod = {
    numberOfOneTimeDays: [],
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number'.
    numberOfDailyDays: null,
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number'.
    numberOfWeeks: null,
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number'.
    numberOfMonths: null,
    excludeWeekends: true,
    recurringWeekdays: [],
  };

  if (serializedData) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    if (serializedData.frequency.type === "daily") {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      [timePeriod.numberOfDailyDays] = serializedData.frequency.interval;
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    } else if (serializedData.frequency.type === "weekly") {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      [timePeriod.numberOfWeeks] = serializedData.frequency.interval;
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      timePeriod.recurringWeekdays = serializedData.frequency.days.map(
        (day: string) => day as DAYS_OF_WEEK,
      );
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    } else if (serializedData.frequency.type === "monthly") {
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      [timePeriod.numberOfMonths] = serializedData.frequency.interval;
    }
  }

  return timePeriod;
};

export const deserializeFormData = (
  serializedData: Partial<CustomNotificationRule>,
) => {
  const frequencyType = getFrequencyType(serializedData);
  const timePeriod = getTimePeriod(serializedData);

  return {
    enabled: serializedData.enabled,
    starting_date: serializedData.starting_date,
    end_date: serializedData.end_date,
    title: serializedData.title,
    message: serializedData.message,
    frequency: frequencyType,
    recipients: serializedData.recipients,
    timePeriod,
  };
};

export const formatFrequency = (frequencyValue: string) => {
  const words = frequencyValue.split("_");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1),
  );
  return capitalizedWords.join(" ");
};

import type { ReactNode } from "react";
import React from "react";
import { Route, Switch } from "react-router-dom";

import CompanyHeader from "@/components/settings/CompanyHeader";
import CustomNotifications from "@/components/settings/notificationsCenter/custom-notifications/CustomNotifications";
import CustomNotificationsSinglePage from "@/components/settings/notificationsCenter/custom-notifications/CustomNotificationsSinglePage";
import AllReviews from "@/components/settings/notificationsCenter/reviews/AllReviews";
import EditTask from "@/components/settings/notificationsCenter/tasks/EditTask";

function NotificationsCenter() {
  return (
    <Switch>
      <Route exact path="/notifications/tasks/:task_id/edit">
        <NotificationsTasksReviewsWrapper>
          <EditTask />
        </NotificationsTasksReviewsWrapper>
      </Route>
      <Route exact path="/notifications/reviews">
        <NotificationsTasksReviewsWrapper>
          <AllReviews />
        </NotificationsTasksReviewsWrapper>
      </Route>
      <Route exact path="/notifications/custom-notifications/create">
        <CustomNotificationsSinglePage />
      </Route>
      <Route
        exact
        path="/notifications/custom-notifications/edit/:custom_notification_id"
      >
        <CustomNotificationsSinglePage />
      </Route>
      <Route exact path="/notifications/custom-notifications">
        <NotificationsTasksReviewsWrapper>
          <CustomNotifications />
        </NotificationsTasksReviewsWrapper>
      </Route>
    </Switch>
  );
}

function NotificationsTasksReviewsWrapper(props: { children: ReactNode }) {
  return (
    <div className="dashboard-content">
      <CompanyHeader />
      <div className="dashboard-content-wrap">{props.children}</div>
    </div>
  );
}

export default NotificationsCenter;

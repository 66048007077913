// TODO: Improve this type, it's almost as bad as 'any'
export interface RailsErrors {
  [key: string]: string | string[];
}

export function formatErrors(errors?: RailsErrors): string {
  const formattedErrors = [];

  for (const key in errors) {
    const fieldErrors = errors[key];
    const formattedFieldErrors = Array.isArray(fieldErrors)
      ? fieldErrors.join(", ")
      : fieldErrors;

    if (key === "base") {
      formattedErrors.push(`${formattedFieldErrors}.`);
    } else {
      const capitalizedKey = key[0].toUpperCase() + key.slice(1).toLowerCase();
      formattedErrors.push(`${capitalizedKey} ${formattedFieldErrors}.`);
    }
  }

  return formattedErrors.join("\n");
}

export function containsError(
  errors: Record<string, string | string[]> | null,
  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  fieldName,
  // @ts-expect-error TS(7006) FIXME: Parameter 'errorMessage' implicitly has an 'any' t... Remove this comment to see the full error message
  errorMessage,
): boolean {
  const fieldErrors = errors?.[fieldName] || [];
  return Array.isArray(fieldErrors)
    ? fieldErrors.includes(errorMessage)
    : fieldErrors === errorMessage;
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteTagMutationResponse,
  DeleteTagPathParams,
} from "../../models/DeleteTag";

/**
 * @description Delete tag
 * @summary Delete tag
 * @link /tags/:tag_id
 */
export async function deleteTag(
  tagId: DeleteTagPathParams["tag_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteTagMutationResponse>["data"]> {
  const res = await client<DeleteTagMutationResponse>({
    method: "delete",
    url: `/tags/${tagId}`,
    ...options,
  });
  return res.data;
}

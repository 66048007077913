import dayjs from "dayjs";
import { cloneDeep, isEmpty, pickBy } from "lodash";
import { action, computed, makeObservable, observable } from "mobx";

import type {
  ColorScheme,
  CountByDateMutationRequest,
  CountByStatusMutationRequest,
} from "@/api";
import { reportsService, tablesService } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";
import { Dashboards } from "@/components/reports/common/MetricsPage/types";
import type { Workspace } from "@/stores/types/workspace-types";

import { API_URL } from "../../components/constants";
import { formatDate, waitForCondition } from "../../components/reports/helpers";
import { selectFields as qaSelectFields } from "../../components/reports/qa/data";
import type { MainStore } from "../Main";
import type { ModuleIdentifier } from "../types/module-workspaces-types";

const DEFAULT_COLOR_SCHEME = [
  {
    title: "Active",
    background_color: "#DDFDF0",
    text_color: "#30A071",
    id: 1, // id doesn't matter
    border: null,
  },
  {
    title: "Archived",
    background_color: "#F5F5F5",
    text_color: "#8C8C97",
    id: 1,
    border: null,
  },
  {
    title: "Completed",
    background_color: "#C5EEFA",
    text_color: "#21A9D3",
    id: 1,
    border: null,
  },
  {
    title: "Disabled",
    background_color: "#FDE1DD",
    text_color: "#F95B46",
    id: 1,
    border: null,
  },
  {
    title: "In Review",
    background_color: "#E3E3FD",
    text_color: "#5C5FC8",
    id: 1,
    border: null,
  },
  {
    title: "Pending Attachment",
    background_color: "#FDE1DD",
    text_color: "#F95B46",
    id: 1,
    border: null,
  },
  {
    title: "Pending Finalization",
    background_color: "#DDFDF0",
    text_color: "#30A071",
    id: 1,
    border: null,
  },
  {
    title: "Published",
    background_color: "#C5EEFA",
    text_color: "#21A9D3",
    id: 1,
    border: null,
  },
  {
    title: "Ready to Finalize",
    background_color: "#DDFDF0",
    text_color: "#30A071",
    id: 1,
    border: null,
  },
];

export default class ReportStore {
  mainStore: MainStore;

  policies = {};
  complaints = {};
  dateColumnName = "";
  groupFieldName = Dashboards.STATUS;
  qaSelectFields = cloneDeep(qaSelectFields);
  currentFields = [];
  currentFieldOptions = [];
  allCurrentFieldOptions = [];
  recordsByMonthIsReady = false;

  recordsByMonth = [];
  recordsByMonthStackedBar = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordsForMetrics: any = [];
  recordsByFieldOption = {};
  colorSchemes: ColorScheme[] = DEFAULT_COLOR_SCHEME;
  fromValue = dayjs().subtract(1, "year").toDate();
  toValue = dayjs().endOf("month").toDate();
  fieldName: Record<string, string> = {};
  fieldValue: Record<string, string>[] = [];
  selectedWorkspaceIDs: (number | string)[] = [];
  selectAllWorkspaces = false;
  refetchRecordsCount = 0;
  displayedWorkspace: Workspace | "All Workspace" = "All Workspace";

  imSourceGroupName = "source";
  countRecords = 0;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      complaints: observable,
      setComplaints: action,
      qa: computed,
      dateColumnName: observable,
      qaTimeline: computed,
      groupFieldName: observable,
      qaSelectFields: observable,
      currentFields: observable,
      setDateColumnName: action,
      updateQASelectFields: action,
      setGroupFieldName: action,
      setCurrentFields: action,
      setCurrentFieldOptions: action,
      allCurrentFieldOptions: observable,
      setAllCurrentFieldOptions: action,
      recordsByMonth: observable,
      setRecordsByMonth: action,
      recordsForMetrics: observable,
      setRecordsForMetrics: action,
      setRecordsByFieldOption: action,
      setRecordsByMonthStackedBar: action,
      recordsByMonthStackedBar: observable,
      recordsByFieldOption: observable,
      countRecords: observable,
      setCountRecords: action,
      fromValue: observable,
      toValue: observable,
      setFromValue: action,
      setToValue: action,
      fieldName: observable,
      fieldValue: observable,
      setFieldName: action,
      setFieldValue: action,
      selectedWorkspaceIDs: observable,
      setSelectedWorkspaceIDs: action,
      selectAllWorkspaces: observable,
      setSelectAllWorkspaces: action,
      refetchRecordsCount: observable,
      updateRefetchRecordsCount: action,
      displayedWorkspace: observable,
      setDisplayedWorkspace: action,
      imSourceGroupName: observable,
      setIMSourceGroupName: action,
    });
    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersions' implicitly has an 'any'... Remove this comment to see the full error message
  generateReportCount = (recordVersions) => {
    const report = {
      count: {
        total: recordVersions.length,
        new: 0,
        inProgress: 0,
        completed: 0,
      },
      recordVersions: [],
    };

    // @ts-expect-error TS(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
    recordVersions.forEach(({ data }) => {
      const { status } = data;
      switch (status.options[0]) {
        case "new":
          report.count.new += 1;
          break;
        case "completed":
          report.count.completed += 1;
          break;
        case "in_progress":
          report.count.inProgress += 1;
          break;
        default:
          break;
      }
    });

    report.recordVersions = recordVersions;

    return report;
  };

  get qa() {
    const recordVersions = this.mainStore.recordVersions.list.filter(
      (recordVersion) => {
        const startDate = this.mainStore.avroSchemas.valueForField(
          "start_date",
          recordVersion.data,
        );
        const currentFieldValue = this.mainStore.avroSchemas.valueForField(
          this.fieldName?.name,
          recordVersion.data,
        );

        if (currentFieldValue && Object.keys(this.fieldValue).length > 0) {
          return (
            startDate &&
            dayjs(formatDate(startDate)).isAfter(dayjs(this.fromValue)) &&
            dayjs(formatDate(startDate)).isBefore(dayjs(this.toValue)) &&
            currentFieldValue?.includes(this.fieldValue[0]?.name)
          );
        }

        return (
          startDate &&
          dayjs(formatDate(startDate)).isAfter(dayjs(this.fromValue)) &&
          dayjs(formatDate(startDate)).isBefore(dayjs(this.toValue))
        );
      },
    );

    const report = this.generateReportCount(recordVersions);

    return report;
  }

  get qaTimeline() {
    const report: { [key: string]: object[] } = {};
    const groupedByOptions = this.mainStore.fieldOptions.optionsForField(
      this.groupFieldName,
    );

    groupedByOptions.forEach((option) => {
      report[option.display_name] = this.qa.recordVersions.filter(
        (recordVersion) => {
          const currentFieldValue = this.mainStore.avroSchemas.valueForField(
            this.groupFieldName,
            // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
            recordVersion.data,
          );
          return currentFieldValue && currentFieldValue?.includes(option.name);
        },
      );
    });

    const filteredReport = pickBy(
      report,
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      (value: []) => value && value.length > 0,
    );

    const [key] = Object.keys(report);
    const value = report[key];

    return isEmpty(filteredReport) ? { [key]: value } : filteredReport;
  }

  async getReportsByMonthByColumn({
    // @ts-expect-error TS(7031) FIXME: Binding element 'date_column_name' implicitly has ... Remove this comment to see the full error message
    date_column_name,
    // @ts-expect-error TS(7031) FIXME: Binding element 'identifier' implicitly has an 'an... Remove this comment to see the full error message
    identifier,
    // @ts-expect-error TS(7031) FIXME: Binding element 'fromValue' implicitly has an 'any... Remove this comment to see the full error message
    fromValue,
    // @ts-expect-error TS(7031) FIXME: Binding element 'toValue' implicitly has an 'any' ... Remove this comment to see the full error message
    toValue,
    // @ts-expect-error TS(7031) FIXME: Binding element 'tableName' implicitly has an 'any... Remove this comment to see the full error message
    tableName,
    // @ts-expect-error TS(7031) FIXME: Binding element 'field_name' implicitly has an 'an... Remove this comment to see the full error message
    field_name,
    // @ts-expect-error TS(7031) FIXME: Binding element 'field_value' implicitly has an 'a... Remove this comment to see the full error message
    field_value,
  }) {
    this.setRecordsByMonthIsReady(false);
    const params = {
      date_column_name,
      identifier,
      from_value: fromValue,
      to_value: toValue,
      table_name: tableName,
      filter_field_name: field_name,
      filter_field_value: field_value,
      workspace_ids: this.mainStore.context.activeWorkspace?.is_internal
        ? this.selectedWorkspaceIDs
        : [this.mainStore.context.activeWorkspace?.id],
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: "/api/react/report/records_by_month_by_column",
        headers: this.mainStore.getHeaders(),
        params,
      });
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response.response);
      } else {
        this.setRecordsByMonth(response.data);
      }
      this.setRecordsByMonthIsReady(true);
    } catch (err) {
      if (err) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        this.mainStore.toast.setErrorText(err);
        window.console.log(`"Report#index for Policy" error ${err}`);
      }
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  async getReportsByFieldOption(fieldName, identifier, tableName) {
    if (!this.recordsByMonthIsReady) {
      await waitForCondition(() => this.recordsByMonthIsReady, 100);
    }

    const params = {
      column_option_column_name: fieldName,
      identifier,
      table_name: tableName,
      workspace_ids: this.mainStore.context.activeWorkspace?.is_internal
        ? this.selectedWorkspaceIDs
        : [this.mainStore.context.activeWorkspace?.id],
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: "/api/react/report/records_by_column_option",
        headers: this.mainStore.getHeaders(),
        params,
      });
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response.response);
      } else {
        this.setRecordsByFieldOption(response.data);
      }
    } catch (err) {
      if (err) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        this.mainStore.toast.setErrorText(err);
        window.console.log(`"Report#index for Policy" error ${err}`);
      }
    }
  }

  async getFields(moduleWorkspaceID: number) {
    const tables = await tablesService.listTables(moduleWorkspaceID);
    return this.setCurrentFields(tables[0].fields);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async getFieldOptions(moduleWorkspaceID) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/field_options`,
        headers: this.mainStore.getHeaders(),
      });

      if (response.isAxiosError) {
        return;
      }

      this.setCurrentFieldOptions(response.data.field_options);
    } catch (error) {
      window.console.log(
        `"NotificationRules#getModuleFieldOptions" error ${error}`,
      );
    }
  }

  async getRecordsByMonthByColumnByOtherColumn({
    // @ts-expect-error TS(7031) FIXME: Binding element 'workspace_ids' implicitly has an ... Remove this comment to see the full error message
    workspace_ids,
    // @ts-expect-error TS(7031) FIXME: Binding element 'date_column_name' implicitly has ... Remove this comment to see the full error message
    date_column_name,
    // @ts-expect-error TS(7031) FIXME: Binding element 'identifier' implicitly has an 'an... Remove this comment to see the full error message
    identifier,
    // @ts-expect-error TS(7031) FIXME: Binding element 'group_column_name' implicitly has... Remove this comment to see the full error message
    group_column_name,
    // @ts-expect-error TS(7031) FIXME: Binding element 'from_value' implicitly has an 'an... Remove this comment to see the full error message
    from_value,
    // @ts-expect-error TS(7031) FIXME: Binding element 'to_value' implicitly has an 'any'... Remove this comment to see the full error message
    to_value,
    // @ts-expect-error TS(7031) FIXME: Binding element 'table_name' implicitly has an 'an... Remove this comment to see the full error message
    table_name,
    // @ts-expect-error TS(7031) FIXME: Binding element 'field_name' implicitly has an 'an... Remove this comment to see the full error message
    field_name,
    // @ts-expect-error TS(7031) FIXME: Binding element 'field_values' implicitly has an '... Remove this comment to see the full error message
    field_values,
  }) {
    const params = {
      workspace_ids,
      date_column_name,
      identifier,
      group_column_name,
      from_value,
      to_value,
      table_name,
      filter_field_name: field_name,
      filter_field_values: field_values,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: "/api/react/report/records_by_month_by_column_by_other_column",
        headers: this.mainStore.getHeaders(),
        params,
      });
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response.response);
      } else {
        this.setRecordsByMonthStackedBar(response.data);
      }
    } catch (err) {
      if (err) {
        this.mainStore.toast.setErrorText(err.toString());
        window.console.log(`"Report#index for Policy" error ${err}`);
      }
    }
  }

  // GET api/react/report/records_by_month_by_workspace(.:format)
  async getReportsByMonthByWorkspace({
    date_column_name,
    identifier,
    from_value,
    to_value,
    tableName,
    field_name,
    field_values,
  }: {
    date_column_name: string;
    identifier: string;
    from_value: string | Date | undefined;
    to_value: string | Date | undefined;
    tableName: string;
    field_name: string | undefined;
    field_values: string | string[] | undefined;
  }) {
    this.setRecordsByMonthIsReady(false);
    const params = {
      date_column_name,
      identifier,
      from_value,
      to_value,
      table_name: tableName,
      filter_field_name: field_name,
      filter_field_values: field_values,
      workspace_ids: this.mainStore.context.activeWorkspace?.is_internal
        ? this.selectedWorkspaceIDs
        : [this.mainStore.context.activeWorkspace?.id],
    };
    try {
      const response = await legacyApi({
        method: "GET",
        url: "/api/react/report/records_by_month_by_workspace",
        headers: this.mainStore.getHeaders(),
        params,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.setRecordsByMonthIsReady(true);
        this.mainStore.toast.setErrorText(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          response?.response?.data?.errors?.base,
        );
      } else {
        this.setRecordsByMonthIsReady(true);
        this.setRecordsByMonth(response.data);
      }
    } catch (err) {
      if (err) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        this.mainStore.toast.setErrorText(err);
        window.console.log(`"Report#index for Policy" error ${err}`);
      }
    }
  }

  // Only used in Issue Management Metrics
  async getRecordsForStatusDashboard({
    identifier,
    from_value,
    to_value,
    table_name,
    interval,
    filter_params,
  }: {
    identifier: ModuleIdentifier;
    from_value: string | Date | undefined;
    to_value: string | Date | undefined;
    table_name: string;
    interval: string;
    filter_params: undefined;
  }) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: "/api/react/report/records_by_status",
        headers: this.mainStore.getHeaders(),
        params: {
          identifier,
          from_value,
          to_value,
          table_name,
          workspace_id: this.mainStore.context.activeWorkspace?.id,
          interval,
          filter_params,
        },
      });
      if (!response.isAxiosError) {
        this.setRecordsForMetrics(response.data);
      }
    } catch (err) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.mainStore.toast.setErrorText(err);
      window.console.log(`"Report#records_by_status" error ${err}`);
    }
  }

  async getRecordsForCreatedDateDashboard({
    identifier,
    from_value,
    to_value,
    table_name,
    interval,
    filter_params,
  }: {
    identifier: ModuleIdentifier;
    from_value: string | Date | undefined;
    to_value: string | Date | undefined;
    table_name: string;
    interval: string;
    filter_params: undefined;
  }) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: "/api/react/report/records_by_created_at",
        headers: this.mainStore.getHeaders(),
        params: {
          identifier,
          from_value,
          to_value,
          table_name,
          workspace_id: this.mainStore.context.activeWorkspace?.id,
          interval,
          field_name: this.imSourceGroupName,
          filter_params,
        },
      });
      if (!response.isAxiosError) {
        this.setRecordsForMetrics(response.data);
      }
    } catch (err) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.mainStore.toast.setErrorText(err);
      window.console.log(`"Report#records_by_created_at" error ${err}`);
    }
  }

  // GET /api/react/report/count_by_time_from_date
  async getRecordsForOverdueDashboard({
    identifier,
    table_name,
    type,
    date_column_name,
    start_date,
    end_date,
    filter_params,
  }: {
    identifier: string;
    table_name: string;
    type: string;
    date_column_name: string;
    start_date: string;
    end_date: string;
    filter_params: undefined;
  }) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: "/api/react/report/count_by_time_from_date",
        headers: this.mainStore.getHeaders(),
        params: {
          identifier,
          table_name,
          workspace_id: this.mainStore.context.activeWorkspace?.id,
          type,
          date_column_name,
          from_value: start_date,
          to_value: end_date,
          filter_params,
        },
      });
      if (!response.isAxiosError) {
        return response.data;
      }
    } catch (err) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.mainStore.toast.setErrorText(err);
      window.console.log(`"Report#count_by_time_from_date" error ${err}`);

      return null;
    }
  }

  async getRecordsForTimeToCloseDashboard({
    identifier,
    from_value,
    to_value,
    table_name,
    interval,
    filter_params,
  }: {
    identifier: ModuleIdentifier;
    from_value: string | Date | undefined;
    to_value: string | Date | undefined;
    table_name: string;
    interval: string;
    filter_params: undefined;
  }) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: "/api/react/report/records_by_time_to_close",
        headers: this.mainStore.getHeaders(),
        params: {
          identifier,
          from_value,
          to_value,
          table_name,
          workspace_id: this.mainStore.context.activeWorkspace?.id,
          interval,
          filter_params,
        },
      });
      if (!response.isAxiosError) {
        this.setRecordsForMetrics(response.data);
      }
    } catch (err) {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      this.mainStore.toast.setErrorText(err);
      window.console.log(`"Report#records_by_time_to_close" error ${err}`);
    }
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'workspace_ids' implicitly has an ... Remove this comment to see the full error message
  async getAllFieldOptions({ workspace_ids, identifier }) {
    const params = { workspace_ids, identifier };
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await legacyApi({
        method: "GET",
        url: `${API_URL}/field_options`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response.response);
      }

      this.setAllCurrentFieldOptions(response.data.field_options);
    } catch (error) {
      window.console.log(
        `"NotificationRules#getModuleFieldOptions" error ${error}`,
      );
    }
  }

  // @route PUT /api/frontend/reports/count_by_status {format: "json"} (count_by_date_api_frontend_reports)
  async recordsCountByStatuses(moduleWorkspaceID: number, statuses: string) {
    const data: CountByStatusMutationRequest = {
      module_workspace_id: moduleWorkspaceID,
      statuses,
    };
    const response = await reportsService.countByStatus(data);
    this.setCountRecords(response);

    return response;
  }

  // @route PUT /api/frontend/reports/count_by_date {format: "json"} (count_by_date_api_frontend_reports)
  async recordsCountBySpecifiedDate(
    moduleWorkspaceID: number,
    sectionTagID: number,
    fieldName: string,
    numberOfDays: number,
  ) {
    const data: CountByDateMutationRequest = {
      module_workspace_id: moduleWorkspaceID,
      section_tag_id: sectionTagID === 0 ? null : sectionTagID,
      field_name: fieldName,
      number_of_days: numberOfDays,
    };
    const response = await reportsService.countByDate(data);
    this.setCountRecords(response);

    return response;
  }

  // Store Helpers
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPolicies(value) {
    if (value) {
      this.policies = value;
    } else {
      this.policies = {};
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setRecordsByMonthIsReady(value) {
    this.recordsByMonthIsReady = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setDateColumnName(value) {
    this.dateColumnName = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setGroupFieldName(value) {
    this.groupFieldName = value ? value : "status";
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setRecordsByMonth(value) {
    this.recordsByMonth = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setRecordsByMonthStackedBar(value) {
    this.recordsByMonthStackedBar = value ? value : [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setRecordsForMetrics(value) {
    this.recordsForMetrics = value ? value : [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setRecordsByFieldOption(value) {
    this.recordsByFieldOption = value ? value : {};
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentFields(value) {
    this.currentFields = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrentFieldOptions(value) {
    this.currentFieldOptions = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAllCurrentFieldOptions(value) {
    this.allCurrentFieldOptions = value || [];
  }

  setCountRecords(value: number) {
    this.countRecords = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setComplaints(value) {
    if (value) {
      this.complaints = value;
    } else {
      this.complaints = {};
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setFromValue(value) {
    this.fromValue = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setToValue(value) {
    this.toValue = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setFieldName(value) {
    this.fieldName = value || {};
    this.setFieldValue([]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setFieldValue(value) {
    this.fieldValue = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSelectedWorkspaceIDs(value) {
    this.selectedWorkspaceIDs = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSelectAllWorkspaces(value) {
    this.selectAllWorkspaces = value || false;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setDisplayedWorkspace(value) {
    this.displayedWorkspace = value || "All Workspace";
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setIMSourceGroupName(value) {
    this.imSourceGroupName = value || "created date";
  }

  updateRefetchRecordsCount() {
    this.refetchRecordsCount += 1;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  updateQASelectFields(fieldName, value) {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (this.qaSelectFields[fieldName]) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      this.qaSelectFields[fieldName].value = value;
    }
  }

  cleanup() {
    this.setPolicies({});
    this.setComplaints({});
    this.setGroupFieldName(undefined);
    this.setCurrentFields(undefined);
    this.setRecordsByMonthStackedBar(undefined);
    this.setCurrentFieldOptions(undefined);
    this.setAllCurrentFieldOptions(undefined);
    this.setRecordsForMetrics([]);
    this.refetchRecordsCount = 0;
  }
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const CloudDownload = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.56023 10.8285C6.4317 10.9626 6.26436 11.0624 6.07022 11.1086C4.30906 11.5274 3 13.1122 3 15.0002C3 17.2094 4.79086 19.0002 7 19.0002C7.38062 19.0002 7.74717 18.9474 8.09352 18.8492C8.62487 18.6986 9.17772 19.0072 9.32834 19.5386C9.47897 20.0699 9.17033 20.6228 8.63898 20.7734C8.11683 20.9214 7.56678 21.0002 7 21.0002C3.68629 21.0002 1 18.3139 1 15.0002C1 12.3751 2.68503 10.1457 5.03157 9.33088C5.36842 5.77877 8.35971 3 12 3C15.6573 3 18.6596 5.80487 18.973 9.38085C21.2471 10.234 22.8662 12.427 22.8662 15.0002C22.8662 18.3139 20.1799 21.0002 16.8662 21.0002C16.2994 21.0002 15.7494 20.9214 15.2272 20.7734C14.6959 20.6228 14.3872 20.0699 14.5378 19.5386C14.6885 19.0072 15.2413 18.6986 15.7727 18.8492C16.119 18.9474 16.4856 19.0002 16.8662 19.0002C19.0753 19.0002 20.8662 17.2094 20.8662 15.0002C20.8662 13.1122 19.5572 11.5274 17.796 11.1086C17.3803 11.0098 17.0875 10.6647 17.0354 10.2647C17.0123 10.1804 17 10.0917 17 10C17 7.23858 14.7614 5 12 5C9.23858 5 7 7.23858 7 10C7 10.3447 6.8256 10.6487 6.56023 10.8285Z"
      fill="#353549"
    />
    <path
      d="M16.2758 14.2957C16.6648 14.6877 16.6623 15.3209 16.2703 15.7099L12.7043 19.2481C12.3129 19.6364 11.681 19.6347 11.2917 19.2441L7.82444 15.7654C7.43456 15.3743 7.43561 14.7411 7.82677 14.3512C8.21794 13.9613 8.85111 13.9624 9.24099 14.3536L11 16.1184V10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V16.1372L14.8616 14.2901C15.2536 13.9011 15.8868 13.9036 16.2758 14.2957Z"
      fill="#353549"
    />
  </svg>
);

CloudDownload.displayName = "CloudDownloadIcon";

export default CloudDownload;

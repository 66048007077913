import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Switch = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2929 2.79289C14.6834 2.40237 15.3166 2.40237 15.7071 2.79289L21.7071 8.79289C21.9931 9.07889 22.0787 9.50901 21.9239 9.88268C21.7691 10.2564 21.4045 10.5 21 10.5H2.99999C2.4477 10.5 1.99999 10.0523 1.99999 9.5C1.99999 8.94772 2.4477 8.5 2.99999 8.5H18.5858L14.2929 4.20711C13.9024 3.81658 13.9024 3.18342 14.2929 2.79289ZM2.4756 14.1173C2.63038 13.7436 2.99502 13.5 3.39948 13.5H21.3995C21.9518 13.5 22.3995 13.9477 22.3995 14.5C22.3995 15.0523 21.9518 15.5 21.3995 15.5H5.8137L10.1066 19.7929C10.4971 20.1834 10.4971 20.8166 10.1066 21.2071C9.71608 21.5976 9.08292 21.5976 8.69239 21.2071L2.69238 15.2071C2.40638 14.9211 2.32082 14.491 2.4756 14.1173Z"
      fill="#353549"
    />
  </svg>
);

Switch.displayName = "SwitchIcon";

export default Switch;

import React from "react";
import { useIntl } from "react-intl";

import type { RecordVersionDataPiiCategoriesOptions } from "@/api";
import { Typography } from "@/components/Elements";
import type { TypographyProps } from "@/components/Elements/Typography/typings";

interface PiiCategoryProps extends TypographyProps {
  category: RecordVersionDataPiiCategoriesOptions;
}

const usePiiCategoryMessages = () => {
  const { formatMessage } = useIntl();

  const messages: Record<RecordVersionDataPiiCategoriesOptions, string> = {
    credit_card_numbers: formatMessage({
      defaultMessage: "Credit card numbers",
    }),
    date_of_birth: formatMessage({
      defaultMessage: "Date of birth",
    }),
    driver_s_license_number: formatMessage({
      defaultMessage: "Driver's license number",
    }),
    email_address: formatMessage({
      defaultMessage: "Email address",
    }),
    full_name: formatMessage({
      defaultMessage: "Full name",
    }),
    home_address: formatMessage({
      defaultMessage: "Home address",
    }),
    passport_number: formatMessage({
      defaultMessage: "Passport number",
    }),
    social_security_number: formatMessage({
      defaultMessage: "Social security number",
    }),
    telephone_number: formatMessage({
      defaultMessage: "Telephone number",
    }),
  };

  return messages;
};

export const PiiCategory = ({ category, ...rest }: PiiCategoryProps) => {
  const messages = usePiiCategoryMessages();

  return <Typography {...rest}>{messages[category]}</Typography>;
};

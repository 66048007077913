import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ArrowUp = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711C18.3166 10.0976 17.6834 10.0976 17.2929 9.70711L13 5.41421V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V5.41421L6.70711 9.70711C6.31658 10.0976 5.68342 10.0976 5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289L11.2929 2.29289Z"
      fill="#353549"
    />
  </svg>
);

ArrowUp.displayName = "ArrowUpIcon";

export default ArrowUp;

import { observer } from "mobx-react";
import React from "react";

import QuestionnaireContextMenu from "@/components/table/vendor-due-diligence/QuestionnaireContextMenu";
import VendorQuestionnaireTableActionButton from "@/components/table/vendor-due-diligence/VendorQuestionnairesTable/components/VendorQuestionnaireTableActionButton";
import { getStatus } from "@/components/table/vendor-due-diligence/VendorQuestionnairesTable/helpers";
import { useMainStore } from "@/contexts/Store";

export const VendorQuestionnaireTableAction = observer(
  ({
    questionnaire,
    isActive,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    questionnaire: any; // TODO: type questionnaire in vendor-due-diligence
    isActive: boolean;
  }) => {
    const mainStore = useMainStore();

    const isUserInternal = !mainStore.users.user?.roles?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (elem: any) => elem.company_id === mainStore.companies.company?.id,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    )?.is_external;

    const isWorkspaceInternal = mainStore.context.isIW;

    const status = getStatus(questionnaire);

    function getContextMenuActions() {
      if (!isWorkspaceInternal) {
        return ["Shareable"];
      }

      if (
        isWorkspaceInternal &&
        ["Shared", "Incomplete", "Not Started"].includes(status)
      ) {
        return ["Edit", "Shareable"];
      }

      if (isWorkspaceInternal && isUserInternal && status === "Complete") {
        return ["Reopen", "Review", "Shareable"];
      }

      return [];
    }

    return (
      <>
        <VendorQuestionnaireTableActionButton
          status={status}
          questionnaire={questionnaire}
          isActive={isActive}
          isUserInternal={isUserInternal}
        />
        <QuestionnaireContextMenu
          questionnaireID={questionnaire.id}
          name={questionnaire.name}
          additionalActions={getContextMenuActions()}
          externalID={questionnaire.external_id}
          allowDelete={isActive && isUserInternal && isWorkspaceInternal}
        />
      </>
    );
  },
);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const BookmarkSolid = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.65079 21.7593C5.00212 22.3153 4 21.8544 4 21V5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V21C20 21.8544 18.9979 22.3153 18.3492 21.7593L12 16.3171L5.65079 21.7593Z"
      fill="#353549"
    />
  </svg>
);

BookmarkSolid.displayName = "BookmarkSolidIcon";

export default BookmarkSolid;

export const kriUrl = "/workspaces/:workspace_id/modules/key-risk-indicators";
export const kriListUrl =
  "/workspaces/:workspace_id/modules/key-risk-indicators/list";
export const kriSummaryUrl =
  "/workspaces/:workspace_id/modules/key-risk-indicators/summary";
export const kriInputUrl =
  "/workspaces/:workspace_id/modules/key-risk-indicators/input";
export const kriThemisLibraryUrl =
  "/workspaces/:workspace_id/modules/key-risk-indicators/themis-kri-library";
export const kriCompanyLibraryUrl =
  "/workspaces/:workspace_id/modules/key-risk-indicators/templates";
export const kriDetailViewUrl =
  "/workspaces/:workspace_id/modules/:moduleIdentifier/:record_version_id";
export const kriAuditTrailUrl =
  "/workspaces/:workspace_id/modules/:moduleIdentifier/:record_version_id/audit_trail";
export const kriModuleAuditTrailUrl =
  "/workspaces/:workspace_id/modules/:moduleIdentifier/audit_trail";
export const kriCompanyLibraryUrlEdit = `${kriCompanyLibraryUrl}/:record_id`;
export const kriCompanyLibraryUrlNew = `${kriCompanyLibraryUrl}/new`;

import { cva } from "cva";
import { debounce } from "lodash";
import * as React from "react";
import { PiCaretLeft } from "react-icons/pi";
import { cn } from "../../lib/utils";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, } from "../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
var defaultStyles = "tw-flex tw-w-full tw-bg-neutral-25 tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-transition-colors";
var fontStyles = "tw-text-sm tw-font-semibold tw-font-sans";
var focusStyles = "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
var readOnlyStyles = "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var lockedStyles = "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
var DEBOUNCE_TIMEOUT = 250;
var selectInputVariants = cva({
    base: [defaultStyles, fontStyles, focusStyles],
    variants: {
        size: {
            sm: "tw-h-7",
            md: "tw-h-8",
            lg: "tw-h-9",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});
var MultiStepSelect = React.forwardRef(function (_a, ref) {
    var _b;
    var steps = _a.steps, _c = _a.onSelect, onSelect = _c === void 0 ? function () { } : _c, _d = _a.onGoBack, onGoBack = _d === void 0 ? function () { } : _d, className = _a.className, locked = _a.locked, readOnly = _a.readOnly, _e = _a.buttonText, buttonText = _e === void 0 ? "Select" : _e;
    var _f = React.useState(0), activeStep = _f[0], setActiveStep = _f[1];
    var _g = React.useState(false), open = _g[0], setOpen = _g[1];
    var _h = React.useState(""), search = _h[0], setSearch = _h[1];
    var disabled = locked || readOnly;
    var resetValues = function () {
        setActiveStep(0);
        setSearch("");
        setOpen(false);
    };
    var handleSelect = function (value) {
        if (activeStep < steps.length - 1) {
            setActiveStep(function (prev) { return prev + 1; });
            onSelect(activeStep, value);
        }
        else {
            resetValues();
            // Since we hide the selected item from the list, this ensures list doesn't glitch before closing popup
            setTimeout(function () { return onSelect(activeStep, value); }, 100);
        }
    };
    var goBack = function () {
        onGoBack(activeStep);
        setActiveStep(activeStep - 1);
    };
    var _j = steps[activeStep], title = _j.title, subtitle = _j.subtitle, searchable = _j.searchable, items = _j.items, Component = _j.Component, onScroll = _j.onScroll, onSearchChange = _j.onSearchChange;
    var debouncedHandleFetchSearchResults = React.useMemo(function () {
        return debounce(function (keyword) {
            if (onSearchChange) {
                onSearchChange(keyword);
            }
        }, DEBOUNCE_TIMEOUT);
    }, [onSearchChange]);
    var onScrollChange = function (e) {
        if (onScroll &&
            e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
                e.currentTarget.scrollHeight) {
            onScroll(search);
        }
    };
    var onTextChange = function (value) {
        setSearch(value);
        debouncedHandleFetchSearchResults(value);
    };
    return (React.createElement(Popover, { open: open, onOpenChange: function (val) {
            setOpen(val);
            if (!val) {
                resetValues();
            }
        } },
        React.createElement(PopoverTrigger, { disabled: disabled, className: cn((_b = {},
                _b[lockedStyles] = locked,
                _b[readOnlyStyles] = readOnly,
                _b["tw-cursor-default"] = disabled,
                _b), className), ref: ref, asChild: true },
            React.createElement(Button, { color: "tertiary", disabled: disabled, onClick: function () { return setOpen(true); } }, buttonText)),
        React.createElement(PopoverContent, { align: "start", className: "tw-min-w-60" },
            (activeStep > 0 || title) && (React.createElement("div", { className: "tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100" },
                React.createElement("div", { className: "tw-flex tw-items-center tw-justify-between tw-rounded-t-md tw-bg-neutral-25 tw-p-1" },
                    React.createElement("div", { className: "tw-flex tw-items-center" },
                        activeStep > 0 && (React.createElement(IconButton, { Icon: PiCaretLeft, onClick: goBack, className: "[&_svg]:tw-fill-primaryDim-300", color: "transparent", size: "lg", "popup-select-button": "true" })),
                        title && (React.createElement("div", { className: "tw-text-sm tw-font-semibold tw-text-primaryDim-300" }, title)))))),
            React.createElement(Command, { tabIndex: 0, className: "tw-outline-none", shouldFilter: !onSearchChange },
                searchable && (React.createElement(CommandInput, { autoFocus: true, value: search, onValueChange: onTextChange, placeholder: "Search..." })),
                subtitle && (React.createElement("div", { className: cn("tw-w-full tw-px-4 tw-pt-1 tw-text-sm tw-font-semibold tw-text-primaryDim-300", {
                        "tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-neutral-100": searchable,
                    }) }, subtitle)),
                React.createElement(CommandList, { className: "tw-max-h-72 tw-overflow-y-auto", onScroll: onScrollChange },
                    searchable && React.createElement(CommandEmpty, null, "No results."),
                    React.createElement(CommandGroup, null, items.map(function (_a) {
                        var value = _a.value, label = _a.label, other = _a.other;
                        return (React.createElement(CommandItem, { key: value + label, value: value.toString(), keywords: [label], onSelect: handleSelect }, Component
                            ? Component(label, value.toString(), other)
                            : label));
                    })))))));
});
MultiStepSelect.displayName = "MultiStepSelect";
export { MultiStepSelect, };

import { Table } from "@themis/ui";
import type { RecordWithId } from "packages/ui/src/components/Table/types";
import React, { useMemo } from "react";

import type { Questionnaire } from "@/api";
import { useAccount } from "@/api/queries/accounts";
import { useRiskMethodologies } from "@/api/queries/riskMethodologies";
import { useUsers } from "@/api/queries/users";
import type { RiskMethodology } from "@/features/risk-assessment";
import { getDefinitionsFromScores } from "@/features/risk-assessment";

import { columns } from "./TableColumns";

interface AccountQuestionnairesProps {
  accountId: number;
  workspaceId: string | number;
}

function AccountQuestionnaires({
  accountId,
  workspaceId,
}: AccountQuestionnairesProps) {
  const { data: accountData, isLoading: accountIsLoading } = useAccount({
    workspaceId: Number(workspaceId),
    accountId: Number(accountId),
    queryParams: {
      expand: "questionnaires",
    },
  });

  const { data: usersData, isLoading: usersIsLoading } = useUsers(
    Number(workspaceId),
  );
  const { data: riskData, isLoading: riskIsLoading } = useRiskMethodologies(
    Number(workspaceId),
  );

  const users = usersData?.data.users || [];

  const riskMethodologies: RiskMethodology[] =
    riskData?.data.risk_methodologies;

  const tableRows = useMemo(() => {
    return (
      accountData?.data.questionnaires?.map((questionnaire: Questionnaire) => {
        const methodology = riskMethodologies?.find(
          (rm) => rm.id === questionnaire.risk_methodology_id,
        );

        const {
          overallRiskRating,
          controlRiskRating,
          inherentRiskRating,
          residualRiskRating,
        } = getDefinitionsFromScores(questionnaire.scoring, methodology);

        return {
          ...questionnaire,
          overallRiskRating,
          controlRiskRating,
          inherentRiskRating,
          residualRiskRating,
        };
      }) || []
    );
  }, [accountData, riskMethodologies]);

  if (
    accountIsLoading ||
    usersIsLoading ||
    riskIsLoading ||
    !tableRows.length
  ) {
    return null;
  }

  return (
    <Table
      columns={columns(users, workspaceId)}
      rows={tableRows as RecordWithId[]}
      width="100%"
    />
  );
}

export default AccountQuestionnaires;

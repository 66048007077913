import { sortBy } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import type { RecordVersion } from "@/api";
import Info from "@/assets/svgs/info";
import type { Filter } from "@/components/filters/types";
import {
  formatDateForCellValue,
  stringToDate,
} from "@/components/helpers/DateFormatters";
import { recordTypeForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import {
  DOCUMENTS_AT_RISK_DAYS,
  DOCUMENTS_OPEN_STATUS,
  MODULE_CHARTS,
  MONTH_AT_RISK,
} from "@/components/reports/constants";
import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

const MONTH_MODULE_LABELS = {
  at_risk_label: "The Next Month",
}; // policy and procedures module

const MONTH_FILTERED_TITLE = {
  at_risk: "Up for Review",
  overdue: "Overdue",
}; // policy and procedures module

const MODULE_LABELS = {
  [MODULE_CHARTS.policy]: MONTH_MODULE_LABELS,
  [MODULE_CHARTS.documents]: {
    at_risk_label: "Exact Dates",
  },
  [MODULE_CHARTS.procedures]: MONTH_MODULE_LABELS,
};

const FILTERED_TITLE = {
  [MODULE_CHARTS.policy]: MONTH_FILTERED_TITLE,
  [MODULE_CHARTS.documents]: {
    at_risk: "At Risk",
    overdue: "Overdue",
  },
  [MODULE_CHARTS.procedures]: MONTH_FILTERED_TITLE,
};

const FILTERED_DATE = {
  [MODULE_CHARTS.policy]: {
    count: MONTH_AT_RISK,
  },
  [MODULE_CHARTS.documents]: {
    count: DOCUMENTS_AT_RISK_DAYS,
  },
  [MODULE_CHARTS.procedures]: {
    count: MONTH_AT_RISK,
  },
};

const DUE_DATE_FIELD = {
  [MODULE_CHARTS.policy]: {
    dueDate: "review_date",
  },
  [MODULE_CHARTS.documents]: {
    dueDate: "due_date",
  },
  [MODULE_CHARTS.procedures]: {
    dueDate: "review_date",
  },
};

interface Props {
  recordVersions: RecordVersion[];
  moduleWorkspaceID: number;
  sectionTagID?: number;
  filtersViewEnabled: boolean;
  setFiltersViewEnabled: (arg: boolean) => void;
  moduleName: ModuleIdentifier;
}

interface CountRecords {
  at_risk_count: number;
  overdue_count: number;
}

const DueDateTableChart = ({
  recordVersions,
  sectionTagID,
  moduleWorkspaceID,
  filtersViewEnabled,
  setFiltersViewEnabled,
  moduleName,
}: Props) => {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const defaultSectionTagID = !sectionTagID ? 0 : sectionTagID;
  const numberOfDaysArray = [FILTERED_DATE[moduleName].count, 0];
  const recordTypePlural =
    recordTypeForThemisModuleIdentifier(moduleName).plural;

  const STANDARD_TOOLTIP_CONTENT = {
    dueDateInfo: `${recordTypePlural} with Review Dates within the next month`,
  }; // policy and procedures module

  const TOOLTIP_CONTENT = {
    [MODULE_CHARTS.policy]: STANDARD_TOOLTIP_CONTENT,
    [MODULE_CHARTS.documents]: {
      dueDateInfo: "Documents due within the next two weeks",
    },
    [MODULE_CHARTS.procedures]: STANDARD_TOOLTIP_CONTENT,
  };

  // State
  const [countRecords, setCountRecords] = useState<CountRecords>({
    at_risk_count: 0,
    overdue_count: 0,
  });
  const fieldName = DUE_DATE_FIELD[moduleName].dueDate;
  const { setTableFiltersParam, getTableFiltersParam } = useUpdateFilter();

  // Effects
  useEffect(() => {
    fetchDays();
  }, [recordVersions]);

  function handleClick(condition: string) {
    if (!filtersViewEnabled) {
      setFiltersViewEnabled(true);
    }

    addFilter(condition);
  }

  function addFilter(condition: string) {
    const today = new Date();
    const filters = getTableFiltersParam();

    const lastDateAtRiskCount = new Date();
    lastDateAtRiskCount.setDate(
      lastDateAtRiskCount.getDate() + FILTERED_DATE[moduleName].count,
    );

    const todayString = formatDateForCellValue(
      stringToDate(today) || undefined,
    );
    const lastDateAtRiskCountString = formatDateForCellValue(
      stringToDate(lastDateAtRiskCount) || undefined,
    );

    const todayFilter = {
      condition,
      name: fieldName,
      label: "Today",
      value: todayString,
      numOfDays: null,
    };

    const exactDateFilter = {
      condition,
      name: fieldName,
      label: MODULE_LABELS[moduleName].at_risk_label,
      value: lastDateAtRiskCountString,
      numOfDays: null,
    };

    const carryoverFilters = filters.filter(
      (filter: Filter) => filter.name !== "status" && filter.name !== fieldName,
    );

    if (moduleName !== "documents") {
      return setTableFiltersParam([
        ...carryoverFilters,
        // filter by date column
        {
          name: fieldName,
          condition,
          options:
            condition === "is within"
              ? sortBy(
                  [todayFilter, exactDateFilter],
                  (filterItem) => filterItem?.value,
                )
              : [todayFilter],
        },
      ]);
    }

    return setTableFiltersParam([
      ...carryoverFilters,
      // filter only open statuses
      {
        name: "status",
        condition: mainStore.filters.getConditionText(
          "com.askthemis.types.v1.option",
          true,
        ),
        options: DOCUMENTS_OPEN_STATUS,
      },
      // filter by date column
      {
        name: fieldName,
        condition,
        options:
          condition === "is within"
            ? sortBy(
                [todayFilter, exactDateFilter],
                (filterItem) => filterItem?.value,
              )
            : [todayFilter],
      },
    ]);
  }

  async function fetchDays() {
    if (recordVersions) {
      const promises = numberOfDaysArray.map((numberOfDays: number) => {
        return mainStore.reports.recordsCountBySpecifiedDate(
          moduleWorkspaceID,
          defaultSectionTagID,
          fieldName,
          numberOfDays,
        );
      });

      try {
        const results = await Promise.all(promises);
        const resultsObject = {
          at_risk_count: results[0],
          overdue_count: results[1],
        };
        setCountRecords({ ...resultsObject });
      } catch (error) {
        window.console.error("Error:", error);
      }
    }
  }

  return (
    <div className="summary-content">
      <div className="documents-list">
        <div className="record-status" onClick={() => handleClick("is within")}>
          {FILTERED_TITLE[moduleName].at_risk}
          <Info
            fill="#8080a3"
            style={{
              width: 15,
              height: 15,
              marginLeft: 5,
              paddingBottom: 0,
            }}
            data-tooltip-id="tooltip"
            data-tooltip-content={TOOLTIP_CONTENT[moduleName].dueDateInfo}
            data-tooltip-place="bottom"
          />
        </div>
        <p
          className="record-status-count"
          onClick={() => handleClick("is within")}
        >
          {countRecords.at_risk_count}
        </p>
      </div>
      <div className="documents-list">
        <div className="record-status" onClick={() => handleClick("is before")}>
          {FILTERED_TITLE[moduleName].overdue}
        </div>
        <p
          className="record-status-count"
          onClick={() => handleClick("is before")}
        >
          {countRecords.overdue_count}
        </p>
      </div>
    </div>
  );
};

export default observer(DueDateTableChart);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListOfCompanyControlCategoriesPathParams,
  ListOfCompanyControlCategoriesQueryResponse,
} from "../../models/ListOfCompanyControlCategories";

/**
 * @description List of company control categories
 * @summary List of company control categories
 * @link /companies/:company_id/control_categories
 */
export async function listOfCompanyControlCategories(
  companyId: ListOfCompanyControlCategoriesPathParams["company_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<ListOfCompanyControlCategoriesQueryResponse>["data"]
> {
  const res = await client<ListOfCompanyControlCategoriesQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/control_categories`,
    ...options,
  });
  return res.data;
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateFieldMutationRequest,
  UpdateFieldMutationResponse,
  UpdateFieldPathParams,
} from "../../models/UpdateField";

/**
 * @description Update an existing field for a specific record type
 * @summary Update an existing field
 * @link /companies/:company_id/:record_type/fields/:id
 */
export async function updateField(
  companyId: UpdateFieldPathParams["company_id"],
  recordType: UpdateFieldPathParams["record_type"],
  id: UpdateFieldPathParams["id"],
  data?: UpdateFieldMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateFieldMutationResponse>["data"]> {
  const res = await client<
    UpdateFieldMutationResponse,
    UpdateFieldMutationRequest
  >({
    method: "put",
    url: `/companies/${companyId}/${recordType}/fields/${id}`,
    data,
    ...options,
  });
  return res.data;
}

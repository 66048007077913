import {
  Checkbox,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@themis/ui";
import type { CheckboxProps } from "packages/ui/src/components/Form/Checkbox";
import type { ReactNode } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

interface CheckboxFormFieldProps<T extends FieldValues>
  extends Partial<CheckboxProps> {
  label?: ReactNode;
  control: Control<T>;
  name: Path<T>;
}

export function CheckboxFormField<T extends FieldValues>({
  required,
  label,
  control,
  name,
  ...rest
}: CheckboxFormFieldProps<T>) {
  return (
    <FormField
      required={required}
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="tw-flex tw-gap-4">
          <FormControl>
            <Checkbox
              required={required}
              checked={field.value ?? false}
              onCheckedChange={field.onChange}
              {...rest}
            />
          </FormControl>

          {label && (
            <FormLabel className="tw-cursor-pointer">{label}</FormLabel>
          )}

          <FormMessage />
        </FormItem>
      )}
    />
  );
}

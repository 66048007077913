import { cn, Tooltip, TooltipContent, TooltipTrigger } from "@themis/ui";
import React from "react";

const LEGEND_COLORS = [
  "tw-bg-primary-200",
  "tw-bg-secondary-100",
  "tw-bg-fuchsia-3",
  "tw-bg-warning-500",
  "tw-bg-mint-3",
  "tw-bg-primaryDim-400",
  "tw-bg-[#6EC8F9]",
  "tw-bg-blue-3",
  "tw-bg-orange-3",
  "tw-bg-warning-200",
  "tw-bg-fuchsia-2",
  "tw-bg-fuchsia-4",
  "tw-bg-secondary-25",
] as const;

const LOW_MEDIUM_HIGH_COLORS = [
  "tw-bg-green-3",
  "tw-bg-yellow-4",
  "tw-bg-warning-300",
] as const;

function getBackgroundColorClassName({
  index,
  hasLowMediumHigh,
}: {
  index: number;
  hasLowMediumHigh?: boolean;
}) {
  const colors = hasLowMediumHigh
    ? [...LOW_MEDIUM_HIGH_COLORS, ...LEGEND_COLORS]
    : LEGEND_COLORS;

  return colors[index % colors.length];
}

function LegendText({
  children,
  isTooltipText,
  isVertical,
}: {
  children: string;
  isTooltipText?: boolean;
  isVertical?: boolean;
}) {
  return (
    <p
      className={cn("tw-font-medium tw-text-neutral-300", {
        "tw-text-sm tw-text-neutral-500": isTooltipText,
        "tw-text-xs": !isTooltipText,
        "tw-truncate": isVertical,
      })}
      title={children}
    >
      {children}
    </p>
  );
}

function LegendTextAndIcon({
  text,
  colorIndex,
  isTooltipText,
  hasLowMediumHigh,
  isVertical,
}: {
  text: string;
  colorIndex: number;
  isTooltipText?: boolean;
  hasLowMediumHigh?: boolean;
  isVertical?: boolean;
}) {
  return (
    <div
      className={cn("tw-flex tw-items-center tw-gap-1.5", {
        "tw-gap-2": isTooltipText,
        "tw-w-full": isVertical,
      })}
    >
      <div
        className={cn(
          `tw-h-2 tw-w-2 tw-rounded-full ${getBackgroundColorClassName({
            index: colorIndex,
            hasLowMediumHigh,
          })}`,
          {
            "tw-h-3 tw-w-3": isTooltipText,
            "tw-shrink-0": isVertical,
          },
        )}
      />
      <LegendText isTooltipText={isTooltipText} isVertical={isVertical}>
        {text}
      </LegendText>
    </div>
  );
}

function formatKey(key: string) {
  return key
    .replace(/_/gi, " ")
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

export function TruncatedLegend({
  seriesNames,
  hasLowMediumHigh,
  isVertical,
}: {
  seriesNames: string[];
  hasLowMediumHigh?: boolean;
  isVertical?: boolean;
}) {
  return (
    <div
      className={cn(
        "tw-flex tw-h-9 tw-flex-wrap tw-items-center tw-justify-center tw-gap-x-2 tw-gap-y-1",
        {
          "tw-h-auto tw-w-[100px] tw-flex-col tw-items-start tw-gap-2":
            isVertical,
        },
      )}
    >
      {seriesNames.slice(0, 5).map((key, index) => (
        <LegendTextAndIcon
          key={key}
          text={formatKey(key)}
          colorIndex={index}
          hasLowMediumHigh={hasLowMediumHigh}
          isVertical={isVertical}
        />
      ))}
      {seriesNames.length > 5 && (
        <Tooltip>
          <TooltipTrigger>
            <LegendText>{`+${String(seriesNames.length - 5)}`}</LegendText>
          </TooltipTrigger>
          <TooltipContent
            disableArrow
            side="bottom"
            className="tw-bg-neutral-25 tw-px-4 tw-py-5"
          >
            <div className="tw-flex tw-flex-col tw-gap-5">
              {seriesNames.slice(5).map((key, index) => (
                <LegendTextAndIcon
                  isTooltipText
                  key={key}
                  text={formatKey(key)}
                  colorIndex={index + 5}
                  hasLowMediumHigh={hasLowMediumHigh}
                />
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
}

import { LinkButton } from "@themis/ui";
import React from "react";
import type { LinkProps } from "react-router-dom";

interface BackButtonProps {
  linkTo: LinkProps["to"];
  replaceHistory?: boolean;
}

export function ErrorContainer({
  children,
  backButtonProps: { linkTo, replaceHistory },
}: {
  children: React.ReactNode;
  backButtonProps: BackButtonProps;
}) {
  return (
    <div className="tw-flex tw-h-72 tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-text-sm tw-text-primaryDim-300">
      {children}
      <LinkButton color="tertiary" to={linkTo} replace={replaceHistory}>
        Go Back
      </LinkButton>
    </div>
  );
}

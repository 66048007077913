import classNames from "classnames";
import React from "react";

import { MODE } from "./DataTile";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mode?: any; // TODO: PropTypes.oneOf(Object.values(MODE))
}

const LoadingTile = ({ mode }: Props) => (
  <div
    data-testid="loading-tile"
    className={classNames("loading-tile", {
      "loading-tile--dark": mode === MODE.dark,
      "loading-tile--light": mode === MODE.light,
    })}
  />
);

LoadingTile.defaultProps = {
  mode: MODE.light,
};

export default LoadingTile;
export { MODE };

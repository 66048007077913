import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupAssetPathParams,
  WebsiteMonitoringGroupAssetQueryResponse,
} from "../../models/WebsiteMonitoringGroupAsset";

/**
 * @description Website Monitoring - Asset
 * @summary Website Monitoring Asset
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_asset
 */
export async function websiteMonitoringGroupAsset(
  monitoringGroupAssetId: WebsiteMonitoringGroupAssetPathParams["monitoring_group_asset_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<WebsiteMonitoringGroupAssetQueryResponse>["data"]> {
  const res = await client<WebsiteMonitoringGroupAssetQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_asset`,
    ...options,
  });
  return res.data;
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupAssetUpdateMutationRequest,
  WebsiteMonitoringGroupAssetUpdateMutationResponse,
  WebsiteMonitoringGroupAssetUpdatePathParams,
} from "../../models/WebsiteMonitoringGroupAssetUpdate";

/**
 * @description Update Website Monitoring - Group asset
 * @summary Update Website Monitoring group asset
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_asset_update
 */
export async function websiteMonitoringGroupAssetUpdate(
  monitoringGroupAssetId: WebsiteMonitoringGroupAssetUpdatePathParams["monitoring_group_asset_id"],
  data: WebsiteMonitoringGroupAssetUpdateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<WebsiteMonitoringGroupAssetUpdateMutationResponse>["data"]
> {
  const res = await client<
    WebsiteMonitoringGroupAssetUpdateMutationResponse,
    WebsiteMonitoringGroupAssetUpdateMutationRequest
  >({
    method: "put",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_asset_update`,
    data,
    ...options,
  });
  return res.data;
}

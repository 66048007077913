import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Broadcast = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.63715 4.294C6.02706 4.68514 6.02607 5.3183 5.63493 5.70821C2.12169 9.21048 2.12169 14.8875 5.63493 18.3897C6.02607 18.7796 6.02706 19.4128 5.63715 19.8039C5.24723 20.1951 4.61407 20.1961 4.22293 19.8062C-0.074311 15.5223 -0.074311 8.5756 4.22293 4.29179C4.61407 3.90187 5.24723 3.90287 5.63715 4.294ZM18.3629 4.294C18.7528 3.90287 19.3859 3.90187 19.7771 4.29179C24.0743 8.5756 24.0743 15.5223 19.7771 19.8062C19.3859 20.1961 18.7528 20.1951 18.3629 19.8039C17.9729 19.4128 17.9739 18.7796 18.3651 18.3897C21.8783 14.8875 21.8783 9.21048 18.3651 5.70821C17.9739 5.3183 17.9729 4.68514 18.3629 4.294ZM8.73155 6.81821C9.10809 7.22223 9.08581 7.85501 8.68179 8.23155C6.4394 10.3214 6.4394 13.6786 8.68179 15.7685C9.08581 16.145 9.10809 16.7778 8.73155 17.1818C8.35501 17.5858 7.72223 17.6081 7.31821 17.2315C4.22726 14.3508 4.22726 9.64915 7.31821 6.76845C7.72223 6.39191 8.35501 6.41419 8.73155 6.81821ZM15.2685 6.81821C15.645 6.41419 16.2778 6.39191 16.6818 6.76845C19.7727 9.64915 19.7727 14.3508 16.6818 17.2315C16.2778 17.6081 15.645 17.5858 15.2685 17.1818C14.8919 16.7778 14.9142 16.145 15.3182 15.7685C17.5606 13.6786 17.5606 10.3214 15.3182 8.23155C14.9142 7.85501 14.8919 7.22223 15.2685 6.81821ZM12 10.7833C11.2941 10.7833 10.7273 11.3529 10.7273 12.049C10.7273 12.745 11.2941 13.3146 12 13.3146C12.7059 13.3146 13.2727 12.745 13.2727 12.049C13.2727 11.3529 12.7059 10.7833 12 10.7833ZM8.72727 12.049C8.72727 10.2425 10.1955 8.78335 12 8.78335C13.8045 8.78335 15.2727 10.2425 15.2727 12.049C15.2727 13.8555 13.8045 15.3146 12 15.3146C10.1955 15.3146 8.72727 13.8555 8.72727 12.049Z"
      fill="#353549"
    />
  </svg>
);

Broadcast.displayName = "BroadcastIcon";

export default Broadcast;

import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/check-icon.svg";
import unlockIcon from "../../../images/unlock.svg";

function AuditDetailViewHeaderButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const recordVersionID = parseInt(
    window.location.pathname.split("/audits/")[1].split("/")[0],
    10,
  );
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionID,
  );
  const verifierID = mainStore.avroSchemas.valueForField(
    "approval",
    recordVersion?.data,
  )?.verifier_id;
  const isApproved = Boolean(verifierID);
  const { hasModuleWriteAccess } = mainStore.userPermissions;

  // Functions
  function reopen() {
    mainStore.themisAudits.reopenAuditIssue(recordVersionID);
  }

  function approve() {
    mainStore.themisAudits.approveAuditIssue(recordVersionID);
  }

  const renderButton = () => {
    if (isApproved) {
      return (
        <button
          className="table-button unlock audit-reopen"
          onClick={reopen}
          disabled={!hasModuleWriteAccess}
        >
          <img src={unlockIcon} alt="unlock-icon" />
          Reopen
        </button>
      );
    }

    return (
      <button
        className="table-button approve audit-approve"
        onClick={approve}
        disabled={!hasModuleWriteAccess}
      >
        <img src={checkIcon} alt="upload-icon" />
        Approve
      </button>
    );
  };

  return (
    <div className="header-action approve-button-container">
      {renderButton()}
    </div>
  );
}

export default observer(AuditDetailViewHeaderButton);

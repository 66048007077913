import type { PreviewTableColumn } from "@themis/ui";
import React from "react";
import { PiBookOpenBold, PiTextTBold } from "react-icons/pi";

import type { Document } from "@/api";
import DocumentsActionCell from "@/features/accounts/components/Documents/DocumentsActionCell";

export const PREVIEW_DOCUMENTS_COLUMNS: PreviewTableColumn<Document>[] = [
  {
    title: "Name",
    type: "locked",
    width: 276,
    Icon: PiTextTBold,
    Component: (document) => document.file?.file_name,
  },
  {
    key: "upload_date",
    title: "Uploaded at",
    type: "locked",
    width: 200,
    Icon: PiBookOpenBold,
  },
  {
    type: "actions",
    Component: (document) => (
      <DocumentsActionCell
        documentId={document.id}
        documentFile={document.file}
      />
    ),
  },
] as const;

import { action, makeObservable, observable } from "mobx";

import { CommentsAPI } from "@/api/legacy/comments";
import type {
  CommentCreateData,
  CommentUpdateData,
} from "@/api/legacy/comments/types";
import { getCommentModuleWorkspaceID } from "@/stores/helpers/CommentsHelpers";
import { toDisplayUser } from "@/stores/helpers/UsersHelpers";
import type { MainStore } from "@/stores/Main";
import type {
  CommentTypeProperty,
  TaskableType,
  UserComment,
} from "@/stores/types/comment-types";
import type { DisplayUser } from "@/stores/types/user-types";

export default class CommentsStore {
  mainStore: MainStore;

  // Observable objects
  recordID: number | null = null;
  recordType: null | TaskableType = null;

  comments: UserComment[] = [];
  privateComments: UserComment[] = [];
  privateUsers: DisplayUser[] = [];
  showPrivateComments = false;
  /**
   * Contains associated `record_id`, `attachment_group_id` or `template_id`
   * of the comment
   */
  commentTypeProperty: CommentTypeProperty | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      comments: observable,
      privateComments: observable,
      privateUsers: observable,
      showPrivateComments: observable,
      commentTypeProperty: observable,
      recordID: observable,
      setRecordID: action,
      setRecordType: action,
      setComments: action,
      setPrivateComments: action,
      setPrivateUsers: action,
      setShowPrivateComments: action,
      setCommentTypeProperty: action,
    });

    this.mainStore = mainStore;
  }

  /*
   * GET /api/react/module_workspaces/:module_workspace_id/comments
   */
  async index(params: CommentTypeProperty | null) {
    this.setCommentTypeProperty(params);

    try {
      if (!params) {
        throw new Error("CommentTypeProperty is null");
      }

      const response = await CommentsAPI.getAllComments(
        {
          moduleWorkspaceID: this.mainStore.context.moduleWorkspaceID as number,
        },
        params,
      );

      this.setComments(response.comments);
      this.setPrivateComments(response.private_comments);
      this.setShowPrivateComments(response.show_private_comments);
      if (response.show_private_comments) {
        this.getPrivateUsers();
      }
    } catch (error) {
      window.console.error(`"Comments#Index for RecordVersion" error ${error}`);
    }
  }

  /**
   * POST /api/react/module_workspaces/:module_workspace_id/comments
   */
  async create(
    commentData: CommentCreateData,
    params?: {
      commentableType: string;
      commentableID: number;
      moduleWorkspaceID: number;
    },
  ) {
    const getCommentTypeProperty: () => CommentTypeProperty | null = () => {
      if (!params) {
        return this.commentTypeProperty;
      }

      if (params.commentableType === "Record") {
        return { record_id: params.commentableID };
      }

      if (params.commentableType === "BaseChecklist") {
        return { template_id: params.commentableID };
      }

      if (params.commentableType === "AttachedDocument") {
        return { attachment_group_id: params.commentableID };
      }

      if (params.commentableType === "QuestionGroup") {
        return { question_group_id: params.commentableID };
      }

      return null;
    };

    try {
      const commentTypeProperty = getCommentTypeProperty();

      if (!commentTypeProperty) {
        throw new Error("CommentTypeProperty is null");
      }
      await CommentsAPI.createComment(
        {
          moduleWorkspaceID:
            params?.moduleWorkspaceID ||
            (this.mainStore.context.moduleWorkspaceID as number),
        },
        { ...commentTypeProperty, comment: commentData },
      );
      // Only refetch when creating comments in modules/creatives etc.
      // Will have re-fetched via Tasks store with myTasks request if providing
      // `moduleWorkspaceID`
      if (!params) {
        // Refetch the list of Comments
        await this.index(this.commentTypeProperty);
      }
    } catch (error) {
      window.console.log(`"Comments#create for RecordVersion" error ${error}`);
    }
  }

  /**
   * PATCH /api/react/module_workspaces/:module_workspace_id/comments/:id
   */
  async update(
    commentID: number,
    commentData: CommentUpdateData,
    moduleWorkspaceID?: number,
  ) {
    try {
      await CommentsAPI.putComment(
        {
          moduleWorkspaceID:
            moduleWorkspaceID ||
            (this.mainStore.context.moduleWorkspaceID as number),
          commentID,
        },
        { comment: commentData },
      );

      // Only refetch when updating comments in modules/creatives etc.
      // Will have re-fetched via Tasks store with myTasks request if providing
      // `moduleWorkspaceID`
      if (!moduleWorkspaceID) {
        // Refetch the list of Comments
        await this.index(this.commentTypeProperty);
      }
    } catch (error) {
      window.console.log(`"Comments#update for RecordVersion" error ${error}`);
    }
  }

  /**
   * DELETE /api/react/module_workspaces/:module_workspace_id/comments/:id
   */
  async delete(commentID: number, moduleWorkspaceID?: number) {
    try {
      await CommentsAPI.deleteComment({
        commentID,
        moduleWorkspaceID: getCommentModuleWorkspaceID(
          moduleWorkspaceID,
          this.mainStore.context.moduleWorkspaceID,
        ),
      });

      // Only refetch when updating comments in modules/creatives etc.
      // Will have re-fetched via Tasks store with myTasks request if providing
      // `moduleWorkspaceID`
      if (!moduleWorkspaceID) {
        // Refetch the list of Comments
        await this.index(this.commentTypeProperty);
      }
    } catch (error) {
      window.console.log(`"Comments#delete" error ${error}`);
    }
  }

  async getPrivateUsers(moduleWorkspaceID?: number) {
    try {
      const response = await CommentsAPI.getPrivateUsers({
        moduleWorkspaceID: getCommentModuleWorkspaceID(
          moduleWorkspaceID,
          this.mainStore.context.moduleWorkspaceID,
        ),
      });

      this.setPrivateUsers(response.users.map(toDisplayUser));
    } catch (error) {
      window.console.error(error);
    }
  }

  async updateSubscription(commentID: number, enabled: boolean) {
    try {
      await CommentsAPI.updateSubscription(commentID, enabled);

      this.mainStore.toast.setText(
        `You have ${
          enabled ? "subscribed" : "unsubscribed"
        } to the comment thread!`,
      );

      await this.index(this.commentTypeProperty);
    } catch (error) {
      window.console.error("Comments#updateSubscription error", error);
    }
  }

  // Actions
  setComments(value: UserComment[]) {
    this.comments = value || [];
  }

  setPrivateComments(value: UserComment[] | undefined) {
    this.privateComments = value || [];
  }

  setPrivateUsers(value: DisplayUser[]) {
    this.privateUsers = value || [];
  }

  setShowPrivateComments(value: boolean) {
    this.showPrivateComments = value || false;
  }

  setCommentTypeProperty(value: CommentTypeProperty | null) {
    this.commentTypeProperty = value;
  }

  setRecordID(value: number | null) {
    this.recordID = value;
  }

  setRecordType(value: TaskableType | null) {
    this.recordType = value;
  }

  async copyCommentLink(comment: UserComment) {
    await navigator.clipboard.writeText(comment.details_view_url);

    this.mainStore.toast.setInfoText("Comment link copied to clipboard");
  }

  // Store Helpers

  cleanup() {
    this.setCommentTypeProperty(null);
    this.setRecordType(null);
    this.setComments([]);
    this.setPrivateComments([]);
    this.setShowPrivateComments(false);
    this.setPrivateUsers([]);
  }
}

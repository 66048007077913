import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateFavoriteModuleWorkspaceMutationRequest,
  CreateFavoriteModuleWorkspaceMutationResponse,
  CreateFavoriteModuleWorkspacePathParams,
} from "../../models/CreateFavoriteModuleWorkspace";

/**
 * @description Create a favorite module workspace
 * @summary Create a favorite module workspace
 * @link /workspaces/:workspace_id/favorites
 */
export async function createFavoriteModuleWorkspace(
  workspaceId: CreateFavoriteModuleWorkspacePathParams["workspace_id"],
  data: CreateFavoriteModuleWorkspaceMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<CreateFavoriteModuleWorkspaceMutationResponse>["data"]
> {
  const res = await client<
    CreateFavoriteModuleWorkspaceMutationResponse,
    CreateFavoriteModuleWorkspaceMutationRequest
  >({
    method: "post",
    url: `/workspaces/${workspaceId}/favorites`,
    data,
    ...options,
  });
  return res.data;
}

import "./generic-input-cell.scss";

import classNames from "classnames";
import React, { useRef, useState } from "react";

import TableCell from "../TableCell";
import ContentCell from "./ContentCell";

type GenericInputCellProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  "onChange" | "onInput"
> & {
  onChange: (value: string) => void;
  isFirstDataCell?: boolean;
  isLastDataCell?: boolean;
  minWidth?: number;
};

const GenericInputCell = ({
  onChange,
  className,
  onBlur,
  onFocus,
  minWidth,
  isFirstDataCell,
  isLastDataCell,
  ...rest
}: GenericInputCellProps) => {
  const ref = useRef(null);
  const [focused, setFocused] = useState(false);
  return rest.disabled ? (
    <ContentCell content={rest.value} />
  ) : (
    <TableCell
      minWidth={minWidth}
      noPadding
      isFocused={focused}
      isEditable
      isFirstDataCell={isFirstDataCell}
      isLastDataCell={isLastDataCell}
    >
      <input
        spellCheck={false}
        {...rest}
        className={classNames(className, "generic-input-cell")}
        onChange={(ev) => onChange(ev.target.value)}
        ref={ref}
        onFocus={(...args) => {
          setFocused(true);
          onFocus?.(...args);
        }}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
      />
    </TableCell>
  );
};

export default GenericInputCell;

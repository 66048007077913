import { Button } from "@themis/ui";
import classNames from "classnames";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { PiLockSimple } from "react-icons/pi";

import { COLOR } from "@/config/theme";

interface AddRegisterMenuProps {
  groupId: number;
  registers?: Array<{ id: number; title: string }>;
  disabledIds?: number[];
  onClose: (refetch?: boolean) => void;
  onSubmit: (groupId: number, registerIds: number[]) => void;
}

function AddRegisterMenu(props: AddRegisterMenuProps) {
  const [selectedRegisters, setSelectedRegisters] = useState<number[]>([]);

  const submit = async () => {
    props.onSubmit(props.groupId, selectedRegisters);
    setSelectedRegisters([]);
    props.onClose();
  };

  const handleRegisterSelect = (id: number) => {
    const exists = selectedRegisters.includes(id);
    if (exists) {
      setSelectedRegisters((prev) => prev.filter((pid) => pid !== id));
    } else {
      setSelectedRegisters((prev) => [...prev, id]);
    }
  };

  return (
    <div>
      <div
        className=" tw-flex tw-items-center tw-justify-between tw-px-5 tw-py-3 tw-text-neutral-500"
        style={{ borderBottom: `1px solid ${COLOR.generalIconGray}` }}
      >
        <p className="tw-font-sans tw-text-base tw-font-semibold">
          Add New Register
        </p>
        <IoClose
          className="tw-h-5 tw-w-5 tw-cursor-pointer"
          onClick={() => props.onClose()}
        />
      </div>
      <div className="tw-px-5 tw-pt-4">
        {props.registers?.length === 0 && (
          <p className="emptystate">
            You do not have any Risk Registers with score data. Once you do,
            have Risk Register with score data they will show up here.
          </p>
        )}
        {props.registers?.length !== 0 && (
          <div className="tw-text-sm tw-font-semibold tw-text-primaryDim-300">
            Select the Risk Registers you want to score
          </div>
        )}
      </div>
      {props.registers
        ?.sort((a, b) => (b.title > a.title ? -1 : 1))
        .map((register) => {
          const isDisabled = props.disabledIds?.includes(register.id);
          return (
            <div
              key={register.id}
              className={classNames(
                "tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-px-5 tw-py-[10px]",
                {
                  "tw-bg-secondary-25": selectedRegisters.includes(register.id),
                  "tw-cursor-not-allowed": isDisabled,
                },
              )}
              {...(!isDisabled
                ? { onClick: () => handleRegisterSelect(register.id) }
                : {})}
            >
              <p
                className={classNames("tw-text-sm tw-font-semibold", {
                  "tw-text-neutral-300": isDisabled,
                  "tw-text-neutral-500": !isDisabled,
                  "tw-text-secondary-300": selectedRegisters.includes(
                    register.id,
                  ),
                })}
              >
                {register.title}
              </p>
              {isDisabled ? (
                <PiLockSimple className="tw-mr-[1px] tw-h-5 tw-w-5 tw-fill-neutral-300" />
              ) : (
                <input
                  type="checkbox"
                  readOnly
                  checked={selectedRegisters.includes(register.id)}
                  className="tw-boder-neutral-500 tw-h-4 tw-w-4 tw-text-neutral-500"
                />
              )}
            </div>
          );
        })}
      <div
        className="tw-mt-2 tw-px-5 tw-py-3"
        style={{
          borderTop: `1px solid ${COLOR.generalIconGray}`,
          borderBottom: `1px solid ${COLOR.generalIconGray}`,
        }}
      >
        <Button disabled={selectedRegisters.length === 0} onClick={submit}>
          Confirm
        </Button>
      </div>
    </div>
  );
}

export default AddRegisterMenu;

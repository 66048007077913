import classNames from "classnames";
import type { PropsWithChildren } from "react";
import React from "react";

import { Typography } from "@/components/Elements";

type EmptyMessageProps = PropsWithChildren<{
  className?: string;
}>;

function EmptyMessage(props: EmptyMessageProps) {
  return (
    <div
      className={classNames(
        "tw-flex tw-min-h-[120px] tw-w-full tw-max-w-[600px] tw-items-center tw-justify-center tw-rounded-lg tw-bg-neutral-50",
        props.className,
      )}
    >
      <Typography label={props.children} color="extrasBlueGrayDarker" />
    </div>
  );
}

export default EmptyMessage;

import { useInfiniteQuery } from "@tanstack/react-query";

import { listRelatables } from "../gen/axios/sharedController";

export const SHARED_QUERY_KEY = "shared";

export function useRelatables(
  companyId: number | undefined,
  moduleWorkspaceId: number | undefined,
  name?: string,
  limit: number = 10,
) {
  return useInfiniteQuery({
    queryKey: [SHARED_QUERY_KEY, companyId, moduleWorkspaceId, name],
    queryFn: (params) => {
      if (!companyId || !moduleWorkspaceId) {
        return;
      }

      return listRelatables(companyId, {
        module_workspace_id: moduleWorkspaceId,
        name,
        offset: params.pageParam * limit,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (!lastPage?.meta.next_page) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });
}

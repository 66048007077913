import { useQuery } from "@tanstack/react-query";

import api from "../legacy/api";

export const getRiskMethodologiesKey = (workspaceId: string) => [
  workspaceId,
  "risk_methodologies",
];

export function useRiskMethodologies(workspaceId: number) {
  return useQuery({
    queryKey: getRiskMethodologiesKey(workspaceId.toString()),
    queryFn: () => api.get(`/workspaces/${workspaceId}/risk_methodologies`),
    enabled: !!workspaceId,
  });
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  controlMappingsDetails,
  controlMappingsEffectiveness,
  controlMappingsLibrary,
  createAndFinalizeControlMappings,
  createControlMappings,
} from "../gen/axios/controlMappingsController";
import type { CreateAndFinalizeControlMappingsMutationRequest } from "../gen/models/CreateAndFinalizeControlMappings";
import type { CreateControlMappingsMutationRequest } from "../gen/models/CreateControlMappings";

export const CREATE_CONTROL_MAPPINGS = "create_control_mapping";
export const CREATE_AND_FINALIZE_CONTROL_MAPPINGS =
  "create_and_finalize_control_mapping";
export const CONTROL_MAPPINGS_DETAILS = "control_mapping_details";
export const CONTROL_MAPPINGS_LIBRARY = "control_mapping_library";
export const LIST_CONTROLS_MODULES_QUERY_KEY = "list_controls_modules";
export const CONTROL_MAPPINGS_EFFECTIVENESS = "control_mapping_effectiveness";

export function useCreateControlMappings({
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateControlMappingsMutationRequest) =>
      createControlMappings(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CREATE_CONTROL_MAPPINGS, recordVersionId],
      });
      onSuccess();
    },
    onError,
  });
}

export function useControlMappingsDetails(recordVersionId: number) {
  return useQuery({
    queryKey: [CONTROL_MAPPINGS_DETAILS, recordVersionId],
    queryFn: () => controlMappingsDetails(),
    enabled: !!recordVersionId,
  });
}

export function useControlMappingsLibrary(
  recordVersionId: number,
  libraryType?: string,
) {
  return useQuery({
    queryKey: [CONTROL_MAPPINGS_LIBRARY, libraryType, recordVersionId],
    queryFn: () => controlMappingsLibrary({ library_type: libraryType }),
    enabled: !!recordVersionId,
  });
}

export function useControlMappingsEffectiveness(
  recordVersionId: number,
  moduleWorkspaceID: number,
) {
  return useQuery({
    queryKey: [CONTROL_MAPPINGS_EFFECTIVENESS, recordVersionId],
    queryFn: () => controlMappingsEffectiveness(moduleWorkspaceID),
    enabled: !!recordVersionId,
  });
}

export function useCreateAndFinalizeControlMappings({
  recordVersionId,
  onSuccess = () => {},
  onError = () => {},
}: {
  recordVersionId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateAndFinalizeControlMappingsMutationRequest) =>
      createAndFinalizeControlMappings(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CREATE_AND_FINALIZE_CONTROL_MAPPINGS, recordVersionId],
      });
      queryClient.invalidateQueries({
        queryKey: [LIST_CONTROLS_MODULES_QUERY_KEY, recordVersionId],
      });
      onSuccess();
    },
    onError,
  });
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Unlock = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C14.6888 2 16.8818 4.12231 16.9954 6.78311L17 7V10C18.6569 10 20 11.3431 20 13V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V13C4 11.3431 5.34315 10 7 10H15V7C15 5.40232 13.7511 4.09634 12.1763 4.00509L12 4C10.3431 4 9 5.34315 9 7C9 7.55228 8.55228 8 8 8C7.44772 8 7 7.55228 7 7C7 4.23858 9.23858 2 12 2ZM17 12H7C6.44772 12 6 12.4477 6 13V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V13C18 12.4477 17.5523 12 17 12Z"
      fill="#353549"
    />
  </svg>
);

Unlock.displayName = "UnlockIcon";

export default Unlock;

import dayjs from "dayjs";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";

import type { RecordVersion } from "@/api";
import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import Table from "@/components/table/Table";
import { useMainStore } from "@/contexts/Store";
import type { Section } from "@/stores/types/section-tags";

import KRIInputSection from "../components/kri-input/KRIInputSection";
import {
  getMonths,
  isFieldNameAQuarter,
} from "../components/kri-summary/helpers";
import KRITabsHeader from "../components/KRITabsHeader";
import TimeRange from "../components/TimeRange/TimeRange";

const KRIInputPage = ({ moduleName }: { moduleName: string }) => {
  const mainStore = useMainStore();

  const recordVersions: RecordVersion[] = mainStore.recordVersions.list;
  const { moduleWorkspaceID } = mainStore.context;
  const { data } = mainStore.keyRiskIndicators;

  const [kriValuesHeader, setKriValuesHeader] = React.useState<string[]>([]);
  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();

  // If the data is updated or the start or end date is changed,
  // refresh the month columns in the table
  const refreshMonths = (start?: Date, end?: Date) => {
    const headers = getMonths(toJS(data?.record_versions), start, end);
    setKriValuesHeader(headers);
  };

  useEffect(() => {
    refreshMonths();
  }, [data]);

  useEffect(() => {
    // Filter out the quarter headers
    const validDates = kriValuesHeader.filter(
      (date) => !isFieldNameAQuarter(date),
    );

    if (!startDate && validDates.length > 0) {
      setStartDate(
        dayjs(validDates[validDates.length - 1], "MMM YYYY").toDate(),
      );
    }
    if (!endDate && validDates.length > 0) {
      setEndDate(dayjs(validDates[0], "MMM YYYY").toDate());
    }
  }, [kriValuesHeader]);

  useEffect(() => {
    mainStore.sectionTags.index(Number(moduleWorkspaceID));
  }, [moduleWorkspaceID]);

  const sectionTagsList: Section[] = mainStore.sectionTags.list;

  return (
    <DashboardContent>
      <DashboardHeader
        title={moduleName}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />
      <DashboardContentWrapper>
        <KRITabsHeader />
        <TimeRange
          refreshMonths={refreshMonths}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <Table extraClassNames="kri kri-input-tables">
          <div className="table-list-wrap">
            {/* Default table with records that have no section id */}
            <KRIInputSection
              recordVersions={recordVersions.filter(
                (recordVersion) => !recordVersion.section_tag_id,
              )}
              kriValuesHeader={kriValuesHeader}
              refreshMonths={refreshMonths}
              startDate={startDate}
              endDate={endDate}
              isDropdownOpen
            />
            {/* Render other tables with section ids */}
            {sectionTagsList.map((sectionTag) => {
              const filteredRecordVersions = recordVersions.filter(
                (recordVersion) =>
                  recordVersion.section_tag_id === sectionTag.id,
              );
              return (
                <KRIInputSection
                  key={sectionTag.id}
                  recordVersions={filteredRecordVersions}
                  kriValuesHeader={kriValuesHeader}
                  refreshMonths={refreshMonths}
                  sectionTag={sectionTag}
                  startDate={startDate}
                  endDate={endDate}
                  isDropdownOpen
                />
              );
            })}
          </div>
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
};

export default observer(KRIInputPage);

import { makeObservable } from "mobx";

import { attachmentFileType } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class AttachmentsStore {
  mainStore: MainStore;

  constructor(mainStore: MainStore) {
    makeObservable(this, {});

    this.mainStore = mainStore;
  }

  // API

  /**
   * POST /api/react/attachment_groups/:attachment_group_id/attachments
   */
  // @ts-expect-error TS(7031) FIXME: Binding element 'attachmentGroupID' implicitly has... Remove this comment to see the full error message
  async create({ attachmentGroupID, signedID }) {
    const attachmentParams = {
      file_type: attachmentFileType.direct_upload,
      original: signedID,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/attachment_groups/${attachmentGroupID}/attachments`,
        headers: this.mainStore.getHeaders(),
        data: { attachment: attachmentParams },
      });

      return response?.data?.attachment;
    } catch (error) {
      window.console.log(`"AttachmentGroups#create" error ${error}`);

      return null;
    }
  }

  /**
   * DELETE /api/react/attachments/:id
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentID' implicitly has an 'any' t... Remove this comment to see the full error message
  async delete(attachmentID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/attachments/${attachmentID}`,
        headers: this.mainStore.getHeaders(),
      });

      return true;
    } catch (error) {
      window.console.log(`"AttachmentGroups#create" error ${error}`);
      return false;
    }
  }

  /**
   * POST /api/react/record_versions/:record_version_id/attachments/:id/replace
   */
  // @ts-expect-error TS(7031) FIXME: Binding element 'recordVersionID' implicitly has a... Remove this comment to see the full error message
  async replace({ recordVersionID, attachmentID, signedID }) {
    const attachmentParams = {
      file_type: attachmentFileType.direct_upload,
      original: signedID,
    };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/attachments/${attachmentID}/replace`,
        headers: this.mainStore.getHeaders(),
        data: { attachment: attachmentParams },
      });
    } catch (error) {
      window.console.log(`"AttachmentGroups#create" error ${error}`);
    }
  }

  // Actions

  // Store Helpers

  cleanup() {}
}

import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { formatDateForCellValue } from "@/components/helpers/DateFormatters";
import { TIME_RANGE_OPTIONS } from "@/components/reports/common/MetricsPage/constants";
import { getInterval } from "@/components/reports/common/MetricsPage/helpers";

import useDidMountEffect from "./useDidMount";
import useMetricsParams from "./useMetricsParams";

// @ts-expect-error TS(7031) FIXME: Binding element 'defaultDateField' implicitly has ... Remove this comment to see the full error message
const useMetricsData = ({ defaultDateField }) => {
  const { timeRangeParam, setMetricsParam, intervalParam } = useMetricsParams();

  const [timeRange, setTimeRange] = useState(timeRangeParam || "Last 365 Days");
  const [intervalDisplayName, setIntervalDisplayName] = useState(
    intervalParam || "Auto",
  );
  const [customCalendarOptions, setCustomCalendarOptions] = useState<{
    [x: string]: string;
    // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
  }>({ Custom: null });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dateField, setDateField] = useState(defaultDateField);
  const [startDate, setStartDate] = useState<Date | undefined>(
    dayjs().subtract(365, "days").toDate(),
  );
  const [endDate, setEndDate] = useState<Date | undefined>(dayjs().toDate());
  const [calendarDates, setCalendarDates] = useState({
    start: null,
    end: null,
  });

  const updateDateRange = () => {
    if (timeRange === "All Time") {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setStartDate(null);
      // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      setEndDate(null);
    } else if (Object.keys(TIME_RANGE_OPTIONS).includes(timeRange)) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const days = TIME_RANGE_OPTIONS[timeRange];
      const start = dayjs().subtract(days, "days");

      setStartDate(start.toDate());
      setEndDate(dayjs().toDate());
    } else if (timeRange.includes("Custom")) {
      const [key] = Object.keys(customCalendarOptions);
      const dates = customCalendarOptions[key]?.split(" - ");

      if (dates) {
        setStartDate(new Date(dates[0]));
        setEndDate(new Date(dates[1]));
      }
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'date' implicitly has an 'any' type.
  const updateCalendar = (date) => {
    setCalendarDates({
      start: date[0],
      end: date[1],
    });
  };

  useEffect(() => {
    if (timeRange !== "Custom") {
      updateDateRange();
    }
  }, [timeRange]);

  useDidMountEffect(() => {
    if (timeRangeParam) {
      setTimeRange(timeRangeParam);
    }
  }, [timeRangeParam]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const handleChangeTimeRange = (option) => {
    if (option.includes("Custom")) {
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
      setCustomCalendarOptions({ Custom: null });
      setTimeRange("Custom");

      resetCalendarDates();
    } else {
      setTimeRange(option);
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string'.
      setCustomCalendarOptions({ Custom: null });
    }

    setMetricsParam("timeRange", option);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
  const handleChangeInterval = (option) => {
    setIntervalDisplayName(option);
    setMetricsParam("interval", option);
  };

  const interval = getInterval(intervalDisplayName, startDate, endDate);

  const resetCalendarDates = () => {
    setCalendarDates({
      start: null,
      end: null,
    });
  };

  const onCalendarSelect = () => {
    const newOption = `${formatDateForCellValue(
      // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      calendarDates.start,
      true,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    )} - ${formatDateForCellValue(calendarDates.end, true)}`;
    const customOptionKey = `Custom (${newOption})`;

    setCustomCalendarOptions({
      [customOptionKey]: newOption,
    });
    setTimeRange(customOptionKey);

    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setStartDate(calendarDates.start);
    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    setEndDate(calendarDates.end);

    resetCalendarDates();
  };

  return {
    timeRange,
    handleChangeTimeRange,
    startDate,
    endDate,
    dateField,
    onCalendarSelect,
    updateCalendar,
    customCalendarOptions,
    intervalDisplayName,
    handleChangeInterval,
    interval,
  };
};

export default useMetricsData;

import { MiniTag } from "@themis/ui";
import React from "react";

import type { ModuleAssessment } from "@/api";

import { getRatingColorName } from "../../config";

export function ModuleAssessmentControlEffectivenessRating({
  moduleAssessment,
}: {
  moduleAssessment: ModuleAssessment;
}) {
  const color =
    moduleAssessment?.control_effectiveness_rating?.color || "#e1e1e3";
  const title =
    moduleAssessment?.control_effectiveness_rating?.title || "No Control";

  return (
    <MiniTag size="lg" color={getRatingColorName(color)}>
      {title}
    </MiniTag>
  );
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Book2 = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3.5C1 2.94772 1.44772 2.5 2 2.5H8C9.6356 2.5 11.0878 3.28534 12 4.49951C12.9122 3.28534 14.3644 2.5 16 2.5H22C22.5523 2.5 23 2.94772 23 3.5V18.5C23 19.0523 22.5523 19.5 22 19.5H15C13.8954 19.5 13 20.3954 13 21.5C13 22.0523 12.5523 22.5 12 22.5C11.4477 22.5 11 22.0523 11 21.5C11 20.3954 10.1046 19.5 9 19.5H2C1.44772 19.5 1 19.0523 1 18.5V3.5ZM11 18.0351C10.4117 17.6948 9.72857 17.5 9 17.5H3V4.5H8C9.65687 4.5 11 5.84313 11 7.5V18.0351ZM13 7.5V18.0351C13.5883 17.6948 14.2714 17.5 15 17.5H21V4.5H16C14.3431 4.5 13 5.84313 13 7.5Z"
      fill="#353549"
    />
  </svg>
);

Book2.displayName = "Book2Icon";

export default Book2;

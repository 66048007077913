import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  RecordsByFieldOptionPathParams,
  RecordsByFieldOptionQueryParams,
  RecordsByFieldOptionQueryResponse,
} from "../../models/RecordsByFieldOption";

/**
 * @description Get records grouped by the specified field option
 * @summary Records by field option
 * @link /companies/:company_id/:record_type/reports/records_by_field_option
 */
export async function recordsByFieldOption(
  companyId: RecordsByFieldOptionPathParams["company_id"],
  recordType: RecordsByFieldOptionPathParams["record_type"],
  params: RecordsByFieldOptionQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<RecordsByFieldOptionQueryResponse>["data"]> {
  const res = await client<RecordsByFieldOptionQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/${recordType}/reports/records_by_field_option`,
    params,
    ...options,
  });
  return res.data;
}

import "./styles.scss";

import classNames from "classnames";
import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import Popup from "reactjs-popup";

import { Flex, MiniTag, Typography } from "@/components/Elements";
import iconMap from "@/components/helpers/moduleIcons";
import type { THEMIS_MINITAG_THEMES } from "@/config/theme";
import { useMainStore } from "@/contexts/Store";
import checkBlueIcon from "@/images/table-image/icon/options-check-blue-light.svg";
import type { ValueLabelOption } from "@/stores/types/field-types";
import { RELATIVE_TO } from "@/stores/types/notification-rule-types";

type SelectFieldProps<T extends FieldValues> = UseControllerProps<T> & {
  placeholder: string;
  /**
   * If passed will render a `<MiniTag />` component for the field value
   * and options in dropdown.
   */
  miniTagTheme?: (typeof THEMIS_MINITAG_THEMES)[number];
  options?: ValueLabelOption[];
  /**
   * Show module icon beside each select option
   */
  moduleIcon?: boolean;
};

function SelectField<T extends FieldValues>({
  placeholder,
  options,
  miniTagTheme,
  moduleIcon,
  ...controller
}: SelectFieldProps<T>) {
  const mainStore = useMainStore();
  const { field, fieldState } = useController(controller);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // @ts-expect-error TS(7006) FIXME: Parameter 'newValue' implicitly has an 'any' type.
  const handleChange = (newValue) => {
    field.onChange(newValue);

    setDropdownOpen(false);
  };

  const labelText = (() => {
    // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    const label: string =
      field.value &&
      options?.find((option) => option.value === field.value)?.label;

    // converts `same day of` to `Same day of` for display purposes
    if (label && field.value === RELATIVE_TO.ON) {
      return capitalize(label);
    }

    return label;
  })();

  return (
    <Popup
      keepTooltipInside
      open={dropdownOpen}
      onOpen={() => setDropdownOpen(true)}
      trigger={
        <div
          className={classNames("notification-select", {
            ["notification-select--error"]: fieldState.error,
          })}
        >
          {field.value && miniTagTheme ? (
            <MiniTag theme={miniTagTheme} label={labelText} />
          ) : (
            <Typography
              className={classNames({
                ["notification-select__placeholder"]: !field.value,
                ["notification-select__placeholder--error"]: fieldState.error,
              })}
              label={field.value ? labelText : placeholder}
              size="md"
              color={field.value ? "accentsSkyBlue" : "extrasBlueGrayDarker"}
            />
          )}
        </div>
      }
    >
      <div className="select">
        <div className="select-dropdown">
          {options?.map((option) => {
            const isSelected = field.value === option.value;

            return (
              <li
                key={option.value}
                className={classNames("select-option", {
                  "select-option-active": isSelected,
                })}
                onClick={() => handleChange(option.value)}
              >
                <div className="select-option-label-wrapper">
                  <Flex alignCenter columnGap={6}>
                    {moduleIcon && (
                      <img
                        className="notification-select--icon-image"
                        // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
                        src={iconMap[mainStore.context.themisModuleIdentifier]}
                        alt={`${mainStore.context.themisModuleIdentifier}-icon`}
                      />
                    )}
                    {miniTagTheme ? (
                      <MiniTag label={option.label} theme={miniTagTheme} />
                    ) : (
                      <Typography
                        size="sm"
                        label={option.label}
                        weight="semiBold"
                      />
                    )}
                  </Flex>
                </div>
                {isSelected && (
                  <span className="select-option-check-icon">
                    <img src={checkBlueIcon} alt="check-icon" />
                  </span>
                )}
              </li>
            );
          })}
        </div>
      </div>
    </Popup>
  );
}

export default observer(SelectField);

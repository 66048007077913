import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteFindingMutationResponse,
  DeleteFindingPathParams,
} from "../../models/DeleteFinding";

/**
 * @description Delete finding
 * @summary Delete finding
 * @link /findings/:id
 */
export async function deleteFinding(
  id: DeleteFindingPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteFindingMutationResponse>["data"]> {
  const res = await client<DeleteFindingMutationResponse>({
    method: "delete",
    url: `/findings/${id}`,
    ...options,
  });
  return res.data;
}

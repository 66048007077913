import React from "react";

import themisLogo from "../../images/logo.svg";
import Button from "../Elements/Button";
import Typography from "../Elements/Typography";

const ErrorBoundaryFallback = () => (
  <div className="error-boundary-fallback">
    <a href="/home">
      <img src={themisLogo} alt="themis-logo" />
    </a>
    <Typography
      label="Something went wrong..."
      type="h3"
      size="lg"
      weight="bold"
      color="generalDark"
      className="error-boundary-fallback__title"
    />
    <Typography
      label="We have been notified of this issue and will work to resolve it."
      weight="bold"
      color="generalDarkGray"
    />
    <Typography
      className="error-boundary-fallback__feedback"
      label=" If you have additional feedback, please use the feedback button at the bottom of the page."
      weight="bold"
      color="generalDarkGray"
    />
    <Button
      className="error-boundary-fallback__button"
      onClick={() => (location.pathname = "/home")}
      label="Reload page"
    />
  </div>
);

export default ErrorBoundaryFallback;

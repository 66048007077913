import type { MiniTagProps } from "@themis/ui";
import { MiniTag } from "@themis/ui";
import { capitalize } from "lodash";
import React from "react";

import { findingSource } from "@/api";

const SOURCE_COLOR_MAP: Record<string, MiniTagProps["color"]> = {
  [findingSource.compliance]: "turquoise",
  [findingSource.audit]: "pink",
  [findingSource.regulatory]: "yellow",
} as const;

export function SourceTypeTag({ type }: { type: string }) {
  return <MiniTag color={SOURCE_COLOR_MAP[type]}>{capitalize(type)}</MiniTag>;
}

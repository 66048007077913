import { useState } from "react";

export function useBoolean(props?: { defaultValue?: boolean }) {
  const [value, onChange] = useState<boolean>(
    () => props?.defaultValue || false,
  );

  function onTrue() {
    onChange(true);
  }
  function onFalse() {
    onChange(false);
  }
  function onToggle() {
    onChange((x) => !x);
  }

  return { value, onChange, onTrue, onFalse, onToggle };
}

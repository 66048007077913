import { Stack } from "@themis/ui";
import type { Control } from "react-hook-form";
import { useIntl } from "react-intl";

import { CheckboxFormField } from "../../../../form-fields/checkbox-form-field/checkbox-form-field";
import { TextInputFormField } from "../../../../form-fields/text-input-form-field/text-input-form-field";
import type { ValidationSchema } from "../../use-validation-schema/use-validation-schema";

export interface MultipleColumnControlsProps {
  control: Control<ValidationSchema>;
}

export const MultipleColumnControls = ({
  control,
  ...rest
}: MultipleColumnControlsProps) => {
  const { formatMessage } = useIntl();

  return (
    <Stack spacing="3" {...rest}>
      <TextInputFormField
        required
        control={control}
        name="columnData.name"
        placeholder={formatMessage({ defaultMessage: "Column Name" })}
      />

      <CheckboxFormField
        control={control}
        name="columnData.requiredColumn"
        color="primary"
        label={formatMessage({ defaultMessage: "Required Column" })}
      />

      <CheckboxFormField
        control={control}
        name="columnData.multipleSelections"
        color="primary"
        label={formatMessage({ defaultMessage: "Multiple Selections" })}
      />
    </Stack>
  );
};

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const CheckedSolid = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 1C2.79086 1 1 2.79086 1 5V19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5ZM6 12.071L10.2426 16.3137L17.3137 9.24262L15.8995 7.8284L10.2426 13.4853L7.41421 10.6568L6 12.071Z"
      fill="#353549"
    />
  </svg>
);

CheckedSolid.displayName = "CheckedSolidIcon";

export default CheckedSolid;

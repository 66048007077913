import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import checkIcon from "../../../images/table-image/icon/check-small-black-tail.svg";
import { Icon } from "../../Elements";
import ListDefaultApprovers from "./ListDefaultApprovers";

interface Props {
  moduleIdentifier: string;
}

function ShowModuleConfiguration({ moduleIdentifier }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Variables
  const modulesWhichConfConnected = ["marketing", "documents"];

  const moduleWorkspace = mainStore.moduleWorkspaces.list?.find(
    (mw) => mw.themis_module?.identifier === moduleIdentifier,
  );
  const moduleWorkspaceID = moduleWorkspace?.id;
  const moduleConfiguration = mainStore.moduleConfigurations.current;

  // Hooks
  useEffect(() => {
    if (!moduleWorkspaceID) {
      return;
    }

    mainStore.moduleConfigurations.fetch(moduleWorkspaceID);
  }, [moduleWorkspaceID]);

  // Memos
  const moduleConfigurationStatus = useMemo(() => {
    if (modulesWhichConfConnected.includes(moduleIdentifier)) {
      switch (moduleWorkspace?.module_configuration?.approvers_configuration) {
        case "all":
          return "all";
        case "at_least_one":
          return "at_least_one";
        default:
          return "at_least_one";
      }
    }
  }, [moduleWorkspace?.module_configuration, moduleIdentifier]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'approversConfiguration' implicitly has ... Remove this comment to see the full error message
  function handleUpdate(approversConfiguration) {
    const params = { approvers_configuration: approversConfiguration };
    // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
    mainStore.moduleConfigurations.update(moduleConfiguration.id, params);
    setShowPopup(false);
  }

  // Rendering
  const triggerClasses = classNames("select-approvers", { active: showPopup });
  const atLeastOne = classNames("approvals-li", {
    "set-approvals-configuration": moduleConfigurationStatus === "at_least_one",
  });
  const all = classNames("approvals-li", {
    "set-approvals-configuration": moduleConfigurationStatus === "all",
  });

  const renderTrigger = (
    <li className={triggerClasses}>
      <span className="select-approvers">
        {moduleConfigurationStatus === "at_least_one"
          ? "Any 1 approval"
          : "All Approvals"}
      </span>
      <Icon name="chevronDown" color="generalDark" size="de" />
    </li>
  );

  return (
    <div>
      <div className="edit-parameters-element">
        <div className="approvals-list-setiing">
          <div className="configuration-text">
            <p className="approvals-info">No. of Approvals</p>
            <p className="approvals-context">
              Determine how many approvals a record needs before changing record
              status to approved
            </p>
          </div>
          <Popup
            position="bottom left"
            // @ts-expect-error TS(2322) FIXME: Type 'string | Element' is not assignable to type ... Remove this comment to see the full error message
            trigger={moduleConfigurationStatus ? renderTrigger : ""}
            open={showPopup}
            onOpen={() => setShowPopup(true)}
            onClose={() => setShowPopup(false)}
            keepTooltipInside
            nested
          >
            <div className="users-dropdown time-popup-wrap">
              <li
                className={atLeastOne}
                onClick={() => {
                  handleUpdate("at_least_one");
                }}
              >
                Any 1 approval
                {moduleConfigurationStatus === "at_least_one" && (
                  <img src={checkIcon} alt="check-icon" />
                )}
              </li>
              <li
                className={all}
                onClick={() => {
                  handleUpdate("all");
                }}
              >
                All Approvals
                {moduleConfigurationStatus === "all" && (
                  <img src={checkIcon} alt="check-icon" />
                )}
              </li>
            </div>
          </Popup>
        </div>
      </div>

      <ListDefaultApprovers moduleConfiguration={moduleConfiguration} />
    </div>
  );
}

export default observer(ShowModuleConfiguration);

import pluralize from "pluralize";

import type { NotificationRuleData } from "@/stores/types/notification-rule-types";
import { RELATIVE_TO, SCALE } from "@/stores/types/notification-rule-types";

export function getNotifyText(
  scheduled: NotificationRuleData["data"]["scheduled"],
  recurring: NotificationRuleData["data"]["recurring"],
) {
  if (scheduled) {
    const scheduledScale = scheduled.scale?.options[0];

    if (!scheduledScale) {
      return;
    }

    const { interval } = scheduled;

    // Remove `s` char if interval is only `1` e.g. '1 weeks' becomes '1 week'
    const singular = interval.length === 1 && interval[0] === 1;

    return `${scheduled.interval.join(", ")} ${
      singular ? pluralize.singular(scheduledScale) : scheduledScale
    }`;
  }

  if (recurring) {
    const [recurringScale] = recurring.scale.options;
    const interval = recurring.interval.distance.value;

    return pluralize(recurringScale, interval, true);
  }
}

export function getFrequencyText(
  scheduled: NotificationRuleData["data"]["scheduled"],
  recurring: NotificationRuleData["data"]["recurring"],
) {
  if (scheduled) {
    return "One Time";
  }

  if (recurring) {
    switch (recurring.scale.options[0]) {
      case SCALE.DAILY:
        return "Daily";
      case SCALE.WEEKLY:
        return "Weekly";
      case SCALE.MONTHLY:
        return "Monthly";
      default:
        return "";
    }
  }
}

export function getConditionText(
  scheduled: NotificationRuleData["data"]["scheduled"],
  recurring: NotificationRuleData["data"]["recurring"],
) {
  const relative = scheduled
    ? scheduled.relative.options[0]
    : // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      recurring.relative.options[0];

  switch (relative) {
    case RELATIVE_TO.AFTER:
      return "after";
    case RELATIVE_TO.BEFORE:
      return "before";
    case RELATIVE_TO.ON:
      return "Same day of";
    default:
      return "";
  }
}

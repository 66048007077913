import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import Button from "@/components/form/Button";
import TextAreaInput from "@/components/form/TextAreaInput";
import { useMainStore } from "@/contexts/Store";
import logo from "@/images/logo.svg";

function SubmitMoreInformation() {
  const mainStore = useMainStore();
  const recordVersionStore = mainStore.recordVersions;
  // @ts-expect-error TS(2339) FIXME: Property 'token' does not exist on type '{}'.
  const { token } = useParams();
  const recordVersion = recordVersionStore.current;
  const additionalInfoRef = React.createRef();

  useEffect(() => {
    recordVersionStore.fetchPublic(token);
  }, []);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleSubmit(event) {
    event.preventDefault();

    const params = {
      // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
      external_token: recordVersion.external_token,
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      additional_info: additionalInfoRef.current.value,
    };

    mainStore.conflicts.submitRequestMoreInformation(params);
  }

  return (
    <div className="submit-conflict">
      <img src={logo} alt="logo" className="logo" />
      <div className="submit-conflict-container">
        <h2> Submit More Information </h2>

        <form onSubmit={handleSubmit}>
          <section>
            <h4> Additional Information </h4>
            <TextAreaInput
              // @ts-expect-error TS(2322) FIXME: Type '{ type: string; placeholder: string; name: s... Remove this comment to see the full error message
              type="text"
              placeholder="Address any compliance concerns here"
              name="Additional Info"
              inputRef={additionalInfoRef}
            />
          </section>
          <Button title="Submit" />
        </form>
      </div>
    </div>
  );
}

export default observer(SubmitMoreInformation);

import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import type { TableName } from "@/api";
import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import ExportBulk from "../../dashboard/ExportBulk";
import { formatDate, stringToDate } from "../../helpers/DateFormatters";
import { recordTypeForThemisModuleIdentifier } from "../../helpers/nameForThemisModuleIdentifier";
import Loading from "../../Loading";
import RecordsSection from "../procedure/RecordsSection";
import ListSettings from "../settings/ListSettings";
import ListTitleSettings from "../settings/ListTitleSettings";
import Table from "../Table";
import AttestationsSection from "../training/AttestationsSection";
import ArchivedEmpty from "./ArchivedEmpty";

const TABS = [
  {
    name: "Published",
    key: "published",
    value: "published",
    dataTestId: "archived-tab-published-trigger",
  },
  {
    name: "Attestations",
    key: "attestations",
    value: "attestations",
    dataTestId: "archived-tab-attestations-trigger",
  },
];

const ATTESTATION_HEADERS = [
  {
    field_name: "attestation_status",
    title: "Status",
    disabled: false,
    width: undefined,
  },
  {
    field_name: "meta.name",
    title: "Training Title",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "record_version",
    title: "Version",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "attestation_completion",
    title: "Completion %",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "initial_attestation_date",
    title: "Attestation Start Date",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "latest_attestation_date",
    title: "Attestation Due Date",
    icon: null,
    disabled: true,
    width: undefined,
  },
  {
    field_name: "assignees",
    title: "Assignees",
    icon: null,
    disabled: true,
    width: undefined,
  },
];

interface Props {
  moduleIdentifier: ModuleIdentifier;
  tableName: TableName;
}

function Archived({ moduleIdentifier, tableName }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);
  const [records, setRecords] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(TABS[0]);

  // Variables
  const { workspaceID } = mainStore.context;
  const { moduleWorkspaceID } = mainStore.context;
  const hasArchivedAttestations = moduleIdentifier === "training";
  const isAttestation = currentScreen.name === "Attestations";

  // Effects
  useEffect(() => {
    if (workspaceID) {
      mainStore.users.indexForModules({ workspaceID });
    }
  }, [workspaceID]);

  // funcs
  async function loadData() {
    if (workspaceID && moduleIdentifier) {
      setLoading(true);
      const data = isAttestation
        ? await mainStore.trainings.archivedAttestations(workspaceID)
        : // @ts-expect-error TS(2538) FIXME: Property 'archivedList' does not exist on type... Remove this comment to see the full error message
          await mainStore.getStore(moduleIdentifier)?.archivedList();
      setRecords(data?.records || []);
      setFields(data?.fields || []);
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => await loadData())();
  }, [isAttestation, workspaceID, moduleIdentifier]);

  const afterDelete = () => {
    (async () => await loadData())();
  };

  const recordType =
    recordTypeForThemisModuleIdentifier(moduleIdentifier)?.singular || "Record";
  const pluralRecordName =
    recordTypeForThemisModuleIdentifier(moduleIdentifier)?.plural || "Records";

  function handleTabChange(screen: string) {
    const newTab = TABS.find((tab) => tab.value === screen) || TABS[0];

    setFields([]);
    setRecords([]);
    setCurrentScreen(newTab);
  }

  const renderPlaceholder = (
    <ArchivedEmpty
      title={`Review your archived ${pluralRecordName}`}
      description={`Archived ${pluralRecordName} and all their previous versions are stored here. All Records are view only.`}
    />
  );

  const renderBaseRecords =
    !isAttestation &&
    records.map((record) => {
      // @ts-expect-error TS(2339) FIXME: Property 'meta' does not exist on type 'never'.
      const archivedAt = formatDate(stringToDate(record.meta.archived_at));

      return (
        <RecordsSection
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          activeDateRange={archivedAt}
          afterDelete={afterDelete}
          archived
          fields={fields.filter(
            // @ts-expect-error TS(2339) FIXME: Property 'position' does not exist on type 'never'... Remove this comment to see the full error message
            (field) => field.position > 0 && !field.is_hidden,
          )}
          isDropdownOpen
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          key={record.id}
          // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
          moduleWorkspaceID={moduleWorkspaceID}
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          recordID={record.id}
          recordType={recordType}
          // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
          recordVersions={record.record_versions}
          sectionTag={{
            id: -1,
            // @ts-expect-error TS(2339) FIXME: Property 'meta' does not exist on type 'never'.
            title: record.meta.name,
            department_ids: [],
            selectable_user_ids: [],
            editable: false,
            module_workspace_id: 1,
            selectable_department_ids: [],
            files: [],
            created_at: "",
            updated_at: "",
            user_access_count: 0,
            user_ids: [],
            table_id: 1,
          }}
          tableName={tableName}
        />
      );
    });

  const renderAttestationRecords =
    isAttestation &&
    records.map((attestation) => {
      // @ts-expect-error TS(2339) FIXME: Property 'archived_at' does not exist on type 'nev... Remove this comment to see the full error message
      const archivedAt = formatDate(stringToDate(attestation.archived_at));

      const renderHeaders = (
        <>
          {ATTESTATION_HEADERS.map((header) => (
            <ListTitleSettings
              key={header.field_name}
              width={header.width}
              title={header.title}
              fieldName={header.field_name}
            />
          ))}
        </>
      );

      const renderRows = [
        <ListSettings
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          key={attestation.id}
          archived
          headers={ATTESTATION_HEADERS}
          data={attestation}
          model="Attestation"
          // @ts-expect-error TS(2339) FIXME: Property 'global_id' does not exist on type 'never... Remove this comment to see the full error message
          globalID={attestation.global_id}
        />,
      ];

      return (
        <AttestationsSection
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          key={attestation.id}
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          attestationID={attestation.id}
          // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          activeDateRange={archivedAt}
          afterDelete={afterDelete}
          archived
          isDropdownOpen
          rows={renderRows}
          headers={renderHeaders}
          sectionTag={{
            id: -1,
            // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
            title: attestation.name,
            department_ids: [],
            selectable_user_ids: [],
            editable: false,
            created_at: "",
            module_workspace_id: 1,
            selectable_department_ids: [],
            table_id: 1,
            updated_at: "",
            files: [],
            user_access_count: 0,
            user_ids: [],
          }}
        />
      );
    });

  if (loading) {
    return <Loading loadingLayout="table" showTableHeader={false} />;
  }

  return (
    <Table>
      {hasArchivedAttestations && (
        <div className="table-header-wrap">
          <HeaderTabs
            tabs={TABS}
            selectedTab={currentScreen.value}
            onSelectTab={handleTabChange}
            dataTestId="archived-main-tabs"
          />
          <div className="import-export-buttons-container">
            <ExportBulk
              specificStatuses={["archived"]}
              isAttestation={isAttestation}
            />
          </div>
        </div>
      )}
      <div
        className={classNames("archived-list-wrap table-list-wrap", {
          "table-training-wrap": hasArchivedAttestations,
        })}
        data-testid="archived"
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {records.length === 0
          ? renderPlaceholder
          : isAttestation
          ? renderAttestationRecords
          : renderBaseRecords}
      </div>
    </Table>
  );
}

export default observer(Archived);

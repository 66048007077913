import { observer } from "mobx-react";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import legacyApi from "@/api/legacy/legacy-api";
import { useMainStore } from "@/contexts/Store";

import { API_URL } from "../constants";

function FigmaIntegrationAuth() {
  const mainStore = useMainStore();
  const { search } = useLocation();

  legacyApi({
    method: "GET",
    url: `${API_URL}/integrations/figma/oauth/handle_redirect${search}`,
    headers: mainStore.getHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      mainStore.users.setUser(response.data.user);
    }
  });

  return <Redirect to="/user/integrations" />;
}

export default observer(FigmaIntegrationAuth);

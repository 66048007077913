import classNames from "classnames";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  createTileLink,
  dateRangeStringToDateRange,
  mapHeader,
  timeRangeStringToDateRange,
} from "@/components/reports/common/MetricsPage/helpers";
import { useMainStore } from "@/contexts/Store";

import { Dashboards } from "../../types";

const NumberOfCountsTable = ({
  columnHeaders,
  data,
  totalData,
  timeRange,
  dashboard,
  intervalDisplayName,
}: {
  columnHeaders: Array<string>;
  data: Map<string, Record<string, number>>;
  name?: string;
  timeRange?: string;
  totalData?: { total: number; label: string; link: string }[];
  dashboard: Dashboards;
  intervalDisplayName: string;
}) => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const history = useHistory();
  const location = useLocation();

  const inStatusDashboard = dashboard === Dashboards.STATUS;
  const isTimeToCloseDashboard = dashboard === Dashboards.TIME_TO_CLOSE;

  const groupFieldName = inStatusDashboard
    ? mainStore.reports.groupFieldName
    : mainStore.reports.imSourceGroupName;

  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  const redirectToFilteredPage = ({ label, dateRangeString }) => {
    let startDate;
    let endDate;

    if (dateRangeString) {
      // @ts-expect-error TS(2461) FIXME: Type 'any[] | undefined' is not an array type.
      [startDate, endDate] = dateRangeStringToDateRange(
        dateRangeString,
        intervalDisplayName,
        timeRange,
      );
    } else {
      [startDate, endDate] = timeRangeStringToDateRange(timeRange);
    }
    if (["N/A", "n/a", "N/a"].includes(label)) {
      return;
    }

    const link = createTileLink({
      workspaceID,
      label,
      startDate,
      endDate,
      groupFieldName: isTimeToCloseDashboard ? undefined : groupFieldName,
      // @ts-expect-error TS(2322) FIXME: Type 'Dashboards.TIME_TO_CLOSE | undefined' is not... Remove this comment to see the full error message
      dashboard: isTimeToCloseDashboard ? dashboard : undefined,
      identifier: mainStore.context.themisModuleIdentifier,
      fields: mainStore.fields.allFields,
      departments: mainStore.departments.departments,
    });

    history.push(link, { from: location.pathname });
  };

  const renderRows = () => {
    // @ts-expect-error TS(7034) FIXME: Variable 'rows' implicitly has type 'any[]' in som... Remove this comment to see the full error message
    const rows = [];

    data.forEach((row, dateRangeString) => {
      rows.push(
        <>
          <td
            className="metrics-row month-row"
            data-testid={`${dateRangeString}-item`}
          >
            {dateRangeString}
          </td>
          {Object.keys(row).map((l, index) => {
            const label = columnHeaders[index];
            const cellValue = row[label];

            return (
              <td
                key={label}
                className="metrics-row count-link"
                data-testid={`${dateRangeString}-${label}`}
                onClick={() =>
                  redirectToFilteredPage({ label, dateRangeString })
                }
              >
                {cellValue}
              </td>
            );
          })}
        </>,
      );
    });

    // @ts-expect-error TS(7005) FIXME: Variable 'rows' implicitly has an 'any[]' type.
    return rows.reverse();
  };

  return (
    <table
      className="metrics-table"
      data-testid="metrics-table"
      id="number-of-issues-table"
    >
      <thead>
        <tr
          className={classNames("metrics-row-container border-top", {
            "status-dashboard": inStatusDashboard,
          })}
        >
          <th className="metrics-row" />
          {columnHeaders.map((label) => (
            <th key={label} className="metrics-row">
              {`${mapHeader(
                mainStore.fieldOptions.all,
                groupFieldName,
                label,
              )}`}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {renderRows().map((row, index) => (
          <tr
            key={index}
            className={classNames("metrics-row-container", {
              "status-dashboard": inStatusDashboard,
            })}
          >
            {row}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr
          className={classNames("metrics-row-container", {
            "status-dashboard": inStatusDashboard,
          })}
        >
          <td className="metrics-row month-row" data-testid="metrics-total">
            Totals
          </td>
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {totalData.map((item) => {
            return (
              <td
                key={item.label}
                className="metrics-row count-link"
                data-testid={`total-${item.label}`}
                onClick={() =>
                  redirectToFilteredPage({
                    label: item.label,
                    dateRangeString: null,
                  })
                }
              >
                {item.total || 0}
              </td>
            );
          })}
        </tr>
      </tfoot>
    </table>
  );
};

export default NumberOfCountsTable;

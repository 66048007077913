import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Trash = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7189 9.1963C18.7189 9.1963 18.1382 16.402 17.8013 19.4371C17.753 19.8739 17.6209 20.2324 17.3634 20.4706C17.1112 20.7057 16.7516 20.8076 16.318 20.8152C13.5319 20.8656 10.7426 20.8688 7.95753 20.8098C7.95753 20.8098 7.95753 20.8098 7.95646 20.8098C7.55185 20.8023 7.21485 20.6982 6.97552 20.4706C6.72438 20.2313 6.59238 19.875 6.54515 19.4446C6.20601 16.3827 5.62861 9.1963 5.62861 9.1963C5.58139 8.60602 5.06302 8.16492 4.47274 8.21215C3.88246 8.26044 3.44136 8.77774 3.48858 9.36802C3.48858 9.36802 4.07027 16.5995 4.41156 19.6807C4.5221 20.6789 4.91062 21.4688 5.49446 22.0247C6.09118 22.5935 6.90254 22.9359 7.91353 22.9563C10.7254 23.0153 13.5427 23.0121 16.3567 22.9616C17.3945 22.9423 18.2198 22.6053 18.824 22.044C19.4251 21.4859 19.8222 20.6917 19.9348 19.6732C20.274 16.6188 20.8589 9.36909 20.8589 9.36909C20.9061 8.77774 20.465 8.26044 19.8747 8.21215C19.2845 8.16492 18.7672 8.60602 18.7189 9.1963ZM6.5795 4.74452H3.07324C2.48081 4.74452 2 5.22533 2 5.81776C2 6.41018 2.48081 6.89099 3.07324 6.89099H21.2732C21.8656 6.89099 22.3464 6.41018 22.3464 5.81776C22.3464 5.22533 21.8656 4.74452 21.2732 4.74452H17.7669C17.4353 4.74452 17.1509 4.50948 17.0854 4.18536L16.8246 2.88138C16.8203 2.85884 16.8149 2.83631 16.8096 2.81377C16.522 1.74161 15.5518 1 14.4452 1H9.90224C8.79466 1 7.82445 1.74161 7.5379 2.81377C7.53146 2.83631 7.52609 2.85884 7.5218 2.88138L7.261 4.18536C7.19661 4.50948 6.91113 4.74452 6.58057 4.74452H6.5795ZM15.0119 4.74452C15.0001 4.69944 14.9904 4.6533 14.9808 4.60715C14.9808 4.60715 14.7297 3.34824 14.7275 3.34395C14.6856 3.22589 14.5719 3.14647 14.4452 3.14647H9.90224C9.77452 3.14647 9.66183 3.22589 9.61783 3.34824L9.36562 4.60715C9.35596 4.6533 9.3463 4.69944 9.33449 4.74452H15.0119Z"
      fill="#353549"
    />
  </svg>
);

Trash.displayName = "TrashIcon";

export default Trash;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import { PiCaretRightBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { cn } from "../../lib/utils";
var Breadcrumb = React.forwardRef(function (_a, ref) {
    var props = __rest(_a, []);
    return React.createElement("nav", __assign({ ref: ref, "aria-label": "breadcrumb" }, props));
});
Breadcrumb.displayName = "Breadcrumb";
var BreadcrumbList = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("ol", __assign({ ref: ref, className: cn("tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-font-sans tw-text-base tw-font-semibold tw-text-neutral-300", className) }, props)));
});
BreadcrumbList.displayName = "BreadcrumbList";
var BreadcrumbItem = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("div", __assign({ ref: ref, className: cn("tw-inline-flex tw-items-center tw-gap-2", className) }, props)));
});
BreadcrumbItem.displayName = "BreadcrumbItem";
var BreadcrumbLink = React.forwardRef(function (_a, ref) {
    var asChild = _a.asChild, className = _a.className, props = __rest(_a, ["asChild", "className"]);
    var Comp = asChild ? Slot : Link;
    return (React.createElement(Comp, __assign({ ref: ref, className: cn("tw-transition-colors hover:tw-text-neutral-500", className) }, props)));
});
BreadcrumbLink.displayName = "BreadcrumbLink";
var BreadcrumbPage = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("span", __assign({ ref: ref, role: "link", "aria-disabled": "true", "aria-current": "page", className: cn("tw-whitespace-nowrap tw-text-neutral-500", className) }, props)));
});
BreadcrumbPage.displayName = "BreadcrumbPage";
var BreadcrumbSeparator = function (_a) {
    var children = _a.children, className = _a.className, props = __rest(_a, ["children", "className"]);
    return (React.createElement("div", __assign({ role: "presentation", "aria-hidden": "true", className: cn("tw-flex [&>svg]:tw-size-4", className) }, props), children !== null && children !== void 0 ? children : React.createElement(PiCaretRightBold, null)));
};
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";
var Breadcrumbs = function (_a) {
    var breadcrumbItems = _a.breadcrumbItems;
    return (React.createElement(Breadcrumb, null,
        React.createElement(BreadcrumbList, null, breadcrumbItems.map(function (_a, index) {
            var label = _a.label, to = _a.to;
            return (React.createElement(BreadcrumbItem, { key: index },
                to ? (React.createElement(BreadcrumbLink, { to: to }, label)) : (React.createElement(BreadcrumbPage, null, label)),
                index < breadcrumbItems.length - 1 && React.createElement(BreadcrumbSeparator, null)));
        }))));
};
export { Breadcrumbs };

import "./questionnaire-form.scss";

import { observer } from "mobx-react";
import React, { useState } from "react";

import { Icon, Typography } from "@/components/Elements";
import ThemisLogo from "@/images/logo.svg";
import { mapFormGroupsAndQuestionsToFormGroups } from "@/stores/helpers/RiskAssessmentHelpers";

import type {
  AutoSaveOptions,
  QuestionnaireFormRead,
  QuestionnaireForm as TQuestionnaireForm,
} from "../../../types/questionnaire-form";
import QuestionnaireFormBody from "./QuestionnaireFormBody";
import SubmittedForm from "./SubmittedForm/SubmittedForm";

interface Props {
  questionnaireForm: QuestionnaireFormRead;
  externalId: string;
}

function QuestionnaireForm({ questionnaireForm, externalId }: Props) {
  const [form, setForm] = useState<TQuestionnaireForm>({
    ...questionnaireForm,
    external_id: externalId,
    groups: mapFormGroupsAndQuestionsToFormGroups(
      questionnaireForm.questions,
      questionnaireForm.question_groups,
    ),
  });
  const [autoSaveState, setAutoSaveState] =
    useState<AutoSaveOptions>("no changes");

  if (form.completed) {
    return (
      <SubmittedForm
        downloadSlug={form.download_slug}
        passwordProtected={form.password_protected}
      />
    );
  }

  return (
    <div className="questionnaire-form">
      <img src={form.company_logo_url || ThemisLogo} alt="themis-logo" />
      <Typography
        label="Review your Questionnaire Below"
        size="md"
        color="generalMidnightDark"
        className="questionnaire-form__heading-text"
      />
      <header className="questionnaire-form__header">
        <Typography
          label={form.name}
          color="generalMidnightDark"
          weight="semiBold"
        />
        <div className="questionnaire-form__auto-save-indicator">
          {autoSaveState === "saving" && (
            <>
              <Typography
                color="generalDarkGray"
                label="Answers saving..."
                size="xs"
              />
              <Icon
                name="loading"
                color="generalDarkGray"
                className="auto-save-icon--spinning"
              />
            </>
          )}
          {autoSaveState === "saved" && (
            <>
              <Typography
                color="generalDarkGray"
                label="Answers saved"
                size="xs"
              />
              <Icon
                name="check"
                color="extrasGreen"
                className="auto-save-icon"
              />
            </>
          )}
          {autoSaveState === "failed" && (
            <>
              <Typography
                color="generalDarkGray"
                label="Answers failed to save"
                size="xs"
              />
              <Icon
                name="close"
                color="generalError"
                className="auto-save-icon"
              />
            </>
          )}
        </div>
      </header>
      <QuestionnaireFormBody
        questionnaireForm={questionnaireForm}
        externalId={externalId}
        setAutoSaveState={setAutoSaveState}
        afterSubmit={(questionnaire: TQuestionnaireForm) =>
          setForm(questionnaire)
        }
      />
    </div>
  );
}

export default observer(QuestionnaireForm);

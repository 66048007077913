/**
 * Want to use z.string().date() but it's not available in
 * zod 3.22.4 https://github.com/colinhacks/zod/issues/3387
 * should be out in next version
 */
export function zodDateCheck(str: string) {
  return (
    /^\d{4}-\d{2}-\d{2}$/.test(str) &&
    new Date(str).toISOString().startsWith(str)
  );
}

export function isContractActive(contractEndDate: string) {
  return new Date(contractEndDate) > new Date();
}

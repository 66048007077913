import { COLOR } from "@/config/theme";

export const API_URL = "/api/react";
export const LONG_API_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const SHAREPOINT_CLIENT_ID = "717a3ee6-6203-45be-90d8-f700f0610b82";
export const SHORT_API_FORMAT = "YYYY-MM-DD";

// Matching the colors in the design system/tailwind config
// so that the new Avatar component will be consistent with
// the rest of the app
export const userColors = [
  "#fdb932",
  "#f95939",
  "#cc0e2e",
  "#ff879b",
  "#f042f0",
  "#8f92f9",
  "#aa75f0",
  "#6161c3",
  "#6d8cf9",
  "#55bce7",
];
export const LOADING_DELAY = 300;
export const EXTENSION_URL =
  "chrome-extension://hibhkgbenmcngiacpdofligolpclnbpl/assets/icon.png";
export const LINK_EXTENSION_STORE =
  "https://chrome.google.com/webstore/detail/themiscom-chrome-extensio/hibhkgbenmcngiacpdofligolpclnbpl?hl=en-US";

// Reporting
export const graphColors = [
  COLOR.extrasLightNavy,
  COLOR.extrasAqua,
  COLOR.logoC4,
  COLOR.accentsBlue,
  COLOR.extrasFlamingo,
  COLOR.accentsPink,
  COLOR.extrasDeepSkyBlue,
  COLOR.accentsViolet,
  COLOR.extrasLightBlue,
  COLOR.accentsNavy,
  COLOR.accentsOrange,
  COLOR.accentsPurple,
  COLOR.extrasWine,
  COLOR.accentsLavender,
  COLOR.extrasPersianBlue,
  COLOR.extrasTeal,
  COLOR.extrasGrassGreen,
  COLOR.logoC2,
  COLOR.extrasBlush,
  COLOR.extrasShamrock,
  COLOR.extrasFuchsia,
  COLOR.generalError,
  COLOR.generalDark,
  COLOR.accentsNavy,
  COLOR.brandingDimViolet,
  COLOR.extrasCarmine,
  COLOR.extrasGold,
  COLOR.accentsSkyBlue,
  COLOR.extrasMint,
];

export const pieChartColors = [
  COLOR.extrasDeepSkyBlue,
  COLOR.accentsPurple,
  COLOR.extrasLightBlue,
  COLOR.extrasGold,
  COLOR.extrasCarmine,
  COLOR.extrasGrassGreen,
  COLOR.extrasLightNavy,
  COLOR.accentsLavender,
  COLOR.accentsBlue,
  COLOR.accentsSkyBlue,
  COLOR.brandingDimTurquoise,
  COLOR.accentsPink,
  COLOR.extrasGreen,
  COLOR.extrasMint,
  COLOR.extrasFuchsia,
  COLOR.logoC4,
  COLOR.logoC3,
  COLOR.logoC2,
  COLOR.accentsOrange,
  COLOR.extrasBlush,
  COLOR.generalError,
  COLOR.extrasFlamingo,
  COLOR.accentsViolet,
  COLOR.generalDark,
  COLOR.extrasPersianBlue,
  COLOR.extrasGinger,
  COLOR.extrasShamrock,
  COLOR.accentsNavy,
  COLOR.brandingDimViolet,
];

export const graphOptions = {
  barThickness: 20,
  borderRadius: 12,
  responsive: true,
  parsing: {
    xAxisKey: "month",
    yAxisKey: "count",
  },
  plugins: {
    legend: {
      position: "top",
      display: true,
      title: {
        display: false,
      },
    },
  },

  stack: "source",

  elements: {
    bar: {
      barThickness: 500,
      backgroundColor: graphColors[0],
    },
  },

  scales: {
    y: {
      min: 0,
      title: {
        text: "Count",
        display: true,
        font: {
          size: 14,
          weight: "bold",
        },
      },
      ticks: {
        precision: 0,
        font: {
          size: 10,
          weight: "bold",
        },
        min: 0,
      },
      grid: {
        display: false,
      },
    },
    x: {
      barThickness: 200, // number (pixels) or 'flex'

      ticks: {
        font: {
          size: 12,
          weight: "bold",
        },
      },
      grid: {
        display: false,
      },
      title: {
        display: true,
        font: {
          size: 14,
          weight: "bold",
        },
      },
    },
  },
};

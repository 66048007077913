import "./risk-methodology-builder.scss";

import classnames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { ConfirmationPopup } from "@/modules/shared/confirmation-popup/ConfirmationPopup";

import { IconButton } from "../../../../../components/Elements";
import { useMainStore } from "../../../../../contexts/Store";
import { RISK_RATING_COLORS } from "../../../config";
import type { RiskRating as RiskRatingInputProps } from "../../../types/risk-methodology";
import ColorSelect from "./ColorSelect";

interface CustomInputProps extends RiskRatingInputProps {
  index?: number;
}

interface RiskRatingRowProps extends CustomInputProps {
  index: number;
  disabled?: boolean;
  globalHigherRange: number;
  updateText: (index: number, value: string) => void;
  updateUpperValue?: (index: number, value: number) => void;
  updateColor: (index: number, value: string) => void;
  removeRating?: (index: number) => void;
  dataTestID?: string;
  hasError: boolean;
}

function RiskRatingRow({
  index,
  text,
  color,
  lower_range,
  higher_range,
  disabled,
  globalHigherRange,
  updateText,
  updateUpperValue,
  dataTestID,
  removeRating,
  updateColor,
  hasError,
}: RiskRatingRowProps) {
  const mainStore = useMainStore();
  const [clonedName, setClonedName] = useState(text);
  const [clonedUpperRange, setClonedUpperRange] = useState<number | string>(
    higher_range,
  );
  const [lastValidValue, setLastValidValue] = useState<number | string>(
    higher_range,
  );
  const [errorText, setErrorText] = useState("");

  // @ts-expect-error TS(7006) FIXME: Parameter 'localDisabled' implicitly has an 'any' ... Remove this comment to see the full error message
  const classess = (localDisabled) =>
    classnames("risk-methodology-builder__content__risk-rating-value", {
      "risk-methodology-builder__content__risk-rating-value__disabled":
        localDisabled,
      "ra-risk-rating-error": !localDisabled && errorText.length > 0,
    });

  const updateValidValue = (val: string) => {
    if (!/^\d*\.?\d*$/.test(val)) {
      return;
    }

    setClonedUpperRange(val);
    const parsedValue = parseFloat(val);
    if (
      !Number.isNaN(parsedValue) &&
      parsedValue >= lower_range &&
      parsedValue < globalHigherRange
    ) {
      setLastValidValue(parsedValue);
    }
  };

  const updateVal = () => {
    const parsedValue = parseFloat(clonedUpperRange as string);
    setErrorText("");

    if (Number.isNaN(parsedValue)) {
      setClonedUpperRange(lastValidValue);
      setErrorText("Higher range cannot be blank");
    }

    if (parsedValue <= lower_range) {
      setClonedUpperRange(lastValidValue);
      setErrorText("Higher range cannot be lower than or equal to lower range");
    }

    if (parsedValue > globalHigherRange) {
      setClonedUpperRange(lastValidValue);
      setErrorText("Higher range cannot be higher than section high range");
    }

    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    updateUpperValue(index, Number(lastValidValue));
  };

  useEffect(() => {
    if (higher_range !== clonedUpperRange) {
      setClonedUpperRange(higher_range);
    }
    if (text !== clonedName) {
      setClonedName(text);
    }
  }, [higher_range, text]);

  useEffect(() => {
    if (errorText.length === 0) {
      return;
    }

    mainStore.toast.setErrorText(errorText);
  }, [errorText]);

  return (
    <div className="risk-methodology-builder__content__risk-rating-input-area">
      <span className="tw-contents">
        <textarea
          className={classnames(
            "risk-methodology-builder__content__risk-rating-name",
            { "ra-risk-rating-error": hasError || clonedName.length === 0 },
          )}
          onChange={(e) => setClonedName(e.target.value)}
          onBlur={(e) => updateText(index, e.target.value)}
          value={clonedName}
          data-testid={`${dataTestID}-row-${index}-name`}
        />
        <ColorSelect
          index={index}
          selectedColor={color}
          updateColor={updateColor}
          availableColors={RISK_RATING_COLORS}
        />
      </span>
      <span className="risk-methodology-builder__content__risk-rating-value-area">
        <span className="risk-rating-range-label"> Range </span>
        <input
          className={classess(true)}
          value={`${index === 0 ? "" : ">"} ${lower_range}`}
          data-testid={`${dataTestID}-row-${index}-lower-range`}
          disabled
          type="text"
        />
        <span className="risk-rating-range-label risk-rating-range-label__dash">
          {" "}
          -{" "}
        </span>
        <input
          className={classess(disabled)}
          onChange={(e) => {
            updateValidValue(e.target.value);
          }}
          onBlur={updateVal}
          value={clonedUpperRange}
          data-testid={`${dataTestID}-row-${index}-higher-range`}
          disabled={disabled}
        />
        <ConfirmationPopup
          text="Are you sure you want to delete this risk rating?"
          onConfirm={() => removeRating?.(index)}
          trigger={
            <IconButton
              icon="trash"
              data-testid={`${dataTestID}-row-${index}-remove`}
            />
          }
          disabled={!removeRating}
        />
      </span>
    </div>
  );
}

export default observer(RiskRatingRow);

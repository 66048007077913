var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { cn } from "../../lib/utils";
var CommandInput = React.forwardRef(function (_a, ref) {
    var className = _a.className, props = __rest(_a, ["className"]);
    return (React.createElement("div", { className: "tw-relative" },
        React.createElement(PiMagnifyingGlassBold, { className: "tw-absolute tw-right-3 tw-top-3 tw-h-4 tw-w-4 tw-text-neutral-500" }),
        React.createElement(CommandPrimitive.Input, __assign({ ref: ref, className: cn("tw-ring-none tw-box-border tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border-none tw-bg-transparent tw-px-4 tw-font-sans tw-outline-none", "tw-text-sm tw-font-medium tw-text-neutral-500", "placeholder:tw-font-sans placeholder:tw-text-sm placeholder:tw-font-medium placeholder:tw-text-neutral-200 placeholder:tw-opacity-100", "disabled:tw-cursor-not-allowed disabled:tw-opacity-50", className) }, props))));
});
CommandInput.displayName = CommandPrimitive.Input.displayName;
export { CommandInput };

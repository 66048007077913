import { DragDropContext } from "@akojic27/react-beautiful-dnd";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import {
  getRecordVersionIdFromDraggableId,
  getSectionIdFromDroppableId,
} from "../../helpers/draggable";
import { getRepositionPayloadFromIndices } from "../../helpers/reordering";

interface Props {
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRecordMoved?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSectionChange?: (...args: any[]) => any;
}

const SectionDragContext = ({
  children,
  onSectionChange,
  onRecordMoved,
}: Props) => {
  const { recordVersions } = useMainStore();
  const { list } = recordVersions;

  const onRecordMovedDefault = async (
    // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionId' implicitly has an 'any... Remove this comment to see the full error message
    recordVersionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sectionId' implicitly has an 'any' type... Remove this comment to see the full error message
    sectionId,
    // @ts-expect-error TS(7006) FIXME: Parameter 'sourceIndex' implicitly has an 'any' ty... Remove this comment to see the full error message
    sourceIndex,
    // @ts-expect-error TS(7006) FIXME: Parameter 'destinationIndex' implicitly has an 'an... Remove this comment to see the full error message
    destinationIndex,
  ) => {
    const relevantRecordVersions = list.filter(
      (recordVersion) => recordVersion.section_tag_id === sectionId,
    );
    await recordVersions.reorder(
      recordVersionId,
      getRepositionPayloadFromIndices(
        relevantRecordVersions,
        sourceIndex,
        destinationIndex,
      ),
    );
  };

  // @ts-expect-error TS(7031) FIXME: Binding element 'source' implicitly has an 'any' t... Remove this comment to see the full error message
  const onDragEnd = ({ source, destination, draggableId }) => {
    if (!source || !destination) {
      return;
    }

    const sectionId = getSectionIdFromDroppableId(destination.droppableId);
    if (sectionId < 0) {
      return;
    }

    // if it is coming from a different list the source index doesn't carry over
    const hasMovedSections = source.droppableId !== destination.droppableId;
    const sourceIndex = hasMovedSections
      ? Number.MAX_SAFE_INTEGER
      : source.index;
    const recordVersionId = getRecordVersionIdFromDraggableId(draggableId);

    if (hasMovedSections) {
      if (onSectionChange) {
        onSectionChange(recordVersionId, sectionId || null);
      }
    } else {
      if (onRecordMoved) {
        onRecordMoved(
          recordVersionId,
          sectionId || null,
          sourceIndex,
          destination.index,
        );
      } else {
        onRecordMovedDefault(
          recordVersionId,
          sectionId || null,
          sourceIndex,
          destination.index,
        );
      }
    }
  };

  return <DragDropContext onDragEnd={onDragEnd}>{children}</DragDropContext>;
};

export default SectionDragContext;

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { camelCase, isEmpty, snakeCase, startCase, uniq } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import Popup from "reactjs-popup";

import type { Field } from "@/api";
import { recordsByMonthByWorkspace } from "@/api/gen/axios/reportsController/recordsByMonthByWorkspace";
import { useMainStore } from "@/contexts/Store";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";
import type { FieldOption } from "@/stores/types/field-types";

import { graphColors, graphOptions } from "../../constants";
import { recordTypeForThemisModuleIdentifier } from "../../helpers/nameForThemisModuleIdentifier";
import ClearFilterField from "../common/ClearFilterField";
import ExportDashboard from "../common/MetricsPage/dashboards/components/ExportDashboard";
import SingleSelectField from "../common/SingleSelectField";
import { DateFilter } from "../dateFilter";
import { legendHeightPlugin } from "./chartHelpers";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorSchemes?: any[];
  dateColumnDefault?: string;
  identifier?: string;
  tableName?: string;
  yAxisTitle?: string;
}

const RecordsByMonthStackedBar = ({
  identifier,
  dateColumnDefault,
  tableName,
  yAxisTitle,
  colorSchemes,
}: Props) => {
  const mainStore = useMainStore();
  const recordTypePlural = yAxisTitle
    ? yAxisTitle
    : // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      recordTypeForThemisModuleIdentifier(identifier).plural;

  // Fields State
  const [dateColumnName, setDateColumnName] = useState(dateColumnDefault);
  const [fieldName, setFieldName] = useState<Field>();
  const [fieldValue, setFieldValue] = useState<FieldOption>();
  const [showDatepicker, setShowDatepicker] = useState(false);
  const { allFields } = mainStore.fields;

  const dateFields = useMemo(
    () =>
      allFields?.filter(
        // @ts-expect-error TS(2339) FIXME: Property 'data_type' does not exist on type 'never... Remove this comment to see the full error message
        (field) => field.data_type === "com.askthemis.types.v1.date",
      ),
    [allFields],
  );
  const isDisabled = fieldName?.display_name === undefined;
  const isFilterClearable = !isDisabled;
  const { selectedWorkspaceIDs } = mainStore.reports;

  useEffect(() => {
    getRecords();
  }, [
    dateColumnName,
    selectedWorkspaceIDs,
    fieldValue?.name,
    mainStore.reports.refetchRecordsCount,
  ]);

  useEffect(() => {
    if (!fieldName) {
      return;
    }
    if (
      mainStore.featureFlags.getIsEnabled(
        FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
      ) &&
      identifier === "training"
    ) {
      return;
    }

    mainStore.reports.getAllFieldOptions({
      workspace_ids: selectedWorkspaceIDs,
      identifier,
    });
  }, [fieldName, selectedWorkspaceIDs]);

  const {
    handleDisplayRange,
    validRange,
    fromValue,
    toValue,
    dayPickerTrigger,
    renderDayPicker,
  } = DateFilter();

  const newOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        title: {
          text: "",
          display: true,
          padding: 5,
        },
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
    scales: {
      y: {
        grid: {
          display: true,
          borderDash: [6],
        },
        title: {
          text: recordTypePlural,
          display: true,
        },
        ticks: {
          precision: 0,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  async function getRecords() {
    if (!dateColumnName || !identifier || isEmpty(selectedWorkspaceIDs)) {
      return;
    }
    if (
      mainStore.featureFlags.getIsEnabled(
        FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
      ) &&
      identifier === "training"
    ) {
      mainStore.reports.setRecordsByMonthIsReady(false);
      const params = {
        date_field_name: dateColumnName,
        workspace_ids: mainStore.reports.selectedWorkspaceIDs as number[],
        from_date: fromValue,
        to_date: toValue,
      };
      const response = await recordsByMonthByWorkspace(
        mainStore.context.companyID!,
        "trainings",
        params,
      );
      mainStore.reports.setRecordsByMonth(response.data);
    } else {
      mainStore.reports.getReportsByMonthByWorkspace({
        date_column_name: dateColumnName,
        identifier,
        from_value: fromValue,
        to_value: toValue,
        tableName: tableName!,
        field_name: fieldName?.name,
        field_values: fieldValue?.name,
      });
    }
  }
  const data = mainStore.reports.recordsByMonth;
  // @ts-expect-error TS(2339) FIXME: Property 'month' does not exist on type 'never'.
  const X_AXIS_LABELS = uniq(data?.map((item) => item.month));
  const dataLabels = uniq(
    data
      // @ts-expect-error TS(2339) FIXME: Property 'count' does not exist on type 'never'.
      .filter((item) => item.count !== 0)
      // @ts-expect-error TS(2339) FIXME: Property 'source' does not exist on type 'never'.
      .map((item) => item.source?.toString()),
  );

  const sortedDataLabels = dataLabels.sort();

  const insertData = () => {
    const renderDataSets = sortedDataLabels.map((label, index) => ({
      label: startCase(camelCase(label)) || "n/a",
      // @ts-expect-error TS(2339) FIXME: Property 'source' does not exist on type 'never'.
      data: data.filter((item) => item.source?.toString() === label),
      stack: "source",
      maxBarThickness: 150,
      borderRadius: 0,
      backgroundColor:
        colorSchemes?.find(
          (colorScheme) => snakeCase(colorScheme.title) === label,
        )?.text_color || graphColors[index],

      borderSkipped: false,
    }));

    return {
      options: {
        ...graphOptions,
        ...newOptions,
      },
      data: {
        datasets: renderDataSets,
        labels: X_AXIS_LABELS,
      },
    };
  };

  const renderChart = (
    <Bar
      data={insertData().data}
      plugins={[legendHeightPlugin]}
      // @ts-expect-error no proper typing was used when building the options object
      options={insertData().options}
      id="stackedBarChart"
      key="type"
    />
  );

  function handleDatepickerClose() {
    setShowDatepicker(false);
    handleDisplayRange();
    if (validRange) {
      getRecords();
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'column' implicitly has an 'any' type.
  const handleDateColumnChange = (column) => {
    setDateColumnName(column.name);
  };

  const resetFilter = () => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setFieldName();
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    setFieldValue();
  };

  return (
    <div className="report-dashboard-contents">
      <div className="report-dashboard-header-multiple-row">
        <div className="row graph-title-container">
          <div className="graph-title">
            {recordTypePlural && `${recordTypePlural} by month`}
          </div>
          <div className="report-dashboard-header-right">
            <div className="date-picker">
              <Popup
                position="bottom right"
                trigger={dayPickerTrigger}
                open={showDatepicker}
                onOpen={() => setShowDatepicker(true)}
                onClose={() => handleDatepickerClose()}
                keepTooltipInside
              >
                {renderDayPicker}
              </Popup>
              {isFilterClearable && (
                <ClearFilterField resetFilter={resetFilter} />
              )}
            </div>
            <ExportDashboard
              chartId="report-bar-by-month-stacked-bar-workspaces-chart-container"
              fileDownloadName={`${recordTypePlural}_by_month_${dateColumnName}`}
              forTables={false}
            />
          </div>
        </div>
        <div className="selects-container">
          <SingleSelectField
            label="Date Column"
            value={startCase(dateColumnName)}
            options={dateFields}
            handleChange={handleDateColumnChange}
            testId="group-select-date-field"
            tooltip="Select a date column from any of the selected workspaces. If the record does does not have a value for this column, or the workspace does not have this column, the record will not be included in the report."
          />
        </div>
      </div>
      <div
        className="report-bar-chart-container"
        id="report-bar-by-month-stacked-bar-workspaces-chart-container"
      >
        {renderChart}
      </div>
    </div>
  );
};

export default observer(RecordsByMonthStackedBar);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Diamond = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.95312 1.569C4.97871 1.55896 5.00475 1.54997 5.03118 1.54205C5.13426 1.51107 5.2388 1.49759 5.34144 1.5H18.6585C18.7609 1.4976 18.8651 1.51099 18.9679 1.54176C18.9946 1.54975 19.021 1.55884 19.0469 1.569C19.2682 1.65562 19.4422 1.8134 19.5517 2.00664L23.3585 8.63692C23.4373 8.76847 23.4867 8.91963 23.4977 9.08134C23.4987 9.09702 23.4994 9.11271 23.4998 9.12839C23.5001 9.14335 23.5001 9.15831 23.4997 9.17326C23.4942 9.41331 23.4042 9.63247 23.2582 9.80208L12.7685 22.1399C12.7418 22.172 12.7131 22.2023 12.6827 22.2307C12.6312 22.2788 12.5748 22.3213 12.5145 22.3575C12.3625 22.4487 12.1857 22.5 12 22.5C11.6971 22.5 11.4178 22.3636 11.2315 22.1399L0.741879 9.80215C0.595771 9.63245 0.505666 9.41316 0.500252 9.17297C0.499723 9.15005 0.499979 9.12711 0.501025 9.10418C0.508705 8.93386 0.55899 8.77464 0.641476 8.63692L4.4483 2.00671C4.55779 1.81344 4.73177 1.65563 4.95312 1.569ZM10.1636 3.5H6.72989L7.94033 6.94193L10.1636 3.5ZM9.54095 8.15H14.459L12 4.34303L9.54095 8.15ZM16.0597 6.94193L13.8364 3.5H17.2701L16.0597 6.94193ZM18.9012 4.89042L20.7727 8.15H17.7549L18.9012 4.89042ZM12 18.4857L14.9315 10.15H9.06852L12 18.4857ZM5.09879 4.89042L6.2451 8.15H3.22727L5.09879 4.89042ZM6.94845 10.15H3.66277L9.26626 16.7408L6.94845 10.15ZM20.3372 10.15H17.0515L14.7337 16.7408L20.3372 10.15Z"
      fill="#353549"
    />
  </svg>
);

Diamond.displayName = "DiamondIcon";

export default Diamond;

import type { SVGProps } from "react";
import React from "react";

const FlagLinear = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0.667969C2.36819 0.667969 2.66667 0.966446 2.66667 1.33464H11.3333C11.5644 1.33464 11.779 1.45427 11.9004 1.65081C12.0219 1.84736 12.0329 2.09279 11.9296 2.29944L10.7454 4.66797L11.9296 7.03649C12.0329 7.24315 12.0219 7.48858 11.9004 7.68512C11.779 7.88167 11.5644 8.0013 11.3333 8.0013H2.66667V14.0013H3.33333C3.70152 14.0013 4 14.2998 4 14.668C4 15.0362 3.70152 15.3346 3.33333 15.3346H0.666667C0.298477 15.3346 0 15.0362 0 14.668C0 14.2998 0.298477 14.0013 0.666667 14.0013H1.33333V1.33464C1.33333 0.966446 1.63181 0.667969 2 0.667969ZM2.66667 6.66797H10.2546L9.40371 4.96611C9.30987 4.77843 9.30987 4.55751 9.40371 4.36983L10.2546 2.66797H2.66667V6.66797Z"
      fill="#353549"
    />
  </svg>
);

FlagLinear.displayName = "FlagLinearIcon";

export default FlagLinear;

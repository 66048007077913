export const selectFields = {
  fieldName: {
    value: [],
    error: null,
  },
  fieldValue: {
    value: [],
    error: null,
  },
  groupedBy: {
    value: [],
    error: null,
  },
};

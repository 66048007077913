import { HeaderTabs } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@/components/Elements";
import { getSelectedTab } from "@/components/helpers/Tabs";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";
import { VendorContracts } from "@/features/vendor-due-diligence-contracts";
import type { Vendor } from "@/stores/types/vendor-types";

import { useMainStore } from "../../../contexts/Store";
import responseIcon from "../../../images/table-image/icon/response.svg";
import { LOADING_DELAY } from "../../constants";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import Loading from "../../Loading";
import RecordNotFound from "../../shared/RecordNotFound";
import Table from "../Table";
import BuildVendor from "./BuildVendor";
import VendorDocumentsWrapper from "./Documents/VendorDocumentsWrapper";
import RiskAssessments from "./RiskAssessments";
import VendorQuestionnaires from "./VendorQuestionnaires";

const SAVED_MSG_TIMEOUT = 2000;

interface Props {
  defaultScreen?: string;
}

function ViewVendor({ defaultScreen }: Props) {
  const mainStore = useMainStore();

  const [initialLoad, setInitialLoad] = useState(true);
  const [activeScreen, setActiveScreen] = useState(defaultScreen);
  const [loading, setLoading] = useState(true);
  const [showAutosave, setShowAutosave] = useState(false);

  const { vendor_id } = useParams<{ vendor_id: string }>();

  const { moduleWorkspaceID, workspaceID } = mainStore.context;
  const { currentVendor, data } = mainStore.vendors || {};
  const { active_workspace: workspace } = mainStore.users.user;
  const { canManageAccounts } = mainStore.userPermissions;
  // @ts-expect-error TS(2339) FIXME: Property 'risk_assessment_templates' does not exis... Remove this comment to see the full error message
  const riskAssessmentTemplates = data?.risk_assessment_templates || [];
  const questionnaires = currentVendor?.record?.vendor_checklists || [];
  const riskAssessments = currentVendor?.record?.risk_assessments || [];
  const hasData = activeScreen === "Documents" || currentVendor?.id;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const recordName = getRecordName(
    "vendor_due_diligence",
    moduleWorkspaces,
    true,
  );
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === (currentVendor as Vendor).id,
  );

  const TABS = [
    {
      name: "Vendor Info",
      key: "",
      value: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}`,
      dataTestId: "vdd-view-vendor-tab-vendor-info",
    },
    {
      name: "Contracts",
      key: "contracts",
      value: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/contracts`,
      dataTestId: "vdd-view-vendor-tab-contracts",
    },
    {
      name: "Questionnaires",
      key: "questionnaires",
      value: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/questionnaires`,
      dataTestId: "vdd-view-vendor-tab-questionnaires",
    },
    {
      name: "Risk Assessment",
      key: "risk_assessment",
      value: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/risk_assessment`,
      hasPermission: canManageAccounts,
      dataTestId: "vdd-view-vendor-tab-risk-assessment",
    },
    {
      name: "Documents",
      key: "documents",
      value: `/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/documents`,
      dataTestId: "vdd-view-vendor-tab-documents",
    },
  ];

  useEffect(() => {
    mainStore.vendors.setCurrentVendor({});
    if (vendor_id) {
      mainStore.vendors.getVendorById(vendor_id);
    }
  }, [vendor_id]);

  useEffect(() => {
    if (!workspace?.id) {
      return;
    }

    if (!mainStore.users.users?.length) {
      mainStore.users.indexForModules({ workspaceID: workspace.id });
    }

    // @ts-expect-error TS(2339) FIXME: Property 'module_workspace_id' does not exist on t... Remove this comment to see the full error message
    if (!mainStore.vendors.data?.module_workspace_id) {
      mainStore.vendors.index({ workspaceID: workspace.id });
    }
  }, [workspace?.id]);

  useEffect(() => {
    setActiveScreen(defaultScreen);
  }, [defaultScreen]);

  useEffect(() => {
    setInitialLoad(false);
  }, []);

  useEffect(() => {
    if (initialLoad) {
      return;
    }

    setTimeout(() => {
      setLoading(Boolean(!moduleWorkspaceID));
    }, LOADING_DELAY);
  }, [initialLoad, moduleWorkspaceID]);

  function handleGeneratePDFReport() {
    mainStore.vendors.generateAndOpenPDFReport(vendor_id);
  }

  function autoSave() {
    setShowAutosave(true);
    setTimeout(() => setShowAutosave(false), SAVED_MSG_TIMEOUT);
  }

  return (
    <Table>
      {loading && activeScreen === "ActiveVendors" && (
        <Loading loadingLayout="small-table" showTableHeader />
      )}
      {loading &&
        (activeScreen === "Questionnaires" ||
          activeScreen === "RiskAssessment" ||
          activeScreen === "Documents" ||
          activeScreen === "Contracts") && (
          <Loading loadingLayout="table" showTableHeader />
        )}
      {!loading && !hasData && <RecordNotFound />}
      {!loading && hasData && (
        <>
          <div
            className="table-header-wrap"
            data-testid="vdd-view-vendor-header"
          >
            <HeaderTabs
              tabs={TABS}
              selectedTab={getSelectedTab(TABS)?.value || ""}
              isLink
              dataTestId="vdd-view-vendor-tabs"
            />
            <div className="add-new-block">
              <div
                className={classNames("saved-success-msg", {
                  active: showAutosave,
                })}
                data-testid="vdd-saved-success-msg"
              >
                <img src={responseIcon} alt="response-icon" />
                <span>Vendor Auto Saved</span>
              </div>
              <Button
                type="button"
                size="sm"
                onClick={handleGeneratePDFReport}
                label={`Export ${recordName} to PDF`}
              >
                Export {recordName} to PDF
              </Button>
              {activeScreen === "ActiveVendors" && recordVersion && (
                <CommentsSlideMenu
                  renderCommentsButton
                  recordID={recordVersion.record_id}
                  globalID={recordVersion.global_id}
                  uncompletedCommentsCount={
                    recordVersion.uncompleted_comments_count
                  }
                  taskableType="Record"
                />
              )}
            </div>
          </div>
          <div
            className="view-vendor-content"
            data-testid="vdd-view-vendor-content"
          >
            {activeScreen === "ActiveVendors" && (
              <BuildVendor vendor={currentVendor} autoSave={autoSave} />
            )}
            {activeScreen === "Contracts" && canManageAccounts && (
              <VendorContracts accountId={currentVendor.record?.account_id} />
            )}
            {activeScreen === "Questionnaires" && (
              <VendorQuestionnaires questionnaires={questionnaires} />
            )}
            {activeScreen === "RiskAssessment" && (
              <RiskAssessments
                vendorID={vendor_id}
                templates={riskAssessmentTemplates}
                riskAssessments={riskAssessments}
              />
            )}
            {activeScreen === "Documents" && recordVersion?.id && (
              <VendorDocumentsWrapper recordVersionID={recordVersion.id} />
            )}
          </div>
        </>
      )}
    </Table>
  );
}

ViewVendor.defaultProps = {
  defaultScreen: "ActiveVendors",
};

export default observer(ViewVendor);

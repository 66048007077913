import { observer } from "mobx-react";
import React from "react";
import { NavLink } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

function CurrentUserSettingsNavigation() {
  const mainStore = useMainStore();

  const { isCurrentWorkspaceActive } = mainStore.workspaces;

  const publicApiEnabled =
    isCurrentWorkspaceActive &&
    mainStore.featureFlags.getIsEnabled(FEATURE_FLAG_ID.COM_PUBLIC_API);

  return (
    <div className="settings-links-wrap">
      <ul>
        <li data-testid="current-user-settings-li">
          <NavLink to="/user/profile">Profile Details</NavLink>
        </li>
        {isCurrentWorkspaceActive && (
          <li data-testid="current-user-settings-li">
            <NavLink to="/user/integrations">Integrations</NavLink>
          </li>
        )}
        {publicApiEnabled && (
          <li data-testid="current-user-settings-li">
            <NavLink to="/user/api-keys">API Keys</NavLink>
          </li>
        )}
      </ul>
    </div>
  );
}

export default observer(CurrentUserSettingsNavigation);

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { useUser } from "@/api/queries/users";

import CompanyOutlook from "./CompanyOutlook";
import CompanyRetro from "./CompanyRetro";
import { MyWork } from "./MyWork";

const HOME_ROUTES = {
  base: "/home",
  myWork: "/my-work",
  companyRetro: "/company-retro",
} as const;

export const HOME_PATH =
  `/workspaces/:workspace_id${HOME_ROUTES.base}` as const;

export const MY_WORK_PATH = `${HOME_PATH}${HOME_ROUTES.myWork}` as const;

export const COMPANY_RETRO_PATH =
  `${HOME_PATH}${HOME_ROUTES.companyRetro}` as const;

export function HomeRoutes() {
  const { path } = useRouteMatch();

  const { isPending, isError } = useUser("current");

  if (isPending || isError) {
    return null;
  }

  return (
    <Switch>
      <Route path={`${path}${HOME_ROUTES.myWork}`}>
        <MyWork />
      </Route>
      <Route path={`${path}${HOME_ROUTES.companyRetro}`}>
        <CompanyRetro />
      </Route>
      <Route exact path={path}>
        <CompanyOutlook />
      </Route>
    </Switch>
  );
}

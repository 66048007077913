import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import type { Workspace } from "@/stores/types/workspace-types";

import { Icon, Typography } from "../../Elements";
import { getWorkspaceLogo } from "../../helpers/getWorkspaceLogo";

const WorkspaceName = ({
  workspace,
}: {
  workspace: Workspace | "All Workspace";
}) => {
  if (workspace === "All Workspace") {
    return (
      <Typography
        className="single-selection-trigger"
        data-testid="selected-item"
        size="sm"
        color="generalMidnightDark"
        label={workspace}
      />
    );
  }

  const { logo } = getWorkspaceLogo(workspace);

  return (
    <div className="single-workspace-name">
      <img src={logo} alt="workspace-icon" />
      <li className="single-selection-trigger" key={workspace.name}>
        {workspace.name}
      </li>
    </div>
  );
};

const SingleWorkspaceSelection = ({
  allowAllWorkspacesOption = true,
}: {
  allowAllWorkspacesOption?: boolean;
}) => {
  const mainStore = useMainStore();

  const [open, setOpen] = useState(false);

  const workspaces = mainStore.workspaces.list;
  const { displayedWorkspace } = mainStore.reports;

  useEffect(() => {
    if (!allowAllWorkspacesOption && mainStore.context.activeWorkspace) {
      mainStore.reports.setDisplayedWorkspace(
        mainStore.context.activeWorkspace,
      );
    }
  }, [allowAllWorkspacesOption, mainStore.context.activeWorkspace]);

  const handleClose = useCallback(() => {
    setOpen(false);

    if (mainStore.reports.displayedWorkspace === "All Workspace") {
      mainStore.reports.setSelectAllWorkspaces(true);
      const allWorkspaceIds = workspaces.map((workspace) => workspace.id);
      mainStore.reports.setSelectedWorkspaceIDs(allWorkspaceIds);
    } else {
      const selectedWorkspace = workspaces.find(
        (workspace) =>
          workspace.id ===
          (mainStore.reports.displayedWorkspace as Workspace)?.id,
      );

      if (selectedWorkspace) {
        mainStore.reports.setSelectedWorkspaceIDs([selectedWorkspace.id]);
        mainStore.reports.setSelectAllWorkspaces(false);
      }
    }

    mainStore.reports.updateRefetchRecordsCount();
  }, [mainStore.reports.displayedWorkspace]);

  const handleSelection = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'workspace' implicitly has an 'any' type... Remove this comment to see the full error message
    (workspace) => {
      mainStore.reports.setDisplayedWorkspace(workspace);
      handleClose();
    },
    [mainStore.reports.setDisplayedWorkspace, handleClose],
  );

  const allWorkspaceSelected = displayedWorkspace === "All Workspace";

  return (
    <Popup
      trigger={
        <button
          className={classNames("reports-module-dropdown", { opened: open })}
          data-testid="single-workspace-selection-trigger"
        >
          <div className="module-item">
            <WorkspaceName workspace={displayedWorkspace} />
          </div>
          {/* <img src={ arrowDownIcon }  /> */}

          <Icon name="arrowDownBlack" />
        </button>
      }
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
    >
      <div
        className="table-dropdown single-workspace-dropdown-popup"
        data-testid="workspace-dropdown"
      >
        <ul>
          {allowAllWorkspacesOption && (
            <div
              className={classNames("single-workspace-name-container", {
                selected: allWorkspaceSelected,
              })}
              onClick={() => handleSelection("All Workspace")}
            >
              <div className="single-workspace-name">
                <li>All Workspace</li>
              </div>
              {allWorkspaceSelected && (
                <Icon name="checkBlue" className="check" />
              )}
            </div>
          )}
          {workspaces.map((workspace) => {
            const { logo } = getWorkspaceLogo(workspace);
            const selected =
              workspace.id === (displayedWorkspace as Workspace).id;
            return (
              <div
                key={workspace.name}
                className={classNames("single-workspace-name-container", {
                  selected,
                })}
                onClick={() => handleSelection(workspace)}
              >
                <div className="single-workspace-name">
                  <img src={logo} alt="workspace-icon" />
                  <li key={workspace.name}>{workspace.name}</li>
                </div>
                {selected && <Icon name="checkBlue" className="check" />}
              </div>
            );
          })}
        </ul>
      </div>
    </Popup>
  );
};

export default observer(SingleWorkspaceSelection);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { cx } from "cva";
import { debounce } from "lodash";
import React, { useRef } from "react";
import { PiArrowsOutSimpleBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import { Button } from "../../Button/Button";
import { LinkButton } from "../../Button/LinkButton";
import { TextInput } from "../../Form/TextInput";
import { Popover, PopoverAnchor, PopoverContent } from "../../Popover/Popover";
function IdentifierCellRenderer(_a) {
    var _b;
    var value = _a.value, to = _a.to, onClick = _a.onClick, _c = _a.editable, editable = _c === void 0 ? false : _c, props = __rest(_a, ["value", "to", "onClick", "editable"]);
    var isEditable = editable || ((_b = props.colDef) === null || _b === void 0 ? void 0 : _b.editable);
    var textRef = useRef(null);
    var _d = React.useState(false), isHovering = _d[0], setIsHovering = _d[1];
    var history = useHistory();
    var isTextOverflowing = function () {
        var textElement = textRef.current;
        return textElement
            ? textElement.scrollWidth > textElement.clientWidth
            : false;
    };
    var debouncedHandleMouseEnter = debounce(function () {
        setIsHovering(true);
    }, 500);
    var handleOnMouseLeave = function () {
        debouncedHandleMouseEnter.cancel();
        setIsHovering(false);
    };
    return (React.createElement("div", { ref: textRef, onMouseEnter: debouncedHandleMouseEnter, onMouseLeave: handleOnMouseLeave, id: "identifier-cell", className: "tw-group/parent tw-flex tw-h-full tw-w-full tw-items-center tw-font-sans tw-font-medium tw-text-neutral-500", onFocus: function () {
            var _a;
            props.node.setData(__assign(__assign({}, props.node.data), { isEditing: true }));
            props.api.startEditingCell({
                rowIndex: props.node.rowIndex || 0,
                colKey: ((_a = props.column) === null || _a === void 0 ? void 0 : _a.getColId()) || "",
            });
        } },
        React.createElement("div", { className: "tw-group/cell tw-hidden tw-h-full tw-w-full tw-items-center tw-justify-between tw-px-2 group-hover/parent:tw-flex" },
            isEditable ? (React.createElement(TextInput, { id: "identifier-cell-text-input", className: "tw-does-nothing tw-mr-1.5 tw-h-5 tw-rounded-[4px] tw-border-0 tw-px-0.5 focus-visible:!tw-border focus-visible:!tw-border-neutral-100 focus-visible:tw-ring-0 group-hover/cell:!tw-bg-white", placeholder: "Add Text", value: value, onClick: function () {
                    var _a;
                    props.node.setData(__assign(__assign({}, props.node.data), { isEditing: true }));
                    props.api.startEditingCell({
                        rowIndex: props.node.rowIndex || 0,
                        colKey: ((_a = props.column) === null || _a === void 0 ? void 0 : _a.getColId()) || "",
                    });
                }, onChange: function () { } })) : (React.createElement(React.Fragment, null,
                React.createElement("span", { className: "tw-overflow-hidden tw-text-ellipsis tw-text-neutral-300" }, value),
                React.createElement(Popover, { open: isHovering && isTextOverflowing() },
                    React.createElement(PopoverAnchor, { virtualRef: textRef }),
                    React.createElement(PopoverContent, { align: "center", side: "bottom", onEscapeKeyDown: function () { return setIsHovering(false); } },
                        React.createElement("div", { className: "tw-max-w-[250px] tw-p-2 tw-text-sm tw-text-neutral-500" }, value))))),
            to ? (React.createElement(LinkButton, { id: "identifier-cell-link-button", className: "tw-mx-1 tw-hidden tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium group-hover/cell:tw-flex", color: "transparent", RightIcon: PiArrowsOutSimpleBold, to: to, onKeyDown: function (e) {
                    if (e.key === " " || e.key === "Enter") {
                        e.preventDefault();
                        history.push(to);
                    }
                } }, "Open")) : (React.createElement(Button, { id: "identifier-cell-button", className: "tw-mx-1 tw-hidden tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium group-hover/cell:tw-flex", color: "transparent", RightIcon: PiArrowsOutSimpleBold, onMouseDown: onClick ? onClick : function (e) { return e.stopPropagation(); }, onKeyDown: function (e) { return (e.key === "Enter" && onClick ? onClick() : null); } }, "Open"))),
        React.createElement("span", { className: cx("tw-visible tw-overflow-hidden tw-text-ellipsis !tw-px-2 group-hover/parent:tw-hidden ", !isEditable && "tw-text-neutral-300"), "data-testid": "identifier-cell-text" }, value)));
}
export default IdentifierCellRenderer;

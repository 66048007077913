import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteTrainingMutationResponse,
  DeleteTrainingPathParams,
} from "../../models/DeleteTraining";

/**
 * @description Delete training
 * @link /trainings/:id
 */
export async function deleteTraining(
  id: DeleteTrainingPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteTrainingMutationResponse>["data"]> {
  const res = await client<DeleteTrainingMutationResponse>({
    method: "delete",
    url: `/trainings/${id}`,
    ...options,
  });
  return res.data;
}

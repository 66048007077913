import classNames from "classnames";
import { observer } from "mobx-react";
import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";
import Popup from "reactjs-popup";

import {
  formatDate,
  formatDateForCellValue,
  stringToDate,
} from "@/components/helpers/DateFormatters";
import { useMainStore } from "@/contexts/Store";
import arrowLeft from "@/images/table-image/icon/arrow-left.svg";
import arrowRight from "@/images/table-image/icon/arrow-right.svg";

type Props<T extends FieldValues> = UseControllerProps<T> & {
  fieldName?: string;
  hasErrors?: boolean;
  width?: number | string;
  hasErrorClass?: string;
  locked?: boolean;
};

function DateField<T extends FieldValues>({
  hasErrorClass = "has-errors",
  hasErrors = false,
  locked,
  width,
  ...controller
}: Props<T>) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const { field, fieldState } = useController(controller);

  // Variables
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [activeStartDate, setActiveStartDate] = useState<Date | undefined>(
    undefined,
  );
  const [dateString, setDateString] = useState<string>(formatDate(date) || "");

  // Effects
  useEffect(() => {
    if (field.value) {
      setDate(new Date(field.value));
    }
  }, [field.value]);

  // Functions
  const onCalendarChange = (newDate: Date) => {
    setDate(newDate);
    setDateString(formatDate(newDate) || "");
    setShowPopup(false);
    field.onChange(formatDateForCellValue(newDate));
  };

  const onActiveStartDateChange = (value: { activeStartDate: Date }) => {
    setActiveStartDate(value.activeStartDate);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newDateString = event.target.value;

    // Set dateString without any conditions, since it defines the value in input
    setDateString(newDateString);

    // Try to parse a date
    if (newDateString !== null && newDateString !== "") {
      const dateParts = newDateString.split("/");
      const month = Number(dateParts[0]);
      const day = Number(dateParts[1]);
      const year = Number(dateParts[2]);

      if (year >= 2000 && month >= 1 && month <= 12 && day >= 1 && day <= 31) {
        const newDate = new Date(year, month - 1, day);

        if (!isNaN(newDate.getTime())) {
          setDate(newDate);
          setActiveStartDate(
            new Date(newDate.getFullYear(), newDate.getMonth(), 1),
          );
        }
      }
    }
  };

  const clearDate = (event: React.MouseEvent) => {
    field.onChange("");
    setDate(new Date());
    setShowPopup(false);
    event?.stopPropagation();
  };

  const setToday = () => {
    const today = new Date();
    setActiveStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
    onCalendarChange(today);
  };

  const triggerClasses = classNames("set-date-button", {
    active: showPopup,
    [hasErrorClass]: hasErrors || !!fieldState.error,
    illumination: showPopup,
    "pointer-events-none": locked || isReadOnly,
    "table-cell--disabled": isReadOnly,
    "locked-cell": locked && isCurrentWorkspaceActive,
  });

  const datePlaceholderClasses = classNames("date-placeholder", {
    "placeholder-locked": locked,
  });

  const modalTrigger = (
    <li className={triggerClasses} style={{ width }}>
      <div className="cell-content">
        {field.value ? (
          formatDate(stringToDate(field.value))
        ) : (
          <span className={datePlaceholderClasses}>
            {locked ? "N/A" : "- Select -"}
          </span>
        )}
      </div>
    </li>
  );

  return (
    <Popup
      trigger={modalTrigger}
      position="bottom left"
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      keepTooltipInside
    >
      <div
        className="select-small-wrap calendar-wrap"
        data-testid="date-cell-popup"
      >
        <input
          className="input-date"
          data-testid="date-cell-input"
          value={dateString}
          onChange={handleInputChange}
        />
        <Calendar
          activeStartDate={activeStartDate}
          onActiveStartDateChange={onActiveStartDateChange}
          formatShortWeekday={(_, weekday) =>
            ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][weekday.getDay()]
          }
          value={date}
          onChange={onCalendarChange}
          prevLabel={<img src={arrowLeft} alt="image" />}
          nextLabel={<img src={arrowRight} alt="image" />}
          minDate={new Date()}
        />
        <div className="manage-date">
          <button
            className="table-button set-today"
            data-testid="date-cell-set-today"
            onClick={setToday}
          >
            Set Today
          </button>
          <button className="table-button clear-button" onClick={clearDate}>
            Clear
          </button>
        </div>
      </div>
    </Popup>
  );
}

export default observer(DateField);

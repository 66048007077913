import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListPermissionSetsPathParams,
  ListPermissionSetsQueryResponse,
} from "../../models/ListPermissionSets";

/**
 * @description List permission sets
 * @summary List permission sets
 * @link /companies/:company_id/permission_sets
 */
export async function listPermissionSets(
  companyId: ListPermissionSetsPathParams["company_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListPermissionSetsQueryResponse>["data"]> {
  const res = await client<ListPermissionSetsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/permission_sets`,
    ...options,
  });
  return res.data;
}

import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";
import { patternToRoute } from "@/utils/routing";

import {
  kriCompanyLibraryUrl,
  kriInputUrl,
  kriListUrl,
  kriSummaryUrl,
  kriThemisLibraryUrl,
} from "../urlPaths";

const KeyRiskIndicatorsTabs = () => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const TABS = [
    {
      name: "KRIs",
      key: "list",
      value: patternToRoute(kriListUrl, { workspace_id: workspaceID }),
    },
    {
      name: "Input",
      key: "input",
      value: patternToRoute(kriInputUrl, { workspace_id: workspaceID }),
      tooltip:
        "Your hub for editing and viewing input data for all Key Risk Indicators.",
    },
    {
      name: "Summary",
      key: "summary",
      value: patternToRoute(kriSummaryUrl, { workspace_id: workspaceID }),
      tooltip:
        "Visually represents how each KRI fares against your predefined thresholds.",
    },
    {
      name: "Company KRI Library",
      key: "templates",
      value: patternToRoute(kriCompanyLibraryUrl, {
        workspace_id: workspaceID,
      }),
      tooltip:
        "KRI templates applicable across all workspaces. Create new templates, or customize Themis provided templates to tailor them to your specific business requirements.",
    },
    {
      name: "Themis KRI Library",
      key: "themis-kri-library",
      value: patternToRoute(kriThemisLibraryUrl, { workspace_id: workspaceID }),
      tooltip:
        "Industry-standard KRIs curated by compliance experts at Themis. Use these as a benchmark to enhance your risk management strategy and copy them to your Company Library for further customization.",
    },
  ];

  return (
    <HeaderTabs
      tabs={TABS}
      selectedTab={getSelectedTab(TABS, true)?.value || ""}
      isLink
    />
  );
};

export default observer(KeyRiskIndicatorsTabs);

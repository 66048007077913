import {
  Breadcrumbs,
  Button,
  ConfirmationPopup,
  HeaderTabs,
  useToast,
} from "@themis/ui";
import React, { useEffect, useState } from "react";
import {
  generatePath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import type { ShowAccountQueryParams } from "@/api";
import { useAccount, useUpdateAccount } from "@/api/queries/accounts";
import { useColumns } from "@/api/queries/columns";
import { useCompany } from "@/api/queries/companies";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";

import { ACCOUNTS_LIST_PATH } from "..";
import { AccountContacts } from "./AccountContacts";
import { AccountContracts } from "./AccountContracts";
import { AccountInfo } from "./AccountInfo";
import AccountQuestionnaires from "./AccountQuestionnaires/AccountQuestionnaires";
import { ChildAccounts } from "./ChildAccounts";
import DueDiligence from "./due-diligence/due-diligence";

export const ACCOUNT_DETAILS_ROUTES = {
  contacts: "/contacts",
  contracts: "/contracts",
  childAccounts: "/sub-accounts",
  dueDiligence: "/due-diligence",
  questionnaires: "/questionnaires",
} as const;

export type AccountLocationState = { from?: string } | undefined;

export function AccountDetails() {
  const toast = useToast();
  const history = useHistory();
  const { url, path } = useRouteMatch();

  const pageContentRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    pageContentRef.current?.scrollTo({ top: 0 });
  }, [url]);

  const { pathname, search } = useLocation();

  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();

  const searchParams = new URLSearchParams(search);
  const redirectTo = searchParams.get("redirectTo");

  const isNewAccount = !Number.isInteger(Number(accountId));

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const queryParams: ShowAccountQueryParams = {
    expand: "contacts,contracts,child_accounts,documents",
  };

  const {
    data: accountData,
    isPending: isAccountPending,
    isError: isAccountError,
  } = useAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
    queryParams,
  });

  const {
    data: company,
    isPending: isCompanyPending,
    isError: isCompanyError,
  } = useCompany("current");

  const {
    data: columns,
    isPending: isColumnsPending,
    isError: isColumnsError,
  } = useColumns({
    companyId: Number(company?.data.company.id),
    recordClass: "accounts",
  });

  const accountTypeFieldOptions =
    columns?.data.find(
      ({ name: fieldConfigName }) => fieldConfigName === "account_types",
    )?.options || [];

  const account = accountData?.data;

  const { mutateAsync: updateAccount } = useUpdateAccount({
    accountId: Number(accountId),
    workspaceId: Number(workspace_id),
  });

  async function handleUpdateStatus() {
    setIsConfirmationOpen(false);

    try {
      const response = await updateAccount({
        name: account?.name || "",
        status: account?.status === "active" ? "inactive" : "active",
        website: account?.website || "",
      });

      toast({
        content: `Account "${account?.name}" has been ${
          response.data?.status === "active" ? "activated" : "deactivated"
        }!`,
        variant: "success",
      });

      if (redirectTo) {
        history.push(redirectTo);
      }
    } catch {
      toast({
        content: "Something went wrong. Could not update account status.",
        variant: "error",
      });
    }
  }

  function handleClickActivate() {
    setIsConfirmationOpen(true);
  }

  function handleCancelStatusUpdate() {
    setIsConfirmationOpen(false);
  }

  if (
    (isAccountPending || isCompanyPending || isColumnsPending) &&
    !isNewAccount
  ) {
    return (
      <PageContent>
        <Loading loadingLayout="small-table" />
      </PageContent>
    );
  }

  if (isAccountError || isCompanyError || isColumnsError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath(ACCOUNTS_LIST_PATH, {
              workspace_id,
            }),
          }}
        >
          Could not load account details
        </ErrorContainer>
      </PageContent>
    );
  }

  const TABS = [
    {
      name: "Account Info",
      key: "",
      value: generatePath(`${url}`, {
        accountId,
      }),
    },
    {
      name: "Contracts",
      key: "contracts",
      value: generatePath(`${url}${ACCOUNT_DETAILS_ROUTES.contracts}`),
    },
    {
      name: "Sub Accounts",
      key: "sub-accounts",
      value: generatePath(`${url}${ACCOUNT_DETAILS_ROUTES.childAccounts}`),
    },
    {
      name: "Contacts",
      key: "contacts",
      value: generatePath(`${url}${ACCOUNT_DETAILS_ROUTES.contacts}`, {
        accountId,
      }),
    },
    {
      name: "Questionnaires",
      key: "questionnaires",
      value: generatePath(`${url}${ACCOUNT_DETAILS_ROUTES.questionnaires}`, {
        accountId,
      }),
    },
    {
      name: "Due Diligence",
      key: "due-diligence",
      value: generatePath(`${url}${ACCOUNT_DETAILS_ROUTES.dueDiligence}`, {
        accountId,
      }),
    },
  ];

  return (
    <PageLayout>
      <Header
        HeaderOptions={
          !isNewAccount &&
          pathname === url && (
            <ConfirmationPopup
              anchor
              align="end"
              title={
                account?.status === "active"
                  ? "Deactivate Account"
                  : "Activate Account"
              }
              content="Are you sure you want to proceed?"
              open={isConfirmationOpen}
              onCancel={handleCancelStatusUpdate}
              onConfirm={handleUpdateStatus}
            >
              <Button color="tertiary" onClick={handleClickActivate}>
                {account?.status === "active" ? "Deactivate" : "Activate"}
              </Button>
            </ConfirmationPopup>
          )
        }
        title={
          <Breadcrumbs
            breadcrumbItems={[
              {
                label: "Accounts",
                to: generatePath(ACCOUNTS_LIST_PATH, {
                  workspace_id,
                }),
              },
              {
                label: isNewAccount ? "Untitled" : account?.name || "",
              },
            ]}
          />
        }
      />
      {!isNewAccount && (
        <div className="tw-flex tw-h-12 tw-w-full tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5 tw-py-0">
          <HeaderTabs tabs={TABS} selectedTab={pathname} isLink />
        </div>
      )}

      <PageContent ref={pageContentRef}>
        {isNewAccount || !account ? (
          <AccountInfo />
        ) : (
          <Switch>
            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.contracts}`}>
              <AccountContracts contracts={account.contracts!} />
            </Route>

            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.childAccounts}`}>
              <ChildAccounts
                childAccounts={account.child_accounts!}
                accountTypeFieldOptions={accountTypeFieldOptions}
              />
            </Route>

            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.dueDiligence}`}>
              <DueDiligence />
            </Route>

            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.contacts}`}>
              <AccountContacts contacts={account.contacts!} />
            </Route>

            <Route path={`${path}${ACCOUNT_DETAILS_ROUTES.questionnaires}`}>
              <AccountQuestionnaires
                accountId={account.id}
                workspaceId={workspace_id}
              />
            </Route>

            <Route path="">
              <AccountInfo account={account} />
            </Route>
          </Switch>
        )}
      </PageContent>
    </PageLayout>
  );
}

import type { ZendeskConfigFormValues } from "./types";

export const initialZendeskConfigValues: ZendeskConfigFormValues = {
  selected_tags: [],
  mapped_fields: [],
  selected_statuses: [],
};

export const mappingRules = {
  subject: ["com.askthemis.types.v1.text", "com.askthemis.types.v1.long_text"],
  description: ["com.askthemis.types.v1.long_text"],
  status: ["com.askthemis.types.v1.option"],
  priority: ["com.askthemis.types.v1.option"],
  integer: [
    "com.askthemis.types.v1.integer",
    "com.askthemis.types.v1.text",
    "com.askthemis.types.v1.long_text",
  ],
  text: ["com.askthemis.types.v1.text", "com.askthemis.types.v1.long_text"],
  checkbox: ["com.askthemis.types.v1.checkbox"],
  date: ["com.askthemis.types.v1.date"],
  tagger: ["com.askthemis.types.v1.option"],
  multiselect: ["com.askthemis.types.v1.option"],
  decimal: ["com.askthemis.types.v1.text", "com.askthemis.types.v1.long_text"],
  textarea: ["com.askthemis.types.v1.text", "com.askthemis.types.v1.long_text"],
};

import React from "react";

import starIcon from "../../../images/table-image/icon/star-icon.svg";

interface Props {
  subject: string;
}

export default function CreateFirstCTA({ subject }: Props) {
  function h3Title() {
    const sentences = {
      Creative: "Add your first Advertisement",
      Issue: "Add your first Issue",
      "Change Management": "Add your first Change",
      Training: "Add your first Training",
      Complaint: "Add your first Complaint",
      Audit: "Add your first Audit",
      Document: "Add your first Document",
      Vendor: "Add your first Vendor",
      "QA Test": "Add your first Test",
      Notifications: "There are no notifications set for this module",
      Zendesk: "Review your first Zendesk Complaint",
      "Key Risk Indicators": "Add your first Key Risk Indicator",
      ModuleAssessment: "Add your first Control Assessment",
    };

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!sentences[subject]) {
      return null;
    }

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return <h3>{sentences[subject]}</h3>;
  }

  function mainText() {
    const sentences = {
      Creative: [
        "Click “Add New Marketing Creative +” field to add a new blank row in the table above.",
      ],
      Issue: [
        "Click “Add New Issue +” field below to add a new blank row in the table above.",
      ],
      Training: [
        "Click “Add new Training +” field below to add a new blank row in the table above.",
      ],
      Complaint: [
        "Click “Add new Complaint +” field below to add a new blank row in the table above.",
      ],
      Document: [
        "Click “Add New Document +” field above to add a new blank role in the table.",
        "Drag & Drop any document from your device to here.",
        "We support various file types like .pdf / .doc / .docx / .xls / .png / .jpg",
      ],
      Audit: [
        "Click “Add new Audit +” field below to add a new blank row in the table above.",
      ],
      Vendor: [
        "Click “Add New Vendor +” field above to add a fill in vendor details in a new page.",
        "Click “Add New Section” to create different vendor groupings (eg. Marketing / Finance / Service etc.)",
      ],
      "QA Test": [
        "Click “Add New Test +” field to add a new blank role in the table above.",
        "Click “Add New Section +” field to add a new Section.",
        "Tap on cells to input / link content.",
      ],
      Notifications: [
        "Click “Add New Notification +” field below to add a new blank role in the table above.",
      ],
      Zendesk: [
        "When properly synced with Zendesk, your zendesk tickets will be shown above. All you need to do is click on Sync to bring in Complaints data from Zendesk.",
      ],
      "Key Risk Indicators": [
        "Click “Add New Key Risk Indicator +” field to add a new blank role in the table above.",
        "Tap on cells to input / link content.",
        "If you need to create new table column, click “+” on right hand side of the table titles and select the dynamic cells you need.",
      ],
      ModuleAssessment: [
        "Click “+ Add Control Assessment” field to get started.",
      ],
    };

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (!sentences[subject]) {
      return null;
    }

    return (
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      sentences[subject].map((item, index) => (
        <p key={index}>
          <img src={starIcon} alt="star-icon" />
          <span>{item}</span>
        </p>
      ))
    );
  }

  return (
    <div className="no-column-wrap" data-testid="create-first-cta">
      <div className="no-column-block">
        {subject === "Procedure" ? (
          <div className="no-column-procedure">
            <div className="no-column-title">
              <h3>Add your first {subject}</h3>
            </div>
            <p>
              <img src={starIcon} alt="star-icon" />
              <span>
                1. Click “Add New Section +” field below to add a new blank
                section in the table above.
              </span>
            </p>
            <p>
              <img src={starIcon} alt="star-icon" />
              <span>
                2. Click “Add New {subject} +” within a section to create a new
                blank row to start.
              </span>
            </p>
            <p className="no-column-text">
              ( we created 2 templates above for your reference )
            </p>
          </div>
        ) : (
          <div className="no-column-procedure">
            <div className="no-column-title">{h3Title()}</div>
            {mainText()}
            {![
              "Document",
              "Vendor",
              "QA Test",
              "Notifications",
              "Zendesk",
              "Key Risk Indicators",
              "ModuleAssessment",
            ].includes(subject) && (
              <>
                <p>
                  <img src={starIcon} alt="star-icon" />
                  <span>Tap on cells to input / link content.</span>
                </p>
                <p>
                  <img src={starIcon} alt="star-icon" />
                  <span>
                    If you need to create new table column, click “+” on right
                    hand side of the table titles and select the dynamic cells
                    you need.
                  </span>
                </p>
                <p className="no-column-text">
                  ( we created 2 templates above for your reference )
                </p>
              </>
            )}

            {subject === "QA Test" && (
              <p className="no-column-text">
                ( we created 2 templates above for your reference )
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

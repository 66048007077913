import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import FileInput from "../FileInput";

interface Props {
  isNew?: boolean;
  moduleIdentifier: ModuleIdentifier;
}

function BuildDocument({ moduleIdentifier, isNew = false }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [document, setDocument] = useState({ name: "", file: {} });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // vars
  const history = useHistory();
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id, document_id } = useParams();
  const recordVersionID = Number(record_version_id);
  const isAudits = moduleIdentifier === "audits";
  const isQA = moduleIdentifier === "qa_tests_development";

  // effects
  useEffect(() => {
    if (isNew) {
      return;
    }
    if (!document_id) {
      return;
    }

    const documentID = Number(document_id);
    const recordVersion = mainStore.recordVersions.list.find(
      (rv) => rv.id === recordVersionID,
    );

    if (!recordVersion) {
      return;
    }

    const newDocument = recordVersion.record.documents.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
      (item) => item.id === documentID,
    );

    setDocument(newDocument);
  }, [document_id]);

  useEffect(() => {
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
    setIsSubmitDisabled(!document.name || !document.file?.name || isSubmitting);
  }, [document, isSubmitting]);

  // funcs
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  function handleNameChange(e) {
    const { value: name } = e.target;
    setDocument({ ...document, name });
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  function handleAttachment(val, filedata) {
    const filename = filedata?.name;
    setDocument({
      ...document,
      file: { name: filename, id: val[0], filedata },
    });
  }

  async function handleSubmit() {
    setIsSubmitting(true);

    if (isNew) {
      if (isAudits) {
        await mainStore.themisAudits.createDocument(recordVersionID, document);
      } else if (isQA) {
        // @ts-expect-error TS(2339) FIXME: Property 'qaTests' does not exist on type 'MainSto... Remove this comment to see the full error message
        await mainStore.qaTests.createDocument(recordVersionID, document);
      }
    } else {
      if (isAudits) {
        await mainStore.themisAudits.updateDocument(
          recordVersionID,
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{ name: stri... Remove this comment to see the full error message
          document.id,
          document,
        );
      } else if (isQA) {
        // @ts-expect-error TS(2339) FIXME: Property 'qaTests' does not exist on type 'MainSto... Remove this comment to see the full error message
        await mainStore.qaTests.updateDocument(
          recordVersionID,
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{ name: stri... Remove this comment to see the full error message
          document.id,
          document,
        );
      }
    }

    history.goBack();
  }

  // renders
  const renderHeader = (
    <div className="document-header">
      <h2>Document Details</h2>
    </div>
  );

  const renderNameInput = (
    <div className="input-block column column-input">
      <h4>Name</h4>
      <input
        type="text"
        name="name"
        placeholder="Document Name"
        value={document.name}
        onChange={handleNameChange}
      />
    </div>
  );

  const fileProviderTrigger = (
    <p className="truncate">
      {/* @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'. */}
      {document.file?.name || "+ Add a file"}
    </p>
  );

  const renderFileProvider = (
    <div className="input-block column column-input">
      <h4>File</h4>
      <div
        // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type '{}'.
        className={classNames("file-select", { active: document.file?.name })}
        data-testid="build-vendor-input-contract"
      >
        <FileInput
          handleAttachment={(signedIds, file) =>
            handleAttachment(signedIds, file)
          }
          trigger={fileProviderTrigger}
          triggerStyles={{ display: "flex", width: "85%", textAlign: "left" }}
          acceptableFileTypes={["PDF", "JPEG", "PNG"]}
        />
      </div>
    </div>
  );

  const renderSubmit = (
    <button
      type="button"
      className="table-button"
      disabled={isSubmitDisabled}
      onClick={handleSubmit}
    >
      {isNew ? "Create Document" : "Update Document"}
    </button>
  );

  return (
    <section className="build-document-container">
      {renderHeader}
      <div className="fields-row">
        {renderNameInput}
        {renderFileProvider}
      </div>
      {renderSubmit}
    </section>
  );
}

export default observer(BuildDocument);

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import DetailView from "@/components/detailView/DetailView";
import { useMainStore } from "@/contexts/Store";

const ViewComplaintCS: React.FC = () => {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const complaintID = Number(record_version_id);
  const recordVersion = mainStore.recordVersions.current;

  // Effects
  useEffect(() => {
    const fetchData = async () => {
      if (complaintID === recordVersion?.id) {
        return;
      }

      await mainStore.recordVersions.fetch(complaintID);
    };

    fetchData();
  }, [complaintID]);

  if (recordVersion?.table_name === "Customer Support") {
    return (
      <DetailView
        moduleIdentifier="customer_support"
        ignoredFields={[
          "risk_level",
          "secondary_category",
          "complaint_details",
          "status",
          "main_category",
          "priority",
          "response",
          "reviewer",
          "verifier",
          "date_closed",
          "resolution",
          "response_document",
        ]}
      />
    );
  }

  return (
    <DetailView
      moduleIdentifier="customer_support"
      ignoredFields={[
        "response",
        "customer_support_status",
        "reviewer",
        "complaint_details",
        "response_document",
      ]}
    />
  );
};

export default observer(ViewComplaintCS);

import workspaceIcon0 from "../../images/setting-workspace/icon/ws-icon-0.svg";
import workspaceIcon1 from "../../images/setting-workspace/icon/ws-icon-1.svg";
import workspaceIcon2 from "../../images/setting-workspace/icon/ws-icon-2.svg";
import workspaceIcon3 from "../../images/setting-workspace/icon/ws-icon-3.svg";
import workspaceIcon4 from "../../images/setting-workspace/icon/ws-icon-4.svg";
import workspaceIcon5 from "../../images/setting-workspace/icon/ws-icon-5.svg";
import workspaceIcon6 from "../../images/setting-workspace/icon/ws-icon-6.svg";
import workspaceIcon7 from "../../images/setting-workspace/icon/ws-icon-7.svg";
import workspaceIcon8 from "../../images/setting-workspace/icon/ws-icon-8.svg";
import workspaceIcon9 from "../../images/setting-workspace/icon/ws-icon-9.svg";
import workspaceIcon10 from "../../images/setting-workspace/icon/ws-icon-10.svg";
import workspaceIcon11 from "../../images/setting-workspace/icon/ws-icon-11.svg";
import workspaceIcon12 from "../../images/setting-workspace/icon/ws-icon-12.svg";
import workspaceIcon13 from "../../images/setting-workspace/icon/ws-icon-13.svg";
import workspaceIcon14 from "../../images/setting-workspace/icon/ws-icon-14.svg";
import workspaceIcon15 from "../../images/setting-workspace/icon/ws-icon-15.svg";
import workspaceIcon16 from "../../images/setting-workspace/icon/ws-icon-16.svg";
import workspaceIcon17 from "../../images/setting-workspace/icon/ws-icon-17.svg";
import workspaceIcon18 from "../../images/setting-workspace/icon/ws-icon-18.svg";
import workspaceIcon19 from "../../images/setting-workspace/icon/ws-icon-19.svg";
import workspaceIcon20 from "../../images/setting-workspace/icon/ws-icon-20.svg";
import workspaceIcon21 from "../../images/setting-workspace/icon/ws-icon-21.svg";
import workspaceIcon22 from "../../images/setting-workspace/icon/ws-icon-22.svg";
import workspaceIcon23 from "../../images/setting-workspace/icon/ws-icon-23.svg";
import workspaceIcon24 from "../../images/setting-workspace/icon/ws-icon-24.svg";

export const workspaceIcons = [
  {
    url: workspaceIcon0,
    id: 0,
  },
  {
    url: workspaceIcon1,
    id: 1,
  },
  {
    url: workspaceIcon2,
    id: 2,
  },
  {
    url: workspaceIcon3,
    id: 3,
  },
  {
    url: workspaceIcon4,
    id: 4,
  },
  {
    url: workspaceIcon5,
    id: 5,
  },
  {
    url: workspaceIcon6,
    id: 6,
  },
  {
    url: workspaceIcon7,
    id: 7,
  },
  {
    url: workspaceIcon8,
    id: 8,
  },
  {
    url: workspaceIcon9,
    id: 9,
  },
  {
    url: workspaceIcon10,
    id: 10,
  },
  {
    url: workspaceIcon11,
    id: 11,
  },
  {
    url: workspaceIcon12,
    id: 12,
  },
  {
    url: workspaceIcon13,
    id: 13,
  },
  {
    url: workspaceIcon14,
    id: 14,
  },
  {
    url: workspaceIcon15,
    id: 15,
  },
  {
    url: workspaceIcon16,
    id: 16,
  },
  {
    url: workspaceIcon17,
    id: 17,
  },
  {
    url: workspaceIcon18,
    id: 18,
  },
  {
    url: workspaceIcon19,
    id: 19,
  },
  {
    url: workspaceIcon20,
    id: 20,
  },
  {
    url: workspaceIcon21,
    id: 21,
  },
  {
    url: workspaceIcon22,
    id: 22,
  },
  {
    url: workspaceIcon23,
    id: 23,
  },
  {
    url: workspaceIcon24,
    id: 24,
  },
];

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const HeartLinear = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.16 5C19.1 3.93721 17.6948 3.28854 16.1983 3.17117C14.7019 3.0538 13.2128 3.47546 12 4.36C10.7277 3.41364 9.14399 2.98451 7.56792 3.15903C5.99185 3.33356 4.54044 4.09878 3.50597 5.30058C2.47151 6.50239 1.93082 8.05152 1.9928 9.63601C2.05478 11.2205 2.71482 12.7227 3.84 13.84L10.05 20.06C10.57 20.5718 11.2704 20.8586 12 20.8586C12.7296 20.8586 13.43 20.5718 13.95 20.06L20.16 13.84C21.3276 12.6653 21.9829 11.0763 21.9829 9.42C21.9829 7.76372 21.3276 6.17473 20.16 5V5ZM18.75 12.46L12.54 18.67C12.4693 18.7414 12.3852 18.798 12.2925 18.8366C12.1999 18.8753 12.1004 18.8952 12 18.8952C11.8996 18.8952 11.8001 18.8753 11.7075 18.8366C11.6148 18.798 11.5307 18.7414 11.46 18.67L5.25 12.43C4.46576 11.6283 4.02661 10.5515 4.02661 9.43C4.02661 8.30853 4.46576 7.23165 5.25 6.43C6.04916 5.64099 7.12697 5.19857 8.25 5.19857C9.37303 5.19857 10.4508 5.64099 11.25 6.43C11.343 6.52373 11.4536 6.59812 11.5754 6.64889C11.6973 6.69966 11.828 6.7258 11.96 6.7258C12.092 6.7258 12.2227 6.69966 12.3446 6.64889C12.4664 6.59812 12.577 6.52373 12.67 6.43C13.4692 5.64099 14.547 5.19857 15.67 5.19857C16.793 5.19857 17.8708 5.64099 18.67 6.43C19.465 7.22115 19.9186 8.29219 19.9335 9.41368C19.9485 10.5352 19.5236 11.6179 18.75 12.43V12.46Z"
      fill="#353549"
    />
  </svg>
);

HeartLinear.displayName = "HeartLinearIcon";

export default HeartLinear;

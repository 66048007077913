import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { useFilters } from "@/hooks/useFilters";
import { useLoading } from "@/hooks/useLoading";
import { useTableData } from "@/hooks/useTableData";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import Loading from "../../../components/Loading";
import Table from "../../../components/table/Table";
import { useMainStore } from "../../../contexts/Store";
import QAActiveTests from "../components/QAActiveTests";
import QACompleted from "../components/QACompleted";
import QAHeader from "../components/QAHeader";

function QAPage() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Variables
  const {
    moduleWorkspaceID,
    tableName,
    workspaceID,
    isIW: isInternal,
  } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const recordVersions = mainStore.recordVersions.list;
  const sectionTags = mainStore.sectionTags.orderedList;
  const isInTests = tableName === "Default";
  const fields = mainStore.fields.visibleFields;

  // Hooks
  const location = useLocation();
  // @ts-expect-error TS(2345) FIXME: Argument of type 'Field[] | null' is not assignabl... Remove this comment to see the full error message
  const loading = useLoading(isInternal ? fields : null);

  useTableData();

  const filtersData = useFilters({
    fields,
  });
  const { getTableFiltersParam } = useUpdateFilter();

  // State
  const [showAddNewSection, setShowAddNewSection] = useState(false);
  const [hideSchedule, setHideSchedule] = useState(false);
  const [tableWidth, setTableWidth] = useState(isInTests ? "75%" : "100%");

  // Effects
  useEffect(() => {
    if (isInTests) {
      if (hideSchedule) {
        setTableWidth("100%");
      } else {
        setTableWidth("75%");
      }
    }
  }, [isInTests, hideSchedule]);

  useEffect(() => {
    if (
      mainStore.sectionTags.current &&
      Object.keys(mainStore.sectionTags.current).length !== 0
    ) {
      setShowAddNewSection(false);
    }
  }, [mainStore.sectionTags.current]);

  useEffect(() => {
    if (location.pathname === `/workspaces/${workspaceID}/modules/qa-tests`) {
      mainStore.qa.index({
        workspaceID,
        tableFilters: getTableFiltersParam(),
      });
      mainStore.context.setSubModuleTableName(null);
    }
  }, [location]);

  // Functions
  const addNewSection = () => {
    setShowAddNewSection((prevState) => !prevState);
  };

  const showCTA =
    recordVersions.length < 3 &&
    location.pathname === `/workspaces/${workspaceID}/modules/qa-tests`;

  if (
    loading &&
    [
      `/workspaces/${workspaceID}/modules/qa-tests`,
      `/workspaces/${workspaceID}/modules/qa-tests/completed`,
    ].includes(location.pathname)
  ) {
    return (
      <Table>
        <Loading loadingLayout="table" showTableHeader={false} />
      </Table>
    );
  }

  return (
    <Table>
      <Route
        exact
        path={[
          "/workspaces/:workspace_id/modules/qa-tests",
          "/workspaces/:workspace_id/modules/qa-tests/completed",
          "/workspaces/:workspace_id/modules/qa/templates",
        ]}
      >
        <QAHeader
          setHideSchedule={setHideSchedule}
          hideSchedule={hideSchedule}
          filtersData={filtersData}
        />
      </Route>

      <div className="qa-content">
        <Switch>
          <Route path="/workspaces/:workspace_id/modules/qa-tests/completed">
            <QACompleted />
          </Route>

          <Route path="/workspaces/:workspace_id/modules/qa-tests">
            <QAActiveTests
              hideSchedule={hideSchedule}
              recordVersions={recordVersions}
              tableWidth={tableWidth}
              showAddNewSection={showAddNewSection}
              // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              moduleWorkspaceID={moduleWorkspaceID}
              addNewSection={addNewSection}
              showCTA={showCTA}
              isInternal={isInternal}
              sectionTags={sectionTags}
              canManageSections={canManageSections}
              onCancelAddNewSection={() => setShowAddNewSection(false)}
            />
          </Route>
          <Route path="/workspaces/:workspace_id/modules/qa-tests-development">
            <QAActiveTests
              hideSchedule={hideSchedule}
              recordVersions={recordVersions}
              tableWidth={tableWidth}
              showAddNewSection={showAddNewSection}
              // @ts-expect-error TS(2322) FIXME: Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
              moduleWorkspaceID={moduleWorkspaceID}
              addNewSection={addNewSection}
              showCTA={showCTA}
              isInternal={isInternal}
              sectionTags={sectionTags}
              canManageSections={canManageSections}
              onCancelAddNewSection={() => setShowAddNewSection(false)}
            />
          </Route>
        </Switch>
      </div>
    </Table>
  );
}

export default observer(QAPage);

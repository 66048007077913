import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import RenderMarketingButton from "../../table/marketing/RenderMarketingButton";

function MarketingCreativeViewButton() {
  // Import MobX stores
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const { pathname } = useLocation();
  const prefix = `/workspaces/${workspaceID}/modules/marketing`;

  // Variables
  const recordVersionID = pathname.startsWith(prefix)
    ? parseInt(pathname.split("/")[5], 10)
    : -1;

  // Functions
  const handleUnlock = async () => {
    await mainStore.creatives.unlock(recordVersionID, true);
    await mainStore.recordVersions.fetch(recordVersionID);
  };

  return (
    <div className="marketing-creative-view-button list-points">
      <RenderMarketingButton
        recordVersionID={recordVersionID}
        handleUnlock={handleUnlock}
      />
    </div>
  );
}

export default observer(MarketingCreativeViewButton);

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { TaskDetailView } from "../components/TaskDetailView";
import Archived from "./Archived";
import MyTasksPage from "./MyTasksPage";

export const MY_TASKS_ROUTES = {
  base: "/my-tasks",
  viewsBase: "/my-tasks/view",
  taskDetail: "/my-tasks/tasks/:task_id",
  assignedToMeView: "/my-tasks/view/assigned-to-me",
  createdByMeView: "/my-tasks/view/created-by-me",
  assignedToOthersView: "/my-tasks/view/assigned-to-others",
  allView: "/my-tasks/view/all",
  archived: "/my-tasks/archived",
};

export function MyTasksRoutes() {
  return (
    <Switch>
      <Route exact path={MY_TASKS_ROUTES.base}>
        <Redirect
          to={{
            pathname: MY_TASKS_ROUTES.assignedToMeView,
          }}
        />
      </Route>
      <Route path={MY_TASKS_ROUTES.viewsBase}>
        <MyTasksPage />
      </Route>
      <Route path={MY_TASKS_ROUTES.taskDetail}>
        <TaskDetailView />
      </Route>
      <Route path={MY_TASKS_ROUTES.archived}>
        <Archived />
      </Route>
    </Switch>
  );
}

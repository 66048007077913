import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListOfWorkspaceContractsPathParams,
  ListOfWorkspaceContractsQueryParams,
  ListOfWorkspaceContractsQueryResponse,
} from "../../models/ListOfWorkspaceContracts";

/**
 * @description List of workspace contracts
 * @summary List of workspace contracts
 * @link /workspaces/:workspace_id/contracts
 */
export async function listOfWorkspaceContracts(
  workspaceId: ListOfWorkspaceContractsPathParams["workspace_id"],
  params?: ListOfWorkspaceContractsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListOfWorkspaceContractsQueryResponse>["data"]> {
  const res = await client<ListOfWorkspaceContractsQueryResponse>({
    method: "get",
    url: `/workspaces/${workspaceId}/contracts`,
    params,
    ...options,
  });
  return res.data;
}

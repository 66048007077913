import React from "react";
import {
  generatePath,
  Link,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { FINDING_ROUTES } from "@/features/findings/pages";

export function FindingIdLinkCell({ findingId }: { findingId: number }) {
  const { url } = useRouteMatch();
  const location = useLocation();

  return (
    <Link
      className="tw-w-full hover:tw-underline"
      to={{
        pathname: generatePath(
          generatePath(`${url}${FINDING_ROUTES.details}`, {
            findingId,
          }),
        ),
        state: { from: location.pathname },
      }}
    >
      {findingId}
    </Link>
  );
}

import { observer } from "mobx-react";
import React, { useMemo } from "react";

import { useMainStore } from "@/contexts/Store";
import departmentIcon from "@/images/table-image/icon/department.svg";

interface DepartmentsSearchListProps {
  /**
   * Ids of selected departments
   */
  selectedDepartmentIds: number[];
  /**
   * Search query of input field
   */
  query: string;
  /**
   * Method to call when department is clicked
   * @param department - id of selected department
   */
  onAddDepartment: (departmentId: number) => void;
}

const DepartmentsSearchResults: React.FC<DepartmentsSearchListProps> = ({
  query,
  selectedDepartmentIds,
  onAddDepartment,
}) => {
  const mainStore = useMainStore();

  const filteredDepartments = useMemo(
    () =>
      mainStore.departments.departments.filter(
        (department) =>
          department.title?.toLowerCase().includes(query.toLowerCase()) &&
          !selectedDepartmentIds.includes(department.id),
      ),
    [query, mainStore.departments.departments, selectedDepartmentIds],
  );

  return (
    <div className="email-list-wrap" data-testid="list-departments">
      {filteredDepartments.map((department) => (
        <li
          key={department.id}
          className="select-option"
          onClick={() => {
            onAddDepartment(department.id);
          }}
          data-testid="notification-department"
        >
          <div
            className="select-option-label-wrapper"
            data-testid="department-title"
          >
            <span>
              <img
                src={departmentIcon}
                className="department"
                alt="department-icon"
              />
            </span>
            {department.title}
          </div>
        </li>
      ))}
    </div>
  );
};

export default observer(DepartmentsSearchResults);

import classNames from "classnames";
import React from "react";

import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import { Icon } from "../Elements";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any[]) => any;
}

function FiltersTrigger({ onClick }: Props) {
  // vars
  const { getTableFiltersParam } = useUpdateFilter();
  const filters = getTableFiltersParam();
  const filtersAreActive = filters.length > 0;

  return (
    <button
      type="button"
      className={classNames("filters-trigger-btn", {
        active: filtersAreActive,
      })}
      onClick={onClick}
      data-testid="filters-trigger-btn"
    >
      <Icon
        name="filters"
        color={filtersAreActive ? "generalWhite" : "generalDark"}
        size="de"
        data-testid="filters-trigger-icon"
      />
      <span>Filter{filtersAreActive ? ` (${filters.length})` : ""}</span>
    </button>
  );
}

FiltersTrigger.defaultProps = {
  onClick: () => {},
};

export default FiltersTrigger;

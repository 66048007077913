import React from "react";
import ReactPlayer from "react-player";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleFileLoaded?: (...args: any[]) => any;
  isControls?: boolean;
  url?: string;
}

function CreativeViewVideo({ handleFileLoaded, isControls, url }: Props) {
  return (
    <div className="file-viewer" data-testid="cv-video-container">
      <ReactPlayer onReady={handleFileLoaded} controls={isControls} url={url} />
    </div>
  );
}

export default CreativeViewVideo;

import { MiniTag } from "@themis/ui";
import React from "react";

export const ChildStatus = {
  "Need Review": {
    value: "Need Review",
    label: "Need Review",
    Component: () => <MiniTag color="grey">Need Review</MiniTag>,
  },
  Resolved: {
    value: "Resolved",
    label: "Resolved",
    Component: () => <MiniTag color="primary">Resolved</MiniTag>,
  },
} as const;

export const CHILD_STATUSES = Object.values(ChildStatus);

import { observer } from "mobx-react";
import React from "react";

import type { RecordVersion } from "@/api";
import { useMainStore } from "@/contexts/Store";

import CreativeViewReviewers from "./CreativeViewReviewers";

interface Props {
  recordVersion: RecordVersion;
}

function CreativeViewPendingSubmitter({ recordVersion }: Props) {
  const mainStore = useMainStore();

  const { allUsers } = mainStore.users;
  const submitter = allUsers?.find(
    (user) => user.id === recordVersion?.data?.submitter?.ids[0],
  ) || {
    full_name: "Not Found",
    initials: "NF",
    icon_color_index: 0,
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-center ">
      <CreativeViewReviewers
        reviewers={[submitter]}
        text="Pending Changes From"
      />
      <span className="tw-text-neutral-500">{submitter.full_name}</span>
    </div>
  );
}

export default observer(CreativeViewPendingSubmitter);

import classNames from "classnames";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
var idCellStyles = "tw-mr-1 tw-rounded tw-bg-blue-1 tw-text-blue-4";
var lockedCellStyles = "tw-bg-primary-25 tw-text-primaryDim-300";
var readOnlyCellStyles = "tw-bg-neutral-25 tw-text-neutral-300";
var actionsCellStyles = "tw-sticky tw-right-0 tw-flex tw-gap-1 tw-bg-neutral-25 tw-pl-2 tw-pr-0";
var middleCellStyles = "tw-border-l-0 tw-border-r tw-border-solid tw-border-neutral-100";
var rowStartCellStyles = "tw-rounded-l tw-border-x tw-border-solid tw-border-neutral-100";
var rowEndCellStyles = "tw-rounded-r tw-border-l-0 tw-border-r tw-border-solid tw-border-neutral-100";
var cellPaddingStyles = "tw-px-2.5 tw-py-1";
function TableCellContainer(_a) {
    var _b;
    var children = _a.children, isRowEnd = _a.isRowEnd, isRowStart = _a.isRowStart, isTextContent = _a.isTextContent, isTruncated = _a.isTruncated, noCellPadding = _a.noCellPadding, type = _a.type, _c = _a.customCellClassName, customCellClassName = _c === void 0 ? "" : _c, _d = _a.customRowClassName, customRowClassName = _d === void 0 ? "" : _d;
    var isIdCell = type === "id";
    var isLockedCell = type === "locked";
    var isReadOnlyCell = type === "readOnly";
    var isActionsCell = type === "actions";
    var isMiddleCell = !isRowEnd && !isRowStart && (isLockedCell || isReadOnlyCell);
    var isCustomCell = !!customCellClassName;
    var isCustomRow = !!customRowClassName;
    return (React.createElement("div", { className: classNames("tw-relative tw-box-border tw-flex tw-min-h-10 tw-items-center tw-border-y", (_b = {},
            // Added because text will not overflow as ellipsis in responsive mode
            _b[customCellClassName] = isCustomCell,
            _b[customRowClassName] = isCustomRow,
            _b["tw-min-w-0"] = !isCustomCell && isTruncated,
            _b[idCellStyles] = !isCustomCell && isIdCell,
            _b[lockedCellStyles] = !isCustomCell && isLockedCell,
            _b[readOnlyCellStyles] = !isCustomCell && isReadOnlyCell,
            _b[actionsCellStyles] = !isCustomCell && isActionsCell,
            _b[middleCellStyles] = !isCustomCell && isMiddleCell,
            _b[rowStartCellStyles] = !isCustomRow && isRowStart,
            _b[rowEndCellStyles] = !isCustomRow && isRowEnd,
            _b[cellPaddingStyles] = !noCellPadding,
            _b)) }, isTextContent ? (React.createElement("p", { title: renderToStaticMarkup(children), className: "tw-line-clamp-2 tw-text-sm tw-font-semibold" }, children)) : (children)));
}
function TableCellContent(_a) {
    var _b;
    var columnKey = _a.columnKey, isCustomColumn = _a.isCustomColumn, row = _a.row, Component = _a.Component;
    if (Component) {
        return Component(row);
    }
    if (!columnKey) {
        throw new Error("Row key is required for columns without a component");
    }
    if (isCustomColumn) {
        var value = (_b = row.custom_fields) === null || _b === void 0 ? void 0 : _b[columnKey];
        return Array.isArray(value) ? value.join(", ") : value;
    }
    return row[columnKey];
}
export function TableRow(_a) {
    var row = _a.row, rowActions = _a.rowActions, columns = _a.columns, componentOverrides = _a.componentOverrides, customFieldComponentOverrides = _a.customFieldComponentOverrides, customCellClassName = _a.customCellClassName, customRowClassName = _a.customRowClassName;
    return (React.createElement(React.Fragment, null,
        columns.map(function (_a) {
            var _b;
            var isCustomColumn = _a.isCustomColumn, isRowEnd = _a.isRowEnd, isRowStart = _a.isRowStart, isTruncated = _a.isTruncated, key = _a.key, title = _a.title, type = _a.type, noCellPadding = _a.noCellPadding, Component = _a.Component;
            function getComponent() {
                if (isCustomColumn && key) {
                    return ((customFieldComponentOverrides === null || customFieldComponentOverrides === void 0 ? void 0 : customFieldComponentOverrides[key]) || Component);
                }
                var ComponentOverride = componentOverrides === null || componentOverrides === void 0 ? void 0 : componentOverrides[key];
                return ComponentOverride ? ComponentOverride : Component;
            }
            return (React.createElement(TableCellContainer, { key: type === "actions" ? "actions" : title, isRowEnd: isRowEnd, isRowStart: isRowStart, isTextContent: !getComponent() || typeof ((_b = getComponent()) === null || _b === void 0 ? void 0 : _b(row)) === "string", isTruncated: isTruncated || !getComponent(), type: type, customCellClassName: customCellClassName, customRowClassName: customRowClassName, noCellPadding: noCellPadding },
                React.createElement(TableCellContent, { columnKey: key, isCustomColumn: isCustomColumn, row: row, Component: getComponent() })));
        }),
        rowActions && (React.createElement(TableCellContainer, { type: "actions" }, rowActions(row)))));
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateFindingStatusOpenMutationResponse,
  UpdateFindingStatusOpenPathParams,
} from "../../models/UpdateFindingStatusOpen";

/**
 * @description Update finding status to open
 * @summary Update finding status to open
 * @link /findings/:finding_id/status/reopen
 */
export async function updateFindingStatusOpen(
  findingId: UpdateFindingStatusOpenPathParams["finding_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateFindingStatusOpenMutationResponse>["data"]> {
  const res = await client<UpdateFindingStatusOpenMutationResponse>({
    method: "put",
    url: `/findings/${findingId}/status/reopen`,
    ...options,
  });
  return res.data;
}

import classnames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";

import { ActionButtonPopup } from "@/components/table/menu-actions/components";

import { Icon, IconButton } from "../../components/Elements";
import { RISK_RATING_COLORS } from "../risk-assessment";
import ColorSelect from "../risk-assessment/components/RiskMethodology/RiskMethodologyBuilder/ColorSelect";

interface ScoringMatrixRatingRowProps {
  id?: number;
  index: number;
  text: string;
  color: string;
  riskType: string;
  value: number;
  updateTitle: (index: number, value: string) => void;
  updateColor: (index: number, value: string) => void;
  updateValue: (index: number, value: number) => void;
  removeRating?: (index: number) => void;
  dataTestID?: string;
  hasError: boolean;
  riskRatingsCount: number;
}

function ScoringMatrixRatingRow({
  id,
  index,
  dataTestID,
  text,
  color,
  riskType,
  value,
  updateTitle,
  updateValue,
  removeRating,
  updateColor,
  hasError,
  riskRatingsCount,
}: ScoringMatrixRatingRowProps) {
  const [clonedName, setClonedName] = useState(text);
  const [lastValidValue, setLastValidValue] = useState<number | string>(value);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);

  const disableDelete = !removeRating || riskRatingsCount <= 3;

  // @ts-expect-error TS(7006) FIXME: Parameter 'localDisabled' implicitly has an 'any' ... Remove this comment to see the full error message
  const classes = (localDisabled) =>
    classnames("risk-methodology-builder__content__risk-rating-value", {
      "risk-methodology-builder__content__risk-rating-value__disabled":
        localDisabled,
      "ra-risk-rating-error": !localDisabled && hasError,
    });

  const updateValidValue = (val: string) => {
    const parsedValue = parseInt(val);
    if (!Number.isNaN(parsedValue)) {
      setLastValidValue(parsedValue);
      updateValue(index, parsedValue);
    } else {
      setLastValidValue(0);
      updateValue(index, 0);
    }
  };

  const renderDeleteTrigger = (
    <button
      data-testid="action-btn-popup-trigger"
      className={classnames(
        "gray-button",
        "themisIconButton",
        "themisIconButton--active",
      )}
      disabled={disableDelete}
    >
      <Icon name="trash" data-testid="action-btn-popup-trigger-icon" />
    </button>
  );

  return (
    <div className="risk-methodology-builder__content__risk-rating-input-area">
      <span className="risk-methodology-builder__content__input-container">
        <textarea
          className={classnames(
            "risk-methodology-builder__content__risk-rating-name",
            { "ra-risk-rating-error": hasError },
          )}
          onChange={(e) => setClonedName(e.target.value)}
          onBlur={(e) => updateTitle(index, e.target.value)}
          value={clonedName}
          data-testid={`${dataTestID}-row-${index}-name`}
        />
        <ColorSelect
          index={index}
          selectedColor={color}
          updateColor={updateColor}
          availableColors={RISK_RATING_COLORS}
        />
      </span>
      <span className="risk-methodology-builder__content__risk-rating-value-area">
        <span className="risk-rating-range-label"> Value: </span>
        <input
          className={classes(false)}
          onChange={(e) => {
            updateValidValue(e.target.value);
          }}
          value={lastValidValue}
          data-testid={`${dataTestID}-row-${index}-lower-range`}
          type="text"
        />
        {riskType === "inherent" && id ? (
          <ActionButtonPopup
            showPopup={showDeleteConfirmPopup}
            trigger={renderDeleteTrigger}
            title="Delete Confirmation"
            text="Are you sure you want to delete this rating? It will also delete the corresponding row in the residual matrix!"
            onCancel={() => setShowDeleteConfirmPopup(false)}
            onConfirm={() => removeRating?.(index)}
            onClose={() => setShowDeleteConfirmPopup(false)}
            onOpen={() => setShowDeleteConfirmPopup(true)}
          />
        ) : (
          <IconButton
            onClick={() => removeRating?.(index)}
            disabled={disableDelete}
            icon="trash"
            data-testid={`${dataTestID}-row-${index}-remove`}
          />
        )}
      </span>
    </div>
  );
}

export default observer(ScoringMatrixRatingRow);

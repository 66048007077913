import classNames from "classnames";
import React from "react";
import { PiCaretUpBold } from "react-icons/pi";

import SectionTag from "@/components/table/shared/SectionTag";
import type { Section } from "@/stores/types/section-tags";

import SectionPopupSettings from "./SectionPopupSettings";
import Sharing from "./Sharing";

interface SectionDropdownTitleProps {
  title: string;
  archived?: boolean;
  isOpen: boolean;
  onToggleOpen: () => void;
  isEditable?: boolean;
  isEditMode?: boolean;
  setIsEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  onDelete?: () => void;
  canManageSections?: boolean;
  tableName?: string;
  moduleWorkspaceId?: string;
  sectionTag?: Section;
  rolePrefix?: string;
}

const SectionDropdownTitle = (props: SectionDropdownTitleProps) => {
  // Functions
  const onRename = () => {
    props.setIsEditMode?.(true);
  };

  return (
    <div className="tw-ml-[18px] tw-flex" data-testid="hide-section">
      <div
        className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
        onClick={props.onToggleOpen}
        data-testid="hide-section-button"
      >
        <PiCaretUpBold
          className={classNames(
            "tw-h-[18px] tw-w-[18px] tw-origin-center  tw-text-neutral-500",
            {
              "tw-rotate-180": !props.isOpen,
              "tw-rotate-0 ": props.isOpen,
            },
          )}
        />
      </div>
      {props.title && (
        <SectionTag
          isEditMode={props.isEditMode as boolean}
          setIsEditMode={
            props.setIsEditMode as React.Dispatch<React.SetStateAction<boolean>>
          }
          sectionTagID={props.sectionTag?.id as number}
          sectionTagTitle={props.title as string}
          dataTestID={`${props.rolePrefix}-heading`}
        />
      )}

      {props.isEditable && !props.isEditMode && props.canManageSections && (
        <>
          <Sharing
            moduleWorkspaceID={Number(props.moduleWorkspaceId)}
            objectID={props.sectionTag?.id}
            selectedDepartmentIDs={props.sectionTag?.department_ids}
            selectedUserIDs={props.sectionTag?.user_ids}
            tableName={props.tableName}
            allowSharing
          />

          <SectionPopupSettings onRename={onRename} onDelete={props.onDelete} />
        </>
      )}
    </div>
  );
};

export default SectionDropdownTitle;

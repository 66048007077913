import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ShowFindingPathParams,
  ShowFindingQueryResponse,
} from "../../models/ShowFinding";

/**
 * @description Finding with related data
 * @summary finding
 * @link /findings/:id
 */
export async function showFinding(
  id: ShowFindingPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ShowFindingQueryResponse>["data"]> {
  const res = await client<ShowFindingQueryResponse>({
    method: "get",
    url: `/findings/${id}`,
    ...options,
  });
  return res.data;
}

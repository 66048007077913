import {
  LinkButton,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@themis/ui";
import React from "react";
import { PiClockBold } from "react-icons/pi";

function AuditTrailButton({
  recordType,
  themisRecordId,
}: {
  recordType: string;
  themisRecordId?: number;
}) {
  const pathname = themisRecordId
    ? `/${recordType}/${themisRecordId}/audit_trail`
    : `/${recordType}/audit_trail`;
  return (
    <TooltipProvider>
      <Tooltip delayDuration={700}>
        <TooltipTrigger className="tw-cursor-pointer">
          <LinkButton
            color="transparent"
            to={{
              pathname,
              state: { from: location.pathname },
            }}
            RightIcon={PiClockBold}
            aria-label="View Activity"
          />
        </TooltipTrigger>
        <TooltipContent align="end" side="top" disableArrow>
          <p>View Activity</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default AuditTrailButton;

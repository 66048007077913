import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Copy = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 18L3.11662 17.9933C3.5757 17.9399 3.93995 17.5757 3.99327 17.1166L4 17V4H17L17.1166 3.99327C17.614 3.93551 18 3.51284 18 3C18 2.48716 17.614 2.06449 17.1166 2.00673L17 2H3L2.88338 2.00673C2.4243 2.06005 2.06005 2.4243 2.00673 2.88338L2 3V17L2.00673 17.1166C2.06005 17.5757 2.4243 17.9399 2.88338 17.9933L3 18ZM21 22L21.1166 21.9933C21.5757 21.9399 21.9399 21.5757 21.9933 21.1166L22 21V7L21.9933 6.88338C21.9444 6.46255 21.6343 6.12142 21.2293 6.02641L21.1166 6.00673L21 6H7L6.88338 6.00673C6.4243 6.06005 6.06005 6.4243 6.00673 6.88338L6 7V21L6.00673 21.1166C6.05561 21.5374 6.36575 21.8786 6.77071 21.9736L6.88338 21.9933L7 22H21ZM8 8V20H20V8H8Z"
      fill="#353549"
    />
  </svg>
);

Copy.displayName = "CopyIcon";

export default Copy;

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Documents = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 11C9.5 10.4477 9.94772 10 10.5 10H16.5C17.0523 10 17.5 10.4477 17.5 11C17.5 11.5523 17.0523 12 16.5 12H10.5C9.94772 12 9.5 11.5523 9.5 11Z"
      fill="#353549"
    />
    <path
      d="M10.5 14C9.94772 14 9.5 14.4477 9.5 15C9.5 15.5523 9.94772 16 10.5 16H16.5C17.0523 16 17.5 15.5523 17.5 15C17.5 14.4477 17.0523 14 16.5 14H10.5Z"
      fill="#353549"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1C3.17158 1 2.5 1.67158 2.5 2.5V19C2.5 19.5523 2.94772 20 3.5 20H5.5V22C5.5 22.5523 5.94772 23 6.5 23H20.5C21.0523 23 21.5 22.5523 21.5 22V5C21.5 4.44772 21.0523 4 20.5 4H18.5V2C18.5 1.44772 18.0523 1 17.5 1H4ZM16.5 4V3H4.5V18H5.5V5C5.5 4.44772 5.94772 4 6.5 4H16.5ZM7.5 21H19.5V6H7.5V21Z"
      fill="#353549"
    />
  </svg>
);

Documents.displayName = "DocumentsIcon";

export default Documents;

import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Spinner from "../../table/shared/Spinner";

function RiskRegisterOperationalControlsSaveButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSaving, setIsSaving] = useState(false);

  // Variables
  const history = useHistory();
  const { workspaceID } = mainStore.context;
  const { changed } = mainStore.riskRegisters.operationalControlsData;
  const disabled = isSaving || !changed;

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const id = parseInt(
      window.location.pathname
        .split("/risk-register/")[1]
        .split("/operational-controls-add-new")[0],
      10,
    );
    await mainStore.riskRegisters.saveOperationalControls(id);

    mainStore.toast.setInfoText("Operational Controls data saved!");
    setIsSaving(false);

    history.push(
      `/workspaces/${workspaceID}/modules/risk-register/${id}/operational-controls`,
    );
  }

  return (
    <button
      className="table-button header-action"
      disabled={disabled}
      onClick={handleClick}
    >
      Apply Controls
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(RiskRegisterOperationalControlsSaveButton);

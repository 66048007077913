import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListProjectsPathParams,
  ListProjectsQueryParams,
  ListProjectsQueryResponse,
} from "../../models/ListProjects";

/**
 * @description List projects
 * @summary List projects
 * @link /companies/:company_id/projects
 */
export async function listProjects(
  companyId: ListProjectsPathParams["company_id"],
  params?: ListProjectsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListProjectsQueryResponse>["data"]> {
  const res = await client<ListProjectsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/projects`,
    params,
    ...options,
  });
  return res.data;
}

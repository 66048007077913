import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateContactMutationRequest,
  UpdateContactMutationResponse,
  UpdateContactPathParams,
} from "../../models/UpdateContact";

/**
 * @description Update contact
 * @summary Update contact
 * @link /contacts/:id
 */
export async function updateContact(
  id: UpdateContactPathParams["id"],
  data: UpdateContactMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateContactMutationResponse>["data"]> {
  const res = await client<
    UpdateContactMutationResponse,
    UpdateContactMutationRequest
  >({
    method: "put",
    url: `/contacts/${id}`,
    data,
    ...options,
  });
  return res.data;
}

import { ChildAccount } from "./ChildAccount";
import type { Contact } from "./Contact";
import type { Contract } from "./Contract";
import type { Document } from "./Document";
import type { File } from "./File";
import { PartialRecordVersion } from "./PartialRecordVersion";
import { Questionnaire } from "./Questionnaire";
import type { ThemisRecord } from "./ThemisRecord";

export const accountStatus = {
  active: "active",
  inactive: "inactive",
} as const;
export type AccountStatus = (typeof accountStatus)[keyof typeof accountStatus];
export type Account = ThemisRecord & {
  /**
   * @description Name
   * @type string
   */
  name: string;
  /**
   * @description Status
   * @type string
   */
  status: AccountStatus;
  /**
   * @description Legal Name
   * @type string
   */
  legal_name?: string | null;
  /**
   * @description Account Types\' key sourced from the FieldOptions\' key
   * @type array
   */
  account_types: string[];
  /**
   * @description Website
   * @type string
   */
  website?: string | null;
  /**
   * @description DUNS Number
   * @type string
   */
  duns_number?: string | null;
  /**
   * @description Phone
   * @type string
   */
  phone?: string | null;
  logo?: File;
  /**
   * @description Description
   * @type string
   */
  description?: string | null;
  /**
   * @description Mailing Street
   * @type string
   */
  mailing_street?: string | null;
  /**
   * @description Mailing City
   * @type string
   */
  mailing_city?: string | null;
  /**
   * @description Mailing State
   * @type string
   */
  mailing_state?: string | null;
  /**
   * @description Mailing Postal Code
   * @type string
   */
  mailing_postal_code?: string | null;
  /**
   * @description Mailing Country
   * @type string
   */
  mailing_country?: string | null;
  /**
   * @description Tag
   * @type string
   */
  tag?: string | null;
  /**
   * @description Main Contact ID
   * @type integer
   */
  main_contact_id?: number | null;
  main_contact?: Contact | null;
  /**
   * @description Account Owner ID
   * @type integer
   */
  account_owner_id?: number | null;
  /**
   * @type array | undefined
   */
  contracts?: Contract[];
  /**
   * @type array | undefined
   */
  contacts?: Contact[];
  /**
   * @type array | undefined
   */
  documents?: Document[];
  /**
   * @type array | undefined
   */
  child_accounts?: ChildAccount[];
  /**
   * @type array | undefined
   */
  questionnaires?: Questionnaire[];
  /**
   * @type array | undefined
   */
  vendors?: PartialRecordVersion[];
  /**
   * @description Parent Account Name
   * @type string
   */
  parent_account_name?: string | null;
};

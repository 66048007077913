import axios from "axios";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import { getFileExtension } from "@/components/helpers/Files";
import UploadDate from "@/components/table/shared/UploadDate";
import { useMainStore } from "@/contexts/Store";
import warningIcon from "@/images/table-image/icon/warning-icon.svg";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";

type PopupTypes =
  | "file-attachment"
  | "file-changes"
  | "file-error"
  | "file-upload";

interface Props {
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadingFile?: (...args: any[]) => any;
  errorMessage?: string;
  hasErrorClass?: string;
  hasErrors?: boolean;
  isInTableView?: boolean;
  width?: number | string;
  attachmentViewText?: string;
  attachmentDownloadText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  document: any;
}

function AuditFileSelect({
  downloadingFile,
  errorMessage,
  fieldName,
  hasErrorClass,
  hasErrors,
  width,
  isInTableView,
  attachmentViewText = "",
  attachmentDownloadText = "",
  document,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const location = useLocation();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState<PopupTypes | null>(null);
  const [showFileActions, setShowFileActions] = useState(!isInTableView);
  const [showFileInfo, setShowFileInfo] = useState(true);

  // Variables
  const { themisModuleIdentifier, workspaceID } = mainStore.context;
  const fileNames = document.file.name;
  const { file } = document;

  const files = fileNames;

  useEffect(() => {
    if (
      (viewPopup?.includes("preview-") || viewPopup?.includes("-loading")) &&
      files.length === 0
    ) {
      return;
    }

    if (files.length === 0) {
      setViewPopup("file-upload");
    } else {
      setViewPopup("file-attachment");
    }
  }, [files, location.pathname]);

  useEffect(() => {
    if (
      mainStore.toast.text.includes(
        "account isn't connected or you are not the file owner",
      )
    ) {
      setShowPopup(false);
    }
  }, [mainStore.toast.text]);

  useEffect(() => {
    if (!files || files.length === 0) {
      setShowFileActions(true);
      setShowFileInfo(false);
    }
  }, [files]);

  useEffect(() => {
    if (viewPopup === "file-changes") {
      setShowFileActions(true);
      setShowFileInfo(false);
    }
  }, [viewPopup]);

  const onClose = () => {
    if (viewPopup?.includes("-loading") || viewPopup?.includes("preview-")) {
      return setShowPopup(false);
    }

    setViewPopup(files?.length === 0 ? "file-upload" : "file-attachment");
    setShowPopup(false);

    if (isInTableView && files && files.length > 0) {
      setShowFileInfo(true);
      setShowFileActions(false);
    }
  };

  const handleShowFileActions = useCallback(() => {
    if (isInTableView && files.length > 0) {
      setShowFileActions(true);
      setShowFileInfo(false);
      setShowPopup(true);
    }
  }, [isInTableView, files]);

  const fileFormat =
    fileNames.length > 0 ? fileNames[0]?.split(".").pop() : null;
  const fileExtensions = getFileExtension(file).toLowerCase();

  const style = { width };

  const popupClasses = classNames("file-placeholder", {
    active: showPopup || viewPopup === "file-error",
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    "file-uploaded": files.length > 0 || (meta && meta.url),
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "active-file-finalized":
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      (["policy", "procedures"].includes(themisModuleIdentifier) &&
        (fileFormat === "pdf" || fileExtensions.includes("pdf"))) ||
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      ["complaints", "issue_management"].includes(themisModuleIdentifier),
  });

  async function handleClickDownload() {
    const documentFile = file;
    if (!documentFile) {
      return;
    }

    const downloadResponse = await axios({
      method: "GET",
      url: documentFile.url,
      responseType: "blob",
    });

    const blob = downloadResponse.data;
    downloadFile(blob, documentFile.name);
  }

  const renderTrigger = () => {
    const numberOfFiles = fileNames.length;

    // @ts-expect-error TS(7006) FIXME: Parameter 'index' implicitly has an 'any' type.
    const filenameContainerClasses = (index) => {
      const canAddMb = () => {
        if (fileNames.length <= 1) {
          return false;
        }

        return numberOfFiles > 1 && index < numberOfFiles;
      };

      return classNames("file-select-display-container", {
        "file-select-container-mb": canAddMb(),
      });
    };

    return (
      <div
        className="file-select-display-file"
        data-testid="file-select-filename"
      >
        <div
          key={`file-name-${file.id}`}
          data-testid={`attachments-cell-${file.id}`}
        >
          <div className={filenameContainerClasses(0)}>
            <div className="file-select-download">
              <a
                target="_blank"
                href={file.url || undefined}
                rel="noreferrer"
                className="integration-icon-link"
                data-testid="integration-icon-link"
              />
            </div>
            <div
              className="file-select-filename"
              data-testid="file-select-filename"
            >
              {fileNames}
            </div>
          </div>
        </div>

        {file.length === 0 && (
          <>
            <span>{fileNames}</span>
            <Icon
              name="download"
              onClick={(event) => {
                event.stopPropagation();
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefine... Remove this comment to see the full error message
                downloadingFile();
              }}
            />
          </>
        )}
      </div>
    );
  };

  const renderFileInfo = () => (
    <div className="table-dropdown file-info-container" data-testid="file-info">
      <div className="info-container">
        <div
          key={`attachment-group-${file.id}`}
          className="file-info file-select-display-file"
        >
          <div className="file-select-display-container">
            <div className="file-info-name" data-testid="file-select-filename">
              {file.name}
            </div>
            <div className="file-select-download">
              <a
                target={
                  ["device", "direct_upload", null].includes(file.content_type)
                    ? ""
                    : "_blank"
                }
                rel="noreferrer"
                className="attachment-download"
                data-testid="attachments-cell-download"
                onClick={(event) => {
                  event.stopPropagation();
                  handleClickDownload();
                }}
              >
                <Icon name="download" />
              </a>
            </div>
          </div>
          <UploadDate date={document.upload_date} />
        </div>
      </div>
    </div>
  );

  // Variables
  const moduleIdentifier = themisModuleIdentifier
    ? kebabCase(themisModuleIdentifier)
    : "";
  const documentPreviewURL = `/workspaces/${workspaceID}/modules/${moduleIdentifier}/document_preview/${file?.id}`;

  const renderFileActions = () => (
    <div className="file-actions" data-testid="file-actions">
      {viewPopup === "file-attachment" && (
        <div
          className="table-dropdown attachment-dropdown"
          data-testid="attachment-cell-dropdown"
        >
          <ul className="file-select-popup-hover-container">
            {attachmentViewText && (
              <li
                className="hover-container"
                data-testid="view-attachment-button"
              >
                <Icon className="field-icon no-hover-only" name="eye" />
                <Icon
                  className="field-icon hover-only"
                  name="eye"
                  color="brandingHighlightViolet"
                />
                <NavLink to={documentPreviewURL} className="link-creative">
                  {attachmentViewText}
                </NavLink>
              </li>
            )}
            {attachmentDownloadText && (
              <li
                className="hover-container"
                data-testid="view-attachment-button"
              >
                <Icon className="field-icon no-hover-only" name="download" />
                <Icon
                  className="field-icon hover-only"
                  name="download"
                  color="brandingHighlightViolet"
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="attachment-download1"
                  data-testid="attachments-cell-download"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClickDownload();
                  }}
                >
                  {attachmentDownloadText}
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );

  const popup = (
    <Popup
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => (
        <ul>
          <li
            className={popupClasses}
            style={{
              ...style,
              borderColor: "transparent",
              border: "0px",
              padding: "0px",
            }}
            data-testid="attachments-cell-trigger"
            data-field-name={fieldName}
            onClick={handleShowFileActions}
          >
            <div className="cell-content">{renderTrigger()}</div>
          </li>
        </ul>
      )}
      onOpen={() => setShowPopup(true)}
      keepTooltipInside
      open={showPopup}
      onClose={onClose}
      on={isInTableView && files.length > 0 && showFileInfo ? "hover" : "click"}
    >
      {showFileInfo && isInTableView && files.length > 0 && renderFileInfo()}
      {showFileActions && renderFileActions()}
    </Popup>
  );

  if (errorMessage !== undefined) {
    return (
      <>
        {showPopup && popup}
        {!showPopup && (
          <Popup
            position="bottom right"
            // eslint-disable-next-line react/no-unstable-nested-components
            trigger={() => (
              <ul>
                <li
                  className={popupClasses}
                  style={{
                    ...style,
                    borderColor: "transparent",
                    border: "0px",
                    padding: "0px",
                  }}
                  onClick={() => {
                    setViewPopup("file-upload");
                    setShowPopup(true);
                  }}
                >
                  <div
                    className="cell-content"
                    style={{ borderColor: "transparent" }}
                  >
                    {renderTrigger()}
                  </div>
                </li>
              </ul>
            )}
            on="hover"
            open={viewPopup === "file-error"}
            onOpen={() => setViewPopup("file-error")}
            onClose={() => setViewPopup(null)}
            keepTooltipInside
          >
            <div className="table-dropdown error">
              <ul className="errors">
                <li>
                  <img src={warningIcon} alt="warning-icon" />
                  {errorMessage}
                </li>
              </ul>
            </div>
          </Popup>
        )}
      </>
    );
  }

  if (Number(files?.length) > 0 || Number(file?.length) > 0) {
    return popup;
  }
}

AuditFileSelect.defaultProps = {
  width: "100%",
  hasErrors: false,
  attachmentGroups: [],
  hasErrorClass: "has-errors",
  isInTableView: false,
  file: null,
};

export default observer(AuditFileSelect);

import { observer } from "mobx-react";
import React from "react";
import { Link, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

function VendorEmptyQuestionnaires() {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const { vendor_id } = useParams<{ vendor_id?: string }>();

  return (
    <div
      className="no-column-wrap vdd-add-first-questionnaire"
      data-testid="create-first-cta"
    >
      <div className="no-column-block">
        <div className="no-column-procedure">
          <div className="no-column-title">
            <h3>Add your first Questionnaire</h3>
          </div>
          <p style={{ marginBottom: "24px" }}>
            Click “Add New Questionnaire” below to create the first
            questionnaire under this vendor. If you want to start with a
            template, find a suitable template and click “Use” to start building
            it.
          </p>
          <Link
            to={`/workspaces/${workspaceID}/modules/vendor-due-diligence/${vendor_id}/questionnaires/create`}
            className="table-button questionnaire"
          >
            Add New Questionnaire
          </Link>
        </div>
      </div>
    </div>
  );
}

export default observer(VendorEmptyQuestionnaires);

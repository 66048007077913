import React from "react";
import { useParams } from "react-router-dom";

import { useMonitoringGroupResults } from "@/api/queries/websiteMonitoring";
import { Header } from "@/components/Layout/Header";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";

import { WEBSITE_MONITORING_ROUTES } from "..";
import { Subtitle } from "../../components/Subtitle";
import { MonitoringGroupResultInfo } from "./MonitoringGroupResultInfo";

export function MonitoringGroupDetails() {
  const { monitoringGroupAssetId } = useParams<{
    monitoringGroupAssetId: string;
  }>();

  const mainStore = useMainStore();

  const { data, isLoading, error } = useMonitoringGroupResults(
    Number(monitoringGroupAssetId),
  );

  return (
    <PageLayout>
      <Header
        backButtonLinkTo={`${WEBSITE_MONITORING_ROUTES.base}${WEBSITE_MONITORING_ROUTES.monitoringGroups}`}
        title={
          <div className="tw-align-center tw-flex tw-gap-1">
            Website Monitoring
            {!(isLoading || error) && (
              <Subtitle>{data?.data?.group?.group_name || ""}</Subtitle>
            )}
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === "website_monitoring",
                )?.id
              }
            />
          </div>
        }
      />
      {!(isLoading || error) && (
        <MonitoringGroupResultInfo monitoringGroupResults={data?.data} />
      )}
    </PageLayout>
  );
}

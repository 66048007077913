import { observer } from "mobx-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Spinner from "../../table/shared/Spinner";

function CMLibraryItemSaveButton() {
  // Import MobX stores
  const mainStore = useMainStore();

  // state
  const [isSaving, setIsSaving] = useState(false);

  // Variables
  const history = useHistory();
  const { libraryItem } = mainStore.controlMappings;
  const disabled =
    isSaving ||
    (!libraryItem?.control_mapping_type &&
      !libraryItem?.control_category &&
      !libraryItem?.title &&
      !libraryItem?.description);

  // Functions
  async function handleClick() {
    setIsSaving(true);

    const data = {
      // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type '{}'.
      title: libraryItem.title,
      // @ts-expect-error TS(2339) FIXME: Property 'description' does not exist on type '{}'... Remove this comment to see the full error message
      description: libraryItem.description,
      tag_ids: libraryItem?.frameworks?.map((f) => f.id),
    };

    await mainStore.controlMappings.updateLibraryItem(
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type '{}'.
      libraryItem.id,
      data,
      // @ts-expect-error TS(2339) FIXME: Property 'control_category' does not exist on type... Remove this comment to see the full error message
      libraryItem.control_category,
      libraryItem?.control_mapping_type,
    );
    mainStore.toast.setInfoText("Done!");

    history.goBack();
  }

  return (
    <button
      className="table-button header-action"
      disabled={disabled}
      onClick={handleClick}
      data-testid="cm-save-library-item"
    >
      Save Library Item
      {isSaving && <Spinner />}
    </button>
  );
}

export default observer(CMLibraryItemSaveButton);

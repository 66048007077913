import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ChevronUp = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0002 16.0003C21.0002 15.7443 20.9023 15.4883 20.7073 15.2933L12.7072 7.29325C12.3162 6.90225 11.6843 6.90225 11.2933 7.29325L3.29325 15.2933C2.90225 15.6843 2.90225 16.3163 3.29325 16.7073C3.68425 17.0983 4.31625 17.0983 4.70725 16.7073L12.0002 9.41425L19.2932 16.7073C19.6842 17.0983 20.3163 17.0983 20.7073 16.7073C20.9023 16.5123 21.0002 16.2563 21.0002 16.0003"
      fill="#353549"
    />
  </svg>
);

ChevronUp.displayName = "ChevronUpIcon";

export default ChevronUp;

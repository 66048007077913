import React from "react";

import { iconForThemisModuleIdentifier } from "@/components/helpers/iconForThemisModuleIdentifier";
import { nameForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

export function SourceModuleCell({
  moduleIdentifier,
}: {
  moduleIdentifier: ModuleIdentifier;
}) {
  const moduleName = nameForThemisModuleIdentifier(moduleIdentifier);

  return (
    <div className="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden">
      <img
        className="tw-h-5 tw-w-5"
        src={iconForThemisModuleIdentifier(moduleIdentifier)}
        alt={`Module Icon for ${moduleName}`}
      />
      <p className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-font-semibold">
        {moduleName}
      </p>
    </div>
  );
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ControlMappingsEffectivenessPathParams,
  ControlMappingsEffectivenessQueryResponse,
} from "../../models/ControlMappingsEffectiveness";

/**
 * @description Control Mappings Effectiveness
 * @summary Control Mappings Effectiveness
 * @link /module_workspaces/:module_workspace_id/control_mappings/effectiveness
 */
export async function controlMappingsEffectiveness(
  moduleWorkspaceId: ControlMappingsEffectivenessPathParams["module_workspace_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ControlMappingsEffectivenessQueryResponse>["data"]> {
  const res = await client<ControlMappingsEffectivenessQueryResponse>({
    method: "get",
    url: `/module_workspaces/${moduleWorkspaceId}/control_mappings/effectiveness`,
    ...options,
  });
  return res.data;
}

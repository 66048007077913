import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListOfAccountContractsPathParams,
  ListOfAccountContractsQueryParams,
  ListOfAccountContractsQueryResponse,
} from "../../models/ListOfAccountContracts";

/**
 * @description List of account contracts
 * @summary List of account contracts
 * @link /accounts/:account_id/contracts
 */
export async function listOfAccountContracts(
  accountId: ListOfAccountContractsPathParams["account_id"],
  params?: ListOfAccountContractsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListOfAccountContractsQueryResponse>["data"]> {
  const res = await client<ListOfAccountContractsQueryResponse>({
    method: "get",
    url: `/accounts/${accountId}/contracts`,
    params,
    ...options,
  });
  return res.data;
}

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

interface Props {
  fieldName: string;
  hasErrors: boolean;
  isUserEditable: boolean;
  recordVersionID: number;
  width?: number | string;
  errorMessage?: string;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propsOnClose?: (...args: any[]) => any;
  value?: { [key: string]: string | number };
}

function ControlEffectivenessRating({
  fieldName,
  recordVersionID,
  width,
  isUserEditable,
  hasErrors,
  errorMessage,
  value = {},
  locked,
  hasErrorClass,
  propsOnClose,
  pinned,
}: Props) {
  const mainStore = useMainStore();

  const [showPopup, setShowPopup] = useState(false);
  const [newValue, setNewValue] = useState<number | null>(null);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

  const { controlMappingsEffectiveness } = mainStore.controlMappings;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  const noControl = { title: "No Control", value: -1, color: "#e1e1e3" };
  const originalValue = controlMappingsEffectiveness.find(
    (item) =>
      item?.value === value?.value &&
      item?.title === value?.title &&
      item?.color === value?.color,
  );

  const selectedOption = () => {
    if (value !== null && Object.keys(value).length > 0) {
      return originalValue || value;
    }
    if (value === null || controlMappingsEffectiveness.length === 0) {
      return null;
    }

    return noControl;
  };

  const options = () => {
    const result =
      selectedOption !== null && controlMappingsEffectiveness.length > 0
        ? [noControl, ...controlMappingsEffectiveness]
        : [];
    return result;
  };

  const style = { width };
  const liClassNames = classNames("cell column-options-cell", {
    active: isSelectModalOpen,
    // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
    [hasErrorClass]: hasErrors,
    "read-only": !isUserEditable && isCurrentWorkspaceActive,
    illumination: selectedOption,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "table-cell--disabled": isReadOnly,
    "pointer-events-none": locked || isReadOnly,
    pinned,
  });

  function checkEqualValue(
    newValueInput: number,
    valueInput: { [key: string]: string | number },
  ) {
    const mapNewValue = controlMappingsEffectiveness[newValueInput];
    if (mapNewValue && valueInput) {
      if (
        mapNewValue.title === valueInput.title &&
        mapNewValue.value === valueInput.value &&
        mapNewValue.color === valueInput.color
      ) {
        return true;
      }
    }
    return false;
  }

  const newValueField = (newValueInput: number) => {
    if (newValueInput === -1) {
      return {};
    }
    return controlMappingsEffectiveness[newValueInput];
  };

  function addItem(index: number | null) {
    setNewValue(index);
    setShowPopup(false);
  }

  function onClose() {
    if (newValue === null) {
      return;
    }
    if (checkEqualValue(newValue, value)) {
      return;
    }

    if (propsOnClose) {
      return propsOnClose(newValue);
    }

    mainStore.recordVersions.update({
      fieldName,
      recordVersionID,
      value: mainStore.avroSchemas.serializeValue(
        fieldName,
        newValueField(newValue),
      ),
    });
  }

  function handlePopUpOpen() {
    setShowPopup(true);
    setIsSelectModalOpen(true);
  }

  function handlePopUpClose() {
    onClose();
    setShowPopup(false);
    setIsSelectModalOpen(false);
  }

  const titlePlaceholder = hasErrors ? "" : "- Select Control Rating -";
  const optionsPlaceholder = (
    <p
      className="options-placeholder nodata"
      data-testid="column-options-empty"
    >
      {titlePlaceholder}
    </p>
  );

  const renderTrigger = (
    <li
      className={liClassNames}
      style={style}
      data-testid="column-options-trigger"
    >
      <div className="cell-content">
        <div
          className="options column-options-container"
          data-testid="column-options-container"
        >
          {selectedOption() === null && optionsPlaceholder}

          {hasErrors && <div>{errorMessage}</div>}

          {!hasErrors && selectedOption() && (
            <div
              className="options column-options-item"
              data-testid="column-options-item"
            >
              <span
                className="value value-type rr-inherent-value-cell-item-white"
                style={{
                  backgroundColor: `${selectedOption()?.color}`,
                }}
                data-testid="column-options-value"
              >
                {selectedOption()?.title}
              </span>
            </div>
          )}
        </div>
      </div>
    </li>
  );

  const renderContent = (
    <div
      className="options-dropdown"
      data-testid="column-options-popup-content"
    >
      <div
        className="options-dropdown-list"
        data-testid="column-options-dropdown-list"
      >
        <ul>
          <div>
            {controlMappingsEffectiveness?.length === 0 && (
              <h4 className="no-result">No result found</h4>
            )}

            {options().map((item, index) => {
              return (
                <li
                  key={item.value}
                  style={{ fontWeight: "300" }}
                  data-testid="column-options-dropdown-item"
                  onClick={() =>
                    item.value === -1 ? addItem(-1) : addItem(index - 1)
                  }
                >
                  <span className="rr-inherent-value-cell-items-container">
                    <span
                      className="rr-inherent-value-cell-item-white"
                      style={{
                        backgroundColor: `${item.color}`,
                      }}
                    >
                      {item.title}
                    </span>
                  </span>
                </li>
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );

  if (!isUserEditable) {
    return renderTrigger;
  }

  return (
    <Popup
      position="bottom left"
      // eslint-disable-next-line react/no-unstable-nested-components
      trigger={() => renderTrigger}
      open={showPopup}
      onOpen={handlePopUpOpen}
      onClose={handlePopUpClose}
      keepTooltipInside
    >
      {renderContent}
    </Popup>
  );
}

ControlEffectivenessRating.defaultProps = {
  hasErrorClass: "has-errors",
};

export default observer(ControlEffectivenessRating);

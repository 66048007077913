import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UnarchiveWorkspaceMutationResponse,
  UnarchiveWorkspacePathParams,
} from "../../models/UnarchiveWorkspace";

/**
 * @description Unarchive workspace
 * @summary Unarchive workspace
 * @link /workspaces/:id/unarchive
 */
export async function unarchiveWorkspace(
  id: UnarchiveWorkspacePathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UnarchiveWorkspaceMutationResponse>["data"]> {
  const res = await client<UnarchiveWorkspaceMutationResponse>({
    method: "put",
    url: `/workspaces/${id}/unarchive`,
    ...options,
  });
  return res.data;
}

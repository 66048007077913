import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateTaskMutationRequest,
  CreateTaskMutationResponse,
  CreateTaskPathParams,
} from "../../models/CreateTask";

/**
 * @description Create task
 * @summary Create task
 * @link /companies/:company_id/tasks
 */
export async function createTask(
  companyId: CreateTaskPathParams["company_id"],
  data: CreateTaskMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateTaskMutationResponse>["data"]> {
  const res = await client<
    CreateTaskMutationResponse,
    CreateTaskMutationRequest
  >({
    method: "post",
    url: `/companies/${companyId}/tasks`,
    data,
    ...options,
  });
  return res.data;
}

import {
  ConfirmationPopup,
  IconButton,
  LinkIconButton,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { PiPencilSimpleLineBold, PiTrashSimpleBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import type { Contract } from "@/api";
import { useDeleteContract } from "@/api/queries/contracts";
import {
  ALL_CONTRACTS_PATH,
  CONTRACT_DETAILS_PATH,
} from "@/features/accounts/pages";

export function ContractsActionsCell({ contract }: { contract: Contract }) {
  const isAllContracts = Boolean(useRouteMatch(ALL_CONTRACTS_PATH));

  const toast = useToast();
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { mutate: deleteContract } = useDeleteContract({
    workspaceId: isAllContracts ? Number(workspace_id) : undefined,
    accountId: !isAllContracts ? contract.account_id : undefined,
    onSuccess: () => {
      toast({
        content: "Contract has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove contract!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    setIsConfirmationOpen(false);
    deleteContract(contract.id);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  return (
    <>
      <LinkIconButton
        aria-label="Edit Contract"
        aria-describedby={`contract-id-${contract.id}`}
        to={(location) => ({
          pathname: generatePath(CONTRACT_DETAILS_PATH, {
            workspace_id,
            accountId: contract.account_id,
            contractId: contract.id,
          }),
          state: { from: location.pathname },
        })}
        Icon={PiPencilSimpleLineBold}
        size="lg"
        color="tertiary"
      />
      <span id={`contract-id-${contract.id}`} className="tw-hidden">
        Contract Name {contract.file?.file_name}
      </span>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Contract"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      >
        <IconButton
          aria-label="Delete Contract"
          aria-describedby={`contract-id-${contract.id}`}
          Icon={PiTrashSimpleBold}
          size="lg"
          color="tertiary"
          onClick={handleClickDelete}
        />
      </ConfirmationPopup>
    </>
  );
}

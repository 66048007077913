import { autorun, toJS } from "mobx";
import { useEffect } from "react";

import { LOADING_DELAY } from "../components/constants";
import { useMainStore } from "../contexts/Store";

/**
 * @param options - https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 */
export function useScrollToError(options: ScrollIntoViewOptions = {}) {
  const mainStore = useMainStore();

  useEffect(
    () =>
      autorun(() => {
        const cellErrors = toJS(mainStore.recordVersions.cellsErrors);

        if (Array.isArray(cellErrors) && cellErrors.length > 0) {
          setTimeout(() => {
            const elements = document.querySelectorAll("li.has-errors");
            if (elements.length > 0) {
              elements[0].scrollIntoView({
                behavior: "smooth",
                inline: "center",
                ...options,
              });
            }
          }, LOADING_DELAY);
        }
      }),
    [],
  );
}

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateFindingMutationRequest,
  CreateFindingMutationResponse,
} from "../../models/CreateFinding";

/**
 * @description Create finding
 * @summary Create finding
 * @link /findings
 */
export async function createFinding(
  data: CreateFindingMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateFindingMutationResponse>["data"]> {
  const res = await client<
    CreateFindingMutationResponse,
    CreateFindingMutationRequest
  >({
    method: "post",
    url: `/findings`,
    data,
    ...options,
  });
  return res.data;
}

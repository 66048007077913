import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Feedback = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2C20.5949 2 21.9035 3.25157 21.9949 4.82401L22 5V15C22 16.5949 20.7484 17.9035 19.176 17.9949L19 18H15.5L12.8 21.6C12.611 21.852 12.315 22 12 22C11.7244 22 11.4633 21.8867 11.2757 21.6895L11.2 21.6L8.5 18H5C3.40507 18 2.09654 16.7484 2.0051 15.176L2 15V5C2 3.40507 3.25157 2.09654 4.82401 2.0051L5 2H19ZM19 4H5C4.48836 4 4.06466 4.38715 4.00675 4.88362L4 5V15C4 15.5116 4.38715 15.9353 4.88362 15.9933L5 16H9C9.27563 16 9.5367 16.1133 9.72428 16.3105L9.8 16.4L12 19.333L14.2 16.4C14.3654 16.1795 14.6127 16.0386 14.8829 16.0069L15 16H19C19.5116 16 19.9353 15.6129 19.9933 15.1164L20 15V5C20 4.48836 19.6129 4.06466 19.1164 4.00675L19 4ZM12 6C12.5126 6 12.9355 6.38629 12.9933 6.88343L13 7V9H15C15.5126 9 15.9355 9.38629 15.9933 9.88343L16 10C16 10.5126 15.6137 10.9355 15.1166 10.9933L15 11H13V13C13 13.5126 12.6137 13.9355 12.1166 13.9933L12 14C11.4874 14 11.0645 13.6137 11.0067 13.1166L11 13V11H9C8.48743 11 8.06453 10.6137 8.00673 10.1166L8 10C8 9.48743 8.38629 9.06453 8.88343 9.00673L9 9H11V7C11 6.48743 11.3863 6.06453 11.8834 6.00673L12 6Z"
      fill="#353549"
    />
  </svg>
);

Feedback.displayName = "FeedbackIcon";

export default Feedback;

import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class RecordsStore {
  mainStore: MainStore;
  policies = [];
  procedures = [];
  trainings = [];
  audits = [];
  qaTestsDevelopments = [];
  current = {};

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      policies: observable,
      procedures: observable,
      trainings: observable,
      audits: observable,
      qaTestsDevelopments: observable,
      current: observable,
      setPolicies: action,
      setProcedures: action,
      setTrainings: action,
      setAudits: action,
      setQaTestsDevelopments: action,
      setCurrent: action,
      deleteRecordByID: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/records
  // @ts-expect-error TS(7006) FIXME: Parameter 'dataType' implicitly has an 'any' type.
  async index(dataType, workspaceId) {
    const params = {
      data_type: dataType,
      workspace_id: workspaceId,
    };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/records`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      switch (dataType) {
        case "record:policy":
          this.setPolicies(response.data.records);
          break;
        case "record:procedure":
          this.setProcedures(response.data.records);
          break;
        case "record:training":
          this.setTrainings(response.data.records);
          break;
        case "record:audit":
          this.setAudits(response.data.records);
          break;
        case "record:qa_tests_development":
          this.setQaTestsDevelopments(response.data.records);
          break;
        default:
          this.setPolicies([]);
          this.setProcedures([]);
          this.setTrainings([]);
          this.setAudits([]);
          this.setQaTestsDevelopments([]);
      }
    } catch (error) {
      window.console.log(`"Records#index" error ${error}`);
    }
  }

  // POST /api/react/tables/:table_id/records
  // @ts-expect-error TS(7006) FIXME: Parameter 'tableID' implicitly has an 'any' type.
  async create(tableID, sectionTagID) {
    const params = {};
    if (sectionTagID) {
      // @ts-expect-error TS(2339) FIXME: Property 'section_tag_id' does not exist on type '... Remove this comment to see the full error message
      params.section_tag_id = sectionTagID;
    }

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/tables/${tableID}/records`,
        headers: this.mainStore.getHeaders(),
        data: params,
      });

      this.setCurrent(response.data.record);
    } catch (error) {
      window.console.log(`"Records#create for Table" error ${error}`);
    }
  }

  // DELETE /api/react/records/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordID' implicitly has an 'any' type.
  async delete(recordID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/records/${recordID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Records#delete" error ${error}`);
    }
  }

  // GET /api/react/records/:record_id/activity_events/export
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordID' implicitly has an 'any' type.
  async exportActivityEvents(recordID) {
    try {
      this.mainStore.toast.setText("Processing table download...");

      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/records/${recordID}/activity_events/export`,
        headers: this.mainStore.getHeaders(),
      });

      return result.data;
    } catch (error) {
      this.mainStore.toast.setInfoText(
        "An error occurred during export. Please try again or contact Themis support if the problem persists.",
      );
      window.console.log(`"Records#exportActivityEvents" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPolicies(value) {
    if (value) {
      this.policies = value;
    } else {
      this.policies = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setProcedures(value) {
    if (value) {
      this.procedures = value;
    } else {
      this.procedures = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setTrainings(value) {
    this.trainings = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAudits(value) {
    this.audits = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setQaTestsDevelopments(value) {
    this.qaTestsDevelopments = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    if (value) {
      this.current = value;
    } else {
      this.current = {};
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'deletedRecordID' implicitly has an 'any... Remove this comment to see the full error message
  deleteRecordByID(deletedRecordID) {
    this.mainStore.recordVersions.deleteByRecordID(deletedRecordID);
  }

  // Store Helpers

  cleanup() {
    this.setPolicies([]);
    this.setProcedures([]);
    this.setTrainings([]);
    this.setAudits([]);
    this.setQaTestsDevelopments([]);
    this.setCurrent({});
  }
}

export const FIELD_TYPES_CUSTOM_COLUMN = [
  {
    value: "com.askthemis.types.v1.long_text",
    code: "long_text",
    label: "Long Text",
  },
  {
    value: "com.askthemis.types.v1.option",
    code: "option",
    label: "Dropdown",
  },
  {
    value: "com.askthemis.types.v1.link",
    code: "link",
    label: "Link",
  },
  {
    value: "com.askthemis.types.v1.date",
    code: "date",
    label: "Date",
  },
  {
    value: "com.askthemis.types.v1.checklist",
    label: "Checklist",
  },
  {
    value: "com.askthemis.types.v1.attachment",
    code: "attachment",
    label: "Attachment",
  },
  {
    value: "com.askthemis.types.v1.tag_user",
    code: "users",
    label: "Users",
  },
];

export const FIELD_TYPES = [
  ...FIELD_TYPES_CUSTOM_COLUMN,
  {
    value: "com.askthemis.types.v1.text",
    code: "text",
    label: "Free Text",
  },
  {
    value: "com.askthemis.types.v1.tag_user_contact",
    code: "user_contact",
    label: "User Contact",
  },
  {
    value: "com.askthemis.types.v1.tag_department",
    code: "department",
    label: "Department",
  },
  {
    value: "com.askthemis.types.v1.record_policy",
    code: "related_policies",
    label: "Related Policies",
  },
  {
    value: "com.askthemis.types.v1.record_procedure",
    code: "related_procedures",
    label: "Related Procedures",
  },
  {
    value: "com.askthemis.types.v1.tag_verifier",
    code: "verifier",
    label: "Verifier",
  },
  {
    value: "com.askthemis.types.v1.tag_product",
    code: "product",
    label: "Product",
  },
  {
    value: "com.askthemis.types.v1.tag",
    code: "tag",
    label: "Tag",
  },
  {
    value: "com.askthemis.types.v1.tag_control_category",
    code: "category",
    label: "Category",
  },
  {
    value: "com.askthemis.types.v1.tag_control_mapping_type",
    code: "type",
    label: "Type",
  },
  {
    value: "com.askthemis.types.v1.tag_risk_type",
    code: "risk_type",
    label: "Risk Type",
  },
  {
    value: "com.askthemis.types.v1.tag_main_category",
    code: "main_category",
    label: "Main Category",
  },
  {
    value: "com.askthemis.types.v1.multiline_text",
    code: "multiline_text",
    label: "Multiline Text",
  },
  {
    value: "com.askthemis.types.v1.review",
    code: "review",
    label: "Validator",
  },
  {
    value: "com.askthemis.types.v1.integer",
    code: "integer",
    label: "Integer",
  },
];

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { filterCellProps } from "../../../lib/utils";
import { TextInput } from "../../Form/TextInput";
var TextEditorCell = function (_a) {
    var value = _a.value, onValueChange = _a.onValueChange, props = __rest(_a, ["value", "onValueChange"]);
    var filteredProps = filterCellProps(__assign({ value: value, onValueChange: onValueChange }, props));
    return (React.createElement(TextInput, __assign({ className: "tw-h-full tw-rounded-none tw-border-0", onChange: function (e) { return onValueChange(e.target.value); }, onBlur: function () { return props.stopEditing(); }, value: value, autoFocus: true }, filteredProps)));
};
TextEditorCell.displayName = "TextEditorCell";
export default TextEditorCell;

import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import warningIcon from "../../../../images/table-image/icon/warning-icon.svg";
import { formatDate, stringToDate } from "../../../helpers/DateFormatters";
import ConfirmationDialog from "../ConfirmationDialog";
import UsersCircle from "../UsersCircle";

interface Props {
  enableApprove: boolean;
  hasErrors: boolean;
  recordVersionID: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reviews: any[];
  width: number | string;
  errorMessage?: string;
  hasErrorClass?: string;
  locked?: boolean;
  pinned?: boolean;
  viewIssueDetail?: boolean;
}

function ApprovalNew({
  reviews,
  hasErrors,
  recordVersionID,
  width,
  errorMessage,
  hasErrorClass,
  enableApprove,
  locked,
  pinned,
  viewIssueDetail,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isUnapproveShown, setIsUnapproveShown] = useState(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
  const [showPopupError, setShowPopupError] = useState(false);

  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  // Functions
  const approve = () => {
    clearError();

    const recordVersionList = mainStore.recordVersions.list.find(
      (rv) => rv.id === recordVersionID,
    );
    const recordVersionFetch = mainStore.recordVersions.current;
    const recordVersion = recordVersionList || recordVersionFetch;
    const isCreative =
      mainStore.moduleWorkspaces.list.find(
        (moduleWorkspace) =>
          // @ts-expect-error TS(2533) FIXME: Object is possibly 'null' or 'undefined'.
          moduleWorkspace.id === recordVersion.record.module_workspace_id,
      )?.themis_module?.identifier === "marketing";

    if (isCreative) {
      mainStore.creatives.approve(recordVersionID);
    } else {
      mainStore.reviews.createApproval(recordVersionID);
    }
    setIsSelectModalOpen(false);
  };

  const unapprove = () => {
    mainStore.reviews.delete(recordVersionID);
    setIsSelectModalOpen(false);
  };

  const clearError = () => {
    if (errorMessage !== undefined) {
      setShowPopupError(false);
    }
  };

  const style = { width };
  const currentUser = mainStore.users.user;
  const approvedUserIDs = reviews.map((approval) => approval.user_id);
  const approvedUser = mainStore.users.allUsers.find((user) =>
    approvedUserIDs.includes(user.id),
  );
  const approvedDate = reviews.find(
    (approval) => approval.user_id === approvedUser?.id,
  )?.created_at;
  const approvedByCurrentUser = currentUser.id === approvedUser?.id;
  const className = classNames("approval-current-user", {
    active: isSelectModalOpen,
    "hover-container": approvedByCurrentUser,
    "hover-always-visible": approvedByCurrentUser,
    "unapprove-hover": isUnapproveShown,
    "locked-cell": locked && isCurrentWorkspaceActive,
    "pointer-events-none": locked || isReadOnly,
    "table-cell--disabled": isReadOnly,
    "view-approval": viewIssueDetail,
    pinned,
  });
  const unapproveClasses = classNames({
    "unapprove-hover": isUnapproveShown,
    "hover-only": isUnapproveShown,
    "unapprove-flex-column": isUnapproveShown,
    "no-hover-only": !isUnapproveShown,
  });
  const usersClasses = classNames("block-view-users", {
    "no-hover-only": isUnapproveShown,
    "hover-only": !isUnapproveShown,
  });

  const renderApprovedUser = approvedUser && (
    <UsersCircle
      user={approvedUser}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      additionalText={formatDate(stringToDate(approvedDate))}
    />
  );

  const approvedByAnotherUserContent = (
    <li
      className={classNames({
        "locked-cell": locked && isCurrentWorkspaceActive,
        "pointer-events-none": locked || isReadOnly,
        "table-cell--disabled": isReadOnly,
        pinned,
      })}
      style={style}
    >
      <div
        className="users approval approval-another-user"
        data-testid="approval-cell-approved-by-another-user"
      >
        {renderApprovedUser}
      </div>
    </li>
  );

  const approvedByCurrentUserContent = (
    <li
      className={className}
      style={style}
      onMouseEnter={() => setIsUnapproveShown(true)}
      onMouseLeave={() => setIsUnapproveShown(false)}
      data-testid="approval-cell-approved-by-current-user"
    >
      <div className="cell-content">
        <div className="users approval">
          <div className={usersClasses}>{renderApprovedUser}</div>
          <div className={unapproveClasses}>
            <div className="block-view-unapprove">Unapprove Item</div>
          </div>
        </div>
      </div>
    </li>
  );

  const unapproveModalContent = (
    <div className="table-dropdown success-dropdown approval-dropdown">
      <ConfirmationDialog
        heading="Unapprove Item"
        content="Do you want to unapprove this item?"
        handleConfirm={unapprove}
        handleReject={() => {
          setIsSelectModalOpen(false);
        }}
      />
    </div>
  );

  const naStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "black",
  };
  const approveItemContent = (
    <li
      className={classNames(
        "approval-default hover-container hover-always-visible",
        {
          "locked-cell": locked && isCurrentWorkspaceActive,
          "table-cell--disabled": isReadOnly,
          "pointer-events-none": locked || isReadOnly,
          // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
          [hasErrorClass]: hasErrors,
          pinned,
        },
      )}
      style={style}
      data-testid="approval-button"
      onClick={approve}
    >
      <div className="cell-content">
        <span className="no-hover-only">N/A</span>
        <span className="hover-only">Click to Approve Item</span>
      </div>
    </li>
  );

  const approvalNotAllowedContent = (
    <li
      className={classNames("approval-default", {
        "locked-cell": locked && isCurrentWorkspaceActive,
        "pointer-events-none": locked || isReadOnly,
        "table-cell--disabled": isReadOnly,
        // @ts-expect-error TS(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
        [hasErrorClass]: hasErrors,
        pinned,
      })}
      onClick={clearError}
      style={{ ...style, paddingTop: "5px" }}
    >
      <div className="cell-content" style={naStyle}>
        <span>N/A</span>
      </div>
    </li>
  );

  if (approvedUser) {
    return approvedByCurrentUser ? (
      <Popup
        position="bottom right"
        trigger={approvedByCurrentUserContent}
        open={isSelectModalOpen}
        onOpen={() => {
          setIsSelectModalOpen(true);
        }}
        onClose={() => {
          setIsSelectModalOpen(false);
        }}
        keepTooltipInside
      >
        {unapproveModalContent}
      </Popup>
    ) : (
      approvedByAnotherUserContent
    );
  }

  const mainContent =
    enableApprove && hasModuleWriteAccess
      ? approveItemContent
      : approvalNotAllowedContent;

  if (errorMessage !== undefined) {
    return (
      <Popup
        position="bottom right"
        trigger={mainContent}
        on="hover"
        open={showPopupError}
        onOpen={() => setShowPopupError(true)}
        onClose={() => setShowPopupError(false)}
        keepTooltipInside
      >
        <div className="table-dropdown error">
          <ul className="errors">
            <li>
              <img src={warningIcon} alt="warning-icon" />
              {errorMessage}
            </li>
          </ul>
        </div>
      </Popup>
    );
  }

  return mainContent;
}

ApprovalNew.defaultProps = {
  hasErrorClass: "has-errors",
  hasErrors: false,
  viewIssueDetail: false,
};

export default observer(ApprovalNew);

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Import = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V9C20 9.55228 19.5523 10 19 10H13C12.4872 10 12.0645 9.61396 12.0067 9.11662L12 9L11.999 4H7C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44772 20 7 20H9C9.55228 20 10 20.4477 10 21C10 21.5523 9.55228 22 9 22H7C5.34315 22 4 20.6569 4 19V5C4 3.34315 5.34315 2 7 2H14ZM13.999 4.414L14 8H17.586L13.999 4.414ZM16.2929 12.2929C16.6834 11.9024 17.3166 11.9024 17.7071 12.2929L21.9142 16.5C22.3047 16.8905 22.3047 17.5237 21.9142 17.9142C21.5237 18.3047 20.8905 18.3047 20.5 17.9142L18 15.4142V21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21V15.4142L13.5 17.9142C13.1095 18.3047 12.4763 18.3047 12.0858 17.9142C11.6953 17.5237 11.6953 16.8905 12.0858 16.5L16.2929 12.2929Z"
      fill="#353549"
    />
  </svg>
);

Import.displayName = "ImportIcon";

export default Import;

import dayjs from "dayjs";
import { capitalize, isEqual, uniq } from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import React, { createRef, useCallback, useEffect, useMemo } from "react";
import { getElementAtEvent } from "react-chartjs-2";
import { useHistory, useLocation } from "react-router-dom";

import RecordsByMonthHorizontalBar from "@/components/reports/sharedGraphs/records-by-month-horizontal-bar";
import { useMainStore } from "@/contexts/Store";
import useMetricsData from "@/hooks/useMetricsData";
import usePrevious from "@/hooks/usePrevious";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";

import {
  chartTiles,
  chartTitles,
  chartYAxisTitle,
  INTERVAL_OPTIONS,
  overdueBarChartColors,
  overdueDashboardDays,
  TIME_RANGE_OPTIONS,
} from "../constants";
import { createTileLink } from "../helpers";
import type { MetricsPageProps } from "../types";
import { Dashboards, OverdueCharts } from "../types";
import DashboardOptions from "./components/DashboardOptions";
import TotalCountTiles from "./components/TotalCountTiles";

const chartNames = Object.values(OverdueCharts);

const OverdueDashboard = ({
  name,
  identifier,
  defaultDateField,
}: MetricsPageProps) => {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  const history = useHistory();
  const location = useLocation();
  const activeWorkspaceId = mainStore.context.activeWorkspace?.id;

  const data = toJS(mainStore.reports.recordsForMetrics);

  const chartRefs = useMemo(
    () => chartNames.map(() => createRef()),
    [chartNames],
  );

  const {
    timeRange,
    handleChangeTimeRange,
    dateField,
    startDate,
    endDate,
    customCalendarOptions,
    onCalendarSelect,
    updateCalendar,
  } = useMetricsData({ defaultDateField });

  useEffect(() => {
    return () => {
      mainStore.reports.setRecordsForMetrics([]);
    };
  }, []);

  const { getTableFiltersParam } = useUpdateFilter();
  const prevFilter = usePrevious(getTableFiltersParam());
  useEffect(() => {
    if (isEqual(prevFilter, getTableFiltersParam())) {
      return;
    }
    getRecords();
  }, [getTableFiltersParam()]);

  useEffect(() => {
    getRecords();
  }, [activeWorkspaceId, dateField, startDate]);

  const getChartRecords = async (chartType: string) => {
    return await mainStore.reports.getRecordsForOverdueDashboard({
      identifier,
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      table_name: mainStore.context.tableName,
      type: chartType,
      date_column_name:
        chartType === OverdueCharts.DAYS_OPEN ? "created_at" : "due_date",
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      start_date: startDate?.toString(),
      // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      end_date: endDate?.toString(),
      filter_params: getTableFiltersParam(),
    });
  };

  const getRecords = async () => {
    if (!activeWorkspaceId) {
      return;
    }

    const chartData = {};

    for (let index = 0; index < chartNames.length; index++) {
      const chartName = chartNames[index];
      const chartApiData = await getChartRecords(chartName);

      if (chartApiData) {
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        chartData[chartName] = chartApiData;
      }
    }

    mainStore.reports.setRecordsForMetrics(chartData);
  };

  const chartOptions = useCallback(
    // @ts-expect-error TS(7031) FIXME: Binding element 'yAxisTitle' implicitly has an 'an... Remove this comment to see the full error message
    ({ yAxisTitle }) => ({
      maintainAspectRatio: false,
      responsive: true,
      barThickness: 10,
      borderRadius: 0,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      stack: "source",
      elements: {
        bar: {
          barThickness: 500,
          backgroundColor: "#2F76B6",
        },
      },
      scales: {
        y: {
          grid: {
            display: false,
          },
          title: {
            text: yAxisTitle,
            display: true,
          },
        },
        x: {
          min: 0,
          ticks: {
            stepSize: 1,
          },
          grid: {
            borderDash: [6],
          },
        },
      },
      indexAxis: "y",
      hover: {
        // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? "pointer" : "default";
        },
      },
    }),
    [],
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'chartName' implicitly has an 'any' type... Remove this comment to see the full error message
  const getBGcolor = (chartName) => {
    switch (chartName) {
      case OverdueCharts.COMING_DUE:
        return overdueBarChartColors;
      case OverdueCharts.OVERDUE:
        return overdueBarChartColors[0];
      case OverdueCharts.DAYS_OPEN:
        return overdueBarChartColors;
      default:
        return undefined;
    }
  };

  const getChartData = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'chartName' implicitly has an 'any' type... Remove this comment to see the full error message
    (chartName) => {
      const singleChartData = data[chartName]?.count;
      const comingDueChart = chartName === OverdueCharts.COMING_DUE;

      if (!singleChartData) {
        return null;
      }

      const chartLabel = uniq(Object.keys(singleChartData));
      const chartDataset = comingDueChart
        ? Object.values(singleChartData).reverse()
        : Object.values(singleChartData);

      return chartDataset.every((item) => item === 0)
        ? null
        : {
            labels: comingDueChart ? chartLabel.reverse() : chartLabel,
            datasets: [
              {
                data: chartDataset,
                stack: "source",
                maxBarThickness: 150,
                borderSkipped: false,
                borderRadius: 10,
                backgroundColor: getBGcolor(chartName),
              },
            ],
          };
    },
    [data],
  );

  const getTileLink = (
    // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
    label,
    // @ts-expect-error TS(7006) FIXME: Parameter 'start' implicitly has an 'any' type.
    start,
    // @ts-expect-error TS(7006) FIXME: Parameter 'end' implicitly has an 'any' type.
    end,
    startDueDate = null,
    endDueDate = null,
  ) =>
    createTileLink({
      workspaceID,
      label,
      startDate: start,
      endDate: end,
      identifier,
      fields: mainStore.fields.allFields,
      groupFieldName: mainStore.reports.groupFieldName,
      departments: [],
      startDueDate,
      endDueDate,
    });

  const getTotalTilesData = useCallback(() => {
    // @ts-expect-error TS(7034) FIXME: Variable 'result' implicitly has type 'any[]' in s... Remove this comment to see the full error message
    const result = [];
    Object.keys(data).forEach((chartName) => {
      let startCreatedDate = startDate;
      let endCreatedDate = endDate;
      let startDueDate;
      let endDueDate;

      switch (chartName) {
        case OverdueCharts.COMING_DUE:
          // Due Date is within the next 5 days
          startDueDate = dayjs().toDate();
          endDueDate = dayjs().add(5, "days").toDate();
          break;
        case OverdueCharts.OVERDUE:
          // Due Date is before Today
          startDueDate = dayjs()
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            .subtract(TIME_RANGE_OPTIONS[timeRange], "days")
            .toDate();
          endDueDate = dayjs().subtract(1, "day").toDate();
          break;
        case OverdueCharts.DAYS_OPEN:
          // Created At is 30 days ago or more (3000 days)
          startCreatedDate = dayjs()
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            .subtract(TIME_RANGE_OPTIONS[timeRange], "days")
            .toDate();
          endCreatedDate = dayjs().subtract(30, "days").toDate();
          break;
        default:
          break;
      }

      result.push({
        total: data[chartName]?.total || 0,
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        label: chartTiles[chartName],
        link: getTileLink(
          chartName,
          startCreatedDate,
          endCreatedDate,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | undefined' is not assigna... Remove this comment to see the full error message
          startDueDate,
          endDueDate,
        ),
      });
    });

    // @ts-expect-error TS(7005) FIXME: Variable 'result' implicitly has an 'any[]' type.
    return result;
  }, [data, mainStore.reports.recordsForMetrics, timeRange]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleClickBar = (event, index, chartName) => {
    const chart = chartRefs[index]?.current;
    const comingDueChart = chartName === OverdueCharts.COMING_DUE;

    if (!chart) {
      return;
    }

    const singleChartData = data[chartName]?.count;
    // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
    const clickedElement = getElementAtEvent(chart, event)?.[0];

    if (!clickedElement) {
      return;
    }
    const elementIndex = clickedElement.index;
    const clickedLabel = (
      comingDueChart
        ? Object.keys(singleChartData).reverse()
        : Object.keys(singleChartData)
    )[elementIndex];
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const days = overdueDashboardDays[clickedLabel];

    if (!days) {
      return;
    }

    let startCreatedDate = startDate || dayjs().subtract(3000, "days").toDate();
    let endCreatedDate = endDate || dayjs().toDate();
    let startDueDate;
    let endDueDate;

    switch (chartName) {
      case OverdueCharts.COMING_DUE:
        // Due Date is within the next min - max days
        startDueDate = dayjs(endCreatedDate).add(days.min, "days").toDate();
        endDueDate = dayjs(endCreatedDate)
          .add(days.max || 1000, "days")
          .toDate();
        break;
      case OverdueCharts.OVERDUE:
        // Due Date is before max - min days
        startDueDate = dayjs(endCreatedDate)
          .subtract(days.max || 1000, "days")
          .toDate();
        endDueDate = dayjs(endCreatedDate).subtract(days.min, "days").toDate();
        break;
      case OverdueCharts.DAYS_OPEN:
        // Created At is before max - min days ago
        startCreatedDate = dayjs(endCreatedDate)
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          .subtract(days.max || TIME_RANGE_OPTIONS[timeRange], "days")
          .toDate();
        endCreatedDate = dayjs(endCreatedDate)
          .subtract(days.min, "days")
          .toDate();
        break;
      default:
        break;
    }

    const link = getTileLink(
      chartName,
      startCreatedDate,
      endCreatedDate,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | undefined' is not assigna... Remove this comment to see the full error message
      startDueDate,
      endDueDate,
    );
    history.push(link, { from: location.pathname });
  };

  return (
    <div className="metrics-container" data-testid="overdue-dashboard">
      <div className="metrics-header">{`${capitalize(
        mainStore.reports.groupFieldName,
      )} ${name}`}</div>
      <DashboardOptions
        timeRangeValue={timeRange}
        timeRangeOptions={[
          ...Object.keys(TIME_RANGE_OPTIONS),
          ...Object.keys(customCalendarOptions),
        ]}
        handleChangeTimeRange={handleChangeTimeRange}
        intervalOptions={Object.keys(INTERVAL_OPTIONS)}
        onCalendarSelect={onCalendarSelect}
        onDateChange={updateCalendar}
        dashboard={Dashboards.OVERDUE}
      />
      <TotalCountTiles useLabelOnly data={getTotalTilesData()} />
      {chartNames.map((chartName, index) => {
        return (
          <div key={chartName} className="chart-section">
            <RecordsByMonthHorizontalBar
              chartData={getChartData(chartName)}
              chartOptions={chartOptions({
                yAxisTitle: chartYAxisTitle[chartName],
              })}
              index={0}
              barRef={chartRefs[index]}
              // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
              onClick={(e) => handleClickBar(e, index, chartName)}
              chartTitle={chartTitles[chartName]}
            />
          </div>
        );
      })}
    </div>
  );
};

export default observer(OverdueDashboard);

import { observer } from "mobx-react";
import React, { useEffect } from "react";

import { useMainStore } from "@/contexts/Store";

import DocumentsContextMenu from "./DocumentsContextMenu";

function DocumentsActionBar() {
  // Import MobX stores
  const mainStore = useMainStore();
  const path = location.pathname;
  const recordVersionId = Number(path.substring(path.lastIndexOf("/") + 1));

  const recordVersionList = mainStore.recordVersions.list.find(
    (rv) => rv.id === recordVersionId,
  );
  const recordVersionFetch = mainStore.recordVersions.current;
  const recordVersion = recordVersionList || recordVersionFetch;

  useEffect(() => {
    const fetchData = async () => {
      if (recordVersionList || !recordVersionId) {
        return;
      }
      await mainStore.recordVersions.fetch(recordVersionId);
    };

    fetchData();
  }, [recordVersionId]);

  if (!recordVersion || !recordVersionId) {
    return null;
  }
  return <DocumentsContextMenu recordVersion={recordVersion} showOnlyApprove />;
}

export default observer(DocumentsActionBar);

import _ from "lodash";

export const MODULE_LIST = [
  {
    identifier: "policy",
    title: "Policy",
    description:
      "Keep your team aligned by drafting and finalizing important policies in a centralized repository.",
    isIncluded: true,
    isComingSoon: false,
  },
  {
    identifier: "procedures",
    title: "Procedures",
    description:
      "Keep your team aligned by drafting and finalizing important procedures in a centralized repository.",
    isIncluded: true,
    isComingSoon: false,
  },
  {
    identifier: "marketing",
    title: "Marketing",
    description:
      "Collaborate on sales material between departments and provide real-time comments.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "new_product_approval",
    title: "Change Management",
    description:
      "Submit and request approvals for material changes to programs, operations, products and services.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "documents",
    title: "Documents",
    description:
      "Store important documents such as legal agreements, pricing sheets and terms and conditions.",
    isIncluded: true,
    isComingSoon: false,
  },
  {
    identifier: "vendor_due_diligence",
    title: "Vendor Due Diligence",
    description:
      "Share questionnaires with vendors and partners while recording risk assessments on their companies.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "complaints",
    title: "Complaints",
    description:
      "Track complaints from multiple sources centrally to make sure complaints are handled in a timely manner.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "audits",
    title: "Audits",
    description:
      "Store audit reports and issues across IT, Compliance and Finance so risk managers and board members have access to all outstanding company issues.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "qa_tests_development",
    title: "Monitoring & Testing",
    description:
      "Record internal company testing which has validated your company's compliance procedures.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "training",
    title: "Training",
    description:
      "Track company wide and department specific training and communications.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "issue_management",
    title: "Issue Management",
    description:
      "Identify and track firm-wide issues to determine appropriate action plans, risk rate issues and create alerts.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "conflicts_of_interest",
    title: "Conflicts Of Interest",
    description:
      "Track employees' potential conflicts of interest with automated submissions and approvals.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "risk_register",
    title: "Risk Register",
    description: "Identify inherent and residual risks in company controls.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "control_mapping",
    title: "Control Mapping",
    description:
      "Create an inventory of controls, identify effectiveness & perform attestation of controls to meet applicable regulatory and contractual requirements.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "risk_assessment",
    title: "Questionnaires",
    description:
      "Create a risk matrix to determine risk associated with new products, BSA/AML and Sanctions.",
    isIncluded: true,
    isComingSoon: false,
  },
  {
    identifier: "zendesk",
    title: "Zendesk",
    description:
      "Integrate your Zendesk account to auto-import tickets to support the monitoring of issues and complaints in Themis.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "key_risk_indicators",
    title: "Key Risk Indicators",
    description:
      "The Key Risk Indicator (KRI) module allows Themis users to easily track and monitor risk levels, providing valuable insights for effective risk management and decision-making.",
    isIncluded: false,
    isComingSoon: false,
  },
  {
    identifier: "record_retention",
    title: "Record Retention",
    description: "Create a record retention log to comply with regulations.",
    isIncluded: false,
    isComingSoon: true,
  },
];

export const MODULE_MAP = _.keyBy(MODULE_LIST, "identifier");

import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class ProductsStore {
  mainStore: MainStore;

  // Observable objects
  products = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      products: observable,
      setProducts: action,
    });

    this.mainStore = mainStore;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async index(companyID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}/products`,
        headers: this.mainStore.getHeaders(),
      });

      this.setProducts(response.data.products);
    } catch (error) {
      window.console.log(`"Products#Index for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async create(companyID, params) {
    const data = { product: params };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/companies/${companyID}/products`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        const toastError = result.response.data.errors;
        this.mainStore.toast.setInfoText(toastError);
        return;
      }

      // Refetch the list of Products
      this.index(companyID);
    } catch (error) {
      window.console.log(`"Products#create for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async update(companyID, productID, params) {
    const data = { product: params };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/products/${productID}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // Refetch the list of Products
      this.index(companyID);
    } catch (error) {
      window.console.log(`"Products#update for Company" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'companyID' implicitly has an 'any' type... Remove this comment to see the full error message
  async delete(companyID, productID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/products/${productID}`,
        headers: this.mainStore.getHeaders(),
      });

      // Refetch the list of Products
      this.index(companyID);
    } catch (error) {
      window.console.log(`"Products#delete" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setProducts(value) {
    if (value) {
      this.products = value;
    } else {
      this.products = [];
    }
  }

  // Store Helpers

  cleanup() {
    this.setProducts([]);
  }
}

import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import ManageApprovalsSteps from "../manage-approvals/ManageApprovalsSteps";
import StepsSaveButton from "../manage-approvals/StepsSaveButton";
import Table from "../table/Table";
import DashboardContent from "./DashboardContent";
import DashboardContentWrapper from "./DashboardContentWrapper";
import DashboardHeader from "./DashboardHeader";

interface Props {
  moduleIdentifier: string;
}

function ManageApprovalsStepsPage({ moduleIdentifier }: Props) {
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;
  const history = useHistory();

  const backPath = useMemo(() => {
    switch (moduleIdentifier) {
      case "policy":
        return `/workspaces/${workspaceID}/modules/policy/drafts`;

      case "marketing":
        return `/workspaces/${workspaceID}/modules/marketing`;

      default:
        return `/workspaces/${workspaceID}/home`;
    }
  }, [moduleIdentifier]);

  return (
    <DashboardContent>
      {moduleIdentifier === "policy" && (
        <DashboardHeader
          title="Manage Approvals"
          onBackClick={() => history.push(backPath)}
          RightActionBar={<StepsSaveButton />}
        />
      )}
      <DashboardContentWrapper>
        <Table>
          <ManageApprovalsSteps />
        </Table>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}

export default observer(ManageApprovalsStepsPage);

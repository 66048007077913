import auditsIcon from "@/images/modules/audits.svg";
import changeManagementIcon from "@/images/modules/change-management.svg";
import complaintsIcon from "@/images/modules/complaints.svg";
import controlMappingIcon from "@/images/modules/control-inventory.svg";
import customerSupportIcon from "@/images/modules/customer-support.svg";
import defaultIcon from "@/images/modules/default.svg";
import documentsIcon from "@/images/modules/documents.svg";
import finraIcon from "@/images/modules/finra.svg";
import issueManagementIcon from "@/images/modules/issue-management.svg";
import keyRiskIndicatorsIcon from "@/images/modules/key-risk-indicators.svg";
import marketingIcon from "@/images/modules/marketing.svg";
import monitoringAndTestingIcon from "@/images/modules/monitoring-and-testing.svg";
import newRuleInventoryIcon from "@/images/modules/new-rule-inventory.svg";
import policyIcon from "@/images/modules/policies.svg";
import proceduresIcon from "@/images/modules/procedures.svg";
import questionnairesIcon from "@/images/modules/questionnaires.svg";
import recordRetentionIcon from "@/images/modules/record-retention.svg";
import riskRegisterIcon from "@/images/modules/risk-register.svg";
import trainingIcon from "@/images/modules/training.svg";
import vendorDueDiligenceIcon from "@/images/modules/vendors.svg";
import zendeskIcon from "@/images/modules/zendesk.svg";
import type { ModuleWorkspaceIdentifier } from "@/stores/types/module-workspaces-types";

const moduleIcons = {
  policy: policyIcon,
  procedures: proceduresIcon,
  marketing: marketingIcon,
  new_product_approval: newRuleInventoryIcon,
  documents: documentsIcon,
  vendor_due_diligence: vendorDueDiligenceIcon,
  complaints: complaintsIcon,
  audits: auditsIcon,
  training: trainingIcon,
  issue_management: issueManagementIcon,
  risk_register: riskRegisterIcon,
  risk_assessment: questionnairesIcon,
  control_mapping: controlMappingIcon,
  zendesk: zendeskIcon,
  key_risk_indicators: keyRiskIndicatorsIcon,
  finra: finraIcon,
  customer_support: customerSupportIcon,
  change_management: changeManagementIcon,
  monitoring_and_testing: monitoringAndTestingIcon,
  record_retention: recordRetentionIcon,
  questionnaires: questionnairesIcon,
  conflicts_of_interest: defaultIcon,
  qa_tests_development: defaultIcon,
  action_plans: defaultIcon,
  linked_documents: defaultIcon,
  qa_findings: defaultIcon,
  qa_procedures: defaultIcon,
  samples: defaultIcon,
  values: defaultIcon,
  accounts: defaultIcon,
  website_monitoring: defaultIcon,
};

const getModuleWorkspaceIcon = (module: ModuleWorkspaceIdentifier) => {
  if (moduleIcons[module] === undefined) {
    return defaultIcon;
  }

  return moduleIcons[module];
};

export { getModuleWorkspaceIcon };

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  RecordsByMonthByFieldOptionPathParams,
  RecordsByMonthByFieldOptionQueryParams,
  RecordsByMonthByFieldOptionQueryResponse,
} from "../../models/RecordsByMonthByFieldOption";

/**
 * @description Get records grouped by the specified field option and month
 * @summary Records by month by field option
 * @link /companies/:company_id/:record_type/reports/records_by_month_by_field_option
 */
export async function recordsByMonthByFieldOption(
  companyId: RecordsByMonthByFieldOptionPathParams["company_id"],
  recordType: RecordsByMonthByFieldOptionPathParams["record_type"],
  params: RecordsByMonthByFieldOptionQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<RecordsByMonthByFieldOptionQueryResponse>["data"]> {
  const res = await client<RecordsByMonthByFieldOptionQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/${recordType}/reports/records_by_month_by_field_option`,
    params,
    ...options,
  });
  return res.data;
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Anchor = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 13H17C16.7348 13 16.4804 13.1054 16.2929 13.2929C16.1054 13.4804 16 13.7348 16 14C16 14.2652 16.1054 14.5196 16.2929 14.7071C16.4804 14.8946 16.7348 15 17 15H17.91C17.7016 16.2264 17.1171 17.3578 16.2374 18.2374C15.3578 19.1171 14.2264 19.7016 13 19.91V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73479 14.8946 9.48043 14.7071 9.2929C14.5196 9.10536 14.2652 9 14 9H13V7.82C13.6672 7.58411 14.2296 7.11994 14.5876 6.50952C14.9457 5.89911 15.0765 5.18177 14.9568 4.48427C14.8371 3.78678 14.4747 3.15404 13.9337 2.6979C13.3926 2.24176 12.7077 1.99158 12 1.99158C11.2923 1.99158 10.6074 2.24176 10.0663 2.6979C9.52527 3.15404 9.16288 3.78678 9.0432 4.48427C8.92353 5.18177 9.05429 5.89911 9.41237 6.50952C9.77045 7.11994 10.3328 7.58411 11 7.82V9H10C9.73478 9 9.48043 9.10536 9.29289 9.2929C9.10536 9.48043 9 9.73479 9 10C9 10.2652 9.10536 10.5196 9.29289 10.7071C9.48043 10.8946 9.73478 11 10 11H11V19.91C9.77356 19.7016 8.64222 19.1171 7.76256 18.2374C6.8829 17.3578 6.29842 16.2264 6.09 15H7C7.26522 15 7.51957 14.8946 7.70711 14.7071C7.89464 14.5196 8 14.2652 8 14C8 13.7348 7.89464 13.4804 7.70711 13.2929C7.51957 13.1054 7.26522 13 7 13H5C4.73478 13 4.48043 13.1054 4.29289 13.2929C4.10536 13.4804 4 13.7348 4 14C4 16.1217 4.84285 18.1566 6.34315 19.6569C7.84344 21.1571 9.87827 22 12 22C14.1217 22 16.1566 21.1571 17.6569 19.6569C19.1571 18.1566 20 16.1217 20 14C20 13.7348 19.8946 13.4804 19.7071 13.2929C19.5196 13.1054 19.2652 13 19 13ZM12 6C11.8022 6 11.6089 5.94136 11.4444 5.83147C11.28 5.72159 11.1518 5.56541 11.0761 5.38269C11.0004 5.19996 10.9806 4.9989 11.0192 4.80491C11.0578 4.61093 11.153 4.43275 11.2929 4.2929C11.4327 4.15305 11.6109 4.0578 11.8049 4.01922C11.9989 3.98063 12.2 4.00044 12.3827 4.07612C12.5654 4.15181 12.7216 4.27999 12.8315 4.44443C12.9414 4.60888 13 4.80222 13 5C13 5.26522 12.8946 5.51957 12.7071 5.70711C12.5196 5.89465 12.2652 6 12 6Z"
      fill="#353549"
    />
  </svg>
);

Anchor.displayName = "AnchorIcon";

export default Anchor;

import type { AxiosResponse } from "axios";
import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { buildWorkspaceSummaryInformation } from "../helpers/WorkspaceSummaryHelper";
import type { MainStore } from "../Main";
import type {
  ModuleSectionInfo,
  ModuleSummaryInfo,
  ModuleWorkspacesResponse,
  WorkspaceModuleSectionsResponse,
} from "../types/workspace-types";

export default class WorkspaceSummaryStore {
  mainStore: MainStore;

  currentWorkspaceId: string | null = null;
  modules: Array<ModuleSummaryInfo> = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      currentWorkspaceId: observable,
      modules: observable,

      setCurrentWorkspaceId: action,
      setModules: action,
    });

    this.mainStore = mainStore;
  }

  async index(workspaceId: string) {
    if (this.currentWorkspaceId === workspaceId) {
      return;
    }
    this.cleanup();
    this.setCurrentWorkspaceId(workspaceId);

    const [modulesResponse, modulesWithSections] = await Promise.all([
      this.mainStore.moduleWorkspaces.fetchModuleWorkspaces(
        workspaceId,
      ) as Promise<AxiosResponse<ModuleWorkspacesResponse>>,
      this.fetchModuleSections(workspaceId),
    ]);

    this.setModules(
      buildWorkspaceSummaryInformation(
        modulesResponse.data.module_workspaces,
        modulesWithSections,
      ),
    );
  }

  // GET /api/react/workspaces/:workspace_id/module_sections
  async fetchModuleSections(
    workspaceId: string,
  ): Promise<Array<ModuleSectionInfo>> {
    const response: AxiosResponse<WorkspaceModuleSectionsResponse> =
      await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceId}/module_sections`,
        headers: this.mainStore.getHeaders(),
      });
    return response.data.module_sections;
  }

  setModules(modules: Array<ModuleSummaryInfo>) {
    this.modules = modules;
  }

  setCurrentWorkspaceId(id: string | null) {
    this.currentWorkspaceId = id;
  }

  // Store Helpers
  cleanup() {
    this.setCurrentWorkspaceId(null);
    this.setModules([]);
  }
}

import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiPlusBold } from "react-icons/pi";

import { useMainStore } from "@/contexts/Store";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import AddNewButtonPopup from "./AddNewButtonPopup";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionTag?: any;
  workspaceID?: number | null;
}

function AddNewButton({ workspaceID, sectionTag }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  function onPopupOpen() {
    setShowPopup(true);
  }

  if (mainStore.riskRegisters.activeScreen === "Completed") {
    return <div />;
  }

  return (
    <>
      <div
        className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
        onClick={onPopupOpen}
        data-testid="risk-register-table-add-new"
      >
        <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
        <div className="tw-neutral-300 tw-text-sm">
          Add {getRecordName("risk_register", moduleWorkspaces, true)}...
        </div>
      </div>
      <AddNewButtonPopup
        sectionTagID={sectionTag.id}
        workspaceID={workspaceID}
        showPopupParent={showPopup}
      />
    </>
  );
}

export default observer(AddNewButton);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  RecordsByMonthByWorkspacePathParams,
  RecordsByMonthByWorkspaceQueryParams,
  RecordsByMonthByWorkspaceQueryResponse,
} from "../../models/RecordsByMonthByWorkspace";

/**
 * @description Get records grouped by workspace and month
 * @summary Records by month by workspace
 * @link /companies/:company_id/:record_type/reports/records_by_month_by_workspace
 */
export async function recordsByMonthByWorkspace(
  companyId: RecordsByMonthByWorkspacePathParams["company_id"],
  recordType: RecordsByMonthByWorkspacePathParams["record_type"],
  params: RecordsByMonthByWorkspaceQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<RecordsByMonthByWorkspaceQueryResponse>["data"]> {
  const res = await client<RecordsByMonthByWorkspaceQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/${recordType}/reports/records_by_month_by_workspace`,
    params,
    ...options,
  });
  return res.data;
}

import React from "react";

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const Hub = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C11.4477 3 11 3.44772 11 4C11 4.5523 11.4477 5 12 5C12.5522 5 13 4.55223 13 4C13 3.44772 12.5523 3 12 3ZM9 4C9 2.34314 10.3432 1 12 1C13.6568 1 15 2.34314 15 4C15 5.30622 14.1651 6.41745 13 6.82929V11.4226L17.0218 13.7446C17.5498 13.2811 18.2421 13 19 13C20.6568 13 22 14.3432 22 16C22 17.6568 20.6568 19 19 19C17.3432 19 16 17.6568 16 16C16 15.8258 16.0148 15.6552 16.0433 15.4891L12 13.1547L7.95667 15.4891C7.98516 15.6551 8 15.8258 8 16C8 17.6568 6.65683 19 5 19C3.34314 19 2 17.6568 2 16C2 14.3432 3.34314 13 5 13C5.75788 13 6.45013 13.281 6.97821 13.7446L11 11.4226V6.82929C9.83477 6.41744 9 5.30618 9 4ZM5 7C4.44772 7 4 7.44773 4 8C4 8.55227 4.44772 9 5 9C5.55227 9 6 8.55227 6 8C6 7.44773 5.55227 7 5 7ZM2 8C2 6.34317 3.34314 5 5 5C6.65683 5 8 6.34317 8 8C8 9.65683 6.65683 11 5 11C3.34314 11 2 9.65683 2 8ZM19 7C18.4477 7 18 7.44773 18 8C18 8.55227 18.4477 9 19 9C19.5523 9 20 8.55227 20 8C20 7.44773 19.5523 7 19 7ZM16 8C16 6.34317 17.3432 5 19 5C20.6568 5 22 6.34317 22 8C22 9.65683 20.6568 11 19 11C17.3432 11 16 9.65683 16 8ZM5 15C4.44772 15 4 15.4477 4 16C4 16.5523 4.44772 17 5 17C5.55227 17 6 16.5523 6 16C6 15.4477 5.55227 15 5 15ZM19 15C18.4477 15 18 15.4477 18 16C18 16.5523 18.4477 17 19 17C19.5523 17 20 16.5523 20 16C20 15.4477 19.5523 15 19 15ZM12 19C11.4477 19 11 19.4477 11 20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20C13 19.4477 12.5523 19 12 19ZM9 20C9 18.3432 10.3432 17 12 17C13.6568 17 15 18.3432 15 20C15 21.6568 13.6568 23 12 23C10.3432 23 9 21.6568 9 20Z"
      fill="#353549"
    />
  </svg>
);

Hub.displayName = "HubIcon";

export default Hub;

import { Draggable } from "@akojic27/react-beautiful-dnd";
import classNames from "classnames";
import { noop } from "lodash";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import type { RecordVersion, TableName } from "@/api";
import { tableName as tableNameEnum } from "@/api";
import {
  FinraBrexCategories,
  ProblemProductCodes,
} from "@/components/table/finra-complaints/finnra-complaints-cells";
import TestSamplesContextMenu from "@/components/table/qa/TestSamplesContextMenu";
import { useMainStore } from "@/contexts/Store";
import KeyRiskIndicatorsContextMenu from "@/features/key-risk-indicators/components/KeyRiskIndicatorsContextMenu";
import { isKriCellLocked } from "@/features/key-risk-indicators/components/kri-summary/helpers";
import KRIValuesContextMenu from "@/features/key-risk-indicators/components/kri-value/KRIValuesContextMenu";
import PartnerContextMenu from "@/features/risk-assessment/components/PartnerContextMenu";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";
import {
  Submodule,
  TopLevelModule,
} from "@/stores/types/module-workspaces-types";

import draggableIcon from "../../../images/table-image/icon/draggable-icon.svg";
import {
  differenceBetweenDates,
  stringToDate,
} from "../../helpers/DateFormatters";
import ThemisAuditContextMenu from "../audit/ThemisAuditContextMenu";
import ChangeManagementContextMenu from "../change-management/ChangeManagementContextMenu";
import ComplaintsContextMenu from "../complaints/ComplaintsContextMenu";
import ConflictsOfInterestContextMenu from "../conflicts-of-interest/ConflictsOfInterestContextMenu";
import ControlEffectivenessRating from "../control-mapping/ControlEffectivenessRating";
import ControlMappingActions from "../control-mapping/ControlMappingActions";
import CustomerSupportComplaintsContextMenu from "../customer-support/CustomerSupportComplaintsContextMenu";
import DocumentsContextMenu from "../documents/DocumentsContextMenu";
import FinraComplaintsContextMenu from "../finra-complaints/FinraComplaintsContextMenu";
import ActionPlansContextMenu from "../issue-management/ActionPlansContextMenu";
import IMOperationalControlsActions from "../issue-management/IMOperationalControlsActions";
import IMRelatedRisksActions from "../issue-management/IMRelatedRisksActions";
import IssueManagementContextMenu from "../issue-management/IssueManagementContextMenu";
import MarketingContextMenu from "../marketing/MarketingContextMenu";
import PolicyProcedureContextMenu from "../policy/PolicyProcedureContextMenu";
import QAContextMenu from "../qa/QAContextMenu";
import QAFindingsContextMenu from "../qa/QAFindingsContextMenu";
import QALinkedDocumentsContextMenu from "../qa/QALinkedDocumentsContextMenu";
import QAProcedureStepsContextMenu from "../qa/QAProcedureStepsContextMenu";
import OperationalControlsActions from "../risk-register/OperationalControlsActions";
import RiskRegisterContextMenu from "../risk-register/RiskRegisterContextMenu";
import RiskRegisterControlRatingCell from "../risk-register/RiskRegisterControlRatingCell";
import RiskRegisterInherentValuesCell from "../risk-register/RiskRegisterInherentValuesCell";
import RiskRegisterScoringValueCell from "../risk-register/RiskRegisterScoringValueCell";
import TrainingContextMenu from "../training/TrainingContextMenu";
import VDDContextMenu from "../vendor-due-diligence/TableActions";
import ZendeskContextMenu from "../zendesk/ZendeskContextMenu";
import ActionPlanOwners from "./cell-type/ActionPlanOwners";
import ActionPlans from "./cell-type/ActionPlans";
import ApprovalNew from "./cell-type/ApprovalNew";
import Checkbox from "./cell-type/Checkbox";
import Contracts from "./cell-type/Contracts";
import ControlCategoriesSelect from "./cell-type/ControlCategoriesSelect";
import ControlMappingTypesSelect from "./cell-type/ControlMappingTypesSelect";
import Controls from "./cell-type/Controls";
import DateSelect from "./cell-type/DateSelect";
import DateTimeCell from "./cell-type/DateTimeCell";
import DepartmentSelect from "./cell-type/DepartmentSelect";
import FileSelect from "./cell-type/file-select/FileSelect";
import FinraSubmissionCell from "./cell-type/FinraSubmissionCell";
import InputCell from "./cell-type/InputCell";
import IntegerCell from "./cell-type/IntegerCell";
import LinkCell from "./cell-type/LinkCell";
import LongInputCell from "./cell-type/LongInputCell";
import MainCategoriesSelect from "./cell-type/MainCategoriesSelect";
import MultilineInputCell from "./cell-type/MultilineInputCell";
import OperationalControls from "./cell-type/OperationalControls";
import ProductsSelect from "./cell-type/ProductsSelect";
import Questionnaires from "./cell-type/Questionnaires";
import RecordSelect from "./cell-type/RecordSelect";
import RelatedRisks from "./cell-type/RelatedRisks";
import RiskTypesSelect from "./cell-type/RiskTypesSelect";
import Select from "./cell-type/Select";
import TagSelect from "./cell-type/TagSelect";
import ThemisID from "./cell-type/ThemisID";
import { ThemisModuleCell } from "./cell-type/ThemisModuleCell";
import UsersSelect from "./cell-type/users-select/UsersSelect";
import Verifier from "./cell-type/Verifier";
import Checklist from "./checklist/Checklist";
import CommentsSlideMenu from "./comments/CommentsSlideMenu";
import UsersCircle from "./UsersCircle";

interface Props {
  tableID: number;
  additionalRowClassName?: string;
  archived?: boolean;
  currentTableName?: TableName;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dragHandler?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  finraSubmission?: any;
  hasReview?: boolean;
  inSelectMenuActionMode?: boolean;
  isApprovedMarketing?: boolean;
  isArchivedRow?: boolean;
  isComplaintClosed?: boolean;
  isCompleted?: boolean;
  isDraggable?: boolean;
  isLockedArchiveTable?: boolean;
  isLockedRow?: boolean;
  isSorted?: boolean;
  isUserEditable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDate?: any; // TODO: PropTypes.instanceOf(Date)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxDateFunction?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDate?: any; // TODO: PropTypes.instanceOf(Date)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  minDateFunction?: (...args: any[]) => any;
  moduleIdentifier?: ModuleIdentifier;
  moduleWorkspaceID?: number;
  order?: number;
  persistOnCallback?: boolean;
  performValidations?: ({
    recordVersionID,
    fieldName,
    value,
  }: {
    recordVersionID: number | undefined;
    fieldName: string;
    value: string;
  }) => string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  renderFirstBlock?: React.ReactNode;
  showCheckboxFromSlideMenu?: boolean;
  tableName?: TableName;
  width?: number;
  handleUpdate?: (
    fieldName: string,
    value: string,
    recordVersion: RecordVersion,
  ) => void;
}

function ModuleRecordVersion({
  additionalRowClassName,
  archived,
  currentTableName,
  fields,
  finraSubmission,
  hasReview,
  inSelectMenuActionMode,
  isApprovedMarketing,
  isArchivedRow,
  isComplaintClosed,
  isCompleted,
  isDraggable,
  isLockedArchiveTable,
  isLockedRow,
  isSorted,
  isUserEditable,
  maxDate,
  maxDateFunction,
  minDate,
  minDateFunction,
  moduleIdentifier,
  moduleWorkspaceID,
  order,
  persistOnCallback,
  performValidations,
  recordVersion,
  renderFirstBlock,
  tableID,
  tableName,
  showCheckboxFromSlideMenu,
  handleUpdate,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  // States
  const [highlighted, setHighlighted] = useState(false);

  // Variables
  const location = useLocation();
  const recordVersionID = recordVersion.id;
  const reviewedByUserIds =
    // @ts-expect-error TS(7006) FIXME: Parameter 'review' implicitly has an 'any' type.
    recordVersion.reviews?.map((review) => review.user_id) || [];
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const isReadOnly = !hasModuleWriteAccess || isCurrentWorkspaceArchived;

  const status = mainStore.avroSchemas.firstValueForField(
    "status",
    recordVersion.data,
  );

  // Hooks
  useEffect(() => {
    setHighlighted(
      mainStore.context.highlightedRecordId === recordVersion.record.id,
    );
  }, [
    mainStore.context.highlightedRecordId,
    moduleWorkspaceID,
    recordVersion.id,
  ]);

  // Helper functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  const onDataChange = async (fieldName, serializedValue, value) => {
    const newValue = serializedValue || value;

    if (!recordVersion.id) {
      return;
    }
    if (!fieldName) {
      return;
    }
    if (!newValue) {
      return;
    }

    const updatedRecordVersion = await mainStore.recordVersions.update({
      fieldName,
      recordVersionID,
      // @ts-expect-error TS(2322) FIXME: Type '{ code: any; id?: undefined; option?: undefi... Remove this comment to see the full error message
      value: mainStore.avroSchemas.serializeValue(fieldName, newValue, fields),
    });

    // Update RecordVersion in respective Store
    if (moduleIdentifier === "linked_documents") {
      mainStore.linkedDocuments.updateRecordVersion(updatedRecordVersion);
    }
  };

  // Rendering
  const renderComputedCell = (
    // @ts-expect-error TS(7006) FIXME: Parameter 'computedColumnIdentifier' implicitly ha... Remove this comment to see the full error message
    computedColumnIdentifier,
    // @ts-expect-error TS(7006) FIXME: Parameter 'defaultProps' implicitly has an 'any' t... Remove this comment to see the full error message
    defaultProps,
    // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
    fieldName,
    // @ts-expect-error TS(7006) FIXME: Parameter 'style' implicitly has an 'any' type.
    style,
    // @ts-expect-error TS(7006) FIXME: Parameter 'pinned' implicitly has an 'any' type.
    pinned,
  ) => {
    switch (computedColumnIdentifier) {
      case "record_version":
        // Render version field for RecordVersion
        return (
          <li
            key={fieldName}
            style={style}
            className={classNames("cell-version cell read-only", {
              "locked-cell": defaultProps.locked && isCurrentWorkspaceActive,
              "pointer-events-none": defaultProps.locked || isReadOnly,
              "table-cell--disabled": isReadOnly,
              pinned,
            })}
            data-testid="cell-version"
          >
            <div className="cell-content">{recordVersion.version}</div>
          </li>
        );

      case "owner_id": {
        // Render owner_id field for Record
        const owner = mainStore.users.allUsers.find(
          (user) => user.id === recordVersion.owner_id,
        );

        return (
          <li
            key={fieldName}
            style={style}
            className={classNames("computed-columns", {
              "locked-cell pointer-events-none": defaultProps.locked,
            })}
          >
            <div className="cell-content">
              {owner && <UsersCircle user={owner} />}
              {!owner && "N/A"}
            </div>
          </li>
        );
      }

      case "meta.days_open": {
        // Calculation of the number of days from when the Complaint is Received till the Complaint is Closed
        // Now this functional work only for Zendesk module
        const startDate = mainStore.avroSchemas.valueForField(
          "complaint_start_date",
          recordVersion.data,
          // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
          fields,
        );
        const endDate =
          mainStore.avroSchemas.valueForField(
            "complaint_end_date",
            recordVersion.data,
            // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
            fields,
          ) || new Date();
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        const daysOpen = differenceBetweenDates(startDate, endDate, "N/A");
        const daysOpenClasses = classNames("cell computed-columns read-only", {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
          warning: !isNaN(daysOpen) && daysOpen > 30,
          "locked-cell pointer-events-none": defaultProps.locked,
          pinned: defaultProps.pinned,
        });

        return (
          <li key={fieldName} className={daysOpenClasses}>
            <div
              className="cell-content"
              data-testid={`days-open-${recordVersion.id}`}
            >
              {daysOpen}
            </div>
          </li>
        );
      }

      case "questionnaires":
        return (
          <Questionnaires
            {...defaultProps}
            checklistCount={recordVersion?.record?.checklist_count}
            completedChecklistCount={
              recordVersion?.record?.completed_checklist_count
            }
          />
        );

      case "contracts":
        return (
          <Contracts {...defaultProps} contracts={recordVersion?.contracts} />
        );

      case "action_plan_owners":
        return (
          <ActionPlanOwners
            {...defaultProps}
            items={recordVersion?.record?.action_plans || []}
          />
        );
      case "account.name":
        return (
          <li
            key={fieldName}
            style={style}
            className={classNames({
              "locked-cell pointer-events-none": defaultProps.locked,
            })}
          >
            <div
              className="cell-content"
              data-testid={`account-name-${recordVersion.id}`}
            >
              {recordVersion.record?.account?.name}
            </div>
          </li>
        );

      default:
        return (
          <li
            key={fieldName}
            className={classNames({
              "locked-cell pointer-events-none": defaultProps.locked,
            })}
            style={style}
          >
            <div className="cell-content">
              {`Unknown computed column ${computedColumnIdentifier}`}
            </div>
          </li>
        );
    }
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderCells = fields.map((field) => {
    const fieldName = field.name;
    const dataType = field.data_type;
    const value = mainStore.avroSchemas.valueForField(
      fieldName,
      recordVersion.data,
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
      fields,
    );
    const columnTitle = field.display_name;
    const columnWidth = field.width || 200;
    const style = { width: columnWidth };
    const pinned = fieldName === mainStore.fields.pinnedFieldName;
    const isMultiSelect = field.is_multiselect;
    const isSingleSelect = !isMultiSelect;
    const isPrivate = field.private || false;
    const isDefaultField = field.is_default_field;
    const isComputedColumn = field.is_computed_column;

    const cellError = mainStore.recordVersions.errorsForField(
      recordVersion.id,
      fieldName,
    );
    const hasErrors = Boolean(cellError);
    const errorMessage = cellError ? cellError.description : undefined;

    // Common props
    const defaultProps = {
      key: fieldName,
      fieldName,
      onDataChange: persistOnCallback ? onDataChange : undefined,
      width: columnWidth,
      recordVersionID: recordVersion.id,
      hasErrors,
      errorMessage,
      isUserEditable:
        isUserEditable !== undefined ? isUserEditable : field.is_user_editable,
      disabled: isReadOnly,
      locked:
        isLockedRow ||
        isLockedArchiveTable ||
        isArchivedRow ||
        isComplaintClosed ||
        hasReview ||
        isApprovedMarketing,
      pinned,
      recordVersion,
    };

    const files = recordVersion.files || [];
    const processedFiles = recordVersion.processed_files || [];
    const filesWithIDs = recordVersion.files_with_ids || [];
    const attachmentGroups = recordVersion.attachment_groups?.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'attachmentGroup' implicitly has an 'any... Remove this comment to see the full error message
      (attachmentGroup) => attachmentGroup.field_name === fieldName,
    );

    const option = value?.option;
    const options = option ? [option] : [];
    const dollarAmount = value?.dollar_amount;

    const computedColumnIdentifier = field.computed_column_identifier;
    const isLocked = status === "published";
    let checklist;
    let dateTimeCell;
    let dateSelect;
    let isMonthPicker;

    switch (dataType) {
      case "com.askthemis.types.v1.attachment":
        return (
          <FileSelect
            isInTableView
            {...defaultProps}
            // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type '{ url?: str... Remove this comment to see the full error message
            meta={null}
            files={files}
            processedFiles={processedFiles}
            isLockedTable={isLockedRow}
            columnTitle={columnTitle}
            isApprovedMarketing={isApprovedMarketing}
            filesWithIds={filesWithIDs}
            attachmentGroups={attachmentGroups}
            isMultiple={isMultiSelect}
            onAfterFileUpload={() =>
              mainStore.recordVersions.setCellsErrors([])
            }
          />
        );

      case "com.askthemis.types.v1.checklist":
        if (
          moduleIdentifier === "new_product_approval" &&
          fieldName === "checklist"
        ) {
          checklist = recordVersion.record.new_product_checklist;
        } else {
          const base_checklists = recordVersion.record?.base_checklists;
          checklist = base_checklists?.find(
            // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
            (item) => item.field_name === fieldName,
          );
        }

        return (
          <Checklist
            {...defaultProps}
            recordID={recordVersion.record.id}
            defaultValue={checklist}
            locked={defaultProps.locked || isLocked}
            fieldName={fieldName}
            moduleIdentifier={moduleIdentifier}
          />
        );

      case "com.askthemis.types.v1.action_plans":
        return (
          <ActionPlans
            {...defaultProps}
            items={recordVersion?.record?.action_plans || []}
          />
        );

      case "com.askthemis.types.v1.module_controls":
        return (
          <Controls
            {...defaultProps}
            items={recordVersion?.record?.owned_control?.linked_records || []}
          />
        );

      case "com.askthemis.types.v1.operational_controls":
        return (
          <OperationalControls
            {...defaultProps}
            items={
              recordVersion?.record?.owned_control
                ?.control_mapping_record_versions || []
            }
          />
        );

      case "com.askthemis.types.v1.related_risks":
        return (
          <RelatedRisks
            {...defaultProps}
            items={recordVersion?.record?.owned_control?.linked_records || []}
          />
        );

      case "com.askthemis.types.v1.date":
        isMonthPicker =
          (moduleIdentifier === Submodule.VALUES && field.name === "date") ||
          (moduleIdentifier === TopLevelModule.KEY_RISK_INDICATORS &&
            ["start_date", "end_date"].includes(field.name));
        if (isMonthPicker) {
          return (
            <DateSelect
              {...defaultProps}
              onlyMonthYearPicker
              initialValue={value ? new Date(value) : undefined}
              columnTitle={columnTitle}
              isLockedRow={isArchivedRow}
              // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              maxDate={maxDate || maxDateFunction(columnTitle)}
              // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              minDate={minDate || minDateFunction(columnTitle)}
            />
          );
        }
        dateTimeCell = (
          // @ts-expect-error TS(2322) FIXME: Type '{ value: any; key: any; fieldName: any; onDa... Remove this comment to see the full error message
          <DateTimeCell {...defaultProps} value={value} />
        );

        dateSelect = (
          <DateSelect
            {...defaultProps}
            initialValue={value}
            columnTitle={columnTitle}
            isLockedRow={isArchivedRow}
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            maxDate={maxDate || maxDateFunction(columnTitle)}
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            minDate={minDate || minDateFunction(columnTitle)}
          />
        );

        return isDefaultField ? dateTimeCell : dateSelect;

      case "com.askthemis.types.v1.finra_product_code":
        return (
          <ProblemProductCodes
            key={fieldName}
            fieldName={fieldName}
            recordVersionID={recordVersion.id}
            width={columnWidth}
            isProductCodes
            selectedCode={value}
            hasErrors={hasErrors}
            errorMessage={errorMessage}
            locked={defaultProps.locked}
          />
        );

      case "com.askthemis.types.v1.finra_problem_code":
        return (
          <ProblemProductCodes
            key={fieldName}
            fieldName={fieldName}
            recordVersionID={recordVersion.id}
            width={columnWidth}
            isProductCodes={false}
            selectedCode={value}
            hasErrors={hasErrors}
            errorMessage={errorMessage}
            locked={defaultProps.locked}
          />
        );

      case "com.askthemis.types.v1.finra_brex_category":
        return (
          <FinraBrexCategories
            key={fieldName}
            fieldName={fieldName}
            recordVersion={recordVersion}
            width={columnWidth}
            isBrexCategory
            selectedID={value}
            hasErrors={hasErrors}
            errorMessage={errorMessage}
            locked={defaultProps.locked}
          />
        );

      case "com.askthemis.types.v1.finra_brex_sub_category":
        return (
          <FinraBrexCategories
            key={fieldName}
            fieldName={fieldName}
            recordVersion={recordVersion}
            width={columnWidth}
            isBrexCategory={false}
            selectedID={value}
            hasErrors={hasErrors}
            errorMessage={errorMessage}
            locked={defaultProps.locked}
          />
        );

      case "com.askthemis.types.v1.long_text":
        return (
          <LongInputCell
            {...defaultProps}
            initialValue={value}
            readOnly={
              isReadOnly || isArchivedRow || !defaultProps.isUserEditable
            }
            isPrivate={isPrivate}
          />
        );

      case "com.askthemis.types.v1.review":
        return (
          <ApprovalNew
            {...defaultProps}
            reviews={recordVersion.reviews}
            enableApprove
          />
        );

      case "com.askthemis.types.v1.multiline_text":
        return (
          <MultilineInputCell
            {...defaultProps}
            initialValue={value}
            disabled={!defaultProps.isUserEditable || isReadOnly}
          />
        );

      case "com.askthemis.types.v1.option":
        if (
          moduleIdentifier === "vendor_due_diligence" &&
          fieldName === "pii_categories"
        ) {
          const piiProvided =
            mainStore.avroSchemas.firstValueForField(
              "pii_provided",
              recordVersion.data,
              // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
              fields,
            ) === "yes";
          defaultProps.isUserEditable = piiProvided;
        }

        if (
          moduleIdentifier === "finra" &&
          tableName === "FINRA Review" &&
          fieldName === "review_status"
        ) {
          return (
            <FinraSubmissionCell
              key={fieldName}
              finraSubmission={finraSubmission}
              width={columnWidth}
              locked={defaultProps.locked}
            />
          );
        }

        if (
          moduleIdentifier === "risk_register" &&
          ["main_category"].includes(fieldName)
        ) {
          // @ts-expect-error TS(2339) FIXME: Property 'allowDelete' does not exist on type '{ k... Remove this comment to see the full error message
          defaultProps.allowDelete = false;
          // @ts-expect-error TS(2339) FIXME: Property 'renderEmptyOption' does not exist on typ... Remove this comment to see the full error message
          defaultProps.renderEmptyOption = true;
        }

        if (
          moduleIdentifier === "risk_register" &&
          ["control_rating"].includes(fieldName)
        ) {
          return (
            <RiskRegisterControlRatingCell
              {...defaultProps}
              value={parseInt(value, 10)}
              fieldName={fieldName}
            />
          );
        }

        if (
          moduleIdentifier === "risk_register" &&
          ["impact", "likelihood"].includes(fieldName)
        ) {
          return (
            <RiskRegisterInherentValuesCell
              {...defaultProps}
              value={mainStore.avroSchemas.firstValueForField(
                fieldName,
                recordVersion.data,
                // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
                fields,
              )}
              // @ts-expect-error TS(2322) FIXME: Type '{ value: any; columnTitle: any; key: any; fi... Remove this comment to see the full error message
              columnTitle={columnTitle}
            />
          );
        }

        if (
          moduleIdentifier === "risk_register" &&
          ["inherent_risk_rating", "residual_risk_rating"].includes(fieldName)
        ) {
          return (
            <RiskRegisterScoringValueCell
              {...defaultProps}
              fieldName={fieldName}
              matrixCell={
                fieldName === "inherent_risk_rating"
                  ? recordVersion.inherent_risk_rating_cell
                  : recordVersion.residual_risk_rating_cell
              }
            />
          );
        }

        return (
          <Select
            {...defaultProps}
            selectedOptions={value}
            columnTitle={columnTitle}
            moduleIdentifier={moduleIdentifier}
            isMultiSelect={isMultiSelect}
          />
        );

      case "com.askthemis.types.v1.constrained_option":
        return (
          <Select
            {...defaultProps}
            constrained
            selectedOptions={value}
            columnTitle={columnTitle}
            moduleIdentifier={moduleIdentifier}
            isMultiSelect={isMultiSelect}
          />
        );
      case "com.askthemis.types.v1.record_policy":
        return (
          <RecordSelect
            {...defaultProps}
            dataType="record:policy"
            selectedIDs={value}
            singleSelection={isSingleSelect}
          />
        );

      case "com.askthemis.types.v1.record_procedure":
        return (
          <RecordSelect
            {...defaultProps}
            dataType="record:procedure"
            selectedIDs={value}
            singleSelection={isSingleSelect}
          />
        );

      case "com.askthemis.types.v1.tag_risk_type":
        return <RiskTypesSelect {...defaultProps} selectedOptions={value} />;

      case "com.askthemis.types.v1.tag_main_category":
        return (
          <MainCategoriesSelect
            {...defaultProps}
            selectedOptions={value}
            l1ID={mainStore.avroSchemas.firstValueForField(
              "l1_risk_taxonomy",
              recordVersion?.data,
              // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
              fields,
            )}
          />
        );

      case "com.askthemis.types.v1.tag_control_mapping_type":
        return (
          <ControlMappingTypesSelect
            {...defaultProps}
            selectedOptions={value}
          />
        );

      case "com.askthemis.types.v1.control_effectiveness_rating":
        return (
          <ControlEffectivenessRating
            {...defaultProps}
            value={value}
            fieldName={fieldName}
          />
        );

      case "com.askthemis.types.v1.tag_control_category":
        return (
          <ControlCategoriesSelect {...defaultProps} selectedOptions={value} />
        );

      case "com.askthemis.types.v1.tag_department":
        return (
          <DepartmentSelect
            {...defaultProps}
            singleSelection={isSingleSelect}
            selectedIDs={value}
          />
        );

      case "com.askthemis.types.v1.tag_product":
        return <ProductsSelect {...defaultProps} selectedOptions={value} />;

      case "com.askthemis.types.v1.tag":
        return (
          <TagSelect {...defaultProps} isMultiSelect selectedOptions={value} />
        );

      case "com.askthemis.types.v1.tag_user":
        return (
          <UsersSelect
            {...defaultProps}
            singleSelection={isSingleSelect}
            columnTitle={columnTitle}
            selectedIDs={value}
            reviews={recordVersion.reviews}
            tableName={tableName || tableNameEnum.Default}
            moduleWorkspaceID={moduleWorkspaceID}
            isUserEditable={defaultProps.isUserEditable}
            withUsers={!field.include || Boolean(field.include.users)}
            withContacts={field.include && Boolean(field.include.contacts)}
          />
        );

      case "com.askthemis.types.v1.tag_user_contact":
        return (
          <UsersSelect
            {...defaultProps}
            singleSelection={isSingleSelect}
            columnTitle={columnTitle}
            selectedIDs={value}
            reviews={recordVersion.reviews}
            tableName={tableName || tableNameEnum.Default}
            withContacts
          />
        );

      case "com.askthemis.types.v1.tag_verifier":
        return (
          <Verifier
            key={fieldName}
            date={stringToDate(value?.verified_at)}
            verifierID={value?.verifier_id}
            width={columnWidth}
            locked={defaultProps.locked}
            pinned={defaultProps.pinned}
          />
        );

      case "com.askthemis.types.v1.checkbox":
        return <Checkbox {...defaultProps} defaultValue={value} />;

      case "com.askthemis.types.v1.link":
        return <LinkCell {...defaultProps} initialValue={value} />;

      case "com.askthemis.types.v1.text":
        if (field.name === "id") {
          return (
            <ThemisID
              key={`text-${recordVersion.id}`}
              recordVersionID={recordVersion.id}
              pinned={pinned}
              isPositionFirst={field.position === 1}
              themisID={mainStore.avroSchemas.valueForField(
                "id",
                recordVersion.data,
                // @ts-expect-error TS(2345) FIXME: Argument of type 'any[] | undefined' is not assign... Remove this comment to see the full error message
                fields,
              )}
            />
          );
        }

        return (
          <InputCell
            {...defaultProps}
            initialValue={value}
            type="text"
            disabled={isReadOnly || !defaultProps.isUserEditable}
            columnTitle={columnTitle}
          />
        );

      case "com.askthemis.types.v1.finra_dispute_amount":
        return (
          <Select
            {...defaultProps}
            selectedOptions={options}
            isFinraDisputeAmount
            columnTitle={columnTitle}
            moduleIdentifier={moduleIdentifier}
            dollarAmount={dollarAmount}
            isMultiSelect={isMultiSelect}
          />
        );

      case "com.askthemis.types.v1.integer":
        if (isComputedColumn) {
          return (
            <IntegerCell
              {...defaultProps}
              value={value}
              fieldName={fieldName}
              width={columnWidth}
              pinned={pinned}
              locked={defaultProps.locked}
            />
          );
        }

        return (
          <InputCell
            {...defaultProps}
            initialValue={value}
            type="number"
            disabled={isReadOnly}
            step="1"
          />
        );

      case "com.askthemis.types.v1.float": {
        let isCellLocked;

        // KRI input cell disabling logic based on dates and quarter/monthly intervals
        if (location.pathname.includes("/modules/key-risk-indicators/input")) {
          const kriIntervalType = field.kri_interval_type;
          isCellLocked = isKriCellLocked(
            recordVersion,
            kriIntervalType,
            fieldName,
          );
        }

        return (
          <InputCell
            {...defaultProps}
            initialValue={value}
            disabled={isReadOnly || isCellLocked}
            step=".01"
            performValidations={performValidations}
            type="number"
            recordVersion={recordVersion}
            handleUpdate={handleUpdate}
          />
        );
      }
      case "com.askthemis.types.v1.computed":
        if (!computedColumnIdentifier) {
          return null;
        }

        return renderComputedCell(
          computedColumnIdentifier,
          defaultProps,
          fieldName,
          style,
          pinned,
        );

      case "com.askthemis.types.v1.themis_module":
        return (
          <ThemisModuleCell
            fieldName={fieldName}
            value={value}
            style={style}
            locked={defaultProps.locked}
          />
        );

      default:
        return (
          <li
            key={fieldName}
            className={classNames({
              "locked-cell pointer-events-none": defaultProps.locked,
            })}
            style={style}
          >
            <div className="cell-content">{`${columnTitle} (${dataType})`}</div>
          </li>
        );
    }
  });

  // @ts-expect-error TS(7034) FIXME: Variable 'contextMenu' implicitly has type 'any' i... Remove this comment to see the full error message
  let contextMenu;

  switch (moduleIdentifier) {
    case "marketing":
      contextMenu = <MarketingContextMenu recordVersion={recordVersion} />;
      break;
    case "new_product_approval":
      contextMenu = (
        <ChangeManagementContextMenu recordVersion={recordVersion} />
      );
      break;
    case "vendor_due_diligence":
      contextMenu = (
        <VDDContextMenu
          recordVersion={recordVersion}
          currentTableName={currentTableName || tableNameEnum.Default}
        />
      );
      break;
    case "risk_register":
      if (currentTableName === "RelatedRisks") {
        contextMenu = (
          <IMRelatedRisksActions recordID={recordVersion.record.id} />
        );
      } else {
        contextMenu = (
          <RiskRegisterContextMenu
            recordVersionID={recordVersion.id}
            isLocked={isArchivedRow}
            isVersionHistory={location.pathname.includes("version-history")}
            isHistory={location.pathname.endsWith("risk-register-history")}
          />
        );
      }
      break;
    case "key_risk_indicators":
      contextMenu = (
        <KeyRiskIndicatorsContextMenu recordVersion={recordVersion} />
      );
      break;
    case Submodule.VALUES:
      contextMenu = <KRIValuesContextMenu recordVersion={recordVersion} />;
      break;
    case "policy":
    case "procedures":
      contextMenu = (
        <PolicyProcedureContextMenu
          recordVersion={recordVersion}
          moduleWorkspaceID={moduleWorkspaceID}
          tableID={tableID}
          tableName={tableName || tableNameEnum.Default}
          approvedByUserIds={reviewedByUserIds}
          archived={archived}
          moduleStore={mainStore.policies}
          isPolicy={moduleIdentifier === "policy"}
        />
      );
      break;
    case "issue_management":
      contextMenu = (
        <IssueManagementContextMenu
          recordVersion={recordVersion}
          isClosed={isArchivedRow}
        />
      );
      break;
    case "conflicts_of_interest":
      contextMenu = (
        <ConflictsOfInterestContextMenu
          recordVersion={recordVersion}
          reviewedByUserIds={reviewedByUserIds}
          moduleIdentifier={moduleIdentifier}
          status={status}
          moduleStore={mainStore.conflicts}
        />
      );
      break;
    case "training":
      contextMenu = (
        <TrainingContextMenu
          recordVersionID={recordVersion.id}
          approvedByUserIds={reviewedByUserIds}
          tableName={tableName || tableNameEnum.Default}
          // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          moduleWorkspaceID={moduleWorkspaceID}
        />
      );
      break;
    case "control_mapping":
      if (currentTableName === "OperationalControls") {
        if (mainStore.context.themisModuleIdentifier === "issue_management") {
          contextMenu = (
            <IMOperationalControlsActions
              controlRecordVersionID={recordVersion.id}
            />
          );
        } else {
          contextMenu = (
            <OperationalControlsActions
              controlRecordVersionID={recordVersion.id}
            />
          );
        }
      } else {
        contextMenu = (
          <ControlMappingActions
            recordVersion={recordVersion}
            // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
            moduleWorkspaceID={moduleWorkspaceID}
            approvedByUserIds={reviewedByUserIds}
            tableName={tableName || tableNameEnum.Default}
          />
        );
      }
      break;
    case "complaints":
      contextMenu = <ComplaintsContextMenu recordVersion={recordVersion} />;
      break;
    case "customer_support":
      contextMenu = (
        <CustomerSupportComplaintsContextMenu recordVersion={recordVersion} />
      );
      break;
    case "finra":
      contextMenu = (
        <FinraComplaintsContextMenu recordVersion={recordVersion} />
      );
      break;
    case "documents":
      contextMenu = <DocumentsContextMenu recordVersion={recordVersion} />;
      break;
    case "audits":
      contextMenu = <ThemisAuditContextMenu recordVersion={recordVersion} />;
      break;
    case "qa_tests_development":
      contextMenu = <QAContextMenu recordVersion={recordVersion} />;
      break;
    case "qa_findings":
      contextMenu = (
        <QAFindingsContextMenu findingsRecordVersionId={recordVersion.id} />
      );
      break;
    case "qa_procedures":
      contextMenu = (
        <QAProcedureStepsContextMenu
          procedureRecordVersion={recordVersion}
          isClosed={isArchivedRow}
          isCompleted={isCompleted}
        />
      );
      break;
    case "linked_documents":
      contextMenu = (
        <QALinkedDocumentsContextMenu recordVersion={recordVersion} />
      );
      break;
    case "action_plans":
      contextMenu = (
        <ActionPlansContextMenu actionPlansRecordVersionID={recordVersion.id} />
      );
      break;
    case "samples":
      contextMenu = (
        <TestSamplesContextMenu recordVersionId={recordVersion.id} />
      );
      break;
    case "zendesk":
      contextMenu = <ZendeskContextMenu recordVersion={recordVersion} />;
      break;
    case "risk_assessment":
      contextMenu = <PartnerContextMenu recordVersion={recordVersion} />;
      break;
    default:
      break;
  }

  if (location.pathname.includes("/historical-versions")) {
    contextMenu = null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const handleSelection = (event, selectedRecordVersionID) => {
    const { selectedMenuAction, menuActions } = mainStore.dynamicTable;

    // @ts-expect-error TS(2339) FIXME: Property 'selectAll' does not exist on type '{ sel... Remove this comment to see the full error message
    if (selectedMenuAction === menuActions.selectAll) {
      mainStore.dynamicTable.setSelectedMenuAction(
        menuActions.selectIndividual,
      );
    }

    // Select or deselect IDs of RecordVersions during edit mode
    if (event.target.checked) {
      mainStore.dynamicTable.addSelectedRecordVersionID(
        selectedRecordVersionID,
      );
    } else {
      mainStore.dynamicTable.removeSelectedRecordVersionID(
        selectedRecordVersionID,
      );
    }
  };

  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id, folder_id } = useParams();
  const showLockIcon = [
    `/workspaces/${workspaceID}/modules/documents`,
    `/workspaces/${workspaceID}/modules/documents/search`,
    `/workspaces/${workspaceID}/modules/documents/${folder_id}`,
    `/workspaces/${workspaceID}/modules/documents/folder/${folder_id}`,
    `/workspaces/${workspaceID}/modules/complaints`,
    `/workspaces/${workspaceID}/modules/complaints/completed`,
    `/workspaces/${workspaceID}/modules/finra/escalated`,
    `/workspaces/${workspaceID}/modules/finra/review`,
    `/workspaces/${workspaceID}/modules/customer-support/support`,
    `/workspaces/${workspaceID}/modules/customer-support/compliance`,
    `/workspaces/${workspaceID}/modules/procedures/drafts`,
    `/workspaces/${workspaceID}/modules/policy/drafts`,
    `/workspaces/${workspaceID}/modules/training`,
    `/workspaces/${workspaceID}/modules/qa-tests/completed`,
    `/workspaces/${workspaceID}/modules/qa-tests/${record_version_id}/issues`,
    `/workspaces/${workspaceID}/modules/qa-tests/${record_version_id}/linked_documents`,
    `/workspaces/${workspaceID}/modules/zendesk/completed`,
    `/workspaces/${workspaceID}/modules/control-mapping`,
    `/workspaces/${workspaceID}/modules/control-mapping/completed`,
    `/workspaces/${workspaceID}/modules/risk-register`,
    `/workspaces/${workspaceID}/modules/risk-register/completed`,
  ].includes(location.pathname);

  const listTableClasses = classNames(additionalRowClassName, {
    "disabled-marketing-row":
      isApprovedMarketing && moduleIdentifier === "marketing",
    "list-table": true,
    "draggable-wrap": isDraggable,
    highlighted,
  });

  const listTableBlockClasses = classNames({
    "close-complaint": isComplaintClosed && tableName === "FINRA Review",
    "list-table-block": true,
  });

  const getCheckboxStyle = () => {
    if (
      isInPolicyDrafts ||
      ["documents", "training", "marketing", "qa_tests_development"].includes(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        mainStore.context.themisModuleIdentifier,
      )
    ) {
      return {
        justifyContent: "flex-end",
        marginLeft: "auto",
        paddingLeft: "6px",
        paddingRight: "8px",
        marginRight: "6px",
      };
    }

    if (
      [
        "complaints",
        "vendors",
        "procedures",
        "issue_management",
        "control_mapping",
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      ].includes(mainStore.context.themisModuleIdentifier) &&
      inSelectMenuActionMode
    ) {
      return {
        justifyContent: "flex-start",
        width: "28px",
        marginRight: "2px",
        paddingLeft: "6px",
      };
    }

    return {};
  };

  const isChecked = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    (id) => mainStore.dynamicTable.selectedRecordVersionIDs.includes(id),
    [mainStore.dynamicTable.selectedRecordVersionIDs],
  );

  const isInPolicyDrafts =
    mainStore.context.themisModuleIdentifier === "policy" &&
    mainStore.context.tableName === "Drafts";

  const isInProcedureDrafts =
    mainStore.context.themisModuleIdentifier === "procedures" &&
    mainStore.context.tableName === "ProceduresDrafts";

  const haveLinkedControls = recordVersion?.linked_controls;
  const checkboxEnabled =
    (inSelectMenuActionMode || showCheckboxFromSlideMenu) && !isLockedRow;

  // @ts-expect-error TS(7006) FIXME: Parameter 'provided' implicitly has an 'any' type.
  const dragHandler = (provided) =>
    provided && (
      <div
        className={classNames("drag-handler", isSorted && "disabled")}
        data-testid="drag-handler"
        {...provided.dragHandleProps}
        data-tooltip-id="tooltip"
        data-tooltip-html="Drag to reorder. Drag and drop</br>
        is disabled while table is sorted."
        data-tooltip-place="bottom"
      >
        <img
          className="draggable-icon"
          src={draggableIcon}
          alt="draggable"
          data-testid="draggable-icon"
        />
      </div>
    );

  // @ts-expect-error TS(7006) FIXME: Parameter 'provided' implicitly has an 'any' type.
  const renderContent = (provided) => {
    const wrapperProps = {
      id: `record-version-${recordVersion.id}`,
      className: listTableClasses,
      "data-testid": "module-record-version",
      "data-record-version-id": recordVersion.id,
      "data-record-id": recordVersion.record_id,
    };

    const extraProps = provided
      ? {
          ref: provided.innerRef,
          ...provided.draggableProps,
        }
      : {};

    return (
      <div {...wrapperProps} {...extraProps}>
        <ul>
          {!isLockedRow && (
            <div
              className={classNames("list-table-wrap", {
                "add-margin-left":
                  isInProcedureDrafts && inSelectMenuActionMode,
              })}
              data-testid="record-version-row"
            >
              {checkboxEnabled && (
                <div
                  className={classNames("table-checkbox", {
                    "hidden-checkbox": haveLinkedControls,
                  })}
                  data-testid="record-checkbox"
                  style={getCheckboxStyle()}
                >
                  <input
                    type="checkbox"
                    id={recordVersion.id}
                    onChange={(event) => {
                      handleSelection(event, recordVersion.id);
                    }}
                    checked={isChecked(recordVersion.id)}
                  />
                  <label htmlFor={recordVersion.id} />
                </div>
              )}

              {!checkboxEnabled && !archived && (
                <div
                  className={classNames("table-first-block", {
                    "no-padding-left": inSelectMenuActionMode,
                  })}
                >
                  {isDraggable && dragHandler(provided)}
                  {renderFirstBlock ||
                    (!inSelectMenuActionMode && (
                      <CommentsSlideMenu
                        globalID={recordVersion.global_id}
                        recordID={recordVersion.record_id}
                        isLockedRow={
                          isApprovedMarketing ||
                          isArchivedRow ||
                          hasReview ||
                          false
                        }
                        uncompletedCommentsCount={
                          recordVersion.uncompleted_comments_count
                        }
                        taskableType="Record"
                      />
                    ))}
                </div>
              )}

              <div
                className={listTableBlockClasses}
                data-testid="module-record-version-cells"
                id={`cells-${recordVersion.id}`}
              >
                {renderCells}
                <span className="stretch-cell" />
              </div>
            </div>
          )}

          {isLockedRow && (
            <>
              {showCheckboxFromSlideMenu && (
                <div
                  className="table-checkbox"
                  data-testid="record-checkbox"
                  style={getCheckboxStyle()}
                >
                  <input
                    type="checkbox"
                    id={recordVersion.id}
                    onChange={(event) => {
                      handleSelection(event, recordVersion.id);
                    }}
                    checked={isChecked(recordVersion.id)}
                  />
                  <label htmlFor={recordVersion.id} />
                </div>
              )}
              <div className="list-table-wrap">
                {(isDraggable || showLockIcon) && (
                  <div className="table-first-block">
                    {isDraggable && dragHandler(provided)}

                    {showLockIcon && (
                      <CommentsSlideMenu
                        globalID={recordVersion.global_id}
                        recordID={recordVersion.record_id}
                        uncompletedCommentsCount={
                          recordVersion.uncompleted_comments_count
                        }
                        isLockedRow
                        taskableType="Record"
                      />
                    )}
                  </div>
                )}

                <div
                  className="list-table-block"
                  data-testid="module-record-version-cells"
                  id={`cells-${recordVersion.id}`}
                >
                  {renderCells}
                  <span className="stretch-cell" />
                </div>
              </div>
            </>
          )}

          {/* @ts-expect-error TS(7005) FIXME: Variable 'contextMenu' implicitly has an 'any' typ... Remove this comment to see the full error message */}
          {contextMenu}
        </ul>
      </div>
    );
  };

  if (isDraggable) {
    return (
      <Draggable
        key={recordVersion.id}
        draggableId={`record-version-draggable-${recordVersion.id}`}
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        index={order}
        isDragDisabled={isSorted}
        dropTargetCalculationMode="pointer"
      >
        {(provided) => renderContent(provided)}
      </Draggable>
    );
  }

  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  return renderContent();
}

ModuleRecordVersion.defaultProps = {
  inSelectMenuActionMode: false,
  isLockedRow: false,
  isLockedArchiveTable: false,
  archived: false,
  isCompleted: false,
  isDraggable: false,
  isSorted: false,
  performValidations: noop,
  maxDateFunction: noop,
  minDateFunction: noop,
  persistOnCallback: false,
};

export default observer(ModuleRecordVersion);

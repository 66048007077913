import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateThemisDocumentMutationRequest,
  CreateThemisDocumentMutationResponse,
  CreateThemisDocumentPathParams,
} from "../../models/CreateThemisDocument";

/**
 * @description Create a document
 * @summary Create a document
 * @link /:record_type/:record_id/themis_documents
 */
export async function createThemisDocument(
  recordType: CreateThemisDocumentPathParams["record_type"],
  recordId: CreateThemisDocumentPathParams["record_id"],
  data: CreateThemisDocumentMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateThemisDocumentMutationResponse>["data"]> {
  const res = await client<
    CreateThemisDocumentMutationResponse,
    CreateThemisDocumentMutationRequest
  >({
    method: "post",
    url: `/${recordType}/${recordId}/themis_documents`,
    data,
    ...options,
  });
  return res.data;
}

import { observer } from "mobx-react";
import type { ReactNode } from "react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useCompanyUsers } from "@/api/queries/users";
import { userColors } from "@/components/constants";
import { Icon } from "@/components/Elements";
import UsersCircle from "@/components/table/shared/UsersCircle";
import { useMainStore } from "@/contexts/Store";

interface QuestionnaireBuilderUserAssignmentProps {
  selectedIDs: number[];
  setSelectedIDs: (ids: number[]) => void;
  userIDsToInclude?: number[];
  ActionButton?: ReactNode;
  isSaving?: boolean;
  nonRemovableIDs?: number[];
}

const QuestionnaireBuilderUserAssignment = ({
  selectedIDs,
  setSelectedIDs,
  userIDsToInclude,
  ActionButton,
  isSaving,
  nonRemovableIDs = [],
}: QuestionnaireBuilderUserAssignmentProps) => {
  const mainStore = useMainStore();
  const [query, setQuery] = useState<string>("");
  const AWAITING_VERIFICATION_STATUS = "Awaiting Verification";
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const workspaceID = Number(workspace_id);
  const companyID = mainStore.context.companyID!;

  const { data: users } = useCompanyUsers(companyID, {
    workspace_id: Number(workspaceID),
  });

  const userList =
    users?.data.filter(
      (user) =>
        userIDsToInclude === undefined ||
        userIDsToInclude?.includes(user.id) ||
        query === "" ||
        user.full_name?.toLowerCase().includes(query.toLowerCase()),
    ) || [];

  const clickCheckbox = (userID: number) => {
    if (selectedIDs.includes(userID)) {
      setSelectedIDs(selectedIDs.filter((id) => id !== userID));
    } else {
      setSelectedIDs([...selectedIDs, userID]);
    }
  };

  const RenderCheckBox = () =>
    userList.map((user) => {
      const style = {
        background: userColors[user.icon_color_index as number],
      };
      const isAwaitingVerification =
        user.status === AWAITING_VERIFICATION_STATUS;

      return (
        <div key={user.id} className="user-assign-row">
          <input
            className="user-assignment-checkbox"
            type="checkbox"
            value={user.id}
            checked={selectedIDs.includes(user.id)}
            onChange={() => clickCheckbox(user.id)}
            disabled={nonRemovableIDs.includes(user.id) || isSaving}
          />
          <UsersCircle
            key={user.id}
            user={user}
            additionalStyles={style}
            isAwaitingVerification={isAwaitingVerification}
          />
        </div>
      );
    });

  return (
    <div className="users-dropdown" data-testid="users-select-dropdown">
      <div className="users-dropdown-search users-dropdown-search__update">
        <input
          type="text"
          data-testid="users-select-search-input"
          placeholder="Search Users"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
        />
        <Icon name="search" className="search-icon" />
      </div>
      <div className="users-assignment user-assignment-dropdown">
        <RenderCheckBox />
        {ActionButton}
      </div>
    </div>
  );
};

export default observer(QuestionnaireBuilderUserAssignment);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ListRelatablesPathParams,
  ListRelatablesQueryParams,
  ListRelatablesQueryResponse,
} from "../../models/ListRelatables";

/**
 * @description List relatable objects
 * @summary List relatable objects
 * @link /companies/:company_id/relatables
 */
export async function listRelatables(
  companyId: ListRelatablesPathParams["company_id"],
  params: ListRelatablesQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ListRelatablesQueryResponse>["data"]> {
  const res = await client<ListRelatablesQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/relatables`,
    params,
    ...options,
  });
  return res.data;
}

import type { ReactNode } from "react";
import React, { useMemo, useState } from "react";
import { IntlProvider } from "react-intl";

import { useLocaleMessages } from "./use-locale-messages/use-locale-messages";

export interface LocaleContextValue {
  locale: string;
  onChange: (locale: string) => void;
}

export const LocaleContext = React.createContext<LocaleContextValue | null>(
  null,
);

interface LocaleProviderProps {
  children: ReactNode;
}

export function LocaleProvider({ children }: LocaleProviderProps) {
  const [locale, setLocale] = useState<string>(
    typeof window === "undefined" ? "en-US" : window.navigator.language,
  );

  const value = useMemo((): LocaleContextValue => {
    return {
      locale,
      onChange: setLocale,
    };
  }, [locale]);

  const { data: messages } = useLocaleMessages(value.locale);

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider
        locale={value.locale}
        messages={messages?.default}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

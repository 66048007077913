import type { CheckedState } from "@radix-ui/react-checkbox";
import {
  Button,
  Checkbox,
  MiniTag,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  SortableList,
} from "@themis/ui";
import type { ReactElement } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { TbColumns3 } from "react-icons/tb";
import { useIntl } from "react-intl";

import type {
  FieldConfiguration,
  RecordType,
  UpdateFieldMutationRequest,
} from "@/api/gen/models";
import { useColumns, useUpdateColumns } from "@/api/queries/columns";
import { useOpen } from "@/hooks/use-open/use-open";

import { CustomColumnDialog } from "../custom-column-dialog/custom-column-dialog";

interface ColumnOption {
  label: string;
  id: number;
  readOnly: boolean;
  selected: boolean;
}

interface ColumnsSelectProps {
  companyId: number;
  recordClass: RecordType;
}

export function ColumnsSelect({
  companyId,
  recordClass,
}: ColumnsSelectProps): ReactElement {
  const { close, isOpen, toggle } = useOpen();
  const customColumnDialog = useOpen();
  const intl = useIntl();

  const [columnOptions, setColumnOptions] = useState<ColumnOption[]>([]);

  const { sortedColumns } = useColumns({
    companyId,
    recordClass,
  });
  const updateColumns = useUpdateColumns({ companyId, recordClass });

  const buttonColor = isOpen ? "active" : "transparent";

  const initColumnOptions = useCallback((fields: FieldConfiguration[]) => {
    setColumnOptions(
      fields.map((column) => ({
        label: column.display_name,
        id: column.id,
        readOnly: column.required,
        selected: !!column.visible,
      })),
    );
  }, []);

  useEffect(() => {
    initColumnOptions(sortedColumns);
  }, [sortedColumns, initColumnOptions]);

  const handleAddCustom = () => {
    close();
    customColumnDialog.open();
  };

  const handleApply = async () => {
    const updates = columnOptions.reduce(
      (
        acc: Record<number, UpdateFieldMutationRequest>,
        column,
        index: number,
      ) => {
        const update: UpdateFieldMutationRequest = {
          visible: column.selected,
          position: index,
        };

        return {
          ...acc,
          [column.id]: update,
        };
      },
      {},
    );
    await updateColumns.mutateAsync(updates);
    close();
  };

  const handleCancel = () => {
    // reset columns to initial state
    initColumnOptions(sortedColumns);
    close();
  };

  const handleCheckedChange = (optionId: number, checked: CheckedState) => {
    setColumnOptions((previousOptions) => {
      return previousOptions.map((option) => {
        if (option.id === optionId) {
          option.selected = Boolean(checked);
        }

        return option;
      });
    });
  };

  const handleReorder = (_options: ColumnOption[]) => {
    setColumnOptions(_options);
  };

  const numberOfSelectedColumns = 2;

  return (
    <>
      <Popover open={isOpen}>
        <PopoverTrigger asChild>
          <Button LeftIcon={TbColumns3} color={buttonColor} onClick={toggle}>
            {intl.formatMessage({
              defaultMessage: "Columns",
              description: "Custom columns select trigger text",
            })}
            <MiniTag
              className="tw-min-w-5"
              size="sm"
              variant="default"
              color="black"
            >
              {numberOfSelectedColumns}
            </MiniTag>
          </Button>
        </PopoverTrigger>

        <PopoverContent align="end" onEscapeKeyDown={handleCancel}>
          <div className="tw-box-border tw-flex tw-min-w-72 tw-max-w-80 tw-flex-col tw-gap-1 tw-font-sans">
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-pb-2 tw-pt-4">
              <h3 className="tw-font-semibold tw-text-neutral-500">
                {intl.formatMessage({
                  defaultMessage: "Column Settings",
                  description: "Custom columns popover title",
                })}
              </h3>
            </div>
          </div>
          <ScrollArea className="tw-flex tw-max-h-60 tw-flex-col">
            <SortableList
              classes={{
                item: "tw-px-4 tw-py-1.5",
              }}
              items={columnOptions}
              renderItem={(option) => (
                <label
                  className="tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-2"
                  key={option.id}
                >
                  <Checkbox
                    size="md"
                    color="primary"
                    checked={option.selected}
                    disabled={option.readOnly}
                    onCheckedChange={(checked) =>
                      handleCheckedChange(option.id, checked)
                    }
                  />
                  <span className="tw-select-none tw-text-sm tw-font-medium tw-text-neutral-500">
                    {option.label}
                  </span>
                </label>
              )}
              onReorder={handleReorder}
            />
          </ScrollArea>
          <div className="tw-h-px tw-bg-primary-50" />
          <div className="tw-bg-primary-25 tw-px-1.5 tw-py-1">
            <Button
              color="transparent"
              LeftIcon={PiPlusBold}
              onClick={handleAddCustom}
            >
              {intl.formatMessage({
                defaultMessage: "Add Custom Column",
                description: "Add Custom Column button text",
              })}
            </Button>
          </div>
          <div className="tw-h-px tw-bg-primary-50" />
          <div className="tw-flex tw-items-center tw-justify-end tw-gap-2 tw-px-4 tw-py-3">
            <Button loading={updateColumns.isPending} onClick={handleApply}>
              {intl.formatMessage({
                defaultMessage: "Apply",
                description: "Apply button text",
              })}
            </Button>
            <Button color="tertiary" onClick={handleCancel}>
              {intl.formatMessage({
                defaultMessage: "Cancel",
                description: "Cancel button text",
              })}
            </Button>
          </div>
        </PopoverContent>
      </Popover>

      {customColumnDialog.isOpen && (
        <CustomColumnDialog open onOpenChange={customColumnDialog.toggle} />
      )}
    </>
  );
}

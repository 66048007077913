import React from "react";

import type { TopAndSubModuleToModuleInfoMap } from "@/stores/types/module-workspaces-types";
import type { ModuleWorkspace } from "@/stores/types/workspace-types";

import { NavigationLink } from "./navigation/NavigationLink";

interface Props {
  moduleInfo: TopAndSubModuleToModuleInfoMap;
  moduleWorkspace: ModuleWorkspace;
}
function DashboardMenuItem({ moduleInfo, moduleWorkspace }: Props) {
  const moduleIdentifier = moduleWorkspace.themis_module.identifier;
  const mInfo = moduleInfo[moduleIdentifier];
  const { name } = moduleWorkspace;
  return (
    <NavigationLink
      key={mInfo.url}
      badge={mInfo.badge}
      url={mInfo.url}
      basePath={mInfo.basePath}
      name={name}
      moduleIdentifier={moduleIdentifier}
    />
  );
}

export default DashboardMenuItem;

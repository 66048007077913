import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  WebsiteMonitoringGroupChildPathParams,
  WebsiteMonitoringGroupChildQueryParams,
  WebsiteMonitoringGroupChildQueryResponse,
} from "../../models/WebsiteMonitoringGroupChild";

/**
 * @description List Website Monitoring - Groups with children
 * @summary List Website Monitoring
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_children
 */
export async function websiteMonitoringGroupChild(
  monitoringGroupAssetId: WebsiteMonitoringGroupChildPathParams["monitoring_group_asset_id"],
  params?: WebsiteMonitoringGroupChildQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<WebsiteMonitoringGroupChildQueryResponse>["data"]> {
  const res = await client<WebsiteMonitoringGroupChildQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_children`,
    params,
    ...options,
  });
  return res.data;
}

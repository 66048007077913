import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import type { User } from "@/stores/types/user-types";

import { userColors } from "../../constants";

interface UsersCircleProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  additionalClassNames?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalStyles?: any;
  additionalText?: string;
  isAwaitingVerification?: boolean;
  showFullName?: boolean;
}

function UsersCircleBase({
  user,
  additionalStyles,
  showFullName,
  additionalText,
  additionalClassNames,
  isAwaitingVerification,
}: UsersCircleProps & { user: NonNullable<UsersCircleProps["user"]> }) {
  const mainStore = useMainStore();

  const { allUsers } = mainStore.users;
  const { pathname } = useLocation();
  const { isIW } = mainStore.context;
  const isAllUsers = pathname === "/settings/users" && isIW;

  const username: string = user.full_name || user.username;

  const [awaitingVerification, setAwaitingVerification] = useState<
    boolean | undefined
  >(isAwaitingVerification || user.awaiting_verification);
  const [isContact, setIsContact] = useState<boolean | undefined>(
    user.is_contact,
  );
  const [isInactive, setIsInactive] = useState<boolean | undefined>(
    user.is_inactive,
  );

  // if user doesn't have certain properties (eg. this component was rendered by AuditTrail), look them up using user_id
  if (
    awaitingVerification === undefined &&
    isContact === undefined &&
    isInactive === undefined
  ) {
    // @ts-expect-error TS(2322) FIXME: Type 'User | undefined' is not assignable to type ... Remove this comment to see the full error message
    const matchingUserFromStore: User = allUsers.find(
      (userFromAllUsers) => userFromAllUsers.id === user.user_id,
    );

    if (matchingUserFromStore) {
      setAwaitingVerification(matchingUserFromStore.awaiting_verification);
      setIsContact(matchingUserFromStore.is_contact);
      setIsInactive(matchingUserFromStore.is_inactive);
    }
  }

  return (
    <div
      data-testid="users-circle-container"
      className={classNames("users users-circle-container", {
        "awaiting-verification": awaitingVerification,
        contact: isContact,
        inactive: !isAllUsers && isInactive,
      })}
    >
      <div
        className={classNames("users-circle", additionalClassNames)}
        style={{
          ...additionalStyles,
          borderColor: isContact
            ? userColors[user.icon_color_index]
            : "inherit",
        }}
      >
        <span data-testid="users-circle-initials">{user.initials}</span>
      </div>
      {showFullName && (
        <div className="fullname" data-testid="users-circle-fullname">
          {username} <div className="additional-text">{additionalText}</div>
        </div>
      )}
    </div>
  );
}

function UsersCircle({ user, ...rest }: UsersCircleProps) {
  // todo -> no info about external user in the internal workspace
  if (!user) {
    return null;
  }

  return <UsersCircleBase user={user} {...rest} />;
}

UsersCircle.defaultProps = {
  additionalStyles: {},
  additionalClassNames: "",
  additionalText: "",
  showFullName: true,
};

export default observer(UsersCircle);

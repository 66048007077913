var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Slot } from "@radix-ui/react-slot";
import { cva, cx } from "cva";
import * as React from "react";
import { cn } from "../../lib/utils";
import { buttonBaseVariants } from "./ButtonBase";
var iconButtonVariants = cva({
    base: ["tw-rounded"],
    variants: {
        variant: {
            square: "",
            vertical: "",
        },
        size: {
            sm: "tw-h-6",
            md: "tw-h-7",
            lg: "tw-h-8",
        },
    },
    compoundVariants: [
        {
            variant: "square",
            size: "sm",
            className: "tw-w-6",
        },
        {
            variant: "square",
            size: "md",
            className: "tw-w-7",
        },
        {
            variant: "square",
            size: "lg",
            className: "tw-w-8",
        },
        {
            variant: "vertical",
            size: ["sm", "md", "lg"],
            className: "tw-w-4",
        },
    ],
    defaultVariants: {
        variant: "square",
        size: "md",
    },
});
var IconButton = React.forwardRef(function (_a, ref) {
    var className = _a.className, color = _a.color, disabled = _a.disabled, size = _a.size, variant = _a.variant, _b = _a.asChild, asChild = _b === void 0 ? false : _b, Icon = _a.Icon, _c = _a.type, type = _c === void 0 ? "button" : _c, props = __rest(_a, ["className", "color", "disabled", "size", "variant", "asChild", "Icon", "type"]);
    var Comp = asChild ? Slot : "button";
    return (React.createElement(Comp, __assign({ className: cn(cx(buttonBaseVariants({ color: color, disabled: disabled }), iconButtonVariants({ size: size, variant: variant, className: className }))), disabled: disabled, ref: ref, type: type }, props),
        React.createElement(Icon, { className: "tw-h-4 tw-w-4" })));
});
IconButton.displayName = "IconButton";
export { IconButton, iconButtonVariants };

import { MiniTag } from "@themis/ui";
import React from "react";

export const TaskStatus = {
  not_started: {
    value: "not_started",
    key: "not_started",
    label: "Not Started",
    Component: () => <MiniTag color="grey">Not Started</MiniTag>,
  },
  in_progress: {
    value: "in_progress",
    key: "in_progress",
    label: "In Progress",
    Component: () => <MiniTag color="primary">In Progress</MiniTag>,
  },
  done: {
    value: "done",
    key: "done",
    label: "Completed",
    Component: () => <MiniTag color="turquoise">Completed</MiniTag>,
  },
} as const;

export const TASK_STATUSES = Object.values(TaskStatus);

import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateProjectMutationRequest,
  UpdateProjectMutationResponse,
  UpdateProjectPathParams,
} from "../../models/UpdateProject";

/**
 * @description Update project
 * @summary Update project
 * @link /projects/:id
 */
export async function updateProject(
  id: UpdateProjectPathParams["id"],
  data: UpdateProjectMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateProjectMutationResponse>["data"]> {
  const res = await client<
    UpdateProjectMutationResponse,
    UpdateProjectMutationRequest
  >({
    method: "put",
    url: `/projects/${id}`,
    data,
    ...options,
  });
  return res.data;
}
